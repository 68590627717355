import { createContext } from 'react';

interface ScreenTypes {
  isSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
}

const MediaContext = createContext<ScreenTypes | undefined>(undefined);

export { MediaContext, ScreenTypes };
