import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import React from 'react';
import styled from 'styled-components';

function CheckTopic({
  startColor,
  endColor,
  topic,
  onClick,
  showIcon,
  percentage,
}: {
  startColor?: string;
  endColor?: string;
  topic?: any;
  onClick?: () => void;
  showIcon?: boolean;
  percentage?: number;
}) {
  return (
    <ContainerStyles>
      <FillerStyles endColor={endColor} completed={percentage ?? 0}>
        <LabelStyles>{topic?.title}</LabelStyles>
      </FillerStyles>
      {/* {showIcon === false ? null : (
        <ArrowForwardIos
          style={{
            width: 16,
            color: 'rgba(202, 202, 202, 1)',
            position: 'absolute',
            right: 40,
            height: 14,
            cursor: 'pointer',
            marginTop: 3,
          }}
        />
      )} */}
    </ContainerStyles>
  );
}

const ContainerStyles = styled.div`
  border-radius: 1.25rem;
  border: 1px solid rgba(23, 51, 0, 0.3);
  background: white;
`;

const FillerStyles = styled.div<{ endColor?: string; completed?: number }>`
  position: relative;
  height: 100%;
  padding: ${(props) =>
    props.completed === 0 ? '1.65rem 0rem 1.65rem 0rem' : '1.65rem 1rem 1.65rem 1rem'};
  width: ${(props) => props.completed}%;
  background-color: ${(props) => props.endColor};
  border-top-left-radius: 1.2rem;
  border-bottom-left-radius: 1.2rem;
  border-top-right-radius: ${(props) => (props.completed === 100 ? '1.2rem' : '0rem')};
  border-bottom-right-radius: ${(props) => (props.completed === 100 ? '1.2rem' : '0rem')};
`;

const LabelStyles = styled.div`
  display: inline-block;
  position: absolute;
  width: max-content;
  z-index: 1;
  font-size: 14px;
  font-weight: 500;
  bottom: 15px;
  margin-left: 12px;
`;

export default CheckTopic;
