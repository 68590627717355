/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HttpClient, RequestParams } from './http-client';

export class OpenGraphService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Opengraph
   *
   * @tags OpenGraphService
   * @name GetOpenGraphData
   * @summary Opengraph
   * @request GET:/v2/opengraph
   * @secure
   */
  getOpenGraphData = (
    query: {
      url: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<object, any>({
      path: `/v2/opengraph`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
