/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import { InternalLinkCard, PostModel } from 'types/CommonTypes';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { observer } from 'mobx-react';
import { getFeedThmbnailUrl } from 'components/items/commonLogic';
import { useStore } from 'stores/StoreHelper';
import { OhmossButton } from 'resources/controls/OhmossButton';
import styled, { css } from 'styled-components';

import VideoComponent from 'features/adminFeatures/commons/components/VideoComponent';

import 'swiper/css/pagination';
import 'swiper/css';
import ReplyForm from 'features/board/containers/ReplyForm';
import ListOfComments from 'features/board/containers/ListOfComments';

import { isMobile } from 'react-device-detect';
import PostModifyTool from '../PostModifyTool';

// todo : 수정처리 하기
const PostListItem = observer(({ item }: { item: PostModel }) => {
  const containerRef = useRef<any>(null);
  const { postStore, channelStore, myChannelStore, uiStore, uiStores } = useStore();
  const [cards, setCards] = useState<Array<InternalLinkCard>>();
  const [swiper, setSwiper] = useState<any>(null);
  const [index, setIndex] = useState<number>(0);
  const [imgBoxHeight, setImgBoxHeight] = useState<number>();

  const imgRefs = useRef<any>([]);

  const setMaxHeight = () => {
    const heights: Array<number> = [];

    const maxHeight = async () => {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < imgRefs.current.length; index++) {
        imgRefs.current[index].addEventListener('load', () => {
          const { height } = imgRefs.current[index].getBoundingClientRect();
          heights.push(height);
          if (imgRefs.current.length === heights.length) {
            setImgBoxHeight(Math.max(...heights));
          }
        });
      }
    };
    maxHeight();
  };

  const setMaxHeight2 = () => {
    const heights: Array<number> = [];
    setTimeout(() => {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < imgRefs.current.length; index++) {
        const { height } = imgRefs.current[index].getBoundingClientRect();
        heights.push(height);
        if (imgRefs.current.length === heights.length) {
          setImgBoxHeight(Math.max(...heights));
        }
      }
    }, 100);
  };

  useEffect(() => {
    if (typeof postStore.post?.items !== 'undefined') {
      setCards(postStore.post?.items);
    }
  }, [postStore.post?.items]);

  useEffect(() => {
    if (item) {
      getItem();
    }
    async function getItem() {
      if (channelStore.channel) {
        const items = (await postStore.getPost(
          channelStore.channel?.channelName!,
          uiStores.userHomeStore.currentBoard?.id as number,
          item.id as number,
          myChannelStore.currentChannel?.id as number,
        )) as any;
        setCards(items.items);
      }
    }
  }, [item]);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(index);
    }
  }, [index]);

  useEffect(() => {
    window.addEventListener('resize', setMaxHeight2);
    return () => {
      window.removeEventListener('resize', setMaxHeight2);
      postStore.unMount();
    };
  }, []);

  useEffect(() => {
    if (typeof cards !== 'undefined') {
      setMaxHeight();
    }
  }, [cards]);

  return (
    <CardContainerStyle
      ref={containerRef}
      style={{
        overflowY: 'hidden',
        marginBottom: 15,
        // height: 'auto',
        background: 'transparent',
      }}
    >
      {cards && cards?.length > 0 && (
        <>
          <div className="feedlink-component">
            <Swiper
              // style={{ overflow: 'hidden', borderRadius: 20 }}
              pagination
              modules={[Pagination]}
              spaceBetween={0}
              slidesPerView={1}
              style={{ margin: isMobile ? 0 : '0 20px', paddingBottom: 30 }}
              onSwiper={(swiper) => setSwiper(swiper)}
            >
              {cards?.map((card: InternalLinkCard, index: number) => (
                <SwiperSlide key={card.id} style={{ alignItems: 'center', display: 'flex' }}>
                  <>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <div>
                        {card.file && (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              background: '#000',
                              height: imgBoxHeight,
                            }}
                          >
                            {card.file?.contentType?.indexOf('image/') === 0 ? (
                              <>
                                <img
                                  ref={(el: any) => {
                                    // eslint-disable-next-line no-return-assign
                                    return (imgRefs.current[index] = el);
                                  }}
                                  className="feed-image"
                                  src={getFeedThmbnailUrl(card.file?.publicUrl, '900')}
                                  style={{ width: '100%', height: 'auto' }}
                                  alt=""
                                />
                              </>
                            ) : (
                              <VideoComponent
                                isCover={card.file?.sizeInfo?.isCover}
                                video={card.file}
                              />
                            )}
                          </div>
                        )}
                      </div>
                      <FeedItemDescriptionStyle style={{ background: '#fff' }}>
                        <FeeItemTitleStyle>{card.title && card.title} </FeeItemTitleStyle>
                        {card.linkUrl && (
                          <OhmossButton
                            type="simple"
                            onClick={() => {
                              const linkUrl = card.linkUrl?.includes('http')
                                ? card.linkUrl
                                : `http://${card.linkUrl}`;
                              window.open(linkUrl, '_blanc');
                            }}
                          >
                            방문하기
                          </OhmossButton>
                        )}
                      </FeedItemDescriptionStyle>
                    </div>
                  </>
                  {/* <>{cardItem(card, index)}</> */}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </>
      )}
      <PostModifyTool
        item={item}
        shareSubject={item.title || ''}
        // eslint-disable-next-line max-len
        shareUrl={`https://ohmoss.com/${channelStore.channel.channelName}/${uiStores.userHomeStore.currentBoard?.id}/${item.id}`}
      />
      <div
        style={{ whiteSpace: 'pre-line', padding: '0 20px', paddingBottom: 20 }}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: postStore.post?.content as string,
        }}
      />

      {uiStores.userHomeStore.currentBoard?.isCommentAllow && (
        <>
          <div style={{ marginLeft: 20 }}>
            <ListOfComments postId={item.id as number} />
          </div>
          <ReplyForm feedId={item?.id} type="reply" />
        </>
      )}
    </CardContainerStyle>
  );
});
// min-width: ${(props) => (props.isSimulatore ? '100px' : props.isMobile ? '110px' : '150px')};

const CardContainerStyle = styled.div`
  transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);

  @media ${(props) => props.theme.media.mobile} {
  }
`;

const ArrowStyle = css<{ isMobile: boolean }>`
  top: 50%;
  width: ${(props) => (props.isMobile ? '20px' : '30px')};
  height: ${(props) => (props.isMobile ? '20px' : '30px')};
  padding: 5px 5px 5px 5px;
  border-radius: ${(props) => (props.isMobile ? '20px' : '30px')};
  background: rgba(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 20;
  position: absolute;
`;

const ArrowStyleLeft = styled.div`
  ${ArrowStyle}
  left: 5px;
`;

const ArrowStyleRight = styled.div`
  ${ArrowStyle}
  right:5px;
`;

const FeedItemDescriptionStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  text-align: center;
  width: 100%;
  margin-top: 10px;
`;
const FeeItemTitleStyle = styled.div`
  font-weight: 500;
  font-size: 24px;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 20px;
  }
`;

export default PostListItem;
