import React, { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';

import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { LinkModel } from 'types/CommonTypes';
import { LinkDto, ChannelBoardDto, BoardCategoryDto } from 'services/data-contracts';

import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { ToggleButton } from 'components/commons/ToggleButton';
import { Swiper, SwiperSlide } from 'swiper/react';

import AddIcon from '@mui/icons-material/Add';
import _ from 'lodash';
import ListOfLinksContainer from 'features/adminFeatures/link/containers/ListOfLinksContainer';
import CreateBoardForm from 'features/adminFeatures/profile/components/CreateBoardForm';
import ToggleContainerStyle from 'resources/controls/ToggleContainerStyle';
import { linkTypes, ILinkItemType } from '../definitions/LinkTypes';
import LinkTypeSelector from '../components/LinkTypeSelector';

import 'swiper/css/pagination';
import 'swiper/css';

/**
 * @description 모든 링크를 생성하기 위한 컨테이너임
 */
const LinkCreateContainer = observer(() => {
  const stores = useStore();
  const { t } = useTranslation();
  const { adminStore, userHomeStore } = stores.uiStores;

  const [currentBoard, setCurrentBoard] = useState<ChannelBoardDto>();
  const [linkBoards, setLinkBoards] = useState<Array<ChannelBoardDto>>();
  const [showBoardForm, setShowBoardForm] = useState<boolean>(false);

  const handleOnItemDelete = (link: LinkDto) => {
    stores.uiStore.confirm.show({
      style: { maxWidth: 400, height: 220 },
      message: t('A134'),
      okText: t('Delete'),
      closeText: t('Cancel'),
      onConfirmed: async () => {
        const result = await stores.myLinkStore.deleteItem(
          stores.myChannelStore.currentChannel?.id as number,
          userHomeStore.currentBoard?.id as number,
          link?.id as number,
        );
        if (result) {
          stores.myLinkStore.updateLinkByItem(link, 'delete');
        }
      },
    });
  };

  const handleClickCreate = (linkType?: ILinkItemType, model?: LinkDto) => {
    const defaultlink = typeof linkType === 'undefined' ? linkTypes[0] : linkType;
    stores.uiStore.bottomSheet.show({
      // title: model?.id ? t('Modify') : defaultlink?.title,
      // description: defaultlink.description,
      // modalOption: { style: { maxWidth: 900 } },
      topPosition: 100,
      // bottomSheetOptions: { topPosition: isMobile ? 0 : 10, blocking: false },
      children: (
        <LinkContainerStyle>
          {typeof model?.id === 'undefined' && (
            <LinkTypeSelector selectedType={defaultlink} onClickLinkType={handleClickCreate} />
          )}
          <EditorComponentStyle>
            {getEditorComponent(defaultlink, model)}
          </EditorComponentStyle>
        </LinkContainerStyle>
      ),
      onClose: () => {
        adminStore.setShowNewLinkEditor(false);
      },
      onConfirmed() {
        adminStore.setShowNewLinkEditor(false);
      },
    });
  };

  function getEditorComponent<T extends LinkDto>(
    LinkType: ILinkItemType,
    model?: LinkModel,
  ): React.ReactElement {
    const Children = LinkType.component;
    const newModel: any = { ...model, linkType: LinkType.id };

    return (
      <Children
        model={newModel as T}
        mode={typeof model === 'undefined' ? 'new' : 'modify'}
        stores={stores}
        onRegistComplete={(result: any, linkItem: LinkModel) => {
          if (linkItem === null) {
            return;
          }
          if (typeof newModel.id === 'undefined') {
            stores.myLinkStore.appendLink(linkItem);
          } else {
            stores.myLinkStore.updateLinkByItem(linkItem);
          }
          stores.uiStore.universalModal.close();
        }}
        onCancelModity={() => { }}
      />
    );
  }

  const initChannelBoards = async () => {
    const boards: Array<ChannelBoardDto> = (await stores.myBoardStore.getBoards(
      stores.myChannelStore.currentChannel?.id as number,
    )) as never;
    const linkBoards = boards.filter(
      (channelBoard: ChannelBoardDto) => channelBoard.boardType === 'LINK',
    );
    if (linkBoards.length > 0) {
      setLinkBoards(linkBoards);
      setCurrentBoard(linkBoards[0]);
    } else {
      setLinkBoards([]);
    }
  };

  useEffect(() => {
    if (stores.myChannelStore?.currentChannel && stores.uiStores.userHomeStore.currentBoard) {
      stores.myLinkStore.getItems(
        stores.myChannelStore?.currentChannel?.id as number,
        stores.uiStores.userHomeStore.currentBoard?.id as number,
      );
    }
  }, [stores.myChannelStore?.currentChannel, stores.uiStores.userHomeStore.currentBoard]);

  useEffect(() => {
    if (adminStore.showNewLinkEditor) {
      handleClickCreate();
    }
  }, [adminStore.showNewLinkEditor]);

  useLayoutEffect(() => {
    if (stores.myChannelStore.currentChannel) initChannelBoards();
  }, [stores.myChannelStore.currentChannel]);

  useEffect(() => {
    if (currentBoard) userHomeStore.setBoard(currentBoard);
  }, [currentBoard]);

  return (
    <ContainerStyle>
      {typeof stores.myBoardStore?.myBoards.find((board: BoardCategoryDto) => board.boardType === 'LINK') !== 'undefined' && (
        <CreateButtonStyle>
          <OhmossButtonStyle
            type="primary"
            style={{ height: 50, flex: 1, display: 'flex', margin: 20, padding: '0px 10px' }}
            onClick={() => {
              handleClickCreate();
            }}
          >
            <AddIcon />
            <div>{t('A133')}</div>
          </OhmossButtonStyle>
        </CreateButtonStyle>
      )}
      <div style={{ margin: '0 15px' }}>
        {Array.isArray(stores.myBoardStore?.myBoards) && (
          <>
            <Swiper spaceBetween={0} slidesPerView={2.5}>
              {linkBoards?.map((channelBoard: ChannelBoardDto) => (
                <SwiperSlide className="swiper" key={channelBoard.id}>
                  <ToggleContainerStyle
                    style={{ width: '100%' }}
                    key={channelBoard?.id}
                    onClick={() => {
                      setCurrentBoard(channelBoard);
                    }}
                  >
                    <ToggleButton
                      item={{ description: channelBoard.name, id: channelBoard.id!.toString() }}
                      defaultChecked={channelBoard?.id === currentBoard?.id}
                    />
                  </ToggleContainerStyle>
                </SwiperSlide>
              ))}
            </Swiper>

          </>
        )}
        {stores.myLinkStore.links?.length > 0 ? (
          <ListOfLinksContainer
            draggable
            onLinkDelete={(link: LinkDto) => {
              handleOnItemDelete(link);
            }}
            onLinkEdit={(link: LinkDto) => { }}
            onLinkSelect={(link: LinkModel) => {
              const index = linkTypes.findIndex((type) => type.id === link?.linkType);
              handleClickCreate(linkTypes[index], link);
            }}
          />
        ) : (
          <>
            {stores.myBoardStore.linkBoards?.length === 0 ? (
              <EmptyLinkStyle>
                <div style={{ textAlign: 'center' }}>
                  <div>{t('A130')}</div>
                  <div style={{ marginTop: 20 }}>
                    <OhmossButton
                      type="primary"
                      style={{ height: 50, width: 120 }}
                      onClick={() => {
                        setShowBoardForm(true);
                      }}
                    >
                      <div>{t('A125')}</div>
                    </OhmossButton>
                  </div>
                </div>
              </EmptyLinkStyle>
            ) : (
              <EmptyLinkStyle>
                <div style={{ textAlign: 'center' }}>
                  <div>{t('A131')}</div>
                  <div>{t('A132')}</div>
                </div>
              </EmptyLinkStyle>
            )}
          </>
        )}
        <CreateBoardForm
          boardType="LINK"
          show={showBoardForm}
          onClose={() => {
            setShowBoardForm(false);
            initChannelBoards();
          }}
        />
      </div>
    </ContainerStyle >
  );
});

const OhmossButtonStyle = styled(OhmossButton)`
  /* overflow: hidden; */
  padding: 20px;
`;

const ContainerStyle = styled.div`
  white-space: pre-line;
  @media ${(props) => props.theme.media.mobile} {
    /* position: absolute; */
    left: 0;
    right: 0;
    /* bottom: 180px; */
  }
`;

const CreateButtonStyle = styled.div`
  z-index: 100;
  /* display: none; */
  @media ${(props) => props.theme.media.mobile} {
    width: 80%;
    display: unset;
  }
`;

const EmptyLinkStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
`;

const LinkContainerStyle = styled.div`
  max-width: 550px;
`;

const EditorComponentStyle = styled.div`
  padding:0 20px;
`;

export default LinkCreateContainer;
