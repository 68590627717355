import React, { CSSProperties, useEffect, useState } from 'react';
import { TextContent } from 'types/CommonTypes';

const Title = ({
  title,
  mode,
  titleProps,
}: {
  title?: string;
  mode: 'view' | 'edit';
  titleProps?: CSSProperties;
}) => {
  const [displayTitle, setDisplayTitle] = useState<string>();
  useEffect(() => {
    if (mode === 'edit') {
      if (typeof title === 'undefined' || title === null || title === '') {
        setDisplayTitle('나를 한마디로 표현해주세요.');
      } else {
        setDisplayTitle(title);
      }
    } else {
      setDisplayTitle(title);
    }
  }, [title, mode]);
  return (
    <div
      style={{
        marginTop: 5,
        whiteSpace: 'pre-line',
        background: mode === 'edit' ? '#fafafa' : 'transparent',
        padding: 10,
        borderRadius: 10,
        ...titleProps,
      }}
    >
      {displayTitle}
    </div>
  );
};

export default Title;
