import { makeObservable, observable } from 'mobx';
import { MyTag as MyTagTagService } from 'services/MyTag';
import { REST_API_URL, REST_API_URL_V2 } from 'stores/AppStore';
import { TagDto } from 'services/data-contracts';
import { GroupedTag } from 'types/CommonTypes';

import _ from 'lodash';
import ServiceStoreBase from './ServiceStoreBase';
import { HttpClient } from 'services/http-client';
import i18n from 'i18n';

export interface isValid {
  isValid: boolean;
}

class MyTagStore extends ServiceStoreBase {
  t = i18n.t;

  tags: Array<TagDto>;

  groupTags?: Array<GroupedTag>;

  constructor() {
    super();
    makeObservable(this, {
      tags: observable,
      groupTags: observable,
    });
  }

  api: MyTagTagService<unknown> = new MyTagTagService(new HttpClient());

  *getItems(args: Array<never>) {
    const { response } = yield this.api.getTags(args[0]);
    const grouped = _.chain(response.data)
      .groupBy('groupName')
      .map((tags, groupName) => ({ groupName, tags }))
      .value();
    this.groupTags = grouped;
    this.tags = response.data;
  }

  *createItem(tagInfo: TagDto, args: Array<never>) {
    const { response } = yield this.api.createTag(args[0], tagInfo);
    return super.handleByStatus(response, { successMessage: this.t('A218') });
  }

  *updateItem(chanelId: number, tagInfo: TagDto) {
    if (tagInfo.id) {
      const { response } = yield this.api.updateTag(chanelId, tagInfo.id, tagInfo);
      return super.handleByStatus(response, { successMessage: this.t('A219') });
    }
  }

  *deleteItem(channelId: number, args: Array<never>) {
    const { response } = yield this.api.deleteTag(channelId, args[0]);
    return super.handleByStatus(response, { successMessage: this.t('A220') });
  }
}

export default MyTagStore;
