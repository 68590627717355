import React, { CSSProperties, useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton } from '@mui/material';
import { StickerTypes } from './StickerTypes';

const StickerColorTheme = ({
  sticker,
  onClick,
}: {
  sticker?: StickerTypes;
  onClick: (style: CSSProperties) => void;
}) => {
  const handleClickTheme = (style: CSSProperties, index: number) => {
    onClick(style);
  };

  const Temp = sticker?.component;
  const styles = [
    { fill: '#fff', stroke: '#05C755', background: '#fff' },
    {
      color: '#ff6b6b',
      borderColor: '#ff6b6b',
      fill: '#fff',
      stroke: '#ff6b6b',
      background: '#fff',
    },
    {
      color: '#6741d9',
      borderColor: '#6741d9',
      background: '#fff',
      fill: '#fff',
      stroke: '#6741d9',
    },
    {
      color: '#fcc419',
      borderColor: '#fcc419',
      background: '#fff',
      fill: '#fff',
      stroke: '#fcc419',
    },
    {
      color: '#5c7cfa',
      borderColor: '#5c7cfa',
      background: '#fff',
      fill: '#fff',
      stroke: '#5c7cfa',
    },
    { fill: '#05C755', stroke: '#fff', background: '#05C755', color: '#fff', borderColor: '#fff' },
    {
      color: '#fff',
      borderColor: '#fff',
      fill: '#ff6b6b',
      stroke: '#fff',
      background: '#ff6b6b',
    },
    {
      color: '#fff',
      borderColor: '#fff',
      background: '#6741d9',
      fill: '#6741d9',
      stroke: '#fff',
    },
    {
      color: '#fff',
      borderColor: '#fff',
      background: '#fcc419',
      fill: '#fcc419',
      stroke: '#fff',
    },
    {
      color: '#fff',
      borderColor: '#fff',
      background: '#5c7cfa',
      fill: '#5c7cfa',
      stroke: '#fff',
    },
  ];
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '15px',
        justifyContent: 'center',
        maxWidth: '80%',
      }}
    >
      {typeof sticker !== 'undefined' && (
        <>
          {styles?.map((style, index) => (
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'relative' }} onClick={() => handleClickTheme(style, index)}>
                {Temp && <Temp style={style} />}
                {/* {selectedThemeStyle.fill === style.fill &&
            selectedThemeStyle.stroke === style.stroke ? (
              <IconButton
                style={{
                  background: '#fff',
                  zIndex: 3,
                  position: 'absolute',
                  border: '1px solid #05C755',
                  width: '20px',
                  height: '20px',
                  left:
                    sticker.stickerType === 'TypeA' ||
                    sticker.stickerType === 'TypeB' ||
                    sticker.stickerType === 'TypeE' ||
                    sticker.stickerType === 'TypeF'
                      ? '50px'
                      : '60px',
                  bottom: sticker.stickerType === 'TypeF' ? '20px' : '15px',
                }}
              >
                <CheckIcon style={{ fontSize: '12px', color: '#05C755' }} />
              </IconButton>
            ) : (
              <></>
            )} */}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default StickerColorTheme;
