import React, { useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { toast } from 'react-toastify';

interface DraggableListProps<T extends WithPrimitive> {
  items?: T[];
  hasLast?: boolean; //Footer가 있을 경우 적용(리오더 금지)
  children?: React.ReactNode;
  Template?: React.ComponentType<T>;
  onOrderChanged: (items?: T[], item?: T) => void;
  isDragDisabled?: boolean;
}

interface WithPrimitive {
  id?: number;
  seq?: number;
  lockPosition?: boolean;
}

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
): React.CSSProperties => ({
  ...draggableStyle,
  margin: '15px 0',
});

const DraggableList = <T extends WithPrimitive>({
  items,
  hasLast,
  children,
  Template,
  onOrderChanged,
  isDragDisabled,
}: DraggableListProps<T>) => {
  const reorder = (startIndex: number, endIndex: number): Array<T> => {
    const result = Array.from(items ?? []);
    if (startIndex === endIndex) {
      return result;
    }
    const [dragItem] = result.splice(startIndex, 1);

    /** 서버 반영전, UI 변경 목적 */
    result.splice(endIndex, 0, dragItem);

    /** 옮긴 링크의 seq를 옮긴 index + 1 값으로 수정해준다 */
    result.forEach((item, index) => {
      item.seq = index + 1;
    });
    onOrderChanged(result, { ...dragItem, seq: endIndex + 1 });

    return result;
  };

  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }
    if (hasLast && items && result.destination.index == items?.length - 1) {
      toast('마지막 항목은 이동할 수 없습니다.', { type: 'warning' });
      return;
    }

    reorder(result.source.index, result.destination.index);
  };

  /** (라이브러리) Droppable 스타일링 */
  const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
    width: '100%',
  });

  /** (라이브러리) Draggable 스타일링 */

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot): JSX.Element => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {children}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export { DraggableList, getItemStyle };
