import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Theme from 'resources/styles/common/theme';

interface DefaultProps {
  defaultValue?: boolean;
  model?: boolean;
  children?: any;
  onChange: (e: boolean) => void;
}

function ToggleButton<T>({ defaultValue, children, model, onChange }: DefaultProps) {
  const [selected2, setSelected] = useState<boolean>(false);

  const handleClickButton = () => {
    setSelected(!selected2);

    if (onChange) {
      onChange(!selected2);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);
  return (
    <ButtonStyle onClick={handleClickButton} selected={selected2}>
      {children && children}
    </ButtonStyle>
  );
}

const ButtonStyle = styled.div<{ selected: boolean }>`
  display: inline-block;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 12px;
  border: 1px solid #c5c5c5;
  background: ${(props) => (props.selected ? Theme.colors.defaultBackground : 'transparent')};
  color: ${(props) => (props.selected ? 'white' : 'black')};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-right: 10px;

  &:hover {
    transition: all 0.2s ease-in-out;
  }
`;

export default ToggleButton;
