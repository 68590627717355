import TitleForm from 'components/items/forms/TitleForm';
import React, { CSSProperties, useRef, useState } from 'react';
import Search from '@mui/icons-material/Search';
import styled from 'styled-components';
import { borderRadius } from '@mui/system';
import { Row } from './layouts';

const SearchBox = ({
  binded,
  style,
  onEntered,
  onCancel,
}: {
  binded?: boolean;
  style?: CSSProperties;
  onEntered: (value: string) => void;
  onCancel?: () => void;
}) => {
  const inputRef = useRef<any>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const handleChangeValue = (value: string) => {
    setSearchValue(value);
    // onChangeValue(value);
  };

  const handleCancel = () => {
    setSearchValue('');
    inputRef.current?.setValue('');
    onCancel?.();
  };

  const handleEnter = (value: string) => {
    onEntered(value);
  };

  return (
    <Row>
      <TitleForm
        required={false}
        validationType={undefined}
        ref={inputRef}
        onChangeValue={handleChangeValue}
        prefix={<img src="/images/search_grey.svg" alt="" />}
        style={{ borderRadius: 30, height: '2.75rem', background: 'white', ...style }}
        placeHolder="검색"
        onEntered={handleEnter}
      />
      <>
        {searchValue.length > 0 && (
          <CancelButtonStyle onClick={handleCancel}>cancel</CancelButtonStyle>
        )}
      </>
    </Row>
  );
};

const CancelButtonStyle = styled.div`
  padding: 10px;
  cursor: pointer;
`;

export default SearchBox;
