import React, { useState, useCallback, useEffect, useRef } from 'react';
import { validationHelper as VH } from 'controls/validationHelper';
import { FormControl, InputLabel, OutlinedInput, FormHelperText, Checkbox } from '@mui/material';
import { debounce } from 'lodash';
import { useStore } from 'stores/StoreHelper';
import { SignUpDto, TermDto } from 'services/data-contracts';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: any) => ({
  outlinedInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.625rem',
      border: 'none',
      background: 'rgba(23, 51, 0, 0.1)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.625rem',
      border: '1.5px solid #173300',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.625rem',
      border: '1.5px solid #173300',
    },
  },
});

interface TermModel extends TermDto {
  checked: boolean;
}
const CommonSignUpForm = ({
  onValidChannelName,
  onTermsChanged,
  classes,
}: {
  onValidChannelName: (channelName: string) => void;
  onTermsChanged: (isValid: boolean) => void;
  classes?: any;
}) => {
  const { t } = useTranslation();
  const channelNameRef = useRef<any>(null);
  const [model, setModel] = useState<SignUpDto>();
  const [invalidChannelName, setDuplicateChannelName] = useState<boolean>(false);
  const [channelNameErrorMessage, setChannelNameErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const { channelStore, termStore } = useStore();

  const handleChangeChannelName = useCallback(
    debounce(async (channelName) => {
      if (channelName === '') {
        setChannelNameErrorMessage('');
      } else {
        const nameResult = VH.alphanumericWithSymbols(channelName);
        if (nameResult) {
          const result = (await channelStore.duplicateChannelName(
            channelName,
          )) as unknown as boolean;
          setDuplicateChannelName(!result);
          raiseValidState();
          if (!result) {
            setChannelNameErrorMessage(t('A261'));
          } else {
            setChannelNameErrorMessage(t('A262'));
          }
        } else {
          setChannelNameErrorMessage(t('A263'));
          setDuplicateChannelName(true);
          raiseValidState();
        }
      }
      setModel((prev) => {
        return { ...prev, channelName, channelNickName: channelName };
      });
    }, 500),
    [],
  );

  const raiseValidState = () => {
    if (!invalidChannelName) {
      onValidChannelName(channelNameRef.current.value);
    }
  };

  return (
    <>
      <ChannelStyle>
        <DomainStyle>ohmoss.com /</DomainStyle>
        <ChannelNameStyle valid={invalidChannelName}>{model?.channelName}</ChannelNameStyle>
      </ChannelStyle>
      <FormControl sx={{ width: '100%' }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password" shrink required>
          {t('A112')}
        </InputLabel>
        <OutlinedInput
          style={{ marginTop: 8 }}
          className={classes.outlinedInput}
          inputRef={channelNameRef}
          error={invalidChannelName}
          required
          id="outlined-adornment-password"
          label={t('A112')}
          onChange={(e: any) => {
            e.target.value = e.target.value.toLowerCase();
            handleChangeChannelName(e.target.value);
          }}
          endAdornment={
            !invalidChannelName &&
            model?.channelName && <img src="/images/checkbox-circle-line.svg" alt="" />
          }
        />
        <FormHelperText>{channelNameErrorMessage}</FormHelperText>
      </FormControl>
      <TermsStyle>
        <>
          {termStore.terms && (
            <>
              {termStore.terms?.map((term: TermModel, i: number) => (
                <TermStyle>
                  <Checkbox
                    onChange={(e: any) => {
                      term.checked = e.target.checked;
                      const valid =
                        termStore.terms.filter((term: TermModel) => term.checked).length ===
                        termStore.terms.length;
                      onTermsChanged(valid);
                    }}
                  />
                  {term.name}에 동의
                </TermStyle>
              ))}
            </>
          )}
        </>
      </TermsStyle>
    </>
  );
};

const ChannelStyle = styled.div`
  height: 50px;
  /* width: 400px; */
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
`;

const DomainStyle = styled.div`
  font-size: 1rem;
  padding: 20px 5px 20px 10px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  letter-spacing: 0.03rem;
  font-weight: 600;
`;

const ChannelNameStyle = styled.div<{ valid: boolean }>`
  font-size: 24px;
  font-weight: 600;
  margin-left: 10px;

  color: ${(props) => (!props.valid ? '#96e06e' : 'red')};
`;

const TermsStyle = styled.div`
  padding: 20px 0;
  text-align: center;
  margin: auto;
`;

const TermStyle = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default withStyles(styles)(CommonSignUpForm);
