import React from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import { StickerTypes } from 'components/commons/stickers/StickerTypes';
import OhmossStickers from 'components/commons/stickers';

type CommonProps = {
  initialValue?: StickerTypes[];
  initialPosition?: 'top' | 'bottom';
  onChange?: (value: StickerTypes[]) => void;
  onChangePosition: (position: 'top' | 'bottom') => void;
};
const CommonSticker = ({
  initialValue,
  initialPosition,
  onChange,
  onChangePosition,
}: CommonProps) => {
  return (
    <>
      <ItemSectionStyle>
        <ItemSectionTitle>스티커</ItemSectionTitle>
        <ItemContent>
          <OhmossStickers
            initialValue={initialValue}
            initialPosition={initialPosition}
            onChange={(stickers: any) => {
              onChange?.(stickers);
            }}
            onChangePosition={(position: 'top' | 'bottom') => {
              onChangePosition?.(position);
            }}
          />
        </ItemContent>
      </ItemSectionStyle>
    </>
  );
};

export default CommonSticker;
