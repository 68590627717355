// useMyContext.ts
import { useContext, createContext } from 'react';
import { ManagementContextType } from './providers/ContextTypes';

const PageModelContext = createContext<any | undefined>(undefined);

function usePageModelContext<T>(): ManagementContextType<T> & T {
  const context = useContext(PageModelContext);
  if (!context) {
    // console.log('check context');
  }
  return context;
}

export { PageModelContext, usePageModelContext };
