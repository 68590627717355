import { IChannelProfile } from 'types/BlockExtendsTypes';

export const ChannelProfileDtoDummy: IChannelProfile = {
  nickname: undefined,
  profileImage: undefined,
  backgroundImage: undefined,
  profileBackgroundImage: undefined,
  footerImage: undefined,
  introduction: undefined,
  address: undefined,
  metadata: undefined,
  layout: undefined,
  contentAlign: undefined,
  isPin: undefined,
  isFollowAllow: undefined,
  useTransparent: undefined,
  createDate: undefined,
};
