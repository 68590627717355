import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { GnbAdmin } from 'features/commonFeatures/navigationBar/containers';
import { isMobile } from 'react-device-detect';
import { useStore } from 'stores/StoreHelper';
import { Row } from 'components/commons/layouts';

import FollowProvider from 'providers/follow/FollowProvider';
import DealsProvider from 'features/mossMain/context/DealsProvider';
import UniversalLayout from 'components/commons/layouts/UniversalLayout';
import styled from 'styled-components';
import BottomSheetOnly from 'components/commons/BottomSheetOnly';
import FullModal from 'components/commons/FullModal';
import Confirm2 from 'components/commons/Confirm2';
import UniversalModal from 'components/commons/UniversalModal/Modal';
import magRoutes from 'routes/magRoutes';
import ChannelProvider from 'features/linkService/context/ChannelProvider';
import ProviderWrapper from './ProviderWrapper';
import AuthContainer from './AuthContainer';
import { isPublieMenu, initGA, sendGA } from './MainContainerBase';
import AppInfoFooter from '../components/AppInfoFooter';

const MagRouter = () => {
  const { pathname } = useLocation();
  const location = useLocation();

  const [isPublic, setIsPublic] = useState<boolean>();
  const { historyStore } = useStore();

  const getElement = (route: any) => {
    if (window.isWebView) {
      return route.webViewElement ? <route.webViewElement /> : <route.element />;
    } else if (isMobile) {
      return route.mobileElement ? <route.mobileElement /> : <route.element />;
    } else {
      return <route.element />;
    }
  };

  useEffect(() => {
    initGA();
    // setValidUser();
    if (typeof pathname !== 'undefined') {
      setIsPublic(isPublieMenu(pathname));
    }
  }, []);

  useEffect(() => {
    historyStore.pushPopHistory({ key: location.key, path: location.pathname });
  }, [location]);

  return (
    <>
      {/* <GlobalBgStyle src="/images/ohmoss_bg.jpg" /> */}
      <UniversalLayout>
        <MainContainerStyle>
          <AppInfoFooter />
          <RightContainerStyle>
            <>
              {isPublic && (
                <div id="gnb_container">
                  <GnbAdmin />
                </div>
              )}
              <div style={{ width: '100%', height: '100%' }}>
                <Routes location={location}>
                  {magRoutes.map((route, index: number) => {
                    return (
                      <Route
                        key={index}
                        path={route.path}
                        element={
                          <AuthContainer isPublic={route.isPublic} title={route.title}>
                            {/* <ChannelProvider> */}
                            <ProviderWrapper provider={route.provider}>
                              <BottomSheetOnly />
                              <FullModal />
                              <Confirm2 />
                              <UniversalModal />
                              {getElement(route)}
                            </ProviderWrapper>
                            {/* </ChannelProvider> */}
                          </AuthContainer>
                        }
                      />
                    );
                  })}
                </Routes>
              </div>
            </>
          </RightContainerStyle>
        </MainContainerStyle>
      </UniversalLayout>
    </>
  );
};

const MainContainerStyle = styled.div`
  display: flex;
`;

const RightContainerStyle = styled.div`
  justify-content: center;
  position: absolute;
  margin: auto;
  max-width: 550px;
  width: 100%;
  background: #fff;
  z-index: 1;
  @media screen and (max-width: 1160px) {
    justify-content: center;
    display: flex;
  }
`;

export default MagRouter;
