// useMyContext.ts
import { useContext } from 'react';
import { PageContext, propsTypes } from './PageContext';

export const usePageContext = (): propsTypes => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error('useMyContext must be used within a MyProvider');
  }
  return context;
};
