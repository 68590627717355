import React, { useState, useEffect, useRef } from 'react';
import { initLinkItemDto, updateLink, updateModel } from 'components/items/commonLogic';
import { defaultLinkEditorProps } from 'types/CommonTypes';
// import { PrimaryButtonStyle } from 'resources/controls';
import { LinkDto } from 'services/data-contracts';
import { Row } from 'components/commons/layouts';
import { isValid } from 'libs/helper/utils';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import SimplePreview from 'components/items/SimplePreview';
import WhiteButton from 'components/commons/WhiteButton';
import TextArea from 'components/commons/TextArea';
import styled from 'styled-components';

const TextLinkEditor = ({
  model,
  stores,
  onRegistComplete,
  onCancelModity,
}: defaultLinkEditorProps<any>) => {
  const titleRef = useRef<any>(null);
  const [linkModel, setLinkModel] = useState<LinkDto>();
  const [isFieldValid, setIsFieldValid] = useState<boolean>(false);
  // const isFormValid = useForm(stores.formValidationStore);

  // const stores = useStore();
  const handleSaveLink = async () => {
    // const newModel = { ...linkModel };
    // newModel!.title = linkModel?.content?.substring(0, 50);
    // if (newModel) {
    //   const result = await updateLink(newModel, isValid(model), { ...stores });
    //   onRegistComplete(result!);
    //   if (result!) {
    //     toast(t('linkRegistration'));
    //   }
    // }
  };

  // useEffect(() => {
  //   if (typeof model !== 'undefined') {
  //     setLinkModel(model);
  //   } else {
  //     setLinkModel({ linkType: 'TextLink', content: '' });
  //     setLinkModel(initLinkItemDto('TextLink'),);
  //   }
  // }, [model]);

  useEffect(() => {
    return () => {
      titleRef.current.value = '';
    };
  }, []);

  return (
    // <TextLinkContainer>
    <>
      <SimplePreviewContainerStyle>
        <SimplePreview model={linkModel} />
      </SimplePreviewContainerStyle>
      <TextLinkEditorContainerStyle>
        <TextArea
          placeholder={t('content')}
          type="textarea"
          required
          id="textContent"
          containerStyle={{ border: 0, marginTop: 10 }}
          style={{
            width: 'calc(100% - 12px)',
            height: 250,
            fontSize: 16,
            color: '#111',
            borderRadius: 6,
            border: '1px solid #bbb',
            padding: 10,
            background: '#F8F8F8',
          }}
          value={linkModel?.content}
          onChangeValue={(value: string) => {
            updateModel(setLinkModel, 'content', value);
            if (value.length > 0) {
              setIsFieldValid(true);
            } else {
              setIsFieldValid(false);
            }
          }}
        />
      </TextLinkEditorContainerStyle>

      <ButtonContainerStyle>
        {typeof model !== 'undefined' ? (
          <Row>
            <WhiteButton
              type="Cancel"
              style={{ backgroundColor: 'gray', width: 150, marginRight: 10 }}
              onClick={onCancelModity}
            />
            <WhiteButton type="Update" disabled={!isFieldValid} onClick={handleSaveLink} />
          </Row>
        ) : (
          <WhiteButton type="SAVE" disabled={!isFieldValid} onClick={handleSaveLink} />
        )}
      </ButtonContainerStyle>
    </>
    // </TextLinkContainer>
  );
};

const SimplePreviewContainerStyle = styled.div`
  width: 97%;
`;

// const TextLinkContainer = styled.div`
//   border: 1px solid #dddddd;
//   border-radius: 4px;
//   padding: 10px;
// `;

const TextLinkEditorContainerStyle = styled.div`
  width: 100%;
  align-items: center;
  border-radius: 5px;
`;

const ButtonContainerStyle = styled.div`
  margin: 10px 0 0 0;
`;

export default TextLinkEditor;
