import React, { useEffect, useState, useRef, CSSProperties } from 'react';
import styled from 'styled-components';
import { PostCommentDto, SimpleChannelDto } from 'services/data-contracts';
import { observer } from 'mobx-react';

import { useStore } from 'stores/StoreHelper';
import { Row } from 'components/commons/layouts';
import { IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import MyAvatar from 'components/commons/MyAvatar';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import ClearIcon from '@mui/icons-material/Clear';
import useToken from 'libs/hooks/useToken';
import { ChannelDto } from 'services/data-contracts';

const ReplyForm = observer(
  ({
    feedId,
    type,
    parentId,
    replyModel,
    replyTo,
    style,
    onClose,
  }: {
    feedId?: number;
    type: 'reply' | 'answer';
    parentId?: number;
    replyModel?: PostCommentDto;
    replyTo?: ChannelDto;
    style?: CSSProperties;
    onClose?: () => void;
  }) => {
    const inputRef = useRef<any>(null);
    const { uiStore, channelStore, myChannelStore, postStore, uiStores } = useStore();
    const { userHomeStore } = uiStores;
    const { validUser } = useToken();

    const [model, setModel] = useState<PostCommentDto | undefined>(() => {
      return replyModel || undefined;
    });
    const [replyTarget, setReplyTarget] = useState<SimpleChannelDto | undefined>();

    const handleClickReply = async () => {
      if (channelStore.channel?.channelName && model) {
        let result;
        if (!model.id) {
          result = (await postStore.createBoardComment(
            channelStore.channel?.channelName,
            userHomeStore.currentBoard?.id as number,
            feedId as number,
            {
              ...model,
              parentId,
              ownChannelId: myChannelStore.currentChannel?.id,
              targetWriterId: replyTarget?.id as number,
            },
          )) as any;
        } else {
          result = (await postStore.updateBoardComment(
            channelStore.channel?.channelName,
            userHomeStore.currentBoard?.id as number,
            feedId as number,
            model?.id as number,
            {
              ...model,
              ownChannelId: myChannelStore.currentChannel?.id,
            },
          )) as any;
        }
        if (result === null) {
          return;
        }
        result.replies = model.replies;
        postStore.updateCommentByCommentItem(result, !model.id ? 'new' : 'update');
        setModel(undefined);
        if (inputRef.current) {
          inputRef.current.value = '';
        }

        if (onClose) onClose();
      }
    };

    useEffect(() => {
      if (replyTo) setReplyTarget(replyTo);
    }, [replyTo]);

    return (
      <ReplyFormStyle style={style}>
        {validUser() && (
          <Row style={{ alignItems: 'flex-start' }}>
            <>
              {typeof replyModel === 'undefined' && (
                <div style={{ marginRight: 15 }}>
                  <MyAvatar />
                </div>
              )}
            </>
            <Paper
              component="form"
              style={{ boxShadow: 'none', border: '1px solid #d0d0d0' }}
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                alignContent: 'flex-start',
              }}
            >
              <div style={{ width: '100%' }}>
                <InputBase
                  inputRef={inputRef}
                  rows={4}
                  defaultValue={replyModel?.content}
                  multiline
                  // value={model?.content}
                  sx={{
                    ml: 1,
                    flex: 1,
                    width: '100%',
                  }}
                  placeholder="댓글 작성"
                  inputProps={{ 'aria-label': '댓글 작성' }}
                  onChange={(e: any) => {
                    setModel((prev: PostCommentDto) => {
                      return { ...prev, content: e.target.value };
                    });
                  }}
                />
                {replyTarget && (
                  <ReplyToStyle>
                    <div>
                      <b>{replyTarget.channelName}</b>에게 답글쓰는중...
                      <IconButton
                        onClick={() => {
                          setReplyTarget(undefined);
                          if (onClose) onClose();
                        }}
                      >
                        <ClearIcon style={{ color: '#fff' }} />
                      </IconButton>
                    </div>
                  </ReplyToStyle>
                )}
              </div>
              {/* <EditableFormStyle style={{ width: '80%' }} contentEditable /> */}
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: '10px' }}
                aria-label="directions"
                onClick={handleClickReply}
              >
                <SendIcon />
              </IconButton>
            </Paper>
          </Row>
        )}
      </ReplyFormStyle>
    );
  },
);

const ReplyFormStyle = styled.div`
  padding: 20px;
  width: calc(100% - 60px);
`;

const ReplyToStyle = styled.div`
  > div {
    display: inline-block;
    width: auto;
    background: rebeccapurple;
    color: #fff;
    padding-left: 15px;
    border-radius: 8px;
  }
`;

export default ReplyForm;
