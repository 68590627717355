import React, { useEffect, useState } from 'react';
import { LinkDto } from 'services/data-contracts';
import styled, { CSSProperties, keyframes } from 'styled-components';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react-lite';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import useToken from 'libs/hooks/useToken';
import useLocalParams from 'libs/hooks/useLocalParams';
import { t } from 'i18next';
import useNavigation from 'hooks/useNavigation';
import { IconButton } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Row } from 'components/commons/layouts';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import ProposalForm from '../ProposalForm';
import RadioButtonsGroup from '../../common/RadioButton';

const ProposalTemplate = observer(
  ({
    item,
    editMode = true,
    textStyle,
  }: {
    item: LinkDto;
    editMode?: boolean;
    textStyle?: CSSProperties;
  }) => {
    const { uiStore } = useStore();
    const { currentChannel } = useChannelContext();
    const { validUser } = useToken();
    const { channelName } = useLocalParams();
    const { navigate } = useNavigation();

    const [tempLabel, setTempLabel] = useState<any[]>([]);
    const [formTitle, setFormTitle] = useState<string>('');
    const [expanded, setExpanded] = useState<boolean>(false);

    const updateFormTitle = (item: string) => {
      setFormTitle(item);
    };

    const handleClickSuggest = () => {
      if (channelName && currentChannel && !currentChannel?.isOwner) {
        if (!editMode && validUser()) {
          uiStore.modal.show({
            style: {
              maxHeight: '100vh',
            },
            title: formTitle,
            children: <ProposalForm label={formTitle} />,
          });
        } else {
          uiStore.confirm.show({
            message: t('A245'),
            onConfirmed: async () => {
              navigate(`/login`);
            },
          });
        }
      }
      if (currentChannel?.isOwner) {
        toast.error('채널 소유자는 자신에게 제안을 보낼 수 없습니다.');
      }
    };

    useEffect(() => {
      if (item?.items) {
        const temp = item.items.map((item) => item.title);
        setTempLabel(temp);
      }
    }, [item]);

    return (
      <ContainerStyle>
        {item.thumbnail?.publicUrl ? (
          <>
            <ImageContainerStyle src={item.thumbnail?.publicUrl} />
            <TitleContainerStyle
              style={{ justifyContent: item.contentAlign as 'left' | 'center' | 'right' }}
            >
              <div
                onClick={handleClickSuggest}
                style={{
                  ...textStyle,
                  marginRight: item?.contentAlign === 'right' ? '30px' : '0px',
                }}
              >
                {item.title}
              </div>
              <Row style={{ width: 'unset', position: 'absolute', left: isMobile ? '84%' : '88%' }}>
                <div>
                  <IconButton
                    onClick={() => {
                      setExpanded((prev) => !prev);
                    }}
                  >
                    {tempLabel?.length > 0 &&
                      (expanded ? (
                        <ExpandLessIcon style={{ color: '#000' }} />
                      ) : (
                        <ExpandMoreIcon style={{ color: '#000' }} />
                      ))}
                  </IconButton>
                </div>
              </Row>
            </TitleContainerStyle>
            {/* 제안 종류 */}
            {tempLabel?.length > 0 && expanded && (
              <RadioBtnContainerStyle
                style={{
                  justifyContent:
                    item?.contentAlign === 'left'
                      ? 'flex-start'
                      : item.contentAlign === 'right'
                      ? 'flex-end'
                      : 'center',
                  marginRight: item?.contentAlign === 'right' ? '50px' : '0px',
                  marginLeft: item?.contentAlign === 'left' ? '30px' : '0px',
                }}
              >
                <RadioButtonsGroup
                  selectedValue={formTitle}
                  onRadioBtnChange={(item) => updateFormTitle(item)}
                  label={tempLabel}
                />
              </RadioBtnContainerStyle>
            )}
            {/* 문의하기 버튼 */}
            {formTitle.length > 0 && expanded ? (
              <ProposeBtnStyle onClick={handleClickSuggest}>문의 하기</ProposeBtnStyle>
            ) : (
              <></>
            )}
          </>
        ) : (
          <ProposeBtnNoImgStyle
            style={{ textAlign: item.contentAlign as 'left' | 'center' | 'right' }}
            onClick={handleClickSuggest}
          >
            문의 하기
          </ProposeBtnNoImgStyle>
        )}
      </ContainerStyle>
    );
  },
);

const slideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ContainerStyle = styled.div`
  cursor: pointer;
  max-width: 100%;
  height: auto;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const ImageContainerStyle = styled.img`
  max-width: 100%;
  height: 176px;
  object-fit: cover;
`;

const TitleContainerStyle = styled.div`
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 30px;
  /* margin-top: 8px; */
`;

const ProposeBtnStyle = styled.div`
  display: flex;
  height: 48px;
  padding: 12.5px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #efefef;
  background-color: #fff;
  margin: 0 10px 10px 10px;
  animation: ${slideDown} 0.4s ease-out;
`;

const ProposeBtnNoImgStyle = styled.div`
  text-align: center;
  padding: 24px;
`;

const RadioBtnContainerStyle = styled.div`
  display: flex;
  margin: 0 0 10px 20px;
`;

export default ProposalTemplate;
