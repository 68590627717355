import { makeObservable, observable, action, flow } from 'mobx';
import { MyChannelAnalytic } from 'services/MyChannelAnalytic';
import { LinkDto, MessageDto } from 'services/data-contracts';

import _ from 'lodash';
import ServiceStoreBase from './ServiceStoreBase';
import { HttpClient } from 'services/http-client';
import i18n from 'i18n';

export interface isValid {
  isValid: boolean;
}

class MyChannelAnalyticsStore extends ServiceStoreBase {
  t = i18n.t;

  links: Array<LinkDto>;

  constructor() {
    super();
    makeObservable(this, {
      getAnalytics: flow,
    });
  }

  api: MyChannelAnalytic<unknown> = new MyChannelAnalytic(new HttpClient());

  *getAnalytics(channelId: number, from: string, to: string) {
    const { response } = yield this.api.getStatistics(channelId, { limitSize: 5, from, to });
    return response.status < 300 ? response.data : null;
  }
}

export default MyChannelAnalyticsStore;
