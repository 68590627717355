import React, { useState } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import UrlForm from 'components/items/forms/UrlForm';

type CommonProps = {
  initialValue?: string;
  onChange?: (value: string) => void;
};
const CommonUrl = ({ initialValue, onChange }: CommonProps) => {
  return (
    <>
      <ItemSectionStyle>
        <ItemSectionTitle>링크주소</ItemSectionTitle>
        <ItemContent>
          <UrlForm
            onChangeValue={(value: string) => {
              if (onChange) {
                onChange(value);
              }
            }}
            initialValue={initialValue}
            label="링크주소"
          />
        </ItemContent>
      </ItemSectionStyle>
    </>
  );
};

export default CommonUrl;
