import React, { useState, useEffect, useRef } from 'react';
import { initLinkItemDto, updateLink, updateModel } from 'components/items/commonLogic';
import { defaultLinkEditorProps, PageLinkModel } from 'types/CommonTypes';
import { validationHelper as VH } from 'controls/validationHelper';
import { useTranslation } from 'react-i18next';
import LinkEditorContainerStyle from 'resources/controls/LinkEditorConatainerStyle';

import styled from 'styled-components';
import _ from 'lodash';
import { observer } from 'mobx-react';
import Editor from 'components/commons/webEditor/Editor';
import TitleForm from './forms/TitleForm';
import SaveButtonWrapper from './SaveButtonWrapper';

const PageEditor = observer(
  ({ model, stores, onRegistComplete }: defaultLinkEditorProps<PageLinkModel>) => {
    const [linkModel, setLinkModel] = useState<PageLinkModel>();
    const [editorValue, setEditorValue] = useState<string>();
    const [isValid, setIsValid] = useState<boolean>(false);

    const titleRef = useRef<any>(null);
    const editorRef = useRef<any>(null);
    const { t } = useTranslation();

    const checkValidation = () => {
      const result = VH.required(linkModel?.title);
      const urlResult =
        linkModel?.linkUrl && linkModel?.linkUrl?.length > 0 ? VH.url(linkModel?.linkUrl) : true;
      setIsValid(result && urlResult);
    };

    const handleClickSave = async () => {
      if (editorRef && linkModel) {
        const data = editorRef.current.getData();
        linkModel.content = data;
        // todo: 페이지 dto 체크해 볼것
        // linkModel.page = { contents: data };
        if (typeof linkModel !== 'undefined' && typeof onRegistComplete === 'function') {
          const result = await updateLink(linkModel, typeof linkModel.id !== 'undefined', stores);
          onRegistComplete(true, result);
          // if (result!) {
          //   toast(t('linkRegistration'));
          // }
        }
      }
    };

    /** 지우지 말것 */
    // const handleClickHtmlEditor = () => {
    //   stores.uiStore.modal.show({
    //     children: <HTMLEditor />,
    //     style: { width: '90%', height: '90%' },
    //   });
    // };

    // // const handleUploadImage = (any: any) => {};
    // const uploadImageHandler = async (blobInfo: any) => {
    //   const blobImage = blobInfo.blobUri();
    //   const response = await fetch(blobImage);
    //   const blob = await response.blob();
    //   const data: any = await stores.fileStore.uploadImage(blob, 'Link');

    //   // return new Promise(async (resolve, reject) => {
    //   //   resolve(data.publicUrl);
    //   // });
    //   return new Promise((resolve, reject) => {
    //     resolve(data.publicUrl);
    //   });
    // };

    useEffect(() => {
      if (typeof linkModel !== 'undefined') {
        checkValidation();
      }
    }, [linkModel]);

    useEffect(() => {
      if (typeof model !== 'undefined') {
        // setIsNew(false);
        if (typeof model.metadata !== 'undefined') {
          setLinkModel({ ...model, metadataObject: JSON.parse(model.metadata) });
        } else {
          setLinkModel(model);
        }

        setEditorValue(model?.content);
      } else {
        setLinkModel(initLinkItemDto('Page'));
      }
    }, [model]);

    useEffect(() => {
      return () => {
        titleRef.current.value = '';
      };
    }, []);

    return (
      <>
        <LinkEditorContainerStyle>
          <SaveButtonWrapper
            isValid={isValid}
            onSave={handleClickSave}
            isNew={typeof linkModel?.id === 'undefined'}
          >
            <TitleForm
              label={t('A163')}
              model={linkModel?.title}
              onChangeValue={(value: string) => {
                setLinkModel((prev: any) => {
                  return {
                    ...prev,
                    title: value,
                  };
                });
              }}
            />

            <PageEditStyle>
              <Editor
                ref={editorRef}
                defaultValue={editorValue}
                onEditorChange={(content: string) => {
                  updateModel(setLinkModel, 'page', { ...linkModel?.page, content });
                }}
              />
            </PageEditStyle>
            {/* <Button onClick={handleClickHtmlEditor}>Page Editor사용하기</Button> */}
          </SaveButtonWrapper>
        </LinkEditorContainerStyle>
      </>
    );
  },
);

const PageEditStyle = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 30px;
`;

export default PageEditor;
