import React, { useEffect, useState } from 'react';
import { Row } from 'components/commons/layouts';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { AdDto, PostDto, PostTagDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';

import useNavigation from 'hooks/useNavigation';
import { useFollowContext } from 'providers/follow/useFollowContext';

import MobileAdPostItem from 'pages/MobileAdPostItem';
import UniversalLayout from 'components/commons/layouts/UniversalLayout';
import { usePageContext } from '../context/usePageContext';

const LinkPreview = observer(({ adItem }: { adItem: AdDto }) => {
  const { uiStore } = useStore();
  const { followInfo } = useFollowContext();
  const { setFavoriteAd } = usePageContext();
  const { navigate } = useNavigation();

  const [showFloating, setShowFloating] = useState<boolean>(false);
  const [postItem, setPostItem] = useState<PostDto>();

  const handleClickTag = (tag: PostTagDto) => {
    navigate(`/?tags=${tag.name}`);
    uiStore.modal.close();
  };

  const handleClickFavorite = async (isFavorite: boolean) => {
    const result = setFavoriteAd(isFavorite, adItem.channel?.id as number, adItem.id as number);
  };

  useEffect(() => {
    if (adItem) {
      const temp: PostDto = { ...adItem.post, channel: adItem.channel, ad: adItem };
      delete temp.ad?.post;
      setPostItem(temp);
    }
  }, []);

  useEffect(() => {
    if (followInfo) {
      uiStore.universalModal.confirm(followInfo);
      if (uiStore.modal.options?.invoke) {
        uiStore.modal.options?.invoke(followInfo);
      }
    }
  }, [followInfo]);

  return (
    <UniversalLayout>
      <>
        {typeof postItem !== 'undefined' && (
          <Row
            style={{
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              padding: 2,
            }}
          >
            <MobileAdPostItem
              post={postItem}
              onClickTag={handleClickTag}
              onClickVisit={() => {}}
              onClickFavorite={handleClickFavorite}
              isOwner
            />
            <>
              {typeof adItem.linkUrl !== 'undefined' && (
                <div style={{ margin: 10, width: '90%' }}>
                  <OhmossButton
                    style={{ padding: '10px 20px', width: '100%' }}
                    onClick={() => {
                      window.open(`agreement?url=${adItem.linkUrl}`, '_blank');
                    }}
                  >
                    더 알아보기
                  </OhmossButton>
                </div>
              )}
            </>
          </Row>
        )}
      </>
    </UniversalLayout>
  );
});

export default LinkPreview;
