import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Row } from 'components/commons/layouts';
import { FriendshipDto } from 'services/data-contracts';
import MyAnalyticsStore from 'stores/MyAnalyticsStore';
import KMFormater from 'components/commons/KMFormater';
import MyChannelStore from 'stores/MyChannelStore';
import { Avatar } from '@mui/material';
import useToken from 'libs/hooks/useToken';
import { useStore } from 'stores/StoreHelper';
import FollowListContainer from 'components/commons/follower/container/FollowListContainer';
import { useRootContext } from 'libs/hooks/useRootContext';

const Analytics = () => {
  const myAnalyticsStore = new MyAnalyticsStore();
  const myChannelStore = new MyChannelStore();
  const { validUser } = useToken();
  const { uiStore } = useStore();
  const { myChannel } = useRootContext();

  const [visit, setVisit] = useState<number>(0);
  const [click, setClick] = useState<number>(0);
  const [followersProfile, setFollowersProfile] = useState<FriendshipDto[]>([]);

  const handleClickFollow = () => {
    if (validUser()) {
      uiStore.universalModal.show({
        children: <FollowListContainer />,
        useHeader: false,
        bottomSheetOptions: { blocking: true },
        modalOption: { style: { maxWidth: 500, maxHeight: 500 } },
      });
    }
  };
  const getAnlytics = async () => {
    const visitCount = (await myAnalyticsStore.getVisitCount(
      myChannel?.id as number,
    )) as unknown as number;
    setVisit(visitCount);

    const clickCount = (await myAnalyticsStore.getLinkClickCount(
      myChannel?.id as number,
    )) as unknown as number;
    setClick(clickCount);

    // const followerCount = (await myAnalyticsStore.getFollowerCount(
    //   myChannel?.id as number,
    // )) as unknown as number;
    // setFollow(followerCount);

    const followersProfile = (await myChannelStore.getMyFollowers(
      myChannel?.id as number,
    )) as unknown as FriendshipDto[];
    setFollowersProfile(followersProfile);
  };

  useEffect(() => {
    if (myChannel) {
      getAnlytics();
    }
  }, []);

  return (
    <Row
      style={{
        justifyContent: 'space-evenly',
        borderRadius: '0.875rem',
        border: '1px solid rgba(23, 51, 0, 0.30)',
        background: 'white',
        padding: '1rem',
      }}
    >
      <AnalyticsStyle>
        <div>
          <CountStyle>
            <div
              style={{
                display: 'flex',
                background: 'rgba(23, 51, 0, 0.10)',
                borderRadius: 20,
                width: '2rem',
                height: '2rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src="/images/visitors.svg"
                alt="visitors"
                style={{ width: '1rem', height: '1.2rem' }}
              />
            </div>
            <KMFormater num={visit} />
          </CountStyle>
          <TypeTextStyle>방문</TypeTextStyle>
        </div>
      </AnalyticsStyle>

      <VerticalDivider />

      <AnalyticsStyle>
        <div>
          <CountStyle>
            <div
              style={{
                display: 'flex',
                background: 'rgba(23, 51, 0, 0.10)',
                borderRadius: 20,
                width: '2rem',
                height: '2rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src="/images/clicks.svg"
                alt="clicks"
                style={{ width: '1rem', height: '1.2rem' }}
              />
            </div>
            <KMFormater num={click} />
          </CountStyle>
          <TypeTextStyle>클릭</TypeTextStyle>
        </div>
      </AnalyticsStyle>

      <VerticalDivider />

      <AnalyticsStyle>
        <div onClick={handleClickFollow} style={{ cursor: 'pointer' }}>
          <CountStyle>
            <div style={{ display: 'flex', position: 'relative', height: 30 }}>
              {followersProfile?.length > 0 &&
                followersProfile?.length < 3 &&
                followersProfile.map((follower, index) => (
                  <Avatar
                    key={index}
                    src={follower?.followerProfile?.profileImage?.publicUrl}
                    style={{
                      width: 30,
                      height: 30,
                    }}
                  />
                ))}
              {followersProfile?.length >= 3 &&
                followersProfile.slice(0, 2).map((follower, index) => (
                  <Avatar
                    key={index}
                    src={follower?.followerProfile?.profileImage?.publicUrl}
                    style={{
                      width: 30,
                      height: 30,
                    }}
                  />
                ))}
              {followersProfile.length > 2 ? (
                <FollowerImageStyle>
                  +{followersProfile.length > 0 ? followersProfile?.length - 2 : 0}
                </FollowerImageStyle>
              ) : (
                <></>
              )}
            </div>
            <KMFormater num={followersProfile?.length ?? 0} />
          </CountStyle>
          <TypeTextStyle>팔로워</TypeTextStyle>
        </div>
      </AnalyticsStyle>
    </Row>
  );
};

const AnalyticsStyle = styled.div`
  text-align: center;
  /* min-width: 50px; */
`;

const CountStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > span:first-child {
  }
`;

const TypeTextStyle = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.35);
`;

const FollowerImageStyle = styled.div<{ index?: number; url?: string }>`
  display: flex;
  width: 32px;
  height: 32px;
  border: 0.25px solid #000;
  border-radius: 50%;
  background-color: #f6f6f6;
  position: absolute;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 10;
  color: 'black';
`;

const VerticalDivider = styled.div`
  background: rgba(0, 0, 0, 0.35);
  width: 0.01875rem;
  height: 4.5625rem;
`;

export default Analytics;
