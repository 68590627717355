import CategoryPage from 'features/management/pages/CategoryPage';
import BackgroundPage from 'features/management/pages/BackgroundPage';
import DesignPage from 'features/management/pages/DesignPage';
import EtcPage from 'features/management/pages/EtcPage';
import { RoutePropInterface } from 'types/CommonTypes';
import LinkPage from 'features/management/pages/LinkPage';
import ProfilePage from 'features/management/pages/ProfilePage';
import ProfileProvider from 'features/management/context/providers/ProfileProvider';
import CategoryProvider from 'features/management/context/providers/CategoryProvider';
import LinkProvider from 'features/management/context/providers/LinkProvider';
import MyStyleProvider from 'features/management/context/providers/MyStyleProvider';
import MyShopPage from 'features/management/pages/MyShopPage';
import MyShopDetailPage from 'features/management/pages/MyShopDetailPage';
import PreviewPage from 'features/management/pages/PreviewPage';

const managementRoute: RoutePropInterface[] = [
  {
    path: '/management',
    title: '관리자 메인(프로필)',
    element: CategoryPage,
    provider: CategoryProvider,
  },
  {
    path: '/management/profile',
    title: 'Edit',
    goBack: 'channel',
    element: ProfilePage,
    provider: ProfileProvider,
  },
  {
    path: '/management/categories',
    title: 'Edit',
    goBack: 'channel',
    element: CategoryPage,
    provider: CategoryProvider,
  },
  {
    path: '/management/myshop/:categoryid',
    title: 'Edit',
    element: MyShopDetailPage,
    provider: CategoryProvider,
  },
  {
    path: '/management/categories/:categoryid',
    title: '링크페이지',
    element: LinkPage,
    provider: LinkProvider,
  },
  {
    path: '/management/shop',
    title: 'Edit',
    goBack: 'channel',
    element: MyShopPage,
    provider: CategoryProvider,
  },
  {
    path: '/management/design',
    title: 'Edit',
    goBack: 'channel',
    element: DesignPage,
    provider: MyStyleProvider,
  },
  {
    path: '/management/background',
    title: 'Edit',
    goBack: 'channel',
    element: BackgroundPage,
    provider: ProfileProvider,
  },
  {
    path: '/management/etc',
    title: '기타',
    element: EtcPage,
  },
];

export default managementRoute;
