import React, { useState } from 'react';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Row } from 'components/commons/layouts';

function CardHeader({
  children,
  onExpand,
}: {
  children: React.ReactNode;
  onExpand?: (value: boolean) => void;
}) {
  const [show, setShow] = useState<boolean>(false);
  return (
    <>
      <Row style={{ minHeight: 50 }}>
        <div>{children}</div>
      </Row>
      <>
        {typeof onExpand === 'function' && (
          <ExpanderStyle
            onClick={() => {
              if (typeof onExpand === 'function') {
                setShow((prev: boolean) => !prev);
                onExpand(!show);
              }
            }}
          >
            <ExpandMoreIconStyle show={show} />
          </ExpanderStyle>
        )}
      </>
    </>
  );
}

const ExpanderStyle = styled.div<{ show?: boolean }>`
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  right: 20px;
  padding: 10px;
  /* transform: translate(-50%, -50%); */
`;

const ExpandMoreIconStyle = styled(ExpandMoreIcon)<{ show?: boolean }>`
  transition: all 0.5s ease-in-out;
  transform: ${(props) => (props.show ? 'rotate(180deg)' : ' rotate(0deg)')};
`;

export default CardHeader;
