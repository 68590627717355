import React, { useState, useEffect, useRef } from 'react';
import { CustomContent, CustomFile, PostModel, PostItemModel } from 'types/CommonTypes';
import { ImageUploader } from 'components/commons';
import { cloneDeep, setDefaultValue } from 'libs/helper/utils';
import { useTranslation } from 'react-i18next';
import { Button, FormControlLabel, Switch } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Row } from 'components/commons/layouts';
import { DottedBoxStyle } from 'resources/controls';
import { useStore } from 'stores/StoreHelper';
import { PostDto } from 'services/data-contracts';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import styled from 'styled-components';
import LinkEditorContainerStyle from 'resources/controls/LinkEditorConatainerStyle';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import VideoComponent from 'features/adminFeatures/commons/components/VideoComponent';
import Editor from 'components/commons/webEditor/Editor';
import boardCommonLogics from 'features/board/logic/boardCommonLogic';
import SaveButtonWrapper from './SaveButtonWrapper';
import TitleForm from './forms/TitleForm';
import UrlForm from './forms/UrlForm';

/**
 * 
 * @param param0 useAd : 이어서 광고등록이 필요한경우.
 * @returns 
 */
const PostEditor = ({ model, useAd }: { model: PostDto, useAd?: boolean; }) => {
  const intDto = (reset?: boolean): PostModel => {
    if (reset) {
      return {
        title: 'feed',
        channel: {},
        items: [],
      };
    } else {
      return {
        ...model,
        title: setDefaultValue(model?.title, 'feed_'),
        channel: setDefaultValue(model?.channel, {}),
      };
    }
  };
  const editorRef = useRef<any>(null);
  const { handleSavePost } = boardCommonLogics();

  const { t } = useTranslation();
  const { uiStore, myChannelStore, channelStore, uiStores, postStore } = useStore();

  const [feedItems, setFeedItems] = useState<Array<PostItemModel>>(() => {
    return uiStore.universalModal.contentModel?.items || [];
  });
  const [feedModel, setFeedModel] = useState<PostModel>(() => {
    return uiStore.universalModal.contentModel?.model || intDto();
  });

  const [showEditor, setShowEditor] = useState<boolean>();
  const [swiper, setSwiper] = useState<any>(null);

  const handleOnSave = () => {
    let content;
    if (editorRef.current) {
      content = editorRef.current.getData();
    }
    const newFeedModel: PostModel = { ...feedModel, content, items: feedItems };
    handleSavePost(newFeedModel, (result: boolean, model: PostDto) => {
      if (result) {
        // postStore.updateFeedByItem(model, 'new');
        uiStore.universalModal.close();
      }
    });
  };

  const handleDeleteCard = (id: number) => {
    uiStore.confirm.show({
      message: t('A214'),
      onConfirmed: () => {
        const newCards = feedItems.filter((card, index) => {
          return index !== id;
        });
        setFeedItems(newCards);
      },
    });
  };

  // 멀티플 업로드 대응
  const handleBindMultipleImage = (images: Array<any>) => {
    const newCards = [...feedItems];

    const seq =
      // eslint-disable-next-line no-nested-ternary
      feedItems.length === 0 ? 0 : feedItems[feedItems.length - 1].seq;
    // eslint-disable-next-line array-callback-return
    images.map((image: any) => {
      const { file } = image;
      file.publicUrl = image.publicUrl;
      newCards.push({
        seq: typeof seq === 'undefined' ? 0 : seq + 1,
        isActive: true,
        file: image as CustomFile,
        metadataObject: cloneDeep(defalutCardCustomProps),
      });
    });
    setFeedItems([...newCards]);
    updateGlobalModel(newCards);
    swiper?.slideTo(newCards.length - 1);
  };

  const updateGlobalModel = (images?: any) => {
    uiStore.universalModal.updateModel({
      model: { ...feedModel, content: editorRef.current.getData() },
      items: images || feedItems,
    });
  };

  const initModel = async () => {
    const feed = (await postStore.getPost(
      channelStore.channel?.channelName!,
      uiStores.userHomeStore.currentBoard?.id as number,
      model.id as number,
      myChannelStore.currentChannel?.id as number,
    )) as any;

    setFeedModel(feed);
    setFeedItems(
      uiStore.universalModal?.contentModel?.items.length > 0
        ? uiStore.universalModal?.contentModel?.items.length
        : (feed.items as Array<PostItemModel>),
    );
  };

  useEffect(() => {
    if (model.id) {
      initModel();
      setShowEditor(typeof model.content === 'string');
    }
  }, [model]);

  return (
    <>
      <LinkEditorContainerStyle>
        <SaveButtonWrapper
          isValid={feedItems.length > 0}
          onSave={handleOnSave}
          isNew={typeof feedModel?.id === 'undefined'}
        >
          <div style={{ marginBottom: 20 }}>
            {typeof feedItems !== 'undefined' && (
              <>
                <CardContainerStyle>
                  {feedItems.length > 0 ? (
                    <>
                      <Swiper
                        centeredSlides
                        spaceBetween={10}
                        grabCursor
                        enabled
                        onSwiper={setSwiper}
                      >
                        {feedItems.map((card: PostItemModel, index: number) => (
                          <SwiperSlide>
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <CardCanvasContainerStyle>
                                {typeof card?.file !== 'undefined' && (
                                  <>
                                    {card?.file?.contentType?.indexOf('image/') === 0 ? (
                                      <>
                                        {typeof card?.file?.publicUrl !== 'undefined' && (
                                          <BackgroundImageStyle
                                            style={{
                                              backgroundImage: `url(${card?.file?.publicUrl})`,
                                            }}
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <VideoComponent
                                          video={card?.file}
                                          isCover
                                          containerStyle={{ objectFit: 'cover' }}
                                        />
                                      </>
                                    )}
                                  </>
                                )}

                                <DeleteButtonSyle
                                  onClick={() => {
                                    handleDeleteCard(index);
                                  }}
                                >
                                  <DeleteOutlineIcon />
                                </DeleteButtonSyle>
                              </CardCanvasContainerStyle>
                              <>
                                <CardEditorContainerStyle>
                                  <div style={{ marginTop: 30 }}>
                                    <TitleForm
                                      placeHolder={t('A215')}
                                      label={t('Title')}
                                      required={false}
                                      multiline={false}
                                      model={card.title}
                                      onChangeValue={(value: string) => {
                                        card.title = value;
                                        setFeedItems([...feedItems]);
                                        updateGlobalModel();
                                      }}
                                    />
                                  </div>

                                  <div style={{ marginTop: 20 }}>
                                    <UrlForm
                                      model={card.linkUrl}
                                      onChangeValue={(value: string) => {
                                        card.linkUrl = value;
                                        setFeedItems([...feedItems]);
                                        updateGlobalModel();
                                      }}
                                    />
                                  </div>
                                </CardEditorContainerStyle>
                                {/* )} */}
                              </>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </>
                  ) : (
                    <ImageUploaderContainerStyle>
                      <ImageUploader
                        type='multiple'
                        cropSize={{ width: 1200, height: 1200 }}
                        attachType="media"
                        useCrop={false}
                        multipleUpload
                        onSelected={(thumbnails: any) => {
                          handleBindMultipleImage(thumbnails);
                        }}
                      >
                        <DottedBoxStyle width="100%" height="140px">
                          <div style={{ textAlign: 'center', margin: 'auto', padding: 20 }}>
                            <CameraAltIcon sx={{ width: 30, height: 30 }} />
                            <div>{t('Multi upload')}</div>
                          </div>
                        </DottedBoxStyle>
                      </ImageUploader>
                    </ImageUploaderContainerStyle>
                  )}
                </CardContainerStyle>
                {feedItems.length > 0 && (
                  <AddImageStyle>
                    <Row>
                      <ImageUploader
                        type='multiple'
                        cropSize={{ width: 1200, height: 6000 }}
                        attachType="media"
                        useCrop={false}
                        multipleUpload
                        onSelected={(thumbnails: any) => {
                          handleBindMultipleImage(thumbnails);
                        }}
                      >
                        <Button
                          variant="outlined"
                          sx={{ width: 200, height: 50, borderRadius: 30 }}
                          startIcon={<CameraAltIcon />}
                        >
                          {t('Multi upload')}
                        </Button>
                      </ImageUploader>
                    </Row>
                  </AddImageStyle>
                )}
              </>
            )}
          </div>

          <FormControlLabel
            control={
              <Switch
                checked={showEditor}
                onChange={(event: never, value: boolean) => {
                  setShowEditor(value);
                }}
              />
            }
            label={t('A213')}
          />
          {showEditor && (
            <div>
              <Editor
                ref={editorRef}
                defaultValue={
                  uiStore.universalModal.contentModel?.model?.content || feedModel?.content
                }
                onEditorChange={() => {
                  // 브라우저 사이즈 변경할때 모델을 공유하기 위해 공용 모델을 업데이트 한다.
                  // Editor는 실시간으로 데이터를 취득하지 않고 저장하는 시점에 꺼내온다.
                  uiStore.universalModal.updateModel({
                    model: { ...feedModel, content: editorRef.current.getData() },
                    items: feedItems,
                  });
                }}
              />
            </div>
          )}
        </SaveButtonWrapper>
      </LinkEditorContainerStyle>
    </>
  );
};

const ImageUploaderContainerStyle = styled.div`
  aspect-ratio: 16 / 9;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  > div {
    width: 100%;
  }
`;

const defalutCardCustomProps: CustomContent = {
  titleContents: {
    text: '',
    style: {},
    use: false,
  },
  descriptionContents: {
    text: '',
    use: false,
    style: { fontSize: 16, color: '#000000' },
  },
  isModified: false,
};

const AddImageStyle = styled.div`
  margin-top: 20px;
  left: 20px;
`;

const DeleteButtonSyle = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
`;

const CardContainerStyle = styled.div`
  width: 100%;
`;

const CardCanvasContainerStyle = styled.div`
  width: 100%;
  /* border-radius: 10px; */
  position: relative;
  overflow: hidden;
  margin: 20px auto 10px auto;
  aspect-ratio: 1 / 1;
`;

const BackgroundImageStyle = styled.img`
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  border-radius: 10px;
  /* border: ${(props) => `1px solid ${props.theme.colors.borderColor}`}; */
  // background-image: ${(props) => props.src};
`;

const CardEditorContainerStyle = styled.div`
  /* padding: 10px 10px 10px 10px; */
`;
const UseMyChannelStyle = styled.div`
  color: #fff;
  background: rebeccapurple;
  padding: 20px;
  border-radius: 8px;
`;
export default PostEditor;
