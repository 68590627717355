// useMyContext.ts
import { useContext, createContext } from 'react';

const Context = createContext<propsTypes | undefined>(undefined);

interface propsTypes {
  follow: (
    channelName: string,
    isFollow: boolean,
  ) => Promise<{ isFollow: boolean; channelName: string }>;
  isChannelFollow?: boolean;
  followInfo?: { channelName: string; isFollow?: boolean };
  setIsChannelFollow: (isFollow: boolean) => void;
}

export const useFollowContext = (): propsTypes => {
  const context = useContext(Context);
  if (!context) {
    return {} as any;

    // throw new Error("useMyContext must be used within a MyProvider");
  }
  return context;
};

export { Context, propsTypes };
