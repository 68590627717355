import React, { useState, ReactNode } from 'react';
import { PageContext, displayTypes } from './PageContext';
import { AdCategoryDto, AdDto, PostTagDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import useToken from 'libs/hooks/useToken';
import MyAdStore from 'stores/MyAdStore';
import { useRootContext } from 'libs/hooks/useRootContext';

interface PageProviderProps {
  children: ReactNode | ReactNode[];
}

const DealsProvider = ({ children }: PageProviderProps) => {
  const myAdStore = new MyAdStore();

  const { adStore, myChannelStore } = useStore();
  const { validUser } = useToken();
  const { myChannel } = useRootContext();

  const [selectedCategory, setSelectedCategory] = useState<AdCategoryDto | undefined>();
  const [displayType, setSelectedDisplayType] = useState<displayTypes>('list');
  const [selectedTags, setSelectedTags] = useState<PostTagDto[] | undefined>();
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [deals, setDeals] = useState<AdDto[]>([]);

  const ProviderInstance = this;

  const setCategory = (category: AdCategoryDto | undefined) => {
    setSelectedCategory(category);
    if (category?.name === '내찜') {
      getInterestAds();
    } else {
      getAds({
        category: category?.name,
        ownChannelId: (myChannelStore.currentChannel?.id as number) || '',
      });
    }
    if (typeof category === 'undefined') {
      setSelectedTags([]);
    }
  };

  const setDisplayType = (type: displayTypes) => {
    setSelectedDisplayType(type);
    // window.scrollTo(0, 0);
  };

  const getAds = async (query?: any) => {
    const dealList = (await adStore.getAds({
      ...query,
      ownChannelId: (myChannel?.id as number) || '',
    })) as never as AdDto[];
    setDeals(dealList);
  };

  const getInterestAds = async () => {
    const dealList = (await myAdStore.getInterestedAds(
      myChannel?.id as number,
    )) as never as AdDto[];
    setDeals(dealList);
  };

  const setTags = (tags?: PostTagDto[]) => {
    setSelectedTags(tags);
  };

  const setLatestDeals = (deals: AdDto[]) => {
    setDeals(deals);
  };
  const updateFollowStatus = (isFollow?: boolean, channelName?: string) => {
    adStore.updateFollowStatus(isFollow, channelName);
  };

  const setFavoriteAd = async (isFavorite: boolean, channelId: number, adId: number) => {
    const result = await adStore.setFavorite(isFavorite, channelId, adId);
  };

  const initPage = async (query?: any) => {
    const valid = validUser();
    setIsLogin(valid);
  };

  return (
    <>
      {typeof children !== 'undefined' ? (
        <PageContext.Provider
          value={{
            selectedCategory,
            displayType,
            selectedTags,
            isLogin,
            setCategory,
            setDisplayType,
            getAds,
            setTags,
            initPage,
            updateFollowStatus,
            ProviderInstance,
            setFavoriteAd,
            deals,
            setLatestDeals,
          }}
        >
          {children}
        </PageContext.Provider>
      ) : (
        <></>
      )}
    </>
  );
};

export default DealsProvider;
