import React from 'react';
import { Avatar, IconButton } from '@mui/material';
import { useStore } from 'stores/StoreHelper';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import MyChannelList from 'features/commonFeatures/containers/MyChannelList';
import { useLocation } from 'react-router-dom';
import { Row } from './layouts';

const MyAvatarBox = ({
  avatarUrl,
  channelName,
  channelType,
}: {
  avatarUrl?: string;
  channelName?: string;
  channelType?: string;
}) => {
  const { uiStore, myChannelStore } = useStore();
  const { pathname } = useLocation();

  const handleClickMore = () => {
    uiStore.bottomSheet.show({
      children: (
        <ContainerStyle>
          <MyChannelList type="my" />
        </ContainerStyle>
      ),
    });
  };

  return (
    <Row
      style={{
        display: 'flex',
        padding: '1rem',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 14,
        background: '#fff',
        borderRadius: '0.875rem',
        position: 'relative',
      }}
    >
      <Avatar
        src={avatarUrl || '/images/avatar_basic_green.svg'}
        style={{ width: 50, height: 50 }}
      />
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20 }}>
        <div style={{ color: 'rgba(4, 4, 4, 0.87)', fontWeight: '700', fontSize: '1.2rem' }}>
          {pathname === '/cstown' ? `${channelName}` : `@${channelName}`}
        </div>
        <div style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '0.8rem' }}>{channelType}</div>
      </div>
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          right: 24,
        }}
      >
        {myChannelStore.myChannels?.length > 1 && (
          <IconButton onClick={handleClickMore}>
            <ExpandMoreIcon style={{ color: '#CACACA' }} />
          </IconButton>
        )}
      </div>
    </Row>
  );
};

const ContainerStyle = styled.div`
  padding: 0 20px;
  width: 100%;
`;
export default MyAvatarBox;
