import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { ChannelBoardDto } from 'services/data-contracts';
import { Draggable } from 'react-beautiful-dnd';
import { DraggableList, getItemStyle } from 'components/commons/draggableList';
import { useStore } from 'stores/StoreHelper';
import { ProfileTypes } from '../context/providers/ContextTypes';
import ProfileTemplate from 'features/management/components/profile/ProfileTemplate';
import Profile from 'features/management/components/profile/Profile';
import CategoryTab from '../components/profile/CategoryTab';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import MyBoardStore from 'stores/MyBoardStore';
import { useOhmossContext } from '../context/useOhmossContext';
import { BlockContainerStyle } from '../components/common/BlockContainerStyle';
import CategoryTabTemplateList from '../components/profile/CategoryTabTemplateList';
import ProfileController from 'controllers/ProfileController';
import { IChannelProfile } from 'types/BlockExtendsTypes';
import { useRootContext } from 'libs/hooks/useRootContext';
import { ListBoxItemTemplate } from 'features/csTown/commonComponents/styles';
import InfoBox from 'components/commons/InfoBox';
import { Row } from 'components/commons/layouts';
import DragIcon from 'components/commons/buttons/DragIcon';
import { useStateContext } from 'libs/hooks/usePageState';

const ProfilePage = observer(() => {
  const itemsTemplate = [
    { type: 'profile', id: 1, template: ProfileTemplate },
    { type: 'tabBlock', id: 2, template: CategoryTabTemplateList },
  ];

  const myBoardStore = new MyBoardStore();
  const { uiStore } = useStore();
  const { myChannel, setChannel } = useRootContext();
  const { broadcast } = useStateContext();

  const [items, setItems] = useState<ChannelBoardDto[]>([]);
  const [boards, setBoards] = useState<ChannelBoardDto[]>([]);

  const { initialBinding, updateServiceModel, serviceModel } =
    useOhmossContext<ProfileTypes<IChannelProfile>>();

  const { updateProfile } = ProfileController();

  useInitializeModel<Partial<IChannelProfile>>(myChannel?.profile ?? {}, undefined, initialBinding);

  const handleReorder = async (categories: any, category: any) => {
    setItems(categories);
    await updateServiceModel('isPin', categories[0].type === 'profile');
    updateProfile(serviceModel);
    broadcastProfile();
  };

  const handleChangeActive = async (value: boolean) => {
    updateServiceModel('globalActive', value);
    await updateProfile(serviceModel);
    broadcastProfile();
  };

  const broadcastProfile = () => {
    broadcast({ id: 'ACTION', param: { id: 'PROFILE_UPDATE_DONE', profile: serviceModel } });
  };

  const handleClickItem = (item: any) => {
    uiStore.universalModal.show({
      // style: { minHeight: 500, maxHeight: '80%' },
      modalOption: { style: { height: 'auto' } },
      children: (
        <BlockContainerStyle style={{ width: '100%', margin: 0 }}>
          {item.type === 'profile' ? <Profile blockModel={item?.model} /> : <CategoryTab />}
        </BlockContainerStyle>
      ),
      onConfirmed(result: any) {
        // setChannel(result);
        broadcast({ id: 'ACTION', param: { id: 'PROFILE_UPDATE_DONE', profile: { ...result } } });
      },
    });
  };

  const getBoards = async () => {
    const boards = (await myBoardStore.getBoards(
      myChannel?.id as number,
    )) as any as ChannelBoardDto[];
    setBoards(boards);
  };

  const getTemplate = (item: any) => {
    const Temp = item.template;
    item.model = myChannel?.profile;

    return (
      <Temp
        item={item?.model}
        channelBoards={boards}
        origin="profile"
        onActiveChange={(result: boolean) => {
          handleChangeActive(result);
        }}
        onClick={() => {
          handleClickItem(item);
        }}
      />
    );
  };

  useEffect(() => {
    if (myChannel?.profile) {
      getBoards();

      if ((myChannel.profile as IChannelProfile).isPin === true) {
        setItems(itemsTemplate.sort((a, b) => a.id - b.id));
      } else {
        setItems(itemsTemplate.sort((a, b) => b.id - a.id));
      }
    }
  }, [myChannel?.profile]);

  return (
    <div style={{ padding: 20 }}>
      <InfoBox useIcon={false}>
        <Row>
          <DragIcon style={{ background: '#efefef', marginRight: 10 }} />
          <div>아이콘을 드래그 하여 위치를 변경할 수 있습니다.</div>
        </Row>
      </InfoBox>
      <DraggableList items={items} onOrderChanged={handleReorder}>
        {items?.map((dragItem: any, index: number) => (
          <>
            <Draggable
              key={dragItem?.id}
              draggableId={String(dragItem?.id)}
              index={index}
              isDragDisabled={boards?.length === 1}
            >
              {(provided, snapshot): JSX.Element => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  // {...provided.dragHandleProps}
                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                  <div {...provided.dragHandleProps}>
                    <DragIcon />
                  </div>
                  <ListBoxItemTemplate style={{ padding: 0 }}>
                    {getTemplate(dragItem)}
                  </ListBoxItemTemplate>
                </div>
              )}
            </Draggable>
          </>
        ))}
      </DraggableList>
    </div>
  );
});

export default ProfilePage;
