import React, { useEffect, useRef, useState } from 'react';

import { ChatModel } from 'types/CommonTypes';
import { useRootContext } from 'libs/hooks/useRootContext';
import { GroupChannel, MessageEventContext } from '@sendbird/chat/groupChannel';
import { BaseMessage } from '@sendbird/chat/message';

import LinkController from 'controllers/LinkController';
import CustomerChatBox from 'components/commons/chat/CustomerChatBox';
import { ChannelDto, LinkDto } from 'services/data-contracts';
import chatController from 'features/community/controllers/chatController';

interface DealInquiryByCustomerProps {
  chatInfo?: ChatModel;
  seller?: ChannelDto;
}

/**
 * @description 공구 블럭에서 일반 고객이 사용하는 채팅 창
 * @description 일반 고객은 셀러/브랜드멤버에 상관 없이 셀러로 표시되어야 함.
 * @param param0
 * @returns
 */
const CustomerChatContainer: React.FC<DealInquiryByCustomerProps> = ({ chatInfo, seller }) => {
  const { getDealMembers } = LinkController();
  const channelRef = useRef<any>();
  const messagesRef = useRef<BaseMessage[]>([]);
  const messagesColection = useRef<any>([]);

  const { loadMessages, sendMessage, sendFile, createInquiryChannel, createChatroom } =
    chatController();

  const [currentChannel, setCurrentChannel] = useState<GroupChannel>();
  const [messages, setMessages] = useState<BaseMessage[]>([]);

  const scrollToBottom = (item: any, smooth: string) => {
    item?.scrollTo({
      top: item.scrollHeight,
      // behavior: smooth,
    });
  };

  const createInquiry = async () => {
    if (chatInfo) {
      // if (myChannel) {
      let msgs: any;
      const onCacheResult = (err: any, messages: any) => {
        const aa = messages;
      };

      const onApiResult = (err: any, messages: any) => {
        setMessages(messages.reverse());
        messagesRef.current = messages;
      };

      let invitedMembers;
      if (chatInfo?.deal) {
        invitedMembers = (await getDealMembers(
          chatInfo.deal?.linkKey as string,
        )) as unknown as string[];

        const newOperators = invitedMembers
          .filter((member) => member.indexOf('.') === -1)
          .map((channelName) => {
            {
              return { channelName: channelName };
            }
          });

        const tempOperators = [...chatInfo.operators, ...newOperators].filter(
          (member, index, self) =>
            index === self.findIndex((t) => t.channelName === member.channelName),
        );

        chatInfo.operators = tempOperators as ChannelDto[];
        const channelMembers = invitedMembers?.map((member) => {
          return {
            channelName: member,
            profile: { nickname: member },
          };
        });

        const [groupChannel, error] = await createInquiryChannel(
          chatInfo.operators,
          chatInfo?.deal as LinkDto,
          channelMembers,
          chatInfo.deal?.linkKey,
          chatInfo?.guestInfo?.id,
        );

        setCurrentChannel(groupChannel);
        msgs = loadMessages(groupChannel, messageHandlers, onCacheResult, onApiResult, [
          chatInfo.deal?.linkKey as string,
        ]);
      } else {
        const [groupChannel, error] = await createChatroom(chatInfo.operators, chatInfo.members);
        setCurrentChannel(groupChannel);
        msgs = loadMessages(groupChannel, messageHandlers, null, null, [
          chatInfo.deal?.linkKey as string,
        ]);
      }

      messagesColection.current = msgs;

      messagesRef.current = msgs.messages;
      hideSettingButton();
      // }
    }
  };

  const messageHandlers = {
    onMessagesAdded: (
      context: MessageEventContext,
      channel: GroupChannel,
      incomingMessages: any,
    ) => {
      const updatedMessages = [...(messagesRef.current || []), ...incomingMessages];
      setMessages(updatedMessages);
      messagesRef.current = updatedMessages;
    },

    onMessagesUpdated: (context: MessageEventContext, channel: GroupChannel, messages: any) => {
      const updatedMessages = [...messagesRef.current];
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const i in messages) {
        const incomingMessage = messages[i];
        const indexOfExisting = messagesRef.current.findIndex((message: any) => {
          return incomingMessage.reqId === message.reqId;
        });

        if (indexOfExisting !== -1) {
          updatedMessages[indexOfExisting] = incomingMessage;
        }
        if (!incomingMessage.reqId) {
          updatedMessages.push(incomingMessage);
        }
      }
      messagesRef.current = updatedMessages;
      setMessages(updatedMessages);
      scrollToBottom(channelRef.current, 'smooth');
    },

    onMessagesDeleted: (
      context: MessageEventContext,
      channel: GroupChannel,
      messageIds: string[],
    ) => {},
    onChannelUpdated: (context: MessageEventContext, channel: GroupChannel) => {},
    onChannelDeleted: (context: MessageEventContext, channelUrl: string) => {},
    onHugeGapDetected: () => {},
  };

  const hideSettingButton = () => {
    const temp = document.querySelector('.sendbird-chat-header__right') as any;
    if (temp) {
      temp.style.display = 'none';
    }
  };

  const getPreviousMessages = async () => {
    if (messagesColection?.current?.hasPrevious && channelRef.current) {
      // 이전 메시지를 불러오기 전의 현재 스크롤 위치를 기록합니다.
      const currentScrollPosition = channelRef.current.scrollTop;

      // 이전 메시지를 불러옵니다.
      const prevMessages: BaseMessage[] = await messagesColection?.current.loadPrevious();

      // 메시지 상태를 업데이트합니다.
      setMessages([...prevMessages, ...messagesRef.current]);

      setTimeout(() => {
        // 새로운 메시지가 추가된 후의 스크롤 높이를 측정합니다.
        const newScrollHeight = channelRef.current.scrollHeight;

        // 불러오기 전과 후의 스크롤 높이 차이를 계산합니다.
        const scrollHeightDiff =
          newScrollHeight - channelRef.current.scrollHeight + currentScrollPosition;

        // 계산된 높이 차이만큼 스크롤 위치를 조정하여, 사용자가 이전에 보던 위치에 머무를 수 있도록 합니다.
        channelRef.current.scrollTop = scrollHeightDiff;
      }, 0);
    }
  };

  const sendCutomerMessage = async (msg: string) => {
    if (currentChannel && msg) {
      sendMessage(currentChannel, msg);
    }
  };

  const sendCustomerFile = async (files: File[]) => {
    if (currentChannel && files) {
      sendFile(currentChannel, files);
    }
  };

  useEffect(() => {
    if (chatInfo) {
      createInquiry();
    }
  }, [chatInfo]);

  useEffect(() => {
    setTimeout(() => {
      const scrollableElement = document.getElementById('messsageListContainer');

      // 스크롤 이벤트 리스너 추가
      if (scrollableElement !== null) {
        scrollableElement.addEventListener('scroll', () => {
          // 특정 스크롤 위치 (예: 100px)
          const triggerScrollPosition = 100;

          // 현재 스크롤 위치가 트리거 위치에 도달했는지 확인
          if (scrollableElement && scrollableElement?.scrollTop <= triggerScrollPosition) {
            // 여기에 원하는 이벤트 처리 로직을 추가합니다.
            getPreviousMessages();
          }
        });
      }
    }, 1000);

    setTimeout(() => {
      scrollToBottom(channelRef.current, 'smooth');
    }, 0);
  }, []);

  return (
    <>
      <CustomerChatBox
        onSendMessage={sendCutomerMessage}
        onSendFile={sendCustomerFile}
        messages={messages}
        channel={currentChannel}
      />
    </>
  );
};

export default CustomerChatContainer;
