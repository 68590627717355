import React, { useState, useEffect, useCallback } from 'react';
import ListItemHeader from './ListItemTemplates/ListItemHeader';

import { LinkDto } from 'services/data-contracts';
import { Blocks } from '.';
import { BlockContainerStyle } from '../common/BlockContainerStyle';
import { CSSProperties } from 'styled-components';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react-lite';
import { MyStyle } from 'types/CommonTypes';
import BlockController from 'controllers/BlockController';
import { parseJSONAll } from 'libs/helper/utils';

const BlockItem = observer(
  ({
    item,
    editMode = true,
    isPreviewMode,
    customStyle,
    blockMargin,
    onClick,
    onClickMore,
  }: {
    item: LinkDto;
    editMode?: boolean;
    isPreviewMode?: boolean;
    blockMargin?: number;
    customStyle?: MyStyle | undefined;
    onClick?: () => void;
    onClickMore?: () => void;
  }) => {
    const { uiStore, uiLinkStore, linkStore } = useStore();
    const { updateBlock, deleteBlock } = BlockController<LinkDto>();

    const [isTransparent, setIsTransparent] = useState<boolean>();

    const handleClickDelete = (item: LinkDto) => {
      uiStore.confirm.show({
        message: `${item?.title}항목을 삭제하시겠습니까?`,
        onConfirmed: async () => {
          deleteBlock?.(item);
        },
      });
    };

    const setBlockMargin = (margin: number) => {
      const blockItemStyles = document.querySelectorAll('.block-item') as any;
      if (blockItemStyles) {
        blockItemStyles.forEach(function (p: any) {
          p.style.marginTop = `${margin}px`;
          p.style.marginBottom = `${margin}px`;
        });
      }
    };

    const getTemplate = useCallback(
      (innerCustomStyle?: MyStyle) => {
        const temp = item.linkType;
        const SelectedBlock = Blocks[temp as keyof typeof Blocks]?.template;
        const metadata = JSON.parse(item?.metadata || '{}');
        const blockItems = item?.items?.map((item) => ({
          ...item,
          ...(parseJSONAll(item?.metadata) as any),
        }));

        const newItem: LinkDto = { ...item, ...metadata, items: blockItems };

        return (
          <div style={{ width: '100%' }}>
            <>
              <SelectedBlock
                isPreviewMode={isPreviewMode}
                textStyle={innerCustomStyle?.textStyle}
                boxStyle={innerCustomStyle?.boxStyle}
                editMode={editMode}
                item={newItem}
                onClick={() => {}}
              />
            </>
          </div>
        );
      },
      [item, onClick],
    );

    useEffect(() => {
      if (customStyle) {
        if (item.useTransparent === true) {
          uiLinkStore.setBoxStyle({
            ...customStyle.boxStyle,
            border: 'none',
            boxShadow: 'none',
          });
        } else {
          if (typeof customStyle?.boxStyle !== 'undefined') {
            uiLinkStore.setBoxStyle(customStyle?.boxStyle);
          }
        }
      }
    }, [customStyle]);

    useEffect(() => {
      if (editMode === true) {
        return;
      }
      if (item) {
        if (
          item.linkType === 'Seperator' ||
          item.linkType === 'Spacer' ||
          item.linkType === 'Shop'
        ) {
          setIsTransparent(true);
          return;
        }

        setIsTransparent(item.useTransparent ?? false);
      }
    }, [item]);

    useEffect(() => {
      if (blockMargin) {
        setBlockMargin(blockMargin);
      }
    }, [blockMargin]);

    useEffect(() => {
      if (uiLinkStore.boxStyle?.marginTop) {
        setBlockMargin(uiLinkStore.boxStyle?.marginTop as number);
      }
    }, [uiLinkStore.boxStyle]);

    return (
      <>
        {editMode === true && (
          <div style={{ textAlign: 'right', padding: '0 20px' }}>
            <ListItemHeader
              // title={item.title}
              initialActive={item.isActive}
              onActiveChange={(isActive) => {
                const newModel = { ...item, isActive };
                updateBlock(newModel as LinkDto);
              }}
              onDelete={() => {
                handleClickDelete(item);
              }}
              onModify={() => {
                onClick?.();
              }}
            />
          </div>
        )}
        <BlockContainerStyle
          className="block-item"
          useTransparent={isTransparent}
          style={{
            ...(customStyle?.boxStyle ?? ({} as CSSProperties)),
            minHeight:
              item.linkType === 'Spacer' || item.linkType === 'Seperator'
                ? 0
                : customStyle?.boxStyle?.minHeight,
          }}
        >
          {typeof item !== 'undefined' && getTemplate(customStyle)}
        </BlockContainerStyle>
      </>
    );
  },
);

export default BlockItem;
