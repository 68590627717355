import React from 'react';
import { observer } from 'mobx-react';

import DashboardContainer from '../containers/DashboardContainer';
import DashboardProvider from '../context/dashboard/DashboardProvider';
const DashBoard = observer(() => {
  return (
    <DashboardProvider>
      <DashboardContainer />
    </DashboardProvider>
  );
});


export default DashBoard;
