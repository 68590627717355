import React from 'react';
import useNavigation from 'hooks/useNavigation';
import styled from 'styled-components';
import CSTownInquiriesCountContainer from '../CSTownInquiriesCountContainer';

const InquirySummery = ({
  newInquiry,
  unHandledInquiry,
}: {
  newInquiry: number;
  unHandledInquiry: number;
}) => {
  const { navigate } = useNavigation();

  return (
    <InquiriesCountWrapper>
      <CSTownInquiriesCountContainer
        style={{ width: '50%', marginTop: 16 }}
        count={newInquiry || 0}
        title="신규문의"
      />
      <CSTownInquiriesCountContainer
        style={{ width: '50%', marginTop: 16 }}
        count={unHandledInquiry || 0}
        title="미처리문의"
      />
    </InquiriesCountWrapper>
  );
};

const InquiriesCountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`;
export default InquirySummery;
