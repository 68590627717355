const LandingPageData = [
  {
    title: '이런 인플루언서에게 추천드려요!',
    description:
      '인플루언서 비즈니스를 시작하시는 분이시라면, 궁금증과 기대감을 갖고 방문하는 인친들이 더 만족하여 단골이 될 수 있도록 특별한 경험을 선사해주셔야 합니다.',
    description2:
      '시작부터 꾸준히 oh!moss와 함께 공구, 광고, 스케줄을 관리하면서 데이터를 쌓아간다면 안정된 비즈니스 여정을 만들어갈 수 있습니다.',
    imgdata: {
      title: '초보자 혹은 시작한지 3개월이내',
      subtitle: '여정의 시작',
      image: '/images/landing/landing_banner1.jpg',
    },
  },
  {
    title: '이런 인플루언서에게 추천드려요!',
    description:
      '인플루언서 공구를 계획하고 계시고, 인플루언서 전용 링크를 찾고 계셨다면 더 늦기 전에 oh!moss로 체계적인 프로세스를 구축해보세요.',
    description2:
      '매출이 작은 공구부터 큰 규모의 공구까지, 그리고 늘어날 공구를 효율적으로 관리할 수 있습니다.',
    imgdata: {
      title: '공구 시작/링크 이전 준비',
      subtitle: '체계를 갖추기',
      image: '/images/landing/landing_banner2.jpg',
    },
  },
  {
    title: '이런 인플루언서에게 추천드려요!',
    description:
      '매번 같은 링크가 아닌 다른 링크로 공구를 하게되었을때, 각 공구별 cs와 고객관리가 어려워지고, 이로인해 인친들과의 관계가 무너지고, 비즈니스 신뢰가 추락하는 것이 걱정되신다면?',
    description2:
      'oh!moss에선 한군데서 고객과 업체와 이야기 하고, 함께 cs를 대응할 수 있습니다. 각 공구(딜)별 데이터를 수집해서 전체적인 비즈니스 현황을 알도록 도와드립니다. 항상 유지되는 고객서비스를 통해 인친과의 관계가 끊기지 않도록 지속적으로 관리가 가능합니다.',
    imgdata: {
      title: '다양한 링크로 공구를 한다면',
      subtitle: '유지와 도약',
      image: '/images/landing/landing_banner3.jpg',
    },
  },
];

export default LandingPageData;
