import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { Row } from 'components/commons/layouts';
import { Button, Avatar } from '@mui/material';
import { isExpired, decodeToken } from 'react-jwt';
import styled from 'styled-components';

const GoogleSignupPage = observer(() => {
  const googleUri = 'https://people.googleapis.com/v1/people/me';
  const [searchParams] = useSearchParams();

  const [userInfo, setUserInfo] = useState<any>();
  // const loginInGoogle = async () => {
  //   const idToken = searchParams.get('id_token');
  //   alert(idToken);
  //   axios.defaults.headers.common.Authorization = `Bearer ${idToken}`;
  //   const res = await axios.get(`${googleUri}`);
  //   console.log(res);
  // };

  useEffect(() => {
    // loginInGoogle();
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const decodedToken = decodeToken(accessToken);
      console.log('decodedToken', accessToken);

      setUserInfo(decodeToken);
    }

    // const initClient = () => {
    //   gapi.client.init({
    //     clientId: '523747023589-pc6h4e4tnji96l3r3borqfk3go1menb0.apps.googleusercontent.com',
    //     scope: '',
    //   });
    // };
    // gapi.load('client:auth2', initClient);
    // setTimeout(() => {
    //   console.log('ccc##############', gapi.auth2);
    //   console.log('ccc##############', gapi.auth2.GoogleUser());
    // }, 1000);
    // const t = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
  }, []);

  return (
    <ContainerStyle>
      <div>
        <WelcomeHeaderStyle>Welcome!</WelcomeHeaderStyle>
        <Row>
          {/* <Avatar src={userInfo.kakao_account.profile.thumbnail_image_url} /> */}
          <div>{userInfo?.name}님!</div>
        </Row>
        <div>회원가입이 완료되었습니다!</div>
        <Button>Channel 생성</Button>
      </div>
    </ContainerStyle>
  );
});

const WelcomeHeaderStyle = styled.div`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;
const ContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export default GoogleSignupPage;
