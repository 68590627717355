const MembershipManualData = [
  {
    type: 'PRO',
    titleData: [
      '한 곳에서 간편하게 관리할 수 \n있는 CS TOWN',
      '공구를 꼼꼼히 준비하는\n셀러를 위한 체크리스트',
      '눈에 확인할 수 있는\n공구현황 대시보드',
      '셀러에게 딱 맞는\n가장 완벽한 링크',
    ],
    subtitle: ['매니저 20명까지 초대가능'],
    description:
      '인플루언서 비즈니스를 시작하시는 분이시라면, 궁금증과 기대감을 갖고 방문하는 인친들이 더 만족하여 단골이 될 수 있도록 특별한 경험을 선사해주셔야 합니다.',
    boxData: {
      text1: '셀러피드백',
      text2: 'Seller’s Choice',
      text3: '7일간 무료',
      text4: '사용 EVENT',
    },
    priceData: {
      price: '39,000원',
      period: '/월',
      discount: '6개월(10%) / 12개월(20%)',
    },
    imgData: [
      '/images/management/membership/membership_seller_pro_1.svg',
      '/images/management/membership/membership_seller_pro_2.svg',
      '/images/management/membership/membership_seller_pro_3.svg',
      '/images/management/membership/membership_seller_pro_4.svg',
    ],
  },
  {
    type: 'PREMIUM',
    titleData: [
      '한 곳에서 간편하게 관리할 수\n있는 CS TOWN',
      '공구 전문가의 공구 리뷰\n컨설팅 월 1회 무료',
      '소수정예 공구 세미나\n최우선 참여권',
    ],
    description:
      '인플루언서 비즈니스를 시작하시는 분이시라면, 궁금증과 기대감을 갖고 방문하는 인친들이 더 만족하여 단골이 될 수 있도록 특별한 경험을 선사해주셔야 합니다.',
    boxData: {
      text1: '셀러피드백',
      text2: 'Seller’s Choice',
      text3: '7일간 무료\n사용 EVENT',
    },
    priceData: {
      price: '99,000원',
      period: '/월',
      discount: '6개월(10%) / 12개월(20%)',
    },
    imgData: [
      '/images/management/membership/membership_seller_pro_1.svg',
      '/images/management/membership/membership_seller_premium_1.svg',
      '/images/management/membership/membership_seller_premium_2.svg',
    ],
  },
];

export default MembershipManualData;
