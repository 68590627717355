import React from 'react';
import styled from 'styled-components';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ItemSectionTitle } from 'resources/controls/ItemSectionStyle';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';

const AssignedDealsContainer = ({ deals }: { deals?: LinkDtoWithAssignedDeal[] }) => {
  return (
    <>
      <ItemSectionTitle style={{ margin: '10px 0px 5px 0px', fontSize: 13 }}>
        위임할 공구
      </ItemSectionTitle>
      <DealListWrapper>
        {deals?.map((deal, index) => (
          <>
            <DealContainer>
              <img src={deal?.thumbnail?.publicUrl} style={{ width: 50, height: 50 }} alt="" />
              {deal?.title ?? ''}
              <ArrowForwardIosIcon
                style={{
                  height: 14,
                  color: '#BEBEBE',
                  position: 'absolute',
                  right: 30,
                }}
              />
            </DealContainer>
          </>
        ))}
      </DealListWrapper>
    </>
  );
};

const DealListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DealContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-direction: row;
  border-radius: 0.625rem;
  border: ${({ isSelected }) =>
    isSelected ? '1px solid #96e06e' : '1px solid rgba(0, 0, 0, 0.35)'};
  color: ${({ isSelected }) => (isSelected ? '#fff' : '#48505d')};
  font-weight: 400;
  padding: 10px 30px 10px 10px;
  font-size: 0.875rem;
  gap: 10px;
  background-color: ${({ isSelected }) => (isSelected ? '#96e06e' : 'transparent')};
`;

export default AssignedDealsContainer;
