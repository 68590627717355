/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useCallback, useState, memo } from 'react';
import MUISwitch from 'components/commons/buttons/MUISwitch';
import { Row } from 'components/commons/layouts';
import SNSIconWapper from 'components/items/SNSIconWrapper';
import { CustomFile, SNSLinkItemModel } from 'types/CommonTypes';
import { debounce } from 'lodash';
import useSetState from 'libs/hooks/useSetState';
import UrlForm from 'components/items/forms/UrlForm';
import { validationHelper } from 'controls/validationHelper';
import { ImageUploader } from 'components/commons';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { ISocialBlock } from 'types/BlockExtendsTypes';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { LinkTypes } from 'features/management/context/providers/ContextTypes';
import BlockController from 'controllers/BlockController';
import DeleteIcon from 'features/csTown/commonComponents/DeleteIcon';

const SocialItem = ({
  name,
  defaultModel,
  itemIndex,
  onDelete,
  onChange,
}: {
  name?: string;
  itemIndex: number;
  onDelete: (id: number) => void;
  defaultModel?: SNSLinkItemModel;
  onChange?: (model: SNSLinkItemModel) => void;
}) => {
  const { serviceModel, updateServiceModel } = useOhmossContext<LinkTypes<ISocialBlock>>();

  const { updateItem } = BlockController<ISocialBlock>();
  /**
   * @description 개별 컴포넌트 업데이를 위한 모델
   */
  const [localModel, setLocalModel] = useSetState<SNSLinkItemModel>();

  const handleChange = useCallback(
    debounce(async (value: string) => {
      const result = validationHelper.url(value);
      const index =
        serviceModel?.items?.findIndex((item) => item.title === localModel?.title) ?? -1;
      if (serviceModel?.items) {
        updateItem(serviceModel, index, {
          ...serviceModel.items[index],
          linkUrl: value,
          isActive: result,
        });
      }
      setLocalModel('isActive', result);
    }, 500),
    [localModel, serviceModel, updateItem],
  );

  const handleClickDelete = useCallback(() => {
    onDelete(localModel?.itemId as number);
  }, [onDelete, localModel]);

  useEffect(() => {
    if (defaultModel) {
      setLocalModel(defaultModel);
    }
  }, [defaultModel]);

  return (
    <>
      <Row style={{ justifyContent: 'space-between', margin: '10px 0' }}>
        <div>
          {name === 'custom' ? (
            <ImageUploader
              type="single"
              useCircularCrop={false}
              attachType="image"
              initialValue={localModel?.file}
              onSelected={(image: CustomFile) => {
                updateItem(serviceModel, itemIndex, { ...localModel, file: image });
              }}
            />
          ) : (
            <div style={{ width: 60, display: 'flex', justifyContent: 'center' }}>
              <SNSIconWapper useOrigin name={name} width={50} />
            </div>
          )}
        </div>
        <div style={{ flexGrow: 1, margin: '0 15px' }}>
          <UrlForm
            initialValue={localModel?.linkUrl}
            placeHolder="주소를 입력해 주세요."
            onChangeValue={(value) => handleChange(value)}
          />
        </div>
        <Row style={{ width: 'unset' }}>
          <DeleteIcon onClick={handleClickDelete} style={{ marginRight: 5 }} />
          {/* <OhmossButton type="simple" onClick={handleClickDelete} style={{ marginRight: 5 }}>
            <img src="/image/trash.png" alt="" />
          </OhmossButton> */}
          <MUISwitch
            defaultChecked={localModel?.isActive}
            checked={localModel?.isActive}
            onChange={(e: any, value: boolean) => {
              setLocalModel('isActive', value);
              updateItem(serviceModel, itemIndex, { ...localModel, isActive: value });
            }}
          />
        </Row>
      </Row>
    </>
  );
};

export default memo(SocialItem);
