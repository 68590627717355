import React, { useEffect, useState } from 'react';

import {
  Stepper,
  StepContent,
  StepLabel,
  Button,
  Step,
  Paper,
  Typography,
  Box,
} from '@mui/material';
import { useStore } from 'stores/StoreHelper';
import { PushModel, CustomFile } from 'types/CommonTypes';

import Step1 from '../components/Step1';
import Step2 from '../components/Step2';
import Step3 from '../components/Step3';
import Step4 from '../components/Step4';

const CreatePush = () => {
  const { linkStore, uiStore, pushStore, channelStore, myChannelStore } = useStore();
  const channel = myChannelStore.currentChannel;

  const [activeStep, setActiveStep] = useState(0);
  const [pushModel, setPushModel] = useState<PushModel>();

  const steps = [
    {
      label: '메세지 기본정보',
      description: `고객에게 전송하고자 하는 메시지를 입력 해주세요.`,
      components: <Step1 model={[pushModel, setPushModel]} />,
    },
    {
      label: '메세지 링크',
      description: `전송할 메시지에 연결할 링크를 선택 해주세요.`,
      components: <Step2 links={linkStore.links} />,
    },
    {
      label: '전송 대상선택',
      description:
        '메세지를 전송할 대상을 선택해 주세요. 대상은 Tag단위 및 개별전송 모두 가능합니다.',
      components: <Step3 model={[pushModel, setPushModel]} />,
    },
    {
      label: '메세지 예약전송',
      description: `메세지는 즉시전송 및 예약전송으로 발송할 수 있습니다.`,
      components: <Step4 />,
    },
  ];

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      uiStore.confirm.show({
        message: '설정하신 내용으로 메시지를 전송하시겠습니까?',
        onConfirmed: async () => {
          if (pushModel) {
            // 유저 변경후 작업 필요
            // if (pushModel?.image.file?.constructor.name === 'Blob') {
            //   const uploadImage: CustomFile = (await fileStore.uploadImage(
            //     pushModel?.image?.file,
            //   )) as any;
            //   pushModel.imageUrl = uploadImage.publicUrl;
            // }

            pushStore.pushMessage(channelStore.channel.id as number, pushModel);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        },
      });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  useEffect(() => {
    console.log(pushModel);
  }, [pushModel]);

  useEffect(() => {
    // linkStore();
  }, []);

  return (
    <>
      <Box sx={{ maxWidth: 600 }}>
        <Stepper nonLinear activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === 3 ? <Typography variant="caption">마지막 단계입니다.</Typography> : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                {step.components}
                <div style={{ marginTop: 20 }}>
                  <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                    {index === steps.length - 1 ? 'Send Campaign' : '다음단계'}
                  </Button>
                  <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    이전단계
                  </Button>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
      </Box>
    </>
  );
};

export default CreatePush;
