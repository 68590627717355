import React, { useEffect, useState } from 'react';
import { ChannelDto } from 'services/data-contracts';
import { v4 as uuidv4 } from 'uuid';

import CustomerChatContainer from 'components/commons/chat/containers/CustomerChatContainer';
import { useChannelContext } from '../context/useChannelContext';
import { useRootContext } from 'libs/hooks/useRootContext';
import { IDealBlock } from 'types/BlockExtendsTypes';
import { ChatModel } from 'types/CommonTypes';
import { useChat } from 'libs/hooks/useChat';
import LinkController from 'controllers/LinkController';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

/**
 * @description 모바일 공구 블럭에서 일반 고객이 사용하는 채팅 창
 * @returns
 */
const InquiryChatPage = () => {
  const { currentChannel } = useChannelContext();
  const { linkkey } = useParams<{ linkkey: string }>();
  const { myChannel } = useRootContext();
  const { getLinkByKey } = LinkController();
  const { initChat } = useChat();

  const [chatInfo, setChatInfo] = useState<ChatModel | undefined>(undefined);

  const beginChat = async () => {
    const dealInfo = await getLinkByKey(linkkey as string);
    try {
      let chatInfo: ChatModel | undefined;
      if (myChannel) {
        chatInfo = {
          members: [myChannel],
          operators: [dealInfo?.channel as ChannelDto, myChannel],
          deal: dealInfo,
        };
        await initChat(myChannel.channelName as string);
      } else {
        let guestId = localStorage.getItem('guestId');
        if (guestId === null) {
          guestId = uuidv4();
          localStorage.setItem('guestId', guestId);
        }
        const guestChannel = {
          channelName: guestId,
        };

        localStorage.setItem(`guest-inquiry`, `${dealInfo.linkKey}`);

        chatInfo = {
          members: [guestChannel],
          operators: [dealInfo?.channel as ChannelDto, guestChannel],
          deal: dealInfo,
          guestInfo: { id: guestId },
        };

        await initChat(guestId as string);
      }
      setChatInfo(chatInfo);
    } catch (e) {
      alert(e.message);
    }
  };

  useEffect(() => {
    if (linkkey) {
      beginChat();
    }
  }, [linkkey]);
  return (
    <ChatContainerStyle>
      <CustomerChatContainer chatInfo={chatInfo} seller={currentChannel} />
    </ChatContainerStyle>
  );
};

const ChatContainerStyle = styled.div`
  width: 100vw;
`;

export default InquiryChatPage;
