import React, { CSSProperties, useEffect } from 'react';
import styled from 'styled-components';
import { IVideoBlock } from 'types/BlockExtendsTypes';
import YouTube from 'react-youtube';

const VideoTemplate = ({
  item,
  boxStyle,
  editMode,
  onClick,
}: {
  item: IVideoBlock;
  boxStyle?: CSSProperties;
  editMode?: boolean;
  onClick: () => void;
}) => {
  const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)|(?:https?:\/\/)?youtu\.be\/([a-zA-Z0-9_-]+)/;
  const shortRegexs = /https:\/\/(?:www\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]+)/;

  const [address, setAddress] = React.useState<string>();
  const opts = {
    height: '250px',
    width: '100%',
    playerVars: {
      autoplay: item.autoplay ? (editMode ? 0 : 1) : 0,
      playsinline: 1,
    },
  };

  useEffect(() => {
    if (item) {
      const match = youtubeRegex.exec(item?.linkUrl ?? '');
      const matchShort = shortRegexs.exec(item?.linkUrl ?? '');

      // 매치된 결과가 있으면 해당 비디오 ID 반환
      if (match) {
        const code = match[1] ? match[1] : match[2];
        setAddress(code);
      } else if (matchShort) {
        const shortCode = matchShort[1];
        setAddress(shortCode);
      } else {
        setAddress(item.address);
      }
    }
  }, [item]);

  return (
    <ContainerStyle>
      <YouTube videoId={address} opts={opts} />
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  /* background: #fff; */
  /* border-radius: 10px; */
  width: 100%;

  overflow: hidden;
`;

export default VideoTemplate;
