import React, { useEffect, useRef } from 'react';
import { PartnerDto } from 'services/data-contracts';
import { isMobile } from 'react-device-detect';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useStateContext } from 'libs/hooks/usePageState';
import { CSTownPartnerState } from 'types/CommonTypes';
import * as Containers from '../containers';
import CSTownPartnersController from './pageControllers/CSTownPartnersController';

const CSTownPartnersPage = () => {
  const { getPartnersList, getInvitationsResult } = CSTownPartnersController();
  const { setPageState, receiver } = useStateContext<CSTownPartnerState>();
  const { myChannel } = useRootContext();

  const originPartners = useRef<PartnerDto[]>([]);

  const handleSearch = (value: string) => {
    if (value === '') {
      setPageState('originalPartners', originPartners.current);
    } else {
      setPageState(
        'partnerList',
        originPartners.current.filter(
          (partner) => partner.partnerChannel?.channelName?.includes(value),
        ),
      );
    }
  };

  useEffect(() => {
    if (myChannel) {
      getPartnersList();
      getInvitationsResult();
    }
  }, [myChannel]);

  useEffect(() => {
    if (receiver) {
      if (receiver?.id === 'reload') {
        getInvitationsResult();
        getPartnersList();
      }
    }
  }, [receiver]);

  return (
    <>
      {isMobile ? (
        <Containers.MobileContainers.MobilePartnerListContainer onSearch={handleSearch} />
      ) : (
        <Containers.PCContainers.PartnerListContainer onSearch={handleSearch} />
      )}
    </>
  );
};

export default CSTownPartnersPage;
