import React, { useRef, useEffect } from 'react';

import { useMediaContext } from 'providers/useMediaContext';
import MyPageMobileContainer from '../containers/MyPageMobileContainer';
import MYPCContainer from '../containers/MYPCContainer';

const MyPage = () => {
  const ScreenTypes = useMediaContext();
  const instagramRef = useRef<string>();

  useEffect(() => {
    instagramRef.current = undefined;
  }, []);

  return <>{ScreenTypes.isLarge ? <MYPCContainer /> : <MyPageMobileContainer />}</>;
};

export default MyPage;
