import { useGlobalContext } from 'context/useGlobalContext';
import owebviewPostMessage from 'libs/webview/owebviewPostMessage';
import { useCallback } from 'react';
import useNavigation from 'hooks/useNavigation';
import { useStore } from 'stores/StoreHelper';
import { useRootContext } from 'libs/hooks/useRootContext';

export default function useLogout() {
  const { logout, setLogin } = useGlobalContext();
  const { myChannelStore, authStore } = useStore();
  const { setChannel } = useRootContext();

  const { navigate } = useNavigation();

  return useCallback(async () => {
    await authStore.logout();
    console.log('logout');

    logout();
    setChannel(undefined);
    owebviewPostMessage('LOGOUT');
    setLogin(false);
    navigate('/');
  }, [myChannelStore, authStore]);
}
