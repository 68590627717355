import { LinkItemDto } from 'services/data-contracts';

export const LinkItemDtoDummy: LinkItemDto = {
  id: undefined,
  seq: undefined,
  linkId: undefined,
  title: undefined,
  content: undefined,
  linkUrl: undefined,
  file: undefined,
  startDate: undefined,
  endDate: undefined,
  metadata: undefined,
  isActive: undefined,
  createDate: undefined,
  modifyDate: undefined,
};
