import React, { CSSProperties } from 'react';
import { LinkDto } from 'services/data-contracts';
import styled from 'styled-components';

const SimplePreview = ({
  model,
  showTitle,
  backgroundUrl,
}: {
  model?: LinkDto;
  showTitle?: boolean;
  backgroundUrl?: string;
}) => {
  return (
    <>
      <LinkPreviewTitleStyle>Link Preview</LinkPreviewTitleStyle>
      <PreviewContainerStyle>
        <PreviewStyle backgroundUrl={backgroundUrl}>
          <LinkContainerTextStyle>
            {typeof model?.thumbnail?.publicUrl !== 'undefined' && (
              <div style={imgStyle}>
                <img
                  src={model?.thumbnail?.publicUrl}
                  width={'100%'}
                  height={'100%'}
                  alt=""
                  style={{ borderRadius: 8 }}
                />
              </div>
            )}
            <LinckContainerTitleStyle>
              <LinkTitleStyle
                style={{
                  display: 'table-cell',
                  whiteSpace: 'pre-line',
                }}
              >
                <>{showTitle !== false && (model?.content || model?.title || 'Preview')}</>
              </LinkTitleStyle>
            </LinckContainerTitleStyle>
          </LinkContainerTextStyle>
        </PreviewStyle>
      </PreviewContainerStyle>
    </>
  );
};

const imgStyle: CSSProperties = {
  height: 50,
  width: 50,
};

const LinkPreviewTitleStyle = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 5px;
  margin-top: 30px;
`;

const PreviewContainerStyle = styled.div`
  position: relative;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-right: 10px;
  > div > div {
    width: 100%;
    height: 100%;
    min-height: 40px;
    border: 1px solid #dadada;
    display: flex;
    align-items: center;
    align-content: center;
    box-shadow: none;
  }
  > div > div > div > img {
  }
`;

const PreviewStyle = styled.div<{ backgroundUrl?: string }>`
  width: 100%;
  min-height: 60px;
  aspect-ratio: ${(props) => (typeof props.backgroundUrl !== 'undefined' ? '16 / 9' : 'unset')};
  display: table;
  overflow: auto;
  text-overflow: clip;
  word-break: break-all;
  /* min-height: 300px;
  max-height: 500px; */
  background-position: center;
  background-size: cover;
  background-image: ${(props) =>
    typeof props.backgroundUrl !== 'undefined' ? `url(${props.backgroundUrl})` : ''};
`;

const LinkContainerTextStyle = styled.div`
  border-radius: 10px;
  text-align: center;
  word-wrap: break-word;
  padding: 5px 5px 5px 5px;
  align-items: center;
  justify-content: space-between;
  display: flex;

  position: relative;
  box-shadow: 0px 0px 7px -1px #c8c8c8;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const LinckContainerTitleStyle = styled.div`
  flex: 1;
  display: table;
`;

const LinkTitleStyle = styled.div`
  font-size: 18px;
`;
export default SimplePreview;
