import styled, { css } from 'styled-components';

const MenuTitleStyle = styled.div`
  color: #505050;
  font-size: 35px;
  font-weight: 300;
  padding: 30px 0 0 30px;
  height: 80px;
  width: 300px;
  text-transform: uppercase;

  @media ${(props) => props.theme.media.mobile} {
    padding: 10px 0px 0 30px;
    height: 50px;
  }
`;

export default MenuTitleStyle;
