import React, { useEffect, useState } from 'react';

import { DealMemberDto, LinkDto, MemberDto, SimpleDealDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import { useRootContext } from 'libs/hooks/useRootContext';
import { Row } from 'components/commons/layouts';
import { OhmossButton } from 'resources/controls/OhmossButton';

import AddIcon from '@mui/icons-material/Add';
import CSTownController from 'controllers/CSTownController';
import styled from 'styled-components';
import ManagerListItem from '../ManagerListItem';
import chatController from 'features/community/controllers/chatController';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';
import { toast } from 'react-toastify';

interface MemberModel extends MemberDto {
  assigned?: boolean;
}

const CSManagers = ({ deal }: { deal?: SimpleDealDto }) => {
  const { uiStore } = useStore();
  const { myChannel } = useRootContext();
  const { Brand } = CSTownController();

  const { inviteOrDeleteUser } = chatController();

  const [members, setMembers] = useState<DealMemberDto[]>();

  const handleAddMember = async () => {
    const result = (await Brand.DEAL.getMembers(myChannel?.id as number)) as unknown as MemberDto[];
    const combined = result.map((member: DealMemberDto) => {
      return {
        ...member,
        assigned: members?.find((r) => r.member?.id === member.id) ? true : false,
      };
    }) as unknown as MemberModel[];

    uiStore.drawer.show({
      title: '매니저 지정',
      description: (
        <Row style={{ justifyContent: 'flex-start' }}>
          <div>공구를 관리할 매니저를 추가합니다.</div>
        </Row>
      ),
      children: (
        <>
          <ManagerList members={combined} />
        </>
      ),
    });
  };

  /**
   * @description 매니저 추가
   * @param manager
   */
  const handleAddManager = async (manager?: MemberModel) => {
    const result = (await Brand.DEAL.assignDealsToManager(
      myChannel?.id as number,
      manager?.id as number,
      { deals: [deal as LinkDto] },
    )) as unknown as string;

    if (result === '') {
      await inviteOrDeleteUser(
        [{ ...deal, assigned: true } as LinkDtoWithAssignedDeal],
        [manager?.memberUser?.username as string],
      );
      getDeal();
    }
  };

  /**
   * @description 매니저를 공구에서 위임 해제.
   * @param manager
   */
  const handleDeleteManager = async (manager?: DealMemberDto) => {
    uiStore.confirm.show({
      message: '매니저를 위임 해제 하시겠습니까?',
      onConfirmed: async () => {
        const result = (await Brand.DEAL.unassignDealFromManager(
          myChannel?.id as number,
          manager?.member?.id as number,
          deal?.id as number,
        )) as unknown as string;

        if (result === '') {
          await inviteOrDeleteUser(
            [{ ...deal, assigned: false }],
            [manager?.member?.memberUser?.username as string],
          );
          toast('매니저를 위임 해제 하였습니다', { type: 'success' });
          getDeal();
        }
      },
    });
  };

  const ManagerList = ({ members }: { members: MemberModel[] }) => {
    return (
      <div style={{ marginTop: 50 }}>
        <Row style={{ width: '100%', flexDirection: 'column', gap: 10 }}>
          {members.map((member: any) => (
            <>
              <ManagerListItem
                member={member}
                assigned={member.assigned}
                type="ADD"
                onAdd={() => {
                  handleAddManager(member);
                }}
                onDelete={() => {
                  handleDeleteManager(member);
                }}
                style={{ width: '90%' }}
              />
            </>
          ))}
        </Row>
      </div>
    );
  };

  const getDeal = async () => {
    const tempDeal = (await Brand.DEAL.getDealById(
      myChannel?.id as number,
      deal?.id as number,
    )) as unknown as SimpleDealDto;
    setMembers(tempDeal?.dealMembers);
  };

  useEffect(() => {
    if (deal) {
      getDeal();
    }
  }, [deal]);

  return (
    <div style={{}}>
      <div style={{ flex: 0.5 }}>
        <div>
          <Row style={{ justifyContent: 'space-between' }}>
            <BlockHeaderStyle>담당 매니저</BlockHeaderStyle>
            <OhmossButton
              type="square"
              onClick={handleAddMember}
              style={{ height: 30, width: 'unset' }}
            >
              <AddIcon style={{ width: 25, height: 25 }} />
              <div>매니저 지정</div>
            </OhmossButton>
          </Row>
          <Row style={{ gap: 10, justifyContent: 'flex-start', flexWrap: 'wrap' }}>
            {members?.map((member: DealMemberDto) => (
              <>
                <ManagerListItem
                  member={member?.member}
                  type="EDIT"
                  onAdd={() => {
                    handleAddManager(member);
                  }}
                  onDelete={() => {
                    handleDeleteManager(member);
                  }}
                  style={{ width: '32%' }}
                />
              </>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

const BlockHeaderStyle = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0;
  padding: 20px;
`;
export default CSManagers;
