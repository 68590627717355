import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { useRootContext } from 'libs/hooks/useRootContext';
import useSandbirdChat from 'hooks/useSandbirdChat';
import { useChat } from 'libs/hooks/useChat';
import { ChannelQueryType } from 'types/CommonTypes';

import * as Containers from '../containers';
import CSTownRootContainer from '../containers/pc/CSTownRootContainer';

const CSTownRootPage = () => {
  const { initCompleted } = useChat();
  const { myChannel } = useRootContext();

  // const { loadChannels } = chatController();
  const { getInquiryChannels } = useSandbirdChat();

  const getInquiries = async () => {
    if (myChannel) {
      if (myChannel?.channelType === 'SELLER') {
        const query: ChannelQueryType = {
          channelType: 'OWNER',
          metaValues: [myChannel?.id!.toString()],
        };
        getInquiryChannels(query);
      } else {
        const query: ChannelQueryType = {
          channelType: 'DELEGATED',
        };
        getInquiryChannels(query);
      }
    }
  };

  const initialize = async () => {
    getInquiries();
  };

  useEffect(() => {
    if (myChannel && initCompleted) {
      initialize();
    }
  }, [myChannel, initCompleted]);

  return (
    <>{isMobile ? <Containers.MobileContainers.MobileRootContainer /> : <CSTownRootContainer />}</>
  );
};

export default CSTownRootPage;
