import TitleForm from 'components/items/forms/TitleForm';
import useSetState from 'libs/hooks/useSetState';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { DealServiceItemModel } from 'types/CommonTypes';
import { DealCustomType } from '.';

const DealCustomTypeD = ({
  item,
  onChange,
}: {
  item: DealServiceItemModel;
  onChange: (result: DealServiceItemModel) => void;
}) => {
  const [localModel, setLocalModel] = useSetState<DealServiceItemModel>();

  useEffect(() => {
    if (item) {
      setLocalModel(item);
    }
  }, [item]);

  return (
    <CustomItemWrapper>
      <CustomItemStyle>{/* <img src={icon} alt="" /> */}</CustomItemStyle>
      <FormContainer>
        <TitleForm
          onChangeValue={(value: string) => {
            setLocalModel('title', value);
            onChange({ ...item, title: value });
          }}
          initialValue={item.title}
        />
        <TitleForm
          onChangeValue={(value: string) => {
            setLocalModel('content', value);
            onChange({ ...item, content: value });
          }}
          initialValue={item.content}
        />
      </FormContainer>
    </CustomItemWrapper>
  );
};

const CustomItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CustomItemStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: #efefef;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-right: 10px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  gap: 8px;
`;

export default DealCustomTypeD;
