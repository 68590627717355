import React, { useEffect, useState } from 'react';
import { QRContainer } from 'containers/adminContainers';
import styled from 'styled-components';

const QRCodePage = () => {
  const [windowSearch, setWindowSearch] = useState<string | undefined>();

  useEffect(() => {
    const { search } = window.location;
    setWindowSearch(search);
  }, []);

  return <Container>{/* <QRContainer username={windowSearch} /> */}</Container>;
};

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default QRCodePage;
