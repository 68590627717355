import React, { useEffect, useRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { LocationCountSeriesDto } from 'services/data-contracts';
import styled from 'styled-components';
import ChartProps from './ChartProps';

const DonutChart = ({
  title,
  subTitle,
  data,
}: {
  title?: string;
  subTitle?: string;
  data?: LocationCountSeriesDto[];
}) => {
  const [dataArray, setDataArray] = React.useState<any[]>([]);
  const [percentageArray, setPercentageArray] = React.useState<any[]>([]);

  interface PercentageResult {
    value: any;
    percentage: number;
  }

  function calculatePercentageForEachValue(arr: any[]): { name: any; y: number }[] {
    const totalCount = arr.length;

    // Use a Map to store the count of each unique value
    const valueCounts = new Map<any, number>();
    arr.forEach((item) => {
      valueCounts.set(item, (valueCounts.get(item) || 0) + 1);
    });

    // Calculate the percentage for each unique value
    const percentages: PercentageResult[] = Array.from(valueCounts.entries()).map(
      ([value, count]) => ({
        value,
        percentage: (count / totalCount) * 100,
      }),
    );
    const resultArray = percentages.map(({ value, percentage }) => ({
      name: value,
      y: percentage,
    }));
    setPercentageArray(resultArray);
    return resultArray;
  }
  useEffect(() => {
    if (dataArray.length > 0) {
      calculatePercentageForEachValue(dataArray);
    }
  }, [dataArray]);

  useEffect(() => {
    if (typeof data !== 'undefined' && title === '접속지역') {
      const newArray = data?.map((item) => item.location?.substring(0, 2));
      if (newArray && newArray.length > 0) {
        setDataArray(newArray);
      }
    } else if (typeof data !== 'undefined' && title === '진입경로') {
      const newArray = data?.map((item) => item.location);
      if (newArray && newArray.length > 0) {
        setDataArray(newArray);
      }
    }
  }, [data]);
  const options: Highcharts.Options = {
    colors: ['#121d21', '#464b42', '#FAA74B', '#B37CD2'],
    chart: {
      type: 'pie',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    title: {
      text: title,
    },
    subtitle: {
      text: subTitle,
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>',
    },
    // credits: {
    //   enabled: false,
    // },
    series: [
      {
        name: title,
        type: 'pie',
        // colorByPoint: true,
        innerSize: '75%',
        data: percentageArray ?? [],
      },
    ],
  };
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  return (
    <>
      {percentageArray.length > 0 ? (
        <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
      ) : (
        <div>
          <TextContainer>표시할 데이터가 없습니다.</TextContainer>
        </div>
      )}
    </>
  );
};

const TextContainer = styled.div`
  text-align: center;
  font-size: 13px;
  font-weight: 600;
`;

export default DonutChart;
