import React, { useEffect, useState } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import { ChannelBoard, ChannelBoardDto } from 'services/data-contracts';
import { validationHelper as VH } from 'controls/validationHelper';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { Row } from 'components/commons/layouts';
import { ImageUploader } from 'components/commons';
import { CustomFile } from 'types/BlockExtendsTypes';

import LinkCategoryForm from 'features/management/components/category/LinkCategoryForm';
import BoardCategoryForm from 'features/management/components/category/BoardCategoryForm';
import MUISwitch from 'components/commons/buttons/MUISwitch';
import TitleForm from 'components/items/forms/TitleForm';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import { useStore } from 'stores/StoreHelper';
import CategoryController from 'controllers/CategoryController';
import { usePageModelContext } from 'features/management/context/usePageModelContext';
import Required from 'components/commons/Required';

const CategoryFormContainer = ({ item }: { item?: ChannelBoardDto }) => {
  const defaultProps: ChannelBoardDto = {
    boardType: 'LINK',
    viewType: 'NONE',
    isCommentAllow: false,
    useProfile: true,
    isDefault: false,
    isPrimary: false,
    isActive: true,
  };

  const { uiStore } = useStore();

  const { updateServiceModel, initialBinding, initialModel, unmountModel, serviceModel } =
    usePageModelContext<ChannelBoardDto>();

  const { updateCategory } = CategoryController();

  const [localModel, setLocalModel] = useInitializeModel<Partial<ChannelBoardDto>>(
    item,
    defaultProps,
    initialBinding,
  );

  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    if (localModel) {
      const name = VH.required(localModel?.name);
      setIsValid(name);
    }
  }, [localModel]);

  useEffect(() => {
    return () => {
      unmountModel();
    };
  }, [item]);

  return (
    <div style={{ padding: 20 }}>
      <ItemSectionStyle>
        <ItemSectionTitle>섬네일</ItemSectionTitle>
        <ItemContent>
          <ImageUploader
            type="single"
            modalType="modal"
            useCircularCrop={false}
            initialValue={localModel?.thumbnail}
            attachType="image"
            onSelected={(image: CustomFile) => {
              updateServiceModel('thumbnail', image);
            }}
          />
        </ItemContent>
      </ItemSectionStyle>
      {/* {typeof item === 'undefined' && (
        <ItemSectionStyle>
          <ItemSectionTitle>종류</ItemSectionTitle>
          <ItemContent>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={localModel?.boardType || 'LINK'}
                name="radio-buttons-group"
                onChange={(e: any, value: string) => {
                  updateServiceModel('boardType', value as ChannelBoard['boardType']);
                  setLocalModel('boardType', value as ChannelBoard['boardType']);
                }}
              >
                <FormControlLabel value="LINK" control={<MUIRadio />} label="링크 만들기" />
                <FormControlLabel value="BOARD" control={<MUIRadio />} label="게시판 만들기" />
              </RadioGroup>
            </FormControl>
          </ItemContent>
        </ItemSectionStyle>
      )} */}
      <ItemSectionStyle>
        <ItemSectionTitle>
          제목
          <Required />
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            initialValue={initialModel?.name}
            placeHolder="페이지 이름"
            onChangeValue={(value: string) => {
              setLocalModel('name', value);
              updateServiceModel('name', value);
            }}
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>
          <Row style={{ justifyContent: 'space-between' }}>
            <div>공개</div>
            <MUISwitch
              checked={localModel?.isActive}
              onChange={(e: any, value: boolean) => {
                // setLocalModel('isActive', value);
                updateServiceModel('isActive', value);
              }}
            />
          </Row>
        </ItemSectionTitle>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <>{console.log('localModel', localModel)}</>
        <ItemContent>
          {localModel?.boardType === 'LINK' ? (
            <LinkCategoryForm />
          ) : (
            <BoardCategoryForm
              viewType={item?.viewType}
              onChangeviewType={(viewType: ChannelBoard['viewType']) => {
                updateServiceModel('viewType', viewType);
              }}
            />
          )}
        </ItemContent>
      </ItemSectionStyle>
      <div style={{ marginTop: 50 }}>
        <OhmossButton
          style={{ width: '100%' }}
          onClick={async () => {
            const result = await updateCategory(serviceModel);
            uiStore?.bottomSheet.confirm(result);
            if (uiStore?.drawer?.drawerShow) {
              uiStore.drawer?.confirm(result);
            }
          }}
          disabled={!isValid}
        >
          {typeof item === 'undefined' ? '추가' : '적용'}
        </OhmossButton>
      </div>
    </div>
  );
};

export default CategoryFormContainer;
