import React from 'react';
import Common from './Common';
import { MembershipPlanDto } from 'services/data-contracts';

const BRAND_PRO = ({ plan }: { plan: MembershipPlanDto }) => {
  const texts = [
    '공구/샵 블록(링크서비스) 제공',
    'CS 팀 서비스 제공 - 매니저 5명까지 운영',
    '공구 체크리스트 제공',
    '공구 대시보드 제공',
  ];
  const description = '성공적인 공구를 위한\r강력한 솔루션';
  return (
    <>
      <Common texts={texts} plan={plan} description={description} />
    </>
  );
};

export default BRAND_PRO;
