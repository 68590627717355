import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { DealsTypes } from 'features/management/context/providers/ContextTypes';

import {
  ChannelChecklistCreationDto,
  ChannelChecklistDto,
  SimpleDealDto,
  ChannelChecklistTopicDto,
} from 'services/data-contracts';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useStore } from 'stores/StoreHelper';
import ChecklistController from 'controllers/ChecklistController';
import { toast } from 'react-toastify';
import DealListSelector from 'features/myPage/containers/checklist/DealListSelector';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import { isMobile } from 'react-device-detect';
import * as Containers from '../containers';

const CSTownChecklistDetailPage = () => {
  const { uiStore } = useStore();
  const location = useLocation();
  const [searchParam] = useSearchParams();
  const { myChannel } = useRootContext();
  const { getChannelDeals } = useOhmossContext<DealsTypes>();
  const {
    createMyChecklist,
    getMyChecklist,
    getChannelChecklistTopic,
    getChecklistsByCategory,
    deleteMyChecklist,
    updateMyChecklist,
  } = ChecklistController();
  const { serviceModel, updateServiceModel } = useModelManagement<ChannelChecklistCreationDto>();

  const [channelDeals, setChannelDeals] = useState<SimpleDealDto[]>([]);
  const [checklistInfo, setChecklistInfo] = useState<ChannelChecklistDto>();
  const [tab, setTab] = useState('체크리스트 상세');
  const [selectedDeal, setSelectedDeal] = useState<SimpleDealDto>();
  const [productName, setProductName] = useState<string>();
  const [topics, setTopics] = useState<ChannelChecklistTopicDto[]>([]);

  const category = searchParam.get('category');
  const id = searchParam.get('id');

  const getDeals = async () => {
    const deals = (await getChannelDeals()) as unknown as SimpleDealDto[];
    setChannelDeals(deals);
  };

  const getChecklistInfo = async () => {
    const result = await getMyChecklist(myChannel?.id as number, Number(id));
    if (result) {
      setChecklistInfo(result);
    }
    if (result?.deal) {
      setSelectedDeal(result?.deal);
    }
  };

  const getTopics = async () => {
    const tempTopics = await getChannelChecklistTopic(myChannel?.id as number, Number(id));
    setTopics(tempTopics);
  };

  const getNewChecklistTopics = async () => {
    const tempTopics = (await getChecklistsByCategory(
      Number(category),
      myChannel?.id as number,
    )) as any;

    if (tempTopics?.items) {
      const uniqueTopics = Array.from(
        new Set(tempTopics?.items.map((item: any) => item.topicName)),
      ).map((topicName) => ({
        topicName,
        progressBar: 0,
      }));
      setTopics(uniqueTopics as ChannelChecklistTopicDto[]);
    }
  };

  const handleSelectDeal = () => {
    uiStore.modal.show({
      title: '공구 선택',
      style: { height: '80vh', padding: '0px 20px 0px 20px' },
      children: <DealListSelector onClick={setSelectedDeal} deals={channelDeals} />,
      onConfirmed(result) {
        if (result) {
          setSelectedDeal(result);
          updateServiceModel('dealId', result?.id);
        }
      },
    });
  };

  const handleConfirm = async () => {
    if ((serviceModel?.dealId || serviceModel?.title) && serviceModel?.categoryId) {
      if (id !== null) {
        await updateMyChecklist(myChannel?.id as number, Number(id), serviceModel);
      } else {
        await createMyChecklist(myChannel?.id as number, serviceModel);
      }
    } else {
      toast.error('필수 정보를 입력해주세요');
    }
  };

  const handleDelete = async () => {
    if (id !== null) {
      uiStore.confirm.show({
        message: '체크리스트를 삭제하시겠습니까?',
        onConfirmed: async () => {
          await deleteMyChecklist(myChannel?.id as number, Number(id));
          window.history.go(-1);
        },
      });
    }
  };

  useEffect(() => {
    if (myChannel) {
      if (id !== null) {
        getDeals();
        getChecklistInfo();
        getTopics();
        updateServiceModel('categoryId', Number(category));
      } else {
        getDeals();
        getNewChecklistTopics();
        updateServiceModel('categoryId', Number(category));
      }
    }
  }, [myChannel]);

  useEffect(() => {
    if (location.pathname) {
      setTimeout(() => {
        const t = document.querySelector('.ohmoss-navigation-tab') as any;
        if (
          (t && location.pathname === '/my/deals/checklist/detail/list') ||
          location.pathname === '/cstown/checklist/detail/list'
        ) {
          t.style.display = 'none';
        } else {
          t.style.display = 'flex';
        }
      }, 0);
    }
  }, [location]);

  return (
    <>
      {isMobile ? (
        <Containers.MobileContainers.MobileCSTownChecklistDetailContainer />
      ) : (
        <Containers.PCContainers.CSTownChecklistDetailContainer />
      )}
    </>
  );
};

export default CSTownChecklistDetailPage;
