import React, { useState, useEffect } from 'react';
import { useStore } from 'stores/StoreHelper';
import { Row } from 'components/commons/layouts';
import { OhmossButton } from 'resources/controls/OhmossButton';

import koLocale from '@fullcalendar/core/locales/ko';
import enLocale from '@fullcalendar/core/locales/en-gb';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import styled from 'styled-components';
import CommonCalendarItem from './CommonCalendarItem';

type CommonProps = {
  events?: any;
};
const CommonCalendar = ({ events }: CommonProps) => {
  const { uiStore } = useStore();
  const [locale, setLocale] = useState<any>();

  const handleClickDate = (info: any) => {
    const targetEvent = events?.find((event: any) => event.id === Number(info.event._def.publicId));
    uiStore.universalModal.show({
      modalOption: { style: { height: '60%' } },
      children: (
        <Row style={{ alignContent: 'flex-start', justifyContent: 'center' }}>
          <CommonCalendarItem style={{ margin: 'unset' }} event={targetEvent} />
        </Row>
      ),
    });
  };

  useEffect(() => {
    switch (document.documentElement.lang) {
      case 'en':
        setLocale(enLocale);
        break;
      case 'ko':
        setLocale(koLocale);
        break;
      default:
        break;
    }
  }, []);
  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        contentHeight={500}
        locale={locale}
        eventClick={handleClickDate}
        events={events}
      />
    </>
  );
};

const ContainerStyle = styled.div`
  padding: 20px;
`;

const TitleStyle = styled.div`
  font-weight: 700;
  font-size: 16px;
`;

const ContentsStyle = styled.div`
  margin-top: 20px;
  font-size: 14px;
`;

const DateStyle = styled.div`
  font-size: 14px;
  color: #4e4e4e;
`;

const AlldayStyle = styled.div`
  background: rebeccapurple;
  padding: 2px 10px;
  margin-left: 10px;
  border-radius: 8px;
  color: #fff;
`;

const ButtonStyle = styled.div`
  padding: 20px;
  margin-top: 30px;
`;

export default CommonCalendar;
