import React, { useEffect } from 'react';
import ChecklistController from 'controllers/ChecklistController';
import ChecklistCategorySelector from 'features/myPage/components/checklist/ChecklistCategory';
import { useStateContext } from 'libs/hooks/usePageState';
import { useRootContext } from 'libs/hooks/useRootContext';

import { toast } from 'react-toastify';
import { useStore } from 'stores/StoreHelper';
import { CSTownChecklistState } from 'types/CommonTypes';
import { useLocation } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
import { ChannelChecklistDto } from 'services/data-contracts';
import CSTownController from 'controllers/CSTownController';
import styled from 'styled-components';

const CSTownChecklistController = () => {
  const { uiStore } = useStore();
  const { navigate } = useNavigation();
  const { deals, Brand, Member, Seller } = CSTownController();

  const location = useLocation();

  const getDeals = async () => {
    switch (myChannel?.channelType) {
      case 'SELLER':
        await Seller.DEAL.getDeals();
        break;
      case 'BRAND':
        await Brand.DEAL.getDelegatedDeals();
        break;
      case 'MEMBER':
        await Member.DEAL.getDeals();
        break;
      default:
        break;
    }
  };

  const { getChecklistCategories, getMyChecklists } = ChecklistController();

  const { setPageState, setMultipleState, stateModel } = useStateContext<CSTownChecklistState>();
  const { myChannel } = useRootContext();

  const getChecklists = async () => {
    const result = await getMyChecklists(myChannel?.id as number);
    setPageState('checklists', result);
    // checklistRef.current = result;
  };

  const search = (value: string) => {
    // if (value === '') {
    //   setChecklist(checklistRef.current);
    // } else {
    //   const result = checklistRef?.current.filter((item) => {
    //     return (
    //       item?.title?.includes(value) || item?.deal?.sellerChannel?.channelName?.includes(value)
    //     );
    //   });
    //   setChecklist(result);
    // }
  };

  const clickItem = (item: ChannelChecklistDto) => {
    if (location.pathname === '/cstown/checklist') {
      navigate(`/cstown/checklist/detail?category=${item?.category?.id}&id=${item?.id}`);
    } else if (location.pathname === '/my/deals/checklist') {
      navigate(`/my/deals/checklist/detail?category=${item?.category?.id}&id=${item?.id}`);
    }
  };

  const createCheckList = async () => {
    if (myChannel?.membershipType === 'FREE') {
      toast.warn('유료 회원만 이용 가능합니다.');
      return;
    }

    const categories = await getChecklistCategories(myChannel?.id as number);
    uiStore.bottomSheet.show({
      title: '체크리스트 카테고리',
      style: { minHeight: '320px' },
      children: (
        <CategoryWrapperStyle>
          <ChecklistCategorySelector categoryProps={categories} />
        </CategoryWrapperStyle>
      ),
    });
  };

  const unmountDeals = () => {
    setPageState('deals', undefined);
  };

  const unmountAll = () => {
    setMultipleState({
      selectedTopic: null,
      deals: null,
      deal: undefined,
      checklists: null,
      selectedChecklist: null,
    });
  };

  const resetChecklist = () => {
    setMultipleState({ selectedTopic: undefined });
  };

  useEffect(() => {
    if (deals) {
      setPageState('deals', deals);
    }
  }, [deals]);

  return {
    getChecklists,
    search,
    createCheckList,
    clickItem,
    getDeals,
    getChecklistCategories,
    unmountDeals,
    unmountAll,
    resetChecklist,
  };
};

const CategoryWrapperStyle = styled.div`
  padding-top: 20px;
  background: rgb(248, 248, 248);
`;

export default CSTownChecklistController;
