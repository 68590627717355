import MobileModalHeader from 'components/commons/MobileModalHeader';
import React from 'react';
import Analytics from '../containers/Analytics';
import DetailPageHeader from 'components/commons/DetailPageHeader';

const AllDealsDashboardPage = () => {
  return (
    <div>
      {/* <DetailPageHeader title={'공구 통합 대시보드'} /> */}
      <div style={{ padding: '20px' }}>
        <Analytics />
      </div>
    </div>
  );
};

export default AllDealsDashboardPage;
