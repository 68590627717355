/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  DealListDto,
  MemberDto,
  PartnerDto,
  PartnerUpdateDto,
  SimpleDealDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class PartnerService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 파트너 정보
   *
   * @tags PartnerService
   * @name GetPartner1
   * @summary 파트너 정보
   * @request GET:/v2/me/channels/{channelId}/partners/{partnerId}
   * @secure
   */
  getPartner1 = (channelId: number, partnerId: number, params: RequestParams = {}) =>
    this.http.request<PartnerDto, any>({
      path: `/v2/me/channels/${channelId}/partners/${partnerId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 파트너 수정
   *
   * @tags PartnerService
   * @name UpdatePartner
   * @summary 파트너 수정
   * @request PUT:/v2/me/channels/{channelId}/partners/{partnerId}
   * @secure
   */
  updatePartner = (
    channelId: number,
    partnerId: number,
    data: PartnerUpdateDto,
    params: RequestParams = {},
  ) =>
    this.http.request<PartnerDto, any>({
      path: `/v2/me/channels/${channelId}/partners/${partnerId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 파트너 삭제
   *
   * @tags PartnerService
   * @name DeletePartner
   * @summary 파트너 삭제
   * @request DELETE:/v2/me/channels/{channelId}/partners/{partnerId}
   * @secure
   */
  deletePartner = (channelId: number, partnerId: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/partners/${partnerId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 파트너 딜 목록
   *
   * @tags PartnerService
   * @name GetPartnerDeals1
   * @summary 파트너 딜 목록
   * @request GET:/v2/me/channels/{channelId}/partners/{partnerId}/deals
   * @secure
   */
  getPartnerDeals1 = (
    channelId: number,
    partnerId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["id,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SimpleDealDto[], any>({
      path: `/v2/me/channels/${channelId}/partners/${partnerId}/deals`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 파트너 딜 위임
   *
   * @tags PartnerService
   * @name AssignDeals
   * @summary 파트너 딜 위임
   * @request PUT:/v2/me/channels/{channelId}/partners/{partnerId}/deals
   * @secure
   */
  assignDeals = (
    channelId: number,
    partnerId: number,
    data: DealListDto,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/partners/${partnerId}/deals`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 파트너 딜 위임
   *
   * @tags PartnerService
   * @name AssignDeal
   * @summary 파트너 딜 위임
   * @request PUT:/v2/me/channels/{channelId}/partners/{partnerId}/deals/{dealId}/assign
   * @secure
   */
  assignDeal = (channelId: number, partnerId: number, dealId: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/partners/${partnerId}/deals/${dealId}/assign`,
      method: 'PUT',
      secure: true,
      ...params,
    });
  /**
   * @description 파트너 목록
   *
   * @tags PartnerService
   * @name GetPartners1
   * @summary 파트너 목록
   * @request GET:/v2/me/channels/{channelId}/partners
   * @secure
   */
  getPartners1 = (
    channelId: number,
    query?: {
      /** @format int64 */
      dealId?: number;
      isActive?: boolean;
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<PartnerDto[], any>({
      path: `/v2/me/channels/${channelId}/partners`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 파트너 매니저 목록
   *
   * @tags PartnerService
   * @name GetPartnerMembers
   * @summary 파트너 Member 목록
   * @request GET:/v2/me/channels/{channelId}/partners/members
   * @secure
   */
  getPartnerMembers = (
    channelId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["id,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<MemberDto[], any>({
      path: `/v2/me/channels/${channelId}/partners/members`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description member 별 딜목록
   *
   * @tags PartnerService
   * @name GetDealsByMember
   * @summary Member 별 딜목록
   * @request GET:/v2/me/channels/{channelId}/partners/members/{memberId}/deals
   * @secure
   */
  getDealsByMember = (
    channelId: number,
    memberId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["id,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SimpleDealDto[], any>({
      path: `/v2/me/channels/${channelId}/partners/members/${memberId}/deals`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 파트너 딜 위임 해제
   *
   * @tags PartnerService
   * @name UnassignDeal
   * @summary 파트너 딜 위임 해제
   * @request DELETE:/v2/me/channels/{channelId}/partners/{partnerId}/deals/{dealId}/unassign
   * @secure
   */
  unassignDeal = (
    channelId: number,
    partnerId: number,
    dealId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/partners/${partnerId}/deals/${dealId}/unassign`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
}
