import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useRootContext } from 'libs/hooks/useRootContext';
import useNavigation from 'hooks/useNavigation';

const screenWidth = window.innerWidth;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CommonRoundTabButtons({
  items,
  initialValue,
  style,
  noOfTabs,
  onHandleTabClick,
}: {
  items: string[];
  initialValue?: string;
  style?: React.CSSProperties;
  noOfTabs?: number;
  onHandleTabClick: (label: string) => void;
}) {
  const { myChannel } = useRootContext();

  const { navigate } = useNavigation();

  const [value, setValue] = React.useState(0);
  const [newItems, setNewItems] = React.useState<string[]>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleTabClick = (label: string) => {
    onHandleTabClick(label);
  };

  React.useEffect(() => {
    // 탭 배열의 내용 세팅
    if (initialValue && items) {
      if(noOfTabs === 3) {
        setNewItems(items.filter(item => item !== '멤버십 설명서' && item !== '멤버십 선물'));
      } else if(noOfTabs === 4) {
        setNewItems(items.filter(item => item !== '멤버십 설명서'));
      } else {
        setNewItems(items.filter(item => item !== '멤버십 설명서'));
      }
    }
  }, [initialValue, myChannel]);

  React.useEffect(() => {
    // 탭 인덱스 세팅
    if(newItems){
      const i = newItems?.findIndex((item: string) => item === initialValue);
      if(i > -1){
        setValue(i);
      } else {
        // 새로고침시 newItems 에 바인딩 되기 전 처리
        switch(initialValue) {
          case '멤버쉽 구독':
            setValue(0);
            break;
          case '내 멤버쉽':
            setValue(1);
            break;
          case '결제 내역':
            setValue(2);
            break;
          case '멤버십 선물':
            if(noOfTabs === 4) {
              setValue(3);
            } else {
              navigate(`/my/subscriptions`);
              setValue(0);
            }
            break;
          default:
            navigate(`/my/subscriptions`);
        }
      }
    }
  },[newItems])
  

  return (
    <Tabs
      sx={{
        marginTop: '30px',
        width: 'auto',
        display: 'inline-block',
        alignItems: 'center',
        paddingTop: '5px',
        border: '1px solid #d6d6d6',
        // background: '#fff',
        borderRadius: '30px',
        '& .MuiTabs-root': { margin: '0' },
        '& .MuiTabs-indicator': { height: '100%' },
      }}
      TabIndicatorProps={{
        style: {
          background: 'rebeccapurple',
          borderRadius: '30px',
          border: '3px solid #dbdbdb',
        },
      }}
      value={value}
      onChange={(event: React.SyntheticEvent, newValue: number) => handleChange(event, newValue)}
    >
      {newItems?.map((item: string) => (
        <Tab
          sx={{
            width: noOfTabs === 4 ? '150px' : '200px',
            fontSize: '14px',
            fontWeight: '500',
            // color: 'grey',
            // background: '#fff',
            color: '#4d4d4d',
            borderRadius: '30px',
            padding: '10px 30px',
            margin: '0 10px',
            wordBreak: 'keep-all',
            whiteSpace: 'pre-wrap',
            zIndex: 1,
            '&.Mui-selected': {
              // color: '#173300',
              color: '#fff',
            },
            // '&.MuiTab-indicator': {
            //   border: 'none',
            // },
          }}
          label={item}
          onClick={() => handleTabClick(item)}
          {...a11yProps(1)}
        />
      ))}
    </Tabs>
  );
}
