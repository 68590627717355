import React, { useEffect } from 'react';
import { Row } from 'components/commons/layouts';
import { ColorPicker } from 'components/items';
import { observer } from 'mobx-react';
import { Button } from '@mui/material';
import { useStore } from 'stores/StoreHelper';
import styled, { CSSProperties } from 'styled-components';
import { Stack } from '@mui/system';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { ProfileTypes } from 'features/management/context/providers/ContextTypes';
import { IChannelProfile } from 'types/BlockExtendsTypes';

import ColorizeIcon from '@mui/icons-material/Colorize';
import SaveButton from 'features/adminFeatures/admin/components/SaveButton';
import useSetState from 'libs/hooks/useSetState';
import { useRootContext } from 'libs/hooks/useRootContext';
import { OhmossButton } from 'resources/controls/OhmossButton';

const BackgroundColorComponent = observer(({ onSave }: { onSave: () => void }) => {
  const { uiStores } = useStore();
  const { profileStore } = uiStores;
  const { myChannel } = useRootContext();

  const { updateServiceModel, serviceModel, stateModel } =
    useOhmossContext<ProfileTypes<IChannelProfile>>();

  const [localModel, setLocalModel, deleteProperty] = useSetState<CSSProperties>();

  const handleClickSave = () => {
    updateServiceModel('contentStyle', localModel);
    onSave();
  };

  useEffect(() => {
    if (myChannel && serviceModel.contentStyle) {
      setLocalModel(serviceModel.contentStyle);
    }
  }, [myChannel]);

  return (
    <>
      <div style={{ position: 'relative', zIndex: 1 }}>
        <CustomColorStyle
          selected={
            typeof profileStore?.profileMetadataObject?.backgroundProps?.backgroundColor !==
            'undefined'
          }
          style={{
            background: profileStore?.profileMetadataObject?.backgroundProps?.backgroundColor,
          }}
        />
      </div>
      <div style={{ flexGrow: 1 }}>
        <>
          <div style={{ margin: 'auto' }}>
            <ColorPicker
              useSwipe={false}
              useGradient
              onColorChanged={(color: string) => {
                if (typeof color === 'undefined') {
                  deleteProperty('backgroundColor');
                } else {
                  setLocalModel('backgroundColor', color);
                  updateServiceModel('backgroundType', 'color');
                }
              }}
              defaultValue={localModel?.backgroundColor}
            >
              <Button
                variant="contained"
                startIcon={<ColorizeIcon />}
                sx={{ borderRadius: 20, background: 'rebeccapurple' }}
              >
                Custom Color
              </Button>
            </ColorPicker>
          </div>
        </>
      </div>
      <div style={{ padding: 20 }}>
        <OhmossButton onClick={handleClickSave} type="primaryPc">
          저장하기
        </OhmossButton>
      </div>
    </>
  );
});

const CustomColorStyle = styled.div<{ selected: boolean }>`
  border-radius: 10px;
  margin: 0 15px 15px 15px;
  justify-content: center;
  display: flex;
  align-items: center;
  height: ${(props) => (props.selected ? '150px' : 0)};
  border: ${(props) => (props.selected ? '1px solid #c1c1c1' : 'none')};
`;

export default BackgroundColorComponent;
