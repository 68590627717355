/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { File, FileDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class FileUpload<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description S3 파일 업로드
   *
   * @tags file upload
   * @name UploadFile
   * @summary 파일 업로드
   * @request POST:/v2/files
   * @secure
   */
  uploadFile = (
    query: {
      fileType:
        | 'Profile'
        | 'Background'
        | 'Footer'
        | 'Link'
        | 'Notification'
        | 'Coupon'
        | 'Post'
        | 'Template'
        | 'AdCategory'
        | 'ChannelBoard'
        | 'Magazine'
        | 'DealInquiryThread'
        | 'Proposal'
        | 'ChecklistCategory';
    },
    data: {
      /** @format binary */
      file: File;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileDto, any>({
      path: `/v2/files`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * @description 관리자 파일 업로드
   *
   * @tags file upload
   * @name UploadAdminFile
   * @summary 관리자 파일 업로드
   * @request POST:/v2/admin-files
   * @secure
   */
  uploadAdminFile = (
    query: {
      fileType:
        | 'Profile'
        | 'Background'
        | 'Footer'
        | 'Link'
        | 'Notification'
        | 'Coupon'
        | 'Post'
        | 'Template'
        | 'AdCategory'
        | 'ChannelBoard'
        | 'Magazine'
        | 'DealInquiryThread'
        | 'Proposal'
        | 'ChecklistCategory';
    },
    data: {
      /** @format binary */
      file: File;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileDto, any>({
      path: `/v2/admin-files`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
}
