/* eslint-disable no-param-reassign */
import React, { useState, useEffect, CSSProperties, useRef } from 'react';
import { CardLinkRenderType, CardLinkModel, InternalLinkCard } from 'types/CommonTypes';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useStore } from 'stores/StoreHelper';
import { Pagination } from 'swiper';
import VideoComponent from 'features/adminFeatures/commons/components/VideoComponent';
import AddLinkIcon from '@mui/icons-material/AddLink';
import useScreenType from 'libs/hooks/useScreenType';
import styled, { css } from 'styled-components';
import 'swiper/css/pagination';
import 'swiper/css';
import VideoEditor from 'features/adminFeatures/commons/components/VideoEditor';
import { observer } from 'mobx-react';
import { isJsonObject } from 'libs/helper/utils';
// import { LinkDto } from 'services/data-contracts';

type defaultProps = {
  item: CardLinkModel;
};

const CardLinkComponent = observer(({ item }: defaultProps) => {
  const [cards, setCards] = useState<Array<InternalLinkCard>>();
  const [renderType, setRenderType] = useState<CardLinkRenderType>();
  const [swiper, setSwiper] = useState<any>(null);
  const [index, setIndex] = useState<number>(0);
  const [offsetWidth, setOffsetWidth] = useState<number | undefined>(0);

  const screenType = useScreenType();
  const cardItem = (card: InternalLinkCard) => {
    const getStyle = (card: InternalLinkCard, style?: CSSProperties) => {
      // 배경이 없는경우, 기본 검정으로 배경을 지정한다.
      if (typeof style === 'undefined') {
        style = { background: '#000' };
      }
      return {
        minWidth: screenType !== 'mobile' ? '100%' : '100%',
        aspectRatio: '1 / 1',
        width: '100%',
        height: '100%',
        cursor: card.linkUrl !== null ? 'pointer' : 'default',
        ...style,
      };
    };

    return (
      <div
        style={getStyle(card, card.metadataObject?.backgroundStyle)}
        onClick={() => {
          if (card.linkUrl !== null) {
            if (card.linkUrl?.includes('http')) {
              window.open(card.linkUrl, '_blank');
            } else {
              window.open(`http://${card.linkUrl}`, '_blank');
            }
          }
        }}
      >
        {card.file && (
          <div style={{ overflow: 'hidden', height: '100%' }}>
            {card.file?.contentType?.indexOf('image/') === 0 ? (
              <div
                style={{
                  width: '100%',
                  aspectRatio: '1',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  backgroundSize: 'cover',
                  backgroundImage: `url(${card.file.publicUrl})`,
                  backgroundPosition: 'center center',
                }}
              />
            ) : (
              <VideoComponent
                isCover={card.file?.sizeInfo?.isCover}
                video={card.file}
                // containerSize={
                //   templateStore.isSimulator ? 400 : screenType === 'mobile' ? 350 : 550
                // }
              />
            )}
          </div>
        )}

        <div className="card-canvas-title-container">
          {typeof card?.metadataObject?.titleContents?.text !== 'undefined' && (
            <div className="card-canvas-title">{card?.metadataObject.titleContents.text}</div>
          )}
          {/* {typeof card.linkUrl !== 'undefined' && (
            <div className="card-canvas-title-link-container">
              <div className="card-canvas-title-link">
                <a href={card.linkUrl}>
                  <AddLinkIcon style={{ position: 'relative', top: 7 }} />
                </a>
              </div>
            </div>
          )} */}
        </div>
      </div>
    );
  };

  /** 왼쪽 화살표 클릭 이벤트 */
  const handleClickArrow = (isForward: boolean) => {
    if (isForward) {
      setIndex((prev) => {
        return item.items?.length! - 1 === prev ? prev : prev + 1;
      });
    } else {
      setIndex((prev) => {
        return prev === 0 ? prev : prev - 1;
      });
    }
  };

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    item?.items?.map((card: InternalLinkCard) => {
      if (card?.metadata) {
        // card.contentObject = JSON.parse(card?.content);
        const middleObject = JSON.parse(card.metadata);
        card.metadataObject = middleObject.metadataObject;
        if (card.file) {
          card.file.sizeInfo = middleObject?.sizeInfo;
        }
      }
    });
    if (typeof item?.items !== 'undefined') {
      setCards(item?.items.sort((a, b) => a.seq! - b.seq!));
    }
  }, [item?.items]);

  useEffect(() => {
    if (swiper) {
      try {
        swiper?.slideTo(index);
      } catch (e) {}
    }
  }, [index]);

  useEffect(() => {
    if (typeof item.metadata !== 'undefined') {
      if (isJsonObject(item.metadata)) {
        const _cardLink: CardLinkModel = { metadataObject: JSON.parse(item.metadata), ...item };
        setRenderType(_cardLink.metadataObject?.renderType);
        if (_cardLink.metadataObject?.renderType === 'grid') {
          if (
            !Number.isNaN(_cardLink.metadataObject?.renderItemsPerRow) &&
            _cardLink.metadataObject?.renderItemsPerRow
          ) {
            const ratio = 100 / _cardLink.metadataObject.renderItemsPerRow;
            setOffsetWidth(Number(ratio.toFixed(1)));
          } else {
            setOffsetWidth(33.3);
          }
        }
      }
    }
  }, [item]);

  return (
    <CardContainerStyle
      style={{
        overflowY: 'hidden',
        borderRadius: 8,
        lineHeight: 0,
        height: 'auto',
        background: 'transparent',
      }}
    >
      {cards?.length === 1 ? (
        <>
          {cards[0]?.file?.contentType?.indexOf('image/') === 0 ? (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <img
              onClick={() => {
                if (cards[0].linkUrl !== null) {
                  if (cards[0].linkUrl?.includes('http')) {
                    window.open(cards[0].linkUrl, '_blank');
                  } else {
                    window.open(`http://${cards[0].linkUrl}`, '_blank');
                  }
                }
              }}
              src={cards[0]?.file.publicUrl}
              style={{
                cursor: cards[0].linkUrl !== null ? 'pointer' : 'default',
                width: '100%',
                backgroundSize: 'cover',
              }}
              alt=""
            />
          ) : (
            <VideoComponent
              isCover={cards[0]?.file?.sizeInfo?.isCover}
              video={cards[0]?.file}
              // containerSize={
              //   templateStore.isSimulator ? 400 : screenType === 'mobile' ? 350 : 550
              // }
            />
          )}
        </>
      ) : (
        <>
          {renderType === 'grid' ? (
            <>
              {cards?.map((card: InternalLinkCard) => (
                <GridItemStyle
                  isSimulatore
                  isMobile={screenType === 'mobile'}
                  style={{ width: `${offsetWidth}%`, float: 'left' }}
                >
                  {cardItem(card)}
                </GridItemStyle>
              ))}
            </>
          ) : (
            <div style={{ position: 'relative' }}>
              {cards?.length! > 1 && (
                <ArrowStyleLeft
                  isMobile={screenType === 'mobile'}
                  style={{ opacity: index === 0 ? 0.2 : 0.7 }}
                  onClick={() => {
                    handleClickArrow(false);
                  }}
                >
                  <img
                    style={{ width: screenType === 'mobile' ? 6 : 10 }}
                    src="/image/leftArrow.png"
                  />
                </ArrowStyleLeft>
              )}
              <Swiper
                pagination={true}
                modules={[Pagination]}
                spaceBetween={50}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => setSwiper(swiper)}
              >
                {cards?.map((card: InternalLinkCard) => (
                  <SwiperSlide className="swiper" key={card.id}>
                    <>{cardItem(card)}</>
                  </SwiperSlide>
                ))}
              </Swiper>
              {cards?.length! > 1 && (
                <ArrowStyleRight
                  isMobile={screenType === 'mobile'}
                  style={{ opacity: index === cards?.length! - 1 ? 0.2 : 0.7 }}
                  onClick={() => {
                    handleClickArrow(true);
                  }}
                >
                  <img
                    style={{ width: screenType === 'mobile' ? 6 : 10 }}
                    src="/image/rightArrow.png"
                  />
                </ArrowStyleRight>
              )}
            </div>
          )}
        </>
      )}
    </CardContainerStyle>
  );
});
// min-width: ${(props) => (props.isSimulatore ? '100px' : props.isMobile ? '110px' : '150px')};

const CardContainerStyle = styled.div`
  /* transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8); */
  /* &:hover {
    transform: scale(1.05);
  } */
  /* @media ${(props) => props.theme.media.mobile} {
    &:hover {
      transform: scale(1);
    }
  } */
`;

const GridItemStyle = styled.div<{
  isSimulatore: boolean;
  isMobile: boolean;
}>`
  flex: 1;
  height: 100%;

  position: relative;
`;

const ArrowStyle = css<{ isMobile: boolean }>`
  top: 50%;
  width: ${(props) => (props.isMobile ? '20px' : '30px')};
  height: ${(props) => (props.isMobile ? '20px' : '30px')};
  padding: 5px 5px 5px 5px;
  border-radius: ${(props) => (props.isMobile ? '20px' : '30px')};
  background: rgba(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 20;
  position: absolute;
`;

const ArrowStyleLeft = styled.div`
  ${ArrowStyle}
  left: 5px;
`;

const ArrowStyleRight = styled.div`
  ${ArrowStyle}
  right:5px;
`;
export default CardLinkComponent;

//https://css-tricks.com/practical-css-scroll-snapping/
