import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import useNavigation from 'hooks/useNavigation';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { OhmossButton } from 'resources/controls/OhmossButton';

const UserNotFound = observer(() => {
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  return (
    <Container className="global-width">
      <Content>
        <Magnifier src={'/images/cry.png'} />
        <Title>
          채널을 찾을 수 없습니다. <br />
          채널주소를 확인해주세요.
        </Title>
      </Content>
      <div style={{ padding: 120, width: '100%' }}>
        <OhmossButton
          onClick={() => {
            navigate('/');
          }}
          style={{ padding: '10px 40px' }}
        >
          홈으로
        </OhmossButton>
      </div>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
`;

const Content = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 50px 30px 50px 30px;
  background-color: #f8f8f8;
  border-radius: 50px;
  text-align: center;
  align-items: center;
`;

const Magnifier = styled.img`
  width: 70%;
  margin: 0 0 30px 0;
`;

const Title = styled.p`
  color: #222;
  font-weight: 500;
  font-size: 20px;
`;

const MossTitle = styled.div`
  text-align: center;
  font-family: 'sacramento';
  font-size: 70px;
  margin-bottom: 20px;
  color: #3e64ea;
  font-weight: 500;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 4em;
  }
`;

export default UserNotFound;
