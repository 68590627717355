import React, { useEffect, useState } from 'react';
import { ImageUploader } from 'components/commons';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';

import { OhmossButton } from 'resources/controls/OhmossButton';
import { CustomFile } from 'types/CommonTypes';
import { ISuggestion } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { validationHelper } from 'controls/validationHelper';

import TitleForm from 'components/items/forms/TitleForm';
import { ProposalDto, TermDto } from 'services/data-contracts';
import ProposalController from 'controllers/ProposalController';
import useSetState from 'libs/hooks/useSetState';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import useLocalParams from 'libs/hooks/useLocalParams';
import FileUploader from 'components/commons/imageUploader/FileUploader';
import EmailForm from 'components/items/forms/EmailForm';
import styled from 'styled-components';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { useChannelContext } from 'features/linkService/context/useChannelContext';

const ProposalForm = ({ label }: { label?: any }) => {
  const { uiStore, xhrStore } = useStore();
  const { serviceModel, updateServiceModel, unmountModel } = useModelManagement<ProposalDto>();
  const { createProposal } = ProposalController();
  const { channelName } = useLocalParams();
  const { currentChannel } = useChannelContext();

  const defaultProps: Partial<ISuggestion> = {
    linkType: 'Suggestion',
    contactType: 'offer',
    isActive: true,
  };
  const [localModel, setLocalModel] = useSetState<Partial<ProposalDto>>(defaultProps);

  const [isValid, setIsValid] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean[]>([true, false]);

  const sendProposal = async () => {
    if (serviceModel && channelName) {
      updateServiceModel('requestType', 'BLOCK');
      xhrStore.setState('pending');
      const response = await createProposal(serviceModel, channelName);
      xhrStore.setState('done');
      if (typeof response === 'undefined') {
        toast.error('제안 전송에 실패했습니다.');
      } else {
        toast('제안이 전송되었습니다.');
        uiStore.modal.close();
      }
    }
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const checkBoxes = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label={
          <Typography
            style={{
              fontSize: !isMobile ? '15px' : '11px',
              fontWeight: '400',
              color: '#333',
            }}
          >
            이용약관 및 개인정보 처리방침 동의
          </Typography>
        }
        control={
          <Checkbox
            required
            color="success"
            checked={checked[0]}
            onChange={(event) => setChecked([event.target.checked, checked[1]])}
          />
        }
      />
      <FormControlLabel
        label={
          <Typography
            style={{
              fontSize: !isMobile ? '15px' : '11px',
              fontWeight: '400',
              color: '#333',
            }}
          >
            (선택) 크리에이터의 마케팅 활용 동의
          </Typography>
        }
        control={
          <Checkbox
            color="success"
            checked={checked[1]}
            onChange={(event) => setChecked([event.target.checked, checked[0]])}
          />
        }
      />
    </Box>
  );

  useEffect(() => {
    if (label && label?.length > 0) {
      setLocalModel('proposalType', label);
      updateServiceModel('proposalType', label);
    } else {
      setLocalModel('proposalType', undefined);
      updateServiceModel('proposalType', undefined);
    }
  }, [label]);

  useEffect(() => {
    if (localModel) {
      const titleValid = validationHelper.required(localModel?.title);
      const contentValid = validationHelper.required(localModel?.content);
      const phoneNumberValid = validationHelper.tel(localModel?.phoneNumber);
      const contactReplyValid = validationHelper.email(localModel?.contactReply);
      const senderValid = validationHelper.required(localModel?.sender);

      setIsValid(
        titleValid && contentValid && phoneNumberValid && contactReplyValid && senderValid,
      );
    }
  }, [localModel]);

  useEffect(() => {
    return () => {
      unmountModel();
    };
  }, []);

  return (
    <div style={{ padding: 20 }}>
      <ItemSectionStyle>
        <ItemSectionTitle style={{ flexDirection: 'row', display: 'flex' }}>
          제목<div style={{ color: '#F00' }}>&nbsp;*</div>
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            onChangeValue={(value: string) => {
              updateServiceModel('title', value);
              setLocalModel('title', value);
            }}
            label="제목"
            placeHolder="문의 제목을 입력해 주세요"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle style={{ flexDirection: 'row', display: 'flex' }}>
          내용<div style={{ color: '#F00' }}>&nbsp;*</div>
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            multiline
            rows={5}
            maxLength={500}
            useCount
            onChangeValue={(value: string) => {
              updateServiceModel('content', value);
              setLocalModel('content', value);
            }}
            initialValue={localModel?.content}
            placeHolder="문의 내용을 입력해 주세요"
            label="내용"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle style={{ flexDirection: 'row', display: 'flex' }}>
          전화번호<div style={{ color: '#F00' }}>&nbsp;*</div>
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            validationType="tel"
            onChangeValue={(value: string) => {
              updateServiceModel('phoneNumber', value);
              setLocalModel('phoneNumber', value);
            }}
            placeHolder="전화번호를 입력해 주세요"
            label="전화번호"
          />
        </ItemContent>
      </ItemSectionStyle>

      <ItemSectionStyle>
        <ItemSectionTitle style={{ flexDirection: 'row', display: 'flex' }}>
          이메일 주소<div style={{ color: '#F00' }}>&nbsp;*</div>
        </ItemSectionTitle>
        <ItemContent>
          <EmailForm
            placeholder="이메일 주소를 입력해 주세요"
            onChangeValue={(value: string) => {
              setLocalModel('contactReply', value);
              updateServiceModel('contactReply', value);
            }}
          />
        </ItemContent>
      </ItemSectionStyle>

      <ItemSectionStyle>
        <ItemSectionTitle style={{ flexDirection: 'row', display: 'flex' }}>
          보내는 사람<div style={{ color: '#F00' }}>&nbsp;*</div>
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            onChangeValue={(value: string) => {
              setLocalModel('sender', value);
              updateServiceModel('sender', value);
            }}
            initialValue={localModel?.sender}
            placeHolder="문의하시는 분의 이름을 입력하세요"
          />
        </ItemContent>
      </ItemSectionStyle>
      <div style={{ marginBottom: 20 }}>
        파일첨부
        <FileUploader
          onSelected={(files: CustomFile[]) => {
            setLocalModel('attechFile', files[0]);
            updateServiceModel('attechFile', files[0]);
          }}
        />
      </div>
      <AgreementContainer>
        <FormControlLabel
          label={
            <Typography
              style={{
                fontSize: '15px',
                fontWeight: '700',
                color: '#333',
              }}
            >
              전체동의
            </Typography>
          }
          control={
            <Checkbox
              color="success"
              checked={checked[0] && checked[1]}
              indeterminate={checked[0] !== checked[1]}
              onChange={handleCheckBoxChange}
            />
          }
        />
        {checkBoxes}
      </AgreementContainer>
      <Row style={{ gap: 10 }}>
        <OhmossButton
          type="box"
          style={{ color: '#000' }}
          onClick={() => {
            uiStore.modal.close();
          }}
        >
          취소
        </OhmossButton>
        <OhmossButton
          onClick={() => {
            sendProposal();
          }}
          disabled={!isValid}
        >
          보내기
        </OhmossButton>
      </Row>
    </div>
  );
};

const AgreementContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #efefef;
  margin-bottom: 20px;
  padding: 10px 20px 10px 20px;
`;

export default ProposalForm;
