import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { useParams } from 'react-router-dom';
import LinkController from 'controllers/LinkController';
import DealItemDetail from 'pages/DealItemDetail';
import { LinkDto } from 'services/data-contracts';
import { useRootContext } from 'libs/hooks/useRootContext';

const DealDetailPage = observer(() => {
  const { linkkey } = useParams();
  const { getLinkByKey } = LinkController();
  const { myChannel } = useRootContext();

  const [deal, setDeal] = useState<LinkDto>();

  const getDealDetail = async () => {
    if (linkkey) {
      const result = (await getLinkByKey(linkkey)) as any as LinkDto;
      setDeal(result);
    }
  };

  useEffect(() => {
    getDealDetail();
  }, [linkkey]);

  return (
    <div className="global-width" style={{ textAlign: 'center' }}>
      <DealItemDetail post={deal} isOwner={deal?.channel?.channelName === myChannel?.channelName} />
    </div>
  );
});

export default DealDetailPage;
