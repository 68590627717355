import React, { useState, useEffect } from 'react';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ItemSectionDescription } from 'resources/controls/ItemSectionStyle';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { koKR } from '@mui/x-date-pickers';
import { ko } from 'date-fns/locale';
import { Row } from 'components/commons/layouts';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import moment from 'moment';
import { toast } from 'react-toastify';
import TimeDisplay from 'features/csTown/components/pc/TimeDisplay';

const TimeDealPicker = ({
  start,
  end,
  timePickerType,
  timeRange,
  mode,
}: {
  start?: string;
  end?: string;
  timePickerType?: 'time' | 'day';
  timeRange: (
    from: string | undefined,
    to: string | undefined,
    toHour: number | string | undefined,
    type?: 'time' | 'day' | undefined,
  ) => void;
  mode: 'NEW' | 'EDIT';
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | string>('');
  const [selectedTime, setSelectedTime] = useState<string | string>('');
  const [selectedMinute, setSelectedMinute] = useState<string | string>('');
  const [toHour, setToHour] = useState<number | string>('');
  const [pickerType, setPickerType] = useState<'time' | 'day' | undefined>(undefined);
  const [dateRange, setDateRange] = useState<{ from?: string; to?: string }>({ from: '', to: '' });
  const FORMAT = 'YYYY-MM-DD HH:mm:ss';
  const handleDateChange = (date: any) => {
    if (date === null) {
      setSelectedDate('');
    } else {
      setSelectedDate(date);
    }
  };

  const handleChangeTime = (event: SelectChangeEvent, data: any) => {
    setSelectedTime(data.props.value);
  };
  const handleChangeMinute = (event: SelectChangeEvent, data: any) => {
    setSelectedMinute(data.props.value);
  };

  const handleChangeToHour = (event: SelectChangeEvent, data: any) => {
    setToHour(data.props.value);
  };

  const handleChangeTimeType = (event: never, value: 'time' | 'day') => {
    if (value !== null) {
      setPickerType(value);
      setToHour('');
    }
  };

  const setNewTime = () => {
    if (selectedDate !== '' && selectedTime !== '' && selectedMinute !== '' && toHour !== '') {
      const time = moment(selectedDate).format('YYYY-MM-DD');
      const newTime = moment(
        `${time} ${
          selectedTime.length === 1 ? `0${selectedTime}` : selectedTime
        }:${selectedMinute}:00`,
      );

      const from = newTime.toISOString();
      const to =
        pickerType === 'time'
          ? newTime.add(toHour, 'h').toISOString()
          : newTime.add(toHour, 'd').toISOString();
      const now = new Date();
      const past = new Date(from);
      if (now > past) {
        if (mode === 'NEW') {
          toast('광고시작 시간은 현재시간 이후여야 합니다.', { type: 'warning' });
          timeRange(undefined, undefined, undefined, undefined);
          setDateRange({ from: undefined, to: undefined });
          return;
        }
      }
      timeRange(from, to, toHour, pickerType);
      setDateRange({ from: from, to: to });
    } else {
      timeRange(undefined, undefined, undefined, undefined);
      setDateRange({ from: undefined, to: undefined });
    }
  };

  function parseTimeAndDifference() {
    if (start && end) {
      const time = new Date(start);
      const compareTime = new Date(end);
      const hours = time.getHours();
      const minutes = time.getMinutes();

      const difference = compareTime.getTime() - time.getTime();

      const diffHours = Math.floor(difference / (1000 * 60 * 60));
      setSelectedDate(time);
      setSelectedTime(`${hours}`);
      setSelectedMinute(`${minutes}`);
      setToHour(timePickerType === 'day' ? Math.round(diffHours / 24) : diffHours);
    }
  }

  useEffect(() => {
    setNewTime();
  }, [selectedDate, selectedTime, selectedMinute, toHour]);

  useEffect(() => {
    if (start && end) {
      parseTimeAndDifference();
    }
  }, [start, end]);

  useEffect(() => {
    if (toHour) {
      const t = toHour;
    }
  }, [toHour]);

  useEffect(() => {
    if (timePickerType) {
      setPickerType(timePickerType);
    }
  }, [timePickerType]);

  return (
    <>
      <ItemSectionDescription style={{ marginBottom: 0 }}>시작시간</ItemSectionDescription>
      <Row style={{ gap: 10 }}>
        <LocalizationProvider adapterLocale={ko} dateAdapter={AdapterDateFns}>
          <DatePicker
            value={selectedDate}
            format="yyyy-MM-dd"
            disablePast
            localeText={koKR.components.MuiLocalizationProvider.defaultProps.localeText}
            sx={{ '&.MuiFormControl-root': { flex: 1 } }}
            onChange={handleDateChange}
            slotProps={{ textField: { fullWidth: true } }}
          />
        </LocalizationProvider>
        <div style={{ width: 100 }}>
          <FormControl fullWidth>
            <ToggleButtonGroup
              color="primary"
              style={{ height: 55 }}
              exclusive
              value={pickerType}
              onChange={handleChangeTimeType}
              aria-label="Platform"
            >
              <ToggleButton value="time">시간</ToggleButton>
              <ToggleButton value="day">일</ToggleButton>
            </ToggleButtonGroup>
          </FormControl>
        </div>
      </Row>
      <Row className="pt-4" style={{ justifyContent: 'flex-start' }}>
        <FormControl>
          <Select
            value={selectedTime}
            disabled={selectedDate === ''}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            onChange={handleChangeTime}
          >
            <MenuItem value="">
              <em>시</em>
            </MenuItem>
            {Array.from({ length: 24 }, (_, index) => (
              <MenuItem value={index}>{index}시</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <Select
            value={selectedMinute}
            disabled={selectedTime === ''}
            autoWidth
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            onChange={handleChangeMinute}
          >
            <MenuItem value="">
              <em>분</em>
            </MenuItem>
            {Array.from({ length: 4 }, (_, index) => (
              <MenuItem value={index * 15}>{index * 15}분</MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="px-4">부터</div>
        <FormControl style={{ flex: 1 }}>
          <Select
            value={toHour}
            disabled={selectedMinute === ''}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            onChange={handleChangeToHour}
          >
            <MenuItem value="">
              <em> {pickerType === 'time' ? '시간' : '일'}</em>
            </MenuItem>
            {Array.from({ length: pickerType === 'time' ? 12 : 30 }, (_, index) => (
              <MenuItem value={index + 1}>
                {index + 1} {pickerType === 'time' ? '시간' : '일'}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Row>
      <Row
        style={{
          flexWrap: 'wrap',
          justifyContent: 'space-evenly',
          marginTop: 20,
          border: '1px solid #ebebeb',
          borderRadius: 20,
          padding: 20,
        }}
      >
        <TimeDisplay title="공구시작일" date={dateRange.from} />
        <TimeDisplay type="END" title="공구종료일" date={dateRange.to} />
      </Row>
    </>
  );
};

export default TimeDealPicker;
