import React, { useEffect, useState, useRef } from 'react';
import { ImageUploader } from 'components/commons';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import TitleForm from 'components/items/forms/TitleForm';
import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { CustomFile } from 'types/CommonTypes';
import { Align, IPopUpBlock } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import UrlForm from 'components/items/forms/UrlForm';
import WebEditor from 'components/commons/webEditor/Editor';
import { validationHelper } from 'controls/validationHelper';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import { LinkTypes } from 'features/management/context/providers/ContextTypes';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import BlockController from 'controllers/BlockController';
import CommonAlign from '../commonControl/CommonAlign';
import ActionBox from 'features/csTown/components/pc/ActionBox';

const PopUp = ({ blockModel }: { blockModel?: IPopUpBlock }) => {
  const { uiStore } = useStore();

  const defaultProps: Partial<IPopUpBlock> = {
    linkType: 'Popup',
    isActive: true,
  };

  const { initialModel, serviceModel, updateServiceModel, initialBinding, unmountModel } =
    useOhmossContext<LinkTypes<IPopUpBlock>>();

  const { updateBlock } = BlockController<IPopUpBlock>();
  const editorRef = useRef<any>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [localModel, setLocalModel] = useInitializeModel<Partial<IPopUpBlock>>(
    blockModel,
    defaultProps,
    initialBinding,
  );

  useEffect(() => {
    if (localModel) {
      const title = validationHelper.required(localModel.title);
      const url = validationHelper.urlOrEmpty(localModel.linkUrl);
      setIsValid(title && url);
    }
  }, [localModel]);

  useEffect(() => {
    return () => {
      unmountModel();
    };
  }, []);

  return (
    <div style={{}}>
      <ItemSectionStyle>
        <ImageUploader
          type="single"
          useCircularCrop={false}
          initialValue={initialModel?.thumbnail}
          attachType="image"
          onSelected={(image: CustomFile) => {
            updateServiceModel('thumbnail', image);
          }}
        />
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>타이틀</ItemSectionTitle>
        <ItemContent>
          <TitleForm
            onChangeValue={(value: string) => {
              updateServiceModel('title', value);
              setLocalModel('title', value);
            }}
            initialValue={initialModel?.title}
            errorText="타이틀은 필수항목입니다."
            placeHolder="타이틀을 작성해 주세요"
            label="타이틀"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <WebEditor
          ref={editorRef}
          onEditorChange={(contents: string) => {
            // set('content', contents);
          }}
          initialValue={initialModel?.content}
        />
      </ItemSectionStyle>
      <CommonAlign
        title="타이틀 정렬"
        initialValue={initialModel?.contentAlign || 'center'}
        onChange={(align: Align) => updateServiceModel('contentAlign', align)}
      />
      <ItemSectionStyle>
        <ItemSectionTitle>링크주소</ItemSectionTitle>
        <ItemContent>
          <UrlForm
            onChangeValue={(value: string) => {
              setLocalModel('linkUrl', value);
              updateServiceModel('linkUrl', value);
            }}
            initialValue={initialModel?.linkUrl}
            label="링크주소"
          />
        </ItemContent>
      </ItemSectionStyle>
      <MenuItemCheckBox
        onChangeValue={(value: boolean) => {
          updateServiceModel('useTransparent', value);
        }}
        initialValue={initialModel?.useTransparent}
        title="투명배경"
      />
      <MenuItemCheckBox
        onChangeValue={(value: boolean) => {
          updateServiceModel('usePopUp', value);
        }}
        initialValue={initialModel?.usePopUp || false}
        title="링크페이지 접속시 팝업보이기"
      />

      <ActionBox
        style={{ marginTop: 60, paddingBottom: 20 }}
        isValid={isValid}
        okText="저장"
        onOk={() => {
          const data = editorRef.current.getData();
          updateServiceModel('content', data);
          updateBlock(serviceModel);
        }}
        onCancel={() => {
          uiStore.modal.close();
        }}
      />
    </div>
  );
};

export default PopUp;
