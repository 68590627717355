/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  DealInquiryDto,
  DealInquiryThreadDto,
  InquirySummaryDto,
  PartnerDto,
  SimpleDealDto,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class PartnershipService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 파트너 목록
   *
   * @tags PartnershipService
   * @name GetPartners
   * @summary 파트너 목록
   * @request GET:/v2/me/partner-channels/{channelId}/partners
   * @secure
   */
  getPartners = (
    channelId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<PartnerDto[], any>({
      path: `/v2/me/partner-channels/${channelId}/partners`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 파트너 정보
   *
   * @tags PartnershipService
   * @name GetPartner
   * @summary 파트너 정보
   * @request GET:/v2/me/partner-channels/{channelId}/partners/{partnerId}
   * @secure
   */
  getPartner = (channelId: number, partnerId: number, params: RequestParams = {}) =>
    this.http.request<PartnerDto, any>({
      path: `/v2/me/partner-channels/${channelId}/partners/${partnerId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 파트너별 위임된 딜 목록
   *
   * @tags PartnershipService
   * @name GetPartnerDeals
   * @summary 파트너별 위임된 딜 목록
   * @request GET:/v2/me/partner-channels/{channelId}/partners/{partnerId}/deals
   * @secure
   */
  getPartnerDeals = (
    channelId: number,
    partnerId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["id,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SimpleDealDto[], any>({
      path: `/v2/me/partner-channels/${channelId}/partners/${partnerId}/deals`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PartnershipService
   * @name GetInquiriesByPartnerChannel
   * @summary 문의목록
   * @request GET:/v2/me/partner-channels/{channelId}/inquiries
   * @secure
   */
  getInquiriesByPartnerChannel = (
    channelId: number,
    query?: {
      status?: 'PENDING' | 'ANSWERED' | 'CLOSED';
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DealInquiryDto[], any>({
      path: `/v2/me/partner-channels/${channelId}/inquiries`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PartnershipService
   * @name GetInquirySummaryForPartnerChannel
   * @summary 위임된 전체 딜 문의 집계
   * @request GET:/v2/me/partner-channels/{channelId}/inquiries/summary
   * @secure
   */
  getInquirySummaryForPartnerChannel = (channelId: number, params: RequestParams = {}) =>
    this.http.request<InquirySummaryDto, any>({
      path: `/v2/me/partner-channels/${channelId}/inquiries/summary`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 위임된 전체 딜 목록
   *
   * @tags PartnershipService
   * @name GetAllPartnerDeals
   * @summary 위임된 전체 딜 목록
   * @request GET:/v2/me/partner-channels/{channelId}/deals
   * @secure
   */
  getAllPartnerDeals = (
    channelId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["id,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SimpleDealDto[], any>({
      path: `/v2/me/partner-channels/${channelId}/deals`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 위임된 딜 정보
   *
   * @tags PartnershipService
   * @name GetPartnerDeal
   * @summary 위임된 딜 정보
   * @request GET:/v2/me/partner-channels/{channelId}/deals/{dealId}
   * @secure
   */
  getPartnerDeal = (channelId: number, dealId: number, params: RequestParams = {}) =>
    this.http.request<SimpleDealDto, any>({
      path: `/v2/me/partner-channels/${channelId}/deals/${dealId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PartnershipService
   * @name GetInquiries
   * @summary 딜 별 문의목록
   * @request GET:/v2/me/partner-channels/{channelId}/deals/{dealId}/inquiries
   * @secure
   */
  getInquiries = (
    channelId: number,
    dealId: number,
    query?: {
      status?: 'PENDING' | 'ANSWERED' | 'CLOSED';
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DealInquiryDto[], any>({
      path: `/v2/me/partner-channels/${channelId}/deals/${dealId}/inquiries`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PartnershipService
   * @name GetInquiryThreads
   * @summary 문의 스레드목록
   * @request GET:/v2/me/partner-channels/{channelId}/deals/{dealId}/inquiries/{inquiryId}/threads
   * @secure
   */
  getInquiryThreads = (
    channelId: number,
    dealId: number,
    inquiryId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DealInquiryThreadDto[], any>({
      path: `/v2/me/partner-channels/${channelId}/deals/${dealId}/inquiries/${inquiryId}/threads`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PartnershipService
   * @name GetUnassignedDealsForMembers
   * @summary 할당하지 않은 딜 목록
   * @request GET:/v2/me/partner-channels/{channelId}/deals/unassigned
   * @secure
   */
  getUnassignedDealsForMembers = (
    channelId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["id,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SimpleDealDto[], any>({
      path: `/v2/me/partner-channels/${channelId}/deals/unassigned`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PartnershipService
   * @name GetDealsAssignedToMembers
   * @summary 할당한 딜 목록
   * @request GET:/v2/me/partner-channels/{channelId}/deals/assigned
   * @secure
   */
  getDealsAssignedToMembers = (
    channelId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["id,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SimpleDealDto[], any>({
      path: `/v2/me/partner-channels/${channelId}/deals/assigned`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
