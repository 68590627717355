import React, { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';

import styled from 'styled-components';
import NameCard from './NameCard';
import TemplateCommon from './TemplateCommon';

const HomepageTemplate = observer(() => {
  const { channelStore, uiStores } = useStore();
  const { profileStore } = uiStores;
  const avatarRef = useRef<any>(null);

  const handleScroll = () => {
    const currentScroll = window.scrollY;

    if (avatarRef.current !== null) {
      avatarRef.current.style.top = `${-currentScroll / 2}px`;
      avatarRef.current.style.height = `${(isMobile ? 600 : 300) - currentScroll / 2}px`;
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', () => {
      handleScroll();
    });
  }, []);

  return (
    <>
      <ProfileWrapStyle isMine={channelStore.channel?.isOwner} isDevice={isMobile}>
        <div ref={avatarRef} style={{ width: '100%' }}>
          <img
            src={profileStore.profile?.profileImage?.publicUrl || '/images/ohmossc1.png'}
            alt=""
            style={{ width: '100%', height: '100%', objectFit: 'cover', aspectRatio: 1 }}
          />
        </div>
      </ProfileWrapStyle>
      <ItemContainerStyle>
        <NameCard profileInfo={profileStore.profile} type={'filled'} />
        <TemplateCommon />
      </ItemContainerStyle>
    </>
  );
});

const ProfileWrapStyle = styled.div<{ isMine?: boolean; isDevice?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  max-width: 550px;
  margin: auto;

  justify-content: center;
  > div:first-child {
    overflow: hidden;
    width: 550px;
    margin-bottom: 20px;
    top: 0;

    position: fixed;
    aspect-ratio: 1;
    @media ${(props) => props.theme.media.mobile} {
      margin-top: 0;
    }
  }
`;

const ItemContainerStyle = styled.div`
  margin-top: 250px;
  padding-bottom: 10px;
  @media ${(props) => props.theme.media.mobile} {
  }
  position: relative;
`;

export default HomepageTemplate;
