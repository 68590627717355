import React from 'react';
import UseTermsContainer from 'features/mainFeatures/terms/containers/UseTermsContainer';
import styled from 'styled-components';

const UseTermsPage = () => {
  return (
    <TermsPage>
      <UseTermsContainer />
    </TermsPage>
  );
};

/**
 * @description
 */
const TermsPage = styled.div`
  padding-top: 2em;
  padding-bottom: 2em;
  background: white;
  justify-content: center;
  display: flex;
  overflow: auto;
  padding:20px;
`;

export default UseTermsPage;
