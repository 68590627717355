import React, { memo, useEffect, useLayoutEffect, useRef, useMemo } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CountDto } from 'services/data-contracts';
import styled from 'styled-components';

const BarChart = memo(({ title, data }: { title?: string; data?: CountDto[] }) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const dataArray = useMemo(() => {
    if (data) {
      return data?.map((item) => item.count).filter((count) => count !== undefined);
    }
    return [];
  }, [data]);

  useLayoutEffect(() => {
    if (chartComponentRef.current && dataArray.length > 0) {
      const chart = chartComponentRef.current.chart;
      const newDateArray = data?.map((item) => item?.date ?? '');

      chart.xAxis[0].setCategories(newDateArray as string[], false);

      chart.series[0].setData(dataArray as Highcharts.PointOptionsType[], false);
      chart.redraw();
    }
  }, [dataArray]);

  const options: Highcharts.Options = useMemo(
    () => ({
      chart: {
        height: 400,
      },
      title: {
        text: title,
      },
      yAxis: {
        tickInterval: 1,
        title: {
          text: '팔로워',
        },
      },
      xAxis: {
        categories: [],
        title: {
          text: '기간',
        },
        accessibility: {
          description: '기간',
        },
      },
      series: [
        {
          type: 'column',
          name: '팔로워',
          data: [],
          pointWidth: 20,
        },
      ],
    }),
    [title],
  );

  return (
    <>
      {dataArray.length > 0 ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartComponentRef}
          allowChartUpdate={false}
        />
      ) : (
        <TextContainer>표시할 데이터가 없습니다.</TextContainer>
      )}
    </>
  );
});

const TextContainer = styled.div`
  text-align: center;
  font-size: 13px;
  font-weight: 600;
`;

export default BarChart;
