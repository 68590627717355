import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { useGoogleLogin } from '@react-oauth/google';
import { isExpired } from 'react-jwt';
import { ROOT_HOST, KAKAO_REST_API_KEY, KAKAO_CLIENT_SECRET_KEY } from 'stores/AppStore';
import {
  SignUpTitleStyle,
  LoginStyle,
  SNSTypeStyle,
  LogoImageStyle,
} from 'resources/LoginPageStyle';

import ATagStyle from 'features/mainFeatures/auth/components/ATagStyle';
import EmailLogin from 'features/mainFeatures/auth/components/EmailLogin';
import { useGlobalContext } from 'context/useGlobalContext';
import useToken from 'libs/hooks/useToken';
import { Checkbox } from '@material-ui/core';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import DetailPageHeader from 'components/commons/DetailPageHeader';
import { useRootContext } from 'libs/hooks/useRootContext';
import LoginPageController from './pageControllers/LoginPageController';
import { useMediaContext } from 'providers/useMediaContext';

const LoginPage = observer(() => {
  const ScreenTypes = useMediaContext();
  const { t } = useTranslation();
  const { uiStore, authStore } = useStore();
  const [searchParams] = useSearchParams();
  const { login } = useGlobalContext();
  const { validUser } = useToken();
  const { getMyChannel } = useRootContext();
  const { navigate } = useNavigation();
  const redirectUri = `${ROOT_HOST}login/kakao`;
  const { redirect } = LoginPageController();

  const [managerLogin, setManagerLogin] = useState<boolean>(false);

  const handleClickKakaoJoin = async () => {
    const paramsTemp = searchParams.get('action');
    const params = paramsTemp || '';
    // eslint-disable-next-line max-len
    const payload = `?grant_type=authorization_code&client_id=${KAKAO_REST_API_KEY}&redirect_uri=${redirectUri}&client_secret=${KAKAO_CLIENT_SECRET_KEY}&response_type=code&state=${params}`;
    window.location.href = `https://kauth.kakao.com/oauth/authorize${payload}`;
  };

  const handleClickGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const googleResult = (await authStore.googleLogin(tokenResponse.access_token)) as any;
      if (googleResult.status !== 200) {
        uiStore.universalModal.show({
          title: t('A100'),
          useDefaultButtons: true,
          modalOption: {
            style: { maxWidth: 400, maxHeight: 300, height: 'auto' },
          },
          children: <>{googleResult.data.errorMessage}</>,
        });
      } else {
        login();
        getMyChannel();
        const expired = isExpired(googleResult.data.accessToken as string);
        if (!expired) {
          redirect();
        }
      }
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  /** 로그인이 되어 있으면 my페이지로 강제 이동 */
  useEffect(() => {
    if (validUser()) {
      navigate('/my');
    }
  }, []);

  return (
    <>
      <DetailPageHeader title="로그인" />
      <RootStyle>
        <SignUpTitleStyle>{t('A95')}</SignUpTitleStyle>
        <div style={{ flexGrow: 1 }}>
          <div
            style={{ display: 'flex', justifyContent: 'center', fontWeight: 300, marginBottom: 10 }}
          >
            {t('A96')}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0 20px',
            }}
          >
            <SNSTypeStyle
              onClick={() => {
                handleClickKakaoJoin();
              }}
            >
              <LogoImageStyle src="/images/kakao_logo.svg" />
              카카오 로그인
            </SNSTypeStyle>
            <SNSTypeStyle
              onClick={() => {
                handleClickGoogleLogin();
              }}
            >
              <LogoImageStyle src="/images/google_logo.svg" />
              구글 로그인
            </SNSTypeStyle>
          </div>
          <div style={{ marginTop: 30 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 300,
                marginBottom: 10,
              }}
            >
              {managerLogin ? '아이디로 로그인하기' : t('A97')}
            </div>
            {managerLogin === true ? <EmailLogin type="manager" /> : <EmailLogin type="normal" />}
          </div>
          <LoginStyle style={{ marginTop: 20 }}>
            {t('A98')}
            <div style={{ marginLeft: 15 }}>
              <ATagStyle color={'#3e64ea'} href={`/sign-up?action=${searchParams.get('action')}`}>
                {t('A99')}
              </ATagStyle>
            </div>
          </LoginStyle>
          <LoginStyle>
            비밀번호를 잊어버리셨나요?
            <div style={{ marginLeft: 15 }}>
              <ATagStyle color={'#3e64ea'} href="/forgot-password">
                {t('A45')}
              </ATagStyle>
            </div>
          </LoginStyle>
          <ManagerLoginContainer>
            <Checkbox
              onClick={() => {
                setManagerLogin(!managerLogin);
              }}
              style={{
                color: '#011208',
              }}
            />
            매니저 로그인
          </ManagerLoginContainer>
        </div>
      </RootStyle>
    </>
  );
});

const RootStyle = styled(ManagementLayout)`
  /* min-height: calc(100vh - 100px); */
`;

const ManagerLoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  font-size: 14px;
`;

export default LoginPage;
