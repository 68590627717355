import React, { useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Row } from 'components/commons/layouts';
import { PostDto } from 'services/data-contracts';
import { getFeedThmbnailUrl, stripedSting } from 'components/items/commonLogic';
import { useStore } from 'stores/StoreHelper';
import { OhmossButton } from 'resources/controls/OhmossButton';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import boardCommonLogics from 'features/board/logic/boardCommonLogic';

const AdPostItem = observer(
  ({
    post,
    useAdmin,
    onClickPost,
    onClickDeal,
    onShowMore,
  }: {
    post: PostDto;
    useAdmin?: boolean;
    onClickPost: () => void;
    onClickDeal?: () => void;
    onShowMore: () => void;
  }) => {

    const getButtons = () => {
      switch (post.ad?.status) {
        case 'WAITING':
          return (
            <OhmossButton type="box" style={{ color: '#638DFF', flex: 1 }}>
              광고대기중
            </OhmossButton>
          );
        case 'EXPIRED':
          return (
            <OhmossButton type="box" style={{ color: '#638DFF', flex: 1 }} onClick={() => { }}>
              만료됨
            </OhmossButton>
          );
        case 'ONGOING':
          return (
            <OhmossButton type="disabled" style={{ color: '#638DFF', flex: 1, cursor: 'default' }}>
              광고진행중
            </OhmossButton>
          );
        case 'BLOCKED':
          return (
            <OhmossButton type="box" style={{ color: '#638DFF', flex: 1 }}>
              차단됨
            </OhmossButton>
          );
        case 'CANCELED':
          return (
            <OhmossButton type="box" style={{ color: '#638DFF', flex: 1 }}>
              취소됨
            </OhmossButton>
          );
        default:
          return (
            <OhmossButton
              type="box"
              style={{ color: '#638DFF', flex: 1 }}
              onClick={() => {
                if (onClickDeal) {
                  onClickDeal();
                }
              }}
            >
              딜 광고하기
            </OhmossButton>
          );
      }
    };
    return (
      <GridItemStyle>
        <Row
          style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}
          onClick={() => {
            onClickPost();
            // const { currentChannel } = myChannelStore;
            // const newPost = { ...post };
            // if (newPost.channel === null) {
            //   newPost.channel = currentChannel as ChannelDto;
            // }
            // if (post.board) {
            //   handleClickPost(newPost, post.board);
            // }
          }}
        >
          <ImageContainerStyle>
            <img src={getFeedThmbnailUrl(post?.items?.[0]?.file?.publicUrl, '150')} />
          </ImageContainerStyle>
          <Row style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <div style={{ paddingLeft: 10 }}>
              <MainTitleTextStyle>
                {post.ad?.status === 'ONGOING' && <OnAirStyle>ONAIR</OnAirStyle>}
                {post.title}
              </MainTitleTextStyle>
              <ContentTextStyle>{stripedSting(post.content, 70)}</ContentTextStyle>
            </div>
          </Row>
        </Row>
        {useAdmin && (
          <Row style={{ marginTop: 10, gap: 5 }}>
            {getButtons()}
            <OhmossButton type="box" style={{ width: 48 }}
              onClick={() => {
                onShowMore();
              }}
            >
              <MoreHorizIcon
                style={{ color: '#000000' }}

              />
            </OhmossButton>
          </Row>
        )}
      </GridItemStyle>
    );
  },
);

const ImageContainerStyle = styled.div`
  width: 100px;
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  > img {
    max-width: unset !important;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const GridItemStyle = styled.div`
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 5px;
  transition: all 0.2s ease;
  &:hover {
    background: #fff;
  }
  @media ${(props) => props.theme.media.mobile} {
    padding-left: 5px 15px;
  }
`;

const MainTitleTextStyle = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const ContentTextStyle = styled.div`
  font-size: 13px;
  color: #666;
`;

const OnAirStyle = styled.div`
  background: #f91959;
  display: inline;
  padding: 0 5px;
  font-size: 10px;
  color: #fff;
  margin-right: 5px;
  border-radius: 6px;
`;

export default AdPostItem;
