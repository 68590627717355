import React from 'react';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { ListBoxTemplate, ListBoxWrapper } from 'features/csTown/commonComponents/styles';
import { useStore } from 'stores/StoreHelper';

import SBConversation from '@sendbird/uikit-react/GroupChannel';
import styled from 'styled-components';
import ChannelListBox from 'components/commons/chat/TalkChannelListBox';

const MyInquriesContainer = ({
  inquiries,
  channel,
  onJoinChannel,
}: {
  inquiries?: GroupChannel[];
  channel?: string;
  onJoinChannel?: (channel: GroupChannel) => void;
}) => {
  const { uiStore } = useStore();

  const handleClickChannel = (channel: GroupChannel) => {
    uiStore.modal.show({
      modalType: 'modal',
      style: { height: '95%' },
      children: (
        <ConversationStyle>
          <SBConversation channelUrl={channel?.url || ''} />
        </ConversationStyle>
      ),
    });
  };
  return (
    <>
      <ListBoxWrapper>
        <ListBoxTemplate style={{ padding: '0 10px' }}>
          <ChannelListBox inquiries={inquiries} onJoinChannel={handleClickChannel} />
        </ListBoxTemplate>
      </ListBoxWrapper>
    </>
  );
};

const ConversationStyle = styled.div`
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #fff;
`;
export default MyInquriesContainer;
