import React from 'react';
import { useSearchParams } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { toast } from 'react-toastify';
import TopWrapper from 'features/mainFeatures/auth/components/TopWrapper';
import TopWrapperLeft from 'features/mainFeatures/auth/components/TopWrapperLeft';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { OhmossButton } from 'resources/controls/OhmossButton';

const VerifyPage = observer(() => {
  const { t } = useTranslation();
  const { authStore } = useStore();
  const { navigate } = useNavigation();

  const [searchParams] = useSearchParams();

  const handleClickConfirm = async () => {
    const token = searchParams.get('token');
    if (token) {
      const response: any = await authStore.confirmJoin(token);
      if (response?.data?.errorCode) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        const { errorCode, errorMessage } = response.data;
        if (errorCode === 'USER.ALREADY_VERIFIED') {
          toast(t('A266'), {
            type: 'error',
            position: 'top-center',
            autoClose: 2000,
            onClose: () => {
              navigate('/login');
            },
            closeOnClick: false,
            pauseOnHover: false,
            closeButton: false,
          });
        } else if (errorCode === 'CONFIRMATION_TOKEN.TOKEN_EXPIRED') {
          // TODO: 정확한 에러 코드를 알아내어 분기해줘야 함 (ex: 인증 메일 시간 만료)
          toast(errorMessage, {
            type: 'error',
            position: 'top-center',
            autoClose: 2000,
            onClose: () => {
              navigate('/unverified');
            },
            closeOnClick: false,
            pauseOnHover: false,
            closeButton: false,
          });
        } else {
          toast(errorMessage, {
            type: 'error',
            position: 'top-center',
            autoClose: 2000,
            closeOnClick: false,
            pauseOnHover: false,
            closeButton: false,
          });
        }
      }
    } else {
      toast(t('informationDoesNotExist'));
    }
  };

  return (
    <div>
      <EmailAuthContainer>
        <TopWrapper style={{ width: '100vw' }}>
          <TopWrapperLeft>
            <ArrowBackIcon
              style={{
                color: '#222222',
                paddingRight: '16px',
                width: '18px',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/sign-up');
              }}
            />
            <div>{t('signUp')}</div>
          </TopWrapperLeft>
        </TopWrapper>
        <EmailImg />
        <div>
          <Title> {t('A265')}</Title>
        </div>
        <GrayBox>
          <Text>{t('A264')}</Text>
        </GrayBox>
        <OhmossButton
          style={{ padding: '15px 60px', margin: '30px 0' }}
          onClick={handleClickConfirm}
        >
          {t('Authenticate')}
        </OhmossButton>
      </EmailAuthContainer>
    </div>
  );
});

const textCenter = css`
  text-align: center;
`;

const grayText = css`
  color: #666666;
  font-weight: 500;
  font-size: 14px;
`;

const EmailAuthContainer = styled.div`
  width: 100%;
  /* height: 100%; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  /* margin-top: 2em; */
  z-index: 100;
  background: white;
`;

const Title = styled.div`
  ${textCenter}
  color: #000;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 1em;
  margin-top: 1.5em;
`;

const Text = styled.div`
  ${textCenter}
  font-size: 14px;
  margin-bottom: 2em;
  @media screen and (max-width: 360px) {
    padding: 0 20px;
  }
`;

const GrayBox = styled.div`
  background: #f6f6f6;
  /* margin-top: 1em;
  margin: 0 auto; */
  margin: 1em 1em 0 1em;
  width: 400px;
  padding: 0.5em 2em;
  ${grayText}
  > ul {
    padding-inline-start: 2em;
    margin-right: 1em;
    margin-top: 0;
    color: #999999;
    font-weight: 400;
    line-height: 23px;
  }
  > ul > p {
    text-align: right;
    padding-top: 0;
  }
`;

const Img = styled.img.attrs((props) => ({
  alt: '',
}))``;

const EmailImg = styled(Img).attrs((props) => ({
  src: 'images/ohmoss_logo.svg',
}))`
  width: 200px;
  height: 110px;
  margin-top: 8em;
`;

export default VerifyPage;
