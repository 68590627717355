import React, { useEffect, useState } from 'react';
import { KAKAO_REST_API_KEY, KAKAO_CLIENT_SECRET_KEY } from 'stores/AppStore';
import { isExpired } from 'react-jwt';
import { useStore } from 'stores/StoreHelper';

import axios from 'axios';
import useNavigation from 'hooks/useNavigation';
import AuthStore from 'stores/AuthStore';

declare global {
  interface Window {
    Kakao: any;
  }
}

const useKakaoLogin = (code: string | null) => {
  const { navigate } = useNavigation();
  // eslint-disable-next-line max-len
  const payload = `?grant_type=authorization_code&client_id=${KAKAO_REST_API_KEY}&client_secret=${KAKAO_CLIENT_SECRET_KEY}&code=${code}`;
  const { uiStore, authStore } = useStore();

  const [userInfo, setKakaoUserInfo] = useState<any>();

  /**
   * @description 공통 카카오 로그인(이름 변경 필요)
   */
  const checkJoin = async () => {
    // eslint-disable-next-line max-len
    try {
      const kakaoResult = await axios.get(`https://kauth.kakao.com/oauth/token${payload}`);
      const apiResult = (await authStore.kakaoLogin(kakaoResult.data.access_token)) as any;
      if (apiResult.status !== 200) {
        uiStore.universalModal.show({
          title: '로그인 계정을 확인해주세요',
          useDefaultButtons: true,
          modalOption: { style: { maxWidth: 400, maxHeight: 300, height: 'auto' } },
          children: <>{apiResult.data.errorMessage}</>,
          onClose: () => {
            window.location.href = '/login';
          },
        });
      } else {
        const expired = isExpired(apiResult.data.accessToken as string);

        if (!expired) {
          window.Kakao.Auth.setAccessToken(kakaoResult.data.access_token);
          const kakaoMe = await window.Kakao.API.request({
            url: '/v2/user/me',
          });
          await setTokens(apiResult.data.accessToken, apiResult.data.refreshToken);
          setKakaoUserInfo(kakaoMe);
          return true;
        }
      }
    } catch (e) {
      if (e.response.data.error === 'invalid_grant') {
        alert('토큰이 만료되었습니다. 다시 시도 해주세요.');
        window.history.back();
      } else {
        uiStore.confirm.show({
          message: '아직 오모스의 회원이 아니시네요. 회원가입 하시겠습니까?',
          onConfirmed: () => {
            window.location.href = '/sign-up';
          },
          onCancel: () => {
            // 토큰이 유효하지 않은 경우이므로 사용자에게 메시지 표시 후 이동한다.
            window.location.href = '/login';
          },
        });
      }
    }
  };

  const checkSignup = async () => {
    try {
      const kakaoResult = await axios.get(`https://kauth.kakao.com/oauth/token${payload}`);
      const apiResult = (await authStore.kakaoLogin(kakaoResult.data.access_token, true)) as any;
      if (apiResult.status === 200) {
        uiStore.confirm.show({
          message: '이미 가입된 계정입니다. 로그인 하시겠습니까?',
          onConfirmed: async () => {
            await setTokens(apiResult.data.accessToken, apiResult.data.refreshToken);
            navigate('/');
          },
          onCancel: () => {
            navigate('/login');
          },
        });
      } else if (apiResult.status === 401) {
        getUserInfo(kakaoResult.data.access_token);
      } else if (apiResult.status === 400) {
        uiStore.confirm.show({
          message: (
            <div>
              <div>{apiResult.data.errorMessage}</div>
              <div>회원가입 페이지로 이동하시겠습니까?</div>
            </div>
          ),
          onConfirmed: async () => {
            // await setTokens(apiResult.data.accessToken, apiResult.data.refreshToken);
            navigate('/login');
          },
          onCancel: () => {
            navigate('/login');
          },
        });
      }
    } catch (e) {
      setKakaoUserInfo(null);
    }
  };

  const getUserInfo = async (kakaoAccessToken: string) => {
    window.Kakao.Auth.setAccessToken(kakaoAccessToken);
    const kakaoMe = await window.Kakao.API.request({
      url: '/v2/user/me',
    });
    kakaoMe.socialToken = kakaoAccessToken;
    setKakaoUserInfo(kakaoMe);
  };

  const setTokens = async (accessToken: string, refreshToken: string) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  };

  // useEffect(() => {
  //   checkJoin();
  // }, []);

  // return userInfo;
  return {
    checkJoin,
    checkSignup,
    getUserInfo,
    userInfo,
  };
};

export default useKakaoLogin;
