import React, { useEffect, useState } from 'react';
import { ProposalDto } from 'services/data-contracts';
import styled from 'styled-components';
import { Row } from 'components/commons/layouts';
import NoContent from 'components/commons/NoContent';
import ProposalController from 'controllers/ProposalController';
import { useStore } from 'stores/StoreHelper';
import CloseIcon from '@mui/icons-material/Close';

import ReceivedProposalContainer from '../components/ReceivedProposalContainer';
import ProposalBox from '../components/ProposalBox';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useMediaContext } from 'providers/useMediaContext';
import { AnimatedTabButton, TabItem } from 'components/commons/buttons/AnimatedTabButton';

const ProposalList = () => {
  const { myChannel } = useRootContext();
  const { uiStore } = useStore();
  const [sentInquiries, setSentInquiries] = useState<ProposalDto[]>();
  const [receivedInquiries, setReceivedInquiries] = useState<ProposalDto[]>();
  const [tabLabel, setTabValue] = useState<number>(1);

  const { getReceivedProposals, deleteProposalByReceipient, getSentProposals } =
    ProposalController();

  const getProposals = async () => {
    const receivedInquiriesTemp = (await getReceivedProposals()) as unknown as ProposalDto[];
    const sentInquiriesTemp = (await getSentProposals()) as unknown as ProposalDto[];

    const receivedInquiriesArr = receivedInquiriesTemp.filter(
      (proposal) => proposal.requestType === 'BLOCK',
    );
    const sentInquiriesArr = sentInquiriesTemp.filter(
      (proposal) => proposal.requestType === 'BLOCK',
    );
    setReceivedInquiries(receivedInquiriesArr);
    setSentInquiries(sentInquiriesArr);
  };

  const handleClickProposal = (proposal: ProposalDto) => {
    uiStore.modal.show({
      style: {
        maxHeight: '85%',
      },
      children: <ReceivedProposalContainer proposal={proposal} />,
    });
  };

  const deleteProposal = async (proposal: ProposalDto) => {
    if (proposal?.id !== undefined) {
      uiStore.confirm.show({
        message: '정말 삭제하시겠습니까?',
        onConfirmed: async () => {
          await deleteProposalByReceipient(proposal);
          getProposals();
        },
      });
    }
  };

  useEffect(() => {
    if (myChannel) getProposals();
  }, [myChannel]);

  return (
    <>
      <TabContainer>
        <AnimatedTabButton
          onClick={(item: any) => {
            setTabValue(Number(item.key));
          }}
          style={{ width: '100%' }}
        >
          <TabItem key={1}>받은문의</TabItem>
          <TabItem key={2}>보낸문의</TabItem>
        </AnimatedTabButton>
      </TabContainer>
      {tabLabel === 1 && (
        <div>
          {receivedInquiries?.map((inquiry) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InquiryStyle
                onClick={() => {
                  handleClickProposal(inquiry);
                }}
              >
                <Row style={{ justifyContent: 'space-between' }}>
                  <ProposalBox inquiry={inquiry} inquiryType="RECEIVED" />
                </Row>
              </InquiryStyle>
              <div style={{ marginLeft: 10, alignSelf: 'center' }}>
                <CloseIcon onClick={() => deleteProposal(inquiry)} />
              </div>
            </div>
          ))}
          {receivedInquiries?.length === 0 && (
            <NoContent style={{ marginTop: 200 }} title="받은 문의가" />
          )}
        </div>
      )}

      {tabLabel === 2 && (
        <div>
          {sentInquiries?.map((inquiry) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InquiryStyle
                onClick={() => {
                  handleClickProposal(inquiry);
                }}
              >
                <Row style={{ justifyContent: 'space-between' }}>
                  <ProposalBox inquiry={inquiry} inquiryType="SENT" />
                </Row>
              </InquiryStyle>
              <div style={{ marginLeft: 10, alignSelf: 'center' }}>
                <CloseIcon onClick={() => deleteProposal(inquiry)} />
              </div>
            </div>
          ))}
          {sentInquiries?.length === 0 && (
            <NoContent style={{ marginTop: 200 }} title="보낸 문의가" />
          )}
        </div>
      )}
    </>
  );
};

const InquiryStyle = styled.div`
  cursor: pointer;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin: 10px;
`;

const TabContainer = styled.div`
  @media ${(props) => props.theme.media.desk} {
    width: 400px;
  }
  @media ${(props) => props.theme.media.mobile} {
    position: sticky;
    top: 0px;
    width: 100%;
    padding: 10px;
    background-color: #f0f0f0;
    z-index: 1;
  }
`;

export default ProposalList;
