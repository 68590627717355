import React, { useEffect, useState } from 'react';
import theme from 'resources/styles/common/theme';
import { InquiryDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Row } from 'components/commons/layouts';
import { t } from 'i18next';

const SuggestionPage = () => {
  const { inquryStore, channelStore, myChannelStore, uiStore } = useStore();

  const [inquiryData, setInquiryData] = useState<InquiryDto>({});
  const [isActive, setIsActive] = useState<boolean>(false);

  const sendSuggestion = async () => {
    const channelId = myChannelStore.currentChannel?.id;
    if (!channelId) {
      return;
    }
    const result: any = await inquryStore.inquire(channelId, {
      ...inquiryData,
      receiveChannelName: channelStore.channel?.channelName,
    });
    if (!result?.status) {
      toast(t('A222'));
      setTimeout(() => {
        uiStore.universalModal.close();
      }, 700);
    } else {
      toast(t('A223'), { type: 'error' });
    }
  };

  return (
    <Container>
      <SuggestionContainer>
        <FormContainer>
          <Row style={{ justifyContent: 'space-between' }}>
            <Title>{t('A242')}</Title>
            <div style={{ marginLeft: 20 }}>500/({inquiryData.contents?.length || 0})</div>
          </Row>
          <Input
            maxLength={500}
            onChange={(e: any) => {
              if (e.target.value !== '') {
                setInquiryData({ ...inquiryData, contents: e.target.value });
                setIsActive(true);
              } else {
                setIsActive(false);
              }
            }}
            style={{ borderRadius: 16, height: '30vh' }}
          />
        </FormContainer>
        <SendBtn
          isActive={isActive}
          onClick={() => {
            sendSuggestion();
          }}
        >
          {t('A224')}
        </SendBtn>
        <NextTimeStyle
          onClick={() => {
            uiStore.universalModal.close();
          }}
        >
          {t('A225')}
        </NextTimeStyle>
      </SuggestionContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: calc(100% + 60px);
  background-color: #f1f1f1;
`;

const SuggestionContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 36px;
  padding: 36px 24px 0;
`;

const NextTimeStyle = styled.div`
  margin: auto;
  font-size: 14px;
  color: #929292;
  margin-top: 20px;
  cursor: pointer;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = styled.textarea`
  border-width: 0;
  border-radius: 16px;
  padding: 24px;
  resize: none;
  margin: 15px 0 0 0;
  font-size: 16px;
  text-align: flex-start;
`;

const Title = styled.text`
  color: #c3c9d1;
  font-size: 16px;
  font-weight: 600;
  line-hgeiht: 22px;
`;

const SendBtn = styled.button<{ isActive?: boolean }>`
  border-radius: 999px;
  border-width: 0;
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin: 10px auto;
  padding: 13px 0;
  width: 100%;
  max-width: 500px;
  pointer-events: ${(props) => (props.isActive ? 'all' : 'none')};
  background-color: ${(props) => (props.isActive ? theme.colors.defaultBackground : '#C3C9D1')};
`;

export default SuggestionPage;
