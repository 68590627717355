import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';

const GridSelector = observer(
  ({
    selectedNumber,
    onUpdate,
  }: {
    selectedNumber?: number;
    onUpdate: (selected: number) => void;
  }) => {
    const numbers = [1, 2, 3];
    const [selected, setSelected] = useState<number>(1);

    useEffect(() => {
      if (selectedNumber) setSelected(selectedNumber);
    }, [selectedNumber]);

    return (
      <>
        <GridSelectorContainerStyle>
          {numbers.map((number: number, i: number) => (
            <>
              <GridItemStyle
                key={number}
                on={selected > i}
                onClick={() => {
                  setSelected(i + 1);
                  onUpdate(i + 1);
                }}
              />
            </>
          ))}
        </GridSelectorContainerStyle>
      </>
    );
  },
);

const GridSelectorContainerStyle = styled.div`
  display: flex;
`;
const GridItemStyle = styled.div<{ on?: boolean }>`
  width: 30px;
  height: 30px;
  background: ${(props) => (props.on ? 'rebeccapurple' : '#c9c1d1')};
  border-radius: 4px;
  margin-right: 3px;
  cursor: pointer;
`;

export default GridSelector;
