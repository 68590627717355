import React, { ReactNode } from 'react';

import { LinkDto, LinkItemDto } from 'services/data-contracts';

import MyChannelAnalyticsStore from 'stores/MyChannelAnalytics';
import moment from 'moment';
import MyNotificationStore from 'stores/MyNotificationStore';
import MyDealStore from 'stores/MyDealStore';
import CustomerInquiryStore from 'stores/CustomerInquiryStore';
import { Context } from '../useOhmossContext';
import { useRootContext } from 'libs/hooks/useRootContext';

interface PageProviderProps {
  children: ReactNode | ReactNode[] | Element | Element[];
}

const DealsProvider = <T extends LinkDto, U extends LinkItemDto>({
  children,
}: PageProviderProps) => {
  const customerInquiryStore = new CustomerInquiryStore();
  const myChannelAnalyticsStore = new MyChannelAnalyticsStore();
  const myNotificationStore = new MyNotificationStore();
  const myDealStore = new MyDealStore();

  /** Primitive */

  const { myChannel } = useRootContext();

  /**
   * @description 인플루언서 회원이 받은 문의 조회
   * @returns
   */
  const getChannelInquiry = async () => {
    return await myDealStore.getReceivedAllInquiries(myChannel?.id as number);
  };

  /**
   * @description 일반 회원이 질의한 문의 목록 조회
   * @returns
   */
  const getCustomerInquiry = async () => {
    return await customerInquiryStore.getCustomerInquiries(myChannel?.id as number);
  };

  const getChannelDeals = async () => {
    return await myDealStore.getMyDeals(myChannel?.id as number);
  };

  const getChannelAnlytics = async () => {
    const yesterday = moment().add(-2, 'day').format('YYYY-MM-DD');
    const today = moment().add(-1, 'day').format('YYYY-MM-DD');
    return await myChannelAnalyticsStore.getAnalytics(myChannel?.id as number, yesterday, today);
  };

  const getChannelNotifications = async () => {
    const data = await myNotificationStore.getMyNotifications(myChannel?.id as number);
    return data;
  };

  const getReceivedInquiriesByDeal = async (dealId: number) => {
    return await myDealStore.getReceivedInquiriesByDeal(dealId as number, myChannel?.id as number);
  };

  /**
   * @description 인플루언서용 문의 쓰레드 조회
   * @param dealId
   * @param inquiryId
   * @returns
   */
  const getInquiryThreds = async (dealId: number, inquiryId: number) => {
    return await myDealStore.getInquiryThreads(myChannel?.id as number, dealId, inquiryId);
  };

  /**
   * @description 딜 상세 조회(인플루언서용)
   * @param dealId
   * @returns
   */
  const getDeal = async (dealId: number) => {
    return await myDealStore.getDeal(myChannel?.id as number, dealId as number);
  };

  /**
   * @deprecated
   * @description 일반 사용자용 문의 쓰레드 조회
   * @param dealId
   * @param inquiryId
   * @returns
   */
  const getCustomerInquiryThreds = async (inquiryId: number) => {
    return await customerInquiryStore.getCustomerInquiryThreads(inquiryId, myChannel?.id as number);
  };

  const getMyInquiryDetail = async (inquiryId: number) => {
    return await customerInquiryStore.getInquiryByInquiryId(
      inquiryId as number,
      myChannel?.id as number,
    );
  };

  return (
    <>
      {typeof children !== 'undefined' ? (
        <Context.Provider
          value={{
            getChannelInquiry,
            getCustomerInquiry,
            getMyInquiryDetail,
            getChannelDeals,
            getInquiryThreds,
            getCustomerInquiryThreds,
            getChannelAnlytics,
            getChannelNotifications,
            getReceivedInquiriesByDeal,
            getDeal,
          }}
        >
          {children}
        </Context.Provider>
      ) : (
        <></>
      )}
    </>
  );
};

export default DealsProvider;
