import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import useNavigation from 'hooks/useNavigation';

import BarChartIcon from '@mui/icons-material/BarChart';
import ChecklistIcon from '@mui/icons-material/Checklist';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { useStateContext } from 'libs/hooks/usePageState';
import { MenuBoxItemStyle, MenuBoxStyle } from '../commonComponents/styles';

const CsTownSellerLnb = ({ currentMenu }: { currentMenu?: string }) => {
  const { navigate } = useNavigation();
  const { setPageHeaderTitle } = useStateContext();

  return (
    <>
      <MenuBoxStyle>
        <MenuBoxItemStyle
          selected={currentMenu === 'dashboard'}
          onClick={() => {
            setPageHeaderTitle('통합 대시보드');
            navigate('/cstown/dashboard');
          }}
        >
          <IconButton>
            <BarChartIcon style={{ color: currentMenu === 'dashboard' ? '#fff' : 'unset' }} />
          </IconButton>
          통합 대시보드
        </MenuBoxItemStyle>
        <MenuBoxItemStyle
          selected={currentMenu === 'checklist'}
          onClick={() => {
            setPageHeaderTitle('체크리스트');
            navigate('/cstown/checklist');
          }}
        >
          <IconButton>
            <ChecklistIcon style={{ color: currentMenu === 'checklist' ? '#fff' : 'unset' }} />
          </IconButton>
          체크리스트
        </MenuBoxItemStyle>
        <MenuBoxItemStyle
          selected={currentMenu === 'partners'}
          onClick={() => {
            setPageHeaderTitle('파트너 관리');
            navigate('/cstown/partners');
          }}
        >
          <IconButton>
            <PersonAddAltOutlinedIcon
              style={{ color: currentMenu === 'partners' ? '#fff' : 'unset' }}
            />
          </IconButton>
          파트너 관리
        </MenuBoxItemStyle>
      </MenuBoxStyle>
    </>
  );
};

export default CsTownSellerLnb;
