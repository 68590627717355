import React, { CSSProperties, useEffect, useState } from 'react';
import styled from 'styled-components';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import TitleForm from 'components/items/forms/TitleForm';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import SimpleColorPickerWithPopOver from 'components/items/SimpleColorPickerWithPopOver';
import { Badge } from '@mui/material';
import DeleteButton from 'resources/controls/DeleteButton';
import { toast } from 'react-toastify';
import { StickerTypes } from './StickerTypes';
import StickerColorTheme from './StickerColorTheme';
import { Row } from '../layouts';
import Stickers from './Stickers';
import Text from '../Text';

const StickerEditor = ({
  stickers,
  targetSticker,
  onSelected,
}: {
  stickers: StickerTypes[];
  targetSticker?: StickerTypes;
  onSelected?: (StickerTypes: StickerTypes[]) => void;
}) => {
  const { uiStore } = useStore();

  const [selectedStickers, setSelectedStickers] = useState<StickerTypes[]>([]);
  const [selectedSticker, setSelectedSticker] = useState<StickerTypes>();

  const handleClickApply = () => {
    onSelected?.(selectedStickers);
    uiStore.bottomSheet.confirm(selectedStickers);
  };

  const handleOnClickTheme = (style: CSSProperties) => {
    // setLocalModel('internalStyle', style);
    if (selectedSticker) {
      const target = selectedStickers.find((item) => item.seq === selectedSticker?.seq);
      if (target) {
        target.internalStyle = style;
        setSelectedStickers([...selectedStickers]);
      }
    } else {
      toast('먼저 스티커를 선택해주세요.', { type: 'warning' });
    }
  };

  const handleClickDelete = (sticker: StickerTypes) => {
    const target = selectedStickers.find((item) => item.seq === sticker.seq);
    if (target) {
      setSelectedStickers(selectedStickers.filter((item) => item.seq !== sticker.seq));
    }
    setSelectedSticker({ label: '' });
  };

  const handleClickEmoji = () => {
    uiStore.universalModal.show({
      children: <Picker data={data} onEmojiSelect={handleClickEmoji} />,
    });
  };

  const getSticker = (sticker: StickerTypes) => {
    const Temp = sticker.component;
    return (
      <Row>
        <Badge color={selectedSticker?.seq === sticker.seq ? 'secondary' : 'default'} variant="dot">
          <div
            onClick={() => {
              // setLocalModel({});
              setSelectedSticker(sticker);
            }}
          >
            <Temp style={sticker?.internalStyle} text={sticker?.label} />
          </div>
        </Badge>
        <DeleteButton
          onClick={() => {
            handleClickDelete(sticker);
          }}
        />
      </Row>
    );
  };

  useEffect(() => {
    if (stickers) {
      setSelectedStickers(stickers);
    }
  }, [stickers]);

  useEffect(() => {
    if (targetSticker) {
      setSelectedSticker(targetSticker);
    }
  }, [targetSticker]);

  return (
    <div style={{ padding: '0 20px 20px 20px', width: '100%' }}>
      <ItemSectionStyle>
        <ItemSectionTitle>스티커 목록</ItemSectionTitle>
        <Text type="H4" buttonRole="DESCRIPTION">
          목록에서 스티커 종류를 선택 해주세요.
        </Text>
        <ItemContent>
          <Stickers
            onClick={(sticker: StickerTypes) => {
              if (selectedStickers.length < 10) {
                const maxValue =
                  selectedStickers.length === 0
                    ? 0
                    : Math.max(...selectedStickers?.map((item) => item.seq ?? 0));

                const targetSticker = {
                  ...sticker,
                  seq: maxValue + 1,
                  label: 'sample',
                  internalStyle: { fill: '#fff', stroke: '#05C755', background: '#fff' },
                };
                setSelectedStickers(selectedStickers.concat(targetSticker));
                setSelectedSticker(targetSticker);
              } else {
                toast('스타커는 최대 10개까지 선택 가능합니다.');
              }
            }}
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>선택된 스티커</ItemSectionTitle>
        <ItemContent>
          <Row
            style={{
              gap: 10,
              minHeight: 50,
              alignContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {selectedStickers?.map((sticker: StickerTypes) => (
              <div style={{ display: 'inline-block' }}>{getSticker(sticker)}</div>
            ))}
          </Row>
        </ItemContent>
        {/* {localModel && <div style={{ display: 'inline-block' }}>{getSticker()}</div>} */}
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle style={{ marginBottom: 10 }}>
          <div>스티커 내용</div>
          {/* <div onClick={handleClickEmoji}>이모지</div> */}
        </ItemSectionTitle>
        <TitleForm
          initialValue={selectedSticker?.label}
          onChangeValue={(value: string) => {
            const target = selectedStickers?.find((item) => item.seq === selectedSticker?.seq);
            if (target) {
              target.label = value;
              setSelectedStickers([...selectedStickers]);
            }
          }}
        />
      </ItemSectionStyle>
      <div style={{ marginBlock: 20 }} />
      <ItemSectionStyle>
        <ItemSectionTitle>테마 선택</ItemSectionTitle>
        <SelectedStickersWrapper>
          <StickerColorTheme sticker={selectedSticker} onClick={handleOnClickTheme} />
        </SelectedStickersWrapper>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>색상 커스터마이징</ItemSectionTitle>
        <ItemContent>
          <Row style={{ width: 'unset', gap: 20 }}>
            <div style={{ textAlign: 'center' }}>
              <div>배경색상</div>
              <div>
                <SimpleColorPickerWithPopOver
                  useGradient
                  defaultValue={selectedSticker?.internalStyle?.background}
                  onColorChanged={(color: string) => {
                    const target = selectedStickers.find(
                      (item) => item.seq === selectedSticker?.seq,
                    );
                    if (
                      (target && selectedSticker?.stickerType !== 'TypeE') ||
                      (target && selectedSticker?.stickerType !== 'TypeF')
                    ) {
                      target.internalStyle = {
                        ...target.internalStyle,
                        background: color,
                        fill: color,
                      };
                      setSelectedStickers([...selectedStickers]);
                    }
                  }}
                />
              </div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <div>텍스트색상</div>
              <div>
                <SimpleColorPickerWithPopOver
                  useGradient
                  defaultValue={selectedSticker?.internalStyle?.background}
                  onColorChanged={(color: string) => {
                    const target = selectedStickers.find(
                      (item) => item.seq === selectedSticker?.seq,
                    );
                    if (target) {
                      target.internalStyle = { ...target.internalStyle, color };
                      setSelectedStickers([...selectedStickers]);
                    }
                  }}
                />
              </div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <div>경계선색상</div>
              <div>
                <SimpleColorPickerWithPopOver
                  useGradient
                  defaultValue={selectedSticker?.internalStyle?.borderColor}
                  onColorChanged={(color: string) => {
                    const target = selectedStickers.find(
                      (item) => item.seq === selectedSticker?.seq,
                    );
                    if (
                      (target && selectedSticker?.stickerType === 'TypeA') ||
                      (target && selectedSticker?.stickerType === 'TypeB')
                    ) {
                      target.internalStyle = {
                        ...target.internalStyle,
                        border: `1px solid ${color}`,
                        borderColor: color,
                      };
                      setSelectedStickers([...selectedStickers]);
                    } else if (target && selectedSticker?.stickerType === 'TypeC') {
                      target.internalStyle = {
                        ...target.internalStyle,
                        borderLeft: 'none',
                        borderRight: 'none',
                        borderTop: `1px solid ${color}`,
                        borderBottom: `1px solid ${color}`,
                        stroke: color,
                      };
                      setSelectedStickers([...selectedStickers]);
                    } else if (
                      (target && selectedSticker?.stickerType === 'TypeE') ||
                      (target && selectedSticker?.stickerType === 'TypeF')
                    ) {
                      target.internalStyle = {
                        ...target.internalStyle,
                        borderLeft: 'none',
                        borderRight: 'none',
                        border: `1px solid ${color}`,
                        stroke: color,
                      };
                      setSelectedStickers([...selectedStickers]);
                    }
                  }}
                />
              </div>
            </div>
          </Row>
        </ItemContent>
      </ItemSectionStyle>
      <OhmossButton style={{ marginTop: 20 }} onClick={handleClickApply}>
        확인
      </OhmossButton>
    </div>
  );
};

const SelectedStickersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 10px 0;
`;

export default StickerEditor;
