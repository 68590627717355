import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { useStore } from 'stores/StoreHelper';
import { Row } from 'components/commons/layouts';

import styled from 'styled-components';

const SaveButtonWrapper = ({
  children,
  isValid,
  saveText,
  isNew,
  reset,
  useCancelButton,
  onSave,
}: {
  children: any;
  isValid: boolean;
  saveText?: string;
  reset?: boolean;
  isNew: boolean;
  useCancelButton?: boolean;
  onSave: () => void;
}) => {
  const { uiStore } = useStore();
  /**
   * @description 기본값 설정을 위한 모델
   * @description 각 Input control의 DefaultValue와 동일한 의미임.
   */
  const [isValidValue, setIsValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClickSave = async () => {
    if (typeof onSave === 'function') {
      setIsLoading(true);
      onSave();
    }
  };

  useEffect(() => {
    if (reset) {
      setIsValid(true);
      setIsLoading(false);
    }
  }, [reset]);

  useEffect(() => {
    setIsValid(isValid);
  }, [isValid]);

  return (
    <>
      {children}
      <Row
        style={{
          marginTop: 50,
          gap: '15px',
        }}
      >
        <BtnStyle isValid={isValidValue} style={{ flex: 1 }}>
          <LoadingButton
            onClick={handleClickSave}
            disabled={!isValidValue}
            loading={isLoading}
            loadingPosition="end"
          >
            {isNew ? saveText || '적용' : '수정'}
          </LoadingButton>
        </BtnStyle>
        <>
          {useCancelButton !== false && (
            <CancelBtnStyle style={{ flex: 1 }}>
              <Button
                onClick={() => {
                  if (uiStore.universalModal.modalShow) {
                    uiStore.universalModal.close();
                  }
                  if (uiStore.modal.modalShow) {
                    uiStore.modal.close();
                  }
                  if (uiStore.bottomSheet.modalShow) {
                    uiStore.bottomSheet.close();
                  }
                }}
              >
                취소
              </Button>
            </CancelBtnStyle>
          )}
        </>
      </Row>
    </>
  );
};

const BtnStyle = styled.div<{ isValid?: boolean; isMobile?: boolean }>`
  > button {
    font-size: 16px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    border-radius: 8px;
    padding: 10px 40px;
    background: ${(props) => (props.isValid ? '#11C668' : '#a9dec2')};
    color: #fff !important;
    &:hover {
      background-color: #078544;
    }
  }
`;

const CancelBtnStyle = styled.div<{ isValid?: boolean; isMobile?: boolean }>`
  > button {
    font-size: 16px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    border-radius: 8px;
    padding: 10px 40px;
    color: #474747;
    border: 1px solid #e0e0e0;
    &:hover {
      color: #11c668;
    }
  }
`;

export default SaveButtonWrapper;
