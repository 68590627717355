import React, { useEffect, useState, CSSProperties } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import { fontList } from 'resources/definitions/Definitions';
import { FormControl, Select, MenuItem } from '@mui/material';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { getBrightnessType } from 'libs/helper/utils';
import { MyStyle } from 'types/CommonTypes';
import { MyStyleTypes } from 'features/management/context/providers/ContextTypes';
import { useOhmossContext } from 'features/management/context/useOhmossContext';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ColorPickerInstance from 'components/items/ColorPickerInstance';
import useSetState from 'libs/hooks/useSetState';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';

type CommonProps = {
  initialValue?: CSSProperties;
  useTextEffect?: boolean;
  onChange?: (value: CSSProperties) => void;
};
const CommonFontSelect = ({ initialValue, useTextEffect, onChange }: CommonProps) => {
  const defaultFont = fontList.ko[0].value;
  const { uiStore } = useStore();
  const [formats, setFormats] = useState<string[] | undefined>();
  const [style, setStyle] = useSetState<CSSProperties>();
  const { serviceModel } = useOhmossContext<MyStyleTypes<MyStyle>>();

  const handleFormat = (
    event: React.MouseEvent<HTMLElement> | undefined,
    newFormats: string[] | undefined,
  ) => {
    if (typeof newFormats !== 'undefined') {
      setFormats(newFormats);
      const newStyle: CSSProperties = {};

      const bold = newFormats.find((format) => format === 'bold');
      const italic = newFormats.find((format) => format === 'italic');
      const underline = newFormats.find((format) => format === 'underline');

      newStyle.fontWeight = bold ? 700 : 400;
      newStyle.fontStyle = italic ? 'italic' : 'unset';
      newStyle.textDecoration = underline ? 'underline' : 'none';
      setStyle({ ...serviceModel?.textStyle, ...newStyle });
    }
  };

  const handleShow = () => {
    uiStore.universalModal.show({
      title: '색상선택',
      bottomSheetOptions: { blocking: false, expandOnContentDrag: false, topPosition: 0 },
      modalOption: { style: { width: 300, height: 700 } },
      children: (
        <>
          <ColorPickerInstance
            useGradient
            onClose={() => {
              uiStore.universalModal?.close();
            }}
            onColorChanged={(color: string) => {
              setStyle('color', color);
            }}
          />
        </>
      ),
    });
  };

  useEffect(() => {
    if (style && onChange) {
      onChange(style);
    }
  }, [style]);

  useEffect(() => {
    if (initialValue) {
      // let css: Partial<CSSProperties> = {};
      // if (typeof initialValue === 'string') {
      //   css = JSON.parse(initialValue as string);
      // }
      const newArray = [
        initialValue.fontWeight === 700 ? 'bold' : undefined,
        initialValue.fontStyle === 'italic' ? 'italic' : undefined,
        initialValue.textDecoration === 'underline' ? 'underline' : undefined,
      ].filter((arr): arr is string => arr !== undefined);

      if (typeof newArray !== 'undefined') {
        handleFormat(undefined, newArray);
      }
      setStyle(initialValue);
    } else {
      setStyle({ fontFamily: defaultFont });
    }
  }, [initialValue]);

  return (
    <>
      <ItemSectionStyle style={{}}>
        <ItemSectionTitle>글자체</ItemSectionTitle>
        <ItemContent>
          <Row style={{ gap: 20 }}>
            <FormControl fullWidth>
              <Select
                value={style?.fontFamily ?? defaultFont}
                onChange={(e: any) => {
                  setStyle('fontFamily', e.target.value);
                }}
              >
                {fontList.ko.map((font: any) => (
                  <MenuItem value={font.value}>{font.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <>
              {useTextEffect === true && (
                <>
                  <div>
                    <ToggleButtonGroup
                      value={formats}
                      onChange={(e: any, value: []) => {
                        handleFormat(e, value);
                      }}
                      aria-label="text formatting"
                    >
                      <ToggleButton value="bold" aria-label="bold">
                        <FormatBoldIcon />
                      </ToggleButton>
                      <ToggleButton value="italic" aria-label="italic">
                        <FormatItalicIcon />
                      </ToggleButton>
                      <ToggleButton value="underline" aria-label="underline">
                        <FormatUnderlinedIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  <Row
                    onClick={handleShow}
                    style={{
                      width: 'unset',
                      cursor: 'pointer',
                      background:
                        getBrightnessType(style?.color || '#000000') === 'bright'
                          ? '#efefef'
                          : '#ffffff',
                      padding: 10,
                      borderRadius: 8,
                    }}
                  >
                    <ColorLensOutlinedIcon style={{ color: style?.color }} />
                    <ArrowDropDownIcon />
                  </Row>
                </>
              )}
            </>
          </Row>
        </ItemContent>
      </ItemSectionStyle>
    </>
  );
};

export default CommonFontSelect;
