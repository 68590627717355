import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AdminMainPage } from '.';
import { isMobile } from 'react-device-detect';
import styled, { keyframes } from 'styled-components';
import ViewSimulator from 'features/adminFeatures/simulator/containers/ViewSimulator';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const AdminContainerPage = observer(() => {
  const [simulatorMode, setSimulatorMode] = useState<boolean>(false);

  return (
    <>
      {isMobile ? (
        <>
          <Swiper
            style={{ minHeight: '100vh' }}
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={() => {
              setSimulatorMode(!simulatorMode);
            }}
          >
            <SwiperSlide>
              <AdminMainPage />
            </SwiperSlide>
            <SwiperSlide style={{ height: '100vh' }}>
              <ViewSimulator />
            </SwiperSlide>
          </Swiper>
          <BtnStyle simulatorMode={simulatorMode}>
            {simulatorMode ? (
              <>
                <KeyboardDoubleArrowLeftIcon /> Links
              </>
            ) : (
              <>
                <KeyboardDoubleArrowRightIcon /> Simulator
              </>
            )}
          </BtnStyle>
        </>
      ) : (
        <AdminMainPage />
      )}
    </>
  );
});

const leftLoop = keyframes`
    0%{
    	right:0px;
    }
    100%{
        right:5px;    
    }
`;

const rightLoop = keyframes`
  0%{
    	right:5px;
    }
    100%{
        right:0px;    
    }
`;

const BtnStyle = styled.div<{ simulatorMode?: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  z-index: 100;

  right: 0;
  left: 0;
  width: 150px;
  margin: 0 auto;
  background-color: #11c668;
  border-radius: 20px;
  color: white;
  padding: 5px 0;
  font-weight: 600;
  align-items: center;
  margin-top: 60px;
  > svg {
    position: relative;
    animation: 0.8s 3;
    animation-name: ${(props) => (props.simulatorMode ? leftLoop : rightLoop)};
    margin-right: 5px;
  }
`;

export default AdminContainerPage;
