import ManagementLayout from 'features/management/components/common/ManagementLayout';
import React from 'react';
import styled from 'styled-components';

const MembershipTerms = () => {
  return (
    <ManagementLayout>
      <MainContainer>
        <Title>{'<회원 본인용으로 구매시>'}</Title>
        <Bold style={{ marginTop: 18 }}>{'>구매한 멤버십은 즉시 사용이 시작됩니다'}</Bold>
        <Bold style={{ marginTop: 18 }}>{'>멤버십 업그레이드'}</Bold>
        <SubText>
          1. 변경날짜로부터 기존 결제일까지의 금액 환불
          <br /> 2. 변경날짜로부터 상위멤버십 시작 및 결제
        </SubText>
        <SubText style={{ marginTop: 18 }}>
          예) FREE ➡️ 셀러PRO
          <br />
          <span style={{ fontWeight: 500, fontSize: 12, textDecoration: 'underline' }}>
            멤버십구독
          </span>
          에서 셀러PRO를 선택후 결제방식을
          <br />
          고른 후 결제를 하면 업그레이드됩니다.
        </SubText>
        <SubText style={{ marginTop: 18 }}>
          예) 셀러PRO ➡️ 셀러PREMIUM <br />
          <span style={{ fontWeight: 500, fontSize: 12, textDecoration: 'underline' }}>
            멤버십구독
          </span>
          에서 셀러PREMIUM을 선택후 결제방식을 고른 후<br /> 결제를 하면 업그레이드됩니다. 프로에서
          프리미엄으로 업그레이드
          <br /> 하는 경우 프로의 남은 기간에 대한 환불이 이루어집니다.
        </SubText>
        <Bold style={{ marginTop: 18 }}>{'>멤버십 다운그레이드'}</Bold>
        <SubText>
          1. 변경날짜이후 기존결제일까지의 금액 환불
          <br />
          (기존 할인된 금액과 위약수수료를 제한 후 환불)
          <br /> 2. 변경날짜로부터 하위멤버십 시작 및 결제
        </SubText>
        <SubText style={{ marginTop: 18 }}>
          예) 셀러PREMIUM ➡️ 셀러PRO
          <br />
          멤버십구독에서 셀러PRO을 선택후 결제방식을 고른 후 결제를 하면 다운그레이드됩니다.
        </SubText>
        <SubText style={{ marginTop: 18 }}>
          프리미엄에서 프로로 다운그레이드 하는 경우 프리미엄 서비스를 사용하는 기간동안 할인된
          금액과 위약수수료를 제한 나머지 시용일에 대한 금액을 환불해드립니다.
        </SubText>
        <Bold style={{ marginTop: 18 }}>{'>멤버십 해지'}</Bold>
        <SubText>구독 멤버십을 해지하게 되면, 즉시 결제가 해지됩니다</SubText>
        <SubText style={{ marginTop: 18 }}>예) 셀러PRO or 셀러PREMIUM ➡️ FREE</SubText>
        <SubText style={{ marginTop: 18 }}>
          ✔️ 주의사항
          <br />
          이용중인 구독 멤버십이 즉시 해지되며, 유료서비스 이용 제공이
          <br /> 즉시 중단됩니다. 이점을 유의하셔서 해지준비를 하시기 바랍니다.
          <br /> 해지후 15일이내에 멤버십 재 구독시 기존 데이터를 사용하실 수 <br />
          있습니다 (15일 이후에는 데이터 복구가 불가능합니다) <br />
          해당일로부터 일할 계산되어 환불되어 집니다. 멤버십기간내에 <br />
          해지시 위약수수료가 발생하며, 환불시 위약수수료를 제한 후 환불해 드립니다.
        </SubText>

        <Title style={{ marginTop: 18 }}>{'<선물용으로 구매시>'}</Title>
        <SubText style={{ marginTop: 18 }}>
          {'> 선물받은 멤버십은 선불용 교환권으로 사용함과 동시에 환불이 불가능합니다.'}
        </SubText>
        <SubText style={{ marginTop: 18 }}>
          {
            '> 구매자는 최초 유효기간(3개월) 이내에 [선물함 > 주문내역 > 상세보기] 에서 결제 취소 또는 결제금액 100%에 대한 환불을 요청할 수 있습니다.'
          }
        </SubText>
        <SubText style={{ marginTop: 18 }}>
          {'> 최초 유효기간 이후에는 수신자(선물을 받는 분)가 결제금액의 90%에'}
          {'대해서 환불 요청이 가능하며, [선물함 > 받은선물함 > 상세보기]에서 신청할 수'}
          {'있습니다. 단, 환불 신청 시 간단한 본인인증 및 본인계좌 확인이 필요합니다.'}
        </SubText>
        <SubText style={{ marginTop: 18 }}>
          {'> 수신자는 고객센터 및 선물함을 통해서 교환권의 수신거절을 요청할 수 있으며,'}
          {'이 경우 구매자에게 취소 및 환불에 대한 안내가 이루어집니다.'}
        </SubText>
        <SubText style={{ marginTop: 18 }}>
          {'> 환불요청은 미사용 멤버십 교환권에 대해서만 가능하며, '}
          {'사용한 멤버십 교환권에 대해서는 불가능합니다.'}
        </SubText>
        <SubText style={{ marginTop: 18 }}>
          {
            '# 멤버십은 미성년자가인 회원이 구매를 할 수 없으며, 만약 미성년자인 회원이 법정대리인의 동의 없이 상품 등을 구매하는 경우 본인 또는 법정대리인이 이를 취소할 수 있습니다.'
          }
        </SubText>
      </MainContainer>
    </ManagementLayout>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Title = styled.div`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem;
  text-decoration-line: underline;
`;

const Bold = styled.div`
  color: #000;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.125rem;
`;

const SubText = styled.div`
  color: #000;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.125rem;
`;

export default MembershipTerms;
