import React, { useState, useEffect } from 'react';
import { HexColorPicker, RgbaColorPicker, RgbaColor } from 'react-colorful';
import { TextField } from '@mui/material';
import { observer } from 'mobx-react';
import { Row } from 'components/commons/layouts';
import { colorGroups } from 'resources/definitions/Definitions';
import { checkColorFormat, hexToRgba, parseRgba, rgbToHex } from 'libs/helper/utils';
import styled from 'styled-components';

const SolidColorPickerWithOpacity = observer(
  ({
    defaultColor,
    onColorChanged,
  }: {
    defaultColor?: string;
    onColorChanged?: (color: string) => void;
  }) => {
    const [rgbaColor, setRgbaColor] = useState<RgbaColor>();
    const [hexColor, setHexColor] = useState<string>('');
    const [pickerType, setPickerType] = useState<'hex' | 'rgba'>('hex');

    const getRGBAInput = (target: keyof RgbaColor, value?: number) => {
      return (
        <div>
          <div style={{ textAlign: 'center', textTransform: 'uppercase' }}>{target}</div>
          <TextField
            value={value}
            onChange={(e: any) => {
              if (target) {
                setRgbaColor((prev: RgbaColor) => {
                  return { ...prev, [target]: parseInt(e.target.value, 10) };
                });

                const newColor = { ...rgbaColor, [target]: parseInt(e.target.value, 10) };
                onColorChanged?.(rgbaToString(newColor));
              }
            }}
            inputProps={{
              maxLength: 3,
            }}
          />
        </div>
      );
    };

    const rgbaToString = (rgba?: Partial<RgbaColor>) => {
      return 'rgba(' + rgba?.r + ',' + rgba?.g + ',' + rgba?.b + ',' + rgba?.a + ')';
    };

    useEffect(() => {
      if (pickerType) {
        if (pickerType === 'hex' && rgbaColor) {
          setHexColor(rgbToHex(rgbaColor));
        } else if (pickerType === 'rgba' && hexColor) {
          setRgbaColor(hexToRgba(hexColor));
        }
      }
    }, [pickerType]);

    useEffect(() => {
      if (typeof defaultColor !== 'undefined') {
        if (checkColorFormat(defaultColor) === 'HEX') {
          setHexColor(defaultColor);
        } else if (checkColorFormat(defaultColor) === 'RGBA') {
          setPickerType('rgba');
          setRgbaColor(parseRgba(defaultColor));
        } else {
        }
      }
    }, [defaultColor]);

    return (
      <>
        <Row style={{ gap: 10, justifyContent: 'flex-start', marginBottom: 20 }}>
          <PickerTypeStyle
            selected={pickerType === 'hex'}
            onClick={() => {
              setPickerType('hex');
            }}
          >
            HEX
          </PickerTypeStyle>
          <PickerTypeStyle
            selected={pickerType === 'rgba'}
            onClick={() => {
              setPickerType('rgba');
            }}
          >
            RGBA
          </PickerTypeStyle>
        </Row>
        {pickerType === 'hex' ? (
          <HexColorPicker
            color={hexColor}
            onChange={(color: string) => {
              setHexColor(color);
              onColorChanged?.(color);
            }}
          />
        ) : (
          <RgbaColorPicker
            color={rgbaColor ?? { r: 255, g: 0, b: 0, a: 1 }}
            style={{ margin: 'auto' }}
            onChange={(color: any) => {
              setRgbaColor(color);
              onColorChanged?.(rgbaToString(color));
            }}
          />
        )}
        <>
          <Row
            style={{
              padding: '0 10px',
              margin: '20px 0',
              alignItems: 'flex-end',
              alignContent: 'center',
            }}
          >
            <>
              {pickerType === 'hex' ? (
                <div>
                  <TextField
                    defaultValue={hexColor}
                    inputProps={{
                      maxLength: 7,
                    }}
                    value={hexColor}
                    style={{ width: '100%', height: 50 }}
                    onChange={(e: any) => {
                      setHexColor(e.target.value);
                      onColorChanged?.(e.target.value);
                    }}
                  />
                </div>
              ) : (
                <Row style={{ gap: 5 }}>
                  {getRGBAInput('r', rgbaColor?.r)}
                  {getRGBAInput('g', rgbaColor?.g)}
                  {getRGBAInput('b', rgbaColor?.b)}
                  {getRGBAInput('a', rgbaColor?.a)}
                </Row>
              )}
            </>
          </Row>
        </>
        <Row style={{ flexWrap: 'wrap', gap: 5 }}>
          {colorGroups.map((color: string) => (
            <div style={{ position: 'relative' }}>
              <ColorStyle
                useHover
                style={{
                  overflow: 'hidden',
                  background: color,
                  border: color === '#ffffff' ? '1px solid #ddd' : '1px solid transparent',
                }}
                onClick={() => {
                  if (pickerType === 'hex') {
                    setHexColor(color);
                    onColorChanged?.(color);
                  } else {
                    setRgbaColor(hexToRgba(color));
                    onColorChanged?.(rgbaToString?.(hexToRgba(color)));
                  }
                }}
              />
            </div>
          ))}
        </Row>
      </>
    );
  },
);

const ColorStyle = styled.div<{ selected?: boolean; useHover?: boolean }>`
  width: 50px;
  height: 50px;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  @media ${(props) => props.theme.media.mobile} {
    width: 50px;
    height: 50px;
  }
  &:hover {
    transition: all 0.1s ease-in-out;
    transform: ${(props) => (props.useHover ? 'scale(1.1)' : 'none')};
  }
`;

const PickerTypeStyle = styled.div<{ selected?: boolean }>`
  cursor: pointer;
  color: ${(props) => (props.selected ? '#000' : '#999')};
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
`;

export default SolidColorPickerWithOpacity;
