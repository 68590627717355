import React, { useEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import LinkComponent from 'components/items/admin/LinkComponent';
import styled from 'styled-components';
import { LinkModel } from 'types/CommonTypes';
import { LinkDto, SocialLinkDto } from 'services/data-contracts';
import { Row } from 'components/commons/layouts';

const ListOfSNSContainer = observer(
  ({
    draggable,
    onLinkSelect,
    onLinkDelete,
    onLinkEdit,
  }: {
    draggable?: boolean;
    onLinkSelect?: (SNS: SocialLinkDto) => void;
    onLinkDelete?: (SNS: SocialLinkDto) => void;
    onLinkEdit?: (SNS: SocialLinkDto) => void;
  }) => {
    const { t } = useTranslation();
    const { mySocialStore, myChannelStore } = useStore();
    const [links, setLinks] = useState<LinkModel[]>([]);
    const grid = 1;

    const reorder = (startIndex: number, endIndex: number): Array<LinkModel> => {
      const result = Array.from(links);
      const [dragItem] = result.splice(startIndex, 1);

      /** 서버 반영전, UI 변경 목적 */
      result.splice(endIndex, 0, dragItem);

      /** 옮긴 링크의 seq를 옮긴 index + 1 값으로 수정해준다 */
      dragItem.seq = endIndex + 1;

      /** 링크 순번 수정 */
      // if (dragItem.id) {
      //   socialStore.reOrderLinkSeq(dragItem?.id, dragItem);
      // }

      return result;
    };

    const onDragEnd = (result: DropResult): void => {
      if (!result.destination) {
        return;
      }
      const dragLinks: Array<LinkModel> = reorder(result.source.index, result.destination.index);
      setLinks(dragLinks);
    };

    /** (라이브러리) Droppable 스타일링 */
    const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
      padding: grid,
      width: '100%',
    });

    /** (라이브러리) Draggable 스타일링 */
    const getItemStyle = (
      isDragging: boolean,
      draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
    ): React.CSSProperties => ({
      ...draggableStyle,

      userSelect: 'none',
      padding: grid * 2,
      margin: `0 0 ${grid}px 0`,
      left: '0 !important',
      top: '0 !important',
    });

    const SocialChild = ({ sns }: { sns: SocialLinkDto }) => {
      return (
        <SocialChildStyle>
          <Row style={{ justifyContent: 'flex-start' }}>
            <img width="30" src={`./images/sns/${sns.socialType}.png`} alt="" />
            <div style={{ marginLeft: 10 }}>{sns.socialType}</div>
          </Row>
        </SocialChildStyle>
      );
    };

    const LinkComponetWrapper = (sns: SocialLinkDto) => {
      return (
        <LinkComponent
          item={sns}
          on={false}
          child={<SocialChild sns={sns} />}
          onSelect={() => {
            if (typeof onLinkSelect === 'function') onLinkSelect(sns);
          }}
          onDelete={() => {
            // eslint-disable-next-line react/destructuring-assignment
            if (typeof onLinkDelete === 'function') onLinkDelete(sns);
          }}
          onEdit={(sns: SocialLinkDto) => {
            if (typeof onLinkEdit === 'function') onLinkEdit(sns);
          }}
          onShowChange={(isShow: boolean) => {
            handleLinkShowChanged(sns, isShow);
          }}
        />
      );
    };

    const handleLinkShowChanged = async (SNS: SocialLinkDto, isShow: boolean) => {
      if (typeof SNS?.id !== 'undefined') {
        const sns = { ...SNS };
        sns.isActive = isShow;
        // const result = await socialStore.updateSocialLink(sns);

        // if (result) {
        //   socialStore.getSocials();
        //   // if (typeof meStore?.me?.userName !== 'undefined') {
        //   //   userStore.getUserInfo(meStore?.me?.userName);
        //   // }
        // }
      }
    };

    // useEffect(() => {
    //   if (myChannelStore.currentChannel) {
    //     mySocialStore.getSocials(myChannelStore.currentChannel?.id as number);
    //   }
    // }, [myChannelStore.currentChannel]);

    useEffect(() => {
      console.log(mySocialStore.socials);
    }, [mySocialStore.socials]);

    return (
      <>
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot): JSX.Element => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {mySocialStore.socials?.map((sns: SocialLinkDto, index: number) => (
                    <Draggable key={sns?.id} draggableId={String(sns?.id)} index={index}>
                      {(provided, snapshot): JSX.Element => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <LinkComponetWrapper {...sns} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>

        {/* {socialStore.socials?.length === 0 && (
          <EmptyContainer>{t('registerNotLink')}</EmptyContainer>
        )} */}
      </>
    );
  },
);

const EmptyContainer = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const SocialChildStyle = styled.div`
  display: flex;
`;

export default ListOfSNSContainer;
