import React, { useState } from 'react';
import styled from 'styled-components';
import TitleForm from 'components/items/forms/TitleForm';
import { OhmossButton } from 'resources/controls/OhmossButton';
import SemiDonutChart from './dashboard/SemiDonutChart';
import HalfRating from './dashboard/Rating';

const DealReviews = ({
  title,
  subTitle, // data,
}: {
  title?: string;
  subTitle?: string;
  // data?: LocationCountSeriesDto[];
}) => {
  const [reviewCategory, setReviewCategory] = useState([
    '제품(품질)평가',
    '배송평가',
    '고객 CS 평가',
    '소비자 반응 평가',
    '공급자 평가',
    '수익 평가',
  ]);

  const [memoContents, setMemoContents] = useState<string>('');
  const [editMode, setEditMode] = useState<boolean>(false);

  return (
    <Wrapper>
      {/* 딜 리뷰 */}
      <Title>
        공구 리뷰
        <img
          onClick={() => setEditMode(!editMode)}
          src="/images/adjust_icon.svg"
          alt="icon"
          style={{ cursor: 'pointer' }}
        />
      </Title>
      {reviewCategory.map((category) => (
        <CategoryContainer>
          <CategoryText>{category}</CategoryText>
          <HalfRating edit={editMode} />
        </CategoryContainer>
      ))}
      <Divider />
      <CategoryContainer>
        <CategoryText>총평</CategoryText>
        <HalfRating edit={editMode} />
      </CategoryContainer>
      <CategoryContainer style={{ flexDirection: 'column' }}>
        <CategoryText style={{ marginBottom: 10 }}>메모</CategoryText>
        <TitleForm
          disabled={!editMode}
          multiline
          rows={5}
          placeHolder="메모를 입력해주세요"
          onChangeValue={(value: string) => setMemoContents(value)}
        />
      </CategoryContainer>
      {editMode && <OhmossButton style={{ marginTop: 10 }}> 저장하기 </OhmossButton>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: 'flex';
  justify-content: 'center';
  padding: 10px 20px;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
  color: #666;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 13px;
  }
`;

const CategoryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px 20px;
`;

const CategoryText = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 500;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 13px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 3px;
  background-color: #efefef;
`;

const MemoContainer = styled.div`
  display: flex;
  border: 1px solid #efefef;
`;

export default DealReviews;
