import React, { ReactNode, useEffect, useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';

import Report from 'features/mossMain/components/Report';
import useToken from 'libs/hooks/useToken';
import { AdDto, ChannelDto } from 'services/data-contracts';
import { GlobalContext } from './GlobalContext';
import { useLocation } from 'react-router-dom';
import useChannelSelector from 'libs/hooks/useChannelSelector';

import AdStore from 'stores/AdStore';
import MyBoardStore from 'stores/MyBoardStore';
import useNavigation from 'hooks/useNavigation';
import { ReturnUrl } from 'types/CommonTypes';
import { useRootContext } from 'libs/hooks/useRootContext';

interface ProviderProps {
  children: ReactNode | ReactNode[];
}

const GlobalProvider = ({ children }: ProviderProps) => {
  const { uiStore } = useStore();
  const { validUser } = useToken();
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const { setChannel } = useRootContext();

  const { initializeFirstChannel } = useChannelSelector();

  const location = useLocation();
  const adStore = new AdStore();

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isDeal, setPageIsDeal] = useState<boolean>(false);

  /** @description 로그인 했을경우 내 채널정보 */
  const [myChannel, setMyChannelInfo] = useState<ChannelDto | null>();

  const setLogin = (isLogin: boolean) => {
    setIsLoggedIn(isLogin);
  };

  const setDeal = (isDealPage: boolean) => {
    setPageIsDeal(isDealPage);
  };

  const login = async () => {
    setMyChannelInfo(undefined);
    await initChannel();
  };

  const logout = async () => {
    setMyChannelInfo(null);
  };

  const report = async (adId: number) => {
    if (uiStore.bottomSheet.modalShow) {
      uiStore.bottomSheet.close();
    }

    if (uiStore.universalModal.modalShow) {
      uiStore.universalModal.close();
    }

    if (!isLoggedIn) {
      uiStore.confirm.show({
        message: t('A245'),
        onConfirmed: async () => {
          window.location.href = `/login?action=(7.adid=${adId})`;
        },
      });
      return;
    }

    uiStore.bottomSheet.close();

    const ad = (await adStore.getAd(adId, {
      ownChannelId: myChannel?.id as number,
    })) as never as AdDto;
    if (ad.channel?.id === myChannel?.id) {
      alert('내 게시물은 신고할 수 없습니다.');
    } else {
      uiStore.universalModal.show({
        children: <Report adId={adId} />,
        useHeader: false,
        modalOption: { style: { width: 800, maxHeight: 800 } },
      });
    }
  };

  const initChannel = async () => {
    const channel = await initializeFirstChannel();
    setMyChannelInfo(channel || null);
    if (channel) {
      setChannel(channel);
    }
  };

  useLayoutEffect(() => {
    const result = validUser();
    if (result) {
      // initChannel();
    } else {
      setMyChannelInfo(null);
    }
  }, []);

  useEffect(() => {
    const result = validUser();
    setIsLoggedIn(result);
  }, [location]);

  return (
    <>
      {typeof children !== 'undefined' ? (
        <GlobalContext.Provider
          value={{
            // currentChannel,
            // getCategoryBoard,
            // board,
            report,
            isLoggedIn,
            setLogin,
            isDeal,
            setDeal,
            // setCurrentChannel,
            login,
            logout,
          }}
        >
          {children}
        </GlobalContext.Provider>
      ) : (
        <></>
      )}
    </>
  );
};

export default GlobalProvider;
