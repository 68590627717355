import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import BottomSheetOnly from 'components/commons/BottomSheetOnly';
import FullModal from 'components/commons/FullModal';
import Confirm2 from 'components/commons/Confirm2';
import UniversalModal from 'components/commons/UniversalModal/Modal';
import publicRoutes from 'routes/publicRoutes';
import ProviderWrapper from './ProviderWrapper';
import { initGA } from './MainContainerBase';

const PublicRouter = () => {
  const location = useLocation();

  const getElement = (route: any) => {
    if (window.isWebView) {
      return route.webViewElement ? <route.webViewElement /> : <route.element />;
    } else if (isMobile) {
      return route.mobileElement ? <route.mobileElement /> : <route.element />;
    } else {
      return <route.element />;
    }
  };

  useEffect(() => {
    initGA();
  }, []);

  return (
    <>
      <Routes location={location}>
        {publicRoutes.map((route, index: number) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <ProviderWrapper provider={route.provider}>
                  <BottomSheetOnly />
                  <FullModal />
                  <Confirm2 />
                  <UniversalModal />
                  {getElement(route)}
                </ProviderWrapper>
              }
            />
          );
        })}
      </Routes>
    </>
  );
};

export default PublicRouter;
