// useMyContext.ts
import { useContext } from 'react';
import { MediaContext, ScreenTypes } from './MediaContext';

export const useMediaContext = (): ScreenTypes => {
  const context = useContext(MediaContext);
  if (!context) {
    throw new Error('useMediaContext must be used within a MyProvider');
  }
  return context;
};
