import React, { useEffect, useState } from 'react';
import { ServiceTerms, PrivateTerms, LocationTerms } from 'features/mainFeatures/terms/containers';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TermsBtnStyle from 'features/mainFeatures/terms/components/TermsBtnStyle';
import TermsBtnLabelStyle from 'features/mainFeatures/terms/components/TermsBtnLabelStyle';

const UseTermsContainer = () => {
  const { t } = useTranslation();
  const [selectedTerms, setSelectedTerms] = useState(1);

  const terms = [
    { id: 1, title: '이용약관', content: <ServiceTerms /> },
    { id: 2, title: '개인정보', content: <PrivateTerms /> },
    { id: 3, title: '위치기반 이용약관', content: <LocationTerms /> },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedTerms]);

  return (
    <TermWrapper>
      {/* <MobileHeader title="이용약관" /> */}
      <TermsBtnContainer>
        {terms.map(({ id, title }) => (
          <TermsBtnWrapper key={id}>
            <TermsBtnStyle
              id={title}
              key={id}
              type="radio"
              name="termsModal"
              onClick={() => {
                setSelectedTerms(id);
              }}
              defaultChecked={id === 1}
            />
            <TermsBtnLabelStyle htmlFor={title} key={title}>
              {title}
            </TermsBtnLabelStyle>
          </TermsBtnWrapper>
        ))}
      </TermsBtnContainer>
      <TermsContent id="terms_content">{terms[selectedTerms - 1].content}</TermsContent>
    </TermWrapper>
  );
};

const TermWrapper = styled.div`
  margin-top: 30px;
  padding:20px;
`;
const TermsBtnContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding:10px;
  /* margin-top: 32px; */
`;

const TermsContent = styled.div`
  height: 100%;
`;

const TermsBtnWrapper = styled.div`
  width: 100%;
  display: contents;
`;

export default UseTermsContainer;
