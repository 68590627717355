/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  DealInquiryDto,
  DealInquiryThreadDto,
  DealOptionDto,
  InquirySummaryDto,
  LinkDto,
  MessageDto,
  SimpleDealDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MyDealService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags MyDealService
   * @name CloseReceivedInquiry
   * @summary 접수된 문의 종료처리
   * @request PUT:/v2/me/channels/{channelId}/deals/{dealId}/inquiries/{inquiryId}/close
   * @secure
   */
  closeReceivedInquiry = (
    channelId: number,
    dealId: number,
    inquiryId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<DealInquiryDto, any>({
      path: `/v2/me/channels/${channelId}/deals/${dealId}/inquiries/${inquiryId}/close`,
      method: 'PUT',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyDealService
   * @name GetDealOptions
   * @summary Deal 옵션 목록
   * @request GET:/v2/me/channels/{channelId}/deals/{dealId}/options
   * @secure
   */
  getDealOptions = (channelId: number, dealId: number, params: RequestParams = {}) =>
    this.http.request<DealOptionDto[], any>({
      path: `/v2/me/channels/${channelId}/deals/${dealId}/options`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyDealService
   * @name SaveDealOptions
   * @summary Deal 옵션 저장
   * @request POST:/v2/me/channels/{channelId}/deals/{dealId}/options
   * @secure
   */
  saveDealOptions = (
    channelId: number,
    dealId: number,
    data: DealOptionDto[],
    params: RequestParams = {},
  ) =>
    this.http.request<DealOptionDto[], any>({
      path: `/v2/me/channels/${channelId}/deals/${dealId}/options`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyDealService
   * @name GetReceivedInquiryThreads
   * @summary 접수된 문의 스레드목록
   * @request GET:/v2/me/channels/{channelId}/deals/{dealId}/inquiries/{inquiryId}/threads
   * @secure
   */
  getReceivedInquiryThreads = (
    channelId: number,
    dealId: number,
    inquiryId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DealInquiryThreadDto[], any>({
      path: `/v2/me/channels/${channelId}/deals/${dealId}/inquiries/${inquiryId}/threads`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyDealService
   * @name Answer
   * @summary 답변 하기
   * @request POST:/v2/me/channels/{channelId}/deals/{dealId}/inquiries/{inquiryId}/threads
   * @secure
   */
  answer = (
    channelId: number,
    dealId: number,
    inquiryId: number,
    data: MessageDto,
    params: RequestParams = {},
  ) =>
    this.http.request<DealInquiryThreadDto, any>({
      path: `/v2/me/channels/${channelId}/deals/${dealId}/inquiries/${inquiryId}/threads`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyDealService
   * @name DeleteAnswer
   * @summary 답변 삭제
   * @request POST:/v2/me/channels/{channelId}/deals/{dealId}/inquiries/{inquiryId}/threads/{threadId}
   * @secure
   */
  deleteAnswer = (
    channelId: number,
    dealId: number,
    inquiryId: number,
    threadId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/deals/${dealId}/inquiries/${inquiryId}/threads/${threadId}`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags MyDealService
   * @name GetInquirySummaryForChannel
   * @summary 전체 문의 집계
   * @request GET:/v2/me/channels/{channelId}/inquiries/summary
   * @secure
   */
  getInquirySummaryForChannel = (channelId: number, params: RequestParams = {}) =>
    this.http.request<InquirySummaryDto, any>({
      path: `/v2/me/channels/${channelId}/inquiries/summary`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyDealService
   * @name GetDeals
   * @summary 딜 목록
   * @request GET:/v2/me/channels/{channelId}/deals
   * @secure
   */
  getDeals = (
    channelId: number,
    query?: {
      isActive?: boolean;
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SimpleDealDto[], any>({
      path: `/v2/me/channels/${channelId}/deals`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyDealService
   * @name GetDeal
   * @summary 딜 정보
   * @request GET:/v2/me/channels/{channelId}/deals/{dealId}
   * @secure
   */
  getDeal = (channelId: number, dealId: number, params: RequestParams = {}) =>
    this.http.request<LinkDto, any>({
      path: `/v2/me/channels/${channelId}/deals/${dealId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyDealService
   * @name GetReceivedInquiriesByDeal
   * @summary Deal 문의 목록
   * @request GET:/v2/me/channels/{channelId}/deals/{dealId}/inquiries
   * @secure
   */
  getReceivedInquiriesByDeal = (
    channelId: number,
    dealId: number,
    query?: {
      status?: 'PENDING' | 'ANSWERED' | 'CLOSED';
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DealInquiryDto[], any>({
      path: `/v2/me/channels/${channelId}/deals/${dealId}/inquiries`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyDealService
   * @name GetReceivedInquiry
   * @summary Deal 문의 정보
   * @request GET:/v2/me/channels/{channelId}/deals/{dealId}/inquiries/{inquiryId}
   * @secure
   */
  getReceivedInquiry = (
    channelId: number,
    dealId: number,
    inquiryId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<DealInquiryDto, any>({
      path: `/v2/me/channels/${channelId}/deals/${dealId}/inquiries/${inquiryId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyDealService
   * @name GetReceivedAllInquiries
   * @summary Deal 전체 문의 목록
   * @request GET:/v2/me/channels/{channelId}/all-inquiries
   * @secure
   */
  getReceivedAllInquiries = (
    channelId: number,
    query?: {
      status?: 'PENDING' | 'ANSWERED' | 'CLOSED';
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DealInquiryDto[], any>({
      path: `/v2/me/channels/${channelId}/all-inquiries`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
