/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { SocialLinkDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class SocialLink<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 소셜링크 목록
   *
   * @tags social-link
   * @name GetSocialLinks1
   * @summary 소셜링크 목록
   * @request GET:/v2/channels/{channelName}/social-links
   * @secure
   */
  getSocialLinks1 = (channelName: string, params: RequestParams = {}) =>
    this.http.request<SocialLinkDto[], any>({
      path: `/v2/channels/${channelName}/social-links`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 소셜링크 정보
   *
   * @tags social-link
   * @name GetSocialLink1
   * @summary 소셜링크 정보
   * @request GET:/v2/channels/{channelName}/social-links/{socialLinkId}
   * @secure
   */
  getSocialLink1 = (channelName: string, socialLinkId: number, params: RequestParams = {}) =>
    this.http.request<SocialLinkDto, any>({
      path: `/v2/channels/${channelName}/social-links/${socialLinkId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
