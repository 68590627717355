import styled, { css } from 'styled-components';

export const ItemContainerStyle = styled.div<{ hover?: boolean }>`
  overflow: hidden;
  border-radius: 10px;
  min-height: 60px;
  background: #fff;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
  text-align: center;
  height: 100%;

  border: 1px solid #fff;
  box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.15);
  transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  transform: scale(1);

  @media (hover: hover) {
    &:hover {
      background: ${(props) => (props.hover ? '#fff !important' : 'initial')};
      color: ${(props) => (props.hover ? '#000 !important;' : 'initial')};

      box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.15);
      transform: ${(props) => (props.hover ? 'scale(1.05)' : 'scale(1)')};

      /* color: #fff; */
      /* background: #efefef; */
    }
  }
`;

// 아이템 스타일 새로운 디자인으로 변경 (우선 simpleLink와 TelLink에만 적용)
export const NewItemContainerStyle = styled.div<{ preview?: boolean }>`
  overflow: hidden;
  background: #fff;
  /* height: 50px; */
  margin: 0 auto 15px auto;
  text-align: center;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  display: flex;
  align-items: center;
  /* transition: all 0.3s ease-out; */
  transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);

  &:hover {
    ${(props) =>
      props.preview
        ? css``
        : css`
            box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.5);
            transform: scale(1.05);
          `}
  }
  @media ${(props) => props.theme.media.mobile} {
    &:hover {
      transform: scale(1);
    }
  }
`;

export const SimpleLinkStyle = styled(NewItemContainerStyle)`
  justify-content: center;
  min-height: 60px;
  a > div > div > div > img {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
  }
`;

export const TelLinkStyle = styled(NewItemContainerStyle)`
  justify-content: center;
`;
