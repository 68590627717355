import { DefaultTheme } from 'styled-components';

/**
 * media에 사용되는 고정 사이즈
 * tablet도 desk로 다룬다.
 */
const size = {
  mobile: '600px',
  desk: '960px',
};

export const theme: DefaultTheme = {
  colors: {
    pointColor: '#3E64EA',
    defaultTextColor: '#333',
    borderColor: '#ddd',
    defaultBackground: '#0BBD58',
  },
  typos: {
    title: { fontWeight: 700, fontSize: 20 },
  },
  platformHeight: 'auto', // 기본값, 이후 usePlatformChecker에서 동적으로 설정
  defaultFont: {
    color: '#333',
    fontSize: 14,
  },

  /**
   *  미디어 쿼리 사용방식
   *  @media ${(props) => props.theme.media.mobile} {
   *  }
   */
  media: {
    desk: `(min-width: ${size.desk})`,
    mobile: `(max-width: ${size.mobile})`,
    pad: `(min-width: ${size.mobile}) and (max-width: ${size.desk})`,
  },
};

export default theme;
