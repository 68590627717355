import { useStore } from 'stores/StoreHelper';

import { SignatureDto } from 'services/data-contracts';
import sha256 from 'crypto-js/sha256';
import CryptoJS from 'crypto-js';
/**
 * @description 로그인 후 기본 채널을 설정한다.
 * https://www.npmjs.com/package/secure-web-storage
 * @returns
 */
function useLog() {
  const { logStore } = useStore();
  const writeLog = () => {
    const r = document.referrer;
    const sha256Script = sha256('test').toString();
    const log: SignatureDto = {
      data: '',
      signature: '',
    };
    // logStore.createLog(log, r);
  };

  return {
    writeLog,
  };
}

export default useLog;
