import { makeAutoObservable } from 'mobx';
import { LinkDto, MembershipPurchaseDto, TosspayApprovalDto } from 'services/data-contracts';

import _ from 'lodash';
import { HttpClient } from 'services/http-client';
import i18n from 'i18n';
import { MembershipPlan } from 'services/MembershipPlan';
import { PaymentService } from 'services/PaymentService';
import { MembershipService } from 'services/MembershipService';
import { OrderService } from 'services/OrderService';
import { toast } from 'react-toastify';
import { GiftService } from 'services/GiftService';

export interface isValid {
  isValid: boolean;
}

class MembershipStore {
  t = i18n.t;

  constructor() {
    makeAutoObservable(this);
  }

  api: MembershipPlan<unknown> = new MembershipPlan(new HttpClient());

  payment_api: PaymentService<unknown> = new PaymentService(new HttpClient());

  my_membership: MembershipService<unknown> = new MembershipService(new HttpClient());

  order_api: OrderService<unknown> = new OrderService(new HttpClient());

  gift_api: GiftService<unknown> = new GiftService(new HttpClient());

  *getMembershipPlans(query: { channelType: 'SELLER' | 'BRAND' | 'MEMBER' }) {
    const { response } = yield this.api.getMembershipPlans(query);
    return response.status < 300 ? response.data : [];
  }

  *getMembershipPlan(id: number) {
    const { response } = yield this.api.getMembershipPlan(id);
    return response.status < 300 ? response.data : [];
  }

  *pay(query: { ownChannelId: number }, data: MembershipPurchaseDto) {
    // const { response } = yield this.payment_api.pay(query, data);
    // if (response.status > 300) {
    //   toast.error(response.data.errorMessage);
    // }
    // return response.status < 300 ? response.data : [];
  }

  *purchaseMembership(channelId: number, data: MembershipPurchaseDto) {
    const { response } = yield this.my_membership.purchaseMembership(channelId, data);
    if (response.status > 299) {
      toast.error(response.data.errorMessage);
      return response;
    }
    return response.data;
  }

  *cancelMembership(channelId: number, membershipId: number) {
    const { response } = yield this.my_membership.cancelMembership(channelId, membershipId);
    if (response.status > 299) {
      toast.error(response.data.errorMessage);
      return response;
    }
    return response.data;
  }

  // *approve(ownChannelId: number, data: TosspayApprovalDto) {
  //   const { response } = yield this.payment_api.approvePayment({ ownChannelId }, data);
  //   if (response.status > 300) {
  //     toast.error(response.data.errorMessage);
  //   }
  //   return response.status < 300 ? response.data : response;
  // }

  *getCurrentMembership(channelId: number) {
    const { response } = yield this.my_membership.getCurrentMembership(channelId);
    return response.status < 300 ? response.data : undefined;
  }

  *getMemberships(channelId: number) {
    const { response } = yield this.my_membership.getMemberships(channelId);
    return response.status < 300 ? response.data : [];
  }

  // *cancelMembership(channelId: number, orderId: number) {
  //   // const { response } = yield this.order_api.cancelOrder(channelId, orderId);
  //   // if (response.status > 300) {
  //   //   toast.error(response.data.errorMessage);
  //   // } else {
  //   //   toast.success('멤버십 해지가 완료되었습니다.');
  //   // }
  //   // return response.status < 300 ? response.data : response.data;
  // }

  *payGift(ownChannelId: number , data: MembershipPurchaseDto) {
    const { response } = yield this.payment_api.payGift(ownChannelId, data);
    if (response.status > 300) {
      toast.error(response.data.errorMessage);
    }
    return response.status < 300 ? response.data : [];
  }

  *approveGiftPayment(ownChannelId: number, data: TosspayApprovalDto) {
    const { response } = yield this.payment_api.approveGiftPayment({ ownChannelId }, data);
    if (response.status > 300) {
      toast.error(response.data.errorMessage);
    }
    return response.status < 300 ? response.data : response;
  }

  *getSentGifts(channelId: number) {
    const { response } = yield this.gift_api.getSentGifts(channelId);
    return response.status < 300 ? response.data : [];
  }

  *getReceivedGifts(channelId: number) {
    const { response } = yield this.gift_api.getReceivedGifts(channelId);
    return response.status < 300 ? response.data : [];
  }

  *getSentMembershipGift(channelId: number, membershipGiftId: number) {
    const { response } = yield this.gift_api.getSentMembershipGift(channelId, membershipGiftId);
    return response.status < 300 ? response.data : [];
  }

  *getReceivedMembershipGift(channelId: number, membershipGiftId: number) {
    const { response } = yield this.gift_api.getReceivedMembershipGift(channelId, membershipGiftId);
    return response.status < 300 ? response.data : [];
  }
}

export default MembershipStore;
