import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import DealsContainer from '../container/DealsContainer';

const DealsPage = observer(() => {
  return <DealsContainer />;
});

export default DealsPage;
