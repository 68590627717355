import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Switch, SwitchProps } from '@mui/material';
import { StyledEngineProvider } from '@mui/styled-engine';
import { useStore } from 'stores/StoreHelper';

interface MUISwitchProps extends SwitchProps {
  useConfirm?: boolean;
  title?: string;
  message?: string;
}

const MUISwitch = (props: MUISwitchProps) => {
  const { uiStore } = useStore();
  const [checkedValue, setChecked] = useState<boolean | undefined>(false);

  const handleChangeActive = (e: any, value: boolean) => {
    if (props.useConfirm === true) {
      uiStore.confirm.show({
        title: props.title,
        message: props.message,
        onConfirmed: async () => {
          props.onChange?.(e, value);
          setChecked(value);
        },
      });
    } else {
      props.onChange?.(e, value);
      setChecked(value);
    }
  };

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  return (
    <StyledEngineProvider injectFirst>
      <IOSSwitch
        color="secondary"
        {...props}
        checked={checkedValue}
        // checked={props.checked}
        onChange={handleChangeActive}
      />
    </StyledEngineProvider>
  );
};

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({}) => ({
  width: '50px',
  height: '30px',
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 2,
    margin: 2,
    transitionDuration: '300ms',

    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        border: 0,
        backgroundColor: '#96E06E !important',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#96E06E',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#efefef',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    background: '#fff',
    // marginTop: 1,
    // marginLeft: 4,
  },
  '& .MuiSwitch-track': {
    borderRadius: 50 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
  },
}));
export default MUISwitch;
