import styled from 'styled-components';

export const ContextContainer = styled.div`
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
`;

export const ContextItem = styled.div<{ itemType?: 'danger' }>`
  cursor: pointer;
  ${(props) => (props.itemType === 'danger' ? 'color: red' : '')};
`;

export const NameStyle = styled.div`
  background: #173300;
  color: #96e06e;
  padding: 2px 10px;
  border-radius: 40px;
  text-align: center;
  font-size: 12px;
  display: inline-block;
`;

export const MainText = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 10px;
`;
