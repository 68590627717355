import React, { memo, useEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ChannelBoardDto } from 'services/data-contracts';

import styled from 'styled-components';
import Avatar from 'components/commons/Avatar';
import Footer from 'components/items/Footer';
import NameCard from './NameCard';
import FreeBoardType from 'features/board/containers/FreeBoardType';
import FeedBoardType from 'features/board/containers/FeedBoardType';
import PinterestBoardType from 'features/board/containers/PinterestBoardType';
import LinkBoardType from 'features/adminFeatures/template/components/LinkBoardType';
import CalendarBoardType from 'features/board/containers/CalendarBoardType';

import 'swiper/css/pagination';
import 'swiper/css';
import TemplateCommon from './TemplateCommon';

const WebViewSimpleTemplate = observer(() => {
  const { channelStore, uiStores } = useStore();
  const { profileStore, userHomeStore } = uiStores;

  return (
    <StackStyle>
      {userHomeStore.currentBoard?.useProfile !== false ? (
        <ProfileWrapStyle
          isMine={channelStore.channel?.isOwner}
          style={{ marginTop: window.isWebView ? 70 : 100 }}
        >
          <Avatar size="medium" profile={profileStore.profile} />
        </ProfileWrapStyle>
      ) : (
        <div style={{ marginTop: 20 }} />
      )}
      <ItemContainerStyle>
        <NameCard profileInfo={profileStore.profile} type={'simple'} />
        <TemplateCommon />
      </ItemContainerStyle>

      <Footer model={profileStore.profile} />
    </StackStyle>
  );
});

const StackStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`;

const ProfileWrapStyle = styled.div<{ isMine?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  max-width: 550px;
  margin: auto;
  margin-top: 100px;
  transition: all 0.3s ease-out;

  @media ${(props) => props.theme.media.mobile} {
    transition: all 0.3s ease-out;
  }

  justify-content: center;
`;

const ItemContainerStyle = styled.div`
  padding-bottom: 10px;
  flex: 3;
  @media ${(props) => props.theme.media.mobile} {
    margin-top: 30px;
    padding: 0px 20px;
  }
  position: relative;
`;

export default memo(WebViewSimpleTemplate);
