import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { OhmossButton } from 'resources/controls/OhmossButton';
import BrandManualData from 'features/myPage/components/membership/BrandManualData';
import useNavigation from 'hooks/useNavigation';
import { IconButton } from '@mui/material';

const BrandMembership = ({ membership }: { membership?: any }) => {
  const { navigate } = useNavigation();

  const SwiperNextBtn = () => {
    const swiper = useSwiper();

    return (
      <IconButton
        style={{
          display: 'flex',
          position: 'absolute',
          alignItems: 'center',
          right: 20,
          width: 32,
          height: 32,
          backgroundColor: 'rgba(255, 255, 255, 0.4)',
        }}
        onClick={() => {
          swiper.slideNext();
        }}
      >
        <img
          src="/images/management/membership/membership_arrow_right_active.svg"
          alt="arrow"
          style={{ width: 24, height: 24 }}
        />
      </IconButton>
    );
  };

  const SwiperPrevBtn = () => {
    const swiper = useSwiper();
    return (
      <IconButton
        style={{
          display: 'flex',
          position: 'absolute',
          alignItems: 'center',
          right: 60,
          width: 32,
          height: 32,
          backgroundColor: 'rgba(255, 255, 255, 0.4)',
        }}
        onClick={() => {
          swiper.slidePrev();
        }}
      >
        <img
          src="/images/management/membership/membership_arrow_left_active.svg"
          alt="arrow"
          style={{ width: 24, height: 24 }}
        />
      </IconButton>
    );
  };

  return (
    <div>
      <Swiper spaceBetween={50} slidesPerView={1}>
        {BrandManualData?.map((data, i) => (
          <>
            {membership === data.type &&
              data.titleData?.map((title, index) => (
                <SwiperSlide key={index}>
                  <SwiperWrapper>
                    <BackgroundImg src={BrandManualData[i]?.imgData?.[index]} alt="background" />
                  </SwiperWrapper>
                  <Box>
                    {data.titleData?.length > 1 && (
                      <>
                        <SwiperPrevBtn />
                        <SwiperNextBtn />
                      </>
                    )}
                    <TopHeader>브랜드 {BrandManualData[i]?.type}</TopHeader>
                    <BoxTitleContainer>
                      {BrandManualData[i]?.titleData?.[index]}
                      <span style={{ fontSize: 12, fontWeight: 200 }}>
                        &nbsp;
                        {index === 0 && BrandManualData[index]?.subtitle}
                      </span>
                    </BoxTitleContainer>
                    <InnerBox>
                      <InnerBoxItem>
                        {BrandManualData[i]?.boxData?.text1}
                        <br />
                        ★★★★★
                      </InnerBoxItem>
                      <InnerBoxLine />
                      <InnerBoxItem>
                        <Badge />
                        <img
                          src={
                            membership === 'PRO'
                              ? '/images/management/membership/medal_icon.svg'
                              : '/images/management/membership/medal_icon_gold.svg'
                          }
                          style={{ position: 'absolute', marginLeft: -86, marginTop: 1 }}
                          alt=""
                        />
                        {BrandManualData[i]?.boxData?.text2}
                      </InnerBoxItem>
                      <InnerBoxLine />
                      <InnerBoxItem>
                        {BrandManualData[i]?.boxData?.text3}
                        <br />
                        {BrandManualData[i]?.boxData?.text4}
                      </InnerBoxItem>
                    </InnerBox>
                  </Box>
                  <SubscribeBox>
                    <PriceInfo>
                      <div style={{ flexDirection: 'row' }}>
                        {BrandManualData[i]?.priceData?.price}
                        <span style={{ color: '#275700', fontSize: 14, fontWeight: 300 }}>
                          {BrandManualData[i]?.priceData?.period}
                        </span>
                      </div>
                      <span style={{ color: '#173300', fontWeight: 300, fontSize: 12 }}>
                        {BrandManualData[i]?.priceData?.discount}
                      </span>
                    </PriceInfo>
                    <OhmossButton
                      onClick={() => navigate('/my/subscriptions?tab=멤버십구독')}
                      style={{ width: '38%', borderRadius: 10 }}
                    >
                      구독하기
                    </OhmossButton>
                  </SubscribeBox>
                </SwiperSlide>
              ))}
          </>
        ))}
      </Swiper>
    </div>
  );
};

const SwiperWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BackgroundImg = styled.img`
  position: relative;
  width: 100%;
  height: calc(100vh - 120px);
  object-fit: cover;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 100px;
  height: 230px;
  background: rgba(23, 51, 0, 0.78);
  width: 100%;
  padding: 20px;
`;

const BoxTitleContainer = styled.div`
  color: #fff;
  font-size: 1.25rem;
  font-weight: 500;
  margin: 8px;
  white-space: pre-line;
`;

const TopHeader = styled.div`
  display: inline-flex;
  min-width: 80px;
  max-width: max-content;
  padding: 0.175rem 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 62.4375rem;
  background: #96e06e;
  z-index: 1;
  font-size: 12px;
  font-weight: 500;
`;

const InnerBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 0.9375rem;
  height: 200px;
  border: 1px solid rgba(255, 255, 255, 0.75);
  white-space: pre-line;
`;

const InnerBoxItem = styled.div`
  display: flex;
  gap: 6px;
  color: #fff;
  font-size: 12px;
  font-weight: 200;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const InnerBoxLine = styled.div`
  width: 0.0625rem;
  height: 2.375rem;
  background: rgba(255, 255, 255, 0.75);
`;

const Badge = styled.div`
  position: relative;
  background-color: #d60863;
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

const SubscribeBox = styled.div`
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  bottom: 0;
  padding: 20px;
  background-color: #fff;
`;

const PriceInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
`;

export default BrandMembership;
