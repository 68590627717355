import React, { useEffect, useRef, useState } from 'react';
import { Row } from 'components/commons/layouts';

import SendIcon from '@mui/icons-material/Send';
import { useChat } from 'libs/hooks/useChat';
import { GroupChannel, Member } from '@sendbird/chat/groupChannel';
import { BaseMessage } from '@sendbird/chat/message';
import { timestampToTime } from 'libs/helper/chatUtil';
import { ChannelDto, LinkDto, SimpleDealDto } from 'services/data-contracts';
import { IconButton, TextField } from '@mui/material';
import AttachFile from '@mui/icons-material/AttachFile';
import styled from 'styled-components';
import { useRootContext } from 'libs/hooks/useRootContext';
import { Divider } from 'features/myPage/components/CommonStyleComponent';
import CommonThumbnail from 'features/management/components/commonControl/CommonThumbnail';
import Text from '../Text';
import { OhmossButton } from 'resources/controls/OhmossButton';
import useNavigation from 'hooks/useNavigation';

// 모바일 기본 100%
let containerHeightRef: number | string = '100%';

function scrollToBottom(delay: number = 0) {
  const modalHeight = document.querySelector('.ReactModal__Content') as any;
  if (modalHeight) {
    containerHeightRef = modalHeight.offsetHeight - 50;
  }
  setTimeout(() => {
    const scrollableElement = document.getElementById('ModalContentStyleBox');
    const messageListElement = document.getElementById('messsageListContainer') as any;

    if (scrollableElement) {
      scrollableElement.scrollTop = messageListElement.offsetHeight;
    }
    // 스크롤 이벤트 리스너 추가
    if (scrollableElement !== null) {
      scrollableElement.addEventListener('scroll', () => {
        // 특정 스크롤 위치 (예: 100px)
        const triggerScrollPosition = 100;

        // 현재 스크롤 위치가 트리거 위치에 도달했는지 확인
        if (scrollableElement && scrollableElement?.scrollTop <= triggerScrollPosition) {
          // 여기에 원하는 이벤트 처리 로직을 추가합니다.
          // getPreviousMessages();
        }
      });
    }
  }, delay);
}

/** @description 고객별 문의 쓰레드 */
interface DealInquiryByCustomerProps {
  dealInfo?: SimpleDealDto;
  messages?: BaseMessage[];
  channel?: GroupChannel;
  currentUserId?: string;
  onSendMessage: (msg: string) => void;
  onSendFile: (files: File[]) => void;
}

/**
 * @description 일반 고객이 사용하는 채팅 창
 * @description 일반 고객은 셀러/브랜드멤버에 상관 없이 셀러로 표시되어야 함.
 * @param param0
 * @returns
 */
const CustomerChatBox: React.FC<DealInquiryByCustomerProps> = ({
  dealInfo,
  messages,
  channel,
  onSendMessage,
  onSendFile,
}) => {
  const channelRef = useRef<any>();
  const messageInputRef = useRef<string>('');
  const { myChannel } = useRootContext();

  const [messageInput, setMessageInput] = useState<string>('');
  const [operatorMember, setOperatorMember] = useState<Member>();
  const { initCompleted } = useChat();

  const sendMessage = async () => {
    onSendMessage(messageInputRef?.current as string);
    setMessageInput('');
    scrollToBottom();
  };

  // useEffect(() => {
  //   if (uiStore.modal.modalShow === false) {
  //     scrollToBottom(500);
  //   }
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, [messages]);

  useEffect(() => {
    const t = dealInfo;
    if (channel) {
      const operator = channel.members.find(
        (member) => member.role === 'operator' && member.userId !== myChannel?.channelName,
      );
      if (operator) {
        setOperatorMember(operator);
      }
    }
  }, [channel]);

  return (
    <div
      id="inquiryChatContainer"
      style={{
        height: containerHeightRef,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {initCompleted && (
        <>
          {dealInfo && <DealBoxInfo dealInfo={dealInfo as LinkDto} />}
          {messages && messages?.length === 0 && (
            <WelcomeMessageStyle>안녕하세요. 무엇이 궁금하신가요?</WelcomeMessageStyle>
          )}
          <MessageBoxStyle
            ref={channelRef}
            id="messsageListContainer"
            className="messsage-list-container"
          >
            <>
              {messages && (
                <>
                  <MessagesList
                    messages={messages}
                    operator={operatorMember}
                    onChangeHeight={scrollToBottom}
                  />
                </>
              )}
            </>
          </MessageBoxStyle>
          <InputStyle>
            <MessageInput
              value={messageInput}
              onChange={(value: string) => {
                messageInputRef.current = value;
                setMessageInput(value);
              }}
              sendMessage={sendMessage}
              onFileInputChange={onSendFile}
            />
          </InputStyle>
        </>
      )}
    </div>
  );
};

export default CustomerChatBox;

const DealBoxInfo = ({ dealInfo }: { dealInfo: LinkDto }) => {
  const { navigate } = useNavigation();

  return (
    <ContainerStyle>
      <Row style={{ justifyContent: 'space-between' }}>
        <Row style={{ justifyContent: 'flex-start' }}>
          <CommonThumbnail initialValue={dealInfo?.thumbnail?.publicUrl} />
          <Text type="H3" style={{ marginLeft: 10 }}>
            {dealInfo?.title}
          </Text>
        </Row>
        <OhmossButton
          style={{ width: 150 }}
          type="square"
          onClick={() => {
            window.open(`/${dealInfo?.channel?.channelName}/deals/${dealInfo?.linkKey}`);
            // navigate(`/${dealInfo?.sellerChannel?.channelName}/deals/${dealInfo?.linkKey}`);
          }}
        >
          공구상세
        </OhmossButton>
      </Row>
    </ContainerStyle>
  );
};
const ContainerStyle = styled.div`
  width: 100%;
  margin: auto;
  padding: 10px;
  text-align: center;
  margin-bottom: 15px;
  border: 1px solid #d3d3d3;
  background: #fff;
  /* border-radius: 15px; */
  position: absolute;
  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

const MessagesList = ({
  messages,
  operator,
  onChangeHeight,
}: {
  messages: any;
  operator?: Member;
  onChangeHeight: (height: number) => void;
}) => {
  const channelRef = useRef<any>();
  const { myChannel } = useRootContext();

  const [guestId, setGuestId] = useState<string | undefined | null>(null);

  const getUser = (message: any, messageSentByYou: boolean) => {
    if (messageSentByYou) {
      return message.sender;
    } else if (operator) {
      return operator;
    } else {
      return message.sender;
    }
  };

  /**
   * @description 메시지창의 크기를 observer로 감지하여 스크롤을 맨 아래로 이동시킴
   */
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        onChangeHeight(entry?.contentRect?.height);
        scrollToBottom();
      }
    });

    if (channelRef.current) {
      resizeObserver.observe(channelRef.current);
    }

    return () => {
      if (channelRef.current) {
        resizeObserver.unobserve(channelRef.current);
      }
    };
  }, [channelRef.current]);

  useEffect(() => {
    if (myChannel) {
      setGuestId(undefined);
    } else {
      const guestId = localStorage.getItem('guestId');
      if (guestId !== null) {
        setGuestId(guestId);
      }
    }
  }, [myChannel]);

  return (
    <div className="message-list" id="messageList">
      {guestId !== null && (
        <ListBoxStyle
          ref={channelRef}
          style={{ height: '100%' }}
          id="messageListBox"
          className="message-list-box"
        >
          {messages?.map((message: any) => {
            if (!message.sender) return null;

            const messageSentByYou =
              message.sender.userId === (guestId ? guestId : myChannel?.channelName);

            return (
              <div
                key={message.messageId}
                className={`message-item ${messageSentByYou ? 'message-from-you' : ''}`}
              >
                <>
                  {message.parentMessage ? (
                    <>
                      <ReplyMessage message={message} messageSentByYou={messageSentByYou} />
                      <ProfileImage user={getUser(message, messageSentByYou)} />
                    </>
                  ) : (
                    <>
                      <Message message={message} messageSentByYou={messageSentByYou} />
                      <ProfileImage user={getUser(message, messageSentByYou)} />
                    </>
                  )}
                </>
              </div>
            );
          })}
        </ListBoxStyle>
      )}
    </div>
  );
};

const Message = ({ message, messageSentByYou }: { message: any; messageSentByYou: unknown }) => {
  const { myChannel } = useRootContext();

  const [channelName, setChannelName] = useState<string>();

  useEffect(() => {
    if (myChannel) {
      setChannelName(myChannel.channelName);
    } else {
      const guestId = localStorage.getItem('guestId');
      setChannelName(guestId || '');
    }
  }, [myChannel]);

  if (message?.url) {
    return (
      <div className="message-box">
        <div
          className={`message  ${messageSentByYou ? 'message-from-you' : ''}`}
          style={{ overflow: 'hidden', padding: 0 }}
        >
          <img src={message?.url} />
        </div>
        <div className="date">{timestampToTime(message.createdAt)}</div>
      </div>
    );
  }
  const messageSentByCurrentUser = message.sender.userId === channelName;

  return (
    <>
      {channelName && (
        <div style={{ display: 'flex', alignItems: 'center' }} className="message-box">
          {messageSentByCurrentUser && (
            <div className="date">{timestampToTime(message.createdAt)}</div>
          )}
          <div className={`message  ${messageSentByYou ? 'message-from-you' : ''}`}>
            <div className="message-info">
              {messageSentByCurrentUser && (
                <div>
                  {/* <button className="control-button" onClick={() => updateMessage(message)}>
              <img className="message-icon" src="/icon_edit.png" />
            </button>
            <button className="control-button" onClick={() => handleDeleteMessage(message)}>
              <img className="message-icon" src="/icon_delete.png" />
            </button> */}
                </div>
              )}
            </div>
            <div>{message.message}</div>
          </div>
          {!messageSentByCurrentUser && (
            <div className="date">{timestampToTime(message.createdAt)}</div>
          )}
        </div>
      )}
    </>
  );
};
const ReplyMessage = ({
  message,
  messageSentByYou,
}: {
  message: any;
  messageSentByYou: unknown;
}) => {
  const { currentUserRef } = useChat();
  const { myChannel } = useRootContext();

  const [channelName, setChannelName] = useState<string>();

  useEffect(() => {
    if (myChannel) {
      setChannelName(myChannel.channelName);
    } else {
      const guestId = localStorage.getItem('guestId');
      setChannelName(guestId || '');
    }
  }, [myChannel]);

  if (message?.url) {
    return (
      <div className="message-box">
        <div
          className={`message  ${messageSentByYou ? 'message-from-you' : ''}`}
          style={{ overflow: 'hidden', padding: 0 }}
        >
          <img src={message?.url} />
        </div>
        <div className="date">{timestampToTime(message.createdAt)}</div>
      </div>
    );
  }
  const messageSentByCurrentUser = message.sender.userId === currentUserRef?.current?.userId;

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', background: 'blue !important' }}
      className="message-box"
    >
      {messageSentByCurrentUser && <div className="date">{timestampToTime(message.createdAt)}</div>}
      <div className={`message  ${messageSentByYou ? 'message-from-you' : ''}`}>
        <div className="message-info">{messageSentByCurrentUser && <div />}</div>
        <div>
          {message.parentMessage.sender.userId === myChannel?.channelName ? '나에게답장' : '답장'}
        </div>
        <div style={{ color: '#afafaf' }}>{message.parentMessage.message}</div>
        {console.log('############', message)}
        <Divider />
        <div>{message.message}</div>
      </div>
      {!messageSentByCurrentUser && (
        <div className="date">{timestampToTime(message.createdAt)}</div>
      )}
    </div>
  );
};

const ProfileImage = ({ user, seller }: { user: any; seller?: ChannelDto }) => {
  const t = user;
  return (
    <>
      {user?.plainProfileUrl ? (
        <>
          <img className="profile-image" src={user.plainProfileUrl} />
        </>
      ) : (
        <div
          className="profile-image-fallback"
          style={{
            fontSize: 20,
            fontWeight: 400,
            textTransform: 'uppercase',
            minWidth: 50,
            minHeight: 50,
          }}
        >
          {seller?.channelName?.charAt(0)}
        </div>
      )}
    </>
  );
};

const MessageInput = ({
  value,
  onChange,
  sendMessage,
  onFileInputChange,
}: {
  value: string;
  onChange: any;
  sendMessage: () => void;
  onFileInputChange: (files: any) => void;
}) => {
  const fileRef = useRef<any>(null);
  return (
    <Row style={{ justifyContent: 'space-between', padding: 10, width: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        <TextField
          fullWidth
          // onFocus={onFocus}
          // onBlur={onBlur}
          inputRef={(input) => input && input.focus()}
          value={value}
          style={{ borderRadius: 10 }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              sendMessage();
            }
          }}
          onChange={(e: any) => {
            onChange(e.target.value);
          }}
        />
      </div>
      <Row style={{ width: 'unset', marginLeft: 10 }}>
        <IconButton
          onClick={() => {
            fileRef.current.click();
          }}
        >
          <AttachFile />
        </IconButton>
        <IconButton onClick={sendMessage}>
          <SendIcon />
        </IconButton>
        <input
          ref={fileRef}
          id="upload"
          className="file-upload-button"
          type="file"
          hidden
          onInput={() => {
            onFileInputChange(fileRef.current?.files);
          }}
        />
      </Row>
    </Row>
  );
};

const WelcomeMessageStyle = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
  left: 0;
  right: 0;
`;

const InputStyle = styled.div<{ isKeyboardVisible?: boolean }>`
  width: 100%;
  max-width: var(--pc-max-modal-width);
  background: #fff;
  bottom: 0px;
  border-bottom-left-radius: 20;
  border-bottom-right-radius: 20;
  left: 0;
  left: 0;
  right: 0;
  height: 78px;
  margin: auto;
  position: sticky;
  bottom: 0;

  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    max-width: var(--max-width);
  }
`;

const MessageBoxStyle = styled.div`
  width: 100%;
  flex-grow: 1;
  @media ${(props) => props.theme.media.mobile} {
    margin-bottom: 55px;
  }

  @media ${(props) => props.theme.media.desk} {
    /* margin: 30px; */
    /* padding-bottom: 80px; */
  }
`;

const ListBoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
