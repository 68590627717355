import { useStore } from 'stores/StoreHelper';
import { MyStyle } from 'types/CommonTypes';

import MyChannelStyleStore from 'stores/MyChannelStyleStore';
import ChannelStore from 'stores/ChannelStore';
import { ChannelStyleDto } from 'services/data-contracts';
import { useRootContext } from 'libs/hooks/useRootContext';

/**
 * @description 각 Block의 등록/수정/삭제를 수행함.
 * @returns
 */
const StyleController = () => {
  const { xhrStore } = useStore();
  const channelStore = new ChannelStore();
  const myChannelStyleStore = new MyChannelStyleStore();

  const { myChannel } = useRootContext();

  const getChannelStyle = (channelId: number) => {
    // return myLinkStore.getChannelStyle(channelId);
  };

  /**
   * @description 링크박스 스타일 적용하기
   */
  const getMyStyle = async (channelName: string) => {
    const data = (await channelStore.getMyStyle(channelName)) as unknown as ChannelStyleDto;
    const styleObject: MyStyle = JSON.parse(data?.style ?? '{}');
    if (Object.keys(styleObject?.boxStyle ?? {}).length === 0) {
      // 기존 데이터가 없을경우 기본값으로 적용
      styleObject.boxStyle = {
        borderRadius: '15px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
      };
    }
    return styleObject;
    // setChannelStyle(styleObject);
  };

  const updateStyle = async (serviceModel: MyStyle) => {
    xhrStore.setState('pending');
    if (JSON.stringify(serviceModel?.boxStyle) !== '{}') {
      delete serviceModel?.created;
      const result = await myChannelStyleStore.updateMyStyle(myChannel?.id as number, {
        style: JSON.stringify(serviceModel),
      });
      if (!result) {
        const result = await myChannelStyleStore.createMyStyle(myChannel?.id as number, {
          style: JSON.stringify(serviceModel),
        });
      }
    } else {
      const result = await myChannelStyleStore.createMyStyle(myChannel?.id as number, {
        style: JSON.stringify(serviceModel),
      });

      if (!result) {
        myChannelStyleStore.updateMyStyle(myChannel?.id as number, {
          style: JSON.stringify(serviceModel),
        });
      }
    }
    xhrStore.setState('done');
  };

  return {
    updateStyle,
    getMyStyle,
  };
};

export default StyleController;
