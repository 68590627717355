import React from 'react';

import ActionBox from 'features/csTown/components/pc/ActionBox';
import BlockController from 'controllers/BlockController';
import { useStore } from 'stores/StoreHelper';
import { LinkDto } from 'services/data-contracts';
import { useStateContext } from 'libs/hooks/usePageState';
import useLocalParams from 'libs/hooks/useLocalParams';

const ActionButton = ({ isValid, serviceModel }: { isValid?: boolean; serviceModel: LinkDto }) => {
  const { updateBlock } = BlockController();
  const { uiStore } = useStore();
  const { broadcast } = useStateContext();
  const { categoryId } = useLocalParams();

  return (
    <ActionBox
      style={{ marginTop: 60, paddingBottom: 20 }}
      isValid={isValid}
      okText="저장"
      onOk={async () => {
        await updateBlock(serviceModel);
        broadcast({ id: 'ACTION', param: { id: 'LINK_UPDATE_DONE', categoryId: categoryId } });
      }}
      onCancel={() => {
        uiStore.modal.close();
      }}
    />
  );
};

export default ActionButton;
