import React, { createContext } from 'react';
import { AdCategoryDto } from 'services/data-contracts';

export type displayTypes = 'list' | 'square' | 'category';
interface propsTypes {
    selectedCategory?: AdCategoryDto;
    displayType?: displayTypes;
    setCategory: (category?: AdCategoryDto) => void;
    setDisplayType: (type?: displayTypes) => void;
    getAds: (query?: any) => void;
}

const DashboardContext = createContext<propsTypes | undefined>(undefined);


export { DashboardContext, propsTypes };