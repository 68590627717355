import React from 'react';
import MenuItem from 'components/commons/menuItem/MenuItem';
import MenuItemBox from 'components/commons/menuItem/MenuItemBox';
import TextLabel from 'components/commons/labels/TextLabel';
import useNavigation from 'hooks/useNavigation';

import useLogout from 'hooks/useLogout';
import SettingProvider from '../context/settings/SettingProvider';

const MemberMenus = () => {
  const { navigate } = useNavigation();
  const logout = useLogout();
  const { withDrawal } = SettingProvider();

  return (
    <>
      <MenuItemBox title="인플루언서">
        <MenuItem
          title="내 대시보드 보기"
          onClick={() => {
            navigate('/settings/dashboard');
          }}
        />
        <MenuItem title="인플루언서 인증">
          <div>
            <TextLabel type="content">딜 광고를 시작하세요</TextLabel>
            <MenuItem title="인플루언서 인증">
              {/* <FacebookProvider appId="360396142998604"> */}
              <div>
                <TextLabel type="content">인증완료</TextLabel>
                <a href="https://www.facebook.com/v18.0/dialog/oauth?response_type=code&display=popup&client_id=360396142998604&redirect_uri=https://localhost:3000/my/auth/instagram&scope=public_profile%2Cinstagram_basic%2Cemail">
                  Login to Facebook
                </a>
              </div>
              {/* </FacebookProvider> */}
            </MenuItem>
          </div>
        </MenuItem>
      </MenuItemBox>
      <MenuItemBox title="회원">
        <MenuItem
          title="계정 설정"
          onClick={() => {
            navigate('/admin/account');
          }}
        />
        {/* <MenuItem
          title="알림설정"
          onClick={() => {
            navigate('/settings/notification');
          }}
        /> */}
      </MenuItemBox>
      <MenuItemBox title="서비스 정보">
        <MenuItem
          title="서비스 이용 약관"
          onClick={() => {
            navigate('/settings/userterms');
          }}
        />
        <MenuItem
          title="개인정보 수집 및 취급 동의"
          onClick={() => {
            navigate('/deals/privacypolicy');
          }}
        />
      </MenuItemBox>
      <MenuItemBox title="기타">
        <MenuItem title="앱 버전" menuType="contents">
          <TextLabel type="content">{process.env.VERSION}</TextLabel>
        </MenuItem>
        <MenuItem title="고객센터">
          <TextLabel type="content">
            <a href="mailto:cs@nbhd.kr">cs@nbhd.kr</a>
          </TextLabel>
        </MenuItem>
      </MenuItemBox>
      <MenuItemBox title={` `}>
        <MenuItem title="로그아웃" onClick={logout} menuType="action" />
        <MenuItem
          title="탈퇴하기"
          onClick={() => {
            withDrawal();
          }}
          menuType="danger"
        />
      </MenuItemBox>
    </>
  );
};

export default MemberMenus;
