import React, { useEffect, useRef, useState } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { IContactItem } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { validationHelper } from 'controls/validationHelper';

import TitleForm from 'components/items/forms/TitleForm';
import styled from 'styled-components';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import Required from 'components/commons/Required';
import DateRangePicker from 'components/items/DateRangePicker';
import { EventType } from 'types/CommonTypes';
import CommonUrl from '../commonControl/CommonUrl';

const CalendarItem = ({ itemModel, seq }: { itemModel?: IContactItem; seq?: number }) => {
  const { uiStore } = useStore();

  const [isValid, setIsValid] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);

  const {
    initialModel,
    setMultipleState,
    updateServiceModelMultiple,
    initialBinding,
    updateServiceModel,
    serviceModel,
    stateModel,
    unmountModel,
  } = useModelManagement<IContactItem>();

  const validate = () => {
    const title = validationHelper.required(serviceModel?.title);
    const start = validationHelper.date(serviceModel?.startDate);
    const end = validationHelper.date(serviceModel?.endDate);

    // const startTemp = moment(serviceModel?.startDate || '');
    // const endTemp = moment(serviceModel?.endDate || '');

    setIsValid(title && start && end);
  };

  const initializeModel = () => {
    if (itemModel) {
      initialBinding(itemModel);
      updateServiceModelMultiple({
        startDate: itemModel.startDate,
        endDate: itemModel.endDate,
      });
      validate();
    } else {
      // UTC 시간을 로컬 시간으로 변환
      // const localTime = moment().startOf('day').hour(8);
      // const start = localTime.minute(0).second(0).format(FORMAT);
      // const end = localTime.minute(30).second(0).format(FORMAT);
      // initialBinding({
      //   startDate: start,
      //   endDate: end,
      //   isActive: true,
      //   seq,
      // });
      // updateServiceModelMultiple({
      //   startDate: start,
      //   endDate: end,
      //   isActive: true,
      // });
    }
  };

  const handleChangeTimeRange = (event: EventType) => {
    if (event) {
      updateServiceModelMultiple({
        startDate: event.start,
        endDate: event.end,
        alldayEvent: event.allDay,
      });
    }
  };

  useEffect(() => {
    if (stateModel) {
      validate();
    }
  }, [stateModel]);

  useEffect(() => {
    initializeModel();
    return () => {
      unmountModel();
    };
  }, []);

  return (
    <div style={{ padding: '0 20px' }}>
      <ItemSectionStyle>
        <ItemSectionTitle>
          일정 타이틀
          <Required />
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            onChangeValue={(value: string) => {
              updateServiceModel('title', value);
              validate();
            }}
            initialValue={serviceModel?.title}
            errorText="타이틀은 필수항목입니다."
            placeHolder="타이틀을 작성해 주세요"
            label="타이틀"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>일정 상세</ItemSectionTitle>
        <ItemContent>
          <TitleForm
            multiline
            rows={3}
            onChangeValue={(value: string) => {
              updateServiceModel('content', value);
            }}
            initialValue={serviceModel?.content}
          />
        </ItemContent>
      </ItemSectionStyle>
      <CommonUrl
        initialValue={initialModel?.linkUrl}
        onChange={(value: string) => {
          updateServiceModel('linkUrl', value);
        }}
      />
      <DateRangePicker
        event={{
          allDay: serviceModel?.alldayEvent,
          start: serviceModel?.startDate,
          end: serviceModel?.endDate,
        }}
        useAllday
        timeRange={(event: EventType) => {
          handleChangeTimeRange(event);
        }}
      />
      <Row style={{ gap: 10 }}>
        <OhmossButton
          type="box"
          style={{ color: '#000' }}
          onClick={() => {
            uiStore.bottomSheet.close();
          }}
        >
          취소
        </OhmossButton>
        <OhmossButton
          onClick={() => {
            validate();
            if (isValid) {
              uiStore.bottomSheet.confirm(serviceModel);
            }
            // else {
            //   toast('잘못된 값이 포함되어 있습니다.', { type: 'warning' });
            // }
          }}
          disabled={!isValid}
        >
          적용
        </OhmossButton>
      </Row>
    </div>
  );
};

const EventStyle = styled.div`
  height: 30px;
`;

export default CalendarItem;
