import React, { useLayoutEffect, useEffect, useState } from 'react';
import styled from 'styled-components';
import GnbComponent from 'features/commonFeatures/navigationBar/containers/GnbComponent';
import SignInMenu from 'features/commonFeatures/navigationBar/containers/SignInMenu';
import PublicMenu from 'features/commonFeatures/navigationBar/containers/PublicMenu';
import useToken from 'libs/hooks/useToken';
import GnbHeader from 'features/commonFeatures/navigationBar/containers/GnbHeader';
import { GnbComponentStyle } from '../components/Styles';
import RootGnbComponent from 'components/commons/RootGnbComponent';

const CompanyPage = () => {
  const { validUser } = useToken();
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    scrollTo(0, 0);
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    console.log(window.innerWidth, isMobile);
  }, [isMobile]);

  useEffect(() => {
    const container = document.querySelector('.universal-layout') as any;
    if (container) {
      container.style.maxWidth = '100%';
      container.style.width = '100%';
    }
  }, []);
  return (
    <div style={{ maxWidth: isMobile ? 550 : 1250, width: '100%', margin: 'auto' }}>
      <RootGnbComponent position="bottom" />
      <Wrapper>
        <NameContainer></NameContainer>
        <img src="/images/landing/company_1.png" alt="company" />
        <TextContainer>
          <TitleText>For the Success of Influencer.</TitleText>
          <ContentText>
            매일 새로운 인플루언서가 탄생하고 있습니다. 대부분은 유튜브나 블로그, 다른 인플루언서의
            인스타그램등을 보며 꿈을 키우고 있습니다. 주식회사 엔비에이치디는 ohmoss앱을 통해
            인플루언서의 비즈니스가 지속하능하게 서비스, 정보, 광고, 상품, 커머스를 연결시켜주는
            것을 목적으로 하고 있습니다.
          </ContentText>
        </TextContainer>
        <TextContainer>
          <SubTitleText>Values</SubTitleText>
        </TextContainer>
        <TextContainer>
          <TitleText>Exceptional team members</TitleText>
          <ContentText>
            소수 정예로 세계에 도전할 수 있는 특출난 멤버를 구성하고 있습니다.
            <br /> 글로벌 인플루언서가 사용할 수 있는 서비스를 만들어 가고 있습니다.
            <br /> We are a small but amazing team with ambitions to build a product that all
            influencers around the globe will use.
          </ContentText>
          <Divider />
        </TextContainer>
        <TextContainer>
          <TitleText>Enjoy the growth of influencer</TitleText>
          <ContentText>
            인플루언서의 성장과 오모스의 성장은 우리에게 동의어입니다. <br />
            우리가 함께 지속적으로 성장할 것임을 믿어 의심치 않습니다. <br />
            The growth of influencer and the growth of ohmoss are synonymous with us. We have no
            doubt that we will continue to grow together.
          </ContentText>
          <Divider />
        </TextContainer>
        <TextContainer>
          <TitleText>Cutting edge technology</TitleText>
          <ContentText>
            인플루언서의 지속적인 성장을 지원하기 위한 새로운 기술을 적용합니다.
            <br /> Embrace new technologies to support the continued growth of influencer.
          </ContentText>
          <Divider />
        </TextContainer>
        <BottomContainer>
          <CompanyLocationContainer>
            <img
              src="images/landing/ohmoss_small_logo.svg"
              alt="logo"
              style={{ marginLeft: '-20px' }}
            />
            <CompanyName>
              주식회사 <strong>엔비에이치디</strong>
            </CompanyName>
            <CompanyLocation>
              49 17 Achasanro SEOUL KOREA
              <br />
              서울 성동구 아차산로17길 49
              <img
                src="/images/landing/location_2.svg"
                alt="location_2"
                style={{ marginLeft: '10px' }}
              />
            </CompanyLocation>
          </CompanyLocationContainer>
          <img
            src="images/landing/friendly_moss.svg"
            alt="friendly_moss"
            style={{ width: '25%' }}
          />
        </BottomContainer>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  @media ${(props) => props.theme.media.mobile} {
    height: 100%;
    margin-top: 0px;
  }
  background-color: #fff;
`;

const RunningIconWrapper = styled.div`
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: white;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 60px;
  width: 300px;
  height: 35px;
  color: 'black';
  font-size: 10px;
  gap: 5px;
  margin-left: 20px;
`;

const TextContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 10px 20px 0 20px;
`;

const TitleText = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

const ContentText = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #797979;
`;

const SubTitleText = styled.div`
  margin-top: 30px;
  color: #a8a8a8;
  font-size: 18px;
  font-weight: bold;
`;

const Divider = styled.div`
  width: 100%;
  background-color: #d8d8d8;
  height: 1px;
  margin: 10px 20px 0 0;
`;

const CompanyName = styled.div`
  font-weight: 400;
  font-size: 15px;
  margin-top: 10px;
`;
const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 20px 0 10px;
  padding-bottom: 30px;
`;
const CompanyLocationContainer = styled.div`
  width: 280px;
  flex-direction: column;
  margin: 10px 20px 0 20px;
`;

const CompanyLocation = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin-top: 10px;
  color: #717171;
`;
export default CompanyPage;
