import React from 'react';
import { makeAutoObservable } from 'mobx';
import {
  MetadataObjectModel,
  MetadataObjectTypes,
  BackgroundProps,
  TextProps,
  SocialProps,
  ChannelProfileModel,
  CustomFile,
  FooterProps,
} from 'types/CommonTypes';

class ProfileStore {
  profileMetadataObject: MetadataObjectModel = {
    backgroundProps: {},
    introductionProps: {},
    nicknameProps: {},
    socialProps: {},
  };

  profile: ChannelProfileModel;

  constructor() {
    makeAutoObservable(this);
  }

  updateProfileMetadata(
    type: MetadataObjectTypes,
    model: BackgroundProps | TextProps | SocialProps | FooterProps,
  ) {
    if (typeof this.profileMetadataObject === 'undefined') {
      return;
    }
    switch (type) {
      case 'background':
        this.profileMetadataObject.backgroundProps = model as BackgroundProps;
        break;
      case 'introduction':
        this.profileMetadataObject.introductionProps = model as TextProps;
        break;
      case 'nickname':
        this.profileMetadataObject.nicknameProps = model as TextProps;
        break;
      case 'social':
        this.profileMetadataObject.socialProps = model as SocialProps;
        break;
      case 'footer':
        this.profileMetadataObject.footerProps = model as FooterProps;
        break;
      default:
        break;
    }
  }

  updateImage(type: MetadataObjectTypes, file: CustomFile | undefined, isDelete: boolean) {
    switch (type) {
      case 'background':
        if (isDelete) {
          delete this.profile.backgroundImage;
        } else {
          this.profile.backgroundImage = file;
        }
        break;

      default:
        break;
    }
  }

  setProfile(profile: ChannelProfileModel) {
    if (profile.metadata) {
      this.profileMetadataObject = JSON.parse(profile.metadata) as MetadataObjectModel;
    } else {
      this.profileMetadataObject = {};
    }
    profile.metadataObject = this.profileMetadataObject;
    this.profile = profile;
  }

  unMount() {}
}

export default ProfileStore;
