import React from 'react';
import { TextContent } from 'types/CommonTypes';

const SelfIntroduction = ({
  selfIntroduction,
  mode,
  selfIntroductionProps,
}: {
  selfIntroduction?: string;
  mode?: 'view' | 'edit';
  selfIntroductionProps?: TextContent;
}) => {
  return (
    <>
      {typeof selfIntroduction !== 'undefined' && (
        <div
          style={{
            ...selfIntroductionProps?.style,
            whiteSpace: 'pre-line',
            background: mode === 'edit' ? '#fafafa' : 'transparent',
            padding: 10,
            borderRadius: 10,
          }}
        >
          {selfIntroduction}
        </div>
      )}
    </>
  );
};

export default SelfIntroduction;
