import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Avatar } from '@mui/material';
import { useStore } from 'stores/StoreHelper';
import { t } from 'i18next';
import { ChannelNotificationDto } from 'services/data-contracts';
import styled from 'styled-components';
import moment from 'moment';
import { Row } from './layouts';
import useNavigation from 'hooks/useNavigation';
import { OhmossButton } from 'resources/controls/OhmossButton';

const MyNotifyInfo = observer(
  ({ onClick }: { onClick?: (alarm: ChannelNotificationDto) => void }) => {
    const { channelStore, myChannelStore, myNotificationStore, uiStore } = useStore();
    const { navigate } = useNavigation();

    const [selected, setSelected] = useState<ChannelNotificationDto>();

    const handleClickNotification = async (notification: ChannelNotificationDto) => {
      onClick?.(notification);
      setSelected(notification);
      await myNotificationStore.read(
        myChannelStore.currentChannel?.id as number,
        notification.id as number,
      );
      myNotificationStore.updateRead(notification.id!);
      await myNotificationStore.getMyNotificationCount(myChannelStore.currentChannel?.id as number);

      if (notification.notificationType === 'Invitation' && notification.link) {
        const receiverChannelId = notification?.receiver?.id;
        const invitationKey = notification?.link?.split('=')[1];
        if (receiverChannelId === myChannelStore?.currentChannel?.id) {
          navigate(`/invitations/${invitationKey}?partnerChannelId=${receiverChannelId}`);
          uiStore.universalModal.close();
        }
      }
    };

    const toTypeString = (type?: string) => {
      switch (type) {
        case 'None':
          return '';
        case 'Event':
          return t('A227');
        case 'Suggest':
          return t('A228');
        case 'Inquiry':
          return t('A229');
        case 'Coupon':
          return t('A230');
        case 'Notice':
          return t('A231');
        case 'News':
          return t('A232');
        default:
          return '';
      }
    };
    useEffect(() => {
      myNotificationStore.getMyNotifications(myChannelStore.currentChannel?.id as number);
      return () => {
        channelStore.unMount();
      };
    }, []);

    return (
      <div style={{ width: '100%' }}>
        {myNotificationStore.notifications.map((notification: ChannelNotificationDto) => (
          <NotificationStyle
            read={notification?.isRead}
            onClick={() => {
              handleClickNotification(notification);
            }}
            // style={{ display: showDetail ? 'none' : 'initial' }}
          >
            <Row style={{ justifyContent: 'flex-start', margin: 20, alignItems: 'flex-start' }}>
              <Avatar src={notification.sender?.profile?.profileImage?.publicUrl} />
              <div style={{ marginLeft: 5, flex: 1 }}>
                <Row style={{ justifyContent: 'flex-start' }}>
                  <TypeStyle>{toTypeString(notification?.notificationType)} </TypeStyle>
                  <div style={{ fontSize: 12, marginLeft: 5 }}>
                    {moment(notification.createDate).fromNow()}
                  </div>
                </Row>
                <div style={{ marginLeft: 5, maxWidth: '90%', marginTop: 3 }}>
                  {selected?.id === notification?.id ? (
                    <>{notification?.contents}</>
                  ) : (
                    <>
                      {notification?.contents?.substring(0, 50)}
                      {notification?.contents && notification?.contents?.length > 40 && '...'}
                    </>
                  )}
                </div>
              </div>
            </Row>
          </NotificationStyle>
        ))}
        <>
          {myNotificationStore.notifications.length === 0 && (
            <ChannelBoxStyle>{t('A226')}</ChannelBoxStyle>
          )}
        </>
        <div style={{ margin: 30 }}>
          <OhmossButton
            onClick={() => {
              uiStore.universalModal.close();
            }}
          >
            닫기
          </OhmossButton>
        </div>
      </div>
    );
  },
);

const NotificationStyle = styled.div<{ read?: boolean }>`
  background: ${(props) => (props.read ? '#fff' : '#efefef')};
  padding: 1px;
  margin: 3px 10px;
  border-radius: 15px;
  cursor: pointer;
`;

const TypeStyle = styled.div`
  font-size: 12px;
  padding: 3px 10px;
  border: 1px solid #555;
  border-radius: 10px;
`;
const ChannelBoxStyle = styled.div`
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${(props) => props.theme.media.mobile} {
    width: 90%;
    margin: auto;
    min-height: 300px;
  }
`;

export default MyNotifyInfo;
