import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { useRootContext } from 'libs/hooks/useRootContext';

const InstagramAuthPage = () => {
  const [searchParam] = useSearchParams();

  const { myChannel } = useRootContext();

  const auth = async () => {
    // https://localhost:3000/my/auth/instagram?code=AQBKa1h1s8q0AKobucnhzLjt5ckcr4EqsqDLb9h38lYHkOOitTLEe_rEITVq7SXzqRWGVVsgfvwM_eDsFHEhepOF9sKPJumtOnXkzhF6rpp5VHUVOSkXDcQou5dLb6esyo5HHAQfpe7v35h0OaiMQgjMnHYRApDbvs7lpg5N5iP7haR4KUyrdZqM2AoTJc0-ErkyI_-MsfTsh8Du94W9l4UMFwAet1cVr1-zHaVoUZcRQf_linNrBSbYEt6Aw_qHJMzvif1lqwFu1n-QHTRuRqIbCm0Cfy885_E3D4Kd-Wml0XvJteC0TtGG71DfjawCXhP6Y7BnvdFq1_DkwGggGaW29K7Bsv9E3g3_RjMJFncE8ML7AyOvf5sVlLYz5XZwUL0#_=_
    const code = searchParam.get('code');
    console.log('code', location);
    // if (code) {
    //   const result = await myChannelStore.authInstagram(
    //     myChannel?.id as number,
    //     code + location.hash,
    //     'https://localhost:3000/my/auth/instagram',
    //   );

    //   alert(result);
    // }
  };
  useEffect(() => {
    if (myChannel) {
      auth();
    }
  }, [myChannel]);
  return <></>;
};

const ContainerStyle = styled.div`
  padding: 20px;
  width: 100%;
`;

export default InstagramAuthPage;
