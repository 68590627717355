import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStore } from 'stores/StoreHelper';
import styled from 'styled-components';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { toast } from 'react-toastify';
import { IconButton } from '@mui/material';
import { Row } from '../layouts';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { copyToClipboard } from 'libs/helper/utils';

interface OhmossAIProps {
  onClick?: () => void;
  content?: string;
}

const OhmossAI: React.FC<OhmossAIProps> = observer(({ content, onClick }) => {
  const { aiStore } = useStore();

  useEffect(() => {
    return () => {
      aiStore.unmount();
    };
  }, []);

  return (
    <>
      <AIContainerStyle>
        <TitleStyle>✨oh!moss AI적용</TitleStyle>
        <SubTitleStyle>무료사용 이벤트중입니다!</SubTitleStyle>
        <div style={{ textAlign: 'center', marginBottom: 20 }}>
          <OhmossButton onClick={onClick} style={{ width: 200, height: 35 }}>
            내용 멋지게 바꿔보기
          </OhmossButton>
        </div>
        {aiStore.busyStatus === 'init' && <img src="/images/ohmossai.svg" style={{ height: 20 }} />}
        {aiStore.messages && aiStore.messages.length > 0 && (
          <ContentStyle>{aiStore.messages}</ContentStyle>
        )}
        {aiStore.busyStatus === 'done' && (
          <Row
            style={{ width: 'unset', justifyContent: 'flex-end', cursor: 'pointer' }}
            onClick={() => {
              copyToClipboard(aiStore.messages);
            }}
          >
            <IconButton>
              <AssignmentOutlinedIcon />
            </IconButton>
            <div>내용복사</div>
          </Row>
        )}
      </AIContainerStyle>
    </>
  );
});

const AIContainerStyle = styled.div`
  padding: 10px;
  border: 1px solid #0bbd58;
  border-radius: 8px;
`;

const ContentStyle = styled.div``;

const TitleStyle = styled.div`
  font-size: 20px;
  font-weight: 700;
  text-align: center;
`;

const SubTitleStyle = styled.div`
  color: #bebebe;
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
`;
export default OhmossAI;
