import React, { ReactNode } from 'react';

import { useModelManagement } from 'libs/hooks/useModelUpdate';
import { ChannelProfileDto } from 'services/data-contracts';
import { ChannelDto } from 'services/data-contracts';

import FileStore from 'stores/FileStore';
import MyChannelStore from 'stores/MyChannelStore';
import { ChannelProfileDtoDummy } from '../dummy';
import { Context } from '../useOhmossContext';
import { CustomFile } from 'types/BlockExtendsTypes';
import { useRootContext } from 'libs/hooks/useRootContext';

interface PageProviderProps<T> {
  children: ReactNode | ReactNode[] | Element | Element[];
}

const ProfileProvider = <T extends ChannelProfileDto>({ children }: PageProviderProps<T>) => {
  const fileStore = new FileStore();
  const myChannelStore = new MyChannelStore();

  /** Primitive */
  const serviceItems = useModelManagement<T>();
  const { serviceModel, extractAdditionalProperties } = serviceItems;
  const { myChannel } = useRootContext();

  /**
   * @description 채널의 프로필을 업데이트한다.
   * @param standAlonModel
   * @returns
   */
  const updateProfile = async (standAlonModel?: T) => {
    const newModel = standAlonModel || serviceModel;
    try {
      if (typeof newModel === 'undefined') {
        return;
      }

      if ((newModel?.profileImage as CustomFile)?.blob) {
        const file = (await fileStore.uploadImage(
          (serviceModel?.profileImage as CustomFile)?.blob,
          'Profile',
        )) as any;
        newModel.profileImage = { id: file.id };
      }

      if ((newModel?.backgroundImage as CustomFile)?.blob) {
        const file = (await fileStore.uploadImage(
          (serviceModel?.backgroundImage as CustomFile)?.blob,
          'Background',
        )) as any;
        newModel.backgroundImage = { id: file.id };
      }

      if ((newModel?.profileBackgroundImage as CustomFile)?.blob) {
        const file = (await fileStore.uploadImage(
          (serviceModel?.profileBackgroundImage as CustomFile)?.blob,
          'Profile',
        )) as any;
        newModel.profileBackgroundImage = { id: file.id };
      }

      // extended로 확장된 properties를 추출한다.
      const extracted = extractAdditionalProperties<T, ChannelProfileDto>(
        newModel,
        ChannelProfileDtoDummy,
      );
      if (typeof extracted !== 'undefined') {
        newModel.metadata = JSON.stringify(extracted);
        //  newModel.metadata = '{}';
      }

      const result = (await myChannelStore.updateItem(
        myChannel?.id as number,
        newModel,
      )) as unknown as ChannelDto;

      return result;
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {typeof children !== 'undefined' ? (
        <Context.Provider
          value={{
            updateProfile,
            ...serviceItems,
          }}
        >
          {children}
        </Context.Provider>
      ) : (
        <></>
      )}
    </>
  );
};

export default ProfileProvider;
