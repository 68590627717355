import React, { useEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { ChannelBoardDto } from 'services/data-contracts';
import BoardComponent from '../components/BoardComponent';
import styled from 'styled-components';
import { Badge } from '@mui/material';

const ListOfBoardsContainer = observer(
  ({
    draggable,
    onBoardSelect,
    onBoardDelete,
    onLinkEdit,
    onVisibleChange,
  }: {
    draggable?: boolean;
    onBoardSelect?: (board: ChannelBoardDto) => void;
    onBoardDelete?: (board: ChannelBoardDto) => void;
    onLinkEdit?: (board: ChannelBoardDto) => void;
    onVisibleChange: (board: ChannelBoardDto, visible: boolean) => void;
  }) => {
    const { t } = useTranslation();
    const { myChannelStore, myBoardStore } = useStore();
    const { currentChannel } = myChannelStore;
    const [boards, setBoards] = useState<Array<ChannelBoardDto>>([]);
    const grid = 1;

    const reorder = (startIndex: number, endIndex: number): Array<ChannelBoardDto> => {
      const result = Array.from(boards);
      const [dragItem] = result.splice(startIndex, 1);

      /** 서버 반영전, UI 변경 목적 */
      result.splice(endIndex, 0, dragItem);

      /** 옮긴 링크의 seq를 옮긴 index + 1 값으로 수정해준다 */
      dragItem.seq = endIndex + 1;

      /** 링크 순번 수정 */
      if (dragItem.id) {
        myBoardStore.reOrderBoardItem(
          currentChannel?.id as number,
          dragItem?.id as number,
          dragItem as ChannelBoardDto,
        );
      }

      return result;
    };

    const onDragEnd = (result: DropResult): void => {
      if (!result.destination) {
        return;
      }
      const dragLinks: Array<ChannelBoardDto> = reorder(
        result.source.index,
        result.destination.index,
      );
      setBoards(dragLinks);
    };

    /** (라이브러리) Droppable 스타일링 */
    const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
      padding: grid,
      width: '100%',
    });

    /** (라이브러리) Draggable 스타일링 */
    const getItemStyle = (
      isDragging: boolean,
      draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
    ): React.CSSProperties => ({
      ...draggableStyle,
      userSelect: 'none',
      padding: grid * 2,
      margin: `0 0 ${grid}px 0`,
      left: '0 !important',
      top: '0 !important',
    });

    const BoardComponetWrapper = (board: ChannelBoardDto) => {
      return (
        <BoardComponent
          style={{ height: 90 }}
          item={board}
          child={
            <>
              <div style={{ fontWeight: 600, paddingBottom: 10 }}>
                {board.name} ({board.postCount || 0} Items)
              </div>
              <div style={{ fontSize: 13 }}>
                <BoardTypeStyle>{board.boardType}</BoardTypeStyle>{' '}
                {board.boardType !== 'LINK' && (
                  <>
                    <BoardTypeStyle style={{ background: '#337c99' }}>
                      {board.viewType}
                    </BoardTypeStyle>
                  </>
                )}
              </div>
            </>
          }
          on={false}
          onSelect={() => {
            if (typeof onBoardSelect === 'function') onBoardSelect(board);
          }}
          onDelete={() => {
            if (typeof onBoardDelete === 'function') onBoardDelete(board);
          }}
          onShowChange={(isShow: boolean) => {
            handleLinkShowChanged(board, isShow);
          }}
        />
      );
    };

    const handleLinkShowChanged = async (board: ChannelBoardDto, isShow: boolean) => {
      if (onVisibleChange) onVisibleChange(board, isShow);
    };

    useEffect(() => {
      setBoards(myBoardStore.myBoards);
    }, [myBoardStore.myBoards]);

    return (
      <>
        {draggable === true ? (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot): JSX.Element => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {boards?.map((board: ChannelBoardDto, index: number) => (
                      <Draggable key={board?.id} draggableId={String(board?.id)} index={index}>
                        {(provided, snapshot): JSX.Element => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <BoardComponetWrapper {...board} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        ) : (
          <>
            {boards?.map((board: ChannelBoardDto, index: number) => (
              <div key={index}>
                <BoardComponetWrapper {...board} />
              </div>
            ))}
          </>
        )}
      </>
    );
  },
);

const BoardTypeStyle = styled.div`
  background: #efefef;
  display: inline-block;
  padding: 3px 10px;
  margin-right: 3px;
  border-radius: 15px;
  background: rebeccapurple;
  color: #fff;
`;

export default ListOfBoardsContainer;
