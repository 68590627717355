/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { LinkDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class LinkService2<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 링크외부이동클릭
   *
   * @tags LinkService2
   * @name RecodeLinkOutboundClick
   * @summary 링크외부이동클릭
   * @request POST:/v2/links/{linkKey}/outbound-click
   * @secure
   */
  recodeLinkOutboundClick = (linkKey: string, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/links/${linkKey}/outbound-click`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 링크앵콜
   *
   * @tags LinkService2
   * @name EncoreLink
   * @summary 링크앵콜
   * @request POST:/v2/links/{linkKey}/encore
   * @secure
   */
  encoreLink = (
    linkKey: string,
    query: {
      /** @format int64 */
      ownChannelId: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/v2/links/${linkKey}/encore`,
      method: 'POST',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description 링크클릭
   *
   * @tags LinkService2
   * @name RecodeLinkClick
   * @summary 링크클릭
   * @request POST:/v2/links/{linkKey}/click
   * @secure
   */
  recodeLinkClick = (linkKey: string, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/links/${linkKey}/click`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 링크정보
   *
   * @tags LinkService2
   * @name GetLink2
   * @summary 링크정보
   * @request GET:/v2/links/{linkKey}
   * @secure
   */
  getLink2 = (linkKey: string, params: RequestParams = {}) =>
    this.http.request<LinkDto, any>({
      path: `/v2/links/${linkKey}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 링크 맴버목록
   *
   * @tags LinkService2
   * @name GetDealMembers
   * @summary 링크 맴버목록
   * @request GET:/v2/links/{linkKey}/members
   * @secure
   */
  getDealMembers = (linkKey: string, params: RequestParams = {}) =>
    this.http.request<string[], any>({
      path: `/v2/links/${linkKey}/members`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
