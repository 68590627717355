import axios, { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { isExpired } from 'react-jwt';
import { v4 as uuidv4 } from 'uuid';

import _ from 'lodash';
import { REST_API_URL_V2 } from 'stores/AppStore';
import { getDeviceToken } from 'libs/helper/utils';

const RefreshToken = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  if (accessToken && refreshToken) {
    const expired = isExpired(accessToken as string);
    //const expired = true;
    if (expired) {
      // 토큰 갱신 서버통신
      await axios({
        method: 'post',
        url: `${REST_API_URL_V2}v2/auth/refresh-token`,
        data: {
          accessToken: String(accessToken),
          refreshToken: String(refreshToken),
          deviceToken: getDeviceToken(),
        },
      })
        .then((response) => {
          localStorage.setItem('accessToken', response.data.accessToken);
          localStorage.setItem('refreshToken', response.data.refreshToken);
          if (config.headers) {
            config.headers['Authorization'] = `Bearer ${response.data.accessToken}`;
          }
        })
        .catch((response) => {
          console.log('token refresh error');
          // alert('token refresh error');
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
        });
    } else {
      if (config.headers) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
    }

    return config;
  } else {
    return config;
  }
};

// eslint-disable-next-line class-methods-use-this
const getUUID = () => {
  // const email = getUserEmail();
  // const uuid = localStorage.getItem(`${email}_uuid`);
  const uuid = localStorage.getItem(`uuid`);
  if (uuid === null) {
    const uuid = uuidv4();
    localStorage.setItem(`uuid`, uuid);
    return uuid;
  }
  return uuid;
};

const refreshErrorHandle = (err: any) => {
  localStorage.removeItem('refreshToken');
};

export { RefreshToken, refreshErrorHandle };
