import React, { useEffect, useState } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';

import { OhmossButton } from 'resources/controls/OhmossButton';
import { ISeparatorBlock } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { LineThumbnails } from 'resources/images/separators/thumbnails';
import styled from 'styled-components';
import MUISlider from 'components/commons/buttons/MUISlider';
import TitleForm from 'components/items/forms/TitleForm';
import { toast } from 'react-toastify';
import { LinkTypes } from 'features/management/context/providers/ContextTypes';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import CommonColorSet from '../commonControl/CommonColorSet';
import BlockController from 'controllers/BlockController';
import ActionBox from 'features/csTown/components/pc/ActionBox';

const Separator = ({ blockModel, seq }: { blockModel?: ISeparatorBlock; seq?: number }) => {
  const { uiStore } = useStore();
  const defaultProps: Partial<ISeparatorBlock> = {
    linkType: 'Seperator',
    title: '구분선',
    separatorType: 'line1',
    useTransparent: true,
    isActive: true,
    seq,
  };

  const { initialModel, initialBinding, updateServiceModel, serviceModel, unmountModel } =
    useOhmossContext<LinkTypes<ISeparatorBlock>>();

  const { updateBlock } = BlockController<ISeparatorBlock>();

  useInitializeModel<Partial<ISeparatorBlock>>(blockModel, defaultProps, initialBinding);

  const [widthRate, setWidthRate] = useState<number | undefined>(80);
  const [selectedLine, setSelectedLine] = useState<string | undefined>('line1');
  const separatorTypes = ['line1', 'line2', 'line3', 'line4'];

  const Thumbnail = (props: any) => {
    // eslint-disable-next-line react/destructuring-assignment
    const Temp = props.icon;
    return <Temp />;
  };
  const initializeModel = () => {
    if (blockModel) {
      setSelectedLine(blockModel?.separatorType);
      setWidthRate(blockModel?.widthRate);
      initialBinding(blockModel);
    }
  };

  useEffect(() => {
    initializeModel();
    return () => {
      unmountModel();
    };
  }, [blockModel]);

  return (
    <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      <div style={{ flexGrow: 1 }}>
        <ItemSectionStyle>
          <ItemSectionTitle>종류</ItemSectionTitle>
          <ItemContent>
            <Row style={{ gap: '20px', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
              {separatorTypes.map((separatorType) => (
                <DefaultIconStyle
                  selected={separatorType === selectedLine}
                  onClick={() => {
                    updateServiceModel('separatorType', separatorType);
                    setSelectedLine(separatorType);
                  }}
                >
                  {separatorType === 'line1' ? (
                    <SolidLineStyle />
                  ) : separatorType === 'line2' ? (
                    <DottedLineStyle />
                  ) : separatorType === 'line3' ? (
                    <DashedLineStyle />
                  ) : (
                    <DoubleLineStyle />
                  )}
                </DefaultIconStyle>
              ))}
            </Row>
          </ItemContent>
        </ItemSectionStyle>
        <ItemSectionStyle>
          <ItemSectionTitle>폭 비율</ItemSectionTitle>
          <ItemContent>
            <Row style={{ marginLeft: 10 }}>
              <MUISlider
                min={60}
                onChange={(e: any, value: number) => {
                  setWidthRate(value);
                  updateServiceModel('widthRate', value);
                }}
                value={widthRate}
              />
              <div style={{ marginLeft: 20, width: 115 }}>
                <TitleForm
                  validationType="number"
                  surfix="%"
                  initialValue={widthRate?.toString() || initialModel?.widthRate?.toString()}
                  onChangeValue={(value: string) => {
                    const heightNumber = parseInt(value, 10);
                    if (Number.isNaN(heightNumber)) {
                      toast('숫자형식으로 입력해주세요.', { type: 'warning' });
                      setWidthRate(80);
                      updateServiceModel('widthRate', 80);
                      return;
                    }
                    if (heightNumber > 100) {
                      toast('폭은 100%까지만 허용됩니다.', { type: 'warning' });
                      setWidthRate(100);
                      updateServiceModel('widthRate', 100);

                      return;
                    }
                    setWidthRate(heightNumber);
                    updateServiceModel('widthRate', heightNumber);
                  }}
                />
              </div>
            </Row>
          </ItemContent>
        </ItemSectionStyle>
        <CommonColorSet
          title="라인 색상"
          initialValue={initialModel?.strokeColor}
          onChange={(value: string) => {
            updateServiceModel('strokeColor', value);
          }}
        />
      </div>
      <ActionBox
        style={{ marginTop: 60, paddingBottom: 20 }}
        isValid={true}
        okText="저장"
        onOk={() => {
          updateServiceModel('widthRate', widthRate);
          updateBlock(serviceModel);
        }}
        onCancel={() => {
          uiStore.modal.close();
        }}
      />
    </div>
  );
};

const DefaultIconStyle = styled.div<{ selected?: boolean; valid?: boolean }>`
  min-width: 60px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  outline: ${(props) => (props.selected ? '2px solid #2ebe59' : '2px solid transparent')};
  outline-offset: 3px;
  transition: background 0.2s ease-in-out;

  :hover {
    background: #efefef;
  }
`;

const SolidLineStyle = styled.div`
  height: 1.5px;
  background-color: #6e6e6e;
  width: 30px;
`;
const DottedLineStyle = styled.div`
  width: 30px;
  height: 0;
  border-top: 1px dotted #6e6e6e;
`;

const DashedLineStyle = styled.div`
  width: 30px;
  height: 0;
  border-top: 1px dashed #6e6e6e;
`;
const DoubleLineStyle = styled.div`
  width: 30px;
  height: 0;
  border-top: 5px double #6e6e6e;
`;
export default Separator;
