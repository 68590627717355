import React, { useEffect, useState, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';

import styled from 'styled-components';
import SocialComponent from 'features/commonFeatures/SocialComponent';
import Footer from 'components/items/Footer';
import NameCard from './NameCard';
import TemplateCommon from './TemplateCommon';

const ProfileTemplate = observer(() => {
  const { channelStore, uiStores, socialStore } = useStore();
  const { profileStore } = uiStores;
  const avatarRef = useRef<any>(null);

  const [socialPosition, setSocialPosition] = useState<'top' | 'bottom'>();

  const handleScroll = () => {
    const currentScroll = window.scrollY;

    if (avatarRef.current !== null) {
      avatarRef.current.style.top = `${-currentScroll / 2}px`;
      avatarRef.current.style.height = `${(isMobile ? 600 : 530) - currentScroll / 2}px`;
    }
  };

  const getTemplateCommon = () => {
    return <TemplateCommon />;
  };

  useEffect(() => {
    setSocialPosition(profileStore.profile?.metadataObject?.socialProps?.position);
    const t = uiStores.userHomeStore.currentBoard?.useProfile;
  }, [profileStore.profile]);

  useEffect(() => {
    const t = uiStores.userHomeStore.currentBoard?.useProfile;
  }, [uiStores.userHomeStore.currentBoard]);

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', () => {
      handleScroll();
    });
  }, []);

  return (
    <StackStyle>
      {uiStores.userHomeStore.currentBoard?.useProfile !== false ? (
        <ProfileWrapStyle isMine={channelStore.channel?.isOwner} isDevice={isMobile}>
          <div ref={avatarRef}>
            <img
              src={profileStore.profile?.profileImage?.publicUrl || '/images/ohmossc1.png'}
              alt=""
              style={{ width: '100%', height: '100%', objectFit: 'cover', aspectRatio: 1 }}
            />
          </div>
        </ProfileWrapStyle>
      ) : (
        <div style={{ marginTop: 80 }} />
      )}
      <ItemContainerStyle useProfile={uiStores.userHomeStore.currentBoard?.useProfile}>
        <NameCard profileInfo={profileStore.profile} type={'filled'} />
        <div style={{ padding: '0 10px' }}>{getTemplateCommon()}</div>
      </ItemContainerStyle>
      <>
        {socialPosition === 'bottom' && (
          <div style={{ marginTop: 30 }}>
            <SocialComponent
              socials={socialStore?.socials}
              socialProps={profileStore.profile?.metadataObject?.socialProps}
            />
          </div>
        )}
      </>
      <Footer model={profileStore.profile} />
    </StackStyle>
  );
});

const StackStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`;

const ProfileWrapStyle = styled.div<{ isMine?: boolean; isDevice?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  max-width: 550px;
  margin: auto;

  justify-content: center;
  > div:first-child {
    overflow: hidden;
    width: 550px;
    margin-top: 90px;
    margin-bottom: 20px;
    top: 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    position: fixed;
    aspect-ratio: 1;
    @media ${(props) => props.theme.media.mobile} {
      margin-top: 100px;
    }
  }
`;

const ItemContainerStyle = styled.div<{ useProfile?: boolean }>`
  margin-top: ${(props) => (props.useProfile ? '550px' : '0')};
  padding-bottom: 10px;
  height: 320px;
  flex: 3;
  z-index: 1;
  @media ${(props) => props.theme.media.mobile} {
    margin-top: ${(props) => (props.useProfile ? '550px' : '40px')};
  }
  position: relative;
`;

export default ProfileTemplate;
