import React from 'react';
import { Navigate } from 'react-router-dom';

import useToken from 'libs/hooks/useToken';

const AuthContainer = ({
  isPublic,
  title,
  children,
}: {
  isPublic?: boolean;
  title?: string;
  children: JSX.Element;
}) => {
  const { validUser } = useToken();

  if (isPublic !== true) {
    const t = title;
    if (validUser()) {
      return children;
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return children;
  }
};

export default AuthContainer;
