import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import useChannelSelector from 'libs/hooks/useChannelSelector';
import MossCoinContainer from '../containers/MossCoinContainer';
import DashboardProvider from '../context/dashboard/DashboardProvider';

const MossCoin = observer(() => {
  const { initializeFirstChannel } = useChannelSelector();

  const init = async () => {
    await initializeFirstChannel();
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <DashboardProvider>
      <MossCoinContainer />
    </DashboardProvider>
  );
});

export default MossCoin;
