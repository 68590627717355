/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ChannelBoardDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MyBoard<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 게시판 정보
   *
   * @tags my board
   * @name GetBoard
   * @summary 게시판 정보
   * @request GET:/v2/me/channels/{channelId}/boards/{boardId}
   * @secure
   */
  getBoard = (channelId: number, boardId: number, params: RequestParams = {}) =>
    this.http.request<ChannelBoardDto, any>({
      path: `/v2/me/channels/${channelId}/boards/${boardId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 게시판 수정
   *
   * @tags my board
   * @name UpdateBoard
   * @summary 게시판 수정
   * @request PUT:/v2/me/channels/{channelId}/boards/{boardId}
   * @secure
   */
  updateBoard = (
    channelId: number,
    boardId: number,
    data: ChannelBoardDto,
    params: RequestParams = {},
  ) =>
    this.http.request<ChannelBoardDto, any>({
      path: `/v2/me/channels/${channelId}/boards/${boardId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 게시판 삭제
   *
   * @tags my board
   * @name DeleteBoard
   * @summary 게시판 삭제
   * @request DELETE:/v2/me/channels/{channelId}/boards/{boardId}
   * @secure
   */
  deleteBoard = (channelId: number, boardId: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/boards/${boardId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 게시판 순번 수정
   *
   * @tags my board
   * @name UpdateSeqOfBoard
   * @summary 게시판 순번 수정
   * @request PUT:/v2/me/channels/{channelId}/boards/{boardId}/seq
   * @secure
   */
  updateSeqOfBoard = (
    channelId: number,
    boardId: number,
    data: ChannelBoardDto,
    params: RequestParams = {},
  ) =>
    this.http.request<ChannelBoardDto, any>({
      path: `/v2/me/channels/${channelId}/boards/${boardId}/seq`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 게시판 목록
   *
   * @tags my board
   * @name GetBoards
   * @summary 게시판 목록
   * @request GET:/v2/me/channels/{channelId}/boards
   * @secure
   */
  getBoards = (
    channelId: number,
    query?: {
      isActive?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ChannelBoardDto[], any>({
      path: `/v2/me/channels/${channelId}/boards`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 게시판 생성
   *
   * @tags my board
   * @name CreateBoard
   * @summary 게시판 생성
   * @request POST:/v2/me/channels/{channelId}/boards
   * @secure
   */
  createBoard = (channelId: number, data: ChannelBoardDto, params: RequestParams = {}) =>
    this.http.request<ChannelBoardDto, any>({
      path: `/v2/me/channels/${channelId}/boards`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
