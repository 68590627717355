import React, { useContext, useEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { BottomSheet as BottomSheetOrigin } from 'react-spring-bottom-sheet';

import { OhmossButton } from 'resources/controls/OhmossButton';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import configurationContext from 'contexts/configurationContext';
import { UniversalOptions } from 'types/CommonTypes';
import { Row } from './layouts';
import 'react-spring-bottom-sheet/dist/style.css';
import CloseIcon from '@mui/icons-material/Close';

const UniversalHeader = observer(
  ({ topPosition, useDefaultButtons }: { topPosition?: number; useDefaultButtons?: boolean }) => {
    const { uiStore } = useStore();
    const defaultTopPosition = topPosition || 10;
    const config = useContext(configurationContext);
    const [options, setOptions] = useState<UniversalOptions>();

    // const getSnapPoint = (heightProps: any) => {
    //   if (typeof options?.bottomSheetOptions?.topPosition === 'undefined') {
    //     return [heightProps.minHeight];
    //   }
    //   return [
    //     heightProps.maxHeight - (options?.bottomSheetOptions?.topPosition || defaultTopPosition),
    //   ];
    // };

    useEffect(() => {
      setOptions(uiStore.universalModal.options);
    }, [uiStore.universalModal.options]);

    useEffect(() => {
      if (!uiStore.universalModal.modalShow) {
        const body = document.querySelector('body');
        if (body !== null) {
          body.style.overflow = '';
        }
      }
    }, [uiStore.universalModal.modalShow]);

    return (
      <>
        <TitleStyle isSingle={typeof uiStore.confirm.options?.title === 'undefined'}>
          <Row style={{ justifyContent: 'flex-start' }}>
            <LeftIconStyle />

            <MainTitleStyle>
              {typeof uiStore.universalModal.options?.title === 'undefined'
                ? ''
                : uiStore.universalModal.options?.title}
              <DescriptionStyle>
                {typeof uiStore.universalModal.options?.description !== 'undefined' &&
                  uiStore.universalModal.options?.description}
              </DescriptionStyle>
            </MainTitleStyle>

            {/* <CloseButtonStyle
              onClick={() => {
                if (typeof uiStore.universalModal.options?.onClose !== 'undefined') {
                  uiStore.universalModal.options?.onClose();
                }
                uiStore.universalModal.close();
              }}
            >
              <CloseIcon />
            </CloseButtonStyle> */}
          </Row>
        </TitleStyle>
      </>
    );
  },
);

const TitleStyle = styled.div<{ isSingle?: boolean }>`
  display: flex;
  min-height: 60px;
  width: 100%;

  @media ${(props) => props.theme.media.mobile} {
    font-size: 18px;
    text-align: center;
    margin: 20px 0 0 0;
  }
  @media ${(props) => props.theme.media.desk} {
    font-size: 18px;
    text-align: center;
    margin: 20px 0 0 0;
  }
`;

const MainTitleStyle = styled.div`
  margin-left: 14px;
  font-weight: 600;
  text-align: left;
  width: 100%;
  & > div {
    text-align: left;
  }
`;

const DescriptionStyle = styled.div`
  text-align: left;
  font-size: 14px;
  margin-top: 5px;
  font-weight: 400;
`;

const CloseButtonStyle = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

const LeftIconStyle = styled.div`
  width: 20px;
  height: 100%;
  left: 0;
  background: ${(props) => props.theme.colors.defaultBackground};
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
`;

export default UniversalHeader;
