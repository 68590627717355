import { Avatar } from '@mui/material';
import React, { CSSProperties, useEffect, useState } from 'react';

const Thumbnail = ({
  src,
  size = '150',
  prefix,
  style,
}: {
  src?: string;
  size?: '150' | '300';
  prefix: 'ChannelBoard' | 'Profile';
  style?: CSSProperties;
}) => {
  const [profileUrl, setProfileUrl] = useState<string>('');
  useEffect(() => {
    const regex = new RegExp(`/${prefix}`);
    const newUrl = src?.replace(regex, `/thumbnails/${size}/${prefix}`);

    setProfileUrl(newUrl || '');
  }, [src, size]);

  return (
    <>
      <img src={profileUrl} style={style} />
    </>
  );
};

export default Thumbnail;
