import { PostDto } from 'services/data-contracts';

export const postDtoDummy: PostDto = {
  id: undefined,
  channel: undefined,
  board: undefined,
  postType: undefined,
  title: undefined,
  content: undefined,
  startDate: undefined,
  endDate: undefined,
  linkUrl: undefined,
  thumbnail: undefined,
  metadata: undefined,
  postKey: undefined,
  likeCount: undefined,
  address: undefined,
  latitude: undefined,
  longitude: undefined,
  isLike: undefined,
  isActive: undefined,
  isModify: undefined,
  isDelete: undefined,
  isBlock: undefined,
  ad: undefined,
  items: undefined,
  tags: undefined,
  commentCount: undefined,
  createDate: undefined,
  modifyDate: undefined,
  openNewWindow: undefined,
  thumbnailTitle: undefined,
  thumbnailContent: undefined,
  thumbnailPrice: undefined,
  thumbnailPriceUnit: undefined,
};
