import React from 'react';

import { observer } from 'mobx-react';
import { MenuType } from 'types/CommonTypes';

import AdminLnbLong from './AdminLnbLong';
import AdminLnbShort from './AdminLnbShort';

const AdminLnb = observer(
  ({
    selectedIndex,
    onChangeMode,
    handleShowMenu,
  }: {
    selectedIndex?: number;
    onChangeMode?: (id: MenuType) => void;
    handleShowMenu?: (show: boolean, menu: string | undefined) => void;
  }) => {
    return (
      <>
        <AdminLnbLong onChangeMode={onChangeMode} />
        <AdminLnbShort onChangeMode={onChangeMode} />
      </>
    );
  },
);

export default AdminLnb;
