
import { useSearchParams } from 'react-router-dom';

function useParam() {
  const [searchParams] = useSearchParams();
  /**
   * @param channel
   * @description 로그인한 시점에 마지막 사용한 채널을 취득하거나 없을경우 첫번째
   * @description 채널로 설정한다.
   */
  const get = (key: string, type: 'string' | 'number') => {
    const temp = searchParams.get(key);
    if (temp) {
      switch (type) {
        case 'number':
          const temp2 = parseInt(temp);
          return Number.isNaN(temp2) ? NaN : temp2;
        case 'string':
          return temp;
        default:
          return temp;
          break;
      }
    } else {
      return NaN;
    }
  };

  return {
    get
  };
}
export default useParam;
