import styled from 'styled-components';

const OverlayStyle = styled.div<{ isShow: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${(props) => (props.isShow ? 'rgba(0, 0, 0, 0.5)' : 'transparent')};
  z-index: 1;
  /* backdrop-filter: ${(props) => (props.isShow ? 'blur(3px)' : 'none')}; */
  pointer-events: ${(props) => (props.isShow ? 'box' : 'none')};
  transition:
    backdrop-filter 0.3s ease-in-out,
    background 0.3s ease-in-out;
`;

export default OverlayStyle;
