import { makeObservable, flow, observable } from 'mobx';
import { MyChannelConfig as MyChannelConfigService } from 'services/MyChannelConfig';
import { HttpClient } from 'services/http-client';
import { ChannelConfigModel } from 'types/CommonTypes';
import ServiceStoreBase from './ServiceStoreBase';
import i18n from 'i18n';

/**
 * @description Public Link Store
 */
class MyChannelConfigStore extends ServiceStoreBase {
  t = i18n.t;

  constructor() {
    super();
    makeObservable(this, {
      saveSettings: flow,
      channelConfig: observable,
    });
  }

  api: MyChannelConfigService<unknown> = new MyChannelConfigService(new HttpClient());

  channelConfig: ChannelConfigModel | null;

  *getItems(args?: Array<any>) {
    if (args) {
      const { response } = yield this.api.getChannelConfig(args[0]);
      if (response.status === 404) {
        this.channelConfig = null;
      } else if (response.data.metadata) {
        this.channelConfig = {
          ...response.data,
          metadataObject: JSON.parse(response.data.metadata),
        };
      } else {
        this.channelConfig = response.data;
      }
    }
  }

  *saveSettings(channelId: number, configInfo: ChannelConfigModel) {
    if (typeof configInfo.metadataObject !== 'undefined') {
      configInfo.metadata = JSON.stringify(configInfo.metadataObject);
      delete configInfo.metadataObject;
    }
    // configInfo.isChannelPrivate = false;

    const { response } = yield this.api.saveChannelConfig(channelId, configInfo);
    super.handleByStatus(response, { successMessage: this.t('A260') });
    if (response.status === 200) {
      return response.data;
    }
  }
}

export default MyChannelConfigStore;
