import GnbComponent from 'features/commonFeatures/navigationBar/containers/GnbComponent';
import GnbHeader from 'features/commonFeatures/navigationBar/containers/GnbHeader';
import PublicMenu from 'features/commonFeatures/navigationBar/containers/PublicMenu';
import SignInMenu from 'features/commonFeatures/navigationBar/containers/SignInMenu';
import { GnbComponentStyle } from 'features/public/landing/components/Styles';
import useToken from 'libs/hooks/useToken';
import React, { useRef } from 'react';

const RootGnbComponent = ({
  current,
  position,
}: {
  current?: 'MAG' | 'LINK';
  position?: 'top' | 'bottom';
}) => {
  const gnbRef = useRef<any>(null);
  const { validUser } = useToken();

  const handleClick = () => {
    gnbRef.current.close();
  };

  return (
    <GnbComponentStyle>
      <GnbComponent
        defaultComponent={<GnbHeader />}
        ref={gnbRef}
        current={current}
        position={position}
      >
        <>{validUser() ? <SignInMenu onClick={handleClick} /> : <PublicMenu />}</>
      </GnbComponent>
    </GnbComponentStyle>
  );
};

export default RootGnbComponent;
