import DetailPageHeader from 'components/commons/DetailPageHeader';
import MobileModalHeader from 'components/commons/MobileModalHeader';
import React from 'react';

const DashboardContainer = () => {
  return (
    <>
      {/* <DetailPageHeader title="내 대시보드" /> */}
      Dashboard
    </>
  );
};

export default DashboardContainer;
