import { useMessageContext } from 'context/MessageProvider';
import {
  OWebViewPostMessageData,
  OWebViewPostMessageDataType,
} from 'ohmoss-common-types/dist/owebviewTypes';
import { useMemo } from 'react';

export default function useOWebViewMessage<T extends OWebViewPostMessageDataType>(dataType: T) {
  const { message } = useMessageContext();

  return useMemo(
    () => (message?.type === dataType ? (message as OWebViewPostMessageData<T>) : undefined),
    [message, dataType],
  );
}
