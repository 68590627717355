import { makeAutoObservable } from 'mobx';
import { CSSProperties } from 'react';
import { MyStyle } from 'types/CommonTypes';

class UILinkStore {
  constructor() {
    makeAutoObservable(this);
  }

  boxStyle: CSSProperties;
  textStyle: CSSProperties;
  myStyle: MyStyle;

  setBoxStyle(style?: CSSProperties) {
    if (style) {
      this.boxStyle = style;
      this.textStyle = style;
    }
  }

  setMyStyle(style?: MyStyle) {
    if (style) {
      this.myStyle = style;
    }
  }
}

export default UILinkStore;
