import React, { useEffect, useState } from 'react';
import { DealInquiryDto, LinkDto } from 'services/data-contracts';

import useNavigation from 'hooks/useNavigation';
import { MainText } from 'resources/styles/common/ContextItem';
import CSTownController from 'controllers/CSTownController';
import InquiryListItem from './InquiryListItem';
import SimpleDealInfo from './SimpleDealInfo';

/** @description 고객별 문의 쓰레드 */
interface DealInquiryByCustomerProps {
  inquiry?: DealInquiryDto;
}

const DealSellersInquiry: React.FC<DealInquiryByCustomerProps> = ({ inquiry }) => {
  const { Seller, inquiries } = CSTownController();
  const { navigate } = useNavigation();

  const [status, setStatus] = useState<string>('-');

  const getInquiries = () => {
    Seller.CS.getInquiries();
  };

  const statusToDescription = () => {
    switch (inquiry?.status) {
      case 'PENDING':
        setStatus('답변 대기중인 문의 입니다.');
        break;
      case 'ANSWERED':
        setStatus('답변완료된 문의 입니다.');
        break;
      case 'CLOSED':
        setStatus('답변종료된 문의 입니다.');
        break;

      default:
        break;
    }
  };

  const handleClick = (inquiry: DealInquiryDto) => {
    navigate(`/my/deals/${inquiry?.id}/inquiry/${inquiry?.id}`);
  };

  useEffect(() => {
    if (inquiry) {
      getInquiries();
      statusToDescription();
    }
  }, [inquiry]);

  return (
    <div>
      <div>
        <SimpleDealInfo item={{ ...inquiry, status: inquiry?.status } as LinkDto} cutOff={100} />
      </div>
      <div style={{ margin: 10 }}>
        <MainText>문의 목록</MainText>
      </div>
      <div style={{ padding: '0px 10px' }}>
        {inquiries?.map((inquiry) => (
          <InquiryListItem
            inquiry={inquiry}
            onClick={() => {
              handleClick(inquiry);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default DealSellersInquiry;
