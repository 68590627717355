import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
// import { AdDto, LinkDto, PostDto, PostTagDto } from 'services/data-contracts';
import { Avatar, IconButton } from '@mui/material';
import { stripedSting } from 'components/items/commonLogic';
import { Row } from 'components/commons/layouts';

import styled from 'styled-components';
import BookmarkBorder from '@mui/icons-material/BookmarkBorder';
import Countdown from 'react-countdown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TextLabel from 'components/commons/labels/TextLabel';
import PostSwipeItem from './PostSwipeItem';
import { useStore } from 'stores/StoreHelper';
import AdItemMoreButtons from 'features/mossMain/components/AdItemMoreButtons';
import HomeFollowButtonsContainer from 'features/mainFeatures/follow/containers/HomeFollowButtonsContainer';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import LinkServiceProvider from 'features/linkService/context/LinkServiceProvider';
import { LinkDto, PostDto, PostTagDto } from 'services/data-contracts';

const MobileAdPostItem = observer(
  ({
    post,
    isPreview,
    isOwner,
    onClickVisit,
    onClickTag,
    onClickFavorite,
  }: {
    post: PostDto;
    isPreview?: boolean;
    isOwner: boolean;
    onClickVisit?: () => void;
    onClickTag?: (tag: PostTagDto) => void;
    onClickFavorite: (isFavorite: boolean) => void;
  }) => {
    const handleTimerComplete = () => {};
    const { uiStore, myChannelStore } = useStore();

    const [cutstringLength, setCutStringLength] = useState<number>(80);
    const [isFavorite, setIsFavorite] = useState<boolean>(false);

    const handleClickShowMore = () => {
      uiStore.bottomSheet.show({
        children: <AdItemMoreButtons link={post as LinkDto} />,
      });
    };

    const StrippedString = () => {
      const string = stripedSting(post?.content);
      if (string) {
        if (string.length > cutstringLength) {
          return (
            <div>
              {string.substring(0, cutstringLength)}...{' '}
              <span
                onClick={() => {
                  setCutStringLength(10000);
                }}
                style={{ color: '#BEBEBE' }}
              >
                더보기
              </span>
            </div>
          );
        }
      }
      return <div>{string}</div>;
    };

    useEffect(() => {
      if (post) {
        setIsFavorite(post.ad?.isInterest || false);
      }
    }, [post]);
    return (
      <>
        <FeedItemStyle>
          <Row style={{ justifyContent: 'space-between' }} className="py-2">
            <Row style={{ justifyContent: 'flex-start' }} className="pl-4">
              <Avatar src={post.channel?.profile?.profileImage?.publicUrl} />
              <div className="ml-2">{post.channel?.channelName}</div>
              <>
                {myChannelStore.currentChannel?.channelName !== post.channel?.channelName && (
                  <HomeFollowButtonsContainer
                    isMy={post.channel?.isOwner}
                    channelName={post.channel?.channelName}
                    action="5"
                    isFollow={post.channel?.isFollow}
                  />
                )}
              </>
            </Row>
            <div className="pr-2">
              <IconButton onClick={handleClickShowMore}>
                <MoreHorizIcon />
              </IconButton>
            </div>
          </Row>
          <div onClick={onClickVisit}>
            <PostSwipeItem items={post?.items} />
          </div>
          <div className="px-4">
            <InfoStyle>
              <CountDownStyle style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                <Countdown
                  daysInHours
                  date={post.ad?.endDate}
                  onComplete={() => {
                    handleTimerComplete();
                  }}
                />
              </CountDownStyle>
              {!isOwner && (
                <IconButton
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setIsFavorite((prev: boolean) => !prev);
                    onClickFavorite(!isFavorite);
                  }}
                >
                  {isFavorite ? (
                    <BookmarkIcon style={{ color: '#23BA50' }} />
                  ) : (
                    <BookmarkBorder style={{ color: '#23BA50' }} />
                  )}
                </IconButton>
              )}
            </InfoStyle>
            <TextLabel type="subject" style={{ margin: '10px 0' }}>
              {post?.title !== 'feed_' ? post?.title : ''}
            </TextLabel>
            {post?.content && (
              <>
                {isPreview ? (
                  <TextLabel type="content" style={{ color: '#4C4C4C', fontSize: 14 }}>
                    <StrippedString />
                  </TextLabel>
                ) : (
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: post?.content as string,
                    }}
                  />
                )}
              </>
            )}
            {post?.tags && post?.tags?.length > 0 && (
              <TagsStyle>
                {post.tags.map((tag: PostTagDto) => (
                  <div
                    onClick={() => {
                      if (onClickTag) {
                        onClickTag(tag);
                      }
                    }}
                  >
                    #{tag.name}
                  </div>
                ))}
              </TagsStyle>
            )}
          </div>
        </FeedItemStyle>
      </>
    );
  },
);

const FeedItemStyle = styled.div`
  border-top: 1px solid #efefef;
  width: 100%;
  background: #fff;
  padding-bottom: 40px;
`;

const InfoStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const CountDownStyle = styled.div`
  & span {
    display: inline-block;
    font-weight: 600;
    letter-spacing: 2px;
  }
`;

const TagsStyle = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  & div {
    color: #638dff;
    font-size: 13px;
    font-weight: 400;
  }
`;
export default MobileAdPostItem;
