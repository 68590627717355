import styled from 'styled-components';

export const BlockContainerStyle = styled.div<{ useTransparent?: boolean }>`
  background: #fff;
  /* border-radius: 10px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  min-height: 60px;
  overflow: hidden;
  box-shadow: ${(props) => props.useTransparent === true && 'none !important'};
  background: ${(props) => props.useTransparent && 'transparent !important'};
  border: ${(props) => props.useTransparent && 'none !important'};
`;

export const AdminBlockContainerStyle = styled.div<{ useTransparent?: boolean }>`
  background: #fff;
  border-radius: 10px;
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  > div > div:last-child {
    /* min-height: 80px; */
  }
`;
