import React, { useState } from 'react';
import { Row } from 'components/commons/layouts';
import { t } from 'i18next';

import styled from 'styled-components';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import OverlayStyle from 'resources/controls/OverlayStyle';

const InformationBox = () => {
  const [showBody, setShowBody] = useState<boolean>(false);

  return (
    <>
      <InformationConatinerStyle>
        <InformationStyle show={showBody}>
          <InformationTitleStyle
            onClick={() => {
              setShowBody(!showBody);
            }}
          >
            <Row style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
              <HelpOutlineRoundedIcon />
              <>{t('A198')}</>
            </Row>
          </InformationTitleStyle>
          <InformationBodyStyle>{t('A199')}</InformationBodyStyle>
        </InformationStyle>
      </InformationConatinerStyle>
      <OverlayStyle
        isShow={showBody}
        onClick={() => {
          setShowBody(false);
        }}
      />
    </>
  );
};

const InformationConatinerStyle = styled.div`
  position: relative;
  height: 80px;
`;

const InformationStyle = styled.div<{ show?: boolean }>`
  border: 1px solid #efefef;
  position: fixed;
  border-radius: 15px;
  margin: 20px;
  padding: 20px;
  background: #efefef;
  overflow: hidden;
  /*  height 가 auto일때는 Animation이 동작 안함 */
  height: ${(props) => (props.show ? 'auto' : '20px')};
  max-height: ${(props) => (props.show ? '200px' : '20px')};
  transition: all 0.5s ease;

  z-index: 1000003;
`;

const InformationTitleStyle = styled.div`
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
`;

const InformationBodyStyle = styled.div`
  white-space: pre-line;
  padding-top: 20px;
  line-height: 1.4;
`;

export default InformationBox;
