import { makeObservable, observable, action, flow } from 'mobx';
import { MyChannelProposalConfigService as ProposalConfigService } from 'services/MyChannelProposalConfigService';
import { ChannelDto, UserProfileDto } from 'services/data-contracts';
import _ from 'lodash';
import { HttpClient } from 'services/http-client';
import { ChannelModel } from 'types/CommonTypes';

import i18n from 'i18n';
import { ChannelProposalConfigDto } from 'services/data-contracts';
import ServiceStoreBase from './ServiceStoreBase';

export interface isValid {
  isValid: boolean;
}

class ProposalConfigServiceStore extends ServiceStoreBase {
  t = i18n.t;

  myChannels: Array<ChannelDto> = [];

  filteredFollowers: Array<UserProfileDto> | undefined;

  /**
   * @deprecated
   */
  currentChannelId: number;

  currentChannel: ChannelModel | undefined;

  constructor() {
    super();
    makeObservable(this, {
      myChannels: observable,
      currentChannel: observable,
      unMount: action,
      createChannelProposalConfig: flow,
      getChannelProposalConfig: flow,
    });
  }

  api: ProposalConfigService<unknown> = new ProposalConfigService(new HttpClient());

  *getChannelProposalConfig(channelId: number) {
    const { response } = yield this.api.getChannelProposalConfig(channelId);
    return response.status === 200 ? response.data : null;
  }

  *createChannelProposalConfig(channelId: number, data: ChannelProposalConfigDto) {
    const { response } = yield this.api.createChannelProposalConfig(channelId, data);
    return response.status === 200 ? response.data : null;
  }

  unMount() {
    this.myChannels = [];
  }
}

export default ProposalConfigServiceStore;
