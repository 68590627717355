import React from 'react';
import styled from 'styled-components';
import useNavigation from 'hooks/useNavigation';
import { LandingPageWrapper } from './LandingPageWrapper';

const LandingSlideA = () => {
  const { navigate } = useNavigation();

  const handleClickSignUp = () => {
    navigate('/sign-up');
  };

  return (
    <LandingPageWrapperStyle>
      {/* <ImgContainer src="/images/temp2/AdobeStock_692173106_Preview.jpeg" /> */}
      <BodyContainer>
        <MainText>
          인플루언서를 위한
          <br />
          링크를 찾고 계셨나요?
        </MainText>
        <SubText>
          <StyledText>내 비즈니스를 도와주는</StyledText>
          <StyledText>
            <StyledText>프로페셔널 링크,</StyledText> <OhmossText>oh!moss</OhmossText>
          </StyledText>
        </SubText>
        <CreateAccountBtn
          onClick={handleClickSignUp}
          style={{ cursor: 'pointer', position: 'relative', zIndex: 2 }}
        >
          <span style={{ marginRight: 10 }}>
            <img alt="mascot" src="/images/landing/ohmoss_mascot_ver2.svg" style={{ width: 20 }} />
          </span>
          <span>Create your oh!moss</span>
        </CreateAccountBtn>
      </BodyContainer>
    </LandingPageWrapperStyle>
  );
};

const LandingPageWrapperStyle = styled(LandingPageWrapper)`
  @media ${(props) => props.theme.media.mobile} {
    flex-direction: column;
  }
`;

const BodyContainer = styled.div`
  padding: 50px 50px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.4);
  min-width: 300px;
  width: 800px;
  bottom: -200px;
  position: relative;
  z-index: 1;

  @media ${(props) => props.theme.media.mobile} {
    position: absolute;
    padding: 10px;
    bottom: 80px;
    width: auto;
    justify-content: center;
    align-items: center;
  }
`;

const MainText = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;
  text-align: center;
  color: var(--text-87, rgba(255, 255, 255, 0.9));
  /* font-family: Apple SD Gothic Neo; */
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: 41px;
  padding: 0 20px;
  /* @media screen and (max-height: 690px) {
    font-size: 26px;
  } */
`;

const SubText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
  text-align: center;
  color: var(--text-54, rgba(0, 0, 0, 0.54));
  /* font-family: 'Apple SD Gothic Neo'; */
  font-size: 23px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: -0.46px;
  padding: 0 20px;
  /* @media screen and (max-height: 690px) {
    font-size: 18px;
  } */
`;

const StyledText = styled.span`
  margin-right: 5px;
  color: #96e06e;
  /* font-family: 'Apple SD Gothic Neo'; */
  font-size: 23px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: -0.46px;
  @media screen and (max-height: 690px) {
    font-size: 18px;
  }
`;

const OhmossText = styled.span`
  color: var(--primary_green, #96e06e);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.096px;
  @media screen and (max-height: 690px) {
    font-size: 18px;
  }
`;

const CreateAccountBtn = styled.button`
  display: flex;
  justify-self: center;
  justify-content: center;
  align-items: center;
  width: 335px;
  padding: 15px;

  border-radius: 40px;
  color: #96e06e;
  background-color: #173300;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.17px;
  margin: 25px auto;
  margin-top: 40px;
`;

export default LandingSlideA;
