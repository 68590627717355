import React, { useState } from 'react';
import { isExpired } from 'react-jwt';
import { useStore } from 'stores/StoreHelper';

import axios from 'axios';
import useNavigation from 'hooks/useNavigation';

declare global {
  interface Window {
    Kakao: any;
  }
}

const useGoogleLogin = () => {
  const { navigate } = useNavigation();
  const { uiStore, authStore } = useStore();

  const [userInfo, setGoogleUserInfo] = useState<any>();

  const googleLogin = async (tokenResponse: any) => {
    try {
      const apiResult = (await authStore.googleLogin(tokenResponse)) as any;
      if (apiResult.status !== 200) {
        uiStore.universalModal.show({
          title: '로그인 계정을 확인해주세요',
          useDefaultButtons: true,
          modalOption: { style: { maxWidth: 400, maxHeight: 300, height: 'auto' } },
          children: <>{apiResult.data.errorMessage}</>,
          onClose: () => {
            window.location.href = '/login';
          },
        });
      } else {
        const expired = isExpired(apiResult.data.accessToken as string);

        if (!expired) {
          await setTokens(apiResult.data.accessToken, apiResult.data.refreshToken);
          // setGoogleUserInfo(kakaoMe);
        }
      }
    } catch (e) {
      uiStore.confirm.show({
        message: '아직 오모스의 회원이 아니시네요. 회원가입 하시겠습니까?',
        onConfirmed: () => {
          window.location.href = '/sign-up';
        },
        onCancel: () => {
          // 토큰이 유효하지 않은 경우이므로 사용자에게 메시지 표시 후 이동한다.
          window.location.href = '/login';
        },
      });
    }
  };

  const checkSignup = async (tokenResponse: any) => {
    try {
      const apiResult = (await authStore.googleLogin(tokenResponse.access_token, true)) as any;
      if (apiResult.status === 200) {
        uiStore.confirm.show({
          message: '이미 가입된 계정입니다. 로그인 하시겠습니까?',
          onConfirmed: async () => {
            await setTokens(apiResult.data.accessToken, apiResult.data.refreshToken);
            navigate('/');
          },
          onCancel: () => {
            navigate('/login');
          },
        });
      } else if (apiResult.status === 401) {
        getUserInfo(tokenResponse);
      }
    } catch (e) {
      setGoogleUserInfo(null);
    }
  };

  const getUserInfo = async (googleToken: any) => {
    const googleUri = 'https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=';
    const res = await axios.get(`${googleUri + googleToken.access_token}`);
    res.data.socialToken = googleToken.access_token;
    setGoogleUserInfo(res.data);
  };

  const setTokens = async (accessToken: string, refreshToken: string) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  };

  // useEffect(() => {
  //   checkJoin();
  // }, []);

  // return userInfo;
  return {
    googleLogin,
    checkSignup,
    getUserInfo,
    userInfo,
  };
};

export default useGoogleLogin;
