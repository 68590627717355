import React, { useState } from 'react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import styled from 'styled-components';

import useToken from 'libs/hooks/useToken';
import { Swiper, SwiperSlide } from 'swiper/react';
import NavigationTabBar from 'components/commons/NavigationTabBar';
import LandingSlideA from '../components/LandingSlideA';
import LandingSlideB from '../components/LandingSlideB';
import LandingSlideC from '../components/LandingSlideC';
import LandingSlideD from '../components/LandingSlideD';
import LandingSlideE from '../components/LandingSlideE';
import LandingPageData from '../data/LandingPageData';
import RecommendLandingSlides from '../components/RecommendLandingSlides';
import LandingSlideF from '../components/LandingSlideF';
import LandingSlideG from '../components/LandingSlideG';
import LandingSlideH from '../components/LandingSlideH';

import FooterPC from './FooterPC';

const PC = ({ isMobile }: { isMobile?: boolean }) => {
  const { validUser } = useToken();

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  return (
    <div>
      <ScrollItemStyle>
        <LandingSlideA />
      </ScrollItemStyle>
      <ScrollItemStyle style={{ background: '#EEE' }}>
        <LandingSlideC isMobile={isMobile} />
      </ScrollItemStyle>
      <ScrollItemStyle style={{ background: '#fff' }}>
        <LandingSlideD />
      </ScrollItemStyle>
      <ScrollItemStyle style={{ background: '#EEE' }}>
        <LandingSlideE isMobile={isMobile} />
      </ScrollItemStyle>
      <ScrollItemStyle style={{ background: '#fff' }}>
        <LandingSlideF isMobile={isMobile} />
      </ScrollItemStyle>
      <ScrollItemStyle style={{ background: '#EEE' }}>
        <LandingSlideB />
      </ScrollItemStyle>
      <ScrollItemStyle style={{ background: '#fff', paddingTop: 150 }}>
        <RecommendStyle>이런 인플루언서에게 추천드려요!</RecommendStyle>
        <Swiper
          style={{ maxWidth: 1280, height: '100%' }}
          modules={[Navigation, Pagination, Autoplay]}
          slidesPerView={1.2}
          spaceBetween={30}
        >
          {LandingPageData.map((item, index) => (
            <SwiperSlide key={index} style={{ maxWidth: 1280, maxHeight: 800 }}>
              <ScrollItemStyle style={{}}>
                <RecommendLandingSlides
                  description={item.description}
                  description2={item.description2}
                  imgData={item.imgdata}
                  size={500}
                />
              </ScrollItemStyle>
            </SwiperSlide>
          ))}
        </Swiper>
      </ScrollItemStyle>
      <ScrollItemStyle style={{ paddingTop: 0 }}>
        <FooterPC size={windowHeight} />
      </ScrollItemStyle>
      {validUser() && (
        <GnbComponentStyle>
          <NavigationTabBar type="main" />
        </GnbComponentStyle>
      )}
    </div>
  );
};

const ScrollItemStyle = styled.div`
  /* scroll-snap-align: start; */
  width: 100%;
  max-width: 1250px;
  position: relative;

  @media ${(props) => props.theme.media.desk} {
    /* padding-top: 40px; */
    max-width: 100%;
  }
`;

const GnbComponentStyle = styled.div`
  position: fixed;
  top: 10px;
  z-index: 10;

  width: 100%;
  text-align: center;

  @media ${(props) => props.theme.media.mobile} {
    position: fixed;
    border-radius: 0;
    top: 0px;
    box-shadow: none;
  }
`;

const RecommendStyle = styled.div`
  position: absolute;
  top: 50px;
  max-width: 500px;
  left: 0;
  right: 0;
  z-index: 1;
  margin: auto;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  padding: 20px;
  border-radius: 60px;
  background: rgba(255, 255, 255, 0.8);
`;
export default PC;
