import { isExpired, decodeToken } from 'react-jwt';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { JsonObject, JsonValue } from 'types/CommonTypes';
import { log } from 'console';
/**
 * @description null이나 undefined가 아닌지 체크
 * @param value
 * @returns
 */
export const isValid = (value: any) => {
  const t = !!(typeof value !== 'undefined' && value !== null);
  return t;
};

export const isEmpty = (value: any) => {
  return !!(typeof value !== 'undefined' && value !== null && value !== '');
};

export const validAll = (values: Array<any>) => {
  let isValid = true;
  values.forEach((value: any) => {
    if (typeof value === 'undefined' || value === null) {
      isValid = false;
    }
  });

  return isValid;
};

export const cloneDeep = (value: any) => {
  return typeof value === 'object' ? JSON.parse(JSON.stringify(value)) : value;
};

export const hasAnyValue = (value: any, key: Array<string>) => {};

export const getYoutubeUrl = (value: string) => {
  if (value === null) {
    return '';
  }
  if (value.length < 10) {
    return '';
  } else if (value.length === 11) {
    return value;
  } else {
    const url = new URL(value);
    return value.substring(value.length - 11);
  }
};

export const setDefaultValue = (value: any, defaultValue: unknown) => {
  if (typeof value === 'undefined' || value === null) {
    return defaultValue;
  }
  return value;
};

export const isJsonObject = (value?: string) => {
  try {
    JSON.parse(value!);
  } catch (e) {
    return false;
  }
  return true;
};
// export { isValid, isEmpty, cloneDeep };

export const getUserEmail = () => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    const t = !isExpired(accessToken);
    const userInfo = decodeToken(accessToken) as any;
    if (t && userInfo) {
      return userInfo?.sub;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const formatString = (format: string, ...args: any[]): string => {
  let formatted = format;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < args.length; i++) {
    const regexp = new RegExp(`\\{${i}\\}`, 'gi');
    formatted = formatted.replace(regexp, args[i]);
  }
  return formatted;
};

export const getBrightnessType = (hexColor: string): 'dark' | 'bright' | 'normal' => {
  if (!isValidHex(hexColor)) {
    return 'normal';
  }
  // HEX 코드를 RGB로 변환
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);

  const brightness = Math.round((r * 299 + g * 587 + b * 114) / 1000);

  if (brightness > 245) {
    return 'bright';
  } else if (brightness < 125) {
    return 'dark';
  }

  return 'normal';
};

function isValidHex(hex: string) {
  const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

  if (hexRegex.test(hex)) {
    return true;
  } else {
    return false;
  }
}

export const isOver24Hours = (oldDate: Date): boolean => {
  const now: Date = new Date();
  const elapsed: number = now.getTime() - oldDate.getTime();
  const hours24: number = 24 * 60 * 60 * 1000;
  return elapsed > hours24;
};

export const parseJSONAll = (value?: string) => {
  const parsedObject = JSON.parse(value || '{}') as JsonObject;
  return parseJSONStrings(parsedObject);
};

const parseJSONStrings = (obj: JsonValue): JsonValue => {
  if (typeof obj === 'string') {
    try {
      return JSON.parse(obj) as JsonValue;
    } catch (e) {
      return obj; // JSON 형식이 아니면 원래 문자열 반환
    }
  } else if (Array.isArray(obj)) {
    return obj.map((item) => parseJSONStrings(item));
  } else if (obj !== null && typeof obj === 'object') {
    const result: JsonObject = {};
    for (const [key, value] of Object.entries(obj)) {
      result[key] = parseJSONStrings(value);
    }
    return result;
  }

  return obj;
};

export const cutoffString = (str?: string, length?: number) => {
  if (typeof str !== 'undefined' && typeof length !== 'undefined') {
    if (str.length <= length) {
      return str;
    }
  } else {
    return str;
  }

  const chars = [...str];
  return `${chars.slice(0, length).join('')}..`;
};

export const copyToClipboard = (message?: string) => {
  if (message === undefined) return;
  navigator.clipboard
    .writeText(message)
    .then(() => {
      toast('클립보드에 복사되었습니다.');
    })
    .catch((err) => {
      console.error('복사 실패:', err);
    });
};

export const getFlexPosition = (position?: string) => {
  switch (position) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    default:
      return 'center';
  }
};

export const getDeviceToken = () => {
  const deviceToken = localStorage.getItem(`deviceToken`) || '';
  if (deviceToken === '') {
    const newDeviceToken = uuidv4();
    localStorage.setItem(`deviceToken`, newDeviceToken);
    return newDeviceToken;
  }
  return deviceToken;
};

export const rgbToHex = ({ r, g, b }: { r: number; g: number; b: number }) => {
  const toHex = (c: number) => {
    const hex = c.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export const hexToRgba = (hex: string) => {
  const regex = /^#([0-9A-F]{3}){1,2}([0-9A-F]{2})?$/i;
  if (!regex.test(hex)) {
    return { r: 0, g: 0, b: 0, a: 1 };
  }

  // HEX 코드의 길이에 따라 처리
  let r;
  let g;
  let b;
  let a = 1; // 알파 기본값은 1
  if (hex.length === 4) {
    // 3자리 HEX 코드
    r = parseInt(hex[1] + hex[1], 16) || 0;
    g = parseInt(hex[2] + hex[2], 16) || 0;
    b = parseInt(hex[3] + hex[3], 16 || 0);
  } else if (hex.length === 7 || hex.length === 9) {
    // 6자리 또는 8자리 HEX 코드
    r = parseInt(hex.slice(1, 3), 16) || 0;
    g = parseInt(hex.slice(3, 5), 16) || 0;
    b = parseInt(hex.slice(5, 7), 16) || 0;
    if (hex.length === 9) {
      // 알파 값 처리
      a = parseInt(hex.slice(7, 9), 16) / 255 || 1;
    }
  }
  return { r: r || 0, g: g || 0, b: b || 0, a: a || 1 };
  // return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export const checkColorFormat = (color: string) => {
  const hexRegex = /^#([0-9A-F]{3}){1,2}([0-9A-F]{2})?$/i;
  const rgbaRegex = /^rgba?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(,\s*(0|1|0?\.\d+))?\)$/;

  if (hexRegex.test(color)) {
    return 'HEX';
  } else if (rgbaRegex.test(color)) {
    return 'RGBA';
  } else {
    return null;
  }
};

export const parseRgba = (rgbaString?: string) => {
  const rgbaRegex = /^rgba?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(,\s*(0|1|0?\.\d+))?\)$/;
  const match = rgbaString?.match(rgbaRegex);

  if (match) {
    const r = parseInt(match[1], 10);
    const g = parseInt(match[2], 10);
    const b = parseInt(match[3], 10);
    const a = match[5] !== undefined ? parseFloat(match[5]) : 1;

    return { r, g, b, a };
  } else {
    return { r: 0, g: 0, b: 0, a: 1 };
  }
};

export const isGuid = (guid: string) => {
  const guidPattern =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return guidPattern.test(guid);
};
