import React, { useEffect, useState } from 'react';
import useNavigation from 'hooks/useNavigation';
import styled, { css } from 'styled-components';

const MainSwitchButton = ({ current }: { current?: 'MAG' | 'LINK' }) => {
  const { reset } = useNavigation();

  const [currentMenu, setCurrentMemu] = useState<'MAG' | 'LINK'>('LINK');

  useEffect(() => {
    if (current) {
      setCurrentMemu(current);
    }
  }, [current]);
  return (
    <>
      <IconButtonStyle>
        <ItemButton
          onClick={() => {
            setCurrentMemu('MAG');
            reset('/mag');
          }}
        >
          <InnerButton selected={currentMenu === 'MAG'}>MAG</InnerButton>
        </ItemButton>
        <ItemButton
          onClick={() => {
            setCurrentMemu('LINK');
            reset('/');
          }}
        >
          <InnerButton selected={currentMenu === 'LINK'}> LINK</InnerButton>
        </ItemButton>
      </IconButtonStyle>
    </>
  );
};

const IconButtonStyle = styled.div`
  border: 2px solid var(--primary-color);
  border-radius: 50px;
  display: flex;
  padding: 2px 2px;
  height: 30px;
  align-items: center;
`;

const Selected = css`
  background: var(--primary-color);
  border-radius: 40px;
  color: #fff;
`;

const Default = css`
  color: #fff;
  color: #000;
`;

const ItemButton = styled.div<{ selected?: boolean }>`
  font-size: 11px;
`;

const InnerButton = styled.div<{ selected?: boolean }>`
  font-size: 11px;
  padding: 3px 5px;
  font-weight: 500;
  cursor: pointer;

  ${(props) => (props.selected ? Selected : Default)}
`;
export default MainSwitchButton;
