import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { useParams } from 'react-router-dom';
import boardCommonLogics from 'features/board/logic/boardCommonLogic';
import BoardTemplaes from '.';
import { ChannelBoardDto, PostDto } from 'services/data-contracts';
// import { useLinkServiceContext } from 'features/linkService/context/useLinkServiceContext';
import CategoryTabTemplate from 'features/management/components/profile/CategoryTabView';
import { XScrollBox } from 'components/commons/XScrollBox.tsx';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { LinkServiceType } from 'features/management/context/providers/ContextTypes';
import { useChannelContext } from 'features/linkService/context/useChannelContext';

const BoardBase = observer(({ posts }: { posts: PostDto[] }) => {
  const { channelStore, myChannelStore, postStore, uiStores, uiStore, myBoardStore } = useStore();
  const { userHomeStore } = uiStores;
  const { boardId, itemId } = useParams();
  const { handleClickPost } = boardCommonLogics();
  // const { board } = useOhmossContext<LinkServiceType>();
  const { categoryBoard } = useChannelContext();

  /**
   * @description 새로운 보드 타입은 BoardTemplaes 추가한다.
   * @returns
   */
  const getBoardTemplateByBoardType = () => {
    if (typeof categoryBoard !== 'undefined') {
      const viewType = categoryBoard?.isDefault ? 'AD' : categoryBoard?.viewType;
      const Template = BoardTemplaes[viewType || 'NONE'];
      return <Template posts={posts} currentBoard={categoryBoard} />;
    }
  };

  /**
   * @description DeepLink
   */
  const showBoardItem = async (boardId?: string, itemId?: string) => {
    if (itemId && boardId && channelStore.channel.channelName && userHomeStore.currentBoard) {
      const itemid = parseInt(itemId, 10);
      const post = (await postStore.getPost(
        channelStore.channel?.channelName,
        userHomeStore.currentBoard?.id as number,
        itemid,
        myChannelStore.currentChannel ? myChannelStore.currentChannel?.id : undefined,
      )) as any;
      if (post) {
        handleClickPost(post, userHomeStore.currentBoard);
      } else {
        //TODO : replace hash
      }
    } else {
      uiStore.universalModal.close();
      uiStore.bottomSheet.close();
    }
  };

  useEffect(() => {
    showBoardItem(boardId, itemId);
  }, [boardId, itemId, channelStore.channel, userHomeStore.currentBoard]);

  useEffect(() => {
    const handlePopState = (event: any) => {
      const newPath = window.location.pathname.toString().split('/');
      if (newPath.length === 4) {
      } else {
        uiStore.universalModal.close(true);
        uiStore.bottomSheet.close();
      }
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return <div style={{ padding: 20 }}>{posts && getBoardTemplateByBoardType()}</div>;
});

export default BoardBase;
