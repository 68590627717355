import React, { useEffect, useState, useCallback, useLayoutEffect } from 'react';

import { validationHelper as VH } from 'controls/validationHelper';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@mui/material';

import { debounce } from 'lodash';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import { ChannelDto, DuplicationUserDto, SignUpDto, TermDto } from 'services/data-contracts';
import useNavigation from 'hooks/useNavigation';

import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import PasswordForm from 'components/items/forms/PasswordForm';
import { useTranslation } from 'react-i18next';
import { Row } from 'components/commons/layouts';
import MUISwitch from 'components/commons/buttons/MUISwitch';
import TextLabel from 'components/commons/labels/TextLabel';
import styled from 'styled-components';
import MossyInputForm from 'components/items/forms/MossyInputForm';
import { withStyles } from '@material-ui/core/styles';
import DetailPageHeader from 'components/commons/DetailPageHeader';
import RadioButtonsGroup from 'features/management/components/common/RadioButton';
import CommonSignUpForm from './CommonSignUpForm';
import AuthStore from 'stores/AuthStore';

const styles = (theme: any) => ({
  outlinedInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.625rem',
      border: ({ titleModel }: any) => (titleModel?.length > 0 ? '1.5px solid #173300' : 'none'),
      background: 'rgba(23, 51, 0, 0.1)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.625rem',
      border: '1.5px solid #173300',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.625rem',
      border: '1.5px solid #173300',
    },
  },
});

interface SignUpModel extends SignUpDto {
  termsValid?: boolean;
}

const ChannelTypeSelector = ({
  onSelectedType,
}: {
  onSelectedType: (type: 'SELLER' | 'BRAND') => void;
}) => {
  const handleSelectChannelType = (channelType: string) => {
    if (channelType === '셀러(일반) 채널') {
      onSelectedType?.('SELLER');
    } else {
      onSelectedType?.('BRAND');
    }
  };

  return (
    <>
      <Row style={{ justifyContent: 'flex-start', marginTop: 20, marginBottom: 10 }}>
        <TextLabel type="subject">채널 유형 선택하기</TextLabel>
        <img src="/images/hover_details.svg" alt="" style={{ marginLeft: 6 }} />
      </Row>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 12,
          border: '1px solid rgba(23, 51, 0, 0.30)',
          borderRadius: 10,
          padding: '10px 0px 10px 0px',
        }}
      >
        <Row style={{ justifyContent: 'space-between', padding: '0px 12px 0px 12px' }}>
          <RadioButtonsGroup
            label={['셀러(일반) 채널', '브랜드 / 에이전시채널']}
            onRadioBtnChange={(item: any) => handleSelectChannelType(item)}
          />
        </Row>
      </div>
    </>
  );
};

const TitleText = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02rem;
`;

export default ChannelTypeSelector;
