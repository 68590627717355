import styled from 'styled-components';

const LayoutBox = styled.div``;

const ContainerStyle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftContainerStyle = styled.div`
  max-width: 350px;
  width: 100%;
  min-width: 300px;
  /* background: #fff; */
  flex: 0 0 10%;
  position: relative;
`;

const CenterContainerStyle = styled.div`
  flex: 1;
  justify-content: center;
  position: relative;
  min-width: 50%;
`;

const RightContainerStyle = styled.div`
  justify-content: center;
  overflow: scroll;
  position: relative;
  flex: 0 0 20%;
`;

const ManagementLayoutStyle = styled.div`
  background: #fff !important;
  position: absolute;
  top: 80px;
  overflow: hidden;
  right: 20px;
  left: 20px;
`;

const GnbStyle = styled.div`
  padding: 0 20px;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 80px;
  right: 0px;
  z-index: 2;
  background: #fbfbfb;
`;

export {
  ContainerStyle,
  LeftContainerStyle,
  CenterContainerStyle,
  RightContainerStyle,
  LayoutBox,
  GnbStyle,
  ManagementLayoutStyle,
};
