import React, { useState, useEffect, useRef } from 'react';

import { initLinkItemDto, updateLink } from 'components/items/commonLogic';
import { defaultLinkEditorProps, ContactLinkModel } from 'types/CommonTypes';
import { validationHelper as VH } from 'controls/validationHelper';
import { Row } from 'components/commons/layouts';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { t } from 'i18next';

import LinkEditorContainerStyle from 'resources/controls/LinkEditorConatainerStyle';
import SaveButtonWrapper from './SaveButtonWrapper';
import TitleForm from './forms/TitleForm';
import TelephoneForm from './forms/TelephoneForm';
import ThumbnailUploader from './ThumbnailUploader';
import EmailForm from './forms/EmailForm';

const TelLinkEditor = ({
  model,
  stores,
  onRegistComplete,
}: defaultLinkEditorProps<ContactLinkModel>) => {
  const titleRef = useRef<HTMLInputElement>(null);
  const telRef = useRef<HTMLInputElement>(null);

  const [linkModel, setLinkModel] = useState<ContactLinkModel>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [contactType, setContactType] = useState<'phone' | 'email' | undefined>('phone');

  const handleClickSave = async () => {
    if (typeof linkModel !== 'undefined' && typeof onRegistComplete === 'function') {
      if (contactType === 'email') {
        linkModel.phoneNumber = '0000';
      }
      const result = await updateLink(linkModel, typeof linkModel.id !== 'undefined', stores);
      onRegistComplete(true, result);
    }
  };

  const handleChangeValue = (key: 'title' | 'phoneNumber', value: string) => {
    setLinkModel((prev: ContactLinkModel) => {
      return { ...prev, [key]: value };
    });
  };

  const checkValidation = () => {
    const result = VH.required(linkModel?.title);
    const result2 =
      linkModel?.metadataObject?.contactType === 'phone'
        ? VH.tel(linkModel?.phoneNumber)
        : VH.email(linkModel?.metadataObject?.contact);

    setIsValid(result && result2);
  };

  useEffect(() => {
    if (typeof model?.id === 'undefined') {
      // setLinkModel({ linkType: 'TelLink', customObject: { contactType: 'phone' } });
      setLinkModel(initLinkItemDto('TelLink', { contactType: 'phone' }));
      setContactType('phone');
    } else {
      setLinkModel(model);
      if (typeof model.metadata !== 'undefined') {
        model.metadataObject = JSON.parse(model.metadata);
        setContactType(model.metadataObject?.contactType);
        if (model.metadataObject?.contactType === 'email') {
          model.phoneNumber = undefined;
        }
      }

      const result = VH.required(model.title) && VH.tel(model.phoneNumber);
      setIsValid(result);
    }
  }, [model]);

  useEffect(() => {
    if (typeof linkModel !== 'undefined') {
      checkValidation();
    }
  }, [linkModel]);

  return (
    <LinkEditorContainerStyle>
      <SaveButtonWrapper
        isValid={isValid}
        onSave={handleClickSave}
        isNew={typeof linkModel?.id === 'undefined'}
      >
        <>
          <ThumbnailUploader
            url={linkModel?.thumbnail?.publicUrl}
            onImageChange={(image: any) => {
              setLinkModel((prev: ContactLinkModel) => {
                return { ...prev, thumbnail: image };
              });
            }}
          />
          <Row style={{ justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20 }}>
            <div style={{ width: '100%' }}>
              <TitleForm
                multiline
                errorText={t('A153')}
                helperText={t('A149')}
                ref={titleRef}
                model={linkModel?.title}
                onChangeValue={(value: string) => {
                  handleChangeValue('title', value);
                }}
              />
              <div style={{ marginBottom: 20, marginTop: 20 }}>
                <ToggleButtonGroup
                  color="primary"
                  value={linkModel?.metadataObject?.contactType}
                  exclusive
                  onChange={(e: any, value: any) => {
                    if (value === null) {
                      return;
                    }
                    setContactType(value);
                    setLinkModel((prev: any) => {
                      return {
                        ...prev,
                        // phoneNumber: '0000000000',
                        metadataObject: { ...prev.metadataObject, contactType: value },
                      };
                    });
                  }}
                  aria-label="Platform"
                >
                  <ToggleButton
                    sx={{
                      '&.MuiToggleButton-root': {
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                        padding: '0 20px',
                        height: 40,
                      },
                      '&.Mui-selected': {
                        color: '#11C668',
                        background: '#F8FFFB',
                      },
                    }}
                    value="phone"
                  >
                    phone
                  </ToggleButton>
                  <ToggleButton
                    value="email"
                    sx={{
                      '&.MuiToggleButton-root': {
                        borderTopRightRadius: 10,
                        borderBottomRightRadius: 10,
                        padding: '0 20px',
                        height: 40,
                      },
                      '&.Mui-selected': {
                        color: '#11C668',
                        background: '#F8FFFB',
                      },
                    }}
                  >
                    email
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <>
                {linkModel?.metadataObject?.contactType === 'phone' ? (
                  <TelephoneForm
                    ref={telRef}
                    model={linkModel?.phoneNumber}
                    onChangeValue={(value: string) => {
                      handleChangeValue('phoneNumber', value);
                    }}
                  />
                ) : (
                  <EmailForm
                    ref={telRef}
                    model={linkModel?.metadataObject?.contact}
                    onChangeValue={(value: string) => {
                      setLinkModel((prev: any) => {
                        return {
                          ...prev,
                          metadataObject: { ...prev.metadataObject, contact: value },
                        };
                      });
                    }}
                  />
                )}
              </>
            </div>
          </Row>
          {/* <TelLink isPreview item={linkModel} /> */}
        </>
      </SaveButtonWrapper>
    </LinkEditorContainerStyle>
  );
};

export default TelLinkEditor;
