import { useState } from 'react';
import {
  DealListDto,
  LinkDto,
  MemberCreationDto,
  MemberUpdateDto,
  SimpleDealDto,
} from 'services/data-contracts';
import MyMemberStore from 'stores/MyMemberStore';
import PartnerInvitationStore from 'stores/PartnerInvitationStore';
import PartnershipStore from 'stores/PartnershipStore';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useStore } from 'stores/StoreHelper';

/** CS(Inquiry)를 제외한 BL
 * @description
 * @returns
 */
const BrandController = () => {
  const { myChannel } = useRootContext();
  const myMemberStore = new MyMemberStore();
  const partnershipStore = new PartnershipStore();
  const partnerinvitationsStore = new PartnerInvitationStore();
  const { authStore } = useStore();

  const [deals, setDeals] = useState<LinkDto[]>();

  const getMembers = async (channelId: number) => {
    return myMemberStore.getMembers(channelId);
  };

  /**
   * @description 매니저 생성
   * @param channelId
   * @param manager
   * @returns
   */

  const createManager = async (channelId: number, manager: MemberCreationDto) => {
    return myMemberStore.createManager(channelId, manager);
  };

  /**
   * @description 매니저 수정
   * @param channelId
   * @param managerId
   * @param manager
   * @returns
   */
  const updateManager = async (channelId: number, managerId: number, manager: MemberUpdateDto) => {
    return myMemberStore.updateManager(channelId, managerId, manager);
  };

  /**
   * @description 매니저 삭제
   * @param channelId
   * @param managerId
   * @returns
   */
  const deleteManager = async (managerId: number) => {
    return myMemberStore.deleteManager(myChannel?.id as number, managerId);
  };

  /**
   * @description 중복된 아이디 체크
   * */
  const checkDuplicateUsername = async (username: string) => {
    return authStore.duplicateUserName(username);
  };

  /**
   * @description 초대를 수락한다.(추후 브랜드 컨트롤러로 옮길것)
   * @param invitationKey
   * @param query
   * @returns
   */
  const acceptInvitation = async (
    invitationKey: string,
    query: { partnerChannelId: number; inviterChannelName: string },
  ) => {
    const invitation = await partnerinvitationsStore.acceptInvitation(invitationKey, query);
    return invitation;
  };

  /**
   * @description 위임된 공구 리스트 및 전체 공구리스트를 가저와 병합함.
   * @param channelId
   * @returns
   */
  const getDelegatedDeals = async (managerId?: number) => {
    const allDeals = (await partnershipStore.getDelegatedDeals(
      myChannel?.id as number,
    )) as unknown as SimpleDealDto[];
    if (managerId) {
      const assignedDeals = (await myMemberStore.getAssignedDeals(
        myChannel?.id as number,
        managerId,
      )) as unknown as SimpleDealDto[];

      const mergedArray = allDeals?.map((itemA) => {
        const itemInB = assignedDeals?.find((itemB) => itemB.id === itemA.id);
        if (itemInB) {
          return { ...itemA, assigned: true };
        }
        return itemA;
      }) as LinkDtoWithAssignedDeal[];
      setDeals(mergedArray as any);
      return mergedArray;
    } else {
      setDeals(allDeals as any);
      return allDeals;
    }
  };

  /**
   * @description 매니저에게 공구 위임
   * @param channelId
   * @param managerId
   * @param dealList
   * @returns
   */
  const assignDealsToManager = async (
    channelId: number,
    managerId: number,
    dealList: DealListDto,
  ) => {
    return myMemberStore.assignDeals(channelId, managerId, dealList);
  };

  const assignDealToManager = async (channelId: number, managerId: number, dealId: number) => {
    return myMemberStore.assignDeal(channelId, managerId, dealId);
  };

  const unassignDealFromManager = async (channelId: number, managerId: number, dealId: number) => {
    return myMemberStore.unassignDeal(channelId, managerId, dealId);
  };

  /**
   * @description 파트너 리스트를 가져옴
   * @param channelId
   * @returns
   */
  const getPartners = async (channelId: number) => {
    return partnershipStore.getPartners(channelId);
  };

  const getInvitations = async (channelId: number) => {
    return partnerinvitationsStore.getPartnerInvitations(channelId);
  };

  const getDealsByPartner = async (channelId: number, partnerId: number) => {
    return partnershipStore.getDealsByPartner(channelId, partnerId);
  };

  const getDealById = async (channelId: number, dealId: number) => {
    return partnershipStore.getDealById(channelId, dealId);
  };

  const updateManagerPassword = async () => {};

  return {
    deals,
    getMembers,
    getDelegatedDeals,
    getPartners,
    getInvitations,
    getDealsByPartner,
    getDealById,
    createManager,
    updateManager,
    checkDuplicateUsername,
    deleteManager,
    assignDealsToManager,
    updateManagerPassword,
    acceptInvitation,
    assignDealToManager,
    unassignDealFromManager,
  };
};

export default BrandController;
