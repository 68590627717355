import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
import { observer } from 'mobx-react';

import useKakaoLogin from 'libs/hooks/useKakaoLogin';
import useChannelSelector from 'libs/hooks/useChannelSelector';
import { useGlobalContext } from 'context/useGlobalContext';
import { useTranslation } from 'react-i18next';
import { useRootContext } from 'libs/hooks/useRootContext';
import LoginPageController from './pageControllers/LoginPageController';

const KakaoLoginPage = observer(() => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const { navigate } = useNavigation();
  const { setLogin, login } = useGlobalContext();
  const { getMyChannel } = useRootContext();

  const { redirect } = LoginPageController();
  const { userInfo, checkJoin } = useKakaoLogin(code);
  const { initializeFirstChannel } = useChannelSelector();

  const checkKakaoLogin = async () => {
    const result = await checkJoin();
    if (result) {
      await initializeFirstChannel();
      setLogin(true);
      await login();
      getMyChannel();
      redirect();
    } else {
      // toast(t('A102'), { type: 'warning' });
    }
  };

  useEffect(() => {
    if (userInfo) {
      if (state) {
        const t = state.replace('(', '').replace(')', '').replace('.', '?action=');
        navigate(`/${t}`);
      } else {
        // init();
      }
    }
  }, [userInfo]);

  useEffect(() => {
    checkKakaoLogin();
  }, []);

  return <></>;
});

export default KakaoLoginPage;
