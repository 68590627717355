/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from 'react';
import { SocialLinkDto } from 'services/data-contracts';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { Row } from 'components/commons/layouts';
import AddIcon from '@mui/icons-material/Add';

import {
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  FormControlLabel,
  Switch,
} from '@mui/material';
import {
  SNSLinkModel,
  defaultLinkEditorProps,
  SNSLinkItemModel,
  SocialProps,
} from 'types/CommonTypes';

import { validationHelper as VH } from 'controls/validationHelper';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { getBrightnessType, setDefaultValue } from 'libs/helper/utils';
import { GraySNS, ISNS, OriginSNS } from 'resources/images/sns';
import { updateLink } from 'components/items/commonLogic';

import ImageSearchOutlined from '@mui/icons-material/ImageSearchOutlined';
import Confirm from 'components/commons/Confirm';
import UrlForm from 'components/items/forms/UrlForm';
import _ from 'lodash';

import SNSIconsComponent from './SNSIconsComponent';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import styled from 'styled-components';
import SimpleColorPickerWithPopOver from './SimpleColorPickerWithPopOver';
import SaveButtonWrapper from './SaveButtonWrapper';
import SimpleDragDropBox from 'components/commons/SimpleDragDropBox';
import SNSItem from './SNSIconWrapper';

/**
 * 프리뷰 만들어야 함.
 */
const SocialEditor = observer(
  ({ model, stores, onRegistComplete }: defaultLinkEditorProps<SNSLinkModel>) => {
    const ids = useRef<number>(1000);
    const intDto = (reset?: boolean): SNSLinkModel => {
      if (reset) {
        return {
          title: 'Social links',
          channel: {},
          seq: 0,
          linkType: 'SNS',
        };
      } else {
        return {
          ...model,
          linkType: 'SNS',
          title: setDefaultValue(model?.title, ''),
          channel: setDefaultValue(model?.channel, {}),
          seq: setDefaultValue(model?.seq, 0),
          isActive: true,
        };
      }
    };

    const { myChannelStore, mySocialStore, uiStore, uiStores } = useStore();
    const { t } = useTranslation();
    const urlRef = useRef<HTMLFormElement>(null);
    const { profileStore } = uiStores;
    const [linkModel, setLinkModel] = useState<SNSLinkModel>(intDto(true));
    const selectedItem = useRef<SNSLinkItemModel | null>(null);

    const [socialLink, setSocialLink] = useState<SocialLinkDto>();
    const [deleteConfirmVisible, setDeleteConfirmVisible] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [snsItems, setSnsItems] = useState<Array<SNSLinkItemModel>>([]);
    const [showSNSIcons, setShowSNSIcons] = useState<boolean>(false);
    const [iconType, setIconType] = useState<'color' | 'solid'>('color');
    const [color, setColor] = useState<string>('#000000');
    const [reset, setReset] = useState<boolean>(false);

    /** Primitives */
    const handleClickSave = async () => {
      if (typeof linkModel !== 'undefined' && typeof onRegistComplete === 'function') {
        const saveModel = { ...linkModel };
        saveModel.items = snsItems.map((item: SNSLinkItemModel, index: number) => {
          if (item.origin) {
            return { ...item };
          } else {
            delete item.id;
            return item;
          }
        });
        saveModel.isActive = true;
        const result = (await updateLink(
          saveModel,
          typeof saveModel.id !== 'undefined',
          stores,
        )) as SNSLinkModel;
        if (result !== null) {
          onRegistComplete(true, result);
        } else {
          setReset(true);
        }
      }
    };

    const handleChangeUrl = (value: string) => {
      const result = VH.url(value);
      if (result) {
        handlePasteUrl(value);
      } else {
        setIsValid(false);
      }
    };

    const handlePasteUrl = (url: string) => {
      const preCheck = url.indexOf('http');
      if (preCheck !== 0) {
        url = `https://${url}`;
      }
    };

    const handleChanegeSeq = (items: Array<SNSLinkItemModel>) => {
      setSnsItems(items);
    };

    const validateSNS = () => {
      if (selectedItem.current) {
        const result = VH.url(selectedItem.current.linkUrl);
        // selectedItem.current.isValidated =
        //   result && typeof selectedItem.current.title !== 'undefined';

        const recent = snsItems.findIndex(
          (items: SNSLinkItemModel) => items.id === selectedItem.current?.id,
        );
        const newItems = [...snsItems];
        newItems.splice(recent, 1, selectedItem.current);
        setSnsItems(newItems);
      }
    };

    const checkSNSValidation = () => {
      if (snsItems.length > 0) {
        // const result = snsItems.filter((item: SNSLinkItemModel) => item.isValidated);
        // setIsValid(result.length === snsItems.length);
      } else {
        setIsValid(false);
      }
    };
    const handleClickAddSNS = () => {
      const newItems = [...snsItems];
      ids.current += 1;
      newItems?.push({ id: ids.current, isActive: true, seq: snsItems.length + 1 });
      setSnsItems(newItems);
    };

    const SNSIconComponent = ({ name, useOrigin }: { name: string; useOrigin?: boolean }) => {
      return <SNSItem name={name} useOrigin={useOrigin} />;
      // let Icon;
      // if (useOrigin || iconType === 'color') {
      //   Icon = OriginSNS.find((sns: ISNS) => sns.name === name);
      // } else {
      //   Icon = GraySNS.find((sns: ISNS) => sns.name === name);
      // }
      // if (Icon) {
      //   return <Icon.component style={{ width: 40, fill: color }} />;
      // }

      // return <ImageSearchOutlined />;
    };

    const handleDeleteSNS = async (sns: SNSLinkItemModel, index: number) => {
      let newSNSItems = [...snsItems];
      newSNSItems.splice(index, 1);
      setSnsItems(newSNSItems);
    };

    useEffect(() => {
      checkSNSValidation();
    }, [snsItems]);

    useEffect(() => {
      if (myChannelStore.currentChannel) {
        mySocialStore.getSocials(myChannelStore.currentChannel.id as number);
      }
    }, [myChannelStore.currentChannel]);

    useEffect(() => {
      if (model.items) {
        const newItems = model.items.map((item: SNSLinkItemModel) => {
          return { ...item, isValidated: true, origin: true };
        });
        setSnsItems(newItems);
      }
      if (model) {
        setLinkModel(model);
        model.metadataObject = JSON.parse(model.metadata || '{}');
        setIconType(model.metadataObject?.colorType || 'color');
        setColor(model.metadataObject?.solidColor || '#000000');
      }
    }, [model]);

    return (
      <>
        <SaveButtonWrapper
          isValid={isValid}
          reset={reset}
          onSave={handleClickSave}
          isNew={typeof linkModel?.id === 'undefined'}
        >
          <>
            <div className="text-sm pb-2">Color type</div>
            <Row style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
              <div>
                <ToggleButtonGroup
                  color="primary"
                  value={linkModel?.metadataObject?.colorType || 'color'}
                  exclusive
                  onChange={(e: any, value: any) => {
                    if (value !== null) {
                      setIconType(value);
                      setLinkModel((prev: SNSLinkModel) => {
                        return {
                          ...prev,
                          metadataObject: { ...prev.metadataObject, colorType: value },
                        };
                      });
                    }
                  }}
                  aria-label="Platform"
                >
                  <ToggleButton value="color">Color</ToggleButton>
                  <ToggleButton value="solid">Solid</ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className="pl-4">
                {iconType === 'solid' && (
                  <Row>
                    <div>
                      <SimpleColorPickerWithPopOver
                        useGradient={false}
                        onColorChanged={(value: string) => {
                          setColor(value);
                          setLinkModel((prev: SNSLinkModel) => {
                            return {
                              ...prev,
                              metadataObject: { ...prev.metadataObject, solidColor: value },
                            };
                          });
                        }}
                      />
                    </div>
                    <div>
                      {color && (
                        <CurrentBackgroundStyle
                          style={{ background: color }}
                          colorType={getBrightnessType(color)}
                        >
                          {color}
                        </CurrentBackgroundStyle>
                      )}
                    </div>
                  </Row>
                )}
              </div>
            </Row>
          </>
          <div className="py-6">
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={linkModel?.metadataObject?.useTransparent}
                  onChange={(event: never, value: boolean) => {
                    setLinkModel((prev: SNSLinkModel) => {
                      return {
                        ...prev,
                        metadataObject: {
                          ...prev.metadataObject,
                          useTransparent: value,
                        },
                      };
                    });
                  }}
                />
              }
              label={t('A151')}
            />
          </div>
          <div className="pb-4">
            <div className="text-sm pb-2">Preview</div>
            <div className="text-sm pb-2">Drag and Drop으로 순서를 변경할 수 있습니다.</div>
            <SimpleDragDropBox
              items={snsItems}
              iconType={iconType}
              color={color}
              onChangeItemSeq={handleChanegeSeq}
            />
            {/* <Row>
              {snsItems?.map((item: SNSLinkItemModel) => (
                <div style={{ margin: 10 }}>
                  {item.isValidated && item.title && <SNSIconComponent name={item.title} />}
                </div>
              ))}
            </Row> */}
          </div>
          <>
            <div className="py-2">SNS icon과 URL을 지정하여주세요.</div>
            {snsItems?.map((item: SNSLinkItemModel, index: number) => (
              <Row style={{ justifyContent: 'space-between', marginTop: 20, flexDirection: 'row' }}>
                <div
                  style={{ marginRight: 20, cursor: 'pointer' }}
                  onClick={() => {
                    setShowSNSIcons(true);
                    if (item) {
                      selectedItem.current = { ...item };
                    }
                  }}
                >
                  {typeof item.title !== 'undefined' ? (
                    <SNSIconComponent name={item.title} useOrigin />
                  ) : (
                    <IconButton>
                      <ImageSearchOutlined />
                    </IconButton>
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  <UrlForm
                    usePrefix={false}
                    ref={urlRef}
                    label="SNS Url"
                    disable={false}
                    model={item?.linkUrl || ''}
                    onChangeValue={handleChangeUrl}
                    onPaste={handlePasteUrl}
                    onBlur={(value: string) => {
                      item.linkUrl = value;
                      selectedItem.current = { ...item, linkUrl: value };

                      validateSNS();
                    }}
                  />
                </div>
                <Row
                  style={{
                    width: typeof socialLink?.id !== 'undefined' ? 150 : 100,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    onClick={() => {
                      handleDeleteSNS(item, index);
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                  {/* 
                  <>
                    {typeof socialLink?.id !== 'undefined' && (
                      <OhmossButton
                        type="link"
                        onClick={() => {
                          reset();
                        }}
                      >
                        {t('Cancel')}
                      </OhmossButton>
                    )}
                  </> */}
                </Row>
              </Row>
            ))}
            <div className="pt-4">
              <OhmossButton
                style={{ padding: '10px 20px' }}
                onClick={handleClickAddSNS}
                type="simple"
              >
                <AddIcon />
                링크 추가
              </OhmossButton>
            </div>
            {showSNSIcons && (
              <OverlayStyle>
                <OverlayContainerStyle>
                  <SNSIconsComponent
                    defaultIconName={selectedItem.current?.title}
                    onSelected={(SNS: ISNS) => {
                      if (selectedItem.current) {
                        selectedItem.current.title = SNS.name;
                        validateSNS();
                        setShowSNSIcons(false);
                      }
                    }}
                    onCancel={() => {
                      setShowSNSIcons(false);
                    }}
                  />
                </OverlayContainerStyle>
              </OverlayStyle>
            )}
          </>

          <Confirm
            title={t('linkDelete')}
            visible={deleteConfirmVisible}
            setVisible={setDeleteConfirmVisible}
          />
        </SaveButtonWrapper>
      </>
    );
  },
);

const CurrentBackgroundStyle = styled.div<{ colorType: 'dark' | 'bright' | 'normal' }>`
  padding: 5px 10px;
  border-radius: 10px;
  color: ${(props) => (props.colorType === 'dark' ? '#fff' : '#000')};
  border: ${(props) =>
    props.colorType === 'dark' ? '1px solid transparent' : '1px solid #efefef'};
`;

const OverlayStyle = styled.div`
  padding: 20px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100000;
`;

const OverlayContainerStyle = styled.div`
  border-radius: 10px;
  background: #fff;
  padding: 20px;
`;
export default SocialEditor;
