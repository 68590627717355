import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { useStore } from 'stores/StoreHelper';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { StickerTypes } from './StickerTypes';
import { Row } from '../layouts';

export interface CustomizedStickersProps {
  selectedStickers?: StickerTypes[];
  onChildDataChange?: (sticker: StickerTypes) => void;
  onChildDelete?: (sticker: StickerTypes) => void;
  mode: 'edit' | 'view';
}

const CustomizedStickers = ({
  selectedStickers,
  onChildDataChange,
  onChildDelete,
  mode,
}: CustomizedStickersProps) => {
  const handleClickStickerType = (sticker: StickerTypes, mode: string) => {
    if (mode === 'edit') {
      if (onChildDataChange) {
        onChildDataChange(sticker);
      }
    }
  };

  const renderEditedStickers = (sticker: StickerTypes) => {
    const Temp = sticker?.component;
    if (typeof Temp === 'undefined') return <></>;
    return (
      <Temp
        style={sticker.internalStyle ? sticker?.internalStyle : sticker?.style}
        text={sticker.label}
      />
    );
  };

  const handleRemoveSticker = (sticker: StickerTypes) => {
    if (onChildDelete) {
      onChildDelete(sticker);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: 10,
      }}
    >
      {selectedStickers?.map((sticker: StickerTypes, index: number) => (
        <div style={{ cursor: 'pointer' }} key={index}>
          <Row style={{ width: 'unset' }}>
            <div
              onClick={() => {
                handleClickStickerType(sticker, mode);
              }}
            >
              {renderEditedStickers(sticker)}
            </div>
            {mode === 'edit' ? (
              <IconButton
                onClick={() => {
                  handleRemoveSticker(sticker);
                }}
              >
                <DeleteOutlinedIcon fontSize="small" />
              </IconButton>
            ) : (
              <></>
            )}
          </Row>
        </div>
      ))}
    </div>
  );
};

export default CustomizedStickers;
