import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';

import GnbAdmin2 from 'features/commonFeatures/navigationBar/containers/AdminLnb';
import MenuTitleStyle from 'resources/controls/MenuTitleStyle';

import Followers from 'features/proFeatures/followers/containers/Followers';

const CRMHomePage = observer(() => {
  const GNB_MAX_WIDTH = 150;

  // const init = async () => {
  //   await meStore.getMyProfile();
  // };
  // useEffect(() => {
  //   if (!meStore.myProfile) {
  //     init();
  //   }
  // }, []);
  return (
    <AdminStyle showMenu isMobile={false} mobileMode={false} gnbWidth={GNB_MAX_WIDTH}>
      <GnbContainerStyle isMobile={false} showMenu maxWidth={GNB_MAX_WIDTH}>
        <GnbAdmin2 />
      </GnbContainerStyle>
      <ContainerStyle showSimulator={false} showMenu isMobile={false} gnbWidth={GNB_MAX_WIDTH}>
        <MenuTitleStyle>FOLLOWERS</MenuTitleStyle>
        <div style={{ padding: 20 }}>
          <Followers />
        </div>
      </ContainerStyle>
    </AdminStyle>
  );
});

const AdminStyle = styled.div<{
  showMenu?: boolean;
  isMobile?: boolean;
  mobileMode?: boolean;
  gnbWidth: number;
}>`
  padding-left: ${(props) => (props.showMenu || props.mobileMode ? '0' : `${props.gnbWidth}px`)};
  background-color: #eeeeee;
  ${(props) =>
    props.isMobile &&
    css`
      padding-left: 0;
      background-color: white;
    `}
  ${(props) =>
    props.mobileMode
      ? css`
          flex-direction: column;
          height: 100%;
        `
      : css`
          flex-direction: row;
          height: 100vh;
        `}
    @media screen and (max-width: 1040px) {
    ${(props) =>
      !props.showMenu &&
      css`
        height: 100vh;
      `}
  }
  @media screen and (max-width: 740px) {
    padding-left: 0;
    ${(props) =>
      props.isMobile &&
      props.showMenu &&
      css`
        height: 100%;
      `}
  }
`;

const GnbContainerStyle = styled.div<{ isMobile?: boolean; showMenu?: boolean; maxWidth: number }>`
  z-index: 1000;
  position: absolute;
  left: 0;
  background: white;
  ${(props) =>
    props.isMobile &&
    !props.showMenu &&
    css`
      z-index: 0;
    `}
  > div {
    display: flex;
    flex-direction: column;
    max-width: ${(props) => props.maxWidth}px;
    height: 100%;
    background: white;
    z-index: 100;
    @media screen and (max-width: 740px) {
      background: white;
      z-index: 100;
      height: 70px;
      flex-direction: row;
      max-width: unset;
    }
  }
`;

const ContainerStyle = styled.div<{
  showSimulator?: boolean;
  showMenu?: boolean;
  isMobile?: boolean;
  gnbWidth: number;
}>`
  background: ${(props) => (props.isMobile ? 'transparent' : '#fff')};
  overflow: auto;
  position: absolute;
  /* background-color: white; */
  /* z-index: 100; */
  max-width: 600px;
  left: ${(props) => (props.showMenu ? `${props.gnbWidth}px` : '-550px')};
  transition: left 0.5s ease-in-out;
  width: 40%;
  height: 100%;
  overflow: scroll;
  @media screen and (max-width: 890px) {
    /* width: calc(100% - 150px); */
    max-width: 890px;
  }

  @media screen and (max-width: 740px) {
    left: 0;
    width: 100%;
    margin-top: 50px;
    padding-left: 0;
    ${(props) =>
      props.isMobile &&
      css`
        transition: left 0.5s ease-in-out;
        transition: none;
        margin-top: 100px;
      `}
    ${(props) =>
      props.showMenu
        ? css`
            left: 0;
          `
        : css`
            left: -890px;
          `}
  }
  > div {
    ${(props) =>
      props.isMobile &&
      css`
        /* max-width: 360px; */
      `}
  }
`;

export default CRMHomePage;
