import { createContext, useContext, Context as ReactContext } from 'react';
import { ChannelDto } from 'services/data-contracts';
import { ChannelDtoModel, CountType } from 'types/CommonTypes';

interface PropsTypes {
  myChannel?: ChannelDtoModel;
  unreadCount?: CountType;
  getMyChannel: () => void;
  setChannel: (channel: ChannelDto | undefined) => void;
  setUnreadCount: React.Dispatch<React.SetStateAction<CountType>>;
}

const Context = createContext<(PropsTypes & any) | undefined>(undefined);

function useRootContext(): PropsTypes {
  const context = useContext(Context as ReactContext<PropsTypes | undefined>);
  if (!context) {
    throw new Error('useStateContext must be used within a Provider that has a value');
  }
  return context;
}

export { useRootContext, Context, PropsTypes };
