import React, { useEffect } from 'react';
import { useStore } from 'stores/StoreHelper';
import { useLocation, useRoutes } from 'react-router-dom';
import { observer } from 'mobx-react';
import { sendGA } from 'features/mainFeatures/main/containers/MainContainerBase';
import { isMobile } from 'react-device-detect';
import { SEND_BIRD_KEY } from 'stores/AppStore';

import SBProvider from '@sendbird/uikit-react/SendbirdProvider';
import linksRoute from 'routes/linksRoute';

import ManagementRouter from 'features/mainFeatures/main/containers/ManagementRouter';
import MyRouter from 'features/mainFeatures/main/containers/MyRouter';
import MagRouter from 'features/mainFeatures/main/containers/MagRouter';
import { useRootContext } from 'libs/hooks/useRootContext';
import ChatRouter from 'features/mainFeatures/main/containers/ChatRouter';
import PublicRouter from 'features/mainFeatures/main/containers/PublicRouter';
import cstownRoute from 'routes/csTownRoute';
import dealsRoutes from 'routes/dealsRoutes';
import magRoutes from 'routes/magRoutes';
import mainRoutes from 'routes/mainRoutes';
import managementRoute from 'routes/managementRoute';
import myRoutes from 'routes/myRoutes';
import publicRoutes from 'routes/publicRoutes';
import LinksRouter from 'features/mainFeatures/main/containers/LinksRouter';
import styled, { css } from 'styled-components';
import { BrowserType } from 'types/CommonTypes';
import { useMediaContext } from 'providers/useMediaContext';
import ManagementPCRouter from 'features/mainFeatures/main/containers/ManagementPCRouter';
import MyPCRouter from 'features/mainFeatures/main/containers/MyPCRouter';
import CSTownRouter from 'features/mainFeatures/main/containers/CSTownRouter';
import CSTownPCRouter from 'features/mainFeatures/main/containers/CSTownPCRouter';

const LayoutWrapper = observer(() => {
  const { uiStore } = useStore();
  const { pathname } = useLocation();
  const { myChannel } = useRootContext();
  const ScreenTypes = useMediaContext();
  const currentRoute = useRoutes([
    ...cstownRoute,
    ...dealsRoutes,
    ...linksRoute,
    ...magRoutes,
    ...mainRoutes,
    ...managementRoute,
    ...myRoutes,
    ...publicRoutes,
  ]);

  const handleClickAppStore = (type: 'apple' | 'google') => {
    if (type === 'apple') {
      window.open('https://apps.apple.com/kr/app/oh-moss/id1665052510', '_blank');
    } else {
      window.open(
        'https://play.google.com/store/apps/details?id=com.nbhd.ohmoss&hl=en-KR',
        '_blank',
      );
    }
  };

  /**
   * @description 현재 어떤 기능에 접근 하는지 파악하여 반환함.
   * @description 현재는 경로를 기준으로 구분함.
   * @returns
   */
  const getRouterContainer = () => {
    const channelName = currentRoute?.props?.match?.params?.channelname;

    if (channelName) {
      return <LinksRouter />;
    }
    const path = window.location.pathname.split('/');
    const domain = path[1] || '';
    switch (domain) {
      case 'management':
        return ScreenTypes.isLarge ? <ManagementPCRouter /> : <ManagementRouter />;
      case 'my':
        return ScreenTypes.isLarge ? <MyPCRouter /> : <MyRouter />;
      case 'cstown':
        return (
          <SBProvider
            appId={SEND_BIRD_KEY as string}
            userId={(myChannel?.channelName as string) || ''}
            nickname={(myChannel?.channelName as string) || ''}
          >
            {isMobile ? <CSTownRouter /> : <CSTownPCRouter />}
          </SBProvider>
        );

      case 'mag':
        return <MagRouter />;
      case 'chat':
        return <ChatRouter />;
      // case 'cstown':
      //   return (
      //     <SBProvider
      //       appId={SEND_BIRD_KEY as string}
      //       userId={(myChannel?.channelName as string) || ''}
      //       nickname={(myChannel?.channelName as string) || ''}
      //     >
      //       {isMobile ? <CSTownRouter /> : <CSTownPCRouter />}
      //     </SBProvider>
      //   );
      default:
        return <PublicRouter />;
    }
  };

  useEffect(() => {
    uiStore.bottomSheet.close();
    uiStore.modal.close();

    if (process.env.NODE_ENV === 'production') {
      sendGA(pathname);
    }
  }, [pathname]);

  return (
    <LayoutContainerStyle browserType={window.browserType} id="layoutWrapper">
      {getRouterContainer()}
    </LayoutContainerStyle>
  );
});

const LayoutContainerStyle = styled.div<{ browserType: BrowserType }>`
  ${(props) => {
    if (props.browserType === 'ios') {
      return iosStyle;
    } else if (props.browserType === 'android') {
      return androidStyle;
    } else if (props.browserType === 'pc') {
      return pcStyle;
    }
  }}
`;

const iosStyle = css`
  height: -webkit-fill-available;
`;

const androidStyle = css`
  height: 100%;
`;

const pcStyle = css`
  /* background: #f9fafb; */
  @media ${(props) => props.theme.media.mobile} {
  }
`;

export default LayoutWrapper;
