import React, { useEffect, useRef, useState } from 'react';
import { Routes, Route, useLocation, useRoutes } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useStore } from 'stores/StoreHelper';

import csTownRoute from 'routes/csTownRoute';
import BottomSheetOnly from 'components/commons/BottomSheetOnly';
import FullModal from 'components/commons/FullModal';
import Confirm2 from 'components/commons/Confirm2';
import UniversalModal from 'components/commons/UniversalModal/Modal';
import styled from 'styled-components';
import useToken from 'libs/hooks/useToken';
import DetailPageHeader from 'components/commons/DetailPageHeader';
import ManagerCSTownNavigationTabBar from 'components/commons/ManagerCSTownNavigationTabBar';
import CSTownNavigationTabBar from 'components/commons/CSTownNavigationTabBar';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useChat } from 'libs/hooks/useChat';
import useMobileLayout from 'hooks/useMobileLayout';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import chatController from 'features/community/controllers/chatController';
import CSTownController from 'controllers/CSTownController';
import { LinkDto } from 'services/data-contracts';
import useNavigation from 'hooks/useNavigation';
import { initGA } from './MainContainerBase';
import AuthContainer from './AuthContainer';
import ProviderWrapper from './ProviderWrapper';

/**
 * @description Mobile Router
 * @returns
 */
const CSTownRouter = () => {
  const containerRef = useRef(null);

  const location = useLocation();
  const currentRoute = useRoutes(csTownRoute);
  const { setSize } = useMobileLayout(containerRef);
  const { acceptIncommingInvitations } = chatController();
  const { Brand } = CSTownController();

  const { myChannel } = useRootContext();
  const { validUser } = useToken();
  const { myStore } = useStore();
  const { initChat } = useChat();
  const { navigate } = useNavigation();

  const [validMember, setValidMember] = useState<boolean>();

  const getElement = (route: any) => {
    if (window.isWebView) {
      return route.webViewElement ? <route.webViewElement /> : <route.element />;
    } else if (isMobile) {
      return route.mobileElement ? <route.mobileElement /> : <route.element />;
    } else {
      return <route.element />;
    }
  };

  document.querySelector('html')!.style.background = '#fafafa';
  useEffect(() => {
    initGA();
  }, []);

  const initializeChat = async () => {
    await initChat(myChannel?.channelName as string);
    if (myChannel?.channelType !== 'SELLER') {
      // Seller가 아닌 경우, 초대장을 체크하여 수락한다.
      const dealsTemp = (await Brand.DEAL.getDelegatedDeals()) as any as LinkDto[];
      const deals = dealsTemp.map((deal) => deal.linkKey);
      if (deals?.length > 0) {
        acceptIncommingInvitations(deals as string[]);
      }
    }
  };

  useEffect(() => {
    if (myChannel && myChannel?.membershipType !== 'FREE') {
      initializeChat();
      setValidMember(true);
    }
    if (myChannel?.channelType === 'SELLER' && myChannel?.membershipType === 'FREE') {
      navigate('/');
    }
  }, [myChannel]);

  useEffect(() => {
    setSize();
    if (validUser()) {
      myStore.getLoginInfo();
    }
  }, []);

  return (
    <>
      {validMember === true && (
        <ManagementLayout>
          <div style={{ position: 'sticky', top: 0, zIndex: 2 }}>
            {currentRoute?.props?.match?.route?.useHeader !== false && (
              <DetailPageHeader
                title={currentRoute?.props?.match?.route?.title}
                gobackUrl={currentRoute?.props?.match?.route?.goBack}
              />
            )}
          </div>
          <div style={{}}>
            <Routes location={location}>
              {csTownRoute.map((route, index: number) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <AuthContainer isPublic={route.isPublic} title={route.title}>
                        <ProviderWrapper provider={route.provider}>
                          <BottomSheetOnly />
                          <FullModal />
                          <Confirm2 />
                          <UniversalModal />
                          {/* <div className="management-layout" ref={containerRef}> */}
                          {getElement(route)}
                          {/* </div> */}
                        </ProviderWrapper>
                      </AuthContainer>
                    }
                  />
                );
              })}
            </Routes>
          </div>
          {myChannel?.channelType === 'MEMBER' ? (
            <ManagerCSTownNavigationTabBar style={{}} />
          ) : (
            <CSTownNavigationTabBar type="main" style={{}} />
          )}
        </ManagementLayout>
      )}
      {/* </UniversalLayout> */}
    </>
  );
};

const MainContainer = styled.div`
  display: flex;
`;

const RightContainerStyle = styled.div`
  justify-content: center;
  margin: auto;
  max-width: 550px;
  width: 100%;
  background: #fff;
  flex-direction: column;
  z-index: 1;
  flex-grow: 1;
  @media screen and (max-width: 1160px) {
    height: 100%;
    justify-content: center;
    display: flex;
  }
`;
export default CSTownRouter;
