import React, { useEffect, useState } from 'react';
import { GroupChannel } from '@sendbird/chat/groupChannel';

import SBConversation from '@sendbird/uikit-react/GroupChannel';
import SBChannelSettings from '@sendbird/uikit-react/ChannelSettings';
import styled from 'styled-components';
import {
  ListBoxContentsTemplate,
  ListBoxTemplate,
  ListBoxWrapper,
} from 'features/csTown/commonComponents/styles';
import ChannelListBox from 'components/commons/chat/TalkChannelListBox';
import NoContent from 'components/commons/NoContent';
import { useStateContext } from 'libs/hooks/usePageState';
import { MyInquiryState } from 'types/CommonTypes';
import TalkMemberChannelListBox from 'components/commons/chat/TalkMemberChannelListBox';
import { AnimatedTabButton, TabItem } from 'components/commons/buttons/AnimatedTabButton';

const MyInquriesContainer = ({
  inquiries,
  channel,
  onJoinChannel,
  onLeaveChannel,
}: {
  inquiries?: GroupChannel[];
  channel?: string;
  onJoinChannel: (channel: GroupChannel) => void;
  onLeaveChannel: () => void;
}) => {
  const { broadcast } = useStateContext<MyInquiryState>();

  // const [unreadMessages, setUnreadMessages] = useState<{
  //   unreadGroup: number;
  //   unreadCustomer: number;
  // }>({ unreadGroup: 0, unreadCustomer: 0 });
  const [showSettings, setShowSettings] = useState(false);
  const [listType, setListType] = useState<'CUSTOMER' | 'PARTNER'>('CUSTOMER');

  const handleTabChange = (tab: number) => {
    if (tab === 1) {
      broadcast({ id: 'UPDATE', param: 'CUSTOMER' });
      setListType('CUSTOMER');
    } else {
      broadcast({ id: 'UPDATE', param: 'PARTNER' });
      setListType('PARTNER');
    }
  };

  // useEffect(() => {
  //   if (inquiries) {
  //     const unread = inquiries.reduce(
  //       (result, obj) => {
  //         if (obj.url && obj.url.startsWith('sendbird_group')) {
  //           result.unreadGroup += obj.unreadMessageCount || 0;
  //         } else {
  //           result.unreadCustomer += obj.unreadMessageCount || 0;
  //         }
  //         return result;
  //       },
  //       { unreadGroup: 0, unreadCustomer: 0 },
  //     );
  //     setUnreadMessages(unread);
  //   }
  // }, [inquiries]);

  return (
    <div style={{ width: '100%' }}>
      <ListBoxWrapper>
        <ListBoxTemplate style={{ padding: 0 }}>
          <div>
            <AnimatedTabButton
              onClick={(item: any) => {
                const key = Number(item.key);
                handleTabChange(key);
              }}
              style={{ width: 'calc(100% - 10px)' }}
            >
              <TabItem key={1}>고객문의</TabItem>
              <TabItem key={2}>파트너톡</TabItem>
            </AnimatedTabButton>
          </div>
          <div style={{ flexGrow: 1, position: 'relative' }}>
            {inquiries?.length === 0 ? (
              <NoContent title={'표시할 문의가 '} />
            ) : (
              <>
                {listType === 'CUSTOMER' ? (
                  // 고객문의
                  <ChannelListBox inquiries={inquiries} onJoinChannel={onJoinChannel} />
                ) : (
                  // 파트너 톡
                  <TalkMemberChannelListBox channels={inquiries} onJoinChannel={onJoinChannel} />
                )}
              </>
            )}
          </div>
        </ListBoxTemplate>
        <ListBoxContentsTemplate style={{ padding: '0 10px', height: 'calc(100vh - 85px)' }}>
          <SBConversation
            channelUrl={channel || ''}
            onChatHeaderActionClick={() => {
              setShowSettings(true);
            }}
          />
          {showSettings && (
            <SettingStyle>
              <SBChannelSettings
                channelUrl={channel || ''}
                onCloseClick={() => {
                  setShowSettings(false);
                }}
                onLeaveChannel={() => {
                  onLeaveChannel();
                  setShowSettings(false);
                }}
              />
            </SettingStyle>
          )}
        </ListBoxContentsTemplate>
      </ListBoxWrapper>
    </div>
  );
};

const SettingStyle = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 320px;
  height: 100vh;
`;
export default MyInquriesContainer;
