import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Collapse, IconButton } from '@mui/material';
import { Row } from 'components/commons/layouts';
import { useStateContext } from 'libs/hooks/usePageState';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useLocation, useSearchParams } from 'react-router-dom';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { ChannelChecklistItemDto } from 'services/data-contracts';
import { CSTownChecklistState } from 'types/CommonTypes';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import TitleForm from 'components/items/forms/TitleForm';
import ChecklistController from 'controllers/ChecklistController';
import styled from 'styled-components';
import { useStore } from 'stores/StoreHelper';
import ChecklistTopicSummaryContainer from './ChecklistTopicSummaryContainer';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const ChecklistTopicItemsContainer = () => {
  const [searchParam] = useSearchParams();
  // const checklistId = searchParam.get('id');
  const topicIndex = searchParam.get('topicIndex');
  // const topicId = searchParam.get('topic');
  // const category = searchParam.get('category');
  const descriptionRef = useRef<string>();
  const { uiStore } = useStore();
  const { myChannel } = useRootContext();
  const {
    getMyChecklistItems,
    getChannelChecklistTopic,
    getChecklistsByCategory,
    updateMyChecklistItem,
  } = ChecklistController();

  const { stateModel, setPageState, broadcast } = useStateContext<CSTownChecklistState>();
  const [items, setItems] = useState<ChannelChecklistItemDto[]>([]);
  const [itemsState, setItemsState] = useState<any>();

  const handleToggleForm = (index: number) => {
    setItemsState((prevState: any) =>
      prevState.map((item: any, i: number) =>
        i === index ? { ...item, isShowForm: !item.isShowForm } : item,
      ),
    );
  };

  const handleToggleQuestion = (index: number) => {
    setItemsState((prevState: any) =>
      prevState.map((item: any, i: number) =>
        i === index ? { ...item, isShowQuestion: !item.isShowQuestion } : item,
      ),
    );
  };

  const getChecklistItems = async () => {
    if (stateModel?.selectedChecklist) {
      // const tempTopics = await getChannelChecklistTopic(
      //   myChannel?.id as number,
      //   stateModel?.selectedChecklist?.id as number,
      // );
      const allChecklistItems = await getMyChecklistItems(
        myChannel?.id as number,
        stateModel?.selectedChecklist?.id as number,

        stateModel?.selectedTopic?.id as number,
      );

      if (allChecklistItems) {
        const newItems = allChecklistItems.filter((item: any) => {
          return item?.topicId === (stateModel?.selectedTopic?.id as number);
        });

        setItems(newItems);
      }
    } else {
      const allChecklistItems = (await getChecklistsByCategory(
        stateModel?.selectedChecklist?.category?.id as number,
        myChannel?.id as number,
      )) as any;
      setItems(allChecklistItems?.items as ChannelChecklistItemDto[]);
    }
  };

  const handleShowSummary = () => {
    uiStore.drawer.show({ children: <ChecklistTopicSummaryContainer /> });
  };

  const handleSaveChecklistItem = async (itemId: number, index: number) => {
    await updateMyChecklistItem(
      myChannel?.id as number,
      stateModel?.selectedChecklist?.id as number,
      stateModel?.selectedTopic?.id as number,
      itemId,
      {
        isCheck: itemsState[index]?.isCheck,
        remark: descriptionRef.current,
      },
    );

    setItemsState((prevState: any) =>
      prevState.map((item: any, i: number) =>
        i === index ? { ...item, remark: descriptionRef.current } : item,
      ),
    );
    descriptionRef.current = '';
  };

  const handleSaveChecklistItemCheck = async (itemId: number, index: number, value: boolean) => {
    await updateMyChecklistItem(
      myChannel?.id as number,
      stateModel?.selectedChecklist?.id as number,
      stateModel?.selectedTopic?.id as number,
      itemId,
      {
        isCheck: value,
        remark: itemsState[index].remark,
      },
    );

    broadcast({ id: 'reload', param: 'checklistItemUpdate' });

    setItemsState((prevState: any) =>
      prevState.map((item: any, i: number) => (i === index ? { ...item, isCheck: value } : item)),
    );
  };

  // useEffect(() => {
  //   if (location.pathname) {
  //     setTimeout(() => {
  //       const t = document.querySelector('.ohmoss-navigation-tab') as any;
  //       if (
  //         (t && location.pathname === '/my/deals/checklist/detail/list') ||
  //         location.pathname === '/cstown/checklist/detail/list'
  //       ) {
  //         t.style.display = 'none';
  //       } else {
  //         t.style.display = 'flex';
  //       }
  //     }, 0);
  //   }
  // }, [location.pathname]);

  useEffect(() => {
    getChecklistItems();
  }, [stateModel?.selectedTopic]);

  useEffect(() => {
    if (items) {
      setItemsState(
        items?.map((item) => ({
          ...item,
          isShowForm: false,
        })),
      );
    }
  }, [items]);

  return (
    <>
      <Row
        style={{
          padding: '0 20px',
          justifyContent: 'space-between',
        }}
      >
        <IconButton
          style={{ background: '#fff' }}
          onClick={() => {
            setPageState('selectedTopic', undefined);
          }}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
        <OhmossButton onClick={handleShowSummary} style={{ width: 'auto' }} type="square">
          <Row>
            <IconButton>
              <SummarizeOutlinedIcon />
            </IconButton>
            <div>{stateModel?.selectedTopic?.name} 요약보기</div>
          </Row>
        </OhmossButton>
      </Row>
      <Row
        style={{
          flexDirection: 'column',
          gap: 5,
          width: '100%',
          padding: '20px 0',
          alignItems: 'flex-start',
        }}
      >
        {itemsState?.map((item: any, index: number) => (
          <ContainerWrapperStyle>
            <Container>
              <>
                <Checkbox
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                  defaultChecked={item?.isCheck}
                  onChange={(e: any, value: boolean) => {
                    handleSaveChecklistItemCheck(item?.id, index, value);
                  }}
                />
                <div style={{ flexGrow: 1 }}>{item?.contents}</div>
                <div style={{ minWidth: 80 }}>
                  <IconButton>
                    <EditIcon
                      src="/images/management/pencil.svg"
                      alt=""
                      onClick={() => {
                        handleToggleForm(index);
                      }}
                    />
                  </IconButton>
                  <IconButton>
                    <QuestionIcon
                      src="/images/management/checklist/question.svg"
                      alt=""
                      onClick={() => {
                        handleToggleQuestion(index);
                      }}
                    />
                  </IconButton>
                  {/* <DeleteIcon onClick={() => {}} /> */}
                </div>
              </>
            </Container>
            <>
              {item?.remark !== null && <MyText>{item?.remark}</MyText>}
              <Collapse
                style={{ marginTop: item?.isShowForm ? 10 : 0 }}
                in={item?.isShowForm}
                collapsedSize={0}
              >
                <Row style={{ padding: '10px 30px', gap: 10, alignItems: 'flex-start' }}>
                  <TitleForm
                    style={{ borderRadius: '5px !important' }}
                    placeHolder="기록할 내용을 입력해주세요."
                    initialValue={item?.remark}
                    onChangeValue={(value: string) => {
                      descriptionRef.current = value;
                    }}
                    // onEntered={() => handleToggleForm(index)}
                  />
                  <OhmossButton
                    type="square"
                    style={{ height: 53, width: 200, borderRadius: 5 }}
                    onClick={() => handleSaveChecklistItem(item?.id, index)}
                  >
                    저장
                  </OhmossButton>
                </Row>
              </Collapse>
              <Collapse collapsedSize={0} in={item?.isShowQuestion && item?.description}>
                <Divider style={{ margin: 0 }} />
                <Row style={{ padding: '10px 30px', justifyContent: 'flex-start' }}>
                  <InfoIcon
                    src="/images/management/checklist/information.svg"
                    alt=""
                    visible={item?.isShowQuestion}
                  />
                  <InfoText>{item?.description}</InfoText>
                </Row>
              </Collapse>
            </>
          </ContainerWrapperStyle>
        ))}
      </Row>
    </>
  );
};

const ContainerWrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  border: 1px solid #b9c2b2;
  background: #fff;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  margin: 0 20px;
  align-items: center;

  color: rgba(0, 0, 0, 0.87);

  @media ${(props) => props.theme.media.mobile} {
    margin: 5px 0px;
  }
`;

const MyText = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: #808080;
  margin-top: 10px;
  margin-left: 30px;
  margin-bottom: 20px;
`;

const QuestionIcon = styled.img``;

const EditIcon = styled.img``;

const InfoIcon = styled.img<{ visible?: boolean }>``;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px dashed #cecece;
  margin: 20px 0px;
`;

const InfoText = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: rgba(76, 76, 76, 1);
  padding: 0px 0px 0px 30px;
`;

export default ChecklistTopicItemsContainer;
