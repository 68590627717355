import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { ChannelBoardDto, LinkDto, PostDto } from 'services/data-contracts';
import { ILinkItemType, linkTypes } from 'features/adminFeatures/admin/definitions/LinkTypes';
import { LinkModel } from 'types/CommonTypes';

import styled from 'styled-components';
import useParam from 'libs/hooks/useParam';
import MobileModalHeader from 'components/commons/MobileModalHeader';
import UniversalLayout from 'components/commons/layouts/UniversalLayout';
import LinkTypeSelector from 'features/adminFeatures/admin/components/LinkTypeSelector';
import DetailPageHeader from 'components/commons/DetailPageHeader';

const NewLinkPage = observer(() => {
  const { myPostStore, uiStore, uiStores } = useStore();
  const navigation = useNavigation();

  const { get } = useParam();
  const { boardid } = useParams();
  const [searchParam] = useSearchParams();
  const stores = useStore();

  const [model, setModel] = useState<LinkModel>();
  const [channelId, setChannelId] = useState<number>();
  const [boardId, setBoardId] = useState<number>();
  const [defaultLinkType, setDefaultLinkType] = useState<any>();

  function getEditorComponent<T extends LinkDto>(
    LinkType: ILinkItemType,
    model?: LinkModel,
  ): React.ReactElement {
    if (typeof LinkType === 'undefined') {
      return <></>;
    }
    const Children = LinkType.component;
    const newModel: any = { ...model, linkType: LinkType.id };

    return (
      <Children
        model={newModel as T}
        mode={typeof model === 'undefined' ? 'new' : 'modify'}
        stores={stores}
        onRegistComplete={(result: any, linkItem: LinkModel) => {
          if (linkItem === null) {
            return;
          }
          navigation.pop();
        }}
        onCancelModity={() => {}}
      />
    );
  }

  const handleSelectLinkType = (linkType: ILinkItemType, model: LinkDto) => {
    setModel(model);
    setDefaultLinkType(linkType);
  };

  useLayoutEffect(() => {
    const defaultlink = typeof defaultLinkType === 'undefined' ? linkTypes[0] : defaultLinkType;
    setDefaultLinkType(defaultlink);
    const boardId = Number(boardid);
    if (Number.isNaN(boardId)) {
      alert('게시판이 바르지 않습니다. 다시 시도 해 주세요.');
      navigation.pop();
    }
    setBoardId(boardId);

    uiStores.userHomeStore.setBoard({ id: boardId });
    setChannelId(get('channelid', 'number') as number);
  }, []);

  return (
    <UniversalLayout>
      {/* <DetailPageHeader title="새 링크" /> */}
      <LinkTypeSelector selectedType={defaultLinkType} onClickLinkType={handleSelectLinkType} />
      <EditorComponentStyle>{getEditorComponent(defaultLinkType, model)}</EditorComponentStyle>
    </UniversalLayout>
  );
});

const EditorComponentStyle = styled.div``;

export default NewLinkPage;
