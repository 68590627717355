import React, { useState, useRef, ReactChild, useEffect } from 'react';

import styled from 'styled-components';

type ToggleProps = {
  label?: any;
  id?: string;
  checked?: boolean;
  onToggleClick: (checked: boolean) => void;
  onText?: string;
  offText?: string;
};

const ToggleButton = ({ label, id, checked, onToggleClick, onText, offText }: ToggleProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [clickToggle, setClickToggle] = useState<boolean>(false);

  useEffect(() => {
    if (typeof checked !== 'undefined') {
      setClickToggle(checked);
    }
    return () => {
      setClickToggle(false);
    };
  }, [checked]);
  return (
    <div className="row" style={{ minWidth: 100, justifyContent: 'center' }}>
      {typeof label !== 'undefined' && <ToggleLabelStyle>{label}</ToggleLabelStyle>}
      <ToggleInputStyle
        ref={inputRef}
        type="checkbox"
        id={`toggle_${id}`}
        checked={clickToggle}
        hidden
        onClick={() => {
          onToggleClick(true);

          if (typeof inputRef.current?.checked !== 'undefined') {
            setClickToggle(inputRef.current?.checked);
            onToggleClick(true);
          }
        }}
      />
      <ToggleStyle htmlFor="toggle" className="toggleSwitch">
        <span />
        <ToggleTextStyle clickToggle={clickToggle}>{clickToggle ? 'ON' : 'OFF'}</ToggleTextStyle>
      </ToggleStyle>
    </div>
  );
};

const ToggleInputStyle = styled.input`
  &:checked + label {
    background: #80e530;
    > span {
      transition: all 0.3s ease-in-out;
      left: calc(100% - 1.8rem);
      background: #fff;
    }
  }
`;

const ToggleLabelStyle = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
`;
const ToggleStyle = styled.label`
  margin: 0 10px 0 10px;
  width: 65px;
  height: 32px;
  display: block;
  position: relative;
  border-radius: 30px;
  background-color: #dfdfdf;
  cursor: pointer;
  > span {
    transition: all 0.3s ease-in-out;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
    border-radius: 50%;
    background: #fff;
  }
`;

const ToggleTextStyle = styled.div<{ clickToggle?: boolean }>`
  color: white;
  position: relative;
  top: 6px;
  font-size: 14px;
  left: ${({ clickToggle }) => (clickToggle ? '6px' : '15px')};
`;

export default ToggleButton;
