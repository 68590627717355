import styled from 'styled-components';

export const MenuContainer = styled.div`
  border-radius: 0.875rem;
  background-color: #fff;
  padding: 1rem;
`;

export const Divider = styled.div`
  background: rgba(0, 0, 0, 0.35);
  width: 98%;
  height: 0.03125rem;
  margin: 10px 0px;
`;
