import { makeAutoObservable, toJS } from 'mobx';
import { toast } from 'react-toastify';
// import { SocialLoginDto } from 'services/Api';
// import { Auth as AuthService } from 'services/Auth';
import { My as MyService } from 'services/My';
import { UserDto, UserProfileDto } from 'services/data-contracts';

import { HttpClient } from 'services/http-client';

export interface isValid {
  isValid: boolean;
}

class MyStore {
  constructor() {
    makeAutoObservable(this);
  }

  api: MyService<unknown> = new MyService(new HttpClient());

  userInfo: UserProfileDto;

  loginInfo: UserDto;

  *getLoginInfo() {
    const { response } = yield this.api.getUserLoginInfo();
    this.loginInfo = response.data;
    return response.data;
  }

  *getMe() {
    const { response } = yield this.api.getUserProfile();
    this.userInfo = response.data;
  }

  *withDrawal() {
    try {
      yield this.api.withdrawal();
    } catch (e) {
      const t = e;
    }
    // return response.status === 200;
  }
}

export default MyStore;
