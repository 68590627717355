import { useMessageContext } from 'context/MessageProvider';
import { OWebViewPostMessageData } from 'ohmoss-common-types/dist/owebviewTypes';
import { useLayoutEffect } from 'react';

export default function useOWebViewMessageListenerEffect() {
  const { setMessage } = useMessageContext();
  useLayoutEffect(() => {
    if (!window.isWebView) {
      return;
    }

    const listener = (message: MessageEvent<any>) => {
      if (!message?.data) {
        return;
      }
      const data = JSON.parse(message.data) as OWebViewPostMessageData<any>;
      if (!data.type) {
        return;
      }
      setMessage(data);
    };

    document.addEventListener('message', listener);
    window.addEventListener('message', listener);
  }, []);
}
