import React, { memo, useEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { isValid } from 'libs/helper/utils';

import _ from 'lodash';
import LinkBoardType from 'features/adminFeatures/template/components/LinkBoardType';
import Avatar from 'components/commons/Avatar';
import Fnb from 'features/commonFeatures/components/Fnb';
import SocialComponent from 'features/commonFeatures/SocialComponent';
import { TextProps } from 'types/CommonTypes';
import Footer from 'components/items/Footer';
import { LinkDto } from 'services/data-contracts';

const ViewSimulator = observer(() => {
  const {
    myChannelStore,
    myLinkStore,
    uiStores,
    mySocialStore,
    myChannelStyleStore,
    channelStore,
  } = useStore();
  const { currentChannel } = myChannelStore;
  const { profileStore } = uiStores;
  const { profileMetadataObject } = profileStore;

  const [backgroundImage, setBackgroundImage] = useState<string | undefined>();
  const [backgroundColor, setBackgroundColor] = useState<string | undefined>();
  const [backgroundType, setBackgroundType] = useState<'image' | 'color' | undefined>();
  const [nicknameProps, setNicknameProps] = useState<TextProps>();
  const [introductionProps, setIntroductionProps] = useState<TextProps>();
  const [socialPosition, setSocialPosition] = useState<'top' | 'bottom'>();
  const [links, setLinks] = useState<Array<LinkDto>>([]);

  const getMyStyle = async () => {
    const style = (await myChannelStyleStore.getMyStyle(
      myChannelStore.currentChannel?.id as number,
    )) as any;
    channelStore.setMyStyle(style.style);
    console.log(style.style);
  };

  useEffect(() => {
    if (isValid(profileStore.profile)) {
      if (typeof profileMetadataObject !== 'undefined') {
        setNicknameProps(profileMetadataObject?.nicknameProps);
        setBackgroundType(profileMetadataObject?.backgroundProps?.backgroundType);
        setIntroductionProps(profileMetadataObject?.introductionProps);
        if (profileMetadataObject?.backgroundProps?.backgroundType === 'image') {
          setBackgroundImage(currentChannel?.profile?.backgroundImage?.publicUrl);
          setBackgroundColor(undefined);
        } else {
          setBackgroundImage(undefined);
          setBackgroundColor(profileMetadataObject?.backgroundProps?.backgroundColor);
        }
      } else {
        setBackgroundImage(undefined);
        setBackgroundType('color');
      }
      if (typeof profileMetadataObject?.socialProps === 'undefined') {
        setSocialPosition('top');
      } else {
        setSocialPosition(profileMetadataObject.socialProps.position);
      }
    }
  }, [profileStore.profile]);

  useEffect(() => {
    getMyStyle();
  }, [myChannelStore.currentChannel]);

  useEffect(() => {
    if (myLinkStore.links) {
      // setLinks(myLinkStore.links);
    }
  }, [myLinkStore.links]);

  return (
    <ContainerStyle
      isMobile={isMobile}
      backgroundUrl={backgroundType === 'image' ? backgroundImage : undefined}
      backgroundColor={backgroundType === 'color' ? backgroundColor : '#fff'}
    >
      <InnerContainerStyle>
        <AvatarContainerStyle>
          <Avatar profile={currentChannel?.profile} />
        </AvatarContainerStyle>
        {typeof currentChannel?.profile?.nickname !== 'undefined' &&
          currentChannel?.profile?.nickname !== null && (
            <TextStyle styleProps={nicknameProps}>{currentChannel?.profile?.nickname}</TextStyle>
          )}
        {typeof currentChannel?.profile?.introduction !== 'undefined' && (
          <TextStyle styleProps={introductionProps}>
            {currentChannel?.profile?.introduction}
          </TextStyle>
        )}

        {/* /** Todo 반드시 체크할것  */}
        {socialPosition === 'top' && (
          <SocialComponent
            socials={mySocialStore.socials}
            socialProps={profileStore.profileMetadataObject?.socialProps}
          />
        )}
        <LinkContainerStyle
          height={window?.deviceInfo?.bottomSpace + 50}
          isMobile={window.isWebView}
        >
          <LinkBoardType links={links} />
        </LinkContainerStyle>
        {/* {socialPosition === 'bottom' && (
          <SocialComponent
            socials={mySocialStore.socials}
            socialProps={profileStore.profileMetadataObject?.socialProps}
          />
        )} */}
        <div style={{ transform: 'scale(0.6)' }}>
          <Footer model={profileStore.profile} />
        </div>
      </InnerContainerStyle>
    </ContainerStyle>
  );
});

const ContainerStyle = styled.div<{
  backgroundUrl?: string | undefined;
  isMobile?: boolean;
  backgroundColor?: string;
}>`
  margin: 0 auto;
  width: 100%;
  height: 600px;
  max-height: 650px;
  overflow: hidden;
  scroll-behavior: smooth;
  border-radius: 40px;
  border: 10px solid #111;
  top: 15%;
  background: ${(props) => props.backgroundColor};

  ${(props) =>
    props.backgroundUrl &&
    css`
      background-image: url(${props.backgroundUrl});
      background-size: cover;
      background-position: center;
    `}

  overflow: auto;
  max-width: 20rem;
  font-size: 1rem;

  ${(props) =>
    props.isMobile &&
    css`
      width: 100%;
      height: 100%;
      max-height: 100%;
      border-radius: 0;
      max-width: 100%;
      border: none;
    `}

  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
    max-width: 100%;
    border: none;
  }
`;

const InnerContainerStyle = styled.div`
  min-height: 100%;
`;

const AvatarContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  > div > div {
    width: 6em;
    height: 6em;
  }
`;

const TextStyle = styled.div<{ styleProps?: any; }>`
  min-height: 20px;
  margin: 10px;
  word-break: break-all;
  white-space: pre-wrap;
  text-align: center;
  ${(props) => props.styleProps && { ...props.styleProps.style }};
  @media screen and (max-width: 1000px) {
    > div {
      font-size: 0.88em;
    }
    @media screen and (max-width: 400px) {
      > div {
        font-size: 0.75em;
      }
    }
  }
`;

const LinkContainerStyle = styled.div<{ height?: number; isMobile: boolean; }>`
  margin-top: 20px;
  width: calc(100% - 40px);
  margin: auto;
  margin-bottom: ${(props) => (props.isMobile ? '140px' : 0)};
  @media ${(props) => props.theme.media.mobile} {
    margin-top: ${(props) => (props.height ? `${props.height}px` : '70px')};
  }
`;

export default memo(ViewSimulator);
