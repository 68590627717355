import React, { useEffect, useRef, useState } from 'react';
import TextLabel from 'components/commons/labels/TextLabel';
import { Row } from 'components/commons/layouts';
import { OhmossButton } from 'resources/controls/OhmossButton';

import styled from 'styled-components';
import MobileModalHeader from 'components/commons/MobileModalHeader';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import TitleForm from 'components/items/forms/TitleForm';
import { useStore } from 'stores/StoreHelper';
import useToken from 'libs/hooks/useToken';
import { AdReportDto } from 'services/data-contracts';

const Report = ({ adId }: { adId?: number; }) => {
  const { adStore, myChannelStore, uiStore } = useStore();
  const { validUser } = useToken();

  const resons = [
    { id: 1, description: '개인정보 노출/명예훼손/저작권 침해되었습니다.' },
    { id: 2, description: '욕설/생명경시/혐오/차별적 표현입니다' },
    { id: 3, description: '불법정보나 불법 촬영물을 포함하고 있습니다' },
    { id: 4, description: '음란물입니다' },
    { id: 5, description: '스팸 글입니다' },
    { id: 6, description: '기타사유(5자이상)' },
  ];

  const [isEtc, setIsEtc] = useState<boolean>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [selectedReason, setSelectedReason] = useState<string | undefined>('');
  const [etcReason, setEtcReason] = useState<string | undefined>('');

  const handleClickReport = async () => {
    const report: AdReportDto = { reason: selectedReason };
    if (typeof adId === 'undefined') {
      alert('신고하려는 광고가 바르지 않습니다.');
    } else {
      const result = await adStore.reportAd(myChannelStore?.currentChannel?.id as number, adId, report);
      if (result) {
        uiStore.universalModal.close();
      } else {
        alert('신고가 정상적으로 이우러지지 않았습니다. 지속되면 고객센터로 문의 해 주세요!');
      }
    }
  };

  const handleChangeValue = (value: string) => {
    setIsEtc(value === '6');
    if (value !== '6') {
      const description = resons.find((reason: any) => reason.id === Number(value));
      setSelectedReason(description?.description);
    } else {
      setSelectedReason(etcReason);
    }
  };

  const handleChangeReason = (value: string) => {
    setSelectedReason(value);
    setEtcReason(value);
  };

  useEffect(() => {
    if (typeof selectedReason !== 'undefined') {
      setIsValid(selectedReason.length > 5);
    }
  }, [selectedReason]);

  return (
    <>
      <div style={{ width: '100%' }}>
        <div style={{ padding: 40, paddingTop: 10 }}>
          <BlockStyle>
            <TextLabel type="subject">잠깐! 오모스에서 알려드립니다.</TextLabel>
            <ol style={{ marginLeft: 10 }}>
              <li>
                오모스를 운영하는 (주)엔비에이치디는 <StrongStyle>정보 중개자</StrongStyle>이며, 상품
                판매자가 아닙니다.
              </li>
              <li>
                지식재산권 침해를 신고하는 경우를 제외하고 회원님의 신고는 익명으로 처리됩니다.
              </li>
              <li>
                잘못된 신고로 인해 제재를 받을 수 있습니다. 신중하게 신고해 주세요.
              </li>
            </ol>
          </BlockStyle>
        </div>
        <LinkSeparator />
        <div style={{ padding: 40, paddingTop: 10 }}>
          <BlockStyle>
            <TextLabel type="subject">사유선택</TextLabel>
            <FormControl fullWidth>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(e: any, value: any) => {
                  handleChangeValue(value);
                }}
              >
                {resons.map((reson: any) => (
                  <FormControlLabel
                    sx={{
                      '& .MuiFormControlLabel-label':
                        { fontSize: 14, textAlign: 'left' }
                    }}
                    style={{ fontSize: 12 }}
                    value={reson.id}
                    control={<Radio />}
                    label={reson.description}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <div style={{ margin: '0' }}>
              <TitleForm disabled={!isEtc} multiline rows={4} label='기타사유를 입력해주세요'
                onChangeValue={handleChangeReason} />
            </div>
          </BlockStyle>
        </div>
        <div style={{ width: '100%', textAlign: 'center', marginBottom: 10 }}>
          <OhmossButton disabled={!isValid} style={{ width: '90%', margin: 'auto' }} onClick={handleClickReport}>신고하기</OhmossButton>
        </div>
      </div>
    </>
  );
};

const BlockStyle = styled.div`
  > div {
    margin: 20px 0;
    margin-bottom:5px;
    font-size: 15px;
    font-weight: 700;
  }
  > ol > li {
    list-style: decimal;
    font-size: 13px;
  }
`;


const LinkSeparator = styled.div`
  border-top: 1px solid #bebebe;
`;

const StrongStyle = styled.div`
  color: #638dff;
  display: inline;
`;

export default Report;
