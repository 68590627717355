/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ChannelStyleDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MyChannelStyle<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 스타일정보
   *
   * @tags my channel style
   * @name GetStyle
   * @summary 스타일정보
   * @request GET:/v2/me/channels/{channelId}/styles/{type}
   * @secure
   */
  getStyle = (channelId: number, type: string, params: RequestParams = {}) =>
    this.http.request<ChannelStyleDto, any>({
      path: `/v2/me/channels/${channelId}/styles/${type}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 스타일 수정
   *
   * @tags my channel style
   * @name UpdateStyle
   * @summary 스타일 수정
   * @request PUT:/v2/me/channels/{channelId}/styles/{type}
   * @secure
   */
  updateStyle = (
    channelId: number,
    type: string,
    data: ChannelStyleDto,
    params: RequestParams = {},
  ) =>
    this.http.request<ChannelStyleDto, any>({
      path: `/v2/me/channels/${channelId}/styles/${type}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 스타일 등록
   *
   * @tags my channel style
   * @name CreateStyle
   * @summary 스타일 등록
   * @request POST:/v2/me/channels/{channelId}/styles/{type}
   * @secure
   */
  createStyle = (
    channelId: number,
    type: string,
    data: ChannelStyleDto,
    params: RequestParams = {},
  ) =>
    this.http.request<ChannelStyleDto, any>({
      path: `/v2/me/channels/${channelId}/styles/${type}`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 스타일 삭제
   *
   * @tags my channel style
   * @name DeleteStyle
   * @summary 스타일 삭제
   * @request DELETE:/v2/me/channels/{channelId}/styles/{type}
   * @secure
   */
  deleteStyle = (channelId: number, type: string, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/styles/${type}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
}
