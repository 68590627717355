import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@mui/material';
import { useStore } from 'stores/StoreHelper';
import { debounce } from 'lodash';
import { ChannelDto } from 'services/data-contracts';
import { t } from 'i18next';
import styled, { css } from 'styled-components';
import ChannelComponent from 'features/commonFeatures/components/ChannelComponent';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { formatString } from 'libs/helper/utils';
import { OhmossButton } from 'resources/controls/OhmossButton';
import useNavigation from 'hooks/useNavigation';

const ChannelSearchBox = observer(() => {
  const { myChannelStore, channelStore, uiStore } = useStore();
  const { navigate } = useNavigation();

  const handleClickSearch = useCallback(
    debounce(async (e: any) => {
      if (e.target.value.length > 1) {
        channelStore.searchChannels(e.target.value, myChannelStore.currentChannel?.id as number);
      } else {
        channelStore.unMount();
      }
    }, 500),
    [],
  );

  const handleClickChannel = (channel: ChannelDto) => {
    navigate(`/${channel.channelName}`);
    uiStore.modal.close();
  };

  useEffect(() => {
    return () => {
      channelStore.unMount();
    };
  }, []);

  return (
    <ChannelBoxStyle>
      <TextField
        inputRef={(input) => input && input.focus()}
        id="outlined-basic"
        label={t('A237')}
        variant="outlined"
        onChange={handleClickSearch}
        style={{ width: '100%' }}
      />
      <ChannelsStyle>
        <>
          {typeof channelStore.channels !== 'undefined' ? (
            <ChannelResultBoxStyle>
              <>
                {channelStore.channels?.length > 0 ? (
                  <>{formatString(t('A238'), channelStore.channels.length)}</>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <ErrorOutlineIcon style={{ fontSize: 35 }} />
                    {t('A239')}
                  </div>
                )}
              </>
            </ChannelResultBoxStyle>
          ) : (
            <div> {t('A240')}</div>
          )}
        </>
        {channelStore.channels?.map((channel: ChannelDto) => (
          <ChannelComponent channel={channel} onSelected={handleClickChannel} mode="view" />
        ))}
      </ChannelsStyle>
    </ChannelBoxStyle>
  );
});

const ChannelBoxStyle = styled.div`
  width: 100%;
  padding: 20px;
  min-height: 400px;
  position: relative;
  @media ${(props) => props.theme.media.mobile} {
    width: 90%;
    margin: auto;
  }
`;

const ChannelsStyle = styled.div`
  margin-top: 20px;
`;

const ChannelResultBoxStyle = styled.div`
  padding: 10px 20px;
  background: #efefef;
  margin: 10px 0;
  border-radius: 15px;
`;

const CloseStyle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
  width: calc(550px - 40px);
  position: absolute;
  bottom: 0;
`;

export default ChannelSearchBox;
