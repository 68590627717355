import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { t } from 'i18next';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { ProfileTypes } from 'features/management/context/providers/ContextTypes';
import { IChannelProfile } from 'types/BlockExtendsTypes';

import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import BackgroundColorComponent from '../components/BackgroundColorComponent';
import BackgroundImageComponent from '../components/BackgroundImageComponent';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import { ProfileModel } from 'types/CommonTypes';
import ProfileController from 'controllers/ProfileController';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import { useRootContext } from 'libs/hooks/useRootContext';
import ManagementInnerLayout from 'features/management/components/common/ManagementInnerLayout';

const BackgroundContainer = observer(() => {
  const { uiStore, uiStores } = useStore();
  const { profileStore } = uiStores;

  const { myChannel, setChannel } = useRootContext();

  const { initialBinding, serviceModel, initialModel } =
    useOhmossContext<ProfileTypes<ProfileModel>>();

  const { updateProfile } = ProfileController();
  useInitializeModel<Partial<ProfileModel>>(myChannel?.profile ?? {}, undefined, initialBinding);

  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleClickSave = async () => {
    let showConfirm = false;

    if (showConfirm) {
      uiStore.confirm.show({
        message: t('backgroundDelete'),
        onConfirmed: async () => {
          profileStore.updateImage('background', undefined, true);

          saveBackground();
        },
      });
    } else {
      saveBackground();
    }
  };

  const saveBackground = async () => {
    const result = await updateProfile(serviceModel);
    if (result) setChannel(result);
  };

  useEffect(() => {
    if (initialModel) {
      const t = myChannel?.profile as IChannelProfile;
    }
  }, [initialModel]);

  return (
    <ManagementInnerLayout>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label={t('A180')} value="1" />
            <Tab label={t('A181')} value="2" />
          </TabList>
        </Box>
        <TabPanel value="1" style={{ padding: 0 }}>
          <BackgroundImageComponent onSave={handleClickSave} />
        </TabPanel>
        <TabPanel value="2" style={{ padding: 0 }}>
          <BackgroundColorComponent onSave={handleClickSave} />
        </TabPanel>
      </TabContext>
    </ManagementInnerLayout>
  );
});

export default BackgroundContainer;
