import React from 'react';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useRootContext } from 'libs/hooks/useRootContext';
import { SimpleDealDto } from 'services/data-contracts';
import TalkChannelListBox from 'components/commons/chat/TalkChannelListBox';
import { useChat } from 'libs/hooks/useChat';

const DealInquiryList = ({
  deal,
  maxCount,
  onJoinChannel,
}: {
  deal?: SimpleDealDto;
  maxCount?: number;
  onJoinChannel: (channel: GroupChannel) => void;
}) => {
  const { myChannel } = useRootContext();
  const { channels } = useChat();

  return (
    <>
      {typeof myChannel !== 'undefined' && (
        <>
          <TalkChannelListBox
            thumbnailType="USER"
            inquiries={channels}
            maxCount={maxCount}
            onJoinChannel={onJoinChannel}
          />
          {channels.length === 0 && <div>등록된 고객문의가 없습니다.</div>}
        </>
      )}
    </>
  );
};

export default DealInquiryList;
