import { makeObservable, flow, action, observable } from 'mobx';
import { HttpClient } from 'services/http-client';
import { PartnerInvitationDto } from 'services/data-contracts';
import _ from 'lodash';
import i18n from 'i18n';
import { PartnerInvitationService } from 'services/PartnerInvitationService';
import { InvitationService } from 'services/InvitationService';
import { toast } from 'react-toastify';
import ServiceStoreBase from './ServiceStoreBase';

/**
 * @description Public Link Store
 */
class PartnerInvitationStore extends ServiceStoreBase {
  t = i18n.t;

  constructor() {
    super();
    makeObservable(this, {
      myPartners: observable,
      getPartnerInvitations: flow,
      createPartnerInvitation: flow,
      getpartnerInvitationById: flow,
      getInvitation: flow,
      acceptInvitation: flow,
      cancelInvitation: flow,
    });
  }

  api: PartnerInvitationService<unknown> = new PartnerInvitationService(new HttpClient());

  api2: InvitationService<unknown> = new InvitationService(new HttpClient());

  myPartners: Array<PartnerInvitationDto> = [];

  *getPartnerInvitations(channelId: number) {
    const { response } = yield this.api.getPartnerInvitations(channelId);
    if (response.status === 200) {
      this.myPartners = response.data;
      return response.data;
    } else {
      return [];
    }
  }

  *createPartnerInvitation(channelId: number, data: PartnerInvitationDto) {
    const { response } = yield this.api.createPartnerInvitation(channelId, data);
    if (response.status === 200) {
      toast(this.t('파트너 초대장을 발송했습니다.'));
    } else {
      toast.error(
        this.t('파트너 초대장 발송에 실패했습니다. 중복된 초대장이 있는지 확인해주세요.'),
      );
    }
    return response.status < 300 ? response : response;
  }

  *getpartnerInvitationById(channelId: number, invitationId: number) {
    const { response } = yield this.api.getPartnerInvitation(channelId, invitationId);
    return response.status < 300 ? response.data : response;
  }

  *getInvitation(invitationKey: string, query: { partnerChannelId: number }) {
    const { response } = yield this.api2.getInvitation(invitationKey, query);
    return response.status < 300 ? response : response;
  }

  *acceptInvitation(
    invitationKey: string,
    query: { partnerChannelId: number; inviterChannelName: string },
  ) {
    const { response } = yield this.api2.acceptInvitation(invitationKey, query);
    if (response.status === 200) {
      toast(this.t('파트너 초대를 수락했습니다.'));
    }
    return response.status < 300 ? response : response;
  }

  *cancelInvitation(channelId: number, invitationId: number) {
    const { response } = yield this.api.deletePartnerInvitation(channelId, invitationId);
    if (response.status === 200) {
      toast(this.t('파트너 초대를 취소했습니다.'));
    } else {
      toast.error(this.t('파트너 초대 취소에 실패했습니다.'));
    }
    return response.status < 300 ? response : response;
  }
}

export default PartnerInvitationStore;
