import React, { useEffect, useState } from 'react';

import BlockController from 'controllers/BlockController';
import {
  LinkDto,
  SimpleDealDto,
  CountDto,
  DailyCountSeriesDto,
  LocationCountSeriesDto,
} from 'services/data-contracts';
import useLocalParams from 'libs/hooks/useLocalParams';
import styled from 'styled-components';
import DashboardChartController from 'controllers/DashboardChartController';

import moment from 'moment';
import LineChart from 'features/myPage/components/dashboard/LineChart';
import DonutChart from 'features/myPage/components/dashboard/DonutChart';
import DealReviews from 'features/myPage/components/DealReviews';
import DealRecords from 'features/myPage/components/DealRecords';
import { Row } from 'components/commons/layouts';
import { IconButton } from '@mui/material';
import { useStore } from 'stores/StoreHelper';
import ShareBox from 'components/commons/ShareBox';

import {
  DetailHeader,
  ListBoxItemTemplate,
  MenuIconBoxStyle,
} from 'features/csTown/commonComponents/styles';
import ChatContainer from 'features/csTown/containers/ChatContainer';
import { useRootContext } from 'libs/hooks/useRootContext';

const Dashboard = ({
  deal,
  onEditMode,
}: {
  deal?: SimpleDealDto;
  onEditMode: (isEdit: boolean) => void;
}) => {
  const { getBlockByLinkKey } = BlockController<LinkDto>();
  const { myChannel } = useRootContext();
  const {
    getRegionClicks,
    getDailyClicks,
    getRefererClickCountsByDeal,
    getDailyFollowerCountsByChannel,
    getDailyVisitCountsByChannel,
  } = DashboardChartController();

  const { linkKey } = useLocalParams();
  const { uiStore } = useStore();
  const [visitCount, setVisitCount] = useState<CountDto[]>(); // 총 채널 방문자 수
  const [clickCount, setClickCount] = useState<CountDto[]>(); // 일일 딜 클릭 수
  const [followers, setFollowers] = useState<CountDto[]>(); // 팔로워 수
  const [region, setRegion] = useState<LocationCountSeriesDto[]>(); // 지역
  const [referer, setReferer] = useState<DailyCountSeriesDto[]>(); // 집입경로
  const [dashboardToggle, setDashboardToggle] = useState('딜 데이터 분석');

  const setSubMenuSet = () => {};

  const getDealBlock = async () => {
    if (linkKey && myChannel) {
      const block = await getBlockByLinkKey(linkKey);
      // setDealBlock(block);
    }
  };

  const getAnalytics = async () => {
    if (deal) {
      // Get today's date
      const endDate = moment(deal.endDate).format('YYYY-MM-DD');
      const startDate = moment(deal.startDate).format('YYYY-MM-DD');

      // 총 채널 방문자 수
      const dailyVisitCounts = (await getDailyVisitCountsByChannel(
        startDate,
        endDate,
      )) as unknown as CountDto[];
      setVisitCount(dailyVisitCounts as CountDto[]);
      // 일일 딜 클릭 수
      const dailyClicks = (await getDailyClicks(deal.id as number)) as unknown as CountDto[];
      setClickCount(dailyClicks as CountDto[]);
      // 팔로워 수
      const followers = (await getDailyFollowerCountsByChannel(
        startDate,
        endDate,
      )) as unknown as CountDto[];
      setFollowers(followers ?? []);
      // 지역
      const regionClicks = (await getRegionClicks(
        deal.id as number,
      )) as unknown as LocationCountSeriesDto[];
      setRegion(regionClicks ?? []);
      // 집입경로
      const refererClicks = (await getRefererClickCountsByDeal(
        deal.id as number,
      )) as unknown as DailyCountSeriesDto[];

      const updatedRefererClicks = refererClicks.map((item: LocationCountSeriesDto) => {
        if (item.location === '') {
          return { ...item, location: 'ohmoss' }; // title이 빈 문자열인 경우 'test'로 변경
        } else {
          return item; // title이 빈 문자열이 아닌 경우 변경하지 않음
        }
      });
      setReferer(updatedRefererClicks ?? []);
    }
  };

  useEffect(() => {
    getDealBlock();
  }, [linkKey, myChannel]);

  useEffect(() => {
    if (deal) {
      getAnalytics();
    }
  }, [deal]);

  useEffect(() => {
    setSubMenuSet();
  }, []);

  return (
    <div style={{}}>
      <Row style={{ alignItems: 'flex-start', gap: 15 }}>
        <div style={{ flex: 1 }}>
          <ListBoxItemTemplate>
            {dashboardToggle === '딜 데이터 분석' ? (
              <>
                <ChartContainer>
                  <LineChart title="클릭 및 접속" clickCount={clickCount} visitCount={visitCount} />
                </ChartContainer>
                <Row>
                  <ChartContainer>
                    <DonutChart title="접속지역" subTitle="상위 5개지역" data={region} />
                  </ChartContainer>
                  <ChartContainer>
                    <DonutChart
                      title="진입경로"
                      subTitle={typeof referer === 'undefined' ? '' : '상위 4개사이트'}
                      data={referer}
                    />
                  </ChartContainer>
                </Row>
              </>
            ) : (
              <>
                <DealRecords />
                <DealReviews />
              </>
            )}
          </ListBoxItemTemplate>
        </div>
      </Row>
    </div>
  );
};

const ChartContainer = styled.div`
  padding: 20px;
  flex: 1;
`;

export default Dashboard;
