import React, { useEffect, useState } from 'react';
import { ChannelDto } from 'services/data-contracts';
import styled from 'styled-components';

const GiftInfoCard = ({
  channelType,
  membership,
  hideMonth,
}: {
  channelType?: ChannelDto['channelType'];
  membership?: any;
  hideMonth?: boolean;
}) => {
  const getMembershipPeriod = (startDate: string, endDate: string) => {
    if (startDate && endDate) {
      const newStartDate = new Date(startDate);
      const startDateString = newStartDate.toLocaleDateString();
      const newEndDate = new Date(endDate);
      const endDateString = newEndDate.toLocaleDateString();
      return (
        <>
          {startDateString} ~ {endDateString}
        </>
      );
    }
  };

  const getMembershipName = (name: any) => {
    if (name) {
      return <>{name}</>;
    } else {
      return 'FREE 멤버십';
    }
  };

  return (
    <CardContainer>
      <CardLine>
        <CardItem style={{ fontWeight: 500 }}>
          {getMembershipName(membership?.membershipPlan?.name)}
          <Period>
            {getMembershipPeriod(membership?.purchaseDate, membership?.expirationDate)}
          </Period>
        </CardItem>
      </CardLine>

      <CardLine style={{ padding: '0 1.5rem' }}>
        <Divider />
      </CardLine>

      <CardLine>
        <CardItem>개월수: </CardItem>
        <CardItem>{membership?.monthly}개월</CardItem>
      </CardLine>

      <CardLine style={{ padding: '0 1.5rem' }}>
        <Divider />
      </CardLine>

      <CardLine>
        <CardItem>선물받는 분 :</CardItem>
        <CardItem>{membership?.receiverChannel?.channelName}</CardItem>
      </CardLine>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  border-radius: 1.25rem;
  align-self: stretch;
  background-color: #9de4bc;
  border: 1px solid #173300;
`;

const CardLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 1.5rem;
`;

const CardItem = styled.div`
  color: #173300;
  font-size: 1rem;
  font-weight: 100;
`;

const Divider = styled.div`
  width: 100%;
  border: 0.9px dashed rgba(255, 255, 255, 0.45);
`;

const Status = styled.div`
  display: flex;
  height: 1.375rem;
  padding: 0.25rem 0.625rem;
  align-items: center;
  border-radius: 62.4375rem;
  border: 1px solid rgba(239, 239, 239, 0.5);
  color: #173300;
  font-size: 0.75rem;
`;

const Period = styled.div`
  color: rgba(255, 255, 255, 0.45);
  font-size: 13px;
  font-weight: 300;
`;

export default GiftInfoCard;
