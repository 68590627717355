import { makeObservable, observable, flow } from 'mobx';
import { AdCategory as AdCategoryController } from 'services/AdCategory';
import { AdCategoryDto } from 'services/data-contracts';

import _ from 'lodash';
import { HttpClient } from 'services/http-client';
import i18n from 'i18n';
import ServiceStoreBase from './ServiceStoreBase';

export interface isValid {
  isValid: boolean;
}

class AdCategoryStore extends ServiceStoreBase {
  t = i18n.t;

  adCategories: Array<AdCategoryDto>;

  constructor() {
    super();
    makeObservable(this, {
      getAdCategories: flow,
      adCategories: observable,
    });
  }

  api: AdCategoryController<unknown> = new AdCategoryController(new HttpClient());

  *getAdCategories() {
    const { response } = yield this.api.getAdCategories();
    if (response.status === 200) {
      this.adCategories = response.data;
      return response.data;
    } else {
      this.adCategories = [];
      return [];
    }
  }



  unMount() {
    this.adCategories = [];
  }
}

export default AdCategoryStore;
