import React, { useState, useEffect } from 'react';
import { ToggleButton, ToggleButtonType } from 'components/commons/ToggleButton';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { ItemSectionStyle } from 'resources/controls/ItemSectionStyle';
import { t } from 'i18next';

import styled from 'styled-components';
// import BackgroundColorComponent from '../components/BackgroundColorComponent';
// import BackgroundImageComponent from '../components/BackgroundImageComponent';

import BackgroundContainer from '../background/containers/BackgroundContainer';
import LinkDesignContainer from '../linkDesign/containers/LinkDesignContainer';

const BackgroundList: Array<ToggleButtonType> = [
  {
    id: 'Background',
    iconSrc: '/images/image.svg',
    description: t('A72'),
    checked: 'checked',
  },
  {
    id: 'Design',
    iconSrc: '/images/colorfilter.svg',
    description: t('A73'),
  },
];

const AppearanceContainer = observer(() => {
  const { myChannelStore, fileStore, uiStore, uiStores } = useStore();
  const { profileStore } = uiStores;

  const [backgroundType, setBackgroundType] = useState<string>('Image');

  const getLinkContainer = () => {
    switch (backgroundType) {
      case 'Background':
        return <BackgroundContainer />;
      case 'Design':
        return <LinkDesignContainer />;
      // case 'Theme':
      // return <TemplateContainer />;
      default:
        break;
    }
  };

  const handleClickType = (item: ToggleButtonType) => {
    setBackgroundType(item.id);
  };

  useEffect(() => {
    if (myChannelStore.currentChannel?.profile) {
      profileStore.setProfile(myChannelStore.currentChannel?.profile);
    }
  }, [myChannelStore.currentChannel]);

  return (
    <div style={{ margin: 20 }}>
      <ItemSectionStyle style={{ background: 'transparent', boxShadow: 'none' }}>
        <ToggleButtonStyle>
          {BackgroundList.map((item) => (
            <ToggleContainerStyle
              key={item.id}
              onClick={() => {
                handleClickType(item);
              }}
            >
              <ToggleButton item={item} defaultChecked={backgroundType === item.id} />
            </ToggleContainerStyle>
          ))}
        </ToggleButtonStyle>
      </ItemSectionStyle>

      <GetLinkContainerStyle>
        <div>{getLinkContainer()}</div>
      </GetLinkContainerStyle>
    </div>
  );
});

const GetLinkContainerStyle = styled.div`
  margin: 0 0 50px 0;
`;

const ToggleButtonStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  @media ${(props) => props.theme.media.mobile} {
    flex-direction: column;
  }
`;

const ToggleContainerStyle = styled.div`
  margin-bottom: 5px;
  background: #fff;
  width: 50%;
  margin-right: 10px;
  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

export default AppearanceContainer;
