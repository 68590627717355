import { makeObservable, flow, observable, action } from 'mobx';
import { LinkDto, SocialLinkDto } from 'services/data-contracts';
import { MySocialLink as MySocialLinkService } from 'services/MySocialLink';
import { HttpClient } from 'services/http-client';
import i18n from 'i18n';

import _ from 'lodash';
import ServiceStoreBase from './ServiceStoreBase';

class MySocialStore extends ServiceStoreBase {
  t = i18n.t;

  constructor() {
    super();
    makeObservable(this, {
      socials: observable,
      social: observable,
      getSocials: flow,
      createSocialLink: flow,
      updateSocialLink: flow,
      deleteSocialLink: flow,
      reOrderLinkSeq: flow,
      appendSocial: action,
      updateSNSByItem: action,
    });
  }

  api: MySocialLinkService<unknown> = new MySocialLinkService(new HttpClient());

  socials: Array<SocialLinkDto>;

  social: SocialLinkDto;

  /** ========================================================================================= */

  *getSocials(channelId: number) {
    const { response } = yield this.api.getSocialLinks(channelId);
    this.socials = response.data;
  }

  *createSocialLink(channelId: number, snsData: SocialLinkDto) {
    const { response } = yield this.api.createSocialLink(channelId, snsData);
    super.handleByStatus(response, { successMessage: this.t('A218') });
    return response;
  }

  *updateSocialLink(channelId: number, snsData: SocialLinkDto) {
    const { response } = yield this.api.updateSocialLink(channelId, snsData.id as number, snsData);
    super.handleByStatus(response, { successMessage: this.t('A219') });
    return response;
  }

  // *updateLinkActive(linkId: number, isActive: boolean) {
  //   const { data } = yield this.api.updateLinkActive(linkId, { isActive });
  //   return typeof data.id !== 'undefined';
  // }

  *deleteSocialLink(channelId: number, socialLinkId: number) {
    const { response } = yield this.api.deleteSocialLink(channelId, socialLinkId);
    return super.handleByStatus(response, { successMessage: this.t('A220') });
  }

  *reOrderLinkSeq(SNSId: number, SNS: SocialLinkDto) {
    // const { data } = yield this.api.updateLinkSeq(SNSId, SNS);
  }

  appendSocial(SNS: SocialLinkDto) {
    const newSocials = [...this.socials];
    newSocials.push(SNS);
    this.socials = newSocials;
  }

  updateSNSByItem(SNS: SocialLinkDto, type: 'delete' | 'update') {
    const index = this.socials.findIndex((recentLink: SocialLinkDto) => recentLink.id === SNS.id);
    if (type === 'delete') {
      this.socials.splice(index, 1);
    } else {
      this.socials.splice(index, 1, SNS);
    }

    this.socials = [...this.socials];
  }
}

export default MySocialStore;
