import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { useParams, useSearchParams } from 'react-router-dom';
import { useStore } from 'stores/StoreHelper';
import { PostDto } from 'services/data-contracts';

import MobileAdPostItem from 'pages/MobileAdPostItem';
import { useRootContext } from 'libs/hooks/useRootContext';

const AdPostDetailPage = observer(() => {
  const { myPostStore } = useStore();
  const { boardid, postid, channelid } = useParams();

  const [searchParam] = useSearchParams();
  const [postInfo, setPostInfo] = useState<PostDto>();
  const [goBack, setGoBack] = useState<string>();

  const init = async () => {
    const cpcode = searchParam.get('cpcode');

    if (postid && channelid && boardid) {
      const channelId = Number.parseInt(channelid, 10);
      const boardId = Number.parseInt(boardid, 10);
      const postId = Number.parseInt(postid, 10);

      if (!Number.isNaN(channelId) && !Number.isNaN(boardId) && !Number.isNaN(postId)) {
        const post = (await myPostStore.getPost(channelId, boardId, postId)) as never as PostDto;
        if (post) {
          setPostInfo(post);
        }
      }
    }
    if (cpcode) {
      setGoBack(`/`);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {/* <DetailPageHeader title="POST" gobackUrl={goBack} /> */}
      {typeof postInfo !== 'undefined' && (
        <MobileAdPostItem
          isOwner
          post={postInfo}
          onClickVisit={() => {
            // handleClickVisit(ad);
          }}
          onClickFavorite={() => {}}
        />
      )}
    </>
  );
});

export default AdPostDetailPage;
