import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import MobileModalHeader from 'components/commons/MobileModalHeader';
import AdminGNB from '../components/AdminGNB';
import DetailPageHeader from 'components/commons/DetailPageHeader';

const EtcPage = observer(() => {
  const { t } = useTranslation();

  return <>{/* <DetailPageHeader title={'기타 관리'} /> */}</>;
});

export default EtcPage;
