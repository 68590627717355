import { setTokens } from 'libs/helper/localStorageHelper';
import { makeObservable, flow, action, observable } from 'mobx';
import { Push as PushService } from 'services/Push';
import { REST_API_URL, REST_API_URL_V2 } from 'stores/AppStore';
import { PushDto } from 'services/data-contracts_bak';
import ServiceStoreBase from './ServiceStoreBase';

export interface isValid {
  isValid: boolean;
}

class PushStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      pushMessage: flow,
    });
  }

  // api: PushService<unknown> = new PushService({
  //   // baseURL: REST_API_URL_V2,
  // });

  *pushMessage(channelId: number, pushInfo: PushDto) {
    // pushInfo.userIds = [24];
    pushInfo.topic = 'null';
    // pushInfo.data = { url: 'hihi' };
    // pushInfo.image.id
    // const test = {
    //   userIds: [24],
    //   title: '웹에서 전송',
    //   imageUrl:
    //     'https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png',
    //   contents: '안녕하세요, 테스트입니다.',
    //   data: {
    //     uri: 'ohmoss://market/product/1',
    //   },
    // };
    // const { response } = yield this.api.push(channelId, pushInfo);
    // return super.handleByStatus(response, { successMessage: '정상적으로 전송되었습니다.' });
  }
}

export default PushStore;
