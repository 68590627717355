import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Row } from '../layouts';
import useLocalParams from 'libs/hooks/useLocalParams';
import Thumbnail from '../Thumbnail';

interface XScrollItem<T> {
  id?: number;
  item?: T;
  title?: string;
  thumbnail?: string;
  seq?: number;
}

interface Props<T> {
  items?: XScrollItem<T>[];
  onSelectedItem?: (linkItem: XScrollItem<T>) => void;
}

const XScrollBox = <T extends any>({ items, onSelectedItem }: Props<T>) => {
  const { boardId } = useLocalParams();

  const initialized = useRef<boolean>(false);
  const selectedItem = useRef<XScrollItem<T>>();

  const [files, setFiles] = useState<XScrollItem<T>[] | undefined>([]);

  const setUpdateFiles = (targetFiles: XScrollItem<T>[]) => {
    setFiles(targetFiles);
  };

  function cutoffString(str: string, length: number) {
    if (str.length <= length) {
      return str;
    }

    const chars = [...str];
    return chars.slice(0, length).join('') + '..';
  }

  useEffect(() => {
    if (items) {
      if (!selectedItem.current) selectedItem.current = items[0];
      setUpdateFiles([...items]);
      initialized.current = true;
    }
  }, [items, selectedItem]);

  useEffect(() => {
    if (boardId) {
      const selected = items?.find((item: any) => item.item.id === boardId);
      selectedItem.current = selected;
    } else {
    }
  }, [boardId, items]);

  return (
    <ScrollContainer className="scroll-container">
      <Row
        style={{
          justifyContent: 'flex-start',
          flex: 1,
          width: 'auto',
          padding: '10px 0',
          marginLeft: 5,
          marginRight: 5,
        }}
      >
        {files?.map((linkItem: any, index: number) => (
          <TabContainerStyle selected={linkItem.seq === selectedItem?.current?.seq} key={index}>
            <DefaultIconStyle selected={linkItem.seq === selectedItem?.current?.seq}>
              <div style={{ position: 'relative' }}>
                <ImageStyle
                  onClick={() => {
                    selectedItem.current = linkItem;
                    if (onSelectedItem) onSelectedItem(linkItem);
                  }}
                >
                  <Thumbnail
                    prefix="ChannelBoard"
                    src={linkItem?.thumbnail}
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </ImageStyle>
              </div>
            </DefaultIconStyle>
            <TitleStyle selected={linkItem.seq === selectedItem?.current?.seq}>
              {cutoffString(linkItem.title, 10)}
            </TitleStyle>
          </TabContainerStyle>
        ))}
      </Row>
    </ScrollContainer>
  );
};

const TabContainerStyle = styled.div<{ selected?: boolean }>`
  /* background: ${(props) => (props.selected ? '#efefef' : '#ffffff')}; */
  padding: 10px 5px;
  border-radius: 15px;
  min-width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DefaultIconStyle = styled.div<{ selected?: boolean; valid?: boolean }>`
  width: 60px;
  height: 60px;
  margin: auto;
  background: ${(props) => (props.selected ? '#ffffff' : '#e5e5e5')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  cursor: pointer;
  outline: ${(props) => (props.selected ? '2px solid #2ebe59' : '2px solid transparent')};
  outline-offset: 3px;
  transition: background 0.2s ease-in-out;

  :hover {
    background: #2ebe59;
  }
`;

const ImageStyle = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
  object-fit: cover;
  width: 56px;
  height: 56px;
  border-radius: 56px;
`;

const TitleStyle = styled.div<{ selected?: boolean }>`
  margin-top: 10px;
  font-size: 12px;
  display: flex;
  text-align: center;
  color: ${(props) => (props.selected ? '#000000' : '#666666')};
  justify-content: center;
`;

export { XScrollBox, XScrollItem };
