/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import { SNSLinkItemModel } from 'types/CommonTypes';
import { ISocialBlock } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';

import SNSIconWrapper from 'components/items/SNSIconWrapper';
import styled from 'styled-components';

const SocialTemplate = ({ item }: { item?: ISocialBlock }) => {
  const [width, setWidth] = useState<number>();
  const [iconColor, setIconColor] = useState<string>();

  useEffect(() => {
    if (item?.iconSize) {
      switch (item.iconSize) {
        case 'small':
          setWidth(30);
          break;
        case 'middle':
          setWidth(40);
          break;
        case 'large':
          setWidth(50);
          break;
        default:
          setWidth(40);
          break;
      }
    }
  }, [item?.iconSize]);

  useEffect(() => {
    if (item) {
      switch (item.iconColorType) {
        case 'origin':
          setIconColor(undefined);
          break;
        case 'light':
          setIconColor('#ffffff');
          break;
        case 'dark':
          setIconColor('#1f1f1f');
          break;
        case 'gray':
          setIconColor('#7c7c7c');
          break;
        case 'custom':
          setIconColor(item.iconColor ?? '#000000');
          break;
        default:
          setIconColor('#efefef');
          break;
      }
    }
  }, [item]);
  return (
    <div style={{ padding: 20, background: item?.iconColorType === 'light' ? '#f9f9f9' : 'none' }}>
      <Row style={{ gap: 15 }}>
        {item?.items
          ?.filter((sns) => sns.isActive)
          .map((sns: SNSLinkItemModel, index: number) => (
            <div style={{ cursor: 'pointer' }} key={index}>
              {sns?.title === 'custom' ? (
                <>
                  <ImageStyle>
                    <img src={sns.file?.publicUrl} style={{ width }} alt="" />
                  </ImageStyle>
                </>
              ) : (
                <SNSIconWrapper
                  linkUrl={sns?.linkUrl}
                  width={width}
                  name={sns?.title}
                  color={iconColor}
                  openNewWindow={item?.openNewWindow}
                  iconType={item?.iconColorType === 'origin' ? 'origin' : 'solid'}
                />
              )}
            </div>
          ))}
      </Row>
    </div>
  );
};

const ImageStyle = styled.div`
  /* width: 40px; */
  overflow: hidden;
  border-radius: 8px;
`;
export default SocialTemplate;
