import React, { CSSProperties, useEffect, useState } from 'react';
import { useRootContext } from 'libs/hooks/useRootContext';
import MyAnalyticsStore from 'stores/MyAnalyticsStore';
import moment from 'moment';
import { CountDto, SimpleDealDto } from 'services/data-contracts';
import CSTownDealsController from 'features/csTown/pages/pageControllers/CSTownDealsController';
import PCDealList from 'features/csTown/components/pc/DealList';
import useNavigation from 'hooks/useNavigation';
import { Row } from 'components/commons/layouts';

const OnGoingDealsBox = ({ style }: { style?: CSSProperties }) => {
  const myAnalyticsStore = new MyAnalyticsStore();
  const { navigate } = useNavigation();
  const { getDeals, deals } = CSTownDealsController();
  const { myChannel } = useRootContext();

  const [onGoingDeals, setOnGoingDeals] = useState<SimpleDealDto[]>();

  const handleClickDeal = (deal: SimpleDealDto) => {
    navigate(`/cstown/deals/${deal.linkKey}`);
  };

  useEffect(() => {
    if (deals) {
      const onGoing = deals?.filter((deal) => deal.status === 'ONGOING');
      setOnGoingDeals(onGoing);
    }
  }, [deals]);

  useEffect(() => {
    if (myChannel) {
      getDeals();
    }
  }, [myChannel]);
  return (
    <div style={{ margin: 20, ...style }}>
      {onGoingDeals && onGoingDeals?.length > 0 ? (
        <PCDealList deals={onGoingDeals} onClick={handleClickDeal} />
      ) : (
        <Row>
          <div>진행중인 공구가 없습니다.</div>
        </Row>
      )}
    </div>
  );
};

export default OnGoingDealsBox;
