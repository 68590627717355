import React, { CSSProperties, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd';
import styled from 'styled-components';
import { PostDto } from 'services/data-contracts';

import MyShopCardListItem from './MyShopCardListItem';

interface itemType extends PostDto {
  tempSeq: number;
}

const CardDragDrop = observer(
  ({
    items,
    itemsPerRow,
    style,
    thumbnailStyle,
    onClick,
    onChangeItemSeq,
  }: {
    items: Array<PostDto>;
    thumbnailBackgroundColor?: string;
    thumbnailColor?: string;
    style: CSSProperties;
    thumbnailStyle: CSSProperties;
    useThumbnailInfo?: boolean;
    itemsPerRow: number | undefined;
    onClick: (item: PostDto) => void;
    onChangeItemSeq: (item: PostDto) => void;
  }) => {
    const [newItems, setItems] = useState<Array<PostDto>>([]);
    const [rowItemCount, setRowItemCount] = useState<number>(2);

    const onChange = (
      sourceId: string,
      sourceIndex: number,
      targetIndex: number,
      targetId: string,
    ) => {
      const nextState = swap(newItems, sourceIndex, targetIndex);
      onChangeItemSeq({ ...nextState[targetIndex], seq: targetIndex });
      setItems(nextState);
    };

    useEffect(() => {
      if (itemsPerRow) {
        setRowItemCount(itemsPerRow);
      }
      if (items) {
        setItems(items);
      }
    }, [itemsPerRow, items]);

    return (
      <>
        <ConatinerStyle>
          <GridContextProvider onChange={onChange}>
            <GridDropZone
              id="items"
              boxesPerRow={rowItemCount}
              rowHeight={550 / rowItemCount}
              style={{
                //Height가 지정되지 않으면 Drag and Drop이 안됨
                height: 'calc(100vh - 350px)',
                maxWidth: 550,
                margin: 'auto',
              }}
            >
              {newItems.map((item: PostDto) => (
                <GridItem
                  key={item.id}
                  style={{ padding: 2, cursor: 'pointer' }}
                  // onClick={() => {
                  //   onClick(item);
                  // }}
                >
                  <MyShopCardListItem
                    item={item}
                    onClick={onClick}
                    layout={rowItemCount}
                    useThumbnailInfo
                    style={{
                      ...style,
                    }}
                    thumbnailStyle={{
                      ...thumbnailStyle,
                    }}
                  />
                </GridItem>
              ))}
            </GridDropZone>
          </GridContextProvider>
        </ConatinerStyle>
      </>
    );
  },
);

const ConatinerStyle = styled.div``;

export default CardDragDrop;
