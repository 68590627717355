import { extractAdditionalProperties } from 'libs/hooks/useModelUpdate';
import { linkDtoDummy } from 'features/management/context/dummy';
import { LinkItemDtoDummy } from 'features/management/context/dummy/LinkItemDtoDummy';
import { Blocks } from 'features/management/components/block';

import FileStore from 'stores/FileStore';
import MyLinkStore from 'stores/MyLinkStore';
import { useStore } from 'stores/StoreHelper';
import useLocalParams from 'libs/hooks/useLocalParams';
import { parseJSONAll } from 'libs/helper/utils';
import { DealOptionDto, LinkDto, LinkItemDto } from 'services/data-contracts';
import { CustomFile } from 'types/BlockExtendsTypes';
import { useRootContext } from 'libs/hooks/useRootContext';
import MyDealStore from 'stores/MyDealStore';
import { DealServiceItemModel } from 'types/CommonTypes';
import { useStateContext } from 'libs/hooks/usePageState';

/**
 * @description 각 Block의 등록/수정/삭제를 수행함.
 * @returns
 */
const BlockController = <T extends LinkDto>() => {
  const fileStore = new FileStore();
  const myLinkStore = new MyLinkStore();
  const myDealStore = new MyDealStore();

  const { xhrStore } = useStore();
  const { categoryId } = useLocalParams();
  const { myChannel } = useRootContext();
  const { broadcast } = useStateContext();

  const uploadImage = async (thumbnail: CustomFile) => {
    return fileStore.uploadImage(thumbnail?.blob, 'Link');
  };

  const getBlockById = async (blockId: number) => {
    // return myLinkStore.getItem(myChannel?.id as number, id);
    const result = (await myLinkStore.getItem(
      myChannel?.id as number,
      242,
      blockId,
    )) as unknown as LinkDto;

    return result;
  };

  const getBlockByLinkKey = async (linkKey: string) => {
    const result = (await myLinkStore.getItemByKey(linkKey)) as unknown as LinkDto;
    if (result) {
      const metadata =
        result?.metadata === '' ? JSON.parse('{}') : JSON.parse(result?.metadata as string);
      if (result.items) {
        const blockItems = result?.items?.map((item) => ({
          ...item,
          ...(parseJSONAll(item?.metadata) as DealServiceItemModel),
        }));
        const newDeal = { ...result, ...metadata, items: blockItems };
        return newDeal;
      } else {
        return result;
      }
    }
  };

  /**
   * @param standAlonModel 내부의 모델을 사용하지 않고, 직접 모델을 전달하는 경우임.(isActive수정등)
   * @param useFetchData
   * @returns
   */
  const updateBlock = async (serviceModel: T) => {
    xhrStore.setState('pending');
    const newModel = Object.assign({}, serviceModel);
    const targetCategoryId = !Number.isNaN(categoryId)
      ? categoryId
      : (serviceModel.boardId as number);

    try {
      if (typeof newModel === 'undefined') {
        return;
      }
      const extracted = extractAdditionalProperties<T, LinkDto>(newModel, linkDtoDummy);
      if (newModel.items && newModel.items.length > 0) {
        await preProcess(newModel.items);
      }

      if ((newModel?.thumbnail as CustomFile)?.blob) {
        const file = (await uploadImage(newModel?.thumbnail as CustomFile)) as CustomFile;
        newModel.thumbnail = { id: file.id };
      }

      if (typeof extracted !== 'undefined' && Object.keys(extracted).length > 0) {
        newModel.metadata = JSON.stringify(extracted);
      }
      if (newModel.id) {
        const result = await myLinkStore.updateItem(
          myChannel?.id as number,
          targetCategoryId as number,
          newModel,
        );
        xhrStore.setState('done');
        broadcast({ id: 'ACTION', param: { id: 'LINK_UPDATE_DONE', categoryId } });
        return result;
      } else {
        if (!newModel.items) {
          newModel.items = [];
        }

        const result = await myLinkStore.createItem(
          myChannel?.id as number,
          targetCategoryId as number,
          newModel,
        );
        xhrStore.setState('done');
        broadcast({ id: 'ACTION', param: { id: 'LINK_UPDATE_DONE', categoryId } });
        return result;
      }
    } catch (e) {
      console.log(e);
      xhrStore.setState('error');
    }
  };

  const preProcess = async (items?: LinkItemDto[]) => {
    return Promise.all(
      (await items?.map(async (item: LinkItemDto, index: number) => {
        const file = item?.file as CustomFile;
        if (file?.blob?.constructor.name === 'File') {
          const result = (await fileStore.uploadImage(
            (item.file as CustomFile).blob,
            'Link',
          )) as CustomFile;
          item.file = { id: result.id };
        }
        item.isActive = typeof item?.isActive === 'undefined' ? false : item.isActive;
        const extracted = extractAdditionalProperties(item, LinkItemDtoDummy);
        if (typeof extracted !== 'undefined') {
          item.metadata = JSON.stringify(extracted);
        }
        return item;
      })) as unknown as LinkItemDto[],
    );
  };

  function updateItem<U extends LinkItemDto>(
    serviceModel: T,
    index: number,
    newItem: U | null,
  ): void {
    if (!serviceModel || !serviceModel.items) return;

    // Items 배열의 특정 항목을 갱신
    const updatedItems = [...serviceModel.items];
    if (newItem !== null) {
      updatedItems[index] = newItem; // newItem으로 교체
    } else {
      updatedItems.splice(index, 1); // 해당 위치의 항목 삭제
    }

    serviceModel.items = updatedItems;
  }

  const getBlockTypeComponent = (item: Partial<LinkDto>) => {
    const temp = item.linkType;

    const SelectedBlock = Blocks[temp as keyof typeof Blocks].component;
    const metadata = JSON.parse(item?.metadata || '{}');

    const blockItems = item?.items?.map((item) => ({
      ...item,
      ...(parseJSONAll(item?.metadata) as any),
    }));

    const newItem: LinkDto = { ...item, ...metadata, items: blockItems };

    return { Component: SelectedBlock, newItem };
    // SelectedBlock.blockModel = newItem;
    //   <SelectedBlock blockModel={typeof seq !== 'undefined' ? undefined : newItem} seq={seq} />
  };

  const saveDealOptions = async (boardId: number, linkId: number, options: DealOptionDto[]) => {
    return await myDealStore.saveDealOption(myChannel?.id as number, boardId, linkId, options);
  };

  const getDealOptions = async (boardId: number, linkId: number) => {
    return await myDealStore.getDealOptions(myChannel?.id as number, linkId);
  };

  const deleteBlock = async (item: T) => {
    xhrStore.setState('pending');
    const targetCategoryId = !Number.isNaN(categoryId) ? categoryId : (item.boardId as number);
    xhrStore.setState('done');

    return await myLinkStore.deleteItem(
      myChannel?.id as number,
      targetCategoryId as number,
      item?.id as number,
    );
  };
  return {
    saveDealOptions,
    getDealOptions,
    getBlockById,
    updateBlock,
    deleteBlock,
    updateItem,
    getBlockTypeComponent,
    getBlockByLinkKey,
  };
};

export default BlockController;
