import React, { useState } from 'react';
import { Blocks, BlockType } from 'features/management/components/block';
import { useStore } from 'stores/StoreHelper';
import { useRootContext } from 'libs/hooks/useRootContext';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import styled from 'styled-components';
import useNavigation from 'hooks/useNavigation';

const BlockSelectorMobile = ({ onClick }: { onClick: (block: BlockType) => void }) => {
  const { myChannel } = useRootContext();
  const [showCount, setShowCount] = useState<number>(5);

  return (
    <LinkContainerStyle className="block-selector">
      <LinkContentStyle>
        {Object.entries(Blocks)
          .slice(0, showCount)
          .map(([key, value]) => (
            <>
              {(value as BlockType).temp !== true && (
                <div
                  style={{
                    opacity:
                      (value as BlockType).channelType === 'SELLER' &&
                      (myChannel?.membershipType === 'FREE' || myChannel?.channelType === 'BRAND')
                        ? 0.3
                        : 1,
                  }}
                >
                  <LinkItemStyle
                    onClick={() => {
                      onClick(value as BlockType);
                    }}
                  >
                    <img src={value.icon} alt="" />
                  </LinkItemStyle>
                  <div
                    style={{
                      fontSize: 10,
                    }}
                  >
                    {value.title}
                  </div>
                </div>
              )}
            </>
          ))}
        <LinkItemStyle
          style={{ width: 60 }}
          onClick={() => {
            setShowCount(showCount === 5 ? 100 : 5);
          }}
        >
          {showCount === 5 ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        </LinkItemStyle>
      </LinkContentStyle>
    </LinkContainerStyle>
  );
};

const LinkContainerStyle = styled.div`
  padding: 20px;
  position: fixed;
  left: 0;
  right: 0;
  min-height: 50px;
  bottom: 0px;
  text-align: center;
`;

const LinkContentStyle = styled.div`
  margin: auto;
  max-width: 500px;
  width: 100%;
  border: 1px solid #efefef;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
`;

const LinkItemStyle = styled.div`
  display: flex;
  justify-content: center;

  align-items: center;
  border-radius: 100px;
  min-width: 40px;
  height: 40px;
  max-width: 100px;
  border: 1px solid #efefef;
  cursor: pointer;
`;

const TemplateContainer = styled.div`
  padding: 20px;
`;
export default BlockSelectorMobile;
