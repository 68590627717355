import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import styled from 'styled-components';
import { useStore } from 'stores/StoreHelper';
import moment from 'moment';
import CalendarEditor from 'features/board/components/CalendarEditor';
import koLocale from '@fullcalendar/core/locales/ko';
import enLocale from '@fullcalendar/core/locales/en-gb';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import { useParams } from 'react-router-dom';
import boardCommonLogics from 'features/board/logic/boardCommonLogic';
import CalendarItemComponent from './CalendarItemComponent';
import PostModifyTool from './PostModifyTool';

/**
 * @description 실제 링크를 표시하는 컴포넌트
 * @description 시뮬레이터와 사용자링크 페이지에서 함께 사용하므로
 * @description 사이드이펙트에 주의할것.
 */
const Calendar = observer((props) => {
  const { uiStore, channelStore, uiStores } = useStore();
  const { boardId, itemId } = useParams();
  const { handleClickEdit, handleClickDelete } = boardCommonLogics();

  const [events, setEvents] = useState([]);
  const [locale, setLocale] = useState();

  const handleDateChange = (props) => {
    if (typeof props.event === 'undefined' && !channelStore.channel.isOwner) {
      return;
    }
    uiStore.universalModal.show({
      useDrawer: true,
      bottomSheetOptions: { topPosition: 10, blocking: false },
      title: `${typeof props.event === 'undefined' ? '생성하기' : props.event.title}`,
      description: `${
        typeof props.event === 'undefined'
          ? '내 일정을 공유해주세요'
          : moment(props.event?._def?.extendedProps?.created || props.event.createDate)
              .utc()
              .fromNow()
      }`,
      modalOption: {
        style: { maxWidth: 800, height: 800 },
      },
      children:
        typeof props.event === 'undefined' ? (
          <CalendarEditor model={props} />
        ) : (
          <>
            <ItemContanerStyle>
              <PostModifyTool
                onClickDelete={() => {
                  handleClickDelete(props.event._def.extendedProps.origin);
                }}
                onClickEdit={() => {
                  handleClickEdit(
                    props.event._def.extendedProps.origin,
                    uiStores.userHomeStore.currentBoard,
                  );
                }}
              />
              <CalendarItemComponent model={props.event} />
            </ItemContanerStyle>
          </>
        ),
    });
  };

  // const events = [{ title: 'Meeting', start: '2023-06-01', end: '2023-06-12' }];
  const renderEventContent = (eventInfo) => {
    return (
      <>
        {/* <b>{eventInfo.timeText}</b> */}
        <i>{eventInfo.event.title}</i>
      </>
    );
  };

  useEffect(() => {
    if (props.posts) {
      const events = props.posts.map((post) => {
        return {
          title: post.title,
          start: post.startDate,
          end: post.endDate,
          id: post.id,
          created: post.createDate,
          origin: post,
        };
      });
      setEvents(events);
    }

    if (boardId && props.posts) {
      const id = parseInt(itemId, 10);
      const target = props.posts.find((post) => post.id === id);
      if (target) {
        handleDateChange({ event: target });
      }
    }
  }, [props.posts, boardId, itemId]);

  // useEffect(() => {
  //   showBoardItem();
  // }, [boardId, itemId, channelStore.channel, userHomeStore.currentBoard]);

  useEffect(() => {
    switch (document.documentElement.lang) {
      case 'en':
        setLocale(enLocale);
        break;
      case 'ko':
        setLocale(koLocale);
        break;
      default:
        break;
    }
  }, []);

  return (
    <div style={{ background: '#fff', borderRadius: 15 }}>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        style={{ width: 600 }}
        contentHeight={550}
        locale={locale}
        events={events}
        eventContent={renderEventContent}
        dateClick={handleDateChange}
        eventClick={handleDateChange}
      />
    </div>
  );
});

const ItemContanerStyle = styled.div`
  width: 100%;
`;
export default Calendar;
