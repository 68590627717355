import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import useNavigation from 'hooks/useNavigation';
import { useStore } from 'stores/StoreHelper';
import { useTranslation } from 'react-i18next';
import { validationHelper as VH } from 'controls/validationHelper';
import { toast } from 'react-toastify';
import EmailForm from 'components/items/forms/EmailForm';
import { OhmossButton } from 'resources/controls/OhmossButton';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import DetailPageHeader from 'components/commons/DetailPageHeader';

interface InfoDto {
  email?: string;
  userName?: string;
}

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const { authStore } = useStore();
  const [email, setEmail] = useState<string>();
  const [isActiveButton, setIsActiveButton] = useState<boolean>(false);

  const handleClickResetPassword = async () => {
    if (typeof email !== 'undefined') {
      const result = (await authStore.forgotPassword({ username: email })) as any;
      if (result === true) {
        navigate('/email-auth');
      } else {
        toast(result.errorMessage, { type: 'warning' });
      }
    }
  };

  useEffect(() => {
    if (email) {
      setIsActiveButton(VH.email(email));
    }
  }, [email]);

  return (
    <>
      <DetailPageHeader title="패스워드 재설정" />
      <ManagementLayout>
        <FindIdContainer>
          <OhMossImg src="/images/ohmossc.png" alt="" />
          <LoginTitle>패스워드를 재 설정할게요!</LoginTitle>
          <DesctiptionStyle>
            패스워드를 재설정은 이메일로 가입하신 경우에만 <br /> 제공되며, 가입하신 이메일로
            패스워드 재 설정 <br />
            안내가 발송됩니다.
          </DesctiptionStyle>
          <div style={{ marginBottom: 20, fontSize: '14px' }}>
            가입하신 이메일 주소를 입력 후 <br /> 하단의 "인증메일 받기" 버튼을 눌러주세요.
          </div>
          <EmailForm
            style={{ width: '90%' }}
            onChangeValue={setEmail}
            onEnter={handleClickResetPassword}
          />

          <OhmossButton
            disabled={!isActiveButton}
            style={{ marginTop: 20 }}
            type="simple"
            onClick={handleClickResetPassword}
          >
            인증메일 받기
          </OhmossButton>
        </FindIdContainer>
      </ManagementLayout>
    </>
  );
};

const FindIdContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const LoginTitle = styled.div`
  text-align: center;
  font-size: 30px;
  margin-top: 30px;
  font-weight: 600;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 1.5em;
  }
`;
const DesctiptionStyle = styled.div`
  font-size: 16px;
  margin: 20px;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 14px;
  }
`;

const OhMossImg = styled.img`
  margin-top: 70px;
  width: 120px;
  height: 120px;
`;

export default ForgotPassword;
