/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { UserDto, UserProfileDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class My<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 프로필 정보
   *
   * @tags my
   * @name GetUserProfile
   * @summary 프로필 정보
   * @request GET:/v2/me
   * @secure
   */
  getUserProfile = (params: RequestParams = {}) =>
    this.http.request<UserProfileDto, any>({
      path: `/v2/me`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 프로필 저장
   *
   * @tags my
   * @name SaveUserProfile
   * @summary 프로필 저장
   * @request POST:/v2/me
   * @secure
   */
  saveUserProfile = (data: UserProfileDto, params: RequestParams = {}) =>
    this.http.request<UserProfileDto, any>({
      path: `/v2/me`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 회원탈퇴
   *
   * @tags my
   * @name Withdrawal
   * @summary 회원탈퇴
   * @request POST:/v2/me/withdrawal
   * @secure
   */
  withdrawal = (params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/withdrawal`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 휴면해제
   *
   * @tags my
   * @name UnsuspendUser
   * @summary 휴면해제
   * @request POST:/v2/me/unsuspend
   * @secure
   */
  unsuspendUser = (params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/unsuspend`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 모든채널의 알림카운트
   *
   * @tags my
   * @name GetAllNotificationCounts
   * @summary 모든채널의 알림카운트
   * @request GET:/v2/me/notifications/count
   * @secure
   */
  getAllNotificationCounts = (params: RequestParams = {}) =>
    this.http.request<number, any>({
      path: `/v2/me/notifications/count`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 로그인 정보
   *
   * @tags my
   * @name GetUserLoginInfo
   * @summary 로그인 정보
   * @request GET:/v2/me/login-info
   * @secure
   */
  getUserLoginInfo = (params: RequestParams = {}) =>
    this.http.request<UserDto, any>({
      path: `/v2/me/login-info`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
