import React, { useEffect, useState } from 'react';
import { CustomFile } from 'types/CommonTypes';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const SingleFile = ({ onClick }: { onClick: (e: any) => void }) => {
  const [file, setFile] = useState<CustomFile>();

  return (
    <DefaultIconStyle
      onClick={(e: any) => {
        if (!file) onClick(e);
      }}
    >
      <CameraIconStyle>
        <AttachFileOutlinedIcon sx={{ width: 30, height: 30 }} />
      </CameraIconStyle>
    </DefaultIconStyle>
  );
};

const DefaultIconStyle = styled.div`
  width: 60px;
  height: 60px;
  background: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  :hover {
    background: #2ebe59;
  }
`;

const IconStyle = styled.div`
  width: 30px;
  height: 30px;
  background: #05c755;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  /* right:0px; */
  border-radius: 40px;
`;

const ImageStyle = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
  object-fit: cover;
  width: 60px;
  height: 60px;
  border-radius: 8px;
`;

const CameraIconStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SingleFile;
