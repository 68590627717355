import React from 'react';

import { MemberDto, PartnerDto } from 'services/data-contracts';
import { CSTownPartnerState } from 'types/CommonTypes';
import { useStateContext } from 'libs/hooks/usePageState';
import CSPartnerListItem from './CSPartnerListItem';

const CSPartnerList = ({
  onSelect,
  partners,
}: {
  onRefresh?: () => void;
  partners?: PartnerDto[];
  onSelect: (partner: MemberDto) => void;
}) => {
  const { setPageState } = useStateContext<CSTownPartnerState>();
  const handleClickPartner = (partner: PartnerDto) => {
    onSelect(partner);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {partners?.map((partner, index) => (
        <CSPartnerListItem
          partner={partner}
          onSelect={handleClickPartner}
          onDelete={(partner: PartnerDto) => {
            setPageState('selectedPartner', partner);
          }}
          key={index}
        />
      ))}
    </div>
  );
};

export default CSPartnerList;
