import React, { useRef, useEffect } from 'react';
import { OhmossButton } from 'resources/controls/OhmossButton';

import useNavigation from 'hooks/useNavigation';
import MyAvatarBox from 'components/commons/MyAvatarBox';
import { useRootContext } from 'libs/hooks/useRootContext';
import { Row } from 'components/commons/layouts';
import MenuItemBox from 'components/commons/menuItem/MenuItemBox';
import MenuItem from 'components/commons/menuItem/MenuItem';
import { Divider } from '@mui/material';
import ManagementInnerLayout from 'features/management/components/common/ManagementInnerLayout';
import { MenuContainer } from '../components/CommonStyleComponent';
import { useMediaContext } from 'providers/useMediaContext';

const MyPageMobileContainer = () => {
  const { myChannel } = useRootContext();
  const { navigate } = useNavigation();
  const instagramRef = useRef<string>();

  const handleClickMyLink = () => {
    navigate(`/${myChannel?.channelName}`);
  };

  const handleClickMyManagement = () => {
    navigate('/management');
  };

  const getChannelTypeDescription = () => {
    switch (myChannel?.channelType) {
      case 'SELLER':
        return myChannel?.membershipType === 'FREE'
          ? '일반 멤버'
          : `셀러 - ${myChannel?.membershipType}`;
      case 'BRAND':
        return `브랜드 - ${myChannel?.membershipType}`;
      default:
        return '매니저';
    }
  };

  useEffect(() => {
    instagramRef.current = undefined;
  }, []);

  return (
    <ManagementInnerLayout>
      <div style={{ padding: `4px 20px` }}>
        <div>
          <MyAvatarBox
            avatarUrl={myChannel?.profile?.profileImage?.publicUrl}
            channelName={myChannel?.channelName}
            channelType={getChannelTypeDescription()}
          />
        </div>
        <Row style={{ gap: 20 }}>
          <OhmossButton onClick={handleClickMyLink} type="primaryPc">
            내 링크방문
          </OhmossButton>
          <OhmossButton onClick={handleClickMyManagement} type="square">
            내 링크수정
          </OhmossButton>
        </Row>
        {/* <div style={{ paddingTop: 20 }}>{myChannel && <>{getMyPage()}</>}</div> */}
        <MenuItemBox style={{ color: 'rgba(0, 0, 0, 0.54)' }} title="My ohmoss">
          <MenuContainer>
            <MenuItem
              title="구독정보(BETA)"
              onClick={() => {
                navigate('/my/subscriptions');
              }}
              type="INFLUENCER"
              menuType="link"
            />
            <Divider />
            <MenuItem
              title="공구 문의 내역"
              onClick={() => {
                navigate('/my/deals/inquiry');
              }}
            />
            <Divider />
            <MenuItem
              title="제안 내역"
              onClick={() => {
                navigate('/my/business-proposal');
              }}
            />
            <Divider />
            <MenuItem
              title="문의 내역"
              onClick={() => {
                navigate('/my/proposal');
              }}
            />
            <Divider />
            {/* <MenuItemCheckBox title="알림 받기" /> */}
          </MenuContainer>
        </MenuItemBox>
      </div>
    </ManagementInnerLayout>
  );
};

export default MyPageMobileContainer;
