import React, { useState, useEffect, useRef, CSSProperties } from 'react';

import { initLinkItemDto, updateLink } from 'components/items/commonLogic';
import { defaultLinkEditorProps, LinkModel, SimpleLinkModel } from 'types/CommonTypes';
import { validationHelper as VH } from 'controls/validationHelper';
import { FormControlLabel, Switch } from '@mui/material';
import { TextToolBox } from 'components/commons';
import { Row } from 'components/commons/layouts';

import LinkEditorContainerStyle from 'resources/controls/LinkEditorConatainerStyle';
import { t } from 'i18next';
import SaveButtonWrapper from './SaveButtonWrapper';
import TitleForm from './forms/TitleForm';
import UrlForm from './forms/UrlForm';
import ThumbnailUploader from './ThumbnailUploader';

const SimpleLinkEditor = ({
  model,
  stores,
  onRegistComplete,
}: defaultLinkEditorProps<SimpleLinkModel>) => {
  const titleRef = useRef<HTMLInputElement>(null);
  const urlRef = useRef<HTMLInputElement>(null);

  const [linkModel, setLinkModel] = useState<SimpleLinkModel>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [useLink, setUselink] = useState<boolean>(true);
  const [reset, setReset] = useState<boolean>(false);

  /** Primitives */
  const handleClickSave = async () => {
    if (typeof linkModel !== 'undefined' && typeof onRegistComplete === 'function') {
      const saveModel = { ...linkModel };
      saveModel.items = [];
      saveModel.isActive = true;
      const result = (await updateLink(
        saveModel,
        typeof saveModel.id !== 'undefined',
        stores,
      )) as SimpleLinkModel;
      if (result !== null) {
        onRegistComplete(true, result);
      } else {
        setReset(true);
      }
    }
  };

  const handleChangeValue = (key: 'title' | 'linkUrl', value: string) => {
    setLinkModel((prev: SimpleLinkModel) => {
      return { ...prev, [key]: value };
    });
  };

  const checkValidation = () => {
    const result = VH.required(linkModel?.title);
    const urlResult =
      linkModel?.linkUrl && linkModel?.linkUrl?.length > 0 ? VH.url(linkModel?.linkUrl) : true;
    setIsValid(result && urlResult);
  };

  useEffect(() => {
    if (typeof linkModel !== 'undefined') {
      checkValidation();
    }
  }, [linkModel]);

  useEffect(() => {
    if (typeof model?.id === 'undefined') {
      setLinkModel(initLinkItemDto<{ useBlank: boolean; }>('SimpleLink', { useBlank: true }));
    } else {
      setLinkModel(model);
      const result = VH.required(model.title) && VH.url(model.linkUrl);
      setIsValid(result);
    }
  }, [model]);
  /** Primitives */

  return (
    <LinkEditorContainerStyle>
      <SaveButtonWrapper
        reset={reset}
        isValid={isValid}
        onSave={handleClickSave}
        isNew={typeof linkModel?.id === 'undefined'}
      >
        <>
          <ThumbnailUploader
            label={t('Select image')}
            url={linkModel?.thumbnail?.publicUrl}
            onImageChange={(image: any) => {
              setLinkModel((prev: LinkModel) => {
                return { ...prev, thumbnail: image };
              });
            }}
          />

          <div style={{ margin: '20px 0' }}>
            <TitleForm
              errorText={t('A148')}
              helperText={t('A149')}
              ref={titleRef}
              multiline
              model={linkModel?.title}
              onChangeValue={(value: string) => {
                handleChangeValue('title', value);
              }}
            />
          </div>
          <div style={{ margin: '0', marginBottom: 20 }}>
            <UrlForm
              disable={!useLink}
              helperText={t('A150')}
              ref={urlRef}
              model={linkModel?.linkUrl}
              onChangeValue={(value: string) => {
                handleChangeValue('linkUrl', value);
              }}
            />
          </div>
          <div>
            <Row style={{ justifyContent: 'flex-start' }}>
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={linkModel?.metadataObject?.useTransparent}
                    onChange={(event: never, value: boolean) => {
                      setLinkModel((prev: SimpleLinkModel) => {
                        return {
                          ...prev,
                          metadataObject: {
                            ...prev.metadataObject,
                            useTransparent: value,
                          },
                        };
                      });
                    }}
                  />
                }
                label={t('A151')}
              />
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={linkModel?.metadataObject?.useBlank}
                    onChange={(event: never, value: boolean) => {
                      setLinkModel((prev: SimpleLinkModel) => {
                        return {
                          ...prev,
                          metadataObject: {
                            ...prev.metadataObject,
                            useBlank: value,
                          },
                        };
                      });
                    }}
                  />
                }
                label={t('A152')}
              />
            </Row>
            {linkModel?.metadataObject?.useTransparent && (
              <TextToolBox
                show
                colorPickerLocation="top"
                showImageIcon
                style={linkModel.metadataObject?.style}
                containerStyle={toolBoxStyle}
                onChangeStyle={(style: CSSProperties) => {
                  setLinkModel((prev: SimpleLinkModel) => {
                    return {
                      ...prev,
                      metadataObject: {
                        ...prev.metadataObject,
                        style: { ...prev?.metadataObject?.style, ...style },
                      },
                    };
                  });
                }}
              />
            )}
          </div>
          {/* <SimplePreview model={linkModel} /> */}
        </>
      </SaveButtonWrapper>
    </LinkEditorContainerStyle>
  );
};

const toolBoxStyle = {
  borderRadius: 12,
  border: 'none',
};
export default SimpleLinkEditor;
