import React, { useState, useEffect, CSSProperties } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import { ColorPicker } from 'components/items';
import useSetState from 'libs/hooks/useSetState';

type CommonProps = {
  title?: string;
  initialValue?: string;
  onChange?: (value: string) => void;
};
const CommonColorSet = ({ title, initialValue, onChange }: CommonProps) => {
  const [style, setStyle] = useSetState<CSSProperties>();

  // useSetDefaultValue(setValue, initialValue as CSSProperties, '#000000');

  // useEffect(() => {
  //   if (initialValue) {
  //     if (typeof initialValue === 'string') {
  //       setStyle(JSON.parse(initialValue as string));
  //     } else {
  //       setStyle(initialValue);
  //     }
  //   }
  // }, [initialValue]);

  return (
    <>
      <ItemSectionStyle>
        <ItemSectionTitle>{title || '글자 색상'}</ItemSectionTitle>
        <ItemContent>
          <ColorPicker
            useGradient
            defaultValue={initialValue}
            value={style?.color as string}
            onColorChanged={(color: string) => {
              setStyle('color', color);
              if (onChange) {
                onChange(color);
              }
            }}
          >
            <></>
          </ColorPicker>
        </ItemContent>
      </ItemSectionStyle>
    </>
  );
};

export default CommonColorSet;
