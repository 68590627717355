import React, { ReactElement, useEffect, useRef } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useNavigation from 'hooks/useNavigation';
import { IconButton } from '@mui/material';
import StyledRefBox from './layouts/StyledRefBox';
import { Row } from './layouts';

const MobileModalHeader = observer(
  ({
    type = 'modal',
    title,
    gobackUrl,
    onCloseClick,
  }: {
    type?: 'modal' | 'page';
    title?: string | ReactElement;
    gobackUrl?: string;
    onCloseClick?: () => void;
  }) => {
    const boxRef = useRef<any>();
    const navigation = useNavigation();
    const { uiStore } = useStore();

    const handleClickModalClose = () => {
      if (onCloseClick) {
        onCloseClick();
      } else {
        if (uiStore.modal) {
          uiStore.modal.close();
        }
        if (uiStore.universalModal) {
          uiStore.universalModal.close();
        }
      }
    };

    useEffect(() => {
      const parent = document.querySelector('.model-content-style') as any;
      if (parent) {
        const parentSize = parent.getBoundingClientRect();
        if (boxRef.current) {
          boxRef.current.style.width = `${parentSize.width}px`;
        }
      }
    }, [type]);

    return (
      <HeaderContainerStyle className="modal-header">
        <div style={{}}>
          <Row
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              // height: 44,
              position: 'relative',
            }}
          >
            {type === 'modal' ? (
              <>
                {title && (
                  <div
                    style={{
                      fontSize: 17,
                      fontWeight: 700,
                      borderRadius: 10,
                      textAlign: 'center',
                      margin: 'auto',
                      paddingLeft: 20,
                    }}
                  >
                    {title}
                  </div>
                )}
                <LeftAlignStyle style={{ cursor: 'pointer' }}>
                  <IconButton
                    onClick={() => {
                      handleClickModalClose();
                    }}
                  >
                    <CloseIcon style={{ width: 30, height: 30 }} />
                  </IconButton>
                </LeftAlignStyle>
              </>
            ) : (
              <>
                <HeaderInnerStyle
                  ref={boxRef}
                  // className="global-width"
                  style={{
                    borderRadius: 0,
                    height: 95,
                    top: 0,
                    position: 'fixed',
                  }}
                >
                  <Row style={{ justifyContent: 'center', height: 95 }}>
                    <LeftAlignStyle style={{ cursor: 'pointer', position: 'absolute', left: 10 }}>
                      <IconButton
                        onClick={() => {
                          if (gobackUrl) {
                            navigation.navigate(gobackUrl);
                          } else {
                            navigation.pop();
                          }
                        }}
                      >
                        <ArrowBackIosIcon />
                      </IconButton>
                    </LeftAlignStyle>
                    <>{title && <div style={{ fontWeight: 700 }}>{title}</div>}</>
                  </Row>
                </HeaderInnerStyle>
              </>
            )}
          </Row>
        </div>
      </HeaderContainerStyle>
    );
  },
);

const HeaderContainerStyle = styled.div``;

const HeaderInnerStyle = styled(StyledRefBox)`
  /* height: 44px; */
  position: fixed;
  top: auto;
  background: #ffffff;
  margin: auto;
  /* border-radius: 10px; */
  align-items: center;
  z-index: 3;
  cursor: pointer;
`;

const LeftAlignStyle = styled.div`
  /* position: absolute; */
  /* left: 20px; */
  cursor: pointer;
`;
export default MobileModalHeader;
