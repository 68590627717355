import React, { createContext } from 'react';
import { deviceInfo } from 'types/CommonTypes';

const configurationContext = createContext<deviceInfo>({
  headerHeight: 0,
  topSpace: 0,
  bottomSpace: 0,
});

export default configurationContext;
