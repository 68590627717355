import React, { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import MobileModalHeader from 'components/commons/MobileModalHeader';
import useToken from 'libs/hooks/useToken';
import GnbComponent from 'features/commonFeatures/navigationBar/containers/GnbComponent';
import GnbHeader from 'features/commonFeatures/navigationBar/containers/GnbHeader';
import PublicMenu from 'features/commonFeatures/navigationBar/containers/PublicMenu';
import SignInMenu from 'features/commonFeatures/navigationBar/containers/SignInMenu';
import DetailPageHeader from 'components/commons/DetailPageHeader';
import { GnbComponentStyle } from '../components/Styles';
import RootGnbComponent from 'components/commons/RootGnbComponent';

const AboutUsPage = () => {
  const { validUser } = useToken();
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    scrollTo(0, 0);
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // console.log(window.innerWidth, isMobile);
  }, [isMobile]);

  useEffect(() => {
    const container = document.querySelector('.universal-layout') as any;
    if (container) {
      container.style.maxWidth = '100%';
      container.style.width = '100%';
    }
  }, []);

  return (
    <div style={{ maxWidth: isMobile ? 550 : 1250, width: '100%', margin: 'auto' }}>
      <RootGnbComponent position="bottom" />

      <Wrapper>
        <ImgContainer>
          <img src="images/landing/hello_moss.jpg" alt="hellomoss" />
        </ImgContainer>
        <TextContainer>
          <TitleText>What we do</TitleText>
          <img src="images/landing/aboutus_1.png" alt="aboutus1" />
          <ContentText>
            인플루언서분석에 강한 마케팅 회사. 현재 인플루언서 서비스앱인 오모스를 운영 를 운영하고
            있습니다. 인플루언서 전용 링크를 유연한 발상으로 쉽고 간편하게 만들어 공동구매와 광고,
            일정관리, 그리고 고객관리에 대한 프로페셔널하고 철저한 운영을 고집하고 있습니다.
            <br />
            <br />
            고객의 피드백을 최우선으로 생각하고, 브랜드와 소통할 수 있는 오모스앱을 엔비에이치디
            노하우를 총동원해 지원하고 있습니다.
            <br />
            <br />
            요즘은 유튜브등을 통해 인플루언서 정보를 얻고 있지만, 과연 내가 하고 있는 루틴이 올바른
            것인가? 더 이상 성과를 내는 방법은 없나?등의 의문에 대해 부담없이 상담할 수 있는 창구가
            되어 인플루언서의 성공을 지원합니다.
          </ContentText>
          <Divider />
        </TextContainer>
        <TextContainer>
          <TitleText>What we do</TitleText>
          <img src="images/landing/aboutus_2.png" alt="aboutus2" />
          <ContentText>
            이끼는 우리 눈에 잘 띄지 않지만, 우리가 마시는 산소의 30%를 생산하고 있습니다. 우리는
            인플루언서가 소셜생태계에서 이끼와 같은 존재라는 것을 알고 있습니다. 그들이 만들어 내는
            컨텐츠가 소셜라이프의 30%이상의 영향력을 주고 있고, 재미와 유용함을 매일 제공하고
            있습니다. 인플루언서가 소셜에 대한 지식을 쌓고 경험이 생기면, 남에게 의지 않고 다양한
            도전을 할 수 있다고 생각합니다. <br />
            <br />
            오모스는 인플루언서 스스로 계속 성장할 수 있는 힘을 가질 수 있게 서포트할 수 있는 도구를
            목표로 하고 있습니다.
          </ContentText>
          <Divider />
        </TextContainer>
        <TextContainer>
          <TitleText>How we do</TitleText>
          <img src="images/landing/aboutus_3.png" alt="aboutus3" />
          <JoinUsText style={{ fontSize: '1rem' }}>
            Greeting! You are now in Moss Town.
            <br /> Welcome to the Moss family!
          </JoinUsText>
          <ContentText style={{ lineHeight: 2 }}>
            1. 회사와 함께 성장한다. <br />
            2. 자신의 삶의 주인이 되어라. <br />
            3. 원하는 결과를 잊지 마라. <br />
            4. 계획, 실행, 피드백 하라 <br />
            5. 중요한 것에 집중하라.
            <br /> 6. 도전하고 도전하라. <br />
            7.이왕이면 즐겁게 일하라.
          </ContentText>
          <ContentText>
            일하는 시간 아침 9시 ~ 6시(출퇴근 시간은 조정가능, 점심시간 자율), 원거리에서도 근무
            가능 (현재 핀란드에서 리모트로 일하고 있는 멤버 있음)
          </ContentText>
          <Divider />
        </TextContainer>
        <TextContainer style={{ alignItems: 'center' }}>
          <JoinUsTitleText>JOIN US!</JoinUsTitleText>
          <img
            src="images/landing/friendly_moss.svg"
            alt="friendlymoss"
            style={{ width: '280px', alignSelf: 'center', zIndex: 1 }}
          />
          <JoinUsText>Let`s build a cool town together!</JoinUsText>
          <Divider />
        </TextContainer>
        <BottomContainer>
          <CompanyLocationContainer>
            <img
              src="images/landing/ohmoss_small_logo.svg"
              alt="logo"
              style={{ marginLeft: '-20px' }}
            />
            <CompanyName>
              주식회사 <strong>엔비에이치디</strong>
            </CompanyName>
            <CompanyLocation>
              49 17 Achasanro SEOUL KOREA
              <br />
              서울 성동구 아차산로17길 49
              <img
                src="/images/landing/location_2.svg"
                alt="location_2"
                style={{ marginLeft: '10px' }}
              />
            </CompanyLocation>
          </CompanyLocationContainer>
          <img
            src="images/landing/friendly_moss.svg"
            alt="friendly_moss"
            style={{ width: '25%' }}
          />
        </BottomContainer>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${(props) => props.theme.media.mobile} {
    height: 100%;
  }
  background-color: #fff;
`;

const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 60px;
  > img {
    width: auto;
  }
`;

const TextContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

const TitleText = styled.div`
  font-size: 22px;
  font-weight: bold;
  padding: 20px;
`;

const JoinUsTitleText = styled.div`
  font-size: 2rem;
  padding: 20px;
  font-weight: 400;
  color: #3ab54a;
`;

const ContentText = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #797979;
  padding: 20px;
`;

const Divider = styled.div`
  width: 100%;
  background-color: #d8d8d8;
  height: 1px;
  margin: 10px 20px 0 0;
`;

const JoinUsText = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: 600;
  align-self: center;
  z-index: 2;
  margin-bottom: 20px;
  text-align: center;
`;
const CompanyName = styled.div`
  font-weight: 400;
  font-size: 15px;
  margin-top: 10px;
`;
const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 20px 0 10px;
  padding-bottom: 30px;
`;
const CompanyLocationContainer = styled.div`
  width: 280px;
  flex-direction: column;
  margin: 10px 20px 0 20px;
`;

const CompanyLocation = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin-top: 10px;
  color: #717171;
`;
export default AboutUsPage;
