import React, { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import MUISwitch from 'components/commons/buttons/MUISwitch';
import { IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteButton from 'resources/controls/DeleteButton';
import { Row } from 'components/commons/layouts';
import { ChannelBoardModel } from 'types/CommonTypes';
import { useStore } from 'stores/StoreHelper';
import Text from 'components/commons/Text';
import InfoBox from 'components/commons/InfoBox';
import { OhmossButton } from 'resources/controls/OhmossButton';

type Props = {
  icon?: string;
  initialActive?: boolean;
  confirmMessage?: string; //Active 용
  useConfirm?: boolean;
  item?: ChannelBoardModel;
  onModify: () => void;
  onDelete: () => void;
  onActiveChange: (isActive: boolean) => void;
};

const ListItemHeader = ({
  icon,
  item,
  initialActive,
  onModify,
  onDelete,
  onActiveChange,
  useConfirm = false,
  confirmMessage,
}: Props) => {
  const { uiStore } = useStore();
  const [isActive, setIsActive] = useState<boolean>();

  const handleChangeActive = (isActive: boolean) => {
    onActiveChange?.(isActive);
  };

  const handleClickInfo = () => {
    uiStore.universalModal.show({
      modalOption: { style: { width: 400, height: 430, overflow: 'hidden' } },
      title: 'Information',
      children: <Info />,
    });
  };

  const handleClickDelete = () => {
    onDelete?.();
  };

  const Info = () => {
    return (
      <div style={{ padding: 20 }}>
        <div>
          <img src="/images/confirm.png" style={{ width: 100 }} />
        </div>
        <InfoBox>
          <Text>
            종료된 공구는, 고객지원(CS)를 위해 공동구매 기간이 종료된 블럭만 따로 모아놓은 페이지 로
            삭제나 수정이 어렵습니다.
            <br />
            <br />
            다만, 숨김처리는 가능합니다.
          </Text>
        </InfoBox>
        <div style={{ padding: '20px 0' }}>
          <OhmossButton
            type="primaryPc"
            onClick={() => {
              uiStore.universalModal.close();
            }}
          >
            확인
          </OhmossButton>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (typeof initialActive !== 'undefined') {
      setIsActive(initialActive);
    }
  }, [initialActive]);

  return (
    <Row style={{ width: 'unset', justifyContent: 'space-between' }}>
      <div style={{ marginLeft: 30 }}>
        <img src={icon} alt="" />
      </div>
      <Row style={{ width: 130 }}>
        <IconButton onClick={onModify} disabled={item?.isDeal}>
          <EditIcon />
        </IconButton>
        <div style={{ marginRight: 7 }}>
          <>
            {item?.isDeal ? (
              <IconButton onClick={handleClickInfo}>
                <InfoOutlinedIcon />
              </IconButton>
            ) : (
              <DeleteButton onClick={handleClickDelete} />
            )}
          </>
        </div>
        <MUISwitch
          useConfirm={useConfirm}
          message={confirmMessage}
          // defaultChecked={item?.isActive}
          checked={isActive}
          onChange={(e: any, value: boolean) => {
            handleChangeActive(value);
            setIsActive(value);
          }}
        />
      </Row>
    </Row>
  );
};

export default ListItemHeader;
