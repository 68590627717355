import styled from 'styled-components';

export const BackgroundLayerStyle = styled.div<{ zIndex?: number }>`
  z-index: ${(props) => props.zIndex ?? -1};
  width: 550px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* background-color: #ffffff; */
  margin: auto;
  pointer-events: none;
  /* z-index: -1; */
`;
