import styled from 'styled-components';

/**
 * @description 링크 개별 컨테이너 스타일
 */

const LinkContainerLayout = styled.div<{ on: boolean }>`
  text-align: center;
  word-wrap: break-word;
  min-height: 40px;
  display: flex;
  position: relative;
  /* background-color: #fff; */
  margin: 10px 0 5px 0;
  width: 100%;

  @media ${(props) => props.theme.media.mobile} {
    min-height: 40px;
    /* height: 30px; */
    font-size: 16px;
    /* margin: 0 0 10px 0; */
    /* width: calc(100% - 50px); */
  }
`;

export default LinkContainerLayout;
