import React, { useState, useEffect, useRef, CSSProperties } from 'react';

import { initLinkItemDto, updateLink } from 'components/items/commonLogic';
import { defaultLinkEditorProps, LinkModel, SimpleLinkModel } from 'types/CommonTypes';
import { validationHelper as VH } from 'controls/validationHelper';
import { TextToolBox } from 'components/commons';
import { t } from 'i18next';

import LinkEditorContainerStyle from 'resources/controls/LinkEditorConatainerStyle';
import SaveButtonWrapper from './SaveButtonWrapper';
import TitleForm from './forms/TitleForm';
import ThumbnailUploader from './ThumbnailUploader';

const SuggestLinkEditor = ({
  model,
  stores,
  onRegistComplete,
}: defaultLinkEditorProps<SimpleLinkModel>) => {
  const titleRef = useRef<HTMLInputElement>(null);

  const [linkModel, setLinkModel] = useState<SimpleLinkModel>();
  const [isValid, setIsValid] = useState<boolean>(false);

  /** Primitives */
  const handleClickSave = async () => {
    if (typeof linkModel !== 'undefined' && typeof onRegistComplete === 'function') {
      const saveModel = { ...linkModel };
      saveModel.items = [];
      saveModel.isActive = true;
      const result = (await updateLink(
        saveModel,
        typeof saveModel.id !== 'undefined',
        stores,
      )) as SimpleLinkModel;
      onRegistComplete(true, result);
    }
  };

  const handleChangeValue = (key: 'title' | 'linkUrl', value: string) => {
    setLinkModel((prev: SimpleLinkModel) => {
      return { ...prev, [key]: value };
    });
  };

  const checkValidation = () => {
    const result = VH.required(linkModel?.title);
    const urlResult =
      linkModel?.linkUrl && linkModel?.linkUrl?.length > 0 ? VH.url(linkModel?.linkUrl) : true;
    setIsValid(result && urlResult);
  };

  useEffect(() => {
    if (typeof linkModel !== 'undefined') {
      checkValidation();
    }
  }, [linkModel]);

  useEffect(() => {
    if (typeof model?.id === 'undefined') {
      setLinkModel(initLinkItemDto<{ useBlank: boolean }>('Suggestion', { useBlank: true }));
    } else {
      setLinkModel(model);
      const result = VH.required(model.title) && VH.url(model.linkUrl);
      setIsValid(result);
    }
  }, [model]);
  /** Primitives */

  return (
    <LinkEditorContainerStyle>
      <SaveButtonWrapper
        isValid={isValid}
        onSave={handleClickSave}
        isNew={typeof linkModel?.id === 'undefined'}
      >
        <>
          <ThumbnailUploader
            label={t('Select image')}
            url={linkModel?.thumbnail?.publicUrl}
            onImageChange={(image: any) => {
              setLinkModel((prev: LinkModel) => {
                return { ...prev, thumbnail: image };
              });
            }}
          />

          <div style={{ margin: '20px 0' }}>
            <TitleForm
              errorText={t('A148')}
              helperText={t('A149')}
              ref={titleRef}
              multiline
              model={linkModel?.title}
              onChangeValue={(value: string) => {
                handleChangeValue('title', value);
              }}
            />
          </div>

          <div>
            {linkModel?.metadataObject?.useTransparent && (
              <TextToolBox
                show
                colorPickerLocation="top"
                showImageIcon
                style={linkModel.metadataObject?.style}
                containerStyle={toolBoxStyle}
                onChangeStyle={(style: CSSProperties) => {
                  setLinkModel((prev: SimpleLinkModel) => {
                    return {
                      ...prev,
                      metadataObject: {
                        ...prev.metadataObject,
                        style: { ...prev?.metadataObject?.style, ...style },
                      },
                    };
                  });
                }}
              />
            )}
          </div>
          {/* <SimplePreview model={linkModel} /> */}
        </>
      </SaveButtonWrapper>
    </LinkEditorContainerStyle>
  );
};

const toolBoxStyle = {
  borderRadius: 12,
  border: 'none',
};

export default SuggestLinkEditor;
