import React, { useEffect, useState } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import TitleForm from 'components/items/forms/TitleForm';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { validationHelper } from 'controls/validationHelper';
import { LinkTypes } from 'features/management/context/providers/ContextTypes';
import { useOhmossContext } from 'features/management/context/useOhmossContext';

import useInitializeModel from 'libs/hooks/useInitializeModel';
import BlockController from 'controllers/BlockController';
import { ChannelBoardDto } from 'services/data-contracts';
import CategoryController from 'controllers/CategoryController';
import styled from 'styled-components';
import Radio, { RadioProps } from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { createTheme, withStyles } from '@material-ui/core/styles';
import { IShopBlock } from 'types/BlockExtendsTypes';
import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import useNavigation from 'hooks/useNavigation';
import MyShopItem from '../common/MyShopItem';
import { useRootContext } from 'libs/hooks/useRootContext';
import ActionButton from './ActionButton';

const theme = createTheme({
  palette: {
    primary: {
      main: '#23BA50', // Change this to your desired color
    },
  },
});

const ThemedRadio = withStyles({
  root: {
    color: '#efefef',
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const MyShop = ({ blockModel }: { blockModel?: IShopBlock }) => {
  const { navigate } = useNavigation();
  const { uiStore } = useStore();
  const { myChannel } = useRootContext();

  const defaultProps: Partial<IShopBlock> = {
    linkType: 'Shop',
    showTitle: false,
  };

  const { initialModel, updateServiceModel, unmountModel, serviceModel, initialBinding } =
    useOhmossContext<LinkTypes<IShopBlock>>();

  const { updateBlock } = BlockController<IShopBlock>();
  const { getMyCategoryBoards } = CategoryController();

  const [localModel, setLocalModel] = useInitializeModel<Partial<IShopBlock>>(
    blockModel,
    defaultProps,
    initialBinding,
  );
  const [isValid, setIsValid] = useState<boolean>(false);
  const [categories, setCategories] = useState<ChannelBoardDto[]>();
  const [selectedCategory, setSelectedCategory] = useState<number>();

  const fetchData = async () => {
    const response = await getMyCategoryBoards();
    setCategories(response.filter((x) => x.boardType === 'SHOP'));
  };
  useEffect(() => {
    if (localModel) {
      const titleValid = validationHelper.required(localModel?.title);
      setIsValid(titleValid);
    }
  }, [localModel]);

  useEffect(() => {
    return () => {
      unmountModel();
    };
  }, []);

  useEffect(() => {
    if (myChannel) {
      fetchData();
    }
  }, [myChannel]);

  useEffect(() => {
    if (initialModel?.shopBoardId) {
      setSelectedCategory(initialModel?.shopBoardId);
    }
  }, [initialModel]);

  return (
    <div style={{}}>
      <div style={{ marginBottom: 20 }} />
      <ItemSectionStyle>
        <ItemSectionTitle>타이틀</ItemSectionTitle>
        <ItemContent>
          <TitleForm
            onChangeValue={(value: string) => {
              updateServiceModel('title', value);
              setLocalModel('title', value);
            }}
            initialValue={initialModel?.title}
            placeHolder="타이틀을 작성해 주세요"
            label="타이틀"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemContent>
          <MenuItemCheckBox
            initialValue={initialModel?.showTitle}
            onChangeValue={(value: boolean) => {
              updateServiceModel('showTitle', value);
            }}
            title="타이틀표시"
          />
        </ItemContent>
      </ItemSectionStyle>
      <MyShopListContainer>
        {categories?.length === 0 && (
          <NoContent>
            등록된 마이샵이 없습니다.
            <OhmossButton onClick={() => navigate('/management/shop')} style={{ width: '60%' }}>
              마이샵 만들러가기
            </OhmossButton>
          </NoContent>
        )}
        {categories?.map((category: ChannelBoardDto, index: number) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            key={index}
            onClick={() => {
              setSelectedCategory(category.id);
              updateServiceModel('shopBoardId', category.id);
            }}
          >
            <FormControl>
              <RadioGroup aria-labelledby="demo-customized-radios" name="customized-radios">
                <FormControlLabel
                  checked={selectedCategory === category.id}
                  value={index}
                  control={<ThemedRadio />}
                  label=""
                  // onChange={() => {
                  //   setSelectedCategory(category.id);
                  //   updateServiceModel('shopBoardId', category.id);
                  // }}
                />
              </RadioGroup>
            </FormControl>
            <MyShopItem
              fetchData={() => {
                fetchData();
              }}
              editMode={false}
              item={category}
              onClickMore={() => {}}
            />
          </div>
        ))}
      </MyShopListContainer>
      {/* {hasCategory === false && <EmptyPage content="아직 마이샵이 없습니다." />} */}
      <ActionButton isValid={isValid} serviceModel={serviceModel} />
      {/* <Row style={{ gap: 10 }}>
        <OhmossButton
          type="box"
          style={{ color: '#000' }}
          onClick={() => {
            uiStore.modal.close();
          }}
        >
          취소
        </OhmossButton>
        <OhmossButton
          onClick={() => {
            updateBlock(serviceModel);
          }}
          disabled={!isValid}
        >
          적용
        </OhmossButton>
      </Row> */}
    </div>
  );
};

const MyShopListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const NoContent = styled.div`
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;

  gap: 10px;
`;

export default MyShop;
