import React from 'react';
import { SimpleDealDto } from 'services/data-contracts';
import { LinkDtoWithAssignedDeal, MyDealState } from 'types/CommonTypes';
import { ListBoxWrapper } from 'features/csTown/commonComponents/styles';
import AddIcon from '@mui/icons-material/Add';

import AssignedDealsContainer from 'features/csTown/components/AssignedDealsList';
import { IconButton } from '@mui/material';
import styled from 'styled-components';
import { useStateContext } from 'libs/hooks/usePageState';
import { usePageModelContext } from 'features/management/context/usePageModelContext';
import { IDealBlock } from 'types/BlockExtendsTypes';

const CSTownDealCategoryView = ({ deals }: { deals?: SimpleDealDto[] }) => {
  const { setPageState, stateModel, setMultipleState, setSubMenus } =
    useStateContext<MyDealState>();
  const { unmountModel, initialBinding } = usePageModelContext<IDealBlock>();

  return (
    <div style={{ width: '100%' }}>
      <ListBoxWrapper>
        <AssignedDealsContainer dealList={deals as LinkDtoWithAssignedDeal[]} />
      </ListBoxWrapper>
      <NewButtonStyle
        onClick={() => {
          setMultipleState({ selectedDeal: undefined, pageMode: 'EDIT' });
          unmountModel();
          // setDealLink(undefined);
          initialBinding(undefined);
        }}
      >
        <AddIcon />
        <div>공구등록</div>
      </NewButtonStyle>
    </div>
  );
};

const NewButtonStyle = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  bottom: 20px;
  right: 20px;
  background: var(--primary-color);
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
`;

export default CSTownDealCategoryView;
