import React from 'react';
import styled from 'styled-components';
const Required = () => {
  return (
    <>
      <RqeuiredStyle>필수</RqeuiredStyle>
    </>
  );
};

const RqeuiredStyle = styled.div`
  font-size: 10px;
  background: rebeccapurple;
  color: #fff;
  height: 20px;
  padding: 0px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-left: 10px;
`;
export default Required;
