import React, { CSSProperties } from 'react';

import styled from 'styled-components';
import { SimpleDealDto } from 'services/data-contracts';
import CommonThumbnail from 'features/management/components/commonControl/CommonThumbnail';
import { Row } from 'components/commons/layouts';
import { BoxStyle } from '../commonComponents/styles';

const DealBox = ({
  deal,
  style,
  selected,
}: {
  deal?: SimpleDealDto;
  style?: CSSProperties;
  selected?: boolean;
}) => {
  return (
    <BoxStyle style={{ ...style }} selected={selected}>
      <Row style={{ justifyContent: 'flex-start' }}>
        <div style={{ width: 50, height: 50 }}>
          <CommonThumbnail
            useAlternative
            alternativeType="image"
            initialValue={deal?.thumbnail?.publicUrl}
          />
        </div>
        <div style={{ marginLeft: 10, fontSize: 15 }}>{deal?.title}</div>
      </Row>
    </BoxStyle>
  );
};

export default DealBox;
