import React, { useState, ReactNode, useEffect } from 'react';
import { DashboardContext, displayTypes } from './DashboardContext';
import { AdCategoryDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';

interface PageProviderProps {
    children: ReactNode;
}

const DashboardProvider = ({ children }: PageProviderProps) => {
    const { adStore } = useStore();
    const [selectedCategory, setSelectedCategory] = useState<AdCategoryDto | undefined>();
    const [displayType, setSelectedDisplayType] = useState<displayTypes>('list');

    const setCategory = (category: AdCategoryDto | undefined) => {
        setSelectedCategory(category);
        getAds({ category: category?.name });
        if (typeof category === 'undefined') {
            // hashTagStore.setTags([]);
        }
    };

    const setDisplayType = (type: displayTypes) => {
        setSelectedDisplayType(type);
    };

    const getAds = (query: any) => {
        adStore.getAds({ ...query, isOngoing: true });
    };

    return (<DashboardContext.Provider value={{
        selectedCategory,
        displayType,
        setCategory,
        setDisplayType,
        getAds
    }}>
        {children}
    </DashboardContext.Provider>);
};

export default DashboardProvider;
