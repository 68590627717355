/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from 'react';
import { SNSLinkItemModel } from 'types/CommonTypes';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { OriginSNS } from 'resources/images/sns';
import _ from 'lodash';

import { ISocialBlock } from 'types/BlockExtendsTypes';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import SNSIconWapper from 'components/items/SNSIconWrapper';
import { Row } from 'components/commons/layouts';
import MUIRadio from 'components/commons/buttons/MUIRadio';
import styled from 'styled-components';
import { useStore } from 'stores/StoreHelper';
import { LinkTypes } from 'features/management/context/providers/ContextTypes';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import BlockController from 'controllers/BlockController';

/**
 * 프리뷰 만들어야 함.
 */
const SNSSelector = () => {
  const { uiStore } = useStore();

  const defaultSNS: SNSLinkItemModel[] = [
    { title: 'facebook' },
    { title: 'instagram' },
    { title: 'youtube' },
  ];

  const [items, setItems] = useState<SNSLinkItemModel[]>();
  const [selectedItem, setSelectedItem] = useState<string>('');

  const handleClickSelect = () => {
    uiStore.bottomSheet.confirm({ title: selectedItem });
  };

  return (
    <>
      <Row style={{ padding: 30, gap: 20, flexDirection: 'column' }}>
        <FormControl sx={{ '&.MuiFormControl-root': { width: '100%' } }}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={selectedItem}
            name="radio-buttons-group"
            onChange={(e: any, value: string) => {
              setSelectedItem(value);
            }}
          >
            {OriginSNS?.map((sns) => (
              <LayoutStyle
                onClick={() => {
                  setSelectedItem(sns.name);
                }}
              >
                <Row style={{ width: 'auto' }}>
                  <SNSIconWapper useOrigin name={sns.name} />
                  <div style={{ fontSize: 16, fontWeight: 700, marginLeft: 20 }}>{sns.name}</div>
                </Row>
                <FormControlLabel
                  sx={{ '&.MuiFormControlLabel-root': { marginLeft: 0, marginRight: 0 } }}
                  value={sns.name}
                  control={<MUIRadio />}
                  label=""
                />
              </LayoutStyle>
            ))}
            <LayoutStyle
              onClick={() => {
                setSelectedItem('custom');
              }}
            >
              <Row style={{ width: 'auto' }}>
                <IconStyle>
                  <CameraAltIcon style={{ width: 45, height: 45 }} />
                </IconStyle>

                <div style={{ fontSize: 16, fontWeight: 700, marginLeft: 20 }}>이미지사용</div>
              </Row>
              <FormControlLabel
                sx={{ '&.MuiFormControlLabel-root': { marginLeft: 0, marginRight: 0 } }}
                value={'custom'}
                control={<MUIRadio />}
                label=""
              />
            </LayoutStyle>
          </RadioGroup>
        </FormControl>
      </Row>
      <div style={{ margin: 20 }}>
        <OhmossButton type="box" style={{ color: '#000' }} onClick={handleClickSelect}>
          선택
        </OhmossButton>
      </div>
    </>
  );
};

const LayoutStyle = styled.div`
  border: 1px solid #bebebe;
  padding: 20px;
  border-radius: 8px;
  display: flex;

  justify-content: space-between;
`;

const IconStyle = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SNSSelector;
