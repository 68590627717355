import React from 'react';
import ManagementLayout from 'features/management/components/common/ManagementLayout';

import DealInquiryList from 'features/csTown/components/DealInquiryList';

const CSTownDealInquiriesPage = () => {
  return (
    <ManagementLayout style={{ justifyContent: 'flex-start' }}>
      {/* <DealInquiryList onJoinChannel={handleJoinChannel} /> */}
    </ManagementLayout>
  );
};

export default CSTownDealInquiriesPage;
