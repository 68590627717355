import React from 'react';
import {
  ItemSectionStyle,
  ItemSectionTitle,
  ItemSectionDescription,
} from 'resources/controls/ItemSectionStyle';
import { ChannelConfigModel } from 'types/CommonTypes';
import { t } from 'i18next';
import { FormControlLabel, Switch } from '@mui/material';

import TitleForm from 'components/items/forms/TitleForm';

const SettingItemChannelTalk = ({
  model,
}: {
  model: [
    ChannelConfigModel | undefined,
    React.Dispatch<React.SetStateAction<ChannelConfigModel | undefined>>,
  ];
}) => {
  const patchModel = model[0];
  const dispatch = model[1];

  const handleInputChannelTalk = (value: string) => {
    dispatch((prev: ChannelConfigModel) => {
      return {
        ...prev,
        metadataObject: {
          ...prev.metadataObject,
          channelTalk: { ...prev.metadataObject?.channelTalk, id: value },
        },
      };
    });
  };

  return (
    <ItemSectionStyle style={{ padding: 20 }}>
      <ItemSectionTitle>{t('A208')}</ItemSectionTitle>
      <ItemSectionDescription>{t('A209')}</ItemSectionDescription>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={patchModel?.metadataObject?.channelTalk?.use}
            onChange={(event: never, value: boolean) => {
              dispatch((prev: ChannelConfigModel) => {
                return {
                  ...prev,
                  metadataObject: {
                    ...prev.metadataObject,
                    channelTalk: { ...prev.metadataObject?.channelTalk, use: value },
                  },
                };
              });
            }}
          />
        }
        label={t('A211')}
      />
      <div style={{ marginTop: 10 }}>
        <TitleForm
          model={patchModel?.metadataObject?.channelTalk?.id}
          label={t('A210')}
          onChangeValue={handleInputChannelTalk}
        />
      </div>
    </ItemSectionStyle>
  );
};

export default SettingItemChannelTalk;
