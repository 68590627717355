import { makeObservable, flow } from 'mobx';
import { OpenGraphService } from 'services/OpenGraphService';
import _ from 'lodash';
import { HttpClient } from 'services/http-client';
import { OpenGraphType } from 'types/CommonTypes';
import ServiceStoreBase from './ServiceStoreBase';

export interface isValid {
  isValid: boolean;
}

class OpenGraphStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      getSiteInfo: flow,
    });
  }

  api: OpenGraphService<unknown> = new OpenGraphService(new HttpClient());

  // eslint-disable-next-line class-methods-use-this
  isValidUrl(url: string) {
    try {
      // eslint-disable-next-line no-new
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  }

  *getSiteInfo(url: string) {
    const { response } = yield this.api.getOpenGraphData({ url });
    if (response.status === 200) {
      if (response.data.faviconUrl) {
        const urlCheck = this.isValidUrl(response.data.faviconUrl);

        if (!urlCheck) {
          const urlTemp = new URL(url);
          response.data.faviconUrl = urlTemp.origin + response.data.faviconUrl;
        }
        return response.data as OpenGraphType;
      }
    } else {
      return null;
    }
  }
}

export default OpenGraphStore;
