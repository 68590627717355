import React, { useEffect, useRef } from 'react';
import { useStore } from 'stores/StoreHelper';
import styled from 'styled-components';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { ItemSectionStyle } from 'resources/controls/ItemSectionStyle';
import MyChannelList from 'features/commonFeatures/containers/MyChannelList';
import { Row } from 'components/commons/layouts';
import { useTranslation } from 'react-i18next';
import useNavigation from 'hooks/useNavigation';
import Resign from 'components/commons/Resign';
import { isMobile } from 'react-device-detect';
import useLogout from 'hooks/useLogout';
import useToken from 'libs/hooks/useToken';

const VerifyInstagramContainer = () => {
  const { uiStore, myStore } = useStore();
  const { getUserInfo } = useToken();

  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const logout = useLogout();

  const instagramRef = useRef<string>();

  const handleClickResign = async () => {
    uiStore.universalModal.show({
      title: '이용해 주셔서 감사합니다.',
      description: '정상적으로 탈퇴가 완료되었습니다.',
      modalOption: { style: { width: 600, height: 400 } },
      bottomSheetOptions: { style: { padding: 20 } },
      children: (
        <Row style={{ flexDirection: 'column' }}>
          <div>확인을 누르시면 홈으로 이동합니다.</div>
          <div style={{ marginTop: 40 }}>
            <OhmossButton
              style={{ padding: '10px 30px' }}
              onClick={() => {
                navigate('/');
                uiStore.universalModal.close();
              }}
            >
              확인
            </OhmossButton>
          </div>
        </Row>
      ),
    });
    // authStore.logout();
  };

  const handleClickDelete = () => {
    uiStore.universalModal.show({
      title: `계정탈퇴 안내`,
      description: '오모스를 떠나신다니 아쉽네요 :( ',
      modalOption: {
        style: { maxWidth: 600, height: 450 },
      },
      bottomSheetOptions: { style: { padding: 20 } },
      children: (
        <Row
          style={isMobile ? { flexDirection: 'column', fontSize: 11 } : { flexDirection: 'column' }}
        >
          <div>
            <strong>다음의 내용을 확인 하신 후, 탈퇴 버튼을 누르시면 탈퇴가 완료됩니다.</strong>
          </div>
          <Resign />
          <Row style={{ margin: '40px 0', padding: 10, gap: '5px' }}>
            <OhmossButton
              style={{ padding: '10px 30px' }}
              type="danger"
              onClick={handleClickResign}
            >
              탈퇴확인
            </OhmossButton>
            <OhmossButton
              style={{ padding: '10px 30px' }}
              type="simple"
              onClick={() => {
                uiStore.universalModal.close();
              }}
            >
              취소
            </OhmossButton>
          </Row>
        </Row>
      ),
    });
  };

  useEffect(() => {
    instagramRef.current = undefined;
  }, []);

  return (
    <VerifyInstagramContainerWrapper>
      <AccountContainer>
        <ItemSectionStyle>{t('User information')}</ItemSectionStyle>
        <ItemSectionStyle style={{ padding: 10 }}>
          <div>{getUserInfo()}</div>
          <div>{myStore.loginInfo?.loginType}</div>
          {/* {getChannelType() === '일반회원' && (
            <OhmossButton
              onClick={handleClickVerify}
              style={{ border: '0.5px #6e6e6e solid', marginTop: 10 }}
              type="link"
            >
              인스타그램 계정 인증하기
            </OhmossButton>
          )} */}
        </ItemSectionStyle>

        <ItemSectionStyle>{t('My channels')}</ItemSectionStyle>
        <ItemSectionStyle style={{ padding: 10 }}>
          <MyChannelList type="channel" />
        </ItemSectionStyle>
        <ItemSectionStyle>{t('Accounts')}</ItemSectionStyle>
        <ItemSectionStyle style={{ padding: 10 }}>
          <Row style={{ justifyContent: 'flex-start' }}>
            <OhmossButton style={{ padding: '10px 30px' }} onClick={logout}>
              {t('Logout')}
            </OhmossButton>
            <OhmossButton
              style={{ padding: '10px 30px', marginLeft: 10 }}
              onClick={() => {
                navigate('/forgot-password');
              }}
            >
              {t('Change password')}
            </OhmossButton>
          </Row>
        </ItemSectionStyle>

        <ItemSectionStyle> {t('Withdrawal')}</ItemSectionStyle>
        <ItemSectionStyle style={{ padding: 10 }}>
          <OhmossButton type="danger" style={{ padding: '10px 30px' }} onClick={handleClickDelete}>
            {t('Delete account')}
          </OhmossButton>
        </ItemSectionStyle>
      </AccountContainer>
    </VerifyInstagramContainerWrapper>
  );
};

const VerifyInstagramContainerWrapper = styled.div`
  padding: 20px;
  flex-direction: column;
`;

const AccountContainer = styled.div`
  margin: 0 auto;
  max-width: 680px;
  @media ${(props) => props.theme.media.mobile} {
    /* padding-top: 60px; */
    max-width: 500px;
    min-width: 300px;
    padding-left: 1em;
    padding-right: 1em;
  }
`;
export default VerifyInstagramContainer;
