/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AdDto, AdReportDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class AdService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 광고 신고하기
   *
   * @tags AdService
   * @name ReportAd
   * @summary 광고 신고하기
   * @request POST:/v2/channels/{channelId}/ads/{adId}/report
   * @secure
   */
  reportAd = (channelId: number, adId: number, data: AdReportDto, params: RequestParams = {}) =>
    this.http.request<AdDto, any>({
      path: `/v2/channels/${channelId}/ads/${adId}/report`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 광고 즐겨찾기
   *
   * @tags AdService
   * @name AddInterest
   * @summary 광고 즐겨찾기
   * @request POST:/v2/channels/{channelId}/ads/{adId}/interest
   * @secure
   */
  addInterest = (channelId: number, adId: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/channels/${channelId}/ads/${adId}/interest`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 광고 즐겨찾기 해제
   *
   * @tags AdService
   * @name DeleteInterest
   * @summary 광고 즐겨찾기 해제
   * @request DELETE:/v2/channels/{channelId}/ads/{adId}/interest
   * @secure
   */
  deleteInterest = (channelId: number, adId: number, params: RequestParams = {}) =>
    this.http.request<AdDto, any>({
      path: `/v2/channels/${channelId}/ads/${adId}/interest`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 진행 중인 광고 목록 조회
   *
   * @tags AdService
   * @name GetAds1
   * @summary 진행 중인 광고 목록 조회
   * @request GET:/v2/ads
   * @secure
   */
  getAds1 = (
    query?: {
      /** @format int64 */
      ownChannelId?: number;
      /** 카테고리 ('의', '식', '주') */
      category?: string;
      /**
       * 'yyyy-MM-dd' (시작 날짜)
       * @format date
       */
      startDate?: string;
      /**
       * 'yyyy-MM-dd' (마지막 날짜)
       * @format date
       */
      endDate?: string;
      /** @default [] */
      tags?: string[];
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<AdDto[], any>({
      path: `/v2/ads`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 진행 중인 광고 조회
   *
   * @tags AdService
   * @name GetAd1
   * @summary 진행 중인 광고 조회
   * @request GET:/v2/ads/{adId}
   * @secure
   */
  getAd1 = (
    adId: number,
    query?: {
      /** @format int64 */
      ownChannelId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<AdDto, any>({
      path: `/v2/ads/${adId}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
