import MemberCSStore from 'stores/MemberCSStore';
import {
  DealInquiryDto,
  DealInquiryThreadDto,
  InquirySummaryDto,
  LinkDto,
  MessageDto,
} from 'services/data-contracts';
import { useState } from 'react';

/**
 * @description
 * @returns
 */
const ManagerCSController = () => {
  const csStore = new MemberCSStore();

  const [deals, setDeals] = useState<LinkDto[]>();
  const [inquiries, setInquiries] = useState<DealInquiryDto[]>();
  const [threads, setThreads] = useState<DealInquiryThreadDto[]>();
  const [summary, setSummary] = useState<InquirySummaryDto>();

  const getDeals = async () => {
    const result = (await csStore.getDeals()) as unknown as LinkDto[];
    setDeals(result);
  };

  const getInquiryList = async (status?: 'PENDING' | 'ANSWERED' | 'CLOSED') => {
    const result = (await csStore.getInquiryList(status)) as unknown as DealInquiryDto[];
    setInquiries(result);
  };

  const getInquiryDetail = async (inquiryId: number) => {
    return (await csStore.getInquiryDetail(inquiryId)) as DealInquiryDto;
  };

  const getInquiryThreads1 = async (inquiryId: number) => {
    const result = (await csStore.getInquiryThreads(
      inquiryId,
    )) as unknown as DealInquiryThreadDto[];
    setThreads(result);
  };

  const answer1 = async (inquiryId: number, message: MessageDto) => {
    const result = (await csStore.answer(inquiryId, message)) as unknown as DealInquiryThreadDto;
    setThreads((prev) => [...(prev || []), result]);
  };

  const getSummary = async () => {
    const result = (await csStore.getCSSummary()) as unknown as InquirySummaryDto;
    setSummary(result);
  };

  const closeInquiry = async (inquiryId: number) => {
    return (await csStore.closeInquiry(inquiryId)) as unknown as DealInquiryDto;
  };

  return {
    deals,
    inquiries,
    threads,
    summary,
    getDeals,
    getInquiryList,
    getInquiryDetail,
    getInquiryThreads1,
    answer1,
    closeInquiry,
    getSummary,
  };
};

export default ManagerCSController;
