import React, { useEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { ReactComponent as Plus } from 'resources/images/sticker/sticker_plus.svg';

import { Button } from '@mui/material';
import StickerItems from 'resources/images/sticker';
import styled from 'styled-components';
import CommonRadioGroup from 'features/management/components/commonControl/CommonRadioGroup';
import CustomizedStickers from './CustomizedStickers';
import { Row } from '../layouts';
import StickerEditor from './StickerEditor';
import { StickerTypes } from './StickerTypes';

interface StickerContainerProps {
  initialValue?: StickerTypes[];
  initialPosition?: 'top' | 'bottom';
  onChange: (stickers: StickerTypes[]) => void;
  onChangePosition?: (position: 'top' | 'bottom') => void;
}
const OhmossStickers = ({
  onChange,
  onChangePosition,
  initialPosition,
  initialValue,
}: StickerContainerProps) => {
  const { uiStore } = useStore();
  const [selectedStickers, setSelectedStickers] = useState<StickerTypes[]>([]);

  const handleChildDataChange = (sticker?: StickerTypes) => {
    uiStore.bottomSheet.show({
      topPosition: 40,
      children: <StickerEditor stickers={selectedStickers} targetSticker={sticker} />,
      onConfirmed(results: StickerTypes[]) {
        results.map((result) => {
          return { ...result, style: JSON.stringify(result.internalStyle) };
        });

        setSelectedStickers(results);
        onChange(results);
      },
    });
  };

  const handleChildDelete = (sticker: StickerTypes) => {
    const newStickers = selectedStickers.filter((item: StickerTypes) => item.seq !== sticker.seq);
    setSelectedStickers(newStickers);
    onChange(newStickers);
  };

  useEffect(() => {
    const newStickers = selectedStickers.map((sticker: StickerTypes) => {
      return { ...sticker, style: JSON.stringify(sticker.internalStyle) };
    });
    if (selectedStickers.length > 0) {
      onChange(newStickers);
    }
  }, [selectedStickers]);

  useEffect(() => {
    if (initialValue) {
      const data = initialValue.map((sticker) => {
        const parsedStyle = sticker.style !== undefined ? JSON.parse(sticker.style) : {};
        const stickerType = (sticker.stickerType ?? 'TypeA') as keyof typeof StickerItems;
        const { component } = StickerItems[stickerType];
        return {
          ...sticker,
          component,
          internalStyle: parsedStyle,
        };
      });
      setSelectedStickers(data);
    }
  }, [initialValue]);

  return (
    <>
      <Row style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
        <Button
          style={{
            padding: '10px',
            height: '28px',
            border: '1px solid #EFEFEF',
            borderRadius: '50px',
            color: '#BEBEBE',
            fontSize: '14px',
          }}
          onClick={() => handleChildDataChange()}
        >
          스티커 추가 <Plus style={{ marginLeft: '5px' }} />
        </Button>
        <CustomizedStickers
          mode="edit"
          selectedStickers={selectedStickers}
          onChildDataChange={handleChildDataChange}
          onChildDelete={handleChildDelete}
        />
      </Row>
      <div style={{ marginTop: 20 }}>
        <CommonRadioGroup
          onChange={(value) => {
            onChangePosition?.(value as 'top' | 'bottom');
          }}
          title="스티커 표시위치"
          initialValue={initialPosition ?? 'top'}
          items={[
            { label: '타이틀 위', value: 'top' },
            { label: '타이틀 아래', value: 'bottom' },
          ]}
        />
      </div>
    </>
  );
};

const CloseButtonStyle = styled.div`
  padding: 20px;
`;

export default OhmossStickers;
