import TextLabel from 'components/commons/labels/TextLabel';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { ChannelBoardDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import styled from 'styled-components';

const NewPostSelector = ({
  channelId,
  channelName,
}: {
  channelId: number;
  channelName: String;
}) => {
  const { navigate } = useNavigation();

  const { uiStore, myChannelStore, myBoardStore } = useStore();
  const [categories, setCategories] = useState<ChannelBoardDto[]>([]);

  const handleClickCategory = async (category: ChannelBoardDto) => {
    uiStore.bottomSheet.close();
    if (category.boardType === 'LINK') {
      navigate(`/link/${category.id}/new?channelid=${myChannelStore.currentChannel?.id}`);
    } else {
      navigate(`/post/${category.id}/new?origin=${channelName}&channelid=${category.channel?.id}`);
    }
  };

  const handleClickNewCategory = () => {
    uiStore.bottomSheet.close();
    navigate(`${channelName}/admin/links`);
  };

  const initialBoards = async () => {
    if (channelId) {
      const boards = (await myBoardStore.getBoards(
        myChannelStore.currentChannel?.id as number,
      )) as never as ChannelBoardDto[];
      setCategories(boards);
    }
  };

  useEffect(() => {
    initialBoards();
  }, [channelId]);

  return (
    <>
      <TextLabel style={{ padding: '0 40px', color: '#ccc' }} type="subject">
        새글을 작성하실 페이지를 선택 해주세요.
      </TextLabel>
      <BoardContainer>
        {categories.map((category: ChannelBoardDto) => (
          <BoardItemContainer
            onClick={() => {
              handleClickCategory(category);
            }}
          >
            {category.name}{' '}
            {category.boardType === 'LINK' && (
              <LinkBoardTypeStyle>Link category</LinkBoardTypeStyle>
            )}
          </BoardItemContainer>
        ))}
        <BoardItemContainer>
          <OhmossButton onClick={handleClickNewCategory}>새 페이지 만들기</OhmossButton>
        </BoardItemContainer>
      </BoardContainer>
    </>
  );
};

const BoardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;
  margin-bottom: 30px;
`;

const BoardItemContainer = styled.div`
  color: var(--gray-850, #262626);
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 120% */
`;

const LinkBoardTypeStyle = styled.div`
  display: inline;
  background: rebeccapurple;
  padding: 4px;
  border-radius: 8px;
  margin-left: 10px;
  color: #fff;
  font-size: 12px;
`;
export default NewPostSelector;
