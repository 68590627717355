/* eslint-disable import/no-named-as-default */
import React, { useEffect, useState } from 'react';

import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { t } from 'i18next';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import ColorPickerInstance from './ColorPickerInstance';
import { colorGroups } from 'resources/definitions/Definitions';
import { Row } from 'components/commons/layouts';

const ColorPicker = observer(
  ({
    children,
    useGradient,
    useSwipe,
    defaultValue,
    value,
    onClose,
    onColorChanged,
  }: {
    defaultValue?: string;
    value?: string;
    useGradient: boolean;
    useSwipe?: boolean;
    children: React.ReactNode;
    onClose?: () => void;
    onColorChanged: (color: string | undefined) => void;
  }) => {
    const { uiStore } = useStore();
    const [selectedColor, setSelected] = useState<string>();
    const [useCustom, setUseCustom] = useState<'custom' | 'auto'>();

    const handleShow = () => {
      uiStore.universalModal.show({
        title: t('A1863'),
        description: t('A183'),
        bottomSheetOptions: { blocking: false, expandOnContentDrag: false, topPosition: 0 },
        modalOption: { style: { width: 300, height: 700 } },
        children: (
          <>
            <ColorPickerInstance
              useGradient
              onClose={() => {
                uiStore.universalModal?.close();
              }}
              onColorChanged={(color: string) => {
                onColorChanged(color);
                setUseCustom('custom');
                setSelected(color);
              }}
              defaultColor={defaultValue}
            />
          </>
        ),
      });
    };

    useEffect(() => {
      if (defaultValue) {
        const recent = colorGroups.find((color: string) => color === defaultValue);
        setUseCustom(recent ? 'auto' : 'custom');
        setSelected(defaultValue);
      } else {
        setSelected(undefined);
      }
    }, [defaultValue]);

    return (
      <>
        <Row style={{ alignItems: 'flex-start' }}>
          <div style={{ width: 70 }}>
            <ColorChipStyle2
              onClick={handleShow}
              selected={useCustom === 'custom'}
              style={{ background: useCustom === 'custom' ? selectedColor : '#fff' }}
            >
              <AddIcon style={{ color: useCustom !== 'custom' ? '#000' : '#CCCCCC' }} />
            </ColorChipStyle2>
            {selectedColor && (
              <ColorChipStyle2
                selected={useCustom === 'custom'}
                style={{ marginTop: 10 }}
                onClick={() => {
                  setSelected(undefined);
                  onColorChanged(undefined);
                }}
              >
                <ClearIcon style={{ color: useCustom !== 'custom' ? '#000' : '#CCCCCC' }} />
              </ColorChipStyle2>
            )}
          </div>
          <>
            {useSwipe === true ? (
              <Swiper
                spaceBetween={10}
                slidesPerView={7}
                grabCursor
                enabled
                style={{ maxWidth: 500 }}
              >
                {colorGroups.map((color: string) => (
                  <SwiperSlide style={{ padding: 10 }}>
                    <ColorChipStyle
                      selected={color === selectedColor}
                      style={{ background: color }}
                      onClick={() => {
                        setSelected(color);
                        setUseCustom(undefined);
                        onColorChanged(color);
                      }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <Row style={{ flexWrap: 'wrap', gap: 15 }}>
                {colorGroups.map((color: string) => (
                  <ColorChipStyle
                    selected={color === selectedColor}
                    style={{
                      background: color,
                      border: color === '#ffffff' ? '1px solid #d8d8d8' : '1px solid transparent',
                    }}
                    onClick={() => {
                      setSelected(color);
                      setUseCustom(undefined);
                      onColorChanged(color);
                    }}
                  />
                ))}
              </Row>
            )}
          </>
        </Row>
      </>
    );
  },
);

const ColorChipStyle = styled.div<{ selected?: boolean }>`
  width: 36px;
  height: 36px;
  border-radius: 50px;
  outline: ${(props) => (props.selected ? ' 4px solid #2EBE59' : ' 4px solid transparent')};
  outline-offset: ${(props) => (props.selected ? '3px' : '0px')};
  transition: all 0.2s ease-in-out;
`;

const ColorChipStyle2 = styled.div<{ selected?: boolean }>`
  width: 36px;
  height: 36px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  border: 1px solid #cccccc;
  align-items: center;
  border-radius: 50px;
  outline: ${(props) => (props.selected ? ' 4px solid #2EBE59' : ' 4px solid transparent')};
  outline-offset: ${(props) => (props.selected ? '3px' : '0px')};
  transition: all 0.2s ease-in-out;
`;

export default ColorPicker;
