import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { GroupedTag, TagModel } from 'types/CommonTypes';
import { ToggleButton } from 'components/commons/buttons';
import styled from 'styled-components';
import { TagDto } from 'services/data-contracts';
import _ from 'lodash';

const TagList = forwardRef(
  ({ tags, onSelected }: { tags?: Array<GroupedTag>; onSelected?: any }, ref) => {
    const targetTags = useRef<Array<GroupedTag>>();

    React.useImperativeHandle(ref, () => ({ getCheckedTagIds }));

    const getCheckedTagIds = (): Array<number> => {
      let target: Array<number> = [];
      _.map(tags, (el) => {
        return _.filter(el.tags, (tag: TagModel) => tag.selected === true).map((o) => o.id);
      })
        .filter((a) => a.length > 0)
        .map((arr: any) => {
          target = target.concat(arr);
        });

      return target;
    };

    useEffect(() => {
      if (tags) {
        targetTags.current = tags;
      }
    }, [tags]);

    return (
      <div style={{ width: '100%' }}>
        {tags?.map((group: GroupedTag) => (
          <>
            <TagGroupNameStyle>
              {group.groupName !== 'undefined' && group.groupName}
            </TagGroupNameStyle>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {group.tags.map((tag: TagModel) => (
                <div style={{ marginBottom: 10, width: 'auto' }}>
                  <ToggleButton
                    defaultValue={tag.selected}
                    model={tag.selected}
                    onChange={(selected: boolean) => {
                      tag.selected = selected;
                      if (onSelected) {
                        onSelected(getCheckedTagIds());
                      }
                    }}
                  >
                    {tag.name}
                  </ToggleButton>
                </div>
              ))}
            </div>
          </>
        ))}
      </div>
    );
  },
);

const TagGroupNameStyle = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin: 10px 0;
`;

export default TagList;
