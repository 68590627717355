import React, { useState, useEffect, useRef } from 'react';
import { PostModel, PostItemModel } from 'types/CommonTypes';
import { setDefaultValue } from 'libs/helper/utils';
import { useTranslation } from 'react-i18next';
import { validationHelper as VH } from 'controls/validationHelper';
import { useStore } from 'stores/StoreHelper';
import { PostDto } from 'services/data-contracts';

import LinkEditorContainerStyle from 'resources/controls/LinkEditorConatainerStyle';
import Editor from 'components/commons/webEditor/Editor';
import SaveButtonWrapper from 'components/items/SaveButtonWrapper';
import TitleForm from 'components/items/forms/TitleForm';
import boardCommonLogics from '../logic/boardCommonLogic';

const BoardEditor = ({ model }: { model: any }) => {
  const initDto = (reset?: boolean): PostModel => {
    if (reset) {
      return {
        title: 'feed',
        channel: {},
        items: [],
      };
    } else {
      return {
        ...model,
        channel: setDefaultValue(model?.channel, {}),
      };
    }
  };
  const editorRef = useRef<any>(null);

  const { t } = useTranslation();
  const { uiStore, postStore } = useStore();
  const { handleSavePost } = boardCommonLogics();

  const [feedItems, setFeedItems] = useState<Array<PostItemModel>>(() => {
    return uiStore.universalModal.contentModel?.items || [];
  });
  const [feedModel, setFeedModel] = useState<PostModel>(() => {
    return uiStore.universalModal.contentModel?.model || initDto();
  });

  const [isValid, setIsValid] = useState<boolean>(false);

  const handleOnSave = () => {
    const content = editorRef.current.getData();
    const newFeedModel: PostModel = { ...feedModel, content, items: feedItems };
    handleSavePost(newFeedModel, (result: boolean, model: PostDto) => {
      if (result) {
        // postStore.updateFeedByItem(model, 'new');
        uiStore.universalModal.close();
      }
    });
  };

  const checkValidation = () => {
    const title = VH.required(feedModel.title);
    setIsValid(title);
  };

  useEffect(() => {
    if (model.id) {
      setFeedModel(initDto());
    }
  }, [model]);

  useEffect(() => {
    if (feedModel) {
      checkValidation();
    }
  }, [feedModel]);

  return (
    <>
      <LinkEditorContainerStyle>
        <SaveButtonWrapper
          isValid={isValid}
          onSave={handleOnSave}
          isNew={typeof feedModel?.id === 'undefined'}
        >
          <div style={{ margin: '10px 0' }}>
            <TitleForm
              label={t('Title')}
              model={feedModel?.title}
              onChangeValue={(value: string) => {
                setFeedModel((prev: any) => {
                  return { ...prev, title: value };
                });
              }}
            />
          </div>
          <div>
            <Editor
              ref={editorRef}
              defaultValue={
                uiStore.universalModal.contentModel?.model?.content || feedModel.content
              }
              onEditorChange={() => {
                // 브라우저 사이즈 변경할때 모델을 공유하기 위해 공용 모델을 업데이트 한다.
                // Editor는 실시간으로 데이터를 취득하지 않고 저장하는 시점에 꺼내온다.
                uiStore.universalModal.updateModel({
                  model: { ...feedModel, content: editorRef.current.getData() },
                  items: feedItems,
                });
              }}
            />
          </div>
        </SaveButtonWrapper>
      </LinkEditorContainerStyle>
    </>
  );
};

export default BoardEditor;
