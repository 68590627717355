// useMyContext.ts
import { useContext } from 'react';
import { GlobalContext, propsTypes } from './GlobalContext';

export const useGlobalContext = (): propsTypes => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext must be used within a MyProvider');
  }
  return context;
};
