import React, { useEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { ChannelDto, PostCommentDto } from 'services/data-contracts';
import { Avatar, IconButton } from '@mui/material';
import { DateText, SubTitleText } from 'resources/controls/Titles';
import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Row } from 'components/commons/layouts';
import moment from 'moment';
import useToken from 'libs/hooks/useToken';
import ReplyForm from './ReplyForm';

const ListOfComments = observer(({ postId }: { postId: number }) => {
  const { t } = useTranslation();
  const { validUser } = useToken();
  const { myChannelStore, channelStore, postStore, uiStore, uiStores } = useStore();
  const { channel } = channelStore;
  const { currentBoard } = uiStores.userHomeStore;

  /** 댓글 */
  const [currentReply, setCurrentReply] = useState<PostCommentDto | undefined>();
  /** 수정 */
  const [selectedReply, setSelectedReply] = useState<PostCommentDto | undefined>();
  const [replyTo, setReplyTo] = useState<ChannelDto | undefined>();
  const [showEditor, setShowEditor] = useState<PostCommentDto | undefined>();

  const clearInfo = () => {
    setCurrentReply(undefined);
    setReplyTo(undefined);
    setShowEditor(undefined);
    setSelectedReply(undefined);
  };

  const handleEditReply = (comment: PostCommentDto) => {
    setSelectedReply(comment);
    // setReplyTo(comment.writer);
  };

  const handleDeleteReply = (comment: PostCommentDto) => {
    uiStore.confirm.show({
      message: '답글을 삭제하시겠습니까?',
      onConfirmed: async () => {
        const result = (await postStore.deleteBoardComment(
          channelStore.channel.channelName!,
          uiStores.userHomeStore.currentBoard?.id as number,
          postId,
          comment.id as number,
          { ownChannelId: myChannelStore.currentChannel?.id as number },
        )) as any;

        if (result === '') {
          postStore.updateCommentByCommentItem(comment, 'delete');
        }
      },
    });
  };

  const CommentItem = ({
    comment,
    parentId,
    isChild,
  }: {
    comment: PostCommentDto;
    parentId?: number;
    isChild?: boolean;
  }) => {
    return (
      <PostCommentContainerStyle showBorder={!isChild}>
        <Row style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <div style={{ marginRight: 10 }}>
            <Avatar src={comment?.writer?.profileImage?.publicUrl} />
          </div>
          <div style={{ flex: 1 }}>
            <div>
              <SubTitleText>{comment?.writer?.channelName}</SubTitleText>
              <DateText>
                {comment?.createDate ? (
                  <>{moment.utc(comment.createDate).fromNow()}</>
                ) : (
                  <>{moment().fromNow()}</>
                )}
              </DateText>
            </div>
            <CommentStyle>
              {comment && selectedReply?.id === comment?.id ? (
                <>
                  <ReplyForm
                    onClose={() => {
                      clearInfo();
                    }}
                    type="answer"
                    parentId={parentId}
                    feedId={postId}
                    replyModel={selectedReply}
                    style={{ padding: 0, marginTop: 20, width: '100%' }}
                  />
                  <ReplyFormStyle
                    onClick={() => {
                      setSelectedReply(undefined);
                    }}
                    style={{ marginTop: 10, marginBottom: 20 }}
                  >
                    수정취소
                  </ReplyFormStyle>
                </>
              ) : (
                <>
                  <div style={{ marginTop: 10 }}>
                    {comment?.targetWriter && (
                      <span
                        style={{
                          background: '#0BBD58',
                          color: '#fff',
                          padding: 4,
                          borderRadius: 10,
                          marginRight: 10,
                        }}
                      >
                        <b>{comment.targetWriter?.channelName}</b>{' '}
                      </span>
                    )}
                    {comment?.content}
                  </div>
                  {validUser() && (
                    <Row
                      style={{ justifyContent: 'flex-start', alignItems: 'center', marginTop: 10 }}
                    >
                      <ReplyFormStyle
                        onClick={() => {
                          setCurrentReply(!currentReply?.id ? comment : undefined);
                          setReplyTo(comment?.writer);
                        }}
                      >
                        {currentReply?.id === comment?.id ? '답글 취소' : '답글 달기'}
                      </ReplyFormStyle>
                      <>
                        {comment.writer?.id === myChannelStore?.currentChannel?.id && (
                          <>
                            <IconButton
                              style={{ marginLeft: 30 }}
                              onClick={() => {
                                setShowEditor(comment);
                              }}
                            >
                              <MoreHorizIcon />
                            </IconButton>
                            {showEditor?.id === comment.id && (
                              <div
                                style={{
                                  background: '#efefef',
                                  borderRadius: 20,
                                  margin: '0 20px',
                                }}
                              >
                                <IconButton
                                  onClick={() => {
                                    handleEditReply(comment);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    handleDeleteReply(comment);
                                  }}
                                >
                                  <DeleteOutlineIcon />
                                </IconButton>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    </Row>
                  )}
                </>
              )}

              {currentReply?.id === comment?.id && (
                <ReplyForm
                  onClose={() => {
                    clearInfo();
                  }}
                  type="answer"
                  feedId={postId}
                  parentId={parentId}
                  style={{ padding: 0, marginTop: 20, width: '100%' }}
                  replyTo={replyTo}
                />
              )}
            </CommentStyle>
            {comment?.replies?.map((reply: PostCommentDto) => (
              <CommentItem comment={reply} parentId={comment.id} isChild />
            ))}
          </div>
        </Row>
        {/* <AvatarNameCard image="" date={comment.createDate}>
          <div></div>

          
        </AvatarNameCard> */}
      </PostCommentContainerStyle>
    );
  };
  useEffect(() => {
    if (postId && channel.channelName) {
      postStore.getBoardComment(channel?.channelName, currentBoard?.id as number, postId);
    }
  }, [postId, channel]);

  useEffect(() => {
    return () => {
      postStore.unMountPostComment();
    };
  }, []);
  return (
    <PostListStyle>
      {postStore.postComments?.map((comment: PostCommentDto) => (
        <CommentItem comment={comment} parentId={comment.id} />
      ))}
    </PostListStyle>
  );
});

const PostListStyle = styled.div`
  > div:last-child {
    border-bottom: none !important;
  }
`;
const PostCommentContainerStyle = styled.div<{ showBorder?: boolean }>`
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;

  padding: ${(props) => (props.showBorder ? '20px 0' : '0')};
  border-bottom: ${(props) => (props.showBorder ? '1px solid #dadada' : '')};
`;

const ReplyFormStyle = styled.div`
  color: #4c80f1;
  font-size: 14px;
  cursor: pointer;
`;

const CommentStyle = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-right: 40px;
`;
export default ListOfComments;
