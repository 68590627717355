import { makeObservable, flow, observable, action } from 'mobx';
import { MyBoard as MyBoardService } from 'services/MyBoard';
import { HttpClient } from 'services/http-client';
import { ChannelBoardDto } from 'services/data-contracts';
import _ from 'lodash';
import ServiceStoreBase from './ServiceStoreBase';

/**
 * @description Public Link Store
 * @deprecated
 */
class ChannelBoardStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      channelBoards: observable,
      channelBoard: observable,
      // getChannelBoards: flow,
    });
  }

  api: MyBoardService<unknown> = new MyBoardService(new HttpClient());

  channelBoards: Array<ChannelBoardDto> = [];

  channelBoard: ChannelBoardDto;

  /** ========================================================================================= */

  // *getChannelBoards(channelId: number) {
  //   const { response } = yield this.api.getBoards(channelId);
  //   this.channelBoards = response.data;
  //   return this.channelBoards;
  // }
}

export default ChannelBoardStore;
