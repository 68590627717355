import { makeObservable, flow, observable, action } from 'mobx';
import { Post as PostService } from 'services/Post';
import { MyPost as MyPostService } from 'services/MyPost';
import { HttpClient } from 'services/http-client';
import { PagenationType, PostModel } from 'types/CommonTypes';
import i18n from 'i18n';
import {
  ChannelIdentifierDto,
  PostCommentDto,
  PostCommentRequestDto,
  PostDto,
} from 'services/data-contracts';
import _ from 'lodash';
import ServiceStoreBase from './ServiceStoreBase';

/**
 * @description Public Link Store
 */
class PostStore extends ServiceStoreBase {
  t = i18n.t;

  constructor() {
    super();
    makeObservable(this, {
      posts: observable,
      boardPosts: observable,
      post: observable,
      postComments: observable,
      postPaignation: observable,
      updateValues: action,
      unMount: action,
      unMountPostComment: action,
      updateFeedByItem: action,
      getPosts: flow,
      getPost: flow,
      createPost: flow,
      getBoardComment: flow,
      createBoardComment: flow,
      updateBoardComment: flow,
      deleteBoardComment: flow,
    });
  }

  api: PostService<unknown> = new PostService(new HttpClient());

  myApi: MyPostService<unknown> = new MyPostService(new HttpClient());

  posts: Array<PostDto> | undefined = [];

  boardPosts: Array<{ boardId: number; posts: Array<PostDto> | undefined }> = [];

  post: PostDto | undefined;

  postComments: Array<PostCommentDto> | undefined = [];

  postPaignation: PagenationType;

  /** ========================================================================================= */

  *getPosts(channelName: string, boardId: number, isInfinity?: boolean, query?: any) {
    // if (isInfinity) {
    //   query;
    // }
    const { response } = yield this.api.getPosts1(channelName, boardId, {
      ...query,
      sort: ['seq'],
    });

    if (isInfinity) {
      this.posts = this.posts?.concat(response.data);
    } else {
      this.posts = response.data.slice();
    }
    const pagenation = response.headers['x-pagination'];
    if (pagenation) {
      this.postPaignation = JSON.parse(pagenation);
    }
    return response.data;
  }

  *getPost(channelName: string, boardId: number, postId: number, ownChannelId?: number) {
    const { response } = yield this.api.getPost2(channelName, boardId, postId, { ownChannelId });
    this.post = response.data;
    return response.status === 200 ? response.data : null;
  }

  *createPost(channelId: number, boardId: number, postItem: PostDto) {
    const { response } = yield this.myApi.createPost(channelId, boardId, postItem);
    super.handleByStatus(response, { successMessage: this.t('A216') });
    return response.data;
  }

  *getBoardComment(channelName: string, boardId: number, postId: number) {
    // todo :paging 처리 할것
    const { response } = yield this.api.getPostComments(channelName, boardId, postId, {
      size: 100,
    });
    this.postComments = response.data;
  }

  *createBoardComment(
    channelName: string,
    boardId: number,
    postId: number,
    commentData: PostCommentRequestDto,
  ) {
    const { response } = yield this.api.createPostComment(
      channelName,
      boardId,
      postId,
      commentData,
    );
    super.handleByStatus(response);
    return response.status === 200 ? response.data : null;
  }

  *updateBoardComment(
    channelName: string,
    boardId: number,
    postId: number,
    commentId: number,
    commentData: PostCommentRequestDto,
  ) {
    const newModel = { ...commentData };
    const { response } = yield this.api.updatePostComment(
      channelName,
      boardId,
      postId,
      commentId,
      newModel,
    );
    super.handleByStatus(response);
    return response.status === 200 ? response.data : null;
  }

  *deleteBoardComment(
    channelName: string,
    boardId: number,
    postId: number,
    commentId: number,
    commentData: ChannelIdentifierDto,
  ) {
    const { response } = yield this.api.deletePostComment(
      channelName,
      boardId,
      postId,
      commentId,
      commentData,
    );
    super.handleByStatus(response, { successMessage: this.t('A217') });
    return response.status === 200 ? response.data : null;
  }

  updateValues(
    arr: Array<PostCommentDto> | undefined,
    key: string,
    value: any,
    param: string | undefined,
    replaceObject?: PostCommentDto,
  ): Array<PostCommentDto> | undefined | boolean {
    if (typeof arr !== 'undefined') {
      // Nested Array
      return arr.some((o, i, a) =>
        // eslint-disable-next-line no-nested-ternary
        o.id === value
          ? replaceObject
            ? a.splice(i, 1, replaceObject)
            : a.splice(i, 1)
          : this.updateValues(o.replies || [], key, value, param, replaceObject),
      );
    }
  }

  updateCommentByCommentItem(comment: PostCommentDto, type?: 'delete' | 'update' | 'new') {
    if (type === 'new') {
      if (typeof this.postComments === 'undefined') {
        this.postComments = [];
      }
      if (comment.parentId) {
        const parentComments = this.postComments.find(
          (parentComment: PostCommentDto) => parentComment.id === comment.parentId,
        );
        if (parentComments) {
          if (!parentComments.replies) {
            parentComments.replies = [];
          }
          parentComments.replies?.push(comment);
        }
      } else {
        this.postComments.push(comment);
      }
    } else {
      this.updateValues(
        this.postComments,
        'id',
        comment.id,
        'replies',
        type === 'update' ? comment : undefined,
      );
    }
  }

  updateFeedByItem(feed: PostModel, type?: 'delete' | 'update' | 'new') {
    if (this.posts) {
      const index = this.posts.findIndex((recentLink: PostModel) => recentLink.id === feed.id);
      const newPosts = [...this.posts];
      if (type === 'delete') {
        newPosts.splice(index, 1);
      } else if (type === 'update') {
        feed.isNew = true;
        newPosts.splice(index, 1, feed);
      } else {
        feed.isNew = true;
        newPosts.unshift(feed);
      }
      this.posts = [...newPosts];
    }
  }

  unMount() {
    this.post = undefined;
    this.posts = [];
  }

  unMountPostComment() {
    this.postComments = undefined;
  }
}

export default PostStore;
