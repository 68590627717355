import React from 'react';
import { observer } from 'mobx-react';

import LinkDesignContainer from 'features/adminFeatures/appearance/linkDesign/containers/LinkDesignContainer';

const DesignPage = observer(() => {
  return <LinkDesignContainer />;
});

export default DesignPage;
