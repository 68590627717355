import React, { useEffect, useState } from 'react';

import {
  FormGroup,
  FormControlLabel,
  Switch,
  Box,
  Autocomplete,
  TextField,
  IconButton,
} from '@mui/material';
import { useStore } from 'stores/StoreHelper';
import TitleForm from 'components/items/forms/TitleForm';
import { TagDto } from 'services/data-contracts';
import { observer } from 'mobx-react';
import { GroupedTag } from 'types/CommonTypes';
import { setDefaultValue } from 'libs/helper/utils';
import { validationHelper as VH } from 'controls/validationHelper';
import { Row } from 'components/commons/layouts';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { t } from 'i18next';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import CURDHelper from 'libs/helper/crudHelper';

const CreateTag = observer(
  ({
    tag,
    onUnselected,
    onDeletedTag,
  }: {
    tag?: TagDto;
    onUnselected: () => void;
    onDeletedTag?: () => void;
  }) => {
    /**
     * @description 해당 컴포넌트의 기본 모델을 초기화 한다.
     * @returns
     */
    const intDto = (reset?: boolean) => {
      if (reset) {
        return {
          name: '',
          groupName: '',
          isVisible: true,
        };
      } else {
        return {
          ...tag,
          name: setDefaultValue(tag?.name, ''),
          groupName: setDefaultValue(tag?.groupName, ''),
          isVisible: setDefaultValue(tag?.isVisible, true),
        };
      }
    };

    const { myTagStore, myChannelStore, uiStore } = useStore();
    const [model, setModel] = useState<TagDto | undefined>(intDto());
    const [groups, setGroups] = useState<Array<GroupedTag>>([]);
    const [isValid, setSetIsValid] = useState<boolean>(false);

    const crudHelper = new CURDHelper(myTagStore, model);

    const handleClickCreate = async () => {
      if (myChannelStore.currentChannel?.id) {
        if (model) {
          if (!model.id) {
            await crudHelper.create(myChannelStore.currentChannel?.id as number);
          } else {
            await crudHelper.update(myChannelStore.currentChannel?.id as number);
          }
          setModel(intDto(true));
          crudHelper.getItems(myChannelStore.currentChannel?.id);
        }
      } else {
        alert(t('A201'));
      }
    };

    const handleSetModel = (key: keyof TagDto, value: any) => {
      setModel((prevState: TagDto) => {
        return { ...prevState, [key]: value };
      });
    };

    const handleDeleteTag = () => {
      if (onDeletedTag) {
        if (model?.id) {
          uiStore.confirm.show({
            message: t('A202'),
            onConfirmed: async () => {
              await crudHelper.delete(
                myChannelStore.currentChannel?.id as number,
                model?.id as number,
              );
              onDeletedTag();
              // init();
            },
          });
        }
      }
    };

    useEffect(() => {
      setSetIsValid(VH.required(model?.name));
    }, [model]);

    useEffect(() => {
      setModel(intDto());
    }, [tag]);

    useEffect(() => {
      if (myTagStore.groupTags) {
        const group = myTagStore.groupTags.filter((group) => group.groupName !== 'null');
        setGroups(group);
      }
    }, [myTagStore.groupTags]);

    return (
      <>
        <Box sx={{ maxWidth: 600 }}>
          <Autocomplete
            freeSolo
            id="free-solo-2"
            disableClearable
            value={model?.groupName}
            options={groups
              .filter((group) => group.groupName !== 'null')
              .map((group) => group.groupName)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('A200')}
                value={model?.groupName}
                onSelect={(e: any) => {
                  handleSetModel('groupName', e.target.value);
                }}
                onChange={(e: any) => {
                  handleSetModel('groupName', e.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
          />
          <Box sx={{ marginTop: 2 }}>
            <TitleForm
              required={false}
              label={t('A203')}
              model={model?.name}
              onChangeValue={(value: string) => {
                handleSetModel('name', value);
              }}
            />
          </Box>

          <FormGroup style={{ margin: '20px 0' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={model?.isVisible}
                  onChange={(e: unknown, value: boolean) => {
                    handleSetModel('isVisible', value);
                  }}
                />
              }
              label={t('A204')}
            />
          </FormGroup>
          <Row style={{ justifyContent: 'space-between' }}>
            <OhmossButton
              disabled={!isValid}
              style={{ padding: '15px 40px', width: 100 }}
              onClick={handleClickCreate}
            >
              {model?.id ? t('Edit') : t('Create')}
            </OhmossButton>
            <>
              {model?.id && (
                <Row style={{ justifyContent: 'flex-end' }}>
                  <div style={{ marginRight: 20 }}>
                    <IconButton
                      onClick={() => {
                        onUnselected();
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <IconButton style={{}} onClick={handleDeleteTag}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </Row>
              )}
            </>
          </Row>
        </Box>
      </>
    );
  },
);

export default CreateTag;
