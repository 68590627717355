import React, { useEffect, useRef, useState } from 'react';
import { Row } from 'components/commons/layouts';
import { useStateContext } from 'libs/hooks/usePageState';
import { useRootContext } from 'libs/hooks/useRootContext';
import { CSTownChecklistState } from 'types/CommonTypes';
import ReplayIcon from '@mui/icons-material/Replay';
import AIStore from 'stores/AIStore';
import styled from 'styled-components';
import ActionBox from 'features/csTown/components/pc/ActionBox';
import Text from 'components/commons/Text';
import { useStore } from 'stores/StoreHelper';
import ChecklistController from 'controllers/ChecklistController';
import { Button, IconButton } from '@mui/material';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { toast } from 'react-toastify';

const ChecklistTopicSummaryContainer = () => {
  const { myChannel } = useRootContext();
  const { uiStore } = useStore();
  const { saveTopicSummarize } = ChecklistController();
  const { stateModel, setMultipleState } = useStateContext<CSTownChecklistState>();
  const [isComplete, setIsComplete] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);

  const aiStore = new AIStore();
  const summary = useRef<string>('');
  const summaryDiv = useRef<any>();

  const getSummary = async () => {
    setIsBusy(false);
    summary.current = '';
    const url = `v2/me/channels/${myChannel?.id as number}/checklists/${
      stateModel?.selectedChecklist?.id as number
    }/topics/${stateModel?.selectedTopic?.id as number}/summarize`;
    const result = await aiStore.ask(url);
    result.onmessage = (e) => {
      setIsBusy(true);
      if (e.data !== '[DONE]') {
        const parsedData = JSON.parse(e.data);
        const message = parsedData.choices[0].delta?.content;
        if (message === 'html' || message === '' || message === '```') return;
        if (typeof message !== 'undefined') {
          summary.current += message;
          summaryDiv.current.innerHTML = summary.current;
        }
      } else {
        setIsComplete(true);
        setIsButtonActive(true);
        result.close();
      }
    };
  };

  const handleClickSaveSummary = async () => {
    const result = await saveTopicSummarize(myChannel?.id as number, stateModel?.selectedChecklist?.id as number, {
      id: stateModel?.selectedTopic?.id as number,
      summary: summary.current,
    });
    if(result){
      const newResult = new Object(result);
      setMultipleState({selectedTopic:newResult});
      toast.success('요약이 저장되었습니다.', { type: 'success' });
      if(isButtonActive){
        setIsButtonActive(false);
      }
    } else {
      toast.warn('요약저장에 실패하였습니다.');
    }
  };
  useEffect(() => {
    if (stateModel?.selectedTopic === undefined) return;
    if(!isComplete){
      if (!stateModel?.selectedTopic?.summary) {
        getSummary();
      } else {
        setIsBusy(true);
        summaryDiv.current.innerHTML = stateModel?.selectedTopic?.summary;
      }
    }
    
  }, [stateModel?.selectedTopic, isComplete]);



  return (
    <div style={{ padding: 20 }}>
      <Row style={{ justifyContent: 'space-between' }}>
        <Text type="H3">체크리스트 요약</Text>
        <OhmossButton onClick={getSummary} type="square" style={{ width: 50 }}>
          <ReplayIcon />
        </OhmossButton>
      </Row>
      {isBusy === false && <img src="/images/ohmossai.svg" style={{ height: 20 }} />}

      <Row style={{ flexDirection: 'column', gap: 5, width: '100%', padding: 20 }}>
        <SummaryStyle ref={summaryDiv} />
        <div style={{ marginTop: 30, width: '80%' }}>
          {isButtonActive === true && (
            <ActionBox
              onOk={() => {
                handleClickSaveSummary();
              }}
              onCancel={() => {
                uiStore.drawer.close();
              }}
              okText="저장하기"
              isValid={isButtonActive}
            />
          )}
        </div>
      </Row>
    </div>
  );
};

const SummaryStyle = styled.div`
  width: 100%;
  > ul {
    list-style: disc !important;
    margin-left: 20px;
  }
`;

export default ChecklistTopicSummaryContainer;
