import {
  OWebViewMessageData,
  OWebViewMessageDataType,
} from 'ohmoss-common-types/dist/owebviewTypes';

export default function owebviewPostMessage(
  type: OWebViewMessageDataType,
  data?: OWebViewMessageData['value'],
) {
  const next: OWebViewMessageData = { type, value: data };
  const messageData = JSON.stringify(next);
  window?.ReactNativeWebView?.postMessage(messageData);
}
