/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ChannelChecklistCreationDto,
  ChannelChecklistDto,
  ChannelChecklistItemDto,
  ChannelChecklistItemUpdateDto,
  ChannelChecklistTopicDto,
  ChannelChecklistUpdateDto,
  File,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MyChecklistService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 내 체크리스트 정보
   *
   * @tags MyChecklistService
   * @name GetMyChecklist
   * @summary 내 체크리스트 정보
   * @request GET:/v2/me/channels/{channelId}/checklists/{checklistId}
   * @secure
   */
  getMyChecklist = (channelId: number, checklistId: number, params: RequestParams = {}) =>
    this.http.request<ChannelChecklistDto, any>({
      path: `/v2/me/channels/${channelId}/checklists/${checklistId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 체크리스트 정보 수정
   *
   * @tags MyChecklistService
   * @name UpdateMyChecklist
   * @summary 내 체크리스트 정보 수정
   * @request PUT:/v2/me/channels/{channelId}/checklists/{checklistId}
   * @secure
   */
  updateMyChecklist = (
    channelId: number,
    checklistId: number,
    data: ChannelChecklistUpdateDto,
    params: RequestParams = {},
  ) =>
    this.http.request<ChannelChecklistDto, any>({
      path: `/v2/me/channels/${channelId}/checklists/${checklistId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 체크리스트 삭제
   *
   * @tags MyChecklistService
   * @name DeleteMyChecklist
   * @summary 내 체크리스트 삭제
   * @request DELETE:/v2/me/channels/{channelId}/checklists/{checklistId}
   * @secure
   */
  deleteMyChecklist = (channelId: number, checklistId: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/checklists/${checklistId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 내 체크리스트 토픽 요약 저장
   *
   * @tags MyChecklistService
   * @name UpdateTopicSummary
   * @summary 내 체크리스트 토픽 요약 저장
   * @request PUT:/v2/me/channels/{channelId}/checklists/{checklistId}/topics/{topicId}/summary
   * @secure
   */
  updateTopicSummary = (
    channelId: number,
    checklistId: number,
    topicId: number,
    data: ChannelChecklistTopicDto,
    params: RequestParams = {},
  ) =>
    this.http.request<ChannelChecklistTopicDto, any>({
      path: `/v2/me/channels/${channelId}/checklists/${checklistId}/topics/${topicId}/summary`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 체크리스트 아이템 수정
   *
   * @tags MyChecklistService
   * @name UpdateMyChecklistItems
   * @summary 내 체크리스트 아이템 수정
   * @request PUT:/v2/me/channels/{channelId}/checklists/{checklistId}/topics/{topicId}/items/{itemId}
   * @secure
   */
  updateMyChecklistItems = (
    channelId: number,
    checklistId: number,
    topicId: number,
    itemId: number,
    data: ChannelChecklistItemUpdateDto,
    params: RequestParams = {},
  ) =>
    this.http.request<ChannelChecklistItemDto[], any>({
      path: `/v2/me/channels/${channelId}/checklists/${checklistId}/topics/${topicId}/items/${itemId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 체크리스트 목록
   *
   * @tags MyChecklistService
   * @name GetMyChecklists
   * @summary 내 체크리스트 목록
   * @request GET:/v2/me/channels/{channelId}/checklists
   * @secure
   */
  getMyChecklists = (
    channelId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ChannelChecklistDto[], any>({
      path: `/v2/me/channels/${channelId}/checklists`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 체크리스트 생성
   *
   * @tags MyChecklistService
   * @name CreateMyChecklist
   * @summary 내 체크리스트 생성
   * @request POST:/v2/me/channels/{channelId}/checklists
   * @secure
   */
  createMyChecklist = (
    channelId: number,
    data: ChannelChecklistCreationDto,
    params: RequestParams = {},
  ) =>
    this.http.request<ChannelChecklistDto, any>({
      path: `/v2/me/channels/${channelId}/checklists`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 체크리스트 토픽목록
   *
   * @tags MyChecklistService
   * @name GetMyChecklistTopics
   * @summary 내 체크리스트 토픽목록
   * @request GET:/v2/me/channels/{channelId}/checklists/{checklistId}/topics
   * @secure
   */
  getMyChecklistTopics = (channelId: number, checklistId: number, params: RequestParams = {}) =>
    this.http.request<ChannelChecklistTopicDto[], any>({
      path: `/v2/me/channels/${channelId}/checklists/${checklistId}/topics`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyChecklistService
   * @name DownloadTopicSummaryPdf
   * @request GET:/v2/me/channels/{channelId}/checklists/{checklistId}/topics/{topicId}/summary/pdf
   * @secure
   */
  downloadTopicSummaryPdf = (
    channelId: number,
    checklistId: number,
    topicId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/v2/me/channels/${channelId}/checklists/${checklistId}/topics/${topicId}/summary/pdf`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyChecklistService
   * @name SummarizeTopic
   * @request GET:/v2/me/channels/{channelId}/checklists/{checklistId}/topics/{topicId}/summarize
   * @secure
   */
  summarizeTopic = (
    channelId: number,
    checklistId: number,
    topicId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<string[], any>({
      path: `/v2/me/channels/${channelId}/checklists/${checklistId}/topics/${topicId}/summarize`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * @description 내 체크리스트 아이템 목록
   *
   * @tags MyChecklistService
   * @name GetMyChecklistItems
   * @summary 내 체크리스트 아이템 목록
   * @request GET:/v2/me/channels/{channelId}/checklists/{checklistId}/topics/{topicId}/items
   * @secure
   */
  getMyChecklistItems = (
    channelId: number,
    checklistId: number,
    topicId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<ChannelChecklistItemDto[], any>({
      path: `/v2/me/channels/${channelId}/checklists/${checklistId}/topics/${topicId}/items`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyChecklistService
   * @name DownloadTopicsSummaryPdf
   * @request GET:/v2/me/channels/{channelId}/checklists/{checklistId}/summary/pdf
   * @secure
   */
  downloadTopicsSummaryPdf = (channelId: number, checklistId: number, params: RequestParams = {}) =>
    this.http.request<File, any>({
      path: `/v2/me/channels/${channelId}/checklists/${checklistId}/summary/pdf`,
      method: 'GET',
      secure: true,
      format: 'blob',
      ...params,
    });
}
