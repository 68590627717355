import React, { useState, useEffect, useRef } from 'react';
import { CustomContent, CustomFile, PostModel, PostItemModel } from 'types/CommonTypes';
import { ImageUploader } from 'components/commons';
import { cloneDeep, setDefaultValue } from 'libs/helper/utils';
import { useTranslation } from 'react-i18next';

import { useStore } from 'stores/StoreHelper';
import { ChannelBoardDto, PostDto, PostTagDto } from 'services/data-contracts';
import { validationHelper as VH } from 'controls/validationHelper';

import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import LinkEditorContainerStyle from 'resources/controls/LinkEditorConatainerStyle';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import VideoComponent from 'features/adminFeatures/commons/components/VideoComponent';
import Editor from 'components/commons/webEditor/Editor';
import boardCommonLogics from 'features/board/logic/boardCommonLogic';
import SaveButtonWrapper from './SaveButtonWrapper';
import TitleForm from './forms/TitleForm';
import UrlForm from './forms/UrlForm';
import HashEditor from './HashEditor';

/**
 *
 * @param param0 useAd : 이어서 광고등록이 필요한경우.
 * @returns
 */
const AdPostEditor = ({
  model,
  boardId,
  isInactive,
  isAdBoard,
  saveText,
  onRegistred,
}: {
  model?: PostDto;
  boardId: number;
  saveText: string;
  isInactive?: boolean;
  isAdBoard: boolean;
  onRegistred: (result: PostDto) => void;
}) => {
  const intDto = (reset?: boolean): PostModel => {
    if (reset) {
      return {
        title: '',
        channel: {},
        items: [],
      };
    } else {
      return {
        title: setDefaultValue(model?.title, ''),
        channel: setDefaultValue(model?.channel, {}),
        ...model,
      };
    }
  };
  const editorRef = useRef<any>(null);
  const { handleSavePost } = boardCommonLogics();

  const { t } = useTranslation();
  const { uiStore } = useStore();

  const [feedItems, setFeedItems] = useState<Array<PostItemModel>>(() => {
    return uiStore.universalModal.contentModel?.items || [];
  });
  const [feedModel, setFeedModel] = useState<PostModel | undefined>(() => {
    return uiStore.universalModal.contentModel?.model || intDto();
  });

  const [hashs, setHashs] = useState<PostTagDto[]>();

  const [isValid, setIsValie] = useState<boolean>(false);
  const [swiper, setSwiper] = useState<any>(null);


  const handleOnSave = () => {
    let content;
    if (editorRef.current) {
      content = editorRef.current.getData();
    }
    const newFeedModel: PostModel = { ...feedModel, content, items: feedItems, board: { id: boardId } };
    if (isInactive === true) {
      newFeedModel.isActive = false;
    }
    handleSavePost(
      newFeedModel,
      (result: boolean, model: PostDto) => {
        if (result) {
          onRegistred(model);
          setFeedItems([]);
          setFeedModel(undefined);
        }
      },
      true,
    );
  };

  const handleDeleteCard = (id: number) => {
    uiStore.confirm.show({
      message: t('A214'),
      onConfirmed: () => {
        const newCards = feedItems?.filter((card, index) => {
          return index !== id;
        });
        setFeedItems(newCards);
      },
    });
  };

  // 멀티플 업로드 대응
  const handleBindMultipleImage = (images: Array<any>) => {
    const newCards = [...feedItems];

    const seq =
      // eslint-disable-next-line no-nested-ternary
      feedItems?.length === 0 ? 0 : feedItems[feedItems?.length - 1].seq;
    // eslint-disable-next-line array-callback-return
    images.map((image: any) => {
      const { file } = image;
      file.publicUrl = image.publicUrl;
      newCards.push({
        seq: typeof seq === 'undefined' ? 0 : seq + 1,
        isActive: true,
        file: image as CustomFile,
        metadataObject: cloneDeep(defalutCardCustomProps),
      });
    });
    setFeedItems([...newCards]);
    updateGlobalModel(newCards);
    swiper?.slideTo(newCards.length - 1);
  };

  const updateGlobalModel = (images?: any) => {
    uiStore.universalModal.updateModel({
      model: { ...feedModel, content: editorRef.current.getData() },
      items: images || feedItems,
    });
  };

  const checkValidation = () => {
    const title = VH.required(feedModel?.title);
    // const url = VH.url(feedModel?.linkUrl);
    setIsValie(title && feedItems.length > 0);
  };

  const initModel = async () => {
    if (model) {
      setFeedModel(model);
      setFeedItems(model.items as Array<PostItemModel>);
      setHashs(model.tags);
    }
  };

  useEffect(() => {
    if (feedModel) {
      checkValidation();
    }
  }, [feedModel, feedItems]);

  useEffect(() => {
    if (model?.id) {
      initModel();
      // setShowEditor(typeof model.content === 'string');
    }
  }, [model]);


  useEffect(() => {
    return (() => {
      setFeedModel(undefined);
      setFeedItems([]);
    });
  }, []);


  return (
    <>
      <LinkEditorContainerStyle>
        <SaveButtonWrapper
          isValid={isValid}
          useCancelButton={false}
          saveText={saveText}
          onSave={handleOnSave}
          isNew={typeof feedModel?.id === 'undefined'}
        >
          <div style={{ marginBottom: 20 }}>
            {typeof feedItems !== 'undefined' && (
              <>
                <CardContainerStyle>
                  <ImageUploader
                    type='multiple'
                    cropSize={{ width: 1200, height: 1200 }}
                    attachType="media"
                    useCrop={false}
                    multipleUpload
                    onSelected={(thumbnails: any) => {
                      handleBindMultipleImage(thumbnails);
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: 'auto', marginRight: 10, marginTop: 8, width: 60, height: 60, border: '1px solid #efefef' }}>
                      <CameraAltIcon sx={{ width: 30, height: 30 }} />
                    </div>
                  </ImageUploader>
                  <div className='snap-container' style={{ height: 'auto' }}>
                    {feedItems.map((card: PostItemModel, index: number) => (
                      <CardCanvasContainerStyle className='snap-section' style={{ width: 60, height: 60, margin: '8px 6px' }}>
                        {typeof card?.file !== 'undefined' && (
                          <>
                            {card?.file?.contentType?.indexOf('image/') === 0 ? (
                              <>
                                {typeof card?.file?.publicUrl !== 'undefined' && (
                                  <BackgroundImageStyle
                                    style={{
                                      backgroundImage: `url(${card?.file?.publicUrl})`,
                                    }}
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                <VideoComponent
                                  video={card?.file}
                                  isCover
                                  containerStyle={{ objectFit: 'cover' }}
                                />
                              </>
                            )}
                          </>
                        )}

                        <DeleteButtonSyle
                          onClick={() => {
                            handleDeleteCard(index);
                          }}
                        >
                          <CloseIcon style={{ width: 20, height: 20 }} />
                        </DeleteButtonSyle>
                      </CardCanvasContainerStyle>

                    ))}
                  </div>

                </CardContainerStyle>

              </>
            )}
          </div>

          <div className="mb-4">
            <TitleForm
              label="Post title"
              model={feedModel?.title}
              onChangeValue={(value: string) => {
                setFeedModel((prev: PostModel) => {
                  return {
                    ...prev,
                    title: value,
                  };
                });
              }}
            />
          </div>
          <div>
            <Editor
              ref={editorRef}
              defaultValue={
                uiStore.universalModal.contentModel?.model?.content || feedModel?.content
              }
              onEditorChange={() => {
                // 브라우저 사이즈 변경할때 모델을 공유하기 위해 공용 모델을 업데이트 한다.
                // Editor는 실시간으로 데이터를 취득하지 않고 저장하는 시점에 꺼내온다.
                uiStore.universalModal.updateModel({
                  model: { ...feedModel, content: editorRef.current.getData() },
                  items: feedItems,
                });
              }}
            />
          </div>
          <div className="py-4">
            <HashEditor
              model={hashs}
              onSelected={(hash: PostTagDto[]) => {
                setFeedModel((prev: PostModel) => {
                  return {
                    ...prev,
                    tags: hash,
                  };
                });
              }}
            />
          </div>
          <div className="mb-4">
            <UrlForm
              label="Link Url"
              model={feedModel?.linkUrl}
              onChangeValue={(value: string) => {
                setFeedModel((prev: PostModel) => {
                  return {
                    ...prev,
                    linkUrl: value,
                  };
                });
              }}
            />
          </div>
        </SaveButtonWrapper>
      </LinkEditorContainerStyle>
    </>
  );
};

const ImageUploaderContainerStyle = styled.div`
  aspect-ratio: 16 / 9;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  > div {
    width: 100%;
  }
`;

const defalutCardCustomProps: CustomContent = {
  titleContents: {
    text: '',
    style: {},
    use: false,
  },
  descriptionContents: {
    text: '',
    use: false,
    style: { fontSize: 16, color: '#000000' },
  },
  isModified: false,
};

const AddImageStyle = styled.div`
  margin-top: 20px;
  left: 20px;
`;

const DeleteButtonSyle = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
`;

const CardContainerStyle = styled.div`
  display: flex;
  padding:10px;
`;

const CardCanvasContainerStyle = styled.div`
  width: 60px;
  /* border-radius: 10px; */
  position: relative;
  aspect-ratio: 1 / 1;
`;

const BackgroundImageStyle = styled.img`
  width: 60px;
  background-position: center center;
  background-size: cover;
  aspect-ratio: 1;
  border-radius: 3px;
`;

const CardEditorContainerStyle = styled.div`
  /* padding: 10px 10px 10px 10px; */
`;
const UseMyChannelStyle = styled.div`
  color: #fff;
  background: rebeccapurple;
  padding: 20px;
  border-radius: 8px;
`;
export default AdPostEditor;
