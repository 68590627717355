import { setTokens } from 'libs/helper/localStorageHelper';
import { getDeviceToken } from 'libs/helper/utils';
import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { AuthController as AuthService } from 'services/AuthController';
import {
  TokenDto,
  ForgotPasswordDto,
  ChangePasswordDto,
  SignUpDto,
  SocialLoginDto,
  UserAgreementDto,
  LoginDto,
  SocialSignUpDto,
} from 'services/data-contracts';
import { HttpClient } from 'services/http-client';
// import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import ServiceStoreBase from './ServiceStoreBase';

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  error?: any;
  statusText?: string;
}

export interface isValid {
  isValid: boolean;
}

class AuthStore {
  api: AuthService<unknown> = new AuthService(new HttpClient());
  googleTokenResponse: any = null;

  constructor() {
    makeAutoObservable(this);
  }
  /**
   * @description 로그인과 회원가입 동시에 사용함.
   * @param accessToken
   * @param temp: 가입된정보가 있는지 확인하는 용도.
   * @returns
   */
  async kakaoLogin(socialToken: string, temp?: boolean) {
    const dto: SocialLoginDto = { socialToken };
    const uuid = getDeviceToken();
    const pushToken = await this.getPushToken();
    const { response } = (await this.api.loginWithKakao(dto, {
      headers: {
        'device-model': `pc`,
        'os-type': 'window',
        'os-version': '1.0',
        'device-token': uuid,
        'push-token': pushToken,
      },
    })) as any;

    if (response.status === 200) {
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      return response;
    } else {
      // if (typeof temp === 'undefined') {
      //   toast(response.data.errorMessage);
      // }
      return response;
    }
  }

  async kakaoSignup(signupModel: SocialSignUpDto, terms: Array<UserAgreementDto>) {
    const uuid = this.getUUID();
    const pushToken = await this.getPushToken(signupModel.channelName);

    signupModel.userAgreements = terms;
    signupModel.channelNickName = signupModel.channelName;
    signupModel.profile = {
      nickname: signupModel.channelName,
      // phoneNumber: "string",
      // gender: "MALE or FEMALE",
      // address: "string",
      // birthday: "yyyy-MM-dd"
    };
    const { response } = (await this.api.signUpWithKakao(signupModel, {
      headers: {
        'device-model': `pc`,
        'os-type': 'window',
        'os-version': '1.0',
        'device-token': uuid,
        'push-token': pushToken,
      },
    })) as any;

    if (response.status === 200) {
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      return true;
    } else {
      // toast(response.data.errorMessage);
      return false;
    }
  }

  async googleLogin(socialToken: string, temp?: boolean) {
    const dto: SocialLoginDto = { socialToken };
    const pushToken = await this.getPushToken();

    const uuid = getDeviceToken();
    const { response } = (await this.api.loginWithGoogle(dto, {
      headers: {
        'device-model': 'pc',
        'os-type': 'window',
        'os-version': '1.0',
        'device-token': uuid,
        'push-token': pushToken,

        // 'push-token': 'push-token',
      },
    })) as any;

    if (response.status === 200) {
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      return response;
    }
    // if (typeof temp === 'undefined') {
    //   toast(response.data.errorMessage);
    // }
    return response;
  }

  async googleSignup(signupModel: SocialSignUpDto, terms: Array<UserAgreementDto>) {
    const uuid = this.getUUID();
    const pushToken = await this.getPushToken(signupModel.channelName);

    signupModel.userAgreements = terms;
    signupModel.channelNickName = signupModel.channelName;
    signupModel.profile = {
      nickname: signupModel.channelName,
      // phoneNumber: "string",
      // gender: "MALE or FEMALE",
      // address: "string",
      // birthday: "yyyy-MM-dd"
    };
    const { response } = (await this.api.signUpWithGoogle(signupModel, {
      headers: {
        'device-model': `pc`,
        'os-type': 'window',
        'os-version': '1.0',
        'device-token': uuid,
        'push-token': pushToken,
      },
    })) as any;

    if (response.status === 200) {
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      return true;
    } else {
      // toast(response.data.errorMessage);
      return false;
    }
  }

  async emailLogin(login: LoginDto) {
    const uuid = getDeviceToken();
    const pushToken = await this.getPushToken(login.username);

    const { response } = (await this.api.login(login, {
      headers: {
        'device-model': 'pc',
        'os-type': 'window',
        'os-version': '1.0',
        'device-token': uuid,
        'push-token': pushToken,

        // 'push-token': 'push-token',
      },
    })) as any;

    localStorage.setItem('accessToken', response.data.accessToken);
    localStorage.setItem('refreshToken', response.data.refreshToken);

    // console.log('response', response.data);
    return response.status === 200;
  }

  async emailSignUp(signupModel: SignUpDto, terms: Array<UserAgreementDto>) {
    const uuid = this.getUUID();
    const pushToken = await this.getPushToken(signupModel.channelName);

    signupModel.userAgreements = terms;
    signupModel.channelType = 'SELLER';
    signupModel.channelNickName = signupModel.channelName;
    signupModel.profile = {
      nickname: signupModel.channelNickName,
      // phoneNumber: "string",
      // gender: "MALE or FEMALE",
      // address: "string",
      // birthday: "yyyy-MM-dd"
    };
    const { response } = (await this.api.signUpWithEmail(signupModel, {
      headers: {
        'device-model': 'pc',
        'os-type': 'window',
        'os-version': '1.0',
        'device-token': uuid,
        'push-token': pushToken,

        // 'push-token': 'push-token',
      },
    })) as any;

    if (response.status !== 200) {
      if (response.status === 500) {
        toast('회원가입중 오류가 발생하였습니다.', { type: 'error' });
      } else {
        toast(response.data.errorMessage, { type: 'error' });
      }
      return false;
    } else {
      return true;
    }
  }

  async checkUsername(username: string) {
    const { response } = (await this.api.checkDuplicateUsername({ username })) as any;
    return response.data;
  }

  *logout() {
    const ls = localStorage.getItem('accessToken');
    const rs = localStorage.getItem('refreshToken');
    if (ls && rs) {
      const tokens: TokenDto = {
        accessToken: ls,
        refreshToken: rs,
      };
      yield this.api.logout(tokens);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('uuid');
      localStorage.removeItem('pushToken');
    }

    // this.clearStorage();
    // window.location.href = '/';
  }

  async refreshToken(token: TokenDto) {
    const { data } = (await this.api.refreshToken(token)) as any;
    return data;
  }

  async join(joinInfo: any) {}

  async resign() {}

  async confirmJoin(token: string) {
    const { data, status, error } = (await this.api.verifyEmail({ token })) as any;
    if (status === 200) {
      setTokens(data);
      window.location.href = '/login';
      return data as TokenDto;
    } else {
      return error;
    }
  }

  /**
   * @description 가입인증 링크 메일 요청
   * @param joinInfo userName, password
   */
  async requestJoinToken(joinInfo: any) {
    // const { data, status } = yield this.api.requestJoinToken(joinInfo);
    // if (status === 200) {
    //   window.location.href = '/email-auth';
    // } else {
    //   this.authError = data;
    // }
  }

  async duplicateUserName(dupUserName: any) {
    const { data } = (await this.api.checkDuplicateUsername({
      username: dupUserName,
    })) as any;
    // userNameIsValid = data;
    return data.isDuplicated;
  }

  async forgotPassword(info: ForgotPasswordDto) {
    const { response } = (await this.api.forgotPassword(info)) as any;
    if (response.status === 200) {
      return true;
    } else {
      return response.data;
    }
  }

  async changePassword(passworInfo: ChangePasswordDto) {
    // const { data, status } = yield this.api.changePassword(passworInfo);
    // if (status === 200) {
    //   window.location.href = '/admin';
    // } else {
    //   this.authError = data;
    // }
  }

  async resetPassword(token: string, passworInfo: ChangePasswordDto) {
    const { response } = (await this.api.resetPassword({ token }, passworInfo)) as any;
    if (response.status === 200) {
      // window.location.href = '/login';
      return true;
    } else {
      return response.data;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  clearStorage() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    // localStorage.removeItem('uuid');
  }

  // eslint-disable-next-line class-methods-use-this
  getUUID() {
    const uuid = localStorage.getItem(`uuid`);
    if (uuid === null) {
      const uuid = uuidv4();
      localStorage.setItem(`uuid`, uuid);
      return uuid;
    }
    return uuid;
  }

  // eslint-disable-next-line class-methods-use-this
  async getPushToken(uniqueId?: string) {
    const token = localStorage.getItem(`pushToken`) || '';
    return token;
  }

  setGoogleTokenResponse(tokenResponse: any) {
    this.googleTokenResponse = tokenResponse;
  }

  getGoogleTokenResponse() {
    return this.googleTokenResponse;
  }
  // return {
  //   resetPassword,
  //   clearStorage,
  //   changePassword,
  //   forgotPassword,
  //   duplicateUserName,
  //   requestJoinToken,
  //   confirmJoin,
  //   resign,
  //   join,
  //   refreshToken,
  //   logout,
  //   checkUsername,
  //   emailSignUp,
  //   emailLogin,
  //   googleSignup,
  //   googleLogin,
  //   kakaoSignup,
  //   kakaoLogin,
  //   setGoogleTokenResponse,
  //   getGoogleTokenResponse,
  // };
}

export default AuthStore;
