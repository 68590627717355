import { makeAutoObservable } from 'mobx';
import { MemberService } from 'services/MemberService';
import { DealListDto, MemberDto, MemberUpdateDto } from 'services/data-contracts';

import _ from 'lodash';
import { HttpClient } from 'services/http-client';
import i18n from 'i18n';
import { toast } from 'react-toastify';

export interface isValid {
  isValid: boolean;
}

class MyMemberStore {
  t = i18n.t;

  constructor() {
    makeAutoObservable(this);
  }

  api: MemberService<unknown> = new MemberService(new HttpClient());

  *getMembers(channelId: number) {
    const { response } = yield this.api.getMembers(channelId);
    return response.status < 300 ? response.data : null;
  }

  *createManager(channelId: number, manager: MemberDto) {
    const { response } = yield this.api.createMember(channelId, manager);
    if (response.status < 300) {
      toast('매니저가 생성되었습니다.', { type: 'success' });
    } else {
      toast.error(response.data.errorMessage);
    }
    return response.status < 300 ? response.data : null;
  }

  *updateManager(channelId: number, managerId: number, manager: MemberUpdateDto) {
    const { response } = yield this.api.updateMember(channelId, managerId as number, {
      ...manager,
      isActive: true,
    });
    if (response.status < 300) {
      toast('매니저 정보가 수정되었습니다.', { type: 'success' });
    } else {
      toast.error(response.data.errorMessage);
    }
    return response.status < 300 ? response.data : null;
  }

  *deleteManager(channelId: number, memberId: number) {
    const { response } = yield this.api.deleteMember(channelId, memberId);
    return response.status < 300 ? response.data : null;
  }

  *assignDeals(channelId: number, memberId: number, dealList: DealListDto) {
    const { response } = yield this.api.assignDeals1(channelId, memberId, dealList);
    if (response.status > 299) {
      toast.error(response.data.errorMessage);
    }
    return response.status < 300 ? response.data : null;
  }

  /**
   * @description 특정 매니저에게 하나의 공구만 할당.
   * @param channelId
   * @param memberId
   * @param dealId
   * @returns
   */
  *assignDeal(channelId: number, memberId: number, dealId: number) {
    const { response } = yield this.api.assignDeal1(channelId, memberId, dealId);
    if (response.status > 299) {
      toast.error(response.data.errorMessage);
      return null;
    }
    return response.data;
  }

  /**
   *@description 특정매니저에게 할당된 공구를 하나만 해제.
   * @param channelId
   * @param memberId
   * @param dealId
   * @returns
   */
  *unassignDeal(channelId: number, memberId: number, dealId: number) {
    const { response } = yield this.api.unassignDeal1(channelId, memberId, dealId);
    if (response.status > 299) {
      toast.error(response.data.errorMessage);
      return null;
    }
    return response.data;
  }

  *getAssignedDeals(channelId: number, memberId: number) {
    const { response } = yield this.api.getDealsByMember1(channelId, memberId);
    return response.status < 300 ? response.data : null;
  }

  *updateManagerPassword() {
    // const { response } = yield this.api.updateMember();
    // return response.status < 300 ? response.data : null;
  }

  unMount() {}
}

export default MyMemberStore;
