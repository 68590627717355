import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import { ChannelConfigModel } from 'types/CommonTypes';
import { Stack } from '@mui/material';

import SaveButton from 'features/adminFeatures/admin/components/SaveButton';
import SettingItemLanding from '../components/SettingItemLanding';
// import SettingItemChannelMain from '../components/SettingItemChannelMain';
import SettingItemProfileTemplateType from '../components/SettingItemProfileTemplateType';
import SettingItemChannelTalk from '../components/SettingItemChannelTalk';
import SettingItemHideChannel from '../components/SettingItemHideChannel';

/**
 * @description 모든 링크를 생성하기 위한 컨테이너임
 */
const SettingsContainer = observer(() => {
  const { myChannelConfigStore, myChannelStore } = useStore();

  const [configModel, setConfigModel] = useState<ChannelConfigModel>();

  const handleSaveSettings = () => {
    if (configModel) {
      myChannelConfigStore.saveSettings(myChannelStore.currentChannel?.id as number, configModel);
    }
  };

  useEffect(() => {
    if (typeof myChannelConfigStore.channelConfig !== 'undefined') {
      if (myChannelConfigStore.channelConfig === null) {
        setConfigModel((prev: ChannelConfigModel) => {
          return { ...prev, mainScreen: 'Feed', metadataObject: { profileType: 'simple' } };
        });
      } else {
        setConfigModel(myChannelConfigStore.channelConfig);
      }
    }
  }, [myChannelConfigStore.channelConfig]);

  useEffect(() => {
    myChannelConfigStore.getItems([myChannelStore.currentChannel?.id as number]);
  }, []);

  return (
    <Stack style={{ margin: '0', padding: 20 }}>
      <div>
        {/* <SettingItemLanding model={[configModel, setConfigModel]} /> */}
        {/* <SettingItemChannelMain model={[configModel, setConfigModel]} /> */}
        {/* <SettingItemProfileTemplateType model={[configModel, setConfigModel]} /> */}
        <SettingItemChannelTalk model={[configModel, setConfigModel]} />
        <SettingItemHideChannel model={[configModel, setConfigModel]} />
      </div>
      <SaveButton
        onClick={() => {
          handleSaveSettings();
        }}
      />
    </Stack>
  );
});

export default SettingsContainer;
