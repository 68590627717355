import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import AdManagementContainer from 'features/adminFeatures/admin/containers/AdManagementContainer';
import MobileModalHeader from 'components/commons/MobileModalHeader';
import UniversalLayout from 'components/commons/layouts/UniversalLayout';
import { useSearchParams } from 'react-router-dom';
import DetailPageHeader from 'components/commons/DetailPageHeader';

const SettingsMyAdPage = observer(() => {
  const [searchParam] = useSearchParams();
  const [returnUrl, setReturnUrl] = useState<string>();

  useEffect(() => {
    const cpcode = searchParam.get('cpcode');
    if (cpcode === '0061') {
      setReturnUrl('/');
    }
  }, []);
  return (
    <UniversalLayout>
      {/* <DetailPageHeader title="내 광고관리" gobackUrl={returnUrl} /> */}
      <AdManagementContainer />
    </UniversalLayout>
  );
});

export default SettingsMyAdPage;
