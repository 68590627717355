import React, { useEffect, useState, useRef } from 'react';
import { FileDto } from 'services/data-contracts';
import { CropType, CustomFile } from 'types/CommonTypes';
import { useStore } from 'stores/StoreHelper';
import ImageCropper from 'features/adminFeatures/commons/components/ImageCropper';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { convertBase64Files2 } from './commonLogin';

import CropperImage from './CropperImage';
import SingleImage from './SingleImage';

/**
 * @description 이미지 선택부터 크롭까지 제공한다.
 * @params type : copper, single, multiple
 * @returns
 */
const ProfileImageUploader = ({
  children,
  useCircularCrop,
  initialValue,
  type,
  useCrop = true,
  attachType,
  modalType = 'bottomSheet',
  onSelected,
}: {
  children?: React.ReactNode | React.ReactNode[];
  useCircularCrop?: boolean;
  useCrop?: boolean;
  initialValue?: CustomFile | CustomFile[];
  type: 'copper' | 'single' | 'multiple';
  origin?: 'card' | 'video' | undefined;
  attachType?: 'video' | 'image' | 'media';
  currentImage?: FileDto;
  currentColor?: string;
  show?: boolean;
  cropType?: CropType;
  /**
   * @deprecated
   */
  useColor?: boolean;
  cropSize?: { width: number; height: number };
  multipleUpload?: boolean;
  modalType?: 'modal' | 'bottomSheet';
  onSelected: (image: CustomFile | CustomFile[] | undefined) => void;
}) => {
  const { uiStore } = useStore();
  const onChangeFile = useRef<any>(null);

  const files = useRef<any>(null);
  const [accept, setAccept] = useState<string>('');
  const [images, setImages] = useState<CustomFile | CustomFile[]>();

  const getHeaderComponent = () => {
    switch (type) {
      case 'copper':
        return (
          <CropperImage
            image={images as CustomFile}
            onClick={(e: any) => {
              onChangeFile.current.click(e);
            }}
            onDelete={(e: any) => {
              onSelected(undefined);
            }}
          />
        );
      case 'single':
        return (
          <SingleImage
            image={images as CustomFile}
            onClick={(e: any) => {
              onChangeFile.current.click(e);
            }}
            onDelete={(e: any) => {
              onSelected(undefined);
            }}
          />
        );
      default:
        break;
    }
  };

  const handleSetImage = (files: CustomFile) => {
    if (modalType === 'bottomSheet') {
      uiStore.bottomSheet.show({
        expandOnContentDrag: false,
        children: (
          <ImageCropper
            useCircularCrop={useCircularCrop}
            onCancel={() => {
              uiStore.bottomSheet.close();
            }}
            onCropComplete={(c: CustomFile) => {
              setImages(c);
              onSelected(c);
              uiStore.bottomSheet.close();
            }}
            image={files}
          />
        ),
      });
    } else {
      uiStore.modal.show({
        // style: { maxHeight: '60%' },
        modalType: 'modal',
        children: (
          <ImageCropper
            useCircularCrop={useCircularCrop}
            onCancel={() => {
              // onSelected(undefined);
              uiStore.modal.close();
            }}
            onCropComplete={(c: CustomFile) => {
              setImages(c);
              onSelected(c);
              uiStore.modal.close();
            }}
            image={files}
          />
        ),
      });
    }
  };

  useEffect(() => {
    switch (attachType) {
      case 'image':
        setAccept('.jpg, .jpeg, .png, .gif');
        break;
      case 'video':
        setAccept('.mp4');
        break;
      case 'media':
        setAccept('.jpg, .jpeg, .png, .gif, .mp4');
        break;
      default:
        setAccept('');
        break;
    }
  }, [attachType]);

  useEffect(() => {
    if (initialValue) {
      setImages(initialValue);
    }
  }, [initialValue]);

  return (
    <>
      <div
        style={{ position: 'relative' }}
        // onClick={(e: any) => {
        //   onChangeFile.current.click(e);
        // }}
      >
        {children ? (
          <>{children}</>
        ) : (
          <>
            <SingleImage
              image={images as CustomFile}
              onClick={(e: any) => {
                onChangeFile.current.click(e);
              }}
              onDelete={(e: any) => {
                onSelected(undefined);
              }}
            />
          </>
        )}
      </div>
      <input
        type="file"
        multiple={type === 'multiple'}
        accept={accept}
        id="file"
        ref={onChangeFile}
        onInput={async () => {
          files.current = onChangeFile.current.files;
          const converted = (await convertBase64Files2(files.current)) as unknown as CustomFile[];
          if (useCrop) {
            handleSetImage(converted[0]);
          } else {
            const c = type === 'multiple' ? converted : converted[0];
            setImages(c);
            onSelected(c);
            uiStore.bottomSheet.close();
          }
        }}
        style={{ display: 'none' }}
      />
    </>
  );
};

const IconStyle = styled.div`
  width: 20px;
  height: 20px;
  background: #000;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  margin-top: -65px;
  border-radius: 40px;
`;

export default ProfileImageUploader;
