import React, { CSSProperties, useEffect, useState } from 'react';

const Text = ({
  children,
  style,
  type,
  buttonRole,
}: {
  children: any;
  style?: CSSProperties;
  type?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5';
  buttonRole?: 'DESCRIPTION';
}) => {
  const [textStyle, setTextStyle] = useState<CSSProperties>({});

  useEffect(() => {
    let tempStyle: CSSProperties;
    switch (type) {
      case 'H1':
        tempStyle = { fontSize: '40px', fontWeight: 'bold' };
        break;
      case 'H2':
        tempStyle = { fontSize: '25px', fontWeight: 'bold' };
        break;
      case 'H3':
        tempStyle = { fontSize: '20px', fontWeight: 'bold' };
        break;
      case 'H4':
        tempStyle = { fontSize: '1rem', fontWeight: 'bold' };
        break;
      case 'H5':
        tempStyle = { fontSize: '0.8rem' };
        break;
      default:
        tempStyle = { fontSize: '1rem' };
        break;
    }

    if (buttonRole === 'DESCRIPTION') {
      tempStyle = { ...tempStyle, color: '#9d9d9d', fontWeight: 400 };
    }

    setTextStyle(tempStyle);
  }, []);
  return <div style={{ ...textStyle, ...style }}>{children}</div>;
};

export default Text;
