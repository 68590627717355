import React from 'react';
import { Row } from 'components/commons/layouts';
import { cutoffString } from 'libs/helper/utils';
import { SimpleDealDto } from 'services/data-contracts';
import CommonThumbnail from 'features/management/components/commonControl/CommonThumbnail';

/** @description 고객별 문의 쓰레드 */
interface Props {
  item?: SimpleDealDto;
  cutOff?: number;
  onClick?: () => void;
}

const SimpleDealInfo: React.FC<Props> = ({ item, cutOff = 25, onClick }) => {
  return (
    <div style={{ padding: '20px 10px', borderBottom: '1px solid #efefef', width: '100%' }}>
      <Row style={{ width: 'unset', justifyContent: 'space-between' }}>
        <Row style={{ justifyContent: 'flex-start' }}>
          <div style={{}}>
            <CommonThumbnail initialValue={item?.thumbnail?.publicUrl} />
          </div>
          <Row style={{ width: 'unset', marginLeft: 10 }}>
            <Row
              style={{
                justifyContent: 'flex-start',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <div style={{ fontSize: 16, fontWeight: 600 }}>
                {item?.sellerChannel?.channelName}
              </div>
              <div className="item-title" onClick={onClick}>
                {cutoffString(item?.title, cutOff)}
              </div>
            </Row>
          </Row>
        </Row>
      </Row>
    </div>
  );
};

export default SimpleDealInfo;
