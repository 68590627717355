import React from 'react';
import Common from './Common';
import { MembershipPlanDto } from 'services/data-contracts';

const BRAND_PREMIUM = ({ plan }: { plan: MembershipPlanDto }) => {
  const texts = ['브랜드 PRO 서비스 기본 제공', 'CS 팀 서비스 제공 - 매니저 20명까지 운영'];
  const description =
    '공구 전문가의 리뷰와 \n소수정예 세미나 최우선 참여권 \n많은 셀러와 공구를 하는 \n에이전시를 위한 솔루션 ';

  return (
    <>
      <Common texts={texts} plan={plan} description={description} />
    </>
  );
};

export default BRAND_PREMIUM;
