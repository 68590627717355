import React, { useEffect, useState } from 'react';
import { ISpacerBlock } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';
import { Popover, IconButton, Slider } from '@mui/material';

import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import CheckIcon from '@mui/icons-material/Check';
import MUISlider from 'components/commons/buttons/MUISlider';
import styled from 'styled-components';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import BlockController from 'controllers/BlockController';

const SpacerTemplate = ({
  item,
  editMode,
  isPreviewMode,
}: {
  item?: ISpacerBlock;
  editMode?: boolean;
  isPreviewMode?: boolean;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [height, setHeight] = useState<number>(5);

  const { initialModel, updateServiceModel, serviceModel, initialBinding, unmountModel } =
    useModelManagement<ISpacerBlock>();

  const { updateBlock } = BlockController<ISpacerBlock>();

  const handleClickApply = () => {
    if (item) {
      setAnchorEl(null);
      if (serviceModel) {
        updateBlock(serviceModel);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickSpacer = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (item) {
      setHeight(item.height ?? 20);
    }
  }, [item]);

  useEffect(() => {
    if (isPreviewMode === true && item) {
      initialBinding(item);
    }
  }, [isPreviewMode, item]);

  return (
    <>
      {editMode === true ? (
        <div style={{ padding: '0 20px' }}>
          <Row style={{ marginLeft: 10, height: 60 }}>
            <MUISlider value={height} useThumb={false} />
            <div style={{ width: 80, marginLeft: 20 }}>{height?.toString() || '20'}px</div>
          </Row>
        </div>
      ) : (
        <>
          {isPreviewMode ? (
            <>
              <div style={{ position: 'absolute', left: 0, right: 0, textAlign: 'center' }}>
                <ButtonStyle onClick={handleClickSpacer} style={{ height: height }}>
                  <IconButton style={{ background: '#fff' }}>
                    <VerticalAlignCenterIcon />
                  </IconButton>
                </ButtonStyle>
              </div>
              <div style={{ height: height }} />
            </>
          ) : (
            <div style={{ height: height }} />
          )}
        </>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ marginLeft: 30 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: '10px 0', margin: '10px 0', textAlign: 'center' }}>
          <Slider
            sx={{
              '& input[type="range"]': {
                WebkitAppearance: 'slider-vertical',
              },
            }}
            onChange={(e: any, value: number) => {
              setHeight(value);
              updateServiceModel('height', value);
            }}
            style={{ height: 100 }}
            orientation="vertical"
            defaultValue={item?.height ?? 20}
            min={5}
            max={100}
            aria-label="Temperature"
            valueLabelDisplay="auto"
            // onKeyDown={preventHorizontalKeyboardNavigation}
          />
        </div>
        <IconButton onClick={handleClickApply}>
          <CheckIcon />
        </IconButton>
      </Popover>
    </>
  );
};

const ButtonStyle = styled.div`
  /* position: absolute; */
  /* z-index: 10;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center; */
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
`;
export default SpacerTemplate;
