import SearchBox from 'components/commons/SearchBox';
// eslint-disable-next-line max-len
import CSTownChecklistController from 'features/csTown/pages/pageControllers/CSTownChecklistController';
import ChecklistContainer from 'features/myPage/containers/checklist/ChecklistContainer';
import { useStateContext } from 'libs/hooks/usePageState';
import React from 'react';
import { OhmossButton } from 'resources/controls/OhmossButton';
import styled from 'styled-components';
import { CSTownChecklistState } from 'types/CommonTypes';
import { BottomButtonStyle } from './ButtonContainer';

const CSTownChecklistContainer = () => {
  const { stateModel } = useStateContext<CSTownChecklistState>();
  const { createCheckList, clickItem, search } = CSTownChecklistController();

  return (
    <div>
      {/* <SearchBox onEntered={search} onCancel={() => search('')} /> */}
      <div style={{ paddingBottom: 120 }}>
        <ChecklistContainer
          checklist={stateModel?.checklists}
          onClickItem={(item: any) => {
            clickItem(item);
          }}
        />
      </div>
      <BottomButtonStyle>
        <OhmossButton style={{ marginTop: 10 }} onClick={createCheckList} type="primaryPc">
          체크리스트 만들기
        </OhmossButton>
      </BottomButtonStyle>
    </div>
  );
};

export default CSTownChecklistContainer;
