import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import useNavigation from 'hooks/useNavigation';

import { MenuBoxItemStyle, MenuBoxStyle } from 'features/csTown/commonComponents/styles';
import { Row } from 'components/commons/layouts';
import { useRootContext } from 'libs/hooks/useRootContext';
import { Divider } from 'features/myPage/components/CommonStyleComponent';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { MenuItem } from 'definitions/menuItems';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Text from 'components/commons/Text';
import { useStateContext } from 'libs/hooks/usePageState';

const MyLNBCommon = ({ items, style }: { items: MenuItem[]; style?: CSSProperties }) => {
  const { navigate } = useNavigation();
  const { myChannel } = useRootContext();
  const { setPageHeaderTitle } = useStateContext();

  const [rootMenu, setRootMenu] = useState<'my' | 'management'>();
  const [currentMenu, setCurrentMenu] = useState<string | undefined>('');

  const handleClickMyChannel = () => {
    navigate(`/${myChannel?.channelName}`);
  };

  const MenuIcon = ({ item, style }: { item: MenuItem; style?: CSSProperties }) => {
    if (item?.Icon) {
      const Icon = item.Icon;
      return <Icon style={{ transition: 'all 1.4s ease-out', ...style }} />;
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    if (window.location.pathname) {
      const t = window.location.pathname.split('/');
      setRootMenu(t[1] as 'my' | 'management');

      if (t.length > 2) {
        setCurrentMenu(t[t.length - 1]);
      } else {
        setPageHeaderTitle('My page');
        setCurrentMenu(undefined);
      }
    }
  }, [window.location.pathname]);

  return (
    <MenuContainerStyle>
      <Row
        direction="column"
        style={{
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'flex-start',
          paddingBottom: 0,
          paddingTop: 70,
          ...style,
        }}
      >
        <div style={{ width: '100%' }}>
          {items.map((item) => (
            <>
              {item.isGroup ? (
                <>
                  <Text type="H3">{item?.title}</Text>
                  <Divider />
                </>
              ) : (
                <>
                  <MenuBoxStyleWrapper>
                    <MemuBoxItemStyleWrapper
                      selected={currentMenu === item.name}
                      onClick={() => {
                        setPageHeaderTitle(item.title as string);
                        navigate(item?.path as string);
                        setPageHeaderTitle('CSTOWN');
                      }}
                    >
                      <IconButton
                        style={{
                          background: currentMenu === item.name ? '#173300' : '',
                          marginRight: 10,
                        }}
                      >
                        <MenuIcon
                          item={item}
                          style={{ color: currentMenu === item.name ? '#fff' : 'unset' }}
                        />
                      </IconButton>
                      {item.title}
                    </MemuBoxItemStyleWrapper>
                  </MenuBoxStyleWrapper>
                </>
              )}
            </>
          ))}
        </div>

        <div style={{ width: '100%', textAlign: 'center', padding: 20 }}>
          <div style={{ width: '100%', marginBottom: 10 }}>
            {myChannel?.cstownUser === true && (
              <MenuBoxStyleWrapper
                style={{
                  justifyContent: 'center',
                  background: 'fff',
                  borderRadius: 10,
                  margin: 0,
                  marginTop: 50,
                  marginBottom: 10,
                }}
              >
                <CSTownButton
                  onClick={() => {
                    navigate('/cstown');
                  }}
                >
                  <img src="/images/cstown_moss.png" style={{ width: 100 }} />
                  <div>
                    <div style={{ margin: 'auto' }}>
                      <Text
                        type="H3"
                        style={{
                          fontStyle: 'italic',
                        }}
                      >
                        CSTOWN
                      </Text>
                      <OhmossButton type="primaryPc" style={{ padding: '1px 10px' }}>
                        고객관리 바로가기
                      </OhmossButton>
                    </div>
                  </div>
                </CSTownButton>
              </MenuBoxStyleWrapper>
            )}
            {rootMenu === 'my' ? (
              <OhmossButton
                type="primaryPc"
                onClick={() => {
                  navigate(`/management`);
                }}
              >
                <Row style={{ marginRight: 20 }}>
                  <IconButton>
                    <EditIcon style={{ color: 'yellow' }} />
                  </IconButton>
                  <div>내 링크편집</div>
                </Row>
              </OhmossButton>
            ) : (
              <OhmossButton
                type="square"
                onClick={() => {
                  navigate(`/my`);
                }}
              >
                <Row style={{ marginRight: 20 }}>
                  <IconButton>
                    <KeyboardBackspaceOutlinedIcon />
                  </IconButton>
                  <div>편집 종료</div>
                </Row>
              </OhmossButton>
            )}
          </div>
          <OhmossButton type="square" style={{ width: '100%' }} onClick={handleClickMyChannel}>
            내 링크 방문
          </OhmossButton>
        </div>
      </Row>
    </MenuContainerStyle>
  );
};

const MenuContainerStyle = styled.div`
  /* background: #fff; */
  width: 100%;
  height: 100vh;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  > div {
    /* padding: 20px; */
    padding-bottom: 0px;

    flex-grow: unset !important;
  }
`;

const MemuBoxItemStyleWrapper = styled(MenuBoxItemStyle)`
  margin-left: 10px;
  background: ${(props) => (props.selected ? '#fff' : 'none')};
  /* border-left: ${(props) => (props.selected ? '3px solid #df3f00' : 'none')}; */
  color: #000;
  box-shadow: none;
  /* box-shadow: ${(props) => (props.selected ? '0px 0px 5px rgba(0, 0, 0, 0.12)' : 'none')};
   */
  border: ${(props) => (props.selected ? '2px solid #cbcbcb' : '2px solid transparent')};
  padding-left: 10px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-radius: 30px;
  transition: all 0.4s ease-out;
`;

const MenuBoxStyleWrapper = styled(MenuBoxStyle)`
  padding: 0px !important;
`;

const CSTownButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.4s ease-out;
  border: 1px solid var(--primary-color);
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.55); */
  background: #fff;
  &:hover {
    background: var(--primary-color);
    color: #fff;
  }
`;

export default MyLNBCommon;
