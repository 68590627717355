import React, { useEffect, useState } from 'react';

import { ChannelDto, PartnerDto, SimpleDealDto } from 'services/data-contracts';

import { ListBoxItemTemplate, MenuIconBoxStyle } from 'features/csTown/commonComponents/styles';
import { useStore } from 'stores/StoreHelper';
import AddIcon from '@mui/icons-material/Add';

import { IconButton } from '@mui/material';
import PartnerController from 'controllers/PartnerController';
import CSPartnerList from 'features/csTown/commonComponents/CSPartnerList';
import Text from 'components/commons/Text';
import { useStateContext } from 'libs/hooks/usePageState';
import chatController from 'features/community/controllers/chatController';
import SimpleCSPartnerList from '../SimpleCSPartnerList';

const CSPartners = ({ deal }: { deal?: SimpleDealDto }) => {
  const { uiStore } = useStore();
  const { getPartners, assignDeal } = PartnerController();
  const { receiver } = useStateContext();
  const { inviteOrDeleteUser } = chatController();

  const [partners, setPartners] = useState<ChannelDto[]>();

  const handleClickPartner = async (partner: PartnerDto) => {
    if (deal) {
      const result = (await assignDeal(partner, deal)) as unknown as string;
      if (result) {
        inviteOrDeleteUser(
          [{ ...deal, assigned: true }],
          [partner?.partnerChannel?.channelName as string],
        );
      }

      uiStore.modal.close();
    }
  };

  const getPartnerList = async () => {
    const partnerList = await getPartners(deal?.id);
    // const channels = partnerList.map((partner) => partner.partnerChannel);
    // if (channels) {
    setPartners(partnerList as PartnerDto[]);
    // }
  };

  const handleAddPartner = async () => {
    // const channels: PartnerDto[] = partners?.map((partner) => partner.partnerChannel);

    uiStore.drawer.show({
      children: (
        <CSPartnerList
          recentPartners={partners}
          onSelect={async (partner: PartnerDto) => {
            await handleClickPartner(partner);
            getPartnerList();
          }}
          type="ADD_ITEM"
        />
      ),
    });
  };

  useEffect(() => {
    if (receiver?.id === 'reload' && receiver?.param?.id === 'partnerReload') {
      getPartnerList();
    }
  }, [receiver]);

  useEffect(() => {
    if (deal) {
      getPartnerList();
    }
  }, []);

  return (
    <div style={{}}>
      <ListBoxItemTemplate>
        <MenuIconBoxStyle style={{ justifyContent: 'space-between' }}>
          <Text type="H3">CS Partners</Text>
          <IconButton onClick={handleAddPartner} style={{ background: '#ebebeb' }}>
            <AddIcon style={{ width: 25, height: 25 }} />
          </IconButton>
        </MenuIconBoxStyle>
      </ListBoxItemTemplate>
      <div style={{ flex: 0.5 }}>
        <>
          <SimpleCSPartnerList partners={partners} />
        </>
      </div>
    </div>
  );
};

export default CSPartners;
