import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ChannelBoardDto } from 'services/data-contracts';
import { Row } from 'components/commons/layouts';
import useNavigation from 'hooks/useNavigation';
import { useStore } from 'stores/StoreHelper';
import { isMobile } from 'react-device-detect';

import TextLabel from 'components/commons/labels/TextLabel';
import MyBoardStore from 'stores/MyBoardStore';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import MyShopFormContainer from 'features/management/containers/MyShopFormContainer';
import { IChannelBoardModel } from 'types/BlockExtendsTypes';
import CommonThumbnail from '../commonControl/CommonThumbnail';

const MyShopItem = ({
  item,
  onClickMore,
  fetchData,
  editMode,
}: {
  item: ChannelBoardDto;
  fetchData: () => void;
  onClickMore: () => void;
  editMode: boolean;
}) => {
  const { navigate } = useNavigation();

  const { uiStore } = useStore();
  const [newStyle, setNewStyle] = useState<IChannelBoardModel>();

  const handleClickNewCategory = () => {
    uiStore.bottomSheet.show({
      topPosition: isMobile ? 40 : undefined,
      title: item ? '페이지 수정' : '새 페이지 만들기',
      children: <MyShopFormContainer item={item} />,
      onConfirmed: () => {
        fetchData();
      },
    });
  };

  const handleClickCategory = () => {
    if (editMode) {
      navigate(`/management/myshop/${item.id}`);
    }
  };

  useEffect(() => {
    if (typeof item !== 'undefined') {
      if (typeof item.metadata !== 'undefined') {
        const parsedStyle = JSON.parse(item.metadata);
        setNewStyle(parsedStyle);
      }
    }
  }, [item?.metadata]);

  return (
    <ContainerStyle
      style={{
        borderRadius: '8px',
      }}
    >
      <Row style={{ width: '100%' }}>
        <div
          style={{ marginLeft: 10, width: '100%', cursor: 'pointer' }}
          // onClick={handleClickCategory}
        >
          <Row style={{ width: '100%', justifyContent: 'flex-start' }}>
            <CommonThumbnail
              parent={40}
              initialValue={item.thumbnail?.publicUrl ?? '/images/ohmossc.png'}
            />
            <div style={{ marginLeft: 20 }}>
              <TextLabel type="subject" style={{ fontSize: 16, fontWeight: 500 }}>
                {item.name}
              </TextLabel>
              <div style={{ fontSize: 12, fontWeight: 500, color: '#666' }}>
                {item?.postCount}개의 게시물
              </div>
            </div>
          </Row>
        </div>
        {editMode === true ? (
          <>
            <IconContainer onClick={handleClickCategory}>
              <img
                src="/images/management/pencil.svg"
                alt="pencil"
                style={{ width: 32, cursor: 'pointer' }}
              />
            </IconContainer>
            <IconContainer onClick={handleClickNewCategory}>
              <img
                src="/images/management/more.svg"
                alt="pencil"
                style={{ width: 32, cursor: 'pointer' }}
              />
            </IconContainer>
          </>
        ) : (
          <></>
        )}
      </Row>
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  display: flex;
  background: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* height: 100px; */
  padding: 20px 0;
  align-items: center;
  @media ${(props) => props.theme.media.mobile} {
    flex-direction: column;
    padding: 18px;
  }
`;

const IconContainer = styled.div`
  padding: 0 1rem;
`;

export default MyShopItem;
