import React, { useEffect, useState } from 'react';

import PreviewContainer from '../containers/PreviewContainer';
import LinkServiceProvider from 'features/linkService/context/LinkServiceProvider';

const PreviewPage = () => {
  useEffect(() => {}, []);

  return (
    <>
      <LinkServiceProvider>
        <PreviewContainer />
      </LinkServiceProvider>
    </>
  );
};

export default PreviewPage;
