import React from 'react';
import styled from 'styled-components';

const KMFormater = ({ num }: { num: number }) => {
  const convert = () => {
    const absNum = Math.abs(num);

    if (absNum >= 1000000) {
      return (
        <FormatterContainerStyle>
          <div>{(Math.sign(num) * (absNum / 1000000)).toFixed(1)}</div>
          <FormaterStyle>K</FormaterStyle>
        </FormatterContainerStyle>
      );
    } else if (absNum >= 1000) {
      return (
        <FormatterContainerStyle>
          <div>{(Math.sign(num) * (absNum / 1000)).toFixed(1)} </div>
          <FormaterStyle>K</FormaterStyle>
        </FormatterContainerStyle>
      );
    } else {
      return (
        <FormatterContainerStyle>
          <div>{num.toString()}</div>
        </FormatterContainerStyle>
      );
    }
  };
  return <>{convert()}</>;
};

const FormaterStyle = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 20px;
`;

const FormatterContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  > div:first-child {
    font-size: 1.3rem;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 700;
  }
`;

export default KMFormater;
