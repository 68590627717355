import { makeObservable, observable, flow, action } from 'mobx';
import { AdminService } from 'services/AdminService';
import { AdDto, CoinEventDto } from 'services/data-contracts';

import _ from 'lodash';
import { HttpClient } from 'services/http-client';
import i18n from 'i18n';
import ServiceStoreBase from './ServiceStoreBase';

export interface isValid {
  isValid: boolean;
}

class AdminServiceStore extends ServiceStoreBase {
  t = i18n.t;

  coinHistories: CoinEventDto[];

  constructor() {
    super();
    makeObservable(this, {
      getConinHistory: flow,
      coinHistories: observable,
    });
  }

  api: AdminService<unknown> = new AdminService(new HttpClient());

  *getConinHistory(channelId: number, query?: any) {
    const { response } = yield this.api.getCoinHistory(channelId, query);
    if (response.status === 200) {
      this.coinHistories = response.data;

      return response.data;
    } else {
      this.coinHistories = [];
      return [];
    }
  }
}

export default AdminServiceStore;
