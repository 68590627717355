import React from 'react';
import styled from 'styled-components';
import useNavigation from 'hooks/useNavigation';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { useGoogleLogin } from '@react-oauth/google';
import { ROOT_HOST, KAKAO_REST_API_KEY, KAKAO_CLIENT_SECRET_KEY } from 'stores/AppStore';
import { Row } from 'components/commons/layouts';
import { LoginStyle, SNSTypeStyle, LogoImageStyle } from 'resources/LoginPageStyle';

import ATagStyle from 'features/mainFeatures/auth/components/ATagStyle';
import DraftsIcon from '@mui/icons-material/Drafts';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import DetailPageHeader from 'components/commons/DetailPageHeader';

const SignUpSelectorPage = observer(() => {
  const { t } = useTranslation();
  const { authStore } = useStore();
  const { navigate } = useNavigation();

  // kakao redirect
  const redirectUri = `${ROOT_HOST}signup-form/k9934`;

  const handleClickKakaoJoin = async () => {
    // eslint-disable-next-line max-len
    const payload = `?grant_type=authorization_code&client_id=${KAKAO_REST_API_KEY}&redirect_uri=${redirectUri}&client_secret=${KAKAO_CLIENT_SECRET_KEY}&response_type=code`;
    window.location.href = `https://kauth.kakao.com/oauth/authorize${payload}`;
  };

  const handleClickGoogleSignup = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await authStore.setGoogleTokenResponse(tokenResponse);
      navigate('/signup-form/g7931');
    },
    onError: (errorResponse) => alert(errorResponse),
  });

  return (
    <>
      <DetailPageHeader title="회원가입" />
      <ManagementLayout style={{ justifyContent: 'flex-start' }}>
        {/* <SignUpTitleStyle>{t('A106')}</SignUpTitleStyle> */}

        <img
          src="/images/ohmoss_logo.svg"
          style={{
            width: '140px',
            margin: '20px 0 30px 0',
            alignSelf: 'center',
          }}
          alt=""
        />
        <div
          style={{
            textAlign: 'center',
            margin: '0px 20px 30px 20px',
            whiteSpace: 'break-spaces',
            fontWeight: 300,
          }}
        >
          {t('A107')}
        </div>
        <SNSTypeStyle
          onClick={() => {
            handleClickKakaoJoin();
          }}
        >
          <LogoImageStyle src="/images/kakao_logo.png" />
          <div style={{}}>Kakao</div>
        </SNSTypeStyle>
        <SNSTypeStyle
          onClick={() => {
            handleClickGoogleSignup();
          }}
        >
          <LogoImageStyle src="/images/google_logo.svg" />

          <div style={{}}>Google</div>
        </SNSTypeStyle>

        <div style={{ margin: '30px 20px', textAlign: 'center', fontWeight: 300 }}>{t('A108')}</div>
        <SNSTypeStyle
          onClick={() => {
            navigate('/signup-form/a7934');
          }}
        >
          <Row>
            <IconStyle>
              <DraftsIcon />
            </IconStyle>
            <div style={{}}>email</div>
          </Row>
        </SNSTypeStyle>
        <LoginStyle style={{ marginTop: 20 }}>
          {t('A110')}
          <div style={{ marginLeft: 20 }}>
            <ATagStyle color={'#3e64ea'} href="/login">
              {t('A109')}
            </ATagStyle>
          </div>
        </LoginStyle>
      </ManagementLayout>
    </>
  );
});

const IconStyle = styled.div`
  position: absolute;
  left: 20px;
  width: 20px;
`;
export default SignUpSelectorPage;
