import React from 'react';
import MenuItem from 'components/commons/menuItem/MenuItem';
import MenuItemBox from 'components/commons/menuItem/MenuItemBox';
import TextLabel from 'components/commons/labels/TextLabel';
import useNavigation from 'hooks/useNavigation';

const NotLoginMenus = () => {
  const { navigate } = useNavigation();

  return (
    <>
      <MenuItemBox title="회원">
        <MenuItem
          title="회원가입"
          onClick={() => {
            navigate('/sign-up');
          }}
        />
        <MenuItem
          title="로그인"
          onClick={() => {
            navigate('/login');
          }}
        />
      </MenuItemBox>
      <MenuItemBox title="서비스 정보">
        <MenuItem
          title="서비스 이용 약관"
          onClick={() => {
            navigate('/deals/userterms');
          }}
        />
        <MenuItem
          title="개인정보 수집 및 취급 동의"
          onClick={() => {
            navigate('/deals/privacypolicy');
          }}
        />
      </MenuItemBox>
      <MenuItemBox title="기타">
        <MenuItem
          title="앱 버전"
          onClick={() => {
            // navigate('/settings/myad');
          }}
          menuType="contents"
        >
          <TextLabel type="content">{process.env.VERSION}</TextLabel>
        </MenuItem>
        <MenuItem
          title="고객센터"
          onClick={() => {
            // navigate('/settings/myad');
          }}
        >
          <TextLabel type="content">
            <a href="mailto:cs@nbhd.kr">cs@nbhd.kr</a>
          </TextLabel>
        </MenuItem>
      </MenuItemBox>
    </>
  );
};

export default NotLoginMenus;
