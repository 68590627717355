import React, { useState, ReactNode } from 'react';
import { Context, PropsTypes } from 'libs/hooks/usePageState';
import { ReceiverType } from 'types/CommonTypes';

interface PageProviderProps {
  children: ReactNode | ReactNode[];
}

/**
 * @description 페이지 상태를 관리하는 Provider
 * @param param0
 * @returns
 */
const PageStateProvider = <T extends PropsTypes<T> | null | undefined>({
  children,
}: PageProviderProps) => {
  const [stateModel, setStateModel] = useState<T>();
  const [subMenus, setSubMenus] = useState<ReactNode | string>();
  const [pageTitle, setHeaderTitle] = useState<string>('');
  const [receiver, setReceiver] = useState<ReceiverType>();

  function setPageState<K extends keyof T>(key: K, value: T[K]): void {
    setStateModel((prev: T) => {
      return { ...prev, [key]: value };
    });
  }

  function setMultipleState(updates: Partial<T>): void {
    setStateModel((prev: T | undefined) => {
      let result: T | undefined | null;

      if (!prev) {
        result = updates as T; // 만약 prev가 없으면 updates를 반환
      } else {
        result = { ...prev, ...updates };
      }

      return result;
    });
  }

  function setPageHeaderTitle(title: string): void {
    setHeaderTitle(title);
  }

  function broadcast(message: ReceiverType) {
    setReceiver(message);
    setTimeout(() => {
      setReceiver(undefined);
    }, 0);
  }

  return (
    <Context.Provider
      value={{
        subMenus,
        setSubMenus,
        stateModel,
        setPageState,
        pageTitle,
        setPageHeaderTitle,
        broadcast,
        receiver,
        setMultipleState,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default PageStateProvider;
