import React from 'react';
import { MemberDto, PartnerDto } from 'services/data-contracts';
import styled from 'styled-components';
import { Avatar } from '@mui/material';
import { Row } from 'components/commons/layouts';
import { BoxStyle, ListItemTemplate } from 'features/csTown/commonComponents/styles';
import DeleteIcon from 'features/csTown/commonComponents/DeleteIcon';

const CSPartnerListItem = ({
  partner,
  selected,
  onSelect,
  onDelete,
}: {
  partner: PartnerDto;
  selected?: PartnerDto;
  onSelect?: (partner: MemberDto) => void;
  onDelete?: (partner: MemberDto) => void;
}) => {
  const handleClickParener = (partner: PartnerDto) => {
    onSelect?.(partner);
  };

  return (
    <BoxStyle
      selected={selected?.partnerChannel?.id === partner?.id}
      onClick={() => {
        handleClickParener(partner);
      }}
    >
      <Row style={{ justifyContent: 'space-between' }}>
        <Row style={{ flexGrow: 1, flex: 1 }}>
          <div>
            <Avatar
              style={{ width: 60, height: 60 }}
              src={partner?.partnerChannel?.profile?.profileImage?.publicUrl}
            />
          </div>
          <TextWrapper style={{ flexGrow: 1 }}>
            <PartnerNameStyle>
              {partner?.partnerChannel?.channelName
                ? partner?.partnerChannel?.channelName
                : partner?.email}
            </PartnerNameStyle>
            <PartnerDetailStyle>{partner?.alias}</PartnerDetailStyle>
          </TextWrapper>
        </Row>
        <DeleteIconWrapperStyle>
          <DeleteIcon
            onClick={() => {
              onDelete?.(partner);
            }}
          />
        </DeleteIconWrapperStyle>
      </Row>
    </BoxStyle>
  );
};

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding: 1px;
  margin-left: 10px;
`;

const PartnerNameStyle = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-size: 0.8rem;
`;

const PartnerDetailStyle = styled.div`
  border-radius: 0.625rem;
  background: #90a7ec;
  color: #ffffff;
  font-size: 0.6875rem;
  font-style: normal;
  padding: 2px 10px;
  font-weight: 500;
`;

const DeleteIconWrapperStyle = styled.div`
  display: none;
  @media ${(props) => props.theme.media.mobile} {
    display: unset;
  }
`;

export default CSPartnerListItem;
