import React, { useEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { isMobile } from 'react-device-detect';
import { MenuType } from 'types/CommonTypes';
import styled, { css } from 'styled-components';
import { useParams } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { Row } from 'components/commons/layouts';

import CloseIcon from '@mui/icons-material/Close';
import ViewSimulator from 'features/adminFeatures/simulator/containers/ViewSimulator';
import AdminLnb from 'features/commonFeatures/navigationBar/containers/AdminLnb';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import MenuTitleStyle from 'resources/controls/MenuTitleStyle';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import useToken from 'libs/hooks/useToken';
import MobileMenus from 'features/adminFeatures/admin/components/MobileMenus';
import { getAdminMenus } from 'resources/definitions/AdminMenus';
import { useTranslation } from 'react-i18next';
import PageLoader from 'components/commons/PageLoader';

const SIMULATOR_HIDE_POSITION = 940;

const AdminMainPage = observer(() => {
  const { navigate } = useNavigation();
  const { t } = useTranslation();
  const { channelname } = useParams();
  const { menuType } = useParams();
  const { validUser } = useToken();
  const { uiStore, myChannelStore, uiStores, mySocialStore, myBoardStore } = useStore();
  const { profileStore, adminStore } = uiStores;

  const [showSimulator, setShowSimulator] = useState<boolean>(false);

  const [currentMenu, setCurrentMenu] = useState<MenuType>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const handleChangeMode = (menu: MenuType) => {
    if (!isMobile) {
      if (menu) {
        setCurrentMenu(menu);
      }
    }
  };

  const getMenuContainer = () => {
    return currentMenu?.component || <></>;
  };

  const init = async () => {
    if (menuType) {
      setCurrentMenu(getAdminMenus()[1]);
    }
  };

  const handleResize = () => {
    if (window.innerWidth > SIMULATOR_HIDE_POSITION) {
      setShowSimulator(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    init();
    return () => {
      uiStore.universalModal.close();
    };
  }, [channelname]);

  useEffect(() => {
    if (myChannelStore.myChannels) {
      if (myChannelStore.myChannels?.length === 0) navigate('/');
    }
  }, [myChannelStore.myChannels]);

  useEffect(() => {
    if (myChannelStore.currentChannel?.profile) {
      mySocialStore.getSocials(myChannelStore.currentChannel?.id as number);
      profileStore.setProfile(myChannelStore.currentChannel?.profile);
      myBoardStore.getBoards(myChannelStore.currentChannel?.id as number);
    }
  }, [myChannelStore.currentChannel]);

  useEffect(() => {
    async function checkUser() {
      if (!validUser()) {
        navigate('/login');
      }
    }
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
    checkUser();
  }, []);

  return (
    <>
      <MobileMenuContanerStyle>
        <MobileMenus type="mobile" onMenuChange={handleChangeMode} />
      </MobileMenuContanerStyle>
      <AdminStyle>
        <GnbContainerStyle>
          <AdminLnb onChangeMode={handleChangeMode} />
        </GnbContainerStyle>
        <ContainerOuterStyle>
          <ContainerStyle>
            <NewLinkButtonStyle>
              <Row style={{ justifyContent: 'space-between' }}>
                <MenuTitleStyle>{currentMenu?.title}</MenuTitleStyle>
                <>
                  {currentMenu?.title === 'Links' && (
                    <div style={{ marginRight: 20 }}>
                      <OhmossButton
                        disabled={myBoardStore.linkBoards?.length === 0}
                        type="primary"
                        style={{ height: 50, width: 140 }}
                        onClick={() => {
                          adminStore.setShowNewLinkEditor(true);
                        }}
                      >
                        <div>{t('A133')}</div>
                      </OhmossButton>
                    </div>
                  )}
                  {/* {currentMenu?.title === 'Boards' && (
                    <div style={{ marginRight: 20 }}>
                      <OhmossButton
                        type="primary"
                        style={{ height: 50, width: 120 }}
                        onClick={() => {
                          adminStore.setShowNewBoardEditor(true);
                        }}
                      >
                        <div>{t('A125')}</div>
                      </OhmossButton>
                    </div>
                  )} */}
                </>
              </Row>
            </NewLinkButtonStyle>
            {getMenuContainer()}
          </ContainerStyle>
        </ContainerOuterStyle>
        <SimulatorContainerStyle show={showSimulator}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100vh',
              zIndex: 0,
              position: 'relative',
              flexDirection: 'column',
              justifyContent: 'center',
              whiteSpace: 'pre-line',
            }}
          >
            <ViewSimulator />
            <GuideTextStyle>{t('A128')}</GuideTextStyle>
            <GoChannelButtonStyle>
              <div
                onClick={() => {
                  navigate(`/${myChannelStore.currentChannel?.channelName}`);
                }}
              >
                <OhmossButton type="simple">
                  <div style={{ marginRight: 5, display: 'flex', alignItems: 'center' }}>
                    <PhonelinkRingIcon />
                  </div>
                  {t('A129')}
                </OhmossButton>
              </div>
            </GoChannelButtonStyle>
          </div>
        </SimulatorContainerStyle>
      </AdminStyle>
      <ShowBtnStyle>
        {!showSimulator ? (
          <SmartphoneIcon
            onClick={() => {
              setShowSimulator(true);
            }}
            style={{ backgroundColor: '#11C668', borderColor: '#11c668' }}
          />
        ) : (
          <CloseIcon
            onClick={() => {
              setShowSimulator(false);
            }}
            style={{ backgroundColor: 'black' }}
          />
        )}
      </ShowBtnStyle>
      <PageLoader />
    </>
  );
});

const AdminStyle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContainerOuterStyle = styled.div`
  height: 100%;
  overflow: scroll;
  background: #fff;
  max-width: 600px;
  width: 600px;
  flex-grow: 3;
  margin: 20px 0;
  border-radius: 30px;
  height: calc(100vh - 40px);
  left: 230px;
  transition: left 0.5s ease-in-out;
  z-index: 1;

  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  @media screen and (max-width: ${SIMULATOR_HIDE_POSITION}px) {
    width: 100%;
    max-width: unset;
    overflow: scroll;
  }
`;
const GnbContainerStyle = styled.div`
  max-width: 250px;
  width: 250px;
  height: 100vh;
  @media screen and (max-width: 985px) {
    width: 80px;
  }
  @media ${(props) => props.theme.media.mobile} {
    width: 0;
  }
`;

const ContainerStyle = styled.div`
  overflow: scroll;
  @media ${(props) => props.theme.media.mobile} {
    margin-top: 110px;
  }
`;

const SimulatorContainerStyle = styled.div<{ show?: boolean }>`
  flex-grow: 1;
  align-items: center;
  display: flex;
  z-index: 0;

  ${(props) =>
    props.show
      ? css`
          display: flex !important;
          position: fixed;
          background: #efefef;

          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        `
      : css`
          position: unset;
          display: unset;

          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        `};

  @media ${(props) => props.theme.media.mobile} {
    display: none;
    z-index: 1000;
  }
  /* @media screen and (max-width: 768px) {
    display: flex;
  } */
`;

const ShowBtnStyle = styled.div`
  position: fixed;
  bottom: 20px;
  z-index: 98;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  display: none;

  > svg {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    border: 1px solid black;
    border-radius: 50%;
    padding: 10px;
    fill: #ffffff;
  }
  @media screen and (max-width: ${SIMULATOR_HIDE_POSITION}px) {
    display: flex;
    z-index: 1001;
  }
`;

const GuideTextStyle = styled.div`
  margin-top: 40px;
  text-align: center;
  font-size: 14px;
  @media ${(props) => props.theme.media.mobile} {
    display: none;
  }
`;

const GoChannelButtonStyle = styled.div`
  margin-top: 20px;
  display: flex;

  justify-content: center;
  > div {
    background: black;
    padding: 10px 20px;
    border-radius: 10px;
  }
  @media ${(props) => props.theme.media.mobile} {
    margin-bottom: 100px;
  }
`;

const NewLinkButtonStyle = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    display: none;
  }
`;

const MobileMenuContanerStyle = styled.div`
  display: none;
  @media ${(props) => props.theme.media.mobile} {
    display: unset;
  }
`;
export default AdminMainPage;
