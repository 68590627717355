import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { useParams } from 'react-router-dom';
import { Pagination } from '@mui/material';

import boardCommonLogics from 'features/board/logic/boardCommonLogic';
import styled from 'styled-components';
import InfinityBoardBase from './InfinityBoardBase';

const PaginationBase = observer(
  ({
    paginationType,
    containerStyle,
    totalPages,
    onChangePage,
    children,
  }: {
    paginationType?: 'infinity' | 'pager';
    totalPages?: number;
    containerStyle?: CSSProperties;
    onChangePage?: (pageNo: number) => void;
    children: ReactNode;
  }) => {
    const { channelStore, myChannelStore, postStore, uiStores } = useStore();
    const { userHomeStore } = uiStores;
    const { boardId, itemId } = useParams();
    const currentPageRef = useRef<number>(0);
    const { handleClickPost } = boardCommonLogics();

    const [boardPages, setBoardPages] = useState<number>();

    const handleChangePageInfo = (pageInfo: any, value: number) => {
      if (onChangePage) {
        onChangePage(value);
        return;
      }

      if (channelStore.channel?.channelName) {
        postStore.getPosts(
          channelStore.channel?.channelName,
          userHomeStore.currentBoard?.id as number,
          true,
          { page: value - 1, ownChannelId: myChannelStore.currentChannel?.id as number },
        );
      }
    };

    useEffect(() => {
      if (typeof totalPages !== 'undefined') {
        setBoardPages(totalPages);
      } else {
        setBoardPages(postStore.postPaignation?.totalPages);
      }
    }, [totalPages]);

    useEffect(() => {
      return () => {
        currentPageRef.current = 0;
      };
    }, []);

    return (
      <>
        {paginationType === 'infinity' ? (
          <InfinityBoardBase
            hasMore={postStore.postPaignation?.hasNext}
            postCounts={postStore.posts?.length || 0}
            onNext={() => {
              currentPageRef.current++;
              postStore.getPosts(
                channelStore.channel.channelName!,
                userHomeStore.currentBoard?.id as number,
                true,
                {
                  page: currentPageRef.current,
                  size: 30,
                  ownChannelId: myChannelStore.currentChannel?.id as number,
                },
              );
            }}
          >
            <>{children}</>
          </InfinityBoardBase>
        ) : (
          <>
            <>{children}</>
            {typeof totalPages !== 'undefined' && totalPages > 1 && (
              <PaginationStyle style={containerStyle}>
                <Pagination
                  onChange={handleChangePageInfo}
                  count={totalPages}
                  color="primary"
                  shape="rounded"
                />
              </PaginationStyle>
            )}
          </>
        )}
      </>
    );
  },
);

const PaginationStyle = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  background: #ffffff;
  padding: 10px;
  margin: auto;
  max-width: 550px;
  border-radius: 15px;
`;

export default PaginationBase;
