import React from 'react';
import { Avatar, IconButton } from '@mui/material';
import { DealInquiryDto } from 'services/data-contracts';
import styled from 'styled-components';
import CommonThumbnail from 'features/management/components/commonControl/CommonThumbnail';
import moment from 'moment';
import InquiryStatusBox from './InquiryStatusBox';

const InquiryItem = ({
  inquiry,
  useDealInfo = true,
  onClick,
  onDelete,
}: {
  inquiry: DealInquiryDto;
  useDealInfo: boolean;
  onClick: () => void;
  onDelete?: (inquiry: DealInquiryDto) => void;
}) => {
  return (
    <InquiryContainer>
      <SelectBox onClick={onClick}>
        <div style={{ position: 'relative' }}>
          {useDealInfo && (
            <CommonThumbnail initialValue={inquiry?.link?.thumbnail?.publicUrl} useRound />
          )}
          <div style={{ position: useDealInfo ? 'absolute' : 'unset', bottom: 0, right: -20 }}>
            <Avatar src={inquiry?.customerChannel?.profile?.profileImage?.publicUrl} />
          </div>
        </div>
        <InquirerInfo style={{ marginLeft: useDealInfo ? 10 : 0, flexGrow: 1 }}>
          <div style={{ paddingLeft: 4 }}>{inquiry?.customerChannel?.channelName}</div>
          <InquiryStatusInfo>
            <InquiryStatusBox status={inquiry?.status} />
            <Contents>{inquiry?.latestThread?.message ?? '문의 내용 입니다. '}</Contents>
          </InquiryStatusInfo>
        </InquirerInfo>
        <div style={{ marginRight: 10, textAlign: 'center', minWidth: 50, margin: 'auto' }}>
          <div style={{ fontSize: 12, color: '#666', margin: 'auto' }}>
            {moment(inquiry?.createDate).fromNow()}
          </div>
          {inquiry?.status === 'PENDING' && <NewStyle>NEW</NewStyle>}
        </div>
      </SelectBox>

      {/* <IconButton
        style={{ width: '30px', height: '30px', margin: 10 }}
        onClick={() => onDelete?.(inquiry)}
      >
        <img src="/images/close.svg" alt="close" style={{ width: '10px' }} />
      </IconButton> */}
    </InquiryContainer>
  );
};

const InquiryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SelectBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 98%;
  border-bottom: 0.5px solid #cacaca;
  padding: 15px 0px;
  gap: 15px;
`;

const InquirerInfo = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 0.875rem;
  color: #000;
  font-weight: 500;
  gap: 2px;
`;

const InquiryStatusInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Contents = styled.div`
  max-width: 100px;
  color: #666;
  font-size: 0.75rem;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NewStyle = styled.div`
  background: #96e06e;
  padding: 2px 10px;
  float: right;
  font-size: 11px;
  color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;
export default InquiryItem;
