import React, { useCallback, useState } from 'react';
import { AdDto, ChannelBoardDto, PostDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';

import AdPostEditor from './AdPostEditor';
import DealEditor from './DealEditor';
import useNavigation from 'hooks/useNavigation';

const NewAdContainer = ({ board }: { board: ChannelBoardDto }) => {
  const { navigate } = useNavigation();
  const { myAdStore, myChannelStore, uiStore } = useStore();

  const [step, setStep] = useState<'post' | 'ad' | 'confirm'>('post');
  const [postModel, setPostModel] = useState<PostDto>();

  const handleSaveAd = async (data: AdDto) => {
    data.post = { id: postModel?.id };
    const result = await myAdStore.createAd(myChannelStore.currentChannel?.id as number, data);
    if (typeof result !== 'boolean') {
      if (uiStore.modal.modalShow) {
        uiStore.modal.close();
      } else if (uiStore.universalModal.modalShow) {
        uiStore.universalModal.close();
      } else {
        navigate('/settings/myad?type=3&cpcode=0061');
      }
    }
  };

  const gCurrentStepContainer = useCallback(() => {
    switch (step) {
      case 'post':
        return (
          <AdPostEditor
            isAdBoard
            saveText="저장 후 광고설정"
            boardId={board.id as number}
            model={{}}
            onRegistred={handleModelRegistred}
          />
        );
      case 'ad':
        return (
          <DealEditor
            handleClickAd={handleSaveAd}
            handleClickPreview={(ad: AdDto) => {
              alert();
            }}
          />
        );
      default:
        return <></>;
    }
  }, [step]);

  const handleModelRegistred = (model: PostDto) => {
    setPostModel(model);
    setStep('ad');
  };
  return <div style={{ width: '100%' }}>{gCurrentStepContainer()}</div>;
};

export default NewAdContainer;
