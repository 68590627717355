import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { TextField } from '@mui/material';
import { validationHelper } from 'controls/validationHelper';

const UrlForm = forwardRef(
  (
    {
      model,
      label,
      disable,
      initialValue,
      format,
      required = false,
      usePrefix = true,
      placeHolder,
      helperText = '',
      errorText = 'URL형식이 바르지 않습니다',
      valid,
      onChangeValue,
      onPaste,
      onBlur,
    }: {
      model?: string;
      label?: string;
      format?: RegExp | RegExp[];
      initialValue?: string;
      placeHolder?: string;
      required?: boolean;
      disable?: boolean;
      usePrefix?: boolean;
      helperText?: string;
      errorText?: string;
      valid?: (result: boolean) => void;
      onPaste?: (valus: string) => void;
      onChangeValue?: (value: string) => void;
      onBlur?: (value: string, valid: boolean) => void;
    },
    ref,
  ) => {
    const [isValid, setIsValid] = useState<boolean>(true);
    const [fieldModel, setFieldModel] = useState<string>();

    useImperativeHandle(ref, () => ({
      focus,
      reset,
      // value,
    }));

    const reset = () => {
      setFieldModel('');
    };

    const checkValidate = (value: string) => {
      /**
       * disabled === true 면 무시
       * required === false 면 무시
       *
       * */

      if (!disable) {
        if (value?.length === 0) {
          setIsValid(true);
        } else {
          let result;
          if (typeof format !== 'undefined') {
            result = validationHelper.custom(value, format);
          } else {
            result = validationHelper.url(value);
          }
          setIsValid(result);
        }
      } else {
        setIsValid(true);
      }
    };

    useEffect(() => {
      if (valid) valid(typeof isValid === 'undefined' ? true : isValid);
    }, [isValid]);

    useEffect(() => {
      if (typeof initialValue !== 'undefined') {
        setFieldModel(initialValue);
        if (initialValue?.length > 0) {
          checkValidate(initialValue);
        } else {
          setIsValid(true);
        }
      } else {
        setIsValid(true);
      }
      return () => {
        setFieldModel('');
      };
    }, [initialValue]);

    useEffect(() => {
      if (typeof fieldModel !== 'undefined') {
        checkValidate(fieldModel);
      }

      if (disable) {
        setIsValid(true);
      }
    }, [disable]);

    useEffect(() => {
      return () => {
        setFieldModel('');
      };
    }, []);

    return (
      <TextField
        required={required}
        disabled={disable}
        inputRef={ref}
        autoCapitalize="none"
        error={!isValid}
        placeholder={placeHolder}
        // label={label || 'Link Url'}
        onPaste={(e: any) => {
          if (typeof onPaste === 'function') {
            const t = e.clipboardData.getData('text');
            onPaste(t);
          }
        }}
        InputLabelProps={{ shrink: false }}
        InputProps={{
          // startAdornment: (
          //   <InputAdornment position="start">{usePrefix && 'https://'}</InputAdornment>
          // ),
          onBlur: (e: any) => {
            if (onBlur) {
              onBlur(e.target.value, validationHelper.url(e.target.value));
            }
            checkValidate(e.target.value);
          },
        }}
        onChange={(e: any) => {
          checkValidate(e.target.value);
          setFieldModel(e.target.value);
          if (onChangeValue) onChangeValue(e.target.value);
        }}
        sx={{ width: '100%' }}
        helperText={!isValid ? errorText : helperText}
        value={fieldModel}
      />
    );
  },
);

export default UrlForm;
