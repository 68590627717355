import React, { CSSProperties, useEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { LinkDto, MemberDto } from 'services/data-contracts';
import { Row } from 'components/commons/layouts';
import { Avatar, IconButton } from '@mui/material';
import { NameStyle } from 'resources/styles/common/ContextItem';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';
import { toast } from 'react-toastify';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useStateContext } from 'libs/hooks/usePageState';

import styled from 'styled-components';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchBox from 'components/commons/SearchBox';
import CSTownController from 'controllers/CSTownController';
import AssignedDealsList from 'features/myPage/containers/AssignedDealsList';
import MemberContext from 'features/myPage/components/MemberContext';
import { BoxStyle, ListItemTemplate } from 'features/csTown/commonComponents/styles';

/**
 * @description Brand회원의 멤버목록
 * @param param0
 * @returns
 */
const ManagerList = ({
  members,
  style,
  type = 'MOBILE',
  onClick,
}: {
  members: MemberDto[];
  style?: CSSProperties;
  type?: 'PC' | 'MOBILE';
  onClick: (member: MemberDto) => void;
}) => {
  const { uiStore } = useStore();
  const { Brand } = CSTownController();
  const { myChannel } = useRootContext();
  const { broadcast } = useStateContext();

  const [brandMembers, setBrandMembers] = useState<MemberDto[]>();
  const [selected, setSelected] = useState<number>();

  const handleOnSelectedDeals = async (
    selectedDeals: LinkDtoWithAssignedDeal[],
    member: MemberDto,
  ) => {
    const dealList = selectedDeals
      .filter((deal) => deal.assigned)
      .map((deal) => {
        return { id: deal.id };
      });

    await Brand.DEAL.assignDealsToManager(myChannel?.id as number, member?.id as number, {
      deals: dealList,
    });
  };

  const handleClickAddDeal = async (member: MemberDto) => {
    const result = (await Brand.DEAL.getDelegatedDeals(
      member?.id as number,
    )) as unknown as LinkDto[];

    uiStore.bottomSheet.show({
      title: '위임 공구 리스트',
      children: (
        <AssignedDealsList
          dealList={result}
          onSelected={(selectedDeals: LinkDto[]) => {
            handleOnSelectedDeals(selectedDeals, member);
            toast('정상적으로 처리되었습니다.');
          }}
        />
      ),
    });
  };

  const handleOnReload = () => {
    broadcast({ id: 'RELOAD_MANAGER_LIST' });
  };

  const handleClickShowMore = (member: MemberDto) => {
    uiStore.bottomSheet.show({
      children: (
        <MemberContext
          member={member}
          onReload={handleOnReload}
          onEdit={() => {
            // handleAddManager(member);
          }}
        />
      ),
    });
  };

  const handleSearchMember = (value: string) => {
    if (value === '') {
      setBrandMembers(members);
    } else {
      const result = members.filter((member) => {
        return member?.memberUser?.username?.includes(value);
      });
      setBrandMembers(result);
    }
  };

  useEffect(() => {
    if (members) {
      setBrandMembers(members);
    }
  }, [members]);

  return (
    <>
      <div style={style}>
        <MainContainer>
          <MainText>매니저 찾기</MainText>
          <SearchBox
            onEntered={handleSearchMember}
            onCancel={() => {
              handleSearchMember('');
            }}
          />
          <MainText style={{ margin: '20px 0' }}>매니저 리스트</MainText>
          {brandMembers?.length === 0 && (
            <SubText>등록된 매니저가 없습니다. 새로운 멤버를 등록하세요.</SubText>
          )}
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            {brandMembers?.map((member: MemberDto) => (
              <ListItemTemplate selected={selected === member?.id} style={{ padding: 0 }}>
                <BoxStyle
                  selected={selected === member?.id}
                  style={{ justifyContent: 'space-between', margin: 0 }}
                  onClick={() => {
                    onClick(member);
                    setSelected(member?.id);
                  }}
                >
                  <Row style={{ justifyContent: 'flex-start' }}>
                    <div>
                      <Avatar style={{ width: 60, height: 60 }} />
                    </div>
                    <div style={{ marginLeft: 10 }}>
                      <div>{member.memberUser?.username}</div>
                      <NameStyle>{member.name}</NameStyle>
                      {/* <NameStyle>{member.memberUser?.}</NameStyle> */}
                    </div>
                  </Row>
                  <>
                    {type === 'MOBILE' && (
                      <Row style={{ width: 'unset' }}>
                        <IconButton
                          onClick={() => {
                            handleClickAddDeal(member);
                          }}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                        <IconButton>
                          <MoreVertIcon
                            onClick={() => {
                              handleClickShowMore(member);
                            }}
                          />
                        </IconButton>
                      </Row>
                    )}
                  </>
                </BoxStyle>
              </ListItemTemplate>
            ))}
          </div>
        </MainContainer>
      </div>
    </>
  );
};

const MainContainer = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
`;

const MainText = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 10px;
`;

const SubText = styled.div`
  margin-top: 10px;
  color: #cacaca;
  font-size: 0.82rem;
`;

export default ManagerList;
