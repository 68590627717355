import React from 'react';
import styled from 'styled-components';

import BottomSheetButtonContainer from 'components/commons/buttons/BottomSheetButtonContainer';
import BottomSheetButton from 'components/commons/buttons/BottomSheetButton';
import { useStore } from 'stores/StoreHelper';

const ContextMenu = ({
  isActive,
  onDelete,
  onRename,
  onInactive,
}: {
  isActive?: boolean;
  onDelete: () => void;
  onRename: () => void;
  onInactive: () => void;
}) => {
  const { uiStore } = useStore();
  return (
    <BottomSheetButtonContainer>
      <BottomSheetButton
        onClick={() => {
          onRename();
        }}
      >
        정보 바꾸기
      </BottomSheetButton>
      <BottomSheetButton
        onClick={() => {
          onInactive();
        }}
      >
        {isActive ? '비공개' : '공개'}
      </BottomSheetButton>
      <BottomSheetButton type="separator" />
      <>
        <BottomSheetButton
          type="danger"
          onClick={() => {
            onDelete();
          }}
        >
          삭제
        </BottomSheetButton>
        <BottomSheetButton type="separator" />
      </>
      <BottomSheetButton
        onClick={() => {
          uiStore.bottomSheet.close();
        }}
      >
        닫기
      </BottomSheetButton>
    </BottomSheetButtonContainer>
  );
};

export default ContextMenu;
