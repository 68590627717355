import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Avatar } from '@mui/material';
import { useStore } from 'stores/StoreHelper';
import { useSearchParams, useLocation } from 'react-router-dom';
import { AdDto, ChannelBoardDto } from 'services/data-contracts';
import { useFollowContext } from 'providers/follow/useFollowContext';
import { toast } from 'react-toastify';
import { useGlobalContext } from 'context/useGlobalContext';

import styled from 'styled-components';
import useNavigation from 'hooks/useNavigation';
import useToken from 'libs/hooks/useToken';
import NewPostSelector from 'features/board/containers/NewPostSelector';
import useChannelSelector from 'libs/hooks/useChannelSelector';
import useLocalParams from 'libs/hooks/useLocalParams';
import { ReactComponent as HomeImage } from 'resources/images/commons/home.svg';
import { ReactComponent as PlanImage } from 'resources/images/commons/plan.svg';
import { ReactComponent as CSImage } from 'resources/images/commons/cs.svg';
import { ReactComponent as MyImage } from 'resources/images/commons/my.svg';
import { ReactComponent as NotificationImage } from 'resources/images/commons/notification.svg';
import { useRootContext } from 'libs/hooks/useRootContext';
import AIExperience from './AIExperience';
import FollowListContainer from './follower/container/FollowListContainer';
import MyNotifyInfo from './MyNotifyInfo';

const NavigationTabBar = ({
  type,
  board,
}: {
  type: 'main' | 'link';
  board?: ChannelBoardDto | null;
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { uiStore, myNotificationStore, myBoardStore, adStore, myStore } = useStore();
  const { validUser } = useToken();
  const { initializeFirstChannel } = useChannelSelector();
  const { follow } = useFollowContext();
  const { report, isLoggedIn } = useGlobalContext();
  const { myChannel } = useRootContext();
  const [searchParam] = useSearchParams();
  const { channelName } = useLocalParams();
  const { navigate, reset } = useNavigation();

  const [showAI, setShowAI] = useState(false);
  const [currentMenu, setCurrentMenu] = useState<'home' | 'subscriptions' | 'cs' | 'my'>('home');

  const handleClickAlarm = () => {
    const result = validUser();

    if (result) {
      uiStore.universalModal.show({
        title: t('A233'),
        description: t('A234'),
        bottomSheetOptions: { blocking: true, topPosition: 100 },
        modalOption: { style: { maxWidth: 500, maxHeight: 500 } },
        children: (
          <>
            <MyNotifyInfo />
          </>
        ),
      });
    } else {
      showLoginConfirm('2');
    }
  };

  const handleClickAddPost = async () => {
    if (type === 'link') {
      showNewPost();
      return;
    }

    if (validUser() === true) {
      await initializeFirstChannel();
      const boards = (await myBoardStore.getBoards(
        myChannel?.id as number,
      )) as never as ChannelBoardDto[];
      const defaultBoard = boards?.find((board: ChannelBoardDto) => board.isDefault);
      if (defaultBoard && myChannel?.isBusiness) {
        navigate(`/deals/new?channelid=${myChannel?.id}&boardid=${defaultBoard.id}&posttype=1`);
      } else {
        // 보드 목록 표시하기
        showNewPost();
      }
    } else {
      showLoginConfirm('1');
    }
  };

  const showNewPost = async () => {
    if (!validUser()) {
      showLoginConfirm('9');
    } else if (typeof board !== 'undefined' && board?.channel?.id === myChannel?.id) {
      if (board?.boardType !== 'LINK') {
        navigate(`/post/${board?.id}/new?posttype=2&channelid=${myChannel?.id}`);
      } else {
        navigate(`/link/${board?.id}/new?posttype=2&channelid=${myChannel?.id}`);
      }
    } else {
      uiStore.bottomSheet.show({
        children: (
          <NewPostSelector channelName={channelName || ''} channelId={myChannel?.id || 0} />
        ),
      });
    }
  };

  const showLoginConfirm = (referrer: string, params?: string) => {
    uiStore.confirm.show({
      message: t('A245'),
      onConfirmed: async () => {
        navigate(`/login?action=(${referrer}.${params})`);
      },
    });
  };

  const handleClickFollow = () => {
    const result = validUser();

    if (result) {
      uiStore.universalModal.show({
        children: <FollowListContainer />,
        useHeader: false,
        bottomSheetOptions: { blocking: true },
        modalOption: { style: { maxWidth: 500, maxHeight: 500 } },
      });
    } else {
      showLoginConfirm('3');
    }
  };

  const handleClickMyChannel = () => {
    if (validUser()) {
      navigate(`/my`);
    } else {
      showLoginConfirm('4');
    }
  };

  const handleAction = async (action: string) => {
    const params = searchParam.get('params');
    switch (action) {
      case '1':
        handleClickAddPost();
        break;
      case '2':
        handleClickAlarm();
        break;
      case '3':
        handleClickFollow();
        break;
      case '4':
        navigate(`/${myChannel?.channelName}`);
        break;
      case '5': // follow channel
        const channelName = searchParam.get('channelname');
        if (channelName) {
          await follow(channelName, false);
          // updateFollowStatus(true, channelName);
        }
        break;
      case '6':
        if (params !== null) {
          const adid = params.split('=')[1];
          const adId = Number.parseInt(adid);
          if (!Number.isNaN(adId)) {
            const ad = (await adStore.getAd(adId, {
              ownChannelId: myChannel?.id as number,
            })) as never as AdDto;
            if (ad.isInterest) {
              toast('이미 찜된 광고 입니다.');
            } else {
              handleClickFavorite(ad, !ad.isInterest);
            }
          } else {
            alert('광고 정보가 바르지 않습니다. 다시 시도해 주세요.');
          }
        }
        break;
      case '7': // Report
        if (params !== null) {
          const adid = params.split('=')[1];
          const adId = Number.parseInt(adid);

          if (!Number.isNaN(adId)) {
            report(adId);
          }
        }
        break;
      default:
        // showPreview(action);
        break;
    }
    // window.history.replaceState('', '', '/');
  };

  const handleClickFavorite = async (adItem: AdDto, isFavorite: boolean) => {
    const result = validUser();
    if (result) {
    } else {
      showLoginConfirm('6', `adid=${adItem.id}`);
    }
  };

  useLayoutEffect(() => {
    const action = searchParam.get('action');
    const loc = location.pathname.split('/');
    const menu = loc[loc.length - 1];
    if (menu === '') {
      setCurrentMenu('home');
    } else {
      setCurrentMenu(menu as 'subscriptions' | 'cs' | 'my');
    }

    if (action) {
      handleAction(action);
    }
  }, [location]);

  useEffect(() => {
    if (validUser()) {
      myStore.getLoginInfo();
    }
  }, []);

  if (window.isWebView) {
    return <></>;
  }
  return (
    <div className="ohmoss-navigation-tab">
      {myChannel && (
        <GNBBottomStyle>
          <GnbBottomItemStyle
            on={currentMenu === 'home'}
            onClick={() => {
              reset('/');
            }}
          >
            <MenuFontStyle>
              <HomeImage
                style={{
                  stroke: currentMenu === 'home' ? '#173300' : '#cacaca',
                  width: 25,
                  height: 25,
                }}
              />
              홈
            </MenuFontStyle>
          </GnbBottomItemStyle>
          <GnbBottomItemStyle
            onClick={handleClickAlarm}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Badge
              badgeContent={myNotificationStore?.alarmsCount}
              style={{ top: 5, right: -10 }}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              color="primary"
            />
            <MenuFontStyle disabled={!isLoggedIn}>
              <NotificationImage style={{ fill: '#cacaca', width: 25, height: 25 }} />
              알림
            </MenuFontStyle>
          </GnbBottomItemStyle>

          {myChannel?.membershipType !== 'FREE' && (
            <GnbBottomItemStyle
              style={{ position: 'relative', bottom: 15, left: 0, right: 0, margin: 'auto' }}
              onClick={() => {
                navigate('/cstown');
              }}
            >
              <CSButtonStyle>
                <CSImage />
              </CSButtonStyle>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: 2,
                }}
              >
                CS
              </div>
            </GnbBottomItemStyle>
          )}
          <GnbBottomItemStyle
            onClick={() => {
              navigate('/my/subscriptions');
            }}
            on={currentMenu === 'subscriptions'}
          >
            <MenuFontStyle disabled={!isLoggedIn}>
              <PlanImage
                style={{
                  fill: currentMenu === 'subscriptions' ? '#173300' : '#cacaca',
                  width: 25,
                  height: 25,
                }}
              />
              플랜
            </MenuFontStyle>
          </GnbBottomItemStyle>
          <GnbBottomItemStyle onClick={handleClickMyChannel} on={currentMenu === 'my'}>
            <MenuFontStyle disabled={!isLoggedIn}>
              <MyImage
                style={{
                  fill: currentMenu === 'my' ? '#173300' : '#cacaca',
                  width: 25,
                  height: 25,
                }}
              />
              MY
            </MenuFontStyle>
          </GnbBottomItemStyle>
        </GNBBottomStyle>
      )}
      {showAI && (
        <AIExperience
          onClose={() => {
            setShowAI(false);
          }}
        />
      )}
    </div>
  );
};

const GNBBottomStyle = styled.div`
  position: fixed;
  bottom: 10px;
  width: 100%;
  left: 10px;
  right: 10px;
  max-width: 530px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #fff;
  border-radius: 20px;
  margin: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

  > div {
    cursor: pointer;
  }

  @media ${(props) => props.theme.media.mobile} {
    border-radius: 0;
    max-width: 550px;
    left: 0;
    right: 0;

    /* border-top: 1px solid rgba(0, 0, 0, 0.35); */
    bottom: 0px;
  }
`;

const MenuFontStyle = styled.div<{ disabled?: boolean }>`
  font-size: 10px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;

const CSButtonStyle = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: #173300;
  border-radius: 150px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
`;

const GnbBottomItemStyle = styled.div<{ on?: boolean }>`
  flex: 1;
  height: auto;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #cacaca;
  cursor: pointer;
  ${(props) =>
    props.on &&
    `
    height: 69px;

    color:#173300;
  `}
`;

export default NavigationTabBar;
