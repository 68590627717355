import MembershipSelector from 'features/myPage/components/membership/MembershipSelector';
import React from 'react';
import { ChannelDto, MembershipPlanDto } from 'services/data-contracts';
import styled from 'styled-components';

const MembershipGift = ({
  plans,
  type,
  channelType,
}: {
  type?: any;
  plans?: MembershipPlanDto[];
  channelType?: ChannelDto['channelType'];
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingBottom: 90,
      }}
    >
      <TextContainer>
        🙍🏻‍♀️ 셀러에게
        <br />
        오모스 멤버십을
        <br /> 선물하세요! 💰
      </TextContainer>
      <MembershipSelector plans={plans} isGift={true} channelType={channelType} />
    </div>
  );
}; 

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #173300;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.015rem;
  margin-top: 22px;
  margin-bottom: 22px;
`;

export default MembershipGift;
