import React, { useRef, CSSProperties, useState, useEffect } from 'react';
import { Title } from 'features/adminFeatures/template/components';
import { TextAreaStyle } from 'features/adminFeatures/template/components/TemplateStyle';
import { TextToolBox } from 'components/commons';
import { TextContent, TextProps } from 'types/CommonTypes';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@mui/material';
import { observer } from 'mobx-react';

import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';
import TitleForm from 'components/items/forms/TitleForm';
import { useStore } from 'stores/StoreHelper';
import { Row } from 'components/commons/layouts';

const TitleEditor = observer(
  ({
    title,
    defaultTitle,
    titleProps,
    label,
    onChangeTitle,
    onChangeProps,
  }: {
    title?: string;
    defaultTitle?: string;
    titleProps?: TextProps;
    label?: string;
    onChangeTitle: (value?: string) => void;
    onChangeProps: (props?: TextContent) => void;
  }) => {
    const { t } = useTranslation();
    const { uiStore } = useStore();

    /** @description 변경전 임시값을 저장해 놓는다. */
    const titleRef = useRef<string | undefined>('');
    const titlePropsRef = useRef<TextContent | undefined>();

    const [titleState, setTitleState] = useState<string | undefined>('');
    const [titlePropsModel, setTitlePropsModel] = useState<TextContent | undefined>();
    const [isTitleModify, setIsTitleModify] = useState<boolean>(false);

    useEffect(() => {
      if (typeof onChangeProps !== 'undefined' && titlePropsModel) {
        onChangeProps(titlePropsModel);
      }
    }, [titlePropsModel]);

    // useEffect(() => {
    //   if (typeof onChangeTitle !== 'undefined') {
    //     onChangeTitle(titleState);
    //   }
    // }, [titleState]);

    useEffect(() => {
      if (typeof titleProps !== 'undefined') {
        setTitlePropsModel(titleProps);
      }
    }, [titleProps]);

    useEffect(() => {
      if (typeof title !== 'undefined') {
        setTitleState(title);
      }
    }, [title]);

    useEffect(() => {
      if (uiStore.status === 'done') {
        setIsTitleModify(false);
      }
    }, [uiStore.status]);

    return (
      <TitleAreaStyle>
        <div style={{ position: 'relative' }}>
          <div>
            <Row style={{ justifyContent: 'space-between' }}>
              <>{typeof label !== 'undefined' && <div>{label}</div>}</>
              {!isTitleModify ? (
                <IconButton
                  onClick={() => {
                    setIsTitleModify((prev) => !prev);
                    titleRef.current = titleState;
                    titlePropsRef.current = titlePropsModel;
                  }}
                >
                  <EditIcon />
                </IconButton>
              ) : (
                <div style={{ background: 'rebeccapurple', borderRadius: 30 }}>
                  <IconButton
                    onClick={() => {
                      setIsTitleModify((prev) => !prev);
                    }}
                  >
                    <DoneIcon style={{ color: '#fff' }} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setIsTitleModify((prev) => !prev);
                      setTitleState(titleRef.current);
                      setTitlePropsModel(titlePropsRef.current);
                    }}
                  >
                    <ClearIcon style={{ color: '#fff' }} />
                  </IconButton>
                </div>
              )}
            </Row>
          </div>
          {!isTitleModify && (
            <div
              style={{
                minHeight: 60,
                overflow: 'scroll',
              }}
            >
              <Title title={titleState} mode="edit" />
            </div>
          )}
        </div>
        {isTitleModify && (
          <div style={{ marginTop: 20 }}>
            <TitleForm
              label="Profile title"
              multiline
              required={false}
              model={titleState}
              onChangeValue={(value: string) => {
                if (onChangeTitle) {
                  onChangeTitle(value);
                }
              }}
            />

            <TextToolBox
              show
              colorPickerLocation="top"
              showImageIcon
              style={titleProps?.style}
              containerStyle={toolBoxStyle}
              onChangeStyle={(style: CSSProperties) => {
                setTitlePropsModel((prev: TextContent) => {
                  return {
                    ...prev,
                    style: { ...prev?.style, ...style },
                  };
                });
              }}
            />
          </div>
        )}
      </TitleAreaStyle>
    );
  },
);

const TitleAreaStyle = styled(TextAreaStyle)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: center;
  position: relative;
  z-index: 1;
  width: auto;
`;

const toolBoxStyle = {
  borderRadius: 12,
  border: 'none',
};

export default TitleEditor;
