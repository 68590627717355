import React from 'react';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import { SEND_BIRD_KEY } from 'stores/AppStore';
import { ChannelDto } from 'services/data-contracts';

const SendBirdProvider = ({
  children,
  myChannel,
  useProvider,
}: {
  children: any;
  myChannel?: ChannelDto;
  useProvider?: boolean;
}) => {
  if (useProvider) {
    return (
      <SendbirdProvider
        appId={SEND_BIRD_KEY as string}
        userId={(myChannel?.channelName as string) || ''}
        // nickname={myChannel?.profile?.nickname || (myChannel?.channelName as string) || ''}
      >
        {children}
      </SendbirdProvider>
    );
  } else {
    return <div>{children}</div>;
  }
};

export default SendBirdProvider;
