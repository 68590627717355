import React, { memo, useEffect, useRef, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { LinkDto, ChannelProfileDto } from 'services/data-contracts';
import { useParams, useSearchParams } from 'react-router-dom';

import ProfileBlocks from 'features/management/components/profile/ProfileBlocks';

import { ChannelBoardModel } from 'types/CommonTypes';
import { toast } from 'react-toastify';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useChannelContext } from '../context/useChannelContext';

const LinkPageProfileBlocks = ({
  myChannelName,
  myProfile,
  editMode,
  previewMode,
}: {
  myChannelName?: string;
  myProfile?: ChannelProfileDto;
  editMode?: boolean;
  previewMode?: boolean;
}) => {
  const init = useRef<boolean>(false);
  const { channelStore, linkStore } = useStore();
  const { currentChannel, setCategoryBoard } = useChannelContext();
  const { myChannel } = useRootContext();
  const { boardid, channelname } = useParams();

  const [searchParams] = useSearchParams();
  const [boards, setBoards] = useState<ChannelBoardModel[]>();
  const [currentProfile, setCurrentProfile] = useState<ChannelProfileDto>();

  const initMyChannel = async () => {
    if (init.current === true) {
      return;
    }
    const key = searchParams.get('action');
    if (typeof key !== 'undefined' && key !== null) {
      const result = await linkStore.encoreDeal(key as string, myChannel?.id as number);
      if (result !== null) {
        toast('앵콜 요청이 완료되었습니다.');
      } else {
        toast('유효하지 않는 공구주소 입니다.', { type: 'warning' });
      }
      window.history.replaceState('', '', `${window.location.pathname}`);
      init.current = true;
    }
  };

  const getChannelBoards = async (targetChannelName: string) => {
    if (typeof targetChannelName !== 'undefined') {
      const tempBoards = (await channelStore.getChannelBoards(
        targetChannelName as string,
      )) as any as ChannelBoardModel[];
      tempBoards.sort((a, b) => {
        // isPrimary가 true인 항목을 앞으로 이동
        if (a.isPrimary && !b.isPrimary) {
          return -1;
        }
        if (!a.isPrimary && b.isPrimary) {
          return 1;
        }
        // isPrimary가 모두 같다면, seq 기준으로 내림차순
        return a.seq! - b.seq!;
      });

      const endedDeals = (await linkStore.getEndedDealList(
        targetChannelName,
      )) as unknown as LinkDto[];

      if (myChannel?.profile?.useEndedDealPage !== false && endedDeals?.length > 0) {
        const closedDealBoard: ChannelBoardModel = {
          boardType: 'LINK',
          viewType: 'NONE',
          name: '종료된 공구',
          id: 1,
          isDeal: true,
          postCount: endedDeals.length,
          useProfile: false,
          thumbnail: { publicUrl: endedDeals[0].thumbnail?.publicUrl },
          isActive: true,
        };
        tempBoards.push(closedDealBoard);
      }
      setBoards(tempBoards);

      const convertedBoardID = Number.parseInt(boardid || '', 10);
      if (!Number.isNaN(convertedBoardID)) {
        const tempBoard = tempBoards.find(
          (board: ChannelBoardModel) => board.id === convertedBoardID,
        );
        if (tempBoard) {
          setCategoryBoard(tempBoard);
        } else {
          setCategoryBoard(tempBoards[0]);
        }
      } else if (tempBoards.length > 0) {
        const defaultBoard = tempBoards.find((board) => board.isPrimary);
        setCategoryBoard(defaultBoard ?? tempBoards[0]);
        // }
      } else {
        setCategoryBoard(undefined);
      }
    }
  };

  useEffect(() => {
    if (channelname) {
      getChannelBoards(channelname);
    }
  }, [channelname]);

  useEffect(() => {
    if (myChannelName) {
      getChannelBoards(myChannelName);
    }
  }, [myChannelName]);

  useEffect(() => {
    if (myChannel && previewMode === true) {
      initMyChannel();
      getChannelBoards(myChannel?.channelName as string);
    }
  }, [myChannel]);

  useEffect(() => {
    const t = window.location.pathname.split('/');
    if (t[1] === 'management') {
      setCurrentProfile(myChannel?.profile);
    } else {
      setCurrentProfile(
        channelname === myChannel?.channelName
          ? myChannel?.profile
          : currentChannel?.profile ?? myProfile,
      );
    }
  }, [window.location.pathname, channelname, myChannel, currentChannel]);

  return (
    <ProfileBlocks
      editMode={false}
      previewMode={previewMode}
      profile={currentProfile}
      channelBoards={boards}
    />
  );
};

export default memo(LinkPageProfileBlocks);
