import React from 'react';
import TitleForm from 'components/items/forms/TitleForm';
import ThumbnailUploader from 'components/items/ThumbnailUploader';
import { Box } from '@mui/material';
import { PushDto } from 'services/data-contracts_bak';
import { PushModel } from 'types/CommonTypes';

const Step1 = ({
  model,
}: {
  model: [PushModel | undefined, React.Dispatch<React.SetStateAction<PushModel | undefined>>];
}) => {
  const dispatch = model[1];
  return (
    <div style={{ marginTop: 30 }}>
      <TitleForm
        required={false}
        label="메세지 타이틀"
        onChangeValue={(value: string) => {
          dispatch((prevState: PushModel) => {
            return { ...prevState, title: value };
          });
        }}
      />
      <TitleForm
        label="메세지 내용"
        rows={4}
        multiline
        onChangeValue={(value: string) => {
          dispatch((prevState: PushModel) => {
            return { ...prevState, contents: value };
          });
        }}
      />
      <Box sx={{ marginTop: 3 }}>
        <ThumbnailUploader
          align="left"
          url={model[0]?.image?.publicUrl}
          onImageChange={(image: any) => {
            dispatch((prevState: PushModel) => {
              return { ...prevState, image };
            });
          }}
        />
      </Box>
    </div>
  );
};

export default Step1;
