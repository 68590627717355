import { makeObservable, flow } from 'mobx';
import { Friendship as FriendshipService } from 'services/Friendship';
import { REST_API_URL, REST_API_URL_V2 } from 'stores/AppStore';
import { FriendshipTagDto, TagDto } from 'services/data-contracts';
import { HttpClient } from 'services/http-client';

import ServiceStoreBase from './ServiceStoreBase';

/**
 * @deprecated
 */
class FriendshipStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      follow: flow,
      followTag: flow,
      unfollow: flow,
    });
  }

  // api: FriendshipService<unknown> = new FriendshipService(new HttpClient());1

  *follow(channelName: string) {
    // const { response } = yield this.api.follow(channelName);
    // super.handleByStatus(response, {
    //   successMessage: `${channelName}채널을 Follow합니다.`,
    // });
    // return response;
  }

  *followTag(channelName: string, friendshipTag: FriendshipTagDto) {
    // const { response } = yield this.api.addFollowTag1(channelName, friendshipTag);
    // return super.handleByStatus(response);
  }

  *unfollow(channelName: string) {
    // const { response } = yield this.api.unfollow(channelName);
    // return super.handleByStatus(response, {
    //   successMessage: `${channelName}채널을 unfollow합니다.`,
    // });
  }
}

export default FriendshipStore;
