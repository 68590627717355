import React, { useEffect, useState } from 'react';
import { OhmossButton } from 'resources/controls/OhmossButton';

import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { validationHelper } from 'controls/validationHelper';
import { ChannelProposalConfigDto, ChannelProposalTypeDto } from 'services/data-contracts';
import ProposalController from 'controllers/ProposalController';
import ProfileCategoryOptions from '../common/ProfileCategoryOptions';
import { useRootContext } from 'libs/hooks/useRootContext';

const ProfileProposal = () => {
  const { myChannel } = useRootContext();
  const { uiStore, xhrStore } = useStore();
  const { getChannelProposalConfig, createChannelProposalConfig } = ProposalController();

  const [isValid, setIsValid] = useState<boolean>(false);

  const [optionItems, setOptionItems] = useState<ChannelProposalConfigDto>();
  const [newOptionItems, setNewOptionItems] = useState<ChannelProposalConfigDto[]>([]);
  const [label, setLabel] = useState<string>('');

  const getOptions = async () => {
    const optionsTemp = (await getChannelProposalConfig()) as unknown as ChannelProposalConfigDto;
    setOptionItems(optionsTemp);
    setNewOptionItems(optionsTemp?.types as ChannelProposalTypeDto[]);
  };

  const handleCheckIfIsValid = () => {
    const titleValid = validationHelper.required(label);
    const itemsValid = newOptionItems !== undefined && newOptionItems.length > 0;
    setIsValid(titleValid && itemsValid);
  };

  const handleNewProfileItems = async (items: ChannelProposalTypeDto[]) => {
    setNewOptionItems(items);
  };

  const updateProfileProposalConfig = async () => {
    xhrStore.setState('pending');
    const result = (await createChannelProposalConfig({
      label,
      types: newOptionItems,
    })) as unknown as ChannelProposalConfigDto[];
    xhrStore.setState('done');
    uiStore.bottomSheet.close();
  };

  useEffect(() => {
    if (myChannel) getOptions();
  }, [myChannel]);

  useEffect(() => {
    handleCheckIfIsValid();
  }, [label, newOptionItems]);

  return (
    <>
      <ProfileCategoryOptions
        profileItems={optionItems}
        onLabelCheck={(data) => setLabel(data)}
        onChangeProfileItems={(items: ChannelProposalTypeDto[]) => handleNewProfileItems(items)}
      />

      <Row style={{ gap: 10 }}>
        <OhmossButton
          type="box"
          style={{ color: '#000' }}
          onClick={() => {
            uiStore.bottomSheet.close();
          }}
        >
          취소
        </OhmossButton>
        <OhmossButton
          onClick={() => {
            updateProfileProposalConfig();
          }}
          disabled={!isValid}
        >
          적용
        </OhmossButton>
      </Row>
    </>
  );
};
export default ProfileProposal;
