import Text from 'components/commons/Text';
import TitleForm from 'components/items/forms/TitleForm';
import ChecklistController from 'controllers/ChecklistController';
import { validationHelper } from 'controls/validationHelper';
// eslint-disable-next-line max-len
import CSTownChecklistController from 'features/csTown/pages/pageControllers/CSTownChecklistController';
import DealList from 'features/myPage/containers/DealList';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import { useStateContext } from 'libs/hooks/usePageState';
import { useRootContext } from 'libs/hooks/useRootContext';
import useSetState from 'libs/hooks/useSetState';
import React, { useEffect, useState } from 'react';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { ChannelChecklistCreationDto, LinkDto } from 'services/data-contracts';
import styled from 'styled-components';
import { CSTownChecklistState } from 'types/CommonTypes';

import { ListBoxItemTemplate } from 'features/csTown/commonComponents/styles';
import DealInfo from 'features/myPage/components/DealInfo';
import { Row } from 'components/commons/layouts';
import Required from 'components/commons/Required';

function ChecklistCategorySelector({ categoryProps }: { categoryProps: any }) {
  const { getDeals, unmountDeals } = CSTownChecklistController();
  const { myChannel } = useRootContext();
  const { stateModel, broadcast } = useStateContext<CSTownChecklistState>();
  const { updateServiceModel, serviceModel } = useModelManagement<ChannelChecklistCreationDto>();
  const { createMyChecklist } = ChecklistController();

  const [localModel, setLocalModel] = useSetState<ChannelChecklistCreationDto>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [targetDeal, setTargetDeal] = useState<LinkDto>();
  const [checklistType, setChecklistType] = useState<'TITLE' | 'DEAL'>();

  const handleClickDeal = (deal?: LinkDto) => {
    setTargetDeal(deal);
    updateServiceModel('dealId', deal?.id as number);
    setLocalModel('dealId', deal?.id);
    setChecklistType('DEAL');
  };

  const handleClickCreateCheckList = async () => {
    if (serviceModel) {
      const result = await createMyChecklist(myChannel?.id as number, serviceModel);
      if (result) {
        broadcast({ id: 'reload', param: 'setPageMode' });
      }
    }
  };

  const validation = () => {
    const categoryId = validationHelper.required(localModel?.categoryId);
    const title = validationHelper.required(localModel?.title);

    setIsValid(categoryId && title);
  };

  useEffect(() => {
    if (localModel) {
      validation();
    }
  }, [localModel]);

  useEffect(() => {
    return () => {
      unmountDeals();
    };
  }, []);

  return (
    <div style={{ padding: 20 }}>
      <ListBoxItemTemplate>
        <Text type="H4">체크리스트 타입 선택</Text>
        <Text type="H4" buttonRole="DESCRIPTION" style={{ margin: '5px 0', marginBottom: 20 }}>
          생성할 체크리스트 타입을 선택 해주세요.
        </Text>
        <CategorySet>
          {categoryProps?.map((category: any, index: number) => (
            <Category
              selected={localModel?.categoryId === category?.id}
              onClick={() => {
                updateServiceModel('categoryId', category?.id as number);
                setLocalModel('categoryId', category?.id);
              }}
              key={category?.id}
            >
              <img src={category?.image?.publicUrl} alt={category?.id} />
              {category?.name}
            </Category>
          ))}
        </CategorySet>
      </ListBoxItemTemplate>
      {localModel?.categoryId && (
        <Row style={{ gap: 20, alignItems: 'flex-start', justifyContent: 'flex-start' }}>
          <ListBoxItemTemplate>
            <Row style={{ width: 'unset', justifyContent: 'flex-start' }}>
              <Text type="H4">신규 Checklist 생성</Text>
              <Required />
            </Row>
            <Text type="H4" buttonRole="DESCRIPTION" style={{ margin: '5px 0' }}>
              공동구매 시작 준비를 위한 체크리스트를 생성합니다.
              <br />
              나중에 공동구매에 연결할 수도 있습니다.
            </Text>
            <TitleForm
              label="체크리스트 제목"
              placeHolder="체크리스트 제목을 입력 해주세요."
              onChangeValue={(value: string) => {
                updateServiceModel('title', value);
                setLocalModel('title', value);
                setChecklistType('TITLE');
              }}
            />
          </ListBoxItemTemplate>
          <ListBoxItemTemplate>
            <Text type="H4">공구에 연결하기</Text>
            <Text type="H4" buttonRole="DESCRIPTION" style={{ margin: '5px 0' }}>
              공동구매와 연동할 체크리스트를 생성합니다.
            </Text>
            {typeof stateModel?.deals === 'undefined' && (
              <OhmossButton type="square" onClick={getDeals}>
                연결할 공구 불러오기
              </OhmossButton>
            )}
            <div>
              {typeof targetDeal === 'undefined' ? (
                <DealList deals={stateModel?.deals} onClick={handleClickDeal} useContext={false} />
              ) : (
                <div>
                  <div style={{ padding: '20px 0' }}>
                    <DealInfo item={targetDeal} cutOff={1000} useContext={false} />
                  </div>
                  <div style={{ margin: '20px 0' }}>
                    <OhmossButton
                      type="square"
                      style={{ width: 200 }}
                      onClick={() => {
                        setTargetDeal(undefined);
                        setLocalModel('dealId', undefined);
                      }}
                    >
                      선택 취소
                    </OhmossButton>
                  </div>
                </div>
              )}
            </div>
          </ListBoxItemTemplate>
        </Row>
      )}
      <div style={{ margin: '20px 0' }}>
        <OhmossButton
          type="primaryPc"
          disabled={!isValid}
          onClick={handleClickCreateCheckList}
          style={{ width: 300 }}
        >
          Checklist 생성
        </OhmossButton>
      </div>
    </div>
  );
}

const CategorySet = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 18px;
  flex-wrap: wrap;
  /* width: 100%; */
`;
const Category = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 1.25rem;
  font-weight: 700;
  gap: 5px;
  /* width: 110px; */
  width: calc(33.33% - 18px);
  height: 100px;
  max-width: 110px; /* Adjust as needed */
  background-color: ${(props) => (props.selected ? 'rgba(202, 202, 202, 0.3)' : 'white')};
  padding: 0.625rem 1.25rem 0.5rem 1.1875rem;
  align-items: center;
`;

export default ChecklistCategorySelector;
