const REST_API_URL = process.env.REACT_APP_TEST_REST_API_URL;
const REST_API_URL_V2 = process.env.REACT_APP_TEST_REST_API_URL_V2;
const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || '';
const ROOT_HOST = process.env.REACT_APP_HOST;
const TOSSPAY_KEY = process.env.REACT_APP_TOSS_API_KEY;
const KAKAO_REST_API_KEY = process.env.REACT_APP_TEST_KAKAO_REST_API_KEY;
const KAKAO_CLIENT_SECRET_KEY = process.env.REACT_APP_TEST_KAKAO_SECRET_KEY;
const CHAT_GPT_KEY = process.env.REACT_APP_CHAT_GPT_KEY;
const SEND_BIRD_KEY = process.env.REACT_APP_SENDBIRD_KEY;
const REACT_TOSS_SUCCESS_URL = process.env.REACT_APP_TOSS_SUCCESS;
const REACT_TOSS_FAIL_URL = process.env.REACT_APP_TOSS_FAIL;
const SENDBIRD_APP_ID = process.env.REACT_APP_SENDBIRD_APP_ID;

export {
  ROOT_HOST,
  REST_API_URL,
  REST_API_URL_V2,
  KAKAO_CLIENT_SECRET_KEY,
  KAKAO_REST_API_KEY,
  GOOGLE_MAP_API_KEY,
  GOOGLE_CLIENT_ID,
  TOSSPAY_KEY,
  CHAT_GPT_KEY,
  SEND_BIRD_KEY,
  REACT_TOSS_SUCCESS_URL,
  REACT_TOSS_FAIL_URL,
  SENDBIRD_APP_ID,
};
