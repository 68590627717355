import React from 'react';
import MemberList from '../components/MemberList';

const MembersManagementPage = () => {
  return (
    <>
      <MemberList />
    </>
  );
};

export default MembersManagementPage;
