import React, { useRef, useLayoutEffect, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { useSearchParams } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
import { AdDto, ChannelBoardDto, PostDto } from 'services/data-contracts';
import { toast } from 'react-toastify';

import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import AdBoardItem from 'features/board/containers/AdBoardItem';
import EmptyArticle from 'components/commons/EmptyArticle';
import useChannelSelector from 'libs/hooks/useChannelSelector';
import AddIcon from '@mui/icons-material/Add';

import AdPostItem from 'features/board/containers/AdPostItem';
import PaginationBase from 'features/board/containers/PaginationBase';
import styled from 'styled-components';
import { OhmossButton } from 'resources/controls/OhmossButton';
import componentMap from '.';

/**
 * @description 모든 링크를 생성하기 위한 컨테이너임
 */
const AdManagementContainer = observer(({ type }: { type?: string }) => {
  type NewStatus = AdDto['status'] | 'ACTIVE' | 'INACTIVE';
  const { navigate } = useNavigation();

  const { myPostStore, myAdStore, uiStore, myChannelStore, myBoardStore, adCategoryStore } =
    useStore();
  const { initializeFirstChannel } = useChannelSelector();

  const [value, setValue] = React.useState<string>('1');
  const [searchParams] = useSearchParams();
  const defaultAdBoardId = useRef<number>();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    window.history.replaceState('', '', `/deals/settings/myad?type=${newValue}`);
    fetchData(newValue);
  };

  const handleClickItem = (adItem: AdDto) => {
    navigate(
      `/deals/settings/myad/detail/${adItem.channel?.id}/${adItem?.post?.board?.id}/${adItem?.post?.id}`,
    );
  };

  const handleClickPostItem = (adItem: PostDto) => {
    navigate(
      `/deals/settings/myad/detail/${adItem.channel?.id}/${adItem?.board?.id}/${adItem?.id}`,
    );
  };

  const handleClickNew = async () => {
    const boards = (await myBoardStore.getBoards(
      myChannelStore.currentChannel?.id as number,
    )) as never as ChannelBoardDto[];
    const defaultBoard = boards?.find((board: ChannelBoardDto) => board.isDefault);
    if (defaultBoard) {
      navigate(
        `/deals/myad/new?channelid=${myChannelStore.currentChannel?.id}&boardid=${
          defaultBoard.id
        }&posttype=${Number(value) + 1}`,
      );
    } else {
      alert('페이지명이 바르지 않습니다. 페이지 새로고침 후 다시 시도 해주세요.');
    }
  };
  const handleClickShowMore = (post: AdDto | PostDto, status: NewStatus) => {
    if (typeof status !== 'undefined') {
      const ContextMenu = componentMap[status];
      uiStore.bottomSheet.show({
        children: <ContextMenu post={post} />,
      });
    }
  };
  const handleChangePage = (pageNo: number) => {
    fetchData(value, pageNo - 1);
  };

  const fetchData = (type: string, page?: number) => {
    switch (type) {
      case '1':
        myPostStore.getPosts(
          myChannelStore.currentChannel?.id as number,
          defaultAdBoardId.current as number,
          { isActive: true, sort: 'createDate,DESC', page },
        );
        break;
      case '2':
        myPostStore.getPosts(
          myChannelStore.currentChannel?.id as number,
          defaultAdBoardId.current as number,
          { isActive: false, sort: 'createDate,DESC', page },
        );
        break;
      default:
        myAdStore.getMyAds(myChannelStore.currentChannel?.id as number, {
          sort: 'createDate,DESC',
          page,
        });
        break;
    }
  };

  const init = async () => {
    await initializeFirstChannel();
    await myBoardStore.getBoards(myChannelStore.currentChannel?.id as number);
    const defaultAdBoard = myBoardStore.myBoards.find((board: ChannelBoardDto) => board.isDefault);
    if (!defaultAdBoard) {
      toast('광고보드가 없습니다. 고객센터로 문의 해 주세요.');
    } else {
      defaultAdBoardId.current = defaultAdBoard.id;
      adCategoryStore.getAdCategories();
    }

    const type = searchParams.get('type');
    if (type) {
      setValue(type);
      fetchData(type);
    } else {
      fetchData('1');
    }
  };

  useLayoutEffect(() => {
    init();
  }, [type]);

  useEffect(() => {
    console.log(myPostStore.posts);
  }, [myPostStore.posts]);
  return (
    <>
      <TabContext value={value}>
        <TabList
          sx={{ '&. MuiTabs-flexContainer': { justifyContent: 'space-between' } }}
          onChange={handleChange}
          aria-label="lab API tabs example"
        >
          <Tab label="게시물" value="1" />
          <Tab label="비공개" value="2" />
          <Tab label="광고관리" value="3" />
        </TabList>
        <TabPanel value="1" sx={{ '&.MuiTabPanel-root': { background: '#fff' } }}>
          <>
            <PaginationBase
              paginationType="pager"
              totalPages={myPostStore.postPaignation?.totalPages}
              onChangePage={handleChangePage}
            >
              {myPostStore.posts?.map((post: PostDto) => (
                <>
                  <AdPostItem
                    post={post}
                    onClickPost={() => {
                      handleClickPostItem(post);
                    }}
                    useAdmin
                    onClickDeal={() => {
                      navigate(`/deals/settings/myad/newdeal/${post.id}`);
                    }}
                    onShowMore={() => {
                      handleClickShowMore(post, 'ACTIVE');
                    }}
                  />
                </>
              ))}
            </PaginationBase>
            {myPostStore.posts?.length === 0 && <EmptyArticle title="" />}
          </>
        </TabPanel>
        <TabPanel value="2">
          <PaginationBase
            paginationType="pager"
            totalPages={myPostStore.postPaignation?.totalPages}
            onChangePage={handleChangePage}
          >
            {myPostStore.posts?.map((post: PostDto) => (
              <>
                <AdPostItem
                  post={post}
                  onClickPost={() => {
                    handleClickPostItem(post);
                  }}
                  useAdmin
                  onClickDeal={() => {
                    navigate(`/deals/settings/myad/newdeal/${post.id}`);
                  }}
                  onShowMore={() => {
                    handleClickShowMore(post, 'INACTIVE');
                  }}
                />
              </>
            ))}
            {myPostStore.posts?.length === 0 && <EmptyArticle title="비공개" />}
          </PaginationBase>
        </TabPanel>
        <TabPanel value="3">
          <PaginationBase
            paginationType="pager"
            totalPages={myAdStore.postPaignation?.totalPages}
            onChangePage={handleChangePage}
          >
            {myAdStore.myAds?.map((adItem: AdDto) => (
              <>
                <AdBoardItem
                  post={adItem.post || adItem}
                  status={adItem?.status}
                  onClickPost={() => {
                    handleClickItem(adItem);
                  }}
                  currentBoard={adItem.post?.board!}
                  useAdmin
                  onShowMore={(status: AdDto['status']) => {
                    handleClickShowMore(adItem, status);
                  }}
                />
              </>
            ))}
            {myAdStore.myAds?.length === 0 && <EmptyArticle title="광고" />}
          </PaginationBase>
        </TabPanel>
      </TabContext>
      {value !== '3' && (
        <>
          <div style={{ height: 50 }}> </div>
          <NewButtonStyle>
            <OhmossButton onClick={handleClickNew}>
              <AddIcon />새 게시물
            </OhmossButton>
          </NewButtonStyle>
        </>
      )}
    </>
  );
});

const NewButtonStyle = styled.div`
  justify-content: center;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 20px;
  text-align: center;
`;

export default AdManagementContainer;
