import React, { CSSProperties, useEffect } from 'react';
import styled from 'styled-components';
import { Row } from 'components/commons/layouts';
import { IPopUpBlock, IProfileBlock } from 'types/BlockExtendsTypes';
import { useStore } from 'stores/StoreHelper';
import CommonThumbnail from '../../commonControl/CommonThumbnail';
import PopUpModal from '../PopUpModal';

const PopUpTemplate = ({
  item,
  textStyle,
  boxStyle,
  editMode,
  onClick,
}: {
  item: IPopUpBlock;
  textStyle?: CSSProperties;
  boxStyle?: CSSProperties;
  editMode?: boolean;
  onClick: () => void;
}) => {
  const { uiStore } = useStore();

  const handleOnClick = () => {
    // if (editMode !== true) {
    uiStore.modal.show({
      children: <PopUpModal item={item} editMode={true} />,
      modalType: 'modal',
      style: {
        maxHeight: '80%',
      },
    });
    // }
  };

  return (
    <ContainerStyle onClick={handleOnClick}>
      <Row style={{ justifyContent: 'flex-start' }}>
        <div style={{ marginRight: item.thumbnail?.publicUrl && 10 }}>
          <div style={{ padding: '5px 0' }}>
            <CommonThumbnail
              parent={boxStyle?.borderRadius}
              initialValue={item.thumbnail?.publicUrl}
              style={{
                borderRadius: boxStyle?.borderRadius,
                minHeight: boxStyle?.minHeight,
                minWidth: boxStyle?.minHeight,
              }}
            />
          </div>
        </div>
        <ContentWrapper
          style={{
            padding: !item.thumbnail?.publicUrl ? '10px' : 0,
          }}
        >
          <div style={{ ...textStyle, justifyContent: item.contentAlign }}>{item.title}</div>
        </ContentWrapper>
      </Row>
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  padding: 0px 5px;
  padding-right: 10px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  white-space: pre-line;
  > div:first-child {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
`;

export default PopUpTemplate;
