/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { DealInquiryDto, DealInquiryThreadDto, MessageDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class CustomerInquiryService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags CustomerInquiryService
   * @name CloseCustomerInquiry
   * @summary 나의 문의 종료처리
   * @request PUT:/v2/me/inquiries/{inquiryId}/close
   * @secure
   */
  closeCustomerInquiry = (
    inquiryId: number,
    query: {
      /** @format int64 */
      ownChannelId: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DealInquiryDto, any>({
      path: `/v2/me/inquiries/${inquiryId}/close`,
      method: 'PUT',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags CustomerInquiryService
   * @name GetCustomerInquiries
   * @summary 나의 문의 목록
   * @request GET:/v2/me/inquiries
   * @secure
   */
  getCustomerInquiries = (
    query: {
      /** @format int64 */
      ownChannelId: number;
      status?: 'PENDING' | 'ANSWERED' | 'CLOSED';
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DealInquiryDto[], any>({
      path: `/v2/me/inquiries`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags CustomerInquiryService
   * @name CreateCustomerInquiry
   * @summary 나의 문의 생성
   * @request POST:/v2/me/inquiries
   * @secure
   */
  createCustomerInquiry = (
    query: {
      /** @format int64 */
      ownChannelId: number;
    },
    data: DealInquiryDto,
    params: RequestParams = {},
  ) =>
    this.http.request<DealInquiryDto, any>({
      path: `/v2/me/inquiries`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags CustomerInquiryService
   * @name GetCustomerInquiryThreads
   * @summary 나의 질문/답변 목록
   * @request GET:/v2/me/inquiries/{inquiryId}/threads
   * @secure
   */
  getCustomerInquiryThreads = (
    inquiryId: number,
    query: {
      /** @format int64 */
      ownChannelId: number;
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DealInquiryThreadDto[], any>({
      path: `/v2/me/inquiries/${inquiryId}/threads`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags CustomerInquiryService
   * @name AskQuestion
   * @summary 질문하기
   * @request POST:/v2/me/inquiries/{inquiryId}/threads
   * @secure
   */
  askQuestion = (
    inquiryId: number,
    query: {
      /** @format int64 */
      ownChannelId: number;
    },
    data: MessageDto,
    params: RequestParams = {},
  ) =>
    this.http.request<DealInquiryThreadDto, any>({
      path: `/v2/me/inquiries/${inquiryId}/threads`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags CustomerInquiryService
   * @name DeleteQuestion
   * @summary 질문삭제
   * @request POST:/v2/me/inquiries/{inquiryId}/threads/{threadId}
   * @secure
   */
  deleteQuestion = (
    inquiryId: number,
    threadId: number,
    query: {
      /** @format int64 */
      ownChannelId: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/v2/me/inquiries/${inquiryId}/threads/${threadId}`,
      method: 'POST',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags CustomerInquiryService
   * @name GetCustomerInquiry
   * @summary 나의 문의 정보
   * @request GET:/v2/me/inquiries/{inquiryId}
   * @secure
   */
  getCustomerInquiry = (
    inquiryId: number,
    query: {
      /** @format int64 */
      ownChannelId: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DealInquiryDto, any>({
      path: `/v2/me/inquiries/${inquiryId}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
