/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { DailyCountSeriesDto, LocationCountSeriesDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class MyAnalyticService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 지역별 딜 외부이동 클릭 수
   *
   * @tags MyAnalyticService
   * @name GetRegionOutboundClickCountsByDeal
   * @summary 지역별 딜 외부이동 클릭 수
   * @request GET:/v2/me/analytics/deals/{dealId}/region-outbound-clicks
   * @secure
   */
  getRegionOutboundClickCountsByDeal = (dealId: number, params: RequestParams = {}) =>
    this.http.request<LocationCountSeriesDto[], any>({
      path: `/v2/me/analytics/deals/${dealId}/region-outbound-clicks`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 지역별 딜 클릭 수
   *
   * @tags MyAnalyticService
   * @name GetRegionClickCountsByDeal
   * @summary 지역별 딜 클릭 수
   * @request GET:/v2/me/analytics/deals/{dealId}/region-clicks
   * @secure
   */
  getRegionClickCountsByDeal = (dealId: number, params: RequestParams = {}) =>
    this.http.request<LocationCountSeriesDto[], any>({
      path: `/v2/me/analytics/deals/${dealId}/region-clicks`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 유입경로 딜 외부이동 클릭 수
   *
   * @tags MyAnalyticService
   * @name GetRefererOutboundClickCountsByDeal
   * @summary 유입경로 딜 외부이동 클릭 수
   * @request GET:/v2/me/analytics/deals/{dealId}/referer-outbound-clicks
   * @secure
   */
  getRefererOutboundClickCountsByDeal = (dealId: number, params: RequestParams = {}) =>
    this.http.request<LocationCountSeriesDto[], any>({
      path: `/v2/me/analytics/deals/${dealId}/referer-outbound-clicks`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 유입경로 딜 클릭 수
   *
   * @tags MyAnalyticService
   * @name GetRefererClickCountsByDeal
   * @summary 유입경로 딜 클릭 수
   * @request GET:/v2/me/analytics/deals/{dealId}/referer-clicks
   * @secure
   */
  getRefererClickCountsByDeal = (dealId: number, params: RequestParams = {}) =>
    this.http.request<LocationCountSeriesDto[], any>({
      path: `/v2/me/analytics/deals/${dealId}/referer-clicks`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 일별 딜 외부이동 클릭 수
   *
   * @tags MyAnalyticService
   * @name GetDailyOutboundClickCountsByDeal
   * @summary 일별 딜 외부이동 클릭 수
   * @request GET:/v2/me/analytics/deals/{dealId}/daily-outbound-clicks
   * @secure
   */
  getDailyOutboundClickCountsByDeal = (dealId: number, params: RequestParams = {}) =>
    this.http.request<DailyCountSeriesDto[], any>({
      path: `/v2/me/analytics/deals/${dealId}/daily-outbound-clicks`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 일별 딜 팔로워 수
   *
   * @tags MyAnalyticService
   * @name GetDailyFollowerCountsByDeal
   * @summary 일별 딜 팔로워 수
   * @request GET:/v2/me/analytics/deals/{dealId}/daily-follower-counts
   * @secure
   */
  getDailyFollowerCountsByDeal = (dealId: number, params: RequestParams = {}) =>
    this.http.request<DailyCountSeriesDto[], any>({
      path: `/v2/me/analytics/deals/${dealId}/daily-follower-counts`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 일별 딜 앵콜 수
   *
   * @tags MyAnalyticService
   * @name GetDailyEncoreCountsByDeal
   * @summary 일별 딜 앵콜 수
   * @request GET:/v2/me/analytics/deals/{dealId}/daily-encore-counts
   * @secure
   */
  getDailyEncoreCountsByDeal = (
    dealId: number,
    query: {
      /** @format date */
      startDate: string;
      /** @format date */
      endDate: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DailyCountSeriesDto[], any>({
      path: `/v2/me/analytics/deals/${dealId}/daily-encore-counts`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 일별 딜 클릭 수
   *
   * @tags MyAnalyticService
   * @name GetDailyClickCountsByDeal
   * @summary 일별 딜 클릭 수
   * @request GET:/v2/me/analytics/deals/{dealId}/daily-clicks
   * @secure
   */
  getDailyClickCountsByDeal = (dealId: number, params: RequestParams = {}) =>
    this.http.request<DailyCountSeriesDto[], any>({
      path: `/v2/me/analytics/deals/${dealId}/daily-clicks`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 국가별 딜 외부이동 클릭 수
   *
   * @tags MyAnalyticService
   * @name GetCountryOutboundClickCountsByDeal
   * @summary 국가별 딜 외부이동 클릭 수
   * @request GET:/v2/me/analytics/deals/{dealId}/country-outbound-clicks
   * @secure
   */
  getCountryOutboundClickCountsByDeal = (dealId: number, params: RequestParams = {}) =>
    this.http.request<LocationCountSeriesDto[], any>({
      path: `/v2/me/analytics/deals/${dealId}/country-outbound-clicks`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 국가별 딜 클릭 수
   *
   * @tags MyAnalyticService
   * @name GetCountryClickCountsByDeal
   * @summary 국가별 딜 클릭 수
   * @request GET:/v2/me/analytics/deals/{dealId}/country-clicks
   * @secure
   */
  getCountryClickCountsByDeal = (dealId: number, params: RequestParams = {}) =>
    this.http.request<LocationCountSeriesDto[], any>({
      path: `/v2/me/analytics/deals/${dealId}/country-clicks`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 채널 방문자 수
   *
   * @tags MyAnalyticService
   * @name GetVisitCountByChannel
   * @summary 채널 방문자 수
   * @request GET:/v2/me/analytics/channels/{channelId}/visit-count
   * @secure
   */
  getVisitCountByChannel = (channelId: number, params: RequestParams = {}) =>
    this.http.request<number, any>({
      path: `/v2/me/analytics/channels/${channelId}/visit-count`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 전체 링크 외부이동 클릭 수
   *
   * @tags MyAnalyticService
   * @name GetTotalLinkOutboundClickCountByChannel
   * @summary 전체 링크 외부이동 클릭 수
   * @request GET:/v2/me/analytics/channels/{channelId}/link-outbound-click-count
   * @secure
   */
  getTotalLinkOutboundClickCountByChannel = (channelId: number, params: RequestParams = {}) =>
    this.http.request<number, any>({
      path: `/v2/me/analytics/channels/${channelId}/link-outbound-click-count`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 전체 링크 클릭 수
   *
   * @tags MyAnalyticService
   * @name GetTotalLinkClickCountByChannel
   * @summary 전체 링크 클릭 수
   * @request GET:/v2/me/analytics/channels/{channelId}/link-click-count
   * @secure
   */
  getTotalLinkClickCountByChannel = (channelId: number, params: RequestParams = {}) =>
    this.http.request<number, any>({
      path: `/v2/me/analytics/channels/${channelId}/link-click-count`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 채널 팔로워 수
   *
   * @tags MyAnalyticService
   * @name GetFollowerCountByChannel
   * @summary 채널 팔로워 수
   * @request GET:/v2/me/analytics/channels/{channelId}/follower-count
   * @secure
   */
  getFollowerCountByChannel = (channelId: number, params: RequestParams = {}) =>
    this.http.request<number, any>({
      path: `/v2/me/analytics/channels/${channelId}/follower-count`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 일별 채널 방문자 수
   *
   * @tags MyAnalyticService
   * @name GetDailyVisitCountsByChannel
   * @summary 일별 채널 방문자 수
   * @request GET:/v2/me/analytics/channels/{channelId}/daily-visit-counts
   * @secure
   */
  getDailyVisitCountsByChannel = (
    channelId: number,
    query: {
      /** @format date */
      startDate: string;
      /** @format date */
      endDate: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DailyCountSeriesDto[], any>({
      path: `/v2/me/analytics/channels/${channelId}/daily-visit-counts`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 일별 전체 링크 외부이동 클릭 수
   *
   * @tags MyAnalyticService
   * @name GetDailyTotalLinkOutboundClickCountsByChannel
   * @summary 일별 전체 링크 외부이동 클릭 수
   * @request GET:/v2/me/analytics/channels/{channelId}/daily-link-outbound-click-counts
   * @secure
   */
  getDailyTotalLinkOutboundClickCountsByChannel = (
    channelId: number,
    query: {
      /** @format date */
      startDate: string;
      /** @format date */
      endDate: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DailyCountSeriesDto[], any>({
      path: `/v2/me/analytics/channels/${channelId}/daily-link-outbound-click-counts`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 일별 전체 링크 클릭 수
   *
   * @tags MyAnalyticService
   * @name GetDailyTotalLinkClickCountsByChannel
   * @summary 일별 전체 링크 클릭 수
   * @request GET:/v2/me/analytics/channels/{channelId}/daily-link-click-counts
   * @secure
   */
  getDailyTotalLinkClickCountsByChannel = (
    channelId: number,
    query: {
      /** @format date */
      startDate: string;
      /** @format date */
      endDate: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DailyCountSeriesDto[], any>({
      path: `/v2/me/analytics/channels/${channelId}/daily-link-click-counts`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 일별 채널 팔로워 수
   *
   * @tags MyAnalyticService
   * @name GetDailyFollowerCountsByChannel
   * @summary 일별 채널 팔로워 수
   * @request GET:/v2/me/analytics/channels/{channelId}/daily-follower-counts
   * @secure
   */
  getDailyFollowerCountsByChannel = (
    channelId: number,
    query: {
      /** @format date */
      startDate: string;
      /** @format date */
      endDate: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DailyCountSeriesDto[], any>({
      path: `/v2/me/analytics/channels/${channelId}/daily-follower-counts`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
