import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ChatModel, DealServiceItemModel } from 'types/CommonTypes';
import { IDealBlock } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';
import { validationHelper } from 'controls/validationHelper';
import { useStore } from 'stores/StoreHelper';
import ChatContainer from 'features/csTown/containers/ChatContainer';
import { toast } from 'react-toastify';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import CustomerChatContainer from 'components/commons/chat/containers/CustomerChatContainer';
import { Avatar } from '@mui/material';
import chatController from 'features/community/controllers/chatController';
import { useChat } from 'libs/hooks/useChat';
import LinkServicePageController from 'features/linkService/pages/pageControllers/LinkServicePageController';

const DealServiceItems = ({
  // useInquiry 가 false인 경우는 관리자에서 호출하는 경우임
  useInquiry = true,
  item,
  parentBorderRadius,
}: {
  useInquiry?: boolean;
  item?: IDealBlock;
  parentBorderRadius?: number;
}) => {
  const { uiStore } = useStore();
  const { myChannel } = useRootContext();
  const { handleClickInquiry } = LinkServicePageController();

  const { initChat } = useChat();

  const getContent = (content?: string) => {
    const tel = validationHelper.tel(content);
    const email = validationHelper.email(content);
    if (tel) {
      return <a href={`tel:${content}`}>{content}</a>;
    } else if (email) {
      return <a href={`mailto:${content}`}>{content}</a>;
    }
    return content;
  };

  // const handleClickInquiry = () => {
  //   if (myChannel) {
  //     if (myChannel?.id !== item?.channel?.id) {
  //       beginChat(item);
  //     } else {
  //       toast.warn('본인이 올린 공구에는 문의를 할 수 없습니다.');
  //     }
  //   } else {
  //     uiStore.universalModal.show({
  //       modalOption: { style: { maxWidth: 500, maxHeight: 400 } },
  //       children: <ConfirmLogin />,
  //     });
  //   }
  // };

  // const handleClickInquiry = () => {
  //   if (myChannel) {
  //     if (myChannel?.id !== item?.channel?.id) {
  //       const chatInfo: ChatModel = {
  //         members: [myChannel],
  //         operators: [item?.channel!, myChannel],
  //         deal: item,
  //       };
  //       initChat(myChannel.channelName as string);
  //       uiStore.modal.show({
  //         style: { height: '80vh', maxHeight: '80vh' },
  //         // 일반 고객 채팅
  //         children: useInquiry ? (
  //           <CustomerChatContainer chatInfo={chatInfo} seller={currentChannel} />
  //         ) : (
  //           // 관리 페이지에서 사용
  //           <ChatContainer chatInfo={chatInfo} type="new" />
  //         ),
  //       });
  //     } else {
  //       toast.warn('본인이 올린 공구에는 문의를 할 수 없습니다.');
  //     }
  //   }
  // };

  return (
    <ContainerStyle round={parentBorderRadius}>
      <>
        {item?.useInquiry && useInquiry && (
          <DealItemContainerStyle style={{ cursor: 'pointer' }}>
            <Avatar src="/images/cstown/manager.svg" />
            <InquiryStyle
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={() => {
                handleClickInquiry(item);
              }}
            >
              톡으로 문의하기
            </InquiryStyle>
          </DealItemContainerStyle>
        )}
      </>
      <Row style={{ flexWrap: 'wrap' }}>
        {item?.items
          ?.filter((item) => item.isActive)
          .map((serviceItem: DealServiceItemModel) => (
            <DealItemContainerStyle>
              <Row>
                <ServiceIconStyle>{serviceItem?.icon}</ServiceIconStyle>
                <div>{serviceItem?.title}</div>
                <div style={{ marginLeft: 10 }}>{getContent(serviceItem?.content)}</div>
              </Row>
            </DealItemContainerStyle>
          ))}
      </Row>
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div<{ round?: number }>`
  width: 100%;
  padding-bottom: 10px;
`;

const DealItemContainerStyle = styled.div`
  background: #fff;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
`;

const InquiryStyle = styled.div`
  background: #efefef;
  padding: 8px 20px;
  border-radius: 20px;
  align-items: center;
  display: -ms-flexbox;
`;

const ServiceIconStyle = styled.div`
  background: #efefef;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 40px;
`;
export default DealServiceItems;
