import React, { useEffect, useState, CSSProperties } from 'react';
import styled from 'styled-components';

import { ITextBlock } from 'types/BlockExtendsTypes';
import { useStore } from 'stores/StoreHelper';

const TextLinkTemplate = ({
  item,
  boxStyle,
  textStyle,
}: {
  item?: ITextBlock;
  boxStyle?: CSSProperties;
  textStyle?: CSSProperties;
}) => {
  const [style, setStyle] = useState<CSSProperties>();
  const [align, setAlign] = useState<string>();

  useEffect(() => {
    if (item?.contentStyle) {
      const innerStyle = JSON.parse(item.contentStyle);
      if (item.useTransparent) {
        innerStyle.background = 'transparent';
      }
      setStyle(innerStyle);
      switch (item.contentAlign) {
        case 'left':
          setAlign('flex-start');
          break;
        case 'right':
          setAlign('flex-end');
          break;
        default:
          setAlign('center');
          break;
      }
    }
  }, [item?.contentStyle, boxStyle]);

  return (
    <ContainerStyle
      style={{
        background: style?.background,
        // ...boxStyle,
        ...style,
        // minHeight: boxStyle?.minHeight,
      }}
    >
      <ContentStyle
        style={{
          textAlign: (item?.contentAlign as 'left' | 'right' | 'center') ?? 'center',
          justifyContent: align,
          flexDirection: 'column',
          ...style,
        }}
      >
        {item?.showTitle && <div style={{ ...textStyle, fontWeight: 700 }}>{item?.title}</div>}
        {item?.content}
      </ContentStyle>
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  cursor: pointer;
  /* border-radius: 10px; */
  width: 100%;
  min-height: 90px;
  display: flex;
  padding: 0px 10px;
`;

const ContentStyle = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
  padding: 10px;
  white-space: pre-line;
`;
export default TextLinkTemplate;
