import React, { useCallback, useState } from 'react';
import { ChannelProfileModel } from 'types/CommonTypes';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { ToggleButton, ToggleButtonType } from 'components/commons/ToggleButton';
import { t } from 'i18next';
import { updateProfile } from 'components/items/commonLogic';
import { ItemSectionStyle } from 'resources/controls/ItemSectionStyle';

import styled from 'styled-components';
import ToggleContainerStyle from 'resources/controls/ToggleContainerStyle';
import ProfileSocialComponent from '../components/ProfileSocialComponent';
import ProfileFooterComponent from '../components/ProfileFooterComponent';
import ProfileComponent from '../components/ProfileComponent';

const ProfileList: Array<ToggleButtonType> = [
  {
    id: 'Profile',
    iconSrc: '/images/image.svg',
    description: t('A158'),
    checked: 'checked',
  },
  {
    id: 'Socials',
    iconSrc: '/images/colorfilter.svg',
    description: t('A159'),
  },
  {
    id: 'Footer',
    iconSrc: '/images/colorfilter.svg',
    description: t('A160'),
  },
];

const ProfileContainer = observer(() => {
  const { t } = useTranslation();
  const { fileStore, uiStores, myChannelStore } = useStore();
  const { profileStore } = uiStores;

  const [profileType, setProfileType] = useState<string>('Profile');

  const handleClickType = (item: ToggleButtonType) => {
    setProfileType(item.id);
  };

  const getLinkContainer = useCallback(() => {
    switch (profileType) {
      case 'Profile':
        return <ProfileComponent onSave={handleClickSaveProfile} />;
      case 'Footer':
        return <ProfileFooterComponent onSave={handleClickSaveProfile} />;
      case 'Socials':
        return <ProfileSocialComponent onSave={handleClickSaveProfile} />;
      default:
        return <></>;
    }
  }, [profileType]);

  const handleClickSaveProfile = async (
    profile?: ChannelProfileModel,
    type?: 'Profile' | 'Footer',
  ) => {
    if (typeof profile === 'undefined') {
      profile = profileStore.profile;
    }

    updateProfile(profile, fileStore, myChannelStore, profileStore.profileMetadataObject, type);
  };

  return (
    <div style={{ margin: 20 }}>
      <ItemSectionStyle style={{ background: 'transparent', boxShadow: 'none' }}>
        <ToggleButtonStyle>
          {ProfileList.map((itemProfileType) => (
            <ToggleContainerStyle
              key={itemProfileType?.id}
              onClick={() => {
                handleClickType(itemProfileType);
              }}
            >
              <ToggleButton
                item={itemProfileType}
                defaultChecked={profileType === itemProfileType.id}
              />
            </ToggleContainerStyle>
          ))}
        </ToggleButtonStyle>
      </ItemSectionStyle>
      <div>{getLinkContainer()}</div>
    </div>
  );
});

const ToggleButtonStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  @media ${(props) => props.theme.media.mobile} {
    flex-direction: column;
  }
`;

export default ProfileContainer;
