import { useStore } from 'stores/StoreHelper';

import FileStore from 'stores/FileStore';
import MyChannelStore from 'stores/MyChannelStore';
import { ChannelProfileDtoDummy } from 'features/management/context/dummy';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import { ProfileModel } from 'types/CommonTypes';
import { ChannelDto, ChannelProfileDto } from 'services/data-contracts';
import { CustomFile } from 'types/BlockExtendsTypes';
import { useRootContext } from 'libs/hooks/useRootContext';

/**
 * @description 각 Block의 등록/수정/삭제를 수행함.
 * @returns
 */
const ProfileController = () => {
  const fileStore = new FileStore();
  const myChannelStore = new MyChannelStore();
  const serviceItems = useModelManagement<ChannelProfileDto>();

  const { extractAdditionalProperties } = serviceItems;
  const { xhrStore } = useStore();
  const { myChannel } = useRootContext();

  /**
   * @description 채널의 프로필을 업데이트한다.
   * @param standAlonModel
   * @returns
   */
  const updateProfile = async (serviceModel: ChannelProfileDto) => {
    try {
      if (typeof serviceModel === 'undefined') {
        return;
      }

      if ((serviceModel?.profileImage as CustomFile)?.blob) {
        const file = (await fileStore.uploadImage(
          (serviceModel?.profileImage as CustomFile)?.blob,
          'Profile',
        )) as any;
        serviceModel.profileImage = { id: file.id };
      }

      if ((serviceModel?.backgroundImage as CustomFile)?.blob) {
        const file = (await fileStore.uploadImage(
          (serviceModel?.backgroundImage as CustomFile)?.blob,
          'Background',
        )) as any;
        serviceModel.backgroundImage = { id: file.id };
      }

      if ((serviceModel?.profileBackgroundImage as CustomFile)?.blob) {
        const file = (await fileStore.uploadImage(
          (serviceModel?.profileBackgroundImage as CustomFile)?.blob,
          'Profile',
        )) as any;
        serviceModel.profileBackgroundImage = { id: file.id };
      }

      // extended로 확장된 properties를 추출한다.
      const extracted = extractAdditionalProperties<ProfileModel, ChannelProfileDto>(
        serviceModel,
        ChannelProfileDtoDummy,
      );
      if (typeof extracted !== 'undefined') {
        serviceModel.metadata = JSON.stringify(extracted);
        //  newModel.metadata = '{}';
      }

      const result = (await myChannelStore.updateItem(
        myChannel?.id as number,
        serviceModel,
      )) as unknown as ChannelDto;

      return result;
    } catch (e) {
      console.log(e);
    }
  };

  return {
    updateProfile,
  };
};

export default ProfileController;
