import React, { CSSProperties, ReactChild } from 'react';
import styled from 'styled-components';

const Row = ({
  style,
  direction,
  children,
  className,
  onClick,
}: {
  style?: CSSProperties;
  direction?: 'row' | 'column';
  className?: string;
  children?: JSX.Element | JSX.Element[];
  onClick?: () => void;
}) => {
  return (
    <RowStyle
      className={className}
      style={{ flexDirection: direction, ...style }}
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick();
        }
      }}
    >
      {children}
    </RowStyle>
  );
};

const RowStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  > div {
  }
  > div:last-child {
    margin-right: 0px;
  }
`;

export default Row;
