import React, { useState } from 'react';
import {
  Chip,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  ListItemText,
  Checkbox,
  Box,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { PushDto } from 'services/data-contracts_bak';

interface UserInfo {
  name: string;
  userId: number;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const users: Array<UserInfo> = [
  { name: 'tgkim', userId: 24 },
  { name: 'gemmachoi', userId: 81 },
];

const Step3 = ({
  model,
}: {
  model: [PushDto | undefined, React.Dispatch<React.SetStateAction<PushDto | undefined>>];
}) => {
  const dispatch = model[1];

  const [linkType, setLinkType] = useState<string>('internal');
  const [selectedTarget, setSelectedTarget] = useState<Array<UserInfo>>([]);

  const handleLinkTypeChange = (e: any, value: string) => {
    setLinkType(value);
  };

  const handleChangeTag = (event: SelectChangeEvent<typeof selectedTarget>) => {
    if (typeof event.target.value !== 'string') {
      setSelectedTarget(event.target.value);
      const t = event.target.value.map((temp) => temp.userId);
      dispatch((prevState: PushDto) => {
        return { ...prevState, userIds: t };
      });
    }
  };

  return (
    <>
      <div>
        <FormControl sx={{ m: 1, width: 300, marginLeft: 0 }}>
          <InputLabel id="demo-multiple-checkbox-label">Targets</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedTarget}
            onChange={handleChangeTag}
            input={<OutlinedInput label="Targets" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((user: UserInfo) => (
                  <Chip key={user.userId} label={user.name} />
                ))}
              </Box>
            )}
          // MenuProps={MenuProps}
          >
            {users.map((user: UserInfo) => (
              <MenuItem key={user.userId} value={user as any}>
                <Checkbox
                  checked={
                    selectedTarget.find((u: UserInfo) => u.userId === user.userId) ? true : false
                  }
                />
                <ListItemText primary={user.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={{ marginTop: 30 }}>
        <Chip label="Group A" variant="outlined" onDelete={() => { }} />
      </div>
    </>
  );
};

export default Step3;
