import { makeObservable, observable, action, flow } from 'mobx';
import { MyLink as MyLinkService } from 'services/MyLink';
import { MyLinkService2 } from 'services/MyLinkService2';
import { DealOptionDto, LinkDto } from 'services/data-contracts';

import _ from 'lodash';
import ServiceStoreBase from './ServiceStoreBase';
import { HttpClient, RequestParams } from 'services/http-client';
import i18n from 'i18n';

export interface isValid {
  isValid: boolean;
}

class MyLinkStore extends ServiceStoreBase {
  t = i18n.t;

  links: Array<LinkDto>;

  constructor() {
    super();
    makeObservable(this, {
      links: observable,
      reOrderLinkItem: flow,
      getItemByKey: flow,
      appendLink: action,
      updateLinkByItem: action,
      unMount: action,
    });
  }

  api: MyLinkService<unknown> = new MyLinkService(new HttpClient());
  api2: MyLinkService2<unknown> = new MyLinkService2(new HttpClient());

  *getItem(channelId: number, boardId: number, linkId: number) {
    const { response } = yield this.api.getLink(channelId, boardId, linkId);
    return response.data;
  }

  *getItemByKey(linkKey: string) {
    const { response } = yield this.api2.getLink(linkKey);
    return response.status < 300 ? response.data : null;
  }

  *getItems(channelId: number, boardId: number, query?: any) {
    if (channelId && boardId) {
      const { response } = yield this.api.getLinks(channelId, boardId, query);
      this.links = response.data;
      return response.data;
    } else {
      return [];
    }
  }

  *createItem(channelId: number, boardId: number, linkInfo: LinkDto) {
    const { response } = yield this.api.createLink(channelId, boardId, linkInfo);
    super.handleByStatus(response, { successMessage: this.t('A218') });
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  }

  *updateItem(chanelId: number, boardId: number, linkInfo: LinkDto, hideMessage?: boolean) {
    const { response } = yield this.api.updateLink(
      chanelId,
      boardId,
      linkInfo.id as number,
      linkInfo,
    );
    super.handleByStatus(response, hideMessage ? {} : { successMessage: this.t('A219') });
    if (response.status === 200) {
      return response.data;
    }
  }

  *deleteItem(channelId: number, boardId: number, linkId: number) {
    const { response } = yield this.api.deleteLink(channelId, boardId, linkId);
    return super.handleByStatus(response, { successMessage: this.t('A220') });
  }

  *reOrderLinkItem(channelId: number, boardId: number, linkId: number, linkInfo: LinkDto) {
    yield this.api.updateSeqOfLink(channelId, boardId, linkId, linkInfo);
  }

  appendLink(link: LinkDto) {
    this.links?.unshift(link);
  }

  updateLinkByItem(link: LinkDto, type?: 'delete' | 'update') {
    const index = this.links.findIndex((recentLink: LinkDto) => recentLink.id === link.id);
    const newLinks = [...this.links];
    if (type === 'delete') {
      newLinks.splice(index, 1);
    } else {
      newLinks.splice(index, 1, link);
    }
    this.links = [...newLinks];
  }

  unMount() {
    this.links = [];
  }
}

export default MyLinkStore;
