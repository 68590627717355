import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { DealsTypes } from 'features/management/context/providers/ContextTypes';
import {
  ChannelChecklistCreationDto,
  ChannelChecklistDto,
  SimpleDealDto,
  ChannelChecklistTopicDto,
} from 'services/data-contracts';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import styled from 'styled-components';
import CommonTabButtons from 'components/commons/buttons/CommonTabButtons';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useRootContext } from 'libs/hooks/useRootContext';
import TitleForm from 'components/items/forms/TitleForm';
import { useStore } from 'stores/StoreHelper';
import { Row } from 'components/commons/layouts';
import CommonThumbnail from 'features/management/components/commonControl/CommonThumbnail';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import ChecklistController from 'controllers/ChecklistController';
import { toast } from 'react-toastify';
import DealListSelector from '../containers/checklist/DealListSelector';
import ChecklistTopicContainer from '../containers/checklist/ChecklistTopicContainer';

const ChecklistMorePage = () => {
  const { uiStore } = useStore();
  const location = useLocation();
  const [searchParam] = useSearchParams();
  const { myChannel } = useRootContext();
  const { getChannelDeals } = useOhmossContext<DealsTypes>();
  const {
    createMyChecklist,
    getMyChecklist,
    getChannelChecklistTopic,
    getChecklistsByCategory,
    deleteMyChecklist,
    updateMyChecklist,
  } = ChecklistController();
  const { serviceModel, updateServiceModel } = useModelManagement<ChannelChecklistCreationDto>();

  const [channelDeals, setChannelDeals] = useState<SimpleDealDto[]>([]);
  const [checklistInfo, setChecklistInfo] = useState<ChannelChecklistDto>();
  const [tab, setTab] = useState('체크리스트 상세');
  const [selectedDeal, setSelectedDeal] = useState<SimpleDealDto>();
  const [productName, setProductName] = useState<string>();
  const [topics, setTopics] = useState<ChannelChecklistTopicDto[]>([]);

  const category = searchParam.get('category');
  const id = searchParam.get('id');

  const getDeals = async () => {
    const deals = (await getChannelDeals()) as unknown as SimpleDealDto[];
    setChannelDeals(deals);
  };

  const getChecklistInfo = async () => {
    const result = await getMyChecklist(myChannel?.id as number, Number(id));
    if (result) {
      setChecklistInfo(result);
    }
    if (result?.deal) {
      setSelectedDeal(result?.deal);
    }
  };

  const getTopics = async () => {
    const tempTopics = await getChannelChecklistTopic(myChannel?.id as number, Number(id));
    setTopics(tempTopics);
  };

  const getNewChecklistTopics = async () => {
    const tempTopics = (await getChecklistsByCategory(
      Number(category),
      myChannel?.id as number,
    )) as any;

    if (tempTopics?.items) {
      const uniqueTopics = Array.from(
        new Set(tempTopics?.items.map((item: any) => item.topicName)),
      ).map((topicName) => ({
        topicName,
        progressBar: 0,
      }));
      setTopics(uniqueTopics as ChannelChecklistTopicDto[]);
    }
  };

  const handleSelectDeal = () => {
    uiStore.modal.show({
      title: '공구 선택',
      style: { height: '80vh', padding: '0px 20px 0px 20px' },
      children: <DealListSelector onClick={setSelectedDeal} deals={channelDeals} />,
      onConfirmed(result) {
        if (result) {
          setSelectedDeal(result);
          updateServiceModel('dealId', result?.id);
        }
      },
    });
  };

  const handleConfirm = async () => {
    if ((serviceModel?.dealId || serviceModel?.title) && serviceModel?.categoryId) {
      if (id !== null) {
        await updateMyChecklist(myChannel?.id as number, Number(id), serviceModel);
      } else {
        await createMyChecklist(myChannel?.id as number, serviceModel);
      }
    } else {
      toast.error('필수 정보를 입력해주세요');
    }
  };

  const handleDelete = async () => {
    if (id !== null) {
      uiStore.confirm.show({
        message: '체크리스트를 삭제하시겠습니까?',
        onConfirmed: async () => {
          await deleteMyChecklist(myChannel?.id as number, Number(id));
          window.history.go(-1);
        },
      });
    }
  };

  useEffect(() => {
    if (myChannel) {
      if (id !== null) {
        getDeals();
        getChecklistInfo();
        getTopics();
        updateServiceModel('categoryId', Number(category));
      } else {
        getDeals();
        getNewChecklistTopics();
        updateServiceModel('categoryId', Number(category));
      }
    }
  }, [myChannel]);

  useEffect(() => {
    if (location.pathname) {
      setTimeout(() => {
        const t = document.querySelector('.ohmoss-navigation-tab') as any;
        if (
          (t && location.pathname === '/my/deals/checklist/detail/list') ||
          location.pathname === '/cstown/checklist/detail/list'
        ) {
          t.style.display = 'none';
        } else {
          t.style.display = 'flex';
        }
      }, 0);
    }
  }, [location]);

  return (
    <ManagementLayout style={{ padding: '20px 0 0 0' }}>
      <MainContainer>
        {/* 공구를 선택하지 않은 경우 */}
        {!selectedDeal && (
          <DealContainer>
            <Row
              style={{
                position: 'relative',
                width: 'unset',
                justifyContent: 'flex-start',
              }}
            >
              <CommonThumbnail />
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20 }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 6,
                  }}
                >
                  {typeof checklistInfo !== 'undefined' && (
                    <img
                      src={checklistInfo?.category?.image?.publicUrl}
                      alt={checklistInfo?.title}
                      style={{
                        width: 16,
                        height: 16,
                        borderRadius: '0.625rem',
                        padding: 2,
                        border: '1px solid #D3D3D3',
                        background: '#FFF',
                      }}
                    />
                  )}
                  <span style={{ fontSize: 13 }}>{checklistInfo?.title ?? productName}</span>
                </div>
              </div>
            </Row>
          </DealContainer>
        )}

        <DealSearchContainer>
          {/* 공구를 선택한 경우 */}
          {selectedDeal && (
            <DealContainer>
              <Row
                style={{
                  position: 'relative',
                  width: 'unset',
                  justifyContent: 'flex-start',
                }}
              >
                <CommonThumbnail initialValue={selectedDeal?.thumbnail?.publicUrl} />
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20 }}>
                  <span style={{ fontSize: 13 }}>{selectedDeal?.title}</span>
                </div>
              </Row>
            </DealContainer>
          )}

          {/* 공구를 선택하지 않고  */}
          {!selectedDeal && (
            <TitleForm
              required={false}
              validationType={undefined}
              style={{ borderRadius: 10, fontSize: 12 }}
              placeHolder="상품(브랜드)명을 입력하세요"
              onChangeValue={(value: string) => {
                setProductName(value);
                updateServiceModel('title', value);
              }}
            />
          )}
          <SelectDealBtn onClick={handleSelectDeal}>공구 선택</SelectDealBtn>
        </DealSearchContainer>
      </MainContainer>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <CommonTabButtons items={['체크리스트 상세', '요약보기']} onHandleTabClick={setTab} />
        <ChecklistTopicContainer
          category={category ?? undefined}
          topics={topics}
          checklistId={id}
          tabLabel={tab}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            position: 'absolute',
            width: '100%',
            bottom: 10,
            padding: '0px 20px 0px 20px',
          }}
        >
          <OhmossButton type="danger" onClick={handleDelete}>
            삭제
          </OhmossButton>
          <OhmossButton onClick={handleConfirm}>저장</OhmossButton>
        </div>
      </div>
    </ManagementLayout>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 0px 20px;
`;

const DealContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  /* justify-content: center; */
  border: 1px solid #e0e0e0;
  padding: 5px 10px;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const DealSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 20px;
`;

const SelectDealBtn = styled.button`
  background: #011208;
  color: #0bbd58;
  font-size: 0.875rem;
  font-weight: 500;
  width: 100px;
  padding: 0.3rem 0.3rem;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
`;

export default ChecklistMorePage;
