import React from 'react';
import styled from 'styled-components';
import { LandingPageWrapper } from './LandingPageWrapper';
import { BodyContainer, DetailText, MainText, SubText, TextContainer } from './Styles';

const LandingSlideB = () => {
  return (
    <LandingPageWrapper style={{ backgroundColor: '#EEE' }}>
      <BodyContainer>
        <TextContainer>
          <DetailText>링크</DetailText>
          <MainText>쉽고, 멋지고, 재미있는</MainText>
          <SubText>오모스를 사용해서 몇 분안에 셀러전용 링크를 만들고 꾸며보세요</SubText>
        </TextContainer>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <SideImg>
            <img alt="left" src="/images/landing/slideb_image1.png" style={{ width: '70%' }} />
            <img alt="left" src="/images/landing/slideb_image3.jpg" style={{ width: '70%' }} />
            <CenterImg style={{ overflow: 'hidden' }}>
              <img
                alt="left"
                src="/images/landing/slideb_image2.png"
                style={{ borderRadius: 20, position: 'relative', top: -60 }}
              />
            </CenterImg>
          </SideImg>
        </div>
      </BodyContainer>
    </LandingPageWrapper>
  );
};

const CenterImg = styled.div`
  display: flex;
  width: 90%;
  position: absolute;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0px;
  right: 0;
  margin: auto;
  text-align: center;
  bottom: 0;
  border-radius: 20px;
  z-index: 2;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);

  > img {
    max-width: 100%;
    width: 100%;
  }

  @media ${(props) => props.theme.media.mobile} {
    > img {
      width: 100%;
      /* overflow: hidden; */
      border-radius: 20px;
    }
  }
`;

const SideImg = styled.div`
  display: flex;
  margin-top: 50px;
  flex-direction: row;
  width: 100%;
  /* gap: 100px; */
  position: relative;
  justify-content: space-evenly;
  width: 80%;
  z-index: 1;
  @media screen and (max-height: 690px) {
    margin-top: 50px;
    width: 70%;
  }
  @media ${(props) => props.theme.media.desk} {
    width: 70%;
  }
`;

export default LandingSlideB;
