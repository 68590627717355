import React from 'react';
import faqIcon from 'resources/images/cs/faq.svg';
import noticeIcon from 'resources/images/cs/notice.svg';
import inquiryIcon from 'resources/images/cs/inquiry.svg';
import telIcon from 'resources/images/cs/tel.svg';
import shippingIcon from 'resources/images/cs/shipping.svg';

import DealCustomTypeA from './DealCustomTypeA';
import DealCustomTypeB from './DealCustomTypeB';
import DealCustomTypeC from './DealCustomTypeC';
import DealCustomTypeD from './DealCustomTypeD';
import DealCustomTypeE from './DealCustomTypeE';
import DealCustomTypeF from './DealCustomTypeF';

type DealCustomType = {
  component: React.ComponentType<any>;
  key: 'inquiry' | 'tel' | 'shipping' | 'notice' | 'FAQ';
  isActived?: boolean;
  title: string;
  icon: string;
  contact: string;
};

const DealCustomComponents = {
  inquiry: {
    component: DealCustomTypeA,
    title: '문의사항',
    key: 'inquiry',
    icon: inquiryIcon,
    url: '',
  },
  all: {
    component: DealCustomTypeA,
    title: '문의사항',
    key: 'all',
    icon: inquiryIcon,
    url: '',
  },
  tel: {
    component: DealCustomTypeB,
    title: '고객센터',
    key: 'tel',
    icon: telIcon,
    url: '',
  },
  shipping: {
    component: DealCustomTypeC,
    title: '쇼핑안내',
    key: 'shipping',
    icon: shippingIcon,
    url: '',
  },
  notice: {
    component: DealCustomTypeD,
    title: '공지사항',
    key: 'notice',
    icon: noticeIcon,
    url: '',
  },
  FAQ: {
    component: DealCustomTypeE,
    title: 'FAQ',
    key: 'FAQ',
    icon: faqIcon,
    url: '',
  },
  multi: {
    component: DealCustomTypeF,
    title: 'multi',
    key: 'multi',
    icon: faqIcon,
    url: '',
  },
};

export { DealCustomComponents, DealCustomType };
