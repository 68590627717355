import React, { useEffect, useState } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';

import { OhmossButton } from 'resources/controls/OhmossButton';
import { CustomFile } from 'types/CommonTypes';
import { ISuggestion } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { validationHelper } from 'controls/validationHelper';

import TitleForm from 'components/items/forms/TitleForm';
import { ProposalDto, TermDto } from 'services/data-contracts';
import ProposalController from 'controllers/ProposalController';
import useSetState from 'libs/hooks/useSetState';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import useLocalParams from 'libs/hooks/useLocalParams';
import FileUploader from 'components/commons/imageUploader/FileUploader';
import styled from 'styled-components';
import { ChannelProposalConfigDto } from 'services/data-contracts';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import { toast } from 'react-toastify';
import TimeDealPicker from 'components/items/TimeDealPicker';
import RadioButtonsGroup from '../common/RadioButton';
import Required from 'components/commons/Required';

interface CategoryList {
  [key: string]: boolean;
}

const BusinessProposalForm = () => {
  const { uiStore, xhrStore } = useStore();
  const { currentChannel } = useChannelContext();
  const { serviceModel, updateServiceModel, updateServiceModelMultiple, unmountModel } =
    useModelManagement<ProposalDto>();
  const { createProposal, getChannelProposalType } = ProposalController();

  const { channelName } = useLocalParams();

  const defaultProps: Partial<ISuggestion> = {
    linkType: 'Suggestion',
    contactType: 'offer',
    isActive: true,
  };
  const [localModel, setLocalModel] = useSetState<Partial<ProposalDto>>(defaultProps);

  const [isValid, setIsValid] = useState<boolean>(false);
  const [durationLabel, setDurationLabel] = useState<string[]>(['협의 가능', '희망 일자 입력']);
  const [sponsorshipLabel, setSponsorshipLabel] = useState<string[]>(['네', '아니오']);
  const [localDate, setLocalDate] = useState({
    startDate: '',
    endDate: '',
  });
  const [datePicker, setDatePicker] = useState<boolean>(false);
  const [proposalTypeLabel, setProposalTypeLabel] = useState<ChannelProposalConfigDto[]>([]);
  const [categoryList, setCategoryList] = useState<CategoryList>({
    푸드: false,
    뷰티: false,
    생활: false,
    패션: false,
    펫: false,
    여행: false,
    건강: false,
    유아: false,
    기타: false,
  });

  const getProposalTypeLabel = async () => {
    const optionsTemp = (await getChannelProposalType()) as unknown as any;
    const namesArray = optionsTemp?.types?.map((item: any) => item.name);
    setProposalTypeLabel(namesArray);
  };

  const sendProposal = async () => {
    if (serviceModel && channelName) {
      updateServiceModel('requestType', 'CHANNEL');
      xhrStore.setState('pending');
      const response = await createProposal(serviceModel, channelName);
      xhrStore.setState('done');
      if (typeof response === 'undefined') {
        toast.error('제안 전송에 실패했습니다.');
      } else {
        toast('제안이 전송되었습니다.');
        uiStore.modal.close();
      }
    }
  };

  const toggleCategory = (key: any) => {
    setCategoryList((prevCategoryList) => ({
      ...prevCategoryList,
      [key]: !prevCategoryList[key],
    }));
  };

  const updateDurationLabel = (value: string) => {
    if (value === '협의 가능') {
      setDatePicker(false);
      setLocalModel('startDate', undefined);
      setLocalModel('endDate', undefined);
      updateServiceModelMultiple({ startDate: undefined, endDate: undefined });
    }
    if (value === '희망 일자 입력') {
      setDatePicker(true);
    }
  };

  const updateProposalTypeLabel = (value: string) => {
    setLocalModel('proposalType', value);
    updateServiceModel('proposalType', value);
  };

  const updateSponsorshipLabel = (value: string) => {
    if (value === '네') {
      setLocalModel('isSponsorship', true);
      updateServiceModel('isSponsorship', true);
    } else {
      setLocalModel('isSponsorship', false);
      updateServiceModel('isSponsorship', false);
    }
  };

  useEffect(() => {
    if (localModel) {
      const companyNameValid = validationHelper.required(localModel?.company);
      const productNameValid = validationHelper.required(localModel?.productName);
      const sellingPriceValid = validationHelper.number(localModel?.sellingPrice?.toString());
      const salesCommissionValid = validationHelper.number(localModel?.salesCommission?.toString());
      const phoneNumberValid = validationHelper.tel(localModel?.phoneNumber);
      const contactReplyValid = validationHelper.email(localModel?.contactReply);
      const categoryValid = validationHelper.required(localModel?.category);

      setIsValid(
        categoryValid &&
          companyNameValid &&
          productNameValid &&
          sellingPriceValid &&
          salesCommissionValid &&
          phoneNumberValid &&
          contactReplyValid,
      );
    }
  }, [localModel]);

  const handleTimeRangeChange = (from: string, to: string) => {
    if (from && to) {
      setLocalModel('startDate', from);
      setLocalModel('endDate', to);
      updateServiceModelMultiple({ startDate: from, endDate: to });
    }
  };

  const handleClickDatePicker = () => {
    uiStore.bottomSheet.show({
      title: '기간 설정',
      style: { padding: 10 },
      children: (
        <>
          <TimeDealPicker
            mode="NEW"
            timePickerType={'time'}
            start={localModel?.startDate}
            end={localModel?.endDate}
            timeRange={handleTimeRangeChange}
          />
          <Row style={{ gap: 10, marginTop: 20 }}>
            <OhmossButton
              type="box"
              style={{ color: '#000' }}
              onClick={() => {
                uiStore.bottomSheet.close();
              }}
            >
              취소
            </OhmossButton>
            <OhmossButton
              onClick={() => {
                uiStore.bottomSheet.close();
              }}
              // disabled={!isValid}
            >
              적용
            </OhmossButton>
          </Row>
        </>
      ),
    });
  };

  useEffect(() => {
    return () => {
      unmountModel();
    };
  }, []);

  useEffect(() => {
    const newStartDateString = new Date(localModel?.startDate as string);
    const newEndDateString = new Date(localModel?.endDate as string);

    if (newStartDateString && newEndDateString) {
      setLocalDate({
        startDate: newStartDateString.toLocaleString(),
        endDate: newEndDateString.toLocaleString(),
      });
    }
  }, [localModel?.startDate, localModel?.endDate]);

  useEffect(() => {
    const newCategoryList = Object.keys(categoryList).filter((category) => categoryList[category]);
    const trueCategoriesString = newCategoryList.join(', ');
    setLocalModel('category', trueCategoriesString);
    updateServiceModel('category', trueCategoriesString);
  }, [categoryList]);

  useEffect(() => {
    if (currentChannel) {
      getProposalTypeLabel();
    }
  }, [currentChannel]);
  return (
    <div style={{ padding: 20 }}>
      <ItemSectionStyle>
        <ItemSectionTitle style={{ flexDirection: 'row', display: 'flex' }}>
          회사명(성함)
          <Required />
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            placeHolder="제안하는 회사명을 입력해 주세요"
            onChangeValue={(value: string) => {
              setLocalModel('company', value);
              updateServiceModel('company', value);
            }}
            label="회사명"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle style={{ flexDirection: 'row', display: 'flex' }}>
          진행기간
        </ItemSectionTitle>
        <ItemContent>
          <RadioButtonsGroup
            onRadioBtnChange={(item) => updateDurationLabel(item)}
            direction="row"
            label={durationLabel}
          />
          {datePicker && (
            <>
              <DatePickerContainer>
                <DatePickerStyle style={{ cursor: 'default' }}>시작</DatePickerStyle>
                <DatePickerStyle onClick={handleClickDatePicker}>
                  {localDate?.startDate && localDate?.startDate !== 'Invalid Date'
                    ? localDate?.startDate
                    : '날짜 선택'}
                </DatePickerStyle>
              </DatePickerContainer>
              <DatePickerContainer style={{ marginTop: 10 }}>
                <DatePickerStyle style={{ cursor: 'default' }}>종료</DatePickerStyle>
                <DatePickerStyle onClick={handleClickDatePicker}>
                  {localDate?.endDate && localDate?.endDate !== 'Invalid Date'
                    ? localDate?.endDate
                    : '날짜 선택'}
                </DatePickerStyle>
              </DatePickerContainer>
            </>
          )}
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle style={{ flexDirection: 'row', display: 'flex' }}>
          카테고리
          <Required />
        </ItemSectionTitle>
        <ItemContent>
          <CategoryItemContainer>
            {Object.keys(categoryList)?.map((key) =>
              categoryList[key] === false ? (
                <CategoryItemStyle
                  style={{ cursor: 'pointer' }}
                  key={key}
                  onClick={() => toggleCategory(key)}
                >
                  {key}
                </CategoryItemStyle>
              ) : (
                <SelectedCategoryItemStyle
                  style={{ cursor: 'pointer' }}
                  key={key}
                  onClick={() => toggleCategory(key)}
                >
                  {key}
                </SelectedCategoryItemStyle>
              ),
            )}
          </CategoryItemContainer>
        </ItemContent>
      </ItemSectionStyle>
      {proposalTypeLabel?.length > 0 && (
        <ItemSectionStyle>
          <ItemSectionTitle style={{ flexDirection: 'row', display: 'flex' }}>
            제안 종류
          </ItemSectionTitle>
          <ItemContent>
            <RadioButtonsGroup
              onRadioBtnChange={(item) => updateProposalTypeLabel(item)}
              direction="column"
              label={proposalTypeLabel}
            />
          </ItemContent>
        </ItemSectionStyle>
      )}
      <ItemSectionStyle>
        <ItemSectionTitle style={{ flexDirection: 'row', display: 'flex' }}>
          제품명
          <Required />
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            placeHolder="제안하는 제품명을 입력해 주세요"
            onChangeValue={(value: string) => {
              setLocalModel('productName', value);
              updateServiceModel('productName', value);
            }}
            label="제품명"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle style={{ flexDirection: 'row', display: 'flex' }}>
          제품 소개 링크
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            placeHolder="제품 소개 링크를 입력하세요"
            onChangeValue={(value: string) => {
              setLocalModel('productLink', value);
              updateServiceModel('productLink', value);
            }}
            label="제품 소개 링크"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle style={{ flexDirection: 'row', display: 'flex' }}>
          판매가(리테일가)
          <Required />
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            validationType="number"
            onChangeValue={(value: number) => {
              setLocalModel('sellingPrice', Number(value));
              updateServiceModel('sellingPrice', Number(value));
            }}
            placeHolder="가격을 입력해 주세요 (숫자만 입력)"
            label="판매가"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle style={{ flexDirection: 'row', display: 'flex' }}>
          제안 가격 <Required />
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            validationType="number"
            onChangeValue={(value: number) => {
              setLocalModel('proposePrice', Number(value));
              updateServiceModel('proposePrice', Number(value));
            }}
            placeHolder="가격을 입력해 주세요 (숫자만 입력)"
            label="판매가"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle style={{ flexDirection: 'row', display: 'flex' }}>
          판매수수료 (%) <Required />
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            validationType="number"
            onChangeValue={(value: number) => {
              setLocalModel('salesCommission', Number(value));
              updateServiceModel('salesCommission', Number(value));
            }}
            placeHolder="판매 수수료를 입력해 주세요 (숫자만 입력)"
            label="판매 수수료"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle style={{ flexDirection: 'row', display: 'flex' }}>
          추가 설명
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            multiline
            rows={5}
            maxLength={500}
            useCount
            onChangeValue={(value: string) => {
              setLocalModel('description', value);
              updateServiceModel('description', value);
            }}
            placeHolder="추가 설명을 입력해 주세요"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle style={{ flexDirection: 'row', display: 'flex' }}>
          전화번호 <Required />
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            validationType="tel"
            onChangeValue={(value: string) => {
              setLocalModel('phoneNumber', value);
              updateServiceModel('phoneNumber', value);
            }}
            placeHolder="전화번호를 입력해 주세요"
            label="전화번호"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle style={{ flexDirection: 'row', display: 'flex' }}>
          이메일 <Required />
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            validationType="email"
            onChangeValue={(value: string) => {
              setLocalModel('contactReply', value);
              updateServiceModel('contactReply', value);
            }}
            placeHolder="이메일 주소를 입력해 주세요"
            label="이메일 주소"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle style={{ flexDirection: 'row', display: 'flex' }}>
          보내는 사람
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            onChangeValue={(value: string) => {
              setLocalModel('sender', value);
              updateServiceModel('sender', value);
            }}
            placeHolder="문의하시는 분의 이름을 입력해 주세요"
            label="보내는 사람"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle style={{ flexDirection: 'row', display: 'flex' }}>
          제품 협찬 여부
        </ItemSectionTitle>
        <ItemContent>
          <RadioButtonsGroup
            onRadioBtnChange={(item) => updateSponsorshipLabel(item)}
            direction="row"
            label={sponsorshipLabel}
          />
        </ItemContent>
      </ItemSectionStyle>
      <div style={{ marginBottom: 20 }}>
        파일첨부
        <FileUploader
          onSelected={(files: CustomFile[]) => {
            setLocalModel('attechFile', files[0]);
            updateServiceModel('attechFile', files[0]);
          }}
        />
      </div>
      <Row style={{ gap: 10 }}>
        <OhmossButton
          type="box"
          style={{ color: '#000' }}
          onClick={() => {
            uiStore.modal.close();
          }}
        >
          취소
        </OhmossButton>
        <OhmossButton
          onClick={() => {
            sendProposal();
          }}
          disabled={!isValid}
        >
          보내기
        </OhmossButton>
      </Row>
    </div>
  );
};

const CategoryItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
const CategoryItemStyle = styled.div`
  color: #b7b7b7;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: 10px 18px;
  border-radius: 30px;
  border: 1px solid #efefef;
  background: #fff;
`;

const SelectedCategoryItemStyle = styled.div`
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: 10px 18px;
  border-radius: 30px;
  border: 1px solid #fff;
  background: #23ba50;
`;

const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
`;

const DatePickerStyle = styled.div`
  border-radius: 6px;
  border: 1px solid #efefef;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 10px;
  cursor: pointer;
`;

export default BusinessProposalForm;
