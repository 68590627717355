import TitleForm from 'components/items/forms/TitleForm';
import useAI from 'libs/hooks/useAI';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { Swiper, SwiperSlide } from 'swiper/react';
import { toast } from 'react-toastify';
import { IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import OhmossAI from './AI/OhmossAI';
import { Row } from './layouts';

const AIExperience = ({ isWebView, onClose }: { isWebView?: boolean; onClose?: () => void }) => {
  const { chat } = useAI();
  const title = useRef<string>('');
  const [swiper, setSwiper] = useState<any>(null);

  const handleClickUseOhmoss = () => {
    swiper?.slideTo(1);
  };

  const handleClickAI = () => {
    if (title.current.length < 10) {
      toast('내용을 10자 이상 입력해주세요.');
    } else {
      chat(title.current);
    }
  };

  useLayoutEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      setTimeout(() => {
        body.style.overflow = 'hidden';
      }, 500);
    }
    return () => {
      if (body) {
        body.style.overflow = 'auto';
      }
    };
  }, []);

  return (
    <>
      <AIContainerStyle isWebView={isWebView}>
        {!isWebView && (
          <ButtonContainerStyle>
            <IconButton onClick={onClose} style={{ background: '#fff' }}>
              <CloseIcon />
            </IconButton>
          </ButtonContainerStyle>
        )}
        <AIInnerStyle isWebView={isWebView}>
          <Swiper
            pagination={true}
            spaceBetween={0}
            slidesPerView={1}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => setSwiper(swiper)}
          >
            <SwiperSlide className="">
              <FirstSlideStyle>
                <Row direction="column" style={{ justifyContent: 'space-between', height: '100%' }}>
                  <div>
                    <img src="/images/landing/ai_text.svg" style={{ width: 250 }} alt="" />
                  </div>
                  <div
                    style={{
                      background: 'rgba(255,255,255,0.2)',
                      padding: 30,
                      borderRadius: 20,
                      fontSize: 18,
                      color: '#fff',
                      textAlign: 'center',
                      backdropFilter: 'blur(3px)',
                    }}
                  >
                    피드작성 어려워하지 마세요.
                    <br />
                    오모스 AI가 도와드리겠습니다.
                    <div style={{ fontSize: 16 }}>
                      ohmoss AI는 인플루언서 전용기능으로
                      <br /> "인플루언서"인증 후 실 사용이 가능합니다.
                    </div>
                  </div>
                  <div style={{ width: '70%' }}>
                    <OhmossButton onClick={handleClickUseOhmoss}>✨oh!moss AI체험하기</OhmossButton>
                  </div>
                </Row>
              </FirstSlideStyle>
            </SwiperSlide>
            <SwiperSlide className="swiper">
              <div>
                <div style={{ fontWeight: 700, marginBottom: 20, fontSize: 18 }}>
                  oh!moss AI writer
                </div>
                <div>간단하게 요약만 적어주세요. 나머지는 오모스 AI가 채워드립니다.</div>
                <div style={{ margin: '10px 0' }}>
                  <TitleForm
                    placeHolder="신나는 겨울 한정 여성용 화장품 세트"
                    onChangeValue={(value: string) => {
                      title.current = value;
                    }}
                  />
                </div>
                <div>
                  <OhmossAI onClick={handleClickAI} />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </AIInnerStyle>
      </AIContainerStyle>
      <BackgroundStyle />
    </>
  );
};

const FirstSlideStyle = styled.div`
  background-image: url('images/landing/ai_sample.png');
  width: 100%;
  padding: 20px;
`;
const AIContainerStyle = styled.div<{ isWebView?: boolean }>`
  position: fixed;
  ${(props) => props.isWebView && { top: 0 }};
  ${(props) => !props.isWebView && { bottom: 0 }};
  // bottom: ${(props) => (props.isWebView ? '0px' : '70px')};
  z-index: 10;
  padding: 0 10px;
  max-width: 500px;
  margin: auto;
  text-align: center;
  left: 0;
  right: 0;
`;

const AIInnerStyle = styled.div<{ isWebView?: boolean }>`
  background: #fff;
  border-radius: ${(props) => (props.isWebView ? '0px' : '10px')};
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
`;

const BackgroundStyle = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  z-index: 9;
`;

const ButtonContainerStyle = styled.div`
  position: absolute;
  top: -50px;
  right: 10px;
`;

export default AIExperience;
