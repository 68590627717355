import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Row } from 'components/commons/layouts';
import { Badge } from '@mui/material';
import { PostModel } from 'types/CommonTypes';
import { DateText } from 'resources/controls/Titles';
import { ChannelBoardDto, PostDto } from 'services/data-contracts';

import moment from 'moment';
import boardCommonLogics from 'features/board/logic/boardCommonLogic';
import PaginationBase from './PaginationBase';

const FreeBoardType = observer(
  ({ posts, currentBoard }: { posts?: Array<PostDto>; currentBoard: ChannelBoardDto | null }) => {
    const { handleClickPost, getContentsThumbnail } = boardCommonLogics();

    return (
      <PaginationBase paginationType="pager">
        <FreeBoardContainerStyle>
          {posts?.map((post: PostModel) => (
            <GridItemStyle
              onClick={() => {
                handleClickPost(post, currentBoard);
              }}
            >
              <Row style={{ justifyContent: 'space-between' }}>
                <Row style={{ justifyContent: 'flex-start' }}>
                  <div style={{ paddingLeft: 10 }}>
                    <SubTitleTextStyle>{post.title}</SubTitleTextStyle>
                    <DateText style={{ marginTop: 5 }}>
                      {moment.utc(post.createDate).fromNow()}
                    </DateText>
                  </div>
                </Row>
                <Row style={{ marginRight: 5, width: 'auto' }}>
                  <div style={{ marginRight: 15 }}>{getContentsThumbnail(post)}</div>
                  <Badge badgeContent={post.commentCount} color="primary">
                    <img src="/images/bubble-chat.svg" alt="" style={{ width: 22 }} />
                  </Badge>
                </Row>
              </Row>
            </GridItemStyle>
          ))}
        </FreeBoardContainerStyle>
      </PaginationBase>
    );
  },
);

const FreeBoardContainerStyle = styled.div`
  max-width: 570px;
  margin: auto;
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media ${(props) => props.theme.media.mobile} {
  }
`;

const GridItemStyle = styled.div`
  margin: 5px 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 5px;
  background: #fafafa;
  transition: all 0.2s ease;
  &:hover {
    background: #fff;
  }
  @media ${(props) => props.theme.media.mobile} {
    padding-left: 5px 15px;
  }
`;

const SubTitleTextStyle = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export default FreeBoardType;
