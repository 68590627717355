import React, { useEffect, useState, useCallback, useLayoutEffect } from 'react';

import { validationHelper as VH } from 'controls/validationHelper';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@mui/material';

import { debounce } from 'lodash';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import { ChannelDto, DuplicationUserDto, SignUpDto, TermDto } from 'services/data-contracts';
import useNavigation from 'hooks/useNavigation';

import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import PasswordForm from 'components/items/forms/PasswordForm';
import { useTranslation } from 'react-i18next';
import { Row } from 'components/commons/layouts';
import MUISwitch from 'components/commons/buttons/MUISwitch';
import TextLabel from 'components/commons/labels/TextLabel';
import styled from 'styled-components';
import MossyInputForm from 'components/items/forms/MossyInputForm';
import { withStyles } from '@material-ui/core/styles';
import DetailPageHeader from 'components/commons/DetailPageHeader';
import RadioButtonsGroup from 'features/management/components/common/RadioButton';
import CommonSignUpForm from './CommonSignUpForm';
import AuthStore from 'stores/AuthStore';
import ChannelTypeSelector from './ChannelTypeSelector';

const styles = (theme: any) => ({
  outlinedInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.625rem',
      border: ({ titleModel }: any) => (titleModel?.length > 0 ? '1.5px solid #173300' : 'none'),
      background: 'rgba(23, 51, 0, 0.1)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.625rem',
      border: '1.5px solid #173300',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.625rem',
      border: '1.5px solid #173300',
    },
  },
});

interface SignUpModel extends SignUpDto {
  termsValid?: boolean;
}

const EmailSignUpForm = ({ classes }: { classes?: any }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const [model, setModel] = useState<SignUpModel>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | undefined>(undefined);

  const [tempChannelName, setTempChannelName] = useState<string>();
  const [checkDupResult, setCheckDupResult] = useState<boolean>();
  const [invalidPattern, setInvalidPattern] = useState<boolean>();

  const { termStore, uiStore, channelStore, authStore } = useStore();

  const checkValidation = () => {
    const emailResult = VH.email(model?.username) && emailErrorMessage === '';
    const pwResult =
      typeof model?.password !== 'undefined' &&
      VH.required(model?.password) &&
      model?.password?.length > 7;
    const channelName = VH.alphanumericWithSymbols(model?.channelName);

    if (typeof model?.termsValid !== 'undefined') {
      setIsValid(emailResult && pwResult && channelName && model.termsValid);
    }
  };

  const handleChangeChannelName = useCallback(
    debounce(async (channelName) => {
      setTempChannelName(channelName);

      if (channelName.length < 2) {
        setIsValid(false);
        setInvalidPattern(false);
        setCheckDupResult(undefined);
        return;
      }
      const validString = VH.alphanumericWithSymbols(channelName);
      if (!validString) {
        setInvalidPattern(true);
        setCheckDupResult(undefined);
        return;
      } else {
        setInvalidPattern(false);
      }
      const result = (await channelStore.duplicateChannelName(channelName)) as any;
      setModel((prev) => {
        return { ...prev, channelName };
      });
      setCheckDupResult(result as boolean);
    }, 500),
    [],
  );

  const handleChangeEmail = useCallback(
    debounce(async (username) => {
      const emailResult = VH.email(username);
      if (emailResult) {
        const result = (await authStore.checkUsername(username)) as DuplicationUserDto;
        if (result.isDuplicated) {
          setEmailErrorMessage(t('A115'));
          setValidEmail(true);
        } else {
          setValidEmail(false);
          setEmailErrorMessage('');
        }
        setModel((prev) => {
          return { ...prev, username };
        });
      } else {
        setValidEmail(true);
        setEmailErrorMessage(t('A101'));
      }
      setModel((prev) => {
        return { ...prev, username };
      });
    }, 500),
    [],
  );

  const handleClickSignUp = async () => {
    const agreement = termStore?.terms.map((term: TermDto) => ({
      termId: term.id,
      isAgree: true,
    }));
    if (model && isValid) {
      const result = await authStore.emailSignUp(model, agreement);
      if (result) {
        uiStore.universalModal.show({
          useDefaultButtons: true,
          modalOption: { style: { maxWidth: 600, height: 400 } },
          title: t('A114'),
          children: (
            <>
              {t('A113')}
              <br />
            </>
          ),
          onClose: () => {
            navigate('/login');
          },
        });
      }
    }
  };

  const handleSelectChannelType = (channelType: string) => {
    if (channelType === '셀러(일반) 채널') {
      setModel((prev) => {
        return { ...prev, channelType: 'SELLER' };
      });
    } else {
      setModel((prev) => {
        return { ...prev, channelType: 'BRAND' };
      });
    }
  };

  useEffect(() => {
    if (model) {
      checkValidation();
    }
  }, [model]);

  useLayoutEffect(() => {
    termStore.getTerms();
  }, []);

  return (
    <>
      <div style={{ margin: '0px 0px 30px 0px', fontWeight: 300, fontSize: 18 }}>{t('A111')}</div>
      <div>
        <FormControl sx={{ width: '100%' }} variant="outlined">
          <InputLabel shrink htmlFor="outlined-adornment-password" required>
            {t('A103')}
          </InputLabel>
          <OutlinedInput
            className={classes.outlinedInput}
            style={{ marginTop: 8 }}
            label="email"
            required
            error={validEmail}
            endAdornment={
              <>
                {typeof emailErrorMessage !== 'undefined' && (
                  <InputAdornment position="end">
                    {emailErrorMessage ? <DoNotDisturbAltIcon /> : <CheckIcon />}
                  </InputAdornment>
                )}
              </>
            }
            inputProps={{
              onBlur: (e: any) => {
                checkValidation();
              },
            }}
            onChange={(e: any) => {
              handleChangeEmail(e.target.value);
            }}
          />
          <FormHelperText>{emailErrorMessage}</FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl sx={{ width: '100%', mt: 2 }} variant="outlined">
          <PasswordForm
            type="login"
            onChangeValue={(value: string) => {
              setModel((prev) => {
                return { ...prev, password: value };
              });
            }}
            label="비밀번호"
          />
        </FormControl>

        <ChannelTypeSelector
          onSelectedType={(type) => {
            setModel((prev) => {
              return { ...prev, channelType: type };
            });
          }}
        />

        <CommonSignUpForm
          onValidChannelName={(channelName: string) => {
            setModel((prev) => {
              return { ...prev, channelName };
            });
          }}
          onTermsChanged={(termsValid: boolean) => {
            setModel((prev) => {
              return { ...prev, termsValid };
            });
          }}
        />

        <OhmossButton disabled={!isValid} style={{ height: 50 }} onClick={handleClickSignUp}>
          {t('A93')}
        </OhmossButton>
      </div>
    </>
  );
};

const TitleText = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02rem;
`;

export default withStyles(styles)(EmailSignUpForm);
