import React, { CSSProperties, useEffect, useState } from 'react';
import { LinkDto } from 'services/data-contracts';
import { ItemContainerStyle } from 'features/adminFeatures/template/components/ItemContainerStyle';
import _ from 'lodash';
import { SNSLinkItemModel, SNSLinkModel, SimpleLinkModel } from 'types/CommonTypes';
import { validationHelper as VH } from 'controls/validationHelper';
import styled from 'styled-components';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import SNSItem from './SNSIconWrapper';
import { Row } from 'components/commons/layouts';

type defaultProps = {
  item: SNSLinkModel;
  mode?: 'create' | 'edit';
  onDelete?: (item: LinkDto) => void;
};

const SNSLink = observer(({ item, mode, onDelete }: defaultProps) => {
  const { channelStore } = useStore();
  const IMAGE_WIDTH = 50;
  let urlRef = '';

  const [linkModel, setLinkModel] = useState<SNSLinkModel>();
  const [overrideStyle, setOverrideStyle] = useState<CSSProperties>();
  const [iconType, setIconType] = useState<'color' | 'solid'>('color');
  const [color, setColor] = useState<string>('#000000');

  if (!_.isEmpty(item) && item.linkUrl) {
    urlRef =
      item?.linkUrl.startsWith('http://') || item?.linkUrl.startsWith('https://')
        ? item?.linkUrl
        : `//${item?.linkUrl}`;
  }

  const handleClickSNS = (item: SNSLinkItemModel) => {
    const hasScheme = item.linkUrl?.toLocaleLowerCase().indexOf('http');
    if (typeof hasScheme !== 'undefined' && hasScheme! >= 0) {
      window.open(item.linkUrl, '_blank');
    } else {
      window.open(`https://${item.linkUrl}`, '_blank');
    }
  };

  useEffect(() => {
    if (typeof item.metadata !== 'undefined') {
      item.metadataObject = JSON.parse(item.metadata);
      setLinkModel(item);
      setIconType(item.metadataObject?.colorType || 'color');
      setColor(item.metadataObject?.solidColor || '#000000');

      let parsedStyle;
      if (channelStore.myStyle?.style) {
        parsedStyle = JSON.parse(channelStore.myStyle.style);
        setOverrideStyle(parsedStyle);
      }

      /** 투명은 스타일 지정에 우선함 */
      if (item.metadataObject?.useTransparent) {
        setOverrideStyle({
          ...parsedStyle,
          background: 'transparent',
          borderWidth: 0,
          boxShadow: 'unset',
        });
      }
    }
  }, [item, channelStore.myStyle]);

  return (
    <ItemContainerStyleOverride style={overrideStyle} hover={!item.metadataObject?.useTransparent}>
      <div className="link-container-text">
        <Row
          style={{
            position: 'relative',
            width: item.thumbnail !== null ? 'calc(100% - 130px)' : '100%',
          }}
        >
          {item.items?.map((item: SNSLinkItemModel) => (
            <div
              className="px-2"
              onClick={() => {
                handleClickSNS(item);
              }}
            >
              <SNSItem name={item.title!} width={30} />
            </div>
          ))}
        </Row>
      </div>
    </ItemContainerStyleOverride>
  );
});

const ItemContainerStyleOverride = styled(ItemContainerStyle)<{ hover: boolean }>``;

export default SNSLink;
