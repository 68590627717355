import React, { useState, useEffect } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { Chip, Box, Badge } from '@mui/material';
import { TagDto } from 'services/data-contracts';
import { GroupedTag } from 'types/CommonTypes';
import CRUDHelper from 'libs/helper/crudHelper';

import styled from 'styled-components';

const Tags = observer(({ onSelectTag }: { onSelectTag?: (tag: TagDto) => void }) => {
  const { myTagStore } = useStore();

  const handleClickChip = (tag: TagDto) => {};

  return (
    <>
      {myTagStore.groupTags?.map((group: GroupedTag) => (
        <Box sx={{ marginBottom: 3 }}>
          <TagGroupNameStyle>
            {group.groupName === 'null' ? '미분류' : group.groupName}
          </TagGroupNameStyle>
          {group.tags.map((tag: TagDto) => (
            <Badge
              badgeContent={tag.isVisible ? '' : '비공개'}
              color={!tag.isVisible ? 'primary' : undefined}
              sx={{ '& .MuiBadge-badge': { right: 10 } }}
            >
              <Chip
                style={{ padding: '10px 20px' }}
                label={tag.name}
                variant="outlined"
                onClick={() => {
                  handleClickChip(tag);
                  if (onSelectTag) {
                    onSelectTag(tag);
                  }
                }}
                sx={{ marginRight: 1 }}
                // onDelete={() => {
                //   handleDeleteTag(tag);
                // }}
              />
            </Badge>
          ))}
        </Box>
      ))}
    </>
  );
});

const TagGroupNameStyle = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
`;

export default Tags;
