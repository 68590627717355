import React from 'react';
import { useStore } from 'stores/StoreHelper';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

const CommonBottomsheetHeader = ({
  title,
  styles,
}: {
  title: string;
  styles: React.CSSProperties;
}) => {
  const { uiStore } = useStore();

  return (
    <Header styles={styles}>
      <div style={{ fontSize: 18, fontWeight: 200, color: styles ? styles?.color : '#fff' }}>
        오모스 멤버십
      </div>
      <div style={{ fontSize: 20, fontWeight: 500, color: styles ? styles?.color : '#fff' }}>
        {title}
      </div>
      <CloseIconButton
        styles={styles}
        onClick={() => {
          uiStore.bottomSheet.close();
        }}
      />
    </Header>
  );
};

const Header = styled.div<{ styles?: React.CSSProperties }>`
  display: flex;
  height: 120px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 2rem 1rem 1.875rem 1rem;
  background-color: ${(props) => props.styles?.backgroundColor || '#011208'};
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #fff;
`;

const CloseIconButton = styled(CloseIcon)<{ styles?: React.CSSProperties }>`
  width: 30;
  height: 30;
  color: ${(props) => props.styles?.color || '#fff'};
  position: absolute;
  right: 10px;
  top: 10px;
`;

export default CommonBottomsheetHeader;
