import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  white-space: pre-line;
  > div:first-child {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  @media ${(props) => props.theme.media.mobile} {
  }
`;
