import React, { useState, ReactNode, useEffect } from 'react';
import { useStore } from 'stores/StoreHelper';
import { Row } from 'components/commons/layouts';
import { OhmossButton } from 'resources/controls/OhmossButton';
import useNavigation from 'hooks/useNavigation';

import Resign from 'components/commons/Resign';

import useLogout from 'hooks/useLogout';
import Text from 'components/commons/Text';
import MyStore from 'stores/MyStore';
import { BoxStyle } from 'features/csTown/commonComponents/styles';

const SettingProvider = () => {
  const myStore = new MyStore();
  const { uiStore } = useStore();
  const { navigate } = useNavigation();

  const logout = useLogout();
  const ImageBox = () => {
    return (
      <div style={{ marginBottom: 30 }}>
        <img src="/images/cry2.png" style={{ width: 200 }} />
        <div>오모스를 떠나신다니 아쉽네요 :(</div>
      </div>
    );
  };

  const withDrawal = () => {
    uiStore.universalModal.show({
      title: `계정탈퇴 안내`,
      description: '오모스를 떠나신다니 아쉽네요 :( ',
      modalOption: {
        style: { maxWidth: 600, height: 550 },
      },
      bottomSheetOptions: { style: { padding: 20 } },
      children: (
        <Row style={{ flexDirection: 'column' }}>
          <ImageBox />
          <Text type="H3" style={{ marginBottom: 40 }}>
            다음의 내용을 확인 하신 후, 탈퇴 버튼을 누르시면 탈퇴가 완료됩니다.
          </Text>
          <Resign />
          <Row style={{ padding: '20px', gap: 20, marginTop: 40 }}>
            <OhmossButton
              style={{ padding: '10px 30px', borderRadius: 8, width: 200 }}
              type="danger"
              onClick={handleClickResign}
            >
              탈퇴확인
            </OhmossButton>
            <OhmossButton
              style={{ borderRadius: 8, padding: '10px 30px' }}
              type="simple"
              onClick={() => {
                uiStore.universalModal.close();
              }}
            >
              취소
            </OhmossButton>
          </Row>
        </Row>
      ),
    });
  };

  const handleClickResign = () => {
    logout();
    myStore.withDrawal();
    uiStore.universalModal.show({
      title: '이용해 주셔서 감사합니다.',
      description: '정상적으로 탈퇴가 완료되었습니다.',
      modalOption: { style: { width: 600, height: 400 } },
      bottomSheetOptions: { style: { padding: 20 } },
      children: (
        <Row style={{ flexDirection: 'column' }}>
          <Text type="H3" style={{ marginBottom: 40 }}>
            이용해 주셔서 감사합니다. :D
          </Text>
          <BoxStyle style={{ marginTop: 40 }}>
            <OhmossButton
              style={{ padding: '10px 30px' }}
              onClick={() => {
                navigate('/');
                uiStore.universalModal.close();
              }}
            >
              확인
            </OhmossButton>
          </BoxStyle>
        </Row>
      ),
    });
  };

  return { withDrawal };
};

export default SettingProvider;
