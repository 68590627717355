import React from 'react';
import { DealInquiryDto } from 'services/data-contracts';
import styled from 'styled-components';

const InquiryStatusBox = ({ status }: { status: DealInquiryDto['status'] }) => {
  const resolvedInquiriesDescription = () => {
    switch (status) {
      case 'PENDING':
        return '미답변';
      case 'ANSWERED':
        return '진행중';
      case 'CLOSED':
        return '문의 종료';
      default:
        return '알수없음';
    }
  };
  return <OnGoingStyle status={status}>{resolvedInquiriesDescription()}</OnGoingStyle>;
};

const OnGoingStyle = styled.div<{ status: DealInquiryDto['status'] }>`
  border-radius: 20px;
  padding: 0.25rem 0.625rem;
  text-align: center;
  font-size: 0.6875rem;
  min-width: 60px;
  max-width: 70px;

  ${(props) => {
    if (props.status === 'PENDING') {
      return { background: '#96E06E', color: '#000' };
    } else if (props.status === 'ANSWERED') {
      return { background: 'rebeccapurple', color: '#fff' };
    } else if (props.status === 'CLOSED') {
      return { background: '#848484', color: '#fff' };
    }
  }};
`;

export default InquiryStatusBox;
