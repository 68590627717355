import React, { useEffect, useState } from 'react';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import { PartnerDto } from 'services/data-contracts';
import { ContextContainer, ContextItem } from 'resources/styles/common/ContextItem';

import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import PartnerController from 'controllers/PartnerController';
import useNavigation from 'hooks/useNavigation';

const PartnerContext = ({
  partner,
  onReload,
  onEdit,
}: {
  partner: PartnerDto;
  onReload: () => void;
  onEdit: () => void;
}) => {
  const { uiStore } = useStore();
  const { updatePartner, deletePartner } = PartnerController();
  const { navigate } = useNavigation();

  const [isActive, setIsActive] = useState<boolean>();

  const handleDeletePartner = () => {
    uiStore.confirm.show({
      message: '파트너를 삭제하시겠습니까? 이 파트너로부터 위임된 모든 공구에서도 위임 해제됩니다.',
      onConfirmed: async () => {
        const result = (await deletePartner(partner)) as unknown as string;
        if (result === '') {
          onReload();
        }
      },
    });
  };

  const handleDisable = (value: boolean) => {
    uiStore.confirm.show({
      message:
        '파트너를 비활성하시겠습니까? 이 파트너로부터 위임된 모든 공구에서도 일시 해제됩니다.',
      onConfirmed: async () => {
        const result = (await updatePartner(partner.id as number, {
          ...partner,
          isActive: value,
        })) as unknown as string;
        if (result) {
          setIsActive(value);
          onReload();
        }
        uiStore.bottomSheet.close();
      },
      onCancel: () => {
        setIsActive(partner?.isActive as boolean);
        uiStore.bottomSheet.close();
      },
    });
  };

  useEffect(() => {
    if (partner) {
      setIsActive(partner.isActive as boolean);
    }
  }, [partner]);

  return (
    <ContextContainer>
      <ContextItem
        onClick={() => {
          handleDeletePartner();
          uiStore.bottomSheet.close();
        }}
      >
        위임 끊기
      </ContextItem>
      <ContextItem>
        <MenuItemCheckBox
          // value={isActive}
          initialValue={isActive}
          onChangeValue={(value: boolean) => {
            handleDisable(value);
            setIsActive(value);
          }}
          title="활성"
        />
      </ContextItem>
      <ContextItem
        onClick={() => {
          navigate(`/my/partners/${partner?.id}/deals`);
          uiStore.bottomSheet.close();
        }}
      >
        공구리스트
      </ContextItem>
      <ContextItem
        onClick={() => {
          window.open(`/${partner?.channel?.channelName}`, 'channel');
          uiStore.bottomSheet.close();
        }}
      >
        셀러 채널보기
      </ContextItem>
      <ContextItem style={{ padding: '20px 0' }}>
        <OhmossButton
          onClick={() => {
            uiStore.bottomSheet.close();
          }}
        >
          닫기
        </OhmossButton>
      </ContextItem>
    </ContextContainer>
  );
};

export default PartnerContext;
