import React, { useEffect, useRef, useState } from 'react';
import { updateLink, updateModel, initLinkItemDto } from 'components/items/commonLogic';
import { CommonRowStyle } from 'resources/styles/common/styles';
import { Map, defaultLinkEditorProps, MapLinkModel } from 'types/CommonTypes';
import { validationHelper as VH } from 'controls/validationHelper';

import { useTranslation } from 'react-i18next';
import { GoogleMap } from 'components/maps';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { Row } from 'components/commons/layouts';
import { FormControlLabel, Switch } from '@mui/material';
import { t } from 'i18next';

import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import styled from 'styled-components';
import getGeoCodeByAddress from 'libs/helper/geoCodeHelper';
import LinkEditorContainerStyle from 'resources/controls/LinkEditorConatainerStyle';
import TitleForm from './forms/TitleForm';
import SaveButtonWrapper from './SaveButtonWrapper';
import ThumbnailUploader from './ThumbnailUploader';

// TODO:지도가 확정일때만 Validation이 유효하여야 함, 만약 유효한 지도를 수정한 경우 기존 유효를 무효화 하여야 함.
const MapLinkEditor = observer(
  ({ model, stores, onRegistComplete }: defaultLinkEditorProps<MapLinkModel>) => {
    const { t } = useTranslation();
    const titleRef = useRef<HTMLInputElement>(null);
    const addrRef = useRef<HTMLInputElement>(null);

    const [linkModel, setLinkModel] = useState<MapLinkModel>();
    const [isValid, setIsValid] = useState<boolean>(false);

    const [validAddress, setValidAdress] = useState<boolean>(false);
    const [mapInfo, setMapInfo] = useState<Map | null | undefined>();
    const [searchResults, setSearchResults] = useState<any>();
    const [selectedPlace, setSelectedPlace] = useState<any>();

    /** Primitives */
    const handleClickSave = async () => {
      if (typeof linkModel !== 'undefined' && typeof onRegistComplete === 'function') {
        const result = await updateLink(linkModel, typeof linkModel.id !== 'undefined', stores);
        onRegistComplete(true, result);
      }
    };

    const handleChangeValue = (key: 'title' | 'address', value: string) => {
      setLinkModel((prev: MapLinkModel) => {
        return { ...prev, [key]: value };
      });
    };

    const checkValidate = () => {
      const result = VH.required(linkModel?.title) && VH.required(linkModel?.address);
      if (validAddress) {
        setIsValid(result);
      }
    };

    // useEffect(() => {
    //   if (typeof model?.id === 'undefined') {
    //     setLinkModel({ linkType: 'SimpleLink' });
    //   } else {
    //     setLinkModel(model);
    //     const result = VH.required(model.title) && VH.url(model.linkUrl);
    //     setIsValid(result);
    //   }
    // }, [model]);
    /** Primitives */

    const handleClickSearchAddr = async () => {
      if (linkModel?.address) {
        const results = await getGeoCodeByAddress(linkModel?.address);
        if (results === null) {
          toast(t('mapError'));
          return;
        }

        if (results.length === 0) {
          toast(t('enterCorrectAddress'), { position: 'top-center' });
          setSearchResults(undefined);
          setValidAdress(false);
        } else if (results.length === 1) {
          handleClickSelectMap(results[0]);
          setSearchResults(undefined);
          setValidAdress(true);
        } else {
          setSearchResults(results);
        }
      }
    };

    const handleClickSelectMap = (result: any) => {
      const { lat, lng } = result.geometry.location;
      setMapInfo({
        lat,
        lng,
        title: '',
        addr: '',
      });

      setLinkModel((prev: any) => {
        return { ...prev, longitude: lng, latitude: lat, address: result.formatted_address };
      });
    };

    useEffect(() => {
      if (typeof model?.id !== 'undefined') {
        if (typeof model.metadata !== 'undefined') {
          setLinkModel({ ...model, metadataObject: JSON.parse(model.metadata) });
        } else {
          setLinkModel(model);
        }
        if (typeof model.latitude !== 'undefined' && typeof model.longitude !== 'undefined') {
          setMapInfo({
            lat: model.latitude,
            lng: model.longitude,
            title: '',
            addr: '',
          });
        }
        setValidAdress(true);
      } else {
        setLinkModel(initLinkItemDto('MapLink'));
        // setLinkModel({ linkType: 'MapLink', customObject: { isCollapsed: true } });
        setMapInfo(null);
      }
    }, [model]);

    useEffect(() => {
      if (linkModel) {
        checkValidate();
      }
    }, [linkModel, validAddress]);

    return (
      <LinkEditorContainerStyle>
        <SaveButtonWrapper
          isValid={isValid}
          onSave={handleClickSave}
          isNew={typeof linkModel?.id === 'undefined'}
        >
          <>
            <ThumbnailUploader
              url={linkModel?.thumbnail?.publicUrl}
              onImageChange={(image: any) => {
                setLinkModel((prev: MapLinkModel) => {
                  return { ...prev, thumbnail: image };
                });
              }}
            />
            <Row style={{ marginTop: 20 }}>
              <TitleForm
                ref={titleRef}
                multiline
                label="Link title"
                model={linkModel?.title}
                onChangeValue={(value: string) => {
                  handleChangeValue('title', value);
                }}
              />
            </Row>
            <div style={{ marginTop: 10 }}>
              <GoogleMapContainerStyle>
                {mapInfo === null ? (
                  <div style={{ height: '100%' }}>
                    <CenterBox>
                      <FmdGoodOutlinedIcon fontSize="large" sx={{ fontSize: 30 }} />
                      <div>{t('A157')}</div>
                    </CenterBox>
                  </div>
                ) : (
                  <GoogleMap map={mapInfo} />
                )}
              </GoogleMapContainerStyle>
            </div>
            <div style={{ margin: '10px 0' }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={linkModel?.metadataObject?.isCollapsed}
                    disabled={!validAddress}
                    onChange={(event: never, value: boolean) => {
                      updateModel(setLinkModel, 'metadataObject', { isCollapsed: value });
                    }}
                  />
                }
                label={t('collapse')}
              />
            </div>
            <CommonRowStyle style={{ alignItems: 'flex-start' }}>
              <TitleForm
                ref={addrRef}
                label="Address"
                model={linkModel?.address}
                onChangeValue={(value: string) => {
                  handleChangeValue('address', value);
                }}
                onEntered={handleClickSearchAddr}
              />

              <SearchButtonStyle onClick={handleClickSearchAddr}>
                <SearchImageStyle src="/image/search.svg" alt="" />
              </SearchButtonStyle>
            </CommonRowStyle>

            <div style={{ marginLeft: 5 }}>
              {searchResults?.length > 1 && (
                <div style={{ marginTop: 20, marginBottom: 10 }}>{t('searchResults')}</div>
              )}
              {searchResults?.map((result: any) => (
                <SearchResultStyle
                  selected={result?.place_id === selectedPlace?.place_id}
                  onClick={() => {
                    handleClickSelectMap(result);
                    setSelectedPlace(result);
                    setValidAdress(true);
                  }}
                >
                  {result?.formatted_address}
                </SearchResultStyle>
              ))}
            </div>
            {/* <SimplePreview model={linkModel} /> */}
          </>
        </SaveButtonWrapper>
      </LinkEditorContainerStyle>
    );
  },
);

const GoogleMapContainerStyle = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  background-color: #efefef;
  justify-content: center;
  > div {
    width: 100%;
  }
`;

const SearchButtonStyle = styled.div`
  background: #636363;
  border-radius: 6px;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 10px;
  cursor: pointer;
`;

const SearchImageStyle = styled.img`
  width: 22px;
  height: 22px;
`;

const SearchResultStyle = styled.div<{ selected: boolean }>`
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: ${(props) => (props.selected ? '1px solid #bb00ff' : '1px solid #f2f2f2')};
  background: #fcfcfc;
`;

const CenterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
export default MapLinkEditor;
