import React from 'react';
import useNavigation from 'hooks/useNavigation';
import TopWrapperLeft from 'features/mainFeatures/auth/components/TopWrapperLeft';
import TopWrapper from 'features/mainFeatures/auth/components/TopWrapper';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styled from 'styled-components';

interface BasicProps {
  title: string;
}

const MobileHeader = ({ title }: BasicProps) => {
  const navigation = useNavigation();

  return (
    <HeaderWrapperStyle>
      <TopWrapperLeft>
        <ArrowBackIcon
          style={{
            color: '#222222',
            paddingRight: '16px',
            width: '18px',
            cursor: 'pointer',
          }}
          onClick={() => {
            navigation.pop();
          }}
        />
        <div>{title}</div>
      </TopWrapperLeft>
    </HeaderWrapperStyle>
  );
};

const HeaderWrapperStyle = styled(TopWrapper)`
  @media ${(props) => props.theme.media.desk} {
    display: none;
  }
`;

export default MobileHeader;
