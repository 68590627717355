import React from 'react';
import { useChat } from 'libs/hooks/useChat';
import { useRootContext } from 'libs/hooks/useRootContext';
import { v4 as uuidv4 } from 'uuid';

import { IDealBlock } from 'types/BlockExtendsTypes';
import { ChatModel } from 'types/CommonTypes';
import { useStore } from 'stores/StoreHelper';
import CustomerChatContainer from 'components/commons/chat/containers/CustomerChatContainer';
import useSandbirdChat from 'hooks/useSandbirdChat';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import { ChannelDto } from 'services/data-contracts';
import { isMobile } from 'react-device-detect';
import useNavigation from 'hooks/useNavigation';
import { Row } from 'components/commons/layouts';
import { Divider } from '@mui/material';
import Text from 'components/commons/Text';
import InfoBox from 'components/commons/InfoBox';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { toast } from 'react-toastify';

const LinkServicePageController = () => {
  const { navigate } = useNavigation();
  const { myChannel } = useRootContext();
  const { initChat } = useChat();
  const { currentChannel } = useChannelContext();

  const { uiStore } = useStore();
  const { disconnect } = useSandbirdChat();

  const setRedirectUrl = (dealItem: IDealBlock) => {
    const url = {
      path: `/${currentChannel?.channelName}/inquiry/${dealItem.linkKey as string}`,
    };
    localStorage.setItem('redirectUrl', JSON.stringify(url));
  };

  const handleLogin = (dealItem: IDealBlock) => {
    setRedirectUrl(dealItem);
    navigate('/login');
    uiStore?.universalModal.close();
  };

  const handleSignup = (dealItem: IDealBlock) => {
    setRedirectUrl(dealItem);
    navigate('/sign-up');
    uiStore?.universalModal.close();
  };

  const handleGuestInquiry = (dealInfo: IDealBlock) => {
    beginChat(dealInfo);
  };

  const beginChat = async (dealInfo: IDealBlock) => {
    try {
      let chatInfo: ChatModel | undefined;
      if (myChannel) {
        chatInfo = {
          members: [myChannel],
          operators: [dealInfo?.channel as ChannelDto, myChannel],
          deal: dealInfo,
        };
        await initChat(myChannel.channelName as string);
      } else {
        let guestId = localStorage.getItem('guestId');
        if (guestId === null) {
          guestId = uuidv4();
          localStorage.setItem('guestId', guestId);
        }
        const guestChannel = {
          channelName: guestId,
        };

        localStorage.setItem(`guest-inquiry`, `${dealInfo.linkKey}`);

        chatInfo = {
          members: [guestChannel],
          operators: [dealInfo?.channel as ChannelDto, guestChannel],
          deal: dealInfo,
          guestInfo: { id: guestId },
        };

        uiStore.universalModal.close();
        await initChat(guestId as string);
      }
      if (isMobile) {
        navigate('/' + dealInfo?.channel?.channelName + '/inquiry-chat/' + dealInfo?.linkKey);
      } else {
        setTimeout(() => {
          uiStore.modal.show({
            // style: { height: '100vh', borderRadius: 0 },
            // 일반 고객 채팅
            children: <CustomerChatContainer chatInfo={chatInfo} seller={currentChannel} />,
            onClose: () => {
              disconnect();
            },
          });
        }, 400);
      }
    } catch (e) {
      alert(e.message);
    }
  };

  const ConfirmLogin = ({ dealItem }: { dealItem: IDealBlock }) => {
    return (
      <Row style={{ flexDirection: 'column', alignItems: 'center', gap: 5 }}>
        <Text type="H4" style={{ marginBottom: 20 }}>
          로그인이 필요한 서비스입니다.
        </Text>
        <OhmossButton
          type="primaryPc"
          style={{ width: 150 }}
          onClick={() => {
            handleSignup(dealItem);
          }}
        >
          회원가입하기
        </OhmossButton>
        <OhmossButton
          type="link"
          onClick={() => {
            handleLogin(dealItem);
          }}
        >
          로그인
        </OhmossButton>
        <Divider style={{ width: 200 }} />
        <OhmossButton
          type="link"
          onClick={() => {
            handleGuestInquiry(dealItem);
          }}
        >
          비회원으로 문의하기
        </OhmossButton>

        <InfoBox style={{ margin: 20 }}>
          "비회원으로 문의하기"는 동일한 브라우저에서만 판매자의 답글 확인이 가능합니다.
        </InfoBox>
      </Row>
    );
  };

  const handleClickInquiry = (dealItem: IDealBlock) => {
    if (myChannel) {
      if (myChannel?.id !== dealItem?.channel?.id) {
        beginChat(dealItem);
      } else {
        toast.warn('본인의 공구에는 문의를 할 수 없습니다.');
      }
    } else {
      uiStore.universalModal.show({
        modalOption: { style: { maxWidth: 500, maxHeight: 400 } },
        children: <ConfirmLogin dealItem={dealItem} />,
      });
    }
  };

  return {
    beginChat,
    // ConfirmLogin,
    handleClickInquiry,
  };
};

export default LinkServicePageController;
