import React from 'react';
import { Row } from 'components/commons/layouts';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';
import { LinkModel } from 'types/CommonTypes';
import { linkTypes, ILinkItemType } from '../definitions/LinkTypes';

const LinkTypeSelector = observer(
  ({
    selectedType,
    onClickLinkType,
  }: {
    selectedType: ILinkItemType;
    onClickLinkType: (linkType: ILinkItemType, model: LinkModel | undefined) => void;
  }) => {
    return (
      <LinkEditorStyle>
        <Row
          style={{
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          {linkTypes.map((linkType: ILinkItemType) => (
            <>
              {!linkType.exclusive && (
                <LinkTypeStyle
                  selected={selectedType?.id === linkType.id}
                  onClick={() => {
                    onClickLinkType(linkType, undefined);
                  }}
                >
                  <div>
                    <img
                      src={linkType.iconSrc}
                      style={{
                        margin: 10,
                        width: 20,
                        height: 20,
                      }}
                      alt=""
                    />
                  </div>
                </LinkTypeStyle>
              )}
            </>
          ))}
        </Row>
      </LinkEditorStyle>
    );
  },
);

const LinkTypeStyle = styled.div<{ selected: boolean }>`
  font-size: 14px;
  cursor: pointer;
  margin: 3px;
  & > div {
    /* background-color: ${(props) => (props.selected ? '#F8FFFB' : '#F7F7F7')}; */
    border: ${(props) => (props.selected ? '1px solid transparent' : '1px solid #d7d7d7')};
    opacity: ${(props) => (props.selected ? 1 : 0.7)};
    background: ${(props) => (props.selected ? '#11C668' : 'transparent')};
    color: ${(props) => props.selected && `#11C668`};
    padding: 0 10px;
    > img {
      ${(props) =>
        props.selected &&
        css`
          filter: brightness(0) invert(1);
        `}
    }
    padding: 0 5px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
`;

const LinkEditorStyle = styled.div`
  width: 95%;
  margin: auto;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12); */
  margin-top: 10px;
  padding-bottom: 10px;
  @media ${(props) => props.theme.media.mobile} {
  }
`;
export default LinkTypeSelector;
