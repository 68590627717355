import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import List from '@mui/material/List';

interface MenuItemDto {
  title: string;
  code?: string;
  img?: string;
  onClick: () => void;
}

interface MenuPopupDto {
  languageOpen?: boolean;
  open: boolean;
  anchorEl: null | HTMLElement;
  menuList: MenuItemDto[];
  handleClose: () => void;
}

const MenuPopup = ({ open, anchorEl, menuList, handleClose, languageOpen }: MenuPopupDto) => {
  const { i18n } = useTranslation();

  const languageList = [
    { title: '한글', code: 'ko' },
    { title: 'ENG', code: 'en' },
    { title: '日本語', code: 'jp' },
    // { title: 'cn' },
    // { title: 'sp' },
  ];

  const handleClickChangeLanguage = (target: string) => {
    i18n.changeLanguage(target);
    localStorage.setItem('language', target);
    document.documentElement.lang = target;
  };

  return (
    <Menu
      className="gnb-menu"
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      // onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          backgroundColor: '#ffffffde',
          paddingTop: '0px !important',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          borderRadius: '16px',
          marginTop: '8px',
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {languageOpen === true ? (
        <List>
          {languageList?.map((item: any, i: number) => (
            <MenuItem
              key={i}
              onClick={() => {
                handleClickChangeLanguage(item.code);
                handleClose();
              }}
              sx={{
                justifyContent: 'space-evenly',
                borderTop: `${i === 0 ? '' : '1px solid rgba(0,0,0,0.1)'}`,
                width: '180px',
                height: '56px',
                fontWeight: '600',
              }}
            >
              {item.title}
            </MenuItem>
          ))}
        </List>
      ) : (
        <List>
          {menuList?.map((item: MenuItemDto, i: number) => (
            <MenuItem
              key={i}
              onClick={item.onClick}
              sx={{
                justifyContent: 'space-evenly',
                borderTop: `${i === 0 ? '' : '1px solid rgba(0,0,0,0.1)'}`,
                width: '180px',
                height: '56px',
                fontWeight: '600',
                padding: '20px',
              }}
            >
              <img src={item?.img} width={20} height={20} style={{ marginRight: 15 }} />
              {item.title}
            </MenuItem>
          ))}
        </List>
      )}
    </Menu>
  );
};

export default MenuPopup;
