import React, { useEffect, useState, useRef, ReactNode } from 'react';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import { useStore } from 'stores/StoreHelper';
import styled from 'styled-components';
import { convertBase64Files2 } from './commonLogin';
import { Row } from '../layouts';
import { CustomFile } from 'types/BlockExtendsTypes';

interface Props {
  children?: ReactNode;
  attachType?: 'video' | 'image' | 'media';
  onSelected: (image: CustomFile | CustomFile[]) => void;
}

/**
 * @description 이미지 선택부터 크롭까지 제공한다.
 * @params type : copper, single, multiple
 * @returns
 */
const MultipleImageSelector = ({ children, attachType, onSelected }: Props) => {
  const { uiStore } = useStore();
  const onChangeFile = useRef<any>(null);

  const files = useRef<any>(null);
  const [accept, setAccept] = useState<string>('');
  // const [images, setImages] = useState<CustomFile | CustomFile[]>();

  useEffect(() => {
    switch (attachType) {
      case 'image':
        setAccept('.jpg, .jpeg, .png, .gif');
        break;
      case 'video':
        setAccept('.mp4');
        break;
      case 'media':
        setAccept('.jpg, .jpeg, .png, .gif, .mp4');
        break;
      default:
        setAccept('');
        break;
    }
  }, [attachType]);

  return (
    <>
      <>
        <DefaultIconStyle
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            onChangeFile.current.click(e);
          }}
        >
          <CameraIconStyle>
            <CameraAltIcon sx={{ width: 30, height: 30 }} />
          </CameraIconStyle>
        </DefaultIconStyle>
      </>
      <input
        type="file"
        multiple
        accept={accept}
        id="file"
        ref={onChangeFile}
        onInput={async () => {
          files.current = onChangeFile.current.files;
          const converted = (await convertBase64Files2(files.current)) as unknown as CustomFile[];
          // setImages(converted);
          onSelected(converted);
          uiStore.bottomSheet.close();
        }}
        style={{ display: 'none' }}
      />
      {children && children}
    </>
  );
};

const DefaultIconStyle = styled.div`
  width: 60px;
  min-width: 60px;
  margin-right: 15px;
  height: 60px;
  background: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  :hover {
    background: #2ebe59;
  }
`;

const CameraIconStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default MultipleImageSelector;
