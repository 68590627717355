import styled from 'styled-components';

export const BlocktitleBarStyle = styled.div`
  /* font-weight: 700; */
  padding: 0px 4px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 100%;
  flex-grow: 1;
  white-space: pre-line;
  > div:first-child {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  @media ${(props) => props.theme.media.mobile} {
    /* font-size: 12px; */
  }
`;
