import React from 'react';
import styled from 'styled-components';

const MembershipPaymentHistory = ({ history }: { history?: any }) => {
  const getPurchaseDate = (date: string) => {
    if (date) {
      const newDate = new Date(date);
      const year = newDate.getFullYear();
      const month = `0${newDate.getMonth() + 1}`.slice(-2);
      const day = `0${newDate.getDate()}`.slice(-2);
      const localDateString = `${year}-${month}-${day}`;
      return localDateString;
    }
  };

  const formatNumberWithCommas = (number: number) => {
    if (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  };

  return (
    <CardContainer style={{ padding: '20px 0' }}>
      <CardLine style={{ padding: '10px' }}>
        <CardItemTitle>결제일자</CardItemTitle>
        <CardItemTitle>멤버십</CardItemTitle>
        <CardItemTitle>결제수량</CardItemTitle>
        <CardItemTitle>결제금액</CardItemTitle>
      </CardLine>

      <CardLine style={{ padding: '0 1rem' }}>
        <Divider />
      </CardLine>

      {history?.map((item: any, index: number) => (
        <CardLine key={index} style={{ padding: '10px 0' }}>
          <>{console.log(item)}</>
          <CardItem>{getPurchaseDate(item.purchaseDate as string)}</CardItem>
          <CardItem>{item.membershipPlan?.name}</CardItem>
          <CardItem>
            {item?.monthly}개월 {item?.receiverChannel ? '선물' : null}
          </CardItem>
          <CardItem>{formatNumberWithCommas(item?.membershipPlan?.amount as number)}원</CardItem>
        </CardLine>
      ))}
    </CardContainer>
  );
};

const CardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  border-radius: 1.25rem;
  align-self: stretch;
  background-color: #fff;
  padding-bottom: 0.5rem;
`;

const CardLine = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5rem 1.5rem;
`;

const CardItemTitle = styled.div`
  color: #000;
  font-size: 13px;
  font-weight: 300;
  text-align: center;
  width: 25%;
`;

const Divider = styled.div`
  width: 100%;
  border: 0.9px solid #e3e3e3;
`;

const CardItem = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  width: 25%;
`;

export default MembershipPaymentHistory;
