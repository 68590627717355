import React, { useEffect, useState } from 'react';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import { MemberDto } from 'services/data-contracts';
import { ContextContainer, ContextItem } from 'resources/styles/common/ContextItem';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';

import AgencyController from 'controllers/BrandController';
import Deal from 'features/management/components/block/Deal/Deal';
import useNavigation from 'hooks/useNavigation';

const DealListContenxt = ({ deal }: { deal: LinkDtoWithAssignedDeal }) => {
  const { uiStore } = useStore();
  const { navigate } = useNavigation();

  const handleClickShowInquries = () => {
    const isCsTown = window.location.pathname.indexOf('/cstown') === 0;

    if (isCsTown) {
      navigate(`/cstown/deal/${deal?.id}/inquiries`);
    } else {
      uiStore.confirm.show({
        message: '고객문의는 CSTown에서 조회가 가능합니다. \r\nCSTown으로 이동하시겠습니까?',
        onConfirmed: async () => {
          navigate(`/cstown/deal/${deal?.id}/inquiries`);
        },
      });
    }
  };

  // useEffect(() => {
  //   if (myChannel) {
  //     getMemberList();
  //   }
  // }, [myChannel]);

  return (
    <ContextContainer>
      <ContextItem
        onClick={() => {
          handleClickShowInquries();
        }}
      >
        고객 문의 관리
      </ContextItem>
      <ContextItem
        onClick={() => {
          uiStore.bottomSheet.close();
        }}
      >
        대시보드 보기
      </ContextItem>

      <ContextItem style={{ padding: '20px 0' }}>
        <OhmossButton
          onClick={() => {
            uiStore.bottomSheet.close();
          }}
        >
          닫기
        </OhmossButton>
      </ContextItem>
    </ContextContainer>
  );
};

export default DealListContenxt;
