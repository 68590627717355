import React, { useEffect } from 'react';

import { SimpleDealDto } from 'services/data-contracts';
import { ListBoxItemTemplate } from 'features/csTown/commonComponents/styles';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useStore } from 'stores/StoreHelper';
import { GroupChannel } from '@sendbird/chat/groupChannel';

import ChatContainer from 'features/csTown/containers/ChatContainer';
import useSandbirdChat from 'hooks/useSandbirdChat';
import CircularLoading from 'components/commons/CircularLoading';
import { ChannelQueryType } from 'types/CommonTypes';
import DealInquiryList from '../../DealInquiryList';

const InquiryList = ({ deal }: { deal?: SimpleDealDto }) => {
  const { myChannel } = useRootContext();
  const { getInquiryChannels, dispose, markAsRead } = useSandbirdChat();
  const { uiStore } = useStore();

  /**
   * @description 딜에 해당하는 문의만 취득하기.
   */
  const getInquiries = async () => {
    // getInquiryChannels('itemKey', [deal?.linkKey || '']);
    const query: ChannelQueryType = {
      channelType: 'ITEM',
      metaKey: 'itemKey',
      metaValues: [deal?.linkKey || ''],
    };
    getInquiryChannels(query);
  };

  useEffect(() => {
    if (deal) {
      getInquiries();
    }
  }, [deal]);

  useEffect(() => {
    return () => {
      dispose();
    };
  }, []);

  const handleJoinChannel = (channel: GroupChannel) => {
    markAsRead(channel?.url);
    if (myChannel) {
      uiStore.modal.show({
        style: { height: '80vh', maxHeight: '80vh' },
        children: <ChatContainer chatroomUrl={channel?.url} type="new" />,
      });
    }
  };
  return (
    <div style={{ position: 'relative' }}>
      <CircularLoading />
      <div style={{ flex: 0.5 }}>
        <ListBoxItemTemplate>
          <DealInquiryList onJoinChannel={handleJoinChannel} deal={deal} />
        </ListBoxItemTemplate>
      </div>
    </div>
  );
};

export default InquiryList;
