import React, { useEffect, useState, useCallback, useLayoutEffect } from 'react';
import styled from 'styled-components';

import { validationHelper as VH } from 'controls/validationHelper';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { debounce } from 'lodash';

import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import { LoginDto } from 'services/data-contracts';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import CheckIcon from '@mui/icons-material/Check';
import useChannelSelector from 'libs/hooks/useChannelSelector';
import EmailForm from 'components/items/forms/EmailForm';
import PasswordForm from 'components/items/forms/PasswordForm';
import { useRootContext } from 'libs/hooks/useRootContext';
import LoginPageController from 'pages/pageControllers/LoginPageController';

const EmailLogin = ({ type }: { type?: 'manager' | 'normal' }) => {
  const { t } = useTranslation();
  const { termStore, authStore } = useStore();
  const { redirect, doLogin } = LoginPageController();

  const { getMyChannel } = useRootContext();

  const [model, setModel] = useState<LoginDto>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | undefined>(undefined);
  const [meetsPassword, setMeetsPassword] = useState<boolean>(false);

  const checkValidation = () => {
    if (type === 'normal') {
      const emailResult = VH.email(model?.username) && emailErrorMessage === '';
      const pwResult =
        typeof model?.password !== 'undefined' &&
        VH.password(model?.password) &&
        model?.password?.length > 7;

      setIsValid(emailResult && pwResult);
    } else {
      const pwResult =
        typeof model?.password !== 'undefined' &&
        VH.password(model?.password) &&
        model?.password?.length > 7;
      setIsValid(pwResult);
    }
  };

  const handleChangeEmail = useCallback(
    debounce(async (username) => {
      const emailResult = VH.email(username);
      if (emailResult) {
        setValidEmail(true);
        setEmailErrorMessage('');
        setModel((prev) => {
          return { ...prev, username };
        });
      } else {
        setEmailErrorMessage(t('A101'));
      }
      setModel((prev) => {
        return { ...prev, username };
      });
    }, 500),
    [],
  );

  const handleChangeId = useCallback(
    debounce(async (userid) => {
      setModel((prev) => {
        return { ...prev, username: userid };
      });
    }, 500),
    [],
  );

  const handleClickLogin = async () => {
    let result;
    if (model && isValid && type !== 'manager') {
      /** Emaillogin */
      result = await authStore.emailLogin(model);
      if (result) {
        doLogin();
        redirect();
      } else {
        toast(t('A102'), { type: 'warning' });
      }
    } else if (model && type === 'manager') {
      // 매니저로 로그인
      result = await authStore.emailLogin(model);
      if (result) {
        doLogin();
        redirect();
      } else {
        toast(t('A102'), { type: 'warning' });
      }
    }
    if (result) {
      getMyChannel();
    }
  };

  const handleEnterKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleClickLogin();
    }
  };

  useEffect(() => {
    if (model) {
      checkValidation();
    }
  }, [model]);

  useLayoutEffect(() => {
    termStore.getTerms();
  }, []);

  return (
    <LoginBodyStyle style={{ padding: '0 20px' }}>
      <FormControl sx={{ width: '100%' }} variant="outlined">
        {type === 'manager' && (
          <OutlinedInput
            label="userid"
            required
            inputProps={{
              onBlur: (e: any) => {
                checkValidation();
              },
            }}
            onChange={(e: any) => {
              handleChangeId(e.target.value);
            }}
          />
        )}
        {type === 'normal' && (
          <>
            <InputLabel sx={{ fontSize: 11, marginTop: -1.4 }}>이메일</InputLabel>
            <EmailForm
              model={model?.username}
              surfix={validEmail ? <CheckIcon /> : <></>}
              onChangeValue={(value: string) => handleChangeEmail(value)}
            />
          </>
        )}
      </FormControl>
      <FormControl onKeyDown={handleEnterKeyDown} sx={{ width: '100%' }} variant="outlined">
        <InputLabel sx={{ fontSize: 11, marginTop: -1.4 }}>비밀번호</InputLabel>
        <PasswordForm
          showLabel={false}
          type="login"
          onChangeValue={(value: string) => {
            setMeetsPassword(value.length < 8);
            setModel((prev) => {
              return { ...prev, password: value };
            });
          }}
        />
      </FormControl>

      <OhmossButton
        disabled={!isValid}
        style={{ minWidth: 300, height: 50, marginTop: 20 }}
        onClick={handleClickLogin}
      >
        {t('A94')}
      </OhmossButton>
    </LoginBodyStyle>
  );
};

const LoginBodyStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 14px;
`;
export default EmailLogin;
