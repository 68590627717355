import React, { useRef, CSSProperties, useState, useEffect } from 'react';
import { SelfIntroduction, Title } from 'features/adminFeatures/template/components';
import { TextAreaStyle } from 'features/adminFeatures/template/components/TemplateStyle';
import { TextToolBox } from 'components/commons';
import { TextContent } from 'types/CommonTypes';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@mui/material';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { Row } from 'components/commons/layouts';

import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';
import TitleForm from 'components/items/forms/TitleForm';

const DescriptionEditor = observer(
  ({
    description,
    descriptionProps,
    label,
    onChangeDescription,
    onChangeProps,
  }: {
    description?: string;
    descriptionProps?: TextContent;
    label?: string;
    onChangeDescription: (value?: string) => void;
    onChangeProps?: (props?: TextContent) => void;
  }) => {
    const { t } = useTranslation();
    const { uiStore } = useStore();

    const inputRef = useRef<any>(null);

    /** @description 변경전 임시값을 저장해 놓는다. */
    const descriptionRef = useRef<string | undefined>('');
    const descriptionPropsRef = useRef<TextContent | undefined>();

    const [descriptionState, setDescriptionState] = useState<string | undefined>('');
    const [descriptionPropsModel, setDescriptionsPropsModel] = useState<TextContent | undefined>();
    const [isDescriptionModify, setIsDescriptionModify] = useState<boolean>(false);

    useEffect(() => {
      if (typeof onChangeProps !== 'undefined') {
        onChangeProps(descriptionPropsModel);
      }
    }, [descriptionPropsModel]);

    // useEffect(() => {
    //   if (typeof onChangeDescription !== 'undefined') {
    //     onChangeDescription(descriptionState);
    //   }
    // }, [descriptionState]);

    useEffect(() => {
      if (typeof descriptionProps !== 'undefined') {
        setDescriptionsPropsModel({ ...descriptionProps });
      }
    }, [descriptionProps]);

    useEffect(() => {
      if (typeof description !== 'undefined') {
        setDescriptionState(description);
      } else {
        setDescriptionState('');
      }
    }, [description]);

    useEffect(() => {
      if (uiStore.status === 'done') {
        setIsDescriptionModify(false);
      }
    }, [uiStore.status]);

    return (
      <TitleAreaStyle>
        <div style={{ position: 'relative' }}>
          <div>
            <Row style={{ justifyContent: 'space-between' }}>
              <>{typeof label !== 'undefined' && <div>{label}</div>}</>
              {!isDescriptionModify ? (
                <IconButton
                  onClick={() => {
                    setIsDescriptionModify((prev) => !prev);
                    descriptionRef.current = descriptionState;
                    descriptionPropsRef.current = descriptionPropsModel;
                    inputRef.current.handleFocus();
                  }}
                >
                  <EditIcon />
                </IconButton>
              ) : (
                <div style={{ background: 'rebeccapurple', borderRadius: 30 }}>
                  <IconButton
                    onClick={() => {
                      setIsDescriptionModify((prev) => !prev);
                    }}
                  >
                    <DoneIcon style={{ color: '#fff' }} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setIsDescriptionModify((prev) => !prev);
                      setDescriptionState(descriptionRef.current);
                      setDescriptionsPropsModel(descriptionPropsRef.current);
                    }}
                  >
                    <ClearIcon style={{ color: '#fff' }} />
                  </IconButton>
                </div>
              )}
            </Row>
          </div>
          {!isDescriptionModify && (
            <div style={{ minHeight: 50 }}>
              {/* <Title title={titleState} titleProps={titlePropsModel} /> */}
              <SelfIntroduction
                mode="edit"
                selfIntroduction={descriptionState}
                // selfIntroductionProps={descriptionPropsModel}
              />
            </div>
          )}
        </div>
        {isDescriptionModify && (
          <div style={{ marginTop: 20, width: '100%' }}>
            <TitleForm
              ref={inputRef}
              label={label || 'Self introduction'}
              multiline
              required={false}
              model={descriptionState}
              onChangeValue={(value: string) => {
                if (typeof onChangeDescription !== 'undefined') {
                  onChangeDescription(value);
                }
              }}
            />

            <TextToolBox
              show
              colorPickerLocation="top"
              showImageIcon
              style={descriptionPropsModel?.style}
              containerStyle={toolBoxStyle}
              onChangeStyle={(style: CSSProperties) => {
                setDescriptionsPropsModel((prev: TextContent) => {
                  return {
                    ...prev,
                    style: { ...prev?.style, ...style },
                  };
                });
              }}
            />
          </div>
        )}
      </TitleAreaStyle>
    );
  },
);

const TitleAreaStyle = styled(TextAreaStyle)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: center;
  position: relative;
  width: auto;
`;

const toolBoxStyle = {
  borderRadius: 12,
  border: 'none',
};

export default DescriptionEditor;
