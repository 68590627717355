import TitleForm from 'components/items/forms/TitleForm';
import React, { useCallback, useEffect, useState } from 'react';
import { ItemSectionTitle } from 'resources/controls/ItemSectionStyle';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import styled from 'styled-components';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { t } from 'i18next';
import MyDealStore from 'stores/MyDealStore';
import {
  ChannelDto,
  LinkDto,
  PartnerDto,
  PartnerInvitationCreationDto,
} from 'services/data-contracts';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import useSetState from 'libs/hooks/useSetState';
import PartnerController from 'controllers/PartnerController';
import { toast } from 'react-toastify';
import { validationHelper } from 'controls/validationHelper';
import { CSTownPartnerState, LinkDtoWithAssignedDeal } from 'types/CommonTypes';
import BrandSearchBox from 'components/commons/BrandSearchBox';
import { useRootContext } from 'libs/hooks/useRootContext';
import { debounce } from 'lodash';
import ChannelStore from 'stores/ChannelStore';
import { formatString } from 'libs/helper/utils';
import ChannelComponent from 'features/commonFeatures/components/ChannelComponent';
import { Row } from 'components/commons/layouts';
import { useStateContext } from 'libs/hooks/usePageState';
import CSTownPartnersController from 'features/csTown/pages/pageControllers/CSTownPartnersController';
import DeleteIcon from 'features/csTown/commonComponents/DeleteIcon';
import AssignedDealsList from './AssignedDealsList';
import AssignedDealsContainer from './AssignedDealsContainer';
import MembershipChangeInfo from '../components/membership/MembershipChangeInfo';

interface PartnerModel extends ChannelDto {
  isPartner?: boolean;
  type?: 'PARTNER' | 'INVITATION';
}

const PartnerSettingsContainer = ({
  memberInfo,
  showDeals = true,
  onReady,
}: {
  memberInfo?: PartnerDto;
  showDeals?: boolean;
  onReady?: (model?: PartnerInvitationCreationDto) => void;
}) => {
  const { uiStore } = useStore();
  const { myChannel } = useRootContext();
  const { changeAssignedDeals } = CSTownPartnersController();
  const { broadcast, stateModel } = useStateContext<CSTownPartnerState>();
  const { serviceModel, updateServiceModel, updateServiceModelMultiple, unmountModel } =
    useModelManagement<PartnerInvitationCreationDto>();

  const myDealStore = new MyDealStore();
  const channelStore = new ChannelStore();
  const {
    createPartnerInvitation,
    getPartnerDeals1,
    updatePartner,
    deletePartner,
    getPartnersDelegatedDeals,
  } = PartnerController();

  const [localModel, setLocalModel] = useSetState<PartnerInvitationCreationDto>();
  const [ongoingDeals, setOngoingDeals] = React.useState<LinkDto[]>([]);
  const [selectedDeals, setSelectedDeals] = React.useState<LinkDtoWithAssignedDeal[]>([]);
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [searchedChannels, setSearchedChannels] = useState<PartnerModel[]>();
  const [selectedPartner, setSelectedPartner] = useState<ChannelDto>();

  const getMyDeals = async () => {
    const dealsTemp = (await myDealStore.getMyDeals(
      myChannel?.id as number,
    )) as unknown as LinkDto[];

    setOngoingDeals(dealsTemp);
  };

  const handleChangeChannelName = useCallback(
    debounce(async (channelName) => {
      if (channelName.length > 1) {
        const result = (await channelStore.searchBrandChannels(
          channelName,
          myChannel?.id as number,
        )) as unknown as PartnerModel[];

        const newResult = result.map((item) => {
          const recentParenters = stateModel?.partnerList?.find(
            (partner) => partner.partnerChannel?.id === item.id,
          );
          const recentInvitatiosn = stateModel?.invitationList?.find(
            (partner) => partner.partnerChannel?.id === item.id,
          );
          return { ...item, isPartner: !!(recentParenters || recentInvitatiosn) };
        });

        setSearchedChannels(newResult);
      } else {
        setSearchedChannels([]);
      }
    }, 500),
    [],
  );

  const handleOpenListOfDeals = async () => {
    let deals: LinkDto[] | undefined = [];
    if (memberInfo?.id) {
      deals = (await getPartnersDelegatedDeals(memberInfo?.id)) as unknown as LinkDto[];
    } else {
      deals = ongoingDeals;
    }
    if (myChannel && ongoingDeals.length > 0) {
      uiStore.bottomSheet.show({
        title: '위임할 공구목록',
        style: { minHeight: '80vh' },
        children: (
          <AssignedDealsList
            dealList={deals as LinkDto[]}
            onSelected={(deals: LinkDto[]) => {
              const newDeals = changeAssignedDeals(
                deals,
                memberInfo,
              ) as unknown as LinkDtoWithAssignedDeal[];
              setLocalModel(
                'deals',
                newDeals?.filter((deal) => deal.assigned) as LinkDtoWithAssignedDeal[],
              );
              setSelectedDeals(
                newDeals?.filter((deal) => deal.assigned) as LinkDtoWithAssignedDeal[],
              );
            }}
          />
        ),
      });
    }
  };

  const handleCreatePartnerInvitation = async () => {
    if (myChannel && selectedDeals && serviceModel) {
      const newPartnerInvitation = {
        channelName: serviceModel?.channelName,
        alias: serviceModel?.alias,
        phoneNumber: serviceModel?.phoneNumber,
        deals: selectedDeals ?? [],
      } as PartnerInvitationCreationDto;

      const result = (await createPartnerInvitation(newPartnerInvitation)) as any;

      if (result?.status === 200) {
        uiStore.modal.close();
        broadcast({ id: 'reload', param: 'invitation' });
      }
    }
  };

  const handleUpdatePartnerInfo = async () => {
    if (memberInfo && localModel) {
      const newPartnerInfo = {
        id: memberInfo?.id,
        partnerChannel: localModel?.channelName,
        alias: localModel?.alias,
        phoneNumber: localModel?.phoneNumber,
        isActive: true,
      } as PartnerDto;

      const result = await updatePartner(memberInfo?.id as number, newPartnerInfo);
      if (result) {
        uiStore.modal.close();
      } else {
        toast.error('파트너 정보 수정에 실패하였습니다.');
      }
    }
  };

  const handleDeletePartner = async () => {
    if (memberInfo) {
      uiStore.confirm.show({
        title: '파트너 삭제',
        message: '파트너를 삭제하시겠습니까?',
        onConfirmed: async () => {
          await deletePartner(memberInfo);
          uiStore.modal.close();
        },
      });
    }
  };

  const validateForm = async () => {
    const channelName = validationHelper.required(selectedPartner?.channelName as string);
    const aliasValid = validationHelper.required(localModel?.alias as string);
    // const phoneNumberValid = validationHelper.tel(localModel?.phoneNumber as string);
    if (showDeals) {
      if (channelName && aliasValid && selectedDeals.length > 0) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    } else if (channelName && aliasValid) {
      onReady?.(serviceModel);
    } else {
      onReady?.(undefined);
    }
  };

  const getPartnersDeals = async () => {
    const dealsTemp = await getPartnerDeals1(memberInfo?.id as number);
    if (dealsTemp) {
      setSelectedDeals(dealsTemp as LinkDto[]);
    }
  };

  useEffect(() => {
    if (myChannel) {
      getMyDeals();
    }
  }, [myChannel]);

  useEffect(() => {
    validateForm();
  }, [localModel]);

  useEffect(() => {
    if (memberInfo) {
      setLocalModel(memberInfo);
      updateServiceModelMultiple({
        channelName: memberInfo?.channel?.channelName,
        alias: memberInfo?.alias,
        phoneNumber: memberInfo?.phoneNumber,
      });
      getPartnersDeals();
    }
  }, [memberInfo]);

  useEffect(() => {
    unmountModel();
  }, []);

  return (
    <>
      <ContainerStyle>
        <InputBoxWrapper>
          <ItemSectionTitle style={{ margin: '10px 0px 5px 0px', fontSize: 13 }}>
            파트너채널 검색
          </ItemSectionTitle>

          <BrandSearchBox
            onChangeValue={(value: string) => {
              handleChangeChannelName(value);
            }}
            memberInfo={memberInfo}
            onFixSearchBox={(channel: ChannelDto) => {
              // setLocalModel('channelName', channel.channelName);
              // updateServiceModel('channelName', channel.channelName);
            }}
          />
          <>
            {selectedPartner && (
              <Row style={{ justifyContent: 'space-between' }}>
                <ChannelComponent channel={selectedPartner} onSelected={() => {}} mode="view" />
                {/* <div>선택된 파트너</div> */}
                <DeleteIcon
                  onClick={() => {
                    setSelectedPartner(undefined);
                    setSearchedChannels(undefined);
                    updateServiceModel('channelName', undefined);
                    validateForm();
                  }}
                />
              </Row>
            )}
            <ChannelsStyle>
              <>
                {typeof searchedChannels !== 'undefined' && searchedChannels?.length > 0 ? (
                  <ChannelResultBoxStyle>
                    <>
                      {searchedChannels.length > 0 ? (
                        <>{formatString(t('A238'), searchedChannels.length)}</>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <ErrorOutlineIcon style={{ fontSize: 20 }} />
                          {t('A239')}
                        </div>
                      )}
                    </>
                  </ChannelResultBoxStyle>
                ) : (
                  <>
                    {typeof searchedChannels !== 'undefined' && (
                      <div
                        style={{
                          fontSize: 13,
                          padding: 10,
                          border: '1px solid #ebebeb',
                          borderRadius: 8,
                        }}
                      >
                        검색된 브랜드 채널이 없습니다.
                      </div>
                    )}
                  </>
                )}
              </>
              {typeof searchedChannels !== 'undefined' &&
                searchedChannels?.length > 0 &&
                searchedChannels?.map((channel: PartnerModel) => (
                  <Row style={{ justifyContent: 'space-between' }}>
                    <ChannelComponent
                      channel={channel as PartnerModel}
                      onSelected={() => {}}
                      mode="view"
                    />
                    {channel?.isPartner ? (
                      <div style={{ fontSize: 12, color: 'orange' }}>
                        추가되어 있거나
                        <br /> 초대중입니다.
                      </div>
                    ) : (
                      <OhmossButton
                        style={{ width: 60, height: 30, padding: 5 }}
                        type="square"
                        onClick={() => {
                          setSelectedPartner(channel);
                          setSearchedChannels(undefined);
                          updateServiceModel('channelName', channel?.channelName);
                          onReady?.(serviceModel);
                        }}
                      >
                        선택
                      </OhmossButton>
                    )}
                  </Row>
                ))}
            </ChannelsStyle>
          </>
          <ItemSectionTitle style={{ margin: '10px 0px 5px 0px', fontSize: 13 }}>
            파트너 호칭
          </ItemSectionTitle>
          <TitleForm
            style={{ borderColor: 'rgba(0, 0, 0, 0.35)' }}
            onChangeValue={(value: string) => {
              setLocalModel('alias', value);
              updateServiceModel('alias', value);
            }}
            placeHolder="이름 또는 브랜드, 에이전시의 소속을 입력 하세요."
            initialValue={(memberInfo?.alias as string) ?? ''}
          />

          <ItemSectionTitle style={{ margin: '10px 0px 5px 0px', fontSize: 13 }}>
            연락처
          </ItemSectionTitle>
          <TitleForm
            validationType="tel"
            style={{ borderColor: 'rgba(0, 0, 0, 0.35)' }}
            onChangeValue={(value: string) => {
              setLocalModel('phoneNumber', value);
              updateServiceModel('phoneNumber', value);
            }}
            placeHolder="파트너의 연락처를 입력하세요."
            initialValue={(memberInfo?.phoneNumber as string) ?? ''}
          />

          {selectedDeals && selectedDeals?.length > 0 && (
            <AssignedDealsContainer deals={selectedDeals} />
          )}
        </InputBoxWrapper>
        {showDeals && (
          <ButtonWrapper>
            <OhmossButton type="secondary" onClick={handleOpenListOfDeals}>
              내 공구목록
            </OhmossButton>
            {typeof memberInfo === 'undefined' && (
              <OhmossButton disabled={!isValid} onClick={handleCreatePartnerInvitation}>
                확인
              </OhmossButton>
            )}
            <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
              {typeof memberInfo !== 'undefined' && (
                <OhmossButton type="simple" onClick={handleDeletePartner}>
                  삭제
                </OhmossButton>
              )}
              {typeof memberInfo !== 'undefined' && (
                <OhmossButton disabled={!isValid} onClick={handleUpdatePartnerInfo}>
                  확인
                </OhmossButton>
              )}
            </div>
          </ButtonWrapper>
        )}
      </ContainerStyle>
    </>
  );
};

const ContainerStyle = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;

  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
`;

const InputBoxWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
`;
const DeleteButton = styled.div`
  cursor: pointer;
  color: #ff0000;
  font-weight: 500;
  padding: 10px 0px 0px 10px;
  font-size: 12px;
`;

const ChannelsStyle = styled.div`
  margin-top: 8px;
  margin-left: 2px;
  color: #cacaca;
`;

const ChannelResultBoxStyle = styled.div`
  padding: 10px 20px;
  background: #efefef;
  margin: 10px 0;
  border-radius: 15px;
`;

export default PartnerSettingsContainer;
