import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { IconButton } from '@mui/material';
import { stripedSting } from 'components/items/commonLogic';
import { useStore } from 'stores/StoreHelper';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { parseJSONAll } from 'libs/helper/utils';
import { DealServiceItemModel } from 'types/CommonTypes';

import styled from 'styled-components';
import BookmarkBorder from '@mui/icons-material/BookmarkBorder';
import Countdown from 'react-countdown';
import useNavigation from 'hooks/useNavigation';
import TextLabel from 'components/commons/labels/TextLabel';
import AdItemMoreButtons from 'features/mossMain/components/AdItemMoreButtons';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import LinkStore from 'stores/LinkStore';
import DealServiceItems from 'features/management/components/block/ListItemTemplates/DealServiceItems';
import { toast } from 'react-toastify';
import useLocalParams from 'libs/hooks/useLocalParams';
import { LinkDto, PostTagDto } from 'services/data-contracts';
import { useRootContext } from 'libs/hooks/useRootContext';

const DealItemDetail = observer(
  ({
    post,
    isPreview,
    isOwner,
    onClickVisit,
    onClickTag,
    onClickFavorite,
  }: {
    post?: LinkDto;
    isPreview?: boolean;
    isOwner: boolean;
    onClickVisit?: () => void;
    onClickTag?: (tag: PostTagDto) => void;
    onClickFavorite?: (isFavorite: boolean) => void;
  }) => {
    const handleTimerComplete = () => {};
    const linkStore = new LinkStore();
    const { navigate } = useNavigation();

    const { uiStore } = useStore();
    const { channelName } = useLocalParams();
    const { myChannel } = useRootContext();
    const [cutstringLength, setCutStringLength] = useState<number>(80);
    const [isFavorite, setIsFavorite] = useState<boolean>(false);
    const [deal, setDeal] = useState<LinkDto>();

    const handleClickShowMore = () => {
      uiStore.bottomSheet.show({
        children: <AdItemMoreButtons link={post as LinkDto} />,
      });
    };

    const handleClickVisit = () => {
      uiStore.modal.close();
      linkStore.createDealVisitLog(post?.linkKey as string);
      window.open(`/agreement?url=${post?.linkUrl}`, 'deal');
    };

    const handleClickEncore = async () => {
      if (myChannel) {
        const result = await linkStore.encoreDeal(deal?.linkKey as string, myChannel.id as number);
        toast('앵콜요청이 완료되었습니다.');
      } else {
        uiStore.confirm.show({
          message: '로그인이 필요한 메뉴입니다. 로그인 하시겠습니까?',
          onConfirmed: async () => {
            navigate(`/login?action=(/${channelName}/1.${post?.linkKey})`);
          },
        });
      }
    };

    const getPost = async () => {
      const deal = (await linkStore.getItemByKey(post?.linkKey as string)) as unknown as LinkDto;

      if (deal) {
        const metadata = JSON.parse(deal?.metadata as string);
        if (deal.items) {
          const blockItems = deal?.items?.map((item) => ({
            ...item,
            ...(parseJSONAll(item?.metadata) as DealServiceItemModel),
          }));
          const newDeal = { ...deal, ...metadata, items: blockItems };
          setDeal(newDeal);
        } else {
          setDeal(deal);
        }
      }
    };

    const StrippedString = () => {
      const string = stripedSting(post?.content);
      if (string) {
        if (string.length > cutstringLength) {
          return (
            <div>
              {string.substring(0, cutstringLength)}...{' '}
              <span
                onClick={() => {
                  setCutStringLength(10000);
                }}
                style={{ color: '#BEBEBE' }}
              >
                더보기
              </span>
            </div>
          );
        }
      }
      return <div>{string}</div>;
    };

    useEffect(() => {
      if (post) {
        getPost();
        // setIsFavorite(post.ad?.isInterest || false);
      }
    }, [post]);

    return (
      <>
        <FeedItemStyle>
          <div onClick={onClickVisit}>
            <img src={deal?.thumbnail?.publicUrl} alt="" style={{ width: '100%' }} />
          </div>
          <div className="px-4">
            <InfoStyle>
              <CountDownStyle style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                <Countdown
                  daysInHours
                  date={deal?.endDate}
                  onComplete={() => {
                    handleTimerComplete();
                  }}
                />
              </CountDownStyle>
              {!isOwner && (
                <IconButton
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setIsFavorite((prev: boolean) => !prev);
                    onClickFavorite?.(!isFavorite);
                  }}
                >
                  {isFavorite ? (
                    <BookmarkIcon style={{ color: '#23BA50' }} />
                  ) : (
                    <BookmarkBorder style={{ color: '#23BA50' }} />
                  )}
                </IconButton>
              )}
            </InfoStyle>
            <TextLabel type="subject" style={{ margin: '10px 0' }}>
              {deal?.title}
            </TextLabel>
            {deal?.content && (
              <>
                {isPreview ? (
                  <TextLabel type="content" style={{ color: '#4C4C4C', fontSize: 14 }}>
                    <StrippedString />
                  </TextLabel>
                ) : (
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: deal?.content as string,
                    }}
                  />
                )}
              </>
            )}
            {/* {deal?.tags && post?.tags?.length > 0 && (
              <TagsStyle>
                {post.tags.map((tag: PostTagDto) => (
                  <div
                    onClick={() => {
                      if (onClickTag) {
                        onClickTag(tag);
                      }
                    }}
                  >
                    #{tag.name}
                  </div>
                ))}
              </TagsStyle>
            )} */}
            <PoweredByStyle>Powered by oh!moss</PoweredByStyle>
          </div>
          {deal?.linkUrl && deal.status === 'ONGOING' ? (
            <div style={{ padding: 20 }}>
              <OhmossButton onClick={handleClickVisit}>방문하기</OhmossButton>
            </div>
          ) : (
            <>
              <EncoreStyle>
                <div>본 공구는 종료되었습니다. "앵콜요청"을 해 보세요.</div>
                <div style={{ marginTop: 20 }}>
                  <OhmossButton onClick={handleClickEncore}>앵콜요청</OhmossButton>
                </div>
              </EncoreStyle>
            </>
          )}
          <DealServiceItems item={deal} />
        </FeedItemStyle>
      </>
    );
  },
);

const FeedItemStyle = styled.div`
  border-top: 1px solid #efefef;
  width: 100%;
  background: #fff;
  padding-bottom: 40px;
`;

const InfoStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const CountDownStyle = styled.div`
  & span {
    display: inline-block;
    font-weight: 600;
    letter-spacing: 2px;
  }
`;

const EncoreStyle = styled.div`
  text-align: center;
  width: 90%;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #ff9900;
`;

const PoweredByStyle = styled.div`
  text-align: right;
  font-size: 12px;
  color: #7b7b7b;
  padding: 20px;
`;
export default DealItemDetail;
