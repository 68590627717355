import { makeAutoObservable, toJS } from 'mobx';
import { FreeImage as FreeImageService } from 'services/FreeImage';

// import _ from "lodash";
import { REST_API_URL_V2 } from 'stores/AppStore';

import _ from 'lodash';
import { FileDto, FreeImageDto } from 'services/data-contracts';
import { HttpClient } from 'services/http-client';

class FreeImageStore {
  constructor() {
    makeAutoObservable(this);
  }

  api: FreeImageService<unknown> = new FreeImageService(new HttpClient());

  backgroundImages: Array<FreeImageDto>;

  *getItems() {
    const { response } = yield this.api.getFreeImages({ isActive: true });
    this.backgroundImages = response.data;
  }

  /** 링크 이미지 업로드 */
  *uploadImage(
    file: any,
    fileType: 'Profile' | 'Background' | 'Footer' | 'Link' | 'Notification' | 'Feed',
  ) {
    if (typeof file.publicUrl !== 'undefined') {
      delete file.publicUrl;
    }

    // const { response } = yield this.api.uploadFile({ fileType }, { file });
    // if (response.status === 200) {
    //   return response.data;
    // } else {
    //   return response.error;
    // }
  }
}

export default FreeImageStore;
