// useMyContext.ts
import { useContext, createContext } from 'react';
import { ChannelBoardDto, ChannelDto, LinkDto, PostDto } from 'services/data-contracts';
import { ReceiverType } from 'types/CommonTypes';

const Context = createContext<propsTypes | undefined>(undefined);

interface propsTypes {
  // getCurrentChannel: () => void;
  receiver?: ReceiverType;
  links?: LinkDto[];
  posts?: PostDto[];
  currentChannel?: ChannelDto;
  // myChannel?: ChannelDto | null;
  categoryBoards?: ChannelBoardDto[];
  categoryBoard?: ChannelBoardDto | null;
  // getMyChannel: () => void;
  // setMyChannel: (channelInfo: ChannelDto) => void;
  setCategoryBoards: (categoryBoards: ChannelBoardDto[]) => void;
  setCategoryBoard: (categoryBoard: ChannelBoardDto | undefined | null) => void;
  setLinks: (links: LinkDto[]) => void;
  setPosts: (postss: PostDto[]) => void;
  broadcast: (params: ReceiverType) => void;
  getCurrentChannel: (directChannelName?: string) => void;
}

export const useChannelContext = (): propsTypes => {
  const context = useContext(Context);
  if (!context) {
    // throw new Error('useMyContext must be used within a MyProvider');
  }
  return context as any;
};

export { Context, propsTypes };
