import StickerTypeA from 'components/commons/stickers/StickerTypeA';
import StickerTypeB from 'components/commons/stickers/StickerTypeB';
import StickerTypeC from 'components/commons/stickers/StickerTypeC';
// import StickerTypeD from 'components/commons/stickers/StickerTypeD';
import StickerTypeE from 'components/commons/stickers/StickerTypeE';
import StickerTypeF from 'components/commons/stickers/StickerTypeF';

const StickerItems = {
  TypeA: { component: StickerTypeA },
  TypeB: { component: StickerTypeB },
  TypeC: { component: StickerTypeC },
  // TypeD: { component: StickerTypeD },
  TypeE: { component: StickerTypeE },
  TypeF: { component: StickerTypeF },
};

export default StickerItems;
