import React, { CSSProperties } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton } from '@mui/material';

const DeleteIcon = ({ style, onClick }: { style?: CSSProperties; onClick: () => void }) => {
  return (
    <IconButton
      style={{ marginLeft: 20, width: 45, height: 45, background: 'rgb(167, 0, 22)', ...style }}
      onClick={onClick}
    >
      <DeleteOutlineIcon style={{ width: 25, height: 25, color: '#fff' }} />
    </IconButton>
  );
};

export default DeleteIcon;
