import React, { useRef, useState, useEffect } from 'react';

import {
  ChannelProfileModel,
  CustomUserProfile,
  FooterProps,
  TextContent,
} from 'types/CommonTypes';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { Avatar as MUIAvatar } from '@mui/material';
import { ItemSectionStyle, ItemSectionTitle } from 'resources/controls/ItemSectionStyle';
import SettingContainer from './SettingContainer';
import SettingItemRow from './SettingItemRow';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';

import SaveButton from 'features/adminFeatures/admin/components/SaveButton';
import styled from 'styled-components';
import ThumbnailUploader from 'components/items/ThumbnailUploader';
import DescriptionEditor from './DescriptionEditor';
import InlineColorPicker from './InlineColorPicker';
import {
  Button,
  FormControl,
  RadioGroup,
  Switch,
  Slider,
  FormControlLabel,
  Radio,
  RadioProps,
} from '@mui/material';
import { t } from 'i18next';
import { isMobile } from 'react-device-detect';

import Footer from 'components/items/Footer';

const GreenRadio = (props: RadioProps, c: any) => {
  return (
    <Radio
      {...props}
      sx={{
        color: '#11c668',
        '&.Mui-checked': {
          color: '#11c668',
        },
        '& .MuiSvgIcon-root': {
          fontSize: 18,
        },
      }}
    />
  );
};

const ProfileFooterComponent = observer(
  ({
    onSave,
  }: {
    onSave: (profileModel?: ChannelProfileModel, type?: 'Profile' | 'Footer') => void;
  }) => {
    const { uiStores } = useStore();
    const { profileStore } = uiStores;

    const [profileModel, setProfileModel] = useState<ChannelProfileModel>();
    const [footerProps, setFooterProps] = useState<FooterProps>();
    const [imagePosition, setImagePosition] = useState<'top' | 'bottom' | 'left' | 'right'>('left');

    useEffect(() => {
      if (typeof profileStore.profile !== 'undefined') {
        setProfileModel(profileStore.profile);
        profileStore.setProfile(profileStore.profile);

        setFooterProps(profileStore.profileMetadataObject?.footerProps);
      }
    }, [profileStore.profile]);

    const handleChangeSlider = (e: never, value: any) => {
      setProfileModel((prev: CustomUserProfile) => {
        return {
          ...prev,
          borderInfo: { ...prev.borderInfo, borderWidth: value },
        };
      });
    };

    useEffect(() => {
      if (typeof profileModel !== 'undefined' && profileModel.metadataObject?.footerProps) {
        profileStore.updateProfileMetadata('footer', profileModel.metadataObject?.footerProps);
      }
    }, [profileModel]);

    return (
      <>
        <ItemSectionStyle>
          <SettingContainer title="Footer Setting" height={100}>
            <>
              <SettingItemRow title={t('A177')}>
                <ToggleButtonGroup
                  color="primary"
                  value={imagePosition}
                  exclusive
                  onChange={(e: any, value: any) => {
                    if (value === null) {
                      return;
                    }
                    setImagePosition(value);
                    setProfileModel((prev: ChannelProfileModel) => {
                      return {
                        ...prev,
                        metadataObject: {
                          ...prev?.metadataObject,
                          footerProps: {
                            ...prev?.metadataObject?.footerProps,
                            position: value,
                          },
                        },
                      };
                    });
                  }}
                  aria-label="Platform"
                >
                  <ToggleButton value="top">{t('Top')}</ToggleButton>
                  <ToggleButton value="bottom">{t('Bottom')}</ToggleButton>
                  <ToggleButton value="left">{t('Left')}</ToggleButton>
                  <ToggleButton value="right">{t('Right')}</ToggleButton>
                </ToggleButtonGroup>
              </SettingItemRow>
              {/* <SettingItemRow title="이미지 라운드" style={{ marginTop: 20 }}>
                <Slider
                  sx={{ marginLeft: 1, width: 210 }}
                  value={
                    typeof profileModel?.borderInfo?.borderRadius !== 'undefined'
                      ? profileModel?.borderInfo?.borderRadius
                      : 50
                  }
                  min={1}
                  max={50}
                  onChange={handleChangeBorder}
                  aria-label="Default"
                  valueLabelDisplay="auto"
                />
              </SettingItemRow> */}
              {/* <SettingItemRow title="아이콘크기" style={{ margin: '10px 0' }}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
                  <RadioGroup
                    sx={{ '&.MuiFormGroup-root': { justifyContent: 'flex-end' } }}
                    aria-labelledby="demo-radio-buttons-group-label"
                    row
                    value={socialModel?.size || 'medium'}
                    name="radio-buttons-group"
                    onChange={(e: any, value: any) => {}}
                  >
                    <FormControlLabel value="small" control={<GreenRadio />} label="Small" />
                    <FormControlLabel value="medium" control={<GreenRadio />} label="Medium" />
                    <FormControlLabel value="large" control={<GreenRadio />} label="Large" />
                  </RadioGroup>
                </FormControl>
              </SettingItemRow> */}

              {/* <Button
                variant="contained"
                sx={{ borderRadius: 20, marginTop: 2, boxShadow: 'none' }}
              >
                설정저장
              </Button> */}
            </>
          </SettingContainer>
        </ItemSectionStyle>
        <ItemSectionStyle style={{ display: 'none' }}>
          <SettingContainer title="Footer Setting" height={isMobile ? 260 : 260}>
            <ProfileHeaderStyle style={{ border: 'none', paddingLeft: 10 }}>
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    // checked={profileModel?.borderInfo?.useBorder}
                    onChange={(event: never, value: boolean) => {
                      setProfileModel((prev: CustomUserProfile) => {
                        return {
                          ...prev,
                          borderInfo: { ...prev.borderInfo, useBorder: value },
                        };
                      });
                    }}
                  />
                }
                label={'경계'}
              />
              <div style={{ marginTop: 15 }}>
                <SettingItemRow title={t('A178')}>
                  <InlineColorPicker
                    // disabled={!profileModel?.borderInfo?.useBorder}
                    // defaultColor={profileModel?.borderInfo?.borderColor}
                    onChangeColor={(color: string) => {
                      setProfileModel((prev: CustomUserProfile) => {
                        return {
                          ...prev,
                          borderInfo: { ...prev.borderInfo, borderColor: color },
                        };
                      });
                    }}
                  />
                </SettingItemRow>
                <SettingItemRow title={t('A179')} style={{ margin: '10px 0', paddingRight: 20 }}>
                  <Slider
                    // disabled={!profileModel?.borderInfo?.useBorder}
                    sx={{ marginLeft: 1, width: 210 }}
                    // value={
                    //   typeof profileModel?.borderInfo?.borderWidth !== 'undefined'
                    //     ? profileModel?.borderInfo?.borderWidth
                    //     : 2
                    // }
                    min={1}
                    max={10}
                    onChange={handleChangeSlider}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                  />
                </SettingItemRow>
                <SettingItemRow title="프로필경계" style={{ margin: '20px 0', paddingRight: 20 }}>
                  <Slider
                    sx={{ marginLeft: 1, width: 210 }}
                    // value={
                    //   typeof profileModel?.borderInfo?.borderRadius !== 'undefined'
                    //     ? profileModel?.borderInfo?.borderRadius
                    //     : 50
                    // }
                    min={1}
                    max={50}
                    // onChange={handleChangeBorder}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                  />
                </SettingItemRow>
              </div>
            </ProfileHeaderStyle>
          </SettingContainer>
        </ItemSectionStyle>
        <ProfileContainerStyle style={{ marginTop: 20 }}>
          <ItemSectionStyle style={{ padding: 20 }}>
            <div style={{ margin: 'auto', position: 'relative' }}>
              <MUIAvatar
                src={profileModel?.footerImage?.publicUrl}
                sx={{ width: 120, height: 120, margin: 'auto' }}
              />
            </div>
            <ThumbnailUploader
              usePreview={false}
              style={{ marginTop: 20, justifyContent: 'center' }}
              url={profileModel?.footerImage?.publicUrl}
              onImageChange={(image: any) => {
                setProfileModel((prev: ChannelProfileModel) => {
                  return {
                    ...prev,
                    footerImage: image,
                  };
                });
              }}
            />
          </ItemSectionStyle>
        </ProfileContainerStyle>

        {/* 타이틀 */}
        <ItemSectionStyle>
          <ProfileContainerStyle style={{ marginTop: 20 }}>
            <DescriptionEditor
              label={t('A176')}
              description={footerProps?.titleProps?.text}
              descriptionProps={footerProps?.titleProps}
              onChangeDescription={(value: string) => {
                setProfileModel((prev: ChannelProfileModel) => {
                  return {
                    ...prev,
                    metadataObject: {
                      ...prev.metadataObject,
                      footerProps: {
                        ...prev.metadataObject?.footerProps,
                        titleProps: {
                          ...prev.metadataObject?.footerProps?.titleProps,
                          text: value,
                        },
                      },
                    },
                  };
                });
              }}
              onChangeProps={(content: TextContent) => {
                setProfileModel((prev: ChannelProfileModel) => {
                  return {
                    ...prev,
                    metadataObject: {
                      ...prev?.metadataObject,
                      footerProps: {
                        ...prev?.metadataObject?.footerProps,
                        titleProps: {
                          ...prev?.metadataObject?.footerProps?.titleProps,
                          style: content?.style,
                        },
                      },
                    },
                  };
                });
              }}
            />
          </ProfileContainerStyle>
        </ItemSectionStyle>
        <ItemSectionStyle style={{ padding: 20 }}>
          <div style={{ marginBottom: 20 }}>{t('Preview')}</div>

          <Footer model={profileModel} />
        </ItemSectionStyle>
        <SaveButton
          onClick={() => {
            onSave(profileModel, 'Footer');
          }}
        />
      </>
    );
  },
);

const ProfileContainerStyle = styled.div``;
const ProfileHeaderStyle = styled.div`
  text-align: left;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 10px;

  > div {
    font-size: 14px;
    color: #ddd;
  }
`;

export default ProfileFooterComponent;
