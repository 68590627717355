import React, { useState } from "react";
import { ItemContent, ItemSectionStyle, ItemSectionTitle } from "resources/controls/ItemSectionStyle";
import MUIToggleGroup from "components/commons/buttons/MUIToggleGroup";
import MUIToggle from "components/commons/buttons/MUIToggle";
import useSetDefaultValue from "features/management/hooks/useDefaultValue";

type CommonProps = {
    initialValue?: string | number;
    onChange?: (value: string) => void;
};
const CommonFontSize = ({ initialValue, onChange }: CommonProps) => {

    const [value, setValue] = useState<string | number>();

    useSetDefaultValue(setValue, initialValue, '14px');

    return (
        <>
            <ItemSectionStyle>
                <ItemSectionTitle>폰트 크기</ItemSectionTitle>
                <ItemContent>
                    <MUIToggleGroup value={value}
                        onChange={(e: any, value: string) => {
                            setValue(value)
                            if (onChange) {
                                onChange(value);
                            }
                        }}>
                        <MUIToggle value='12px'><span style={{ fontSize: 12 }}>a가</span></MUIToggle>
                        <MUIToggle value='14px'><span style={{ fontSize: 14 }}>a가</span></MUIToggle>
                        <MUIToggle value='16px'><span style={{ fontSize: 16 }}>a가</span></MUIToggle>
                    </MUIToggleGroup>
                </ItemContent>
            </ItemSectionStyle>
        </>
    );
};

export default CommonFontSize;