const BrandManualData = [
  {
    type: 'PRO',
    titleData: [
      '한 곳에서 간편하게 관리할 수 \n있는 CS TOWN',
      '공구를 꼼꼼히 준비하는\n브랜드를 위한 체크리스트',
      '눈에 확인할 수 있는\n공구현황 대시보드',
    ],
    subtitle: ['매니저 5명까지 운영가능'],
    description:
      '인플루언서 비즈니스를 시작하시는 분이시라면, 궁금증과 기대감을 갖고 방문하는 인친들이 더 만족하여 단골이 될 수 있도록 특별한 경험을 선사해주셔야 합니다.',
    boxData: {
      text1: '브랜드선호도',
      text2: 'Brand’s Choice',
      text3: '합리적인',
      text4: '가격!',
    },
    priceData: {
      price: '39,000원',
      period: '/월',
      discount: '6개월(10%) / 12개월(20%)',
    },
    imgData: [
      '/images/management/membership/membership_brand_pro_1.svg',
      '/images/management/membership/membership_seller_pro_2.svg',
      '/images/management/membership/membership_seller_pro_3.svg',
    ],
  },
  {
    type: 'PREMIUM',
    titleData: ['한 곳에서 간편하게 관리할 수\n있는 CS TOWN'],
    subtitle: ['매니저 5명까지 운영가능'],
    description:
      '인플루언서 비즈니스를 시작하시는 분이시라면, 궁금증과 기대감을 갖고 방문하는 인친들이 더 만족하여 단골이 될 수 있도록 특별한 경험을 선사해주셔야 합니다.',
    boxData: {
      text1: 'CS 선호도',
      text2: '매니저 20명 up!',
      text3: '대형\n에이전시',
    },
    priceData: {
      price: '99,000원',
      period: '/월',
      discount: '6개월(10%) / 12개월(20%)',
    },
    imgData: ['/images/management/membership/membership_brand_pro_1.svg'],
  },
];

export default BrandManualData;
