import { Avatar, Badge, IconButton } from '@mui/material';
import { Row } from 'components/commons/layouts';
import { BoxStyle, ListItemTemplate } from 'features/csTown/commonComponents/styles';
import { useRootContext } from 'libs/hooks/useRootContext';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CommonThumbnail from 'features/management/components/commonControl/CommonThumbnail';
import { cutoffString } from 'libs/helper/utils';

/**
 * @description Inquiry목록(채널 채팅)
 * @param param0
 * @returns
 */
const InquiryListItem = ({
  inquiry,
  thumbnailType = 'DEAL',
  role = 'OWNER',
  selected,
  cutoffLength,
  onClick,
  onContextClick,
}: {
  inquiry?: any;
  selected?: boolean;
  thumbnailType?: 'DEAL' | 'USER';
  role?: 'CUSTOMER' | 'OWNER';

  cutoffLength?: number;
  onClick: () => void;
  onContextClick: (e: any) => void;
}) => {
  const { myChannel } = useRootContext();
  const getName = () => {
    return inquiry?.lastMessage?.sender?.nickname !== myChannel?.channelName
      ? inquiry?.data
      : inquiry?.lastMessage?.sender?.nickname;
  };
  return (
    <BoxStyle selected={selected}>
      <div>
        <Row style={{ justifyContent: 'flex-start' }}>
          <AvatarContainerStyle onClick={onClick}>
            {thumbnailType === 'DEAL' ? (
              <CommonThumbnail initialValue={inquiry?.coverUrl} useAlternative />
            ) : (
              <Avatar src={inquiry?.lastMessage?.sender?.plainProfileUrl} />
            )}
          </AvatarContainerStyle>
          <div style={{ marginLeft: 10, flexGrow: 1 }} onClick={onClick}>
            <div style={{ fontWeight: 600 }}>{getName()}</div>
            <MessageStyle>{cutoffString(inquiry?.lastMessage?.message, cutoffLength)}</MessageStyle>
          </div>
          <div style={{ textAlign: 'center' }}>
            <div>
              {inquiry?.unreadMessageCount > 0 && (
                <Badge
                  badgeContent={inquiry?.unreadMessageCount}
                  style={{ background: 'red' }}
                  sx={{
                    '& .MuiBadge-badge': {
                      background: '#173300',
                      color: '#96e06e',
                      fontWeight: 600,
                    },
                  }}
                />
              )}
            </div>
            <div style={{ fontSize: 12, color: '#777' }}>
              {moment(inquiry.lastMessage.createdAt).fromNow()}
            </div>
          </div>
          <IconButton onClick={onContextClick}>
            <MoreVertIcon />
          </IconButton>
        </Row>
      </div>
    </BoxStyle>
  );
};

const AvatarContainerStyle = styled.div`
  display: flex;
  > div {
  }
  > div:nth-child(2) {
    /* margin-left: -30px; */
  }
`;

const MessageStyle = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    font-size: 12px;
  }
`;
export default InquiryListItem;
