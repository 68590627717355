import React from 'react';

import CommonMyMenus from 'features/mossMainSettings/components/CommonMyMenus';

const MySettingsPage = () => {
  return (
    <>
      {/* <DetailPageHeader title={'계정설정'} /> */}
      <div style={{ padding: '0 20px' }}>
        <CommonMyMenus />
      </div>
    </>
  );
};

export default MySettingsPage;
