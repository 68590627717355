import { useStore } from 'stores/StoreHelper';
import {
  DealListDto,
  LinkDto,
  MemberDto,
  PartnerDto,
  PartnerInvitationCreationDto,
  SimpleDealDto,
} from 'services/data-contracts';

import PartnerInvitationStore from 'stores/PartnerInvitationStore';
import PartnerStore from 'stores/PartnerStore';
import MyDealStore from 'stores/MyDealStore';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';
import { useRootContext } from 'libs/hooks/useRootContext';
import chatController from 'features/community/controllers/chatController';

/**
 * @description Seller가 Brand컨트롤을 위해 사용하는 컨트롤러
 * @returns
 */
const PartnerController = () => {
  const { xhrStore } = useStore();
  const { myChannel } = useRootContext();
  const { inviteOrDeleteUser } = chatController();

  const partnerInvitationStore = new PartnerInvitationStore();
  const partnerStore = new PartnerStore();
  const myDealStore = new MyDealStore();

  /**
   * @description 현재 초대중인 목록을 취득한다.
   * @returns
   */
  const getPartnerInvitations = async () => {
    const invitations = await partnerInvitationStore.getPartnerInvitations(myChannel?.id as number);
    return invitations;
  };

  /**
   * @description 파트너 초대장을 생성한다.
   */
  const createPartnerInvitation = async (data: PartnerInvitationCreationDto) => {
    const invitation = await partnerInvitationStore.createPartnerInvitation(
      myChannel?.id as number,
      data,
    );
    const newDeals = data?.deals?.map((deal) => {
      return { ...deal, assigned: true };
    });
    inviteOrDeleteUser(newDeals, [data?.channelName as string]);
    return invitation;
  };

  /**
   * @description 초대장 정보를 가저온다.
   * @param invitationKey
   * @param partnerChannelId
   * @returns
   */
  const getInvitationInfo = async (invitationKey: string, partnerChannelId: number) => {
    const invitation = await partnerInvitationStore.getInvitation(invitationKey, {
      partnerChannelId,
    });
    return invitation;
  };

  /**
   * @description 초대를 취소한다.
   * @param invitationId
   * @returns
   */
  const cancelInvitation = async (invitationId: number) => {
    const invitation = await partnerInvitationStore.cancelInvitation(
      myChannel?.id as number,
      invitationId,
    );
    return invitation;
  };

  /**
   * @description 특정공구에 위임받은 브랜드목록을 취득한다.
   * @param dealId
   * @returns
   */
  const getPartners = async (dealId?: number) => {
    const partners = (await partnerStore.getPartners1(
      myChannel?.id as number,
      dealId,
    )) as unknown as PartnerDto[];
    return partners;
  };

  /**
   * @description 특정 브랜드가 위임받은 공구 목록을 취득한다.
   * @param partnerId
   * @returns
   */
  const getPartnerDeals1 = async (partnerId: number) => {
    const deals = (await partnerStore.getPartnerDeals1(
      myChannel?.id as number,
      partnerId,
    )) as unknown as SimpleDealDto[];
    return deals;
  };

  /**
   * @description 브랜드 정보를 수정한다.
   * @param partnerId
   * @param data
   * @returns
   */
  const updatePartner = async (partnerId: number, data: PartnerDto) => {
    const partner = await partnerStore.updatePartner(myChannel?.id as number, partnerId, data);
    return partner;
  };

  /**
   * @description 브랜드를 삭제하고 참여한 모든 채팅으로부터 제거한다.(멤버 포함)
   * @param partner
   * @returns
   */
  const deletePartner = async (partner: PartnerDto) => {
    const deals = (await partnerStore.getPartnerDeals1(
      myChannel?.id as number,
      partner?.id as number,
    )) as unknown as LinkDto[];

    const newDeals = deals.map((deal: LinkDtoWithAssignedDeal) => {
      return { ...deal, assigned: true };
    });

    const members = (await getMemberIds(partner)) as string[];

    const result = (await partnerStore.deletePartner(
      myChannel?.id as number,
      partner?.id as number,
    )) as unknown as string;

    if (result === '') {
      inviteOrDeleteUser(newDeals, members);
    }
    return partner;
  };

  const assignDeals = async (partner: PartnerDto, data: DealListDto) => {
    const results = await partnerStore.assignDeals(
      myChannel?.id as number,
      partner?.id as number,
      data,
    );
    if (data.deals && data.deals?.length > 0) {
      // 모든 채팅에 참여시키기
      inviteOrDeleteUser(data.deals, [partner?.partnerChannel?.channelName as string]);
    }
    return results;
  };

  const assignDeal = async (partner: PartnerDto, deal: LinkDtoWithAssignedDeal) => {
    const deals = await partnerStore.assignDeal(
      myChannel?.id as number,
      partner?.id as number,
      deal?.id as number,
    );

    // 특정 채팅에 참여시키기
    inviteOrDeleteUser(
      [{ ...deal, assigned: true }],
      [partner?.partnerChannel?.channelName as string],
    );

    return deals;
  };

  /**
   * @description 개별 공구로부터 제거한다.
   * @param channelId
   * @param partner
   * @param deal
   * @returns
   */
  const unsignDeal = async (
    channelId: number,
    partner: PartnerDto,
    deal: LinkDtoWithAssignedDeal,
  ) => {
    const result = (await partnerStore.unsignDeal(
      channelId,
      partner?.id as number,
      deal?.id as number,
    )) as unknown as string;

    if (result === '') {
      // 특정 채팅에서 삭제
      const t = (await partnerStore.getPartnerMembers(
        partner.channel?.id as number,
      )) as unknown as MemberDto[];

      const ids = t.map((item) => {
        return item.memberUser?.username;
      });
      ids.push(partner?.partnerChannel?.channelName as string);

      inviteOrDeleteUser([deal], ids as string[]);
      return '';
    }
  };

  const getPartnersDelegatedDeals = async (partnerId: number) => {
    const allDeals = (await myDealStore.getMyDeals(
      myChannel?.id as number,
    )) as unknown as LinkDto[];

    if (partnerId) {
      const assignedDeals = (await partnerStore.getPartnerDeals1(
        myChannel?.id as number,
        partnerId,
      )) as unknown as SimpleDealDto[];

      const mergedArray = allDeals?.map((itemA) => {
        const itemInB = assignedDeals?.find((itemB) => itemB.id === itemA.id);
        if (itemInB) {
          return { ...itemA, assigned: true };
        }
        return itemA;
      }) as LinkDtoWithAssignedDeal[];
      return mergedArray;
    } else {
      return allDeals;
    }
  };

  const getMemberIds = async (partner: PartnerDto) => {
    const t = (await partnerStore.getPartnerMembers(
      partner.channel?.id as number,
    )) as unknown as MemberDto[];

    const ids = t.map((item) => {
      return item.memberUser?.username;
    });
    ids.push(partner?.partnerChannel?.channelName as string);
    return ids;
  };

  const getPartnerMembers = async () => {
    const partners = (await partnerStore.getPartnerMembers(
      myChannel?.id as number,
    )) as unknown as MemberDto[];

    return partners;
  };

  const getMemberAssignedDeals = async (memberId: number) => {
    const deals = await partnerStore.getDealsByMember(myChannel?.id as number, memberId);
    return deals;
  };

  return {
    getPartnerInvitations,
    createPartnerInvitation,
    getInvitationInfo,
    cancelInvitation,
    getPartners,
    getPartnerDeals1,
    updatePartner,
    deletePartner,
    assignDeals,
    assignDeal,
    getPartnersDelegatedDeals,
    getPartnerMembers,
    getMemberAssignedDeals,
    unsignDeal,
  };
};

export default PartnerController;
