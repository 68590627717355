import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import BackgroundImageComponent from 'features/commonFeatures/components/BackgroundImageComponent';
import purify from 'dompurify';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import styled from 'styled-components';
import _ from 'lodash';
import { PageLinkModel } from 'types/CommonTypes';

const PageLinkPage = observer(() => {
  const { userName, linkId } = useParams();
  const { navigate } = useNavigation();
  const [pageItem, setPageItem] = useState<PageLinkModel>();
  const init = async () => {
    if (!_.isUndefined(userName) && !_.isUndefined(linkId)) {
      // await userStore.getUserInfo(userName);
      // const page = await userStore.getLink(userName, Number(linkId));
      // if (typeof page !== 'undefined') {
      //   setPageItem(page as PageLinkModel);
      // }
    }
    const t = location;
    document.querySelector('#gnb_container')?.setAttribute('style', 'display:none');
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <></>
    // <div>
    //   {/* <GnbUserProfile userName={userName!} /> */}
    //   {pageItem?.metadataObject?.isFullScreen === false && (
    //     <BackgroundImageComponent profile={userStore.userInfo?.profile} useEmpty={false} />
    //   )}
    //   {!_.isUndefined(userStore.link) && (
    //     <ContainerStyle
    //       isFullScreen={pageItem?.metadataObject?.isFullScreen}
    //       className={pageItem?.metadataObject?.isFullScreen === true ? '' : 'global-width'}
    //     >
    //       {/* <PageTitleStyle>{pageItem?.title}</PageTitleStyle>
    //       {typeof pageItem?.page?.content !== 'undefined' && (
    //         <div
    //           style={{ paddingBottom: 20 }}
    //           dangerouslySetInnerHTML={{
    //             __html: `${purify.sanitize(pageItem?.page?.content)}`,
    //           }}
    //         />
    //       )} */}
    //       <div className="row" style={{ marginBottom: 0 }}>
    //         <BackButtonStyle
    //           onClick={() => {
    //             navigate(`/${userName}`);
    //           }}
    //         >
    //           <KeyboardBackspaceIcon />
    //         </BackButtonStyle>
    //         <PageTitleStyle>{pageItem?.title}</PageTitleStyle>
    //       </div>
    //       {typeof pageItem?.page?.content !== 'undefined' && (
    //         <div
    //           style={{ paddingBottom: 20 }}
    //           dangerouslySetInnerHTML={{
    //             __html: `${pageItem?.page?.content}`,
    //           }}
    //         />
    //       )}
    //       {/* <PageButton onClick={handleClickNavigatePrev}>@{userName}</PageButton> */}
    //     </ContainerStyle>
    //   )}
    // </div>
  );
});

const PageTitleStyle = styled.div`
  text-align: center;
  padding: 20px 30px;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 24px;
  }
  @media ${(props) => props.theme.media.desk} {
    font-size: 32px;
  }
`;

const ContainerStyle = styled.div<{ isFullScreen?: boolean }>`
  position: relative;
  background: #fff;
  border-radius: ${(props) => (props.isFullScreen ? 0 : '10px')};
  @media ${(props) => props.theme.media.mobile} {
    margin: ${(props) => (props.isFullScreen ? 0 : '20px')};
  }
`;

const BackButtonStyle = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  transition: all 0.2s ease-in-out;
  &:hover {
    transition: all 0.2s ease-in-out;

    background: #eee;
  }
`;

export default PageLinkPage;
