/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import { PostModel } from 'types/CommonTypes';

import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import BoardListItem from './boardItems/BoardListItem';
import PostListItem from './boardItems/PostListItem';

// todo : 수정처리 하기
const BoardItemSelector = observer(({ item }: { item: PostModel }) => {
  const { uiStores } = useStore();
  const { currentBoard } = uiStores.userHomeStore;
  const getBoardItem = () => {
    if (currentBoard?.boardType === 'BOARD') {
      switch (currentBoard.viewType) {
        case 'GRID':
          return <BoardListItem item={item} />;
        default:
          return <PostListItem item={item} />;
      }
    } else if (currentBoard?.boardType === 'CALENDAR') {
      return <BoardListItem item={item} />;
    } else {
      return <div />;
    }
  };

  return <>{getBoardItem()}</>;
});

export default BoardItemSelector;
