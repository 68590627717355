import React, {
  CSSProperties,
  ReactNode,
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
} from 'react';
import styled, { css } from 'styled-components';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Row } from 'components/commons/layouts';
import { IconButton } from '@mui/material';
import { useStore } from 'stores/StoreHelper';
import SearchIcon from '@mui/icons-material/Search';
import ChannelSearchBox from 'components/commons/ChannelSearchBox';
import useToken from 'libs/hooks/useToken';
import { observer } from 'mobx-react';
import { t } from 'i18next';
import useNavigation from 'hooks/useNavigation';

const MainGnbComponent = observer(
  forwardRef(
    (
      {
        position,
        defaultComponent,
        useCommonButtons,
        style,
        children,
      }: {
        position?: 'top' | 'bottom';
        defaultComponent: ReactNode;
        useCommonButtons?: boolean;
        style?: CSSProperties;
        children?: React.ReactElement;
      },
      ref,
    ) => {
      const { uiStore, myChannelStore, myNotificationStore } = useStore();
      const { validUser } = useToken();
      const [bottom, setBottom] = useState<number>(0);
      const [showMenu, setShowMenu] = useState<boolean>(false);
      const { navigate } = useNavigation();

      const handleClickOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setShowMenu((prev) => !prev);
      };

      useImperativeHandle(ref, () => ({
        close,
      }));

      const close = () => {
        setShowMenu(false);
      };

      const handleClickSearch = () => {
        uiStore.universalModal.show({
          title: t('A235'),
          description: t('A236'),
          useDefaultButtons: true,
          modalOption: {
            style: { maxWidth: 500, maxHeight: 800 },
          },
          bottomSheetOptions: {},
          children: (
            <>
              <ChannelSearchBox />
            </>
          ),
        });
      };

      return (
        <>
          <UserGNBStyle
            isScroll
            location={position}
            bottom={bottom}
            className="gnb-component-container"
          >
            <DefaultComponentStyle className="gnb-default-component">
              {defaultComponent}
              <div className="common-row">
                {useCommonButtons !== false && (
                  <Row style={{ justifyContent: 'flex-end', width: 'unset' }}>
                    <IconButtonStyle
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        handleClickSearch();
                      }}
                    >
                      <SearchIcon style={{}} />
                    </IconButtonStyle>
                    <IconButtonStyle
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        navigate('/deals/settings');
                      }}
                    >
                      <SettingsOutlinedIcon style={{}} />
                    </IconButtonStyle>
                  </Row>
                )}
              </div>
            </DefaultComponentStyle>
          </UserGNBStyle>
        </>
      );
    },
  ),
);

const IconButtonStyle = styled(IconButton)`
  margin-right: 20px !important;

  @media ${(props) => props.theme.media.mobile} {
    margin-right: 5px !important;
  }
`;

const UserGNBStyle = styled.div<{
  isScroll: boolean;
  location?: 'top' | 'bottom';
  height?: number;
  bottom?: number;
}>`
  top: 0;
  /** 낮추지 말것 */
  z-index: 3;
  position: fixed;
  background: rgba(255, 255, 255, 1);
  max-width: 550px;
  width: 100%;
  height: 60px;
  margin: auto;
  /* max-width: 800px; */
  /* border-radius: 30px; */
  transition: all 0.3s ease-in-out;

  @media ${(props) => props.theme.media.mobile} {
    top: 0px;
    border-radius: 0px;
    left: 0px;
    right: 0px;

    backdrop-filter: blur(10px);
  }
`;

const DefaultComponentStyle = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  z-index: 1;
  @media ${(props) => props.theme.media.mobile} {
    background: #fff;
  }
`;

export default MainGnbComponent;
