import React, { useEffect } from 'react';
import { useStore } from 'stores/StoreHelper';
import useNavigation from 'hooks/useNavigation';
import boardCommonLogics from 'features/board/logic/boardCommonLogic';

import { useGlobalContext } from 'context/useGlobalContext';
import { LinkDto, PartnerDto, SimpleDealDto } from 'services/data-contracts';
import BlockController from 'controllers/BlockController';
import styled from 'styled-components';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { ContextContainer, ContextItem } from 'resources/styles/common/ContextItem';
import LinkController from 'controllers/LinkController';
import { useRootContext } from 'libs/hooks/useRootContext';
import moment from 'moment';
import { useStateContext } from 'libs/hooks/usePageState';
import CSPartnerList from 'features/csTown/commonComponents/CSPartnerList';

/**
 * @description 모든 링크를 생성하기 위한 컨테이너임
 */
const AdItemMoreButtons = ({ link }: { link: SimpleDealDto }) => {
  const { showShareBox } = boardCommonLogics();
  const { uiStore } = useStore();
  const { broadcast } = useStateContext();
  const { deleteBlock, getBlockTypeComponent, updateBlock } = BlockController<LinkDto>();
  const { getLinkByKey } = LinkController();
  const { report } = useGlobalContext();
  const { myChannel } = useRootContext();

  const getLocation = () => {
    return `${window.location.protocol}//${window.location.host}`;
  };

  const handleClickModify = async () => {
    uiStore.bottomSheet.close();
    const Component = getBlockTypeComponent(link);
    const linkDetail = (await getLinkByKey(link?.linkKey as string)) as unknown as LinkDto;

    uiStore.modal.show({
      title: link.title,
      style: { height: '100vh', borderRadius: 0 },
      children: (
        <ComponentStyle>
          {/* <Component.Component blockModel={Component.newItem} /> */}
          <Component.Component blockModel={linkDetail} onUpdate={() => {}} />
        </ComponentStyle>
      ),
    });
  };

  const handleClickDelete = () => {
    uiStore.universalModal.close();
    uiStore.bottomSheet.close();
    uiStore.confirm.show({
      title: '게시물 삭제',
      message: '해당 게시물을 삭제하시겠습니까?',
      onConfirmed: async () => {
        deleteBlock(link as LinkDto);
      },
    });
  };

  const handleClickShare = () => {
    showShareBox(
      link?.title || '공유',
      `${getLocation()}/${link?.sellerChannel?.channelName}/deals/${link?.linkKey}`,
    );
  };

  const handleClickShow = () => {
    window.open(`/deals/${link?.linkKey}`, '_blank');
  };
  const handleClickReport = () => {
    report(link?.id as number);
  };

  const handleClickCsPartner = () => {
    uiStore.modal.show({
      children: <CSPartnerList onSelect={(partner: PartnerDto) => {}} />,
    });
  };

  const handleCancelDeal = async () => {
    uiStore.confirm.show({
      message: '진행중인 공구를 취소하시겠습니까?',
      onConfirmed: async () => {
        const linkDetail = (await getLinkByKey(link?.linkKey!)) as unknown as LinkDto;

        const now = moment().utc();
        const formattedTime = now.format('YYYY-MM-DDTHH:mm:00[Z]');
        await updateBlock({ ...linkDetail, endDate: formattedTime } as LinkDto);
        broadcast({ id: 'reload' });
        uiStore.bottomSheet.close();
      },
    });
  };

  return (
    <ContextContainer>
      <ContextItem onClick={handleClickShow}>공구보기</ContextItem>
      <ContextItem onClick={handleClickShare}>공유</ContextItem>
      {link?.sellerChannel?.id !== myChannel?.id ? (
        <ContextItem onClick={handleClickReport}>신고</ContextItem>
      ) : (
        <>
          <ContextItem
            onClick={() => {
              handleClickModify();
            }}
          >
            공구 수정
          </ContextItem>
          {/* <ContextItem
            onClick={() => {
              navigate(`/my/deals/${link?.linkKey}/dashboard`);
            }}
          >
            대시보드 보기
          </ContextItem>
          <ContextItem onClick={handleClickChecklist}>체크 리스트</ContextItem> */}
          <ContextItem
            onClick={() => {
              handleClickCsPartner();
            }}
          >
            CS Partners
          </ContextItem>
          {link.status === 'ONGOING' && (
            <ContextItem onClick={handleCancelDeal}>공구 종료하기</ContextItem>
          )}
          <ContextItem
            itemType="danger"
            onClick={() => {
              handleClickDelete();
            }}
          >
            공구 삭제
          </ContextItem>
        </>
      )}
      {/* <ContextItem type="separator" /> */}
      <OhmossButton
        onClick={() => {
          uiStore.universalModal.close();
          uiStore.bottomSheet.close();
        }}
      >
        닫기
      </OhmossButton>
    </ContextContainer>
  );
};

const ComponentStyle = styled.div`
  padding: 20px;
`;

export default AdItemMoreButtons;
