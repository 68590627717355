import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button, Avatar } from '@mui/material';
import { Row } from 'components/commons/layouts';

import useKakaoLogin from 'libs/hooks/useKakaoLogin';
import SignUpForm from 'features/mainFeatures/auth/components/CommonSignUpForm';

/**
 * @deprecated
 */
const KakaoSignupPage = observer(() => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const { checkSignup } = useKakaoLogin(code);

  const [validToken, setValidToken] = useState<boolean>(true);

  useEffect(() => {
    const preCheck = async () => {
      const result = await checkSignup();
    };
    preCheck();
  }, []);
  return (
    <>
      {validToken && (
        <>
          <Row>{/* <SignUpForm /> */}</Row>
        </>
      )}
    </>
  );
});

export default KakaoSignupPage;
