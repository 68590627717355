import React, { useEffect, useState, useLayoutEffect } from 'react';

import { validationHelper as VH } from 'controls/validationHelper';
import { Avatar } from '@mui/material';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import { SocialSignUpDto, TermDto } from 'services/data-contracts';
import { useSearchParams } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
import useKakaoLogin from 'libs/hooks/useKakaoLogin';
import styled from 'styled-components';
import CommonSignUpForm from './CommonSignUpForm';
import useChannelSelector from 'libs/hooks/useChannelSelector';
import { useGlobalContext } from 'context/useGlobalContext';
import AuthStore from 'stores/AuthStore';
import ChannelTypeSelector from './ChannelTypeSelector';
import LoginPageController from 'pages/pageControllers/LoginPageController';

interface SignUpModel extends SocialSignUpDto {
  termsValid?: boolean;
}

const KakaoSignUpForm = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const { navigate } = useNavigation();
  const { initializeFirstChannel } = useChannelSelector();
  const { userInfo, checkSignup } = useKakaoLogin(code);
  const { redirect, doLogin } = LoginPageController();

  const { uiStore } = useStore();
  const { setLogin, login } = useGlobalContext();
  const { termStore, authStore } = useStore();

  const [model, setModel] = useState<SignUpModel>();
  const [isValid, setIsValid] = useState<boolean>(false);

  const checkValidation = () => {
    const channelName = VH.alphanumericWithSymbols(model?.channelName);

    if (typeof model?.termsValid !== 'undefined') {
      setIsValid(channelName && model.termsValid);
    }
  };

  const handleClickSignUp = async () => {
    setIsValid(false);
    const agreement = termStore?.terms.map((term: TermDto) => ({
      termId: term.id,
      isAgree: true,
    }));
    if (model && isValid) {
      model.socialToken = userInfo.socialToken;
      model.profile = { nickname: userInfo.properties.nickname };
      const result = await authStore.kakaoSignup(model, agreement);
      if (result) {
        await doLogin();
        redirect();
        // await initializeFirstChannel();
        // setLogin(true);
        // await login();
        // const state = searchParams.get('action');
        // if (state) {
        //   //1:return url , 2: params
        //   const t2 = state.replace('(', '').replace(')', '').split('.');
        //   if (t2.length > 1) {
        //     navigate(`${t2[0]}?action=${t2[1]}`);
        //   }
        // } else {
        //   navigate(`/my`);
        // }
      } else {
        // toast(t('A102'), { type: 'warning' });
      }
    }
  };

  useEffect(() => {
    if (model) {
      checkValidation();
    }
  }, [model]);

  useEffect(() => {
    if (typeof userInfo !== 'undefined') {
      if (userInfo === null) {
        uiStore.universalModal.show({
          title: '카카오 토큰이 만료되었습니다.',
          description: '페이지를 새로고침 하거나 오랜시간이 경과하면 발생합니다.',
          modalOption: { style: { maxWidth: 500, height: 400 } },
          useDefaultButtons: true,
          children: (
            <div>
              <br />
              회원가입 페이지에서 다시한번 <br />
              카카오 회원가입 버튼을 클릭 해주세요.
            </div>
          ),
          onClose() {
            navigate('/sign-up');
          },
        });
      } else {
        console.log(userInfo);
      }
    }
  }, [userInfo]);

  useLayoutEffect(() => {
    checkSignup();
    termStore.getTerms();
  }, []);

  return (
    <>
      <div style={{ margin: '30px 0' }}>카카오계정으로 가입</div>
      <div style={{ height: 100 }}>
        {typeof userInfo !== 'undefined' && userInfo !== null && (
          <AvatarStyle>
            <Avatar
              style={{ margin: 'auto', width: 70, height: 70 }}
              src={userInfo.kakao_account.profile?.thumbnail_image_url}
            />
            <div style={{ marginTop: 20 }}>
              {userInfo.kakao_account.profile?.nickname}님 반갑습니다!
            </div>
          </AvatarStyle>
        )}
      </div>
      <ChannelTypeSelector
        onSelectedType={(type) => {
          setModel((prev) => {
            return { ...prev, channelType: type };
          });
        }}
      />
      <div>
        <CommonSignUpForm
          onValidChannelName={(channelName: string) => {
            setModel((prev) => {
              return { ...prev, channelName };
            });
          }}
          onTermsChanged={(termsValid: boolean) => {
            setModel((prev) => {
              return { ...prev, termsValid };
            });
          }}
        />
        <OhmossButton
          disabled={!isValid}
          style={{ minWidth: 300, height: 50 }}
          onClick={handleClickSignUp}
        >
          Sign up
        </OhmossButton>
      </div>
    </>
  );
};

const AvatarStyle = styled.div``;

export default KakaoSignUpForm;
