import { makeObservable, observable, flow, action } from 'mobx';
import { AdService } from 'services/AdService';
import { AdDto, AdReportDto } from 'services/data-contracts';

import _ from 'lodash';
import { HttpClient } from 'services/http-client';
import i18n from 'i18n';
import ServiceStoreBase from './ServiceStoreBase';

export interface isValid {
  isValid: boolean;
}

class AdStore extends ServiceStoreBase {
  t = i18n.t;

  ads: Array<AdDto> = [];

  ad: AdDto;

  constructor() {
    super();
    makeObservable(this, {
      getAds: flow,
      getAd: flow,
      setFavorite: flow,
      reportAd: flow,
      ads: observable,
      ad: observable,
      updateFollowStatus: action,
      setDeals: action
    });
  }

  api: AdService<unknown> = new AdService(new HttpClient());

  *getAds(query?: any) {
    const { response } = yield this.api.getAds1(query);
    if (response.status === 200) {
      this.ads = response.data;

      return response.data;
    } else {
      this.ads = [];
      return [];
    }
  }

  *getAd(adId: number, query?: object) {
    const { response } = yield this.api.getAd1(adId, query);
    if (response.status === 200) {
      this.ad = response.data;
      return response.data;
    } else {
      return null;
    }
  }

  *setFavorite(isFavorite: boolean, channelId: number, adId: number) {
    if (isFavorite) {
      const { response } = yield this.api.addInterest(channelId, adId);
      return super.handleByStatus(response, { successMessage: '내찜광고로 등록되었습니다.' });
    } else {
      const { response } = yield this.api.deleteInterest(channelId, adId);
      return super.handleByStatus(response, { successMessage: '내찜광고를 해제하였습니다.' });
    }
  }

  *reportAd(channelId: number, adId: number, adReport: AdReportDto) {
    const { response } = yield this.api.reportAd(channelId, adId, adReport);
    return super.handleByStatus(response, { successMessage: '감사합니다. 정상적으로 접수되었습니다.' });
  }

  updateFollowStatus(isFollow: boolean | undefined, channelName: string | undefined) {
    if (typeof isFollow !== 'undefined' && typeof channelName !== 'undefined') {
      const old = this.ads.map((ad: AdDto) => {
        return {
          ...ad,
          channel: {
            ...ad.channel,
            isFollow: ad.channel?.channelName === channelName ? isFollow : ad.channel?.isFollow,
          },
        };
      });

      this.ads = old;
    }
  }

  setDeals(deals?: AdDto[]) {
    this.ads = deals || [];
  }

  unMount() {
    this.ads = [];
  }
}

export default AdStore;
