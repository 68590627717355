import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LinkItemDto } from 'services/data-contracts';
import { DealServiceItemModel } from 'types/CommonTypes';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import { DealCustomComponents } from './index';
import { Row } from 'components/commons/layouts';

import MUISwitch from 'components/commons/buttons/MUISwitch';
import DeleteButton from 'resources/controls/DeleteButton';
import ActionBox from 'features/csTown/components/pc/ActionBox';
import AddIcon from 'features/csTown/commonComponents/AddIcon';
import { validationHelper } from 'controls/validationHelper';

const DealCustomerServices = ({
  items,
  initialValue,
  actionType,
}: {
  items?: LinkItemDto[];
  initialValue?: LinkItemDto[];
  actionType: 'Modal' | 'BottomSheet';
}) => {
  const { uiStore } = useStore();
  const [customerServices, setCustomerServices] = useState<DealServiceItemModel[]>(
    items as DealServiceItemModel[],
  );

  const [isValid, setIsValid] = useState<boolean>(false);

  const getCustomServices = (item: DealServiceItemModel) => {
    // const temp = item.serviceType;
    const SelectedService = DealCustomComponents['multi']?.component;
    // const metadata = JSON.parse(item?.metadata || '{}');
    // console.log('metadata :: ', metadata);
    // const newItem: DealServiceItemModel = { ...item };
    // console.log('getCustomServices-newItem :: ', newItem);
    return <SelectedService item={item} onChange={handleChangeValue} />;
  };

  const handleClickDelete = (target: DealServiceItemModel) => {
    const newItems = customerServices.filter(
      (item) => item.seq !== target.seq,
    ) as DealServiceItemModel[];
    setCustomerServices(newItems);
  };

  const handleChangeValue = (value: DealServiceItemModel) => {
    const newItems = customerServices.map((item) => {
      if (item.seq === value.seq) {
        return value;
      }
      return item;
    });
    setCustomerServices(newItems);
    validationCheckArrayObject(newItems);
  };

  const handleClickedAdd = () => {
    if(customerServices.length > 0) {
      const newItems: DealServiceItemModel[] = [
        ...customerServices,
        { seq: Number(customerServices[customerServices.length - 1].seq) + 1, serviceType: 'multi' },
      ];
      validationCheckArrayObject(newItems);
      setCustomerServices(newItems);
    } else {
      const newItems: DealServiceItemModel[] = [
        { seq: 1, serviceType: 'multi' },
      ];
      validationCheckArrayObject(newItems);
      setCustomerServices(newItems);
    }
  };

  // 값을 변경과 새로운 값 추가시 유효검사 함수
  const validationCheckArrayObject = (customerServices: DealServiceItemModel[]) => {
    let validateCheckArray: Array<boolean> = [];
    let validateCheckObjectKey: Array<boolean> = [];
    customerServices.map((customerService) =>{
      for(let [key, value] of Object.entries(customerService)){
        let validateCheck:boolean = false;
        if(key === 'title' || key === 'content'){
          validateCheck = validationHelper.required(value);
          validateCheckArray.push(validateCheck);
        } 
      };
      const found = Object.keys(customerService).find((key) => key === ('title' && 'content'));
      if(found !== ('title' &&  'content')) {
        validateCheckObjectKey.push(false);
      }
    })

    if(validateCheckArray.length > 0){
      const validationFailed = validateCheckArray.find((value) => value === false);
      const validationKeyFailed = validateCheckObjectKey.find((value) => value === false);
      if(!(validationFailed === false) && !(validationKeyFailed === false)){
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    } else {
      setIsValid(false);
    }
  }



  useEffect(() => {
    if (initialValue) {
      setCustomerServices(initialValue as DealServiceItemModel[]);
    }
  }, [initialValue]);

  // cstown - 내 공구목록 - 상세 - 고객서비스 설정 모달 : 유효검사후 확인 버튼 활성화
  useEffect(() =>{
    if(customerServices) {
      validationCheckArrayObject(customerServices);
    }
  },[customerServices])
  return (
    <>
      <SettingsContainerStyle>
        {customerServices?.map((item: DealServiceItemModel) => (
          <Row>
            <div style={{ width: '100%', marginRight: 20 }}>{getCustomServices(item)}</div>
            <div>
              <Row>
                <>
                  {item.serviceType !== 'inquiry' && (
                    <DeleteButton
                      onClick={() => {
                        handleClickDelete(item);
                      }}
                    />
                  )}
                </>
                <MUISwitch
                  checked={item?.isActive}
                  onChange={(e: any, value: boolean) => {
                    handleChangeValue({ ...item, isActive: value });
                  }}
                />
              </Row>
            </div>
          </Row>
        ))}

        <AddIcon onClick={() => handleClickedAdd()} style={{ background: '#ebebeb', margin: 20 }} />

        <ActionBox
          onOk={() => {
            if (actionType === 'BottomSheet') {
              uiStore.bottomSheet.confirm(customerServices);
            } else {
              uiStore.modal.confirm(customerServices);
            }
          }}
          onCancel={() => {
            if (actionType === 'BottomSheet') {
              uiStore.bottomSheet.close();
            } else {
              uiStore.modal.close();
            }
          }}
          isValid={isValid}
        />
      </SettingsContainerStyle>
    </>
  );
};

const SettingsContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default DealCustomerServices;
