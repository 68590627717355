import { useEffect, useState } from 'react';
import {
  DealInquiryDto,
  DealInquiryThreadDto,
  InquirySummaryDto,
  LinkDto,
} from 'services/data-contracts';

import PartnerInvitationStore from 'stores/PartnerInvitationStore';
import PartnershipStore from 'stores/PartnershipStore';
import sellerCSController from 'features/csTown/controllers/sellerCSController';
import BrandCSController from './BrandCSController';
import BrandController from './BrandController';
import ManagerCSController from './ManagerCSController';
import InquiryController from './InquiryController';

/** CS(Inquiry)를 제외한 BL
 * @description
 * @returns
 */
const CSTownController = () => {
  const partnershipStore = new PartnershipStore();
  const partnerinvitationsStore = new PartnerInvitationStore();

  const [deals, setDeals] = useState<LinkDto[]>();
  const [inquiries, setInquiries] = useState<DealInquiryDto[]>();
  const [threads, setThreads] = useState<DealInquiryThreadDto[]>();
  const [summary, setSummary] = useState<InquirySummaryDto>();

  const Brand = {
    CS: BrandCSController(),
    DEAL: BrandController(),
  };

  const Member = {
    CS: ManagerCSController(),
    DEAL: ManagerCSController(),
  };

  const Seller = {
    CS: sellerCSController(),
    DEAL: sellerCSController(),
  };

  const Customer = {
    CS: InquiryController(),
    DEAL: InquiryController(),
  };

  useEffect(() => {
    if (Brand.DEAL.deals) {
      setDeals(Brand.DEAL.deals);
    }

    if (Member.DEAL.deals) {
      setDeals(Member.DEAL.deals);
    }

    if (Seller.DEAL.deals) {
      setDeals(Seller.DEAL.deals);
    }

    if (Customer.DEAL.deals) {
      setDeals(Customer.DEAL.deals);
    }
  }, [Brand.DEAL.deals, Member.DEAL.deals, Seller.DEAL.deals, Customer.DEAL.deals]);

  useEffect(() => {
    if (Brand.CS.inquiries) {
      setInquiries(Brand.CS.inquiries);
    }

    if (Member.CS.inquiries) {
      setInquiries(Member.CS.inquiries);
    }

    if (Seller.CS.inquiries) {
      setInquiries(Seller.CS.inquiries);
    }

    if (Customer.CS.inquiries) {
      setInquiries(Customer.CS.inquiries);
    }
  }, [Brand.CS.inquiries, Member.CS.inquiries, Seller.CS.inquiries, Customer.CS.inquiries]);

  useEffect(() => {
    if (Brand.CS.threads) {
      setThreads(Brand.CS.threads);
    }

    if (Member.CS.threads) {
      setThreads(Member.CS.threads);
    }

    if (Seller.CS.threads) {
      setThreads(Seller.CS.threads);
    }

    if (Customer.CS.threads) {
      setThreads(Customer.CS.threads);
    }
  }, [Brand.CS.threads, Member.CS.threads, Seller.CS.threads, Customer.CS.threads]);

  useEffect(() => {
    if (Brand.CS.summary) {
      setSummary(Brand.CS.summary);
    }

    if (Member.CS.summary) {
      setSummary(Member.CS.summary);
    }

    if (Seller.CS.summary) {
      setSummary(Seller.CS.summary);
    }
  }, [Brand.CS.summary, Member.CS.summary, Seller.CS.summary]);

  /**
  /**
  /**
   * 
   * 아래 전부 제거 예정
  /**
  /**
   * 
   */

  /**
   * @description 파트너 리스트를 가져옴
   * @deprecated
   * @param channelId
   * @returns
   */
  const getPartners = async (channelId: number) => {
    return partnershipStore.getPartners(channelId);
  };

  /**
   * @deprecated 다른곳으로 옮길것
   * @param channelId
   * @returns
   */
  const getInvitations = async (channelId: number) => {
    return partnerinvitationsStore.getPartnerInvitations(channelId);
  };

  /**
   * @deprecated 다른곳으로 옮길것
   * @param channelId
   * @returns
   */
  const getDealsByPartner = async (channelId: number, partnerId: number) => {
    return partnershipStore.getDealsByPartner(channelId, partnerId);
  };

  /**
   * @deprecated 다른곳으로 옮길것
   * @param channelId
   * @returns
   */
  const getDealById = async (channelId: number, dealId: number) => {
    return partnershipStore.getDealById(channelId, dealId);
  };

  /**
   * @deprecated 다른곳으로 옮길것
   * @param channelId
   * @returns
   */
  const updateManagerPassword = async () => {};

  return {
    Brand,
    Seller,
    Member,
    Customer,
    deals,
    inquiries,
    setInquiries,
    setThreads,
    threads,
    summary,
    getPartners,
    getInvitations,
    getDealsByPartner,
    updateManagerPassword,
    getDealById,
  };
};

export default CSTownController;
