import React, { useState } from 'react';
import { Blocks, BlockType } from 'features/management/components/block';
import { useRootContext } from 'libs/hooks/useRootContext';

import styled from 'styled-components';

const BlockSelectorPC = ({ onClick }: { onClick: (block: BlockType) => void }) => {
  const { myChannel } = useRootContext();

  return (
    <LinkContainerStyle className="block-selector">
      <LinkContentStyle>
        {Object.entries(Blocks).map(([key, value]) => (
          <>
            {(value as BlockType).temp !== true && (
              <div
                style={{
                  opacity:
                    (value as BlockType).channelType === 'SELLER' &&
                    (myChannel?.membershipType === 'FREE' || myChannel?.channelType === 'BRAND')
                      ? 0.3
                      : 1,
                }}
              >
                <LinkItemStyle
                  onClick={() => {
                    onClick(value as BlockType);
                  }}
                >
                  <img src={value.icon} alt="" />
                </LinkItemStyle>
                <div
                  style={{
                    fontSize: 10,
                  }}
                >
                  {value.title}
                </div>
              </div>
            )}
          </>
        ))}
      </LinkContentStyle>
    </LinkContainerStyle>
  );
};

const LinkContainerStyle = styled.div`
  min-height: 50px;
  text-align: center;
  @media ${(props) => props.theme.media.desk} {
    left: unset;
  }
`;

const LinkContentStyle = styled.div`
  margin: auto;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
`;

const LinkItemStyle = styled.div`
  display: flex;
  justify-content: center;

  align-items: center;
  border-radius: 100px;
  min-width: 40px;
  height: 40px;
  max-width: 100px;
  border: 1px solid #efefef;
  cursor: pointer;
`;

const TemplateContainer = styled.div`
  padding: 20px;
  width: 100%;
  @media ${(props) => props.theme.media.desk} {
    padding: 20px 40px;
  }
`;
export default BlockSelectorPC;
