import React, { CSSProperties, useEffect, useState } from 'react';
import styled from 'styled-components';
import { PostDto } from 'services/data-contracts';
import { observer } from 'mobx-react';
import NumberFormat from 'react-number-format';
import { Row } from './layouts';

const CardListItemThumbnail3 = observer(
  ({
    item,
    style,
    thumbnailStyle,
  }: {
    item: PostDto;
    style?: CSSProperties;
    thumbnailStyle?: CSSProperties;
  }) => {
    const [currency, setCurrency] = useState<string>('');
    const currencySymbolMapping = {
      KRW: '원',
      USD: '$',
      EUR: '€',
      JPY: '￥',
      CNY: 'Y',
      CAD: 'CAD$',
      GBP: '£',
      SEK: 'kr',
      CHF: 'FR',
      DKK: 'kr',
      CZK: 'Kč',
      PLN: 'zł',
      NZD: 'NZD$',
      AUD: 'AUD$',
      TWD: 'NT$',
      TRY: '₺',
      VND: '₫',
      SGD: 'SGD$',
      PHP: '₱',
      HKD: 'HKD$',
    };

    useEffect(() => {
      if (item?.thumbnailPriceUnit) {
        const selectedCurrencyValue = currencySymbolMapping[item.thumbnailPriceUnit];
        setCurrency(selectedCurrencyValue);
      }
    }, [item?.thumbnailPriceUnit]);

    return (
      <>
        <ThumbnailStyle
          style={{
            ...thumbnailStyle,
            borderBottomRightRadius: style?.borderRadius,
            borderBottomLeftRadius: style?.borderRadius,
          }}
        >
          <Row
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 10,
            }}
          >
            <div
              style={{
                fontSize: 11,
                fontWeight: 600,
                textAlign: 'left',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {item.thumbnailTitle}
            </div>
            <NumberFormat
              displayType="text"
              value={item.thumbnailPrice || null}
              thousandSeparator
              renderText={(value) =>
                item.thumbnailPrice && currency === '원' ? (
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                  >{`${value}${currency}`}</span>
                ) : item.thumbnailPrice && currency !== '원' ? (
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                  >{`${currency} ${value}`}</span>
                ) : (
                  <span />
                )
              }
            />
          </Row>

          <Row
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 10,
            }}
          >
            <div
              style={{
                fontSize: 11,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {item.thumbnailContent}
            </div>
          </Row>
        </ThumbnailStyle>
      </>
    );
  },
);

export default CardListItemThumbnail3;

const ThumbnailStyle = styled.div<{ background?: any; text?: any }>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 30%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: ${(props) => props.background};
  color: ${(props) => props.text};
  /* padding: 5%; */
  padding: 2% 5%;
  @media ${(props) => props.theme.media.mobile} {
    height: 38%;
  }
`;
