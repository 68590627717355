import { makeObservable, flow, observable } from 'mobx';
import { MyNotification as MyNotificationStoreService } from 'services/MyNotification';
import { HttpClient } from 'services/http-client';
import { ChannelNotificationDto } from 'services/data-contracts';
import _ from 'lodash';
import ServiceStoreBase from './ServiceStoreBase';

/**
 * @description Public Link Store
 */
class MyNotificationStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      notifications: observable,
      alarmsCount: observable,

      getMyNotifications: flow,
      getMyNotificationCount: flow,
      read: flow,
      updateRead: flow,
    });
  }

  api: MyNotificationStoreService<unknown> = new MyNotificationStoreService(new HttpClient());

  alarmsCount: number = 0;

  notifications: Array<ChannelNotificationDto> = [];
  /** ========================================================================================= */

  // *getItems(args?: Array<any>) {
  *getMyNotifications(channelId: number) {
    const { response } = yield this.api.getNotifications(channelId);
    if (response.status === 200) {
      this.notifications = response.data;
      return response.data;
    }
  }

  *getMyNotificationCount(channelId: number) {
    const { response } = yield this.api.getNotificationCountOfChannel(channelId);
    if (response.status === 200) {
      this.alarmsCount = response.data;
    }
  }

  *read(channelId: number, notificationId: number) {
    const { response } = yield this.api.getNotification(channelId, notificationId);
    if (response.status === 200) {
      const notification = response.data;
      if (notification) {
        const index = this.notifications.findIndex(
          (recent: ChannelNotificationDto) => recent.id === notification.id,
        );
        const newNotifications = [...this.notifications];
        newNotifications[index].isRead = true;
        this.notifications = newNotifications;
      }
    }
  }

  setAlarms(count: number) {
    this.alarmsCount = count;
  }

  updateRead(id: number) {
    if (this.notifications) {
      const index = this.notifications.findIndex(
        (recent: ChannelNotificationDto) => recent.id === id,
      );
      const newNotifications = [...this.notifications];
      newNotifications[index].isRead = true;
      this.notifications = newNotifications;
    }
  }
}

export default MyNotificationStore;
