import React, { CSSProperties, ReactNode } from 'react';
import styled, { css } from 'styled-components';

type DefaultProps = {
  item: ToggleButtonType;
  width?: number;
  style?: CSSProperties;
  defaultChecked?: boolean;
  children?: ReactNode;
  onClick?: () => void;
};

type ToggleButtonType = {
  description?: string;
  id: string;
  iconSrc?: string;
  checked?: string;
  exclusive?: boolean;
};

const ToggleButton = ({ item, style, children, defaultChecked, onClick }: DefaultProps) => {
  return (
    <>
      <ToggleButtonLabel
        on={defaultChecked!}
        htmlFor={item.id}
        style={style}
        onClick={() => {
          if (onClick) onClick();
        }}
      >
        {children ? (
          <>{children}</>
        ) : (
          <>
            <TitleStyle>{item.description}</TitleStyle>
            {item.iconSrc && <IconStyle src={item.iconSrc} />}
          </>
        )}
      </ToggleButtonLabel>
    </>
  );
};

const ToggleOrigin = css`
  border: 2px solid #f4f4f4;
  > div {
    color: #999;
  }
  > img {
    opacity: 0.2;
    filter: invert(99%) sepia(0%) saturate(4221%) hue-rotate(189deg) brightness(114%) contrast(87%);
  }
  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;
const ToggleChecked = css`
  border: 2px solid #11c668;
  background-color: #f8fffb;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  > div {
    color: #111;
    color: #11c668;
  }

  > img {
    opacity: 1;
    filter: invert(56%) sepia(100%) saturate(383%) hue-rotate(96deg) brightness(89%) contrast(96%);
  }

  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`;

// label, 스타일 수정을 위한 스타일 컴포넌트
const ToggleButtonLabel = styled.label<{ on: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;

  border-radius: 15px;
  cursor: pointer;
  ${(props) => (props.on ? ToggleChecked : ToggleOrigin)}
`;

const IconStyle = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 0 0 8px;
`;

const TitleStyle = styled.div`
  font-size: 16px;
`;

export { ToggleButton, ToggleButtonType };
