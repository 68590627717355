import React from 'react';

import SELLER_FREE from './SELLER_FREE';
import SELLER_PRO from './SELLER_PRO';
import SELLER_PREMIUM from './SELLER_PREMIUM';
import BRAND_GIFT from './BRAND_GIFT';
import BRAND_PRO from './BRAND_PRO';
import BRAND_PREMIUM from './BRAND_PREMIUM';
import BRAND_FREE from './BRAND_FREE';

type BlockType = {
  component: React.ComponentType<any>;
};

const Explains = {
  SELLERFREE: {
    component: SELLER_FREE,
  },
  SELLERPRO: {
    component: SELLER_PRO,
  },
  SELLERPREMIUM: {
    component: SELLER_PREMIUM,
  },
  BRANDFREE: {
    component: BRAND_FREE,
  },
  BRANDPRO: {
    component: BRAND_PRO,
  },
  BRANDPREMIUM: {
    component: BRAND_PREMIUM,
  },
  BRANDGIFT: {
    component: BRAND_GIFT,
  },
};

export default Explains;
