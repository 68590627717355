import OpenGraphStore from 'stores/OpenGraphStore';

function useOpenGraph() {
  const openGraphStore = new OpenGraphStore();
  const getSiteInfo = async (url: string) => {
    return await openGraphStore.getSiteInfo(url)
  }

  return {
    getSiteInfo
  };
}

export default useOpenGraph;
