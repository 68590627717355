import React, { CSSProperties } from 'react';
import { IGalleryBlock } from 'types/BlockExtendsTypes';
import PostSwipeItem from 'pages/PostSwipeItem';
import styled from 'styled-components';
import PostSwipeSquare from 'pages/PostSwipeSquare';
import { BlocktitleBarStyle } from '../../common/BlockTitleBar';
import StickerBox from 'components/commons/stickers/StickerBox';
import { getFlexPosition } from 'libs/helper/utils';

const GalleryTemplate = ({
  item,
  textStyle,
  editMode,
  onClick,
}: {
  item: IGalleryBlock;
  textStyle?: CSSProperties;
  editMode?: boolean;
  onClick: () => void;
}) => {
  const getTemplate = () => {
    switch (item.layout) {
      case 'TypeA':
        return <PostSwipeItem items={item?.items} useAutoPlay={!editMode && item.autoPlay} />;
      case 'TypeB':
        return <PostSwipeItem items={item?.items} useAutoPlay={!editMode && item.autoPlay} />;
      case 'TypeC':
        const result2 = getGroupItems(2);
        return <PostSwipeSquare items={result2} useAutoPlay={!editMode && item.autoPlay} />;
      case 'TypeD':
        const result4 = getGroupItems(4);
        return <PostSwipeSquare items={result4} useAutoPlay={!editMode && item.autoPlay} />;
      case 'typeE':
        break;
      default:
        break;
    }
  };

  function getGroupItems(groupSize: number) {
    if (item.items) {
      const result = [];
      for (let i = 0; i < item.items.length; i += groupSize) {
        result.push(item.items.slice(i, i + groupSize));
      }
      return result;
    }
  }

  const TitleArea = () => {
    return (
      <>
        {item?.title || (item?.stickers && item?.stickers?.length > 0) ? (
          <div>
            <BlocktitleBarStyle
              style={{ textAlign: item?.contentAlign as 'left' | 'center' | 'right', ...textStyle }}
            >
              <div style={{ margin: '5px 0' }}>
                {(item?.stickerPosition === 'top' ||
                  typeof item?.stickerPosition === 'undefined') && (
                  <StickerBox
                    stickers={item?.stickers}
                    style={{
                      justifyContent: getFlexPosition(item?.contentAlign),
                    }}
                  />
                )}
              </div>
              {item?.title && <div style={{ padding: ' 0' }}>{item?.title}</div>}
              <div style={{ margin: '5px 0' }}>
                {item?.stickerPosition === 'bottom' && (
                  <StickerBox
                    stickers={item?.stickers}
                    style={{
                      justifyContent: getFlexPosition(item?.contentAlign),
                    }}
                  />
                )}
              </div>
            </BlocktitleBarStyle>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <ContainerStyle>
      <ConatainerStyle onClick={onClick} style={{}}>
        {item?.layout === 'TypeB' && <TitleArea />}
        {getTemplate()}
        {item?.layout === 'TypeA' && <TitleArea />}
      </ConatainerStyle>
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  width: 100%;
  min-height: 90px;
`;

const ConatainerStyle = styled.div`
  overflow: hidden;
  border-radius: 8px;
`;

export default GalleryTemplate;
