import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
import { useStore } from 'stores/StoreHelper';
import { useTranslation } from 'react-i18next';
import { validationHelper as VH } from 'controls/validationHelper';
import { toast } from 'react-toastify';

import BlueBtn from 'features/mainFeatures/auth/components/BlueBtn';
import PasswordForm from 'components/items/forms/PasswordForm';

const ResetPassword = () => {
  const { navigate } = useNavigation();

  const { t } = useTranslation();
  const { authStore } = useStore();

  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState<string>();
  const [isActiveButton, setIsActiveButton] = useState<boolean>(false);

  const handleClickResetPassword = async () => {
    const token = searchParams.get('token');
    if (token !== null) {
      const result = (await authStore.resetPassword(token, {
        newPassword: password,
        confirmNewPassword: password,
      })) as any;
      if (result === true) {
        navigate('/login');
      } else {
        toast(`${result.errorMessage} 다시 재 설정을 진행 해 주세요`, { type: 'warning' });
      }
    } else {
      // TODO : translate
      toast('잘못된 값이 있거나 기간이 만료되었습니다. 다시 진행 해 주세요.');
    }
  };

  useEffect(() => {
    if (password) {
      setIsActiveButton(VH.required(password));
    }
  }, [password]);

  return (
    <FindIdContainer>
      <img src="/images/ohmossc.png" alt="" />
      <LoginTitle>패스워드를 변경해주세요</LoginTitle>
      <DesctiptionStyle>패스워드는 8자 이상으로 입력해주세요.</DesctiptionStyle>
      <div style={{ width: '400px' }}>
        <PasswordForm onChangeValue={setPassword} showLabel label="새로운 패스워드" />
      </div>

      <FindPasswordButtonStyled onClick={handleClickResetPassword} isActiveButton={isActiveButton}>
        {t('resetPassword')}
      </FindPasswordButtonStyled>
    </FindIdContainer>
  );
};

const flexCenter = css`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const FindIdContainer = styled.div`
  margin: auto;
  height: 100vh;
  ${flexCenter};
  flex-direction: column;
`;

const LoginTitle = styled.div`
  text-align: center;
  font-size: 30px;
  margin-top: 30px;
  font-weight: 600;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 4em;
  }
`;
const DesctiptionStyle = styled.div`
  font-size: 16px;
  margin: 20px;
`;

const FindPasswordButtonStyled = styled(BlueBtn)<{ isActiveButton?: boolean }>`
  margin-top: 56px;
  width: 160px;
  cursor: ${({ isActiveButton }) => (isActiveButton ? 'pointer' : 'none')};
  pointer-events: ${({ isActiveButton }) => (isActiveButton ? 'auto' : 'none')};
  background-color: ${({ isActiveButton }) => (isActiveButton ? '#3e64ea' : '#999999')};
`;

export default ResetPassword;
