import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { useStore } from 'stores/StoreHelper';
import { BoardCategoryModel } from 'types/CommonTypes';
import { observer } from 'mobx-react';
import { ChannelBoardDto } from 'services/data-contracts';
import { OhmossButton } from 'resources/controls/OhmossButton';

import _ from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import ListOfBoardsContainer from 'features/board/containers/ListOfBoardsContainer';
import CreateBoardForm from '../components/CreateBoardForm';
import { useTranslation } from 'react-i18next';

/**
 * @description 모든 링크를 생성하기 위한 컨테이너임
 */
const BoardContainer = observer(() => {
  const { uiStores, uiStore, myChannelStore, myBoardStore } = useStore();
  const { adminStore } = uiStores;
  const { t } = useTranslation();

  const [model, setModel] = useState<ChannelBoardDto | undefined>();
  const [showBoardForm, setShowBoardForm] = useState<boolean>(false);

  const handleOnItemDelete = (board: ChannelBoardDto) => {
    uiStore.confirm.show({
      style: { maxWidth: 400, height: 220 },
      message: `${board?.name} ${t('A127')}?`,
      okText: t('Delete'),
      closeText: t('Cancel'),
      onConfirmed: async () => {
        const result = await myBoardStore.deleteBoard(
          myChannelStore.currentChannel?.id as number,
          board?.id as number,
        );

        myBoardStore.updateBoardByItem(board as ChannelBoardDto, 'delete');
        uiStore.universalModal.close();
      },
    });
  };

  const handleClickCreate = (boardCategory?: BoardCategoryModel) => {
    setShowBoardForm(true);
  };

  const handleVisibleChange = (board: ChannelBoardDto, isVisible: boolean) => {
    const cloned = { ...board, isActive: isVisible };
    myBoardStore.updateBoard(myChannelStore.currentChannel?.id as number, cloned);
  };

  useEffect(() => {
    if (adminStore.showNewBoardEditor) {
      handleClickCreate(model);
    }
  }, [adminStore.showNewBoardEditor]);

  return (
    <ContainerStyle>
      <CreateButtonStyle>
        <OhmossButtonStyle
          type="primary"
          style={{ height: 50, flex: 1, display: 'flex', margin: 20, padding: '100px 10px' }}
          onClick={() => {
            handleClickCreate();
          }}
        >
          <AddIcon />
          <div>{t('A125')}</div>
        </OhmossButtonStyle>
      </CreateButtonStyle>
      <div style={{ margin: '0 15px' }}>
        {myBoardStore.myBoards.length > 0 ? (
          <ListOfBoardsContainer
            draggable
            onBoardDelete={(board: ChannelBoardDto) => {
              handleOnItemDelete(board);
            }}
            onLinkEdit={(board: ChannelBoardDto) => {}}
            onBoardSelect={(board: ChannelBoardDto) => {
              setModel(board);
              handleClickCreate(board);
            }}
            onVisibleChange={handleVisibleChange}
          />
        ) : (
          <EmptyLinkStyle>
            <div style={{ textAlign: 'center' }}>
              <div>{t('A126')}</div>
            </div>
          </EmptyLinkStyle>
        )}
      </div>
      <CreateBoardForm
        boardModel={model}
        show={showBoardForm}
        onClose={() => {
          setShowBoardForm(false);
          setModel(undefined);
        }}
      />
    </ContainerStyle>
  );
});

const OhmossButtonStyle = styled(OhmossButton)`
  /* overflow: hidden; */
  padding: 20px;
`;

const ContainerStyle = styled.div`
  white-space: pre-line;

  @media ${(props) => props.theme.media.mobile} {
    /* position: absolute; */
    left: 0;
    right: 0;
    /* bottom: 180px; */
  }
`;

const CreateButtonStyle = styled.div`
  z-index: 100;
  display: none;
  @media ${(props) => props.theme.media.mobile} {
    width: 80%;
    display: unset;
  }
`;

const EmptyLinkStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
`;

export default BoardContainer;
