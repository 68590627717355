import useNavigation from 'hooks/useNavigation';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import SellerMembership from './SellerMembership';
import BrandMembership from './BrandMembership';

const MembershipManualContainer = ({ mode }: { mode?: '셀러' | '브랜드' }) => {
  const { navigate } = useNavigation();
  const [searchParam] = useSearchParams();
  const membershipType = searchParam.get('membership');

  const handleViewPro = () => {
    if (mode === '셀러') {
      navigate('/my/subscriptions?tab=멤버십 설명서&mode=셀러&membership=PRO');
    } else if (mode === '브랜드') {
      navigate('/my/subscriptions?tab=멤버십 설명서&mode=브랜드&membership=PRO');
    }
  };

  const handleViewPremium = () => {
    if (mode === '셀러') {
      navigate('/my/subscriptions?tab=멤버십 설명서&mode=셀러&membership=PREMIUM');
    } else if (mode === '브랜드') {
      navigate('/my/subscriptions?tab=멤버십 설명서&mode=브랜드&membership=PREMIUM');
    }
  };

  const convertMembershipType = (mode: '셀러' | '브랜드' | undefined) => {
    if (mode === '셀러') {
      return 'Seller';
    } else if (mode === '브랜드') {
      return 'Brand';
    }
  };

  return (
    <Wrapper>
      {!membershipType && (
        <>
          <TopHeader>{mode} 멤버십</TopHeader>
          <HeaderTitle>
            {mode === '셀러' && <>셀러 여러분 부자되세요!</>}
            {mode === '브랜드' && (
              <>
                브랜드가 원하던 공구 서비스가
                <br />
                여기에 있습니다.
              </>
            )}
          </HeaderTitle>
          <HeaderSub style={{ marginTop: mode === '브랜드' ? 34 : 0 }}>
            {mode === '셀러' && <>성공적인 공구를 위한 멤버십이 준비되어 있습니다</>}
            {mode === '브랜드' && <>브랜드, 에이전시, 벤더</>}
          </HeaderSub>
          <BackgroundImg
            src={
              mode === '셀러'
                ? '/images/management/membership/membership_manual_seller.svg'
                : '/images/management/membership/membership_manual_brand.svg'
            }
            alt="background"
          />
          <Box>
            <SelectorContainer>
              <Badge />
              <Text>
                {convertMembershipType(mode)}
                {`'s`}
                <br /> Choice
              </Text>
              <img
                src="/images/management/membership/medal_icon.svg"
                style={{ position: 'absolute', left: 42 }}
                alt=""
              />
              <SelectBtn onClick={handleViewPro}>{mode} PRO</SelectBtn>
            </SelectorContainer>
            <SelectorContainer>
              <Badge />
              <Text>
                공구 컨설팅
                <br />
                포함
              </Text>
              <SelectBtn onClick={handleViewPremium}>{mode} PREMIUM</SelectBtn>
              <img
                src="/images/management/membership/medal_icon_gold.svg"
                style={{ position: 'absolute', left: 42 }}
                alt=""
              />
            </SelectorContainer>
          </Box>
        </>
      )}
      {mode === '셀러' ? (
        <SellerMembership membership={membershipType} />
      ) : (
        <BrandMembership membership={membershipType} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BackgroundImg = styled.img`
  position: relative;
  height: calc(100vh - 120px);
  object-fit: cover;
`;

const Box = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  flex-direction: column;
  background: rgba(23, 51, 0, 0.78);
  width: 100%;
  height: 150px;
  bottom: 0;
  padding: 20px;
  z-index: 1;
`;

const SelectorContainer = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
`;

const Badge = styled.div`
  position: relative;
  background-color: #d60863;
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;

const Text = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  margin-left: 40px;
`;

const SelectBtn = styled.button`
  position: absolute;
  right: 20px;
  padding: 10px 30px;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  border-radius: 0.625rem;
  background: rgba(255, 255, 255, 0.4);
  cursor: pointer;
`;

const TopHeader = styled.div`
  display: inline-flex;
  position: absolute;
  padding: 0.175rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 62.4375rem;
  background: #96e06e;
  top: 140px;
  left: 20px;
  z-index: 1;
  font-size: 12px;
  font-weight: 500;
`;

const HeaderTitle = styled.div`
  color: #fff;
  display: inline-flex;
  position: absolute;
  font-size: 22px;
  font-weight: 500;
  top: 170px;
  left: 20px;
  z-index: 1;
`;

const HeaderSub = styled.div`
  color: #fff;
  display: inline-flex;
  position: absolute;
  font-size: 16px;
  font-weight: 400;
  top: 205px;
  left: 20px;
  z-index: 1;
`;

export default MembershipManualContainer;
