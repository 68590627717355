/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { useStore } from 'stores/StoreHelper';
import { ChannelDto, FriendshipDto, TagDto } from 'services/data-contracts';
import { Row } from 'components/commons/layouts';
import { Avatar } from '@mui/material';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { t } from 'i18next';

import _ from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import NoContent from 'components/commons/NoContent';

const FollowList = observer(({ type }: { type: 'follow' | 'following' }) => {
  type FollowModel = {
    id: number;
    url?: string;
    channelName?: string;
    createDate: string;
  };
  const { myChannelStore } = useStore();
  const [follows, setFollows] = useState<FollowModel[]>();

  const handleClickFollower = (follower: FollowModel) => {
    window.open(`/${follower.channelName}`, '_blank');
  };

  const fetchData = async () => {
    if (myChannelStore.currentChannel) {
      if (type === 'follow') {
        const f = (await myChannelStore.getMyFollowers(
          myChannelStore.currentChannel?.id as number,
        )) as never as FriendshipDto[];
        const conveted = f.map((follow: FriendshipDto) => {
          return {
            id: follow.id,
            url: follow.followerProfile?.profileImage?.publicUrl,
            channelName: follow.follower?.channelName,
          };
        }) as FollowModel[];
        setFollows(conveted);
      } else {
        const f = (await myChannelStore.getMyFollowings(
          myChannelStore.currentChannel?.id as number,
        )) as never as ChannelDto[];
        const conveted = f.map((follow: ChannelDto) => {
          return {
            id: follow.id,
            url: follow.profile?.backgroundImage?.publicUrl,
            channelName: follow.channelName,
          };
        }) as FollowModel[];
        setFollows(conveted);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [myChannelStore.currentChannel, type]);

  return (
    <div style={{ margin: 20, width: '90%' }}>
      {follows?.map((follower: FollowModel) => (
        <FollowerStyle>
          <Row style={{ justifyContent: 'space-between' }}>
            <Row style={{ justifyContent: 'flex-start' }}>
              <Avatar style={{ width: 60, height: 60 }} src={follower.url} />
              <div style={{ marginLeft: 10 }}>
                <FollowerTitleStyle>{follower.channelName}</FollowerTitleStyle>
                <FollowerDateStyle>{moment.utc(follower.createDate).fromNow()}</FollowerDateStyle>
              </div>
            </Row>
            <div style={{ width: 60 }}>
              <OhmossButton
                type="simple"
                style={{ padding: '10px 10px' }}
                onClick={() => {
                  handleClickFollower(follower);
                }}
              >
                {t('A197')}
              </OhmossButton>
            </div>
          </Row>
        </FollowerStyle>
      ))}
      {follows?.length === 0 && (
        <NoContent title={`${type === 'follow' ? '팔로워가' : '팔로잉이'}`} />
      )}
    </div>
  );
});

const FollowerStyle = styled.div`
  margin: 15px 0;
`;

const FollowerTitleStyle = styled.div`
  font-weight: 600;
  font-size: 18px;
`;
const FollowerDateStyle = styled.div`
  color: #888;
  font-size: 14px;
`;

const TagsStyle = styled.div``;

const TagItemStyle = styled.div`
  color: #949494;
`;

export default FollowList;
