import React from 'react';
import Common from './Common';
import { MembershipPlanDto } from 'services/data-contracts';

const SELLER_PREMIUM = ({ plan }: { plan: MembershipPlanDto }) => {
  const texts = [
    '셀러 PRO 서비스 기본 제공',
    'CS 매니저 초대기능 - 30명까지 초대가능',
    '공구 리뷰 컨설팅 월 1회 무료 제공',
    '공구 세미나 최우선 참여권 제공',
  ];

  const description = '공구 전문가의 리뷰와\r소수정예 세미나 최우선 참여권';
  return (
    <>
      <Common texts={texts} plan={plan} description={description} />
    </>
  );
};

export default SELLER_PREMIUM;
