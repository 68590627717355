import React, { useEffect, useState } from 'react';
import { Row } from 'components/commons/layouts';
import { cutoffString } from 'libs/helper/utils';

import CommonThumbnail from 'features/management/components/commonControl/CommonThumbnail';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import { useStore } from 'stores/StoreHelper';
import AdItemMoreButtons from 'features/mossMain/components/AdItemMoreButtons';
import boardCommonLogics from 'features/board/logic/boardCommonLogic';
import { LinkDto } from 'services/data-contracts';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

/** @description 고객별 문의 쓰레드 */
interface Props {
  item?: LinkDto;
  useSimpleInfo?: boolean;
  useContext?: boolean;
  cutOff?: number;
  onClick?: () => void;
}

const DealInfo2: React.FC<Props> = ({
  item,
  cutOff = 25,
  useSimpleInfo,
  onClick,
  useContext = true,
}) => {
  const { uiStore } = useStore();
  const { getImageFromDom } = boardCommonLogics();

  const [thumbnailUrl, setThumbnailUrl] = useState<string>();

  const handleClickShowMore = () => {
    uiStore.bottomSheet.show({
      children: <AdItemMoreButtons link={item as LinkDto} />,
    });
  };

  const onGoingToDescription = () => {
    switch (item?.status) {
      case 'ONGOING':
        return '진행중';
      case 'UPCOMING':
        return '예정';
      case 'ENDED':
        return '완료';
      default:
        return '알수없음';
    }
  };

  useEffect(() => {
    if (item) {
      if (!item.thumbnail?.publicUrl) {
        const thumbnail = getImageFromDom(item?.content);
        if (thumbnail) {
          setThumbnailUrl(thumbnail);
        }
      } else {
        setThumbnailUrl(item.thumbnail?.publicUrl);
      }
    }
  }, [item]);
  return (
    <div style={{ padding: '20px 10px', borderBottom: '1px solid #efefef', width: '100%' }}>
      <Row style={{ width: 'unset', justifyContent: 'space-between' }}>
        <Row style={{ justifyContent: 'flex-start' }}>
          <div style={{}}>
            <CommonThumbnail initialValue={thumbnailUrl} />
          </div>
          <Row style={{ width: 'unset', marginLeft: 10 }}>
            <Row
              style={{
                justifyContent: 'flex-start',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <div className="item-title" onClick={onClick}>
                <OnGoingStyle status={item?.status}>{onGoingToDescription()}</OnGoingStyle>
                <span style={{ color: '#48505D', fontWeight: '400' }}>
                  {cutoffString(item?.title, cutOff)}
                </span>
              </div>
              <>
                {useSimpleInfo !== true && (
                  <>
                    <Row style={{ justifyContent: 'flex-start', gap: 5 }}>
                      <CountStyle>
                        앵콜{' '}
                        {item?.encoreCount === 0 || typeof item?.encoreCount === 'undefined'
                          ? '없음'
                          : `${item?.encoreCount}건`}
                      </CountStyle>
                      <CountStyle>
                        문의{' '}
                        {item?.inquiryCount === 0 || typeof item?.inquiryCount === 'undefined'
                          ? '없음'
                          : `${item?.inquiryCount}건`}
                      </CountStyle>
                    </Row>
                  </>
                )}
              </>
            </Row>
          </Row>
        </Row>
        <>
          {useContext && (
            <IconButton onClick={handleClickShowMore}>
              <img src="/images/edit-button.svg" alt="" />
            </IconButton>
          )}
        </>
      </Row>
    </div>
  );
};

const CountStyle = styled.div<{ type?: 'encore' | 'inquiry' | 'name' | undefined }>`
  border-radius: 62.4375rem;
  text-align: left;
  display: flex;
  padding: 0.25rem 0.625rem;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  /* gap: 10px; */
  font-size: 0.6875rem;
  margin-top: 10px;
  background-color: ${(props) => (props.type === 'encore' ? '#CACACA' : '#96E06E')};
`;

const OnGoingStyle = styled.div<{ status: LinkDto['status'] }>`
  border-radius: 4px;
  padding: 0px 5px;
  min-width: 60px;
  display: inline-block;
  padding: 0.25rem 0.625rem;
  text-align: center;
  font-size: 0.6875rem;
  margin-right: 10px;

  ${(props) => {
    if (props.status === 'ONGOING') {
      return { background: '#0bbd58', color: '#fff' };
    } else if (props.status === 'UPCOMING') {
      return { background: 'rebeccapurple', color: '#fff' };
    } else if (props.status === 'ENDED') {
      return { background: '#848484', color: '#fff' };
    }
  }};
`;
export default DealInfo2;
