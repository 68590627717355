/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PostDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MyPost<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 포스트 정보
   *
   * @tags my post
   * @name GetPost1
   * @summary 포스트 정보
   * @request GET:/v2/me/channels/{channelId}/boards/{boardId}/posts/{postId}
   * @secure
   */
  getPost1 = (channelId: number, boardId: number, postId: number, params: RequestParams = {}) =>
    this.http.request<PostDto, any>({
      path: `/v2/me/channels/${channelId}/boards/${boardId}/posts/${postId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 포스트 수정
   *
   * @tags my post
   * @name UpdatePost
   * @summary 포스트 수정
   * @request PUT:/v2/me/channels/{channelId}/boards/{boardId}/posts/{postId}
   * @secure
   */
  updatePost = (
    channelId: number,
    boardId: number,
    postId: number,
    data: PostDto,
    params: RequestParams = {},
  ) =>
    this.http.request<PostDto, any>({
      path: `/v2/me/channels/${channelId}/boards/${boardId}/posts/${postId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 포스트 삭제
   *
   * @tags my post
   * @name DeletePost
   * @summary 포스트 삭제
   * @request DELETE:/v2/me/channels/{channelId}/boards/{boardId}/posts/{postId}
   * @secure
   */
  deletePost = (channelId: number, boardId: number, postId: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/boards/${boardId}/posts/${postId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 포스트 순번 수정
   *
   * @tags my post
   * @name UpdateSeqOfPost
   * @summary 포스트 순번 수정
   * @request PUT:/v2/me/channels/{channelId}/boards/{boardId}/posts/{postId}/seq
   * @secure
   */
  updateSeqOfPost = (
    channelId: number,
    boardId: number,
    postId: number,
    data: PostDto,
    params: RequestParams = {},
  ) =>
    this.http.request<PostDto, any>({
      path: `/v2/me/channels/${channelId}/boards/${boardId}/posts/${postId}/seq`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 포스트 목록
   *
   * @tags my post
   * @name GetPosts
   * @summary 포스트 목록
   * @request GET:/v2/me/channels/{channelId}/boards/{boardId}/posts
   * @secure
   */
  getPosts = (
    channelId: number,
    boardId: number,
    query?: {
      /** 포스트 타입 (NORMAL, EVENT, TIME_DEAL, APPOINTMENT */
      postType?: 'NORMAL' | 'APPOINTMENT' | 'PRODUCT';
      /** 검색어 (post 제목) */
      searchText?: string;
      /**
       * 'yyyy-MM-dd' (시작 날짜)
       * @format date
       */
      startDate?: string;
      /**
       * 'yyyy-MM-dd' (마지막 날짜)
       * @format date
       */
      endDate?: string;
      /**
       * 위도
       * @format double
       */
      latitude?: number;
      /**
       * 경도
       * @format double
       */
      longitude?: number;
      /**
       * 반경거리
       * @format int32
       */
      radius?: number;
      /** 활성여부 */
      isActive?: boolean;
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<PostDto[], any>({
      path: `/v2/me/channels/${channelId}/boards/${boardId}/posts`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 포스트 생성
   *
   * @tags my post
   * @name CreatePost
   * @summary 포스트 생성
   * @request POST:/v2/me/channels/{channelId}/boards/{boardId}/posts
   * @secure
   */
  createPost = (channelId: number, boardId: number, data: PostDto, params: RequestParams = {}) =>
    this.http.request<PostDto, any>({
      path: `/v2/me/channels/${channelId}/boards/${boardId}/posts`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
