import TitleForm from 'components/items/forms/TitleForm';
import React, { useCallback, useEffect, useState } from 'react';
import { ItemSectionTitle } from 'resources/controls/ItemSectionStyle';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import styled from 'styled-components';
import _, { debounce } from 'lodash';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MyDealStore from 'stores/MyDealStore';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import {
  LinkDto,
  MemberCreationDto,
  MemberDto,
  MemberUpdateDto,
  SimpleLinkDto,
} from 'services/data-contracts';
import PasswordForm from 'components/items/forms/PasswordForm';
import EmailForm from 'components/items/forms/EmailForm';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import AgencyController from 'controllers/BrandController';
import { validationHelper } from 'controls/validationHelper';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';
import PartnerController from 'controllers/PartnerController';
import AssignedDealsList from './AssignedDealsList';
import AssignedDealItem from './AssignedDealItem';

type MemberCombineModel = MemberDto & MemberCreationDto;
const ResetPassword = ({ onUpdate }: { onUpdate?: () => void }) => {
  const { uiStore } = useStore();

  const { initialModel, initialBinding, updateServiceModel, serviceModel } =
    useModelManagement<MemberCombineModel>();

  const [localModel, setLocalModel] = useInitializeModel<Partial<MemberCombineModel>>(
    {},
    undefined,
    initialBinding,
  );

  const { updateManagerPassword } = AgencyController();

  const [isValid, setIsValid] = useState<boolean>(false);

  const validate = () => {
    const password = validationHelper.password(localModel?.password as string);
    const length = !!(localModel?.password && localModel?.password?.length > 7);
    setIsValid(password && length);
  };

  useEffect(() => {
    if (localModel) {
      validate();
    }
  }, [localModel]);

  return (
    <ContainerStyle>
      <InputBoxWrapper>
        <div style={{ marginBottom: 30 }}>
          패스워드, 아바타, 소속, 이름의 매니저 정보가 초기화됩니다.
          <br />
          <br /> 그러나 해당 매니저가 담당하던 공구 리스트는 변경되지 않으며 여전히 관리할 수
          있습니다.
          <br /> <br />
          새로운 매니저에게 액세스 권한을 부여하여 관리할 수 있습니다.
        </div>
        <ItemSectionTitle style={{ margin: '10px 0px 5px 0px', fontSize: 13 }}>
          패스워드
        </ItemSectionTitle>
        <PasswordForm
          autoComplete="new-password"
          onChangeValue={(value: string) => {
            setLocalModel('password', value);
            updateServiceModel('password', value);
          }}
          showLabel={false}
          placeHolder="이메일 주소로 안내가 발송 됩니다."
        />
      </InputBoxWrapper>

      <ButtonWrapper>
        <OhmossButton
          disabled={!isValid}
          onClick={() => {
            updateManagerPassword();
            uiStore.modal.close();
          }}
        >
          확인
        </OhmossButton>
      </ButtonWrapper>
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const InputBoxWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
`;

export default ResetPassword;
