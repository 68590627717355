import React, { useEffect, useRef, useState } from 'react';
import { LinkItemModel } from 'types/CommonTypes';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import ScrollContainer from 'react-indiana-drag-scroll';
import InfoIcon from '@mui/icons-material/Info';
import { Row } from '../layouts';

interface Props {
  initialValue?: LinkItemModel[];
  items?: LinkItemModel[];
  onChange?: (linkItems: LinkItemModel[]) => void;
  onSelectedItem?: (linkItem: LinkItemModel) => void;
}

const MultipleImageHeader = ({ items, initialValue, onChange, onSelectedItem }: Props) => {
  const [files, setFiles] = useState<LinkItemModel[] | undefined>([]);
  const initialized = useRef<boolean>(false);
  const lastItemCount = useRef<number>(0);
  const selectedItem = useRef<LinkItemModel>();

  const handleDelete = (index: number) => {
    const newFiles = files
      ?.filter((file: LinkItemModel, i: number) => i !== index)
      .map((item, i) => ({
        ...item,
        seq: i + 1,
      })) as LinkItemModel[];

    // eslint-disable-next-line prefer-destructuring
    selectedItem.current = newFiles[0];
    setFiles(newFiles);
    if (onChange) {
      onChange(newFiles as LinkItemModel[]);
    }
  };

  const setUpdateFiles = (targetFiles: LinkItemModel[]) => {
    setFiles(targetFiles);
  };

  useEffect(() => {
    // if (items && initialized.current === true) {
    if (items) {
      // 선택된 아이템이 없으면 첫번째를 반환하고,(초기값)
      if (typeof selectedItem.current === 'undefined') {
        selectedItem.current = items[0];
      } else if (lastItemCount.current !== items.length) {
        selectedItem.current = items[lastItemCount.current];
      }
      // } else {
      //   selectedItem.current = items[lastItemCount.current];
      // }
      setUpdateFiles(items);
      if (onSelectedItem) onSelectedItem(items[(selectedItem.current?.seq ?? 0) - 1]);

      // 전체 길이를 할당함.
      lastItemCount.current = items.length;
    }
  }, [items]);

  useEffect(() => {
    if (initialValue) {
      selectedItem.current = initialValue[0];
      setUpdateFiles([...initialValue]);
      initialized.current = true;
    }
  }, [initialValue]);

  return (
    <ScrollContainer className="scroll-container">
      <Row
        style={{
          justifyContent: 'flex-start',
          gap: 15,
          flex: 1,
          width: 'auto',
          padding: '10px 0',
          marginLeft: 5,
          marginRight: 5,
        }}
      >
        {files?.map((linkItem: LinkItemModel, index: number) => (
          <DefaultIconStyle selected={linkItem.seq === selectedItem?.current?.seq}>
            <div style={{ position: 'relative' }}>
              <ImageStyle
                onClick={() => {
                  selectedItem.current = linkItem;
                  if (onSelectedItem) onSelectedItem(linkItem);
                }}
              >
                <img
                  src={linkItem?.file?.publicUrl}
                  alt=""
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </ImageStyle>
              {linkItem?.urlValid === false && (
                <IconStyle2>
                  <InfoIcon style={{ width: 15, height: 15, color: 'orange' }} />
                </IconStyle2>
              )}
              <IconStyle
                onClick={() => {
                  handleDelete(index);
                }}
              >
                <CloseIcon style={{ color: '#fff', width: 18, height: 18 }} />
              </IconStyle>
            </div>
          </DefaultIconStyle>
        ))}
      </Row>
    </ScrollContainer>
  );
};

const DefaultIconStyle = styled.div<{ selected?: boolean; valid?: boolean }>`
  width: 60px;
  height: 60px;
  background: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  outline: ${(props) => (props.selected ? '2px solid #2ebe59' : '2px solid transparent')};
  outline-offset: 3px;
  transition: background 0.2s ease-in-out;

  :hover {
    background: #2ebe59;
  }
`;

const IconStyle = styled.div`
  width: 20px;
  height: 20px;
  background: #000;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 3px;
  right: 3px;
  border-radius: 40px;
`;

const IconStyle2 = styled.div`
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 3px;
  bottom: 3px;
`;

const ImageStyle = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
  object-fit: cover;
  width: 60px;
  height: 60px;
  border-radius: 8px;
`;

export default MultipleImageHeader;
