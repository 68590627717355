import React, { useEffect, useRef } from 'react';
import { Routes, Route, useLocation, useRoutes } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useStore } from 'stores/StoreHelper';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useChat } from 'libs/hooks/useChat';

import FollowProvider from 'providers/follow/FollowProvider';
import DealsProvider from 'features/mossMain/context/DealsProvider';
import FullModal from 'components/commons/FullModal';
import Confirm2 from 'components/commons/Confirm2';
import UniversalModal from 'components/commons/UniversalModal/Modal';
import myRoutes from 'routes/myRoutes';
import NavigationTabBar from 'components/commons/NavigationTabBar';
import DetailPageHeader from 'components/commons/DetailPageHeader';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import BottomSheetOnly from 'components/commons/BottomSheetOnly';
import chatController from 'features/community/controllers/chatController';
import useMobileLayout from 'hooks/useMobileLayout';
import { initGA } from './MainContainerBase';
import ProviderWrapper from './ProviderWrapper';
import AuthContainer from './AuthContainer';
import { ManagemernChildLayout } from 'features/management/components/common/ManagementChildLayout';
import { useMediaContext } from 'providers/useMediaContext';
import styled from 'styled-components';
import MyLNBCommon from 'features/management/components/MyLNBCommon';
import { menuItems } from 'definitions/menuItems';
import CommonGnb from 'features/management/components/CommonGnb';

const MyRouter = () => {
  const location = useLocation();
  const containerRef = useRef(null);
  const currentRoute = useRoutes(myRoutes);
  const { initChat } = useChat();
  const { myChannel } = useRootContext();
  const { getUser } = chatController();
  const { setSize } = useMobileLayout(containerRef);
  const ScreenTypes = useMediaContext();

  const { historyStore } = useStore();

  const getElement = (route: any) => {
    if (window.isWebView) {
      return route.webViewElement ? <route.webViewElement /> : <route.element />;
    } else if (isMobile) {
      return route.mobileElement ? <route.mobileElement /> : <route.element />;
    } else {
      return <route.element />;
    }
  };

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    historyStore.pushPopHistory({ key: location.key, path: location.pathname });
  }, [location]);

  useEffect(() => {
    if (myChannel) {
      const initialize = async () => {
        const result = await getUser(myChannel?.channelName as string);
        if (result) {
          initChat(myChannel?.channelName as string);
        }
      };

      initialize();
    }
  }, [myChannel]);

  useEffect(() => {
    setSize();
  }, []);

  return (
    <>
      {ScreenTypes.isLarge && (
        <>
          <GnbStyle>
            <CommonGnb />
          </GnbStyle>
          <LargeGnbStyle>
            <LogoStyle style={{ width: '100%' }}>
              <div style={{ padding: 10 }}>
                <img src="/images/oh_logo.svg" style={{ width: 60 }} />
              </div>
            </LogoStyle>
            <MyLNBCommon items={menuItems} style={{ paddingTop: 150 }} />
          </LargeGnbStyle>
        </>
      )}
      <ManagementLayout style={{ paddingTop: ScreenTypes.isLarge ? 70 : 0 }}>
        {(ScreenTypes.isSmall || ScreenTypes.isMedium) && (
          <div style={{ position: 'sticky', top: 0, zIndex: 1 }}>
            <DetailPageHeader
              title={currentRoute?.props?.match?.route?.title}
              gobackUrl={currentRoute?.props?.match?.route?.goBack}
            />
          </div>
        )}
        <ManagemernChildLayoutStyle useBottom={true}>
          <Routes location={location}>
            {myRoutes.map((route, index: number) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <AuthContainer isPublic={route.isPublic} title={route.title}>
                      <FollowProvider>
                        <ProviderWrapper provider={route.provider}>
                          <BottomSheetOnly />
                          <FullModal />
                          <Confirm2 />
                          <UniversalModal />
                          <DealsProvider>
                            <div ref={containerRef}>{getElement(route)}</div>
                          </DealsProvider>
                        </ProviderWrapper>
                      </FollowProvider>
                    </AuthContainer>
                  }
                />
              );
            })}
          </Routes>
        </ManagemernChildLayoutStyle>
        {ScreenTypes.isSmall && <NavigationTabBar type="main" />}
      </ManagementLayout>
    </>
  );
};

const LargeGnbStyle = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 270px;
  min-width: 100px;
  align-items: flex-start;
  justify-content: flex-start;
  background: #fff;
`;

const ManagemernChildLayoutStyle = styled(ManagemernChildLayout)``;

const GnbStyle = styled.div`
  padding: 0 20px;
  position: fixed;
  display: flex;
  align-items: center;
  left: 270px;
  height: 60px;
  right: 0px;
  background: #fff;
`;

const LogoStyle = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
`;

export default MyRouter;
