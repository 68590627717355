import React from 'react';
import { LinkDto, MemberDto, PartnerDto, SimpleDealDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import styled from 'styled-components';
import PartnerController from 'controllers/PartnerController';
import { Avatar } from '@mui/material';
import { Row } from 'components/commons/layouts';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';
import PartnerSettingsContainer from './PartnerSettingsContainer';
import AssignedDealsList from './AssignedDealsList';
import MemberCreateContainer from './MemberCreateContainer';

const PartnersManagersList = ({
  partners,
  managers,
  acceptedPartner,
  onRefresh,
}: {
  managers?: MemberDto[];
  partners?: PartnerDto[];
  acceptedPartner?: boolean;
  onRefresh: () => void;
}) => {
  const { uiStore } = useStore();
  const { assignDeals, getPartnersDelegatedDeals, cancelInvitation } = PartnerController();

  const changeAssignedDeals = async (deals: LinkDtoWithAssignedDeal[], partner: PartnerDto) => {
    if (partner) {
      const dealList = deals
        .filter((deal) => deal.assigned)
        .map((deal) => {
          return { id: deal.id };
        });

      assignDeals(partner, {
        deals: dealList,
      });
    }
  };
  const handleClickCancelInvite = (member: PartnerDto) => {
    uiStore.confirm.show({
      message: '보낸 초대를 무효화 하시겠습니까?\n전송된 메일은 회수되지 않습니다.',
      onConfirmed: async () => {
        await cancelInvitation(member?.id as number);
      },
    });
  };
  const handleEditDeals = async (member: PartnerDto) => {
    let deals: LinkDto[] | undefined = [];
    if (typeof member?.id !== 'undefined') {
      deals = (await getPartnersDelegatedDeals(member?.id)) as unknown as LinkDto[];
    }
    uiStore.bottomSheet.show({
      title: '위임 공구',
      style: {
        minHeight: '85vh',
      },
      children: (
        <AssignedDealsList
          dealList={deals as LinkDto[]}
          onSelected={(deals: LinkDto[]) => {
            changeAssignedDeals(deals, member);
          }}
        />
      ),
    });
  };
  const handleEditPartnerInfo = (member: PartnerDto) => {
    uiStore.modal.show({
      title: '파트너 설정',
      style: {
        minHeight: '85%',
      },
      children: <PartnerSettingsContainer memberInfo={member as PartnerDto} />,
      onClose: () => {
        onRefresh();
      },
    });
  };

  const handleViewManagerInfo = async (manager?: MemberDto) => {
    const newModel = { ...manager, username: manager?.memberUser?.username };
    uiStore.modal.show({
      title: '매니저 설정',
      style: {
        minHeight: '85%',
      },
      children: <MemberCreateContainer type="view" model={newModel} managerId={manager?.id} />,
      onClose: () => {
        alert();
      },
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      {partners &&
        partners?.map((member, index) => (
          <Wrapper type="partner" key={index}>
            <Row style={{ width: 'unset' }}>
              <div>
                <Avatar
                  style={{ width: 60, height: 60 }}
                  src={member?.partnerChannel?.profile?.profileImage?.publicUrl}
                />
              </div>
              <TextWrapper>
                <PartnerNameStyle>
                  {member?.partnerChannel?.channelName
                    ? member?.partnerChannel?.channelName
                    : member?.email}
                </PartnerNameStyle>
                <PartnerDetailStyle>{member?.alias}</PartnerDetailStyle>
              </TextWrapper>
            </Row>

            {acceptedPartner === true ? (
              <>
                <CancelInvitationBtn
                  onClick={() => {
                    handleClickCancelInvite(member);
                  }}
                >
                  초대 취소
                </CancelInvitationBtn>
              </>
            ) : (
              <>
                <AssignDealsBtn
                  onClick={() => handleEditDeals(member)}
                  url="/images/add-button.svg"
                />
                <EditPartnerInfoBtn
                  onClick={() => handleEditPartnerInfo(member)}
                  url="/images/edit-button.svg"
                />
              </>
            )}
          </Wrapper>
        ))}
      {managers &&
        managers?.map((manager, index) => (
          <Wrapper type="manager" key={index} onClick={() => handleViewManagerInfo(manager)}>
            <div>
              <Avatar style={{ width: 60, height: 60 }} />
            </div>
            <TextWrapper>
              <PartnerNameStyle>{manager?.memberUser?.username}</PartnerNameStyle>
              <PartnerDetailStyle>{manager?.company}</PartnerDetailStyle>
            </TextWrapper>
          </Wrapper>
        ))}
    </div>
  );
};

const Wrapper = styled.div<{ type?: 'manager' | 'partner' }>`
  display: flex;
  width: 100%;
  justify-content: ${({ type }) => (type === 'partner' ? 'space-between' : 'flex-start')};
  align-items: center;
]`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding: 1px;
  margin-left: 10px;
`;

const PartnerNameStyle = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-size: 0.8rem;
`;

const PartnerDetailStyle = styled.div`
  border-radius: 0.625rem;
  background: #90a7ec;
  color: #ffffff;
  font-size: 0.6875rem;
  font-style: normal;
  padding: 2px 10px;
  font-weight: 500;
`;

const AssignDealsBtn = styled.div<{ url?: string }>`
  cursor: pointer;
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  right: 50px;
  margin-top: 10px;
`;

const EditPartnerInfoBtn = styled.div<{ url?: string }>`
  cursor: pointer;
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  right: 20px;
  margin-top: 10px;
`;

const CancelInvitationBtn = styled.div`
  background-color: #173300;
  cursor: pointer;
  border-radius: 0.5rem;
  color: #96e06e;
  font-size: 0.625rem;
  padding: 0.4rem 0.7rem;
`;

export default PartnersManagersList;
