import { useGlobalContext } from 'context/useGlobalContext';
import useNavigation from 'hooks/useNavigation';
import useChannelSelector from 'libs/hooks/useChannelSelector';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useSearchParams } from 'react-router-dom';

const LoginPageController = () => {
  const [searchParams] = useSearchParams();
  const { navigate } = useNavigation();
  const { setLogin, login } = useGlobalContext();
  const { initializeFirstChannel } = useChannelSelector();
  const { myChannel } = useRootContext();

  const doLogin = async () => {
    await initializeFirstChannel();
    setLogin(true);
    await login();
  };

  const redirect = () => {
    const state = searchParams.get('action');
    const redirect = localStorage.getItem('redirectUrl');
    if (state) {
      const t2 = state.replace('(', '').replace(')', '').split('.');
      if (state) {
        if (t2.length > 1) {
          navigate(`${t2[0]}?action=${t2[1]}`);
        } else {
          navigate(`${t2[0]}`);
        }
      }
    } else if (redirect !== null) {
      const temp = JSON.parse(redirect);
      localStorage.removeItem('redirectUrl');
      setTimeout(() => {
        navigate(temp.path);
      }, 300);
    } else {
      if (myChannel?.channelType === 'MEMBER') {
        navigate(`/cstown`);
      } else {
        navigate(`/my`);
      }
    }
  };

  return {
    redirect,
    doLogin,
  };
};
export default LoginPageController;
