import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';

import GnbAdmin2 from 'features/commonFeatures/navigationBar/containers/AdminLnb';
import MenuTitleStyle from 'resources/controls/MenuTitleStyle';

import LinkEditorStyle from 'resources/controls/CommonButtonsStyle';
import Tags from 'features/proFeatures/tag/containers/Tags';
import CreateTag from 'features/proFeatures/tag/containers/CreateTag';
import { TagDto } from 'services/data-contracts';

const TagHome = observer(() => {
  const GNB_MAX_WIDTH = 150;

  const [selectedTag, setSelectedTag] = useState<TagDto>();

  const handleClickCreate = () => {
    setSelectedTag(undefined);
  };

  return (
    <AdminStyle showMenu isMobile={false} mobileMode={false} gnbWidth={GNB_MAX_WIDTH}>
      <GnbContainerStyle isMobile={false} showMenu maxWidth={GNB_MAX_WIDTH}>
        <GnbAdmin2 />
      </GnbContainerStyle>
      <ContainerStyle showSimulator={false} showMenu isMobile={false} gnbWidth={GNB_MAX_WIDTH}>
        <LeftSectionStyle>
          <MenuTitleStyle>TAG</MenuTitleStyle>
          <LinkEditorStyle
            onClick={() => {
              handleClickCreate();
            }}
          >
            <div>Create New Tag</div>
          </LinkEditorStyle>
          <div style={{ padding: 20 }}>
            <Tags
              onSelectTag={(tag: TagDto) => {
                setSelectedTag(tag);
              }}
              // onDeletedTag={() => {
              //   setSelectedTag(undefined);
              // }}
            />
          </div>
        </LeftSectionStyle>
        <RightSectionStyle>
          <CreateTag
            tag={selectedTag}
            onUnselected={() => {
              setSelectedTag(undefined);
            }}
          />
        </RightSectionStyle>
      </ContainerStyle>
    </AdminStyle>
  );
});

const AdminStyle = styled.div<{
  showMenu?: boolean;
  isMobile?: boolean;
  mobileMode?: boolean;
  gnbWidth: number;
}>`
  padding-left: ${(props) => (props.showMenu || props.mobileMode ? '0' : `${props.gnbWidth}px`)};
  background-color: #eeeeee;
  ${(props) =>
    props.isMobile &&
    css`
      padding-left: 0;
      background-color: white;
    `}
  ${(props) =>
    props.mobileMode
      ? css`
          flex-direction: column;
          height: 100%;
        `
      : css`
          flex-direction: row;
          height: 100vh;
        `}
    @media screen and (max-width: 1040px) {
    ${(props) =>
      !props.showMenu &&
      css`
        height: 100vh;
      `}
  }
  @media screen and (max-width: 740px) {
    padding-left: 0;
    ${(props) =>
      props.isMobile &&
      props.showMenu &&
      css`
        height: 100%;
      `}
  }
`;

const GnbContainerStyle = styled.div<{ isMobile?: boolean; showMenu?: boolean; maxWidth: number }>`
  z-index: 1000;
  position: absolute;
  left: 0;
  background: white;
  ${(props) =>
    props.isMobile &&
    !props.showMenu &&
    css`
      z-index: 0;
    `}
  > div {
    display: flex;
    flex-direction: column;
    max-width: ${(props) => props.maxWidth}px;
    height: 100%;
    background: white;
    z-index: 100;
    @media screen and (max-width: 740px) {
      background: white;
      z-index: 100;
      height: 70px;
      flex-direction: row;
      max-width: unset;
    }
  }
`;

const ContainerStyle = styled.div<{
  showSimulator?: boolean;
  showMenu?: boolean;
  isMobile?: boolean;
  gnbWidth: number;
}>`
  background: ${(props) => (props.isMobile ? 'transparent' : '#fff')};
  overflow: auto;
  display: flex;
  position: absolute;
  /* background-color: white; */
  /* z-index: 100; */
  /* max-width: 600px; */
  left: ${(props) => (props.showMenu ? `${props.gnbWidth}px` : '-550px')};
  transition: left 0.5s ease-in-out;
  width: calc(100% - 150px);
  height: 100%;
  overflow: scroll;
  @media screen and (max-width: 890px) {
    /* width: calc(100% - 150px); */
    max-width: 890px;
  }

  @media screen and (max-width: 740px) {
    left: 0;
    width: 100%;
    margin-top: 50px;
    padding-left: 0;
    ${(props) =>
      props.isMobile &&
      css`
        transition: left 0.5s ease-in-out;
        transition: none;
        margin-top: 100px;
      `}
    ${(props) =>
      props.showMenu
        ? css`
            left: 0;
          `
        : css`
            left: -890px;
          `}
  }
  > div {
    ${(props) =>
      props.isMobile &&
      css`
        /* max-width: 360px; */
      `}
  }
`;

const LeftSectionStyle = styled.div`
  width: 600px;
`;

const RightSectionStyle = styled.div`
  margin-top: 120px;
  margin-left: 40px;
  width: 600px;
`;

export default TagHome;
