import React from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import useNavigation from 'hooks/useNavigation';
import TopWrapper from 'features/mainFeatures/auth/components/TopWrapper';
import TopWrapperLeft from 'features/mainFeatures/auth/components/TopWrapperLeft';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { OhmossButton } from 'resources/controls/OhmossButton';

const EmailAuthPage = observer(() => {
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const handleClick = () => {
    navigate('/login');
  };
  return (
    <div>
      <EmailAuthContainer>
        <TopWrapper style={{ width: '100vw' }}>
          <TopWrapperLeft>
            <ArrowBackIcon
              style={{
                color: '#222222',
                paddingRight: '16px',
                width: '18px',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/sign-up');
              }}
            />
            <div>{t('signUp')}</div>
          </TopWrapperLeft>
        </TopWrapper>
        <EmailImg />
        <div>
          <Title>{t('verificationEmailAddressYouEnter')}</Title>
          <Text>
            {t('checkYourSelectVerify')} <br />
            {t('youNotVerify24HoursSignUpAccountDelete')}
          </Text>
        </div>
        <GrayBox>
          <p>{t('checkNotMyEmail')}</p>
          <ul>
            <li>{t('pleaseCheckSpamYourEmail')}</li>
            <li>{t('emailSpamCheckNotContactAdministrator')}</li>
            <p>{t('email')} : cs@ohmoss.com</p>
          </ul>
        </GrayBox>
        <OhmossButton style={{ padding: '15px 60px', margin: '30px 0' }} onClick={handleClick}>
          로그인 페이지로
        </OhmossButton>
      </EmailAuthContainer>
    </div>
  );
});

const textCenter = css`
  text-align: center;
`;

const grayText = css`
  color: #666666;
  font-weight: 500;
  font-size: 14px;
`;

const EmailAuthContainer = styled.div`
  width: 100%;
  /* height: 100%; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  /* margin-top: 2em; */
  z-index: 100;
  background: white;
`;

const Title = styled.div`
  ${textCenter}
  color: #000;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 1em;
  margin-top: 1.5em;
`;

const Text = styled.div`
  ${textCenter}
  font-size: 14px;
  margin-bottom: 2em;
  @media screen and (max-width: 360px) {
    padding: 0 20px;
  }
`;

const GrayBox = styled.div`
  background: #f6f6f6;
  /* margin-top: 1em;
  margin: 0 auto; */
  margin: 1em 1em 0 1em;
  padding: 0.5em 2em;
  ${grayText}
  > ul {
    padding-inline-start: 2em;
    margin-right: 1em;
    margin-top: 0;
    color: #999999;
    font-weight: 400;
    line-height: 23px;
  }
  > ul > p {
    text-align: right;
    padding-top: 0;
  }
`;

const Img = styled.img.attrs((props) => ({
  alt: '',
}))``;

const EmailImg = styled(Img).attrs((props) => ({
  src: 'images/ohmoss_logo.svg',
}))`
  width: 200px;
  height: 110px;
  margin-top: 8em;
`;

export default EmailAuthPage;
