import React from 'react';
import styled from 'styled-components';
import { useRootContext } from 'libs/hooks/useRootContext';
import { Divider } from '../components/CommonStyleComponent';
import Text from 'components/commons/Text';
import { Row } from 'components/commons/layouts';

const InvoiceContainer = ({
  membership,
  time,
  isGift,
}: {
  membership?: any;
  time?: any;
  isGift?: boolean;
}) => {
  const { myChannel } = useRootContext();
  const numberWithCommas = (price: number) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const getDiscountPrice = (membership: string, time: number) => {
    if (membership === 'PRO') {
      let price = 39000;
      if (time === 1) {
        return numberWithCommas(price);
      } else if (time === 6) {
        price = 39000 * 6 * 0.9;
        return numberWithCommas(price);
      } else if (time === 12) {
        price = 39000 * 12 * 0.8;
        return numberWithCommas(price);
      }
    }

    if (membership === 'PREMIUM') {
      let price = 99000;
      if (time === 1) {
        return numberWithCommas(price);
      } else if (time === 6) {
        price = 99000 * 6 * 0.9;
        return numberWithCommas(price);
      } else if (time === 12) {
        price = 99000 * 12 * 0.8;
        return numberWithCommas(price);
      }
    }
  };

  const getDiscountRate = (time: number) => {
    switch (time) {
      case 1:
        return '';
      case 6:
        return 10;
      case 12:
        return 20;
      default:
        break;
    }
  };

  const getOriginalPrice = (membership: string, time: number) => {
    if (membership === 'PRO') {
      if (time === 1) {
        return numberWithCommas(39000);
      } else if (time === 6) {
        return numberWithCommas(39000 * 6);
      } else if (time === 12) {
        return numberWithCommas(39000 * 12);
      }
    }
    if (membership === 'PREMIUM') {
      if (time === 1) {
        return numberWithCommas(99000);
      } else if (time === 6) {
        return numberWithCommas(99000 * 6);
      } else if (time === 12) {
        return numberWithCommas(99000 * 12);
      }
    }
  };

  const getMembershipPrice = (membership: string) => {
    if (membership === 'PRO') {
      return numberWithCommas(39000);
    }
    if (membership === 'PREMIUM') {
      return numberWithCommas(99000);
    }
  };

  return (
    <MainContainer>
      <Title>인보이스</Title>
      <InvoiceBox>
        <InnerBox>
          <InvoiceItem>
            결제멤버십 <span style={{ marginRight: 10 }} />
            <Text type="H4" style={{ color: 'rebeccapurple' }}>
              {myChannel?.channelType === 'SELLER'
                ? '셀러'
                : myChannel?.channelType === 'BRAND' && isGift === false
                ? '브랜드'
                : '셀러'}
              {membership}
            </Text>
          </InvoiceItem>
          <InvoiceItem>월 {getMembershipPrice(membership)}원</InvoiceItem>
        </InnerBox>
        <InnerBox>
          <InvoiceItem>
            결제방식
            <span style={{ marginRight: 10 }} />
            <Text type="H4" style={{ color: 'rebeccapurple' }}>
              {time}개월 결제
            </Text>
          </InvoiceItem>
          {time !== 1 ? (
            <InvoiceItem style={{ color: 'red' }}>{getDiscountRate(time)}% 할인</InvoiceItem>
          ) : (
            <></>
          )}
        </InnerBox>
        <Divider style={{ margin: 0 }} />
        <InnerBox>
          <InvoiceItem style={{ textAlign: 'center' }}>총 결제 금액(VAT 포함)</InvoiceItem>
          <InvoiceItem>
            {time === 1 ? (
              <>
                <span
                  style={{
                    fontSize: 14,
                    textDecoration: 'none',
                  }}
                >
                  &nbsp;{getOriginalPrice(membership, time)}
                </span>
                원
              </>
            ) : (
              <>
                <Row style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
                  <div
                    style={{
                      fontSize: 14,
                      textDecoration: 'line-through',
                    }}
                  >
                    <DiscountedPrice style={{ marginLeft: 20 }}>
                      {getOriginalPrice(membership, time)}원
                    </DiscountedPrice>
                  </div>
                  <Text type="H3" style={{ marginLeft: 20 }}>
                    {getDiscountPrice(membership, time)} 원
                  </Text>
                </Row>
              </>
            )}
          </InvoiceItem>
        </InnerBox>
      </InvoiceBox>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px 10px 0px;
  gap: 10px;
`;

const Title = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
  font-weight: 400;
`;

const InvoiceBox = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 1.5rem;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 0.875rem;
  border: 0.5px solid #cbcbcb;
  background: #fff;
  justify-content: space-around;
`;

const InnerBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
`;

const InvoiceItem = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  display: flex;
`;

const DiscountedPrice = styled.span`
  color: red;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none !important;
`;

export default InvoiceContainer;
