import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const EmptyPage = observer(({ content }: { content: string }) => {
  return (
    <>
      <NoBoardStyle>
        <div>
          <div style={{ marginBottom: 20 }}>
            <img src="/images/charactor.svg" style={{ width: 120, margin: 'auto' }} />
          </div>
          <div style={{ color: 'rgba(23, 51, 0, 0.30)' }}>{content}</div>
        </div>
      </NoBoardStyle>
    </>
  );
});

const NoBoardStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: calc(100vh - 180px); */
  height: 100%;
`;

export default EmptyPage;
