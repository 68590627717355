import React, { useEffect, useRef, useState } from 'react';
import useToken from 'libs/hooks/useToken';

import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { ChannelBoardModel, MyStyle } from 'types/CommonTypes';
import { BackgroundLayerStyle } from 'features/mainFeatures/main/components/BackgroundComponent';
import { LinkDto } from 'services/data-contracts';

import UserNotFound from 'pages/UserNotFound';
import styled from 'styled-components';
import TemplateMaster from 'features/adminFeatures/template/containers/TemplateMaster';
import GnbComponent from 'features/commonFeatures/navigationBar/containers/GnbComponent';
import PublicMenu from 'features/commonFeatures/navigationBar/containers/PublicMenu';
import SignInMenu from 'features/commonFeatures/navigationBar/containers/SignInMenu';
import GnbHeader from 'features/commonFeatures/navigationBar/containers/GnbHeader';
import useLog from 'libs/hooks/useLog';
import NavigationTabBar from 'components/commons/NavigationTabBar';
import BackgroundImageComponent from 'features/commonFeatures/components/BackgroundImageComponent';
import StyleController from 'controllers/StyleController';
import useLocalParams from 'libs/hooks/useLocalParams';
import LinkController from 'controllers/LinkController';
import LinkPageProfileBlocks from '../components/LinkPageProfileBlocks';
import { useChannelContext } from '../context/useChannelContext';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useSearchParams } from 'react-router-dom';

const LinkServiceContainer = observer(() => {
  const [searchParam] = useSearchParams();

  const gnbRef = useRef<any>(null);
  const { writeLog } = useLog();
  const { uiStores, postStore, linkStore, uiLinkStore } = useStore();
  const { currentChannel, categoryBoard, setLinks } = useChannelContext();
  const { getLinks, getPosts, getClosedDeals } = LinkController();
  const { getMyStyle } = StyleController();
  const { validUser } = useToken();
  const { channelName } = useLocalParams();
  const { myChannel } = useRootContext();

  const [isNotFound, setIsNotFound] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const isPreview = searchParam.get('preview');

  /**
   * @description 채널 Config설정
   */
  const initChannelConfig = () => {
    if (currentChannel?.channelConfig) {
      uiStores.userHomeStore.setChannelConfig(currentChannel?.channelConfig);
    } else {
      uiStores.userHomeStore.setChannelConfig({
        isChannelPrivate: true,
        metadataObject: { profileType: 'simple' },
      });
    }
  };

  const getMyStyles = async () => {
    if (channelName) {
      const style = (await getMyStyle(channelName as string)) as unknown as MyStyle;
      if (style) {
        uiLinkStore.setMyStyle(style);
      }
    }
  };

  const handleClick = () => {
    gnbRef.current.close();
  };

  const getCategoryLinks = async () => {
    if (categoryBoard) {
      const castedBoard = categoryBoard as ChannelBoardModel;
      if (castedBoard.boardType === 'LINK') {
        if (castedBoard?.isDeal) {
          const links = await getClosedDeals();
          setLinks(links as LinkDto[]);
        } else {
          const links = await getLinks(categoryBoard);
          setLinks(links as LinkDto[]);
        }
      } else {
        const posts = await getPosts(categoryBoard);
        // setPosts(posts as PostDto[]);
      }
    }
  };

  useEffect(() => {
    if (channelName) {
      getMyStyles();
      document.title = `${channelName} - oh!moss`;

      if (gnbRef.current) {
        gnbRef.current.close();
      }
    }
    return () => {
      postStore.unMount();
      linkStore.unMount();
    };
  }, [channelName]);

  useEffect(() => {
    if (currentChannel) {
      // getChannelBoardInfo(currentChannel.id as number);
      writeLog();
      initChannelConfig();
      setIsNotFound(false);
    } else if (currentChannel === null) {
      setIsNotFound(true);
    }
  }, [currentChannel]);

  useEffect(() => {
    setLoaded(true);
    getCategoryLinks();

    return () => {
      postStore.unMount();
    };
  }, [categoryBoard]);

  return (
    <div>
      <BackgroundImageComponent profile={currentChannel?.profile} useEmpty={false} />
      <BackgroundLayerStyle zIndex={0} style={{ opacity: 0 }} />

      {validUser() && !isPreview && (
        <>
          <GnbComponent defaultComponent={<GnbHeader />} ref={gnbRef} position="bottom">
            {validUser() ? <SignInMenu onClick={handleClick} /> : <PublicMenu />}
          </GnbComponent>
        </>
      )}
      <ComponentStyle isWebView={window.isWebView} loggedIn={validUser() && !isPreview}>
        <>{myChannel?.channelName && <div style={{ height: 10 }}></div>}</>
        {currentChannel && (
          <div style={{ padding: '0', position: 'relative' }}>
            <LinkPageProfileBlocks />
          </div>
        )}
        {typeof currentChannel !== 'undefined' && <TemplateMaster />}

        <LoaderStyle loaded={loaded}></LoaderStyle>
      </ComponentStyle>
      {!window.isWebView && validUser() && !isPreview && (
        <div style={{ position: 'relative', zIndex: 1 }}>
          <NavigationTabBar type="link" board={categoryBoard} />
        </div>
      )}
      {isNotFound === true && <UserNotFound />}
    </div>
  );
});

const ComponentStyle = styled.div<{ isWebView?: boolean; loggedIn?: boolean }>`
  margin: auto;
  /* height: calc(100vh - 80px); */
  display: flex;
  flex-direction: column;
  @media ${(props) => props.theme.media.mobile} {
    padding-top: ${(props) => (props.loggedIn ? '60px' : 0)};
  }
  /* background: #f5f5f5; */
`;

const NoBoardFoundStyle = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoaderStyle = styled.div<{ loaded?: boolean }>`
  opacity: ${(props) => (props.loaded ? 1 : 0)};
  flex-grow: 1;
`;

export default LinkServiceContainer;
