import styled from 'styled-components';

/**
 * @description 이용약관 (서비스, 개인정보, 위치기반) 내용 padding
 */
const PaddingLayout = styled.div`
  padding: 0 0.8em 4em 0;
  margin-top: 1em;
`;

export default PaddingLayout;
