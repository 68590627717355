import React, { useEffect, useState } from 'react';
import { LinkDto } from 'services/data-contracts';

import ManagementLayout from 'features/management/components/common/ManagementLayout';
import CommonTabButtons from 'components/commons/buttons/CommonTabButtons';
import NoContent from 'components/commons/NoContent';
import CSTownController from 'controllers/CSTownController';
import { isMobile } from 'react-device-detect';
import PCDealList from 'features/csTown/components/pc/DealList';
import {
  ListBoxContentsTemplate,
  ListBoxTemplate,
  ListBoxWrapper,
} from 'features/csTown/commonComponents/styles';
import { useRootContext } from 'libs/hooks/useRootContext';
import DealList from '../containers/DealList';
import CSTownDealInquiriesPage from './CSTownDealInquiriesPage';

const DelegatedDealsPage = () => {
  const { myChannel } = useRootContext();

  const { deals, Brand, Member, Seller } = CSTownController();

  const [filteredDeals, setFilteredDeals] = useState<LinkDto[]>();
  const [tab, setTab] = useState<string>('진행중 공구');
  const [userType, setUserType] = useState<string>('');

  const getDeals = async () => {
    switch (myChannel?.channelType) {
      case 'SELLER':
        Seller.DEAL.getDeals();
        break;
      case 'BRAND':
        Brand.DEAL.getDelegatedDeals();
        break;
      case 'MEMBER':
        Member.DEAL.getDeals();
        break;
      default:
        break;
    }
  };

  const setDealsByType = async () => {
    if (tab === '진행중 공구') {
      const t = deals?.filter((deal: LinkDto) => deal.status === 'ONGOING');
      setFilteredDeals(t);
    } else {
      const t = deals?.filter((deal: LinkDto) => deal.status !== 'ONGOING');
      setFilteredDeals(t);
    }
  };

  useEffect(() => {
    if (deals) {
      setDealsByType();
    }
  }, [deals]);

  useEffect(() => {
    if (myChannel) {
      getDeals();
    }
  }, [tab, myChannel]);

  return (
    <>
      {isMobile ? (
        <ManagementLayout style={{ padding: 0 }}>
          <div>
            <CommonTabButtons items={['진행중 공구', '지난 공구']} onHandleTabClick={setTab} />
            {filteredDeals?.length === 0 && <NoContent title={`${tab}가`} />}
            <DealList deals={filteredDeals} />
          </div>
        </ManagementLayout>
      ) : (
        <ListBoxWrapper>
          <ListBoxTemplate style={{ borderRight: '1px solid #efefef' }}>
            <div style={{ width: '100%' }}>
              <CommonTabButtons items={['진행중 공구', '지난 공구']} onHandleTabClick={setTab} />
              {filteredDeals?.length === 0 && <NoContent title={`${tab}가`} />}
              <PCDealList
                deals={filteredDeals}
                // onClickDeal={(deal) => {
                //   handleClickDeal(deal);
                // }}
              />
            </div>
          </ListBoxTemplate>
          <ListBoxContentsTemplate>
            <CSTownDealInquiriesPage />
          </ListBoxContentsTemplate>
        </ListBoxWrapper>
      )}
    </>
  );
};

export default DelegatedDealsPage;
