import React, { useEffect, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@mui/material';
import { useStore } from 'stores/StoreHelper';
import { ChannelDto, PartnerDto } from 'services/data-contracts';
import styled, { css } from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';
import { useRootContext } from 'libs/hooks/useRootContext';
import ChannelComponent from 'features/commonFeatures/components/ChannelComponent';
import { isEmpty, isValid } from 'libs/helper/utils';

const BrandSearchBox = observer(
  ({
    onFixSearchBox,
    onChangeValue,
    memberInfo,
  }: {
    onFixSearchBox: (channel: ChannelDto | undefined) => void;
    onChangeValue?: (channelName?: string) => void;
    memberInfo?: PartnerDto;
  }) => {
    const { channelStore } = useStore();
    const { myChannel } = useRootContext();

    const [selectedChannel, setSelectedChannel] = useState<ChannelDto | undefined>();

    const handleSearch = async (e: any) => {
      setSelectedChannel(undefined);
      if(!isEmpty(e.target.value.trim())){
        onFixSearchBox(undefined);
        channelStore.unMount();
      } else {
        await channelStore.searchChannels(e.target.value, myChannel?.id as number, {channelType:'SELLER'});
        onChangeValue?.(e.target.value.trim());
      }
    };

    const handleClickChannel = (channel: ChannelDto) => {
      setSelectedChannel(channel);
      onFixSearchBox(channel);
      channelStore.unMount();
    };

    useEffect(() => {
      return () => {
        channelStore.unMount();
      };
    }, []);

    return (
      <ChannelBoxStyle>
        <TextField
          id="outlined-basic"
          onChange={handleSearch}
          value={selectedChannel?.channelName}
          InputProps={{
            endAdornment: (
              <>
                {selectedChannel && (
                  <CheckIcon
                    style={{ color: 'green', width: '20px', height: '20px', marginRight: '10px' }}
                  />
                )}
                <img
                  src="/images/search_grey.svg"
                  alt="search"
                  style={{ width: '20px', height: '20px' }}
                />
              </>
            ),
          }}
          style={{ width: '100%' }}
        />
        {
          channelStore.channels && channelStore.channels?.length > 0 ? (
            channelStore.channels?.map((channel: ChannelDto) => (
              <ChannelComponent channel={channel} onSelected={handleClickChannel} mode="view" />
            ))
          ) :
          <></>
        }
      </ChannelBoxStyle>
    );
  },
);

const ChannelBoxStyle = styled.div`
  width: 100%;
`;

export default BrandSearchBox;
