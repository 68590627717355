import React, { useEffect } from 'react';
import { useRootContext } from 'libs/hooks/useRootContext';
import { isMobile } from 'react-device-detect';
import { useStateContext } from 'libs/hooks/usePageState';
import * as Containers from '../containers';
import CSTownChecklistController from './pageControllers/CSTownChecklistController';
import { CSTownChecklistState } from 'types/CommonTypes';

function CSTownChecklistPage() {
  const { receiver, setMultipleState } = useStateContext<CSTownChecklistState>();
  const { getChecklists, unmountAll } = CSTownChecklistController();
  const { myChannel } = useRootContext();

  useEffect(() => {
    if (
      (myChannel && myChannel?.membershipType === 'PREMIUM') ||
      myChannel?.membershipType === 'PRO'
    ) {
      getChecklists();
    }
  }, [myChannel]);

  useEffect(() => {
    if (receiver?.id === 'reload') {
      getChecklists();
    }
  }, [receiver]);

  useEffect(() => {
    setMultipleState({ selectedChecklist: undefined, selectedTopic: undefined, deal: null });
    return () => {
      unmountAll();
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <div style={{ padding: '10px 20px' }}>
          <Containers.MobileContainers.MobileCSTownChecklistContainer />
        </div>
      ) : (
        <Containers.PCContainers.CSTownChecklistContainer />
      )}
    </>
  );
}

export default CSTownChecklistPage;
