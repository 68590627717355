import PartnerController from 'controllers/PartnerController';
import chatController from 'features/community/controllers/chatController';
import sellerCSController from 'features/csTown/controllers/sellerCSController';
import { useStateContext } from 'libs/hooks/usePageState';
import { MemberDto, PartnerDto, SimpleDealDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import { CSTownPartnerState, LinkDtoWithAssignedDeal } from 'types/CommonTypes';

const CSTownPartnersController = () => {
  const { uiStore } = useStore();
  const { setPageState, setMultipleState, broadcast } = useStateContext<CSTownPartnerState>();
  const { inviteOrDeleteUser } = chatController();
  const { getPartners, cancelInvitation, deletePartner, getPartnerInvitations, assignDeals } =
    PartnerController();
  const { banChatChannels } = sellerCSController();

  const getPartnersList = async () => {
    const result = await getPartners();
    if (result) {
      setMultipleState({ partnerList: result, originalPartners: result });
    }
  };

  const cancelPartnerInvitation = (listType: 'PARTNER' | 'INVITATION', partner: PartnerDto) => {
    const message =
      listType === 'INVITATION'
        ? '초대중인 파트너를 삭제하시겠습니까?'
        : '해당 파트너를 삭제하시겠습니까? \r\n 관리중인 CS부터도 위임 해제됩니다.';
    uiStore.confirm.show({
      message,
      onConfirmed: async () => {
        let result;
        if (listType === 'INVITATION') {
          result = (await cancelInvitation(partner?.id as number)) as any;
        } else {
          banChatChannels(partner as PartnerDto);
          result = (await deletePartner(partner)) as any;
        }

        if (result) {
          broadcast({ id: 'reload' });
        }
      },
    });
  };

  const changeAssignedDeals = async (deals: LinkDtoWithAssignedDeal[], partner?: PartnerDto) => {
    if (partner) {
      const idExtractedDeal = deals
        .filter((item) => item.assigned)
        .map((item) => ({ id: item.id }));
      const dealsObj = { deals: idExtractedDeal as SimpleDealDto[] };
      await assignDeals(partner, dealsObj);
      inviteOrDeleteUser(deals, [partner?.partnerChannel?.channelName as string]);
    } else {
      return deals.filter((deal) => deal.assigned) as LinkDtoWithAssignedDeal[];
    }
  };

  const getInvitationsResult = async () => {
    const result = (await getPartnerInvitations()) as unknown as MemberDto[];
    if (result) {
      setPageState('invitationList', result);
    }
  };

  return {
    getPartnersList,
    cancelPartnerInvitation,
    getInvitationsResult,
    changeAssignedDeals,
  };
};

export default CSTownPartnersController;
