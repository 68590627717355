import React, { useEffect, useState } from 'react';

import { validationHelper as VH } from 'controls/validationHelper';
import { Avatar } from '@mui/material';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import { SocialSignUpDto, TermDto } from 'services/data-contracts';
import useNavigation from 'hooks/useNavigation';

import useGoogleLogin from 'libs/hooks/useGoogleLogin';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import CommonSignUpForm from './CommonSignUpForm';
import ChannelTypeSelector from './ChannelTypeSelector';
import LoginPageController from 'pages/pageControllers/LoginPageController';
import { useGlobalContext } from 'context/useGlobalContext';

interface SignUpModel extends SocialSignUpDto {
  termsValid?: boolean;
}

const GoogleSignUpForm = observer(() => {
  const { navigate } = useNavigation();
  const { redirect, doLogin } = LoginPageController();
  const { login } = useGlobalContext();
  const { uiStore } = useStore();
  const { userInfo, checkSignup } = useGoogleLogin();

  const [model, setModel] = useState<SignUpModel>();
  const [isValid, setIsValid] = useState<boolean>(false);

  const { termStore } = useStore();
  const { authStore } = useStore();

  const checkValidation = () => {
    const channelName = VH.alphanumericWithSymbols(model?.channelName);

    if (typeof model?.termsValid !== 'undefined') {
      setIsValid(channelName && model.termsValid);
    }
  };

  const handleClickSignUp = async () => {
    setIsValid(false);
    const agreement = termStore?.terms.map((term: TermDto) => ({
      termId: term.id,
      isAgree: true,
    }));
    if (model && isValid) {
      model.socialToken = userInfo.socialToken;
      model.profile = { nickname: userInfo.family_name + userInfo.given_name };
      const result = await authStore.googleSignup(model, agreement);
      if (result) {
        await doLogin();
        redirect();
      }
    }
  };

  useEffect(() => {
    if (model) {
      checkValidation();
    }
  }, [model]);

  const expiredSession = () => {
    uiStore.universalModal.show({
      title: '구글인증용 토큰이 만료되었습니다.',
      description: '페이지를 새로고침 하거나 오랜시간이 경과하면 발생합니다.',
      modalOption: { style: { maxWidth: 500, height: 400 } },
      useDefaultButtons: true,
      children: (
        <div>
          <br />
          회원가입 페이지에서 다시한번 <br />
          구글 회원가입 버튼을 클릭 해주세요.
        </div>
      ),
      onClose() {
        navigate('/sign-up');
      },
    });
  };

  useEffect(() => {
    if (typeof userInfo !== 'undefined') {
      if (userInfo === null) {
        expiredSession();
      } else {
        console.log(userInfo);
      }
    }
    console.log('##', userInfo);
  }, [userInfo]);

  useEffect(() => {
    // logout();
    const getToken = async () => {
      await termStore.getTerms();
      const token = authStore.getGoogleTokenResponse();
      if (token) {
        checkSignup(token);
      } else {
        expiredSession();
      }
    };
    getToken();
  }, []);

  return (
    <>
      <div style={{ margin: '30px 0' }}>구글계정으로 가입</div>
      <div style={{ height: 100 }}>
        {typeof userInfo !== 'undefined' && userInfo !== null && (
          <AvatarStyle>
            <Avatar style={{ margin: 'auto', width: 70, height: 70 }} src={userInfo.picture} />
            <div style={{ marginTop: 20 }}>
              {userInfo.family_name + userInfo.given_name}님 반갑습니다!
            </div>
          </AvatarStyle>
        )}
      </div>
      <ChannelTypeSelector
        onSelectedType={(type) => {
          setModel((prev) => {
            return { ...prev, channelType: type };
          });
        }}
      />
      <div>
        <CommonSignUpForm
          onValidChannelName={(channelName: string) => {
            setModel((prev) => {
              return { ...prev, channelName };
            });
          }}
          onTermsChanged={(termsValid: boolean) => {
            setModel((prev) => {
              return { ...prev, termsValid };
            });
          }}
        />
        <OhmossButton
          disabled={!isValid}
          style={{ minWidth: 300, height: 50 }}
          onClick={handleClickSignUp}
        >
          Sign up
        </OhmossButton>
      </div>
    </>
  );
});

const AvatarStyle = styled.div``;

export default GoogleSignUpForm;
