import React, { CSSProperties, useState } from 'react';

import { Row } from 'components/commons/layouts';
import { ImageUploader } from 'components/commons';
import { FileDto } from 'services/data-contracts';
import { OhmossButton, DeleteButtonStyle } from 'resources/controls/OhmossButton';
import { t } from 'i18next';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';
import { CustomFile } from 'types/CommonTypes';
import CustomAvatar from './CustomAvatar';

const ThumbnailUploader = ({
  url,
  useCrop = true,
  usePreview,
  label = t('Select image'),
  style,
  align,
  onImageChange,
}: {
  url?: string;
  useCrop?: boolean;
  usePreview?: boolean;
  label?: string;
  align?: 'left' | 'center';
  style?: CSSProperties;
  onImageChange: (image: FileDto | undefined) => void;
}) => {
  const [img, setImg] = useState<CustomFile>();
  return (
    <Row
      style={{
        margin: '10px',
        justifyContent: align === 'left' ? 'flex-start' : 'center',
      }}
    >
      <div style={{ marginLeft: 10 }}>
        <ImageUploader
          type="single"
          attachType="image"
          show
          useCrop={useCrop}
          // style={{ textAlign: 'left' }}
          cropType="square"
          cropSize={{ width: 1200, height: 1200 }}
          onSelected={(thumbnail: any) => {
            setImg(thumbnail);
            onImageChange(thumbnail);
          }}
        >
          <Row>
            <div style={{ marginRight: 10 }}>
              {usePreview !== false && <CustomAvatar url={url} />}
            </div>
            <OhmossButton style={{ padding: '10px 15px' }}>
              <CameraAltIcon style={{ marginRight: 10 }} />
              {label}
            </OhmossButton>
          </Row>
        </ImageUploader>
      </div>
      <div style={{ marginLeft: 10 }}>
        {typeof url !== 'undefined' && (
          <DeleteButtonStyle
            onClick={() => {
              onImageChange(undefined);
            }}
            variant="outlined"
            color="error"
            startIcon={<CloseIcon />}
            sx={{ borderRadius: 20 }}
          >
            {t('Delete')}
          </DeleteButtonStyle>
        )}
      </div>
      {/* <ImageEditor image={img} /> */}
    </Row>
  );
};

export default ThumbnailUploader;
