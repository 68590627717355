import React, { useState } from 'react';
import styled from 'styled-components';
import { FormControlLabel, Switch } from '@mui/material';
import { ChannelBoardDto } from 'services/data-contracts';
import { Row } from 'components/commons/layouts';
import { t } from 'i18next';

import TitleForm from 'components/items/forms/TitleForm';

const LinkBoard = ({
  model,
}: {
  model: [
    ChannelBoardDto | undefined,
    React.Dispatch<React.SetStateAction<ChannelBoardDto | undefined>>,
  ];
}) => {
  const boardModel = model[0];
  const dispatch = model[1];
  const [isActive, setIsActive] = useState<boolean | undefined>();
  const [useProfile, setUseProfile] = useState<boolean | undefined>();

  const handleChangeValue = (value: string) => {
    dispatch((prev) => {
      return { ...prev, name: value };
    });
  };

  React.useEffect(() => {
    if (!boardModel?.channel) {
      setIsActive(true);
      setUseProfile(true);
      dispatch({
        boardType: 'LINK',
        isActive: true,
        viewType: 'GRID',
        isCommentAllow: false,
        useProfile: true,
        seq: 1,
      });
    } else {
      boardModel.isCommentAllow = false;
      setIsActive(boardModel.isActive);
      setUseProfile(boardModel.useProfile);
      dispatch(boardModel);
    }
  }, [boardModel]);

  return (
    <>
      <TitleForm onChangeValue={handleChangeValue} model={boardModel?.name} label={t('A142')} />
      <Row style={{ justifyContent: 'space-evenly', alignItems: 'flex-start', marginTop: 30 }}>
        <FormControlLabel
          labelPlacement="bottom"
          control={
            <Switch
              color="secondary"
              checked={isActive}
              onChange={(event: never, value: boolean) => {
                setIsActive(value);
                dispatch((prev: ChannelBoardDto) => {
                  return { ...prev, isActive: value };
                });
              }}
            />
          }
          label={t('A143')}
        />
        <FormControlLabel
          labelPlacement="bottom"
          control={
            <Switch
              color="secondary"
              checked={useProfile}
              onChange={(event: never, value: boolean) => {
                setUseProfile(value);
                dispatch((prev: ChannelBoardDto) => {
                  return { ...prev, useProfile: value };
                });
              }}
            />
          }
          label={t('A144')}
        />
      </Row>
    </>
  );
};

export default LinkBoard;
