/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react';
import { GraySNS, ISNS, OriginSNS } from 'resources/images/sns';
import ImageSearchOutlined from '@mui/icons-material/ImageSearchOutlined';
import useNavigation from 'libs/hooks/useNavigate';

const SNSIconWrapper = ({
  name,
  useOrigin,
  iconType,
  color,
  width,
  linkUrl,
  openNewWindow,
}: {
  name?: string;
  useOrigin?: boolean;
  iconType?: 'origin' | 'solid';
  color?: string;
  width?: number;
  linkUrl?: string;
  openNewWindow?: boolean;
}) => {
  let Icon;
  const navigate = useNavigation();
  const handleOnClick = (linkUrl: string) => {
    navigate(linkUrl, openNewWindow ?? true);
  };

  if (useOrigin || iconType === 'origin') {
    Icon = OriginSNS.find((sns: ISNS) => sns.name === name);
  } else {
    Icon = GraySNS.find((sns: ISNS) => sns.name === name);
  }

  if (Icon) {
    if (typeof linkUrl !== 'undefined' && linkUrl !== null) {
      return (
        <>
          <Icon.component
            onClick={() => handleOnClick(linkUrl)}
            style={{
              width: width ?? 40,
              fill: color,
            }}
          />
        </>
      );
    } else {
      return (
        <>
          <Icon.component
            style={{
              width: width ?? 40,
              fill: color,
            }}
          />
        </>
      );
    }
  }

  return <ImageSearchOutlined />;
};
export default SNSIconWrapper;
