import React from 'react';
import { LinkDto, SocialLinkDto } from 'services/data-contracts';
import { IconButton, Switch } from '@mui/material';
import { Row } from 'components/commons/layouts';
import LinkContainerLayout from 'features/adminFeatures/link/components/LinkContainerLayout';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import CommonBox from 'resources/controls/CommonBox';

type defaultProps = {
  item?: LinkDto | SocialLinkDto;
  on: boolean;
  child: any;
  /** @description  view일때는 클릭/삭제만 가능 */
  onSelect?: (item?: LinkDto | SocialLinkDto) => void;
  onEdit?: (item?: LinkDto | SocialLinkDto) => void;
  onDelete?: (item?: LinkDto | SocialLinkDto) => void;
  onShowChange: (isShow: boolean) => void;
};

const LinkComponent = ({
  item,
  on,
  child,
  onSelect,
  onDelete,
  onEdit,
  onShowChange,
}: defaultProps) => {
  const label = { inputProps: { 'aria-label': 'is active' } };

  return (
    <CommonBox>
      <>
        <div style={{ marginLeft: 10 }}>
          <Switch
            size="small"
            {...label}
            defaultChecked={item?.isActive}
            color="secondary"
            onChange={(e: never, value: boolean) => {
              if (typeof onShowChange === 'function') {
                onShowChange(value);
              }
            }}
          />
        </div>
        <LinkContainerLayout on={on}>
          <LinkInfoContainerStyle
            onClick={() => {
              if (typeof onSelect === 'function') {
                onSelect(item);
              }
            }}
          >
            <TitleStyle>{child}</TitleStyle>
          </LinkInfoContainerStyle>
        </LinkContainerLayout>
        <ButtonContainerStyle>
          <IconButton
            onClick={() => {
              if (typeof onDelete !== 'undefined') {
                onDelete(item);
              }
            }}
          >
            <CloseIcon sx={{ fontSize: 16, opacity: 0.4 }} />
          </IconButton>
        </ButtonContainerStyle>
      </>
    </CommonBox>
  );
};

const LinkInfoContainerStyle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  /* border-radius: 50px; */
  cursor: pointer;
`;

const TitleStyle = styled.div`
  flex: 1;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ButtonContainerStyle = styled.div`
  flex-direction: row;
  align-items: center;
  width: 40px;
`;

const RowStyle = styled.div`
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.03);
  border: 2px solid #ebebeb;

  margin: 3px 0;
  border-radius: 10px;
  &:hover {
    background-color: #f0fff7;
  }
`;

export default LinkComponent;
