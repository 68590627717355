import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Row } from 'components/commons/layouts';
import FollowList from '../FollowList';
import { useRootContext } from 'libs/hooks/useRootContext';

const FollowListContainer = observer(() => {
  const { myChannel } = useRootContext();

  const [followType, setFollowType] = useState<'follow' | 'following'>('follow');

  return (
    <div style={{ width: '100%' }}>
      <Row style={{ gap: 20, fontSize: 16 }}>
        <div
          onClick={() => {
            setFollowType('follow');
          }}
        >
          <b>{myChannel?.followerCount}</b> 팔로워
        </div>
        <div
          onClick={() => {
            setFollowType('following');
          }}
        >
          <b>{myChannel?.followingCount}</b> 팔로잉
        </div>
      </Row>
      <FollowList type={followType} />
    </div>
  );
});

export default FollowListContainer;
