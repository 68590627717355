import React, { useEffect, useRef, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { LinkDto, PartnerInvitationCreationDto } from 'services/data-contracts';
import PartnerSettingsContainer from 'features/myPage/containers/PartnerSettingsContainer';

import { Row } from 'components/commons/layouts';

import { useRootContext } from 'libs/hooks/useRootContext';
import AssignedDealsList from 'features/myPage/containers/AssignedDealsList';
import MyDealStore from 'stores/MyDealStore';
import Text from 'components/commons/Text';
import StyleBox from 'components/commons/StyleBox';
import ActionBox from 'features/csTown/components/pc/ActionBox';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';
import PartnerController from 'controllers/PartnerController';
import { useStateContext } from 'libs/hooks/usePageState';

const NewPartnerContainer = () => {
  const myDealStore = new MyDealStore();
  const { createPartnerInvitation } = PartnerController();
  const { broadcast } = useStateContext();
  const { uiStore } = useStore();
  const { myChannel } = useRootContext();
  const selectedDeals = useRef<LinkDto[]>();
  const selectedModel = useRef<PartnerInvitationCreationDto>();

  const [myDeals, setMyDeals] = useState<LinkDto[]>();
  const [isValid, setIsValid] = useState<boolean>(false);

  const getMyDeals = async () => {
    const dealsTemp = (await myDealStore.getMyDeals(
      myChannel?.id as number,
    )) as unknown as LinkDto[];
    setMyDeals(dealsTemp);
  };

  const handleReady = (model: PartnerInvitationCreationDto) => {
    selectedModel.current = model;
    validate();
  };

  const handleSave = async () => {
    const newPartnerInvitation = {
      channelName: selectedModel?.current?.channelName,
      alias: selectedModel?.current?.alias,
      phoneNumber: selectedModel.current?.phoneNumber,
      deals: selectedDeals.current,
    } as PartnerInvitationCreationDto;

    const result = (await createPartnerInvitation(newPartnerInvitation)) as any;

    if (result?.status === 200) {
      uiStore.modal.close();

      broadcast({ id: 'reload', param: 'invitation' });
    }
  };

  const validate = () => {
    if (selectedModel.current && selectedDeals.current && selectedDeals.current?.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  useEffect(() => {
    getMyDeals();
  }, []);

  return (
    <>
      <Row style={{ alignItems: 'flex-start', padding: 20 }}>
        <div style={{ flex: 0.5 }}>
          <PartnerSettingsContainer
            showDeals={false}
            onReady={(model: PartnerInvitationCreationDto) => {
              handleReady(model);
            }}
          />
        </div>
        <StyleBox style={{ padding: 20, flex: 0.5 }}>
          <StyleBox style={{ marginLeft: 40 }}>
            <Text type="H4">내 공구목록</Text>
            <Text type="H4" buttonRole="DESCRIPTION">
              위임할 공구를 선택 해주세요.
            </Text>
          </StyleBox>
          <AssignedDealsList
            showButton={false}
            dealList={myDeals}
            onSelected={(deals: LinkDtoWithAssignedDeal[]) => {
              const temp = deals.filter((deal) => deal.assigned);
              selectedDeals.current = temp as LinkDto[];
              validate();
            }}
          />
          <div style={{ marginLeft: 20 }}>
            <ActionBox
              onOk={handleSave}
              isValid={isValid}
              onCancel={() => {
                uiStore.modal.close();
              }}
            />
          </div>
        </StyleBox>
      </Row>
    </>
  );
};

export default NewPartnerContainer;
