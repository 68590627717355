import PartnerController from 'controllers/PartnerController';
import chatController from 'features/community/controllers/chatController';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useState } from 'react';
import {
  DealInquiryDto,
  DealInquiryThreadDto,
  InquirySummaryDto,
  LinkDto,
  MessageDto,
  PartnerDto,
} from 'services/data-contracts';
import MyDealStore from 'stores/MyDealStore';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';

/**
 * @description 각 Block의 등록/수정/삭제를 수행함.
 * @returns
 */
const sellerCSController = () => {
  const myDealStore = new MyDealStore();
  const { getPartnerDeals1 } = PartnerController();
  const { inviteOrDeleteUser } = chatController();

  const { myChannel } = useRootContext();

  const [deals, setDeals] = useState<LinkDto[]>();
  const [inquiries, setInquiries] = useState<DealInquiryDto[]>();
  const [threads, setThreads] = useState<DealInquiryThreadDto[]>();
  const [summary, setSummary] = useState<InquirySummaryDto>();

  /**
   * @description 일반 사용자용 문의 쓰레드 조회
   * @param dealId
   * @param inquiryId
   * @returns
   */

  const getDeals = async () => {
    const result = (await myDealStore.getMyDeals(myChannel?.id as number)) as unknown as LinkDto[];
    setDeals(result);
  };

  const getInquiries = async (status?: 'PENDING' | 'ANSWERED' | 'CLOSED') => {
    const result = (await myDealStore.getReceivedAllInquiries(
      myChannel?.id as number,
      status,
    )) as unknown as DealInquiryDto[];
    setInquiries(result);
  };

  const getInquiryByDealId = async (dealId: number) => {
    const result = (await myDealStore.getReceivedInquiriesByDeal(
      dealId,
      myChannel?.id as number,
    )) as unknown as DealInquiryDto[];
    setInquiries(result);
  };

  const answer = async (linkId: number, inquiryId: number, message: MessageDto) => {
    const result = (await myDealStore.answer(
      myChannel?.id as number,
      linkId,
      inquiryId,
      message,
    )) as unknown as DealInquiryThreadDto;
    setThreads((prev) => [...(prev || []), result]);
  };

  const getInquiryThreads = async (dealId: number, inquiryId: number) => {
    const result = (await myDealStore.getInquiryThreads(
      myChannel?.id as number,
      dealId as number,
      inquiryId,
    )) as unknown as DealInquiryThreadDto[];
    setThreads(result);
  };

  const getSummary = async () => {
    const result = (await myDealStore.getCSSummary(
      myChannel?.id as number,
    )) as unknown as InquirySummaryDto;
    setSummary(result);
  };

  /**
   * @description 초대된 브랜드를 삭제한다.
   * @param channelName 브랜드 자신의 채널명
   * @param deals 딜 Key Array
   */
  const banChatChannels = async (brandChannel: PartnerDto) => {
    // const members =
    const temps = (await getPartnerDeals1(brandChannel?.id as number)) as unknown as LinkDto[];
    const deals: LinkDtoWithAssignedDeal[] = temps.map((deal) => {
      return { ...deal, assigned: false };
    });

    const result = await inviteOrDeleteUser(deals, [
      brandChannel?.partnerChannel?.channelName as string,
    ]);
  };

  return {
    deals,
    inquiries,
    threads,
    summary,
    getDeals,
    getInquiries,
    getInquiryByDealId,
    answer,
    getInquiryThreads,
    setInquiries,
    getSummary,
    banChatChannels,
  };
};

export default sellerCSController;
