import { IconButton } from '@mui/material';
import TitleForm from 'components/items/forms/TitleForm';
import React from 'react';
import { ItemSectionStyle } from 'resources/controls/ItemSectionStyle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { isMobile } from 'react-device-detect';

const ProposalOptionItem = ({
  seq,
  initialValue,
  onChangeValue,
  onDeleteClick,
}: {
  seq?: number;
  initialValue?: string;
  onChangeValue: (value: string) => void;
  onDeleteClick: () => void;
}) => {
  return (
    <ItemSectionStyle>
      <TitleForm
        style={{ width: isMobile ? 'calc(100% - 65px)' : 'calc(100% - 65px)' }}
        onChangeValue={(value: string) => {
          onChangeValue?.(value);
        }}
        placeHolder="제안 요청 종류 내용 입력하세요."
        label="타이틀"
        initialValue={initialValue}
      />
      <IconButton
        onClick={() => {
          onDeleteClick?.();
        }}
        style={{
          padding: '14px',
          marginLeft: 10,
          border: '1px solid #EFEFEF',
          borderRadius: ' 4px',
        }}
      >
        <DeleteOutlineIcon style={{ color: '#B7B7B7' }} />
      </IconButton>
    </ItemSectionStyle>
  );
};

export default ProposalOptionItem;
