import React, { useEffect, useRef, useState } from 'react';
import { ImageUploader } from 'components/commons';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { CustomFile, Map } from 'types/CommonTypes';
import { t } from 'i18next';
import { GoogleMap } from 'components/maps';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { validationHelper } from 'controls/validationHelper';

import { toast } from 'react-toastify';

import { LinkTypes } from 'features/management/context/providers/ContextTypes';
import { useOhmossContext } from 'features/management/context/useOhmossContext';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import TitleForm from 'components/items/forms/TitleForm';
import getGeoCodeByAddress from 'libs/helper/geoCodeHelper';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import styled from 'styled-components';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import BlockController from 'controllers/BlockController';
import { LinkDto } from 'services/data-contracts';
import { IMapBlock } from 'types/BlockExtendsTypes';
import CommonAlign from '../commonControl/CommonAlign';
import ActionButton from './ActionButton';

const MapLink = ({ blockModel }: { blockModel?: IMapBlock & LinkDto }) => {
  const { uiStore } = useStore();
  const defaultProps: Partial<LinkDto> = {
    linkType: 'MapLink',
    isActive: true,
  };

  const {
    initialModel,
    serviceModel,
    initialBinding,
    updateServiceModel,
    updateServiceModelMultiple,
  } = useOhmossContext<LinkTypes<IMapBlock & LinkDto>>();

  const { updateBlock } = BlockController<IMapBlock & LinkDto>();

  const [localModel, setLocalModel] = useInitializeModel<Partial<IMapBlock & LinkDto>>(
    blockModel,
    defaultProps,
    initialBinding,
  );

  const addrRef = useRef<any>(null);
  const validAddress = useRef<boolean>(false);

  const [isValid, setIsValid] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any>();
  const [selectedPlace, setSelectedPlace] = useState<any>();
  const [mapInfo, setMapInfo] = useState<Map | null | undefined>();
  console.log(initialModel);

  const handleClickSearchAddr = async () => {
    if (localModel?.address) {
      const results = await getGeoCodeByAddress(localModel?.address);
      if (results === null) {
        toast(t('mapError'));
        return;
      }

      if (results.length === 0) {
        toast(t('enterCorrectAddress'), { position: 'top-center' });
        setSearchResults(undefined);
        validAddress.current = false;
      } else if (results.length === 1) {
        handleClickSelectMap(results[0]);
        updateServiceModel('address', results?.[0].formatted_address);
        addrRef.current?.setValue(results?.[0].formatted_address);
        setSearchResults(undefined);
        validAddress.current = true;
      } else {
        setSearchResults(results);
      }
      checkValidation();
    }
  };

  const handleClickSelectMap = (result: any) => {
    const { lat, lng } = result.geometry.location;
    setMapInfo({
      lat,
      lng,
      title: '',
      addr: '',
    });

    updateServiceModelMultiple({ longitude: lng, latitude: lat });
  };

  const checkValidation = () => {
    const titleValid = validationHelper.required(localModel?.title);
    if (localModel?.showTitle === true) {
      setIsValid(titleValid && validAddress.current);
    } else {
      setIsValid(validAddress.current);
    }
  };

  const initializeModel = () => {
    if (blockModel) {
      const lat = blockModel?.latitude;
      const lng = blockModel?.longitude;

      setMapInfo({
        lat,
        lng,
        title: '',
        addr: '',
      });
      validAddress.current = true;
      // initialBinding(blockModel);
    }
  };

  useEffect(() => {
    if (localModel) {
      checkValidation();
    }
  }, [localModel]);

  useEffect(() => {
    initializeModel();
    if (localModel) {
      checkValidation();
    }
    return () => {
      initialBinding(undefined);
    };
  }, [blockModel]);

  return (
    <div style={{}}>
      <ItemSectionStyle>
        <ItemSectionTitle>타이틀</ItemSectionTitle>
        <ItemContent>
          <TitleForm
            onChangeValue={(value: string) => {
              setLocalModel('title', value);
              updateServiceModel('title', value);
            }}
            initialValue={initialModel?.title}
            placeHolder={`제목을 작성해 주세요.`}
            label=""
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>주소</ItemSectionTitle>
        <Row style={{ alignItems: 'flex-start' }}>
          <TitleForm
            ref={addrRef}
            label="Address"
            errorText="주소를 입력해 주세요"
            initialValue={initialModel?.address}
            onChangeValue={(value: string) => {
              if (validAddress.current === true) {
                validAddress.current = false;
                setMapInfo(null);
              }
              setLocalModel('address', value);
            }}
            onEntered={handleClickSearchAddr}
          />

          <SearchButtonStyle onClick={handleClickSearchAddr}>
            <SearchOutlinedIcon />
          </SearchButtonStyle>
        </Row>
      </ItemSectionStyle>

      <ItemSectionStyle>
        <GoogleMapContainerStyle>
          {!mapInfo ? (
            <div style={{ height: '100%' }}>
              <Row style={{ alignItems: 'center', height: '100%' }}>
                <FmdGoodOutlinedIcon fontSize="large" sx={{ fontSize: 30 }} />
                <div>{t('A157')}</div>
              </Row>
            </div>
          ) : (
            <GoogleMap map={mapInfo} />
          )}
        </GoogleMapContainerStyle>
      </ItemSectionStyle>
      <ItemSectionStyle>
        {searchResults?.length > 1 && (
          <div style={{ marginTop: 20, marginBottom: 10 }}>{t('searchResults')}</div>
        )}
        {searchResults?.map((result: any) => (
          <SearchResultStyle
            selected={result?.place_id === selectedPlace?.place_id}
            onClick={() => {
              handleClickSelectMap(result);
              setSelectedPlace(result);
              updateServiceModel('address', result?.formatted_address);

              // setValidAdress(true);
            }}
          >
            {result?.formatted_address}
          </SearchResultStyle>
        ))}
      </ItemSectionStyle>

      <ItemSectionStyle>
        <MenuItemCheckBox
          onChangeValue={(value: boolean) => {
            setLocalModel('showTitle', value);
            updateServiceModel('showTitle', value);
          }}
          initialValue={initialModel?.showTitle}
          title="타이틀 표시하기"
        />

        <MenuItemCheckBox
          initialValue={initialModel?.collapse}
          onChangeValue={(value: boolean) => {
            updateServiceModel('collapse', value);
          }}
          title="펼쳐 보이기"
        />
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>대체 이미지 선택</ItemSectionTitle>
        <ItemContent>
          <ImageUploader
            type="single"
            useCircularCrop={false}
            initialValue={initialModel?.thumbnail}
            attachType="image"
            onSelected={(image: CustomFile) => {
              updateServiceModel('thumbnail', image);
            }}
          />
        </ItemContent>
      </ItemSectionStyle>
      <CommonAlign
        initialValue={initialModel?.contentAlign || 'center'}
        title="타이틀 정렬"
        onChange={(value: string) => {
          updateServiceModel('contentAlign', value);
        }}
      />

      <ActionButton isValid={isValid} serviceModel={serviceModel} />
    </div>
  );
};

const GoogleMapContainerStyle = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  background-color: #efefef;
  justify-content: center;
  > div {
    width: 100%;
  }
`;

const SearchButtonStyle = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.26);
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 10px;
  cursor: pointer;
`;

const SearchImageStyle = styled.img`
  width: 22px;
  height: 22px;
`;

const SearchResultStyle = styled.div<{ selected: boolean }>`
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  border: ${(props) => (props.selected ? '1px solid #33cf4d' : '1px solid #f2f2f2')};
  background: #fcfcfc;
`;

export default MapLink;
