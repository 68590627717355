import React, { useContext, useEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { BottomSheet as BottomSheetOrigin } from 'react-spring-bottom-sheet';

import { OhmossButton } from 'resources/controls/OhmossButton';
import { isMobile } from 'react-device-detect';
import { t } from 'i18next';
import styled from 'styled-components';
import configurationContext from 'contexts/configurationContext';
import { UniversalOptions } from 'types/CommonTypes';
import 'react-spring-bottom-sheet/dist/style.css';
import UniversalHeader from './UniversalHeader';

/**
 * @description debug 모드에서 작동안함
 */
const BottomSheet = observer(
  ({ topPosition, useDefaultButtons }: { topPosition?: number; useDefaultButtons?: boolean }) => {
    const { uiStore } = useStore();
    const defaultTopPosition = topPosition || window.isWebView ? 50 : 10;
    const config = useContext(configurationContext);
    const [options, setOptions] = useState<UniversalOptions>();

    const getSnapPoint = (heightProps: any) => {
      if (typeof options?.bottomSheetOptions?.topPosition === 'undefined') {
        return [heightProps.minHeight];
      }
      return [
        heightProps.maxHeight - (options?.bottomSheetOptions?.topPosition || defaultTopPosition),
      ];
    };

    useEffect(() => {
      setOptions(uiStore.universalModal.options);
    }, [uiStore.universalModal.options]);

    useEffect(() => {
      return () => {
        uiStore.universalModal.updateModel(uiStore.universalModal?.contentModel);
      };
    }, []);

    return (
      <>
        <BottomSheetOrigin
          expandOnContentDrag={options?.bottomSheetOptions?.expandOnContentDrag}
          open={uiStore.universalModal.modalShow}
          blocking={options?.bottomSheetOptions?.blocking}
          snapPoints={getSnapPoint}
          onDismiss={() => uiStore.universalModal.close(options?.useHistoryBack || false)}
        >
          {uiStore.universalModal.options?.useHeader !== false ? (
            <UniversalHeader />
          ) : (
            <>
              {uiStore.universalModal.options.header && (
                <>{uiStore.universalModal.options.header}</>
              )}
            </>
          )}
          <div
            style={{ marginTop: 20, ...uiStore.universalModal?.options?.bottomSheetOptions?.style }}
          >
            {options?.children}
          </div>
          {options?.useDefaultButtons === true && (
            <ButtonContainerStyle
              style={{
                marginBottom: isMobile ? config.bottomSpace + 65 : 30,
                padding: 20,
              }}
            >
              <OhmossButton
                style={{ padding: '10px 30px' }}
                onClick={() => {
                  uiStore.universalModal.close(options?.useHistoryBack || false);
                }}
              >
                {t('Close')}
              </OhmossButton>
            </ButtonContainerStyle>
          )}
        </BottomSheetOrigin>
      </>
    );
  },
);

const ButtonContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 300;
`;

export default BottomSheet;
