import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import { LinkDto, MemberDto } from 'services/data-contracts';
import { Row } from 'components/commons/layouts';
import { Avatar, IconButton } from '@mui/material';
import { NameStyle } from 'resources/styles/common/ContextItem';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';

import styled from 'styled-components';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchBox from 'components/commons/SearchBox';
import CSTownController from 'controllers/CSTownController';
import { toast } from 'react-toastify';
import { useRootContext } from 'libs/hooks/useRootContext';
import MemberCreateContainer from '../containers/MemberCreateContainer';
import MemberContext from './MemberContext';
import AssignedDealsList from '../containers/AssignedDealsList';

/**
 * @description Brand회원의 멤버목록
 * @param param0
 * @returns
 */
const MemberList = ({ style }: { style?: CSSProperties }) => {
  const originMembers = useRef<MemberDto[]>([]);
  const { uiStore } = useStore();
  const { Brand } = CSTownController();
  const { myChannel } = useRootContext();

  const [members, setMembers] = useState<MemberDto[]>([]);

  const handleOnUpdate = () => {
    uiStore.modal.close();
    uiStore.bottomSheet.close();
    getMemberList();
    toast('정상적으로 처리되었습니다.');
  };

  const handleAddManager = (member?: MemberDto) => {
    const newModel = { ...member, username: member?.memberUser?.username };
    uiStore.modal.show({
      title: '매니저 등록',
      style: {
        maxHeight: '85%',
      },
      children: (
        <MemberCreateContainer
          type="edit"
          model={newModel}
          onUpdate={() => {
            handleOnUpdate();
          }}
        />
      ),
    });
  };

  const handleOnSelectedDeals = async (
    selectedDeals: LinkDtoWithAssignedDeal[],
    member: MemberDto,
  ) => {
    const dealList = selectedDeals
      .filter((deal) => deal.assigned)
      .map((deal) => {
        return { id: deal.id };
      });

    await Brand.DEAL.assignDealsToManager(myChannel?.id as number, member?.id as number, {
      deals: dealList,
    });
  };

  const handleClickAddDeal = async (member: MemberDto) => {
    const result = (await Brand.DEAL.getDelegatedDeals(
      member?.id as number,
    )) as unknown as LinkDto[];

    uiStore.bottomSheet.show({
      title: '위임 공구 리스트',
      children: (
        <AssignedDealsList
          dealList={result}
          onSelected={(selectedDeals: LinkDto[]) => {
            handleOnSelectedDeals(selectedDeals, member);
            toast('정상적으로 처리되었습니다.');
          }}
        />
      ),
    });
  };

  const handleOnReload = () => {
    uiStore.modal.close();
    getMemberList();
  };

  const handleClickShowMore = (member: MemberDto) => {
    uiStore.bottomSheet.show({
      children: (
        <MemberContext
          member={member}
          onReload={handleOnReload}
          onEdit={() => {
            handleAddManager(member);
          }}
        />
      ),
    });
  };

  const getMemberList = async () => {
    const result = (await Brand.DEAL.getMembers(myChannel?.id as number)) as unknown as MemberDto[];
    setMembers(result);
    originMembers.current = result;
  };

  const handleSearchMember = (value: string) => {
    if (value === '') {
      setMembers(originMembers.current);
    } else {
      const result = originMembers.current.filter((member) => {
        return member?.memberUser?.username?.includes(value);
      });
      setMembers(result);
    }
  };

  useEffect(() => {
    if (myChannel) {
      getMemberList();
    }
  }, [myChannel]);

  return (
    <ManagementLayout style={{ height: 'calc(100vh - 180px)', ...style }}>
      <div>
        <MainContainer>
          <MainText>매니저 찾기</MainText>
          <SearchBox
            onEntered={handleSearchMember}
            onCancel={() => {
              handleSearchMember('');
            }}
          />
          <MainText style={{ margin: '20px 0' }}>매니저 리스트</MainText>
          {members.length === 0 && (
            <SubText>등록된 매니저가 없습니다. 새로운 멤버를 등록하세요.</SubText>
          )}
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            {members.map((member: MemberDto) => (
              <Row style={{ justifyContent: 'space-between' }}>
                <Row style={{ justifyContent: 'flex-start' }}>
                  <div>
                    <Avatar style={{ width: 60, height: 60 }} />
                  </div>
                  <div style={{ marginLeft: 10 }}>
                    <div>{member.memberUser?.username}</div>
                    <NameStyle>{member.name}</NameStyle>
                    {/* <NameStyle>{member.memberUser?.}</NameStyle> */}
                  </div>
                </Row>
                <Row style={{ width: 'unset' }}>
                  <IconButton
                    onClick={() => {
                      handleClickAddDeal(member);
                    }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                  <IconButton>
                    <MoreVertIcon
                      onClick={() => {
                        handleClickShowMore(member);
                      }}
                    />
                  </IconButton>
                </Row>
              </Row>
            ))}
          </div>
        </MainContainer>
      </div>
      <div>
        <OhmossButton
          onClick={() => {
            handleAddManager();
          }}
        >
          매니저 추가
        </OhmossButton>
      </div>
    </ManagementLayout>
  );
};

const MainContainer = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
`;

const MainText = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 10px;
`;

const SubText = styled.div`
  margin-top: 10px;
  color: #cacaca;
  font-size: 0.82rem;
`;

export default MemberList;
