import React, { useEffect, useState } from 'react';
import { ImageUploader } from 'components/commons';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import TitleForm from 'components/items/forms/TitleForm';
import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { CustomFile } from 'types/CommonTypes';
import { ILinkButton, Layout } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { validationHelper } from 'controls/validationHelper';
import { LinkTypes } from 'features/management/context/providers/ContextTypes';
import { useOhmossContext } from 'features/management/context/useOhmossContext';

import UrlForm from 'components/items/forms/UrlForm';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import BlockController from 'controllers/BlockController';
import CommonSticker from '../commonControl/CommonSticker';
import CommonAlign from '../commonControl/CommonAlign';
import ActionBox from 'features/csTown/components/pc/ActionBox';
import ActionButton from './ActionButton';

const LinkButton = ({ blockModel }: { blockModel?: ILinkButton }) => {
  const { uiStore } = useStore();

  const defaultProps: Partial<ILinkButton> = {
    linkType: 'SimpleLink',
    openNewWindow: true,
    showIcon: true,
    isActive: true,
    layout: 'TypeA',
  };

  const { initialModel, initialBinding, updateServiceModel, unmountModel, serviceModel } =
    useOhmossContext<LinkTypes<ILinkButton>>();

  const { updateBlock } = BlockController<ILinkButton>();

  const [localModel, setLocalModel] = useInitializeModel<Partial<ILinkButton>>(
    blockModel,
    defaultProps,
    initialBinding,
  );
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    if (localModel) {
      const titleValid = validationHelper.required(localModel?.title);
      const urlValid = validationHelper.urlOrEmpty(localModel?.linkUrl);
      setIsValid(titleValid && urlValid);
    }
  }, [localModel]);

  useEffect(() => {
    return () => {
      unmountModel();
    };
  }, []);

  return (
    <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      <div style={{ flexGrow: 1 }}>
        <div style={{ marginBottom: 20 }}>
          <ImageUploader
            type="single"
            useCircularCrop={false}
            initialValue={initialModel?.thumbnail}
            attachType="image"
            onSelected={(image: CustomFile) => {
              updateServiceModel('thumbnail', image);
            }}
          />
        </div>
        <ItemSectionStyle>
          <ItemSectionTitle>타이틀</ItemSectionTitle>
          <ItemContent>
            <TitleForm
              onChangeValue={(value: string) => {
                updateServiceModel('title', value);
                setLocalModel('title', value);
              }}
              initialValue={initialModel?.title}
              placeHolder="타이틀을 작성해 주세요"
              label="타이틀"
            />
          </ItemContent>
        </ItemSectionStyle>
        <ItemSectionStyle>
          <ItemSectionTitle>링크주소</ItemSectionTitle>
          <ItemContent>
            <UrlForm
              onChangeValue={(value: string) => {
                updateServiceModel('linkUrl', value);
                setLocalModel('linkUrl', value);
              }}
              initialValue={initialModel?.linkUrl}
              label="링크주소"
            />
          </ItemContent>
        </ItemSectionStyle>
        <CommonSticker
          initialValue={initialModel?.stickers}
          initialPosition={initialModel?.stickerPosition}
          onChange={(stickers: any) => {
            updateServiceModel('stickers', stickers);
          }}
          onChangePosition={(position: 'top' | 'bottom') => {
            updateServiceModel('stickerPosition', position);
          }}
        />

        <ItemSectionStyle>
          <MenuItemCheckBox
            onChangeValue={(value: boolean) => {
              updateServiceModel('useTransparent', value);
            }}
            initialValue={initialModel?.useTransparent}
            title="투명배경"
          />

          <MenuItemCheckBox
            initialValue={initialModel?.openNewWindow || true}
            onChangeValue={(value: boolean) => {
              updateServiceModel('openNewWindow', value);
            }}
            title="새창열기"
          />
        </ItemSectionStyle>
        {/* <LinkButtonLayout
        initialValue={initialModel?.layout || 'TypeA'}
        onChange={(layout: Layout) => updateServiceModel('layout', layout)}
      /> */}
        <CommonAlign
          initialValue={initialModel?.contentAlign || 'center'}
          title="타이틀 정렬"
          onChange={(value: string) => {
            updateServiceModel('contentAlign', value);
          }}
        />
      </div>
      <ActionButton isValid={isValid} serviceModel={serviceModel} />
    </div>
  );
};

export default LinkButton;
