import React from 'react';

import ProposalList from '../containers/ProposalList';
import styled from 'styled-components';

const ProposalPage = () => {
  return (
    <ContainerStyle>
      <ProposalList />
    </ContainerStyle>
  );
};
const ContainerStyle = styled.div`
  width: 100%;
  @media ${(props) => props.theme.media.desk} {
    margin-left: 30px;
    width: 800px;
  }
`;

export default ProposalPage;
