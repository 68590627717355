import React, { memo, useEffect, useRef, useState } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { InfoWindow } from '@react-google-maps/api';
import { Map } from 'types/CommonTypes';
import { GOOGLE_MAP_API_KEY } from 'stores/AppStore';

/**
 * @description 구글 맵 표시용 컴포넌트
 * @param status
 * @returns
 */
const render = (status: Status): any => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
};

function GoogleMapComponent({ center, zoom }: { center: google.maps.LatLngLiteral; zoom: number }) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current !== null) {
      const map = new window.google.maps.Map(ref.current, {
        center,
        zoom,
        gestureHandling: 'cooperative',
      });
      new google.maps.Marker({ position: center, map: map });
    }
  }, []);

  return (
    <div ref={ref} id="map" style={{ width: '100%', height: '20em', aspectRatio: '1/1' }}></div>
  );
}

//  google은 nbhd계정임
const GoogleMap = ({ map }: { map?: Map | null | undefined }) => {
  const zoom = 17;

  const [center, setCenter] = useState<google.maps.LatLngLiteral>();

  function getCurrentPositionAsync(options = {}) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  }

  const setPosition = async () => {
    if (map) {
      setCenter({
        lat: Number(map?.lat),
        lng: Number(map?.lng),
      });
      return;
    }
  };

  useEffect(() => {
    setPosition();
  }, [map]);

  return (
    <div>
      {typeof center !== 'undefined' && (
        <Wrapper apiKey={`${GOOGLE_MAP_API_KEY}`} render={render}>
          <GoogleMapComponent center={center} zoom={zoom} />
        </Wrapper>
      )}
    </div>
  );
};

export default memo(GoogleMap);
