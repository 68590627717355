import { makeObservable, flow, action, observable } from 'mobx';
import { MyBoard as MyBoardService } from 'services/MyBoard';
import { HttpClient } from 'services/http-client';
import { ChannelBoardDto } from 'services/data-contracts';
import _ from 'lodash';
import ServiceStoreBase from './ServiceStoreBase';
import i18n from 'i18n';

/**
 * @description Public Link Store
 */
class MyBoardStore extends ServiceStoreBase {
  t = i18n.t;

  constructor() {
    super();
    makeObservable(this, {
      myBoards: observable,
      linkBoards: observable,
      updateBoardByItem: action,
      getBoards: flow,
      getBoardById: flow,
      createBoard: flow,
      updateBoard: flow,
      deleteBoard: flow,
      reOrderBoardItem: flow,
    });
  }

  api: MyBoardService<unknown> = new MyBoardService(new HttpClient());

  myBoards: Array<ChannelBoardDto> = [];

  linkBoards: Array<ChannelBoardDto> = [];

  *getBoards(channelId: number) {
    const { response } = yield this.api.getBoards(channelId);
    if (response.status === 200) {
      this.myBoards = response.data;
      this.linkBoards = response.data.filter(
        (board: ChannelBoardDto) => board.boardType === 'LINK',
      );
      return response.data;
    } else {
      return [];
    }
  }

  *getBoardById(channelId: number, boardId: number) {
    const { response } = yield this.api.getBoard(channelId, boardId);
    return response.status < 300 ? response.data : null;
  }

  *createBoard(channelId: number, boardItem: ChannelBoardDto) {
    const { response } = yield this.api.createBoard(channelId, boardItem);
    super.handleByStatus(response, { successMessage: this.t('A218') });
    return response.data;
  }

  *updateBoard(channelId: number, boardItem: ChannelBoardDto, message?: string) {
    const { response } = yield this.api.updateBoard(channelId, boardItem.id as number, boardItem);
    if (response.status === 200) return response.data;
  }

  *deleteBoard(channelId: number, boardId: number) {
    const { response } = yield this.api.deleteBoard(channelId, boardId);
    super.handleByStatus(response, { successMessage: this.t('A220') });
    return response.status === 200;
  }

  *reOrderBoardItem(channelId: number, boardId: number, boardItem: ChannelBoardDto) {
    const { response } = yield this.api.updateSeqOfBoard(channelId, boardId, boardItem);
    return response.status === 200;
  }

  updateBoardByItem(board: any, type?: 'create' | 'delete' | 'update') {
    if (board.errorCode) {
      return;
    }
    const index = this.myBoards.findIndex(
      (recentBoard: ChannelBoardDto) => recentBoard.id === board.id,
    );
    const newBoards = [...this.myBoards];
    if (type === 'delete') {
      newBoards.splice(index, 1);
    } else if (type === 'update') {
      newBoards.splice(index, 1, board);
    } else {
      newBoards.push(board);
    }
    this.myBoards = [...newBoards];
  }
}

export default MyBoardStore;
