import React, { useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { Row } from 'components/commons/layouts';
import { ChannelBoardDto } from 'services/data-contracts';
import { ItemSectionDescription } from 'resources/controls/ItemSectionStyle';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import TitleForm from 'components/items/forms/TitleForm';

/**
 * @description 피드보드 생성화면
 * @param param0
 * @returns
 */
const PostBoard = ({
  model,
}: {
  model: [
    ChannelBoardDto | undefined,
    React.Dispatch<React.SetStateAction<ChannelBoardDto | undefined>>,
  ];
}) => {
  const boardModel = model[0];
  const dispatch = model[1];
  const { t } = useTranslation();
  // const [viewType, setViewType] = useState<'FEED' | 'PINTEREST' | 'LIST' | undefined>('FEED');
  const [viewType, setViewType] = useState<ChannelBoardDto['viewType']>('GRID');

  const [isActive, setIsActive] = useState<boolean | undefined>();
  const [isAllowComment, setIsAllowComment] = useState<boolean | undefined>();
  const [useProfile, setUseProfile] = useState<boolean | undefined>();

  const handleChangeValue = (value: string) => {
    dispatch((prev) => {
      return { ...prev, name: value };
    });
  };

  React.useEffect(() => {
    if (!boardModel?.channel) {
      setIsActive(true);
      setUseProfile(true);

      setIsAllowComment(false);
      dispatch({
        boardType: 'BOARD',
        isActive: true,
        viewType: 'GRID',
        useProfile: true,
        isCommentAllow: false,
        seq: 1,
      });
      // setIsActive(true);
    } else {
      setViewType(boardModel.viewType);
      setIsActive(boardModel.isActive);
      setIsAllowComment(boardModel.isCommentAllow);
      setUseProfile(boardModel.useProfile);

      dispatch(boardModel);
    }
  }, [boardModel]);

  return (
    <>
      <TitleForm onChangeValue={handleChangeValue} model={boardModel?.name} label={t('A142')} />
      <Row style={{ marginTop: 30, alignItems: 'flex-start' }}>
        <FormControlLabel
          labelPlacement="bottom"
          control={
            <Switch
              color="secondary"
              checked={isActive}
              onChange={(event: never, value: boolean) => {
                setIsActive(value);
                dispatch((prev: ChannelBoardDto) => {
                  return { ...prev, isActive: value };
                });
              }}
            />
          }
          label={t('A143')}
        />
        <FormControlLabel
          labelPlacement="bottom"
          control={
            <Switch
              color="secondary"
              checked={isAllowComment}
              onChange={(event: never, value: boolean) => {
                setIsAllowComment(value);
                dispatch((prev: ChannelBoardDto) => {
                  return { ...prev, isCommentAllow: value };
                });
              }}
            />
          }
          label={t('A145')}
        />
        <FormControlLabel
          labelPlacement="bottom"
          control={
            <Switch
              color="secondary"
              checked={useProfile}
              onChange={(event: never, value: boolean) => {
                setUseProfile(value);
                dispatch((prev: ChannelBoardDto) => {
                  return { ...prev, useProfile: value };
                });
              }}
            />
          }
          label={t('A144')}
        />
      </Row>
      <ItemSectionDescription style={{ marginTop: 10 }}>{t('A138')}</ItemSectionDescription>
      <BoardTypeImageContainer>
        <Row style={{ justifyContent: 'space-around' }}>
          <ProfileTypeImageStyle
            checked={viewType === 'GRID'}
            onClick={() => {
              setViewType('GRID');

              dispatch((prev: ChannelBoardDto) => {
                return { ...prev, viewType: 'GRID' };
              });
            }}
          >
            <img src="/images/insta.svg" alt="" style={{}} />
            <div>SQUARE</div>
          </ProfileTypeImageStyle>
          <ProfileTypeImageStyle
            checked={viewType === 'MASONRY'}
            onClick={() => {
              setViewType('MASONRY');
              dispatch((prev: ChannelBoardDto) => {
                return { ...prev, viewType: 'MASONRY' };
              });
            }}
          >
            <img src="/images/pinterest.svg" alt="" style={{}} />
            <div>UNIQUE</div>
          </ProfileTypeImageStyle>
          <ProfileTypeImageStyle
            checked={viewType === 'NONE'}
            onClick={() => {
              setViewType('NONE');

              dispatch((prev: ChannelBoardDto) => {
                return { ...prev, viewType: 'NONE' };
              });
            }}
          >
            <img src="/images/listboard.svg" alt="" style={{}} />
            <div>BASIC</div>
          </ProfileTypeImageStyle>
        </Row>
      </BoardTypeImageContainer>
    </>
  );
};

const ProfileTypeImageStyle = styled.div<{ checked?: boolean }>`
  cursor: pointer;
  opacity: ${(props) => (props.checked ? 1 : 0.4)};
  transition: all 0.3s ease;
  > div {
    text-align: center;
    margin-top: 20px;
  }
  > img {
    @media ${(props) => props.theme.media.mobile} {
      width: 100px;
    }
  }
`;

const BoardTypeImageContainer = styled.div`
  > img {
    width: 40px;
  }
`;

export default PostBoard;
