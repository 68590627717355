import { useEffect, useState, useRef } from 'react';

function useScrollDetector(selector: string) {
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down' | null>(null);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const targetElement = useRef<HTMLElement | null>(null);
  const ticking = useRef(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollElement = document.getElementById(selector);
      if (!scrollElement) return;

      const currentScrollTop = scrollElement.scrollTop;

      if (!ticking.current) {
        window.requestAnimationFrame(() => {
          if (Math.abs(currentScrollTop - lastScrollTop) >= 1) {
            // Update threshold
            if (currentScrollTop > lastScrollTop) {
              setScrollDirection('down');
            } else if (currentScrollTop < lastScrollTop) {
              setScrollDirection('up');
            }
            setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
          }
          ticking.current = false;
        });
        ticking.current = true;
      }
    };

    const scrollElement = document.getElementById(selector);
    if (scrollElement) {
      targetElement.current = scrollElement;
      scrollElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [selector, lastScrollTop]);

  return { scrollDirection, lastScrollTop, targetElement };
}

export default useScrollDetector;
