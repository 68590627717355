import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { useParams, useSearchParams } from 'react-router-dom';
import { useStore } from 'stores/StoreHelper';
import { PostDto } from 'services/data-contracts';
import MobileAdPostItem from 'pages/MobileAdPostItem';
import { useRootContext } from 'libs/hooks/useRootContext';

const PostDetailPage = observer(() => {
  const { postStore } = useStore();
  const { channelname, boardid, postid } = useParams();
  const [searchParam] = useSearchParams();
  const { myChannel } = useRootContext();

  const [postInfo, setPostInfo] = useState<PostDto>();
  const [goBack, setGoBack] = useState<string>();

  const init = async () => {
    const cpcode = searchParam.get('cpcode');
    if (cpcode) {
      setGoBack(`/${channelname}/${boardid}`);
    }
    if (postid && channelname && boardid) {
      const boardId = Number.parseInt(boardid, 10);
      const postId = Number.parseInt(postid, 10);
      // const channelInfo = await initializeFirstChannel();

      if (channelname && !Number.isNaN(boardId) && !Number.isNaN(postId)) {
        const post = (await postStore.getPost(
          channelname,
          boardId,
          postId,
          myChannel?.id || undefined,
        )) as never as PostDto;
        if (post) {
          setPostInfo(post);
        }
      }
    }
  };

  useEffect(() => {
    init();
  }, [myChannel]);

  return (
    <>
      {/* <DetailPageHeader title="POST" gobackUrl={goBack} /> */}
      {typeof postInfo !== 'undefined' && (
        <MobileAdPostItem
          isOwner={postInfo.channel?.id === myChannel?.id}
          post={postInfo}
          onClickVisit={() => {
            // handleClickVisit(ad);
          }}
          onClickFavorite={() => {}}
        />
      )}
    </>
  );
});

export default PostDetailPage;
