import React, { useEffect } from "react";
import styled, { CSSProperties } from "styled-components";

const TextLabel = ({ type, style, children }: { type: 'subject' | 'content', style?: CSSProperties; children: any; }) => {

    return (
        <div className={`common-typo-title ${type}`} style={style}>
            {/* {children?.split('\\n').map((line: string, index: number) =>
                <span key={index}>
                    {line}
                    {index !== children.split('\\n').length - 1 && <br />}
                </span>
            )} */}
            {children}
        </div>
    );
};

export default TextLabel;

