import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useRoutes } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useStore } from 'stores/StoreHelper';
import styled from 'styled-components';
import BottomSheetOnly from 'components/commons/BottomSheetOnly';
import FullModal from 'components/commons/FullModal';
import Confirm2 from 'components/commons/Confirm2';
import UniversalModal from 'components/commons/UniversalModal/Modal';
import myRoutes from 'routes/myRoutes';
import { useChat } from 'libs/hooks/useChat';
import { useRootContext } from 'libs/hooks/useRootContext';
import chatController from 'features/community/controllers/chatController';
import DetailPageHeader from 'components/commons/DetailPageHeader';
import ProviderWrapper from './ProviderWrapper';
import AuthContainer from './AuthContainer';

const ChatRouter = () => {
  const location = useLocation();
  const { initChat } = useChat();
  const currentRoute = useRoutes(myRoutes);

  const { myChannel } = useRootContext();
  const { historyStore } = useStore();
  const { getUser } = chatController();

  const getElement = (route: any) => {
    if (window.isWebView) {
      return route.webViewElement ? <route.webViewElement /> : <route.element />;
    } else if (isMobile) {
      return route.mobileElement ? <route.mobileElement /> : <route.element />;
    } else {
      return <route.element />;
    }
  };

  useEffect(() => {
    if (myChannel) {
      const initialize = async () => {
        const result = await getUser(myChannel?.channelName as string);
        if (result) {
          await initChat(myChannel?.channelName as string);
        }
      };

      initialize();
    }
  }, [myChannel]);

  useEffect(() => {
    historyStore.pushPopHistory({ key: location.key, path: location.pathname });
  }, [location]);

  return (
    <>
      {/* <GlobalBgStyle src="/images/ohmoss_bg.jpg" /> */}
      <>
        <div style={{ position: 'sticky', top: 0, zIndex: 1 }}>
          <DetailPageHeader
            title={currentRoute?.props?.match?.route?.title}
            gobackUrl={currentRoute?.props?.match?.route?.goBack}
          />
        </div>
        <RootStyle style={{}}>
          <Routes location={location}>
            {myRoutes.map((route, index: number) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <AuthContainer isPublic={route.isPublic} title={route.title}>
                      {/* <ChannelProvider> */}
                      <ProviderWrapper provider={route.provider}>
                        <BottomSheetOnly />
                        <FullModal />
                        <Confirm2 />
                        <UniversalModal />
                        {getElement(route)}
                      </ProviderWrapper>
                      {/* </ChannelProvider> */}
                    </AuthContainer>
                  }
                />
              );
            })}
          </Routes>
        </RootStyle>
      </>
    </>
  );
};

const RootStyle = styled.div`
  max-width: 550px;
  width: 100%;
  height: calc(100vh - 80px);
  margin: auto;
`;

export default ChatRouter;
