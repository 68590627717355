import styled from 'styled-components';

const LinkEditorContainerStyle = styled.div`
  padding: 20px;
  padding-top: 0;
  /* width: 90%; */
  max-width: 800px;
  @media ${(props) => props.theme.media.mobile} {
    /* margin-bottom: 130px; */
  }
`;

export default LinkEditorContainerStyle;
