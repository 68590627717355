import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { t } from 'i18next';
import { MyStyle } from 'types/CommonTypes';
import { Stack } from '@mui/material';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { ItemSectionStyle } from 'resources/controls/ItemSectionStyle';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { MyStyleTypes } from 'features/management/context/providers/ContextTypes';

import styled from 'styled-components';
import BackgroundImageComponent from 'features/commonFeatures/components/BackgroundImageComponent';
import StyleController from 'controllers/StyleController';
import LinkDesignItemBox from '../../components/LinkDesignItemBox';
import LinkDesignItemBoxBorder from '../../components/LinkDesignItemBoxBorder';
import LinkDesignItemBoxText from '../../components/LinkDesignItemBoxText';
import LinkDesignItemBoxShadow from '../../components/LinkDesignItemBoxShadow';
import { toast } from 'react-toastify';
import { useRootContext } from 'libs/hooks/useRootContext';
import ManagementInnerLayout from 'features/management/components/common/ManagementInnerLayout';
import { useMediaContext } from 'providers/useMediaContext';

const LinkDesignContainer = observer(() => {
  const { myChannelStyleStore, xhrStore } = useStore();
  const { myChannel } = useRootContext();
  const ScreenTypes = useMediaContext();

  const { initialBinding, stateModel, serviceModel } = useOhmossContext<MyStyleTypes<MyStyle>>();
  const { updateStyle } = StyleController();

  const getMyStyle = async () => {
    if (myChannel) {
      const myStyle = (await myChannelStyleStore.getMyStyle(myChannel.id as number)) as any;
      if (typeof myStyle !== 'undefined') {
        const parsed = JSON.parse(myStyle.style) as MyStyle;
        initialBinding({ boxStyle: parsed.boxStyle, textStyle: parsed.textStyle, created: true });
      } else {
        initialBinding({ boxStyle: {}, textStyle: {}, created: false });
      }
    }
  };

  useEffect(() => {
    getMyStyle();
  }, [myChannel]);

  // useEffect(() => {
  //   if (xhrStore.state === 'done') {
  //     toast('정상적으로 반영되었습니다.');
  //   }
  // }, [xhrStore.state]);

  useEffect(() => {
    return () => {
      xhrStore.setState('idle');
    };
  }, []);

  return (
    <ManagementInnerLayout>
      <div style={{ flexGrow: 1 }}>
        <ItemSectionStyle
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
            zIndex: 140,
            position: 'sticky',
            top: 0,
            height: ScreenTypes.isLarge ? '150px' : 'auto',
          }}
        >
          <PreviewContainer
            style={{
              minHeight: 100,
              alignItems: 'center',
              display: 'flex',
              width: '100%',
              height: ScreenTypes.isLarge ? '150px' : 'auto',
            }}
          >
            <>
              {!ScreenTypes.isLarge ? (
                <BackgroundImageComponent
                  isPreview
                  profile={myChannel?.profile}
                  useEmpty={false}
                  style={{
                    position: 'absolute',
                    width: '100%',
                    overflow: 'hidden',
                    minHeight: 120,
                    height: '100%',
                  }}
                />
              ) : (
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    overflow: 'hidden',
                    minHeight: 120,
                    height: '100%',
                  }}
                />
              )}
            </>
            <div style={{ ...stateModel?.boxStyle }}>
              <div style={stateModel?.textStyle}> 블럭 디자인 미리보기</div>
            </div>
          </PreviewContainer>
        </ItemSectionStyle>
        <div style={{ padding: 20 }}>
          <LinkDesignItemBox />
          <LinkDesignItemBoxBorder />
          <LinkDesignItemBoxShadow />
          <LinkDesignItemBoxText />
        </div>
      </div>
      {/* <OhmossButton
          onClick={() => {
            updateServiceModelMultiple({ boxStyle: {}, textStyle: {} });
            updateStyle();
          }}
        >
          초기화하기
        </OhmossButton> */}
      <div style={{ padding: 20 }}>
        <OhmossButton
          onClick={async () => {
            await updateStyle(serviceModel);
            toast('정상적으로 반영되었습니다.');
          }}
        >
          저장하기
        </OhmossButton>
      </div>
    </ManagementInnerLayout>
  );
});

const PreviewContainer = styled.div`
  padding: 30px 10px;
  overflow: hidden;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  > div:nth-child(2) {
    width: 100%;
    /* background: #fff; */
    height: 50px;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default LinkDesignContainer;
