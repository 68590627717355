import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css/navigation';
// import { isMobile } from 'react-device-detect';
import MenuList from '@mui/material/MenuList';
import useNavigation from 'hooks/useNavigation';
import { useParams } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import { Row } from 'components/commons/layouts';
import useToken from 'libs/hooks/useToken';
import { useStore } from 'stores/StoreHelper';
import useChannelSelector from 'libs/hooks/useChannelSelector';
import Resign from 'components/commons/Resign';
import { useTranslation } from 'react-i18next';

interface menuType {
  id: number;
  title: string;
  ref: React.RefObject<HTMLDivElement>;
}

interface sectionItemType {
  id: number;
  img: string;
  size?: number;
  title1Color?: string;
  bgColor?: string;
  title1: string;
  title2?: string;
  description1?: string;
  description2?: string;
}

const OhmossIntroducePage = observer(() => {
  const { navigate } = useNavigation();
  const { initializeFirstChannel } = useChannelSelector();
  const { uselanding } = useParams();
  const { validUser } = useToken();
  const { t } = useTranslation();
  const isMobile = true;
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [resize1, setResize1] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>();

  const section1Ref = useRef<HTMLDivElement>(null);
  const section2Ref = useRef<HTMLDivElement>(null);
  const section3Ref = useRef<HTMLDivElement>(null);
  const section4Ref = useRef<HTMLDivElement>(null);
  const section5Ref = useRef<HTMLDivElement>(null);
  const section6Ref = useRef<HTMLDivElement>(null);

  const section3SwiperItem: Array<sectionItemType> = [
    {
      id: 1,
      img: '/images/introduce/section3-1-arrow.png',
      title1: t('A14'),
      description1: t('A15'),
    },
    {
      id: 2,
      img: '/images/introduce/section3-2-arrow.png',
      title1: t('A17'),
      description1: t('A18'),
    },
    {
      id: 3,
      size: 200,
      img: '/images/introduce/section3-3-arrow.png',
      title1: t('A19'),
      description1: t('A20'),
    },
  ];

  const section4SwiperItem: Array<sectionItemType> = [
    {
      id: 1,
      img: '/images/introduce/section3-1.png',
      title1Color: '#011208',
      title1: t('A21'),
      title2: t('A21_1'),
    },
    {
      id: 2,
      img: '/images/introduce/section3-2.png',
      title1Color: '#011208',
      title1: t('A22'),
      title2: t('A22_1'),
    },
    {
      id: 3,
      img: '/images/introduce/section3-1.png',
      title1Color: '#011208',
      title1: t('A23'),
      title2: t('A23_1'),
    },
  ];

  const section5SwiperItem: Array<sectionItemType> = [
    { id: 1, img: '/images/introduce/section3-1.png', title1: '랜딩 페이지', bgColor: '#0BBD58' },
    {
      id: 2,
      img: '/images/introduce/section3-1.png',
      title1: '매장 홈페이지',
      bgColor: '#011308',
    },
    {
      id: 3,
      img: '/images/introduce/section3-1.png',
      title1: '모임 커뮤니티',
      title1Color: 'black',
      bgColor: '#FEC902',
    },
    { id: 4, img: '/images/introduce/section3-1.png', title1: '브랜드', bgColor: '#7D60D1' },
    { id: 5, img: '/images/introduce/section3-1.png', title1: '랜딩 페이지', bgColor: '#0BBD58' },
  ];

  const menuItem: Array<menuType> = [
    {
      id: 1,
      title: t('A1'),
      ref: section1Ref,
    },
    {
      id: 2,
      title: t('A2'),
      ref: section2Ref,
    },
    {
      id: 3,
      title: t('A3'),
      ref: section3Ref,
    },
    // {
    //   id: 4,
    //   title: t('A4'),
    //   ref: section4Ref,
    // },
    {
      id: 5,
      title: t('A5'),
      ref: section5Ref,
    },
    {
      id: 6,
      title: t('A6'),
      ref: section6Ref,
    },
  ];

  const SwiperNextBtn = () => {
    const swiper = useSwiper();
    return (
      <SwiperRightBtn
        resize1={resize1}
        onClick={() => {
          swiper.slideNext();
        }}
      >
        <SwiperBtnImg src="/images/introduce/right.png" resize1={resize1} />
      </SwiperRightBtn>
    );
  };

  const SwiperPrevBtn = () => {
    const swiper = useSwiper();
    return (
      <SwiperLeftBtn
        resize1={resize1}
        onClick={() => {
          swiper.slidePrev();
        }}
      >
        <SwiperBtnImg src="/images/introduce/left.png" resize1={resize1} />
      </SwiperLeftBtn>
    );
  };

  const handleResize = () => {
    const { innerWidth } = window;
    if (innerWidth < 880) {
      setResize1(true);
    } else {
      setResize1(false);
    }
  };

  const checkValidUser = async () => {
    const user = await validUser();
    if (user) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  };

  const getFirstChannel = async () => {
    const channel = await initializeFirstChannel();
    if (channel) {
      navigate(`/${channel.channelName}`);
    }
  };

  useEffect(() => {
    getFirstChannel();
    window.addEventListener('resize', handleResize);
    handleResize();
    if (uselanding) {
      setLoggedIn(false);
    } else {
      checkValidUser();
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {loggedIn === false && (
        <Container>
          <Header resize1={resize1}>
            {/* {resize1 ? ( */}
            <div>
              <MenuImg
                style={{ top: window.isWebView ? 45 : 10 }}
                onClick={() => {
                  setOpenMenu(!openMenu);
                }}
              />
              {openMenu && (
                <>
                  <MenuList style={menuListStyle}>
                    {menuItem.map((item: menuType) => {
                      return (
                        <MenuItem
                          key={item.id}
                          onClick={() => {
                            setOpenMenu(false);
                            item.ref.current?.scrollIntoView({ behavior: 'smooth' });
                          }}
                        >
                          <Text color="white">{item.title}</Text>
                        </MenuItem>
                      );
                    })}
                    <LoginButtonStyle
                      onClick={() => {
                        navigate('/login');
                      }}
                    >
                      {t('A94')}
                    </LoginButtonStyle>
                  </MenuList>
                </>
              )}
            </div>
          </Header>
          <Section1>
            {/* <div>hi</div> */}
            <Section1TitleContainer>
              <LogoImg size={300} style={{ marginBottom: 15 }} />
              <Text color="white" fontSize={30} style={{ marginBottom: 15, fontSize: 24 }}>
                {resize1 ? <>{t('A33')}</> : <>{t('A7')}</>}
              </Text>
            </Section1TitleContainer>
            <ImgContainer>
              {!isMobile && (
                <img
                  src="/images/introduce/tree-left.png"
                  style={{
                    width: resize1 ? 0 : 200,
                    position: 'absolute',
                    bottom: 30,
                    left: 0,
                    zIndex: 1000,
                  }}
                />
              )}
              <img src="/images/introduce/section1.png" style={{ width: '80%' }} />
              {!isMobile && (
                <img
                  src="/images/introduce/tree-right.png"
                  style={{
                    width: resize1 ? 0 : 250,
                    position: 'absolute',
                    bottom: 30,
                    right: 0,
                    zIndex: 1000,
                  }}
                />
              )}
            </ImgContainer>
            {!isMobile && (
              <BackgroundBlock backgroundColor="#f6f6f6" height={resize1 ? '30px' : '100px'} />
            )}
          </Section1>
          <Section2 ref={section1Ref}>
            <SectionTitle style={{ whiteSpace: 'pre-line' }}>{t('A8')}</SectionTitle>
            <ItemContainer>
              <Section2ItemWrapper>
                <GreenBorder>
                  <Section2GreenText>{t('A9')}</Section2GreenText>
                </GreenBorder>
                <img src="/images/introduce/section2-1.png" width={isMobile ? 250 : 300} />
                <Text>{t('A11')}</Text>
              </Section2ItemWrapper>
              <Section2ItemWrapper>
                <GreenBorder>
                  <Section2GreenText>{t('A12')}</Section2GreenText>
                </GreenBorder>
                <img src="/images/introduce/section2-2.png" width={isMobile ? 250 : 300} />
                <Text>{t('A12_1')}</Text>
              </Section2ItemWrapper>
              <Section2BackImg src="/images/introduce/section2-3.png" />
            </ItemContainer>
          </Section2>
          <Section
            backgroundColor="white"
            style={{ justifyContent: 'space-between', height: 'auto' }}
            ref={section2Ref}
          >
            <BlockContainer>
              <SectionTitle>{t('A13')}</SectionTitle>
              <div style={{ display: 'flex', alignItems: 'center', zIndex: 100 }}>
                <SwiperStyle spaceBetween={50} slidesPerView={1} resize1={resize1}>
                  <SwiperBtnContainer>
                    <SwiperPrevBtn />
                    <SwiperNextBtn />
                  </SwiperBtnContainer>
                  {section3SwiperItem?.map((item: sectionItemType, index: number) => (
                    <SwiperSlide key={item.id} style={swiperSlideStyle}>
                      <SwiperIndexNum>
                        <Text color={'#0BBD58'}>{index + 1}</Text>
                        <Text>/3</Text>
                      </SwiperIndexNum>
                      <SwiperContainer resize1={resize1}>
                        <img
                          src={item.img}
                          style={{ width: item?.size ? item?.size : 250, padding: '20px 0' }}
                        />
                        <SwiperTextContainer>
                          <SwiperTitle color={item?.title1Color}>{item.title1}</SwiperTitle>
                          <SwiperTitle greenText>{item?.title2}</SwiperTitle>
                          <Text>{item?.description1}</Text>
                          <Text>{item?.description2}</Text>
                        </SwiperTextContainer>
                      </SwiperContainer>
                    </SwiperSlide>
                  ))}
                </SwiperStyle>
              </div>
            </BlockContainer>
            {!isMobile && (
              <TreeImgContainer>
                <img src="/images/introduce/tree-left-bottom.png" style={{ width: 100 }} />
                <img src="/images/introduce/tree-right-bottom.png" style={{ width: 100 }} />
              </TreeImgContainer>
            )}

            <BackgroundBlock />
          </Section>
          <Section
            backgroundColor="white"
            style={{ justifyContent: 'space-between' }}
            ref={section3Ref}
          >
            <BlockContainer>
              <SectionTitle>{t('A16')}</SectionTitle>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SwiperStyle resize1={resize1} spaceBetween={50} slidesPerView={1}>
                  <SwiperBtnContainer>
                    <SwiperPrevBtn />
                    <SwiperNextBtn />
                  </SwiperBtnContainer>
                  {section4SwiperItem?.map((item: sectionItemType, index: number) => (
                    <SwiperSlide key={item.id} style={swiperSlideStyle}>
                      <SwiperIndexNum>
                        <Text color={'#0BBD58'}>{index + 1}</Text>
                        <Text>/3</Text>
                      </SwiperIndexNum>
                      <SwiperContainer resize1={resize1}>
                        <img
                          src={item.img}
                          style={{ width: item?.size ? item?.size : 250, padding: '20px 0' }}
                        />
                        <SwiperTextContainer>
                          <SwiperTitle color={item?.title1Color}>{item.title1}</SwiperTitle>
                          <SwiperTitle greenText>{item?.title2}</SwiperTitle>
                          <Text>{item?.description1}</Text>
                          <Text>{item?.description2}</Text>
                        </SwiperTextContainer>
                      </SwiperContainer>
                    </SwiperSlide>
                  ))}
                </SwiperStyle>
              </div>
            </BlockContainer>
            <BackgroundBlock backgroundColor="#3E3F5E" />
            <img
              src="/images/introduce/section4.png"
              style={{ position: 'absolute', width: 200, right: 0, bottom: 0 }}
            />
          </Section>
          ;
          {/* <Section ref={section4Ref} style={{ height: resize1 ? 'auto' : '100vh' }}>
            <SectionTitle>{t('A24')}</SectionTitle>
            <Swiper
              style={{ maxHeight: '60%', overflow: 'initial' }}
              pagination={true}
              spaceBetween={50}
              slidesPerView={resize1 ? 1 : 4}
              onSlideChange={() => console.log('slide change')}
            >
              <SwiperBtnContainer2 resize1={resize1}>
                <SwiperPrevBtn />
                <SwiperNextBtn />
              </SwiperBtnContainer2>
              {section5SwiperItem?.map((item: sectionItemType, index: number) => (
                <SwiperSlide key={item.id} style={swiperSlideStyle2}>
                  <SwiperTextContainer>
                    <Text color="#616161">Sample 0{index + 1}.</Text>
                    <SwiperTitleContainer bgColor={item?.bgColor}>
                      <Text color={item.title1Color ? item.title1Color : 'white'}>
                        {item.title1}
                      </Text>
                    </SwiperTitleContainer>
                  </SwiperTextContainer>
                  <img src={item.img} style={{ width: resize1 ? 250 : '70%' }} />
                </SwiperSlide>
              ))}
            </Swiper>
            {!isMobile && (
              <img
                src="/images/introduce/section5-5.png"
                style={{ width: 300, position: 'absolute', bottom: 20, right: 0 }}
              />
            )}
          </Section> */}
          <Section
            backgroundColor="#011308"
            style={{
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: 'auto',
              paddingTop: 30,
            }}
            ref={section5Ref}
          >
            <ColumnCenterDiv style={{ zIndex: 2 }}>
              <SectionTitle color="white">{t('A25')}</SectionTitle>
              <div>
                <OhImg resize1={resize1} />
                <ItemContainer style={{ marginBottom: 30 }}>
                  <ItemWrapper>
                    <ItemWrapperImg src="/images/introduce/section5-1.png" />
                    <ItemTitle>{t('A26')}</ItemTitle>
                    <ItemTitle>{t('A26_1')}</ItemTitle>
                    <Text>{t('A27')}</Text>
                  </ItemWrapper>
                  <ItemWrapper>
                    <ItemWrapperImg src="/images/introduce/section5-2.png" />
                    <ItemTitle>{t('A27')}</ItemTitle>
                    <Text>{t('A27_1')}</Text>
                  </ItemWrapper>
                  <ItemWrapper>
                    <ItemWrapperImg src="/images/introduce/section5-3.png" />
                    <ItemTitle>{t('A29')}</ItemTitle>
                    <Text>{t('A30')}</Text>
                  </ItemWrapper>
                  <ItemWrapper>
                    <ItemWrapperImg src="/images/introduce/section5-4.png" />
                    <ItemTitle>{t('A31')}</ItemTitle>
                    <Text>{t('A32')}</Text>
                  </ItemWrapper>
                </ItemContainer>
              </div>
            </ColumnCenterDiv>
            <MossImg />
          </Section>
          <Section
            ref={section6Ref}
            style={{
              paddingTop: 50,
            }}
          >
            <DownloadContainer>
              <BackgroundImg src="/images/introduce/section6-back.png" />
              <DownloadItemWrapper>
                <Section6ImgContainer>
                  <Section6LeftImg src="/images/introduce/section6-1.png" />
                  <Section6RightImg src="/images/introduce/section6-2.png" />
                </Section6ImgContainer>
                <DownloadItemContainer>
                  <ColumnCenterDiv style={{ alignItems: 'flex-start', marginRight: 30 }}>
                    <img
                      src="/images/introduce/logo_w.png"
                      style={{ width: 200, marginBottom: 10 }}
                    />
                    <Text color="white" fontSize={22} fontWeight={600}>
                      {t('A33')}
                    </Text>
                  </ColumnCenterDiv>
                  {isMobile ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {window.webViewType !== 'ios' && (
                        <DownloadBtn
                          onClick={() => {
                            window.open(
                              'https://play.google.com/store/apps/details?id=com.nbhd.ohmoss',
                              '_blank',
                            );
                          }}
                        >
                          <Text color="white">{t('A91')}</Text>
                        </DownloadBtn>
                      )}
                      <DownloadBtn
                        onClick={() => {
                          window.open(
                            'https://apps.apple.com/us/app/oh-moss/id1665052510',
                            '_blank',
                          );
                        }}
                      >
                        <Text color="white">{t('A92')}</Text>
                      </DownloadBtn>
                    </div>
                  ) : (
                    <>
                      <div style={{ display: 'flex' }}>
                        {window.webViewType !== 'ios' && (
                          <ColumnCenterDiv
                            style={{ marginRight: 30, cursor: 'pointer' }}
                            onClick={() => {
                              window.open(
                                'https://play.google.com/store/apps/details?id=com.nbhd.ohmoss',
                                '_blank',
                              );
                            }}
                          >
                            <QrImg src="/images/qrandroid.png" />
                            <Text color="white">{t('A91')}</Text>
                          </ColumnCenterDiv>
                        )}
                        <ColumnCenterDiv
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            window.open(
                              'https://apps.apple.com/us/app/oh-moss/id1665052510',
                              '_blank',
                            );
                          }}
                        >
                          <QrImg src="/images/qrios.png" />
                          <Text color="white">{t('A92')}</Text>
                        </ColumnCenterDiv>
                      </div>
                    </>
                  )}
                </DownloadItemContainer>
              </DownloadItemWrapper>
            </DownloadContainer>
            <InfoContainer>
              <Text fontWeight={700} style={{ textAlign: 'initial' }}>
                {t('A34')}
              </Text>
              <ul style={{ paddingInlineStart: 20 }}>
                <li>{t('A35')}</li>
                <li>{t('A35_1')}</li>
                <li>{t('A35_2')}</li>
                <li>{t('A35_3')}</li>
                <li>{t('A35_4')}</li>
              </ul>
              <Text fontWeight={700} style={{ textAlign: 'initial' }}>
                {t('A36')}
              </Text>
              <Resign />
            </InfoContainer>
          </Section>
        </Container>
      )}
    </>
  );
});

const swiperSlideStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  margin: '0 auto',
  alignItems: 'center',
};

const swiperSlideStyle2: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const menuListStyle: CSSProperties = {
  position: 'absolute',
  right: 10,
  backgroundColor: 'rgba(0,0,0,0.8)',
  borderRadius: 12,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minWidth: 150,
};

const Container = styled.div`
  white-space: pre-line;
`;

const Header = styled.div<{ resize1?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 98px;
  width: 100vw;
  position: absolute;
  max-width: 550px;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 100000;
  @media ${(props) => props.theme.media.mobile} {
    background-color: transparent;
  }
`;

const LogoImg = styled.img.attrs({
  src: '/images/introduce/logo_w.png',
})<{ size?: number }>`
  width: ${(props) => props.size || 150}px;
`;

const Btn = styled.button`
  background: #011308;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  padding: 10px;
  align-items: center;
  justify-content: center;
  margin: 20px;
`;

const Section = styled.div<{ backgroundColor?: string; height?: string }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: ${(props) => props.height || '100vh'};
  justify-content: center;
  overflow: hidden;
  position: relative;
  align-items: center;
  background-color: ${(props) => props.backgroundColor || '#f6f6f6'};
  @media ${(props) => props.theme.media.mobile} {
    height: auto;
  }
`;

const Section1 = styled(Section)`
  background-color: #3e3f5e;
  height: 100%;
  padding-top: 180px;
  justify-content: flex-end;
  @media ${(props) => props.theme.media.mobile} {
    height: 100%;
    padding-top: 100px;
  }
`;

const Section2 = styled(Section)`
  height: 100%;
  @media screen and (max-width: 880px) {
    flex-direction: column;
    height: auto;
  }
`;

const Text = styled.text<{ color?: string; fontSize?: number; fontWeight?: number }>`
  color: ${(props) => props.color || 'black'};
  font-size: ${(props) => props.fontSize || 14}px;
  font-weight: ${(props) => props.fontWeight || 500};
  text-align: center;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 14px;
  }
`;

const SectionTitle = styled.text<{ color?: string }>`
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  text-align: center;
  white-space: pre-line;
  margin-bottom: 30px;
  letter-spacing: 0.374px;
  color: ${(props) => props.color || '#011208'};
  @media ${(props) => props.theme.media.mobile} {
    font-size: 22px;
    margin: 50px 0 30px;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ItemContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  max-width: 900px;
  flex-wrap: wrap;
  @media screen and (max-width: 880px) {
    flex-direction: column;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 32px;
  margin: 16px;
  padding: 20px;
  min-width: 250px;
`;

const Section2ItemWrapper = styled(ItemWrapper)`
  height: 350px;
  justify-content: space-around;
  padding: 50px;
  z-index: 10;
  @media ${(props) => props.theme.media.mobile} {
  }
`;

const GreenBorder = styled.div`
  border: 2px solid #009247;
  padding: 10px 18px;
  border-radius: 999px;
`;

const SwiperStyle = styled(Swiper)<{ resize1?: boolean }>`
  max-width: ${(props) => (props.resize1 ? 330 : 800)}px;
  display: flex;
  flex-direction: ${(props) => (props.resize1 ? 'column' : 'row')};
  border: 1px solid #e2e2e2;
  border-radius: 24px;
  background-color: white;
`;

const SwiperContainer = styled.div<{ resize1?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.resize1 ? 'column' : 'row')};
`;

const SwiperTitle = styled(Text)<{ greenText?: boolean; color?: string }>`
  font-size: 25px;
  font-weight: 700;
  color: ${(props) => props.color || '#0BBD58'};
  @media ${(props) => props.theme.media.mobile} {
    font-size: 22px;
  }
`;

const SwiperTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${(props) => props.theme.media.mobile} {
    padding: 0 0 30px;
  }
`;

const SwiperIndexNum = styled.div`
  display: flex;
  position: absolute;
  right: 40px;
  top: 30px;
`;

const MenuListItem = styled.button`
  margin: 0 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const Section2GreenText = styled(Text)`
  color: #009247;
  font-size: 16px;
  font-weight: 800;
`;

const Section2BackImg = styled.img.attrs({ src: '/images/introduce/section2-3.png' })`
  width: 300px;
  bottom: 100px;
  left: 48%;
  position: relative;
  @media ${(props) => props.theme.media.mobile} {
    bottom: 40px;
  }
`;

const SwiperBtn = styled.button<{ resize1?: boolean }>`
  cursor: pointer;
  border: 3px solid #011308;
  border-radius: 30px;
  width: ${(props) => (props.resize1 ? 30 : 60)}px;
  height: ${(props) => (props.resize1 ? 30 : 60)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  margin: ${(props) => (props.resize1 ? '0' : '0 30px')};
  @media ${(props) => props.theme.media.mobile} {
    position: relative;
  }
`;

const SwiperLeftBtn = styled(SwiperBtn)`
  background-color: #0bbd58;
`;

const SwiperRightBtn = styled(SwiperBtn)`
  background-color: #ffd91a;
`;

const SwiperBtnImg = styled.img<{ resize1?: boolean }>`
  width: ${(props) => (props.resize1 ? 10 : 16)}px;
`;

const BackgroundBlock = styled.div<{ backgroundColor?: string; height?: string }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: ${(props) => props.backgroundColor || '#0BBD58'};
  height: ${(props) => props.height || '20%'};
  @media ${(props) => props.theme.media.mobile} {
    height: ${(props) => props.height || 250}px;
  }
`;

const InfoContainer = styled.div`
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  @media ${(props) => props.theme.media.mobile} {
    margin-bottom: 80px;
  }
`;

const DownloadItemContainer = styled.div`
  background: #0bbd58;
  border: 3px solid #011308;
  border-radius: 48px;
  z-index: 2;
  position: relative;
  width: 100%;
  padding: 50px 30px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @media ${(props) => props.theme.media.mobile} {
    flex-direction: column;
  }
`;

const Section6ImgContainer = styled.div`
  display: flex;
  position: absolute;
  justify-content: space-between;
  width: 100%;
  bottom: -30px;
`;

const Section6LeftImg = styled.img`
  position: relative;
  z-index: 3;
  width: 150px;
`;

const Section6RightImg = styled.img`
  position: relative;
  z-index: 3;
  width: 100px;
`;

const DownloadItemWrapper = styled.div`
  display: flex;
  max-width: 1000px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 20px auto;
  padding: 20px;
`;

const DownloadContainer = styled.div`
  height: 50%;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BackgroundImg = styled.img`
  position: absolute;
  width: 100vw;
  max-height: 50%;
  z-index: 0;
`;

const ColumnCenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
`;

const QrImg = styled.img`
  width: 70px;
  margin-bottom: 10px;
`;

const ItemWrapperImg = styled.img`
  width: 200px;
`;

const SwiperTitleContainer = styled.div<{ bgColor?: string }>`
  border-radius: 999px;
  display: flex;
  justify-content: center;
  padding: 5px 15px;
  margin: 10px 0 20px;
  background-color: ${(props) => props.bgColor || '#f6f6f6'};
`;

const BlockContainer = styled.div`
  position: relative;
  padding: 5% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ItemTitle = styled(Text)`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
  right: -30px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 0;
`;

const DownloadBtn = styled(Btn)`
  cursor: pointer;
  backgorund-color: #001206;
  border-radius: 12px;
  @media ${(props) => props.theme.media.mobile} {
    margin: 10px 0;
  }
`;

const OhImg = styled.img.attrs({ src: '/images/introduce/oh.png' })<{ resize1?: boolean }>`
  width: 100px;
  position: absolute;
  height: 45px;
  left: ${(props) => (props.resize1 ? 0 : '10%')};
`;

const MossImg = styled.img.attrs({
  src: '/images/introduce/moss.png',
})`
  width: 280px;
  height: 260px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  @media ${(props) => props.theme.media.mobile} {
    position: relative;
    margin-left: auto;
  }
`;

const UnderlineImg = styled.img.attrs({ src: '/images/introduce/underline.png' })`
  width: 30px;
  height: 10px;
`;

const MenuImg = styled.img.attrs({ src: '/images/introduce/menu.png' })`
  width: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const LogoBtn = styled.div`
  cursor: pointer;
`;

const Section1TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  @media ${(props) => props.theme.media.mobile} {
    margin-bottom: 100px;
  }
`;

const SwiperBtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 50%;
`;

const SwiperBtnContainer2 = styled.div<{ resize1?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.resize1 ? 'space-between' : 'center')};
  margin-bottom: 30px;
  width: ${(props) => (props.resize1 ? '100px' : 'initial')};
  margin: 0 auto 30px;
`;

const TreeImgContainer = styled.div`
  display: flex;
  position: absolute;
  justify-content: space-between;
  width: 100%;
  bottom: 30px;
  z-index: 2;
`;

const LoginButtonStyle = styled.div`
  background: #0bbd58;
  padding: 10px;
  border-radius: 15px;
  margin: 10px 0;
  width: 100px;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
`;
export default OhmossIntroducePage;
