import React, { useEffect, useState } from 'react';
import { ChannelProfileModel, CustomType } from 'types/CommonTypes';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { TextAreaStyle } from 'features/adminFeatures/template/components/TemplateStyle';
import {
  Title,
  SelfIntroduction,
  SocialComponent,
} from 'features/adminFeatures/template/components';
import FollowButtonsContainer from 'features/mainFeatures/follow/containers/FollowButtonsContainer';

import styled, { css } from 'styled-components';

type BoxType = 'simple' | 'filled';
const NameCard = ({
  profileInfo,
  type,
}: {
  profileInfo: ChannelProfileModel | undefined;
  type: BoxType;
}) => {
  const { socialStore, uiStores } = useStore();
  const { userHomeStore } = uiStores;

  const [socialPosition, setSocialPosition] = useState<'top' | 'bottom'>();

  useEffect(() => {
    if (typeof profileInfo?.metadataObject?.socialProps?.position === 'undefined') {
      setSocialPosition('top');
    } else {
      setSocialPosition(profileInfo?.metadataObject?.socialProps?.position);
    }
  }, [profileInfo]);

  return (
    <>
      {userHomeStore.currentBoard?.useProfile !== false && (
        <TitleAreaStyle type={type}>
          {typeof profileInfo?.nickname !== 'undefined' && profileInfo?.nickname !== null && (
            <Row>
              <Title
                title={profileInfo?.nickname}
                mode="view"
                titleProps={profileInfo?.metadataObject?.nicknameProps?.style}
              />
            </Row>
          )}
          {socialStore?.socials?.length > 0 && (
            <>
              {socialPosition === 'top' && (
                <div style={{}}>
                  <SocialComponent
                    socials={socialStore?.socials}
                    socialProps={profileInfo?.metadataObject?.socialProps}
                  />
                </div>
              )}
            </>
          )}
          {typeof profileInfo?.introduction !== 'undefined' && (
            <SelfIntroduction
              selfIntroduction={profileInfo?.introduction}
              mode="view"
              selfIntroductionProps={profileInfo?.metadataObject?.introductionProps}
            />
          )}

          {!window.isWebView && (
            <div style={{ marginTop: 10 }}>
              <Row>
                <FollowButtonsContainer />
              </Row>
            </div>
          )}
        </TitleAreaStyle>
      )}
    </>
  );
};

const simpleStyle = css`
  padding: 0;
  border-radius: 20px;
`;

const filledStyle = css`
  background: #fff;
  position: sticky;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  top: 0;
`;

const TitleAreaStyle = styled(TextAreaStyle) <{ customStyle?: CustomType; type: BoxType; }>`
  max-width: 550px;
  min-height: 80px;
  margin: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  position: relative;
  height: auto;
  padding: 20px 0;
  margin-bottom: 20px;

  ${(props) => {
    if (props.type === 'simple') {
      return simpleStyle;
    } else if (props.type === 'filled') {
      return filledStyle;
    }
  }}
  @media
    ${(props) => props.theme.media.mobile} {
    border-radius: 0;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  > div {
    ${(props) => props.customStyle?.titleStyle}
  }
`;

export default NameCard;
