import { makeObservable, flow, observable, action } from 'mobx';
import { Log as LogService } from 'services/Log';
import { HttpClient } from 'services/http-client';
import { SignatureDto } from 'services/data-contracts';
import _ from 'lodash';
import ServiceStoreBase from './ServiceStoreBase';

/**
 * @description Public Link Store
 */
class LogStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      createLog: flow,
    });
  }

  api: LogService<unknown> = new LogService(new HttpClient());

  /** ========================================================================================= */

  // *getItems(args?: Array<any>) {
  *createLog(log: SignatureDto, referrer: string) {
    yield this.api.createEventLinkClickLog(log, {
      headers: {
        referrer,
      },
    });
  }
}

export default LogStore;
