import Text from 'components/commons/Text';
import { useMediaContext } from 'providers/useMediaContext';
import React, { useEffect, useState } from 'react';
import { ChannelDto } from 'services/data-contracts';
import styled from 'styled-components';

const MembershipInfoCard = ({
  channelType,
  membership,
  hideMonth,
}: {
  channelType?: ChannelDto['channelType'];
  membership?: any;
  hideMonth?: boolean;
}) => {
  const ScreenTypes = useMediaContext();
  const getPurchaseDate = (date: string) => {
    if (date) {
      const newDate = new Date(date);
      const year = newDate.getFullYear();
      const month = `0${newDate.getMonth() + 1}`.slice(-2);
      const day = `0${newDate.getDate()}`.slice(-2);
      const localDateString = `${year}년 ${month}월 ${day}일`;
      return localDateString;
    }
  };

  const getMembershipPeriod = (startDate: string, endDate: string) => {
    if (startDate && endDate) {
      const newStartDate = new Date(startDate);
      const startDateString = newStartDate.toLocaleDateString();
      const newEndDate = new Date(endDate);
      const endDateString = newEndDate.toLocaleDateString();
      return (
        <>
          {startDateString} ~ {endDateString}
        </>
      );
    }
  };

  const formatNumberWithCommas = (number: number) => {
    if (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  };

  const getMembershipName = (name: any) => {
    if (name) {
      return <>{name}멤버십을 이용중입니다.</>;
    } else {
      return 'FREE 멤버십';
    }
  };

  return (
    <CardContainer>
      <CardLine>
        <CardItem style={{ fontWeight: 500 }}>
          <Text type={ScreenTypes.isLarge ? 'H3' : 'H4'}>
            {getMembershipName(membership?.membershipPlan?.name)}
          </Text>
          <Text buttonRole="DESCRIPTION" style={{ marginTop: 5 }}>
            {getMembershipPeriod(membership?.purchaseDate, membership?.expirationDate)}
          </Text>
        </CardItem>
        {hideMonth ? (
          <></>
        ) : (
          <Status style={{ padding: '0 10px' }}>
            {membership?.monthly ? `${membership?.monthly}개월 이용중` : '내 구독'}
          </Status>
        )}
      </CardLine>

      <CardLine style={{ padding: '0 1.5rem' }}>
        <Divider />
      </CardLine>

      {membership?.length > 0 && (
        <>
          <CardLine>
            <CardItem>결제일 {console.log(membership)}</CardItem>
            <CardItem>{getPurchaseDate(membership?.purchaseDate)}</CardItem>
          </CardLine>

          <CardLine style={{ padding: '0 1.5rem' }}>
            <Divider />
          </CardLine>

          <CardLine>
            <CardItem>결제 금액</CardItem>
            <CardItem>{formatNumberWithCommas(membership?.order?.totalAmount)}원</CardItem>
          </CardLine>
        </>
      )}
    </CardContainer>
  );
};

const CardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  border-radius: 1.25rem;
  align-self: stretch;
  border: 2px solid #173300;
  /* background-color: #173300; */
`;

const CardLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px;
  @media ${(props) => props.theme.media.desk} {
    padding: 25px;
  }
`;

const CardItem = styled.div`
  /* color: #fff; */
  font-size: 1rem;
  font-weight: 100;
`;

const Divider = styled.div`
  width: 100%;
  border: 0.9px dashed rgba(255, 255, 255, 0.45);
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  border-radius: 30px;
  border: 1px solid rgba(239, 239, 239, 0.5);
  color: #fff;
  font-size: 0.75rem;
  background: var(--primary-color);
  @media ${(props) => props.theme.media.desk} {
    padding: 15px;
  }
`;

export default MembershipInfoCard;
