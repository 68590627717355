import React, { useState, useEffect, CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { parseJSONAll } from 'libs/helper/utils';
import { useStore } from 'stores/StoreHelper';
import { Row } from 'components/commons/layouts';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DealItemDetail from 'pages/DealItemDetail';
import Countdown from 'react-countdown';
import moment from 'moment';
import boardCommonLogics from 'features/board/logic/boardCommonLogic';
import { IDealBlock } from 'types/BlockExtendsTypes';
import { DealServiceItemModel } from 'types/CommonTypes';
import DealServiceItems from 'features/csTown/commonComponents/DealServiceItems';
import { DealItemContainerStyle } from 'features/csTown/commonComponents/styles';
import { Avatar } from '@mui/material';
import { toast } from 'react-toastify';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useChat } from 'libs/hooks/useChat';
import Text from 'components/commons/Text';
import { OhmossButton } from 'resources/controls/OhmossButton';
import LinkServicePageController from 'features/linkService/pages/pageControllers/LinkServicePageController';
import useNavigation from 'hooks/useNavigation';
import { ContentWrapper } from '../styles/ContentWrapperStyle';
import CommonThumbnail from '../../commonControl/CommonThumbnail';
import TitleStickerBox from './TitleStickerBox';
import { Divider } from 'features/myPage/components/CommonStyleComponent';
import InfoBox from 'components/commons/InfoBox';

const DealTemplate = ({
  item,
  textStyle,
  boxStyle,
  editMode,
}: {
  item: IDealBlock;
  textStyle?: CSSProperties;
  boxStyle?: CSSProperties;
  editMode?: boolean;
}) => {
  const { uiStore, linkStore } = useStore();
  const { getImageFromDom } = boardCommonLogics();
  const { currentChannel } = useChannelContext();

  const { handleClickInquiry } = LinkServicePageController();

  const [showServiceItems, setShowServiceItems] = useState<boolean>(false);
  const [dealItem, setDealItem] = useState<IDealBlock>();
  const [showCountDown, setShowCountDown] = useState<boolean>(false);
  const [thumbnailUrl, setThumbnailUrl] = useState<string>();

  const getDealTemplatetyle = () => {
    switch (item.layout) {
      case 'TypeB':
        return (
          <div style={{}}>
            <div
              style={{
                // aspectRatio: '16/9',
                // overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ImageStyle
                editMode={editMode}
                src={thumbnailUrl}
                alt=""
                style={{
                  cursor: 'pointer',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  paddingRight: 0,
                }}
                onClick={handleClickDeal}
              />
              {item?.faviconUrl && editMode !== true && (
                <FaviconStyle>
                  <img src={item?.faviconUrl} alt="" />
                </FaviconStyle>
              )}
            </div>
            <ContentWrapper
              style={{
                padding: 20,
                paddingTop: 5,
                flexDirection: 'row',
                alignItems: item?.contentAlign,
              }}
            >
              <TitleStickerBox
                item={item}
                style={{
                  alignItems:
                    item?.contentAlign === 'left'
                      ? 'flex-start'
                      : item?.contentAlign === 'right'
                      ? 'flex-end'
                      : 'center',
                  paddingRight: 0,
                }}
                textStyle={textStyle}
                boxStyle={boxStyle}
              />
            </ContentWrapper>
          </div>
        );
      case 'TypeC':
        return (
          <div style={{ padding: 20 }}>
            <ContentWrapper
              style={{
                padding: 20,
                paddingTop: 5,
                flexDirection: 'row',
                textAlign: 'center',
              }}
            >
              <TitleStickerBox
                item={item}
                style={{
                  alignItems:
                    item?.contentAlign === 'left'
                      ? 'flex-start'
                      : item?.contentAlign === 'right'
                      ? 'flex-end'
                      : 'center',
                  paddingRight: 0,
                }}
                textStyle={textStyle}
                boxStyle={boxStyle}
              />
            </ContentWrapper>
            <div
              style={{
                // aspectRatio: '20/9',
                // overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 10,
              }}
            >
              <ImageStyle
                editMode={editMode}
                src={thumbnailUrl}
                alt=""
                style={{
                  cursor: 'pointer',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  overflow: 'hidden',
                }}
                onClick={handleClickDeal}
              />
            </div>
          </div>
        );
      case 'TypeD':
        return (
          <div>
            <div
              style={{
                aspectRatio: '1/1',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ImageStyle
                editMode={editMode}
                src={thumbnailUrl}
                alt=""
                style={{
                  cursor: 'pointer',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  overflow: 'hidden',
                }}
                onClick={handleClickDeal}
              />
              <ContentWrapper
                style={{
                  flexDirection: 'row',
                  textAlign: 'center',
                  position: 'absolute',
                  borderRadius: 10,
                  bottom: 20,
                  left: 20,
                  right: 20,
                  width: 'calc(100% - 40px)',
                  backgroundColor: 'rgba(255,255,255,0.7)',
                }}
              >
                <TitleStickerBox
                  item={item}
                  style={{
                    alignItems:
                      item?.contentAlign === 'left'
                        ? 'flex-start'
                        : item?.contentAlign === 'right'
                        ? 'flex-end'
                        : 'center',
                  }}
                  textStyle={textStyle}
                  boxStyle={boxStyle}
                />
              </ContentWrapper>
            </div>
          </div>
        );
      default:
        return (
          <Row style={{ justifyContent: 'flex-start', padding: '0 5px' }} onClick={handleClickDeal}>
            <div style={{ marginRight: thumbnailUrl && 10 }}>
              <div style={{ padding: '5px 0' }}>
                <CommonThumbnail
                  parent={boxStyle?.borderRadius}
                  initialValue={thumbnailUrl}
                  style={{ borderRadius: boxStyle?.borderRadius }}
                />
              </div>
            </div>
            <ContentWrapper
              style={{
                padding: !thumbnailUrl ? '10px' : 0,
                flexDirection: 'row',
              }}
            >
              <TitleStickerBox
                item={item}
                style={{
                  alignItems:
                    item?.contentAlign === 'left'
                      ? 'flex-start'
                      : item?.contentAlign === 'right'
                      ? 'flex-end'
                      : 'center',
                }}
                textStyle={textStyle}
                boxStyle={boxStyle}
              />
            </ContentWrapper>
          </Row>
        );
    }
  };

  const setCountDown = () => {
    if (item) {
      const si = setInterval(() => {
        const now = moment();
        const endDate = moment(item.startDate);
        const result = endDate < now;
        setShowCountDown(result);
        if (result === false) {
          clearInterval(si);
        }
      }, 1000);
    }
  };

  // const ConfirmLogin = () => {
  //   return (
  //     <Row style={{ flexDirection: 'column', alignItems: 'center', gap: 5 }}>
  //       <Text type="H4" style={{ marginBottom: 20 }}>
  //         로그인이 필요한 서비스입니다.
  //       </Text>
  //       <OhmossButton type="primaryPc" style={{ width: 150 }} onClick={handleSignup}>
  //         회원가입하기
  //       </OhmossButton>
  //       <OhmossButton type="link" onClick={handleLogin}>
  //         로그인
  //       </OhmossButton>
  //       <Divider style={{ width: 200 }} />
  //       <OhmossButton type="link" onClick={handleGuestInquiry}>
  //         비회원으로 문의하기
  //       </OhmossButton>

  //       <InfoBox style={{ margin: 20 }}>
  //         "비회원으로 문의하기"는 동일한 브라우저에서만 판매자의 답글 확인이 가능합니다.
  //       </InfoBox>
  //     </Row>
  //   );
  // };

  const setRedirectUrl = () => {
    const url = {
      path: `/${currentChannel?.channelName}/inquiry/${dealItem?.linkKey as string}`,
    };
    localStorage.setItem('redirectUrl', JSON.stringify(url));
  };

  // const handleLogin = () => {
  //   setRedirectUrl();
  //   navigate('/login');
  //   uiStore?.universalModal.close();
  // };

  // const handleSignup = () => {
  //   setRedirectUrl();
  //   navigate('/sign-up');
  //   uiStore?.universalModal.close();
  // };

  // const handleGuestInquiry = () => {
  //   beginChat(item);
  // };

  const handleClickDeal = () => {
    if (item?.linkKey) linkStore.createLog(item?.linkKey);

    uiStore.modal.show({
      style: { maxHeight: '80vh' },
      children: <DealItemDetail isOwner post={item} onClickFavorite={() => {}} />,
    });
  };

  // const handleClickInquiry = () => {
  //   if (myChannel) {
  //     if (myChannel?.id !== item?.channel?.id) {
  //       beginChat(item);
  //     } else {
  //       toast.warn('본인이 올린 공구에는 문의를 할 수 없습니다.');
  //     }
  //   } else {
  //     uiStore.universalModal.show({
  //       modalOption: { style: { maxWidth: 500, maxHeight: 400 } },
  //       children: <ConfirmLogin dealItem={item} />,
  //     });
  //   }
  // };

  useEffect(() => {
    if (item) {
      const blockItems = item?.items?.map((item) => ({
        ...item,
        ...(parseJSONAll(item?.metadata) as DealServiceItemModel),
      }));

      const newBlockModel = {
        ...item,
        items: blockItems?.filter((temp) => temp.isActive === true),
      } as IDealBlock;

      setDealItem(newBlockModel);
      if (item.status === 'ONGOING') {
        setCountDown();
      }

      if (!item.thumbnail?.publicUrl) {
        const thumbnail = getImageFromDom(item.content);
        if (thumbnail) {
          setThumbnailUrl(thumbnail);
        }
      } else {
        setThumbnailUrl(item.thumbnail?.publicUrl);
      }
    }
  }, [item]);

  return (
    <ContainerStyle>
      <div style={{ position: 'relative' }}>
        {showCountDown && item.status === 'ONGOING' && (
          <CountDownStyle>
            <Countdown
              daysInHours
              date={item.endDate}
              onComplete={() => {
                // handleTimerComplete();
              }}
            />
          </CountDownStyle>
        )}
        {editMode === true && item?.status !== 'ONGOING' && (
          <EditModeStyle>
            <Row>
              <InfoOutlinedIcon />
              <div>진행중이 아닌 딜은 다른 사용자에게 표시되지 않습니다.</div>
            </Row>
          </EditModeStyle>
        )}
        {getDealTemplatetyle()}
      </div>
      <>
        {editMode !== true && (
          <div style={{ background: 'rgba(255,255,255, 1)' }}>
            {((dealItem?.items && dealItem.items?.length > 0) || item.useInquiry) && (
              <ExpandButtonStyle
                onClick={() => {
                  setShowServiceItems((prev) => !prev);
                }}
              >
                <div style={{ fontSize: 12 }}>CS보기</div>
                {showServiceItems ? (
                  <ExpandLessIcon style={{ color: '#000' }} />
                ) : (
                  <ExpandMoreIcon />
                )}
                {/* <ExpandMoreIcon style={{ color: '#000' }} /> */}
              </ExpandButtonStyle>
            )}
            <>
              {showServiceItems && (
                <>
                  <div style={{ borderTop: '1px solid #ececec', margin: '0 20px', paddingTop: 10 }}>
                    {item?.useInquiry && (
                      <DealItemContainerStyle style={{ cursor: 'pointer', border: 0 }}>
                        <Avatar src="/images/cstown/manager.svg" />
                        <InquiryStyle
                          style={{ cursor: 'pointer', marginLeft: 10 }}
                          onClick={() => {
                            handleClickInquiry(item);
                          }}
                        >
                          톡으로 문의하기
                        </InquiryStyle>
                      </DealItemContainerStyle>
                    )}
                  </div>
                  <DealServiceItems items={dealItem?.items} mode="VIEW" />
                </>
              )}
            </>
          </div>
        )}
      </>
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  width: 100%;
`;

const ImageStyle = styled.img<{ editMode?: boolean }>`
  width: 100%;
`;

const ExpandButtonStyle = styled.div`
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  cursor: pointer;
  box-shadow: '0px 0px 10px rgba(0, 0, 0, 0.22)';
  padding: 10px;
`;

const CountDownStyle = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  color: #fff;
  border-radius: 10px;
`;

const EditModeStyle = styled.div`
  background: #ffae00;
  padding: 5px 10px;
`;

const FaviconStyle = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background: #fff;
  border-radius: 10px;
  width: 32px;
  height: 32px;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
`;

const InquiryStyle = styled.div`
  background: #efefef;
  padding: 8px 20px;
  border-radius: 20px;
  align-items: center;
  display: -ms-flexbox;
`;

export default DealTemplate;
