import styled from 'styled-components';

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  @media ${(props) => props.theme.media.mobile} {
    padding: 20px;
    width: 100%;
  }
`;

export const MainText = styled.div`
  text-align: start;
  color: rgba(0, 0, 0, 0.87);
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 10px;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 28px;
  }
`;

export const SubText = styled.div`
  text-align: start;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  font-weight: 300;
  max-width: 500px;
  line-height: 31px;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 18px;
  }
`;

export const DetailText = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 18px;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-bottom: 10px;
`;

export const BodyContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 1200px;
  gap: 40px;
  justify-content: center;
  @media ${(props) => props.theme.media.mobile} {
    flex-direction: column;
    gap: 0;
  }
`;

export const CenterImg = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex: 0.9;
  @media ${(props) => props.theme.media.mobile} {
    flex: 1;
  }
`;

export const GnbComponentStyle = styled.div`
  position: fixed;
  top: 10px;
  z-index: 1000;
  left: 20px;
  right: 20px;
  margin: auto;
  bottom: unset;
  @media ${(props) => props.theme.media.mobile} {
    position: fixed;
    border-radius: 0;
    top: 0px;
    box-shadow: none;
    left: 0;
    right: 0;
  }
`;
