import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import styled from 'styled-components';

const NotFoundPage = () => {
  const handleGoMain = () => {
    window.location.href = '/';
  };

  const handleContactAdmin = () => {
    alert('준비중인 기능입니다');
  };

  return (
    <Container>
      <Content>
        <MossTitle>{process.env.REACT_APP_SERVEICE_NAME}</MossTitle>
        <Magnifier src={'/image/magnifier.png'} />
        <Title>페이지를 찾을 수 없습니다</Title>
        <Subtitle>주소를 확인하여주세요</Subtitle>
        <OptionContainer>
          <Option onClick={handleGoMain}>홈으로 가기</Option>,{' '}
          <Option onClick={handleContactAdmin}>관리자에게 연락하기</Option>
        </OptionContainer>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: ${window.innerHeight}px;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 50px 30px 50px 30px;
  background-color: #f8f8f8;
  border-radius: 50px;
  text-align: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 50%;
  margin: 0 0 50px 0;
`;

const Magnifier = styled.img`
  width: 30%;
  margin: 0 0 30px 0;
`;

const Title = styled.p`
  color: #222;
  font-weight: 500;
  font-size: 20px;
`;

const Subtitle = styled.a`
  font-size: 14px;
`;

const OptionContainer = styled.div`
  margin: 20px 0 0 0;
`;

const Option = styled.a`
  cursor: pointer;
  color: #3e64ea;
  font-size: 14px;
  text-decoration: underline;
`;

const MossTitle = styled.div`
  text-align: center;
  font-family: 'sacramento';
  font-size: 100px;
  color: #3e64ea;
  font-weight: 500;
`;

export default NotFoundPage;
