import React, { useEffect, useRef, useState } from 'react';

import { useRootContext } from 'libs/hooks/useRootContext';
import DashboardChartController from 'controllers/DashboardChartController';
import { ListBoxItemTemplate } from '../commonComponents/styles';
import moment from 'moment';
import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import Text from 'components/commons/Text';
import LineChart from 'features/myPage/components/dashboard/LineChart';
import { CountDto } from 'services/data-contracts';
import { Row } from 'components/commons/layouts';
import { AnimatedTabButton, TabItem } from 'components/commons/buttons/AnimatedTabButton';
import { IconButton } from '@mui/material';
import BarChart from 'features/myPage/components/dashboard/BarChart';

const CSTownDashboardPage = () => {
  const start = useRef<string>(moment().subtract(1, 'days').format('YYYY-MM-DD'));
  const end = useRef<string>(moment().subtract(30, 'days').format('YYYY-MM-DD'));

  const { myChannel } = useRootContext();
  const {
    getDailyVisitCounts,
    getDailyTotalLinkClickCountsByChannel,
    getDailyTotalLinkOutboundClickCounts,
  } = DashboardChartController();

  const [yesterdayVisitCount, setYesterdayVisitCount] = useState<number>();
  const [yesterdayClickCount, setYesterdayClickCount] = useState<number>();

  const [dailyVisitCounts, setDailyVisitCounts] = useState<CountDto[]>([]);
  const [dailyClickCounts, setDailyClickCounts] = useState<CountDto[]>([]);
  const [dailyClickOutboundCounts, setDailyClickOutboundCounts] = useState<CountDto[]>([]);

  const [trendType, setTrendType] = useState<'visit' | 'click'>('visit');

  const getVisitData = async () => {
    const t = (await getDailyVisitCounts(
      end.current,
      start.current as string,
    )) as unknown as CountDto[];
    if (t.length > 0) {
      setYesterdayVisitCount(t[0].count);
    }

    setDailyVisitCounts(t);
  };

  const getClikckData = async () => {
    const t = (await getDailyTotalLinkClickCountsByChannel(
      end.current,
      start.current as string,
    )) as unknown as CountDto[];
    if (t.length > 0) {
      setYesterdayClickCount(t[0].count);
    }

    setDailyClickCounts(t);
  };

  const getOutboundData = async () => {
    const t = (await getDailyTotalLinkOutboundClickCounts(
      end.current,
      start.current as string,
    )) as unknown as CountDto[];

    setDailyClickOutboundCounts(t);
  };

  const handleTimeRangeChange = (range: any) => {};

  const handleClickRange = (item: any) => {
    if (myChannel) {
      const range = Number(item.key);
      end.current = moment().subtract(range, 'days').format('YYYY-MM-DD');
      getDatas();
    }
  };

  const getDatas = () => {
    getVisitData();
    getClikckData();
    getOutboundData();
  };

  useEffect(() => {
    if (myChannel && start && end) {
      getDatas();
    }
  }, [myChannel, start, end]);

  return (
    <div style={{ overflow: 'scroll', height: 'calc(100vh - 110px)' }}>
      <Row style={{ justifyContent: 'flex-start' }}>
        <div style={{ flex: 0.7 }}>
          <AnimatedTabButton onClick={handleClickRange} style={{ width: '400px' }}>
            <TabItem key={1}>어제</TabItem>
            <TabItem key={3}>지난 3일</TabItem>
            <TabItem key={7}>지난 7일</TabItem>
            <TabItem key={30}>지난 30일</TabItem>
          </AnimatedTabButton>
          {/* <TimeDealPicker
            mode={'NEW'}
            timePickerType={'day'}
            start={start.current}
            end={end.current}
            timeRange={handleTimeRangeChange}
          /> */}
          <div style={{ margin: '20px 0' }}>
            <AnimatedTabButton style={{ width: 500 }}>
              <TabItem>
                <Row style={{ alignItems: 'flex-start' }}>
                  <IconButton style={{ background: '#B5E3F5' }}>
                    <DirectionsRunOutlinedIcon style={{ fontSize: 24 }} />
                  </IconButton>
                  <div
                    style={{ padding: 10, width: '100%' }}
                    onClick={() => {
                      setTrendType('visit');
                    }}
                  >
                    <Text type="H4" buttonRole="DESCRIPTION" style={{ whiteSpace: 'normal' }}>
                      어제 방문자수
                    </Text>
                    <Text type="H1" style={{ textAlign: 'right' }}>
                      {yesterdayVisitCount}
                    </Text>
                  </div>
                </Row>
              </TabItem>
              <TabItem>
                <Row style={{ alignItems: 'flex-start' }}>
                  <IconButton style={{ background: '#DBD3FB' }}>
                    <AdsClickOutlinedIcon style={{ fontSize: 24 }} />
                  </IconButton>
                  <div
                    style={{ padding: 10, width: '100%' }}
                    onClick={() => {
                      setTrendType('click');
                    }}
                  >
                    <Text type="H4" buttonRole="DESCRIPTION">
                      어제 클릭수
                    </Text>
                    <Text type="H1" style={{ textAlign: 'right' }}>
                      {yesterdayClickCount}
                    </Text>
                  </div>
                </Row>
              </TabItem>
            </AnimatedTabButton>
          </div>

          <ListBoxItemTemplate>
            <LineChart
              title={trendType === 'visit' ? '월간 방문자 수' : '월간 클릭 수'}
              visitCount={trendType === 'visit' ? dailyVisitCounts : dailyClickCounts}
            />
          </ListBoxItemTemplate>
          <ListBoxItemTemplate>
            <BarChart title="외부링크 이동 횟수" data={dailyClickOutboundCounts} />
          </ListBoxItemTemplate>
        </div>
        <div style={{ flex: 0.3 }}></div>
      </Row>
    </div>
    // <>{isMobile ? <Containers.MobileContainers.MobileRootContainer /> : <CSTownRootContainer />}</>
  );
};

export default CSTownDashboardPage;
