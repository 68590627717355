import React, { useEffect, useState } from 'react';
import { InternalLinkCard, SNSLinkItemModel } from 'types/CommonTypes';
import { observer } from 'mobx-react';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd';
import styled, { css } from 'styled-components';
import SNSItem from 'components/items/SNSItem';

const SimpleDragDropBox = observer(
  ({
    items,
    useOrigin,
    iconType,
    color,
    onChangeItemSeq,
  }: {
    items: Array<SNSLinkItemModel>;
    useOrigin?: boolean, iconType: 'color' | 'solid', color: string
    onChangeItemSeq: (items: Array<SNSLinkItemModel>) => void;
  }) => {
    const [newItems, setItems] = useState<Array<SNSLinkItemModel>>([]);
    const [rowItemCount, setRowItemCount] = useState<number>(2);
    const [height, setHeight] = useState<number>(100);

    const onChange = (
      sourceId: string,
      sourceIndex: number,
      targetIndex: number,
      targetId: string,
    ) => {
      const nextState = swap(newItems, sourceIndex, targetIndex);
      const orderedItems = nextState.map((item: SNSLinkItemModel, index: number) => {
        return { ...item, seq: index }
      })
      onChangeItemSeq(orderedItems);
      setItems(orderedItems);
    };

    useEffect(() => {
      if (items) {
        setItems(items);
        setRowItemCount(items.length)
      }
    }, [items]);

    return (
      <>
        <ConatinerStyle>
          <GridContextProvider onChange={onChange}>
            <GridDropZone
              id="items"
              boxesPerRow={rowItemCount}
              rowHeight={100}
              style={{ height: height, width: items.length * 100, margin: 'auto', background: '#efefef' }}
            >
              {newItems.map((item) => (
                <GridItem key={item.seq}
                  style={{
                    padding: 4,
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <SNSItem name={item.title!} useOrigin={useOrigin} iconType={iconType} color={color} />
                </GridItem>
              ))}
            </GridDropZone>
          </GridContextProvider>
        </ConatinerStyle>
      </>
    );
  },
);

const ConatinerStyle = styled.div``;

export default SimpleDragDropBox;
