import React, { CSSProperties } from 'react';
import { useStore } from 'stores/StoreHelper';
import { Row } from 'components/commons/layouts';
import { OhmossButton } from 'resources/controls/OhmossButton';

import styled from 'styled-components';
import moment from 'moment';

const CommonCalendarItem = ({ event, style }: { event: any; style?: CSSProperties }) => {
  const { uiStore } = useStore();

  return (
    <>
      <ContainerStyle style={style}>
        <TitleStyle style={{}}>{event?.title}</TitleStyle>
        <Row style={{ justifyContent: 'flex-start' }}>
          {event.alldayEvent ? (
            <>
              <DateStyle>{moment(event?.start).format('YYYY-MM-DD')} </DateStyle>
              <AlldayStyle>하루종일</AlldayStyle>
            </>
          ) : (
            <>
              <DateStyle>{moment(event?.start).format('YYYY-MM-DD hh:mm')} ~</DateStyle>
              <DateStyle> {moment(event?.start).format('YYYY-MM-DD hh:mm')}</DateStyle>
            </>
          )}
        </Row>
        <ContentsStyle>{event?.origin?.content}</ContentsStyle>
        <ButtonStyle>
          <OhmossButton
            onClick={() => {
              uiStore.universalModal.close();
            }}
          >
            Close
          </OhmossButton>
        </ButtonStyle>
      </ContainerStyle>
    </>
  );
};

const ContainerStyle = styled.div`
  padding: 20px;
  width: 80%;
  margin: auto;
`;

const TitleStyle = styled.div`
  font-weight: 700;
  font-size: 16px;
`;

const ContentsStyle = styled.div`
  margin-top: 20px;
  font-size: 14px;
`;

const DateStyle = styled.div`
  font-size: 14px;
  color: #4e4e4e;
`;

const AlldayStyle = styled.div`
  background: rebeccapurple;
  padding: 2px 10px;
  margin-left: 10px;
  border-radius: 8px;
  color: #fff;
`;

const ButtonStyle = styled.div`
  padding: 20px;
  margin-top: 30px;
`;

export default CommonCalendarItem;
