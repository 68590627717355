import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { PostModel } from 'types/CommonTypes';
import { ChannelBoardDto, PostDto } from 'services/data-contracts';

import styled from 'styled-components';
import VideoComponent from 'features/adminFeatures/commons/components/VideoComponent';
import Masonry from '@mui/lab/Masonry';
import PaginationBase from './PaginationBase';
import useNavigation from 'hooks/useNavigation';

const PinterestBoardType = observer(
  ({ posts, currentBoard }: { posts: Array<PostDto>; currentBoard: ChannelBoardDto | null }) => {
    const { postStore } = useStore();
    const { navigate } = useNavigation();
    // const { getThumbnail, handleClickPost } = boardCommonLogics();

    const handleClickPost = (post: PostDto) => {
      navigate(`/${post.channel?.channelName}/${post.board?.id}/${post.id}`);
    };

    useEffect(() => {
      console.log(posts);

      return () => {
        // postStore.unMount();
      };
    }, []);

    return (
      <>
        {posts && posts?.length > 0 && (
          <PaginationBase paginationType="infinity">
            <FeedContainerStyle>
              <Masonry columns={2} spacing={2}>
                <>
                  {posts?.map((post: PostModel) => (
                    <ImageContainerStyle
                      onClick={() => {
                        handleClickPost(post);
                      }}
                    >
                      {post.items?.[0]?.file?.contentType?.includes('video') ? (
                        <div style={{ width: '100%', height: 'auto' }}>
                          <VideoComponent
                            video={post.items![0].file}
                            isCover
                            style={{ pointerEvents: 'none' }}
                          />
                        </div>
                      ) : (
                        // <img src="https://ohmoss-dev.s3.ap-northeast-2.amazonaws.com/Post/ab34201e-4972-44db-a291-392543c308e5.jpeg" />
                        // <> {getThumbnail(post, { aspectRatio: 'unset' })}</>

                        <img src={post.items?.[0]?.file?.publicUrl} />
                      )}
                    </ImageContainerStyle>
                  ))}
                </>
              </Masonry>
            </FeedContainerStyle>
          </PaginationBase>
        )}
      </>
    );
  },
);

const FeedContainerStyle = styled.div`
  display: flex;
  max-width: 550px;
  justify-content: center;
  margin: auto;
  margin-bottom: 50px;
  @media ${(props) => props.theme.media.mobile} {
    padding: 10px;
  }
`;
const GridItemStyle = styled.div`
  height: auto !important;
  cursor: pointer;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 1.22); */
`;

const ImageContainerStyle = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

export default PinterestBoardType;
