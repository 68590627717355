import React, { useState, useRef, useEffect } from 'react';
import { Row } from 'components/commons/layouts';
import { IconButton, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { observer } from 'mobx-react';
import { t } from 'i18next';

import styled from 'styled-components';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import GradientColorPicker from './GradientColorPicker';
import SolidColorPicker from './SolidColorPicker';
import SolidColorPickerWithOpacity from './SolidColorPickerWithOpacity';

const ColorPickerInstance = observer(
  ({
    defaultColor,
    useGradient,
    useOpacity,
    onColorChanged,
    onClose,
  }: {
    defaultColor?: string;
    useGradient: boolean;
    useOpacity?: boolean;
    onColorChanged: (color: string) => void;
    onClose: () => void;
  }) => {
    const selectedColor = useRef<string>('');
    const [hexColor, setHexColor] = useState<string>();
    const [bgType, setBGType] = useState<'solid' | 'gradient' | undefined>('solid');

    const close = () => {
      onClose();
    };

    useEffect(() => {
      if (typeof defaultColor !== 'undefined') {
        setHexColor(defaultColor);
        setBGType(defaultColor?.length > 12 ? 'gradient' : 'solid');
      }
    }, [defaultColor]);

    return (
      <>
        <PickerButtonStyle>
          {useGradient === true && (
            <div style={{ margin: 20 }}>
              <ToggleButtonGroup
                color="primary"
                value={bgType}
                exclusive
                onChange={(e: any, value: any) => {
                  if (value === null) {
                    return;
                  }
                  setBGType(value);
                }}
                aria-label="Platform"
              >
                <ToggleButton
                  sx={{
                    '&.MuiToggleButton-root': {
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                      padding: '0 20px',
                      height: 40,
                    },
                    '&.Mui-selected': {
                      color: '#11C668',
                      background: '#F8FFFB',
                    },
                  }}
                  value="solid"
                >
                  {t('A184')}
                </ToggleButton>
                <ToggleButton
                  sx={{
                    '&.MuiToggleButton-root': {
                      borderTopRightRadius: 10,
                      borderBottomRightRadius: 10,
                      padding: '0 20px',
                      height: 40,
                    },
                    '&.Mui-selected': {
                      color: '#11C668',
                      background: '#F8FFFB',
                    },
                  }}
                  value="gradient"
                >
                  {t('A185')}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          )}
          <PickerStyle>
            <div>
              <Row style={{ justifyContent: 'flex-end' }}>
                <div>
                  <IconButton
                    onClick={() => {
                      close();
                      if (selectedColor.current) {
                        onColorChanged(selectedColor.current);
                      }
                    }}
                  >
                    <DoneIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      close();
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </div>
              </Row>
            </div>
            <PickInnerStyle>
              {bgType === 'gradient' && useGradient === true ? (
                <GradientColorPicker
                  defaultColor={defaultColor}
                  onColorChanged={(color: string) => {
                    selectedColor.current = color;
                  }}
                />
              ) : useOpacity === true ? (
                <SolidColorPickerWithOpacity
                  defaultColor={defaultColor}
                  onColorChanged={(color: string) => {
                    selectedColor.current = color;
                  }}
                />
              ) : (
                <SolidColorPicker
                  defaultColor={defaultColor}
                  onColorChanged={(color: string) => {
                    selectedColor.current = color;
                  }}
                />
              )}
            </PickInnerStyle>
          </PickerStyle>

          {/* <BackgroundStyle color={hexColor} /> */}
        </PickerButtonStyle>
      </>
    );
  },
);

const PickerButtonStyle = styled.div`
  width: 100%;
`;

const PickerStyle = styled.div<{ location?: 'top' | 'bottom' }>`
  width: 100%;
  z-index: 1;
  left: 150px;
  right: 0;
  @media ${(props) => props.theme.media.mobile} {
    left: 10px;
  }
`;

const PickInnerStyle = styled.div`
  padding: 20px;
  background: #fff;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 18px;
    text-align: center;
    margin: -22px 0 0 0;
  }
`;

export default ColorPickerInstance;
