import React, { ReactChild } from 'react';
import useNavigation from 'hooks/useNavigation';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';

import styled from 'styled-components';
import useToken from 'libs/hooks/useToken';
import useLogout from 'hooks/useLogout';
import { OhmossButton } from 'resources/controls/OhmossButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton } from '@mui/material';
import { useRootContext } from 'libs/hooks/useRootContext';
import { toast } from 'react-toastify';
import { useChannelContext } from 'features/linkService/context/useChannelContext';

const SignInMenu = observer(({ onClick }: { onClick?: () => void }) => {
  const { getUserInfo } = useToken();
  const { channelStore, uiStore } = useStore();
  const { myChannel } = useRootContext();
  const { navigate } = useNavigation();
  const { currentChannel } = useChannelContext();

  const logout = useLogout();

  type MenuType = {
    title: string;
    onClick: () => void;
    children?: ReactChild;
  };

  const handleClickMyAccount = async () => {
    if (getUserInfo()) {
      navigate(`/admin/account`);
    }
  };

  const MenuItem = ({ title, onClick, children }: MenuType) => {
    return (
      <MenuItemStyle
        onClick={() => {
          onClick();
        }}
      >
        {title}
        <IconButton style={{ position: 'absolute', right: 30 }}>
          <ArrowForwardIosIcon style={{ height: 14, color: 'rgba(0, 0, 0, 0.35)' }} />
        </IconButton>
      </MenuItemStyle>
    );
  };

  return (
    <>
      <div style={{ margin: '50px 40px 0px 40px' }}>
        <TitleContainer>
          <TitleStyle>
            인플루언서를 위한
            <br /> 프로페셔널 링크, <OhmossText>oh!moss</OhmossText>
          </TitleStyle>
        </TitleContainer>
      </div>
      <MenuContentStyle>
        <MenuItem title={'계정 설정 하기'} onClick={handleClickMyAccount} />
        <MenuItem
          title={'로그아웃 하기'}
          onClick={async () => {
            await logout();
            onClick?.();
            toast('로그아웃 되었습니다.', { type: 'success' });
          }}
        />
        {/* <ExitToAppIcon /> */}
        {!channelStore.channel?.isOwner && (
          <div style={{ paddingTop: 20 }}>
            <OhmossButton
              onClick={() => {
                if (currentChannel?.channelName === myChannel?.channelName) {
                  toast('현재 내 링크페이지입니다.', { type: 'info' });
                } else {
                  navigate(`/${myChannel?.channelName}`);
                }
                uiStore.universalModal.close();
              }}
            >
              내 링크 보기
            </OhmossButton>
          </div>
        )}
      </MenuContentStyle>
      {/* <SignUpContainer>
        <SignUpTitleStyle>아직 계정이 없으신가요?</SignUpTitleStyle>
        <SignUpTextStyle style={{ marginBottom: 30 }}>
          소셜 계정으로 심플하게 로그인하세요.
        </SignUpTextStyle>
        <OhmossButton type="simple" onClick={() => navigate('/sign-up')}>
          회원가입
        </OhmossButton>
      </SignUpContainer> */}
    </>
  );
});

const MenuContentStyle = styled.div`
  padding: 0px 40px;
  margin: 30px 0;
`;

const MenuItemStyle = styled.div`
  margin: 14px 0;
  /* padding: 10px; */
  display: flex;
  font-size: 1.2rem;
  border-radius: 8px;
  align-items: center;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  &:first-child {
    margin-top: 0;
  }
  &:hover {
    background: #f6f5fa;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
`;

const TitleStyle = styled.div`
  color: #173300;
  font-size: 1.7rem;
  font-weight: 600;
  display: inline;
`;

const OhmossText = styled.span`
  color: #96e06e;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.008rem;
`;

const SignUpContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  margin: 50px 40px 40px 40px;
`;

const SignUpTitleStyle = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: -0.0275rem;
`;

const SignUpTextStyle = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.02rem;
`;

export default SignInMenu;
