/* eslint-disable guard-for-in */
import { useRef } from 'react';
import {
  GroupChannel,
  GroupChannelHandler,
  MessageEventContext,
} from '@sendbird/chat/groupChannel';
import { useChat } from 'libs/hooks/useChat';
import { useRootContext } from 'libs/hooks/useRootContext';
import { BaseMessage } from '@sendbird/chat/message';
import chatController from 'features/community/controllers/chatController';
import { ChannelQueryType } from 'types/CommonTypes';

export default function useSandbirdChat() {
  const inquiryRef = useRef<GroupChannel[]>([]);
  const localMessagesRef = useRef<BaseMessage[]>([]);

  const { myChannel } = useRootContext();
  const { channels, setChannels, setMessages, messagesRef, sendbirdChatRef } = useChat();

  const { loadMessages, loadChannels, disposeChannels, acceptIncommingInvitations } =
    chatController();

  const messageHandlers = {
    onMessagesAdded: (
      context: MessageEventContext,
      channel: GroupChannel,
      incomingMessages: BaseMessage[],
    ) => {
      const updatedMessages = [...localMessagesRef.current, ...incomingMessages];
      setMessages(updatedMessages);
      localMessagesRef.current = updatedMessages;
    },

    onMessagesUpdated: (
      context: MessageEventContext,
      channel: GroupChannel,
      updatedMessages: any,
    ) => {
      const newMessages = [...localMessagesRef.current];
      // eslint-disable-next-line no-restricted-syntax
      for (const i in updatedMessages) {
        const incomingMessage = updatedMessages[i];
        const indexOfExisting = localMessagesRef.current.findIndex((message: any) => {
          return incomingMessage.reqId === message.reqId;
        });

        if (indexOfExisting !== -1) {
          newMessages[indexOfExisting] = incomingMessage;
        }
      }
      setMessages(newMessages);
    },

    onMessagesDeleted: (
      context: MessageEventContext,
      channel: GroupChannel,
      messageIds: string[],
    ) => {},
    onChannelUpdated: (context: MessageEventContext, channel: GroupChannel) => {},
    onChannelDeleted: (context: MessageEventContext, channelUrl: string) => {},
    onHugeGapDetected: () => {},
  };

  const getInquiryChannels = async (query: ChannelQueryType) => {
    const channelHandlers = new GroupChannelHandler();

    channelHandlers.onUserReceivedInvitation = async (channel: any) => {
      channel.acceptInvitation();
    };

    channelHandlers.onUserBanned = (channel: any) => {
      const index = channels.findIndex(
        (existingChannel: GroupChannel) => existingChannel.url === channel.url,
      );
      const newChannels = [...channels];
      if (index !== -1) {
        newChannels.splice(index, 1);
      }

      setChannels(newChannels);
    };

    channelHandlers.onChannelChanged = (channel: any) => {
      const index = channels.findIndex(
        (existingChannel: any) => existingChannel.url === channel.url,
      );

      if (index !== -1) {
        channels[index] = channel;
        setChannels([...channels]);
      } else {
        setChannels([channel, ...channels]);
      }
    };

    channelHandlers.onChannelDeleted = (channelUrl: string) => {
      const index = channels.findIndex(
        (existingChannel: GroupChannel) => existingChannel.url === channelUrl,
      );
      const newChannels = [...channels];
      if (index !== -1) {
        newChannels.splice(index, 1);
      }

      setChannels(newChannels);
    };

    const [channels] = (await loadChannels(channelHandlers, query)) as any;

    inquiryRef.current = channels.filter(
      (channel: any) => channel.customType !== myChannel?.channelName,
    );
    setChannels(inquiryRef.current);
  };

  const getChannelMessages = async (channel: GroupChannel) => {
    const onCacheResult = (err: any, messages: any) => {
      // setMessages(messages.reverse());
    };

    const onApiResult = (err: any, messages: any) => {
      setMessages(messages.reverse());
      localMessagesRef.current = messages;
    };
    loadMessages(channel, messageHandlers, onCacheResult, onApiResult, []);
  };

  const dispose = () => {
    disposeChannels();
  };

  const markAsRead = async (channelUrl: string) => {
    await sendbirdChatRef.current.groupChannel.markAsReadWithChannelUrls([channelUrl]);
  };

  const disconnect = async () => {
    await sendbirdChatRef.current.disconnect();
  };

  return { channels, getInquiryChannels, getChannelMessages, dispose, markAsRead, disconnect };
}
