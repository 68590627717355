import { Avatar, IconButton } from '@mui/material';
import { Row } from 'components/commons/layouts';
import React from 'react';
import { ChannelDto, PartnerDto } from 'services/data-contracts';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { useStore } from 'stores/StoreHelper';
import ChatContainer from 'features/csTown/containers/ChatContainer';
import { ChatModel, LinkDtoWithAssignedDeal, MyDealState } from 'types/CommonTypes';
import { useRootContext } from 'libs/hooks/useRootContext';
import CreateIcon from '@mui/icons-material/Create';
import { ListBoxItemTemplate } from 'features/csTown/commonComponents/styles';
import DeleteIcon from 'features/csTown/commonComponents/DeleteIcon';
import Text from 'components/commons/Text';
import { useStateContext } from 'libs/hooks/usePageState';
import { toast } from 'react-toastify';
import PartnerController from 'controllers/PartnerController';

const SimpleCSPartnerList = ({ partners }: { partners?: PartnerDto[] }) => {
  const { uiStore } = useStore();
  const { myChannel } = useRootContext();
  const { unsignDeal } = PartnerController();
  const { stateModel, broadcast } = useStateContext<MyDealState>();

  const handleBeginChat = (partner: PartnerDto) => {
    const channel = partner?.partnerChannel as ChannelDto;
    if (myChannel) {
      const chatInfo: ChatModel = {
        operators: [myChannel],
        members: [channel],
        title: `${channel?.channelName}님과의 채팅`,
      };
      uiStore.modal.show({
        style: { height: '80vh', maxHeight: '80vh' },
        children: <ChatContainer chatInfo={chatInfo} joinType="CHAT" />,
      });
    }
  };

  const handleClickDelete = async (partner: PartnerDto) => {
    uiStore.confirm.show({
      message: '정말 파트너를 삭제하시겠습니까?. \r\n 현재 위임중인 딜로부터 삭제됩니다.',
      onConfirmed: async () => {
        const result = (await unsignDeal(
          myChannel?.id as number,
          partner,
          stateModel?.selectedDeal as LinkDtoWithAssignedDeal,
        )) as unknown as string;

        if (result === '') {
          broadcast({ id: 'reload', param: { id: 'partnerReload' } });
          toast('정상적으로 삭제되었습니다', { type: 'success' });
        } else {
          toast('삭제에 실패하였습니다. 지속되면 고객센터로 문의주세요.', { type: 'error' });
        }
      },
    });
  };

  return (
    <div>
      {partners && (
        <Row style={{ flexWrap: 'wrap', gap: 10, justifyContent: 'flex-start' }}>
          {partners.map((partner, index) => (
            <ListBoxItemTemplate style={{ padding: 20, flex: 0.33 }}>
              <div style={{ float: 'right' }}>
                <DeleteIcon
                  onClick={() => {
                    handleClickDelete(partner);
                  }}
                />
              </div>

              <Row
                key={index}
                style={{ justifyContent: 'flex-start', flex: 1, flexDirection: 'column' }}
              >
                <Row style={{ width: 'unset', flexDirection: 'column' }}>
                  <Avatar
                    src={partner?.partnerChannel?.profile?.profileImage?.publicUrl}
                    style={{ width: 80, height: 80 }}
                  />
                  <div
                    style={{
                      fontSize: 12,
                      marginTop: 10,
                      background: '#173300',
                      color: '#96e06e',
                      borderRadius: 20,
                      padding: '3px 10px',
                    }}
                  >
                    <Text type="H4">{partner?.partnerChannel?.profile?.nickname}</Text>
                  </div>
                </Row>
                <Row style={{ width: 'unset', gap: 10, marginTop: 30 }}>
                  <IconButton onClick={() => {}}>
                    <CreateIcon style={{ width: 25, height: 25 }} />
                  </IconButton>
                  <IconButton
                    style={{ background: '#efefef' }}
                    onClick={() => {
                      handleBeginChat(partner);
                    }}
                  >
                    <SmsOutlinedIcon />
                  </IconButton>
                </Row>
              </Row>
            </ListBoxItemTemplate>
          ))}
        </Row>
      )}
    </div>
  );
};

export default SimpleCSPartnerList;
