/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { MembershipDto, MembershipGiftDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class GiftService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 받은 맴버십 선물을 사용하여 맴버십을 활성화합니다.
   *
   * @tags GiftService
   * @name UseReceivedMembershipGift
   * @summary 맴버십 선물 사용
   * @request POST:/v2/me/channels/{channelId}/gifts/received/{membershipGiftId}/use
   * @secure
   */
  useReceivedMembershipGift = (
    channelId: number,
    membershipGiftId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<MembershipDto, any>({
      path: `/v2/me/channels/${channelId}/gifts/received/${membershipGiftId}/use`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags GiftService
   * @name GetSentGifts
   * @summary 보낸 선물 목록
   * @request GET:/v2/me/channels/{channelId}/gifts/sent
   * @secure
   */
  getSentGifts = (
    channelId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<MembershipGiftDto[], any>({
      path: `/v2/me/channels/${channelId}/gifts/sent`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 선물 정보
   *
   * @tags GiftService
   * @name GetSentMembershipGift
   * @summary 보낸 선물 정보
   * @request GET:/v2/me/channels/{channelId}/gifts/sent/{membershipGiftId}
   * @secure
   */
  getSentMembershipGift = (
    channelId: number,
    membershipGiftId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<MembershipGiftDto, any>({
      path: `/v2/me/channels/${channelId}/gifts/sent/${membershipGiftId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags GiftService
   * @name GetReceivedGifts
   * @summary 받은 선물 목록
   * @request GET:/v2/me/channels/{channelId}/gifts/received
   * @secure
   */
  getReceivedGifts = (
    channelId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<MembershipGiftDto[], any>({
      path: `/v2/me/channels/${channelId}/gifts/received`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 선물 정보
   *
   * @tags GiftService
   * @name GetReceivedMembershipGift
   * @summary 받은 선물 정보
   * @request GET:/v2/me/channels/{channelId}/gifts/received/{membershipGiftId}
   * @secure
   */
  getReceivedMembershipGift = (
    channelId: number,
    membershipGiftId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<MembershipGiftDto, any>({
      path: `/v2/me/channels/${channelId}/gifts/received/${membershipGiftId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
