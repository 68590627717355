import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ClickAwayListener, IconButton } from '@mui/material';
import useNavigation from 'hooks/useNavigation';
import { MenuBoxItemStyle, MenuBoxStyle } from 'features/csTown/commonComponents/styles';
import { Row } from 'components/commons/layouts';
import { useRootContext } from 'libs/hooks/useRootContext';
import { MenuItem } from 'definitions/menuItems';
import MyChannelList from 'features/commonFeatures/containers/MyChannelList';

const CommonGnb = ({ items, categoryId }: { items?: MenuItem[]; categoryId?: string }) => {
  const { navigate } = useNavigation();

  const popUpType = useRef<'channel' | 'options'>('channel');

  const [currentMenu, setCurrentMenu] = useState<string | undefined>('');
  const [showPopup, setShowPopup] = useState(false);

  const MenuIcon = ({ item, style }: { item: MenuItem; style?: CSSProperties }) => {
    if (item?.Icon) {
      const Icon = item.Icon;
      return <Icon style={style} />;
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    if (window.location.pathname) {
      const t = window.location.pathname.split('/');

      if (t.length > 2) {
        if (t[2].toLowerCase() === 'myshop') {
          setCurrentMenu('shop');
        } else if (t[2].toLowerCase() === 'categories') {
          setCurrentMenu('categories');
        } else {
          setCurrentMenu(t[t.length - 1]);
        }
      } else {
        setCurrentMenu('categories');
      }
    }
  }, [window.location.pathname]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setShowPopup(false);
      }}
    >
      <>
        <Row style={{ width: 'unset', justifyContent: 'space-evenly' }}>
          {items?.map((item) => (
            <>
              <MenuBoxStyleWrapper>
                <MemuBoxItemStyleWrapper
                  selected={currentMenu === item.name}
                  onClick={() => {
                    navigate(item?.path as string);
                  }}
                >
                  <IconButton>
                    <MenuIcon
                      item={item}
                      // style={{ color: currentMenu === item.name ? '#fff' : 'unset' }}
                    />
                  </IconButton>
                  <div style={{ paddingRight: 10 }}>{item.title}</div>
                </MemuBoxItemStyleWrapper>
              </MenuBoxStyleWrapper>
            </>
          ))}
        </Row>

        {showPopup && (
          <>
            {popUpType.current === 'channel' ? (
              <PopUpStyle>
                <>
                  <MyChannelList
                    onClick={() => {
                      // location.reload();
                      setShowPopup(false);
                    }}
                    type="channel"
                  />
                </>
              </PopUpStyle>
            ) : (
              <PopUpStyle>
                <div>로그아웃</div>
              </PopUpStyle>
            )}
          </>
        )}
      </>
    </ClickAwayListener>
  );
};

const MemuBoxItemStyleWrapper = styled(MenuBoxItemStyle)`
  margin: 0;
  background: unset;
  box-shadow: unset;
  border-radius: 0;
  color: unset;

  border-bottom: ${(props) => (props.selected ? '3px solid #000' : 'unset')};
`;

const MenuBoxStyleWrapper = styled(MenuBoxStyle)`
  padding: 0px !important;
  width: unset;
`;

const PopUpStyle = styled.div`
  position: absolute;
  right: 0;
  width: 100%;
  bottom: 0px;
  max-height: 100%;
  overflow-y: auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #ebebeb;
  z-index: 10;
`;

export default CommonGnb;
