/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ChannelChecklistDto, ChecklistCategoryDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class ChecklistService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 체크리스트 목록
   *
   * @tags ChecklistService
   * @name GetChecklistsByCategory
   * @summary 체크리스트 목록
   * @request GET:/v2/checklists
   * @secure
   */
  getChecklistsByCategory = (
    query: {
      /** @format int64 */
      categoryId: number;
      /** @format int64 */
      ownChannelId: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ChannelChecklistDto, any>({
      path: `/v2/checklists`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 체크리스트 카테고리 목록
   *
   * @tags ChecklistService
   * @name GetChecklistCategories
   * @summary 체크리스트 카테고리 목록
   * @request GET:/v2/checklist-categories/
   * @secure
   */
  getChecklistCategories = (
    query: {
      /** @format int64 */
      ownChannelId: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ChecklistCategoryDto[], any>({
      path: `/v2/checklist-categories/`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
