import React from 'react';
import styled from 'styled-components';
import { LandingPageWrapper } from './LandingPageWrapper';
import { BodyContainer, DetailText, MainText, SubText, TextContainer } from './Styles';

const LandingSlideC = ({ isMobile }: { isMobile?: boolean }) => {
  // console.log(isMobile);

  return (
    <LandingPageWrapper>
      <BodyContainer>
        {!isMobile && (
          <CenterImg>
            <img alt="left" src="/images/landing/landingc_image.png" style={{}} />
          </CenterImg>
        )}
        <TextContainer>
          <DetailText>CS TOWN</DetailText>
          <MainText>
            손쉽게 CS 하면서
            <br />
            확실하게 고객응대하기
          </MainText>
          <SubText>
            오모스가 제공하는 공구블록을 사용해 보세요. 각 공구별 문의하기와 상세보기, 공지사항등을
            고객에게 효과적으로 전달할 수 있습니다
          </SubText>
        </TextContainer>
        {isMobile && (
          <CenterImg>
            <img alt="left" src="/images/landing/landingc_image.png" style={{ maxWidth: '100%' }} />
          </CenterImg>
        )}
      </BodyContainer>
    </LandingPageWrapper>
  );
};

const CenterImg = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding: 20px;
  justify-content: center;
`;

export default LandingSlideC;
