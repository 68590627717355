import React from 'react';
import { Row } from 'components/commons/layouts';
import { Avatar } from '@mui/material';
import { DealInquiryDto } from 'services/data-contracts';
import moment from 'moment';

export const InquiryListItem = ({
  inquiry,
  onClick,
}: {
  inquiry: DealInquiryDto;
  onClick: () => void;
}) => {
  return (
    <Row style={{ justifyContent: 'space-between' }}>
      <div style={{ padding: 10 }}>
        <Avatar
          src={inquiry?.customerChannel?.profile?.profileImage?.publicUrl}
          style={{ width: 60, height: 60 }}
        />
      </div>
      <div onClick={onClick} style={{ flexGrow: 1, cursor: 'pointer' }}>
        <div style={{ fontSize: 16, fontWeight: 600 }}>{inquiry?.customerChannel?.channelName}</div>
        <div>{inquiry?.latestThread?.message}</div>
      </div>
      <div style={{ marginRight: 10 }}>
        <div>{moment(inquiry?.createDate).fromNow()}</div>
        {inquiry?.status === 'PENDING' && (
          <div
            style={{
              background: '#96E06E',
              padding: '2px 10px',
              float: 'right',
              fontSize: 11,
              color: '#fff',
              borderRadius: 4,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            NEW
          </div>
        )}
      </div>
    </Row>
  );
};

export default InquiryListItem;
