import React, { useEffect, useState, useRef } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { CommonCenterAllStyle } from 'resources/styles/common/styles';
import { useTranslation } from 'react-i18next';
// import QRCode from 'react-qr-code';
import QRCodePrintModal from 'components/commons/QRCodePrintModal';
import styled from 'styled-components';
import { QRCode } from 'react-qrcode-logo';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

type Props = {
  url?: string;
};

const QRContainer = observer(({ url }: Props) => {
  const { t } = useTranslation();
  const qrRef = useRef<HTMLDivElement>(null);

  const downloadQR = () => {
    const qr = qrRef.current;
    if (qr) {
      html2canvas(qr).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        saveAs(imgData, 'test.png');
        // document.body.removeChild(link);
      });
    }
  };
  return (
    <>
      <Container>
        <CommonCenterAllStyle ref={qrRef}>
          <QRCode
            removeQrCodeBehindLogo
            logoImage="/images/charactor.svg"
            qrStyle="dots"
            size={274}
            value={url}
          />
        </CommonCenterAllStyle>
        {/* <LinkText>{`https://ohmoss.com/${
          typeof username !== 'undefined' ? username : 'test'
        }`}</LinkText> */}
        <CommonCenterAllStyle>
          <SaveButton onClick={() => downloadQR()}>{t('QR Download')}</SaveButton>
        </CommonCenterAllStyle>
      </Container>
      {/* <QRCodePrintModal
        qrRef={qrRef}
        onClose={() => {
          setShowModal(false);
        }}
        userName={typeof username !== 'undefined' ? username : ' meStore.myProfile?.userName'}
        show={showModal}
      /> */}
    </>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.03);
  border-radius: 24px;
  padding: 10px;
`;

const LinkText = styled.div`
  font-size: 16px;
  justify-content: center;
  align-items: center;
  margin: 30px 0 30px 0px;
`;

const SaveButton = styled.div<{ marginLeft?: number }>`
  color: #3e64ea;
  font-size: 14px;
  width: 126px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #3e64ea;
  border-radius: 20px;
  cursor: pointer;
`;

export default QRContainer;
