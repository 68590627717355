import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react';
import { TagDto } from 'services/data-contracts';

import LinkEditorStyle from 'resources/controls/CommonButtonsStyle';
import Tags from 'features/proFeatures/tag/containers/Tags';
import CreateTag from 'features/proFeatures/tag/containers/CreateTag';
import { useStore } from 'stores/StoreHelper';
import NoContent from 'components/commons/NoContent';
import { ItemSectionStyle, ItemSectionTitle } from 'resources/controls/ItemSectionStyle';

import CRUDHelper from 'libs/helper/crudHelper';
import InformationBox from 'components/commons/InformationBox';
import { t } from 'i18next';

const TagsContainer = observer(() => {
  const { myTagStore, myChannelStore } = useStore();
  const crudHelper = new CRUDHelper(myTagStore);

  const [selectedTag, setSelectedTag] = useState<TagDto>();
  const [mode, setMode] = useState<'new' | 'view'>('view');

  const handleClickCreate = () => {
    setSelectedTag(undefined);
    setMode('new');
  };

  const init = () => {
    if (myChannelStore.currentChannel?.id) {
      crudHelper.getItems(myChannelStore.currentChannel.id);
    }
  };

  useEffect(() => {
    if (myChannelStore.currentChannel) init();
  }, [myChannelStore.currentChannel]);

  return (
    <>
      {/* <LinkEditorStyle
        onClick={() => {
          handleClickCreate();
        }}
      >
        <div>Create New Tag</div>
      </LinkEditorStyle> */}
      <InformationBox />
      <>
        <div style={{ padding: 20 }}>
          <CreateTag
            tag={selectedTag}
            onUnselected={() => {
              setSelectedTag(undefined);
            }}
            onDeletedTag={() => {
              setSelectedTag(undefined);
              init();
            }}
          />
        </div>
      </>

      {myTagStore.tags?.length === 0 && mode === 'view' ? (
        <NoContent title="태그가" style={{ paddingTop: 100 }} />
      ) : (
        <ItemSectionStyle style={{ margin: 20, padding: 20 }}>
          <ItemSectionTitle>{t('A205')}</ItemSectionTitle>
          <div style={{ marginBottom: 20, padding: 20 }}>
            <Tags
              onSelectTag={(tag: TagDto) => {
                setSelectedTag(tag);
              }}
            />
          </div>
        </ItemSectionStyle>
      )}
    </>
  );
});

export default TagsContainer;
