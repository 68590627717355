/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';
import { refreshErrorHandle, RefreshToken } from 'libs/commons/RefreshToken';
import _ from 'lodash';
import QueryString from 'qs';
import { REST_API_URL_V2 } from 'stores/AppStore';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;

  noToast?: boolean;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export interface CommonHeaderProperties extends HeadersDefaults {
  Authorization: string;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string | undefined = REST_API_URL_V2;

  public instance: AxiosInstance;

  private securityData: SecurityDataType | null = null;

  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];

  private secure?: boolean;

  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || REST_API_URL_V2,
    });

    this.instance.interceptors.response.use(
      (response) => {
        return Promise.resolve({
          response,
          data: response.data,
          status: response.status,
          error: { hasError: false, data: response },
          headers: response.headers,
        });
      },
      (err) => {
        return Promise.resolve({
          data: undefined,
          response: err.response,
          error: err.response,
          status: err.response.status,
        });
      },
    );

    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      // @ts-ignore
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        // @ts-ignore
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
          ? JSON.stringify(property)
          : `${property}`,
      );
      return formData;
      // @ts-ignore
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const token = localStorage.getItem('accessToken');

    if (_.isUndefined(token)) {
      // localStorage.removeItem('accessToken');
      // localStorage.removeItem('refreshToken');
    }

    if (token !== null) {
      /** 리프레시 토큰 */
      this.instance.interceptors.request.use(RefreshToken, refreshErrorHandle);
    } else {
      // console.log('token', token);
    }

    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};

    //  const requestParams = this.mergeRequestParams(params, secureParams);
    const requestParams = this.mergeRequestParams(
      {
        ...params,
        // paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'brackets' }),
        paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
      },
      secureParams,
    );
    const responseFormat = format==='blob' ? 'blob' : (format && this.format) || void 0;

    // if (!_.isEmpty(token) && !_.isUndefined(requestParams.headers)) {
    //   requestParams.headers['Authorization'] = `Bearer ${token}`;
    // }

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      // @ts-ignore
      requestParams.headers.common = { Accept: '*/*' };

      // @ts-ignore
      requestParams.headers.post = {};

      // @ts-ignore
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        // ...(!_.isEmpty(token) ? { Authorization: "type" } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}
