import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import MyBoardStore from 'stores/MyBoardStore';
import { ChannelBoardDto, LinkDto } from 'services/data-contracts';
import { isMobile } from 'react-device-detect';
import EmptyPage from 'pages/EmptyPage';
import { Draggable } from 'react-beautiful-dnd';
import { DraggableList, getItemStyle } from 'components/commons/draggableList';
import CategoryController from 'controllers/CategoryController';
import { useRootContext } from 'libs/hooks/useRootContext';
import ContextMenu from '../components/category/ContextMenu';
import CategoryFormContainer from '../containers/category/CategoryFormContainer';
import CategoryItem from '../components/category/CategoryItem';
import { ListBoxItemTemplate } from 'features/csTown/commonComponents/styles';
import NoContent from 'components/commons/NoContent';

import ManagementInnerLayout from '../components/common/ManagementInnerLayout';
import { BottomBoxStyle } from '../components/common/BottomButonStyle';
import DragIcon from 'components/commons/buttons/DragIcon';
import LinkStore from 'stores/LinkStore';
import { ChannelBoardModel } from 'types/CommonTypes';

const AdminMainPage = observer(() => {
  const myBoardStore = new MyBoardStore();
  const linkStore = new LinkStore();
  const { myChannel } = useRootContext();
  const { getMyCategoryBoards } = CategoryController();

  const { uiStore } = useStore();

  const [categories, setCategories] = useState<ChannelBoardDto[]>();
  const [dealPage, setDealPage] = useState<ChannelBoardDto>();
  const [hasCategory, setHasCategory] = useState<boolean>();

  const handleClickNewCategory = (item?: ChannelBoardDto) => {
    uiStore.bottomSheet.show({
      topPosition: isMobile ? 40 : undefined,
      title: item ? '페이지 수정' : '새 페이지 만들기',
      children: <CategoryFormContainer item={item} />,
      onConfirmed: () => {
        fetchData();
      },
    });
  };

  const handleReorder = (categories: ChannelBoardDto[], category: ChannelBoardDto) => {
    setCategories(categories);
    myBoardStore.reOrderBoardItem(myChannel?.id as number, category.id as number, category);
  };

  const handleChangePrimary = async (item: ChannelBoardDto) => {
    const newBoard: ChannelBoardDto = { ...item, isPrimary: true };
    await myBoardStore.updateBoard(
      myChannel?.id as number,
      newBoard,
      '선택한 페이지가 가장 먼저 표시됩니다.',
    );
    fetchData();
  };

  const handleDeleteCategory = (item: ChannelBoardDto) => {
    uiStore.bottomSheet.close();
    uiStore.confirm.show({
      message: '페이지를 삭제하시겠습니까? \n 아이템이 있는경우는 삭제하실 수 없습니다.',
      onConfirmed: async () => {
        const result = await myBoardStore.deleteBoard(myChannel?.id as number, item.id as number);
        if (result) {
          fetchData();
        }
      },
    });
  };

  const handleChangeActive = (item: ChannelBoardDto) => {
    uiStore.bottomSheet.close();
    uiStore.confirm.show({
      title: item.isActive ? '비공개 전환' : '공개 전환',
      message: item.isActive
        ? '비공개로 전환되면 다른 사람들은 볼 수 없게됩니다.'
        : '공개로 전환되면 다른 사람들도 볼 수 있습니다.',
      onConfirmed: async () => {
        const newBoard: ChannelBoardDto = { ...item, isActive: !item.isActive };
        const result = await myBoardStore.updateBoard(myChannel?.id as number, newBoard);
        if (result) {
          fetchData();
        }
      },
    });
  };

  const handleRename = (item: ChannelBoardDto) => {
    handleClickNewCategory(item);
  };

  const handleClickShowMore = (item: ChannelBoardDto) => {
    uiStore.bottomSheet.show({
      children: (
        <ContextMenu
          isActive={item.isActive}
          // canDelete={!item.isDefault}
          canDelete
          onDelete={() => {
            handleDeleteCategory(item);
          }}
          onRename={() => {
            handleRename(item);
          }}
          onInactive={() => {
            handleChangeActive(item);
          }}
        />
      ),
    });
  };

  const fetchData = async () => {
    const response = await getMyCategoryBoards();
    const newPages = [...response];
    const endedDeals = (await linkStore.getEndedDealList(
      myChannel?.channelName as string,
    )) as unknown as LinkDto[];

    if (endedDeals?.length > 0) {
      const closedDealBoard: ChannelBoardModel = {
        boardType: 'LINK',

        viewType: 'NONE',
        name: '종료된 공구',
        id: 1000,
        seq: 1000,
        isDeal: true,
        postCount: endedDeals.length,
        useProfile: false,
        thumbnail: { publicUrl: endedDeals[0].thumbnail?.publicUrl },
        isActive: (myChannel?.profile?.useEndedDealPage as boolean) ?? true,
      };
      setDealPage(closedDealBoard);
    }
    setHasCategory(newPages.length > 0);
    setCategories(newPages.filter((x) => x.boardType !== 'SHOP'));
  };

  useEffect(() => {
    if (myChannel) {
      fetchData();
    }
  }, [myChannel]);

  useEffect(() => {}, []);

  return (
    <>
      <ManagementInnerLayout style={{}}>
        {categories?.length === 0 && <NoContent title="등록된 페이지가" />}
        <DraggableList items={categories} onOrderChanged={handleReorder}>
          {categories?.map((dragItem: ChannelBoardModel, index: number) => (
            <Draggable key={dragItem?.id} draggableId={String(dragItem?.id)} index={index}>
              {(provided, snapshot): JSX.Element => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                  {!dragItem.isDeal && (
                    <div {...provided.dragHandleProps} style={{ position: 'absolute' }}>
                      <DragIcon />
                    </div>
                  )}
                  <ListBoxItemTemplate style={{ margin: 0, padding: 0 }}>
                    <CategoryItem
                      fetchData={() => {
                        fetchData();
                      }}
                      item={dragItem}
                      onPrimaryChanged={() => {
                        handleChangePrimary(dragItem);
                      }}
                      onClickMore={() => {
                        handleClickShowMore(dragItem);
                      }}
                    />
                  </ListBoxItemTemplate>
                </div>
              )}
            </Draggable>
          ))}
        </DraggableList>
        {dealPage && (
          <ListBoxItemTemplate style={{ margin: 0, padding: 0 }}>
            <CategoryItem
              fetchData={() => {}}
              item={dealPage}
              onPrimaryChanged={() => {}}
              onClickMore={() => {}}
            />
          </ListBoxItemTemplate>
        )}
      </ManagementInnerLayout>
      <BottomBoxStyle style={{ padding: 20 }}>
        <OhmossButton
          onClick={() => {
            handleClickNewCategory();
          }}
          type="primaryPc"
          style={{
            width: '100%',
            boxShadow: '0px 0px 10px rgba(255, 255, 255, 1)',
          }}
        >
          새 페이지
        </OhmossButton>
      </BottomBoxStyle>
    </>
  );
});

export default AdminMainPage;
