import { SizeInfo } from 'types/CommonTypes';
import { getCroppedImg, resizeImage } from 'libs/helper/cropImage';
import loadBase64Image from 'libs/helper/loadBase64Image';
import { CustomFile } from 'types/BlockExtendsTypes';

const cropImage = async (
  cropSize: SizeInfo,
  targetImage: CustomFile | undefined,
  croppedAreaPixels: any,
  rotation: any,
) => {
  try {
    // Blob file
    const croppedImages = (await getCroppedImg(
      targetImage?.publicUrl,
      croppedAreaPixels,
      rotation,
      {
        width: cropSize.width,
        height: cropSize.height,
      },
    )) as any;

    const blob = (await resizeImage(croppedImages.file, { width: 1200, height: 1200 })) as Blob;

    const thumbnail: CustomFile = { blob: blob };
    thumbnail.publicUrl = URL.createObjectURL(croppedImages.blob);
    thumbnail.contentType = thumbnail.blob?.type;
    // thumbnail.originFile = targetImage?.originFile;

    return thumbnail as CustomFile;
  } catch (e) {
    console.error(e);
  }
};

const convertBase64Files = async (e: any) => {
  if (e === null) {
    return;
  }
  const files = Array.from(e);
  return Promise.all(
    await files?.map(async (file: any) => {
      const base64Image = await loadBase64Image(file);
      const fileObject = await fetch(base64Image as string).then((res) => res.blob());
      let jpgFile;
      if (file.type.indexOf('video') === -1) {
        jpgFile = await resizeImage(file, { width: 900, height: 900 });
      }
      return {
        publicUrl: base64Image,
        file: jpgFile || file,
        originFile: file,
        contentType: fileObject.type,
      } as CustomFile;
    }),
  );
};

const convertBase64Files2 = async (e: any) => {
  if (e === null) {
    return;
  }
  const files = Array.from(e);
  return Promise.all(
    await files?.map(async (file: any) => {
      const base64Image = await loadBase64Image(file);
      // const fileObject = await fetch(base64Image as string).then((res) => res.blob());
      const blob = (await resizeImage(file, { width: 1200, height: 1200 })) as Blob;
      return {
        publicUrl: base64Image,
        blob: blob ?? file,
        contentType: blob.type,
      } as CustomFile;
    }),
  );
};

export { cropImage, convertBase64Files, convertBase64Files2 };
