/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from 'stores/StoreHelper';
import { FriendshipDto, TagDto } from 'services/data-contracts';
import { Row } from 'components/commons/layouts';
import { Avatar } from '@mui/material';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { t } from 'i18next';

import _ from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import NoContent from 'components/commons/NoContent';

const FollowerContainer = observer(() => {
  const { myChannelStore } = useStore();

  const handleClickFollower = (follower: FriendshipDto) => {
    window.open(`/${follower.follower?.channelName}`, '_blank');
  };
  useEffect(() => {
    if (myChannelStore.currentChannel)
      myChannelStore.getMyFollowers(myChannelStore.currentChannel?.id as number);
  }, [myChannelStore.currentChannel]);

  return (
    <div style={{ margin: 20 }}>
      {myChannelStore.myFollowers?.map((follower: FriendshipDto) => (
        <FollowerStyle>
          <Row style={{ justifyContent: 'space-between' }}>
            <Row style={{ justifyContent: 'flex-start' }}>
              <Avatar
                style={{ width: 60, height: 60 }}
                src={follower.followerProfile?.profileImage?.publicUrl}
              />
              <div style={{ marginLeft: 10 }}>
                <FollowerTitleStyle>{follower.followerProfile?.nickname}</FollowerTitleStyle>
                <FollowerDateStyle>{moment.utc(follower.createDate).fromNow()}</FollowerDateStyle>
                <TagsStyle>
                  <Row style={{ justifyContent: 'flex-start' }}>
                    {follower.tags?.map((tag: TagDto) => (
                      <TagItemStyle>#{tag.name}</TagItemStyle>
                    ))}
                  </Row>
                </TagsStyle>
              </div>
            </Row>
            <div style={{ width: 60 }}>
              <OhmossButton
                type="simple"
                style={{ padding: '10px 10px' }}
                onClick={() => {
                  handleClickFollower(follower);
                }}
              >
                {t('A197')}
              </OhmossButton>
            </div>
          </Row>
        </FollowerStyle>
      ))}
      {myChannelStore.myFollowers?.length === 0 && <NoContent title="Follower가" />}
    </div>
  );
});

const FollowerStyle = styled.div`
  margin: 15px 0;
`;

const FollowerTitleStyle = styled.div`
  font-weight: 600;
  font-size: 18px;
`;
const FollowerDateStyle = styled.div`
  color: #888;
  font-size: 14px; ;
`;

const TagsStyle = styled.div``;

const TagItemStyle = styled.div`
  color: #949494;
`;

export default FollowerContainer;
