import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { DealInquiryDto } from 'services/data-contracts';
import AgencyController from 'controllers/BrandController';
import DealInfluencerInquiry from '../components/DealSellersInquiry';
import { useRootContext } from 'libs/hooks/useRootContext';

const InquiryDetailPage = () => {
  const { getDealById } = AgencyController();

  const { dealid } = useParams();
  const { myChannel } = useRootContext();

  const [deal, setDeal] = useState<DealInquiryDto>();

  const getDealInfo = async () => {
    const dealId = Number(dealid);

    const result = (await getDealById(
      myChannel?.id as number,
      dealId,
    )) as unknown as DealInquiryDto;
    setDeal(result);
  };
  useEffect(() => {
    if (dealid && myChannel) {
      getDealInfo();
    }
  }, [dealid, myChannel]);

  return (
    <>
      {deal && (
        <div>
          <DealInfluencerInquiry inquiry={deal} />
        </div>
      )}
    </>
  );
};

export default InquiryDetailPage;
