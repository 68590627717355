/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { DealOptionDto, LinkDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class LinkService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 종료된 딜목록
   *
   * @tags LinkService
   * @name GetEndedDeals
   * @summary 종료된 딜목록
   * @request GET:/v2/channels/{channelName}/ended-links
   * @secure
   */
  getEndedDeals = (
    channelName: string,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<LinkDto[], any>({
      path: `/v2/channels/${channelName}/ended-links`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 링크 목록
   *
   * @tags LinkService
   * @name GetLinks1
   * @summary 링크목록
   * @request GET:/v2/channels/{channelName}/boards/{boardId}/links
   * @secure
   */
  getLinks1 = (channelName: string, boardId: number, params: RequestParams = {}) =>
    this.http.request<LinkDto[], any>({
      path: `/v2/channels/${channelName}/boards/${boardId}/links`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 링크정보
   *
   * @tags LinkService
   * @name GetLink3
   * @summary 링크정보
   * @request GET:/v2/channels/{channelName}/boards/{boardId}/links/{linkId}
   * @secure
   */
  getLink3 = (channelName: string, boardId: number, linkId: number, params: RequestParams = {}) =>
    this.http.request<LinkDto, any>({
      path: `/v2/channels/${channelName}/boards/${boardId}/links/${linkId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 딜 옵션 목록
   *
   * @tags LinkService
   * @name GetDealOptions1
   * @summary 딜 옵션 목록
   * @request GET:/v2/channels/{channelName}/boards/{boardId}/links/{linkId}/options
   * @secure
   */
  getDealOptions1 = (
    channelName: string,
    boardId: number,
    linkId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<DealOptionDto[], any>({
      path: `/v2/channels/${channelName}/boards/${boardId}/links/${linkId}/options`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
