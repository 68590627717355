import { Collapse } from '@mui/material';
import DetailPageHeader from 'components/commons/DetailPageHeader';
import TitleForm from 'components/items/forms/TitleForm';
import ChecklistController from 'controllers/ChecklistController';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import { useRootContext } from 'libs/hooks/useRootContext';
import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { OhmossButton } from 'resources/controls/OhmossButton';
import {
  ChannelChecklistItemDto,
  ChannelChecklistItemUpdateDto,
  ChannelChecklistTopicDto,
} from 'services/data-contracts';
import styled from 'styled-components';

const ChecklistSelectorPage = () => {
  const location = useLocation();
  const [searchParam] = useSearchParams();
  const checklistId = searchParam.get('id');
  const topicIndex = searchParam.get('topicIndex');
  const topicId = searchParam.get('topic');
  const category = searchParam.get('category');

  const { myChannel } = useRootContext();
  const {
    getMyChecklistItems,
    getChannelChecklistTopic,
    getChecklistsByCategory,
    updateMyChecklistItem,
  } = ChecklistController();

  const { serviceModel, updateServiceModel } = useModelManagement<ChannelChecklistItemUpdateDto>();
  const [items, setItems] = useState<ChannelChecklistItemDto[]>([]);
  const [itemsState, setItemsState] = useState<any>();
  const [topicName, setTopicName] = useState<any>();

  const handleToggleForm = (index: number) => {
    setItemsState((prevState: any) =>
      prevState.map((item: any, i: number) =>
        i === index ? { ...item, isShowForm: !item.isShowForm } : item,
      ),
    );
  };

  const handleToggleQuestion = (index: number) => {
    setItemsState((prevState: any) =>
      prevState.map((item: any, i: number) =>
        i === index ? { ...item, isShowQuestion: !item.isShowQuestion } : item,
      ),
    );
  };

  const handleToggleCheck = (index: number) => {
    setItemsState((prevState: any) =>
      prevState.map((item: any, i: number) =>
        i === index ? { ...item, isCheck: !item.isCheck } : item,
      ),
    );
  };

  const handleSetFormValue = (index: number, value: string) => {
    setItemsState((prevState: any) =>
      prevState.map((item: any, i: number) => (i === index ? { ...item, remark: value } : item)),
    );
  };

  const getChecklistItems = async () => {
    if (checklistId) {
      const tempTopics = await getChannelChecklistTopic(
        myChannel?.id as number,
        Number(checklistId),
      );
      const allChecklistItems = await getMyChecklistItems(
        myChannel?.id as number,
        Number(checklistId),
        Number(topicId),
      );

      if (allChecklistItems) {
        const newItems = allChecklistItems.filter((item: any) => {
          return item?.topicId === tempTopics[Number(topicIndex)]?.id;
        });

        setItems(newItems);
        setTopicName(tempTopics[Number(topicIndex)].name);
      }
    } else {
      const allChecklistItems = (await getChecklistsByCategory(
        Number(category),
        myChannel?.id as number,
      )) as any;
      setItems(allChecklistItems?.items as ChannelChecklistItemDto[]);
    }
  };

  const handleSaveChecklistItems = async (itemId: number, index: number) => {
    await updateMyChecklistItem(
      myChannel?.id as number,
      Number(checklistId),
      Number(topicId),
      itemId,
      {
        isCheck: itemsState[index]?.isCheck,
        remark: itemsState[index]?.remark,
      },
    );
  };

  useEffect(() => {
    if (location.pathname) {
      setTimeout(() => {
        const t = document.querySelector('.ohmoss-navigation-tab') as any;
        if (
          (t && location.pathname === '/my/deals/checklist/detail/list') ||
          location.pathname === '/cstown/checklist/detail/list'
        ) {
          t.style.display = 'none';
        } else {
          t.style.display = 'flex';
        }
      }, 0);
    }
  }, [location.pathname]);

  useEffect(() => {
    getChecklistItems();
  }, []);

  useEffect(() => {
    if (items) {
      setItemsState(
        items?.map((item) => ({
          ...item,
          isShowForm: false,
        })),
      );
    }
  }, [items]);

  return (
    <>
      <DetailPageHeader title={topicName} />
      <ManagementLayout
        style={{
          justifyContent: 'flex-start',
          background: 'rgba(202, 202, 202, 0.10)',
          height: 'calc(100vh - 80px)',
        }}
      >
        {itemsState?.map((item: any, index: number) => (
          <Container>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <CheckIcon
                src={
                  item?.isCheck === true
                    ? '/images/management/checklist/checklist_circle_checked.svg'
                    : '/images/management/checklist/checklist_circle.svg'
                }
                alt=""
                style={{ marginRight: 12 }}
                onClick={() => {
                  handleToggleCheck(index);
                }}
              />
              <div
                style={{
                  maxWidth: 'calc(100% - 100px)',
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-line',
                }}
              >
                {item?.contents}
              </div>
              <QuestionIcon
                src="/images/management/checklist/question.svg"
                alt=""
                style={{ position: 'absolute', right: 40, cursor: 'pointer' }}
                onClick={() => {
                  handleToggleQuestion(index);
                }}
              />
              <EditIcon
                src="/images/management/pencil.svg"
                alt=""
                style={{ position: 'absolute', right: 70, cursor: 'pointer' }}
                onClick={() => {
                  handleToggleForm(index);
                }}
              />
            </div>
            {item?.remark !== null && <MyText>{item?.remark}</MyText>}
            <Collapse
              style={{ marginTop: item?.isShowForm ? 10 : 0 }}
              in={item?.isShowForm}
              collapsedSize={0}
            >
              <TitleForm
                style={{ borderRadius: 5 }}
                placeHolder="기록할 내용을 입력하세요."
                initialValue={item?.remark}
                onChangeValue={(value: string) => {
                  handleSetFormValue(index, value);
                }}
                onEntered={() => handleToggleForm(index)}
              />
            </Collapse>
            <Collapse collapsedSize={0} in={item?.isShowQuestion && item?.description}>
              <Divider />
              <InfoIcon
                src="/images/management/checklist/information.svg"
                alt=""
                visible={item?.isShowQuestion}
              />
              <InfoText>{item?.description}</InfoText>
            </Collapse>
            <div
              style={{
                display: 'flex',
                alignSelf: 'flex-end',
                cursor: 'pointer',
                width: '20%',
                marginTop: 10,
              }}
            >
              <OhmossButton
                type="secondary"
                style={{ height: 30 }}
                onClick={() => handleSaveChecklistItems(item?.id, index)}
              >
                저장
              </OhmossButton>
            </div>
          </Container>
        ))}
      </ManagementLayout>
    </>
  );
};

const Container = styled.div`
  display: flex;
  border-radius: 1.25rem;
  border: 1px solid #b9c2b2;
  padding: 0.625rem 1rem;
  flex-direction: column;
  background: #fff;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
`;

const MyText = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: #808080;
  margin-top: 10px;
  margin-left: 10px;
`;

const CheckIcon = styled.img``;

const QuestionIcon = styled.img``;

const EditIcon = styled.img``;

const InfoIcon = styled.img<{ visible?: boolean }>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: absolute;
  width: 20px;
  height: 20px;
  left: 35px;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px dashed #cecece;
  margin: 20px 0px;
`;

const InfoText = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: rgba(76, 76, 76, 1);
  padding: 0px 0px 0px 30px;
`;

export default ChecklistSelectorPage;
