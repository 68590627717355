import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import RootGnbComponent from 'components/commons/RootGnbComponent';
import Mobile from '../components/Mobile';
import PC from '../components/PC';

const LandingPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const container = document.querySelector('.universal-layout') as any;
    if (container) {
      container.style.maxWidth = '100%';
      container.style.width = '100%';
    }
  }, []);

  return (
    <>
      <ImgContainer />
      <LandingPageContainer>
        <RootGnbComponent position="bottom" />
        {isMobile ? <Mobile /> : <PC isMobile={isMobile} />}
      </LandingPageContainer>
    </>
  );
};
const LandingPageContainer = styled.div`
  height: 100vh;
`;

const ImgContainer = styled.div`
  width: 100%;
  height: 110vh;
  position: fixed;
  background-image: url('/images/landing/landing_main.jpg');
  background-size: cover;
  background-position: 43% 40%;
`;

export default LandingPage;
