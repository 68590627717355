import React, { useEffect, useState, useContext } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { Row } from 'components/commons/layouts';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';
import ViewSimulator from 'features/adminFeatures/simulator/containers/ViewSimulator';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OverlayStyle from 'resources/controls/OverlayStyle';
import WebViewContainer from 'features/adminFeatures/admin/containers/WebViewContainer';
import MobileContainer from 'features/adminFeatures/admin/containers/MobileContainer';
import PageLoader from 'components/commons/PageLoader';
import { MenuType } from 'types/CommonTypes';
import { getAdminMenus } from 'resources/definitions/AdminMenus';

/**
 * @description !!!!!!!!!!!!!중요 : 이 페이지가 수정될 경우, adminMainPage도 함께 살펴볼것.
 * @description !!!!!!!!!!!!!중요 : 이 페이지가 수정될 경우, adminMainPage도 함께 살펴볼것.
 */
const MobileAdminMainPage = observer(() => {
  const { myChannelStore, mySocialStore, uiStores } = useStore();
  const { profileStore, adminStore } = uiStores;
  const { channelname } = useParams();

  const [showBottomMenu, setShowBottomMenu] = useState<boolean>(false);
  const [currentMenu, setCurrentMenu] = useState<MenuType>(getAdminMenus()[1]);
  const [loaded, setLoaded] = useState<boolean>(false);

  const getMobileContainer = () => {
    window.scrollTo(1, 0);
    return currentMenu?.component || <></>;
    // const temp = AdminGnbMenus.find((container: any) => container.title === currentMenu);
    // return temp?.component;
    return <></>;
  };

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, [channelname]);

  useEffect(() => {
    if (myChannelStore.currentChannel?.profile) {
      mySocialStore.getSocials(myChannelStore.currentChannel?.id as number);
      profileStore.setProfile(myChannelStore.currentChannel?.profile);
    }
  }, [myChannelStore.currentChannel]);

  return (
    <>
      {window.isWebView ? (
        <WebViewContainer bottomSize={20} onChangeMenu={setCurrentMenu}>
          <>{getMobileContainer()}</>
        </WebViewContainer>
      ) : (
        <MobileContainer bottomSize={20} onChangeMenu={setCurrentMenu}>
          <>{getMobileContainer()}</>
        </MobileContainer>
      )}

      <SimulatorStyle show={adminStore.showSimulator}>
        <SimulatorInnerStyle>
          <ViewSimulator />
          <CloseButtonStyle style={{ bottom: 20 }}>
            <OhmossButton
              style={{ padding: '10px 20px' }}
              onClick={() => {
                adminStore.setShowSimulator();
                window.scrollTo(0, 1);
              }}
            >
              <Row>
                <>
                  <ArrowBackIcon /> 돌아가기
                </>
              </Row>
            </OhmossButton>
          </CloseButtonStyle>
        </SimulatorInnerStyle>
      </SimulatorStyle>

      <OverlayStyle
        isShow={showBottomMenu}
        onClick={() => {
          setShowBottomMenu(false);
        }}
      />
      <PageLoader />
    </>
  );
});

const SimulatorStyle = styled.div<{ show: boolean; }>`
  position: fixed;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  left: ${(props) => (props.show ? 0 : '100%')};
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 1000000;
  background: #f1f1f1;
  transition: left 0.5s ease;
`;

const SimulatorInnerStyle = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 1000000;
  background: #f1f1f1;
`;

const CloseButtonStyle = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${(props) => props.theme.media.mobile} {
    bottom: 20px;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;
  }
`;

const BottomStyle = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
`;
export default MobileAdminMainPage;
