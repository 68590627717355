import React, { ReactNode } from 'react';

import { useGlobalContext } from 'context/useGlobalContext';
import { useModelManagement } from 'libs/hooks/useModelUpdate';

import MyChannelStyleStore from 'stores/MyChannelStyleStore';
import { MyStyle } from 'types/CommonTypes';
import { Context } from '../useOhmossContext';
import { toast } from 'react-toastify';
import { useRootContext } from 'libs/hooks/useRootContext';

interface PageProviderProps<T> {
  children: ReactNode | ReactNode[] | Element | Element[];
}

const MyStyleProvider = <T extends MyStyle>({ children }: PageProviderProps<T>) => {
  const myChannelStyleStore = new MyChannelStyleStore();

  /** Primitive */
  const serviceItems = useModelManagement<T>();
  const { initialModel, serviceModel } = serviceItems;
  const { myChannel } = useRootContext();

  const updateStyle = async () => {
    if (JSON.stringify(serviceModel?.boxStyle) !== '{}') {
      delete serviceModel?.created;
      const result = await myChannelStyleStore.updateMyStyle(myChannel?.id as number, {
        style: JSON.stringify(serviceModel),
      });
      if (!result) {
        const result = await myChannelStyleStore.createMyStyle(myChannel?.id as number, {
          style: JSON.stringify(serviceModel),
        });
      }
    } else {
      const result = await myChannelStyleStore.createMyStyle(myChannel?.id as number, {
        style: JSON.stringify(serviceModel),
      });

      if (!result) {
        myChannelStyleStore.updateMyStyle(myChannel?.id as number, {
          style: JSON.stringify(serviceModel),
        });
      }
    }
    toast('정상적으로 반영되었습니다.');
  };

  return (
    <>
      {typeof children !== 'undefined' ? (
        <Context.Provider
          value={{
            // updateStyle,
            ...serviceItems,
          }}
        >
          {children}
        </Context.Provider>
      ) : (
        <></>
      )}
    </>
  );
};

export default MyStyleProvider;
