import React from 'react';
import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useNavigation from 'hooks/useNavigation';
import useWebViewPrevUrl from 'hooks/useWebViewPrevUrl';

interface MobileBackButtonProps {
  containerStyle?: React.CSSProperties;
}

const MobileBackButton = ({ containerStyle }: MobileBackButtonProps) => {
  const { pop } = useNavigation();
  const prevUrl = useWebViewPrevUrl();

  if (!window.isWebView || !prevUrl) return null;
  return (
    <IconButton onClick={() => pop()} style={containerStyle}>
      <ArrowBackIosIcon style={{ width: 24, height: 24 }} sx={{ color: '#96E06E' }} />
    </IconButton>
  );
};

export default MobileBackButton;
