import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import AdManagementContainer from 'features/adminFeatures/admin/containers/AdManagementContainer';
import useChannelSelector from 'libs/hooks/useChannelSelector';
import { ChannelBoardDto, PostDto } from 'services/data-contracts';
import { toast } from 'react-toastify';
import AdPostEditor from 'components/items/AdPostEditor';
import styled from 'styled-components';
import useParam from 'libs/hooks/useParam';
import MobileModalHeader from 'components/commons/MobileModalHeader';
import NewAdContainer from 'components/items/NewAdContainer';
import { id } from 'date-fns/locale';
import UniversalLayout from 'components/commons/layouts/UniversalLayout';
import DetailPageHeader from 'components/commons/DetailPageHeader';

const NewADPostPage = observer(() => {
  const { myPostStore } = useStore();

  const { get } = useParam();
  const { postid, boardid } = useParams();
  const [searchParam] = useSearchParams();

  const [model, setModel] = useState<PostDto>();
  const [channelId, setChannelId] = useState<number>();
  const [boardId, setBoardId] = useState<number>();
  const [itemId, setItemId] = useState<number>();
  const [postType, setPostType] = useState<'postOnly' | 'withAd' | 'inActive'>();

  const handleModelRegistred = (result: PostDto) => {
    if (result) {
      window.history.back();
    }
  };

  const init = async () => {
    if (!Number.isNaN(itemId) && !Number.isNaN(channelId) && !Number.isNaN(boardId)) {
      const post = (await myPostStore.getPost(
        channelId as number,
        boardId as number,
        itemId as number,
      )) as never as PostDto;
      if (post) {
        setModel(post);
      } else {
        toast('데이터 취득에 실패하였습니다. 다시 시도 해주세요.');
      }
    } else if (Number.isNaN(channelId) && Number.isNaN(boardId)) {
      toast('채널정보가 바르지 않습니다. 다시 시도 해주세요.');
    }
  };

  useEffect(() => {
    if (
      typeof itemId !== 'undefined' &&
      typeof channelId !== 'undefined' &&
      typeof boardId !== 'undefined'
    ) {
      init();
    }
  }, [postType]);

  useLayoutEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);

    const postType = searchParam.get('posttype');

    setItemId(parseInt(postid || '', 10));
    setChannelId(get('channelid', 'number') as number);
    setBoardId(get('boardid', 'number') as number);

    switch (postType) {
      case '1':
        setPostType('withAd');
        break;
      case '2':
        setPostType('postOnly');
        break;
      case '3':
        setPostType('inActive');
        break;
      default:
        break;
    }
  }, []);

  return (
    <UniversalLayout>
      {/* <DetailPageHeader title="새 광고포스트" /> */}
      <>
        {postType === 'withAd' ? (
          <NewAdContainer board={{ id: boardId }} />
        ) : (
          <AdPostEditor
            isAdBoard={postType !== 'postOnly'}
            boardId={boardId as number}
            model={model}
            saveText={'저장'}
            isInactive={postType === 'inActive'}
            onRegistred={handleModelRegistred}
          />
        )}
      </>
    </UniversalLayout>
  );
});

export default NewADPostPage;
