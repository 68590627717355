import React, { useEffect } from 'react';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { SimpleDealDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';

import styled from 'styled-components';
import AssignedDealItem from './AssignedDealItem';

const AssignedDealsList = ({
  dealList,
  onSelected,
  showButton = true,
}: {
  dealList?: LinkDtoWithAssignedDeal[];
  onSelected?: (selectedDeals: SimpleDealDto[]) => void;
  showButton?: boolean;
}) => {
  const { uiStore } = useStore();

  const [newSelectedDeals, setNewSelectedDeals] = React.useState<LinkDtoWithAssignedDeal[]>([]);

  const handleClickShowDeal = (deal: LinkDtoWithAssignedDeal) => {
    window.open(`/share/${deal?.id}`, 'newDeal');
  };

  const handleChangeCheck = (selectedDeal: LinkDtoWithAssignedDeal) => {
    const temp = newSelectedDeals.findIndex((deal) => deal.id === selectedDeal?.id);
    const tempDeals = [...newSelectedDeals];
    if (temp >= 0) {
      tempDeals.splice(temp, 1, selectedDeal);
    }

    // const updatedDeals = newSelectedDeals.map((deal) => {
    //   if (deal.id === selectedDeal.id) {
    //     return selectedDeal;
    //   } else {
    //     return deal;
    //   }
    // });

    if (!showButton) {
      setNewSelectedDeals(tempDeals);
      onSelected?.(tempDeals);
    } else {
      setNewSelectedDeals(tempDeals);
    }
  };

  useEffect(() => {
    if (dealList) {
      const temp = dealList.map((deal) => {
        return { ...deal, assigned: deal.assigned || false };
      });
      setNewSelectedDeals(temp);
    }
  }, [dealList]);

  return (
    <DealListWrapper style={{ padding: 20 }}>
      {newSelectedDeals?.map((deal: LinkDtoWithAssignedDeal, index: number) => (
        <>
          <AssignedDealItem
            deal={deal}
            onClickMore={showButton ? () => handleClickShowDeal(deal) : undefined}
            onChanged={(value: boolean) => {
              handleChangeCheck({ ...deal, assigned: value });
            }}
            index={index}
          />
        </>
      ))}
      {showButton && (
        <OhmossButton
          style={{
            margin: 'auto',
            marginTop: 30,
            width: '90%',
          }}
          type="primaryPc"
          onClick={() => {
            // onSelected?.(newSelectedDeals.filter((deal) => deal.assigned));
            onSelected?.(newSelectedDeals);
            uiStore.bottomSheet.close();
          }}
        >
          확인
        </OhmossButton>
      )}
    </DealListWrapper>
  );
};

const DealListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
`;

export default AssignedDealsList;
