import React, { useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { LandingPageWrapper } from './LandingPageWrapper';
import { BodyContainer, DetailText, MainText, SubText, TextContainer } from './Styles';

const LandingSlideF = ({ isMobile }: { isMobile?: boolean }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  return (
    <LandingPageWrapper>
      <BodyContainer style={{ position: 'relative' }}>
        <TextContainer>
          <DetailText>공구 대시보드</DetailText>
          <MainText>피드백, 이건 정말 중요!</MainText>
          <SubText>
            셀러의 공구는 매번 다르고, 공구를 하면서 다양한 경험이 생기게 됩니다. 이 경험을 잘
            정리해서 셀러는 방향을 정하고, 성공하는 공구를 만들어 나가게 됩니다. 이에, 오모스는 각
            공구별 정산계산서, 리뷰, 대시보드를 제공해서 셀러 스스로가 본인의 공구 경험을 잘 정리할
            수 있게 도와드립니다.
          </SubText>
        </TextContainer>
        <div>
          <Swiper
            style={{ maxWidth: isMobile ? '100vw' : 500 }}
            modules={[Navigation, Pagination, Autoplay]}
            navigation
            pagination
            speed={1200}
            autoplay={{ delay: 3000 }}
          >
            <SwiperSlide style={{ height: 550 }}>
              <CenterImg>
                <img alt="left" src="/images/landing/slidef_image1.svg" style={{}} />
              </CenterImg>
            </SwiperSlide>
            <SwiperSlide style={{ height: 550 }}>
              <CenterImg>
                <img alt="left" src="/images/landing/slidef_image2.svg" style={{}} />
              </CenterImg>
            </SwiperSlide>
            <SwiperSlide style={{ height: 550 }}>
              <CenterImg>
                <img alt="left" src="/images/landing/slidef_image3.svg" style={{}} />
              </CenterImg>
            </SwiperSlide>
          </Swiper>
        </div>
      </BodyContainer>
    </LandingPageWrapper>
  );
};

const CenterImg = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: auto;
`;

export default LandingSlideF;
