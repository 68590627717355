import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';

const TokenTest = observer(() => {
  const [token, setToken] = useState<string>();
  useEffect(() => {
    const action = localStorage.getItem('error');
    if (action) setToken(action);
  }, []);

  return <div style={{ marginTop: 100, padding: 40 }}>tokens : {token}</div>;
});

export default TokenTest;
