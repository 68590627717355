import { makeObservable, flow, observable, action } from 'mobx';
import { MyPost as MyPostService } from 'services/MyPost';
import { HttpClient } from 'services/http-client';
import { PagenationType, PostModel } from 'types/CommonTypes';
import { PostCommentRequestDto, PostCommentDto, PostDto } from 'services/data-contracts';

import i18n from 'i18n';
import _ from 'lodash';
import ServiceStoreBase from './ServiceStoreBase';

/**
 * @description Public Link Store
 */
class MyPostStore extends ServiceStoreBase {
  t = i18n.t;

  constructor() {
    super();
    makeObservable(this, {
      posts: observable,
      post: observable,
      postComments: observable,
      postPaignation: observable,

      unMount: action,
      updateFeedByItem: action,
      getPosts: flow,
      getPost: flow,
      createPost: flow,
      updatePost: flow,
      deletePost: flow,
      getBoardComment: flow,
      createBoardComment: flow,
      updateBoardComment: flow,
      deleteBoardComment: flow,
      reOrderPostItem: flow,
    });
  }

  api: MyPostService<unknown> = new MyPostService(new HttpClient());

  posts: Array<PostDto> | undefined = [];

  post: PostDto | undefined;

  postComments: Array<PostCommentDto>;

  postPaignation: PagenationType;

  /** ========================================================================================= */

  *getPosts(channelId: number, boardId: number, query?: any) {
    const { response } = yield this.api.getPosts(channelId, boardId, { ...query, sort: ['seq'] });
    this.posts = response.data.slice();
    const pagenation = response.headers['x-pagination'];
    if (pagenation) {
      this.postPaignation = JSON.parse(pagenation);
    }
    return response.data;
  }

  *getPost(channelId: number, boardId: number, postId: number) {
    const { response } = yield this.api.getPost1(channelId, boardId, postId);
    // this.posts = response.data.slice();

    return response.status === 200 ? response.data : null;
  }

  *getItem(linkId: number, args?: Array<never>) {
    // if (args) {
    //   const { response } = yield this.api.getFeed1(args[0], linkId);
    //   this.feed = response.data;
    //   return response.data;
    // }
  }

  *createPost(channelId: number, boardId: number, postItem: PostDto) {
    const { response } = yield this.api.createPost(channelId, boardId, postItem);
    super.handleByStatus(response, { successMessage: this.t('A218') });
    return response.data;
  }

  *updatePost(channelId: number, boardId: number, postId: number, postItem: PostDto) {
    const { response } = yield this.api.updatePost(channelId, boardId, postId, postItem);
    super.handleByStatus(response, { successMessage: this.t('A219') });
    return response.data;
  }

  *deletePost(channelId: number, boardId: number, postId: number) {
    const { response } = yield this.api.deletePost(channelId, boardId, postId);
    super.handleByStatus(response, { successMessage: this.t('A220') });
    return response.status === 200;
  }

  *getBoardComment(channelName: string, feedId: number) {
    // todo :paging 처리 할것
    // const { response } = yield this.api.getFeedComments(channelName, feedId);
    // this.boardComments = response.data;
  }

  *createBoardComment(channelName: string, feedId: number, commentData: PostCommentRequestDto) {
    // const { response } = yield this.api.createFeedComment(channelName, feedId, commentData);
  }

  *updateBoardComment(
    channelName: string,
    feedId: number,
    commentId: number,
    commentData: PostCommentRequestDto,
  ) {
    // const { response } = yield this.api.updateFeedComment(
    //   channelName,
    //   feedId,
    //   commentId,
    //   commentData,
    // );
    // return super.handleByStatus(response, { successMessage: '댓글이 수정되었습니다.' });
  }

  *deleteBoardComment(
    channelName: string,
    feedId: number,
    commentId: number,
    commentData: PostCommentRequestDto,
  ) {
    // const { response } = yield this.api.deleteFeedComment(
    //   channelName,
    //   feedId,
    //   commentId,
    //   commentData,
    // );
    // return super.handleByStatus(response, { successMessage: '댓글이 삭제되었습니다.' });
  }

  *reOrderPostItem(channelId: number, boardId: number, boardItem: PostDto) {
    const { response } = yield this.api.updateSeqOfPost(
      channelId,
      boardId,
      boardItem?.id as number,
      boardItem,
    );
    return response.status === 200;
  }

  updateFeedByItem(feed: PostModel, type?: 'delete' | 'update' | 'new') {
    if (this.posts) {
      const index = this.posts.findIndex((recentLink: PostModel) => recentLink.id === feed.id);
      const newPosts = [...this.posts];
      if (type === 'delete') {
        newPosts.splice(index, 1);
      } else if (type === 'update') {
        feed.isNew = true;
        newPosts.splice(index, 1, feed);
      } else {
        feed.isNew = true;
        newPosts.unshift(feed);
      }
      this.posts = [...newPosts];
    }
  }

  unMount() {
    // this.feeds = undefined;
    this.post = undefined;
  }
}

export default MyPostStore;
