import React from 'react';

import { observer } from 'mobx-react';
import { Popover, IconButton } from '@mui/material';

import styled from 'styled-components';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import ColorPickerInstance from './ColorPickerInstance';

const SimpleColorPickerWithPopOver = observer(
  ({
    useGradient,
    useOpacity,
    disabled,
    defaultValue,
    onClose,
    onColorChanged,
  }: {
    useGradient: boolean;
    useOpacity?: boolean;
    disabled?: boolean;
    defaultValue?: any;
    onClose?: () => void;
    onColorChanged: (color: string) => void;
  }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <>
        <IconButton
          aria-describedby={id}
          onClick={(e) => {
            handleClick(e);
          }}
          disabled={disabled}
        >
          <ColorLensOutlinedIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          style={{ paddingTop: 20, overflow: 'scroll', zIndex: 10000000 }}
        >
          <ColorPickerContainerStyle>
            <ColorPickerInstance
              useOpacity={useOpacity}
              useGradient={useGradient}
              onClose={() => {
                handleClose();
                if (onClose) {
                  onClose();
                }
              }}
              onColorChanged={onColorChanged}
              defaultColor={defaultValue}
            />
          </ColorPickerContainerStyle>
        </Popover>
      </>
    );
  },
);

const ColorPickerContainerStyle = styled.div`
  margin-top: 20px;
  width: 350px;
`;

export default SimpleColorPickerWithPopOver;
