import { makeAutoObservable } from 'mobx';
import { MyAnalyticService } from 'services/MyAnalyticService';

import { HttpClient } from 'services/http-client';

class MyAnalyticsStore {
  api: MyAnalyticService<unknown> = new MyAnalyticService(new HttpClient());

  constructor() {
    makeAutoObservable(this);
  }

  *getLinkClickCount(channelId: number) {
    const { response } = yield this.api.getTotalLinkClickCountByChannel(channelId);
    return response.data;
  }

  *getDailyTotalLinkClickCountsByChannel(
    channelId: number,
    query: { startDate: string; endDate: string },
  ) {
    const { response } = yield this.api.getDailyTotalLinkClickCountsByChannel(channelId, query);
    return response.data;
  }

  *getDailyFollowerCountsByChannel(
    channelId: number,
    query: { startDate: string; endDate: string },
  ) {
    const { response } = yield this.api.getDailyFollowerCountsByChannel(channelId, query);
    return response.data;
  }

  *getVisitCount(channelId: number) {
    const { response } = yield this.api.getVisitCountByChannel(channelId);
    return response.data;
  }

  *getFollowerCount(channelId: number) {
    const { response } = yield this.api.getFollowerCountByChannel(channelId);
    return response.data;
  }

  *getCountryClicks(dealId: number) {
    const { response } = yield this.api.getCountryClickCountsByDeal(dealId);
    return response.data;
  }

  *getRegionClicks(dealId: number) {
    const { response } = yield this.api.getRegionClickCountsByDeal(dealId);
    return response.data;
  }

  *getRefererClickCountsByDeal(dealId: number) {
    const { response } = yield this.api.getRefererClickCountsByDeal(dealId);
    return response.data;
  }

  *getDailyFollowerCountsByDeal(dealId: number) {
    const { response } = yield this.api.getDailyFollowerCountsByDeal(dealId);
    return response.data;
  }

  *getDailyEncoreCountsByDeal(dealId: number, query: { startDate: string; endDate: string }) {
    const { response } = yield this.api.getDailyEncoreCountsByDeal(dealId, query);
    return response.data;
  }

  *getDailyClicks(dealId: number) {
    const { response } = yield this.api.getDailyClickCountsByDeal(dealId);
    return response.data;
  }

  *getDailyVisitCounts(channelId: number, startDate: string, endDate: string) {
    const { response } = yield this.api.getDailyVisitCountsByChannel(channelId, {
      startDate,
      endDate,
    });
    return response.data;
  }

  *getEncoreCounts(dealId: number, startDate: string, endDate: string) {
    const { response } = yield this.api.getDailyEncoreCountsByDeal(dealId, { startDate, endDate });
    return response.data;
  }

  *getOutboundClicks(dealId: number) {
    const { response } = yield this.api.getDailyOutboundClickCountsByDeal(dealId);
    return response.data;
  }

  *getDailyTotalLinkOutboundClickCounts(
    channelId: number,
    query: { startDate: string; endDate: string },
  ) {
    const { response } = yield this.api.getDailyTotalLinkOutboundClickCountsByChannel(
      channelId,
      query,
    );
    return response.data;
  }
}

export default MyAnalyticsStore;
