import ServiceStoreBase from 'stores/ServiceStoreBase';

class CRUDHelper<T> {
  private model: T;

  private store: ServiceStoreBase;

  constructor(store: ServiceStoreBase, model?: T | undefined) {
    if (typeof model !== 'undefined') {
      this.model = model;
    }
    this.store = store;
  }

  async getItem<T>(id: number): Promise<T> {
    const result = await this.store?.getItem(id);
    return result as unknown as T;
  }

  async getItems<T>(...args: any): Promise<T> {
    const result = await this.store?.getItems(args);
    return result as unknown as T;
  }

  async create(...args: any): Promise<any> {
    if (typeof this.model !== 'undefined') {
      const result = await this.store?.createItem(this.model, args);
      return result;
    }
    return false;
  }

  async update(id: number, ...args: any): Promise<any> {
    if (typeof this.model !== 'undefined') {
      const result = await this.store?.updateItem(id, this.model, args);
      return result;
    }
    return false;
  }

  async delete(id: number, ...args: any): Promise<any> {
    if (id) {
      const result = await this.store?.deleteItem(id, args);
      return result;
    }
    return false;
  }
}

export default CRUDHelper;
