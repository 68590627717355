import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { FormHelperText, InputAdornment, TextField } from '@mui/material';
import { validationHelper } from 'controls/validationHelper';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: any) => ({
  outlinedInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.625rem',
      border: ({ model }: any) => (model?.length > 0 ? '1.5px solid #173300' : 'none'),
      background: 'rgba(23, 51, 0, 0.1)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.625rem',
      border: '1.5px solid #173300',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.625rem',
      border: '1.5px solid #173300',
    },
  },
});

const EmailForm = forwardRef(
  (
    {
      model,
      onChangeValue,
      onEnter,
      style,
      classes,
      placeholder,
      type,
      surfix,
    }: {
      model?: string;
      onChangeValue: (value: string) => void;
      onEnter?: () => void;
      style?: any;
      classes?: any;
      placeholder?: string;
      type?: 'login' | 'input';
      surfix?: any;
    },
    ref,
  ) => {
    const [isValid, setIsValid] = useState<boolean>(true);
    const [titleModel, setTitleModel] = useState<string>('');

    const checkValidate = (value: string) => {
      const result = validationHelper.email(value);
      setIsValid(result);
    };

    const handleEnterKeyDown = (event: any) => {
      if (onEnter) {
        if (event.key === 'Enter') {
          event.preventDefault();
          onEnter();
        }
      }
    };

    useEffect(() => {
      if (typeof model !== 'undefined') {
        setTitleModel(model);
        checkValidate(model);
      }
      return () => {
        setTitleModel('');
      };
    }, [model]);

    return (
      <>
        <TextField
          className={classes.outlinedInput}
          required
          fullWidth
          inputRef={ref}
          error={!isValid}
          placeholder={placeholder}
          onKeyDown={handleEnterKeyDown}
          inputProps={{
            style: { padding: '1.2rem 1rem 1rem 1.2rem' },
          }}
          InputProps={{
            endAdornment: <InputAdornment position={'end'}>{surfix && surfix}</InputAdornment>,
            style: {
              color: 'rgba(0, 0, 0, 0.87)',
              fontWeight: 400,
              fontSize: '0.9125rem',
            },
            onBlur: (e: any) => {
              checkValidate(e.target.value);
            },
          }}
          onChange={(e: any) => {
            checkValidate(e.target.value);
            setTitleModel(e.target.value);
            onChangeValue(e.target.value);
          }}
          sx={{ width: style?.width ? style?.width : '100%' }}
          value={titleModel}
          style={{ marginBottom: '0px' }}
        />
        {!isValid && <FormHelperText> {'이메일 형식이 바르지 않습니다.'}</FormHelperText>}
      </>
    );
  },
);

export default withStyles(styles)(EmailForm);
