import React, { useState, useEffect, useRef } from 'react';
import { Avatar } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import styled from 'styled-components';

const CustomAvatar = ({ url }: { url?: string }) => {
  return (
    <>
      {typeof url !== 'undefined' ? (
        <Avatar sx={{ width: 60, height: 60, backgroundColor: '#999' }} src={url} />
      ) : (
        <Avatar sx={{ width: 60, height: 60, backgroundColor: '#999' }}>
          <CameraAltIcon />
        </Avatar>
      )}
    </>
  );
};

export default CustomAvatar;
