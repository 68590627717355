import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { LandingPageWrapper } from './LandingPageWrapper';
import useToken from 'libs/hooks/useToken';

const FooterMobile = () => {
  const navigate = useNavigate();
  const { validUser } = useToken();
  return (
    <LandingPageWrapper
      style={{
        backgroundColor: '#173300',
        border: '2px solid #000',
        minHeight: 'unset',
        padding: '40px 0',
      }}
    >
      <BodyContainer style={{ paddingBottom: validUser() ? 50 : 0 }}>
        <CenterImgContainer>
          <MainImg>
            <img alt="mascot" src="/images/landing/ohmoss_mascot_ver2.svg" />
          </MainImg>
          <TextContainer>
            <MainText>
              프로페셔널
              <br />
              인플루언서 링크
            </MainText>
            <img
              alt="logo"
              src="/images/landing/ohmoss_main_logo_light.svg"
              style={{ width: '80%', marginTop: 10 }}
            />
          </TextContainer>
        </CenterImgContainer>
        <DownloadBtnContainer>
          <img alt="apple" src="/images/landing/appstore_ver2.svg" />
          <img alt="google" src="/images/landing/playstore_ver2.svg" />
        </DownloadBtnContainer>
        <Divider />
        <InfoTextContainer>
          <img
            alt="logo"
            src="/images/landing/ohmoss_main_logo_light.svg"
            style={{ width: '40%', marginBottom: 30, marginTop: 10 }}
          />
          <InfoText>
            <AboutUsTextContainer>
              <InfoText onClick={() => navigate('/company')}>Company |</InfoText>
              <InfoText onClick={() => navigate('/aboutus')}>&nbsp;About us</InfoText>
            </AboutUsTextContainer>
            <AboutUsTextContainer style={{ marginTop: 2 }}>
              <InfoText onClick={() => navigate('/my/userterms')}>이용약관</InfoText>
              {/* <InfoText onClick={() => navigate('/aboutus')}></InfoText> */}
              {/* <InfoText onClick={() => navigate('/aboutus')}></InfoText> */}
            </AboutUsTextContainer>
            <AboutUsTextContainer style={{ marginTop: 2 }}>
              <InfoText onClick={() => navigate('/my/privacypolicy')}>개인정보 처리방침</InfoText>
              {/* <InfoText onClick={() => navigate('/aboutus')}>|&nbsp;공지 사항</InfoText> */}
              {/* <InfoText onClick={() => navigate('/aboutus')}>&nbsp;제휴/제안</InfoText> */}
            </AboutUsTextContainer>
            <div style={{ marginTop: 10, fontSize: 13 }}>
              (주)엔비에이치디
              <br />
              대표이사 : 홍성표 <br />
              사업자 번호 : 850-87-02137 <br />
              서울시 성동구 아차산로 17길 47
              <br /> Copyright©NBHD Inc. All right reserved.
            </div>
          </InfoText>
          <AboutUsTextContainer>
            <a href="https://www.instagram.com/ohmoss_official/">
              <img src="/images/landing/instagram-line.svg" alt="instagram" />
            </a>
          </AboutUsTextContainer>
        </InfoTextContainer>
      </BodyContainer>
    </LandingPageWrapper>
  );
};

const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 15px 0 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 0px;
`;

const CenterImgContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MainImg = styled.div`
  display: flex;
  align-items: center;
`;

const MainText = styled.div`
  display: block;
  flex-direction: column;
  font-weight: 700;
  font-size: 24px;
  color: #96e06e;
  margin-top: 40px;
  padding-left: 20px;
  @media screen and (max-height: 700px) {
    font-size: 22px;
  }
`;

const InfoTextContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const InfoText = styled.div`
  /* text-align: center; */
  font-size: 12px;
  color: #96e06e;
  font-weight: 400;

  @media screen and (max-height: 700px) {
    font-size: 14px;
  }
`;

const AboutUsTextContainer = styled.div`
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: 12px;
  color: #96e06e;
  font-weight: 400;
  /* font-family: Apple SD Gothic Neo; */
  margin-top: 10px;
  /* gap: 20px; */
  > div {
    cursor: pointer;
  }
`;

const DownloadBtnContainer = styled.div`
  display: flex;
  z-index: 0;
  /* z-index: 2; */
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 15px;
  margin-top: 26px;
`;

const Divider = styled.div`
  width: 100%;
  /* background-color: #96e06e; */
  margin-top: 30px;
  margin-bottom: 30px;
  border: 0.5px solid #96e06e;
`;

export default FooterMobile;
