import React from 'react';
import styled from 'styled-components';

const MenuItemBox = ({
  title,
  style,
  children,
}: {
  title?: React.ReactNode | string;
  style?: React.CSSProperties;
  children?: React.ReactElement[] | React.ReactElement;
}) => {
  return (
    <>
      {title && <ItemBoxTitleStyle style={style}>{title}</ItemBoxTitleStyle>}
      {children ? children : <></>}
    </>
  );
};

const ItemBoxTitleStyle = styled.div`
  color: #bebebe;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  padding: 5px 0px;
  margin-top: 20px;
`;
export default MenuItemBox;
