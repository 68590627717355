import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { GroupChannel } from '@sendbird/chat/groupChannel';

import useSandbirdChat from 'hooks/useSandbirdChat';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useChat } from 'libs/hooks/useChat';
import { useStateContext } from 'libs/hooks/usePageState';
import { ChannelQueryType, MyInquiryState } from 'types/CommonTypes';
import * as Containers from '../containers';

const CSTownInquiriesPage = () => {
  const inquiryRef = useRef<GroupChannel[]>([]);
  const { receiver } = useStateContext<MyInquiryState>();
  const { initCompleted } = useChat();
  const { myChannel } = useRootContext();
  const { getInquiryChannels, dispose, markAsRead } = useSandbirdChat();

  const [inquiries, setInquiries] = useState<GroupChannel[]>([]);

  const { channels } = useSandbirdChat();
  const [currentChannelUrl, setCurrentChannelUrl] = useState('');

  /**
   * @description 내 문의 목록의 탭 변경에 따른 문의 조회 컴포넌트
   * @param type CUSTOMER : 고객문의 , PARTNER : 멤버간 문의
   */
  const getInquiriesByType = (type: 'CUSTOMER' | 'PARTNER') => {
    if (myChannel) {
      const query: ChannelQueryType = {};
      if (type === 'PARTNER') {
        // if (myChannel?.channelType === 'SELLER') {
        //   query.channelType = 'INQUIRY';
        //   query.metaKey = 'owner';
        //   query.metaValues = [myChannel?.id!.toString()];
        // } else {
        // Brand ,Member모두
        query.channelType = 'MEMBER';
        // }
      } else if (myChannel?.channelType === 'SELLER') {
        query.channelType = 'OWNER';
        query.metaKey = 'dealOwner';
        query.metaValues = [myChannel?.id!.toString()];
      } else {
        query.channelType = 'DELEGATED';
        query.metaKey = 'dealOwner';
        query.metaValues = [myChannel!.id!.toString()];
      }
      getInquiryChannels(query);
    }
  };

  useEffect(() => {
    if (myChannel && initCompleted) {
      getInquiriesByType('CUSTOMER');
    }
  }, [myChannel, initCompleted]);

  useEffect(() => {
    if (receiver?.id === 'UPDATE') {
      if (receiver?.param === 'CUSTOMER') {
        getInquiriesByType('CUSTOMER');
      } else {
        getInquiriesByType('PARTNER');
      }
    }
  }, [receiver]);

  const handleLeaveChannel = () => {
    const updatedChannel = inquiries?.filter((inquiry) => inquiry.url !== currentChannelUrl);

    if (updatedChannel) {
      inquiryRef.current = updatedChannel;
      setInquiries(updatedChannel);
    }
  };

  useEffect(() => {
    return () => {
      dispose();
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <Containers.MobileContainers.MobildMyInquiriesContainer
          inquiries={channels}
          onJoinChannel={(channel: GroupChannel) => {
            setCurrentChannelUrl(channel?.url);
          }}
          channel={currentChannelUrl}
        />
      ) : (
        <Containers.PCContainers.MyInquriesContainer
          inquiries={channels}
          onJoinChannel={(channel: GroupChannel) => {
            setCurrentChannelUrl(channel?.url);
            markAsRead(channel.url);
          }}
          onLeaveChannel={handleLeaveChannel}
          channel={currentChannelUrl}
        />
      )}
    </>
  );
};

export default CSTownInquiriesPage;
