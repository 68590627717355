import React, { useState, useEffect, useRef } from 'react';

import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Row } from 'components/commons/layouts';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import moment from 'moment';
import { toast } from 'react-toastify';
import Required from 'components/commons/Required';
import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import { EventType } from 'types/CommonTypes';

import { useModelManagement } from 'libs/hooks/useModelUpdate';
import TimePicker from './TimePicker';

const DateRangePicker = ({
  event,
  type,
  timeRange,
  useAllday,
  mode,
}: {
  event?: EventType;
  type?: 'RANGE' | 'DEAL';
  timeRange: (event?: EventType) => void;
  useAllday?: boolean;
  mode?: 'NEW' | 'EDIT';
}) => {
  const FORMAT_LOCAL = 'YYYY-MM-DD HH:mm:ss';
  const FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';

  const tempAllDay = useRef<EventType>();
  const endDateRef = useRef<any>();
  const startValid = useRef<boolean>(true);
  const endValid = useRef<boolean>(true);

  // 시작일보다 종료일이 더 빠른 경우, 종료일을 시작일로부터 30분 뒤로 설정
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [minHour, setMinHour] = useState<string>();
  const [isAllday, setIsAllday] = useState<boolean>();
  const [initialized, setInitialized] = useState<boolean>(false);

  const { serviceModel, updateServiceModelMultiple } = useModelManagement<EventType>();

  const setNewTime = () => {
    if (serviceModel && serviceModel.start && serviceModel.end) {
      const startTime = moment(serviceModel.start).local();
      const endTime = moment(serviceModel.end).local();

      const timePlus30Minutes = startTime.clone().add(30, 'minutes');
      setMinHour(timePlus30Minutes.format('HH:mm'));

      // const now = new Date();
      const start = new Date(serviceModel.start);
      const end = new Date(serviceModel.end);

      // if (type === 'DEAL' && now > start) {
      //   toast('시작 시간은 현재시간 이후여야 합니다.', { type: 'warning' });
      // }

      if (end < start) {
        toast('종료 시간이 시작 시간보다 과거일 수 없습니다.', { type: 'warning' });
        const newEndTime = moment(startTime).add(0, 'minutes');
        setEndDate(newEndTime.format(FORMAT_LOCAL));
        timeRange({
          start: startTime.format(FORMAT),
          end: newEndTime.format(FORMAT),
          allDay: serviceModel.allDay,
          isValid: startValid.current && endValid.current,
        });
      } else {
        timeRange({
          start: startTime.utc().format(FORMAT),
          end: endTime.utc().format(FORMAT),
          allDay: serviceModel.allDay,
          isValid: startValid.current && endValid.current,
        });
      }
    } else {
      timeRange(undefined);
    }
  };

  const setallDay = (value: boolean) => {
    tempAllDay.current = { ...serviceModel };
    if (value) {
      const allDayStart = moment(serviceModel?.start);
      const startOfDay = allDayStart.clone().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      const endOfDay = allDayStart.clone().set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
      setEndDate(startOfDay.format(FORMAT_LOCAL));

      updateServiceModelMultiple({
        start: startOfDay.format(FORMAT_LOCAL),
        end: endOfDay.format(FORMAT_LOCAL),
        allDay: value,
      });
    } else {
      setEndDate(tempAllDay.current?.end);
      updateServiceModelMultiple({ ...tempAllDay.current, allDay: value });
    }
    setIsAllday(value);

    setNewTime();
  };

  useEffect(() => {
    if (typeof event?.start !== 'undefined' && initialized === false) {
      setIsAllday(event.allDay);
      const localStart = moment.utc(event.start).local().format(FORMAT_LOCAL);
      const localEnd = moment.utc(event.end).local().format(FORMAT_LOCAL);
      updateServiceModelMultiple({
        ...event,
        start: localStart,
        end: localEnd,
      });
      setStartDate(localStart);
      setEndDate(localEnd);
      setInitialized(true);
    } else {
      const localStart = moment.utc().local();

      if (typeof event?.start === 'undefined') {
        updateServiceModelMultiple({
          ...event,
          start: `${localStart.format('YYYY-MM-DD')} 08:00`,
          end: `${localStart.format('YYYY-MM-DD')} 08:30`,
        });
      }
      setStartDate(serviceModel?.start);
      setEndDate(serviceModel?.end);
      if (initialized === false) {
        timeRange({
          start: moment(serviceModel?.start as string)
            .utc()
            .format(FORMAT),
          end: moment(serviceModel?.end as string)
            .utc()
            .format(FORMAT),
          allDay: serviceModel?.allDay,
          isValid: startValid.current && endValid.current,
        });
        setInitialized(true);
      }
    }
  }, [event]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ItemSectionStyle>
          <ItemSectionTitle>
            <Row style={{ justifyContent: 'space-between' }}>
              <Row style={{ width: 'unset' }}>
                <div>시작 일시</div>
                <Required />
              </Row>
              <>
                {useAllday && (
                  <MenuItemCheckBox
                    onlySwitch
                    initialValue={event?.allDay}
                    onChangeValue={(value: boolean) => {
                      setallDay(value);
                    }}
                    title="하루종일"
                  />
                )}
              </>
            </Row>
          </ItemSectionTitle>
          <ItemContent>
            <TimePicker
              onBlur={setNewTime}
              pickerType="start"
              alldayEvent={isAllday}
              initialValue={startDate}
              onChange={(time: string, hasError?: boolean) => {
                startValid.current = !hasError;
                if (time) {
                  const startTemp = moment(time || '');
                  const endTemp = moment(serviceModel?.end || '');
                  if (startTemp > endTemp) {
                    const newTime = startTemp.add(30, 'minutes').format(FORMAT_LOCAL);

                    updateServiceModelMultiple({
                      ...serviceModel,
                      start: startTemp.format(FORMAT_LOCAL),
                      end: newTime,
                    });

                    const ss = serviceModel;
                    setEndDate(newTime);
                    setStartDate(time);
                  } else {
                    updateServiceModelMultiple({
                      ...serviceModel,
                      start: startTemp.format(FORMAT_LOCAL),
                    });
                    setStartDate(startTemp.format(FORMAT_LOCAL));
                  }
                }
                setNewTime();
              }}
            />
          </ItemContent>
        </ItemSectionStyle>
        <ItemSectionStyle>
          <ItemSectionTitle>
            종료 일시 <Required />
          </ItemSectionTitle>
          <ItemContent>
            <TimePicker
              onBlur={setNewTime}
              pickerType="end"
              ref={endDateRef}
              startDate={startDate}
              minTime={minHour}
              alldayEvent={isAllday}
              initialValue={endDate}
              onChange={(time: string, hasError?: boolean) => {
                endValid.current = !hasError;
                if (time) {
                  const startTemp = moment(serviceModel?.start || '');
                  const endTemp = moment(time || '');
                  setEndDate(endTemp.format(FORMAT_LOCAL));

                  if (startTemp > endTemp) {
                    const newTime = moment(serviceModel?.start).format(FORMAT_LOCAL);
                    updateServiceModelMultiple({
                      ...serviceModel,
                      start: newTime,
                      end: startTemp.add(30, 'minute').format(FORMAT_LOCAL),
                    });

                    setEndDate(startTemp.add(30, 'minute').format(FORMAT_LOCAL));
                    endDateRef.current.setDate(startTemp.add(30, 'minute').format(FORMAT_LOCAL));
                  } else {
                    updateServiceModelMultiple({
                      ...serviceModel,
                      end: endTemp.format(FORMAT_LOCAL),
                    });
                  }
                }
                setNewTime();
              }}
            />
          </ItemContent>
        </ItemSectionStyle>
      </LocalizationProvider>
    </>
  );
};

export default DateRangePicker;
