import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { observer } from 'mobx-react';
import { useFollowContext } from 'providers/follow/useFollowContext';
import { Button } from '@mui/material';

type props = {
  channelName?: string; action?: string; isFollow?: boolean; isMy?: boolean;
};

const HomeFollowButtonsContainer = observer((props: props) => {
  const { follow, followInfo } = useFollowContext();

  const [isFollow, setIsFollow] = useState<boolean>();

  useEffect(() => {
    if (typeof props.isFollow !== 'undefined') {
      setIsFollow(props.isFollow);
    }
  }, [props.isFollow]);

  useEffect(() => {
    if (typeof followInfo !== 'undefined' && props.channelName) {
      setIsFollow(followInfo.isFollow);
    }
  }, [followInfo]);



  return (
    <div className="row" style={{ marginLeft: 5 }}>
      <>
        {!props.isMy && (
          <FollowButtonStyle
            style={{
              padding: '0px 3px',
              fontSize: 12,
              minWidth: 0,
              color: '#fff',
              border: isFollow ? '1px solid transparent' : '1px solid #bebebe',
              background: isFollow ? '#0bbd58' : '#bebebe'
            }}
            isFollow={isFollow}
            onClick={async () => {
              if (typeof props.channelName !== 'undefined' && typeof isFollow !== 'undefined') {
                follow(props.channelName, isFollow);
                setIsFollow(props.isFollow);
              }

            }}
          >
            {isFollow ? '팔로잉' : '팔로우'}
          </FollowButtonStyle>
        )}
      </>
    </div>
  );
},
);

const FollowButtonStyle = styled(Button) <{ isFollow?: boolean; }>`
  border: ${(props) => (props.isFollow ? '1px solid transparent' : '1px solid #bebebe')};
  border-radius: 5px;
  font-size: 12px;
  padding: 3px 5px;
  margin-left: 10px;
  background: ${(props) => (props.isFollow ? '#0bbd58' : '#bebebe')};
`;

export default HomeFollowButtonsContainer;
