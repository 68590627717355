import { makeAutoObservable } from 'mobx';
import {
  ChannelChecklistCreationDto,
  ChannelChecklistItemUpdateDto,
  ChannelChecklistTopicDto,
  ChannelChecklistUpdateDto,
} from 'services/data-contracts';

import _ from 'lodash';
import { HttpClient } from 'services/http-client';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import { ChecklistService } from 'services/ChecklistService';
import { MyChecklistService } from 'services/MyChecklistService';
import { EventSourcePolyfill } from 'event-source-polyfill';

class ChecklistStore {
  t = i18n.t;

  busyStatus: 'loading' | 'done' | 'init' | '';

  messages: string;

  constructor() {
    makeAutoObservable(this);
  }

  api: ChecklistService<unknown> = new ChecklistService(new HttpClient());

  api2: MyChecklistService<unknown> = new MyChecklistService(new HttpClient());

  *getMyChecklist(channelId: number, checklistId: number) {
    const { response } = yield this.api2.getMyChecklist(channelId, checklistId);
    // console.log('response', response);
    return response.status < 300 ? response.data : null;
  }

  *getChecklistsByCategory(categoryId: number, ownChannelId: number) {
    const { response } = yield this.api.getChecklistsByCategory({ categoryId, ownChannelId });
    return response.status < 300 ? response.data : null;
  }

  *getMyChecklists(channelId: number, page?: number, size?: number) {
    const { response } = yield this.api2.getMyChecklists(channelId, {
      page,
      size,
      sort: ['createDate,DESC'],
    });
    return response.status < 300 ? response.data : null;
  }

  *createMyChecklist(channelId: number, data: ChannelChecklistCreationDto) {
    const { response } = yield this.api2.createMyChecklist(channelId, data);
    if (response.status < 300) {
      toast('체크리스트가 생성되었습니다.');
    } else {
      toast.error(response.data.errorMessage);
    }
    return response.status < 300 ? response.data : null;
  }

  *getChecklistCategories(ownChannelId: number) {
    const { response } = yield this.api.getChecklistCategories({ ownChannelId });
    if (response.status > 300) {
      toast.error(response.data.errorMessage);
    }
    return response.status < 300 ? response.data : null;
  }

  *getMyChecklistItems(channelId: number, checklistId: number, topicId: number) {
    const { response } = yield this.api2.getMyChecklistItems(channelId, checklistId, topicId);
    if (response.status > 300) {
      toast.error(response.data.errorMessage);
    }
    return response.status < 300 ? response.data : null;
  }

  *getMyChecklistTopics(channelId: number, checklistId: number) {
    const { response } = yield this.api2.getMyChecklistTopics(channelId, checklistId);
    return response.status < 300 ? response.data : null;
  }

  *updateMyChecklist(channelId: number, checklistId: number, data: ChannelChecklistUpdateDto) {
    const { response } = yield this.api2.updateMyChecklist(channelId, checklistId, data);
    if (response.status < 300) {
      toast('체크리스트가 수정되었습니다.');
    } else {
      toast.error(response.data.errorMessage);
    }
    return response.status < 300 ? response.data : null;
  }

  *deleteMyChecklist(channelId: number, checklistId: number) {
    const { response } = yield this.api2.deleteMyChecklist(channelId, checklistId);
    if (response.status < 300) {
      toast('체크리스트가 삭제되었습니다.', { type: 'success' });
    } else {
      toast.error(response.data.errorMessage);
    }
    return response.status < 300 ? response.data : null;
  }

  getTopicSummarize(channelId: number, checklistId: number, topicId: number) {
    this.busyStatus = 'init';

    // eslint-disable-next-line max-len
    const url = `https://alpha-api.ohmoss.com/v2/me/channels/${channelId}/checklists/${checklistId}/topics/${topicId}/summarize`;
    const token = localStorage.getItem('accessToken');
    const eventSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return eventSource;

    // return response.status < 300 ? response.data : null;
  }

  *saveTopicSummarize(
    channelId: number,
    checklistId: number,
    topicId: number,
    topic: ChannelChecklistTopicDto,
  ) {
    const { response } = yield this.api2.updateTopicSummary(channelId, checklistId, topicId, topic);
    return response.status < 300 ? response.data : null;
  }

  *downloadTopicsPdf(channelId: number, checklistId: number) {
    const { response } = yield this.api2.downloadTopicsSummaryPdf(channelId, checklistId);
    return response.status < 300 ? response.data : null;
  }

  *updateMyChecklistItem(
    channelId: number,
    checklistId: number,
    checklistItemId: number,
    topicId: number,
    data: ChannelChecklistItemUpdateDto,
  ) {
    const { response } = yield this.api2.updateMyChecklistItems(
      channelId,
      checklistId,
      topicId,
      checklistItemId,
      data,
    );
    if (response.status < 300) {
      toast('체크리스트 아이템이 수정되었습니다.', { type: 'success' });
    } else {
      toast.error(response.data.errorMessage);
    }
    return response.status < 300 ? response.data : null;
  }

  unMount() {}
}

export default ChecklistStore;
