import React, { useEffect, useState } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import MUIToggleGroup from 'components/commons/buttons/MUIToggleGroup';
import MUIToggle from 'components/commons/buttons/MUIToggle';
import { ProfileFrameA, ProfileFrameB } from 'features/management/resources/images/profile';
import { Layout } from 'types/BlockExtendsTypes';
import useSetDefaultValue from 'features/management/hooks/useDefaultValue';

type CommonProps = {
  initialValue?: string;
  onChange?: (value: string) => void;
};
const ProfileFrame = ({ initialValue, onChange }: CommonProps) => {
  const [value, setValue] = useState<string>();

  useSetDefaultValue(setValue, initialValue);

  useEffect(() => {
    if (typeof value !== 'undefined' && onChange) onChange(value as Layout);
  }, [value]);

  return (
    <>
      <ItemSectionStyle>
        <ItemSectionTitle>프레임</ItemSectionTitle>
        <ItemContent>
          <MUIToggleGroup
            value={value}
            onChange={(e: any, value: string) => {
              setValue(value);
            }}
          >
            <MUIToggle style={{ stroke: 'none', flexGrow: 'unset', width: '78px' }} value="TypeA">
              <ProfileFrameA />
            </MUIToggle>
            <MUIToggle value="TypeB" style={{ flexGrow: 'unset', width: '78px' }}>
              <ProfileFrameB />
            </MUIToggle>
          </MUIToggleGroup>
        </ItemContent>
      </ItemSectionStyle>
    </>
  );
};

export default ProfileFrame;
