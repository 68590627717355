import React, { useEffect, useState } from 'react';
import { Row } from 'components/commons/layouts';
import AddIcon from 'features/csTown/commonComponents/AddIcon';
import DeleteIcon from 'features/csTown/commonComponents/DeleteIcon';
import { DealItemContainerStyle, ListBoxItem } from 'features/csTown/commonComponents/styles';
import { useStore } from 'stores/StoreHelper';
import { DealOptionModel } from 'types/CommonTypes';
import DealOptions from './DealOptions';
import BlockController from 'controllers/BlockController';
import { IDealBlock } from 'types/BlockExtendsTypes';
import { toast } from 'react-toastify';

const ItemCustomerInquiryBox = ({
  options,
  readonly = false,
  onUpdate,
}: {
  options?: DealOptionModel[];
  readonly?: boolean;
  onUpdate: (options: DealOptionModel[]) => void;
}) => {
  const { uiStore } = useStore();

  const [dealOptions, setDealOptions] = useState<DealOptionModel[]>(options || []);

  const handleClickDelete = (index: number) => {
    const newItems = dealOptions?.filter((_, idx) => idx !== index);
    setDealOptions(newItems);
    onUpdate(newItems);
    toast('삭제하였습니다.');
  };

  const handleClickShowDealOptions = () => {
    uiStore.modal.show({
      title: '제품옵션 설정',
      children: <DealOptions items={dealOptions} onChange={onUpdate} />,
      onConfirmed(result) {
        setDealOptions(result);
        //onUpdate(result);
      },
    });
  };

  useEffect(() => {
    if (options) {
      setDealOptions(options);
    }
  }, [options]);

  return (
    <ListBoxItem
      title="공구 옵션 설정"
      action={
        <>
          {!readonly && (
            <AddIcon style={{ background: '#efefef' }} onClick={handleClickShowDealOptions} />
          )}
        </>
      }
    >
      <>
        <Row style={{ marginTop: 10, gap: 10, flexWrap: 'wrap', justifyContent: 'flex-start' }}>
          {options?.map((item: DealOptionModel, index: number) => (
            <Row style={{ alignItems: 'center', marginBottom: 10, width: 'unset' }}>
              <DealItemContainerStyle
                style={{ padding: '5px 10px', paddingRight: 2, alignItems: 'center' }}
              >
                <div style={{ paddingLeft: 15 }}>{item?.name}</div>
                <DeleteIcon
                  style={{ width: 30, height: 30 }}
                  onClick={() => {
                    handleClickDelete(index);
                  }}
                />
              </DealItemContainerStyle>
            </Row>
          ))}
        </Row>
      </>
    </ListBoxItem>
  );
};

export default ItemCustomerInquiryBox;
