import React, { CSSProperties } from 'react';
import {
  ItemSectionStyle,
  ItemSectionTitle,
  ItemSectionDescription,
} from 'resources/controls/ItemSectionStyle';
import { MyStyle } from 'types/CommonTypes';
import { Row } from 'components/commons/layouts';
import { useTranslation } from 'react-i18next';

import CommonFontSelect from 'features/management/components/commonControl/CommonFontSelect';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { MyStyleTypes } from 'features/management/context/providers/ContextTypes';

const LinkDesignItemBoxText = () => {
  const { t } = useTranslation();
  const { serviceModel, setState, initialModel } = useOhmossContext<MyStyleTypes<MyStyle>>();
  return (
    <ItemSectionStyle style={{ padding: 20 }}>
      <ItemSectionTitle>
        <Row style={{ justifyContent: 'space-between' }}>
          <div>{t('A192')}</div>
        </Row>
      </ItemSectionTitle>
      <ItemSectionDescription>{t('A193')}</ItemSectionDescription>

      <div style={{ marginTop: 10 }}>
        <CommonFontSelect
          useTextEffect
          initialValue={initialModel?.textStyle}
          onChange={(value: CSSProperties) => {
            setState('textStyle', {
              ...serviceModel?.textStyle,
              ...value,
            });
          }}
        />
      </div>
    </ItemSectionStyle>
  );
};

export default LinkDesignItemBoxText;
