import { IconButton } from '@mui/material';
import { Row } from 'components/commons/layouts';
import CommonThumbnail from 'features/management/components/commonControl/CommonThumbnail';
import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ChannelChecklistDto } from 'services/data-contracts';

function ChecklistContainer({
  checklist,
  onClickItem,
}: {
  checklist?: ChannelChecklistDto[] | null | undefined;
  onClickItem?: (item: ChannelChecklistDto) => void;
}) {
  const convertDate = (date: string) => {
    if (date) {
      const newDate = new Date(date);
      const newDateString = new Date(date).toLocaleDateString();
      const today = new Date().toLocaleDateString();

      if (newDateString === today) {
        return newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      } else {
        return (
          <span>
            {newDate.getFullYear()}.{newDate.getMonth() + 1}.{newDate.getDate()}
          </span>
        );
      }
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: 20,
        }}
      >
        {checklist?.map((item, i) => (
          <Row
            style={{
              justifyContent: 'space-between',
              paddingBottom: 10,
              borderBottom: 'rgba(0, 0, 0, 0.35) 0.3px solid',
            }}
            onClick={() => onClickItem?.(item)}
          >
            <Row
              style={{
                position: 'relative',
                width: 'unset',
                justifyContent: 'flex-start',
              }}
            >
              <CommonThumbnail initialValue={item?.deal?.thumbnail?.publicUrl} />
            </Row>
            <Row
              style={{
                justifyContent: 'flex-start',
                overflow: 'hidden',
                height: '100%',
              }}
            >
              <Row
                style={{ display: 'flex', flexDirection: 'column', width: '100%', marginLeft: 10 }}
              >
                <Row
                  style={{
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%',
                  }}
                >
                  <div
                    className="item-title"
                    style={{
                      display: 'flex',
                      whiteSpace: 'nowrap',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 8,
                    }}
                  >
                    <img
                      src={item?.category?.image?.publicUrl}
                      alt={item?.title}
                      style={{
                        width: 16,
                        height: 16,
                        borderRadius: '0.625rem',
                        padding: 2,
                        border: '1px solid #D3D3D3',
                        background: '#FFF',
                      }}
                    />
                    <span style={{ color: '#48505D', fontWeight: '500', fontSize: 15 }}>
                      {item?.title}
                    </span>
                  </div>
                </Row>
                <Row
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0px 20px 0px 0px',
                  }}
                >
                  <span style={{ fontSize: 14, color: '#48505D' }}>{item?.title}</span>
                  <span style={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.54)' }}>
                    {convertDate(item?.createDate as string)}
                  </span>
                </Row>
              </Row>
            </Row>
            <>
              <IconButton>
                <ArrowForwardIosIcon style={{ color: '#CACACA', width: 14, height: 14 }} />
              </IconButton>
            </>
          </Row>
        ))}
      </div>
    </div>
  );
}

export default ChecklistContainer;
