import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import { Row } from 'components/commons/layouts';
import { DealOptionModel } from 'types/CommonTypes';

import TitleForm from 'components/items/forms/TitleForm';
import AddIcon from 'features/csTown/commonComponents/AddIcon';
import DeleteIcon from 'features/csTown/commonComponents/DeleteIcon';
import { validationHelper } from 'controls/validationHelper';
import { toast } from 'react-toastify';
import ActionBox from '../../ActionBox';

const DealOptions = ({
  items,
  actionType,
  onChange,
}: {
  items?: DealOptionModel[];
  actionType?: 'Modal' | 'BottomSheet';
  onChange?: (options: DealOptionModel[]) => void;
}) => {
  const { uiStore } = useStore();
  const [dealItems, setDealItems] = useState<DealOptionModel[]>([]);
  const [isValid, setIsValid] = useState<boolean>(false);

  const handleChangeTitle = (option: DealOptionModel, value: string) => {
    const newItems = dealItems.map((item) => {
      if (item.seq === option.seq) {
        return { ...item, name: value };
      }
      return item;
    });
    setDealItems(newItems);
  };

  const handleClickDelete = (index: number) => {
    const newItems = dealItems.filter((_, idx) => idx !== index);
    setDealItems(newItems);
  };

  const handleClickedAdd = () => {
    const seqValues = Object.values(dealItems)
      .map((item) => item.seq)
      .filter((seq): seq is number => seq !== undefined);

    const maxSeq = seqValues.length > 0 ? Math.max(...seqValues) : 0;

    const newItems: DealOptionModel[] = [...dealItems, { seq: maxSeq + 1 }];
    setDealItems(newItems);
  };

  useEffect(() => {
    if (items) {
      const newItems: DealOptionModel[] = items.map((item) => {
        return { ...item, seq: ((item.id as number) || 0) + 1000 };
      });
      setDealItems(newItems);
    }
  }, [items]);

  useEffect(() => {
    dealItems.map((dealItem) => {
      const validateCheck = validationHelper.required(dealItem.name);
      if (validateCheck) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    });
  }, [handleChangeTitle]);

  return (
    <>
      <SettingsContainerStyle>
        {dealItems?.map((item: DealOptionModel, index: number) => (
          <Row style={{ alignItems: 'flex-start' }}>
            <div style={{ width: '100%', marginRight: 0 }}>
              <TitleForm
                placeHolder="옵션명을 입력해주세요."
                initialValue={item.name}
                onChangeValue={(value: string) => {
                  handleChangeTitle(item, value);
                }}
              />
            </div>
            <div style={{ marginTop: 5 }}>
              <DeleteIcon
                style={{ marginLeft: 5 }}
                onClick={() => {
                  handleClickDelete(index);
                }}
              />
            </div>
          </Row>
        ))}

        <AddIcon onClick={() => handleClickedAdd()} style={{ background: '#ebebeb', margin: 20 }} />

        <OhmossButton
          disabled={isValid}
          type="primaryPc"
          onClick={() => {
            if (actionType === 'Modal') {
              uiStore.modal.confirm(dealItems);
            } else {
              onChange?.(dealItems);
              uiStore.modal.close();
              toast('저장되었습니다.');
            }
          }}
        >
          확인
        </OhmossButton>
      </SettingsContainerStyle>
    </>
  );
};

const SettingsContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default DealOptions;
