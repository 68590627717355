import React, { useEffect } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { AdDto, PostDto } from 'services/data-contracts';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useNavigation from 'hooks/useNavigation';
import { OhmossButton } from 'resources/controls/OhmossButton';

import DealEditor from 'components/items/DealEditor';
import MobileModalHeader from 'components/commons/MobileModalHeader';
import LinkPreview from 'features/mossMain/components/LinkPreview';
import UniversalLayout from 'components/commons/layouts/UniversalLayout';
import DetailPageHeader from 'components/commons/DetailPageHeader';

const NewDealPage = observer(() => {
  const { myAdStore, myChannelStore, uiStore, adCategoryStore, myPostStore } = useStore();
  const { postid } = useParams();
  const { navigate } = useNavigation();

  const handleClickConfirm = () => {
    uiStore.confirm.close();
    navigate('/settings/myad?type=3');
  };

  const handleSaveAd = async (ad: AdDto) => {
    const postId = parseInt(postid || 'NaN');
    if (Number.isNaN(postId)) {
      toast('게시물이 바르지 않습니다. 목록에서 다시 시도 해주세요.');
    } else {
      ad.post = { id: postId };
      const result = await myAdStore.createAd(myChannelStore.currentChannel?.id as number, ad);
      if (typeof result !== 'boolean') {
        uiStore.confirm.show({
          title: '광고요청 주의 사항',
          message: '요청된 공구 광고는 이후 내용 수정이 불가능합니다.',
          customButtons: (
            <div className="my-4 w-full">
              <OhmossButton
                type="box"
                onClick={handleClickConfirm}
                style={{ background: '#23BA50', padding: 10, flex: 1, width: '100%' }}
              >
                확인
              </OhmossButton>
            </div>
          ),
        });
      }
    }
  };

  const handleShowPreview = async (ad: AdDto) => {
    const postId = parseInt(postid || 'NaN');
    const bid = localStorage.getItem('dbid');
    const boardId = parseInt(bid || '', 10);
    if (!Number.isNaN(postId) && !Number.isNaN(boardId)) {
      const post = (await myPostStore.getPost(
        myChannelStore.currentChannel?.id as number,
        boardId as number,
        postId,
      )) as PostDto;

      const model: AdDto = { ...ad, post, channel: post.channel };
      uiStore.universalModal.show({
        title: '미리보기',
        useHeader: false,
        header: <MobileModalHeader type="modal" title={'미리보기'} />,
        bottomSheetOptions: { topPosition: 30 },
        modalOption: { style: { height: '100vh' } },
        children: <LinkPreview adItem={model} />,
        onClose: () => {
          window.history.replaceState('', '', '/');
        },
      });
    }
  };

  useEffect(() => {
    if (postid) {
      adCategoryStore.getAdCategories();
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [postid]);

  return (
    <UniversalLayout>
      {/* <DetailPageHeader title="새 광고" /> */}
      <DealEditor
        handleClickAd={(ad: AdDto) => {
          handleSaveAd(ad);
        }}
        handleClickPreview={(ad: AdDto) => {
          handleShowPreview(ad);
        }}
      />
    </UniversalLayout>
  );
});

export default NewDealPage;
