import { RoutePropInterface } from 'types/CommonTypes';
import * as Pages from 'pages';
import * as AdminPages from 'pages/adminPages';
import TokenTest from 'pages/TokenTest';
import NewLinkPage from 'features/linkService/pages/NewLinkPage';
import AcceptInvitationPage from 'features/myPage/pages/AcceptInvitationPage';
import MembershipPlanPage from 'features/myPage/pages/MembershipPlanPage';
import DealsProvider from 'features/mossMain/context/DealsProvider';
import PreviewPage from 'features/management/pages/PreviewPage';
import { HomepageTemplate } from 'components/template';

const mainRoutes: RoutePropInterface[] = [
  // {
  //   path: '/',
  //   title: 'livelink',
  //   element: Pages.HomePage,
  // },
  {
    path: '/:channelName/admin/:menuType',
    title: 'livelink-admin-main',
    // element: isMobile ? AdminPages.MobileAdminMainPage : AdminPages.AdminMainPage,
    element: AdminPages.MobileAdminMainPage,
  },
  {
    path: '/subscriptions',
    title: 'subscriptions',
    element: MembershipPlanPage,
    provider: DealsProvider,
    useHeader: false,
  },

  // {
  //   path: '/admin/spacepage/:type',
  //   title: 'livelink-admin',
  //   element: AdminPages.SpacePage,
  // },
  {
    path: '/admin/profilepage',
    title: 'livelink-profile',
    element: AdminPages.ProfilePage,
  },
  {
    path: '/admin/account',
    title: '계정 설정',
    element: AdminPages.AccountPage,
  },
  // {
  //   path: '/admin/account/info',
  //   title: '계정 정보',
  //   element: AdminPages.AccountInfoPage,
  // },
  // {
  //   path: '/admin/sociallinks',
  //   title: 'livelink-sociallinks',
  //   element: AdminPages.SocialLinksPage,
  // },
  {
    path: '/:channelName/:boardId/:itemId',
    title: 'ohmoss',
    isPublic: true,
    element: Pages.LinkServicePage,
    // webViewElement: WebviewPages.default,
    // mobileElement: MobilePages.default,
  },
  {
    path: '/:channelName/:boardId',
    title: 'ohmoss',
    isPublic: true,
    element: Pages.LinkServicePage,
    // webViewElement: WebviewPages.default,
    // mobileElement: MobilePages.default,
  },
  {
    path: '/:channelName',
    title: 'ohmoss',
    isPublic: true,
    element: Pages.LinkServicePage,
    // webViewElement: WebviewPages.default,
    // mobileElement: MobilePages.default,
  },
  // {
  //   path: '/:userName/menus/:id',
  //   title: '메뉴모아보기',
  //   authenticated: false,
  //   element: Pages.UserMenuPage,
  // },
  {
    path: '/company/info',
    title: '회사소개',
    isPublic: true,
    element: Pages.CompanyInfoPage,
  },
  {
    path: '/:userName/pages/:linkId',
    title: '페이지',
    isPublic: true,
    element: Pages.PageLinkPage,
  },
  // {
  //   path: '/ads/:adId',
  //   title: 'ohmoss',
  //   isPublic: true,
  //   element: Pages.AdDetailPage,
  //   // element: Pages.AdDetailPage,
  // },
  // {
  //   path: '/:userName/:follow',
  //   title: 'Follow',
  //   element: Pages.UserHomePage,
  // },
  // {
  //   path: '/report',
  //   title: '신고하기',
  //   element: Pages.ReportPage,
  // },
  {
    path: '/login',
    title: '로그인',
    isPublic: true,
    // authenticated: false,
    element: Pages.LoginPage,
  },
  {
    path: '/:channelName/crm',
    title: '로그인',
    isPublic: true,
    element: Pages.CRMHomePage,
  },
  {
    path: '/:channelName/push',
    title: '푸시보내기',
    element: Pages.PushHomePage,
  },
  {
    path: '/:channelName/tag',
    title: '태그관리',
    element: Pages.TagHome,
  },
  {
    path: '/:channelName/follower',
    title: '팔로워목록',
    element: Pages.FollowerList,
  },
  {
    path: '/signup/kakao',
    title: '카카오 회원가입',
    isPublic: true,
    element: Pages.KakaoSignupPage,
  },
  {
    path: '/signup/google',
    title: '구글 회원가입',
    isPublic: true,
    element: Pages.GoogleSignupPage,
  },
  {
    path: '/login/kakao',
    title: '카카오 로그인',
    isPublic: true,
    element: Pages.KakaoLoginPage,
  },
  // {
  //   path: '/login/google',
  //   title: '구글 로그인',
  //   isPublic: true,
  //   element: Pages.GoogleLoginPage,
  // },
  {
    path: '/create-channel',
    title: '채널 생성',
    element: AdminPages.CreateChannel,
  },
  {
    path: '/create-channel/:type',
    title: '채널 생성',
    element: AdminPages.CreateChannel,
  },
  // {
  //   path: "/faq",
  //   title: "FAQ",
  //   element: Pages.FaqPage,
  // },

  {
    path: '/my',
    title: '마이페이지',
    element: Pages.MyPage,
  },
  {
    path: '/edit-profile',
    title: '프로필 소개 수정',
    element: Pages.EditProfilePage,
  },
  {
    path: '/sign-up-email/',
    title: '회원가입 페이지',
    isPublic: true,
    element: Pages.SignUpPage,
  },
  {
    path: '/sign-up',
    title: '회원가입 페이지',
    isPublic: true,
    element: Pages.SignUpSelectorPage,
  },
  {
    path: '/signup-form/:key',
    title: '회원가입 페이지',
    isPublic: true,
    element: Pages.SignUpPage,
  },
  {
    path: '/use-terms',
    title: '이용약관',
    isPublic: true,
    element: Pages.UseTermsPage,
  },
  {
    path: '/service-terms',
    title: '서비스 이용방침',
    isPublic: true,
    element: Pages.ServiceTermsPage,
  },
  {
    path: '/private-terms',
    title: '개인정보 처리방침',
    isPublic: true,
    element: Pages.PrivateTermsPage,
  },
  {
    path: '/location-terms',
    title: '위치기반서비스 이용약관',
    isPublic: true,
    element: Pages.LocationTermsPage,
  },
  {
    path: '/marketing-terms',
    title: '마케팅 활용 및 수신동의',
    isPublic: true,
    element: Pages.MarketingTermsPage,
  },
  {
    path: '/email-auth',
    title: '인증메일 발송 정보',
    isPublic: true,
    element: Pages.EmailAuthPage,
  },
  {
    path: '/verify',
    title: '인증메일 발송',
    isPublic: true,
    element: Pages.VerifyPage,
  },
  {
    path: '/email',
    title: '인증메일 화면',
    isPublic: true,
    element: Pages.EmailPage,
  },
  {
    path: '/find-id',
    title: '아이디/비밀번호 찾기',
    isPublic: true,
    element: Pages.FindIdPage,
  },
  {
    path: '/reset-password',
    title: '패스워드 변경, isPublic:true',
    element: Pages.ResetPassword,
  },
  {
    path: '/forgot-password',
    title: '패스워드 변경',
    isPublic: true,
    element: Pages.ForgotPassword,
  },
  {
    path: '/qr-code',
    title: 'QR코드',
    isPublic: true,
    element: Pages.QRCodePage,
  },
  {
    path: '/en-useTerms',
    title: '미국 이용약관',
    element: Pages.EnUseTermsPage,
  },
  {
    path: '/cn-useTerms',
    title: '중국 이용약관',
    element: Pages.CnUseTermsPage,
  },
  {
    path: '/jp-useTerms',
    title: '일본 이용약관',
    element: Pages.JpUseTermsPage,
  },
  // {
  //   path: '/payments',
  //   title: '결제화면',
  //   element: Pages.PaymentPage,
  // },
  // {
  //   path: '/payments/success',
  //   title: '결제성공',
  //   element: Pages.PaymentSuccessPage,
  // },
  // {
  //   path: '/payments/fail',
  //   title: '결제실패',
  //   element: Pages.PaymentFailPage,
  // },
  {
    path: '/my/orderinfo',
    title: '주문정보',
    element: Pages.OrderInfoPage,
  },
  {
    path: '/notfound',
    title: '채널 없음',
    isPublic: true,

    element: Pages.NotFoundPage,
  },
  {
    path: '/settings',
    title: '채널 없음',
    isPublic: true,
    element: Pages.MainSettingPage,
  },
  {
    path: '/settings/myad',
    title: '내 광고관리',
    element: Pages.SettingsMyAdPage,
  },
  {
    path: '/settings/myad/detail/:channelid/:boardid/:postid',
    title: '내 광고관리',
    element: Pages.PostDetailPage,
  },
  {
    path: '/mypost/detail/:channelname/:boardid/:postid',
    title: '포스트 상세',
    isPublic: true,
    element: Pages.PostDetailPage,
  },
  {
    path: '/settings/myad/detail/:adid',
    title: '내 광고관리',
    element: Pages.AdDetailPage,
  },
  {
    path: '/settings/myad/newdeal',
    title: '채널 없음',
    element: Pages.NewDealPage,
  },
  {
    path: '/settings/myad/newdeal/:postid',
    title: '채널 없음',
    element: Pages.NewDealPage,
  },
  {
    path: '/settings/myad/deals/:adid',
    title: '딜정보',
    element: Pages.DealInfo,
  },
  {
    path: '/myad/new',
    title: '새 게시물',
    element: Pages.NewADPostPage,
  },
  {
    path: '/post/:boardid/new',
    title: '새 게시물',
    element: Pages.NewPostPage,
  },
  {
    path: '/link/:boardid/new',
    title: '새 게시물',
    element: NewLinkPage,
  },
  {
    path: '/settings/myad/:postid/modify',
    title: '게시물 편집',
    element: Pages.NewPostPage,
  },
  {
    path: '/agreement',
    title: '광고 이동 동의',
    isPublic: true,
    element: Pages.Agreement,
  },
  {
    path: '/settings/dashboard',
    title: '대시보드',
    element: Pages.DashBoard,
  },
  {
    path: '/settings/mosscoin',
    title: '모스코인',
    element: Pages.MossCoin,
  },
  {
    path: '/settings/userinfo',
    title: '회원정보',
    element: Pages.UserModifyPage,
  },
  {
    path: '/settings/notification',
    title: '알림설정',
    element: Pages.NotificationPage,
  },
  {
    path: '/settings/userterms',
    title: '이용약관',
    isPublic: true,
    element: Pages.Terms,
  },
  {
    path: '/settings/privacypolicy',
    title: '개인정보 수집 및 동의',
    isPublic: true,
    element: Pages.Privacy,
  },
  // {
  //   path: '/:userName/items/:id',
  //   title: 'items',
  //   element: Pages.ItemsPage,
  // },
  // {
  //   path: '/:userName/book',
  //   title: 'booking',
  //   element: Pages.BookingPage,
  // },
  // {
  //   path: '/:userName/reservations',
  //   title: '예약 목록 조회',
  //   element: Pages.ReservationCheckPage,
  // },
  // {
  //   path: '/reservations/:bookingNo',
  //   title: '예약 목록 조회 - 상세',
  //   element: Pages.ReservationDetailPage,
  // },
  // {
  //   path: '/reservation/search',
  //   title: '예약 목록 조회 - 로그인',
  //   element: Pages.ReservationSearchPage,
  // },
  {
    path: '/editor',
    title: '예약 목록 조회 - 로그인',
    element: AdminPages.HTMLEditorPage,
  },
  {
    path: '/ai',
    title: '',
    element: Pages.AssistantPage,
  },
  {
    path: '/intro',
    title: '오모스 소개 페이지',
    element: Pages.OhmossIntroducePage,
    isPublic: true,
  },
  {
    path: '/',
    title: '오모스 소개 페이지',
    element: Pages.DealsPage,
    // element: Pages.OhmossIntroducePage,
    isPublic: true,
  },
  {
    path: '/ads/:adId',
    title: 'ohmoss',
    isPublic: true,
    element: Pages.DealsPage,
    // element: Pages.AdDetailPage,
  },
  {
    path: '/deals',
    title: '오모스 소개 페이지',
    element: Pages.DealsPage,
    // element: Pages.OhmossIntroducePage,
    isPublic: true,
  },
  {
    path: '/landing/:uselanding',
    title: '오모스 소개 페이지',
    element: Pages.OhmossIntroducePage,
    isPublic: true,
  },
  {
    path: '/token',
    title: '',
    element: TokenTest,
  },
  {
    path: '/:channelName/suggestion',
    title: '제안/문의 페이지',
    element: Pages.SuggestionPage,
    isPublic: true,
  },
  {
    path: '/invitations/:invitationKey',
    title: 'invitations',
    isPublic: true,
    element: AcceptInvitationPage,
  },
];

export default mainRoutes;
