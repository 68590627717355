import React, { useState, ReactNode, useEffect, useRef } from 'react';
import { ChannelBoardDto, ChannelDto, LinkDto, PostDto, UserDto } from 'services/data-contracts';
import { Context } from 'features/linkService/context/useChannelContext';

import ChannelStore from 'stores/ChannelStore';
import useLocalParams from 'libs/hooks/useLocalParams';
import { IChannelBoardModel } from 'types/BlockExtendsTypes';
import { ReceiverType } from 'types/CommonTypes';
import { useRootContext } from 'libs/hooks/useRootContext';

interface PageProviderProps {
  children: ReactNode | ReactNode[];
}

/**
 * @description Provider에서 Store경유하는 로직 적용 금지.
 * @param param0
 * @returns
 */
const ChannelProvider = ({ children }: PageProviderProps) => {
  const channelStore = new ChannelStore();
  const { channelName } = useLocalParams();
  const { myChannel } = useRootContext();

  const [categoryBoards, setCategoryBoardsInfo] = useState<IChannelBoardModel[]>([]);
  const [categoryBoard, setCategoryBoardInfo] = useState<IChannelBoardModel | null>(null);
  // const [myChannel, setMyChannelInfo] = useState<ChannelDto | null>();
  const [currentChannel, setCurrentChannel] = useState<ChannelDto>();
  const [links, setLinks] = useState<LinkDto[]>();
  const [posts, setPosts] = useState<PostDto[]>();
  const [receiver, setReceiver] = useState<ReceiverType>();

  /**
   * @description Router외부에서 호출하는 경우, 직접 셋팅
   * @param channelName
   */
  const getCurrentChannel = async (directChannelName?: string) => {
    let targetChannel;
    const priority = directChannelName || channelName;
    if (priority === null) {
      return;
    }
    if (myChannel) {
      targetChannel = await channelStore.getChannel(priority as string, myChannel.id);
    } else {
      targetChannel = await channelStore.getChannel(priority as string);
    }
    setCurrentChannel(targetChannel as ChannelDto);
  };

  const setCategoryBoard = (board: ChannelBoardDto) => {
    setCategoryBoardInfo(board);
  };

  const setCategoryBoards = (boards: ChannelBoardDto[]) => {
    setCategoryBoardsInfo(boards);
  };

  const broadcast = (params: ReceiverType) => {
    if (params) setReceiver(params);
  };

  // useEffect(() => {
  //   if (typeof myChannel !== 'undefined') {
  //     getCurrentChannel();
  //   }
  // }, [myChannel]);

  return (
    <>
      {typeof children !== 'undefined' ? (
        <Context.Provider
          value={{
            currentChannel,
            getCurrentChannel,
            links,
            receiver,
            // posts,
            // getMyChannel,
            // myChannel,
            categoryBoards,
            categoryBoard,
            setLinks,
            setPosts,
            // setMyChannel,
            setCategoryBoards,
            setCategoryBoard,
            broadcast,
          }}
        >
          {children}
        </Context.Provider>
      ) : (
        <></>
      )}
    </>
  );
};

export default ChannelProvider;
