import React from 'react';
import styled from 'styled-components';

export interface RoundedImgProps {
  image: string;
  subtitle: string;
  title: string;
}

const RoundedImg = ({ image, subtitle, title }: RoundedImgProps) => {
  return (
    <Wrapper>
      <Content>
        <Subtitle>{subtitle}</Subtitle>
      </Content>
      <Title>{title}</Title>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* position: absolute; */
  z-index: 10;
  /* top: -130px; */
  @media ${(props) => props.theme.media.mobile} {
  }
`;

const Content = styled.div`
  padding: 3px 10px;
  border-radius: 15px;
  background-color: #96e06e;
  width: 140px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${(props) => props.theme.media.mobile} and (max-height: 690px) {
  }
  @media ${(props) => props.theme.media.mobile} and (min-height: 700px) {
  }
  @media ${(props) => props.theme.media.desk} {
    /* margin-top: -24em; */
  }
`;

const Subtitle = styled.div`
  color: #173300;
  font-size: 16px;
  font-weight: 600;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 14px;
  }
`;

const Title = styled.div`
  padding-top: 12px;
  color: #fff;
  /* font-family: Apple SD Gothic Neo; */
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  margin-bottom: 30px;
  @media ${(props) => props.theme.media.desk} {
    font-size: 1.75rem;
  }
`;

export default RoundedImg;
