import React, { useEffect, useState } from 'react';
import { GroupChannel } from '@sendbird/chat/groupChannel';

import styled from 'styled-components';
import chatController from 'features/community/controllers/chatController';
import useSandbirdChat from 'hooks/useSandbirdChat';
import { useChat } from 'libs/hooks/useChat';
import MyInquryChatContainer from './MyInquryChatContainer';
import { SimpleDealDto } from 'services/data-contracts';
import LinkController from 'controllers/LinkController';
import CircularLoading from 'components/commons/CircularLoading';

/**
 * @description 일반 고객용
 * @param param0
 * @returns
 */
const MyInquriesChat = ({ channelUrl }: { channelUrl?: string }) => {
  const [channel, setChannel] = useState<GroupChannel>();
  const { getChannelMessages } = useSandbirdChat();
  const { getLinkByKey } = LinkController();
  const { initCompleted } = useChat();
  const { getChannel } = chatController();

  const [dealInfo, setDealInfo] = useState<SimpleDealDto>();

  const getTargetChannel = async (channelUrl: string) => {
    if (initCompleted) {
      const temp = (await getChannel(channelUrl as string)) as unknown as GroupChannel;
      const linkKey = temp.url.split('-')[2];
      if (linkKey) {
        const deal = (await getLinkByKey(linkKey)) as unknown as SimpleDealDto;
        if (deal) {
          setDealInfo(deal);
        }
      }
      setChannel(temp);
      getChannelMessages(temp);
    }
  };

  useEffect(() => {
    const url = window.location.pathname.split('/');
    if (channelUrl) {
      getTargetChannel(channelUrl);
    } else if (url[2]) {
      getTargetChannel(url[2]);
    }
  }, [window.location, initCompleted]);

  return (
    <>
      {channel && <MyInquryChatContainer channel={channel} dealInfo={dealInfo} />}
      <CircularLoading interval={1000} />
    </>
  );
};

export default MyInquriesChat;
