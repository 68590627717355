import React, { useState, CSSProperties, useEffect } from 'react';
import styled from 'styled-components';
import { ChannelProfileModel } from 'types/CommonTypes';

const Footer = ({ model }: { model?: ChannelProfileModel; }) => {
  const [style, setStyle] = useState<CSSProperties>();
  const [position, setPosition] = useState<'top' | 'bottom' | 'left' | 'right'>();

  useEffect(() => {
    if (model) {
      setStyle(model.metadataObject?.footerProps?.titleProps?.style);
      setPosition(model.metadataObject?.footerProps?.position);
    }
  }, [model]);

  const getFooterByType = () => {
    switch (position) {
      case 'top':
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {typeof model?.footerImage?.publicUrl !== 'undefined' && (
              <ImageStyle>
                <img src={model?.footerImage?.publicUrl} width={'100%'} height={'100%'} alt="" />
              </ImageStyle>
            )}
            <LinkTitleStyle style={{ ...style, marginLeft: 0, marginTop: 8 }}>
              <>{model?.metadataObject?.footerProps?.titleProps?.text}</>
            </LinkTitleStyle>
          </div>
        );
      case 'bottom':
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <LinkTitleStyle style={{ ...style, marginLeft: 0, marginBottom: 8 }}>
              <>{model?.metadataObject?.footerProps?.titleProps?.text}</>
            </LinkTitleStyle>
            {typeof model?.footerImage?.publicUrl !== 'undefined' && (
              <ImageStyle>
                <img src={model?.footerImage?.publicUrl} width={'100%'} height={'100%'} alt="" />
              </ImageStyle>
            )}
          </div>
        );
      case 'left':
        return (
          <FooterContainerStyle>
            {typeof model?.footerImage?.publicUrl !== 'undefined' && (
              <ImageStyle>
                <img src={model?.footerImage?.publicUrl} width={'100%'} height={'100%'} alt="" />
              </ImageStyle>
            )}
            <LinkTitleStyle style={{ ...style, marginLeft: 8 }}>
              <>{model?.metadataObject?.footerProps?.titleProps?.text}</>
            </LinkTitleStyle>
          </FooterContainerStyle>
        );
      case 'right':
        return (
          <FooterContainerStyle>
            <LinkTitleStyle style={{ ...style, marginRight: 8 }}>
              <>{model?.metadataObject?.footerProps?.titleProps?.text}</>
            </LinkTitleStyle>
            {typeof model?.footerImage?.publicUrl !== 'undefined' && (
              <ImageStyle>
                <img src={model?.footerImage?.publicUrl} width={'100%'} height={'100%'} alt="" />
              </ImageStyle>
            )}
          </FooterContainerStyle>
        );
      default:
        return (
          <FooterContainerStyle>
            {typeof model?.footerImage?.publicUrl !== 'undefined' && (
              <ImageStyle>
                <img src={model?.footerImage?.publicUrl} width={'100%'} height={'100%'} alt="" />
              </ImageStyle>
            )}
            <LinkTitleStyle style={{ ...style, marginLeft: 8 }}>
              <>{model?.metadataObject?.footerProps?.titleProps?.text}</>
            </LinkTitleStyle>
          </FooterContainerStyle>
        );
    }
  };

  return (
    <FooterOuterContainer>
      <FooterContainer>{getFooterByType()}</FooterContainer>
    </FooterOuterContainer>
  );
};

const FooterOuterContainer = styled.div`
`;
const FooterContainer = styled.div`
  max-width: 550px;
  margin: auto;
  padding: 40px 0;
  position: relative;

  @media ${(props) => props.theme.media.mobile} {
    padding: 0px;
    margin: 0px;
  }
`;
const ImageStyle = styled.div`
  width: 70px;
  height: 70px;
  > img {
    width: 70px;
    height: 70px;
    border-radius: 8px;
  }
`;

const FooterContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinkTitleStyle = styled.div`
  margin-left: 10px;
  white-space: break-spaces;
`;
export default Footer;
