import React, { CSSProperties } from 'react';
import { Row } from 'components/commons/layouts';
import { OhmossButton } from 'resources/controls/OhmossButton';

const ActionBox = ({
  style,
  okText,
  cancelText,
  isValid,
  onOk,
  onCancel,
}: {
  style?: CSSProperties;
  okText?: string;
  cancelText?: string;
  isValid?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}) => {
  return (
    <Row style={{ gap: 20, justifyContent: 'flex-start', ...style }}>
      <OhmossButton
        disabled={!isValid}
        type="primaryPc"
        style={{ minWidth: 200, width: 'unset', borderRadius: 8 }}
        onClick={() => {
          onOk?.();
        }}
      >
        {okText || '확인'}
      </OhmossButton>
      <>
        {onCancel && (
          <OhmossButton
            style={{ color: '#000000', width: 100 }}
            type="square"
            onClick={() => {
              onCancel?.();
            }}
          >
            {cancelText || '취소'}
          </OhmossButton>
        )}
      </>
    </Row>
  );
};

export default ActionBox;
