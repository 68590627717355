import React, { useEffect, useState } from 'react';
import { ProposalDto } from 'services/data-contracts';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { Row } from 'components/commons/layouts';
import NoContent from 'components/commons/NoContent';
import ProposalController from 'controllers/ProposalController';
import { useStore } from 'stores/StoreHelper';
import CommonTabButtons from 'components/commons/buttons/CommonTabButtons';
import SentProposalContainer from '../components/SentProposalContainer';
import ProposalBox from '../components/ProposalBox';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useMediaContext } from 'providers/useMediaContext';
import CommonRoundTabButtons from 'components/commons/buttons/CommonRoundTabButtons';
import { AnimatedTabButton, TabItem } from 'components/commons/buttons/AnimatedTabButton';

const MyProposalList = () => {
  const { myChannel } = useRootContext();
  const { uiStore } = useStore();
  const [receivedProposals, setReceivedProposals] = useState<ProposalDto[]>();
  const [sentProposals, setSentProposals] = useState<ProposalDto[]>();
  const [tabLabel, setTabValue] = useState<number>(1);
  const { getSentProposals, deleteProposalByProposer, getReceivedProposals } = ProposalController();
  const ScreenTypes = useMediaContext();

  const getProposals = async () => {
    const receivedProposalsTemp = (await getReceivedProposals()) as unknown as ProposalDto[];
    const sentProposalsTemp = (await getSentProposals()) as unknown as ProposalDto[];
    const newSentProposalArray = sentProposalsTemp.filter(
      (proposal) => proposal.requestType === 'CHANNEL',
    );
    const newReceivedProposalArray = receivedProposalsTemp.filter(
      (proposal) => proposal.requestType === 'CHANNEL',
    );
    setSentProposals(newSentProposalArray);
    setReceivedProposals(newReceivedProposalArray);
  };

  const handleClickProposal = (proposal: ProposalDto) => {
    uiStore.modal.show({
      style: {
        maxHeight: '85%',
      },
      children: <SentProposalContainer proposal={proposal} />,
    });
  };

  const deleteProposal = async (proposal: ProposalDto) => {
    if (proposal?.id !== undefined) {
      uiStore.confirm.show({
        message: '정말 삭제하시겠습니까?',
        onConfirmed: async () => {
          await deleteProposalByProposer(proposal);
          getProposals();
        },
      });
    }
  };

  useEffect(() => {
    if (myChannel) getProposals();
  }, [myChannel]);

  return (
    <>
      <TabContainer>
        <AnimatedTabButton
          onClick={(item: any) => {
            setTabValue(Number(item.key));
          }}
          style={{ width: '100%' }}
        >
          <TabItem key={1}>받은제안</TabItem>
          <TabItem key={2}>보낸제안</TabItem>
        </AnimatedTabButton>
      </TabContainer>
      {receivedProposals?.length === 0 && <NoContent title="등록된 제안이" />}
      {tabLabel === 1 && (
        <div>
          {receivedProposals?.map((proposal) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InquiryStyle
                onClick={() => {
                  handleClickProposal(proposal);
                }}
              >
                <Row style={{ justifyContent: 'space-between' }}>
                  <ProposalBox inquiry={proposal} inquiryType="RECEIVED" />
                </Row>
              </InquiryStyle>
              <div style={{ marginLeft: 10, alignSelf: 'center' }}>
                <CloseIcon onClick={() => deleteProposal(proposal)} />
              </div>
            </div>
          ))}
          {receivedProposals?.length === 0 && (
            <NoContent style={{ marginTop: 200 }} title="보낸 제안이" />
          )}
        </div>
      )}

      {tabLabel === 2 && (
        <div>
          {sentProposals?.map((proposal) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InquiryStyle
                onClick={() => {
                  handleClickProposal(proposal);
                }}
              >
                <Row style={{ justifyContent: 'space-between' }}>
                  <ProposalBox inquiry={proposal} inquiryType="SENT" />
                </Row>
              </InquiryStyle>
              <div style={{ marginLeft: 10, alignSelf: 'center' }}>
                <CloseIcon onClick={() => deleteProposal(proposal)} />
              </div>
            </div>
          ))}
          {sentProposals?.length === 0 && (
            <NoContent style={{ marginTop: 200 }} title="보낸 제안이" />
          )}
        </div>
      )}
    </>
  );
};

const InquiryStyle = styled.div`
  cursor: pointer;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;

  margin: 10px;
`;

const TabContainer = styled.div`
  @media ${(props) => props.theme.media.desk} {
    width: 400px;
  }
  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
    padding: 10px;
    background-color: #f0f0f0;
  }
`;
export default MyProposalList;
