import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import { ChannelProposalConfigDto } from 'services/data-contracts';

const theme = createTheme({
  palette: {
    primary: {
      main: '#96E06E',
    },
  },
});

const ThemedRadio = withStyles({
  root: {
    color: '#efefef',
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function RadioButtonsGroup({
  label,
  direction,
  onRadioBtnChange,
  selectedValue,
}: {
  label?: ChannelProposalConfigDto[] | string[];
  direction?: 'row' | 'column';
  onRadioBtnChange?: (item: any) => void;
  selectedValue?: string;
}) {
  const handleChange = (item: any) => {
    onRadioBtnChange?.(item);
  };
  return (
    <ThemeProvider theme={theme}>
      <FormControl>
        <RadioGroup
          row={direction === 'row'}
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
        >
          {label?.map((item, index) => {
            return selectedValue ? (
              <FormControlLabel
                checked={selectedValue === item}
                value={index}
                control={<ThemedRadio />}
                label={item}
                onChange={() => handleChange(item)}
              />
            ) : (
              <FormControlLabel
                value={index}
                control={<ThemedRadio />}
                label={item}
                onChange={() => handleChange(item)}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </ThemeProvider>
  );
}
