import React, { CSSProperties, useEffect } from 'react';
import useSandbirdChat from 'hooks/useSandbirdChat';
import TalkChannelListBox from 'components/commons/chat/TalkChannelListBox';
import { useStore } from 'stores/StoreHelper';
import SBConversation from '@sendbird/uikit-react/GroupChannel';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { Row } from 'components/commons/layouts';
import CircularLoading from 'components/commons/CircularLoading';
import { useChat } from 'libs/hooks/useChat';
import { ChannelQueryType } from 'types/CommonTypes';
import { useRootContext } from 'libs/hooks/useRootContext';
import { isMobile } from 'react-device-detect';

const NewInquiriesBox = ({ style }: { style?: CSSProperties }) => {
  const { uiStore } = useStore();
  const { channels, getInquiryChannels, markAsRead } = useSandbirdChat();
  const { initCompleted } = useChat();
  const { myChannel } = useRootContext();

  const onJoinChannel = (channel: GroupChannel) => {
    markAsRead(channel?.url as string);
    uiStore.modal.show({
      style: { height: isMobile ? '100%' : '80vh', borderRadius: isMobile ? '0px' : '10px' },
      children: <SBConversation channelUrl={channel?.url || ''} />,
    });
  };

  const getChannels = async () => {
    if (myChannel?.channelType === 'SELLER') {
      const query: ChannelQueryType = {
        channelType: 'OWNER',
        metaKey: 'dealOwner',
        metaValues: [myChannel!.id!.toString()],
      };
      getInquiryChannels(query);
    } else {
      const query: ChannelQueryType = {
        channelType: 'DELEGATED',
      };
      getInquiryChannels(query);
    }
  };

  useEffect(() => {
    if (myChannel && initCompleted) {
      getChannels();
    }
  }, [myChannel, initCompleted]);

  return (
    <div style={{ margin: 20, position: 'relative', ...style }}>
      <CircularLoading />
      {channels.length > 0 ? (
        <TalkChannelListBox inquiries={channels} onJoinChannel={onJoinChannel} maxCount={5} />
      ) : (
        <Row>
          <div>새로운 문의가 없습니다.</div>
        </Row>
      )}
    </div>
  );
};

export default NewInquiriesBox;
