import { LinkDto } from 'services/data-contracts';

export const linkDtoDummy: LinkDto = {
  id: undefined,
  channel: undefined,
  linkType: 'Page',
  boardId: undefined,
  title: '',
  content: undefined,
  linkUrl: undefined,
  thumbnail: undefined,
  thumbnailUrl: undefined,
  seq: undefined,
  linkKey: undefined,
  video: undefined,
  startDate: undefined,
  endDate: undefined,
  phoneNumber: undefined,
  address: undefined,
  latitude: undefined,
  longitude: undefined,
  layout: undefined,
  contentAlign: undefined,
  useTransparent: undefined,
  openNewWindow: undefined,
  status: undefined,
  metadata: undefined,
  shopBoardId: undefined,
  isActive: undefined,
  items: undefined,
  stickers: undefined,
  inquiryCount: undefined,
  clickCount: undefined,
  outboundClickCount: undefined,
  encoreCount: undefined,
  createDate: undefined,
  modifyDate: undefined,
};
