import React from 'react';
import styled from 'styled-components';

function CSTownInquiriesCountContainer({
  style,
  title,
  count,
}: {
  style?: React.CSSProperties;
  title?: string;
  count?: number;
}) {
  return (
    <Wrapper style={{ ...style }}>
      <Title>{title}</Title>
      <Count>{count}건</Count>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  border: 1px solid rgba(23, 51, 0, 0.3);
  border-radius: 1.75rem;
  background-color: #fff;
  gap: 0.875rem;
  justify-content: space-between;
  padding: 0.6rem 1rem;
  align-items: center;
`;

const Title = styled.div`
  font-size: 0.825rem;
  font-weight: 700;
  color: rgba(4, 4, 4, 0.87);
`;

const Count = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.825rem;
  font-weight: 400;
`;

export default CSTownInquiriesCountContainer;
