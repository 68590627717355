import React, { useState } from 'react';
import styled from 'styled-components';
import TitleForm from 'components/items/forms/TitleForm';
import NumberForm from 'components/items/forms/NumberForm';
import { FormControlLabel, FormGroup, TextField } from '@material-ui/core';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled as styled_ } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DropdownSelection from './dashboard/DropdownSelection';

const DealRecords = ({
  title,
  subTitle, // data,
}: {
  title?: string;
  subTitle?: string;
  // data?: LocationCountSeriesDto[];
}) => {
  const [otherOptions, setOtherOptions] = useState<any>([
    '제품소비자가격',
    '제품공구가격',
    '마진율',
    '배송비',
    '기타',
  ]);
  const [snsOptions, setSnsOptions] = useState<string[]>([
    'Facebook',
    'Instagram',
    'Naver',
    'YouTube',
  ]);

  const [savedOptions, setSavedOptions] = useState<any[]>([{ sns: '', before: '', after: '' }]);
  const [editMode, setEditMode] = useState<boolean>(false);

  const IOSSwitch = styled_((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const handleAddButtonClicked = () => {
    const newSavedOptions = [...savedOptions];
    newSavedOptions.push({ sns: '', before: '', after: '' });
    setSavedOptions(newSavedOptions);
  };

  return (
    <Wrapper>
      {/* 딜 기록 */}
      <Title>
        공구 기록
        <img src="/images/adjust_icon.svg" alt="icon" style={{ cursor: 'pointer' }} />
      </Title>
      <CategoryContainer>
        <CategoryText>
          <img
            src="/images/grey_dot.svg"
            alt="icon"
            style={{ cursor: 'pointer', marginRight: 10, width: 10 }}
          />
          총 매출
        </CategoryText>
        <NumberForm validationType="number" style={{ width: 130 }} onChangeValue={() => {}} />
        <div style={{ position: 'absolute', right: 44 }}>{'원'}</div>
      </CategoryContainer>
      <CategoryContainer>
        <CategoryText>
          <img
            src="/images/red_dot.svg"
            alt="icon"
            style={{ cursor: 'pointer', marginRight: 10, width: 10 }}
          />
          부대비용
        </CategoryText>
        <NumberForm validationType="number" style={{ width: 130 }} onChangeValue={() => {}} />
        <div style={{ position: 'absolute', right: 44 }}>{'원'}</div>
      </CategoryContainer>
      <CategoryContainer style={{ padding: '10px 10px' }}>
        <CategoryText style={{ marginLeft: 20 }}>수익</CategoryText>
        <FormGroup style={{ flexDirection: 'row', alignItems: 'center' }}>
          <CalculationText>자동계산</CalculationText>
          <FormControlLabel control={<IOSSwitch sx={{ ml: 3 }} defaultChecked />} label="" />
        </FormGroup>
      </CategoryContainer>
      <SubTitle>참고 항목</SubTitle>
      {otherOptions.map((item: any, index: number) => (
        <CategoryContainer key={index}>
          <CategoryText>{item}</CategoryText>
          <NumberForm validationType="number" style={{ width: 130 }} onChangeValue={() => {}} />
          <div style={{ position: 'absolute', right: 44 }}>{item !== '마진율' ? '원' : '%'}</div>
        </CategoryContainer>
      ))}
      <SubTitle>SNS 메모</SubTitle>
      <CategoryContainer style={{ padding: '10px 30px' }}>
        <AddCircleOutlineIcon
          onClick={() => {
            handleAddButtonClicked();
          }}
          style={{ position: 'absolute', left: 20, color: '#666' }}
        />
        <DropdownSelection items={snsOptions} savedOptions={savedOptions} />
        {/* <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <FormGroup style={{ flexDirection: 'row', alignItems: 'center' }}>
            <CalculationText style={{ marginRight: 10 }}>이전</CalculationText>
            <NumberForm validationType="number" style={{ width: 100 }} onChangeValue={() => {}} />
          </FormGroup>
          <FormGroup style={{ flexDirection: 'row', alignItems: 'center' }}>
            <CalculationText style={{ marginRight: 10 }}>이후</CalculationText>
            <NumberForm validationType="number" style={{ width: 100 }} onChangeValue={() => {}} />
          </FormGroup>
        </div> */}
      </CategoryContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: 'flex';
  justify-content: 'center';
  padding: 10px 20px;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  padding: 0 20px;
  color: #666;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 13px;
  }
`;

const SubTitle = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  padding: 20px 0px 10px 34px;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 13px;
  }
`;

const PercentageText = styled.div`
  font-size: 28px;
  padding: 0 20px;
  font-weight: 600;
`;

const CategoryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px 50px;
`;

const SubCategoryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px 50px;
  @media ${(props) => props.theme.media.mobile} {
    padding: 10px 20px;
  }
`;

const CategoryText = styled.div`
  display: flex;
  min-width: 100px;
  flex-direction: row;
  font-size: 16px;
  font-weight: 500;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 13px;
  }
`;

const CalculationText = styled.div`
  color: '#666';
  font-size: 15px;
  align-self: center;
  /* margin-left: 130px; */
  @media ${(props) => props.theme.media.mobile} {
    font-size: 13px;
    /* margin-left: 30px; */
    align-self: center;
  }
`;

const NumberText = styled.div`
  font-size: 16px;
  font-weight: 300;
`;

export default DealRecords;
