import React, { useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField, Switch, FormGroup, FormControlLabel } from '@mui/material';

const Step4 = () => {
  const [value, setValue] = useState<string | null>('2014-08-18T21:11:54');
  const [isReserved, setIsReserved] = useState<boolean>(false);

  const handleChange = () => {
    // setValue(newValue);
  };

  const handleReservedChange = (e: any, checked: boolean) => {
    setIsReserved(checked);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <FormGroup>
        <FormControlLabel control={<Switch onChange={handleReservedChange} />} label="예약전송" />
      </FormGroup>

      {isReserved && (
        <div style={{ marginTop: 20 }}>
          <TextField
            id="datetime-local"
            label="예약날짜"
            type="datetime-local"
            defaultValue="2017-05-24T10:30"
            sx={{ width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Step4;
