import { User } from '@sendbird/chat';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { BaseMessage } from '@sendbird/chat/message';
import { createContext, useContext, Context as ReactContext } from 'react';

interface PropsTypes<T> {
  sendbirdChatRef: any;
  currentUserRef: any;
  messagesRef: any;
  // sendbirdChat: any;
  // currentUser: User;
  channels: GroupChannel[];
  messages: BaseMessage[];
  setChannels: (channels: GroupChannel[]) => void;
  setMessages: (messages: BaseMessage[]) => void;
  initChat: (channelName: string) => void;
  initCompleted: boolean;
  unReadMessageCounts: number;
  setUnreadCount: (count: number) => void;
  getUnreadCount: () => void;
}

const Context = createContext<(PropsTypes<any> & any) | undefined>(undefined);

function useChat<T>(): PropsTypes<T> & T {
  const context = useContext(Context as ReactContext<(PropsTypes<T> & T) | undefined>);
  if (!context) {
    return {} as PropsTypes<T> & T;
  }
  return context;
}

export { useChat, Context, PropsTypes };
