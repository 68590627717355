import React from 'react';
import { CustomUserProfile } from 'types/CommonTypes';
import { observer } from 'mobx-react';
import styled from 'styled-components';

interface Props {
  userInfo?: CustomUserProfile;
}
const Fnb = observer(({ userInfo }: Props) => {
  return (
    <FooterTitleStyle style={{ textAlign: 'center', ...userInfo?.footerProps?.style }}>
      {typeof userInfo?.footerImage !== 'undefined' && (
        <FooterLogoImageStyle noTitle={false} src={userInfo?.footerImage?.publicUrl} alt="" />
      )}
      {typeof userInfo?.footerTitle === 'undefined' || userInfo?.footerTitle === '' ? (
        <div style={{ color: 'black', fontWeight: 500, fontSize: 16 }}>oh!moss</div>
      ) : (
        <>{userInfo?.footerTitle}</>
      )}
    </FooterTitleStyle>
  );
});

const FooterTitleStyle = styled.div`
  padding: 0 15px 0 15px;
  white-space: pre-line;
  margin: 20px 0 20px 0;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  > img {
    margin: 0 auto 10px;
  }
`;

const FooterLogoImageStyle = styled.img<{ noTitle: boolean }>`
  width: 80px;
  height: 80px;
  text-align: center;
  border-radius: 50%;
  object-fit: cover;
`;
export default Fnb;
