import React, { useContext } from 'react';


const Context = React.createContext<any | undefined>(undefined);

const useOhmossContext = <T extends {}>(): T => {
  const context = useContext(Context);
  if (!context) {
    console.log('check context');
  }
  return context as T;
};

export { Context, useOhmossContext };
