import React, { useEffect, useRef, useState } from 'react';
import { Row } from 'components/commons/layouts';
import { IconButton, Slider, Checkbox, FormGroup, FormControlLabel } from '@mui/material';

import { OhmossButton } from 'resources/controls/OhmossButton';
import { ChannelBoardDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import MUISwitch from 'components/commons/buttons/MUISwitch';
import styled from 'styled-components';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import useNavigation from 'hooks/useNavigation';
import { observer } from 'mobx-react-lite';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { toast } from 'react-toastify';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useMediaContext } from 'providers/useMediaContext';
import Text from 'components/commons/Text';

const PreviewBox = observer(
  ({
    previewMode,
    categoryId,
    onClickPreview,
  }: {
    previewMode?: boolean;
    categoryId?: number;
    onClickPreview: () => void;
  }) => {
    const { navigate } = useNavigation();
    const { serviceModel, updateServiceModel, initialBinding } =
      useModelManagement<ChannelBoardDto>();
    const { uiLinkStore, myBoardStore, xhrStore } = useStore();
    const { setCategoryBoard, categoryBoards } = useChannelContext();
    const { myChannel } = useRootContext();
    const ScreenTypes = useMediaContext();
    const applyAllRef = useRef<boolean>(false);
    const [showBlockMargin, setShowBlockMargin] = useState<boolean>(false);

    const handleClickApplyBlockMargin = async () => {
      setShowBlockMargin((prev) => !prev);
      if (applyAllRef.current) {
        if (categoryBoards) {
          categoryBoards.forEach(async (item) => {
            await myBoardStore.updateBoard(myChannel?.id as number, {
              ...item,
              blockMargin: uiLinkStore.boxStyle?.marginTop as number,
            });
          });
        }
      } else {
        await myBoardStore.updateBoard(myChannel?.id as number, {
          ...serviceModel,
          blockMargin: uiLinkStore.boxStyle?.marginTop as number,
        });
      }
      toast('적용 되었습니다.');
    };

    const getCategoryById = async () => {
      const category = (await myBoardStore.getBoardById(
        myChannel?.id as number,
        categoryId as number,
      )) as unknown as ChannelBoardDto;
      // setCategoryBoard(category);
      initialBinding(category);
    };

    useEffect(() => {
      if (categoryId && myChannel) {
        getCategoryById();
      }
    }, [categoryId, myChannel]);

    return (
      <PreviewContainerStyle
        isPreview={previewMode}
        style={{
          // position: 'sticky',
          // position: previewMode ? 'fixed' : 'sticky',
          zIndex: 1,
          // top: previewMode ? 25 : 44,
          top: 30,
        }}
      >
        <Row
          style={{
            marginTop: 0,
            gap: 15,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Row
            style={{
              width: ScreenTypes.isLarge ? '100%' : 'unset',
              gap: 15,
              flexDirection: ScreenTypes.isLarge ? 'column' : 'row',
            }}
          >
            <>
              {!ScreenTypes.isLarge && (
                <OhmossButton
                  type="primaryPc"
                  style={{
                    padding: '20px 10px',
                    height: 28,
                    fontSize: 14,
                    minWidth: 100,
                    width: '100%',
                  }}
                  onClick={() => {
                    setShowBlockMargin(false);
                    onClickPreview();
                  }}
                >
                  {previewMode ? '돌아가기' : '미리보기'}
                </OhmossButton>
              )}
            </>
            <>
              {typeof categoryId !== 'undefined' && !ScreenTypes.isLarge && (
                <>
                  {ScreenTypes.isLarge && (
                    <div style={{ textAlign: 'left', width: '100%' }}>
                      <div style={{ display: 'inline-block', marginTop: 10, marginLeft: 5 }}>
                        페이지 선택
                      </div>
                    </div>
                  )}
                  <FormControl fullWidth>
                    <Select
                      sx={{
                        '&.MuiInputBase-root': {
                          height: 40,
                          fontSize: 12,
                          minWidth: 80,
                          background: '#fff',
                        },
                      }}
                      value={categoryId}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      displayEmpty
                      onChange={(e: any, value: any) => {
                        navigate(`/management/categories/${value?.props.value}`);
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {categoryBoards?.map((item) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </>
            <>{!ScreenTypes.isLarge && <HorizontalHrStyle />}</>
            <>
              {categoryId && (
                <Row style={{ gap: 10 }}>
                  <>
                    {previewMode && (
                      <ProfileContainerStyle style={{ width: 'unset' }}>
                        <div
                          style={{
                            width: ScreenTypes.isLarge ? 60 : 40,
                            fontSize: 12,
                            marginRight: ScreenTypes.isSmall ? 0 : 10,
                            color: ScreenTypes.isSmall ? '#000' : '#bdbdbd',
                          }}
                        >
                          {!ScreenTypes.isLarge ? '프로필' : '프로필 표시'}
                        </div>

                        <div>
                          <MUISwitch
                            checked={serviceModel?.useProfile}
                            onChange={(e: any, value: boolean) => {
                              myBoardStore.updateBoard(myChannel?.id as number, {
                                ...serviceModel,
                                useProfile: value,
                              });
                              setCategoryBoard({
                                ...serviceModel,
                                useProfile: value,
                              });
                            }}
                          />
                        </div>
                      </ProfileContainerStyle>
                    )}
                  </>

                  <>
                    {previewMode && (
                      <div
                        style={{
                          fontSize: 12,
                          marginRight: 15,
                          cursor: 'pointer',
                        }}
                      >
                        <IconButton
                          onClick={(e: any) => {
                            if (previewMode) {
                              setShowBlockMargin((prev) => !prev);
                            } else {
                              onClickPreview();
                              setShowBlockMargin((prev) => !prev);
                            }
                          }}
                        >
                          <FormatLineSpacingIcon />
                        </IconButton>
                      </div>
                    )}
                  </>
                </Row>
              )}
            </>
          </Row>
        </Row>
        {showBlockMargin && (
          <>
            <HRStyle />
            <div>
              <Row
                style={{
                  padding: '0 20px',
                  minWidth: 300,
                  height: 60,
                  display: 'felx',
                  alignItems: 'center',
                  alignContent: 'center',
                  position: 'relative',
                  top: 3,
                }}
              >
                <Slider
                  sx={{
                    '& .MuiSlider-track': { background: previewMode ? '#05C755' : '#cdcdcd' },
                    '& .MuiSlider-rail': { background: '#efefef' },
                    '& .MuiSlider-thumb': {
                      background: previewMode ? '#05C755' : '#cdcdcd',
                    },
                  }}
                  disabled={!previewMode}
                  onChange={(e: any, value: number, activeThumb: number) => {
                    uiLinkStore.setBoxStyle({
                      ...uiLinkStore.boxStyle,
                      marginTop: value,
                      marginBottom: value,
                    });
                    updateServiceModel('blockMargin', value);
                  }}
                  defaultValue={(serviceModel?.blockMargin as number) ?? 10}
                  min={5}
                  max={40}
                  aria-label="Default"
                  valueLabelDisplay="off"
                />
              </Row>
              <Row>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e: any, value: boolean) => {
                            applyAllRef.current = value;
                          }}
                        />
                      }
                      label="모든 페이지에 적용"
                    />
                  </FormGroup>
                </div>
                <div>
                  <IconButton style={{ marginLeft: 20 }} onClick={handleClickApplyBlockMargin}>
                    <CheckOutlinedIcon />
                  </IconButton>
                </div>
              </Row>
            </div>
          </>
        )}
      </PreviewContainerStyle>
    );
  },
);

const ProfileContainerStyle = styled(Row)`
  display: flex;
  @media ${(props) => props.theme.media.mobile} {
    /* flex-direction: column; */
  }
`;
const PreviewContainerStyle = styled.div<{ isPreview?: boolean }>`
  min-height: 50px;
  background: #ffffff;
  padding: 10px;
  max-width: 550px;
  width: 100%;

  padding: 10px 20px;
  @media ${(props) => props.theme.media.mobile} {
  }
  @media ${(props) => props.theme.media.desk} {
    flex-direction: column;
    border-radius: 15px;
    padding: 20px;
    /* position: sticky;
    top: 0; */
  }
`;

const HRStyle = styled.div`
  border-top: 1px solid #dfdfdf;
  width: 100%;
  margin: auto;
  margin-top: 20px;
`;

const HorizontalHrStyle = styled.div`
  height: 20px;
  border-left: 1px solid #dfdfdf;
`;
export default PreviewBox;
