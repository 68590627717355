import React, { useEffect, useRef, useState } from 'react';
import NavigationTabBar from 'components/commons/NavigationTabBar';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import useToken from 'libs/hooks/useToken';
import GnbComponent from 'features/commonFeatures/navigationBar/containers/GnbComponent';
import GnbHeader from 'features/commonFeatures/navigationBar/containers/GnbHeader';
import SignInMenu from 'features/commonFeatures/navigationBar/containers/SignInMenu';
import PublicMenu from 'features/commonFeatures/navigationBar/containers/PublicMenu';
import { Fade, Slide } from 'react-awesome-reveal';
import LandingSlideA from '../components/LandingSlideA';
import LandingSlideB from '../components/LandingSlideB';
import LandingSlideC from '../components/LandingSlideC';
import LandingSlideD from '../components/LandingSlideD';
import LandingSlideE from '../components/LandingSlideE';
import LandingPageData from '../data/LandingPageData';
import RecommendLandingSlides from '../components/RecommendLandingSlides';
import { useGlobalContext } from 'context/useGlobalContext';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import AIExperience from 'components/commons/AIExperience';

const AIPreviewPage = () => {
  const gnbRef = useRef<any>(null);
  const { validUser } = useToken();
  const { isLoggedIn } = useGlobalContext();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <AIExperience isWebView={true} />;
};
const LandingPageContainer = styled.div`
  height: 100vh; /* 뷰포트 높이에 맞춤 */
  /* scroll-behavior: smooth; */
  @media ${(props) => props.theme.media.mobile} {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
  }
`;

const ScrollItemStyle = styled.div`
  height: 100vh;
  scroll-snap-align: start;
  @media ${(props) => props.theme.media.desk} {
    padding-top: 40px;
  }
  @media ${(props) => props.theme.media.mobile} and (min-height: 650px) {
    padding-top: 25px;
  }

  /* @media ${(props) => props.theme.media.mobile} and (min-height: 600px) {
    padding-top: 25px;
  } */
  /* @media screen and (max-height: 700px) {
    padding-top: 0;
  } */
`;

const GnbComponentStyle = styled.div`
  position: fixed;
  top: 10px;
  z-index: 10;
  @media ${(props) => props.theme.media.mobile} {
    position: fixed;
    border-radius: 0;
    top: 0px;
    box-shadow: none;
  }
`;
export default AIPreviewPage;
