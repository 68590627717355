import React, { useState, useEffect, SyntheticEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomFile, SizeInfo } from 'types/CommonTypes';
import { ImageUploader } from 'components/commons';
import { useStore } from 'stores/StoreHelper';
import { Button, FormControlLabel, Switch } from '@mui/material';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import styled from 'styled-components';
import { Row } from 'components/commons/layouts';

/**
 * @description 특정 이미지를 크롭후 리사이즈 한다.
 * @param param0
 * @returns
 */
const VideoEditor = ({
  video,
  isCover,
  autoplay,
  videoSettings,
  onVideoChange,
  onObjectFitChange,
  onSettingComplete,
  onAutoplayChange,
  mode,
}: {
  video?: CustomFile;
  isCover?: boolean;
  videoSettings?: SizeInfo;
  autoplay?: boolean;
  mode?: 'view' | 'edit';
  onVideoChange?: (video: any) => void;
  onAutoplayChange?: (autoplay: boolean) => void;
  onObjectFitChange?: (isCover: boolean) => void;

  onSettingComplete?: (settings?: SizeInfo) => void;
}) => {
  const CINEMA = 1.777; // 16:9
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const originWidth = useRef<number>(0);
  const originHeight = useRef<number>(100);

  /** @description 리사이즈한 최초 사이즈(cover인 경우 사이즈가 계속 변하기 때문에 최초사이즈 저장 */

  /** @description 최초로 바인딩할때 비디오 사이즈를 얻어와서 저장한다. */
  // const [isFirstBinding, setIsFirstBinding] = useState<boolean>(true);

  const { uiStore } = useStore();
  const [videoSize, setVideoSize] = useState<SizeInfo>();
  const [_isCover, setIsCover] = useState<boolean>();
  const [videoSource, setVideoSource] = useState<any>();
  const [hideFit, setHideFit] = useState<boolean>(true);
  const newMode = useRef<boolean>(false);

  const setNewSize = () => {
    let tempWidth: number | string = '100%';
    let tempHeight: number | string;
    let priority: 'width' | 'height' | undefined =
      originWidth.current > originHeight.current ? 'width' : 'height';

    if (typeof videoSettings !== 'undefined') {
      priority = videoSettings.priority;
      const r = Number((videoSettings.width / videoSettings.height).toFixed(2));
      setHideFit(r <= 1.8 && r >= 1.72);
      // setHideFit(false);
    }
    if (_isCover || isCover === true) {
      tempWidth = '100%';
      tempHeight = '100%';
    } else if (priority === 'width') {
      tempWidth = '100%';
      tempHeight = 'auto';
    } else {
      tempWidth = 'auto';
      tempHeight = '100%';
    }

    setVideoSize({
      width: tempWidth,
      height: tempHeight,
      isCover: _isCover,
      priority,
    });

    if (newMode.current && typeof onSettingComplete === 'function') {
      onSettingComplete({
        width: originWidth.current,
        height: originHeight.current,
        isCover: _isCover,
        priority,
      });
      const r = Number((originWidth.current / originHeight.current).toFixed(2));
      setHideFit(r <= 1.8 && r >= 1.72);
    } else {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const r = Number((videoSettings?.width / videoSettings?.height).toFixed(2));
      setHideFit(r <= 1.8 && r >= 1.72);
    }
  };

  const handleResize = () => {
    if (containerRef.current !== null) {
      const height = containerRef.current.offsetWidth / CINEMA;
      containerRef.current.setAttribute('style', `height:${height}px`);
    }
  };

  useEffect(() => {
    if (typeof _isCover !== 'undefined') {
      setNewSize();
    }
  }, [_isCover]);

  useEffect(() => {
    if (typeof isCover !== 'undefined') {
      setIsCover(isCover);
    }
  }, [isCover]);

  useEffect(() => {
    if (video) {
      setVideoSource(video.publicUrl);
    } else {
      setHideFit(true);
      newMode.current = true;
    }

    return () => {
      setVideoSource(undefined);
    };
  }, [video]);

  // useEffect(()=>{
  //   if(typeof videoSettings !== 'undefined'){

  //   }
  // }, [videoSettings])

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <VideoEditorContainerStyle isView={mode === 'view'}>
      <div
        ref={containerRef}
        style={{
          width: '100%',
          alignContent: 'center',
          justifyContent: 'center',
          borderRadius: 5,
          overflow: 'hidden',
        }}
      >
        <VideoContainerStyle>
          {typeof videoSource !== 'undefined' ? (
            <>
              <VideoContentStyle
                isCover={_isCover || isCover}
                width={videoSize?.width}
                height={videoSize?.height}
                playsInline
                autoPlay={false}
                key={videoSource}
                controls
                muted
                onLoadedData={(response: SyntheticEvent) => {
                  const { clientWidth, clientHeight } = response.target as any;
                  if (newMode.current) {
                    originWidth.current = clientWidth;
                    originHeight.current = clientHeight;
                  }
                  // 비율이 16:9에 근접하다면 fit버튼을 숨긴다.
                  // const r = Number((clientWidth / clientHeight).toFixed(2));
                  // setHideFit(r <= 1.8 && r >= 1.72);
                  setNewSize();
                }}
              >
                <source src={videoSource} />
                Your browser does not support HTML5 video.
              </VideoContentStyle>
            </>
          ) : (
            <>{mode === 'edit' && <div style={{ color: '#fff' }}>{t('A155_1')}</div>}</>
          )}
        </VideoContainerStyle>
      </div>
      {mode === 'edit' && (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
          <Row style={{ justifyContent: 'space-between' }}>
            <ImageUploader
              type='single'
              multipleUpload={false}
              attachType="video"
              useCrop={false}
              onSelected={(thumbnails: any) => {
                uiStore.progress.show();
                setVideoSource(thumbnails[0].publicUrl);
                setTimeout(() => {
                  uiStore.progress.close();
                }, 500);
                if (typeof onVideoChange !== 'undefined') {
                  onVideoChange(thumbnails);
                  // setHideFit(true);
                  newMode.current = true;
                  if (typeof onObjectFitChange === 'function') {
                    onObjectFitChange(false);
                  }
                }
              }}
            >
              <Button
                variant="contained"
                startIcon={<FileUploadIcon />}
                onClick={() => {
                  newMode.current = false;
                }}
              >
                {t('Upload video')}
              </Button>
            </ImageUploader>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={autoplay}
                  onChange={(event: never, value: boolean) => {
                    if (typeof onAutoplayChange === 'function') {
                      onAutoplayChange(value);
                    }
                  }}
                />
              }
              label={t('Auto play')}
            />
          </Row>
          {!hideFit && (
            <div
              style={{
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bottom: -50,
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={isCover}
                    disabled={typeof videoSource === 'undefined'}
                    onChange={(event: never, value: boolean) => {
                      setIsCover(value);
                      if (typeof onObjectFitChange === 'function') onObjectFitChange(value);
                    }}
                  />
                }
                label={t('A156')}
              />
            </div>
          )}
        </div>
      )}
    </VideoEditorContainerStyle>
  );
};
const VideoEditorContainerStyle = styled.div<{ isView: boolean; }>`
  position: relative;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
  display: ${(props) => (props.isView ? 'flex' : 'unset')};
  background: ${(props) => (props.isView ? '#000' : 'unset')};
`;

const VideoContainerStyle = styled.div`
  margin: auto;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  display: flex;
  justify-content: center;
  background: #000;
`;

const VideoContentStyle = styled.video<{ isCover?: boolean; }>`
  object-fit: ${(props) => (props.isCover ? 'cover' : 'unset')};
  margin: auto;
`;

export default React.memo(VideoEditor);
