import React, { CSSProperties } from 'react';
import styled from 'styled-components';

const StickerTypeA = ({ text, style }: { text: string; style: CSSProperties }) => {
  return (
    <>
      <ContainerWrapper style={style}>
        <ContainerStyle className="sticker-item">
          {typeof text === 'undefined' || text === 'StickerTypeA' ? 'sample' : text}
        </ContainerStyle>
      </ContainerWrapper>
    </>
  );
};

const ContainerStyle = styled.div`
  display: flex;
`;

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 25px;
  font-size: 11px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: 1px solid #05c755;
  min-width: 60px;
  padding: 0 10px 0 10px;
  color: #05c755;
  text-decoration: none !important ;
`;

export default StickerTypeA;
