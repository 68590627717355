import React, {
  useEffect,
  useState,
  SyntheticEvent,
  CSSProperties,
  useRef,
  forwardRef,
} from 'react';
import styled, { CSSProp } from 'styled-components';
import { CustomFile, SizeInfo } from 'types/CommonTypes';

/**
 * @description 특정 이미지를 크롭후 리사이즈 한다.
 * @param param0
 * @returns
 */
const VideoComponent = forwardRef(
  (
    {
      video,
      isCover,
      style,
      containerStyle,
      autoPlay,
    }: {
      video?: CustomFile;
      /** @description 비디오를 표시할 영역에 대한 사이즈를 지정. 없을 경우 100%임. */
      containerSize?: any;
      sizeInfo?: SizeInfo;
      autoPlay?: boolean;
      style?: CSSProperties;
      containerStyle?: CSSProperties;
      isCover?: boolean;
    },
    ref,
  ) => {
    const CINEMA = 1.777; //16:9
    const containerRef = useRef<HTMLDivElement>(null);
    const [source, setSource] = useState<any>();
    const [sourceUrl, setSourceUrl] = useState<any>();
    const [videoSize, setVideoSize] = useState<SizeInfo>();
    const [loaded, setLoaded] = useState<boolean>(false);
    const originWidth = useRef<number>(0);
    const originHeight = useRef<number>(0);

    const setNewSize = (width?: CSSProperties['width'], height?: CSSProperties['height']) => {
      let _width: number | string, _height: number | string;
      let _isCover: boolean = false;

      _isCover = typeof isCover !== 'undefined' ? isCover : false;
      if (_isCover === true) {
        _width = '100%';
        _height = '100%';
      } else {
        if (originWidth.current < originHeight.current) {
          _width = '100%';
          _height = 'auto';
        } else {
          _width = 'auto';
          _height = '100%';
        }
      }
      setVideoSize({
        width: _width,
        height: _height,
        isCover: _isCover,
      });
    };

    useEffect(() => {
      if (video) {
        setSource(video);
        setSourceUrl(`${video?.publicUrl}#t=1.0`);
      }
      return () => {
        setSource(undefined);
        setSourceUrl(undefined);
      };
    }, [video]);

    useEffect(() => {
      setNewSize();
    }, [isCover]);
    return (
      <div
        ref={containerRef}
        style={{ display: 'flex', height: '100%', background: 'transparent', ...style }}
      >
        <VideoContainerStyle
          className="crop-container"
          style={{ width: videoSize?.width, height: videoSize?.height, ...containerStyle }}
        >
          <VideoContentStyle
            key={sourceUrl}
            loaded={loaded}
            isCover={videoSize?.isCover!}
            width={videoSize?.width}
            height={videoSize?.height}
            controls
            preload="metadata"
            loop={true}
            playsInline
            muted
            // autoPlay={autoPlay ? autoPlay : false}
            autoPlay={false}
            onLoadedData={(response: SyntheticEvent) => {
              const { clientWidth, clientHeight } = response.target as any;
              originWidth.current = clientWidth;
              originHeight.current = clientHeight;
              setNewSize(clientWidth, clientHeight);
              setLoaded(true);
            }}
          >
            <source src={sourceUrl} type={video?.file?.type} />
            Your browser does not support HTML5 video.
          </VideoContentStyle>
        </VideoContainerStyle>
      </div>
    );
  },
);

const VideoContainerStyle = styled.div`
  margin: auto;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  background-color: #000;
`;
// width: ${(props) => (props.isCover ? '100%' : props.width)};
const VideoContentStyle = styled.video<{ isCover: boolean; loaded: boolean }>`
  opacity: ${(props) => (props.loaded ? 1 : 0)};
  height: ${(props) => (props.isCover ? '100%' : props.height)};
  object-fit: ${(props) => (props.isCover ? 'cover' : 'unset')};
  transition: all 0.5s ease-out;
  margin: auto;
`;

export default VideoComponent;
