import React from 'react';
import styled from 'styled-components';
import { FormControlLabel, Switch } from '@mui/material';

import TitleForm from 'components/items/forms/TitleForm';
import { Row } from 'components/commons/layouts';
import { t } from 'i18next';

const NormalBoard = () => {
  const handleChangeValue = (value: string) => {};
  return (
    <>
      <div>Board</div>
      <TitleForm onChangeValue={handleChangeValue} label={t('A142')} />
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            // checked={patchModel?.metadataObject?.channelTalk?.use}
            onChange={(event: never, value: boolean) => {
              //   dispatch((prev: ChannelConfigModel) => {
              //     return {
              //       ...prev,
              //       metadataObject: {
              //         ...prev.metadataObject,
              //         channelTalk: { ...prev.metadataObject?.channelTalk, use: value },
              //       },
              //     };
              //   });
            }}
          />
        }
        label="숨기기"
      />
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            // checked={patchModel?.metadataObject?.channelTalk?.use}
            onChange={(event: never, value: boolean) => {
              //   dispatch((prev: ChannelConfigModel) => {
              //     return {
              //       ...prev,
              //       metadataObject: {
              //         ...prev.metadataObject,
              //         channelTalk: { ...prev.metadataObject?.channelTalk, use: value },
              //       },
              //     };
              //   });
            }}
          />
        }
        label="댓글허용"
      />
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            // checked={patchModel?.metadataObject?.channelTalk?.use}
            onChange={(event: never, value: boolean) => {
              //   dispatch((prev: ChannelConfigModel) => {
              //     return {
              //       ...prev,
              //       metadataObject: {
              //         ...prev.metadataObject,
              //         channelTalk: { ...prev.metadataObject?.channelTalk, use: value },
              //       },
              //     };
              //   });
            }}
          />
        }
        label="가격정보 사용"
      />
      <div>Feed 표시형태</div>
      <Row style={{ justifyContent: 'space-around' }}>
        <ProfileTypeImageStyle
          //   checked={pathModel?.metadataObject?.profileType === 'simple'}
          onClick={() => {
            // dispatch((prev: ChannelConfigModel) => {
            //   return { ...prev, metadataObject: { profileType: 'simple' } };
            // });
          }}
        >
          <img src="/images/simpleTemplate.svg" alt="" style={{ width: 100 }} />
        </ProfileTypeImageStyle>
        <ProfileTypeImageStyle
          //   checked={pathModel?.metadataObject?.profileType === 'profile'}
          onClick={() => {
            // dispatch((prev: ChannelConfigModel) => {
            //   return { ...prev, metadataObject: { profileType: 'profile' } };
            // });
          }}
        >
          <img src="/images/profileTemplate.svg" alt="" style={{ width: 100 }} />
        </ProfileTypeImageStyle>
      </Row>
    </>
  );
};

const ProfileTypeImageStyle = styled.div<{ checked?: boolean }>`
  cursor: pointer;
  opacity: ${(props) => (props.checked ? 1 : 0.4)};
  transition: all 0.3s ease;
`;

export default NormalBoard;
