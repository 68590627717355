import React from 'react';
import { PartnerDto } from 'services/data-contracts';
import { observer } from 'mobx-react-lite';
import { Avatar, IconButton } from '@mui/material';
import { Row } from 'components/commons/layouts';
import { NameStyle } from 'resources/styles/common/ContextItem';
import { useStore } from 'stores/StoreHelper';

import NoContent from 'components/commons/NoContent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styled from 'styled-components';
import PartnerContext from '../components/PartnerContext';
import moment from 'moment';

const PartnersList = observer(
  ({
    title,
    partners,
    useNoContent = true,
    onReset,
  }: {
    title: string;
    partners: PartnerDto[] | undefined;
    useNoContent?: boolean;
    onReset?: () => void;
  }) => {
    const { uiStore } = useStore();

    const handleClickShowMore = (partner: PartnerDto) => {
      uiStore.bottomSheet.show({
        children: (
          <PartnerContext
            partner={partner}
            onReload={async () => {
              onReset?.();
            }}
            onEdit={() => {
              // handleAddManager(partner);
            }}
          />
        ),
      });
    };

    const PartnerListItem = ({ partner }: { partner: PartnerDto }) => {
      return (
        <Row style={{ justifyContent: 'space-between', position: 'relative' }}>
          <Row style={{ justifyContent: 'flex-start' }}>
            <div>
              <Avatar
                style={{ width: 60, height: 60 }}
                src={partner?.channel?.profile?.profileImage?.publicUrl}
              />
            </div>
            <div style={{ marginLeft: 10 }}>
              <div style={{ fontWeight: 600, fontSize: 16, marginBottom: 5 }}>
                {partner?.channel?.channelName}
              </div>
              <NameStyle>{moment(partner?.createDate).format('YYYY년 MM월 DD일')}</NameStyle>
            </div>
          </Row>
          <Row style={{ width: 'unset' }}>
            <IconButton>
              <MoreVertIcon
                onClick={() => {
                  handleClickShowMore(partner);
                }}
              />
            </IconButton>
          </Row>
          <>{!partner?.isActive && <MaskStyle />}</>
        </Row>
      );
    };

    return (
      <div>
        <>{useNoContent && partners?.length === 0 && <NoContent title={`${title}가`} />}</>
        <div>
          <TitleStyle>{title}</TitleStyle>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            {partners?.map((partner) => <PartnerListItem partner={partner} />)}
          </div>
        </div>
      </div>
    );
  },
);

const TitleStyle = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 20px;
`;
const MaskStyle = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  pointer-events: none;
`;
export default PartnersList;
