import React, { useEffect, useState } from 'react';
import { ChannelDto, LinkDto, SimpleDealDto } from 'services/data-contracts';
import { useStateContext } from 'libs/hooks/usePageState';
import { MyDealState } from 'types/CommonTypes';
import { Row } from 'components/commons/layouts';
import {
  DetailHeader,
  ListBoxTemplate,
  ListBoxWrapper,
  MenuIconBoxStyle,
  NoContentStyle,
  SubMenuStyle,
} from 'features/csTown/commonComponents/styles';

import { Menu, SubTabs } from 'features/csTown/commonComponents/SubTabs';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { IconButton } from '@mui/material';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useStore } from 'stores/StoreHelper';
import { IDealBlock } from 'types/BlockExtendsTypes';
import { usePageModelContext } from 'features/management/context/usePageModelContext';

import DeleteIcon from 'features/csTown/commonComponents/DeleteIcon';
import ShareBox from 'components/commons/ShareBox';
import CSManagers from 'features/csTown/components/pc/dealList/CSManagers';
import Deal from 'features/csTown/components/pc/Deal';
import BlockController from 'controllers/BlockController';
import CreateIcon from '@mui/icons-material/Create';
import IosShareIcon from '@mui/icons-material/IosShare';
import NoContent from 'components/commons/NoContent';
import PCDealList from 'features/csTown/components/pc/DealList';
import CloseIcon from '@mui/icons-material/Close';
import CSPartners from 'features/csTown/components/pc/dealList/CSPartners';
import DealDetail from 'features/csTown/components/pc/dealList/DealDetail';
import Dashboard from 'features/csTown/components/pc/dealList/Dashboard';
import InquiryList from 'features/csTown/components/pc/dealList/InquiryList';
import styled from 'styled-components';
import moment from 'moment';
import CSTownDealsController from 'features/csTown/pages/pageControllers/CSTownDealsController';
import LinkController from 'controllers/LinkController';
import CircularLoading from 'components/commons/CircularLoading';
import CSTownDealChecklistDetailContainer from './CSTownDealChecklistDetailContainer';
import CSTownDealCategoryView from './CSTownDealCategoryView';
import InfoBox from 'components/commons/InfoBox';
import { AnimatedTabButton, TabItem } from 'components/commons/buttons/AnimatedTabButton';
import useScrollDetector from 'libs/hooks/useScrollDetector';
import { useParams } from 'react-router-dom';

const CstownDealsContainer = ({ deals }: { deals?: SimpleDealDto[] }) => {
  type TempChannelType = NonNullable<ChannelDto['channelType']>;
  const menus: Record<TempChannelType, Menu[]> = {
    SELLER: [
      { id: 1, title: 'Dashboard', Component: Dashboard },
      { id: 4, title: '고객문의', Component: InquiryList, useOngoing: false },
      { id: 2, title: '공구 상세', Component: DealDetail, useOngoing: false },
      {
        id: 5,
        title: '체크리스트',
        Component: CSTownDealChecklistDetailContainer,
        useOngoing: false,
      },
      { id: 3, title: 'CS Partners', Component: CSPartners },
    ],
    BRAND: [
      { id: 4, title: '고객문의', Component: InquiryList },
      { id: 2, title: '공구 상세', Component: DealDetail },
      {
        id: 5,
        title: '체크리스트',
        Component: CSTownDealChecklistDetailContainer,
        useOngoing: false,
      },
      { id: 3, title: '담당 매니저', Component: CSManagers },
    ],
    MEMBER: [{ id: 4, title: '고객문의', Component: InquiryList }],
  };
  const params = useParams();

  const { myChannel } = useRootContext();
  const { setDealsByType } = CSTownDealsController();
  const { setPageState, stateModel, setMultipleState } = useStateContext<MyDealState>();
  const { getBlockByLinkKey, deleteBlock, updateBlock } = BlockController<LinkDto>();
  const { unmountModel, initialBinding } = usePageModelContext<IDealBlock>();
  const { uiStore } = useStore();
  const { getLinkByKey } = LinkController();
  const { scrollDirection, lastScrollTop, targetElement } = useScrollDetector('scrollContainer');

  const [selectedMenu, setSelectedTab] = useState<Menu>();
  const [dealLink, setDealLink] = useState<LinkDto>();
  const [viewType, setViewType] = useState<'TAB' | 'CATEGORY'>('TAB');
  const [deafaultTabIndex, setDefaultTabIndex] = useState<number>(0);

  const handleOnTab = (tab: Menu) => {
    setSelectedTab(tab);
  };

  const statusToDescription = (status: SimpleDealDto['status']) => {
    switch (status) {
      case 'ONGOING':
        return '진행중';
      case 'UPCOMING':
        return '대기중';
      case 'ENDED':
        return '종료됨';
      default:
        return '-';
    }
  };

  const handleTabChange = (key: number) => {
    setDealsByType(key);
  };

  const handleUpdate = () => {
    setMultipleState({ pageMode: 'VIEW', reset: { result: true } });
  };

  const handleDeleteDeal = () => {
    uiStore.confirm.show({
      message: '진행중인 공구를 삭제하시겠습니까?. \r\n관련된 모든 자료가 삭제됩니다.',
      onConfirmed: async () => {
        const result = (await deleteBlock(stateModel?.selectedDeal as LinkDto)) as unknown as any;
        if (result) {
          setMultipleState({ selectedDeal: undefined, reset: { result: true } });
          setDealLink(undefined);
        }
      },
    });
  };

  const handleCancelDeal = async () => {
    uiStore.confirm.show({
      message: '진행중인 공구를 취소하시겠습니까?',
      onConfirmed: async () => {
        const now = moment().utc();
        const formattedTime = now.format('YYYY-MM-DDTHH:mm:00[Z]');
        await updateBlock({ ...dealLink, endDate: formattedTime } as LinkDto);
        handleUpdate();
      },
    });
  };

  const handleClickShare = () => {
    uiStore.modal.show({
      children: (
        <ShareBox
          url={`https://ohmoss.com/${myChannel?.channelName}/deals/${stateModel?.selectedDeal?.linkKey}`}
          subject={stateModel?.selectedDeal?.title}
        />
      ),
      style: { maxHeight: 500 },
    });
  };

  const getTemplate = () => {
    if (myChannel && dealLink) {
      const typeMenu = menus[myChannel.channelType as TempChannelType];
      const Component = typeMenu.find((menu) => menu.id === selectedMenu?.id);
      return Component ? (
        <Component.Component deal={dealLink} onEditMode={stateModel?.pageMode === 'EDIT'} />
      ) : (
        <></>
      );
    } else {
      return <></>;
    }
  };

  /** Link상세를 취득한다. */
  const getDealLink = async (linkKey: string) => {
    let tempDeal;
    if (myChannel?.channelType === 'SELLER') {
      tempDeal = (await getBlockByLinkKey(linkKey)) as unknown as LinkDto;
    } else {
      tempDeal = await getLinkByKey(linkKey);
    }
    setDealLink(tempDeal);
  };

  useEffect(() => {
    if (stateModel?.selectedDeal) {
      getDealLink(stateModel?.selectedDeal?.linkKey as string);
      if (!selectedMenu) {
        if (stateModel?.selectedDeal?.status === 'UPCOMING') {
          setSelectedTab(menus[myChannel?.channelType as TempChannelType][1]);
        } else {
          setSelectedTab(menus[myChannel?.channelType as TempChannelType][0]);
        }
      }
    }
  }, [stateModel?.selectedDeal]);

  const setParamDeal = async () => {
    const deal = await getLinkByKey(params?.dealid as string);
    setPageState('selectedDeal', deal);
    let tIndex;
    switch (deal?.status) {
      case 'UPCOMING':
        tIndex = 1;
        break;
      case 'ONGOING':
        tIndex = 2;
        break;
      default:
        tIndex = 3;
        break;
    }

    setDefaultTabIndex(tIndex - 1);
    handleTabChange(tIndex);
  };

  useEffect(() => {
    // const target = document.getElementById('subMenuContainer') as any;
    // if (lastScrollTop >= 80) {
    //   target?.style.setProperty('position', 'fixed');
    //   target?.style.setProperty('top', '80px');
    //   target?.style.setProperty('z-index', '100');
    //   target?.style.setProperty(
    //     'width',
    //     (targetElement?.current?.clientWidth as number) - 20 + 'px',
    //   );
    // } else {
    //   target?.style.removeProperty('position');
    //   target?.style.removeProperty('top');
    // }
  }, [scrollDirection, lastScrollTop]);

  useEffect(() => {
    if (params.dealid && myChannel) {
      setParamDeal();
    }
  }, [params, myChannel]);

  return (
    <div style={{ width: '100%' }}>
      {viewType === 'CATEGORY' ? (
        <CSTownDealCategoryView deals={stateModel?.originalDeals} />
      ) : (
        <ListBoxWrapper>
          <ListBoxTemplate
            style={{ borderRight: '1px solid #efefef', padding: 0, position: 'relative' }}
          >
            <SwitchButtonStyle>hi</SwitchButtonStyle>
            <div style={{ marginBottom: '10px' }}>
              <AnimatedTabButton
                onClick={(item: any) => {
                  handleTabChange(Number(item.key));
                  setPageState('selectedDeal', undefined);
                }}
                defaultIndex={deafaultTabIndex}
                style={{ width: '400px' }}
              >
                <TabItem key={1}>대기중</TabItem>
                <TabItem key={2}>진행중</TabItem>
                <TabItem key={3}>완료됨</TabItem>
              </AnimatedTabButton>
            </div>
            <div style={{ flexGrow: 1, position: 'relative' }}>
              <CircularLoading />
              {deals?.length === 0 && <NoContent title={'표시할 공구가 '} />}
              <PCDealList deals={deals} />
            </div>
            {myChannel?.channelType === 'SELLER' && (
              <div style={{ padding: '0 20px' }}>
                <OhmossButton
                  style={{ borderRadius: 10, marginBottom: 10, padding: '0px 20px' }}
                  onClick={() => {
                    setMultipleState({ selectedDeal: undefined, pageMode: 'EDIT' });
                    unmountModel();
                    setDealLink(undefined);
                    initialBinding(undefined);
                  }}
                >
                  공구 등록
                </OhmossButton>
              </div>
            )}
            <>
              {myChannel?.channelType === 'BRAND' && (
                <div style={{ padding: 20 }}>
                  <InfoBox>공구 등록은 SELLER전용 기능입니다.</InfoBox>
                </div>
              )}
            </>
          </ListBoxTemplate>
          <div
            id="scrollContainer"
            style={{ height: 'calc(100vh - 80px)', width: '100%', overflow: 'auto' }}
          >
            <Row style={{ alignItems: 'flex-start' }}>
              <div
                style={{
                  flex: 1,
                  padding: '0 20px',
                }}
              >
                {stateModel?.pageMode === 'EDIT' ? (
                  <>
                    <div
                      style={{
                        padding: '20px 0',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        overflow: 'auto',
                      }}
                    >
                      <IconButton
                        style={{ marginRight: 10 }}
                        onClick={() => {
                          setPageState('pageMode', undefined);
                        }}
                      >
                        <CloseIcon style={{ width: 25, height: 25 }} />
                      </IconButton>
                      취소하기
                    </div>
                    <div style={{ padding: 20 }}>
                      <Deal blockModel={dealLink} onUpdate={handleUpdate} />
                    </div>
                  </>
                ) : (
                  <>
                    {stateModel?.selectedDeal ? (
                      <div
                        style={{
                          position: 'relative',
                        }}
                      >
                        <DetailHeader
                          style={{
                            border: '1px solid #d5d5d5',
                            borderRadius: 15,
                            marginTop: 20,
                            paddingBottom: 0,
                          }}
                        >
                          <Row style={{ justifyContent: 'space-between' }}>
                            <div>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <StatusStyle>
                                  {statusToDescription(stateModel?.selectedDeal?.status)}
                                </StatusStyle>
                                <div>
                                  <div
                                    style={{
                                      fontSize: 20,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {stateModel?.selectedDeal?.title}
                                  </div>
                                  <div style={{ color: '#777' }}>
                                    {moment(dealLink?.createDate).format('YYYY년 MM월 DD일')}에 생성
                                  </div>
                                </div>
                              </div>
                            </div>
                            <MenuIconBoxStyle style={{ height: 70 }}>
                              {selectedMenu?.id === 2 &&
                                stateModel?.selectedDeal.sellerChannel?.id === myChannel?.id && (
                                  <>
                                    <IconButton onClick={handleClickShare}>
                                      <IosShareIcon style={{ width: 25, height: 25 }} />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => {
                                        setPageState('pageMode', 'EDIT');
                                      }}
                                    >
                                      <CreateIcon style={{ width: 25, height: 25 }} />
                                    </IconButton>
                                    <IconButton onClick={handleCancelDeal}>
                                      <CloseIcon style={{ width: 25, height: 25 }} />
                                    </IconButton>
                                    <DeleteIcon onClick={handleDeleteDeal} />
                                  </>
                                )}
                            </MenuIconBoxStyle>
                          </Row>
                          <div style={{ width: '100%' }}>
                            <SubMenuStyle
                              id="subMenuContainer"
                              style={{ height: 50, marginBottom: 10 }}
                            >
                              <SubTabs
                                menus={menus[myChannel?.channelType as TempChannelType]}
                                onClickTab={handleOnTab}
                                selected={selectedMenu}
                              />
                            </SubMenuStyle>
                          </div>
                        </DetailHeader>
                        <div style={{ height: 20 }}></div>
                        {dealLink && <>{getTemplate()}</>}
                      </div>
                    ) : (
                      <NoContentStyle>
                        <div>
                          <img
                            src="/images/unknown.png"
                            alt=""
                            style={{ width: 100, margin: 'auto' }}
                          />
                          <div style={{ marginTop: 20 }}>목록에서 공구를 선택 해주세요.</div>
                        </div>
                      </NoContentStyle>
                    )}
                  </>
                )}
              </div>
            </Row>
          </div>
        </ListBoxWrapper>
      )}
    </div>
  );
};

const StatusStyle = styled.div`
  background: rebeccapurple;
  color: #fff;
  font-size: 12px;
  padding: 3px 10px;
  font-weight: 400;
  border-radius: 8px;
  text-align: center;
  margin-left: 10px;
  display: inline-block;
  min-width: 60px;
  margin-right: 20px;
`;

const SwitchButtonStyle = styled.div`
  position: absolute;
  top: -20px;
  right: 20px;
`;
export default CstownDealsContainer;
