import React from 'react';
import { observer } from 'mobx-react';
import MobileModalHeader from 'components/commons/MobileModalHeader';
import DetailPageHeader from 'components/commons/DetailPageHeader';

const NotificationPage = observer(() => {
  return (
    <>
      {/* <DetailPageHeader title="알림설정" /> */}
      알림설정
    </>
  );
});

export default NotificationPage;
