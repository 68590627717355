/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TagDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MyTag<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 내 태그 전체 조회
   *
   * @tags my tag
   * @name GetTags
   * @summary 내 태그 전체 조회
   * @request GET:/v2/me/channels/{channelId}/tags
   * @secure
   */
  getTags = (channelId: number, params: RequestParams = {}) =>
    this.http.request<TagDto[], any>({
      path: `/v2/me/channels/${channelId}/tags`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 태그 생성
   *
   * @tags my tag
   * @name CreateTag
   * @summary 태그 생성
   * @request POST:/v2/me/channels/{channelId}/tags
   * @secure
   */
  createTag = (channelId: number, data: TagDto, params: RequestParams = {}) =>
    this.http.request<TagDto, any>({
      path: `/v2/me/channels/${channelId}/tags`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 태그 조회
   *
   * @tags my tag
   * @name GetTag
   * @summary 내 태그 조회
   * @request GET:/v2/me/channels/{channelId}/tags/{tagId}
   * @secure
   */
  getTag = (channelId: number, tagId: number, params: RequestParams = {}) =>
    this.http.request<TagDto, any>({
      path: `/v2/me/channels/${channelId}/tags/${tagId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 태그 삭제
   *
   * @tags my tag
   * @name DeleteTag
   * @summary 태그 삭제
   * @request DELETE:/v2/me/channels/{channelId}/tags/{tagId}
   * @secure
   */
  deleteTag = (channelId: number, tagId: number, params: RequestParams = {}) =>
    this.http.request<TagDto, any>({
      path: `/v2/me/channels/${channelId}/tags/${tagId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 태그 수정
   *
   * @tags my tag
   * @name UpdateTag
   * @summary 태그 수정
   * @request PATCH:/v2/me/channels/{channelId}/tags/{tagId}
   * @secure
   */
  updateTag = (channelId: number, tagId: number, data: TagDto, params: RequestParams = {}) =>
    this.http.request<TagDto, any>({
      path: `/v2/me/channels/${channelId}/tags/${tagId}`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
