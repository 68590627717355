import React, { CSSProperties, ReactElement } from 'react';
import { Row } from 'components/commons/layouts';
import styled from 'styled-components';

const CommonBox = ({ children, style }: { children: ReactElement; style?: CSSProperties }) => {
  return (
    <RowStyle style={{ ...style }}>
      <Row style={{ justifyContent: 'space-between' }}>{children && children}</Row>
    </RowStyle>
  );
};

const RowStyle = styled.div`
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.03);
  border: 2px solid #ebebeb;
  margin: 3px 0;
  display: flex;
  align-items: center;
  border-radius: 10px;
  &:hover {
    background-color: #f0fff7;
  }
`;

export default CommonBox;
