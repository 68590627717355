import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { DealsTypes } from 'features/management/context/providers/ContextTypes';
import {
  ChannelChecklistCreationDto,
  ChannelChecklistDto,
  SimpleDealDto,
  ChannelChecklistTopicDto,
  ChannelChecklistUpdateDto,
} from 'services/data-contracts';
import styled from 'styled-components';
import CommonTabButtons from 'components/commons/buttons/CommonTabButtons';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useRootContext } from 'libs/hooks/useRootContext';
import TitleForm from 'components/items/forms/TitleForm';
import { useStore } from 'stores/StoreHelper';
import { Row } from 'components/commons/layouts';
import CommonThumbnail from 'features/management/components/commonControl/CommonThumbnail';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import ChecklistController from 'controllers/ChecklistController';
import { toast } from 'react-toastify';
import DealListSelector from 'features/myPage/containers/checklist/DealListSelector';
import ChecklistTopicContainer from 'features/myPage/containers/checklist/ChecklistTopicContainer';
import { CSTownMobileLayout } from 'features/csTown/commonComponents/styles';
import DeleteIcon from 'features/csTown/commonComponents/DeleteIcon';
import { CSTownChecklistState } from 'types/CommonTypes';
import { useStateContext } from 'libs/hooks/usePageState';
import ChecklistTopicItemsContainer from '../pc/ChecklistTopicItemsContainer';
import Text from 'components/commons/Text';

const CSTownChecklistDetailContainer = () => {
  const { uiStore } = useStore();
  const location = useLocation();
  const [searchParam] = useSearchParams();

  const { myChannel } = useRootContext();
  const { getChannelDeals } = useOhmossContext<DealsTypes>();
  const { stateModel, setPageState, setMultipleState, broadcast, receiver } =
    useStateContext<CSTownChecklistState>();

  const {
    createMyChecklist,
    getMyChecklist,
    getChannelChecklistTopic,
    getChecklistsByCategory,
    deleteMyChecklist,
    updateMyChecklist,
  } = ChecklistController();
  const { serviceModel, updateServiceModel } = useModelManagement<ChannelChecklistCreationDto>();
  const checklistId = useRef<number | string>(searchParam.get('id') || null);

  const [channelDeals, setChannelDeals] = useState<SimpleDealDto[]>([]);
  const [checklistInfo, setChecklistInfo] = useState<ChannelChecklistDto>();
  const [tab, setTab] = useState('체크리스트 상세');
  const [selectedDeal, setSelectedDeal] = useState<SimpleDealDto>();
  const [productName, setProductName] = useState<string>();
  const [topics, setTopics] = useState<ChannelChecklistTopicDto[]>([]);

  const category = searchParam.get('category');
  const id = searchParam.get('id');

  const getDeals = async () => {
    const deals = (await getChannelDeals()) as unknown as SimpleDealDto[];
    setChannelDeals(deals);
  };

  const getChecklistInfo = async () => {
    const result = await getMyChecklist(myChannel?.id as number, Number(id));
    if (result) {
      setChecklistInfo(result);
    }
    if (result?.deal) {
      setSelectedDeal(result?.deal);
    }
  };

  const getTopics = async () => {
    const tempTopics = await getChannelChecklistTopic(myChannel?.id as number, Number(id));
    setTopics(tempTopics);
  };

  const getNewChecklistTopics = async () => {
    const tempTopics = (await getChecklistsByCategory(
      Number(category),
      myChannel?.id as number,
    )) as any;

    if (tempTopics?.items) {
      const uniqueTopics = Array.from(
        new Set(tempTopics?.items.map((item: any) => item.topicName)),
      ).map((topicName) => ({
        topicName,
        progressBar: 0,
      }));
      setTopics(uniqueTopics as ChannelChecklistTopicDto[]);
    }
  };

  const handleSelectDeal = () => {
    uiStore.modal.show({
      title: '공구 선택',
      style: { height: '80vh', width: '90%' },
      children: (
        <DealListSelector
          onClick={async (result) => {
            setSelectedDeal(result);
            updateServiceModel('dealId', result?.id);
            setPageState('deal', result);
            const newModel = { ...checklistInfo, ...serviceModel };
            const newChecklist = await updateMyChecklist(
              myChannel?.id as number,
              stateModel?.selectedChecklist?.id as number,
              newModel,
            );
            uiStore.drawer.close();
            broadcast({ id: 'reload' });
            setPageState('selectedChecklist', newChecklist);
          }}
          deals={channelDeals}
        />
      ),
      onConfirmed(result) {
        if (result) {
        }
      },
    });
  };

  const handleDelete = async () => {
    if (id !== null) {
      uiStore.confirm.show({
        message: '체크리스트를 삭제하시겠습니까?',
        onConfirmed: async () => {
          await deleteMyChecklist(myChannel?.id as number, Number(id));
          window.history.go(-1);
        },
      });
    }
  };

  useEffect(() => {
    if (myChannel) {
      if (Number(checklistId.current) > 0) {
        setPageState('selectedChecklist', { id: Number(checklistId.current) });
      }
      if (id !== null) {
        getDeals();
        getChecklistInfo();
        getTopics();
        updateServiceModel('categoryId', Number(category));
      } else {
        getDeals();
        getNewChecklistTopics();
        updateServiceModel('categoryId', Number(category));
      }
    }
  }, [myChannel]);

  useEffect(() => {
    if (location.pathname) {
      setTimeout(() => {
        const t = document.querySelector('.ohmoss-navigation-tab') as any;
        if (
          (t && location.pathname === '/my/deals/checklist/detail/list') ||
          location.pathname === '/cstown/checklist/detail/list'
        ) {
          t.style.display = 'none';
        } else {
          t.style.display = 'flex';
        }
      }, 0);
    }
  }, [location]);

  return (
    <CSTownMobileLayout style={{ padding: '20px' }}>
      <MainContainer>
        {/* 공구를 선택하지 않은 경우 */}
        {!selectedDeal && (
          <DealContainer>
            <Row
              style={{
                position: 'relative',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <CommonThumbnail />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 6,
                    }}
                  >
                    {typeof checklistInfo !== 'undefined' && (
                      <img
                        src={checklistInfo?.category?.image?.publicUrl}
                        alt={checklistInfo?.title}
                        style={{
                          width: 16,
                          height: 16,
                          padding: 2,
                          border: '1px solid #D3D3D3',
                          background: '#FFF',
                        }}
                      />
                    )}
                    <Text type="H3" style={{ fontSize: 16 }}>
                      {checklistInfo?.title ?? productName}
                    </Text>
                  </div>
                </div>
              </div>
              <SelectDealBtn onClick={handleSelectDeal}>공구 연결하기</SelectDealBtn>
            </Row>
          </DealContainer>
        )}

        <DealSearchContainer>
          {/* 공구를 선택한 경우 */}
          {selectedDeal && (
            <DealContainer>
              <Row
                style={{
                  justifyContent: 'space-between',
                }}
              >
                <Row style={{ justifyContent: 'flex-start' }}>
                  <CommonThumbnail initialValue={selectedDeal?.thumbnail?.publicUrl} />
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20 }}>
                    <Text>{checklistInfo?.title}</Text>
                    <Text type="H3" style={{ fontSize: 13 }}>
                      {selectedDeal?.title}
                    </Text>
                  </div>
                </Row>
                <div>
                  <SelectDealBtn style={{ width: 60 }} onClick={handleSelectDeal}>
                    변경
                  </SelectDealBtn>
                </div>
              </Row>
            </DealContainer>
          )}
        </DealSearchContainer>
      </MainContainer>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        {stateModel?.selectedTopic ? (
          <ChecklistTopicItemsContainer />
        ) : (
          //
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              padding: 0,
            }}
          >
            <ChecklistTopicContainer
              style={{ padding: 0 }}
              topics={topics}
              checklistId={stateModel?.selectedChecklist?.id}
            />
          </div>
        )}
        <div
          style={{
            width: '100%',
            margin: '20px 10px',
            height: 100,
          }}
        >
          {/* <OhmossButton onClick={handleConfirm} type="primaryPc">
            저장
          </OhmossButton> */}
          <DeleteIcon
            onClick={() => {
              handleDelete();
            }}
          />
        </div>
      </div>
    </CSTownMobileLayout>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DealContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  /* justify-content: center; */
  border: 1px solid #e0e0e0;
  padding: 5px 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  min-height: 80px;
`;

const DealSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 20px;
`;

const SelectDealBtn = styled.button`
  background: #011208;
  color: #0bbd58;
  font-size: 0.875rem;
  font-weight: 500;
  width: 100px;
  padding: 0.3rem 0.3rem;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
`;

export default CSTownChecklistDetailContainer;
