import React, { CSSProperties } from 'react';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import styled from 'styled-components';

const DragIcon = ({ style }: { style?: CSSProperties }) => {
  return (
    <IconButtonStyle style={style}>
      <DragHandleIcon />
    </IconButtonStyle>
  );
};

const IconButtonStyle = styled.div`
  padding: 10px;
  cursor: grab;
  border-radius: 35px;
`;

export default DragIcon;
