import { IconButton } from '@mui/material';
import CommonTabButtons from 'components/commons/buttons/CommonTabButtons';
import useNavigation from 'hooks/useNavigation';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { useRootContext } from 'libs/hooks/useRootContext';
import MembershipController from 'controllers/MembershipController';
import { MembershipDto, MembershipPlanDto } from 'services/data-contracts';
import MembershipContainer from '../containers/membership/MembershipContainer';
import MyMembershipInfo from '../containers/membership/MyMembershipInfo';
import MembershipManual from '../containers/membership/MembershipManual';
import MembershipGift from '../containers/membership/MembershipGift';
import { useMediaContext } from 'providers/useMediaContext';
import MembershipPaymentHistory from '../components/membership/MembershipPaymentHistory';
import CommonRoundTabButtons from 'components/commons/buttons/CommonRoundTabButtons';

const MembershipPlanPage = () => {
  const [searchParam] = useSearchParams();
  const tabName = searchParam.get('tab');
  const ScreenTypes = useMediaContext();

  const { navigate } = useNavigation();
  const { getMembershipPlans, getPreviousMemberships } = MembershipController();
  const { myChannel } = useRootContext();

  const [tabLabel, setTabLabel] = useState<string>('내 멤버십');
  const [plans, setPlans] = useState<MembershipPlanDto[]>();
  const [giftPlans, setGiftPlans] = useState<MembershipPlanDto[]>();
  const [allMemberships, setAllMemberships] = useState<MembershipDto[]>([]);

  const getMembershipHistory = async () => {
    const result = (await getPreviousMemberships()) as any;
    if (result) {
      setAllMemberships((prev) => [...prev, ...result]);
    }
  };

  const handleTabClick = (tab: string) => {
    setTabLabel(tab);
    switch (tab) {
      case '멤버십 구독':
        navigate(`/my/subscriptions?tab=0`);
        break;
      case '멤버십 설명서':
        navigate(`/my/subscriptions?tab=1`);
        break;
      case '내 멤버십':
        navigate(`/my/subscriptions?tab=2`);
        break;
      case '결제 내역':
        navigate(`/my/subscriptions?tab=3`);
        break;
      case '멤버십 선물':
        navigate(`/my/subscriptions?tab=4`);
        break;
      default:
        break;
    }
  };

  const getTabPage = (tabName?: string) => {
    switch (tabName) {
      case '멤버십 구독':
        navigate(`/my/subscriptions?tab=0`);
        break;
      case '멤버십 설명서':
        navigate(`/my/subscriptions?tab=1`);
        break;
      case '내 멤버십':
        navigate(`/my/subscriptions?tab=2`);
        break;
      case '멤버십 선물':
        navigate(`/my/subscriptions?tab=3`);
        break;
      default:
        break;
    }
  };

  const convertTabName = (tabName: string) => {
    switch (tabName) {
      case '0':
        setTabLabel('멤버십 구독');
        break;
      case '1':
        setTabLabel('멤버십 설명서');
        break;
      case '2':
        setTabLabel('내 멤버십');
        break;
      case '3':
        setTabLabel('결제 내역');
        break;
      case '4':
        setTabLabel('멤버십 선물');
        break;
      default:
        break;
    }
  };

  const getMyPlanInfo = async () => {
    const result = (await getMembershipPlans(
      myChannel?.channelType as 'SELLER' | 'BRAND' | 'MEMBER',
    )) as unknown as MembershipPlanDto[];
    if (result) {
      setPlans([{ membershipType: 'FREE', name: 'FREE', amount: 0 }, ...result]);
    }
  };

  const getMembershipPlanInfos = async () => {
    const result =  await getMembershipPlans('SELLER');
    if(result){
      setGiftPlans(result);
    }
  }

  useEffect(() => {
    if (tabName) {
      // getTabPage(tabName);
      convertTabName(tabName);
    }
  }, [tabName]);

  useEffect(() => {
    setTimeout(() => {
      const t = document.querySelector('.ohmoss-navigation-tab') as any;

      if (t && tabLabel === '멤버십 설명서') {
        t.style.display = 'none';
      } else {
        t.style.display = 'flex';
      }
    }, 0);
  }, [tabLabel]);

  useEffect(() => {
    if (myChannel) {
      getMyPlanInfo();
      getMembershipPlanInfos();
      getMembershipHistory();
    }
  }, [myChannel]);

  return (
    <ContainerStyle>
      {/* CASE 1. 모바일 화면인 경우 */}
      {!ScreenTypes.isLarge ? (
        <>
          <div
            style={{
              width: '100%',
              borderBottom: '1px solid #173300',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 60,
              padding: '0px 20px',
            }}
            className="global-width"
          >
            <Header>오모스 멤버십</Header>
          </div>
          <div
            style={{
              paddingTop: '10px',
              borderBottom: '1px solid #CBCBCB',
            }}
          >
            <CommonTabButtons
              items={
                ['멤버십 구독', '멤버십 설명서', '내 멤버십', '결제 내역', '멤버십 선물']
                // myChannel?.channelType === 'BRAND'
                //   ? // ? ['멤버십 구독', '멤버십 선물', '내 멤버십']
                //     ['멤버십 구독', '내 멤버십', '결제 내역']
                //   : ['멤버십 구독', '내 멤버십']
              }
              initialValue={tabLabel}
              noOfTabs={myChannel?.channelType === 'BRAND' ? 4 : 3}
              onHandleTabClick={handleTabClick}
            />
          </div>
        </>
      ) : (
      /* CASE 2. PC 화면인 경우 */
        <div
          style={{
            paddingTop: 0,
            borderBottom: 'none',
          }}
        >
          {/* todo : 멤버십 설명서 추가할것 */}
          <CommonRoundTabButtons
            items={['멤버십 구독', '멤버십 설명서', '내 멤버십', '결제 내역', '멤버십 선물']}
            initialValue={tabLabel}
            noOfTabs={myChannel?.channelType === 'BRAND' ? 4 : 3}
            onHandleTabClick={handleTabClick}
          />
      </div>
      )}
      {tabLabel === '멤버십 구독' && (
        <MembershipContainer plans={plans} channelType={myChannel?.channelType} />
      )}
      {tabLabel === '멤버십 설명서' && <MembershipManual />}
      {tabLabel === '내 멤버십' && <MyMembershipInfo channelType={myChannel?.channelType} />}
      {tabLabel === '결제 내역' && (
        <>
          {allMemberships.length === 0 ? (
            <div style={{ textAlign: 'center' }}>결제 내역이 없습니다.</div>
          ) : (
            <MembershipPaymentHistory history={allMemberships} />
          )}
        </>
      )}
      {tabLabel === '멤버십 선물' && (
        <MembershipGift plans={giftPlans} type={tabLabel} channelType={myChannel?.channelType} />
      )}
    </ContainerStyle>
  );
};

const Header = styled.div`
  color: #173300;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.096px;
`;

const ContainerStyle = styled.div`
  width: 100%;
  @media ${(props) => props.theme.media.desk} {
    margin-left: 30px;
    width: 800px;
  }
`;

export default MembershipPlanPage;
