import React, { useEffect, useState } from 'react';
import { CustomFile } from 'types/CommonTypes';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const CropperImage = ({ image, onClick, onDelete }: { image?: CustomFile; onClick: (e: any) => void; onDelete: (e: any) => void }) => {
  const [file, setFile] = useState<CustomFile>();

  useEffect(() => {
    if (image) {
      setFile(image);
    }
    return (() => {
      setFile(undefined);
    });
  }, [image]);

  return (
    <DefaultIconStyle >
      {file ? (
        <>
          <div style={{ position: 'relative' }}>
            <ImageStyle onClick={onClick}>
              <img src={file.publicUrl} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </ImageStyle>
            <IconStyle>
              <AddIcon style={{ color: "#fff", width: 10, height: 10 }} />
            </IconStyle>
            <IconStyle style={{ top: -10, right: -10, background: 'red', width: 30, height: 30 }} onClick={onDelete}>
              <DeleteOutlineIcon style={{ color: "#fff", width: 20, height: 20 }} />
            </IconStyle>
          </div>
        </>
      ) : (
        <AddIcon style={{ color: '#fff' }} />
      )}
    </DefaultIconStyle>
  );
};

const DefaultIconStyle = styled.div`
  width:60px;height:60px;
  background:#E5E5E5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  :hover {
    background:#2EBE59;
  }
`;

const IconStyle = styled.div`
  width:20px;
  height:20px;
  background: #05C755;
  border:1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom:0px;
  right:0px;
  border-radius: 40px;
`;

const ImageStyle = styled.div`
  width:60px;height:60px;
  overflow: hidden;
  border-radius: 60px;
`;

export default CropperImage;
