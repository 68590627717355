import React, { useEffect, useState } from 'react';
import { IPopUpBlock } from 'types/BlockExtendsTypes';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import { Row } from 'components/commons/layouts';
import { isOver24Hours } from 'libs/helper/utils';

import useNavigation from 'libs/hooks/useNavigate';
import styled from 'styled-components';

type PopUpStorage = {
  id: string;
  startDate: Date;
};

const PopUpModal = ({ item, editMode }: { item: IPopUpBlock; editMode?: boolean }) => {
  const navigate = useNavigation();
  const { uiStore } = useStore();

  const [isOver24, setIsOver24] = useState<boolean>();

  const handleGo = () => {
    if (item.linkUrl) navigate(item.linkUrl);
  };

  const handleClickClose = (temp?: boolean) => {
    if (temp) {
      uiStore.modal.close();
    } else {
      const temp: PopUpStorage = {
        id: `${item.channel?.id}-${item.id}`,
        startDate: new Date(),
      };
      localStorage.setItem(`${item.channel?.id}-${item.id}-popup`, JSON.stringify(temp));
      uiStore.modal.close();
    }
  };

  useEffect(() => {
    if (item) {
      const popupItem = localStorage.getItem(`${item.channel?.id}-${item.id}-popup`);
      if (popupItem) {
        const oldDate: Date = new Date();
        setIsOver24(isOver24Hours(oldDate));
      } else {
        setIsOver24(true);
      }
    }
  }, [item]);

  return (
    <div>
      <div style={{ padding: 20, fontSize: 16, fontWeight: 700 }}>{item.title}</div>
      <div
        style={{ padding: 20, paddingTop: 0 }}
        dangerouslySetInnerHTML={{
          __html: item.content as string,
        }}
      />
      {item.linkUrl && (
        <div style={{ marginTop: 30, padding: 20 }}>
          <OhmossButton onClick={handleGo}>더 알아보기</OhmossButton>
        </div>
      )}

      {editMode !== true && (
        <NoMoreStyle>
          <Row>
            <div
              style={{ margin: 'auto' }}
              onClick={() => {
                handleClickClose(true);
              }}
            >
              닫기
            </div>
            <>
              {isOver24 && (
                <div
                  style={{ margin: 'auto' }}
                  onClick={() => {
                    handleClickClose(false);
                  }}
                >
                  24시간동안 숨기기
                </div>
              )}
            </>
          </Row>
        </NoMoreStyle>
      )}
    </div>
  );
};

const NoMoreStyle = styled.div`
  position: fixed;
  max-width: 550px;
  z-index: 10;
  color: #fff;
  display: flex;
  cursor: pointer;
  justify-content: center;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  /* bottom: 20px; */
  height: 50px;
`;
export default PopUpModal;
