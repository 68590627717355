import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import useNavigation from 'hooks/useNavigation';
import { useParams, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { GroupedTag } from 'types/CommonTypes';
import { t } from 'i18next';
import Follow from '../components/Follow';
import _ from 'lodash';
import CRUDHelper from 'libs/helper/crudHelper';
import TagList from 'features/mainFeatures/commons/components/TagList';
import useToken from 'libs/hooks/useToken';
import { FriendshipTagDto } from 'services/data-contracts';
import styled from 'styled-components';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { toast } from 'react-toastify';

const FollowButtonsContainer = observer(() => {
  const { channelStore, tagStore, uiStore, myChannelStore, uiStores } = useStore();
  const { userHomeStore } = uiStores;
  const { channelname } = useParams();
  const { validUser, isValidUser } = useToken();
  const [searchParam] = useSearchParams();
  const selectedTags = useRef<FriendshipTagDto>({ tags: [] });
  const tagListRef = useRef<any>(null);
  const crudHelper = new CRUDHelper(tagStore);
  const { navigate } = useNavigation();

  const handleClickFollow = async (isFollow: boolean) => {
    if (validUser()) {
      if (isFollow) {
        const result = (await crudHelper.getItems(channelname)) as unknown as Array<GroupedTag>;
        // const policies = (await channelStore.getChannelPolicies(
        //   channelName as string,
        // )) as PersonalInfoPolicyDto;
        if (result.length === 0) {
          // if (result.length === 0 && policies.personalInfoPolicy === null) {
          handleConfirmFollow();
        } else {
          const modalTitle = <div>{t('A243')}</div>;
          const description = <div>{t('A244')}</div>;

          uiStore.universalModal.show({
            title: modalTitle,
            description,
            bottomSheetOptions: { topPosition: 100 },
            modalOption: {
              style: { maxWidth: '650px', maxHeight: '550px' },
            },
            children: (
              <TagListContainer>
                <TagList tags={result} ref={tagListRef} onSelected={handleSelectedTags} />
                <CenterStyle>
                  <div>
                    <FollowButtonStyle onClick={handleConfirmFollow}>Follow</FollowButtonStyle>
                  </div>
                  <div>
                    <OhmossButton
                      type="link"
                      onClick={() => {
                        uiStore.universalModal.close();
                      }}
                      style={{ marginTop: 15, fontSize: 14 }}
                    >
                      {t('A225')}
                    </OhmossButton>
                  </div>
                </CenterStyle>
              </TagListContainer>
            ),
          });
        }
        // }
      } else {
        if (channelname) {
          const result = await myChannelStore.unfollow(
            myChannelStore.currentChannel?.id as number,
            channelname,
          );
          if (result) {
            userHomeStore.setChannelFollow(false);
          }
        }

        uiStore.universalModal.close();
      }
    } else {
      uiStore.confirm.show({
        message: t('A245'),
        onConfirmed: async () => {
          navigate(`/login?action=(1.${channelname})`);
        },
      });
    }
  };

  const handleSelectedTags = (tags: Array<number>) => {
    selectedTags.current.tags = tags.map((tag: number) => {
      return {
        id: tag,
      };
    }) as any;
  };

  const handleConfirmFollow = async () => {
    if (
      channelname &&
      typeof selectedTags.current !== 'undefined' &&
      channelStore.channel?.channelName
    ) {
      selectedTags.current.targetChannel = { channelName: channelStore.channel.channelName };
      const result = (await myChannelStore.follow(
        myChannelStore.currentChannel?.id as number,
        channelStore.channel?.channelName,
        selectedTags.current,
      )) as any;
      if (result.status === 200) {
        userHomeStore.setChannelFollow(true);
        uiStore.universalModal.close();
      }
    } else {
      alert(t('A246'));
    }
  };

  useEffect(() => {
    if (validUser()) {
      const action = searchParam.get('action');
      if (typeof channelStore.channel?.isFollow !== 'undefined') {
        userHomeStore.setChannelFollow(channelStore.channel?.isFollow);
      }
      if (action && action === '1') {
        if (channelStore.channel?.isFollow) {
          toast(t('A247'));
        } else {
          window.history.replaceState('force', `${channelname}?action=1`, `/${channelname}`);
          handleClickFollow(true);
        }
      }
    }
  }, [channelStore.channel]);

  return (
    <div className="row">
      <>
        <Follow
          follow={userHomeStore?.isFollow}
          onClick={(isFollow: boolean) => {
            handleClickFollow(isFollow);
          }}
        />
      </>
    </div>
  );
});

const TagListContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  height: 95%;
  align-content: space-between;
  flex-direction: row;
  width: 90%;
`;

const FollowButtonStyle = styled(OhmossButton)`
  padding: 10px 20px;
  width: 300px;
`;

const CenterStyle = styled.div`
  margin: auto;
  display: inline-block;
  text-align: center;
  margin-top: 30px;
`;
export default FollowButtonsContainer;
