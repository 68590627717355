/* eslint-disable react/jsx-pascal-case */
import React, { CSSProperties, ReactElement, useState } from 'react';

import { Row } from 'components/commons/layouts';

import styled from 'styled-components';
import _ from 'lodash';

const SettingItemRow = ({
  title,
  style,
  children,
}: {
  title: string;
  style?: CSSProperties;
  children: ReactElement;
}) => {
  return (
    <AdaptiveRowStyle
      style={{
        ...style,
      }}
    >
      <LabelStyle>{title}</LabelStyle>
      {children}
    </AdaptiveRowStyle>
  );
};

const LabelStyle = styled.div`
  font-size: 14px;
  text-align: left;
  color: #999999;
`;

const AdaptiveRowStyle = styled.div<{ style?: CSSProperties }>`
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 20px;
  width: auto;
  justify-items: center;
  align-content: center;
  align-items: center;
  padding: 10px;

  margin: 0 10px;
  @media ${(props) => props.theme.media.mobile} {
    padding: 0;
    margin: 0;
  }
`;
export default SettingItemRow;
