import React from 'react';
import { OhmossButton } from 'resources/controls/OhmossButton';
import styled from 'styled-components';
import { ChannelDto, MembershipDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import useNavigation from 'hooks/useNavigation';
import MembershipInfoCard from './MembershipInfoCard';

const PurchaseSuccessBottomSheet = ({
  channelType,
  membership,
}: {
  channelType?: ChannelDto['channelType'];
  membership?: MembershipDto;
}) => {
  const { uiStore } = useStore();
  const { navigate } = useNavigation();

  return (
    <Wrapper>
      <WelcomeText>환영합니다!</WelcomeText>
      <SubText>
        강력한 멤버십 혜택을 누려보세요!
        <img
          src="/images/welcome.svg"
          alt="welcome_img"
          style={{ width: '100px', position: 'absolute', right: 20, bottom: -22 }}
        />
      </SubText>
      <div style={{ marginTop: 12 }}>
        <MembershipInfoCard channelType={channelType} membership={membership} hideMonth />
      </div>
      <OhmossButton
        onClick={() => {
          uiStore.bottomSheet.close();
          navigate('/my/subscriptions');
        }}
        style={{ marginTop: 20 }}
      >
        확인
      </OhmossButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
`;

const WelcomeText = styled.div`
  color: #505050;
  font-size: 20px;
  font-weight: 500;
`;

const SubText = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.875rem;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.02625rem;
  margin-bottom: 10px;
  position: relative;
`;

export default PurchaseSuccessBottomSheet;
