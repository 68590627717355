import React, { ReactChild, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const MenuItem = ({
  title,
  menuType = 'link',
  children,
  onClick,
  style,
  type,
}: {
  title: React.ReactNode | string;
  menuType?: 'link' | 'action' | 'danger' | 'contents';
  children?: ReactChild;
  onClick?: () => void;
  style?: any;
  type?: string;
}) => {
  return (
    <ItemBoxStyle
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      style={{ cursor: typeof onClick !== 'undefined' ? 'pointer' : 'default' }}
    >
      <TitleStyle style={{ ...style }} type={menuType}>
        {title}
      </TitleStyle>
      <>
        {children ? (
          <>{children}</>
        ) : (
          <>
            {menuType === 'link' && (
              <>
                <div
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontWeight: 500,
                    fontSize: '0.9375rem',
                    position: 'absolute',
                    right: 60,
                  }}
                >
                  {typeof type !== 'undefined' && type === 'SELLER'
                    ? '셀러PRO +'
                    : typeof type !== 'undefined' && type === 'PERSONAL'
                    ? '일반회원'
                    : ''}
                </div>
                <ArrowForwardIosIcon style={{ height: 14, color: '#BEBEBE', marginTop: 3 }} />
              </>
            )}
          </>
        )}
      </>
    </ItemBoxStyle>
  );
};

const ItemBoxStyle = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  color: #1a1a1a;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 10px 0px;
`;

const DangerType = css`
  color: #fe324b;
`;

const TitleStyle = styled.div<{ type?: string }>`
  ${(props) => {
    if (props.type === 'danger') return DangerType;
  }}
`;

export default MenuItem;
