import DetailPageHeader from 'components/commons/DetailPageHeader';
import ChecklistController from 'controllers/ChecklistController';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import CheckTopic from 'features/myPage/components/checklist/CheckTopic';
import { useRootContext } from 'libs/hooks/useRootContext';
import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { ChannelChecklistItemDto, ChannelChecklistTopicDto } from 'services/data-contracts';
import styled from 'styled-components';
import colors from '../components/checklist/ChecklistColors';

const ViewSummary = () => {
  const location = useLocation();
  const [searchParam] = useSearchParams();
  const checklistId = searchParam.get('id');
  const topicId = searchParam.get('topic');
  const topicIndex = searchParam.get('topicIndex');

  const { myChannel } = useRootContext();
  const { getChannelChecklistTopic, getMyChecklistItems } = ChecklistController();

  const [items, setItems] = useState<ChannelChecklistItemDto[]>([]);
  const [allItems, setAllItems] = useState<ChannelChecklistItemDto[]>([]);
  const [topics, setTopics] = useState<ChannelChecklistTopicDto[]>();
  const [topicName, setTopicName] = useState<any>();

  const getChecklistItems = async () => {
    if (checklistId) {
      const tempTopics = await getChannelChecklistTopic(
        myChannel?.id as number,
        Number(checklistId),
      );
      const allChecklistItems = await getMyChecklistItems(
        myChannel?.id as number,
        Number(checklistId),
        Number(topicId),
      );

      if (allChecklistItems && topicIndex !== 'all') {
        const newItems = allChecklistItems.filter((item: any) => {
          return item?.topicId === tempTopics[Number(topicIndex)]?.id;
        });

        setItems(newItems);
        setTopicName(tempTopics[Number(topicIndex)].name);
      }
    }
  };

  const handlePdfBtn = () => {
    toast('PDF 다운로드 기능은 준비중입니다.');
  };

  const getMeanPercentage = () => {
    if (topics && allItems?.length > 0) {
      const meanPercentage =
        topics.reduce((sum, item) => sum + (item?.progressRate ?? 0), 0) / topics.length;
      return meanPercentage as number;
    }
  };

  useEffect(() => {
    if (myChannel) {
      getChecklistItems();
    }
  }, [myChannel]);

  useEffect(() => {
    if (location.pathname) {
      setTimeout(() => {
        const t = document.querySelector('.ohmoss-navigation-tab') as any;
        if (
          (t && location.pathname === '/my/deals/checklist/detail/summary') ||
          location.pathname === '/cstown/checklist/detail/summary'
        ) {
          t.style.display = 'none';
        } else {
          t.style.display = 'flex';
        }
      }, 0);
    }
  }, [location.pathname]);

  console.log(allItems);

  return (
    <div style={{ paddingBottom: 100 }}>
      <DetailPageHeader title="요약보기" />
      <ManagementLayout style={{ justifyContent: 'flex-start' }}>
        {topicIndex === 'all' && (
          <CheckTopic
            topic={{ title: '전체보기' }}
            showIcon={false}
            endColor={'#BEBEBE'}
            percentage={getMeanPercentage()}
          />
        )}
        {topicIndex !== 'all' && (
          <CheckTopic
            topic={{ title: topicName }}
            showIcon={false}
            endColor={colors[topicIndex as unknown as number].color}
            percentage={topics?.[Number(topicIndex)]?.progressRate ?? 0}
          />
        )}

        <SummaryBox>
          {topicIndex === 'all' &&
            allItems.map((item, index) => (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {allItems[index]?.isCheck === true && (
                  <>
                    <span style={{ fontSize: 14, marginBottom: 2 }}>• {topicName ?? ''}</span>
                    <span style={{ fontSize: 13, marginBottom: 8, color: '#48505D' }}>
                      {allItems[index]?.remark}
                    </span>
                  </>
                )}
              </div>
            ))}

          {topicIndex !== 'all' &&
            items?.map((item, index) => (
              <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                {items[index]?.isCheck === true && (
                  <span style={{ fontSize: 13, color: '#48505D' }}>{items[index]?.remark}</span>
                )}
              </div>
            ))}
        </SummaryBox>
      </ManagementLayout>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
          padding: '0px 20px 50px 20px',
          width: '100%',
          bottom: 0,
          position: 'absolute',
        }}
      >
        <OhmossButton onClick={handlePdfBtn} type="secondary">
          PDF 다운로드
        </OhmossButton>
        <OhmossButton type="primary">확인</OhmossButton>
      </div>
    </div>
  );
};

const SummaryBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1.25rem;
  border: 1px solid rgba(23, 51, 0, 0.3);
  padding: 1rem;
  margin-bottom: 20px;
`;

export default ViewSummary;
