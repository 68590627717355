import React, { CSSProperties, memo, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Avatar } from '@mui/material';
import styled, { css } from 'styled-components';
import { useStore } from 'stores/StoreHelper';

type AvatarSizeProps = 'default' | 'tiny' | 'small' | 'medium' | 'large' | 'full';

type AvatarProps = {
  size?: AvatarSizeProps;
  style?: CSSProperties;
};

const MyAvatar = observer(({ size = 'default' }: AvatarProps) => {
  const { myChannelStore } = useStore();

  const getStyle = () => {
    switch (size) {
      case 'default':
        return { width: 50, height: 50 };
      case 'tiny':
        return { width: 50, height: 50 };
      case 'small':
        return { width: 50, height: 50 };
      case 'medium':
        return { width: 50, height: 50 };
      case 'large':
        return { width: 50, height: 50 };
      default:
        return { width: 50, height: 50 };
    }
  };

  return (
    <>
      <Avatar
        src={`${myChannelStore.currentChannel?.profile?.profileImage?.publicUrl}`}
        style={getStyle()}
      />
    </>
  );
});

export default MyAvatar;
