/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AdCategoryDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class AdCategory<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 광고 카테고리 조회
   *
   * @tags ad category
   * @name GetAdCategory
   * @summary 광고 카테고리 조회
   * @request GET:/v2/ad/categories/{adCategoryId}
   * @secure
   */
  getAdCategory = (adCategoryId: number, params: RequestParams = {}) =>
    this.http.request<AdCategoryDto, any>({
      path: `/v2/ad/categories/${adCategoryId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 광고 카테고리 수정
   *
   * @tags ad category
   * @name UpdateAdCategory
   * @summary 광고 카테고리 수정
   * @request PUT:/v2/ad/categories/{adCategoryId}
   * @secure
   */
  updateAdCategory = (adCategoryId: number, data: AdCategoryDto, params: RequestParams = {}) =>
    this.http.request<AdCategoryDto, any>({
      path: `/v2/ad/categories/${adCategoryId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 광고 카테고리 삭제
   *
   * @tags ad category
   * @name DeleteAdCategory
   * @summary 광고 카테고리 삭제
   * @request DELETE:/v2/ad/categories/{adCategoryId}
   * @secure
   */
  deleteAdCategory = (adCategoryId: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/ad/categories/${adCategoryId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 광고 카테고리 목록 조회
   *
   * @tags ad category
   * @name GetAdCategories
   * @summary 광고 카테고리 목록 조회
   * @request GET:/v2/ad/categories
   * @secure
   */
  getAdCategories = (params: RequestParams = {}) =>
    this.http.request<AdCategoryDto[], any>({
      path: `/v2/ad/categories`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 광고 카테고리 등록
   *
   * @tags ad category
   * @name CreateAdCategory
   * @summary 광고 카테고리 등록
   * @request POST:/v2/ad/categories
   * @secure
   */
  createAdCategory = (data: AdCategoryDto, params: RequestParams = {}) =>
    this.http.request<AdCategoryDto, any>({
      path: `/v2/ad/categories`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
