import React, { CSSProperties, memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row } from 'components/commons/layouts';
import { IFooterBlock } from 'types/BlockExtendsTypes';
import { Avatar } from '@mui/material';

const FooterTemplate = ({
  item,
  boxStyle,
  textStyle,
  editMode = true,
}: {
  item: IFooterBlock;
  boxStyle?: CSSProperties;
  textStyle?: CSSProperties;
  editMode?: boolean;
}) => {
  const [style, setStyle] = useState<CSSProperties>();

  const Top = () => {
    return (
      <>
        {item.thumbnail?.publicUrl && (
          <Avatar
            src={item.thumbnail?.publicUrl}
            style={{ width: 80, height: 80, margin: 'auto' }}
          />
        )}
        <AlignStyle align={item?.contentAlign || 'center'}>
          {item.title && <div style={{ ...textStyle }}>{item.title}</div>}
          {item.content && (
            <div style={{ whiteSpace: 'pre-wrap', marginBottom: 20 }}>{item.content}</div>
          )}
        </AlignStyle>
      </>
    );
  };

  const Bottom = () => {
    return (
      <>
        <AlignStyle align={item?.contentAlign || 'center'}>
          {item.title && <div style={{ ...textStyle }}>{item.title}</div>}
          {item.content && (
            <div style={{ whiteSpace: 'pre-wrap', marginBottom: 20 }}>{item.content}</div>
          )}
        </AlignStyle>
        {item.thumbnail?.publicUrl && (
          <Avatar
            src={item.thumbnail?.publicUrl}
            style={{ width: 80, height: 80, margin: 'auto' }}
          />
        )}
      </>
    );
  };

  const Left = () => {
    return (
      <Row style={{ justifyContent: 'space-between' }}>
        <>
          {item.thumbnail?.publicUrl && (
            <div style={{ flex: 1, textAlign: 'center' }}>
              <Avatar
                src={item.thumbnail?.publicUrl}
                style={{ width: 80, height: 80, margin: 'auto' }}
              />
            </div>
          )}
        </>
        <AlignStyle style={{ flex: 1 }} align={item?.contentAlign || 'center'}>
          {item.title && <div style={{ ...textStyle }}>{item.title}</div>}
          {item.content && (
            <div style={{ whiteSpace: 'pre-wrap', marginBottom: 20 }}>{item.content}</div>
          )}
        </AlignStyle>
      </Row>
    );
  };

  const Right = () => {
    return (
      <Row style={{ justifyContent: 'space-between' }}>
        <AlignStyle style={{ flex: 1 }} align={item?.contentAlign || 'center'}>
          {item.title && <div style={{ ...textStyle }}>{item.title}</div>}
          {item.content && (
            <div style={{ whiteSpace: 'pre-wrap', marginBottom: 20 }}>{item.content}</div>
          )}
        </AlignStyle>
        <>
          {item.thumbnail?.publicUrl && (
            <div style={{ flex: 1, textAlign: 'center' }}>
              <Avatar
                src={item.thumbnail?.publicUrl}
                style={{ width: 80, height: 80, margin: 'auto' }}
              />
            </div>
          )}
        </>
      </Row>
    );
  };

  const getComponent = () => {
    switch (item.layout) {
      case 'top':
        return <Top />;
      case 'bottom':
        return <Bottom />;
      case 'left':
        return <Left />;
      case 'right':
        return <Right />;
    }
  };
  useEffect(() => {
    if (item.contentStyle) {
      setStyle(JSON.parse(item.contentStyle as string));
    }
  }, [item.contentStyle]);

  return (
    <ContainerStyle item={item}>
      <div
        onClick={() => item.linkUrl ?? window.open(item.linkUrl)}
        style={{ padding: 20, color: style?.color }}
      >
        {getComponent()}
      </div>
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div<{ item: IFooterBlock }>`
  border-radius: 10px;
  width: 100%;
  cursor: ${(props) => (props.item.linkUrl ? 'pointer' : 'default')};
`;

const AlignStyle = styled.div<{ align: string }>`
  text-align: ${(props) => props.align};
`;
export default memo(FooterTemplate);
