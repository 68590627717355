import { useStore } from 'stores/StoreHelper';
import { useState } from 'react';
import useLocalParams from 'libs/hooks/useLocalParams';
import LinkStore from 'stores/LinkStore';
import PostStore from 'stores/PostStore';
import MyLinkStore from 'stores/MyLinkStore';
import { ChannelBoardDto, LinkDto, PostDto } from 'services/data-contracts';
import { useRootContext } from 'libs/hooks/useRootContext';

/**
 * @description 각 Block의 등록/수정/삭제를 수행함.
 * @returns
 */
const LinkController = () => {
  const { xhrStore } = useStore();
  const { channelName } = useLocalParams();
  const { myChannel } = useRootContext();

  const linkStore = new LinkStore();
  const myLinkStore = new MyLinkStore();
  const postStore = new PostStore();

  const [posts, setPosts] = useState<PostDto[]>([]);
  const [links, setLinks] = useState<LinkDto[]>();
  /**
   * 선택된 보드정보로 링크나 포스트를 취득한다.
   */
  const getLinks = async (board: ChannelBoardDto) => {
    xhrStore.setState('pending');
    setPosts([]);
    setLinks(undefined);
    if (board) {
      if (channelName) {
        const links = (await linkStore.getLinks(
          channelName,
          board.id as number,
        )) as never as LinkDto[];
        // const sorted = getLinks(links);
        xhrStore.setState('done');
        return links;
      }
    }
  };

  const getClosedDeals = async () => {
    xhrStore.setState('pending');
    setPosts([]);
    setLinks(undefined);
    if (channelName) {
      const links = (await linkStore.getEndedDealList(channelName)) as never as LinkDto[];
      xhrStore.setState('done');
      return links;
    }
  };

  const getPosts = async (board: ChannelBoardDto) => {
    xhrStore.setState('pending');
    setPosts([]);
    if (board) {
      if (channelName) {
        const posts = (await postStore.getPosts(channelName || '', board.id as number, true, {
          ownChannelId: myChannel?.id || '',
          size: board.viewType !== 'NONE' ? 30 : 10,
        })) as never as PostDto[];
        xhrStore.setState('done');
        return posts;
      }
    }
  };

  const getMyLinks = async (categoryId: number) => {
    const response = (await myLinkStore.getItems(
      myChannel?.id as number,
      categoryId as number,
    )) as never as LinkDto[];
    return response;
  };

  const getLinkByKey = async (linkKey: string) => {
    return (await linkStore.getItemByKey(linkKey)) as unknown as LinkDto;
  };

  const getDealMembers = async (linkKey: string) => {
    return (await linkStore.getDealMembers(linkKey)) as unknown as LinkDto;
  };

  // const getLinks = (data: IProfileBlock[]) => {
  //   const sortedData = data
  //     .map((item) => {
  //       let parsedMetadata = {};
  //       if (item.metadata) {
  //         try {
  //           parsedMetadata = JSON.parse(item.metadata);
  //         } catch (error) {
  //           console.error('JSON parsing error: ', error);
  //         }
  //       }

  //       return { ...item, ...parsedMetadata };
  //     })
  //     .sort((a, b) => {
  //       const lockPositionA = !!a.lockPosition;
  //       const lockPositionB = !!b.lockPosition;

  //       if (lockPositionA && !lockPositionB) {
  //         return -1;
  //       } else if (!lockPositionA && lockPositionB) {
  //         return 1;
  //       } else {
  //         return 0;
  //       }
  //     });

  //   return sortedData;
  // };
  return {
    getLinks,
    getPosts,
    getMyLinks,
    getClosedDeals,
    getLinkByKey,
    getDealMembers,
  };
};

export default LinkController;
