import React, { useState, useEffect } from 'react';
import { ItemSectionStyle } from 'resources/controls/ItemSectionStyle';
import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import { ICategoryTab, IChannelProfile } from 'types/BlockExtendsTypes';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { ProfileTypes } from 'features/management/context/providers/ContextTypes';
import ProfileController from 'controllers/ProfileController';
import ActionBox from 'features/csTown/components/pc/ActionBox';

const CategoryTab = ({ blockModel }: { blockModel?: IChannelProfile }) => {
  const { uiStore } = useStore();
  const { initialModel, updateServiceModel, serviceModel, initialBinding } =
    useOhmossContext<ProfileTypes<IChannelProfile>>();

  const { updateProfile } = ProfileController();

  const [mergedModel, setMergedModel] = useState<IChannelProfile>();

  useInitializeModel<Partial<IChannelProfile>>(mergedModel, undefined, initialBinding);

  const initializeModel = () => {};

  useEffect(() => {
    initializeModel();
    return () => {};
  }, [blockModel]);

  return (
    <div style={{ width: '100%' }}>
      <div style={{ width: '100%', padding: 40 }}>
        <ItemSectionStyle>
          <MenuItemCheckBox
            onChangeValue={(value: boolean) => {
              const newCategoryInfo: ICategoryTab = {
                ...serviceModel.categoryTabInfo,
                useTransparent: value,
              };
              updateServiceModel('categoryTabInfo', newCategoryInfo);
            }}
            initialValue={initialModel?.categoryTabInfo?.useTransparent}
            title="투명 배경 사용"
          />
        </ItemSectionStyle>
        <ActionBox
          style={{ marginTop: 60, paddingBottom: 20 }}
          isValid={true}
          okText="저장"
          onOk={async () => {
            await updateProfile(serviceModel);
            uiStore.universalModal.confirm(serviceModel);
          }}
          onCancel={() => {
            uiStore.universalModal.close();
          }}
        />
      </div>
    </div>
  );
};

export default CategoryTab;
