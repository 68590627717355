import React, { useEffect, useState } from 'react';

import { useStore } from 'stores/StoreHelper';
import { ChannelBoardDto, PostDto } from 'services/data-contracts';
import { observer } from 'mobx-react-lite';
import { OhmossButton } from 'resources/controls/OhmossButton';

import styled from 'styled-components';
import EmptyPage from 'pages/EmptyPage';
import MyLinkStore from 'stores/MyLinkStore';
import useLocalParams from 'libs/hooks/useLocalParams';
import BlockController from 'controllers/BlockController';
import MyShopItemCreator from '../components/block/MyShopItem';
import MyShopController from 'controllers/MyShopController';
import CardDragDrop from 'components/commons/CardDragDrop';
import { IChannelBoardModel } from 'types/BlockExtendsTypes';
import ContextMenu from '../components/block/ContextMenu';
import { useRootContext } from 'libs/hooks/useRootContext';
import ManagementInnerLayout from '../components/common/ManagementInnerLayout';
import { Row } from 'components/commons/layouts';
import InfoBox from 'components/commons/InfoBox';

const MyShopDetailPage = observer(() => {
  const myLinkStore = new MyLinkStore();
  const { getPosts, getShopBoard, reOrderPostItems } = MyShopController();
  const { categoryId } = useLocalParams();
  const { myChannel } = useRootContext();
  const { uiStore, xhrStore } = useStore();

  const [shopItems, setShopItems] = useState<PostDto[]>([]);
  const [shopBoard, setShopBoard] = useState<IChannelBoardModel>();
  const [layout, setLayout] = useState<number>();

  const handleClickCreate = (post?: PostDto | undefined) => {
    uiStore.modal.show({
      style: { maxHeight: '80vh' },
      title: '새 상품',
      children: <MyShopItemCreator item={post} />,
      onConfirmed: () => {
        fetchData();
      },
    });
  };

  const handleReorder = async (post: PostDto) => {
    if (categoryId) {
      reOrderPostItems(categoryId, post);
    }
  };

  const handleDeleteBlock = (item: ChannelBoardDto) => {
    uiStore.bottomSheet.close();
    uiStore.confirm.show({
      message: '선택하신 블록을 삭제하시겠습니까? ',
      onConfirmed: async () => {
        const result = await myLinkStore.deleteItem(
          myChannel?.id as number,
          categoryId as number,
          item.id as number,
        );
        if (result) {
          fetchData();
        }
      },
    });
  };

  const handleChangeActive = (item: ChannelBoardDto) => {
    uiStore.bottomSheet.close();
    uiStore.confirm.show({
      title: item.isActive ? '비공개 전환' : '공개 전환',
      message: item.isActive
        ? '비공개로 전환되면 다른 사람들은 볼 수 없게됩니다.'
        : '공개로 전환되면 다른 사람들도 볼 수 있습니다.',
      onConfirmed: async () => {
        const newBoard: IChannelBoardModel = { ...item, isActive: !item.isActive };
        // const result = await myBoardStore.updateBoard(myChannel?.id as number, newBoard);
        // if (result) {
        //   fetchData();
        // }
      },
    });
  };

  const handleClickShowMore = (item: ChannelBoardDto) => {
    uiStore.bottomSheet.show({
      children: (
        <ContextMenu
          isActive={item.isActive}
          onDelete={() => {
            handleDeleteBlock(item);
          }}
          onRename={() => {}}
          onInactive={() => {
            handleChangeActive(item);
          }}
        />
      ),
    });
  };

  const fetchData = async () => {
    const response = (await getPosts(categoryId as number)) as PostDto[];
    setShopItems(response);
  };

  const getCategory = async () => {
    if (categoryId && myChannel) {
      const categoryBoard = (await getShopBoard(categoryId)) as IChannelBoardModel;
      setShopBoard(categoryBoard);
      setLayout(categoryBoard?.layout);
    }
  };

  useEffect(() => {
    if (xhrStore.state === 'done') {
      uiStore.modal.close();
    }
  }, [xhrStore.state]);

  useEffect(() => {
    if (myChannel && categoryId) {
      fetchData();
      getCategory();
    }
  }, [myChannel, categoryId]);

  return (
    <>
      <ManagementInnerLayout>
        <div style={{ flexGrow: 1 }}>
          <>
            <InfoBox style={{ margin: 10 }}>Drag and Drop으로 위치를 변경할 수 있습니다 :D</InfoBox>
            {shopItems?.length > 0 ? (
              <div style={{ padding: 10 }}>
                <CardDragDrop
                  onChangeItemSeq={handleReorder}
                  items={shopItems}
                  itemsPerRow={layout}
                  style={{
                    width: '100%',
                    ...shopBoard?.boxStyle,
                  }}
                  thumbnailStyle={{
                    backgroundColor: shopBoard?.thumbnailBackgroundColorRgba,
                    color: shopBoard?.thumbnailColorRgba,
                  }}
                  onClick={(item: PostDto) => {
                    handleClickCreate(item);
                  }}
                />
              </div>
            ) : (
              <EmptyPage content="등록된 상품이 없습니다." />
            )}
          </>
        </div>
        <div style={{ padding: 20 }}>
          <OhmossButton
            onClick={() => {
              handleClickCreate();
            }}
          >
            상품올리기
          </OhmossButton>
        </div>
      </ManagementInnerLayout>
    </>
  );
});

export default MyShopDetailPage;
