import TextLabel from 'components/commons/labels/TextLabel';
import { Row } from 'components/commons/layouts';
import React, { useEffect, useRef, useState } from 'react';
import { OhmossButton } from 'resources/controls/OhmossButton';
import styled, { keyframes, css } from 'styled-components';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router-dom';
import MobileModalHeader from 'components/commons/MobileModalHeader';
import UniversalLayout from 'components/commons/layouts/UniversalLayout';
import { toast } from 'react-toastify';

const Agreement = () => {
  const [progress, setProgress] = React.useState(5);
  const [timer, setTimer] = useState<any>(null);
  const [isPaused, setIsPaused] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  let count = 0;
  let interval: any = null;
  useEffect(() => {
    setTimer(
      (interval = setInterval(() => {
        count += 1;

        if (count >= 10) {
          clearInterval(timer);
          setTimer(null);
          handleClickGo();
        }

        setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
      }, 500)),
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
      </Box>
    );
  }

  const handleClickGo = () => {
    setIsPaused(false);

    const url = searchParams.get('url');
    if (url) {
      if (url.includes('http')) {
        window.location.href = url;
      } else {
        window.location.href = `http://${url}`;
      }
    } else {
      toast('이동할 주소가 바르지 않습니다.', { type: 'warning' });
      clearInterval(interval);
    }
  };

  const handleClickClose = () => {
    window.self.close();
  };

  return (
    <UniversalLayout>
      <MobileModalHeader type="modal" title="구매링크로 이동중" onCloseClick={handleClickClose} />
      <div style={{ padding: 40, paddingBottom: 0, width: '100%' }}>
        <div style={{ height: 100 }}>
          <GifImgStyle
            isPaused={isPaused}
            src="./images/ohmossrun.gif"
            alt=""
            style={{ width: 100, margin: 'auto' }}
          />
        </div>
        <LinearProgressWithLabel value={progress} />
        <Row style={{ padding: 20, gap: 5 }}>
          <OhmossButton
            type="box"
            style={{ color: '#bebebe', paddingLeft: 20, paddingRight: 20 }}
            onClick={() => {
              setIsPaused(true);
              clearInterval(timer);
              setTimer(null);
            }}
          >
            화면정지
          </OhmossButton>
          <OhmossButton onClick={handleClickGo} style={{ paddingLeft: 20, paddingRight: 20 }}>
            화면이동
          </OhmossButton>
        </Row>
      </div>

      <LinkSeparator />
      <div style={{ padding: 40, paddingTop: 10 }}>
        <BlockStyle>
          <TextLabel type="subject">잠깐! 오모스에서 알려드립니다.</TextLabel>
          <ol>
            <li>
              오모스를 운영하는 (주)엔비에이치디는 <StrongStyle>정보 중개자</StrongStyle>이며, 상품
              판매자가 아닙니다.
            </li>
            <li>
              쇼핑몰로 이동 후에{' '}
              <StrongStyle>
                상품 정보, 주문 배송, 환불은 해당 서비스 사이트에서 관리합니다.
              </StrongStyle>
            </li>
            <li>
              쇼핑몰로 이동 후{' '}
              <StrongStyle>
                {' '}
                상품 정보와 가격이 일치하는지 반드시 확인 해 주시기 바랍니다.
              </StrongStyle>
            </li>
          </ol>
        </BlockStyle>
        <BlockStyle2>
          <TextLabel type="subject">자체 보호 쇼핑몰</TextLabel>
          <ul>
            <li>
              자체보호는 해당 쇼핑몰 자체적으로 적용된 매매보호를 통칭하며, 쇼핑몰마다 그 형태가
              다르므로, <b>구매 시 안전거래(에스크로, 전자보증 등)를 통해 구매하시기 바랍니다.</b>
            </li>
            <li>
              안전거래를 이용하지 않고 결제하는 경우,{' '}
              <b>상품을 수령하지 못하는 등 고객에게 피해가 발생할 수 있습니다.</b>
            </li>
            <li>
              주문 과정에서 <b>판매자가 현금 결제 할인을 이유로 타계좌 입금을 유도</b>하는 경우,
              <b>오모스 고객센터에 신고</b>해주시기 바랍니다.
            </li>
          </ul>
        </BlockStyle2>
      </div>
    </UniversalLayout>
  );
};

const BlockStyle = styled.div`
  > div {
    margin: 20px 0;
    font-size: 15px;
    font-weight: 700;
  }
  > ol > li {
    list-style: decimal;
  }
`;

const BlockStyle2 = styled.div`
  > div {
    margin: 20px 0;
    font-weight: 700;
  }
  > ul > li {
    list-style: disc;
    font-size: 16px;
  }
`;

const LinkSeparator = styled.div`
  border-top: 1px solid #bebebe;
`;

const StrongStyle = styled.div`
  color: #638dff;
  display: inline;
`;

const runLogo = keyframes`
  0%{
    	left:0px;
    }
    100%{
        left:100%;    
    }
`;

const GifImgStyle = styled.img<{ isPaused?: boolean }>`
  position: absolute;
  animation: ${(props) =>
    props.isPaused
      ? 'none'
      : css`
          ${runLogo} 3.5s linear infinite
        `};
`;

export default Agreement;
