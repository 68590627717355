import React, { useEffect, useRef, useState } from 'react';
import { MemberDto, PartnerDto } from 'services/data-contracts';

import { isMobile } from 'react-device-detect';

import { useRootContext } from 'libs/hooks/useRootContext';
import PartnerController from 'controllers/PartnerController';
import * as Containers from '../containers';

const ManagerListPage = () => {
  const { getPartners, getPartnerInvitations } = PartnerController();

  const { myChannel } = useRootContext();

  const originPartners = useRef<PartnerDto[]>([]);

  const [partnersList, setPartnersList] = useState<PartnerDto[]>([]);
  const [invitationList, setInvitationList] = useState<MemberDto[]>([]);

  const getPartnersList = async () => {
    const result = await getPartners();
    if (result) {
      setPartnersList(result);
      originPartners.current = result;
    }
  };

  const getInvitationsResult = async () => {
    const result = (await getPartnerInvitations()) as unknown as MemberDto[];
    if (result) {
      setInvitationList(result);
    }
  };

  const handleSearch = (value: string) => {
    if (value === '') {
      setPartnersList(originPartners.current);
    } else {
      setPartnersList(
        originPartners.current.filter(
          (partner) => partner.partnerChannel?.channelName?.includes(value),
        ),
      );
    }
  };

  useEffect(() => {
    if (myChannel) {
      getPartnersList();
      getInvitationsResult();
    }
  }, [myChannel]);

  return (
    <>
      {isMobile ? (
        <Containers.MobileContainers.MobileCstownContainer />
      ) : (
        <Containers.PCContainers.ManagerListContainer />
      )}
    </>
  );
};

export default ManagerListPage;
