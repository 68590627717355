import React, { useRef, useState, useEffect, CSSProperties } from 'react';
import {
  InternalLinkCard,
  CustomContent,
  defaultLinkEditorProps,
  CustomFile,
  CardLinkModel,
  LinkModel,
} from 'types/CommonTypes';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { TextToolBox, ImageUploader } from 'components/commons';
import { updateLink, updateModel } from 'components/items/commonLogic';
import { cloneDeep, isJsonObject, setDefaultValue } from 'libs/helper/utils';
import { useTranslation } from 'react-i18next';
import { ToggleButtonGroup, ToggleButton as MuiToggle, Button } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { validationHelper as VH } from 'controls/validationHelper';
import { Row } from 'components/commons/layouts';
import { DottedBoxStyle } from 'resources/controls';

// import { GridSelect } from 'react-grid-select';
import GridSelector from 'components/commons/GridSelector';
import styled, { css } from 'styled-components';
import VideoEditor from 'features/adminFeatures/commons/components/VideoEditor';
import LinkEditorContainerStyle from 'resources/controls/LinkEditorConatainerStyle';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import LineBreakStyle from 'resources/controls/LineBreakStyle';
import SaveButtonWrapper from './SaveButtonWrapper';
import TitleForm from './forms/TitleForm';
import UrlForm from './forms/UrlForm';
import CardDragDrop from 'components/commons/CardDragDrop';
import { OhmossButton } from 'resources/controls/OhmossButton';

const CardLinkEditor = ({
  model,
  stores,
  onRegistComplete,
}: defaultLinkEditorProps<CardLinkModel>) => {
  const intDto = (reset?: boolean): CardLinkModel => {
    if (reset) {
      return {
        title: '',
        channel: {},
        seq: 0,
        linkType: 'CardLink',
      };
    } else {
      return {
        ...model,
        linkType: 'CardLink',
        title: setDefaultValue(model?.title, ''),
        channel: setDefaultValue(model?.channel, {}),
        seq: setDefaultValue(model?.seq, 0),
        isActive: true,
      };
    }
  };

  const { t } = useTranslation();
  const titleRef = useRef<HTMLInputElement>(null);

  const [cardItems, setCardItems] = useState<Array<InternalLinkCard>>([]);
  const [linkModel, setLinkModel] = useState<CardLinkModel>(intDto());
  const [renderType, setRenderType] = useState<'slide' | 'grid' | 'single' | undefined>('slide');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [swiper, setSwiper] = useState<any>(null);
  const [selectedArea, setSelectedArea] = useState<number | undefined>();
  const [itemsPerRow, setItemsPerRow] = useState<number | undefined>();
  const [showGridSelector, setShowGridSelector] = useState<boolean>(false);

  const handleSaveLink = async () => {
    stores.uiStore.progress.show({});
    await preProcess(linkModel).then((response: any) => {});
    const result = await updateLink(linkModel!, typeof linkModel?.id !== 'undefined', {
      ...stores,
    });
    stores.uiStore.progress.close();
    onRegistComplete(true, result);
  };

  const preProcess = async (model: LinkModel) => {
    return Promise.all(
      (await model?.items?.map(async (item: InternalLinkCard, index: number) => {
        if (typeof item?.metadata !== 'undefined') {
          const newType = {
            customContent: item?.metadataObject,
            sizeInfo: item?.file?.sizeInfo,
          };
          item.content = JSON.stringify(newType);
          item.isActive = true;
        }
        if (item?.file?.file) {
          const data: any = await stores.fileStore.uploadImage(item?.file.file, 'Link');
          item.file = { id: data.id };
        }
        item.isActive = true;
        item.seq = index + 1;
        return item;
      })) as unknown as InternalLinkCard[],
    );
  };

  const handleDeleteCard = (id: number) => {
    stores.uiStore.confirm.show({
      message: '아이템을 삭제하시겠습니까?',
      onConfirmed: () => {
        const newCards = cardItems.filter((card, index) => {
          return index !== id;
        });
        setCardItems(newCards);
      },
    });
  };

  // 멀티플 업로드 대응
  const handleBindMultipleImage = (images: Array<any>) => {
    let newCards = [...cardItems];

    let _seq =
      cardItems.length === 0
        ? 0
        : typeof cardItems[cardItems.length - 1] === 'undefined'
        ? 0
        : cardItems[cardItems.length - 1].seq!;
    images.map((image: CustomFile, index: number) => {
      const _file: any = image.file;
      _file.publicUrl = image.publicUrl;
      newCards.push({
        seq: ++_seq,
        file: image as CustomFile,
        metadataObject: cloneDeep(defalutCardCustomProps),
      });
      setCardItems([...newCards]);
    });
    swiper.slideTo(newCards.length - 1);
  };

  const checkValidation = () => {
    const result = VH.required(linkModel?.title) && cardItems.length > 0;
    setIsValid(result);
  };

  // 부모로부터 전달받은 모델 할당
  useEffect(() => {
    const newModel: CardLinkModel = { ...(model as CardLinkModel) };

    if (typeof model.id !== 'undefined') {
      if (typeof newModel.metadata !== 'undefined' && isJsonObject(model.metadata)) {
        newModel.metadataObject = JSON.parse(newModel.metadata);
        setRenderType(newModel.metadataObject?.renderType || 'slide');
        setSelectedArea(newModel.metadataObject?.renderItemsPerRow || 1);
        setItemsPerRow(newModel.metadataObject?.renderItemsPerRow || 1);
      }

      setLinkModel(newModel);
      if (typeof newModel?.items !== 'undefined') {
        const _cards = cloneDeep(newModel?.items);
        _cards.map((card: InternalLinkCard) => {
          if (card.metadata) {
            card.metadataObject = cloneDeep(defalutCardCustomProps);
            const middleObject = JSON.parse(card.metadata);

            // 과거 데이터때문에 우선 처리
            if (typeof middleObject.customContent === 'undefined') {
              Object.assign(card.metadataObject!, middleObject);
            } else {
              Object.assign(card.metadataObject!, middleObject.customContent);
            }
            if (card.file) {
              card.file.sizeInfo = middleObject.sizeInfo;
            }
          }
          return card;
        });
        setCardItems(_cards);
      }
    } else {
      setRenderType('slide');
      setLinkModel((prev: CardLinkModel) => {
        return {
          ...prev,
          linkType: 'CardLink',
          metadataObject: { renderType: 'slide', cardLinkType: 'cardItem' },
        };
      });
    }
  }, [model]);

  useEffect(() => {
    updateModel(setLinkModel, 'items', cardItems);
  }, [cardItems]);

  useEffect(() => {
    if (typeof linkModel !== 'undefined') {
      checkValidation();
    }
  }, [linkModel]);
  return (
    <>
      <LinkEditorContainerStyle style={{ marginTop: 0 }}>
        <SaveButtonWrapper
          isValid={isValid}
          onSave={handleSaveLink}
          isNew={typeof linkModel?.id === 'undefined'}
        >
          <div style={{ marginBottom: 20 }}>
            <TitleForm
              ref={titleRef}
              model={linkModel?.title}
              onChangeValue={(value: string) => {
                setLinkModel((prev: any) => {
                  return {
                    ...prev,
                    title: value,
                  };
                });
              }}
            />
            {typeof cardItems !== 'undefined' && (
              <>
                <Row
                  style={{
                    justifyContent: cardItems.length === 1 ? 'flex-end' : 'space-between',
                    paddingTop: 10,
                  }}
                >
                  <>
                    {cardItems.length > 1 && (
                      <ToggleButtonGroup
                        color="primary"
                        value={renderType}
                        exclusive
                        onChange={(e: any, value: any) => {
                          if (value === null) {
                            return;
                          }
                          updateModel(setLinkModel, 'metadataObject', {
                            renderType: value,
                          });
                          setRenderType(value);
                          setLinkModel((prev: any) => {
                            return {
                              ...prev,
                              metadataObject: { ...prev.metadataObject, renderType: value },
                            };
                          });
                        }}
                        aria-label="Platform"
                      >
                        <MuiToggle
                          value="slide"
                          sx={{
                            '&.MuiToggleButton-root': {
                              borderTopLeftRadius: 10,
                              borderBottomLeftRadius: 10,
                              padding: '0 20px',
                              height: 40,
                            },
                            '&.Mui-selected': {
                              color: '#11C668',
                              background: '#F8FFFB',
                            },
                          }}
                        >
                          넘기면서 표시
                        </MuiToggle>
                        <MuiToggle
                          value="grid"
                          sx={{
                            '&.MuiToggleButton-root': {
                              borderTopRightRadius: 10,
                              borderBottomRightRadius: 10,
                              padding: '0 20px',
                              height: 40,
                            },
                            '&.Mui-selected': {
                              color: '#11C668',
                              background: '#F8FFFB',
                            },
                          }}
                        >
                          바둑판으로 표시
                        </MuiToggle>
                      </ToggleButtonGroup>
                    )}
                    {cardItems.length > 1 && (
                      <div>
                        <OhmossButton
                          type="primary"
                          style={{ padding: '3px 10px' }}
                          onClick={() => {
                            setShowGridSelector((prev) => !prev);
                          }}
                        >
                          {showGridSelector === true ? '돌아가기' : '이미지 순서변경'}
                        </OhmossButton>
                      </div>
                    )}
                  </>
                </Row>
                {cardItems.length > 1 && renderType === 'grid' && (
                  <>
                    <GridSelectorStyle>
                      <div>한줄에 표시될 이미지 갯수를 선택 해주세요.</div>
                      <GridSelector
                        selectedNumber={selectedArea}
                        onUpdate={(selected: number) => {
                          setItemsPerRow(selected);
                          setLinkModel((prev: any) => {
                            return {
                              ...prev,
                              metadataObject: {
                                ...prev.metadataObject,
                                renderItemsPerRow: selected,
                              },
                            };
                          });
                        }}
                      />
                    </GridSelectorStyle>
                  </>
                )}

                <CardContainerStyle>
                  {cardItems.length > 0 ? (
                    <>
                      {showGridSelector === false ? (
                        <Swiper
                          centeredSlides
                          spaceBetween={10}
                          slidesPerView={1.1}
                          grabCursor
                          enabled
                          style={{ maxWidth: 500 }}
                          onSwiper={setSwiper}
                        >
                          {cardItems.map((card: InternalLinkCard, index: number) => (
                            <SwiperSlide>
                              <div style={{ width: '100%' }}>
                                <CardCanvasContainerStyle
                                  style={card?.metadataObject?.backgroundStyle}
                                  useSizeFree={cardItems.length === 1}
                                >
                                  {typeof card?.file !== 'undefined' && (
                                    <>
                                      {card?.file?.contentType?.indexOf('image/') === 0 ? (
                                        <>
                                          {typeof card?.file?.publicUrl !== 'undefined' && (
                                            <BackgroundImageStyle
                                              src={card?.file?.publicUrl}
                                              useSizeFree={cardItems.length === 1}
                                            />
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <VideoEditor
                                            // isNew={false}
                                            // maxHeight="100%"
                                            mode="view"
                                            video={card?.file}
                                            isCover={card.file?.sizeInfo?.isCover}
                                          />
                                        </>
                                      )}
                                    </>
                                  )}
                                  <CardCanvasTextContainerStyle>
                                    <CardCanvasTextContentStyle
                                      style={{
                                        ...card?.metadataObject?.descriptionContents?.style,
                                        whiteSpace: 'pre-line',
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: card?.metadataObject?.descriptionContents
                                          ?.text as string,
                                      }}
                                    />
                                  </CardCanvasTextContainerStyle>

                                  <DeleteButtonSyle
                                    onClick={() => {
                                      handleDeleteCard(index);
                                    }}
                                  >
                                    <DeleteOutlineIcon />
                                  </DeleteButtonSyle>
                                </CardCanvasContainerStyle>
                                <CardEditorContainerStyle>
                                  <div style={{ marginTop: 20 }}>
                                    <LineBreakStyle />
                                    <UrlForm
                                      model={card.linkUrl}
                                      onChangeValue={(value: string) => {
                                        card.linkUrl = value;
                                        setCardItems([...cardItems]);
                                      }}
                                    />
                                  </div>
                                </CardEditorContainerStyle>
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      ) : (
                        <></>
                        // <CardDragDrop
                        //   onChangeItemSeq={setCardItems}
                        //   items={cardItems}
                        //   itemsPerRow={renderType === 'grid' ? itemsPerRow : 1}
                        // />
                      )}
                    </>
                  ) : (
                    <ImageUploaderContainerStyle>
                      <ImageUploader
                        type="multiple"
                        cropSize={{ width: 1200, height: 1200 }}
                        attachType="media"
                        useCrop={false}
                        multipleUpload={true}
                        onSelected={(thumbnails: any) => {
                          handleBindMultipleImage(thumbnails);
                        }}
                      >
                        <DottedBoxStyle width="100%" height="140px">
                          <div style={{ textAlign: 'center', margin: 'auto', padding: 20 }}>
                            <CameraAltIcon sx={{ width: 30, height: 30 }} />
                            <div>{t('Multi upload')}</div>
                          </div>
                        </DottedBoxStyle>
                      </ImageUploader>
                    </ImageUploaderContainerStyle>
                  )}
                </CardContainerStyle>
                {cardItems.length > 0 && (
                  <AddImageStyle>
                    <Row>
                      <ImageUploader
                        type="multiple"
                        cropSize={{ width: 1200, height: 1200 }}
                        attachType="media"
                        useCrop={false}
                        multipleUpload
                        onSelected={(thumbnails: any) => {
                          handleBindMultipleImage(thumbnails);
                        }}
                      >
                        <Button
                          variant="outlined"
                          sx={{ width: 200, height: 50, borderRadius: 30 }}
                          startIcon={<CameraAltIcon />}
                        >
                          {t('Multi upload')}
                        </Button>
                      </ImageUploader>
                    </Row>
                  </AddImageStyle>
                )}
              </>
            )}
          </div>
        </SaveButtonWrapper>
      </LinkEditorContainerStyle>
    </>
  );
};

const ImageUploaderContainerStyle = styled.div`
  aspect-ratio: 16 / 9;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const defalutCardCustomProps: CustomContent = {
  titleContents: {
    text: undefined,
    style: {},
    use: false,
  },
  descriptionContents: {
    text: '',
    use: false,
    style: { fontSize: 16, color: '#000000' },
  },
  isModified: false,
};

const AddImageStyle = styled.div`
  margin-top: 20px;
  left: 20px;
`;

const DeleteButtonSyle = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
`;

const CardContainerStyle = styled.div`
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
  border: 1px solid #dddddd;
`;

const CardCanvasContainerStyle = styled.div<{ useSizeFree?: boolean }>`
  width: 100%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  margin: 20px auto 10px auto;
  aspect-ratio: 1 / 1;
  ${(props) =>
    props.useSizeFree &&
    css`
      aspect-ratio: initial;
    `}
`;

const CardCanvasTextContainerStyle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
`;

const CardCanvasTextContentStyle = styled.div`
  text-align: center;
  vertical-align: middle;
  word-break: break-all;
  padding: 5px 5px 5px 5px;
  border-radius: 5px;
`;

const BackgroundImageStyle = styled.img<{ useSizeFree?: boolean; url?: string }>`
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;

  ${(props) =>
    props.useSizeFree
      ? css`
          width: 100%;
          height: auto;
          object-fit: cover;
          src: src;
        `
      : css`
          background-image: url(src);
        `};
`;

const CardEditorContainerStyle = styled.div`
  width: 100%;
  padding-bottom: 10px;
`;

const GridSelectorStyle = styled.div`
  margin: 10px 0;
  padding: 5px;
  > div:last-child {
    margin: 5px 0 !important;
  }
`;
export default CardLinkEditor;
