import React from 'react';
import StickerItems from 'resources/images/sticker';
import styled from 'styled-components';
import { StickerTypes } from './StickerTypes';

const Stickers = ({ onClick }: { onClick: (sticker: StickerTypes) => void }) => {
  const basicStyle = { color: '#05C755', background: '#fff', fill: '#fff', stroke: '#05C755' };
  const getSticker = (key?: string, sticker?: any) => {
    const Temp = sticker.component;
    return (
      <div
        onClick={() => {
          const newType: StickerTypes = {
            label: '',
            stickerType: key as string,
            component: sticker.component,
          };
          onClick(newType);
        }}
      >
        <Temp style={basicStyle} />
      </div>
    );
  };
  return (
    <StickersWrapper>
      {Object.entries(StickerItems).map(([key, value]) => (
        <>{getSticker(key, value)}</>
      ))}
    </StickersWrapper>
  );
};

const StickersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export default Stickers;
