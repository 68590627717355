import React, { useEffect, useState } from 'react';

import { useStore } from 'stores/StoreHelper';
import { ChannelBoardDto } from 'services/data-contracts';
import { DraggableList, getItemStyle } from 'components/commons/draggableList';
import { isMobile } from 'react-device-detect';
import { Draggable } from 'react-beautiful-dnd';
import { Row } from 'components/commons/layouts';
import { LinkDto } from 'services/data-contracts';
import { IProfileBlock } from 'types/BlockExtendsTypes';
import { observer } from 'mobx-react-lite';

import styled from 'styled-components';
import EmptyPage from 'pages/EmptyPage';
import BlockSelector from '../containers/block/BlockSelector';
import MyLinkStore from 'stores/MyLinkStore';
import ContextMenu from '../components/block/ContextMenu';
import AddIcon from '@mui/icons-material/Add';
import AdminBlockItem from '../components/block/AdminBlockItem';
import PreviewContainer from '../containers/PreviewContainer';
import LinkServiceProvider from 'features/linkService/context/LinkServiceProvider';
import ProfileProvider from '../context/providers/ProfileProvider';
import PreviewBox from '../components/common/PreviewBox';
import useLocalParams from 'libs/hooks/useLocalParams';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import CategoryController from 'controllers/CategoryController';
import LinkController from 'controllers/LinkController';
import BlockController from 'controllers/BlockController';
import { useRootContext } from 'libs/hooks/useRootContext';
import { ListBoxItemTemplate } from 'features/csTown/commonComponents/styles';
import { useMediaContext } from 'providers/useMediaContext';
import { Divider } from 'features/myPage/components/CommonStyleComponent';
import DragIcon from 'components/commons/buttons/DragIcon';
import { useStateContext } from 'libs/hooks/usePageState';
import Text from 'components/commons/Text';

const LinkPage = observer(() => {
  const myLinkStore = new MyLinkStore();
  const ScreenTypes = useMediaContext();
  const { getMyLinks } = LinkController();
  const { categoryId } = useLocalParams();
  const { getCategoryBoard, getMyCategoryBoards } = CategoryController();
  const { setCategoryBoard, setCategoryBoards } = useChannelContext();
  const { setLinks } = useChannelContext();
  const { myChannel } = useRootContext();
  const { uiStore, xhrStore } = useStore();
  const { getBlockTypeComponent } = BlockController();
  const { receiver, broadcast } = useStateContext();

  const [categories, setCategories] = useState<LinkDto[]>([]);
  const [hasFooter, setHasFooter] = useState<boolean>();
  const [previewMode, setPreviewMode] = useState<boolean>(false);

  const handleClickNewLink = (item?: ChannelBoardDto) => {
    uiStore.bottomSheet.show({
      topPosition: isMobile ? 40 : undefined,
      title: '새 블록',
      children: <BlockSelector />,
      onConfirmed: () => {
        fetchData();
      },
    });
  };

  /**
   * @description 선택된 아이템의 컴포넌트 타입과 metadata를 변환하여 지정한다.
   * @param item
   */
  const handleClickShowLink = (item: Partial<LinkDto>, seq?: number) => {
    const Component = getBlockTypeComponent(item);

    uiStore.modal.show({
      title: item.title,
      style: {
        height: ScreenTypes.isSmall ? '100vh' : '80vh',
        width: '100%',
        maxWidth: ScreenTypes.isSmall ? 550 : 800,
      },
      children: (
        <BlockContainerStyle>
          <Component.Component
            onUpdate={() => {}}
            blockModel={typeof seq !== 'undefined' ? undefined : Component.newItem}
            seq={seq}
          />
        </BlockContainerStyle>
      ),
    });
  };

  const handleReorder = async (links: LinkDto[], link: LinkDto) => {
    setCategories(links);
    await myLinkStore.reOrderLinkItem(
      myChannel?.id as number,
      categoryId as number,
      link.id as number,
      link,
    );
    broadcast({ id: 'ACTION', param: { id: 'LINK_UPDATE_DONE', categoryId } });
  };

  const handleDeleteBlock = (item: ChannelBoardDto) => {
    uiStore.bottomSheet.close();
    uiStore.confirm.show({
      message: '선택하신 블록을 삭제하시겠습니까? ',
      onConfirmed: async () => {
        const result = await myLinkStore.deleteItem(
          myChannel?.id as number,
          categoryId as number,
          item.id as number,
        );
        if (result) {
          fetchData();
        }
      },
    });
  };

  const handleChangeActive = (item: ChannelBoardDto) => {
    uiStore.bottomSheet.close();
    uiStore.confirm.show({
      title: item.isActive ? '비공개 전환' : '공개 전환',
      message: item.isActive
        ? '비공개로 전환되면 다른 사람들은 볼 수 없게됩니다.'
        : '공개로 전환되면 다른 사람들도 볼 수 있습니다.',
      onConfirmed: async () => {
        const newBoard: ChannelBoardDto = { ...item, isActive: !item.isActive };
        // const result = await myBoardStore.updateBoard(myChannel?.id as number, newBoard);
        // if (result) {
        //   fetchData();
        // }
      },
    });
  };

  const handleRename = (item: ChannelBoardDto) => {
    handleClickNewLink(item);
  };

  const handleClickShowMore = (item: ChannelBoardDto) => {
    uiStore.bottomSheet.show({
      children: (
        <ContextMenu
          isActive={item.isActive}
          onDelete={() => {
            handleDeleteBlock(item);
          }}
          onRename={() => {
            handleRename(item);
          }}
          onInactive={() => {
            handleChangeActive(item);
          }}
        />
      ),
    });
  };

  const handleClickPreview = (isPreview?: boolean) => {
    const adminGnb = document.querySelector('.admin-gnb') as any;
    const modalHeader = document.querySelector('.modal-header') as any;
    const blockSelector = document.querySelector('.block-selector') as any;
    if (adminGnb && blockSelector && modalHeader) {
      if (isPreview === true) {
        adminGnb.classList.remove('hide');
        modalHeader.classList.remove('hide');
        blockSelector.classList.remove('hide');
      } else {
        adminGnb.classList.add('hide');
        modalHeader.classList.add('hide');
        blockSelector.classList.add('hide');
      }
    }
    setPreviewMode(isPreview as boolean);
  };

  const fetchData = async () => {
    const response = await getMyLinks(categoryId as number);

    const hasFooterTemp = response.find((link: LinkDto) => link.linkType === 'Footer');
    setHasFooter(typeof hasFooterTemp !== 'undefined');
    setCategories(response);
    setLinks(response);
  };

  const getCategory = async () => {
    if (categoryId && myChannel) {
      const allCategoryBoards = (await getMyCategoryBoards()) as ChannelBoardDto[];
      setCategoryBoards(allCategoryBoards?.filter((board) => board.boardType === 'LINK'));
      const categoryBoard = (await getCategoryBoard(categoryId)) as ChannelBoardDto;
      setCategoryBoard(categoryBoard);
    }
  };

  useEffect(() => {
    if (xhrStore.state === 'done') {
      uiStore.modal.close();
      fetchData();
    }
  }, [xhrStore.state]);

  useEffect(() => {
    if (myChannel && categoryId) {
      fetchData();
      getCategory();
    }
  }, [myChannel, categoryId]);

  useEffect(() => {
    if (receiver?.id === 'PREVIEW_MODE_CHANGE') {
      handleClickPreview(receiver?.param);
    }

    if (receiver?.id === 'ACTION' && receiver?.param?.id === 'LINK_UPDATE_DONE') {
      fetchData();
    }
  }, [receiver]);

  return (
    <>
      {/* <DetailPageHeader title={' 관리'} /> */}
      {!ScreenTypes.isLarge && (
        <div style={{ position: 'sticky', top: 0, zIndex: 1 }}>
          <ProfileProvider>
            <div style={{ position: 'sticky' }}>
              <PreviewBox
                previewMode={previewMode}
                onClickPreview={() => {
                  handleClickPreview(!previewMode);
                }}
                categoryId={categoryId}
              />
            </div>
          </ProfileProvider>
        </div>
      )}
      {/* <ManagementInnerLayout> */}
      <div
        style={{
          paddingLeft: previewMode ? 0 : 20,
          paddingRight: previewMode ? 0 : 20,
          paddingBottom: categories?.length > 1 ? 170 : 0,
          flexGrow: 1,
        }}
      >
        {previewMode ? (
          <LinkServiceProvider>
            <PreviewContainer />
          </LinkServiceProvider>
        ) : (
          <DraggableList items={categories} onOrderChanged={handleReorder} hasLast={hasFooter}>
            {categories?.map((dragItem: IProfileBlock, index: number) => (
              <Draggable
                key={dragItem?.id}
                draggableId={String(dragItem?.id)}
                index={index}
                isDragDisabled={dragItem.linkType === 'Footer'}
              >
                {(provided, snapshot): JSX.Element => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    <div
                      {...provided.dragHandleProps}
                      style={{ marginTop: ScreenTypes.isLarge ? 5 : 0 }}
                    >
                      <DragIcon />
                    </div>
                    <ListBoxItemTemplate style={{ padding: 0, margin: 0 }}>
                      <AdminBlockItem
                        boxStyle={{ margin: 0 }}
                        item={dragItem}
                        onClick={() => {
                          handleClickShowLink(dragItem);
                        }}
                        onClickMore={() => {
                          handleClickShowMore(dragItem);
                        }}
                      />
                    </ListBoxItemTemplate>
                    {/* <div
                      className={`block-spacer block-spacer-${index}`}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 10,
                      }}
                    >
                      <IconButton
                        className={`buttons button-${index}`}
                        style={{ background: '#fff', width: 30, height: 30 }}
                        onClick={() => {
                          handleAddSpacer(index);
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                      <div className={`blocks spacer-${index}`}>
                        <Spacer index={index} />
                      </div>
                    </div> */}
                  </div>
                )}
              </Draggable>
            ))}
            <>
              {hasFooter !== true && categories.length > 1 && (
                <div
                  style={{}}
                  onClick={() => {
                    handleClickShowLink({ linkType: 'Footer' }, categories.length + 1);
                  }}
                >
                  <Row
                    style={{
                      fontSize: 16,
                      background: '#fff',
                      width: 100,
                      borderRadius: 18,
                      margin: 'auto',
                      padding: '5px 10px',
                      cursor: 'pointer',
                    }}
                  >
                    <AddIcon />
                    <div>Footer</div>
                  </Row>
                </div>
              )}
            </>
          </DraggableList>
        )}
        <>
          {categories?.length === 0 && !previewMode && (
            <EmptyPage content="아직 생성된 링크가 없습니다." />
          )}
        </>
      </div>
      <LeftContainerStyle>
        {ScreenTypes.isLarge && (
          <ProfileProvider>
            {previewMode === false && (
              <>
                <Text type="H4" style={{ padding: 20 }}>
                  링크블록 선택
                </Text>
                <Divider style={{ width: '90%', margin: 'auto', opacity: 0.3, marginBottom: 20 }} />
                <BlockSelector />
              </>
            )}
          </ProfileProvider>
        )}
      </LeftContainerStyle>
      {!previewMode && !ScreenTypes.isLarge && (
        <>
          <div style={{ textAlign: 'center' }}>
            <BlockSelector />
          </div>
        </>
      )}
    </>
  );
});

const BlockContainerStyle = styled.div`
  padding: 20px;
`;

const LeftContainerStyle = styled.div`
  position: fixed;
  bottom: 100px;
  left: 10px;
  width: 280px !important;
  background: #fff;
  border-radius: 8px;
`;

export default LinkPage;
