import TitleForm from 'components/items/forms/TitleForm';
import useSetState from 'libs/hooks/useSetState';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import AddIcon from '@mui/icons-material/Add';

import { DealServiceItemModel } from 'types/CommonTypes';
import { validationHelper } from 'controls/validationHelper';
import { useStore } from 'stores/StoreHelper';

const DealCustomTypeF = ({
  item,
  onChange,
}: {
  item: DealServiceItemModel;
  onChange: (result: Partial<DealServiceItemModel>) => void;
}) => {
  const { uiStore } = useStore();

  const [localModel, setLocalModel] = useSetState<DealServiceItemModel>();

  const handleClickImoji = () => {
    uiStore.universalModal.show({
      children: <Picker data={data} onEmojiSelect={handleClickEmoji} />,
    });
  };

  const handleClickEmoji = (event: any, emojiObject: any) => {
    onChange({ ...item, icon: event.native });
    setLocalModel('icon', event.native);
    uiStore.universalModal.close();
  };

  useEffect(() => {
    const validate = validationHelper.tel(localModel?.content);
  }, [localModel]);

  useEffect(() => {
    if (item) {
      setLocalModel(item);
    }
  }, []);

  return (
    <CustomItemWrapper>
      <CustomItemStyle onClick={handleClickImoji}>
        {localModel?.icon ? localModel?.icon : <AddIcon />}
      </CustomItemStyle>
      <FormContainer>
        <TitleForm
          placeHolder="제목을 입력해주세요."
          onChangeValue={(value: string) => {
            setLocalModel('title', value);
            onChange({ ...item, title: value });
          }}
          initialValue={item.title ? item.title : ''}
        />
        <TitleForm
          placeHolder="내용을 입력해주세요."
          onChangeValue={(value: string) => {
            setLocalModel('content', value);
            onChange({ ...item, content: value });
          }}
          initialValue={item.content ? item.content  : ''}
        />
      </FormContainer>
    </CustomItemWrapper>
  );
};

const CustomItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const CustomItemStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: #efefef;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 20px;
  margin-right: 10px;
  min-width: 40px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export default DealCustomTypeF;
