import { makeAutoObservable, toJS } from 'mobx';
import axios from 'axios';

const ICAL = require('ical.js');

class ICalStore {
  constructor() {
    makeAutoObservable(this);
  }

  async fetchICalData(url: string) {
    const response = await axios.get(url);
    return response.data;
  }

  async getReservations() {
    const getics = await fetch(
      `https://cors-anywhere.herokuapp.com/https://www.airbnb.co.kr/calendar/ical/798073652138532797.ics`,
    );

    const icsData = await this.fetchICalData(
      'https://cors-anywhere.herokuapp.com/https://www.airbnb.co.kr/calendar/ical/798073652138532797.ics?s=f94d509c788815fc32d1654dbb745b04',
    );
    // const response = axios.get(
    //   'https://www.airbnb.co.kr/calendar/ical/798073652138532797.ics?s=f94d509c788815fc32d1654dbb745b04',
    // );
  }
}

export default ICalStore;
