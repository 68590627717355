import React, { useState } from 'react';

import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { t } from 'i18next';

import SettingsCellIcon from '@mui/icons-material/SettingsCell';

const MobileGnbBottom = () => {
  const { uiStores } = useStore();
  const { adminStore } = uiStores;

  return (
    <Row style={{ justifyContent: 'center', height: 50 }}>
      <OhmossButton
        onClick={() => {
          adminStore.setShowSimulator();
          // window.scrollTo(1, 0);
        }}
        style={{ marginRight: 10, padding: '10px 15px', alignItems: 'center' }}
      >
        <SettingsCellIcon />
        {t('Preview')}
      </OhmossButton>
    </Row>
  );
};

export default MobileGnbBottom;
