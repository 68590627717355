import React from 'react';
import { observer } from 'mobx-react';
import { ChannelDto } from 'services/data-contracts';
import { Avatar, Button } from '@mui/material';
import { Row } from 'components/commons/layouts';

import _ from 'lodash';
import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CheckIcon from '@mui/icons-material/Check';
import { OhmossButton } from 'resources/controls/OhmossButton';
import ProfileAvatar from 'components/commons/ProfileAvatar';

const ChannelComponent = observer(
  ({
    channel,
    selected,
    mode,
    isOwner,
    canDelete,
    onSelected,
    onEdit,
    onDelete,
  }: {
    channel: ChannelDto;
    selected?: boolean;
    mode: 'view' | 'edit';
    isOwner?: boolean;
    canDelete?: boolean;
    onSelected?: (channel: ChannelDto) => void;
    onEdit?: (channel: ChannelDto) => void;
    onDelete?: (channel: ChannelDto) => void;
  }) => {
    return (
      <MyChannelContainerStyle selected={selected}>
        <Row
          style={{ justifyContent: 'space-between' }}
          onClick={() => {
            if (mode === 'view') {
              if (onSelected) onSelected(channel);
            }
          }}
        >
          <Row style={{ justifyContent: 'flex-start' }}>
            <ProfileAvatar
              style={{ width: 48, height: 48 }}
              src={
                !_.isUndefined(channel?.profile?.profileImage?.publicUrl)
                  ? channel.profile?.profileImage?.publicUrl
                  : '/image/dummyUser.png'
              }
            />
            <div style={{ marginLeft: 7 }}>{channel?.channelName}</div>
          </Row>
          <>
            {isOwner && mode === 'edit' ? (
              <Row style={{ justifyContent: 'flex-end' }}>
                <>
                  {!selected && (
                    <OhmossButton
                      type="square"
                      style={{ minWidth: 30, background: '#fff', padding: 5, width: 80 }}
                      onClick={() => {
                        if (onSelected) onSelected(channel);
                      }}
                    >
                      채널변경
                    </OhmossButton>
                  )}

                  {/* {canDelete && (
                    <Button
                      style={{ minWidth: 30, background: '#fff', padding: 5 }}
                      onClick={() => {
                        if (onDelete) onDelete(channel);
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  )} */}
                </>
              </Row>
            ) : (
              <Row
                style={{ justifyContent: 'flex-end' }}
                onClick={() => {
                  if (onSelected) onSelected(channel);
                }}
              >
                <>{selected && <CheckIcon style={{ color: '#05C755', width: 24 }} />}</>
              </Row>
            )}
          </>
        </Row>
      </MyChannelContainerStyle>
    );
  },
);

const MyChannelContainerStyle = styled.div<{ selected?: boolean }>`
  border-radius: 15px;
  cursor: pointer;
  margin: 0 auto 10px auto;
  padding: 10px;
  flex-grow: 1;
  flex: 1;
  width: 100%;
  justify-content: center;
  border: ${(props) => (props.selected ? '2px solid #96e06e' : '2px solid #fff')};
`;

export default ChannelComponent;
