import React, { CSSProperties } from 'react';
import styled from 'styled-components';

const RowColumn = ({
  style,
  children,
  onClick,
}: {
  style?: CSSProperties;
  children: JSX.Element | JSX.Element[];
  onClick?: () => void;
}) => {
  const _style = { ...style };
  return (
    <ContainerStyle
      style={_style}
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick();
        }
      }}
    >
      {children}
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  /* padding: 20px; */
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  bottom: 0;
`;

export default RowColumn;
