import React, { CSSProperties } from 'react';
import { Row } from 'components/commons/layouts';
import { ILinkButton } from 'types/BlockExtendsTypes';
import { useStore } from 'stores/StoreHelper';
import { getFlexPosition } from 'libs/helper/utils';

import styled from 'styled-components';
import useNavigation from 'libs/hooks/useNavigate';
import StickerBox from 'components/commons/stickers/StickerBox';
import CommonThumbnail from '../../commonControl/CommonThumbnail';
import { BlocktitleBarStyle } from '../../common/BlockTitleBar';
import { ContentWrapper } from '../styles/ContentWrapperStyle';
import TitleStickerBox from './TitleStickerBox';

const LinkButtonTemplate = ({
  blockModel,
  item,
  textStyle,
  boxStyle,
  editMode = true,
  onClick,
}: {
  blockModel?: ILinkButton;
  item: ILinkButton;
  editMode?: boolean;
  textStyle?: CSSProperties;
  boxStyle?: CSSProperties;
  onClick: () => void;
}) => {
  const navigate = useNavigation();

  const handleOnClick = () => {
    if (!editMode) {
      navigate(item?.linkUrl, item.openNewWindow ?? true);
    }
  };

  const getTitleArea = () => {
    const alignment =
      item?.contentAlign === 'left'
        ? 'flex-start'
        : item?.contentAlign === 'right'
        ? 'flex-end'
        : 'center';
    return (
      <BlocktitleBarStyle
        style={{
          alignItems: alignment,
          // ...style,
          ...textStyle,
          flexDirection: 'column',
        }}
      >
        <div>
          {(item?.stickerPosition === 'top' || typeof item?.stickerPosition === 'undefined') && (
            <StickerBox stickers={item?.stickers} />
          )}
        </div>
        {item?.title}
        <div style={{}}>
          {item?.stickerPosition === 'bottom' && (
            <StickerBox
              stickers={item?.stickers}
              style={{ justifyContent: getFlexPosition(item?.contentAlign) }}
            />
          )}
        </div>
      </BlocktitleBarStyle>
    );
  };

  const getType = () => {
    switch (item?.layout) {
      case 'TypeB':
        return (
          <Row style={{ justifyContent: 'center' }}>
            <>
              <div>
                <CommonThumbnail
                  initialValue={item.thumbnail?.publicUrl}
                  parent={boxStyle?.borderRadius}
                  style={{
                    borderRadius: boxStyle?.borderRadius,
                    minHeight: boxStyle?.minHeight,
                    minWidth: boxStyle?.minHeight,
                    justifySelf: 'center',
                  }}
                />
              </div>
            </>
            <ContentWrapper style={{ alignItems: 'center', padding: '10px', flexDirection: 'row' }}>
              {/* <div style={textStyle}>{item.title}</div>
              <StickerBox stickers={item?.stickers} /> */}
              {getTitleArea()}
            </ContentWrapper>
          </Row>
        );
      case 'TypeC':
        return (
          <Row style={{ justifyContent: 'flex-end' }}>
            <ContentWrapper style={{ alignItems: 'center', padding: '10px', flexDirection: 'row' }}>
              {/* <div style={textStyle}>{item.title}</div>
              <StickerBox stickers={item?.stickers} /> */}
              {getTitleArea()}
            </ContentWrapper>
            <div
              style={{
                display: 'flex',
              }}
            >
              <CommonThumbnail
                parent={boxStyle?.borderRadius}
                initialValue={item.thumbnail?.publicUrl}
                style={{
                  borderRadius: boxStyle?.borderRadius,
                  minHeight: boxStyle?.minHeight,
                  minWidth: boxStyle?.minHeight,
                }}
              />
            </div>
          </Row>
        );
      default:
        return (
          <Row style={{ justifyContent: 'flex-start' }}>
            <div style={{ marginRight: item.thumbnail?.publicUrl && 10 }}>
              <div>
                <CommonThumbnail
                  parent={boxStyle?.borderRadius}
                  initialValue={item.thumbnail?.publicUrl}
                  style={{
                    borderRadius: boxStyle?.borderRadius,
                    minHeight: boxStyle?.minHeight,
                    minWidth: boxStyle?.minHeight,
                  }}
                />
              </div>
            </div>
            <ContentWrapper
              style={{
                padding: !item.thumbnail?.publicUrl ? '10px' : 0,
              }}
            >
              {/* <div style={{ ...textStyle }}>{item.title}</div>
              <StickerBox stickers={item?.stickers} /> */}
              {getTitleArea()}
              {/* <TitleArea style={{ alignItems: 'flex-start' }} /> */}
            </ContentWrapper>
          </Row>
        );
    }
  };

  // useEffect(() => {
  //   if (item?.stickers) {
  //     const data = item.stickers.map((sticker) => {
  //       const parsedStyle = sticker.style !== undefined ? JSON.parse(sticker.style) : {};
  //       const stickerType = (sticker.stickerType ?? 'TypeA') as keyof typeof StickerItems;
  //       const { component } = StickerItems[stickerType];
  //       return {
  //         ...sticker,
  //         component,
  //         internalStyle: parsedStyle,
  //       };
  //     });
  //     setSelectedStickers(data);
  //   }
  // }, [item?.stickers]);

  return (
    <ContainerStyle
      id={`block_item_id_${item.id}`}
      onClick={handleOnClick}
      style={{ cursor: item.linkUrl ? 'pointer' : 'default' }}
    >
      {getType()}
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  justify-content: center;
  cursor: pointer;
  width: 100%;
  flex-grow: 1;
  /* min-height: 90px; */
  display: flex;
  padding: 0px 5px;
`;

export default LinkButtonTemplate;
