import React from 'react';
import { Radio, RadioProps, } from '@mui/material';

const MUIRadio = (props: RadioProps) => {
    return (
        <Radio {...props} sx={{ '&.Mui-checked': { color: '#23BA50' } }} />
    );
};

export default MUIRadio;
