import { Row } from 'components/commons/layouts';
import CommonThumbnail from 'features/management/components/commonControl/CommonThumbnail';
import React from 'react';
import { ChannelChecklistDto } from 'services/data-contracts';
import { BoxStyle } from 'features/csTown/commonComponents/styles';
import { useStateContext } from 'libs/hooks/usePageState';
import { CSTownChecklistState } from 'types/CommonTypes';
import { cutoffString } from 'libs/helper/utils';

function ChecklistList({
  checklist,
  onClickItem,
}: {
  checklist?: ChannelChecklistDto[] | null | undefined;
  onClickItem?: (item: ChannelChecklistDto) => void;
}) {
  const { stateModel, setPageState } = useStateContext<CSTownChecklistState>();

  const convertDate = (date: string) => {
    if (date) {
      const newDate = new Date(date);
      const newDateString = new Date(date).toLocaleDateString();
      const today = new Date().toLocaleDateString();

      if (newDateString === today) {
        return newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      } else {
        return (
          <span>
            {newDate.getFullYear()}.{newDate.getMonth() + 1}.{newDate.getDate()}
          </span>
        );
      }
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
      {checklist?.map((item, i) => (
        <BoxStyle
          key={i}
          selected={stateModel?.selectedChecklist?.id === item?.id}
          onClick={() => {
            onClickItem?.(item);
            setPageState('selectedChecklist', item);
          }}
        >
          <Row style={{ justifyContent: 'space-between', gap: 10 }}>
            <div style={{ width: 50 }}>
              <CommonThumbnail initialValue={item?.deal?.thumbnail?.publicUrl} useAlternative />
            </div>
            <Row
              style={{
                flexGrow: 1,
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: 10,
              }}
            >
              <div>
                <Row style={{ gap: 5, justifyContent: 'flex-start' }}>
                  <img
                    src={item?.category?.image?.publicUrl}
                    alt={item?.title}
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  />
                  <div style={{ color: '#48505D', fontWeight: '500', fontSize: 15 }}>
                    {cutoffString(item?.title, 20)}
                  </div>
                </Row>
                {item?.deal && <div>{cutoffString(item?.deal?.title, 20)}</div>}
              </div>
            </Row>

            <span style={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.54)' }}>
              {convertDate(item?.createDate as string)}
            </span>
          </Row>
        </BoxStyle>
      ))}
    </div>
  );
}

export default ChecklistList;
