import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Resign = () => {
  const { t } = useTranslation();

  return (
    <ContainerStyle>
      <ul style={{ paddingInlineStart: 20 }}>
        <li>{t('A37')}</li>
        <li>{t('A37_1')}</li>
        <li>{t('A37_2')}</li>
        <li>{t('A37_3')}</li>
        <li>{t('A37_4')}</li>
        <li>{t('A37_5')}</li>
      </ul>
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  > ul {
    list-style-type: disc;
  }
`;

export default Resign;
