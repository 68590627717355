import BrandSearchBox from 'components/commons/BrandSearchBox';
import React from 'react';
import { ChannelDto } from 'services/data-contracts';
import styled from 'styled-components';

const GiftSearchBox = (
  {
    onSelect,
    onChangeValue,
  }: {
    onSelect?: (channel?: ChannelDto) => void;
    onChangeValue?: (channelName?: string) => void;
  }) => {
  return (
    <Container>
      <Header>선물할 채널 찾기</Header>
      <BrandSearchBox
        onFixSearchBox={(channel) =>{
          onSelect?.(channel);
          onChangeValue?.(channel?.channelName);
        }}
        onChangeValue={onChangeValue}
        // type="seller"
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 1.27088rem 0.5625rem 1.5rem 0.75rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.02725rem;
  border-radius: 0.875rem;
  border: 1px solid rgba(23, 51, 0, 0.3);
  background: #fff;
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 15px;
`;

export default GiftSearchBox;
