import React, { useState, useEffect } from 'react';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useStore } from 'stores/StoreHelper';

const SingleRadio = ({
    useConfirm,
    confirmMessage,
    checked,
    onChange
}: {
    useConfirm?: boolean;
    confirmMessage?: string;
    checked?: boolean;
    onChange: (value: boolean) => void;
}) => {
    const { uiStore } = useStore();
    const [checkedValue, setChecked] = useState<boolean>();

    const handleChangeActive = () => {
        if (checked) {
            return;
        }
        if (useConfirm) {
            uiStore.confirm.show({
                message: confirmMessage || 'Confirm',
                onConfirmed: () => {
                    onChange(!checkedValue);
                },

            });
        } else {
            setChecked((prev: boolean) => !prev);
            onChange(!checkedValue);
        }
    };

    useEffect(() => {
        setChecked(checked);
    }, [checked]);

    return (
        <div onClick={handleChangeActive} style={{ cursor: 'pointer' }}>
            {checkedValue ? <RadioButtonCheckedIcon style={{ color: '#23BA50' }} /> : <RadioButtonUncheckedIcon />}
        </div>

    );
};

export default SingleRadio;
