import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { PostItemDto } from 'services/data-contracts';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getFeedThmbnailUrl } from 'components/items/commonLogic';
import { Autoplay, Pagination, Navigation } from 'swiper';
import useNavigation from 'libs/hooks/useNavigate';

import styled from 'styled-components';

import 'swiper/css/pagination';
import 'swiper/css';

const PostSwipeSquare = observer(
  ({
    items,
    useSwipe,
    useAutoPlay,
  }: {
    items?: PostItemDto[][];
    useSwipe?: boolean;
    useAutoPlay?: boolean;
  }) => {
    const imgRefs = useRef<any>([]);
    const navigate = useNavigation();
    const [swiperModuls, setSwiperModuls] = useState<any>([Pagination]);
    const [initialized, setInitialized] = useState<boolean>(false);

    const handleClickImage = (card: PostItemDto) => {
      if (card.linkUrl) {
        return () => {
          navigate(card.linkUrl, true);
        };
      }
    };

    useEffect(() => {
      if (useAutoPlay) {
        setSwiperModuls([Pagination, Autoplay, Navigation]);
      } else {
        setSwiperModuls([Pagination, Navigation]);
      }
      setInitialized(true);
    }, [useAutoPlay]);

    return (
      <div style={{ background: '#000' }}>
        {initialized === true && (
          <Swiper
            pagination
            centeredSlides={false}
            enabled={useSwipe || true}
            modules={swiperModuls}
            spaceBetween={0}
            style={{ zIndex: 0 }}
            slidesPerView={1}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
          >
            {items?.map((card: PostItemDto[], index: number) => (
              <SwiperSlide key={index} style={{ alignItems: 'center', display: 'flex' }}>
                <>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <div>
                      {card.length > 0 ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            background: '#000',
                            aspectRatio: 1,
                            flexWrap: 'wrap',
                            overflow: 'hidden',
                          }}
                        >
                          {card.map((c, index) => (
                            <div
                              style={{
                                aspectRatio: '1',
                                overflow: 'hidden',
                                display: 'flex',
                                width: card.length === 1 ? '100%' : '50%',
                                height: 'auto',
                                objectFit: 'cover',
                              }}
                              key={index}
                            >
                              <img
                                onClick={handleClickImage(c)}
                                ref={(el: any) => {
                                  return (imgRefs.current[index] = el);
                                }}
                                className="feed-image"
                                src={getFeedThmbnailUrl(c.file?.publicUrl, '900')}
                                alt=""
                                style={{
                                  objectFit: 'cover',
                                  width: '100%',
                                  height: 'auto',
                                  cursor: c.linkUrl ? 'pointer' : 'default',
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <NoImageStyle />
                      )}
                    </div>
                  </div>
                </>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    );
  },
);

const NoImageStyle = styled.div`
  width: 100%;
  min-height: 300px;
  background: #efefef;
`;
export default PostSwipeSquare;
