/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { DealOptionDto, LinkDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MyLink<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 링크정보
   *
   * @tags my link
   * @name GetLink
   * @summary 링크정보
   * @request GET:/v2/me/channels/{channelId}/boards/{boardId}/links/{linkId}
   * @secure
   */
  getLink = (channelId: number, boardId: number, linkId: number, params: RequestParams = {}) =>
    this.http.request<LinkDto, any>({
      path: `/v2/me/channels/${channelId}/boards/${boardId}/links/${linkId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 링크 수정
   *
   * @tags my link
   * @name UpdateLink
   * @summary 링크 수정
   * @request PUT:/v2/me/channels/{channelId}/boards/{boardId}/links/{linkId}
   * @secure
   */
  updateLink = (
    channelId: number,
    boardId: number,
    linkId: number,
    data: LinkDto,
    params: RequestParams = {},
  ) =>
    this.http.request<LinkDto, any>({
      path: `/v2/me/channels/${channelId}/boards/${boardId}/links/${linkId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 링크 삭제
   *
   * @tags my link
   * @name DeleteLink
   * @summary 링크 삭제
   * @request DELETE:/v2/me/channels/{channelId}/boards/{boardId}/links/{linkId}
   * @secure
   */
  deleteLink = (channelId: number, boardId: number, linkId: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/boards/${boardId}/links/${linkId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 링크 순번 수정
   *
   * @tags my link
   * @name UpdateSeqOfLink
   * @summary 링크 순번 수정
   * @request PUT:/v2/me/channels/{channelId}/boards/{boardId}/links/{linkId}/seq
   * @secure
   */
  updateSeqOfLink = (
    channelId: number,
    boardId: number,
    linkId: number,
    data: LinkDto,
    params: RequestParams = {},
  ) =>
    this.http.request<LinkDto, any>({
      path: `/v2/me/channels/${channelId}/boards/${boardId}/links/${linkId}/seq`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 링크 목록
   *
   * @tags my link
   * @name GetLinks
   * @summary 링크 목록
   * @request GET:/v2/me/channels/{channelId}/boards/{boardId}/links
   * @secure
   */
  getLinks = (
    channelId: number,
    boardId: number,
    query?: {
      linkType?:
        | 'NoticeLink'
        | 'ItemLink'
        | 'SimpleLink'
        | 'CardLink'
        | 'TelLink'
        | 'MapLink'
        | 'Youtube'
        | 'Video'
        | 'Page'
        | 'TextLink'
        | 'Booking'
        | 'Suggestion'
        | 'SNS'
        | 'Profile'
        | 'Spacer'
        | 'Popup'
        | 'Seperator'
        | 'Tails'
        | 'Image'
        | 'Gallery'
        | 'Calendar'
        | 'Footer'
        | 'CategoryTab'
        | 'Deal';
      isActive?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<LinkDto[], any>({
      path: `/v2/me/channels/${channelId}/boards/${boardId}/links`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 링크 생성
   *
   * @tags my link
   * @name CreateLink
   * @summary 링크 생성
   * @request POST:/v2/me/channels/{channelId}/boards/{boardId}/links
   * @secure
   */
  createLink = (channelId: number, boardId: number, data: LinkDto, params: RequestParams = {}) =>
    this.http.request<LinkDto, any>({
      path: `/v2/me/channels/${channelId}/boards/${boardId}/links`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags my link
   * @name GetDealOptions
   * @summary Deal 옵션 목록
   * @request GET:/v2/me/channels/{channelId}/boards/{boardId}/links/{linkId}/options
   * @secure
   */
  getDealOptions = (
    channelId: number,
    boardId: number,
    linkId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<DealOptionDto[], any>({
      path: `/v2/me/channels/${channelId}/boards/${boardId}/links/${linkId}/options`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags my link
   * @name SaveDealOptions
   * @summary Deal 옵션 저장
   * @request POST:/v2/me/channels/{channelId}/boards/{boardId}/links/{linkId}/options
   * @secure
   */
  saveDealOptions = (
    channelId: number,
    boardId: number,
    linkId: number,
    data: DealOptionDto[],
    params: RequestParams = {},
  ) =>
    this.http.request<DealOptionDto[], any>({
      path: `/v2/me/channels/${channelId}/boards/${boardId}/links/${linkId}/options`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
