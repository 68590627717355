import React from 'react';

function useSetState<T extends object>(initialValue?: T) {
  const [state, setState] = React.useState<T | undefined>(initialValue);

  function set(keyOrObject: keyof T | Partial<T>, value?: T[keyof T]): void {
    setState((prev) => {
      // prev가 undefined일 때 기본값으로 빈 객체를 사용
      const currentState = prev || ({} as T);

      // key-value 쌍으로 호출된 경우
      if (
        typeof keyOrObject === 'string' ||
        typeof keyOrObject === 'number' ||
        typeof keyOrObject === 'symbol'
      ) {
        return { ...currentState, [keyOrObject]: value };
      }
      // 객체로 호출된 경우
      if (keyOrObject && typeof keyOrObject === 'object') {
        return { ...currentState, ...keyOrObject };
      }
      return currentState; // 여기에 도달하면 예상치 못한 입력이므로 현재 상태를 반환
    });
  }

  function deleteProperty(key: keyof T): void {
    setState((prev) => {
      const currentState = prev ? { ...prev } : ({} as T);
      delete currentState[key];
      return currentState;
    });
  }

  return [state, set, deleteProperty] as const;
}

export default useSetState;
