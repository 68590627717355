/* eslint-disable react/jsx-pascal-case */
import React, { memo, useEffect, useState } from 'react';
import { SNSLinkItemModel } from 'types/CommonTypes';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { ISocialBlock } from 'types/BlockExtendsTypes';
import { useStore } from 'stores/StoreHelper';
import { DraggableList, getItemStyle } from 'components/commons/draggableList';
import { Draggable } from 'react-beautiful-dnd';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { LinkTypes } from 'features/management/context/providers/ContextTypes';

import SaveButtonWrapper from 'components/items/SaveButtonWrapper';
import SNSSelector from './SNSSelector';
import CommonRadioGroup from '../commonControl/CommonRadioGroup';
import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import CommonColorSet from '../commonControl/CommonColorSet';
import SocialItem from './SocialItem';
import BlockController from 'controllers/BlockController';
import ActionButton from './ActionButton';

const SocialEditor = ({ blockModel }: { blockModel?: ISocialBlock }) => {
  const { uiStore } = useStore();
  const {
    initialModel,
    serviceModel,
    setState,
    setMultipleState,

    initialBinding,
    updateServiceModel,
  } = useOhmossContext<LinkTypes<ISocialBlock>>();

  const { updateBlock } = BlockController<ISocialBlock>();

  const [useCustomColor, setUseCustomColor] = useState<boolean | undefined>(false);
  const [localItems, setLocalItems] = useState<SNSLinkItemModel[]>();

  const defaultSNS: SNSLinkItemModel[] = [
    { title: 'facebook', linkUrl: '', itemId: 1, seq: 1 },
    { title: 'instagram', linkUrl: '', itemId: 2, seq: 2 },
    { title: 'youtube', linkUrl: '', itemId: 3, seq: 3 },
  ];

  const sizeItems = [
    { label: '작게', value: 'small' },
    { label: '중간', value: 'middle' },
    { label: '크게', value: 'large' },
  ];

  const itemColors = [
    { label: '기본', value: 'origin' },
    { label: '밝게', value: 'light' },
    { label: '그레이', value: 'gray' },
    { label: '어둡게', value: 'dark' },
    { label: '커스텀', value: 'custom' },
  ];

  const handleClickNew = () => {
    uiStore.bottomSheet.show({
      children: <SNSSelector />,
      onConfirmed: (result: SNSLinkItemModel) => {
        if (typeof localItems !== 'undefined') {
          const maxValue = Math.max(...localItems.map((item) => item.seq || 0));
          const newItems = [
            ...(serviceModel.items || []),
            { ...result, linkUrl: '', itemId: `a${maxValue}${1}`, seq: maxValue + 1 },
          ];
          setLocalItems(newItems);
          updateServiceModel('items', newItems);
        }
      },
    });
  };

  const handleItemDelete = (id: number) => {
    const newItems = localItems
      ?.filter((localItem) => localItem.itemId !== id)
      .map((item, index) => {
        return { ...item, seq: index };
      });
    setLocalItems(newItems);
    updateServiceModel('items', newItems);
  };

  const handleClickSave = () => {
    updateBlock(serviceModel);
  };

  /**
   * @description 모델의 형태(신규/수정)에 따라 초기화한다.
   */
  const initializeModel = () => {
    if (blockModel) {
      initialBinding(blockModel);
      setUseCustomColor(blockModel.iconColorType === 'custom');
      if (blockModel.items) {
        const newItems = blockModel.items.map((item: SNSLinkItemModel) => {
          return { ...item, itemId: item.id };
        });
        setLocalItems(newItems);
      }
    } else {
      initialBinding({
        linkType: 'SNS',
        title: '소셜네트워크',
        isActive: true,
        iconSize: 'small',
        items: defaultSNS,
        openNewWindow: true,
        iconColorType: 'origin',
      });

      setLocalItems(defaultSNS);
    }
  };

  useEffect(() => {
    initializeModel();
    return () => {
      initialBinding(undefined);
    };
  }, [blockModel]);

  return (
    // <SaveButtonWrapper onSave={handleClickSave} isValid isNew>
    <>
      <>
        <div>
          <DraggableList
            items={localItems}
            onOrderChanged={(snsItems: SNSLinkItemModel[]) => {
              const mergedArray = snsItems.map((itemB) => {
                const foundInA = serviceModel?.items?.find((itemA) => itemA.title === itemB.title);
                return foundInA ? { ...foundInA, seq: itemB.seq } : itemB;
              });
              updateServiceModel('items', mergedArray);
              setLocalItems(snsItems);
            }}
          >
            {localItems?.map((dragItem: SNSLinkItemModel, index: number) => (
              <Draggable
                key={dragItem?.itemId}
                draggableId={String(dragItem?.itemId)}
                index={index}
              >
                {(provided, snapshot): JSX.Element => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    <SocialItem
                      key={dragItem.itemId}
                      onDelete={handleItemDelete}
                      itemIndex={index}
                      defaultModel={dragItem}
                      name={dragItem.title}
                      {...dragItem}
                    />
                  </div>
                )}
              </Draggable>
            ))}
          </DraggableList>
        </div>
      </>
      <div style={{ padding: 10 }}>
        <CommonRadioGroup
          title="표시크기"
          onChange={(value: string) => {
            setState('iconSize', value as 'small' | 'middle' | 'large');
          }}
          items={sizeItems}
          initialValue={initialModel?.iconSize || 'small'}
        />
        <CommonRadioGroup
          title="아이콘색상"
          items={itemColors}
          initialValue={initialModel?.iconColorType}
          onChange={(value: string) => {
            setUseCustomColor(value === 'custom');
            setState('iconColorType', value);
          }}
        />
        {useCustomColor === true && (
          <CommonColorSet
            title="커스텀색상"
            initialValue={initialModel?.iconColor}
            onChange={(color: string) => {
              setMultipleState({ iconColorType: 'custom', iconColor: color });
            }}
          />
        )}
        <MenuItemCheckBox
          onChangeValue={(value: boolean) => {
            setState('useTransparent', value);
          }}
          initialValue={initialModel?.useTransparent}
          title="투명배경"
        />
        <MenuItemCheckBox
          onChangeValue={(value: boolean) => {
            setState('openNewWindow', value);
          }}
          initialValue={initialModel?.openNewWindow}
          title="새창열기"
        />
      </div>
      <OhmossButton onClick={handleClickNew}>소셜네트워크 추가</OhmossButton>
      <ActionButton isValid={true} serviceModel={serviceModel} />
    </>
    // </SaveButtonWrapper>
  );
};

export default memo(SocialEditor);
