import CSTownController from 'controllers/CSTownController';
import { useStateContext } from 'libs/hooks/usePageState';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useEffect } from 'react';
import { LinkDto } from 'services/data-contracts';
import { MyDealState } from 'types/CommonTypes';

const CSTownDealsController = () => {
  const { setPageState, stateModel } = useStateContext<MyDealState>();
  const { deals, Brand, Member, Seller } = CSTownController();
  const { myChannel } = useRootContext();

  const getDeals = async () => {
    switch (myChannel?.channelType) {
      case 'SELLER':
        await Seller.DEAL.getDeals();
        break;
      case 'BRAND':
        await Brand.DEAL.getDelegatedDeals();
        break;
      case 'MEMBER':
        await Member.DEAL.getDeals();
        break;
      default:
        break;
    }
  };

  const setDealsByType = async (currentTab: number) => {
    if (currentTab === 1) {
      const t = stateModel?.originalDeals?.filter((deal: LinkDto) => deal.status === 'UPCOMING');
      setPageState('deals', t);
    } else if (currentTab === 2) {
      const t = stateModel?.originalDeals?.filter((deal: LinkDto) => deal.status === 'ONGOING');
      setPageState('deals', t);
    } else {
      const t = stateModel?.originalDeals?.filter((deal: LinkDto) => deal.status === 'ENDED');
      setPageState('deals', t);
    }
  };

  useEffect(() => {
    if (deals) {
      setPageState('originalDeals', deals);
    }
  }, [deals]);

  return {
    deals,
    getDeals,
    setDealsByType,
  };
};

export default CSTownDealsController;
