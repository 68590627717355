import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import BackgroundContainer from 'features/adminFeatures/appearance/background/containers/BackgroundContainer';

const BackgroundPage = observer(() => {
  return <BackgroundContainer />;
});

export default BackgroundPage;
