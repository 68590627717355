import React, { useState, useEffect } from 'react';
import {
  ItemSectionStyle,
  ItemSectionTitle,
  ItemSectionDescription,
} from 'resources/controls/ItemSectionStyle';
import { MyStyle } from 'types/CommonTypes';
import { Row } from 'components/commons/layouts';
import { ToggleButton } from 'components/commons/ToggleButton';
import { t } from 'i18next';

import styled from 'styled-components';
import SimpleColorPickerWithPopOver from 'components/items/SimpleColorPickerWithPopOver';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { MyStyleTypes } from 'features/management/context/providers/ContextTypes';
import { Slider } from '@mui/material';

const LinkDesignItemBox = ({}: {}) => {
  const { serviceModel, setState, initialModel, stateModel } =
    useOhmossContext<MyStyleTypes<MyStyle>>();

  const rounds = [
    {
      description: t('A189'),
      radius: '0px',
    },
    {
      description: t('A190'),
      radius: '15px',
    },
    {
      description: t('A191'),
      radius: '30px',
    },
  ];

  const [selectedRadius, setSelectedRadius] = useState<string | number | undefined>('0px');

  useEffect(() => {
    if (initialModel?.boxStyle?.borderRadius) {
      setSelectedRadius(initialModel?.boxStyle?.borderRadius);
    }
  }, [initialModel]);

  return (
    <ItemSectionStyle style={{ padding: 20 }}>
      <ItemSectionTitle>
        <Row style={{ justifyContent: 'space-between' }}>
          <div>{t('A187')}</div>
          <SimpleColorPickerWithPopOver
            useGradient
            defaultValue={initialModel?.boxStyle?.background}
            onColorChanged={(color: string) => {
              setState('boxStyle', { ...stateModel?.boxStyle, background: color });
            }}
          />
        </Row>
      </ItemSectionTitle>
      <ItemSectionDescription>{t('A188')}</ItemSectionDescription>

      <div style={{ marginTop: 10 }}>
        <Row style={{ justifyContent: 'space-between' }}>
          {rounds.map((round: any) => (
            <ToggleButtonStyle
              onClick={() => {
                const t = serviceModel?.boxStyle;
                setSelectedRadius(round.radius);
                setState('boxStyle', { ...stateModel?.boxStyle, borderRadius: round.radius });
              }}
              item={round}
              defaultChecked={selectedRadius === round.radius}
              style={{ borderRadius: round.radius, margin: '0 10px' }}
            >
              <>{round.description}</>
            </ToggleButtonStyle>
          ))}
        </Row>
      </div>
      <ItemSectionTitle style={{ marginTop: 20 }}>
        <Row style={{ justifyContent: 'space-between' }}>
          <div>박스 높이</div>
        </Row>
      </ItemSectionTitle>
      <ItemSectionDescription>
        <div>블록 박스의 최소 높이를 설정할 수 있습니다.</div>
        <div>*표시되는 컨텐츠에 따라서 최소높이가 다르게 표시될 수 있습니다.</div>
      </ItemSectionDescription>
      <div style={{ marginTop: 10 }}>
        <Slider
          sx={{ marginLeft: 1, width: '95%' }}
          value={(stateModel?.boxStyle?.minHeight as number) || 60}
          min={40}
          max={100}
          onChange={(e: any, value: number) => {
            setState('boxStyle', {
              ...stateModel?.boxStyle,
              minHeight: value,
            });
          }}
          aria-label="Default"
          valueLabelDisplay="auto"
        />
      </div>
    </ItemSectionStyle>
  );
};

const ToggleButtonStyle = styled(ToggleButton)`
  &:nth-child(1) {
    margin: 0 10px !important;
  }
`;

export default LinkDesignItemBox;
