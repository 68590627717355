import React, { memo, useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ItemSectionStyle, ItemSectionTitle } from 'resources/controls/ItemSectionStyle';
import { AdCategoryDto, AdDto } from 'services/data-contracts';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import { Row } from 'components/commons/layouts';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControl from '@mui/material/FormControl';
import NumberFormat from 'react-number-format';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import styled from 'styled-components';
import UrlForm from './forms/UrlForm';
import TimeDealPicker from './TimeDealPicker';
import moment from 'moment';

/**
 *
 * @param param0 useAd : 이어서 광고등록이 필요한경우.
 * @returns
 */
const DealEditor = ({
  handleClickAd,
  handleClickPreview,
}: {
  handleClickAd: (model: AdDto) => void;
  handleClickPreview: (model: AdDto) => void;
}) => {
  const COINPERHOUR = 500;
  const { adCategoryStore, myChannelStore, uiStore } = useStore();
  const [category, setCategory] = useState<number | null>(null);
  const [model, setModel] = useState<AdDto>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [toHour, setToHour] = useState<number>(0);
  const [urlType, setUrlType] = useState<'internal' | 'external'>('internal');

  const handleChangeCategory = (event: never, value: number) => {
    setCategory(value);
    setModel((prev: AdDto) => {
      return { ...prev, category: { id: value, name: 'CLOTHING' } };
    });
  };

  const handleChangeUrl = (value: string) => {
    setModel((prev: AdDto) => {
      return { ...prev, linkUrl: value };
    });
  };

  const handleTimeDealTime = (from: string, to: string, toHour: number, type: 'time' | 'day') => {
    if (typeof from === 'undefined') {
      setIsValid(false);
      return;
    }

    const fromTime = moment(from).toISOString();
    const toTime = moment(to).toISOString();
    setToHour(type === 'time' ? toHour : toHour * 24);
    setModel((prev: AdDto) => {
      return { ...prev, startDate: fromTime, endDate: toTime };
    });
  };

  const handleChangeUrlType = (e: any, value: 'internal' | 'external') => {
    setUrlType(value);
  };

  useEffect(() => {
    if (model) {
      if (model.category && model.startDate && model.endDate) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [model]);

  useEffect(() => {
    const t = myChannelStore.currentChannel;
    window.scrollTo(0, 0);
  }, [myChannelStore.currentChannel]);

  return (
    <>
      <ItemSectionStyle className="p-4 m-4">
        <ItemSectionTitle>페이지</ItemSectionTitle>
        <FormControl fullWidth>
          <ToggleButtonGroup
            color="primary"
            exclusive
            style={{ flexWrap: 'wrap', gap: 10 }}
            value={category}
            onChange={handleChangeCategory}
            aria-label="Platform"
          >
            {adCategoryStore.adCategories?.map((category: AdCategoryDto) => (
              <ToggleButton
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: '#23BA50 !important',
                    color: '#ffffff',
                    border: '1px solid transparent !important',
                  },
                }}
                style={{ borderRadius: 30, padding: '5px 20px', border: '1px solid #bebebe' }}
                value={category.id!}
              >
                {category.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </FormControl>
      </ItemSectionStyle>
      <ItemSectionStyle className="p-4 m-4">
        <ItemSectionTitle>링크</ItemSectionTitle>
        <FormControl fullWidth>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="internal"
            name="radio-buttons-group"
            onChange={handleChangeUrlType}
          >
            <FormControlLabel
              value="internal"
              control={<Radio />}
              label="방금 생성한 포스트에 연결"
            />
            <FormControlLabel value="external" control={<Radio />} label="외부링크로 보내기" />
          </RadioGroup>
        </FormControl>
        <div className="pt-2">
          <UrlForm onChangeValue={handleChangeUrl} disable={urlType === 'internal'} />
        </div>
      </ItemSectionStyle>
      <ItemSectionStyle className="p-4 m-4">
        <ItemSectionTitle>
          광고시간 설정
          <SubContextStyle>(1시간 = {COINPERHOUR}모스코인)</SubContextStyle>
        </ItemSectionTitle>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimeDealPicker mode={'NEW'} timeRange={handleTimeDealTime} />
        </LocalizationProvider>
      </ItemSectionStyle>
      <ItemSectionStyle className="p-4 m-4">
        <ItemSectionTitle>
          광고로 인해 차감될 모스코인
          <SubContextStyle>
            <NumberFormat displayType="text" value={toHour * COINPERHOUR} thousandSeparator />{' '}
            모스코인 (시간당 {COINPERHOUR})
          </SubContextStyle>
        </ItemSectionTitle>
      </ItemSectionStyle>
      <ItemSectionStyle className="p-4 m-4">
        <ItemSectionTitle>보유한 모스코인</ItemSectionTitle>
        <div>
          <NumberFormat
            displayType="text"
            value={myChannelStore.currentChannel?.coin}
            thousandSeparator
          />
          모스코인
        </div>
        <Row style={{ flex: '1 0 auto', alignItems: 'flex-start', gap: 5, marginTop: 10 }}>
          <div style={{ display: 'inline-flex', color: '#FA9E1E' }}>주의사항</div>
          <div style={{ flex: 1 }}>요청된 딜 광고는 이후 수정이 불가합니다.</div>
        </Row>
      </ItemSectionStyle>
      <Row className="p-4" style={{ gap: 5 }}>
        <OhmossButton
          type="box"
          style={{ flex: 1, color: '#000000' }}
          disabled={!isValid}
          onClick={() => {
            if (model) handleClickPreview(model);
          }}
        >
          미리보기
        </OhmossButton>
        <OhmossButton
          disabled={!isValid}
          style={{ flex: 1, background: '#2EBE59' }}
          type="box"
          onClick={() => {
            if (model) {
              if (urlType === 'internal') {
                delete model.linkUrl;
              }
              handleClickAd(model);
            }
          }}
        >
          딜광고 요청
        </OhmossButton>
      </Row>
    </>
  );
};

const SubContextStyle = styled.div`
  font-size: 15px;
  color: #23ba50;
`;

export default memo(DealEditor);
