import React, { useEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { ReactComponent as Plus } from 'resources/images/sticker/sticker_plus.svg';

import { Button } from '@mui/material';
import StickerItems from 'resources/images/sticker';
import styled from 'styled-components';
import CommonRadioGroup from 'features/management/components/commonControl/CommonRadioGroup';
import CustomizedStickers from './CustomizedStickers';
import { Row } from '../layouts';
import StickerEditor from './StickerEditor';
import { StickerTypes } from './StickerTypes';
import { useStateContext } from 'libs/hooks/usePageState';
import { MyDealState } from 'types/CommonTypes';

interface StickerContainerProps {
  initialValue?: StickerTypes[];
  initialPosition?: 'top' | 'bottom';
  onChange: (stickers: StickerTypes[]) => void;
  onChangePosition?: (position: 'top' | 'bottom') => void;
}

/**
 * @description CSTown 공구 상세에서 간단하게 스티커를 추가할수있도록 한다.
 * @param param0
 * @returns
 */
const SimpleStickerBox = ({
  onChange,
  onChangePosition,
  initialPosition,
  initialValue,
}: StickerContainerProps) => {
  const { uiStore } = useStore();
  const [selectedStickers, setSelectedStickers] = useState<StickerTypes[]>([]);

  const handleStickerSelected = (stickers: StickerTypes[]) => {
    const newStickers = stickers.map((result) => {
      return { ...result, style: JSON.stringify(result.internalStyle) };
    });
    onChange(newStickers);
    uiStore.modal.close();
  };

  useEffect(() => {
    const newStickers = selectedStickers.map((sticker: StickerTypes) => {
      return { ...sticker, style: JSON.stringify(sticker.internalStyle) };
    });
    // todo : 영향도 점검할것
    // if (selectedStickers.length > 0) {
    //   onChange(newStickers);
    // }
  }, [selectedStickers]);

  useEffect(() => {
    if (initialValue) {
      const data = initialValue.map((sticker) => {
        const parsedStyle = sticker.style !== undefined ? JSON.parse(sticker.style) : {};
        const stickerType = (sticker.stickerType ?? 'TypeA') as keyof typeof StickerItems;
        const { component } = StickerItems[stickerType];
        return {
          ...sticker,
          component,
          internalStyle: parsedStyle,
        };
      });
      setSelectedStickers(data);
    }
  }, [initialValue]);

  return (
    <>
      <Row style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
        <StickerEditor stickers={selectedStickers} onSelected={handleStickerSelected} />
      </Row>
    </>
  );
};

export default SimpleStickerBox;
