import React from 'react';
import { observer } from 'mobx-react';

import styled from 'styled-components';
import LinkBoardType from 'features/adminFeatures/template/components/LinkBoardType';
import CalendarBoardType from 'features/board/containers/CalendarBoardType';
import BoardBase from 'features/board/containers/BoardBase';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import { PostDto } from 'services/data-contracts';

const SimpleTemplate = observer(() => {
  const { links, posts, categoryBoard } = useChannelContext();

  const getBoardType = () => {
    switch (categoryBoard?.boardType) {
      case 'BOARD':
        return <BoardBase posts={posts as PostDto[]} />;
      case 'LINK':
        return <LinkBoardType links={links} />;
      case 'CALENDAR':
        return <CalendarBoardType links={links} />;
      default:
        break;
    }
  };

  return (
    <StackStyle>
      <div className="global-width">
        <ItemContainerStyle>{getBoardType()}</ItemContainerStyle>
      </div>
    </StackStyle>
  );
});

const StackStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  margin: auto;
  position: relative;
  /* width: 90%; */

  height: 100%;
`;

const ItemContainerStyle = styled.div`
  padding-bottom: 10px;
  @media ${(props) => props.theme.media.mobile} {
    margin-top: 0px;
  }
  position: relative;
`;

export default SimpleTemplate;
