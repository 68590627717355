import { IconButton } from '@mui/material';
import React from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface DeleteButtonProps {
  onClick: () => void;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ onClick }) => {
  return (
    <IconButton onClick={onClick}>
      <DeleteOutlineIcon />
    </IconButton>
  );
};

export default DeleteButton;
