import React, { useState, InputHTMLAttributes, CSSProperties, useEffect, memo } from 'react';
import { ColorPicker } from 'components/items';
import FormatItalicOutlinedIcon from '@mui/icons-material/FormatItalicOutlined';
import TextSizeSelect from 'components/commons/TextSizeSelect';
import useScreenType from 'libs/hooks/useScreenType';
import styled from 'styled-components';
import _ from 'lodash';
import SimpleColorPickerWithPopOver from 'components/items/SimpleColorPickerWithPopOver';
// import SimpleColorPicker from 'components/items/SimpleColorPicker';
import i18n from 'i18n';

const t = i18n.t;

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  show: boolean;
  style?: CSSProperties;
  colorPickerLocation?: 'top' | 'bottom';
  imageUrl?: string | undefined;
  showImageIcon?: boolean;
  containerStyle?: CSSProperties;
  onColorEditing?: (result: boolean) => void;
  onChangeStyle: (style: CSSProperties) => void;
  onChangeImage?: (action: 'add' | 'delete', thumbnail?: any) => void;
}

/**
 * @description 이미지 선택부터 크롭까지 제공한다.
 * @returns
 */
const TextToolBox = ({
  show,
  style,
  imageUrl,
  colorPickerLocation,
  showImageIcon,
  containerStyle,
  onChangeStyle,
  onColorEditing,
  onChangeImage,
}: InputProps) => {
  const fontFamilyList = [
    { key: 1, label: 'Noto Sans KR', value: 'Noto Sans KR' },
    { key: 2, label: 'Nanum Gothic', value: 'Nanum Gothic' },
    { key: 3, label: 'Nanum Myeongjo', value: 'Nanum Myeongjo' },
  ];

  const textSizeList = [
    { key: 1, label: t('A165'), value: '24px' },
    { key: 2, label: t('A166'), value: '20px' },
    { key: 3, label: t('A167'), value: '16px' },
    { key: 4, label: t('A168'), value: '13px' },
    { key: 5, label: t('A169'), value: '11px' },
  ];

  const screenType = useScreenType();
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [styleModel, setStyleModel] = useState<CSSProperties>();
  const [defaultFontFamily, setFontFamily] = useState<{ label: string; value: string }>(
    fontFamilyList[0],
  );
  const [defaultFontSize, setFontSize] = useState<{ label: string; value: string }>({
    label: t('A167'),
    value: '16pt',
  });

  const handleChangeStyle = (style: CSSProperties) => {
    onChangeStyle(style);
  };

  // TODO: 언어에 따라 기본 폰트 패밀리 변경

  useEffect(() => {
    if (typeof style !== 'undefined') {
      setStyleModel({ ...style });
    }
  }, [style]);

  const fontFormat = () => {
    return (
      <>
        <FormatButtonStyle
          onClick={() => {
            handleChangeStyle({
              fontWeight:
                typeof styleModel?.fontWeight === 'undefined' ||
                Number(styleModel?.fontWeight) === 400
                  ? 600
                  : 400,
            });
          }}
        >
          <BoldStyle active={styleModel?.fontWeight === 600}>B</BoldStyle>
        </FormatButtonStyle>
        <FormatButtonStyle
          onClick={() => {
            handleChangeStyle({
              fontStyle:
                typeof styleModel?.fontStyle === 'undefined' || styleModel?.fontStyle === 'normal'
                  ? 'italic'
                  : 'normal',
            });
          }}
        >
          <ItalicStyle active={styleModel?.fontStyle === 'italic'} />
        </FormatButtonStyle>
        {/* <ColorPickerButtonStyle
          style={style}
          onClick={() => {
            setShowColorPicker(true);
            if (typeof onColorEditing === 'function') {
              onColorEditing(true);
            }
          }}
        >
          <ColorPickerButtonIconStyle
            alt=""
            src={
              typeof styleModel?.color !== 'undefined'
                ? '/image/palate_active.png'
                : '/image/palate_inactive.png'
            }
          />
        </ColorPickerButtonStyle> */}
        <SimpleColorPickerWithPopOver
          useGradient={false}
          defaultValue={typeof style?.color === 'undefined' ? '#000000' : style.color}
          onColorChanged={(color: string) => {
            handleChangeStyle({ color });
          }}
        />
      </>
    );
  };

  useEffect(() => {
    if (style) {
      if (style.fontSize) {
        const temp = textSizeList.find(
          (text: { label: string; value: string }) => text.value === style.fontSize,
        );
        setFontSize(
          typeof temp === 'undefined' ? defaultFontSize : { label: temp.label, value: temp.value },
        );
      } else {
        setFontSize(defaultFontSize);
      }

      if (style.fontFamily) {
        const temp = fontFamilyList.find(
          (text: { label: string; value: string }) => text.value === style.fontFamily,
        );
        setFontFamily(
          typeof temp === 'undefined'
            ? fontFamilyList[0]
            : { label: temp.label, value: temp.value },
        );
      } else {
        setFontFamily(fontFamilyList[0]);
      }
    }
  }, [style]);

  return (
    <>
      {show && (
        <ContainerStyle
          type={!_.isUndefined(screenType) ? screenType : ''}
          style={{ ...containerStyle }}
        >
          <OptionContainerStyle style={{ zIndex: 1000 }}>
            <TextSizeSelect
              style={{ fontSize: 15, color: '#111', width: 200 }}
              containerStyle={{ marginRight: 10, marginLeft: 5 }}
              textSizeList={fontFamilyList}
              key={
                typeof styleModel?.fontFamily !== 'undefined'
                  ? String(styleModel?.fontFamily)
                  : 'Font'
              }
              // todo : defaultvalue를 기존값이 있을경우 매칭하여 전달할것
              defaultValue={defaultFontFamily}
              onClick={(font: string) => {
                handleChangeStyle({ fontFamily: font });
              }}
            />
            <TextSizeSelect
              style={{ fontSize: 15, color: '#111' }}
              textSizeList={textSizeList}
              key={
                typeof styleModel?.fontSize !== 'undefined' ? String(styleModel?.fontSize) : '16pt'
              }
              defaultValue={defaultFontSize}
              onClick={(size: number) => {
                handleChangeStyle({ fontSize: String(size).replace('pt', 'px') });
              }}
            />
          </OptionContainerStyle>
          <OptionContainerStyle>
            {fontFormat()}
            <FormatButtonStyle onClick={() => handleChangeStyle({ textAlign: 'left' })}>
              <FormatIconStyle
                src={
                  styleModel?.textAlign === 'left'
                    ? '/image/format_align_left_active.png'
                    : '/image/format_align_left.png'
                }
              />
            </FormatButtonStyle>
            <FormatButtonStyle onClick={() => handleChangeStyle({ textAlign: 'center' })}>
              <FormatIconStyle
                src={
                  styleModel?.textAlign === 'center' || typeof styleModel?.textAlign === 'undefined'
                    ? '/image/format_align_center_active.png'
                    : '/image/format_align_center.png'
                }
              />
            </FormatButtonStyle>
            <FormatButtonStyle onClick={() => handleChangeStyle({ textAlign: 'right' })}>
              <FormatIconStyle
                src={
                  styleModel?.textAlign === 'right'
                    ? '/image/format_align_right_active.png'
                    : '/image/format_align_right.png'
                }
              />
            </FormatButtonStyle>
            {showImageIcon && (
              <ImageContainerStyle>
                {typeof imageUrl !== 'undefined' && (
                  <DeleteButtonStyle
                    onClick={() => {
                      if (typeof onChangeImage !== 'undefined') {
                        onChangeImage('delete');
                      }
                    }}
                  >
                    <DeleteIconStyle src="/image/delete_box.png" />
                  </DeleteButtonStyle>
                )}
              </ImageContainerStyle>
            )}
          </OptionContainerStyle>
        </ContainerStyle>
      )}
    </>
  );
};

const ContainerStyle = styled.div<{ type: string }>`
  border: ${(props) => (props.type === 'mobile' ? '1px solid #bbb' : '')};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: 1px solid #bbb;
  border-left: 1px solid #bbb;
  border-right: 1px solid #bbb;
  padding: 5px 0 15px 0;
`;

const OptionContainerStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 100%; 부모가 flex-wrap:wrap을 선언할 경우 100%이면 적용되지 않아 주석처리 함. */
  padding: 10px 0 0 0;
`;

const FormatButtonStyle = styled.div<{ marginRight?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font-size: 20px;
  margin: 0 ${(props) => (props?.marginRight ? props?.marginRight : 0)}px 0 14px;
  cursor: pointer;
  &:hover {
    background-color: #efefef;
    border-radius: 8px;
  }
`;

const FormatIconStyle = styled.img`
  width: 24px;
  height: 24px;
`;

const BoldStyle = styled.div<{ active: boolean }>`
  color: ${(props) => (props.active ? '#333' : '#ccc')};
  font-size: 19px;
  font-weight: 900;
`;

const ItalicStyle = styled(FormatItalicOutlinedIcon)<{ active: boolean }>`
  color: ${(props) => (props.active ? '#333' : '#ccc')};
`;

const ColorContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 20px;
  left: -220px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  @media ${(props) => props.theme.media.mobile} {
    left: -120px;
    top: 40px;
  }
`;

const ColorPickerButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: 0 0 0 14px;
  cursor: pointer;
  &:hover {
    background-color: #efefef;
    border-radius: 8px;
  }
`;

const ColorPickerButtonIconStyle = styled.img`
  width: 24px;
  height: 24px;
`;

const ImageContainerStyle = styled.div`
  position: relative;
`;

const DeleteButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 100px;
  position: absolute;
  top: -1px;
  right: 0;
  z-index: 2;
  cursor: pointer;
`;

const DeleteIconStyle = styled.img`
  width: 13px;
  height: 13px;
`;

export default memo(TextToolBox);
