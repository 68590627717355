import styled, { css } from 'styled-components';

const LoginContainerStyle = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

const LeftStyle = styled.div`
  width: 600px;
  align-items: center;
  background: #e8f6ff;
  border-radius: 20px;
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media ${(props) => props.theme.media.mobile} {
    display: none;
  }
`;

const LoginBodyStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  @media ${(props) => props.theme.media.mobile} {
    background: url('/images/mossTown0.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
`;
const alignCenter = css`
  display: flex;
  align-items: center;
`;

const SignUpTitleStyle = styled.div`
  ${alignCenter}
  justify-content: center;
  font-size: 28px;
  font-weight: 400;
  margin: 20px;
`;

const SignUpContainerStyle = styled.div`
  /* margin-top: 48px; */
  margin: 0 20px;
  text-align: center;
  @media ${(props) => props.theme.media.mobile} {
    background: rgba(255, 255, 255, 0.99);
    margin: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.22);
    width: 90%;
  }
`;

const LoginStyle = styled.div`
  ${alignCenter}
  justify-content: center;
  font-size: 14px;
  /* padding-bottom: 0.5em; */
`;

const SNSTypeStyle = styled.div`
  display: flex;
  /* width: 20.9375rem; */
  width: 100%;
  padding: 0.875rem 1.125rem;
  justify-content: center;
  align-items: center;
  gap: 1.625rem;
  height: 60px;
  border-radius: 62.4375rem;
  border: 1px solid #173300;
  color: #000;
  cursor: pointer;
  position: relative;
  margin-bottom: 10px;
  font-weight: 500;
`;

const LogoImageStyle = styled.img`
  width: 20px;
`;

export {
  LoginContainerStyle,
  LeftStyle,
  LoginBodyStyle,
  SignUpTitleStyle,
  SignUpContainerStyle,
  LoginStyle,
  SNSTypeStyle,
  LogoImageStyle,
};
