import React, { useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { LinkDto, MemberDto, PartnerDto } from 'services/data-contracts';
import {
  DetailHeader,
  ListBoxContentsTemplate,
  ListBoxTemplate,
  ListBoxWrapper,
  MenuIconBoxStyle,
  NoContentStyle,
} from 'features/csTown/commonComponents/styles';
import { Avatar, IconButton } from '@mui/material';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { Row } from 'components/commons/layouts';
import { CSTownPartnerState, ChatModel, LinkDtoWithAssignedDeal } from 'types/CommonTypes';
import { useStateContext } from 'libs/hooks/usePageState';

import TabButtons from 'features/csTown/components/pc/TabButtons';
import NoContent from 'components/commons/NoContent';
import PartnerController from 'controllers/PartnerController';
import CSPartnerList from 'features/csTown/components/pc/CSPartnerList';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';

import SearchBox from 'components/commons/SearchBox';
import CreateIcon from '@mui/icons-material/Create';

import CSTownPartnersController from 'features/csTown/pages/pageControllers/CSTownPartnersController';
import DeleteIcon from 'features/csTown/commonComponents/DeleteIcon';
import { useRootContext } from 'libs/hooks/useRootContext';
import AssignedDealsList from 'features/myPage/containers/AssignedDealsList';
import AssignedDealsContainer from 'features/csTown/components/AssignedDealsList';

import styled from 'styled-components';
import chatController from 'features/community/controllers/chatController';
import ChatContainer from '../ChatContainer';
import NewPartnerContainer from './NewPartnerContainer';

type tabs = '파트너' | '초대중';
const PartnerListContainer = ({ onSearch }: { onSearch: (value: string) => void }) => {
  const { uiStore } = useStore();
  const { myChannel } = useRootContext();
  const { getPartnersDelegatedDeals } = PartnerController();
  const { changeAssignedDeals, cancelPartnerInvitation } = CSTownPartnersController();

  const { stateModel, setPageState, broadcast } = useStateContext<CSTownPartnerState>();
  const { inviteOrDeleteUser } = chatController();

  const [tab, setTab] = useState<tabs>('파트너');
  const [deals, setDeals] = useState<LinkDtoWithAssignedDeal[]>();

  const handleClickSelect = async (partner: MemberDto) => {
    setPageState('selectedPartner', partner);
    if (typeof partner?.id !== 'undefined') {
      getPartnerDeals(partner);
    }
  };

  const getPartnerDeals = async (partner: MemberDto) => {
    const dealList = (await getPartnersDelegatedDeals(
      partner?.id as number,
    )) as unknown as LinkDtoWithAssignedDeal[];
    setDeals(dealList.filter((deal) => deal.assigned));
  };

  const handleBeginChat = () => {
    if (stateModel?.selectedPartner?.partnerChannel && myChannel) {
      const chatInfo: ChatModel = {
        operators: [myChannel],
        members: [stateModel?.selectedPartner?.partnerChannel],
        title: `${stateModel?.selectedPartner?.partnerChannel}님과의 채팅`,
      };
      uiStore.modal.show({
        style: { height: '80vh', maxHeight: '80vh' },
        children: <ChatContainer chatInfo={chatInfo} joinType="CHAT" />,
      });
    }
  };

  const handleOpenListOfDeals = async () => {
    let deals: LinkDto[] | undefined = [];
    if (stateModel?.selectedPartner) {
      deals = (await getPartnersDelegatedDeals(
        stateModel?.selectedPartner?.id as number,
      )) as unknown as LinkDto[];
      uiStore.drawer.show({
        title: '위임할 공구목록',
        description: '위임하거나 해제할 공구를 선택해주세요',
        children: (
          <AssignedDealsList
            dealList={deals as LinkDto[]}
            onSelected={(deals: LinkDto[]) => {
              changeAssignedDeals(deals, stateModel?.selectedPartner);
              getPartnerDeals(stateModel?.selectedPartner);
              // 관련된 브랜드에 초대장 보내기(센드버드)
              inviteOrDeleteUser(deals, [
                stateModel?.selectedPartner.partnerChannel?.channelName as string,
              ]);
              uiStore.drawer.close();
            }}
          />
        ),
      });
    }
  };

  const handleAddPartner = async (isEdit?: boolean) => {
    uiStore.modal.show({
      title: '파트너 설정',
      style: { width: '60%', maxWidth: '60%' },
      children: <NewPartnerContainer />,
    });
  };

  const handleSearch = (value: string) => {
    setPageState('selectedPartner', undefined);
    onSearch(value);
  };

  const handleDeleteManager = (partner: PartnerDto) => {
    cancelPartnerInvitation(tab === '파트너' ? 'PARTNER' : 'INVITATION', partner);
    setPageState('selectedPartner', undefined);
  };

  return (
    <div style={{ width: '100%' }}>
      <ListBoxWrapper>
        <ListBoxTemplate
          style={{
            borderRight: '1px solid #efefef',

            justifyContent: 'space-between',
          }}
        >
          <SearchBox
            onEntered={handleSearch}
            onCancel={() => {
              handleSearch('');
            }}
          />

          <div style={{ margin: '10px 0' }}>
            <TabButtons
              items={['파트너', '초대중']}
              onHandleTabClick={(value: string) => {
                setTab(value as tabs);
                setPageState('selectedPartner', undefined);
                broadcast({ id: 'reload' });
              }}
            />
          </div>
          <div style={{ flexGrow: 1, position: 'relative' }}>
            {tab === '파트너' && stateModel?.partnerList?.length === 0 && (
              <NoContent title="등록된 파트너가" />
            )}
            {tab === '초대중' && stateModel?.invitationList?.length === 0 && (
              <NoContent title="초대중인 파트너가" />
            )}
            <CSPartnerList
              partners={tab === '파트너' ? stateModel?.partnerList : stateModel?.invitationList}
              onSelect={handleClickSelect}
            />
          </div>
          <OhmossButton
            style={{ borderRadius: 10 }}
            onClick={() => {
              handleAddPartner(false);
            }}
          >
            파트너 초대
          </OhmossButton>
        </ListBoxTemplate>
        <ListBoxContentsTemplate style={{}}>
          {stateModel?.selectedPartner ? (
            <div style={{ position: 'relative' }}>
              <div style={{ padding: '0px 20px' }}>
                <DetailHeader>
                  <Row style={{ justifyContent: 'space-between' }}>
                    <Row
                      style={{ width: 'unset', justifyContent: 'flex-start', alignItems: 'center' }}
                    >
                      <Avatar
                        style={{ width: 80, height: 80 }}
                        src={
                          stateModel?.selectedPartner?.partnerChannel?.profile?.profileImage
                            ?.publicUrl
                        }
                      />
                      <div style={{ fontSize: 18, marginLeft: 10 }}>
                        {stateModel?.selectedPartner?.partnerChannel?.channelName}
                        <div style={{ opacity: 0.5, fontSize: 12 }}>
                          {stateModel?.selectedPartner?.partnerChannel?.channelType} PARTNER
                        </div>
                      </div>
                    </Row>
                    <MenuIconBoxStyle>
                      <IconButton
                        onClick={() => {
                          handleBeginChat();
                        }}
                      >
                        <SmsOutlinedIcon />
                      </IconButton>
                      {tab === '파트너' && (
                        <IconButton
                          onClick={() => {
                            handleOpenListOfDeals();
                          }}
                        >
                          <CreateIcon style={{ width: 25, height: 25 }} />
                        </IconButton>
                      )}
                      <DeleteIcon
                        onClick={() => {
                          handleDeleteManager(stateModel?.selectedPartner as PartnerDto);
                        }}
                      />
                    </MenuIconBoxStyle>
                  </Row>
                  <MenuIconBoxStyle style={{ height: 70 }} />
                </DetailHeader>
              </div>
              <Row style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <div style={{ padding: 20, flexGrow: 1 }}>
                  <div style={{}}>
                    <AssignedDealsContainer dealList={deals as LinkDtoWithAssignedDeal[]} />
                  </div>
                </div>
              </Row>
              {tab === '초대중' && <OverlayStyle />}
            </div>
          ) : (
            <NoContentStyle>
              <div>목록에서 파트너를 선택 해주세요.</div>
            </NoContentStyle>
          )}
        </ListBoxContentsTemplate>
      </ListBoxWrapper>
    </div>
  );
};

const OverlayStyle = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  bottom: 0;
  background: #fff;
  opacity: 0.8;
`;

export default PartnerListContainer;
