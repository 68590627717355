import { makeObservable, flow, observable, action } from 'mobx';
import { CustomerInquiryService } from 'services/CustomerInquiryService';
import { HttpClient } from 'services/http-client';
import { LinkDto, DealInquiryDto, MessageDto } from 'services/data-contracts';
import _ from 'lodash';
import ServiceStoreBase from './ServiceStoreBase';

/**
 * @description Public Link Store
 */
class CustomerInquiryStore extends ServiceStoreBase {
  links: Array<LinkDto> | undefined;

  constructor() {
    super();
    makeObservable(this, {
      createRoom: flow,
      inquiry: flow,
      getCustomerInquiries: flow,
      getInquiryByInquiryId: flow,
      getCustomerInquiryThreads: flow,
      links: observable,
      unMount: action,
    });
  }

  api: CustomerInquiryService<unknown> = new CustomerInquiryService(new HttpClient());

  *createRoom(ownChannelId?: number, inquryData?: DealInquiryDto) {
    const { response } = yield this.api.createCustomerInquiry(
      { ownChannelId: (ownChannelId as number) ?? null },
      inquryData as DealInquiryDto,
    );

    return response.status < 300 ? response.data : null;
  }

  /**
   * @deprecated
   * @param ownChannelId
   * @returns
   */

  *getCustomerInquiries(ownChannelId: number) {
    const { response } = yield this.api.getCustomerInquiries({ ownChannelId });
    return response.status < 300 ? response.data : null;
  }

  *getInquiryThreads(inquiryId: number, ownChannelId: number) {
    const { response } = yield this.api.getCustomerInquiryThreads(inquiryId, { ownChannelId });
    return response.status < 300 ? response.data : null;
  }

  *getInquiryByInquiryId(inquryId?: number, ownChannelId?: number) {
    const { response } = yield this.api.getCustomerInquiry(inquryId as number, {
      ownChannelId: ownChannelId as number,
    });
    return response.status < 300 ? response.data : null;
  }

  *inquiry(inquryId?: number, ownChannelId?: number, message?: MessageDto) {
    const newMessage = _.cloneDeep(message) as any;
    delete newMessage?.link;
    delete newMessage?.dealOptionName;
    const { response } = yield this.api.askQuestion(
      inquryId as number,
      { ownChannelId: ownChannelId as number },
      newMessage as MessageDto,
    );
    return response.status < 300 ? response.data : null;
  }

  *getCustomerInquiryThreads(inquiryId: number, ownChannelId: number) {
    const { response } = yield this.api.getCustomerInquiryThreads(inquiryId, {
      ownChannelId,
      sort: ['createDate,ASC'],
      size: 1000,
    });
    return response.status < 300 ? response.data : null;
  }

  *closeCustomerInquiry(inquiryId: number, ownChannelId: number) {
    const { response } = yield this.api.closeCustomerInquiry(inquiryId, { ownChannelId });
    return response.status < 300 ? response.data : null;
  }

  unMount() {
    this.links = undefined;
  }
}

export default CustomerInquiryStore;
