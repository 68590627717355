import styled from 'styled-components';

export const DetailsContainer = styled.div`
  display: flex;
  padding: 1.5rem 1.25rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  background: #fff;
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  border: 0.5px solid #cbcbcb;
  border-top: none;
`;

export const DetailsHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.015rem;
`;

export const DetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.8rem 5rem 0.8rem 5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #9de4bc;
  font-weight: 500;
  border: 0.5px solid #cbcbcb;
  border-top: none;
  border-bottom: none;
`;
