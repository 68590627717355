import axios from 'axios';
import { ChannelDto } from 'services/data-contracts';
import { SEND_BIRD_KEY, SENDBIRD_APP_ID } from 'stores/AppStore';

// // NBHD Token
// // const API_TOKEN = '1ae3db2fea6f595f81bfda66f38735dc90da45e7';
// // SALADSOFT Token
// const API_TOKEN = '25a0d1d7de55c39c2d44437e9ff7757133fa53a7';

class ChatStore {
  async acceptInvitation(channelUrl: string) {
    return axios
      .get(`https://api-${SEND_BIRD_KEY}.sendbird.com/v3/group_channels/${channelUrl}/accept`, {
        headers: {
          'Api-Token': `${SENDBIRD_APP_ID}`,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response.status === 400) {
          return null;
        } else {
          return undefined;
        }
      });
  }

  async inviteUsers(channelUrl: string, channelNames: string[]) {
    return axios
      .post(
        `https://api-${SEND_BIRD_KEY}.sendbird.com/v3/group_channels/${channelUrl}/invite`,
        {
          user_ids: channelNames,
        },
        {
          headers: {
            'Api-Token': `${SENDBIRD_APP_ID}`,
          },
        },
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error('Error posting data:', error.response);
        return null;
      });
  }

  async getUser(userId: string) {
    return axios
      .get(`https://api-${SEND_BIRD_KEY}.sendbird.com/v3/users/${userId}`, {
        headers: {
          'Api-Token': `${SENDBIRD_APP_ID}`,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response.status === 400) {
          return null;
        } else {
          return undefined;
        }
      });
  }

  async getUnreadCount(userId: string) {
    return axios
      .get(`https://api-${SEND_BIRD_KEY}.sendbird.com/v3/users/${userId}/unread_message_count`, {
        headers: {
          'Api-Token': `${SENDBIRD_APP_ID}`,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response.status === 400) {
          return null;
        } else {
          return undefined;
        }
      });
  }

  async createUser(channelInfo: ChannelDto) {
    return axios
      .post(
        `https://api-${SEND_BIRD_KEY}.sendbird.com/v3/users`,
        {
          user_id: channelInfo?.channelName,
          nickname: channelInfo?.profile?.nickname || channelInfo?.channelName,
          profile_url:
            channelInfo?.profile?.profileImage?.publicUrl ||
            '	https://ohmoss.com/images/ohmossc.png',
        },
        {
          headers: {
            'Api-Token': `${SENDBIRD_APP_ID}`,
          },
        },
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error('Error posting data:', error.response);
        return null;
      });
  }

  async getWhitelist() {
    return axios
      .get(`https://api-${SEND_BIRD_KEY}.sendbird.com/v3/applications/settings/ip_whitelist`, {
        headers: {
          'Api-Token': `${SENDBIRD_APP_ID}`,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error('Error posting data:', error.response);
        return null;
      });
  }

  async searchMessage(keyword: string) {
    return axios
      .get(`https://api-${SEND_BIRD_KEY}.sendbird.com/v3/applications/settings/ip_whitelist`, {
        headers: {
          'Api-Token': `${SENDBIRD_APP_ID}`,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error('Error posting data:', error.response);
        return null;
      });
  }
}

export default ChatStore;
