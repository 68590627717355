import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { MenuType } from 'types/CommonTypes';
import { isMobile } from 'react-device-detect';
import { getAdminMenus } from 'resources/definitions/AdminMenus';

import { OhmossButton } from 'resources/controls/OhmossButton';
import styled, { css } from 'styled-components';

import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from 'components/commons/Avatar';
import { useGlobalContext } from 'context/useGlobalContext';
import useLogout from 'hooks/useLogout';

const AdminLnbShort = observer(
  ({
    selectedIndex,
    onChangeMode,
    handleShowMenu,
  }: {
    selectedIndex?: number;
    onChangeMode?: (id: MenuType) => void;
    handleShowMenu?: (show: boolean, menu: string | undefined) => void;
  }) => {
    const [selectedItem, setSelectedItem] = useState<MenuType | undefined>();
    const logout = useLogout();
    const { t } = useTranslation();
    const { uiStore, myChannelStore } = useStore();
    const { channelname, menuType } = useParams();

    const { navigate } = useNavigation();

    const handleClickLogout = () => {
      uiStore.confirm.show({
        title: t('Logout'),
        message: t('A147'),
        onConfirmed: () => {
          logout();
        },
      });
    };

    useEffect(() => {
      const target = getAdminMenus().find((menu: any) => selectedIndex === menu.key);
      setSelectedItem(target);
    }, [selectedIndex]);

    useEffect(() => {
      setSelectedItem({ key: 0, title: 'Links' });
    }, []);

    return (
      <GnbLayoutStyle>
        <>
          <div style={{ marginTop: 50, textAlign: 'center' }}>
            <LogoStyle
              src="/images/ohmossc.png"
              onClick={() => {
                navigate('/');
              }}
            />
            <div style={{ marginTop: 50, marginBottom: 40 }}>
              <Avatar size="tiny" profile={myChannelStore.currentChannel?.profile} />
            </div>
            <MenuContainerStyle>
              <>
                {getAdminMenus().map((menu: MenuType) => (
                  <>
                    <HeaderItemStyle
                      isSeparate={menu.separate}
                      key={menu.key}
                      selected={selectedItem?.title === menu.title}
                      onClick={() => {
                        uiStore.universalModal.close();
                        if (typeof menu.route !== 'undefined' && !menuType) {
                          navigate(`/${channelname}${menu.route}`);
                          return;
                        }
                        setSelectedItem(menu);
                        if (typeof onChangeMode === 'function') {
                          onChangeMode(menu);
                        }
                        if (typeof handleShowMenu === 'function') {
                          handleShowMenu(true, menu.title);
                        }
                        if (!isMobile && menu.title === selectedItem?.title) {
                          if (typeof handleShowMenu === 'function') {
                            handleShowMenu(false, undefined);
                          }
                        }
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>{menu.icon}</div>
                    </HeaderItemStyle>
                  </>
                ))}
              </>
            </MenuContainerStyle>
          </div>
          <UnderButtonStyle>
            <OhmossButton type="simple" onClick={handleClickLogout}>
              <div style={{ marginRight: 5 }}>
                <LogoutIcon />
              </div>
            </OhmossButton>
          </UnderButtonStyle>
        </>
      </GnbLayoutStyle>
    );
  },
);

const GnbLayoutStyle = styled.div<{ isMobile?: boolean }>`
  display: none;
  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    width: 80px;
    height: 100vh;
    justify-content: space-between;
    align-items: center;
  }
  @media ${(props) => props.theme.media.mobile} {
    display: none;
  }
`;

const MenuContainerStyle = styled.div`
  display: inline-block;
  width: 50px;
  & > div {
    text-align: left;
  }
`;

const LogoStyle = styled.img.attrs({ alt: '' })`
  cursor: pointer;
  width: 40px;
`;

const HeaderItemStyle = styled.div<{ selected: boolean; isSeparate?: boolean }>`
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: all 0.1s ease-out;
  font-size: 14px;
  border-radius: 15px;
  margin-bottom: 20px;
  ${(props) =>
    props.selected &&
    css`
      color: #fff !important;
      background: #0bbd58;
      transition: all 0.1s ease-out;
    `}
  color: ${(props) => (props.selected ? 'black' : '#535353')};
  &:hover {
    opacity: 1;
    color: #068a3f !important;
    background: #fff;
    transition: all 0.1s ease-out;
  }
  height: 40px;
  justify-content: center;
  /* @media screen and (max-width: 1040px) {
    padding: 0 5px;
  } */
`;

const UnderButtonStyle = styled.div`
  display: flex;
  margin-bottom: 40px;
  width: 100%;
  justify-content: center;
`;

export default AdminLnbShort;
