import React, { ReactNode, useRef, useState } from 'react';
import { LinkDto } from 'services/data-contracts';
import { Context } from '../useOhmossContext';
import MagazineStore from 'stores/MagazineStore';
import { MagazineDto } from 'services/data-contracts';
import { PagenationType } from 'types/CommonTypes';

interface PageProviderProps<T> {
  children: ReactNode | ReactNode[] | Element | Element[];
}

const MagProvider = <T extends LinkDto>({ children }: PageProviderProps<T>) => {
  const magazineService = new MagazineStore();
  const [magazines, setMagazines] = useState<MagazineDto[]>([]);
  const [magazine, setMagazine] = useState<MagazineDto>();
  const [hasMore, setHasNore] = useState<MagazineDto>();
  const postPaignation = useRef<Partial<PagenationType>>();

  const getMags = async () => {
    // const currentPage2 = postPaignation.current ? 0 : postPaignation.current?.currentPage + 1;
    const currentPage = !postPaignation.current
      ? 0
      : (postPaignation.current as any).currentPage + 1;

    const mags = (await magazineService.getMagazine(currentPage)) as any;

    const pagenation = mags.headers['x-pagination'];
    if (pagenation) {
      const pg = JSON.parse(pagenation);
      if (pg) {
        postPaignation.current = pg;

        setHasNore(pg.hasNext);
      }
    }
    if (mags.data) {
      if (currentPage === 0) {
        setMagazines(mags.data);
      } else {
        setMagazines(magazines.concat(mags.data));
      }
    }
  };

  const getMagByKey = async (magazineKey: string) => {
    const mag = (await magazineService.getMagazineByKey(magazineKey)) as unknown as MagazineDto;
    if (mag) {
      return mag;
    }
  };

  const unmount = () => {
    setMagazine(undefined);
  };

  return (
    <>
      {typeof children !== 'undefined' ? (
        <Context.Provider value={{ magazines, magazine, getMags, getMagByKey, unmount, hasMore }}>
          {children}
        </Context.Provider>
      ) : (
        <></>
      )}
    </>
  );
};

export default MagProvider;
