import React, { useEffect, useState, CSSProperties } from 'react';
import { ItemContainerStyle } from 'features/adminFeatures/template/components/ItemContainerStyle';
import { GoogleMap } from 'components/maps';
import { Map, MapLinkModel } from 'types/CommonTypes';
import styled from 'styled-components';
import CardHeader from './CardHeader';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';

const MapLinkComponent = observer(
  ({ item, height }: { item: MapLinkModel; height: number | string }) => {
    const { channelStore } = useStore();

    const [map, setMap] = useState<Map>();
    const [isCollapse, setIsCollapse] = useState<boolean>(false);
    const [showMap, setShowMap] = useState<boolean>(false);
    const [overrideStyle, setOverrideStyle] = useState<CSSProperties>();

    const handleExpand = (value: boolean) => {
      setShowMap(value);
    };

    useEffect(() => {
      if (typeof item !== 'undefined') {
        const c = typeof item.metadata !== 'undefined' ? JSON.parse(item.metadata) : {};
        setIsCollapse(c?.isCollapsed);
        setMap({
          lat: Number(item.latitude),
          lng: Number(item.longitude),
          title: item.title as string,
          addr: item.address as string,
        });
        if (channelStore.myStyle?.style) {
          const parsedStyle = JSON.parse(channelStore.myStyle.style);
          setOverrideStyle(parsedStyle);
        }
      }
    }, [item, channelStore.myStyle]);

    return (
      <ItemContainerStyleOverride style={{ flexWrap: 'wrap', ...overrideStyle }} hover={true}>
        {isCollapse ? (
          <>
            <div style={{ flex: '100%', minHeight: 60, display: 'flex', alignItems: 'center' }}>
              <CardHeader
                onExpand={(value: boolean) => {
                  handleExpand(value);
                }}
              >
                <div style={{ fontFamily: overrideStyle?.fontFamily }}>{map?.title}</div>
              </CardHeader>
            </div>
            <div style={{ flex: '100%', position: 'relative', top: 10 }}>
              <MapContentStyle collapsed={!showMap} height={height} style={{ width: '100%' }}>
                {typeof map !== 'undefined' && <GoogleMap map={map} />}
              </MapContentStyle>
              <BottomStyle collapsed={!showMap}>
                <CardHeader>{item?.address}</CardHeader>
              </BottomStyle>
            </div>
          </>
        ) : (
          <>
            <MapContentStyle height={height}>
              {typeof map !== 'undefined' && <GoogleMap map={map} />}
            </MapContentStyle>
            <CardHeader>
              <div style={{ fontFamily: overrideStyle?.fontFamily }}>{map?.title}</div>
            </CardHeader>
          </>
        )}
      </ItemContainerStyleOverride>
    );
  },
);

const MapContentStyle = styled.div<{ collapsed?: boolean; height: number | string }>`
  height: ${(props) => (props.collapsed ? 0 : `${props.height}`)};
  transition: all 0.5s ease-in-out;
  width: 100%;
`;

const BottomStyle = styled.div<{ collapsed?: boolean }>`
  height: ${(props) => (props.collapsed ? 0 : '80px')};
  margin-bottom: ${(props) => (props.collapsed ? 0 : '10px')};
  transition: all 0.5s ease-in-out;
  display: flex;
`;

const ItemContainerStyleOverride = styled(ItemContainerStyle)<{ hover: boolean }>``;

export default React.memo(MapLinkComponent);
