import React from 'react';
import { DealInquiryDto } from 'services/data-contracts';
import InquiryItem from './components/InquiryItem';

function CSTownInquiriesContainer({ inquiries }: { inquiries?: DealInquiryDto[] }) {
  const handleClickInquiry = (inquiry: DealInquiryDto) => {
    // uiStore.modal.show({
    //   style: { height: '100vh' },
    //   modalType: 'modal',
    //   children: <ChatContainer inquiry={inquiry} type="edit" />,
    //   onClose: () => {
    //     broadcast({ id: 'reload' });
    //   },
    // });
  };

  return (
    <>
      {/* 최대 5개만 보여줌 */}
      {inquiries?.slice(0, 5).map((inquiry) => (
        <InquiryItem
          useDealInfo
          inquiry={inquiry}
          onClick={() => {
            handleClickInquiry(inquiry);
          }}
        />
      ))}
    </>
  );
}

export default CSTownInquiriesContainer;
