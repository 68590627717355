import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import TitleForm from 'components/items/forms/TitleForm';
import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';

import { CustomFile } from 'types/CommonTypes';
import { Align, Layout, IChannelProfile } from 'types/BlockExtendsTypes';
import { useStore } from 'stores/StoreHelper';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { ProfileTypes } from 'features/management/context/providers/ContextTypes';
import ProfileImageUploader from 'components/commons/imageUploader/ProfileImageUploader';
import styled from 'styled-components';
import ProfileController from 'controllers/ProfileController';
import MenuItem from 'components/commons/menuItem/MenuItem';
import CommonFontSelect from '../commonControl/CommonFontSelect';
import CommonAlign from '../commonControl/CommonAlign';
import CommonColorSet from '../commonControl/CommonColorSet';
import CommonFontSize from '../commonControl/CommonFontSize';

import ProfileFrame from '../commonControl/ProfileFrame';
import ProfileProposal from './ProfileProposal';
import { useRootContext } from 'libs/hooks/useRootContext';
import ActionBox from 'features/csTown/components/pc/ActionBox';
import Required from 'components/commons/Required';

const Profile = ({
  blockModel,
  proposalType = 'proposal',
}: {
  blockModel?: IChannelProfile;
  proposalType?: 'proposal' | 'inquiry';
}) => {
  const { uiStore } = useStore();
  const { setChannel } = useRootContext();

  const { initialModel, updateServiceModel, initialBinding, stateModel, setState, serviceModel } =
    useOhmossContext<ProfileTypes<IChannelProfile>>();

  const { updateProfile } = ProfileController();

  useInitializeModel<Partial<IChannelProfile>>(blockModel, undefined, initialBinding);

  const [style, setStyle] = useState<CSSProperties>();
  const defaultStyle = useRef<CSSProperties>();

  const update = async () => {
    const result = await updateProfile(serviceModel);
    if (result) {
      setChannel(result);
    }
    uiStore.modal.confirm(result);
  };

  const initializeModel = () => {
    if (blockModel) {
      defaultStyle.current = blockModel?.profileStyle;
      setStyle(blockModel.profileStyle);
    }
  };

  const openProposalBlock = () => {
    uiStore.bottomSheet.show({
      style: {
        padding: 20,
      },
      children: <ProfileProposal />,
    });
  };

  useEffect(() => {
    if (typeof style !== 'undefined') {
      updateServiceModel('profileStyle', style);
    }
  }, [style]);

  useEffect(() => {
    initializeModel();
    return () => {
      // initialBinding(undefined);
    };
  }, [blockModel]);

  // useEffect(() => {
  //   if (stateModel) {
  //     console.log(stateModel);
  //   }
  // }, [stateModel]);

  useEffect(() => {
    const contentBody = document.getElementById('modalContentBody') as any;
    const actionBox = document.getElementById('actionBox') as any;
    const width = contentBody.offsetWidth;
    if (actionBox) {
      actionBox.style.width = width + 'px';
    }
  }, []);

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <div style={{ padding: 40 }}>
        <div style={{ marginBottom: 20, display: 'flex', gap: '30px' }}>
          <ImageWrapper>
            <ProfileImageUploader
              type="copper"
              // useCircularCrop
              initialValue={initialModel?.profileImage}
              attachType="image"
              onSelected={(image: CustomFile) => {
                updateServiceModel('profileImage', image);
              }}
            />
            <ImageTypeText>프로필 이미지</ImageTypeText>
          </ImageWrapper>
          {stateModel?.useTransparent !== true && (
            <ImageWrapper>
              <ProfileImageUploader
                type="single"
                // useCircularCrop
                initialValue={initialModel?.profileBackgroundImage}
                attachType="image"
                onSelected={(image: CustomFile) => {
                  updateServiceModel('profileBackgroundImage', image);
                }}
              />
              <ImageTypeText>프로필 배경</ImageTypeText>
            </ImageWrapper>
          )}
        </div>
        <ProfileFrame
          initialValue={initialModel?.layout || 'TypeA'}
          onChange={(layout: Layout) => updateServiceModel('layout', layout)}
        />
        <ItemSectionStyle>
          <ItemSectionTitle>
            닉네임(검색에 사용됩니다)
            <Required />
          </ItemSectionTitle>
          <ItemContent>
            <TitleForm
              onChangeValue={(value: string) => {
                updateServiceModel('nickname', value);
              }}
              initialValue={initialModel?.nickname}
              placeHolder="닉네임"
              label="닉네임"
            />
          </ItemContent>
        </ItemSectionStyle>
        <ItemSectionStyle>
          <ItemSectionTitle>소개 내용</ItemSectionTitle>
          <ItemContent>
            <TitleForm
              maxLength={500}
              useCount
              onChangeValue={(value: string) => {
                updateServiceModel('introduction', value);
              }}
              initialValue={initialModel?.introduction}
              rows={4}
              label="소개 내용"
              multiline
              placeHolder="소개 내용을 작성해 주세요"
            />
            {/* <AIBox /> */}
          </ItemContent>
        </ItemSectionStyle>
        <ItemSectionStyle>
          <MenuItemCheckBox
            onChangeValue={(value: boolean) => {
              updateServiceModel('useTransparent', value);
              setState('useTransparent', value);
            }}
            initialValue={initialModel?.useTransparent}
            title="투명배경"
          />
          <MenuItemCheckBox
            initialValue={
              initialModel?.isFollowAllow !== undefined ? initialModel?.isFollowAllow : true
            }
            onChangeValue={(value: boolean) => {
              updateServiceModel('isFollowAllow', value);
            }}
            title={'팔로우 버튼'}
          />
          <MenuItemCheckBox
            onChangeValue={(value: boolean) => {
              updateServiceModel('useProposal', value);
              setState('useProposal', value);
            }}
            initialValue={initialModel?.useProposal}
            title="제안받기 활성화"
          />
          {stateModel?.useProposal === true && (
            <MenuItem
              onClick={openProposalBlock}
              style={{ fontSize: '15px', fontWeight: 500 }}
              title="제안 종류 설정"
            />
          )}
        </ItemSectionStyle>
        <CommonFontSize
          initialValue={style?.fontSize}
          onChange={(value) => {
            setStyle((prev: CSSProperties) => {
              return { ...prev, fontSize: value };
            });
          }}
        />
        <CommonFontSelect
          initialValue={initialModel?.profileStyle}
          onChange={(value: CSSProperties) => {
            setStyle((prev: CSSProperties) => {
              return { ...prev, ...value };
            });
          }}
        />
        <CommonAlign
          initialValue={initialModel?.contentAlign || 'center'}
          onChange={(align: Align) => updateServiceModel('contentAlign', align)}
        />
        <CommonColorSet
          initialValue={initialModel?.profileStyle?.color}
          onChange={(color: string) => {
            setStyle((prev: CSSProperties) => {
              return {
                ...prev,
                color,
              };
            });
          }}
        />
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: 40,
          padding: 20,
          width: '100%',
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          background: '#fff',
          borderTop: '1px solid #d1d1d1',
        }}
        id="actionBox"
      >
        <ActionBox
          isValid
          style={{}}
          onOk={async () => {
            await update();
            uiStore.universalModal.confirm(serviceModel);
          }}
          onCancel={() => {
            uiStore.universalModal.close();
          }}
        />
      </div>
      {/* <Row style={{ gap: 10 }}>
        <OhmossButton
          type="box"
          style={{ color: '#000' }}
          onClick={() => {
            uiStore.universalModal.close();
          }}
        >
          취소
        </OhmossButton>
        <OhmossButton
          onClick={() => {
            update();
          }}
        >
          적용
        </OhmossButton>
      </Row> */}
    </div>
  );
};

const ImageTypeText = styled.div`
  color: #bebebe;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  margin-top: 5px;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Profile;
