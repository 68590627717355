import { useStore } from 'stores/StoreHelper';
import MyAnalyticsStore from 'stores/MyAnalyticsStore';
import { useRootContext } from 'libs/hooks/useRootContext';
/**
 * @description 각 Block의 등록/수정/삭제를 수행함.
 * @returns
 */

const DashboardChartController = () => {
  const myAnalyticsStore = new MyAnalyticsStore();

  const { xhrStore } = useStore();
  const { myChannel } = useRootContext();

  /**
   * @description 채널에 대한 제안서를 생성함.
   * @param standAlonModel
   * @returns
   */

  const getLinkClickCount = async () => {
    xhrStore.setState('pending');
    const result = await myAnalyticsStore.getLinkClickCount(myChannel?.id as number);
    xhrStore.setState('done');
    return result;
  };

  const getVisitCount = async () => {
    xhrStore.setState('pending');
    const result = await myAnalyticsStore.getVisitCount(myChannel?.id as number);
    xhrStore.setState('done');
    return result;
  };

  const getFollowerCount = async () => {
    xhrStore.setState('pending');
    const result = await myAnalyticsStore.getFollowerCount(myChannel?.id as number);
    xhrStore.setState('done');
    return result;
  };
  const getDailyVisitCounts = async (startDate: string, endDate: string) => {
    xhrStore.setState('pending');
    const result = await myAnalyticsStore.getDailyVisitCounts(
      myChannel?.id as number,
      startDate,
      endDate,
    );
    xhrStore.setState('done');
    return result;
  };

  const getCountryClicks = async (dealId: number) => {
    xhrStore.setState('pending');
    const result = await myAnalyticsStore.getCountryClicks(dealId);
    xhrStore.setState('done');
    return result;
  };
  const getRegionClicks = async (dealId: number) => {
    xhrStore.setState('pending');
    const result = await myAnalyticsStore.getRegionClicks(dealId);
    xhrStore.setState('done');
    return result;
  };
  const getDailyClicks = async (dealId: number) => {
    xhrStore.setState('pending');
    const result = await myAnalyticsStore.getDailyClicks(dealId);
    xhrStore.setState('done');
    return result;
  };

  const getDailyTotalLinkClickCountsByChannel = async (startDate: string, endDate: string) => {
    xhrStore.setState('pending');
    const result = await myAnalyticsStore.getDailyTotalLinkClickCountsByChannel(
      myChannel?.id as number,
      { startDate, endDate },
    );
    xhrStore.setState('done');
    return result;
  };

  const getDailyFollowerCountsByChannel = async (startDate: string, endDate: string) => {
    xhrStore.setState('pending');
    const result = await myAnalyticsStore.getDailyFollowerCountsByChannel(myChannel?.id as number, {
      startDate,
      endDate,
    });
    xhrStore.setState('done');
    return result;
  };

  const getDailyEncoreCountsByDeal = async (dealId: number, startDate: string, endDate: string) => {
    xhrStore.setState('pending');
    const result = await myAnalyticsStore.getDailyEncoreCountsByDeal(dealId, {
      startDate,
      endDate,
    });
    xhrStore.setState('done');
    return result;
  };

  const getRefererClickCountsByDeal = async (dealId: number) => {
    xhrStore.setState('pending');
    const result = await myAnalyticsStore.getRefererClickCountsByDeal(dealId);
    xhrStore.setState('done');
    return result;
  };

  const getDailyVisitCountsByChannel = async (startDate: string, endDate: string) => {
    xhrStore.setState('pending');
    const result = await myAnalyticsStore.getDailyVisitCounts(
      myChannel?.id as number,
      startDate,
      endDate,
    );
    xhrStore.setState('done');
    return result;
  };

  const getDailyTotalLinkOutboundClickCounts = async (startDate: string, endDate: string) => {
    xhrStore.setState('pending');
    const result = await myAnalyticsStore.getDailyVisitCounts(
      myChannel?.id as number,
      startDate,
      endDate,
    );
    xhrStore.setState('done');
    return result;
  };

  return {
    getLinkClickCount,
    getVisitCount,
    getFollowerCount,
    getCountryClicks,
    getRegionClicks,
    getDailyClicks,
    getDailyVisitCounts,
    getDailyTotalLinkClickCountsByChannel,
    getDailyFollowerCountsByChannel,
    getDailyEncoreCountsByDeal,
    getRefererClickCountsByDeal,
    getDailyVisitCountsByChannel,
    getDailyTotalLinkOutboundClickCounts,
  };
};

export default DashboardChartController;
