import CSTownController from 'controllers/CSTownController';
import chatController from 'features/community/controllers/chatController';
import { useRootContext } from 'libs/hooks/useRootContext';
import { MemberDto, MemberUpdateDto, SimpleDealDto } from 'services/data-contracts';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';

const CSTownManagersController = () => {
  const { myChannel } = useRootContext();
  const { Brand } = CSTownController();
  const { inviteOrDeleteUser } = chatController();

  const updateManager = async (manager?: MemberDto | null, deals?: LinkDtoWithAssignedDeal[]) => {
    let result;
    if (manager?.id) {
      result = (await Brand.DEAL.updateManager(
        myChannel?.id as number,
        manager?.id as number,
        manager as MemberUpdateDto,
      )) as unknown as MemberDto;
    } else {
      result = (await Brand.DEAL.createManager(
        myChannel?.id as number,
        manager as MemberDto,
      )) as unknown as MemberDto;
    }

    await Brand.DEAL.assignDealsToManager(myChannel?.id as number, result?.id as number, {
      deals: deals
        ?.filter((deal) => deal.assigned)
        .map((deal) => {
          return {
            id: deal.id,
          };
        }) as SimpleDealDto[],
    });

    inviteOrDeleteUser(deals, [manager?.memberUser?.username as string]);
    return result;
  };

  return {
    updateManager,
  };
};

export default CSTownManagersController;
