import React, { CSSProperties } from 'react';
import { ChannelBoardDto, PostDto } from 'services/data-contracts';
import RootStore from 'stores';
import { useStore } from 'stores/StoreHelper';
import { PostModel, PostItemModel, CustomFile } from 'types/CommonTypes';
import { t } from 'i18next';
import { Row } from 'components/commons/layouts';
import ShareBox from 'components/commons/ShareBox';
import styled from 'styled-components';
import PostEditor from 'components/items/PostEditor';
import BoardEditor from '../components/BoardEditor';
import CalendarEditor from '../components/CalendarEditor';
import BoardItemSelector from '../components/BoardItemSelector';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import BottomSheetItem from 'components/commons/BottomSheetItem';

/**
 * @description  스토어 값에 유의할것(공유안됨)
 * @returns
 */
const boardCommonLogics = () => {
  const {
    channelStore,
    linkStore,
    postStore,
    myChannelStore,
    uiStore,
    userHomeStore,
    myPostStore,
    fileStore,
    uiStores,
  } = useStore();

  const getPostOrLinkItems = (board: ChannelBoardDto) => {
    if (board) {
      if (channelStore.channel?.channelName) {
        if (board.boardType === 'LINK') {
          linkStore.getLinks(channelStore.channel?.channelName, board.id as number);
        } else {
          postStore.getPosts(channelStore.channel?.channelName, board.id as number, true, {
            ownChannelId: myChannelStore.currentChannel?.id as number,
            size: board.viewType !== 'NONE' ? 30 : 10,
          });
        }
      }
    }
  };

  const handleClickEdit = (feed: PostModel, board: ChannelBoardDto) => {
    uiStore.universalModal.update({
      title: `${board?.name} ${t('Modify')}`,
      useDrawer: true,
      useHistoryBack: true,
      bottomSheetOptions: { blocking: false },
      modalOption: { style: { ...uiStore.universalModal.options?.modalOption?.style } },
      model: feed,
      children:
        // eslint-disable-next-line no-nested-ternary
        board.boardType === 'CALENDAR' ? (
          <CalendarEditor model={feed} />
        ) : board.boardType === 'BOARD' ? (
          <BoardEditor model={feed} />
        ) : (
          <PostEditor model={feed} />
        ),
    });
  };

  const handleSavePost = async (
    model: PostModel,
    onRegistComplete: (result: boolean, model: PostDto) => void,
    stay?: boolean,
  ) => {
    await preProcess(model);
    const result = await updatePost(model, typeof model?.id !== 'undefined', {
      uiStore,
      myChannelStore,
      channelStore,
      fileStore,
      uiStores,
      myPostStore,
      postStore,
    } as typeof RootStore);
    onRegistComplete(true, result);
    postStore.updateFeedByItem(result, model?.id ? 'update' : 'new');
    if (stay !== true) {
      uiStore.universalModal.close();
    }
  };

  const handleClickDelete = (feed: PostModel) => {
    uiStore.confirm.show({
      style: { maxWidth: 400, height: 220 },
      message: t('A221'),
      onConfirmed: async () => {
        userHomeStore.setPreventModelChange(true);
        if (channelStore.channel.isOwner) {
          const result = (await myPostStore.deletePost(
            channelStore.channel.id as number,
            feed?.board?.id as number,
            feed.id as number,
          )) as any;

          if (result) {
            postStore.updateFeedByItem(feed, 'delete');
          }
        }
        uiStore.universalModal.close();

        userHomeStore.setPreventModelChange(false);
      },
    });
  };

  const handleClickPost = (post: PostModel, board: ChannelBoardDto | null) => {
    if (board === null) {
      return;
    }
    const t = window.location.pathname;
    const state = `/${post.channel?.channelName}/${board.id}/${post.id}`;
    if (t !== state) {
      window.history.pushState('force', '', `${state}`);
    }
    const title =
      board.boardType === 'BOARD' ? (
        <>
          <Row style={{ justifyContent: 'center' }}>
            <NameStyle> {board.name}</NameStyle>
          </Row>
        </>
      ) : (
        post.title
      );

    uiStore.universalModal.show({
      useDrawer: true,
      useHistoryBack: true,
      title: <>{title}</>,
      modalOption: {
        style: { maxWidth: 800, marginBottom: 50, top: 100 },
      },
      bottomSheetOptions: {
        topPosition: 50,
        blocking: false,
      },
      useDefaultButtons: true,

      children: (
        <FeedLinkMobileContainerStyle>
          <BoardItemSelector item={post} />
        </FeedLinkMobileContainerStyle>
      ),
    });
  };

  const preProcess = async (model: PostModel) => {
    if (!model.items) {
      return;
    }
    return Promise.all(
      (await model?.items?.map(async (item: PostItemModel) => {
        item.content = JSON.stringify(item?.metadataObject);
        // }
        if (item?.file?.file) {
          const data = (await fileStore.uploadImage(
            item?.file.file,
            'Post',
          )) as unknown as CustomFile;
          item.file = { id: data.id };
        }
        return item;
      })) as unknown as PostItemModel[],
    );
  };

  const updatePost = async (model: PostModel, isModify: boolean, stores: typeof RootStore) => {
    try {
      if (model?.thumbnail?.file) {
        const data: CustomFile = (await stores.fileStore.uploadImage(
          model?.thumbnail.file,
          'Post',
        )) as any;
        model.thumbnail = { id: data.id };
      }

      if (typeof model.metadataObject !== 'undefined') {
        model.metadata = JSON.stringify(model.metadataObject);
      }
      if (isModify) {
        // TODO: 예외처리 필요
        if (stores.channelStore.channel?.isOwner) {
          return (await stores.myPostStore.updatePost(
            stores.channelStore?.channel?.id as number,
            // stores.uiStores.userHomeStore?.currentBoard?.id as number,
            model.board?.id as number,
            model?.id as number,
            model as PostModel,
          )) as any;
        } else {
          return (await stores.myPostStore.updatePost(
            model.channel?.id as number,
            model.board?.id as number,
            model?.id as number,
            model as PostModel,
          )) as any;
        }
      } else {
        if (!model.items) {
          model.items = [];
        }
        if (typeof model.isActive === 'undefined') {
          model.isActive = true;
        }
        //내 채널 카테고리가 아니어도 게시판을 글을 쓸 수 있음.
        if (stores.channelStore.channel?.isOwner) {
          return (await stores.postStore.createPost(
            stores.channelStore.channel?.id as number,
            model.board?.id as number,
            model as PostModel,
          )) as any;
        } else {
          return (await stores.myPostStore.createPost(
            stores.myChannelStore?.currentChannel?.id as number,
            model.board?.id as number,
            model as PostModel,
          )) as any;
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getImageFromDom = (content?: string) => {
    if (!content) {
      return null;
    }
    const parser = new DOMParser();
    const htmlDocument = parser.parseFromString(content, 'text/html');
    const images = htmlDocument.body.getElementsByTagName('img');
    if (images.length > 0) {
      return images[0].src;
    } else {
      return null;
    }
  };

  const getThumbnail = (feed: PostModel, style?: CSSProperties) => {
    return (
      <ImageContainerStyle style={style}>
        {/* image가 업로드되고 섬네일을 만드는동안 오류가 발생하여 원본을 바인딩한다. */}
        {feed.isNew ? (
          <IamgeStyle
            width={feed?.items?.[0]?.file?.width || 0}
            height={feed?.items?.[0]?.file?.height || 0}
            src={feed?.items?.[0]?.file?.publicUrl}
            alt=""
          />
        ) : (
          <IamgeStyle
            src={getFeedThmbnailUrl(feed?.items?.[0]?.file?.publicUrl, '300')}
            width={feed?.items?.[0]?.file?.width || 0}
            height={feed?.items?.[0]?.file?.height || 0}
            alt=""
          />
        )}
      </ImageContainerStyle>
    );
  };

  const getContentsThumbnail = (feed?: PostModel) => {
    const image = getImageFromDom(feed?.content);

    if (feed?.items && image === null) {
      return (
        <NoImageStyle>
          {/*
          <img src="/images/noimage.svg" style={{ width: 30, height: 30 }} alt="" />
          <div style={{ fontSize: 10, opacity: 0.5 }}>No image</div> */}
        </NoImageStyle>
      );
    }
    const tImage =
      feed?.items && feed.items?.length > 0
        ? getFeedThmbnailUrl(feed?.items[0].file?.publicUrl, '300')
        : getFeedThmbnailUrl(image!, '300');

    return (
      <ImageContainerStyle2
        src={tImage}
        style={{ width: 60, height: 60, objectFit: 'cover' }}
        alt=""
      />
    );
  };

  const getFeedThmbnailUrl = (url?: string, size?: '150' | '300' | '900') => {
    if (url) {
      const same = isSameOrigin(url);
      if (same) {
        const first = new URL(url || '');
        return `${first.origin}/thumbnails/${size}${first.pathname}`;
      } else {
        return url;
      }
    }
    return '';
  };

  const isSameOrigin = (url: string) => {
    try {
      const urlObj = new URL(url);
      return window.location.hostname === urlObj.hostname;
    } catch (e) {
      return false;
    }
  };

  const showShareBox = (subject: string, url: string) => {
    uiStore.bottomSheet.show({
      // title: 'Share',
      children: (
        <div>
          <ShareBox url={url} subject={subject} />
        </div>
      ),
    });
  };

  const showEditBox = (item: PostDto, board: ChannelBoardDto) => {
    uiStore.bottomSheet.show({
      blocking: false,
      // title: 'Share',
      children: (
        <div>
          <Row direction="column" style={{ margin: 30, marginTop: 30 }}>
            <BottomSheetItem
              onClick={() => {
                handleClickEdit(item, board);
                uiStore.bottomSheet.close();
              }}
              label={t('Edit')}
            >
              <EditIcon />
            </BottomSheetItem>
            <BottomSheetItem
              onClick={() => {
                handleClickDelete(item);
              }}
              label={t('Delete')}
            >
              <DeleteOutlineIcon />
            </BottomSheetItem>
          </Row>
        </div>
      ),
    });
  };

  return {
    getPostOrLinkItems,
    handleSavePost,
    handleClickPost,
    handleClickEdit,
    handleClickDelete,
    getImageFromDom,
    getThumbnail,
    getContentsThumbnail,
    getFeedThmbnailUrl,
    showShareBox,
    showEditBox,
  };
};

const FeedLinkMobileContainerStyle = styled.div`
  width: 100%;
  position: relative;
`;

const ImageContainerStyle = styled.div`
  display: flex;
  align-items: center;
  aspect-ratio: 1 / 1;
  overflow: hidden;
`;

const ImageContainerStyle2 = styled.img`
  display: flex;
  align-items: center;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  border-radius: 10px;
  object-fit: cover;
`;

const IamgeStyle = styled.img<{ width: number; height: number }>`
  width: ${(props) => (props.width < props.height ? '100%' : 'auto')};
  height: ${(props) => (props.width < props.height ? 'auto' : '100%')};
`;

const NoImageStyle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  align-items: center;
  width: 40px;
  padding: 10px;
  border-radius: 10px;
  /* background: #efefef; */
  justify-content: space-evenly;
`;

const NameStyle = styled.div`
  margin-left: 10px;
`;

export default boardCommonLogics;
