import React, { useState } from 'react';
import NoContent from 'components/commons/NoContent';
import DealList from 'features/myPage/containers/DealList';
import { LinkDto } from 'services/data-contracts';
import CSTownDealsController from 'features/csTown/pages/pageControllers/CSTownDealsController';
import { AnimatedTabButton, TabItem } from 'components/commons/buttons/AnimatedTabButton';
import { Row } from 'components/commons/layouts';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import StyleBox from 'components/commons/StyleBox';
import Deal from 'features/management/components/block/Deal/Deal';
import { useStateContext } from 'libs/hooks/usePageState';
import { BottomButtonStyle } from './ButtonContainer';

/**
 * @description Layout참고 할것
 * @param param0
 * @returns
 */
const DealListContainer = ({ deals }: { deals?: LinkDto[] }) => {
  const { setDealsByType } = CSTownDealsController();
  const { uiStore } = useStore();
  const { broadcast } = useStateContext();

  const [selectedTab, setSelectedTab] = useState<string>('진행중');

  const handleTabChange = (item: any) => {
    const name = item.props.children;
    const nameDisplay = name === '완료됨' ? '완료된' : name;
    setSelectedTab(nameDisplay);
    const key = Number(item.key);
    setDealsByType(key);
  };

  const handleClickNewDeal = () => {
    uiStore.modal.show({
      title: '공구 등록',
      style: { borderRadius: 0, padding: 20, overflow: 'scroll' },
      children: (
        <Deal
          onUpdate={() => {
            broadcast({ id: 'reload' });
          }}
        />
      ),
    });
  };

  return (
    <Row style={{ flexDirection: 'column', height: '100%' }}>
      <div
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          padding: 10,
          background: '#f0f0f0',
        }}
      >
        <AnimatedTabButton
          onClick={(item: any) => {
            handleTabChange(item);
          }}
          style={{ width: '100%' }}
        >
          <TabItem key={1}>진행중</TabItem>
          <TabItem key={2}>대기중</TabItem>
          <TabItem key={3}>완료됨</TabItem>
        </AnimatedTabButton>
      </div>
      <div style={{ flexGrow: 1, width: '100%', paddingBottom: 150 }}>
        {typeof deals !== 'undefined' && deals?.length > 0 ? (
          <div style={{ padding: 20, flexGrow: 1 }}>
            <DealList deals={deals} />
          </div>
        ) : (
          <NoContent title={`${selectedTab} 공구가`} style={{ marginTop: 'calc(100vh / 4)' }} />
        )}
      </div>
      <BottomButtonStyle>
        <OhmossButton onClick={handleClickNewDeal} type="primaryPc">
          공구 등록
        </OhmossButton>
      </BottomButtonStyle>
    </Row>
  );
};

export default DealListContainer;
