import React, { useEffect, useLayoutEffect, useState } from 'react';

import styled from 'styled-components';
import MobileModalHeader from 'components/commons/MobileModalHeader';
import useToken from 'libs/hooks/useToken';
import NotLoginMenus from 'features/mossMainSettings/components/NotLoginMenus';
import useChannelSelector from 'libs/hooks/useChannelSelector';
import BizMemberMenus from 'features/mossMainSettings/components/BizMemberMenus';
import MemberMenus from 'features/mossMainSettings/components/MemberMenus';
import DetailPageHeader from 'components/commons/DetailPageHeader';

const MainSettingContainer = () => {
  const { initializeFirstChannel } = useChannelSelector();
  const { validUser } = useToken();
  const [userType, setUserType] = useState<'annoymourse' | 'biz' | 'member'>();

  const getMenus = () => {
    switch (userType) {
      case 'annoymourse':
        return <NotLoginMenus />;
      case 'biz':
        return <BizMemberMenus />;
      case 'member':
        return <MemberMenus />;
      default:
        return <div></div>;
    }
  };

  const init = async () => {
    const result = await validUser();
    if (result) {
      const channelInfo = await initializeFirstChannel();
      setUserType(channelInfo?.isBusiness ? 'biz' : 'member');
    } else {
      setUserType('annoymourse');
    }
  };
  useLayoutEffect(() => {
    init();
  }, []);

  return (
    <MenuItemGroupStyle>
      {/* <DetailPageHeader title="설정" /> */}
      {typeof userType !== 'undefined' && <>{getMenus()}</>}
    </MenuItemGroupStyle>
  );
};

const MenuItemGroupStyle = styled.div`
  height: 100vh;
  background: red;
`;
export default MainSettingContainer;
