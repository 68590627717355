import React, { useEffect, useRef, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { PostDto } from 'services/data-contracts';

import AdPostEditor from 'components/items/AdPostEditor';
import useNavigation from 'hooks/useNavigation';
import MobileModalHeader from 'components/commons/MobileModalHeader';
import UniversalLayout from 'components/commons/layouts/UniversalLayout';
import useLocalParams from 'libs/hooks/useLocalParams';
import DetailPageHeader from 'components/commons/DetailPageHeader';

const NewPostPage = observer(() => {
  const { channelId, boardId, postId } = useLocalParams();
  const { myPostStore, uiStore } = useStore();
  const navigation = useNavigation();

  const [model, setModel] = useState<PostDto>();

  const handleModelRegistred = (result: PostDto) => {
    if (result.id) {
      if (boardId === result.board?.id) {
        navigation.pop();
      } else {
        uiStore.confirm.show({
          message: '작성한 게시물을 확인하시겠습니까?',
          onConfirmed: () => {
            navigation.navigate(
              `/${result.channel?.channelName}/${result.board?.id}/${result.id}?cpcode=0064`,
            );
          },
        });
      }
    }
  };

  const getPost = async () => {
    if (channelId && boardId && postId) {
      const result = (await myPostStore.getPost(channelId, boardId, postId)) as never as PostDto;
      setModel(result);
    } else {
      setModel({});
    }
  };

  useEffect(() => {
    getPost();
  }, []);

  useEffect(() => {
    if (!boardId) {
      alert('게시판이 바르지 않습니다. 다시 시도 해 주세요.');
      navigation.pop();
    }
  }, []);

  return (
    <UniversalLayout>
      {/* <DetailPageHeader title={model?.id ? '포스트 수정' : '새 포스트'} /> */}
      <AdPostEditor
        isAdBoard
        saveText="저장"
        boardId={boardId as number}
        model={model}
        onRegistred={handleModelRegistred}
      />
    </UniversalLayout>
  );
});

export default NewPostPage;
