import { makeObservable, flow, observable, action } from 'mobx';
import { LinkService } from 'services/LinkService';
import { LinkService2 } from 'services/LinkService2';
import { HttpClient } from 'services/http-client';
import { LinkDto } from 'services/data-contracts';
import _ from 'lodash';
import ServiceStoreBase from './ServiceStoreBase';

/**
 * @description Public Link Store
 */
class LinkStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      links: observable,
      link: observable,
      getLinks: flow,
      getDealMembers: flow,
      getLink: flow,
      createLog: flow,
      createDealVisitLog: flow,
      getItemByKey: flow,
      getDealOptions: flow,
      getEndedDealList: flow,
      encoreDeal: flow,
      unMount: action,
    });
  }

  api: LinkService<unknown> = new LinkService(new HttpClient());

  api2: LinkService2<unknown> = new LinkService2(new HttpClient());

  links: Array<LinkDto> | undefined;

  link: LinkDto;

  /** ========================================================================================= */

  // *getItems(args?: Array<any>) {
  *getLinks(channelName: string, boardId: number) {
    if (channelName && boardId) {
      const { response } = yield this.api.getLinks1(channelName, boardId);
      this.links = response.data.slice();
      return response.data;
    }
    return [];
  }

  *getLink(channelName: string, boardId: number, linkId: number) {
    const { response } = yield this.api.getLink3(channelName, boardId, linkId);
    return response.data;
  }

  *getItemByKey(linkKey: string) {
    const { response } = yield this.api2.getLink2(linkKey);
    return response.status < 300 ? response.data : null;
  }

  *getDealMembers(linkKey: string) {
    const { response } = yield this.api2.getDealMembers(linkKey);
    return response.status < 300 ? response.data : null;
  }

  *getDealOptions(channelName: string, boardId: number, linkId: number) {
    const { response } = yield this.api.getDealOptions1(channelName, boardId, linkId);
    return response.status < 300 ? response.data : [];
  }

  *getEndedDealList(channelName: string) {
    const { response } = yield this.api.getEndedDeals(channelName);
    return response.status < 300 ? response.data : [];
  }

  *createLog(linkKey: string) {
    yield this.api2.recodeLinkClick(linkKey);
  }

  *createDealVisitLog(linkKey: string) {
    yield this.api2.recodeLinkOutboundClick(linkKey);
  }

  *encoreDeal(linkKey: string, ownChannelId: number) {
    const { response } = yield this.api2.encoreLink(linkKey, { ownChannelId });
    return response.status < 300 ? response.data : null;
  }

  unMount() {
    this.links = undefined;
  }
}

export default LinkStore;
