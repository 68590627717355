import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styled from 'styled-components';
import { Row } from 'components/commons/layouts';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabButtons({
  items,
  itemCounts,
  initialValue,
  onHandleTabClick,
}: {
  items: string[];
  itemCounts?: number[];
  initialValue?: string;
  onHandleTabClick: (label: string) => void;
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleTabClick = (label: string) => {
    onHandleTabClick(label);
  };

  const TabItem = ({ count }: { count: number }) => {
    if (typeof itemCounts === 'undefined') {
      return <>{items[count]}</>;
    } else {
      const totlaCount = itemCounts[count];
      return (
        <>
          {items[count]}
          {/* <BadgeStyle hasCount={totlaCount > 0}>{totlaCount > 0 && itemCounts[count]}</BadgeStyle> */}
        </>
      );
    }
  };

  React.useEffect(() => {
    if (initialValue && items) {
      const i = items.findIndex((item: string) => item === initialValue);
      setValue(i || 0);
    }
  }, [initialValue, items]);

  return (
    <Tabs
      style={{ minHeight: 0 }}
      sx={{
        background: '#fff',
        borderBottom: '1px solid #ebebeb',
        '& .MuiTabs-root': { margin: '0' },
      }}
      TabIndicatorProps={{ style: { background: '#173300' } }}
      value={value}
      onChange={(event: React.SyntheticEvent, newValue: number) => handleChange(event, newValue)}
    >
      {items.map((item: string, i: number) => (
        <Tab
          sx={{
            width: `${100 / items.length}%`,
            fontSize: '14px',
            fontWeight: '500',
            color: 'grey',
            '&.Mui-selected': {
              color: '#173300',
            },
          }}
          label={
            <Row>
              <TabItem count={i} />
            </Row>
          }
          onClick={() => handleTabClick(item)}
          {...a11yProps(1)}
          key={i}
        />
      ))}
    </Tabs>
  );
}
