/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MembershipDto,
  MembershipGiftDto,
  MembershipPurchaseDto,
  OrderDto,
  TosspayApprovalDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MembershipService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 모든 맴버십 목록을 제공합니다.
   *
   * @tags MembershipService
   * @name GetMemberships
   * @summary 맴버십 목록
   * @request GET:/v2/me/channels/{channelId}/memberships
   * @secure
   */
  getMemberships = (
    channelId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["purchaseDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<MembershipDto[], any>({
      path: `/v2/me/channels/${channelId}/memberships`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 맴버십 구매
   *
   * @tags MembershipService
   * @name PurchaseMembership
   * @summary 맴버십 구매
   * @request POST:/v2/me/channels/{channelId}/memberships
   * @secure
   */
  purchaseMembership = (
    channelId: number,
    data: MembershipPurchaseDto,
    params: RequestParams = {},
  ) =>
    this.http.request<MembershipDto, any>({
      path: `/v2/me/channels/${channelId}/memberships`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 맴버십을 선물합니다.
   *
   * @tags MembershipService
   * @name PurchaseGiftMembership
   * @summary 맴버십 선물하기
   * @request POST:/v2/me/channels/{channelId}/memberships/gifts
   * @secure
   */
  purchaseGiftMembership = (
    channelId: number,
    data: MembershipPurchaseDto,
    params: RequestParams = {},
  ) =>
    this.http.request<OrderDto, any>({
      path: `/v2/me/channels/${channelId}/memberships/gifts`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 맴버십 선물 결제승인
   *
   * @tags MembershipService
   * @name ConfirmGiftPayment
   * @summary 맴버십 선물 결제승인
   * @request POST:/v2/me/channels/{channelId}/memberships/gifts/approve
   * @secure
   */
  confirmGiftPayment = (
    channelId: string,
    query: {
      /** @format int64 */
      ownChannelId: number;
    },
    data: TosspayApprovalDto,
    params: RequestParams = {},
  ) =>
    this.http.request<MembershipGiftDto, any>({
      path: `/v2/me/channels/${channelId}/memberships/gifts/approve`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 선택된 맴버십의 정보를 제공합니다.
   *
   * @tags MembershipService
   * @name GetMembership
   * @summary 맴버십 정보
   * @request GET:/v2/me/channels/{channelId}/memberships/{membershipId}
   * @secure
   */
  getMembership = (channelId: number, membershipId: number, params: RequestParams = {}) =>
    this.http.request<MembershipDto, any>({
      path: `/v2/me/channels/${channelId}/memberships/${membershipId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 현재 활성화된 맴버십을 해지합니다.
   *
   * @tags MembershipService
   * @name CancelMembership
   * @summary 맴버십 해지
   * @request DELETE:/v2/me/channels/{channelId}/memberships/{membershipId}
   * @secure
   */
  cancelMembership = (channelId: number, membershipId: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/memberships/${membershipId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 현재 구독 중인 맴버십 정보를 제공합니다.
   *
   * @tags MembershipService
   * @name GetCurrentMembership
   * @summary 현재 맴버십 정보
   * @request GET:/v2/me/channels/{channelId}/current-membership
   * @secure
   */
  getCurrentMembership = (channelId: number, params: RequestParams = {}) =>
    this.http.request<MembershipDto, any>({
      path: `/v2/me/channels/${channelId}/current-membership`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
