import React from 'react';
import { Divider } from '@mui/material';
import { MembershipPlanDto } from 'services/data-contracts';
import NumberFormat from 'react-number-format';
import { Description, DetailsContainer, DetailsHeader, DetailsItem } from './styles';

const Common = ({
  texts,
  plan,
  description,
}: {
  texts: string[];
  plan: MembershipPlanDto;
  description: string;
}) => {
  return (
    <>
      <Description>{description}</Description>
      <DetailsContainer>
        <DetailsHeader>
          {plan?.name}
          <br />
          <NumberFormat
            value={plan?.amount}
            style={{ fontSize: 24 }}
            displayType={'text'}
            suffix="원"
            thousandSeparator
          />
        </DetailsHeader>
        <Divider />
        <>
          {texts?.map((feature, index) => (
            <>
              <DetailsItem>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 14 }}>
                  <img src="/images/radio_check_btn.svg" alt="" />
                  {feature}
                </div>
              </DetailsItem>
            </>
          ))}
        </>
      </DetailsContainer>
    </>
  );
};

export default Common;
