import React, { useEffect, useState, useRef } from 'react';
import { FileDto } from 'services/data-contracts';
import { CropType, CustomFile } from 'types/CommonTypes';
import { useStore } from 'stores/StoreHelper';
import ImageCropper from 'features/adminFeatures/commons/components/ImageCropper';
import styled from 'styled-components';
import DeleteButton from 'resources/controls/DeleteButton';
import { convertBase64Files2 } from './commonLogin';

import CropperImage from './CropperImage';
import SingleImage from './SingleImage';
import SingleFile from './SingleFile';
import { Row } from '../layouts';

/**
 * @description 이미지 선택부터 크롭까지 제공한다.
 * @params type : copper, single, multiple
 * @returns
 */
const FileUploader = ({
  onSelected,
}: {
  onSelected?: (image: CustomFile | CustomFile[] | undefined) => void;
}) => {
  const { uiStore } = useStore();
  const onChangeFile = useRef<any>(null);

  const localFiles = useRef<any>(null);
  const [accept, setAccept] = useState<string>('');
  const [files, setFiles] = useState<any>();

  const converbiteToMb = (size: number) => {
    const fileSizeInBytes = size;
    return (fileSizeInBytes / (1024 * 1024)).toFixed(3);
  };

  // useEffect(() => {
  //   console.log('files', typeof files);
  // }, [files]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <SingleFile
          onClick={(e: any) => {
            e.preventDefault();
            onChangeFile.current.click(e);
          }}
        />
        {files && (
          <>
            {Object.entries(files).map(([key, value]) => (
              <Row
                style={{
                  justifyContent: 'flex-start',
                  width: 'usent',
                  gap: 10,
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <div>{(value as any)?.name}</div>
                <div style={{ fontSize: 12, color: '#666' }}>
                  {converbiteToMb((value as any)?.size)}MB
                </div>
                <DeleteButton
                  onClick={() => {
                    setFiles(undefined);
                    onChangeFile.current.value = '';
                  }}
                />
              </Row>
            ))}
          </>
        )}

        {/* {files?.map((item: any) => <div>{item?.name}</div>)} */}
      </div>
      <input
        type="file"
        multiple={false}
        // accept={accept}
        id="file"
        ref={onChangeFile}
        onInput={async () => {
          localFiles.current = onChangeFile.current.files;
          setFiles(localFiles.current);
          onSelected?.(localFiles.current);
        }}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default FileUploader;
