import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { ImageUploader } from 'components/commons';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import TitleForm from 'components/items/forms/TitleForm';
import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { CustomFile } from 'types/CommonTypes';
import { Align, IFooterBlock, Layout } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import UrlForm from 'components/items/forms/UrlForm';
import { Sticker_01 } from 'features/management/resources/stickers';
import { LinkTypes } from 'features/management/context/providers/ContextTypes';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import { validationHelper } from 'controls/validationHelper';
import CommonFontSelect from '../commonControl/CommonFontSelect';
import CommonLayout from '../commonControl/CommonLayout';
import CommonAlign from '../commonControl/CommonAlign';
import CommonColorSet from '../commonControl/CommonColorSet';
import CommonFontSize from '../commonControl/CommonFontSize';
import BlockController from 'controllers/BlockController';
import ActionButton from './ActionButton';

const Footer = ({ blockModel, seq }: { blockModel?: IFooterBlock; seq?: number }) => {
  const { uiStore } = useStore();
  const defaultProps: Partial<IFooterBlock> = {
    linkType: 'Footer',
    isActive: true,
    seq,
  };

  const { initialModel, updateServiceModel, initialBinding, serviceModel } =
    useOhmossContext<LinkTypes<IFooterBlock>>();

  const { updateBlock } = BlockController<IFooterBlock>();
  const [isValid, setIsValid] = useState<boolean>(false);

  const [localModel, setLocalModel] = useInitializeModel<Partial<IFooterBlock>>(
    blockModel,
    defaultProps,
    initialBinding,
  );

  useEffect(() => {
    if (localModel) {
      const titleValid = validationHelper.required(localModel?.title);
      const urlValid = validationHelper.urlOrEmpty(localModel?.linkUrl);
      setIsValid(titleValid && urlValid);
    }
  }, [localModel]);

  const [style, setStyle] = useState<CSSProperties>();
  const defaultStyle = useRef<CSSProperties>();

  const initializeModel = () => {
    if (blockModel) {
      const newStyle = JSON.parse((blockModel.contentStyle as string) || '{}');
      defaultStyle.current = newStyle;
      setStyle(newStyle);
    }
  };

  useEffect(() => {
    if (style) {
      setLocalModel('contentStyle', JSON.stringify(style || {}));
      updateServiceModel('contentStyle', JSON.stringify(style || {}));
    }
  }, [style]);

  useEffect(() => {
    initializeModel();
    return () => {
      initialBinding(undefined);
    };
  }, [blockModel]);

  return (
    <div style={{}}>
      <div style={{ marginBottom: 20 }}>
        <ImageUploader
          type="single"
          useCrop={false}
          // useCircularCrop={false}
          //   style={{ marginTop: 20, justifyContent: 'center' }}
          initialValue={initialModel?.thumbnail}
          attachType="image"
          onSelected={(image: CustomFile) => {
            updateServiceModel('thumbnail', image);
          }}
        />
      </div>
      <ItemSectionStyle>
        <ItemSectionTitle>타이틀</ItemSectionTitle>
        <ItemContent>
          <TitleForm
            onChangeValue={(value: string) => {
              updateServiceModel('title', value);
              setLocalModel('title', value);
            }}
            initialValue={initialModel?.title}
            placeHolder="타이틀을 작성해 주세요"
            label="타이틀"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>푸터 내용</ItemSectionTitle>
        <TitleForm
          initialValue={initialModel?.content}
          multiline
          rows={10}
          maxLength={500}
          useCount
          onChangeValue={(value: string) => {
            updateServiceModel('content', value);
          }}
        />
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>링크주소</ItemSectionTitle>
        <ItemContent>
          <UrlForm
            onChangeValue={(value: string) => {
              updateServiceModel('linkUrl', value);
              setLocalModel('linkUrl', value);
            }}
            initialValue={initialModel?.linkUrl}
            label="링크주소"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <MenuItemCheckBox
          initialValue={initialModel?.fullWidth}
          onChangeValue={(value: boolean) => {
            updateServiceModel('fullWidth', value);
          }}
          title={'가로 채우기'}
        />
        <MenuItemCheckBox
          onChangeValue={(value: boolean) => {
            updateServiceModel('useTransparent', value);
          }}
          initialValue={initialModel?.useTransparent}
          title="투명배경"
        />

        <MenuItemCheckBox
          initialValue={initialModel?.openNewWindow}
          onChangeValue={(value: boolean) => {
            updateServiceModel('openNewWindow', value);
          }}
          title="새창열기"
        />
      </ItemSectionStyle>
      <CommonFontSize
        initialValue={style?.fontSize}
        onChange={(value) => {
          setStyle((prev: CSSProperties) => {
            return { ...prev, fontSize: value };
          });
        }}
      />
      <CommonFontSelect
        // initialValue={initialModel?.contentStyle}
        onChange={(value: CSSProperties) => {
          setStyle((prev: CSSProperties) => {
            return { ...prev, ...value };
          });
        }}
      />
      <CommonLayout
        initialValue={initialModel?.layout || 'bottom'}
        onChange={(layout: Layout) => updateServiceModel('layout', layout)}
      />
      <CommonAlign initialValue={initialModel?.contentAlign || 'center'} />
      <CommonColorSet
        initialValue={style?.color}
        onChange={(color: string) => {
          setStyle((prev: CSSProperties) => {
            return {
              ...prev,
              color,
            };
          });
        }}
      />
      <ActionButton isValid={isValid} serviceModel={serviceModel} />
    </div>
  );
};

export default Footer;
