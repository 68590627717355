import { makeObservable, observable } from 'mobx';
import { Tag as TagService } from 'services/Tag';
import { REST_API_URL, REST_API_URL_V2 } from 'stores/AppStore';
import { TagDto } from 'services/data-contracts';
import { GroupedTag } from 'types/CommonTypes';

import _ from 'lodash';
import { HttpClient } from 'services/http-client';
import ServiceStoreBase from './ServiceStoreBase';

export interface isValid {
  isValid: boolean;
}

class TagStore extends ServiceStoreBase {
  tags: Array<TagDto> = [];

  groupTags?: Array<GroupedTag>;

  constructor() {
    super();
    makeObservable(this, {
      tags: observable,
      groupTags: observable,
    });
  }

  api: TagService<unknown> = new TagService(new HttpClient());

  *getItems(args: Array<never>) {
    const { response } = yield this.api.getTags1(args[0]);
    const grouped = _.chain(response.data)
      .groupBy('groupName')
      .map((tags, groupName) => ({ groupName, tags }))
      .value();
    this.groupTags = grouped;
    this.tags = response.data;
    return grouped;
  }
}

export default TagStore;
