import React, { useEffect, useRef } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';

import Follower from '../components/Follower';
import TagList from 'features/mainFeatures/commons/components/TagList';
import CRUDHelper from 'libs/helper/crudHelper';
import LinkHeaderStyle from 'resources/controls/LinkHeaderStyle';

/**
 * @description Follower Page의 팔로워 목록을 표시하는 컨테이너이며
 * @description 상단에 태그로도 팔로워를 필터링 할 수 있도록 태그목록도 포함한다.
 */
const Followers = observer(() => {
  const { myChannelStore, myTagStore } = useStore();
  const crudHelper = new CRUDHelper(myTagStore);
  const tagListRef = useRef<any>(null);

  const handleSelectedTags = (tags: Array<number>) => {
    myChannelStore.getMyFollowersByTags(myChannelStore.currentChannel?.id as number, tags);
  };

  useEffect(() => {
    if (myChannelStore.currentChannel) {
      myChannelStore.getMyFollowers(myChannelStore.currentChannel.id as number);
      crudHelper.getItems(myChannelStore.currentChannel.id as number);
    }
  }, [myChannelStore.currentChannel]);

  return (
    <>
      <div>
        <TagList ref={tagListRef} tags={myTagStore.groupTags} onSelected={handleSelectedTags} />
      </div>

      <LinkHeaderStyle style={{ marginTop: 50 }}>
        신규 팔로워
        <div>
          최근{' '}
          <span>
            <b style={{ color: 'brown' }}>3</b>
          </span>
          일간의 팔로워 목록입니다
        </div>
      </LinkHeaderStyle>

      {/* {myChannelStore.myFollowers?.map((follower: UserProfileDto) => (
        <Follower follower={follower} />
      ))} */}
    </>
  );
});

export default Followers;
