import React, { useState, useEffect, memo, CSSProperties } from 'react';
import styled from 'styled-components';
import { Row } from 'components/commons/layouts';
import { IMapBlock } from 'types/BlockExtendsTypes';
import { GoogleMap } from 'components/maps';
import { Map } from 'types/CommonTypes';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { copyToClipboard } from 'libs/helper/utils';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { IconButton } from '@mui/material';
import { ContentWrapper } from '../../common/BlockTitleBar';
import CommonThumbnail from '../../commonControl/CommonThumbnail';

const MapLinkTemplate = ({
  item,
  textStyle,
  boxStyle,
  onClick,
  onClickMore,
}: {
  item: IMapBlock;
  textStyle?: CSSProperties;
  boxStyle?: CSSProperties;
  onClick: () => void;
  onClickMore?: () => void;
}) => {
  const [mapInfo, setMapInfo] = useState<Map>();
  const [expand, setExpand] = useState<boolean>();

  useEffect(() => {
    if (item) {
      const lat = item?.latitude;
      const lng = item?.longitude;
      setExpand(item.collapse);

      setMapInfo({
        lat,
        lng,
        title: '',
        addr: '',
      });
    }
  }, [item]);

  return (
    <ContainerStyle>
      <ContentWrapper style={{ ...textStyle }}>
        <Row style={{ justifyContent: 'space-between' }}>
          <div style={{ padding: '5px', marginRight: 5 }}>
            <CommonThumbnail
              initialValue={item.thumbnail?.publicUrl}
              parent={boxStyle?.borderRadius}
              style={{
                borderRadius: boxStyle?.borderRadius,
                minHeight: boxStyle?.minHeight,
                minWidth: boxStyle?.minHeight,
              }}
            />
          </div>
          <Row
            style={{
              marginRight: item.thumbnail?.publicUrl && 10,
              justifyContent:
                item?.contentAlign === 'left'
                  ? 'flex-start'
                  : item.contentAlign === 'right'
                  ? 'flex-end'
                  : 'center',
            }}
          >
            <div style={{ padding: !item.thumbnail?.publicUrl ? '10px 0' : 'none' }}>
              {item?.showTitle && <div style={{ ...textStyle }}>{item.title}</div>}
              <div style={{ justifyContent: 'space-between' }}>
                <div style={{ ...textStyle }}>{item.address}</div>
              </div>
            </div>
          </Row>
          <Row style={{ width: 'unset', justifyContent: 'flex-end', marginRight: 10 }}>
            <div>
              <IconButton
                onClick={() => {
                  copyToClipboard(item.address);
                }}
              >
                <AssignmentOutlinedIcon style={{ width: 20 }} />
              </IconButton>
              {/* <div style={{ fontSize: 12, minWidth: 50 }}>주소복사</div> */}
            </div>
            <div>
              <IconButton
                onClick={() => {
                  setExpand((prev) => !prev);
                }}
              >
                {expand ? (
                  <ExpandLessIcon style={{ color: '#000' }} />
                ) : (
                  <ExpandMoreIcon style={{ color: '#000' }} />
                )}
              </IconButton>
            </div>
          </Row>
        </Row>
      </ContentWrapper>
      <MapWrapperStyle show={expand}>
        <GoogleMap map={mapInfo} />
      </MapWrapperStyle>
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  /* background: #fff; */
  /* border-radius: 10px; */
  width: 100%;
`;

const MapWrapperStyle = styled.div<{ show?: boolean }>`
  height: ${(props) => (props.show ? 'auto' : '0px')};
  overflow: ${(props) => (props.show ? 'auto' : 'hidden')};
`;

export default memo(MapLinkTemplate);
