import React, { useEffect, useState } from 'react';

import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { DealsTypes } from 'features/management/context/providers/ContextTypes';
import { DailyCountSeriesDto, LinkDto, DealInquiryDto } from 'services/data-contracts';
import { useParams } from 'react-router-dom';
import { Row } from 'components/commons/layouts';
import { Avatar } from '@mui/material';
import { useStore } from 'stores/StoreHelper';

import moment from 'moment';
import NoContent from 'components/commons/NoContent';
import MyAnalyticsStore from 'stores/MyAnalyticsStore';
import styled from 'styled-components';
import KMFormater from 'components/commons/KMFormater';
import { useRootContext } from 'libs/hooks/useRootContext';
import DealInfo2 from '../components/DealInfo2';
import DealInfluencerInquiry from '../components/DealSellersInquiry';

import 'moment/locale/ko';

const DealDetailPage = () => {
  moment.locale('ko');
  const myAnalyticsStore = new MyAnalyticsStore();
  const { adid } = useParams();
  const { uiStore } = useStore();
  const { getDeal, getReceivedInquiriesByDeal } = useOhmossContext<DealsTypes>();
  const { myChannel } = useRootContext();

  const [deal, setDeal] = useState<LinkDto>();
  const [dealInquiries, setDealInquiries] = useState<DealInquiryDto[]>();
  const [dailyClick, setDailyClick] = useState<number>(0);
  const [dailyVisit, setDailyVisit] = useState<number>(0);
  const [dailyEncore, setDailyEncore] = useState<number>(0);

  const getInquiries = async (dealId: number) => {
    const inquiries = await getReceivedInquiriesByDeal(dealId);
    setDealInquiries(inquiries as unknown as DealInquiryDto[]);
  };

  const getAnalytics = async (deal: LinkDto) => {
    const dailyDealClick = (await myAnalyticsStore.getDailyClicks(
      deal?.id as number,
    )) as unknown as DailyCountSeriesDto[];
    const total = dailyDealClick.reduce((total, item) => total + item.count!, 0);
    setDailyClick(total);

    const dailyVisit = (await myAnalyticsStore.getOutboundClicks(
      deal?.id as number,
    )) as unknown as DailyCountSeriesDto[];

    const visitTotal = dailyVisit.reduce((total, item) => total + item.count!, 0);
    setDailyVisit(visitTotal);

    const dailyEncore = (await myAnalyticsStore.getEncoreCounts(
      deal?.id as number,
      moment(deal?.endDate as string).format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
    )) as unknown as DailyCountSeriesDto[];

    const encoreTotal = dailyEncore.reduce((total, item) => total + item.count!, 0);
    setDailyEncore(encoreTotal);
  };

  const handleClickInquiry = (inquiry: DealInquiryDto) => {
    uiStore.modal.show({
      style: { height: '80vh' },
      modalType: 'modal',
      children: <DealInfluencerInquiry inquiry={inquiry} />,
      onClose: () => {
        getInquiries(deal!.id as number);
      },
    });
  };

  useEffect(() => {
    if (adid && myChannel) {
      const adId = Number(adid);
      const getDealById = async () => {
        const deal = (await getDeal(adId)) as unknown as LinkDto;
        setDeal(deal);
        getInquiries(deal.id as number);
        getAnalytics(deal);
      };
      getDealById();
    }
  }, [adid, myChannel]);

  return (
    <>
      <div>
        <DealInfo2 item={deal} cutOff={1000} />
        <Row style={{ gap: 20, margin: '20px 0' }}>
          <CountStyle>
            <KMFormater num={dailyClick} />

            <div>공구 클릭 수</div>
          </CountStyle>
          {/* <CountStyle>
            <div>{dailyVisit}</div>
            <div>채널 방문 수</div>
          </CountStyle> */}
          <CountStyle>
            <KMFormater num={dailyVisit} />
            <div>공구 방문 수</div>
          </CountStyle>
          <>
            {deal?.status === 'ENDED' && (
              <CountStyle>
                <KMFormater num={dailyEncore} />
                <div>앵콜 요청 수</div>
              </CountStyle>
            )}
          </>
        </Row>
        {dealInquiries && dealInquiries?.length > 0 && (
          <>
            <div style={{ padding: 20, fontWeight: 700 }}>문의내역</div>
            {dealInquiries?.map((inquiry) => (
              <Row
                style={{
                  width: 'unset',
                  justifyContent: 'flex-start',
                  padding: '10px 20px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleClickInquiry(inquiry);
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <Avatar
                    src={inquiry.sellerChannel?.profile?.profileImage?.publicUrl}
                    style={{ width: 50, height: 50 }}
                  />
                </div>
                <div style={{ flexGrow: 1 }}>
                  <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <div>{inquiry.sellerChannel?.channelName}</div>
                    <div style={{ fontSize: 12 }}>
                      {moment(inquiry.latestThread?.createDate).fromNow()}
                    </div>
                  </Row>
                  <div>
                    <span style={{ fontSize: 13, color: '#777' }}>
                      {inquiry.latestThread?.message}
                    </span>
                  </div>
                </div>
              </Row>
            ))}
          </>
        )}
        <>{dealInquiries?.length === 0 && <NoContent title="등록된 문의가 " />}</>
      </div>
    </>
  );
};

const CountStyle = styled.div`
  text-align: center;
  > div:first-child {
    font-size: 30px;
    font-weight: 900;
    font-family: 'Zen Dots';
  }
`;
export default DealDetailPage;
