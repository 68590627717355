import React from 'react';
import { makeObservable, observable, action, flow } from 'mobx';
import { MyChannelStyle as MyChannelStyleService } from 'services/MyChannelStyle';
import { ChannelStyleDto } from 'services/data-contracts';
import _ from 'lodash';
import ServiceStoreBase from './ServiceStoreBase';

import { HttpClient } from 'services/http-client';
import { ChannelStyleModel } from 'types/CommonTypes';
import i18n from 'i18n';

class MyChannelStyleStore extends ServiceStoreBase {
  myStyle: ChannelStyleModel | undefined;
  t = i18n.t;

  constructor() {
    super();
    makeObservable(this, {
      myStyle: observable,
      getMyStyle: flow,
      createMyStyle: flow,
      updateMyStyle: flow,
      deleteMyStyle: flow,
      // checkChannelName: flow,
    });
  }

  api: MyChannelStyleService<unknown> = new MyChannelStyleService(new HttpClient());

  *getMyStyle(channelID: number) {
    const { response } = yield this.api.getStyle(channelID, 'LINK');
    if (response.status === 200) {
      const style = { ...response.data, created: true };
      this.myStyle = style;
      return style;
    } else {
      this.myStyle = {};
      return { style: '{}' };
    }
  }

  *createMyStyle(channelId: number, channelStyle: ChannelStyleDto) {
    const { response } = yield this.api.createStyle(channelId, 'link', channelStyle);
    return response.status < 300;
  }

  *updateMyStyle(channelId: number, channelStyle: ChannelStyleDto) {
    const { response } = yield this.api.updateStyle(channelId, 'link', channelStyle);
    return response.status < 300;
  }

  *deleteMyStyle(channelId: number, channelStyle: ChannelStyleDto) {
    const { response } = yield this.api.deleteStyle(channelId, 'link');
    return super.handleByStatus(response, { successMessage: this.t('A220') });
  }

  unMount() {
    this.myStyle = undefined;
  }
}

export default MyChannelStyleStore;
