import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { MenuType } from 'types/CommonTypes';
import { isMobile } from 'react-device-detect';

import { OhmossButton } from 'resources/controls/OhmossButton';
import styled, { css } from 'styled-components';
import { getAdminMenus } from 'resources/definitions/AdminMenus';

import LogoutIcon from '@mui/icons-material/Logout';
import { useGlobalContext } from 'context/useGlobalContext';
import useLogout from 'hooks/useLogout';
import AdminLnbAvatar from './AdminLnbAvatar';

const AdminLnbLong = observer(
  ({
    selectedIndex,
    onChangeMode,
    handleShowMenu,
  }: {
    selectedIndex?: number;
    onChangeMode?: (id: MenuType) => void;
    handleShowMenu?: (show: boolean, menu: string | undefined) => void;
  }) => {
    const [selectedItem, setSelectedItem] = useState<MenuType | undefined>();

    const logout = useLogout();
    const { t } = useTranslation();
    const { uiStore } = useStore();
    const { channelname, menuType } = useParams();

    const { navigate } = useNavigation();

    const handleClickLogout = () => {
      uiStore.confirm.show({
        title: t('Logout'),
        message: t('A147'),
        onConfirmed: () => {
          logout();
        },
      });
    };

    useEffect(() => {
      const target = getAdminMenus().find((menu: any) => selectedIndex === menu.key);
      setSelectedItem(target);
    }, [selectedIndex]);

    useEffect(() => {
      setSelectedItem({ key: 0, title: 'Links' });
    }, []);

    return (
      <GnbLayoutStyle>
        <div>
          <LogoStyle
            src={
              window.innerWidth < window.configuration?.deskSize
                ? '/images/ohmoss_logo.svg'
                : '/images/ohmoss_logo.svg'
            }
            onClick={() => {
              navigate('/');
            }}
          />
          <div style={{ marginTop: 50, marginBottom: 40 }}>
            <AdminLnbAvatar />
          </div>
        </div>
        <ProfileContainerStyle>
          <MenuContainerStyle>
            <>
              {getAdminMenus().map((menu: MenuType) => (
                <>
                  <HeaderItemStyle
                    isSeparate={menu.separate}
                    key={menu.key}
                    selected={selectedItem?.title === menu.title}
                    onClick={() => {
                      uiStore.universalModal.close();
                      if (typeof menu.route !== 'undefined' && !menuType) {
                        navigate(`/${channelname}${menu.route}`);
                        return;
                      }
                      setSelectedItem(menu);
                      if (typeof onChangeMode === 'function') {
                        onChangeMode(menu);
                      }
                      if (typeof handleShowMenu === 'function') {
                        handleShowMenu(true, menu.title);
                      }
                      if (!isMobile && menu.title === selectedItem?.title) {
                        if (typeof handleShowMenu === 'function') {
                          handleShowMenu(false, undefined);
                        }
                      }
                    }}
                  >
                    <div style={{ marginRight: 10, display: 'flex', alignItems: 'center' }}>
                      {menu.icon}
                    </div>
                    {menu.description}
                  </HeaderItemStyle>
                </>
              ))}
            </>
          </MenuContainerStyle>
        </ProfileContainerStyle>
        <UnderButtonStyle>
          <OhmossButton type="simple" onClick={handleClickLogout}>
            <div style={{ marginRight: 5 }}>
              <LogoutIcon />
            </div>
            {t('Logout')}
          </OhmossButton>
        </UnderButtonStyle>
      </GnbLayoutStyle>
    );
  },
);

const GnbLayoutStyle = styled.div<{ isMobile?: boolean }>`
  max-width: 250px;
  transition: width 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  @media screen and (max-width: 985px) {
    display: none;
  }
  @media ${(props) => props.theme.media.mobile} {
    display: none;
  }
`;

const MenuContainerStyle = styled.div`
  display: inline-block;
  width: 100%;
  & > div {
    text-align: left;
  }
  @media ${(props) => props.theme.media.mobile} {
    justify-content: flex-start;
    display: flex;
    align-items: center;
  }
`;

const LogoStyle = styled.img.attrs({ alt: '' })<{ isMobile?: boolean; mobileMenu?: boolean }>`
  cursor: pointer;
  width: 120px;
  display: flex;
  margin: auto;
  margin-top: 50px;
`;

const ProfileContainerStyle = styled.div<{ mobileMenu?: boolean }>`
  width: 90%;

  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 2;
`;

const HeaderItemStyle = styled.div<{ selected: boolean; isSeparate?: boolean }>`
  text-align: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: all 0.1s ease-out;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 15px;
  margin: 5px 0;
  ${(props) =>
    props.selected &&
    css`
      color: #fff !important;
      background: #0bbd58;
      transition: all 0.1s ease-out;
    `}
  color: ${(props) => (props.selected ? 'black' : '#535353')};
  &:hover {
    opacity: 1;
    color: #068a3f !important;
    background: #fff;
    transition: all 0.1s ease-out;
  }
`;

const UnderButtonStyle = styled.div`
  display: flex;
  margin-bottom: 40px;
  width: 100%;
  justify-content: center;
`;

export default AdminLnbLong;
