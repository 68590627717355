/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ChannelBoardDto,
  ChannelDto,
  ChannelProposalConfigDto,
  ChannelStyleDto,
  DailyViewCountDto,
  ProposalDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Channel<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 제안하기
   *
   * @tags channel
   * @name Propose
   * @summary 제안하기
   * @request POST:/v2/channels/{channelName}/proposals
   * @secure
   */
  propose = (
    channelName: string,
    query: {
      /** @format int64 */
      ownChannelId: number;
    },
    data: ProposalDto,
    params: RequestParams = {},
  ) =>
    this.http.request<ProposalDto, any>({
      path: `/v2/channels/${channelName}/proposals`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description channel 전체 조회
   *
   * @tags channel
   * @name GetChannels1
   * @summary channel 전체 조회
   * @request GET:/v2/channels
   * @secure
   */
  getChannels1 = (
    query?: {
      /** @format int64 */
      myChannelId?: number;
      /** 검색어 (채널 프로필 닉네임) */
      searchText?: string;
      /** 채널 타입 (SELLER, BRAND) */
      channelType?: 'SELLER' | 'BRAND' | 'MEMBER';
      /**
       * 페이지 번호 default: 1
       * @format int32
       */
      page?: number;
      /**
       * 페이지 사이즈 default: 20
       * @format int32
       */
      size?: number;
      /** 정렬 기준 default: createDate */
      sortBy?: string;
      /** 정렬 방향 (desc, asc) default: 내림차순 */
      direction?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ChannelDto[], any>({
      path: `/v2/channels`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description channel 조회
   *
   * @tags channel
   * @name GetChannel1
   * @summary channel 조회
   * @request GET:/v2/channels/{channelName}
   * @secure
   */
  getChannel1 = (
    channelName: string,
    query?: {
      /** @format int64 */
      myChannelId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ChannelDto, any>({
      path: `/v2/channels/${channelName}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 채널 조회 수
   *
   * @tags channel
   * @name GetChannelViewCount
   * @summary 채널 조회 수
   * @request GET:/v2/channels/{channelName}/view-count
   * @secure
   */
  getChannelViewCount = (channelName: string, params: RequestParams = {}) =>
    this.http.request<DailyViewCountDto, any>({
      path: `/v2/channels/${channelName}/view-count`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 채널 스타일정보
   *
   * @tags channel
   * @name GetChannelStyle
   * @summary 채널 스타일정보
   * @request GET:/v2/channels/{channelName}/styles/{type}
   * @secure
   */
  getChannelStyle = (channelName: string, type: string, params: RequestParams = {}) =>
    this.http.request<ChannelStyleDto, any>({
      path: `/v2/channels/${channelName}/styles/${type}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 채널 제안종류 정보
   *
   * @tags channel
   * @name GetChannelProposalType
   * @summary 채널 제안종류 정보
   * @request GET:/v2/channels/{channelName}/proposal-type
   * @secure
   */
  getChannelProposalType = (channelName: string, params: RequestParams = {}) =>
    this.http.request<ChannelProposalConfigDto, any>({
      path: `/v2/channels/${channelName}/proposal-type`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 채널명 중복체크
   *
   * @tags channel
   * @name CheckDuplicateChannelName
   * @summary 채널명 중복체크
   * @request GET:/v2/channels/{channelName}/check-exist
   * @secure
   */
  checkDuplicateChannelName = (channelName: string, params: RequestParams = {}) =>
    this.http.request<boolean, any>({
      path: `/v2/channels/${channelName}/check-exist`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 게시판 목록
   *
   * @tags channel
   * @name GetBoards1
   * @summary 게시판 목록
   * @request GET:/v2/channels/{channelName}/boards
   * @secure
   */
  getBoards1 = (channelName: string, params: RequestParams = {}) =>
    this.http.request<ChannelBoardDto[], any>({
      path: `/v2/channels/${channelName}/boards`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
