import React, { useState, useEffect, useRef } from 'react';
import { PostModel, PostItemModel } from 'types/CommonTypes';
import { setDefaultValue } from 'libs/helper/utils';
import { useTranslation } from 'react-i18next';
import { validationHelper as VH } from 'controls/validationHelper';
import { useStore } from 'stores/StoreHelper';
import { PostDto } from 'services/data-contracts';

import LinkEditorContainerStyle from 'resources/controls/LinkEditorConatainerStyle';
import Editor from 'components/commons/webEditor/Editor';
import SaveButtonWrapper from 'components/items/SaveButtonWrapper';
import TitleForm from 'components/items/forms/TitleForm';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import boardCommonLogics from '../logic/boardCommonLogic';
import { Row } from 'components/commons/layouts';
import moment from 'moment';
import dayjs from 'dayjs';
import { MainTitleText, SubTitleText } from 'resources/controls/Titles';
import { observer } from 'mobx-react';
import ListOfComments from '../containers/ListOfComments';
import ReplyForm from '../containers/ReplyForm';

const CalendarItemComponent = observer(({ model }: { model: any }) => {
  const editorRef = useRef<any>(null);

  const { t } = useTranslation();
  const { uiStore, postStore, channelStore, myChannelStore, uiStores } = useStore();
  const { handleSavePost } = boardCommonLogics();

  const getPost = () => {
    const id = parseInt(model?.id, 10);
    if (id && channelStore.channel?.channelName) {
      postStore.getPost(
        channelStore.channel?.channelName,
        uiStores.userHomeStore.currentBoard?.id as number,
        id,
        myChannelStore.currentChannel?.id as number,
      );
    }
  };

  useEffect(() => {
    if (model?.id) {
      getPost();
    }
  }, [model]);

  useEffect(() => {
    return () => {
      postStore.unMount();
    };
  }, []);

  return (
    <>
      <LinkEditorContainerStyle>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div>
            <SubTitleText>일정시작일</SubTitleText>
            <div>{dayjs(model?.start).format('YYYY-MM-DD')}</div>
          </div>
          <div>
            <SubTitleText>일정종료일</SubTitleText>
            <div>{dayjs(model?.end).format('YYYY-MM-DD')}</div>
          </div>
        </LocalizationProvider>
        <div
          style={{ marginTop: 30 }}
          dangerouslySetInnerHTML={{
            __html: postStore.post?.content as string,
          }}
        />
        {uiStores.userHomeStore.currentBoard?.isCommentAllow && (
          <>
            <div style={{ marginLeft: 20 }}>
              <ListOfComments postId={model.id as number} />
            </div>
            <ReplyForm feedId={model?.id} type="reply" />
          </>
        )}
      </LinkEditorContainerStyle>
    </>
  );
});

export default CalendarItemComponent;
