import { useRef, useEffect } from 'react';

function useSetRef<T extends object>(initialValue?: T | undefined) {
  const stateRef = useRef<T | undefined>(initialValue);

  const setState = (keyOrObject: keyof T | Partial<T>, value?: T[keyof T]) => {
    // 현재 상태를 얻습니다
    const currentState = stateRef.current || {} as T;

    if (
      typeof keyOrObject === 'string' ||
      typeof keyOrObject === 'number' ||
      typeof keyOrObject === 'symbol'
    ) {
      // 키-값 쌍으로 상태를 업데이트합니다
      stateRef.current = { ...currentState, [keyOrObject]: value } as T;
    } else if (keyOrObject && typeof keyOrObject === 'object') {
      // 객체로 전달된 경우, 상태를 병합합니다
      stateRef.current = { ...currentState, ...keyOrObject } as T;
    }

    // 상태 변경 후 컴포넌트 강제 업데이트
  };

  useEffect(() => {
    // 상태가 초기화될 때만 useRef의 current 값을 설정
    stateRef.current = initialValue;
    // 초기화 후 강제 업데이트
  }, [initialValue]);

  // 상태 참조와 상태 설정 함수를 반환
  return [stateRef, setState] as const; // 이렇게 하면 반환된 배열의 타입이 명확해집니다.
}

export default useSetRef;
