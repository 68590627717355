import React, { useEffect, useState, useCallback } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { IGalleryBlock } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';
import { debounce } from 'lodash';
import { useStore } from 'stores/StoreHelper';
import { LinkTypes } from 'features/management/context/providers/ContextTypes';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { CustomFile, LinkItemModel } from 'types/CommonTypes';
import { validationHelper } from 'controls/validationHelper';

import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import TitleForm from 'components/items/forms/TitleForm';
import UrlForm from 'components/items/forms/UrlForm';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import MultipleImageHeader from 'components/commons/imageUploader/MultipleImageHeader';
import MultipleImageSelector from 'components/commons/imageUploader/MultipleImageSelector';
import BlockController from 'controllers/BlockController';
import { StickerTypes } from 'components/commons/stickers/StickerTypes';
import GalleryLayout from '../commonControl/GalleryLayout';
import CommonSticker from '../commonControl/CommonSticker';
import CommonLayout from '../commonControl/CommonLayout';
import CommonAlign from '../commonControl/CommonAlign';
import ActionBox from 'features/csTown/components/pc/ActionBox';

const Gallery = ({ blockModel }: { blockModel?: IGalleryBlock }) => {
  const { uiStore } = useStore();
  const defaultProps: Partial<IGalleryBlock> = {
    linkType: 'Gallery',
    layout: 'TypeA',
    isActive: true,
    openNewWindow: true,
    stickerPosition: 'top',
  };

  const { initialModel, initialBinding, updateServiceModel, serviceModel } =
    useOhmossContext<LinkTypes<IGalleryBlock>>();

  const { updateBlock } = BlockController<IGalleryBlock>();
  const [localModel, setLocalModel] = useInitializeModel<Partial<IGalleryBlock>>(
    blockModel,
    defaultProps,
    initialBinding,
  );

  const [selectedItem, setSelectedItem] = useState<LinkItemModel>();
  const [galleryItems, setGalleryItems] = useState<LinkItemModel[]>();
  const [isValid, setIsValid] = useState<boolean>(false);

  const initializeModel = () => {
    if (blockModel?.items) {
      setGalleryItems(blockModel.items);
    }
  };

  const handleChangeItemUrlString = useCallback(
    debounce(async (url) => {
      if (url.length === 0) {
        handleChangeItemUrl(url, true);
        return;
      }
      const validString = validationHelper.url(url);
      handleChangeItemUrl(url, validString);
    }, 300),
    [galleryItems, selectedItem],
  );

  const handleChangeItemUrl = (value: string, valid: boolean) => {
    const itemTarget = galleryItems?.findIndex(
      (item: LinkItemModel) => item.seq === selectedItem?.seq,
    );

    if (itemTarget !== undefined && itemTarget >= 0) {
      const newItems = [...(galleryItems || [])] as LinkItemModel[];
      newItems[itemTarget] = {
        ...selectedItem,
        linkUrl: value,
        urlValid: value.length ? valid : true,
      };
      setGalleryItems(newItems);
    }
  };

  const handleSetItems = (newItems: CustomFile[]) => {
    const lastSeq = typeof galleryItems === 'undefined' ? 0 : galleryItems?.length;

    // 이미지를 아이템으로 변환.
    const items: LinkItemModel[] = newItems?.map((newItem, index: number) => {
      return {
        file: newItem,
        seq: lastSeq + index + 1,
        isActive: true,
        linkUrl: '',
      };
    }) as LinkItemModel[];
    setGalleryItems([...(galleryItems || []), ...items]);
  };

  useEffect(() => {
    initializeModel();
    return () => {
      initialBinding(undefined);
    };
  }, [blockModel]);

  useEffect(() => {
    if (localModel) {
      // const title = validationHelper.required(localModel.title);
      const itemsValid = galleryItems?.filter((item: LinkItemModel) => item.urlValid === false);

      if (typeof galleryItems === 'undefined' || galleryItems?.length === 0) {
        setIsValid(false);
        setSelectedItem(undefined);
        return;
      }
      setIsValid(itemsValid?.length === 0);
    }
  }, [localModel, galleryItems]);

  return (
    <div>
      <Row style={{ marginBottom: 20, justifyContent: 'flex-start', marginRight: 20 }}>
        <MultipleImageSelector
          attachType="media"
          onSelected={(thumbnails: CustomFile[]) => {
            if (thumbnails.length > 0) {
              handleSetItems(thumbnails);
            }
          }}
        />
        <MultipleImageHeader
          onChange={(items: LinkItemModel[]) => {
            setGalleryItems(items);
            setLocalModel('items', galleryItems);
          }}
          onSelectedItem={setSelectedItem}
          items={galleryItems}
        />
      </Row>
      <ItemSectionStyle>
        <ItemSectionTitle>타이틀</ItemSectionTitle>
        <ItemContent>
          <TitleForm
            required={false}
            onChangeValue={(value: string) => {
              updateServiceModel('title', value);
              setLocalModel('title', value);
            }}
            initialValue={initialModel?.title}
            placeHolder="타이틀을 작성해 주세요"
            label="타이틀"
          />
        </ItemContent>
      </ItemSectionStyle>
      {selectedItem && (
        <ItemSectionStyle>
          <ItemSectionTitle>{selectedItem?.seq}번째 이미지의 링크 주소</ItemSectionTitle>
          <ItemContent>
            <UrlForm
              onChangeValue={(value: string) => {
                handleChangeItemUrlString(value);
              }}
              onBlur={(value: string, valid: boolean) => {
                handleChangeItemUrl(value, valid);
              }}
              initialValue={selectedItem?.linkUrl}
              placeHolder="링크주소를 입력해 주세요"
            />
          </ItemContent>
        </ItemSectionStyle>
      )}
      <CommonSticker
        initialValue={initialModel?.stickers}
        initialPosition={initialModel?.stickerPosition}
        onChange={(stickers: StickerTypes[]) => {
          updateServiceModel('stickers', stickers);
        }}
        onChangePosition={(position: 'top' | 'bottom') => {
          updateServiceModel('stickerPosition', position);
        }}
      />
      <ItemSectionStyle>
        <MenuItemCheckBox
          initialValue={initialModel?.openNewWindow || true}
          onChangeValue={(value: boolean) => {
            updateServiceModel('openNewWindow', value);
          }}
          title="새창열기"
        />
        <MenuItemCheckBox
          onChangeValue={(value: boolean) => {
            updateServiceModel('useTransparent', value);
          }}
          initialValue={initialModel?.useTransparent}
          title="투명배경"
        />
        <MenuItemCheckBox
          initialValue={initialModel?.autoPlay}
          onChangeValue={(value: boolean) => {
            updateServiceModel('autoPlay', value);
          }}
          title="자동 플레이"
        />
      </ItemSectionStyle>
      <GalleryLayout
        initialValue={initialModel?.layout || 'TypeA'}
        onChange={(value: string) => {
          updateServiceModel('layout', value);
        }}
      />
      <CommonAlign
        initialValue={initialModel?.contentAlign || 'center'}
        title="타이틀 정렬"
        onChange={(value: string) => {
          updateServiceModel('contentAlign', value);
        }}
      />
      <ActionBox
        style={{ marginTop: 60, paddingBottom: 20 }}
        isValid={isValid}
        okText="저장"
        onOk={() => {
          updateServiceModel('items', galleryItems);
          updateBlock(serviceModel);
        }}
        onCancel={() => {
          uiStore.modal.close();
        }}
      />
      {/* <Row style={{ gap: 10 }}>
        <OhmossButton
          type="box"
          style={{ color: '#000' }}
          onClick={() => {
            uiStore.modal.close();
          }}
        >
          취소
        </OhmossButton>
        <OhmossButton
          onClick={() => {
            updateServiceModel('items', galleryItems);
            updateBlock(serviceModel);
          }}
          disabled={!isValid}
        >
          적용
        </OhmossButton>
      </Row> */}
    </div>
  );
};

export default Gallery;
