import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LinkDto } from 'services/data-contracts';

import ManagementLayout from 'features/management/components/common/ManagementLayout';
import AgencyController from 'controllers/BrandController';
import DealList from '../containers/DealList';
import { useRootContext } from 'libs/hooks/useRootContext';

const SellerDealListPage = () => {
  const { partnerid } = useParams();
  const { myChannel } = useRootContext();
  const { getDealsByPartner } = AgencyController();

  const [deals, setDeals] = useState<LinkDto[]>([]);

  const getPartnerDeals = async () => {
    const partnerId = Number(partnerid);
    const dealList = (await getDealsByPartner(
      myChannel?.id as number,
      partnerId,
    )) as unknown as LinkDto[];
    setDeals(dealList);
  };

  useEffect(() => {
    if (partnerid && myChannel) {
      getPartnerDeals();
    }
  }, [partnerid, myChannel]);

  return (
    <>
      <ManagementLayout>
        <DealList deals={deals} />
      </ManagementLayout>
    </>
  );
};

export default SellerDealListPage;
