import React, { CSSProperties, ReactElement, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';

type Props = {
  type?: 'Preview' | 'SAVE' | 'Update' | 'Cancel';
  label?: string;
  isValid?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  children?: any;
  style?: CSSProperties;
};

const WhiteButton = ({ type, onClick, label, disabled, style, children, isValid }: Props) => {
  useEffect(() => {
    // console.log('hji');
  }, [disabled]);

  return (
    <BtnStyle isValid={isValid} style={{ flex: 1 }}>
      <Button onClick={onClick} disabled={!isValid}>
        {label}
      </Button>
    </BtnStyle>
  );
};

const BtnStyle = styled.div<{ isValid?: boolean; isMobile?: boolean }>`
  > button {
    font-size: 16px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    border-radius: 15px;
    padding: 10px 40px;
    background: ${(props) => (props.isValid ? '#11C668' : '#a9dec2')};
    color: #fff !important;
    &:hover {
      background-color: #078544;
    }
  }
`;

export default WhiteButton;
