/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { OrderDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class OrderService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 주문 목록
   *
   * @tags OrderService
   * @name GetOrders
   * @summary 주문 목록
   * @request GET:/v2/me/channels/{channelId}/orders
   * @secure
   */
  getOrders = (
    channelId: number,
    query?: {
      /** @format int64 */
      membershipId?: number;
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["orderDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<OrderDto[], any>({
      path: `/v2/me/channels/${channelId}/orders`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 주문 정보
   *
   * @tags OrderService
   * @name GetOrder
   * @summary 주문 정보
   * @request GET:/v2/me/channels/{channelId}/orders/{orderId}
   * @secure
   */
  getOrder = (channelId: number, orderId: number, params: RequestParams = {}) =>
    this.http.request<OrderDto, any>({
      path: `/v2/me/channels/${channelId}/orders/${orderId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
