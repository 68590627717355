import styled from 'styled-components';

/**
 * @description
 */
const ATagStyle = styled.a<{ color?: string; textDecoration?: string }>`
  color: ${(props) => props.color || '#222222'};
  text-decoration: ${(props) => props.textDecoration || 'none'};
  text-underline-position: under;
  font-size: 14px;
  outline: none;
  &:hover,
  &:active {
    /* text-decoration: none; */
    opacity: 0.4;
  }
`;

export default ATagStyle;
