import React, { useEffect, useState } from 'react';
import { ChannelBoardDto } from 'services/data-contracts';
import { XScrollItem } from 'components/commons/XScrollBox.tsx';
import { IconButton } from '@mui/material';
import { IChannelProfile } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';
import { cutoffString } from 'libs/helper/utils';

import EditIcon from '@mui/icons-material/Edit';
import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';
import useNavigation from 'hooks/useNavigation';
import useLocalParams from 'libs/hooks/useLocalParams';
import { useChannelContext } from 'features/linkService/context/useChannelContext';

/**
 * @description 프로필 페이지의 카테고리 탭
 * @param param0
 * @returns
 */
const CategoryTabTemplateList = ({
  item,
  channelBoards,
  editMode = true,
  onClick,
}: {
  item: IChannelProfile;
  channelBoards?: ChannelBoardDto[];
  editMode?: boolean;
  onClick: () => void;
}) => {
  const { navigate } = useNavigation();
  const { channelName } = useLocalParams();
  const { setCategoryBoard } = useChannelContext();

  const [categories, setCategories] = useState<XScrollItem<ChannelBoardDto>[]>();
  const [selectedCategory, setSelectedCategory] = useState<XScrollItem<ChannelBoardDto>>();

  const initialize = async () => {
    const newCategories = channelBoards
      ?.filter((item: ChannelBoardDto) => item.boardType !== 'SHOP')
      .map((item: ChannelBoardDto) => {
        return {
          item,
          title: item.name,
          thumbnail: item.thumbnail?.publicUrl ?? '/images/ohmossc.png',
          seq: item.seq,
        };
      });
    setCategories(newCategories);
  };

  useEffect(() => {
    if (editMode !== true && selectedCategory?.item) {
      setCategoryBoard(selectedCategory.item);
      navigate(`/${channelName}/${selectedCategory.item?.id}`);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (item && channelBoards) initialize();
  }, [item, channelBoards]);

  return (
    <>
      <ContainerStyle>
        <div style={{ justifyContent: 'flex-end', textAlign: 'right', padding: '10px ' }}>
          <IconButton
            onClick={() => {
              onClick();
            }}
          >
            <EditIcon />
          </IconButton>
        </div>

        <ScrollContainer className="scroll-container">
          <Row
            style={{
              justifyContent: 'flex-start',
              // gap: 30,
              flex: 1,
              width: 'auto',
              padding: '10px 0',
              height: 150,
              marginLeft: 5,
              marginRight: 5,
              marginBottom: 20,
            }}
          >
            <>
              <>
                {categories?.map((linkItem: any, index: number) => (
                  <div style={{ padding: '0 20px', position: 'relative' }}>
                    <DefaultIconStyle>
                      <div style={{ position: 'relative' }}>
                        <ImageStyle>
                          <img
                            src={linkItem?.thumbnail}
                            alt=""
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                          />
                        </ImageStyle>
                      </div>
                    </DefaultIconStyle>
                    <TitleStyle>{cutoffString(linkItem.title, 8)}</TitleStyle>
                  </div>
                ))}
              </>
              <>
                {categories && categories?.length === 0 && (
                  <div style={{ margin: 'auto' }}>등록된 페이지가 없습니다.</div>
                )}
              </>
            </>
          </Row>
        </ScrollContainer>
      </ContainerStyle>
    </>
  );
};

const DefaultIconStyle = styled.div<{ selected?: boolean; valid?: boolean }>`
  width: 80px;
  height: 80px;
  background: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  outline: ${(props) => (props.selected ? '2px solid #2ebe59' : '2px solid transparent')};
  outline-offset: 3px;
  transition: background 0.2s ease-in-out;
`;

const ImageStyle = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
  object-fit: cover;
  width: 90px;
  height: 90px;
  border-radius: 60px;
`;

const ContainerStyle = styled.div<{ useTransparent?: boolean }>`
  border-radius: 10px;
  width: 100%;
  min-height: 90px;
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
`;

const TitleStyle = styled.div<{ selected?: boolean }>`
  margin-top: 10px;
  font-size: 16px;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  color: ${(props) => (props.selected ? '#000000' : '#6f6f6f')};
  justify-content: center;
`;

export default CategoryTabTemplateList;
