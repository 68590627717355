import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { PostTagDto } from 'services/data-contracts';

import styled from 'styled-components';

interface HashTagModel extends PostTagDto {
  selected?: boolean;
}

const HashTagContainer = forwardRef(({ selectedHashs }: { selectedHashs?: PostTagDto[]; }, ref) => {
  const hashse = [{ name: 'test' }, { name: 'test2' }, { name: '이미지2' }, { name: 'test3' }, { name: 'test24' }, { name: '이미지' }] as HashTagModel[];
  const [hashTags, setHashTags] = useState<HashTagModel[]>([]);

  useImperativeHandle(ref, () => ({
    getTags
  }));


  const handleClickTag = (tag: HashTagModel) => {
    const newHashs = [...hashTags];
    const target = newHashs.findIndex((ntag: HashTagModel) => ntag.name === tag.name);
    if (target >= 0) {
      newHashs.splice(target, 1, { ...newHashs[target], selected: !tag.selected });
      setHashTags(newHashs);
    }
  };

  const getTags = () => {
    return hashTags.filter((tag: HashTagModel) => tag.selected === true);
  };

  const getSelectedTag = (tag: HashTagModel) => {
    const getTag = selectedHashs?.find((stag: HashTagModel) => tag.name === stag.name);
    return typeof getTag !== 'undefined';
  };

  useEffect(() => {
    if (selectedHashs && selectedHashs.length > 0) {
      const result = hashse.map((tag: HashTagModel) => { return { ...tag, selected: getSelectedTag(tag) }; });
      setHashTags(result);
    } else {
      setHashTags(hashse);
    }
  }, [selectedHashs]);

  return (
    <HashTagContainerStyle>
      {hashTags.map((hash: HashTagModel) => (
        <HashTagItemStyle selected={hash.selected}
          onClick={() => { handleClickTag(hash); }}>#{hash.name}</HashTagItemStyle>
      ))}
    </HashTagContainerStyle>
  );
});

const HashTagContainerStyle = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  padding-bottom: 30px;
`;

const HashTagItemStyle = styled.div<{ selected?: boolean; }>`
  padding:10px;
  border: ${(props) => props.selected ? '1px solid transparent' : '1px solid #b7b7b7'};
  color: ${(props) => props.selected ? '#ffffff' : '#b7b7b7'};
  background: ${(props) => props.selected ? '#23BA50' : '#ffffff'};

  
  border-radius: 8px;
  cursor: pointer;
  
`;

export default HashTagContainer;
