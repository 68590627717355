import React, { memo, useEffect, useRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CountDto } from 'services/data-contracts';
import styled from 'styled-components';

const LineChart = memo(
  ({
    title,
    visitCount,
    clickCount,
  }: {
    title?: string;
    visitCount?: CountDto[];
    clickCount?: CountDto[];
  }) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    useEffect(() => {
      if (Array.isArray(visitCount) && chartComponentRef.current) {
        const chart = chartComponentRef.current.chart;

        const newVisitArray = visitCount?.map((item) => item?.count ?? 0);
        const newDateArray = visitCount?.map((item) => item?.date ?? '');

        chart.xAxis[0].setCategories(newDateArray as string[], false);
        chart.series[0].setData(newVisitArray as Highcharts.PointOptionsType[], false);
        chart.redraw();
      }
    }, [visitCount]);

    useEffect(() => {
      if (clickCount && chartComponentRef.current) {
        const chart = chartComponentRef.current.chart;
        const newClickArray = clickCount.map((item) => item?.count ?? 0);

        chart.series[1].setData(newClickArray as Highcharts.PointOptionsType[], false);
        chart.redraw();
      }
    }, [clickCount]);

    const options: Highcharts.Options = {
      title: {
        text: title,
      },
      plotOptions: {
        spline: { lineWidth: 4 },
        series: {
          label: {
            connectorAllowed: false,
          },
          marker: {
            enabled: false,
          },
        },
      },
      yAxis: {
        title: {
          text: '클릭/접속자',
        },
      },
      xAxis: {
        categories: [],
        title: {
          text: '기간',
        },
        accessibility: {
          description: '기간',
        },
      },
      series: [
        {
          type: 'spline',
          name: '방문자수',
          data: [],
        },
        {
          type: 'spline',
          name: '클릭수',
          data: [],
        },
      ],
    };

    return (
      <>
        <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
        {(!visitCount || visitCount.length === 0) && (
          <TextContainer>표시할 데이터가 없습니다.</TextContainer>
        )}
      </>
    );
  },
);

const TextContainer = styled.div`
  text-align: center;
  font-size: 13px;
  font-weight: 600;
`;

export default LineChart;
