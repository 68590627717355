import React from 'react';
import DealPartnerList from '../components/DealPartnersManagement';

const PartnersManagementPage = () => {
  return (
    <>
      <DealPartnerList />
    </>
  );
};

export default PartnersManagementPage;
