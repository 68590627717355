import React, { useEffect, useState } from 'react';

import { useStore } from 'stores/StoreHelper';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { AdDto } from 'services/data-contracts';

import MobileModalHeader from 'components/commons/MobileModalHeader';
import useChannelSelector from 'libs/hooks/useChannelSelector';
import UniversalLayout from 'components/commons/layouts/UniversalLayout';
import LinkPreview from 'features/mossMain/components/LinkPreview';
import FollowProvider from 'providers/follow/FollowProvider';
import PageProvider from 'features/mossMain/context/DealsProvider';
import DetailPageHeader from 'components/commons/DetailPageHeader';
import LinkStore from 'stores/LinkStore';

const AdDetailPage = observer(() => {
  const { adId } = useParams();
  const { adStore } = useStore();
  const linkStore = new LinkStore();
  const { initializeFirstChannel } = useChannelSelector();

  const [adInfo, setAdInfo] = useState<AdDto>();

  const getAd = async () => {
    const channel = await initializeFirstChannel();
    const adid = Number.parseInt(adId || '');
    if (!Number.isNaN(adid)) {
      const result = (await adStore.getAd(adid, {
        ownChannelId: channel?.id as number,
      })) as never as AdDto;
      setAdInfo(result);
    } else {
      alert('광고ID가 바르지 않습니다.');
    }
  };

  useEffect(() => {
    getAd();
  }, [adId]);

  return (
    <>
      {/* <DetailPageHeader /> */}
      {typeof adInfo !== 'undefined' && <LinkPreview adItem={adInfo} />}
    </>
  );
});

export default AdDetailPage;
