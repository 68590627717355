/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { BoardCategoryDto, ChannelBoardDto } from 'services/data-contracts';
import { BoardCategoryModel, ChannelBoardTypes } from 'types/CommonTypes';
import { validationHelper as VH } from 'controls/validationHelper';

import {
  ItemSectionStyle,
  ItemSectionTitle,
  ItemSectionDescription,
} from 'resources/controls/ItemSectionStyle';
import { OhmossButton } from 'resources/controls/OhmossButton';

import * as BoardItems from 'features/board/components';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import styled from 'styled-components';
import _ from 'lodash';
import { Row } from 'components/commons/layouts';
import { useTranslation } from 'react-i18next';

const CreateBoardFormTypeSelector = observer(
  ({
    boardCategory,
    boardType,
    boardModel,
    onCreate,
    onDelete,
  }: {
    boardCategory?: BoardCategoryModel;
    boardType?: BoardCategoryDto['boardType'];
    boardModel?: BoardCategoryModel;
    onCreate: (board?: ChannelBoardDto) => void;
    onDelete: (board: ChannelBoardDto) => void;
  }) => {
    const modelRef = useRef<any>(null);
    const { t } = useTranslation();
    const [selectedBoardType, setSelectedBoardType] = useState<BoardCategoryDto['boardType']>();
    const [_, setBoardModel] = useState<ChannelBoardDto | undefined>();
    const [isValid, setIsValid] = useState<boolean>(false);

    const getBoardComponent = () => {
      switch (selectedBoardType) {
        case 'LINK':
          return <BoardItems.LinkBoard model={[boardModel, setBoardModel]} />;
        case 'BOARD':
          return <BoardItems.PostBoard model={[boardModel, setBoardModel]} />;
        case 'CALENDAR':
          return <BoardItems.CalendarBoard model={[boardModel, setBoardModel]} />;

        default:
          break;
      }
    };

    useEffect(() => {
      modelRef.current = _;
    }, [_]);

    useEffect(() => {
      setSelectedBoardType(boardModel?.boardType || 'LINK');
      return () => {
        modelRef.current = null;
        setBoardModel(undefined);
      };
    }, []);

    useEffect(() => {
      setIsValid(VH.required(_?.name));
    }, [_]);
    return (
      <>
        {!boardCategory?.channel && typeof boardType === 'undefined' && (
          <>
            <ItemSectionStyle2>
              <ItemSectionTitle>{t('A136')}</ItemSectionTitle>
              <ItemSectionDescription>{t('A138')}</ItemSectionDescription>
              <ToggleButtonGroup
                color="primary"
                value={selectedBoardType}
                exclusive
                onChange={(e: any, value: ChannelBoardTypes) => {
                  setSelectedBoardType(value);
                }}
                aria-label="Priority"
              >
                <ToggleButtonStyle value="LINK">{t('A139')}</ToggleButtonStyle>
                <ToggleButtonStyle value="BOARD">{t('A140')}</ToggleButtonStyle>
                <ToggleButtonStyle value="CALENDAR">{t('A141')}</ToggleButtonStyle>
                {/* {boardConfigStore.boardCategories?.map((boardCategory: BoardCategoryDto) => (
                  <div
                
                  >
                    <ToggleButtonStyle
                      value={boardCategory.boardType!}
                    >
                      {boardCategory.boardType}
                    </ToggleButtonStyle>
                  </div>
                ))} */}
              </ToggleButtonGroup>
            </ItemSectionStyle2>
          </>
        )}
        <ItemSectionStyle2>
          <ItemSectionTitle>{t('A135')}</ItemSectionTitle>
          <ItemSectionDescription>{t('A137')}</ItemSectionDescription>
          <FormContainerStyle>{getBoardComponent()}</FormContainerStyle>
        </ItemSectionStyle2>
        <Row
          style={{
            justifyContent: boardModel?.channel ? 'space-between' : 'flex-end',
            width: '80%',
          }}
        >
          <>
            {boardModel?.channel && (
              <OhmossButton
                type="danger"
                style={{ padding: '15px 30px' }}
                onClick={() => {
                  onDelete(boardModel);
                }}
              >
                {t('Delete')}
              </OhmossButton>
            )}
          </>
          <OhmossButton
            disabled={!isValid}
            style={{ padding: '15px 30px' }}
            onClick={() => {
              onCreate(_);
            }}
          >
            {boardCategory?.channel ? t('Modify') : t('Create')}
          </OhmossButton>
        </Row>
      </>
    );
  },
);

const ItemSectionStyle2 = styled(ItemSectionStyle)`
  width: 90%;
  box-shadow: none;
  margin-bottom: 20px;
`;

const ToggleButtonStyle = styled(ToggleButton)`
  padding: 10px 40px;
`;

const FormContainerStyle = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

export default CreateBoardFormTypeSelector;
