import { ChannelBoardDto } from 'services/data-contracts';

export const ChannelBoardDtoDummy: ChannelBoardDto = {
  id: undefined,
  channel: undefined,
  boardType: undefined,
  viewType: undefined,
  name: undefined,
  seq: undefined,
  thumbnail: undefined,
  isCommentAllow: undefined,
  useProfile: undefined,
  displayOnThumbnail: undefined,
  thumbnailColorRgba: undefined,
  thumbnailBackgroundColorRgba: undefined,
  useTransparent: undefined,
  isDirectLink: undefined,
  metadata: undefined,
  isPrimary: undefined,
  isDefault: undefined,
  isActive: undefined,
  createDate: undefined,
  modifyDate: undefined,
  postCount: undefined,
};
