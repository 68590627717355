/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import { Row } from 'components/commons/layouts';
import { OriginSNS, ISNS } from 'resources/images/sns';


import SNSIcon from './SNSIcon';
import styled from 'styled-components';
import { OhmossButton } from 'resources/controls/OhmossButton';

const SNSIconsComponent = ({ defaultIconName, onSelected, onCancel }: { defaultIconName?: string; onSelected: (component: ISNS) => void; onCancel: () => void }) => {
  const [selectedItem, setSelectedItem] = useState<ISNS>()

  const handleClickIcon = (icon: ISNS) => {
    setSelectedItem(icon)
  }

  useEffect(() => {
    if (defaultIconName) {
      const icon = OriginSNS.find((icon: ISNS) => icon.name === defaultIconName)
      setSelectedItem(icon);
    }
  }, [defaultIconName])

  return (
    <>

      <Row style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
        <>
          {OriginSNS.map((SNS: ISNS) => (
            <IconContainerStyle>
              <SNSIcon Component={SNS} onSelected={handleClickIcon} checked={SNS.name === selectedItem?.name} />
            </IconContainerStyle>
          ))}
        </>

        <Row style={{ justifyContent: 'space-between' }}>
          <OhmossButton
            style={{ padding: '10px 20px' }}
            onClick={() => {
              onCancel();
            }}
          >
            cancel
          </OhmossButton>
          <OhmossButton
            style={{ padding: '10px 20px' }}
            onClick={() => {
              if (selectedItem) {
                onSelected(selectedItem);
              }
            }}
          >
            Select
          </OhmossButton>
        </Row>
      </Row>
    </>
  )
}

const IconContainerStyle = styled.div`
 
`
export default SNSIconsComponent;
