import { Avatar } from '@mui/material';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

type CommonProps = {
  initialValue?: string;
  useRound?: boolean;
  parent?: any;
  useAlternative?: boolean;
  alternativeType?: 'people' | 'image';
  style?: React.CSSProperties;
};
const CommonThumbnail = ({
  useAlternative,
  initialValue,
  alternativeType = 'people',
  parent = 10,
  useRound = false,
  style,
}: CommonProps) => {
  const [borderRadius, setBorderRadius] = React.useState(0);

  useEffect(() => {
    if (parent) {
      const newRadius = parseInt(parent, 10) - 5;
      setBorderRadius(newRadius);
    }
  }, [parent]);
  return (
    <>
      {initialValue ? (
        <ImageStyle useRound={useRound} style={{ ...style, borderRadius }}>
          <img
            src={initialValue}
            alt=""
            style={{ objectFit: 'cover', height: '100%', width: '100%', marginRight: 15 }}
          />
        </ImageStyle>
      ) : (
        <>
          {useAlternative && (
            <ImageStyle useRound={useRound} style={{ ...style, borderRadius }}>
              {alternativeType === 'people' ? (
                <img src="/images/noPhoto.svg" alt="" style={{ width: 50, height: 50 }} />
              ) : (
                <ImageOutlinedIcon
                  style={{ width: 50, height: 50, fontSize: 50, color: '#9b9b9b' }}
                />
              )}
              {/* <Avatar style={{ borderRadius: 5, width: '100%', height: '100%' }} /> */}
            </ImageStyle>
          )}
        </>
      )}
    </>
  );
};

const ImageStyle = styled.div<{ useRound?: boolean }>`
  /* min-width: 70px;
  max-height: 70px; */
  width: 50px;
  height: 50px;
  /* margin: 5px 0; */
  overflow: hidden;
  /* border-radius: ${(props) => (props.useRound ? '70px' : '8px')}; */
`;
export default CommonThumbnail;
