import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Theme from 'resources/styles/common/theme';

interface DefaultProps {
  children?: any;
}

function BottomSheetButtonContainer({ children }: DefaultProps) {

  return (
    <LayoutStyle>
      {children && children}
    </LayoutStyle>
  );
}

const LayoutStyle = styled.div`
  padding:10px;
  padding-top:0;
  margin:10px;
  margin-top:10px;
  display: flex;
  flex:1;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

export default BottomSheetButtonContainer;
