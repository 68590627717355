import React from 'react';
import BottomSheetButton from 'components/commons/buttons/BottomSheetButton';
import BottomSheetButtonContainer from 'components/commons/buttons/BottomSheetButtonContainer';
import { useStore } from 'stores/StoreHelper';
import { PostDto } from 'services/data-contracts';
import useNavigation from 'hooks/useNavigation';

/**
 * @description 모든 링크를 생성하기 위한 컨테이너임
 */
const StatusCancledButtons = ({ post }: { post: PostDto }) => {
  const { uiStore, myPostStore, postStore } = useStore();
  const { navigate } = useNavigation();

  const fetchData = () => {
    myPostStore.getPosts(post.channel?.id as number, post.board?.id as number, {
      ownChannelId: post.channel?.id as number,
      isActive: false,
    });
  };

  const handleClickShowInfo = async () => {
    uiStore.universalModal.close();
    uiStore.bottomSheet.close();
    navigate(`/deals/settings/myad/deals/${post.id}`);
  };

  const handleClickDelete = () => {
    uiStore.universalModal.close();
    uiStore.bottomSheet.close();
    uiStore.confirm.show({
      title: '게시물 삭제',
      message: '해당 게시물을 삭제하시겠습니까?',
      onConfirmed: async () => {
        await myPostStore.deletePost(
          post.channel?.id as number,
          post.board?.id as number,
          post.id as number,
        );
        fetchData();
      },
    });
  };

  return (
    <BottomSheetButtonContainer>
      <BottomSheetButton
        onClick={() => {
          handleClickShowInfo();
        }}
      >
        광고정보 보기CANCLED
      </BottomSheetButton>
      <BottomSheetButton type="danger" onClick={handleClickDelete}>
        딜 광고 중단
      </BottomSheetButton>
      <BottomSheetButton type="separator" />
      <BottomSheetButton
        onClick={() => {
          uiStore.universalModal.close();
          uiStore.bottomSheet.close();
        }}
      >
        닫기
      </BottomSheetButton>
    </BottomSheetButtonContainer>
  );
};

export default StatusCancledButtons;
