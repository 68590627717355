import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { useParams, } from 'react-router-dom';
import { useStore } from 'stores/StoreHelper';
import useChannelSelector from 'libs/hooks/useChannelSelector';
import MobileModalHeader from 'components/commons/MobileModalHeader';
import { AdDto } from 'services/data-contracts';
import styled from 'styled-components';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { Row } from 'components/commons/layouts';
import UniversalLayout from 'components/commons/layouts/UniversalLayout';

const DealInfo = observer(() => {
  const { myAdStore } = useStore();
  const { adid } = useParams();
  const { initializeFirstChannel } = useChannelSelector();

  const [adInfo, setAdInfo] = useState<AdDto>();
  const [runningHour, setRunningHour] = useState<number>();
  const [runningDays, setRunningDays] = useState<number>(0);

  const init = async () => {
    const channel = await initializeFirstChannel();
    const adId = Number.parseInt(adid || 'a', 10);
    if (!Number.isNaN(adId)) {
      const ad = await myAdStore.getMyAd(channel?.id as number, adId) as never as AdDto;
      if (ad) {
        setAdInfo(ad);
        const end = new Date(ad.endDate).getTime();
        const from = new Date(ad.startDate).getTime();
        const timeDiff = end - from;
        const differenceInSeconds = Math.floor(timeDiff / 1000);
        const differenceInMinutes = Math.floor(differenceInSeconds / 60);
        const differenceInHours = Math.floor(differenceInMinutes / 60);
        const differenceInDays = Math.floor(differenceInHours / 24);
        setRunningHour(differenceInHours);
        setRunningDays(differenceInDays);
      }
    }

  };
  useEffect(() => {
    init();
  }, [adid]);

  return (
    <UniversalLayout>
      <MobileModalHeader type='page' title='광고 정보' />
      <BoxStyle>
        <div>
          <div>광고비용</div>
          <Row>
            <div>
              <img src="/images/coin.svg" alt="" />
            </div>
            <b><NumberFormat displayType='text' value={adInfo?.useCoin} thousandSeparator />  모스코인</b>
          </Row>
        </div>
        <div>
          <div>클릭 당 비용</div>
          <div>-</div>
        </div>
      </BoxStyle>
      <BoxStyle>
        <div>총 클릭</div>
        <div>-</div>
      </BoxStyle>
      <BoxStyle>
        <div>시작</div>
        <div style={{ fontSize: 14 }}>{moment(adInfo?.startDate).format('YY년 MM월 DD일 hh시mm분')}</div>
      </BoxStyle>
      <BoxStyle>
        <div>종료</div>
        <div style={{ fontSize: 14 }}>{moment(adInfo?.endDate).format('YY년 MM월 DD일 hh시mm분')}</div>
      </BoxStyle>
      <BoxStyle>
        <div>총 광고시간</div>
        <div>
          <span>{runningHour}시간</span>
          <span style={{ marginLeft: 10 }}>
            {runningDays > 1 && (`(${runningDays}일)`)}
          </span>
        </div>
      </BoxStyle>
    </UniversalLayout>
  );
});

const BoxStyle = styled.div`
  margin:14px 20px;
  padding:20px;
  border-radius: 6px;
  border: 1px solid var(--gray-80, #EFEFEF);
  background: var(--gray-white, #FFF);
  display: flex;
  justify-content: space-between;
`;


export default DealInfo;
