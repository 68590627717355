import { makeAutoObservable } from 'mobx';
import { ChannelBoardDto } from 'services/data-contracts';

/**
 * @description Public Link Store
 */
class AdminStore {
  constructor() {
    makeAutoObservable(this);
  }

  showSimulator = false;

  showNewLinkEditor = false;

  showNewBoardEditor = false;

  appActions: any;

  navigateTo: string;

  currentBoard: ChannelBoardDto | undefined;

  setShowSimulator() {
    this.showSimulator = !this.showSimulator;
  }

  setShowNewLinkEditor(show: boolean) {
    this.showNewLinkEditor = show;
  }

  setShowNewBoardEditor(show: boolean) {
    this.showNewBoardEditor = show;
  }

  setBoard(board: ChannelBoardDto | undefined) {
    this.currentBoard = board;
  }

  // setActions(actions: any) {
  //   const newAction = JSON.parse(JSON.stringify(actions));
  //   this.appActions = newAction;
  // }

  navigate(to: string) {
    this.navigateTo = to;
  }
}

export default AdminStore;
