import { makeObservable, observable, flow } from 'mobx';
import { MyAd as MyAdController } from 'services/MyAd';
import { AdDto } from 'services/data-contracts';

import _ from 'lodash';
import { HttpClient } from 'services/http-client';
import i18n from 'i18n';
import { PagenationType } from 'types/CommonTypes';
import ServiceStoreBase from './ServiceStoreBase';

class MyAdStore extends ServiceStoreBase {
  t = i18n.t;

  myAds: Array<AdDto>;

  myAd: AdDto | undefined;

  postPaignation: PagenationType;

  constructor() {
    super();
    makeObservable(this, {
      createAd: flow,
      getMyAds: flow,
      getMyAd: flow,
      cancelMyAd: flow,
      getInterestedAds: flow,
      myAds: observable,
      postPaignation: observable,
    });
  }

  api: MyAdController<unknown> = new MyAdController(new HttpClient());

  *createAd(chanelId: number, data: AdDto) {
    const { response } = yield this.api.createAd(chanelId, data);
    super.handleByStatus(response, { successMessage: this.t('A218') });

    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  }

  *getMyAds(channelId: number, query: any) {
    const { response } = yield this.api.getAds(channelId, query);

    if (response.status === 200) {
      this.myAds = response.data;
      const pagenation = response.headers['x-pagination'];
      if (pagenation) {
        this.postPaignation = JSON.parse(pagenation);
      }
    } else {
      this.myAds = [];
    }
  }

  *getMyAd(channelId: number, query: any) {
    const { response } = yield this.api.getAd(channelId, query);

    if (response.status === 200) {
      this.myAd = response.data;
      return response.data;
    } else {
      this.myAd = undefined;
      return undefined;
    }
  }

  *cancelMyAd(channelId: number, adId: number) {
    const { response } = yield this.api.cancel(channelId, adId);
    super.handleByStatus(response);

    return response.status === 200;
  }

  *getInterestedAds(channelId: number) {
    const { response } = yield this.api.getInterestedAds(channelId);
    if (response.status === 200) {
      this.myAd = response.data;
      return response.data;
    } else {
      this.myAd = undefined;
      return undefined;
    }
  }

  unMount() {
    this.myAds = [];
  }
}

export default MyAdStore;
