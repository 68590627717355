import React, { useState, useEffect, memo } from 'react';
import { TextContent, ChannelProfileModel, TextProps } from 'types/CommonTypes';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { isMobile } from 'react-device-detect';
import { useStore } from 'stores/StoreHelper';
import { Stack } from '@mui/material';

import Avatar from 'components/commons/Avatar';
import styled from 'styled-components';
import { ItemSectionStyle } from 'resources/controls/ItemSectionStyle';
import ThumbnailUploader from 'components/items/ThumbnailUploader';
import SaveButton from 'features/adminFeatures/admin/components/SaveButton';
import TitleEditor from './TitleEditor';
import DescriptionEditor from './DescriptionEditor';
import SettingContainer from './SettingContainer';
import SettingItemRow from './SettingItemRow';

const ProfileComponent = observer(
  ({
    onSave,
  }: {
    onSave: (profileModel?: ChannelProfileModel, type?: 'Profile' | 'Footer') => void;
  }) => {
    const { t } = useTranslation();
    const { uiStores } = useStore();
    const { profileStore } = uiStores;

    const [profileModel, setProfileModel] = useState<ChannelProfileModel>();
    const [nicknameProps, setNicknameProps] = useState<TextContent>();
    const [introductionProps, setIntroductionProps] = useState<TextContent>();

    useEffect(() => {
      if (typeof profileStore.profile !== 'undefined') {
        setProfileModel(profileStore.profile);
        profileStore.setProfile(profileStore.profile);

        setNicknameProps(profileStore.profileMetadataObject?.nicknameProps);
        setIntroductionProps(profileStore.profileMetadataObject?.introductionProps);
      }
    }, [profileStore.profile]);

    return (
      <Stack>
        <div>
          <ProfileContainerStyle>
            <ItemSectionStyle style={{ display: 'none' }}>
              <SettingContainer title="Profile Setting" height={isMobile ? 260 : 260}>
                <ProfileHeaderStyle style={{ border: 'none', paddingLeft: 10 }}>
                  {/* <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      checked={profileModel?.borderInfo?.useBorder}
                      onChange={(event: never, value: boolean) => {
                        setProfileModel((prev: CustomUserProfile) => {
                          return {
                            ...prev,
                            borderInfo: { ...prev.borderInfo, useBorder: value },
                          };
                        });
                      }}
                    />
                  }
                  label={t('borderLine')}
                /> */}
                  <div style={{ marginTop: 15 }}>
                    <SettingItemRow title="테두리색상">
                      <div>test</div>
                      {/* <InlineColorPicker
                      disabled={!profileModel?.borderInfo?.useBorder}
                      defaultColor={profileModel?.borderInfo?.borderColor}
                      onChangeColor={(color: string) => {
                        setProfileModel((prev: CustomUserProfile) => {
                          return {
                            ...prev,
                            borderInfo: { ...prev.borderInfo, borderColor: color },
                          };
                        });
                      }}
                    /> */}
                    </SettingItemRow>
                    <SettingItemRow
                      title="테두리두께"
                      style={{ margin: '10px 0', paddingRight: 20 }}
                    >
                      <div>test</div>

                      {/* <Slider
                      disabled={!profileModel?.borderInfo?.useBorder}
                      sx={{ marginLeft: 1, width: 210 }}
                      value={
                        typeof profileModel?.borderInfo?.borderWidth !== 'undefined'
                          ? profileModel?.borderInfo?.borderWidth
                          : 2
                      }
                      min={1}
                      max={10}
                      onChange={handleChangeSlider}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                    /> */}
                    </SettingItemRow>
                    <SettingItemRow
                      title="프로필경계"
                      style={{ margin: '20px 0', paddingRight: 20 }}
                    >
                      <div>test</div>

                      {/* <Slider
                      sx={{ marginLeft: 1, width: 210 }}
                      value={
                        typeof profileModel?.borderInfo?.borderRadius !== 'undefined'
                          ? profileModel?.borderInfo?.borderRadius
                          : 50
                      }
                      min={1}
                      max={50}
                      onChange={handleChangeBorder}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                    /> */}
                    </SettingItemRow>
                  </div>
                </ProfileHeaderStyle>
              </SettingContainer>
            </ItemSectionStyle>
            <ItemSectionStyle>
              <div style={{ padding: 10 }}>
                <div style={{ position: 'relative', zIndex: 1, paddingTop: 20 }}>
                  <Avatar mode="edit" size="large" profile={profileModel} />
                  <ThumbnailUploader
                    label={t('Image upload')}
                    usePreview={false}
                    style={{ marginTop: 20, justifyContent: 'center' }}
                    url={profileModel?.profileImage?.publicUrl}
                    onImageChange={(image: any) => {
                      setProfileModel((prev: ChannelProfileModel) => {
                        return {
                          ...prev,
                          profileImage: image,
                        };
                      });
                    }}
                  />
                </div>
              </div>
            </ItemSectionStyle>
          </ProfileContainerStyle>

          {/* 타이틀 */}
          <ItemSectionStyle>
            <ProfileContainerStyle style={{ borderBottom: '1px solid #eee' }}>
              <TitleEditor
                label={t('A161')}
                title={profileModel?.nickname}
                titleProps={nicknameProps}
                onChangeTitle={(value: string) => {
                  setProfileModel((prev: ChannelProfileModel) => {
                    return {
                      ...prev,
                      nickname: value,
                    };
                  });
                }}
                onChangeProps={(content: TextProps) => {
                  if (typeof content !== 'undefined') {
                    profileStore.updateProfileMetadata('nickname', content);
                  }
                }}
              />
            </ProfileContainerStyle>
          </ItemSectionStyle>
          <ItemSectionStyle>
            <ProfileContainerStyle>
              <DescriptionEditor
                label={t('A162')}
                description={profileModel?.introduction}
                descriptionProps={introductionProps}
                onChangeDescription={(value: string) => {
                  setProfileModel((prev: ChannelProfileModel) => {
                    return {
                      ...prev,
                      introduction: value,
                    };
                  });
                }}
                onChangeProps={(content: TextContent) => {
                  if (typeof content !== 'undefined') {
                    profileStore.updateProfileMetadata('introduction', content);
                  }
                }}
              />
            </ProfileContainerStyle>
          </ItemSectionStyle>
        </div>
        <SaveButton
          onClick={() => {
            onSave(
              // { ...profileModel, metadataObject: profileStore.profileMetadataObject },
              profileModel,
              'Profile',
            );
          }}
        />
      </Stack>
    );
  },
);

const ProfileContainerStyle = styled.div`
  border-radius: 12px;

  > button {
    border: none;
    border-radius: 0;
  }
  @media ${(props) => props.theme.media.mobile} {
  }
`;

const ProfileHeaderStyle = styled.div`
  text-align: left;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 10px;

  > div {
    font-size: 14px;
    color: #ddd;
  }
`;

export default memo(ProfileComponent);
