import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { toast } from 'react-toastify';
import { useStore } from 'stores/StoreHelper';
import { t } from 'i18next';
import MyNotifyInfo from 'components/commons/MyNotifyInfo';
import useToken from 'libs/hooks/useToken';

const isAndroidWebView = /(Version\/[\d.]+.*Chrome\/[\d.]+.*)?; wv/.test(navigator.userAgent);
const isIOSWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
const isWebView = isAndroidWebView || isIOSWebView;

const firebaseConfig = {
  apiKey: 'AIzaSyBJC0vDmVP9dXlIEPQ3rkd_ytY2GUzzEUo',
  authDomain: 'ohmoss-4edc9.firebaseapp.com',
  projectId: 'ohmoss-4edc9',
  storageBucket: 'ohmoss-4edc9.appspot.com',
  messagingSenderId: '845188265664',
  appId: '1:845188265664:web:cb822164b05eaaf10e1f25',
  measurementId: 'G-RXZGBYGRZR',
};

const useNotification = () => {
  const [messageReceived, setMessageReceived] = useState();
  const { uiStore } = useStore();
  initializeApp(firebaseConfig);

  const firebaseApp = initializeApp(firebaseConfig);
  // let messaging;

  async function getFirebaseToken() {
    const notificationPermit = await Notification.requestPermission().then(async function (result) {
      switch (result) {
        case 'denied':
          return false;
        case 'granted':
          return true;
        default:
          return false;
      }
    });
    if (isWebView === false) {
      // 1.firebase 지원여부 -> 2.알림권한 허용여부 
      try {
        const module = await import('firebase/messaging');
        const isFirebaseSupported = await module.isSupported();
        if(isFirebaseSupported){
          const messaging1 = module.getMessaging(firebaseApp);
          onMessageListener(messaging1, module.onMessage);
          if(notificationPermit){
            const currentToken = await module.getToken(messaging1, {
              vapidKey:
                'BOFlStQmPc4Bb3H8Sf5aNz-vWjqB0Rw-ODqeVF2p7zF8k3cHeihIZjvUr282Ov9bVLKyxyaVvrTtREgWizh9UZ4',
            });
  
            if (currentToken) {
              localStorage.setItem('pushToken', currentToken);
            }
          }
        }

      } catch (err) {
        console.error(err);
        // 여기서 에러 처리를 할 수 있습니다.
        throw err; // 에러를 던져 상위 호출자가 처리할 수 있도록 합니다.
      }
    }
    return null; // webView가 true이거나, currentToken이 없을 경우
  }

  const handleClickNotify = () => {
    uiStore.universalModal.show({
      title: t('A233'),
      description: t('A234'),
      useDefaultButtons: true,
      bottomSheetOptions: { blocking: true },
      modalOption: { style: { maxWidth: 500, maxHeight: 500 } },
      children: (
        <>
          <MyNotifyInfo />
        </>
      ),
    });
  };

  const onMessageListener = (messaging, onMessage) =>
    new Promise(() => {
      if (!onMessage) {
        return;
      }
      onMessage(messaging, (payload) => {
        // setMessageReceived(payload);
        // setTimeout(() => {
        //   myNotificationStore.getMyNotificationCount(myChannelStore.currentChannel?.id);
        //   myNotificationStore.getMyNotifications(myChannelStore.currentChannel?.id);
        // }, 500);
        //
        toast(
          <div onClick={handleClickNotify}>
            <div style={{ fontWeight: 500 }}>
              <b>{t('A248')}</b>
            </div>
            <div>{payload?.notification?.body.substring(0, 40)}</div>
          </div>,
        );
      });
    });

  return { getFirebaseToken, messageReceived };
};

export default useNotification;
