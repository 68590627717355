import React, { CSSProperties, memo, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import styled from 'styled-components';

type TextSizeListProps = {
  label?: string | number;
  value: string;
};

const TextSizeSelect = ({
  style,
  containerStyle,
  textSizeList,
  defaultValue,
  onClick,
}: {
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  textSizeList?: TextSizeListProps[];
  defaultValue?: TextSizeListProps;
  onClick: (item?: any | undefined) => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<TextSizeListProps | undefined>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const openPopover = Boolean(anchorEl);

  const handlePopoverOpen = debounce((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, 1000);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  /** select 리스트 onClick 이벤트 */
  const handleOnClickSelectList = (item: TextSizeListProps) => {
    setOpen(false);
    onClick(item.value);
    if (typeof item !== 'undefined') {
      setSelectedValue(item);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <ContainerStyle style={containerStyle}>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={openPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      />
      <TextSelectLabelStyle
        style={style}
        onClick={() => {
          setOpen(!open);
        }}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {selectedValue?.label}
        {open === true ? (
          <ArrowIconStyle src="/image/select_arrow_down.png" />
        ) : (
          <ArrowIconStyle src="/image/select_arrow_down.png" />
        )}
      </TextSelectLabelStyle>

      {/* Select 창 */}
      {open === true && (
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <TextSelectStyle style={style}>
            {textSizeList?.map((item: TextSizeListProps, i: number) => (
              <TextOptionStyle
                key={i}
                value={item.value}
                selectedValue={selectedValue?.label}
                style={{ fontSize: 14 }}
                onClick={() => handleOnClickSelectList(item)}
              >
                {item.value === selectedValue?.value && (
                  <CheckRoundedIcon
                    sx={{
                      fontSize: '18px',
                      paddingRight: '2px',
                      height: '40px',
                    }}
                  />
                )}
                <div>{item.label}</div>
              </TextOptionStyle>
            ))}
          </TextSelectStyle>
        </ClickAwayListener>
      )}
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div<{ contianerStyle?: any }>`
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 6px;
  position: relative;
  ${(props) => props.contianerStyle}
`;

const TextSelectLabelStyle = styled(Typography)`
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 0 0 0 10px;
  cursor: pointer;
  &:hover {
    background: #efefef;
  }
`;

const TextSelectStyle = styled.ul`
  top: -420px;
  height: 160px;
  overflow-y: scroll;
  text-align: end;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.22);
  border-radius: 8px;
  padding: 0;
  margin: 256px 10px 0 0;
  position: absolute;
  right: -4px;
  z-index: 100;
  width: 100px;
  background: #fff;
  cursor: pointer;
`;

const TextOptionStyle = styled.li<{ value: string; selectedValue?: string | number }>`
  background: white;
  height: 40px;
  line-height: 40px;
  text-align: end;
  /* display: ${(props) => (props.value === props.selectedValue ? 'flex' : '')}; */
  display: flex;
  justify-content: flex-end;
  background-color: ${(props) => (props.value === props.selectedValue ? '#f2f2f2' : '')};
  color: ${(props) =>
    props.value === props.selectedValue ? props.theme.colors.pointColor : 'black'};
  padding: 0 10px;
  &:hover {
    background-color: #f2f2f2;
  }
`;

const ArrowIconStyle = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 5px 0 14px;
`;

export default memo(TextSizeSelect);
