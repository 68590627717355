import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import { ProposalDto } from 'services/data-contracts';
import ProposalController from 'controllers/ProposalController';
import { useStore } from 'stores/StoreHelper';
import { OhmossButton } from 'resources/controls/OhmossButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';

/** @description 개별 제안 */

const SentProposalContainer = ({ proposal }: { proposal: ProposalDto }) => {
  const { uiStore } = useStore();

  const [result, setResult] = useState<ProposalDto>();
  const { getSentProposal } = ProposalController();
  const [localDate, setLocalDate] = useState<{ startDate: string; endDate: string }>({
    startDate: '',
    endDate: '',
  });

  const getProposal = async () => {
    const response = (await getSentProposal(proposal?.id as number)) as unknown as ProposalDto;
    setResult(response);
  };

  useEffect(() => {
    if (proposal) {
      getProposal();
    }
  }, [proposal]);

  useEffect(() => {
    if (proposal) {
      const newStartDateString = new Date(proposal?.startDate as string);
      const newEndDateString = new Date(proposal?.endDate as string);

      if (newStartDateString && newEndDateString) {
        setLocalDate({
          startDate: newStartDateString.toLocaleString(),
          endDate: newEndDateString.toLocaleString(),
        });
      }
    }
  }, [proposal?.startDate, proposal?.endDate]);

  return (
    <Wrapper>
      {proposal?.requestType === 'BLOCK' ? (
        <>
          <CategoryTitle>제목</CategoryTitle>
          <TitleContainer>
            <Title>{proposal?.title}</Title>
          </TitleContainer>
          <CategoryTitle>보낸 사람</CategoryTitle>
          <TitleContainer>
            <Title>{proposal?.sender}</Title>
          </TitleContainer>
          <CategoryTitle>전화번호</CategoryTitle>
          <TitleContainer>
            <Title>{proposal?.phoneNumber}</Title>
          </TitleContainer>
          <CategoryTitle>이메일</CategoryTitle>
          <TitleContainer>
            <Title>{proposal?.contactReply}</Title>
          </TitleContainer>
          <CategoryTitle>첨부파일</CategoryTitle>
          <TitleContainer>
            <Title style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {proposal?.attechFile ? (
                <>
                  <a href={proposal?.attechFile ? proposal?.attechFile.publicUrl : '#'}>
                    <IconButton>
                      <AttachFileIcon />
                    </IconButton>
                    {proposal?.attechFile ? proposal?.attechFile.name : '첨부파일 없음'}
                  </a>
                </>
              ) : (
                '첨부파일 없음'
              )}
            </Title>
          </TitleContainer>
          <CategoryTitle>내용</CategoryTitle>
          <ContentContainer>
            <Content>{proposal?.content}</Content>
          </ContentContainer>
          <OhmossButton
            onClick={() => uiStore.modal.close()}
            style={{ width: '80%', marginBottom: 10 }}
            type="primary"
          >
            확인
          </OhmossButton>
        </>
      ) : (
        <>
          <CategoryTitle>회사명</CategoryTitle>
          <TitleContainer>
            <Title>{proposal?.company}</Title>
          </TitleContainer>
          <CategoryTitle>진행 기간</CategoryTitle>
          <TitleContainer>
            <Title>
              {proposal?.startDate !== null && proposal?.endDate !== null
                ? `${localDate?.startDate} ~ ${localDate?.endDate}`
                : '협의 가능'}
            </Title>
          </TitleContainer>
          <CategoryTitle>카테고리</CategoryTitle>
          <TitleContainer>
            <Title>{proposal?.category}</Title>
          </TitleContainer>
          <CategoryTitle>제안 종류</CategoryTitle>
          <TitleContainer>
            <Title>{proposal?.proposalType}</Title>
          </TitleContainer>
          <CategoryTitle>제품명</CategoryTitle>
          <TitleContainer>
            <Title>{proposal?.productName}</Title>
          </TitleContainer>
          <CategoryTitle>제품 소개 링크</CategoryTitle>
          <TitleContainer>
            <Title style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              <a href={proposal?.productLink}>{proposal?.productLink}</a>
            </Title>
          </TitleContainer>
          <CategoryTitle>판매가</CategoryTitle>
          <TitleContainer>
            <Title>{`${proposal?.sellingPrice}원`}</Title>
          </TitleContainer>
          <CategoryTitle>제안 가격</CategoryTitle>
          <TitleContainer>
            <Title>{`${proposal?.proposePrice}원`}</Title>
          </TitleContainer>
          <CategoryTitle>판매 수수료</CategoryTitle>
          <TitleContainer>
            <Title>{`${proposal?.salesCommission}%`}</Title>
          </TitleContainer>
          <CategoryTitle>전화번호</CategoryTitle>
          <TitleContainer>
            <Title>{proposal?.phoneNumber}</Title>
          </TitleContainer>
          <CategoryTitle>회신 연락처</CategoryTitle>
          <TitleContainer>
            <Title>{proposal?.contactReply}</Title>
          </TitleContainer>
          <CategoryTitle>보내는 사람</CategoryTitle>
          <TitleContainer>
            <Title>{proposal?.sender}</Title>
          </TitleContainer>
          <CategoryTitle>제품 협찬 여부</CategoryTitle>
          <TitleContainer>
            <Title>{proposal?.isSponsorship ? '네' : '아니오'}</Title>
          </TitleContainer>
          <CategoryTitle>첨부파일</CategoryTitle>
          <TitleContainer>
            <Title style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {proposal?.attechFile ? (
                <>
                  <a href={proposal?.attechFile ? proposal?.attechFile.publicUrl : '#'}>
                    <IconButton>
                      <AttachFileIcon />
                    </IconButton>
                    {proposal?.attechFile ? proposal?.attechFile.name : '첨부파일 없음'}
                  </a>
                </>
              ) : (
                '첨부파일 없음'
              )}
            </Title>
          </TitleContainer>
          <CategoryTitle>내용</CategoryTitle>
          <ContentContainer>
            <Content>{proposal?.description}</Content>
          </ContentContainer>
          <OhmossButton
            onClick={() => uiStore.modal.close()}
            style={{ width: '80%', marginBottom: 10 }}
            type="primary"
          >
            확인
          </OhmossButton>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  /* width: 100%; */
  padding: 0 10px 10px 10px;
  align-items: center;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  min-height: 50px;
  text-align: flex-start;
  border-radius: 4px;
  border: 1px solid #efefef;
  background: #fff;
  width: 80%;
  background: #fff;
  padding: 15px 20px 15px 20px;
  margin-bottom: 20px;
`;
const Title = styled.div`
  font-size: 14px;
`;
const ContentContainer = styled.div`
  min-height: 120px;
  text-align: flex-start;
  border-radius: 4px;
  border: 1px solid #efefef;
  width: 80%;
  background: #fff;
  padding: 10px 20px 10px 20px;
  margin-bottom: 20px;
`;
const Content = styled.div`
  font-size: 14px;
`;
const CategoryTitle = styled.div`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  padding-left: 12%;
  align-self: flex-start;
  margin-bottom: 5px;
`;

export default SentProposalContainer;
