import React, { CSSProperties, useEffect, useState } from 'react';
import StickerItems from 'resources/images/sticker';
import { LinkDto } from 'services/data-contracts';
import { StickerTypes } from './StickerTypes';
import CustomizedStickers from './CustomizedStickers';

interface StickerContainerProps {
  item?: LinkDto;
  stickerPosition?: 'top' | 'bottom';
  containerStyle?: CSSProperties;
  textBoxStyle?: CSSProperties;
  textStyle?: CSSProperties;
  align?: 'flex-start' | 'center' | 'flex-end';
}
const TitleStickerBox = ({
  item,
  containerStyle,
  stickerPosition,
  textBoxStyle,
  textStyle,
  align,
}: StickerContainerProps) => {
  const [selectedStickers, setSelectedStickers] = useState<StickerTypes[]>([]);

  useEffect(() => {
    if (item?.stickers) {
      const data = item?.stickers.map((sticker) => {
        const parsedStyle = sticker.style !== undefined ? JSON.parse(sticker.style) : {};
        const stickerType = (sticker.stickerType ?? 'TypeA') as keyof typeof StickerItems;
        const { component } = StickerItems[stickerType];
        return {
          ...sticker,
          component,
          internalStyle: parsedStyle,
        };
      });
      setSelectedStickers(data);
    }
  }, [item?.stickers]);

  const Box = () => {
    if (stickerPosition === 'top') {
      return (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: align }}>
            <CustomizedStickers mode="view" selectedStickers={selectedStickers} />
          </div>
          {item?.title && (
            <div style={{ ...textBoxStyle, marginTop: 5, display: 'inline-block' }}>
              <div style={{ ...textStyle }}>{item?.title}</div>
            </div>
          )}
        </>
      );
    } else {
      return (
        <div>
          {item?.title && (
            <div
              style={{
                ...textBoxStyle,
                marginBottom: 5,
                display: 'inline-block',
              }}
            >
              <div
                style={{
                  ...textStyle,
                }}
              >
                {item?.title}
              </div>
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: align }}>
            <CustomizedStickers mode="view" selectedStickers={selectedStickers} />
          </div>
        </div>
      );
    }
  };

  return (
    <div style={{ ...containerStyle }}>
      <Box />
    </div>
  );
};

export default TitleStickerBox;
