import styled from 'styled-components';

const ItemSectionStyle = styled.div`
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  background: #fff;
  margin-bottom: 20px;
  // 새로 추가
  flex: 1;
`;

const ItemSectionTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  display: flex;
  align-items: center;
`;

const ItemSectionDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 20px;
  color: #5a5a5a;
  @media ${(props) => props.theme.media.mobile} {
    margin-bottom: 20px;
  }
`;

const ItemContent = styled.div`
  margin-top: 15px;
`;

export { ItemSectionStyle, ItemSectionTitle, ItemSectionDescription, ItemContent };
