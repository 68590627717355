import SearchBox from 'components/commons/SearchBox';
import {
  ListBoxContentsTemplate,
  ListBoxTemplate,
  ListBoxWrapper,
  NoContentStyle,
} from 'features/csTown/commonComponents/styles';
import ChecklistCategorySelector from 'features/csTown/components/pc/ChecklistCategory';
// eslint-disable-next-line max-len
import CSTownChecklistController from 'features/csTown/pages/pageControllers/CSTownChecklistController';
import { useStateContext } from 'libs/hooks/usePageState';
import { useRootContext } from 'libs/hooks/useRootContext';
import React, { useEffect, useState } from 'react';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { ChecklistCategoryDto } from 'services/data-contracts';
import { CSTownChecklistState } from 'types/CommonTypes';
import NoContent from 'components/commons/NoContent';
import CSTownChecklistDetailContainer from './CSTownChecklistDetailContainer';
import ChecklistList from './ChecklistList';

const CSTownChecklistContainer = () => {
  const { myChannel } = useRootContext();
  const { stateModel, setPageState, setMultipleState, receiver } =
    useStateContext<CSTownChecklistState>();
  const { getChecklistCategories, search, unmountDeals } = CSTownChecklistController();

  const [pageMode, setPageMode] = useState<'READY' | 'NEW' | 'VIEW'>('READY');
  const [checklistCategories, setChecklistCategories] = useState<ChecklistCategoryDto[]>();

  const createChecklist = async () => {
    setPageMode('NEW');
    setPageState('selectedChecklist', null);
    const categories = await getChecklistCategories(myChannel?.id as number);
    setChecklistCategories(categories);
  };

  const handleClickChecklist = (checklist: ChecklistCategoryDto) => {
    setMultipleState({ selectedChecklist: checklist, selectedTopic: undefined });
    setPageMode('VIEW');
  };

  useEffect(() => {
    if (receiver?.id === 'reload' && receiver.param === 'setPageMode') {
      setPageMode('READY');
    }
  }, [receiver]);

  useEffect(() => {
    if (stateModel?.checklists) unmountDeals();
  }, [stateModel?.checklists]);

  return (
    <ListBoxWrapper>
      <ListBoxTemplate style={{ borderRight: '1px solid #efefef' }}>
        <SearchBox onEntered={search} onCancel={() => search('')} />
        <div style={{ flexGrow: 1, overflowY: 'auto' }}>
          {stateModel?.checklists?.length === 0 && <NoContent title={`Checklist 가`} />}

          <ChecklistList checklist={stateModel?.checklists} onClickItem={handleClickChecklist} />
        </div>
        <OhmossButton style={{ marginTop: 10 }} onClick={createChecklist} type="primaryPc">
          체크리스트 만들기
        </OhmossButton>
      </ListBoxTemplate>
      <ListBoxContentsTemplate>
        {pageMode === 'NEW' && (
          <div>
            <ChecklistCategorySelector categoryProps={checklistCategories} />
          </div>
        )}
        {pageMode === 'READY' && (
          <NoContentStyle>
            <div>목록에서 체크리스트를 선택 해주세요.</div>
          </NoContentStyle>
        )}
        {pageMode === 'VIEW' && <CSTownChecklistDetailContainer />}
      </ListBoxContentsTemplate>
    </ListBoxWrapper>
  );
};

export default CSTownChecklistContainer;
