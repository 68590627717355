import { makeAutoObservable } from 'mobx';

type XHRType = 'idle' | 'pending' | 'done' | 'error';
class XHRStore {
  constructor() {
    makeAutoObservable(this);
  }

  state: 'idle' | 'pending' | 'done' | 'error' = 'idle';

  setState(state: XHRType) {
    this.state = state;
  }
}

export default XHRStore;
