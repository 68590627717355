/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ChannelNotificationDto, ChannelNotificationPinDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MyNotification<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 알림 즐겨찾기
   *
   * @tags my notification
   * @name NotificationPin
   * @summary 알림 즐겨찾기
   * @request PATCH:/v2/me/channels/{channelId}/notifications/{notificationId}/pin
   * @secure
   */
  notificationPin = (
    channelId: number,
    notificationId: number,
    data: ChannelNotificationPinDto,
    params: RequestParams = {},
  ) =>
    this.http.request<ChannelNotificationDto, any>({
      path: `/v2/me/channels/${channelId}/notifications/${notificationId}/pin`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 알림 전체 목록
   *
   * @tags my notification
   * @name GetNotifications
   * @summary 알림 전체 목록
   * @request GET:/v2/me/channels/{channelId}/notifications
   * @secure
   */
  getNotifications = (
    channelId: number,
    query?: {
      /** 검색어 (채널명, 제목, 본문) */
      searchText?: string;
      /**
       * 페이지 번호 default: 1
       * @format int32
       */
      page?: number;
      /**
       * 페이지 사이즈 default: 20
       * @format int32
       */
      size?: number;
      /** 알림 타입 */
      notificationType?:
        | 'None'
        | 'Event'
        | 'News'
        | 'Suggest'
        | 'Coupon'
        | 'Notice'
        | 'Inquiry'
        | 'Invitation'
        | 'Gift';
      /** 즐겨찾기 */
      isPin?: boolean;
      /** 정렬 기준 default: createDate */
      sortBy?: string;
      /** 정렬 방향 (desc, asc) default: 내림차순 */
      direction?: string;
      /**
       * 'yyyy-MM-dd' (시작 날짜)
       * @format date
       */
      from?: string;
      /**
       * 'yyyy-MM-dd' (마지막 날짜)
       * @format date
       */
      to?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ChannelNotificationDto[], any>({
      path: `/v2/me/channels/${channelId}/notifications`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 알림 조회
   *
   * @tags my notification
   * @name GetNotification
   * @summary 알림 조회
   * @request GET:/v2/me/channels/{channelId}/notifications/{notificationId}
   * @secure
   */
  getNotification = (channelId: number, notificationId: number, params: RequestParams = {}) =>
    this.http.request<ChannelNotificationDto, any>({
      path: `/v2/me/channels/${channelId}/notifications/${notificationId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 채널의 알림 전체 읽기
   *
   * @tags my notification
   * @name ReadAllNotification
   * @summary 내 채널의 알림 전체 읽기
   * @request GET:/v2/me/channels/{channelId}/notifications/read-all
   * @secure
   */
  readAllNotification = (channelId: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/notifications/read-all`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * @description 내 채널의 알림 카운트
   *
   * @tags my notification
   * @name GetNotificationCountOfChannel
   * @summary 내 채널의 알림 카운트
   * @request GET:/v2/me/channels/{channelId}/notifications/count
   * @secure
   */
  getNotificationCountOfChannel = (channelId: number, params: RequestParams = {}) =>
    this.http.request<number, any>({
      path: `/v2/me/channels/${channelId}/notifications/count`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
