import React, { useEffect, useState } from 'react';

import { LinkDto } from 'services/data-contracts';

import ManagementLayout from 'features/management/components/common/ManagementLayout';
import CommonTabButtons from 'components/commons/buttons/CommonTabButtons';
import CSTownController from 'controllers/CSTownController';
import NoContent from 'components/commons/NoContent';
import { useRootContext } from 'libs/hooks/useRootContext';
import DealList from '../containers/DealList';

const DealListPage = () => {
  const { myChannel } = useRootContext();
  const { deals, Brand, Seller } = CSTownController();

  const [tab, setTab] = useState<string>('진행중 공구');
  const [delegatedDeals, setDelegatedDeals] = useState<LinkDto[]>([]);

  const getSellerDeals = async () => {
    if (myChannel?.channelType === 'BRAND') {
      Brand.DEAL.getDelegatedDeals();
    } else if (myChannel?.channelType === 'SELLER') {
      Seller.DEAL.getDeals();
    }
  };

  useEffect(() => {
    if (deals) {
      if (tab === '진행중 공구') {
        setDelegatedDeals(deals?.filter((deal: LinkDto) => deal.status === 'ONGOING') || []);
      } else {
        setDelegatedDeals(deals?.filter((deal: LinkDto) => deal.status !== 'ONGOING') || []);
      }
    }
  }, [deals]);

  useEffect(() => {
    if (tab && myChannel) {
      getSellerDeals();
    }
  }, [tab, myChannel]);

  return (
    <>
      <ManagementLayout style={{ justifyContent: 'flex-start', padding: 0 }}>
        <CommonTabButtons items={['진행중 공구', '지난 공구']} onHandleTabClick={setTab} />
        <div style={{ padding: '0px 20px 0px 20px' }}>
          <DealList deals={delegatedDeals} />
          {deals && deals?.length === 0 && <NoContent title="표시할 공구가" />}
        </div>
      </ManagementLayout>
    </>
  );
};

export default DealListPage;
