import Resizer from 'react-image-file-resizer';
export const createImage = (url: string): any =>
  new Promise((resolve, reject) => {
    const image = new Image();

    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

export function getRadianAngle(degreeValue: any) {
  return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

async function getCroppedImg(
  imageSrc: any,
  pixelCrop: any,
  rotation = 0,
  imageSize = { width: 200, height: 200 },
  flip = { horizontal: false, vertical: false },
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    image.width * (pixelCrop.x / 100),
    image.height * (pixelCrop.y / 100),
    image.width * (pixelCrop.width / 100),
    image.height * (pixelCrop.height / 100),
  );

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = image.width * (pixelCrop.width / 100);
  canvas.height = image.height * (pixelCrop.height / 100);

  ctx.putImageData(data, 0, 0);

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob: any) => {
      blob.name = 'test.png';
      resizeImage(blob, imageSize).then((file) => {
        resolve({ file, blob });
      });
    }, 'image/png');
  });
}

const resizeImage = (file: any, imageSize: any) =>
  new Promise((resolve) => {
    const t = (file.type ?? file.contentType).split('/');
    const type = t.length === 1 ? file.type : t[1];
    Resizer.imageFileResizer(
      file,
      imageSize.width,
      imageSize.height,
      type,
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      'file',
    );
  });

export { getCroppedImg, resizeImage };
