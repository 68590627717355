import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import UniversalLayout from 'components/commons/layouts/UniversalLayout';
import { useSearchParams } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import useToken from 'libs/hooks/useToken';
import MembershipController from 'controllers/MembershipController';
import { MembershipDto, MembershipPurchaseDto } from 'services/data-contracts';
import { useRootContext } from 'libs/hooks/useRootContext';
import CommonBottomsheetHeader from '../CommonBottomSheetHeader';
import PurchaseSuccessBottomSheet from './PurchaseSuccessBottomSheet';
import GiftSuccessBottomSheet from './GiftSuccessBottomSheet';

export interface MembershipPaymentAuthProps {
  responseStatus: string;
  message: string;
}

const MembershipPaymentAuth = observer(() => {
  const { uiStore } = useStore();
  const authCompleted = useRef<boolean>(false);
  const { navigate } = useNavigation();
  const [searchParam] = useSearchParams();
  const { validUser } = useToken();
  const { getMyChannel } = useRootContext();

  const { myChannel } = useRootContext();
  const { approve, approveGiftPayment, getCurrentMembership, purchaseMembership } =
    MembershipController();

  const [status, setStatus] = useState<MembershipPaymentAuthProps>({
    responseStatus: '',
    message: '',
  });

  // http://localhost:3000/my/subscriptions/payment?customerKey=35be467c-b999-4b38-9471-6dbdc37f8885&authKey=bln_KJPKz01nP2E
  const auth = async () => {
    // billing
    const customerKey = searchParam.get('customerKey');
    const authKey = searchParam.get('authKey');
    const membershipId = searchParam.get('id');
    const duration = searchParam.get('dr');

    // payment
    const orderId = searchParam.get('orderId');
    const paymentKey = searchParam.get('paymentKey');

    const amount = searchParam.get('amount');
    const amountInNumbers = Number(amount);
    const receiverChannelName = searchParam.get('receiverChannelName');

    if (customerKey && authKey && membershipId && duration) {
      billing(customerKey, authKey, Number(membershipId), Number(duration));
      return;
    }

    if (validUser()) {
      if (orderId && paymentKey && amount && receiverChannelName !== null) {
        const response = (await approveGiftPayment(myChannel?.id as number, {
          paymentKey,
          orderId,
          amount: amountInNumbers,
          receiverChannelName,
        })) as any;

        if (response?.data?.errorMessage || response?.data?.error) {
          toast.error(response?.data.errorMessage);
          setStatus({ responseStatus: 'error', message: response?.data.errorMessage });
        } else {
          setStatus({ responseStatus: 'success', message: '' });

          if (response) {
            showGiftPaymentSuccessBottomSheet(response);
          }
        }
      } else if (orderId && paymentKey && amount && receiverChannelName === null) {
        const response = (await approve(myChannel?.id as number, {
          paymentKey,
          orderId,
          amount: amountInNumbers,
        })) as any;

        if (response?.data?.errorMessage || response?.data?.error) {
          toast.error(response?.data.errorMessage);
          setStatus({ responseStatus: 'error', message: response?.data.errorMessage });
        } else {
          setStatus({ responseStatus: 'success', message: '' });

          const result = await getCurrentMembership();
          if (result) {
            showMyPaymentSuccessBottomSheet(result);
          }
        }
      } else {
        setStatus({ responseStatus: 'error', message: '' });
      }
    }
  };

  const billing = async (
    customerKey: string,
    authKey: string,
    membershipPlanId: number,
    monthly: number,
  ) => {
    const params: MembershipPurchaseDto = {
      membershipPlanId,
      monthly,
      customerKey,
      authKey,
    };
    const result = (await purchaseMembership(params)) as any;
    if (result) {
      showMyPaymentSuccessBottomSheet(result);
    }
  };

  const showMyPaymentSuccessBottomSheet = async (membershipInfo: MembershipDto) => {
    if (membershipInfo) {
      await getMyChannel();
      authCompleted.current = true;

      uiStore.bottomSheet.show({
        style: { minHeight: '40vh' },
        header: (
          <CommonBottomsheetHeader
            title="가입이 완료되었습니다"
            styles={{ color: '#9BE56E', backgroundColor: '#173300' }}
          />
        ),
        children: (
          <PurchaseSuccessBottomSheet
            channelType={myChannel?.channelType}
            membership={membershipInfo}
          />
        ),
      });
    }
  };

  const showGiftPaymentSuccessBottomSheet = (membershipInfo: MembershipDto) => {
    if (membershipInfo) {
      uiStore.bottomSheet.show({
        style: { minHeight: '40vh' },
        header: (
          <CommonBottomsheetHeader
            title="멤버십 선물하기"
            styles={{ color: '#9BE56E', backgroundColor: '#173300' }}
          />
        ),
        children: <GiftSuccessBottomSheet membership={membershipInfo} />,
      });
    }
  };

  useEffect(() => {
    if (myChannel && authCompleted?.current === false) {
      auth();
    }
  }, [myChannel]);

  return (
    <UniversalLayout>
      {status.responseStatus === 'success' ? (
        <ContentWrapper>
          <img src="/images/welcome.svg" alt="" style={{ width: '200px' }} />
          <WelcomeText>결제가 성공적으로 완료되었습니다! 🎉</WelcomeText>
          <OhmossButton
            onClick={() => {
              navigate('/my/subscriptions');
            }}
            style={{ marginTop: 30, width: '70%' }}
          >
            돌아가기
          </OhmossButton>
        </ContentWrapper>
      ) : status.responseStatus === 'error' ? (
        <ContentWrapper>
          <img src="/images/sad.svg" alt="" style={{ width: '200px' }} />
          <WelcomeText>
            결제에 실패하였습니다. 😭
            <br />
            {status.message}
          </WelcomeText>
          <OhmossButton
            onClick={() => {
              navigate('/my/subscriptions?tab=멤버십구독');
            }}
            style={{ marginTop: 30, width: '70%' }}
          >
            돌아가기
          </OhmossButton>
        </ContentWrapper>
      ) : (
        <></>
      )}
    </UniversalLayout>
  );
});

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30%;
`;

const WelcomeText = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  color: #4e4e4e;
  text-align: center;
`;

export default MembershipPaymentAuth;
