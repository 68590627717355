/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { BoardCategoryDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class BoardConfig<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 게시판 카테고리 정보
   *
   * @tags board Config
   * @name GetCategory
   * @summary 게시판 카테고리 정보
   * @request GET:/v2/boards/config/categories/{categoryId}
   * @secure
   */
  getCategory = (categoryId: number, params: RequestParams = {}) =>
    this.http.request<BoardCategoryDto, any>({
      path: `/v2/boards/config/categories/${categoryId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 게시판 카테고리 수정
   *
   * @tags board Config
   * @name UpdateCategory
   * @summary 게시판 카테고리 수정
   * @request PUT:/v2/boards/config/categories/{categoryId}
   * @secure
   */
  updateCategory = (categoryId: number, data: BoardCategoryDto, params: RequestParams = {}) =>
    this.http.request<BoardCategoryDto, any>({
      path: `/v2/boards/config/categories/${categoryId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 게시판 카테고리 삭제
   *
   * @tags board Config
   * @name DeleteCategory
   * @summary 게시판 카테고리 삭제
   * @request DELETE:/v2/boards/config/categories/{categoryId}
   * @secure
   */
  deleteCategory = (categoryId: number, params: RequestParams = {}) =>
    this.http.request<string, any>({
      path: `/v2/boards/config/categories/${categoryId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 게시판 카테고리 목록
   *
   * @tags board Config
   * @name GetCategories
   * @summary 게시판 카테고리 목록
   * @request GET:/v2/boards/config/categories
   * @secure
   */
  getCategories = (params: RequestParams = {}) =>
    this.http.request<BoardCategoryDto[], any>({
      path: `/v2/boards/config/categories`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 게시판 카테고리 생성
   *
   * @tags board Config
   * @name CreateCategory
   * @summary 게시판 카테고리 생성
   * @request POST:/v2/boards/config/categories
   * @secure
   */
  createCategory = (data: BoardCategoryDto, params: RequestParams = {}) =>
    this.http.request<BoardCategoryDto, any>({
      path: `/v2/boards/config/categories`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
