import React, { CSSProperties, useEffect, useState } from 'react';
import { LinkDto } from 'services/data-contracts';
import { ItemContainerStyle } from 'features/adminFeatures/template/components/ItemContainerStyle';
import _ from 'lodash';
import { SimpleLinkModel } from 'types/CommonTypes';
import { validationHelper as VH } from 'controls/validationHelper';
import styled from 'styled-components';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';

type defaultProps = {
  item: SimpleLinkModel;
  mode?: 'create' | 'edit';
  onDelete?: (item: LinkDto) => void;
};

const SimpleLink = observer(({ item, mode, onDelete }: defaultProps) => {
  const { channelStore } = useStore();
  const IMAGE_WIDTH = 50;
  let urlRef = '';

  const [linkModel, setLinkModel] = useState<SimpleLinkModel>();
  const [overrideStyle, setOverrideStyle] = useState<CSSProperties>();

  if (!_.isEmpty(item) && item.linkUrl) {
    urlRef =
      item?.linkUrl.startsWith('http://') || item?.linkUrl.startsWith('https://')
        ? item?.linkUrl
        : `//${item?.linkUrl}`;
  }

  const NewTag = (props: any): any => {
    if (item?.linkUrl) {
      const useLink = linkModel?.metadataObject?.useLink;
      const useBlank = linkModel?.metadataObject?.useBlank;
      const validUseLink = typeof useLink === 'undefined' && useLink !== true;
      const result = VH.url(item?.linkUrl);
      if (result && validUseLink) {
        return (
          <>
            <a
              rel="noreferrer"
              href={String(urlRef)}
              target={useBlank ? '_blank' : '_self'}
              style={{
                textDecoration: 'none',
                color: overrideStyle?.color ? overrideStyle.color : 'black',
                width: '100%',
              }}
            >
              <>{props.children}</>
            </a>
          </>
        );
      } else {
        return <div style={{ width: '100%' }}>{props.children}</div>;
      }
    } else {
      return <div style={{ width: '100%' }}>{props.children}</div>;
    }
  };

  useEffect(() => {
    if (typeof item.metadata !== 'undefined') {
      const custom: any = JSON.parse(item.metadata);
      item.metadataObject = custom;
      setLinkModel(item);
      let parsedStyle;
      if (channelStore.myStyle?.style) {
        parsedStyle = JSON.parse(channelStore.myStyle.style);
        setOverrideStyle(parsedStyle);
      }

      /** 투명은 스타일 지정에 우선함 */
      if (item.metadataObject?.useTransparent) {
        setOverrideStyle({
          ...parsedStyle,
          background: 'transparent',
          borderWidth: 0,
          boxShadow: 'unset',
          ...item.metadataObject.style,
        });
      }
    }
  }, [item, channelStore.myStyle]);

  return (
    <ItemContainerStyleOverride style={overrideStyle} hover={!item.metadataObject?.useTransparent}>
      <NewTag>
        <div
          style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <div className="link-container-text">
            {item.thumbnail && (
              <div
                style={{
                  height: IMAGE_WIDTH,
                  width: IMAGE_WIDTH,
                  float: 'left',
                  position: 'relative',
                  overflow: 'hidden',
                  borderRadius: overrideStyle?.borderRadius || 10,
                  marginRight: 15,
                }}
              >
                <img
                  alt=""
                  src={item.thumbnail?.publicUrl}
                  // width={IMAGE_WIDTH}
                  // height={IMAGE_WIDTH}
                  style={{
                    borderRadius: overrideStyle?.borderRadius || 10,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </div>
            )}
            <div
              style={{
                position: 'relative',
                width: item.thumbnail !== null ? 'calc(100% - 130px)' : '100%',
                // width: item.thumbnail !== null ? 'unset' : '100%',
              }}
            >
              <div
                style={{
                  width: '100%',
                  verticalAlign: 'middle',
                  whiteSpace: 'pre-line',
                  textAlign: 'center',
                  // fontSize: 16,
                  fontSize: '1em',
                  fontFamily: overrideStyle?.fontFamily,
                  // letterSpacing: 2,
                }}
              >
                {item?.title}
              </div>
            </div>
          </div>
        </div>
      </NewTag>
      {mode === 'edit' && (
        <div className="row">
          <div>E</div>
          <div
            style={{
              cursor: 'pointer',
              width: 20,
            }}
            onClick={() => {
              if (typeof onDelete !== 'undefined') {
                onDelete(item);
              }
            }}
          >
            <img src="/image/trash.png" alt="" />
          </div>
        </div>
      )}
    </ItemContainerStyleOverride>
  );
});

const ItemContainerStyleOverride = styled(ItemContainerStyle) <{ hover: boolean }>``;

export default SimpleLink;
