import { useRootContext } from 'libs/hooks/useRootContext';
import { useState } from 'react';
import { DealInquiryDto, DealInquiryThreadDto, LinkDto } from 'services/data-contracts';
import CustomerInquiryStore from 'stores/CustomerInquiryStore';

/**
 * @description 각 Block의 등록/수정/삭제를 수행함.
 * @returns
 */
const InquiryController = () => {
  const customerInquiryStore = new CustomerInquiryStore();
  const { myChannel } = useRootContext();
  const [deals, setDeals] = useState<LinkDto[]>();
  const [inquiries, setInquiries] = useState<DealInquiryDto[]>();
  const [threads, setThreads] = useState<DealInquiryThreadDto[]>();

  /**
   * @description 일반 사용자용 문의 쓰레드 조회
   * @param dealId
   * @param inquiryId
   * @returns
   */

  const createRoom = async (inquiryData: DealInquiryDto) => {
    const result = (await customerInquiryStore.createRoom(
      myChannel?.id as number,
      inquiryData,
    )) as unknown as DealInquiryDto;
    return result;
  };

  const inquire = async (inquiryId: number, message: string) => {
    const result = (await customerInquiryStore.inquiry(inquiryId, myChannel?.id as number, {
      message,
    })) as unknown as DealInquiryThreadDto;
    setThreads((prev) => [...(prev || []), result]);
    return result;
  };

  const getCustomerInquiryThreds = async (inquiryId: number) => {
    const result = (await customerInquiryStore.getCustomerInquiryThreads(
      inquiryId,
      myChannel?.id as number,
    )) as unknown as DealInquiryThreadDto[];
    setThreads(result);
  };

  const getCustomerInquiries = async () => {
    return (await customerInquiryStore.getCustomerInquiries(
      myChannel?.id as number,
    )) as unknown as DealInquiryDto[];
  };

  const answer = async (inquiryId: number, message: string) => {
    const result = (await customerInquiryStore.inquiry(inquiryId, myChannel?.id as number, {
      message,
    })) as unknown as DealInquiryThreadDto;
    setThreads((prev) => [...(prev || []), result]);
  };

  const closeInquiry = async (inquiryId: number) => {
    await customerInquiryStore.closeCustomerInquiry(myChannel?.id as number, inquiryId);
  };

  return {
    deals,
    inquiries,
    threads,
    createRoom,
    getCustomerInquiryThreds,
    getCustomerInquiries,
    answer,
    inquire,
    closeInquiry,
  };
};

export default InquiryController;
