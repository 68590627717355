import React, { useState, useEffect, useCallback } from 'react';
import ListItemHeader from './ListItemTemplates/ListItemHeader';

import { LinkDto } from 'services/data-contracts';
import { Blocks } from '.';
import { AdminBlockContainerStyle } from '../common/BlockContainerStyle';
import { CSSProperties } from 'styled-components';
import { useStore } from 'stores/StoreHelper';
import BlockController from 'controllers/BlockController';
import { parseJSONAll } from 'libs/helper/utils';
import { useStateContext } from 'libs/hooks/usePageState';
import useLocalParams from 'libs/hooks/useLocalParams';

const AdminBlockItem = ({
  item,
  editMode = true,
  boxStyle,
  onClick,
  onClickMore,
}: {
  item: LinkDto;
  editMode?: boolean;
  boxStyle?: CSSProperties;
  onClick?: () => void;
  onClickMore?: () => void;
}) => {
  const { uiStore } = useStore();
  const [isTransparent, setIsTransparent] = useState<boolean>();
  const { updateBlock, deleteBlock } = BlockController<LinkDto>();
  const { broadcast } = useStateContext();
  const { categoryId } = useLocalParams();

  const handleClickDelete = (item: LinkDto) => {
    uiStore.confirm.show({
      message: `${item?.title}항목을 삭제하시겠습니까?`,
      onConfirmed: async () => {
        await deleteBlock?.(item);
        broadcast({ id: 'ACTION', param: { id: 'LINK_UPDATE_DONE', categoryId: categoryId } });
      },
    });
  };

  const getTemplate = useCallback(() => {
    const linkType = item.linkType;
    const SelectedBlock = Blocks[linkType as keyof typeof Blocks];
    const metadata = JSON.parse(item?.metadata || '{}');

    const blockItems = item?.items?.map((item) => ({
      ...item,
      ...(parseJSONAll(item?.metadata) as any),
    }));

    const newItem: LinkDto = { ...item, ...metadata, items: blockItems };

    return (
      <div style={{ width: '100%', minHeight: 90 }}>
        <>
          {editMode === true && (
            <div style={{ padding: '0 10px' }}>
              <ListItemHeader
                icon={SelectedBlock.icon}
                initialActive={item.isActive}
                onActiveChange={(isActive) => {
                  const newModel = { ...item, isActive };
                  updateBlock(newModel as LinkDto);
                }}
                onDelete={() => {
                  handleClickDelete(item);
                }}
                onModify={() => {
                  onClick?.();
                }}
              />
            </div>
          )}
          <SelectedBlock.template
            editMode={editMode}
            item={newItem}
            onClick={() => {
              // if (onClick) onClick();
            }}
          />
        </>
      </div>
    );
  }, [item, onClick]);

  // useEffect(() => {
  //   if (boxStyle) {
  //     if (
  //       item.useTransparent === true ||
  //       item.linkType === 'Seperator' ||
  //       item.linkType === 'Spacer'
  //     ) {
  //       setBoxStyleWrapper({
  //         ...boxStyle,
  //         background: 'transparent',
  //         border: 'none',
  //         boxShadow: 'none',
  //       });
  //     } else {
  //       setBoxStyleWrapper(boxStyle);
  //     }
  //   }
  // }, [boxStyle]);

  useEffect(() => {
    if (editMode === true) {
      return;
    }
    if (item) {
      if (item.linkType === 'Seperator' || item.linkType === 'Spacer') {
        setIsTransparent(true);
        return;
      }

      setIsTransparent(item.useTransparent);
    }
  }, [item]);

  return (
    <>
      <AdminBlockContainerStyle
        useTransparent={isTransparent}
        style={{ minHeight: 100, margin: 0 }}
      >
        {typeof item !== 'undefined' && getTemplate()}
      </AdminBlockContainerStyle>
    </>
  );
};

export default AdminBlockItem;
