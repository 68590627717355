import * as React from 'react';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#23BA50',
  },
  '& .MuiRating-iconHover': {
    color: '#173300',
  },
  '& .MuiRating-iconEmpty': {
    color: '#23BA50',
  },
});

export default function HalfRating({ edit }: { edit: boolean }) {
  return (
    <>
      {edit && (
        <Stack spacing={1}>
          <StyledRating
            name="customized-color"
            defaultValue={2}
            getLabelText={(value: number) => `${value} Heart${value !== 1 ? 's' : ''}`}
            precision={0.5}
            icon={<FavoriteIcon fontSize="medium" />}
            emptyIcon={<FavoriteBorderIcon fontSize="medium" style={{ color: '#23BA50' }} />}
          />
        </Stack>
      )}

      {!edit && (
        <Stack spacing={1}>
          <StyledRating
            readOnly
            name="customized-color"
            defaultValue={2}
            getLabelText={(value: number) => `${value} Heart${value !== 1 ? 's' : ''}`}
            precision={0.5}
            icon={<FavoriteIcon fontSize="medium" />}
            emptyIcon={<FavoriteBorderIcon fontSize="medium" />}
          />
        </Stack>
      )}
    </>
  );
}
