import React, { useEffect, useState } from 'react';
import { ListBoxItem } from './styles';
import { IconButton } from '@mui/material';
import { LinkItemDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import AddIcon from '@mui/icons-material/Add';
import DealServiceItems from './DealServiceItems';
import DealCustomerServices from 'features/management/components/block/Deal/DealCustomerServices';
import { usePageModelContext } from 'features/management/context/usePageModelContext';
import { IDealBlock } from 'types/BlockExtendsTypes';

const ItemCustomerService = ({
  items,
  readonly = false,
  onUpdate,
}: {
  items?: LinkItemDto[];
  readonly?: boolean;
  onUpdate: (type?: string, value?: { items?: LinkItemDto[] }) => void;
}) => {
  const { uiStore } = useStore();
  const { initialModel, stateModel, setState } = usePageModelContext<IDealBlock>();

  const handleClickCustomerService = () => {
    uiStore.modal.show({
      title: '고객 서비스 설정',
      children: (
        <DealCustomerServices
          initialValue={stateModel?.items || initialModel?.items}
          actionType="Modal"
        />
      ),
      // 확인버튼 클릭 후, 업데이트
      onConfirmed: (result) => {
        setState('items', result);
        onUpdate('', { items: result });
      },
    });
  };

  useEffect(() => {
    if (items) {
      setState('items', items);
    }
  }, [items]);
  return (
    <ListBoxItem
      title="고객서비스 설정"
      action={
        <>
          {!readonly && (
            <IconButton style={{ background: '#efefef' }} onClick={handleClickCustomerService}>
              <AddIcon />
            </IconButton>
          )}
        </>
      }
    >
      <>
        {stateModel?.items && stateModel?.items?.length > 0 && (
          <div style={{ margin: '10px 0' }}>
            <DealServiceItems readonly={readonly} items={stateModel?.items} onUpdate={onUpdate} />
          </div>
        )}
      </>
    </ListBoxItem>
  );
};

export default ItemCustomerService;
