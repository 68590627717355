import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

interface DefaultProps {
  children?: any;
  type?: 'danger' | 'normal' | 'separator' | 'apply';
  onClick?: () => void;
}

function BottomSheetButton({ children, type, onClick }: DefaultProps) {
  return (
    <ButtonStyle onClick={onClick} type={type} >
      {children && children}
    </ButtonStyle>
  );
}

const ButtonStyle = styled.div<{ type?: 'danger' | 'normal' | 'separator' | 'apply'; }>`
 color:${(props) => props.type === 'danger' ? '#FE324B' : '#000'};
  ${(props) => {
    if (props.type === 'separator') {
      return Separator;
    }

    else {
      if (props.type === 'apply') {
        return Apply;
      }
      return DefaultButtonStyle;
    }
  }}
 
`;

const Separator = css`
  height:30px;
  border:0px;
`;


const DefaultButtonStyle = css`
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
  border: 1px solid #c5c5c5;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-right: 10px;
  justify-content: center;
  display: flex;
  font-weight: 700;
  width:100%;
 
  &:hover {
    transition: all 0.2s ease-in-out;
  }
`;

const Apply = css`
  background:#23BA50;
  color:#fff;
  ${DefaultButtonStyle}
`;

export default BottomSheetButton;
