import React, { useEffect, useState, useRef } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';

import { OhmossButton } from 'resources/controls/OhmossButton';
import { ISpacerBlock } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import TitleForm from 'components/items/forms/TitleForm';
import { toast } from 'react-toastify';
import MUISlider from 'components/commons/buttons/MUISlider';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { LinkTypes } from 'features/management/context/providers/ContextTypes';
import BlockController from 'controllers/BlockController';
import ActionBox from 'features/csTown/components/pc/ActionBox';

const Spacer = ({ blockModel, seq }: { blockModel?: ISpacerBlock; seq?: number }) => {
  const { uiStore } = useStore();
  const { initialModel, updateServiceModel, serviceModel, initialBinding, unmountModel } =
    useOhmossContext<LinkTypes<ISpacerBlock>>();

  const { updateBlock } = BlockController<ISpacerBlock>();

  const defaultProps: Partial<ISpacerBlock> = {
    title: '간격블록',
    linkType: 'Spacer',
    useTransparent: true,
    isActive: true,
    seq: seq,
  };

  useInitializeModel<Partial<ISpacerBlock>>(blockModel, defaultProps, initialBinding);

  const [height, setHeight] = useState<number>(5);
  const inputRef = useRef<any>();

  const initializeModel = () => {
    if (blockModel) {
      setHeight(blockModel?.height || 5);
    }
  };

  useEffect(() => {
    initializeModel();
  }, [blockModel]);

  useEffect(() => {
    return () => {
      unmountModel();
    };
  }, []);

  return (
    <div style={{}}>
      <ItemSectionStyle>
        <ItemSectionTitle>여백넓이</ItemSectionTitle>
        <ItemContent>
          <Row style={{ marginLeft: 10 }}>
            <MUISlider
              onChange={(e: any, value: number) => {
                setHeight(value);
              }}
              value={height}
            />
            <div style={{ marginLeft: 20, width: 115 }}>
              <TitleForm
                validationType="number"
                ref={inputRef}
                surfix="px"
                initialValue={height?.toString() || initialModel?.height?.toString()}
                onChangeValue={(value: string) => {
                  const heightNumber = parseInt(value, 10);
                  if (Number.isNaN(heightNumber)) {
                    toast('숫자형식으로 입력해주세요.', { type: 'warning' });
                    setHeight(5);

                    return;
                  }
                  if (heightNumber > 100) {
                    toast('높이는 100px까지만 허용됩니다.', { type: 'warning' });
                    setHeight(100);
                    return;
                  }
                  setHeight(heightNumber);

                  // else if (heightNumber < 6) {
                  //   toast('최소 높이는 5px입니다.', { type: 'warning' });
                  //   set('height', 5);
                  // }
                }}
              />
            </div>
          </Row>
        </ItemContent>
      </ItemSectionStyle>
      <Row style={{ gap: 10 }}>
        <OhmossButton
          type="box"
          style={{ color: '#000' }}
          onClick={() => {
            uiStore.modal.close();
          }}
        >
          취소
        </OhmossButton>
        <OhmossButton
          onClick={() => {
            updateServiceModel('height', height);
            updateBlock(serviceModel);
          }}
        >
          적용
        </OhmossButton>
      </Row>
      <ActionBox
        style={{ marginTop: 60, paddingBottom: 20 }}
        isValid={true}
        okText="저장"
        onOk={() => {
          updateServiceModel('height', height);
          updateBlock(serviceModel);
        }}
        onCancel={() => {
          uiStore.modal.close();
        }}
      />
    </div>
  );
};

export default Spacer;
