import React from 'react';
import { observer } from 'mobx-react';

import styled from 'styled-components';
import MobileMenus from 'features/adminFeatures/admin/components/MobileMenus';
import MobileGnbBottom from '../components/MobileGnbBottom';
import { MenuType } from 'types/CommonTypes';
import UniversalLayout from 'components/commons/layouts/UniversalLayout';

const MobileContainer = observer(
  ({
    bottomSize,
    children,
    onChangeMenu,
  }: {
    bottomSize: number;
    children: React.ReactNode;
    onChangeMenu: (menu: MenuType) => void;
  }) => {
    return (
      <UniversalLayout>
        <MobileMenus type="mobile" onMenuChange={onChangeMenu} />
        <ContainerStyle style={{ paddingBottom: bottomSize + 50 }}>
          <>{children}</>
        </ContainerStyle>
        <BottomStyle style={{ bottom: bottomSize - 10 }}>
          <MobileGnbBottom />
        </BottomStyle>
      </UniversalLayout>
    );
  },
);

const ContainerStyle = styled.div`
  margin-top: 20px;
  @media ${(props) => props.theme.media.mobile} {
  }
`;

const BottomStyle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  max-width: 550px;
`;
export default MobileContainer;
