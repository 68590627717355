import React from 'react';
import { CardLinkComponent } from 'components/items';

import { ChannelDto } from 'services/data-contracts';
import Gallery from './Gallery';
import Image from './Image';
import LinkButton from './LinkButton';
import Footer from './Footer';
import TelLink from './TelLink';
import MapLink from './MapLink';
import Popup from './PopUp';
import Video from './Video';
import Spacer from './Spacer';
import Separator from './Separator';
import Calendar from './Calendar';
import Proposal from './Proposal';
import SNS from './Social';
import TextLink from './TextLink';
import ProfileTemplate from '../profile/ProfileTemplate';
import LinkButtonTemplate from './ListItemTemplates/LinkButtonTemplate';
import CalendarTemplate from './ListItemTemplates/CalendarTemplate';
import GalleryTemplate from './ListItemTemplates/GalleryTemplate';
import VideoTemplate from './ListItemTemplates/VideoTemplate';
import SpacerTemplate from './ListItemTemplates/SpacerTemplate';
import MapLinkTemplate from './ListItemTemplates/MapLinkTemplate';
import TelLinkTemplate from './ListItemTemplates/TelLinkTemplate';
import SeparatorTemplate from './ListItemTemplates/SeparatorTemplate';
import SocialTemplate from './ListItemTemplates/SocialTemplate';
import PopUpTemplate from './ListItemTemplates/PopUpTemplate';
import ImageTemplate from './ListItemTemplates/ImageTemplate';
import ProposalTemplate from './ListItemTemplates/ProposalTemplate';
import FooterTemplate from './ListItemTemplates/FooterTemplate';
import CategoryTabTemplate from '../profile/CategoryTabView';
import CategoryTab from '../profile/CategoryTab';
import TextLinkTemplate from './ListItemTemplates/TextLinkTemplate';
import Deal from './Deal/Deal';
import DealTemplate from './ListItemTemplates/DealTemplate';
import MyShop from './MyShop';
import MyShopTemplate from './ListItemTemplates/MyShopTemplate';

type BlockType = {
  component: React.ComponentType<any>;
  name: string;
  title: string;
  icon: string;
  template?: React.ComponentType<any>;
  temp?: boolean;
  channelType?: ChannelDto['channelType'];
};

const Blocks = {
  SimpleLink: {
    // Editor
    component: LinkButton,
    // Viewer
    template: LinkButtonTemplate,
    name: 'Link',
    title: '링크',
    icon: '/images/management/blocks/link.svg',
  },
  Deal: {
    // Editor
    component: Deal,
    // Viewer
    template: DealTemplate,
    name: 'Deal',
    title: '딜블럭',
    icon: '/images/management/blocks/deal.svg',
    channelType: 'SELLER',
  },
  Shop: {
    // Editor
    component: MyShop,
    // Viewer
    template: MyShopTemplate,
    name: 'MyShop',
    title: '마이샵',
    icon: '/images/management/blocks/my-shop.svg',
    channelType: 'SELLER',
  },
  Image: {
    component: Image,
    name: 'Image',
    title: '이미지',
    icon: '/images/management/blocks/image.svg',
    template: ImageTemplate,
  },
  Gallery: {
    component: Gallery,
    name: 'Gallery',
    title: '갤러리',
    icon: '/images/management/blocks/gallery.svg',
    template: GalleryTemplate,
  },
  Video: {
    component: Video,
    name: 'Video',
    title: '동영상',
    icon: '/images/management/blocks/video.svg',
    template: VideoTemplate,
  },
  TextLink: {
    component: TextLink,
    name: 'Text',
    title: '문자',
    icon: '/images/management/blocks/text.svg',
    template: TextLinkTemplate,
  },
  Popup: {
    component: Popup,
    name: 'Popup',
    title: '팝업',
    icon: '/images/management/blocks/popup.svg',
    template: PopUpTemplate,
  },
  Calendar: {
    component: Calendar,
    name: 'Calendar',
    title: '캘린더',
    icon: '/images/management/blocks/calendar.svg',
    template: CalendarTemplate,
  },
  Suggestion: {
    component: Proposal,
    name: 'Proposal',
    title: '문의/제안',
    icon: '/images/management/blocks/suggestion.svg',
    template: ProposalTemplate,
  },

  MapLink: {
    component: MapLink,
    name: 'Map',
    title: '지도',
    icon: '/images/management/blocks/map.svg',
    template: MapLinkTemplate,
  },
  TelLink: {
    component: TelLink,
    template: TelLinkTemplate,
    name: 'Contact',
    title: '연락처',
    icon: '/images/management/blocks/contact.svg',
  },
  SNS: {
    component: SNS,
    name: 'Social Network',
    title: 'SNS',
    icon: '/images/management/blocks/social.svg',
    template: SocialTemplate,
  },
  Seperator: {
    component: Separator,
    name: 'Seperator',
    title: '구분선',
    icon: '/images/management/blocks/separator.svg',
    template: SeparatorTemplate,
    // temp: true,
  },
  Spacer: {
    component: Spacer,
    name: 'Spacer',
    title: '여백',
    icon: '/images/management/blocks/spacer.svg',
    template: SpacerTemplate,
    // temp: true,
  },

  Tails: {
    component: Footer,
    name: 'Footer',
    title: '꼬리단',
    icon: '/images/management/blocks/tail.svg',
    template: ProfileTemplate,
    temp: true,
  },
  Footer: {
    component: Footer,
    name: 'Footer',
    title: '푸터',
    icon: '/images/management/blocks/tail.svg',
    template: FooterTemplate,
    temp: true,
  },
  Page: {
    component: Popup,
    name: 'Page',
    title: '페이지',
    icon: '/images/management/blocks/page.svg',
    template: PopUpTemplate,
    temp: true,
  },

  CardLink: {
    component: LinkButton,
    name: 'Link',
    title: '카드링크',
    icon: '/images/management/blocks/link.svg',
    template: CardLinkComponent,
    temp: true,
  },
  Youtube: {
    component: Video,
    title: '비디오',
    name: 'Video',
    icon: '/images/management/blocks/video.svg',
    template: VideoTemplate,
    temp: true,
  },
  CategoryTab: {
    component: CategoryTab,
    title: '카테고리',
    name: 'Categories',
    icon: '/images/management/blocks/video.svg',
    template: CategoryTabTemplate,
    temp: true,
  },
};

export { Blocks, BlockType };
