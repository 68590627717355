import React, { CSSProperties, ReactChild } from 'react';
import styled from 'styled-components';

const UniversalLayout = ({
  style,
  children,
  className,
  useBottom,
  onClick,
}: {
  style?: CSSProperties;
  className?: string;
  children?: JSX.Element | JSX.Element[];
  useBottom?: boolean;
  onClick?: () => void;
}) => {
  return (
    <UniversalStyle
      useBottom={useBottom}
      className="universal-layout "
      style={{ ...style }}
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick();
        }
      }}
    >
      {children}
    </UniversalStyle>
  );
};

const UniversalStyle = styled.div<{ useBottom?: boolean }>`
  width: 100%;
  flex-direction: column;
  /* height: 100%; */
  position: relative;
  /* margin: auto; */
  display: flex;
  /* min-height: 100%; */
  align-items: flex-start;
  padding-bottom: ${(props) => (props.useBottom ? '100px' : '0')};
  @media ${(props) => props.theme.media.mobile} {
    /* background: var(--background); */
  }
`;

export default UniversalLayout;
