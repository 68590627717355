import React, { CSSProperties, ReactNode } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styled from 'styled-components';

const InfoBox = ({
  useIcon = true,
  style,
  children,
}: {
  useIcon?: boolean;
  style?: CSSProperties;
  children: ReactNode;
}) => {
  return (
    <ContentStyle style={style}>
      {useIcon && <InfoOutlinedIcon />}
      <div style={{ marginLeft: '8px' }}>{children}</div>
    </ContentStyle>
  );
};

const ContentStyle = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: var(--border-radius);
  border: 2px solid #50009c;
  word-break: break-all;
  background: #fff;
`;

export default InfoBox;
