import ProfileStore from './ProfileStore';
import UserHomeStore from './UserHomeStore';
import AdminStore from './AdminStore';

const UIRootStore = {
  userHomeStore: new UserHomeStore(),
  profileStore: new ProfileStore(),
  adminStore: new AdminStore(),
};

export default UIRootStore;
