import styled from 'styled-components';

export const ManagemernChildLayout = styled.div<{ useBottom?: boolean }>`
  flex-grow: 1;
  @media ${(props) => props.theme.media.mobile} {
    height: 100vh;
  }
  overflow: auto;
  > div {
    padding-bottom: ${(props) => (props.useBottom ? '100px' : '0')};
  }
`;
