import React from 'react';
import { ItemContainerStyle } from 'features/adminFeatures/template/components/ItemContainerStyle';
import { LinkDto } from 'services/data-contracts';
import styled from 'styled-components';

type defaultProps = {
  item: LinkDto;
  mode?: 'create' | 'edit';
  onDelete?: (item: LinkDto) => void;
};

const TextLink = ({ item, mode, onDelete }: defaultProps) => {
  const IMAGE_WIDTH = 50;

  return (
    <ItemContainerStyle style={{ cursor: 'default' }}>
      <ItemStyle>
        <LinkContainerTextStyle>
          <LinkTextStyle>{item?.content}</LinkTextStyle>
        </LinkContainerTextStyle>
      </ItemStyle>
    </ItemContainerStyle>
  );
};

const ItemStyle = styled.div`
  display: table;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const LinkContainerTextStyle = styled.div`
  border-radius: 10px;
  text-align: center;
  word-wrap: break-word;
  min-height: 50px;
  padding: 5px;
  justify-content: space-between;
  display: flex;
  position: relative;
  box-shadow: 0px 0px 7px -1px #c8c8c8;
`;

const LinkTextStyle = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 16px;
  margin: auto;
  white-space: pre-line;
`;

export default TextLink;
