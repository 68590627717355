import React from 'react';
import { Row } from './layouts';
import TextLabel from './labels/TextLabel';


const EmptyArticle = ({ title }: { title: string; }) => {
  return (<Row style={{ height: 'calc(100vh - 165px)', alignItems: 'center' }}>
    <TextLabel type='subject'><div>{title}게시물이 없어요.</div></TextLabel>
  </Row>);
};


export default EmptyArticle;
