import React, { useEffect } from 'react';
import { getFeedThmbnailUrl } from 'components/items/commonLogic';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { Stack } from '@mui/system';
import { observer } from 'mobx-react';
import { ItemSectionStyle, ItemSectionTitle } from 'resources/controls/ItemSectionStyle';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { ProfileTypes } from 'features/management/context/providers/ContextTypes';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ThumbnailUploader from 'components/items/ThumbnailUploader';
import styled from 'styled-components';
import SaveButton from 'features/adminFeatures/admin/components/SaveButton';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import { IChannelProfile } from 'types/BlockExtendsTypes';
import { useRootContext } from 'libs/hooks/useRootContext';
import { OhmossButton } from 'resources/controls/OhmossButton';

const BackgroundImageComponent = observer(({ onSave }: { onSave: () => void }) => {
  const { freeImageStore } = useStore();
  const { myChannel } = useRootContext();

  const { t } = useTranslation();

  const { initialBinding, updateServiceModelMultiple } =
    useOhmossContext<ProfileTypes<IChannelProfile>>();

  const [localModel, setLocalModel] = useInitializeModel<Partial<IChannelProfile>>(
    myChannel?.profile ?? {},
    undefined,
    initialBinding,
  );

  useEffect(() => {
    if (myChannel) {
      freeImageStore.getItems();
    }
  }, [myChannel]);

  return (
    <Stack>
      <div>
        <ItemSectionStyle>
          <ImageUploadButtonStyle backgroundImage={localModel?.backgroundImage?.publicUrl}>
            {typeof myChannel?.profile?.backgroundImage?.publicUrl === 'undefined' && (
              <CameraAltIcon sx={{ width: 100, height: 100, opacity: 0.4 }} />
            )}
          </ImageUploadButtonStyle>
        </ItemSectionStyle>
        <ThumbnailUploader
          label={t('Select image')}
          usePreview={false}
          useCrop={false}
          style={{ marginTop: 20, justifyContent: 'center' }}
          url={localModel?.backgroundImage?.publicUrl}
          onImageChange={(image: any) => {
            updateServiceModelMultiple({ backgroundImage: image, backgroundType: 'image' });
            setLocalModel('backgroundImage', image);
          }}
        />
        <ItemSectionStyle style={{ marginTop: 30, padding: 20 }}>
          <ItemSectionTitle style={{ marginBottom: 20 }}>{t('A182')}</ItemSectionTitle>
          <BackgroundListStyle>
            {freeImageStore.backgroundImages?.map((background) => (
              <div
                style={{
                  overflow: 'hidden',
                  width: 'auto',
                  minWidth: 150,
                  height: 150,
                  flex: 1,
                  flexWrap: 'wrap',
                }}
              >
                <BackgroundImageStyle
                  style={{ width: '100%', minWidth: 150 }}
                  src={getFeedThmbnailUrl(background.image?.publicUrl, '900')}
                  onClick={() => {
                    updateServiceModelMultiple({
                      backgroundImage: background.image,
                      backgroundType: 'image',
                    });

                    setLocalModel('backgroundImage', background.image);
                  }}
                />
              </div>
            ))}
          </BackgroundListStyle>
        </ItemSectionStyle>
      </div>
      <div style={{ padding: 20 }}>
        <OhmossButton type="primaryPc" onClick={onSave}>
          저장하기
        </OhmossButton>
      </div>
    </Stack>
  );
});

const ImageUploadButtonStyle = styled.div<{ backgroundImage?: string }>`
  width: 100%;
  height: 250px;
  border: 1px solid #eeeeee;
  /* border-radius: 20px; */
  background: #fbfbfb;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: 50% 50%;
  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
    height: 180px;
  }
`;

const BackgroundListStyle = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  overflow: hidden;
  border-radius: 15px;
  margin: 10px 0 0 0;
  > img {
    margin: 10px 0px 10px 0px;
    border-radius: 20px;
    width: 150px;
    height: 150px;
  }
  @media ${(props) => props.theme.media.mobile} {
    > img {
      width: 30%;
      height: 100%;
      margin: 5px 3px;
      object-fit: cover;
    }
    margin-bottom: 20px;
  }
`;

const BackgroundImageStyle = styled.img.attrs({
  alt: '',
})`
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default BackgroundImageComponent;
