import { Row } from 'components/commons/layouts';
import React from 'react';
import styled from 'styled-components';

const AppInfoFooter = () => {
  const handleClickAppStore = (type: 'apple' | 'google') => {
    if (type === 'apple') {
      window.open('https://apps.apple.com/kr/app/oh-moss/id1665052510', '_blank');
    } else {
      window.open(
        'https://play.google.com/store/apps/details?id=com.nbhd.ohmoss&hl=en-KR',
        '_blank',
      );
    }
  };
  return (
    <div style={{ width: 540 }}>
      <OhmossImgStyle>
        <img src="/images/running_ohmoss.svg" style={{ position: 'relative' }} alt="" />
        <Row style={{ marginTop: 26, gap: 10 }}>
          <div
            onClick={() => {
              handleClickAppStore('apple');
            }}
          >
            <img src="/images/applestore.svg" />
          </div>
          <div
            onClick={() => {
              handleClickAppStore('google');
            }}
          >
            <img src="/images/googleplay.svg" />
          </div>
        </Row>
        <InfoStyle>
          © 2023 NBHD, Inc. All right reserved. <br />
          서울시 성동구 아차산로 17길 49, 1406호
          <br />
          (주)엔비에이치디 사업자등록번호 :850-87-02137
          <br /> 대표 : 홍성표
          <br />
          전화번호 : 02-6949-0264
          <br />
          통신사업자등록번호 : 제 2021-서울성동-02720호
        </InfoStyle>
      </OhmossImgStyle>
    </div>
  );
};

const OhmossImgStyle = styled.div`
  position: fixed;
  bottom: 100px;
  width: 500px;
  left: 10%;
  @media screen and (max-width: 1160px) {
    display: none;
  }
`;

const InfoStyle = styled.div`
  margin-top: 12px;
  color: var(--gray-white, #777);
  text-align: center;
  font-family: Noto Sans KR;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-wrap: break-word;
  width: 350px;
`;
export default AppInfoFooter;
