import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Checkbox } from '@mui/material';
import { ChannelDto, MembershipPlanDto } from 'services/data-contracts';
import { useSearchParams } from 'react-router-dom';
import MembershipController from 'controllers/MembershipController';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { toast } from 'react-toastify';
import MembershipTerms from 'features/myPage/containers/membership/MembershipTerms';
import { useStore } from 'stores/StoreHelper';
import GiftSearchBox from 'features/myPage/containers/membership/GiftSearchBox';
import { useRootContext } from 'libs/hooks/useRootContext';
import MembershipDuration from './MembershipDuration';
import InvoiceContainer from '../../containers/InvoiceContainer';
import Explains from './explain';
import { useMediaContext } from 'providers/useMediaContext';
import { validationHelper } from 'controls/validationHelper';

interface Props {
  plans?: MembershipPlanDto[];
  channelType?: ChannelDto['channelType'];
  isGift?: boolean;
}

// 멤버쉽 구독(isGift: false)과 멤버쉽 선물(isGift: true) 컴포넌트에서 사용 : 구분값 - isGift
const MembershipSelector: React.FC<Props> = ({ plans, channelType, isGift }) => {
  const [searchParam] = useSearchParams();
  const tabName = searchParam.get('tab');
  const { myChannel } = useRootContext();
  const { uiStore } = useStore();
  const { billing, payGift } = MembershipController();
  const ScreenTypes = useMediaContext();

  const [membership, setMembership] = useState<MembershipPlanDto>();
  const [membershipDuration, setMembershipDuration] = useState<number>();
  const [checked, setChecked] = useState<boolean>(false);
  const [receiverChannel, setReceiverChannel] = useState<ChannelDto | undefined>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [inputChannelName, setIputChannelName] = useState<string>();

  const getMembershipId = async () => {
    // let result;
    // if (!isGift) {
    //   result = (await getMembershipPlans(
    //     channelType as 'SELLER' | 'BRAND' | 'MEMBER',
    //   )) as unknown as MembershipPlanDto[];
    // } else {
    //   result = (await getMembershipPlans('SELLER')) as unknown as MembershipPlanDto[];
    // }
    // setPlans(result);
  };

  const handleSetPaymentSettings = (duration: string) => {
    switch (duration) {
      case 'annual':
        setMembershipDuration(12);
        break;
      case 'bi-annual':
        setMembershipDuration(6);
        break;
      case 'monthly':
        setMembershipDuration(1);
        break;
      default:
        setMembershipDuration(6);
        break;
    }
  };

  const handleClickPay = async () => {
    if (plans && checked) {
      const selectedPlan = plans.find((plan) => plan.id === membership?.id);
      // await pay(selectedPlan?.id as number, membershipDuration as number);
      const duration = membershipDuration;
      await billing(selectedPlan?.id as number, duration as number);
    } else {
      toast.warn('약관에 동의해주세요.');
    }
  };

  const handlePurchaseGift = async () => {
    if (plans && checked && receiverChannel) {
      const selectedPlan = plans.find((plan) => plan.id === membership?.id);
      await payGift(
        selectedPlan?.id as number,
        membershipDuration as number,
        receiverChannel?.channelName as string,
      );
    } else if (!receiverChannel) {
      toast.warn('선물할 채널을 선택해 주세요.');
    } else if (!checked) {
      toast.warn('약관에 동의해주세요.');
    }
  };

  const handleShowTerms = () => {
    uiStore.modal.show({
      style: { height: '100%', width: '100%' },
      children: <MembershipTerms />,
    });
  };

  const getExplanation = () => {
    const key = `${myChannel?.channelType}${membership?.membershipType}`;

    if (!(key in Explains)) {
      return <></>;
    }

    if(!isGift){
      const explain = (Explains as any)[key];
      const Component = explain.component;
      return <Component plan={membership} />;
    } else {
      const explain = (Explains as any)['BRANDGIFT'];
      const Component = explain.component;
      return (
        <Component plan={membership} />
      );
    }
  };

  const onChangeReceiverChannel = (value: any) => {
    setIputChannelName(value);
  }

  useEffect(() => {
    // if (tabName === '멤버십 구독') {
    //   setMembershipType('FREE');
    // } else {
    //   setMembershipType('PRO');
    // }
    if (plans) {
      setMembership(plans[0]);
    }
  }, [tabName]);

  useEffect(() => {
    if (typeof channelType !== 'undefined') {
      getMembershipId();
    }
  }, [channelType]);

  useEffect(() => {
    setMembershipDuration(1);

    return () => {
      setMembershipDuration(1);
    };
  }, []);

  useEffect(() =>{
    if (plans) {
      const myMembership = plans.findIndex((plan) => {
        return plan.membershipType === myChannel?.membershipType
      })
      setMembership(plans[myMembership]);
    }
  },[plans])
  
  useEffect(() =>{
    // 유효검사 -> input value 와 selected value 비교
    if (checked && validationHelper.required(receiverChannel?.channelName) && membershipDuration) {
      if(receiverChannel?.channelName === inputChannelName) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    } else {
      setIsValid(false);
    }
  },[checked, receiverChannel?.channelName, membershipDuration, inputChannelName])

  return (
    <div style={{ padding: '0 20px 20px 20px' }}>
      {/* CASE 1: 브랜드 회원인 경우에만 멤버쉽 선물하기 */}
      {isGift && myChannel?.channelType === 'BRAND' ? (
      <>
        <div style={{ marginBottom: 30 }}>
          <GiftSearchBox onSelect={setReceiverChannel} onChangeValue={onChangeReceiverChannel}/>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          <MembershipDuration onChange={handleSetPaymentSettings} />
        </div>
        <InvoiceContainer
            membership={membership?.membershipType}
            time={membershipDuration}
            isGift={true}
          />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 10,
          }}
        >
          <Terms>환불조건등 서비스 이용 약관 안내 동의</Terms>
          <div
            onClick={handleShowTerms}
            style={{
              fontSize: 12,
              textDecoration: 'underline',
              color: 'rgba(0, 0, 0, 0.35)',
              cursor: 'pointer',
              marginLeft: 12,
            }}
          >
            약관보기
          </div>
          <Checkbox
            style={{ padding: 0, marginLeft: 4 }}
            size="small"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
        </div>
        <div style={{ padding: ScreenTypes.isLarge ? '20px 0' : '20px 0px' }}>
          {tabName === '4' && (<OhmossButton disabled={!isValid} onClick={() => handlePurchaseGift()}>선물 결제</OhmossButton>)}
        </div>
      </>
      ) : ( 
      <>
      {/* CASE 2: 멤버쉽 구독 */}
      {/* CASE 2-1: 유료 멤버십의 경우 몇 개월 결제인지 탭 선택 */}
      <SelectorTab>
        {plans &&
          plans?.map((plan, index) => (
            <SelectorTabItem
              selected={membership?.membershipType === plan.membershipType}
              onClick={() => {
                setMembership(plan);
                // setMembershipDuration(1);
              }}
            >
              {myChannel?.membershipType === plan?.membershipType && <MyStyle>내 구독</MyStyle>}
              {plan.membershipType}
            </SelectorTabItem>
          ))}
      </SelectorTab>
      {getExplanation()}
      {membership?.membershipType !== 'FREE' &&
        myChannel?.membershipType !== membership?.membershipType && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <MembershipDuration onChange={handleSetPaymentSettings} />
            </div>
          </>
        )}

      {membership?.membershipType !== 'FREE' &&
        myChannel?.membershipType !== membership?.membershipType && (
          <InvoiceContainer
            membership={membership?.membershipType}
            time={membershipDuration}
            isGift
          />
        )}
      {membership?.membershipType !== 'FREE' &&
        myChannel?.membershipType !== membership?.membershipType && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: ScreenTypes.isLarge ? 'left' : 'center',
              padding: ScreenTypes.isLarge ? '10px 0' : '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 10,
              }}
            >
              <Terms>환불조건등 서비스 이용 약관 안내 동의</Terms>
              <div
                onClick={handleShowTerms}
                style={{
                  fontSize: 12,
                  textDecoration: 'underline',
                  color: 'rgba(0, 0, 0, 0.35)',
                  cursor: 'pointer',
                  marginLeft: 12,
                }}
              >
                약관보기
              </div>
              <Checkbox
                style={{ padding: 0, marginLeft: 4 }}
                size="small"
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
            </div>
            <Terms>
              CUSTOM 서비스를 원하시는 에이전시나 기업은
              <br />
              <span style={{ fontSize: 13, color: '#23BA50', textDecoration: 'underline' }}>
                오모스 문의하기
              </span>
              로 연락주시기 바랍니다.
            </Terms>
          </div>
        )}
      <div style={{ padding: ScreenTypes.isLarge ? '20px 0' : '20px 0px' }}>
        {myChannel?.membershipType !== membership?.membershipType && (
          <>
            {membership?.membershipType !== 'FREE' && tabName !== '4' && (
              <OhmossButton
                role={ScreenTypes.isLarge ? 'pc' : 'mobile'}
                type="primaryPc"
                onClick={() => handleClickPay()}
              >
                플랜 결제하기
              </OhmossButton>
            )}
          </>
        )}
      </div>
      </> )
      }
    </div>
  );
};

const SelectorTab = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const MyStyle = styled.div<{ selected?: boolean }>`
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 8px;

  padding: 2px 5px;
  font-size: 10px;
  color: #fff;
  background: ${(props) => (props.selected ? '#173300' : 'rebeccapurple')};
`;

const SelectorTabItem = styled.div<{ selected?: boolean; isGift?: boolean; isEdge?: boolean }>`
  display: flex;
  cursor: pointer;
  /* width: ${(props) => (!props.isGift ? '33.3%' : '50%')}; */
  flex: 1;
  height: 4.0625rem;
  padding: 10px;
  justify-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${(props) => (props.selected ? '#173300' : 'rgba(203,203,203,0.5)')};
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.01125rem;
  text-align: center;
  border-radius: ${(props) =>
    props.isEdge ? '0rem 1.25rem 0rem 0rem' : props.isGift ? '1.25rem 0rem 0rem 0rem' : 'none'};
  border: ${(props) => (props.selected ? '0.5px solid #173300' : '0.5px solid #CBCBCB')};
  color: ${(props) => (props.selected ? '#9DE4BC' : 'black')};
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.8rem 5rem 0.8rem 5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #9de4bc;
  font-weight: 500;
  border: 0.5px solid #cbcbcb;
  border-top: none;
  border-bottom: none;
`;

const Divider = styled.div`
  width: 100%;
  height: 0.03125rem;
  background: rgba(0, 0, 0, 0.35);
`;

const Bonus = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.00938rem;
`;

const Terms = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-size: 13px;
`;

export default MembershipSelector;
