import React from 'react';
import { IconButton } from '@mui/material';
import styled from 'styled-components';
import useNavigation from 'hooks/useNavigation';

import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { useStateContext } from 'libs/hooks/usePageState';
import { MenuBoxItemStyle, MenuBoxStyle } from '../commonComponents/styles';

const CsTownMemberLnb = ({ currentMenu }: { currentMenu?: string }) => {
  const { navigate } = useNavigation();

  return (
    <>
      <MenuBoxStyle>
        <MenuBoxItemStyle
          selected={currentMenu === 'inquiries'}
          onClick={() => {
            navigate('/cstown/inquiries');
          }}
        >
          <IconButton>
            <SmsOutlinedIcon />
          </IconButton>
          내 문의목록
        </MenuBoxItemStyle>
      </MenuBoxStyle>
    </>
  );
};

export default CsTownMemberLnb;
