import { ReactComponent as Youtube } from './youtube.svg';
import { ReactComponent as Instagram } from './instagram.svg';
import { ReactComponent as Pinterest } from './pinterest.svg';
import { ReactComponent as Tiktok } from './tiktok.svg';
import { ReactComponent as Twitter } from './twitter.svg';
import { ReactComponent as Facebook } from './facebook.svg';

import { ReactComponent as GrayYoutube } from './grayscale/YouTube - Negative.svg';
import { ReactComponent as GrayApple } from './grayscale/Apple - Negative.svg';
import { ReactComponent as GrayClubhouse } from './grayscale/Clubhouse - Negative.svg';
import { ReactComponent as GrayDiscord } from './grayscale/Discord - Negative.svg';
import { ReactComponent as GrayDribbble } from './grayscale/Dribbble - Negative.svg';
import { ReactComponent as GrayFacebook } from './grayscale/Facebook - Negative.svg';
import { ReactComponent as GrayFigma } from './grayscale/Figma - Negative.svg';
import { ReactComponent as GrayGithub } from './grayscale/Github - Negative.svg';
import { ReactComponent as GrayGoogle } from './grayscale/Google - Negative.svg';
import { ReactComponent as GrayInstagram } from './grayscale/Instagram - Negative.svg';
import { ReactComponent as GrayLinkedIn } from './grayscale/LinkedIn - Negative.svg';
import { ReactComponent as GrayMedium } from './grayscale/Medium - Negative.svg';
import { ReactComponent as GrayPinterest } from './grayscale/Pinterest - Negative.svg';
import { ReactComponent as GrayReddit } from './grayscale/Reddit - Negative.svg';
import { ReactComponent as GraySignal } from './grayscale/Signal - Negative.svg';
import { ReactComponent as GraySkype } from './grayscale/Skype - Negative.svg';
import { ReactComponent as GraySnapchat } from './grayscale/Snapchat - Negative.svg';
import { ReactComponent as GraySpotify } from './grayscale/Spotify - Negative.svg';
import { ReactComponent as GrayTelegram } from './grayscale/Telegram - Negative.svg';
import { ReactComponent as GrayTiktok } from './grayscale/TikTok - Negative.svg';
import { ReactComponent as GrayTumblr } from './grayscale/Tumblr - Negative.svg';
import { ReactComponent as GrayTwitch } from './grayscale/Twitch - Negative.svg';
import { ReactComponent as GrayTwitter } from './grayscale/Twitter - Negative.svg';
import { ReactComponent as GrayVK } from './grayscale/VK - Negative.svg';

import { ReactComponent as OriginYoutube } from './original/YouTube - Original.svg';
import { ReactComponent as OriginApple } from './original/Apple - Original.svg';
import { ReactComponent as OriginClubhouse } from './original/Clubhouse - Original.svg';
import { ReactComponent as OriginDiscord } from './original/Discord - Original.svg';
import { ReactComponent as OriginDribbble } from './original/Dribbble - Original.svg';
import { ReactComponent as OriginFacebook } from './original/Facebook - Original.svg';
import { ReactComponent as OriginFigma } from './original/Figma - Original.svg';
import { ReactComponent as OriginGithub } from './original/Github - Original.svg';
import { ReactComponent as OriginGoogle } from './original/Google - Original.svg';
import { ReactComponent as OriginInstagram } from './original/Instagram - Original.svg';
import { ReactComponent as OriginLinkedIn } from './original/LinkedIn - Original.svg';
import { ReactComponent as OriginMedium } from './original/Medium - Original.svg';
import { ReactComponent as OriginPinterest } from './original/Pinterest - Original.svg';
import { ReactComponent as OriginReddit } from './original/Reddit - Original.svg';
import { ReactComponent as OriginSignal } from './original/Signal - Original.svg';
import { ReactComponent as OriginSkype } from './original/Skype - Original.svg';
import { ReactComponent as OriginSnapchat } from './original/Snapchat - Original.svg';
import { ReactComponent as OriginSpotify } from './original/Spotify - Original.svg';
import { ReactComponent as OriginTelegram } from './original/Telegram - Original.svg';
import { ReactComponent as OriginTiktok } from './original/TikTok - Original.svg';
import { ReactComponent as OriginTumblr } from './original/Tumblr - Original.svg';
import { ReactComponent as OriginTwitch } from './original/Twitch - Original.svg';
import { ReactComponent as OriginTwitter } from './original/Twitter - Original.svg';
import { ReactComponent as OriginVK } from './original/VK - Original.svg';

interface ISNS {
  name: string;
  component: any;
}

const SNS: Array<ISNS> = [
  { name: 'youtube', component: Youtube },
  { name: 'instagram', component: Instagram },
  { name: 'pinterest', component: Pinterest },
  { name: 'tiktok', component: Tiktok },
  { name: 'twitter', component: Twitter },
  { name: 'facebook', component: Facebook },
];

const GraySNS: Array<ISNS> = [
  { name: 'youtube', component: GrayYoutube },
  { name: 'apple', component: GrayApple },
  { name: 'clubhouse', component: GrayClubhouse },
  { name: 'discord', component: GrayDiscord },
  { name: 'dribbble', component: GrayDribbble },
  { name: 'facebook', component: GrayFacebook },
  { name: 'figma', component: GrayFigma },
  { name: 'github', component: GrayGithub },
  { name: 'google', component: GrayGoogle },
  { name: 'instagram', component: GrayInstagram },
  { name: 'linkedin', component: GrayLinkedIn },
  { name: 'medium', component: GrayMedium },
  { name: 'pinterest', component: GrayPinterest },
  { name: 'reddit', component: GrayReddit },
  { name: 'signal', component: GraySignal },
  { name: 'skype', component: GraySkype },
  { name: 'snapchat', component: GraySnapchat },
  { name: 'spotify', component: GraySpotify },
  { name: 'telegram', component: GrayTelegram },
  { name: 'tiktok', component: GrayTiktok },
  { name: 'tumblr', component: GrayTumblr },
  { name: 'twitch', component: GrayTwitch },
  { name: 'twitter', component: GrayTwitter },
  { name: 'vk', component: GrayVK },
];

const OriginSNS: Array<ISNS> = [
  { name: 'youtube', component: OriginYoutube },
  { name: 'apple', component: OriginApple },
  { name: 'clubhouse', component: OriginClubhouse },
  { name: 'discord', component: OriginDiscord },
  { name: 'dribbble', component: OriginDribbble },
  { name: 'facebook', component: OriginFacebook },
  { name: 'figma', component: OriginFigma },
  { name: 'github', component: OriginGithub },
  { name: 'google', component: OriginGoogle },
  { name: 'instagram', component: OriginInstagram },
  { name: 'linkedin', component: OriginLinkedIn },
  { name: 'medium', component: OriginMedium },
  { name: 'pinterest', component: OriginPinterest },
  { name: 'reddit', component: OriginReddit },
  { name: 'signal', component: OriginSignal },
  { name: 'skype', component: OriginSkype },
  { name: 'snapchat', component: OriginSnapchat },
  { name: 'spotify', component: OriginSpotify },
  { name: 'telegram', component: OriginTelegram },
  { name: 'tiktok', component: OriginTiktok },
  { name: 'tumblr', component: OriginTumblr },
  { name: 'twitch', component: OriginTwitch },
  { name: 'twitter', component: OriginTwitter },
  { name: 'vk', component: OriginVK },
];

export { ISNS, SNS, GraySNS, OriginSNS };
