import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useNavigation from 'hooks/useNavigation';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LayersIcon from '@mui/icons-material/Layers';
import PhotoCameraFrontOutlinedIcon from '@mui/icons-material/PhotoCameraFrontOutlined';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';

const AdminGNB = () => {
  type MenuType = 'profile' | 'categories' | 'design' | 'background' | 'shop' | 'etc';
  const { navigate } = useNavigation();

  const [menu, setMenu] = useState<MenuType>('profile');

  const handleChangeMenu = (menu: MenuType) => {
    setMenu(menu);
    navigate(`/management/${menu}`);
  };

  useEffect(() => {
    const path = window.location.pathname.split('/');
    setMenu((path[2] as MenuType) || 'categories');
  }, [window.location.pathname]);

  return (
    // <StickyHeader top={40}>
    <GNBStyle>
      <GNBItemStyle
        selected={menu === 'categories'}
        onClick={() => {
          handleChangeMenu('categories');
        }}
      >
        <div>
          <LayersIcon />
        </div>
        <TextXtyle>페이지</TextXtyle>
        <BorderStyle selected={menu === 'categories'} />
      </GNBItemStyle>
      <GNBItemStyle
        selected={menu === 'profile'}
        onClick={() => {
          handleChangeMenu('profile');
        }}
      >
        <div>
          <PhotoCameraFrontOutlinedIcon />
        </div>
        <TextXtyle>프로필</TextXtyle>
        <BorderStyle selected={menu === 'profile'} />
      </GNBItemStyle>
      <GNBItemStyle
        selected={menu === 'shop'}
        onClick={() => {
          handleChangeMenu('shop');
        }}
      >
        <div>
          <StorefrontIcon />
        </div>
        <TextXtyle>마이샵</TextXtyle>
        <BorderStyle selected={menu === 'shop'} />
      </GNBItemStyle>
      <GNBItemStyle
        selected={menu === 'design'}
        onClick={() => {
          handleChangeMenu('design');
        }}
      >
        <div>
          <BrushOutlinedIcon />
        </div>
        <TextXtyle>디자인</TextXtyle>
        <BorderStyle selected={menu === 'design'} />
      </GNBItemStyle>
      <GNBItemStyle
        selected={menu === 'background'}
        onClick={() => {
          handleChangeMenu('background');
        }}
      >
        <div>
          <BrokenImageOutlinedIcon />
        </div>
        <TextXtyle style={{ marginTop: 3 }}>배경</TextXtyle>
        <BorderStyle selected={menu === 'background'} />
      </GNBItemStyle>
      {/* <GNBItemStyle
          selected={menu === 'etc'}
          onClick={() => {
            handleChangeMenu('etc');
          }}
        >
          <div>
            <img src="/images/management/etc.svg" alt="" />
          </div>
          <div>기타</div>
        </GNBItemStyle> */}
    </GNBStyle>
    // </StickyHeader>
  );
};

const GNBStyle = styled.div`
  height: 79px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  background: #fff;
  flex-direction: column;
  justify-content: flex-start;

  @media ${(props) => props.theme.media.desk} {
    margin-top: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 40px;
    height: 100vh;
    gap: 30px;
  }
  @media ${(props) => props.theme.media.pad} {
    gap: 35px;
    flex-direction: row;
    justify-content: center;
  }

  @media ${(props) => props.theme.media.mobile} {
    gap: 35px;
    flex-direction: row;
    justify-content: center;
  }
`;

const TextXtyle = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  @media ${(props) => props.theme.media.desk} {
    margin-left: 10px;
  }
`;

const GNBItemStyle = styled.div<{ selected: boolean }>`
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 49px;
  /* border-bottom: ${(props) => (props.selected ? '4px' : '0')}; */
  padding-bottom: 10px;

  /* width: 20%; */
  opacity: ${(props) => (props.selected ? 1 : 0.5)};
  > img {
    margin: auto;
  }

  > div:first-child {
    width: 26px;
    height: 26px;
    margin: auto;
  }

  @media ${(props) => props.theme.media.desk} {
    flex-direction: row;
    padding-bottom: 0;
    height: auto;
  }
`;

const BorderStyle = styled.div<{ selected?: boolean }>`
  position: relative;
  top: 10px;
  border-bottom: ${(props) => (props.selected ? '3px solid #000000' : '3px solid transparent')};
`;

export default AdminGNB;
