import React, { useEffect } from 'react';
import useNavigation from 'hooks/useNavigation';
import styled from 'styled-components';
import { DealInquiryDto } from 'services/data-contracts';
import MyAvatarBox from 'components/commons/MyAvatarBox';
import { IconButton } from '@mui/material';

import InquiryList from '../../components/InquiryList';
import InquirySummery from '../../components/InquirySummery';
import ManagedDeals from '../../components/ManagedDeals';
import { useRootContext } from 'libs/hooks/useRootContext';
import CSTownController from 'controllers/CSTownController';

const CSTownContainer = ({ inquiries }: { inquiries?: DealInquiryDto[] }) => {
  const { navigate } = useNavigation();
  const { myChannel } = useRootContext();

  const { summary, Seller, Brand, Member } = CSTownController();

  useEffect(() => {
    switch (myChannel?.channelType) {
      case 'SELLER':
        Seller.CS.getSummary();
        break;
      case 'BRAND':
        Brand.CS.getSummary();
        break;
      case 'MEMBER':
        Member.CS.getSummary();
        break;
      default:
        break;
    }
  }, [inquiries]);

  return (
    <div style={{ background: '#F3F4F1' }}>
      <div>
        <div
          style={{
            margin: 'auto',
            borderBottom: '1px solid #173300',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 60,
            padding: '0 20px',
          }}
          className="global-width"
        >
          <CSTownStyle>CS TOWN</CSTownStyle>
          <IconButton
            onClick={() => {
              navigate(`/my/settings`);
            }}
          >
            <img src="/images/settings-green.svg" alt="settings-button" />
          </IconButton>
        </div>
        <div style={{ padding: '0 20px' }}>
          <MyAvatarBox
            avatarUrl={myChannel?.profile?.profileImage?.publicUrl}
            channelName={myChannel?.channelName}
            channelType={myChannel?.channelType}
          />
        </div>
      </div>
      {typeof myChannel !== 'undefined' && (
        <div style={{ padding: 20, paddingTop: 0 }}>
          <InquirySummery
            newInquiry={summary?.newInquiriesCount as number}
            unHandledInquiry={summary?.unresolvedInquiriesCount as number}
          />
          <div style={{ margin: '20px 0' }}>
            <ManagedDeals url="/cstown/deals" />
          </div>
          <InquiryList inquiries={inquiries} />
        </div>
      )}
    </div>
  );
};

const CSTownStyle = styled.div`
  color: #173300;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.096px;
`;

export default CSTownContainer;
