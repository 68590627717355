import React, { useState } from 'react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import styled from 'styled-components';

import useToken from 'libs/hooks/useToken';
import { Swiper, SwiperSlide } from 'swiper/react';
import NavigationTabBar from 'components/commons/NavigationTabBar';
import LandingSlideA from '../components/LandingSlideA';
import LandingSlideB from '../components/LandingSlideB';
import LandingSlideC from '../components/LandingSlideC';
import LandingSlideD from '../components/LandingSlideD';
import LandingSlideE from '../components/LandingSlideE';
import LandingPageData from '../data/LandingPageData';
import RecommendLandingSlides from '../components/RecommendLandingSlides';
import LandingSlideF from '../components/LandingSlideF';
import LandingSlideG from '../components/LandingSlideG';
import LandingSlideH from '../components/LandingSlideH';
import FooterMobile from './FooterMobile';

const Mobile = () => {
  const { validUser } = useToken();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  return (
    <div>
      <ScrollItemStyle>
        <LandingSlideA />
      </ScrollItemStyle>
      <ScrollItemStyle style={{ background: '#EEE' }}>
        <LandingSlideC isMobile />
      </ScrollItemStyle>
      <ScrollItemStyle style={{ background: '#fff' }}>
        <LandingSlideD />
      </ScrollItemStyle>
      <ScrollItemStyle style={{ background: '#EEE' }}>
        <LandingSlideE isMobile />
      </ScrollItemStyle>
      <ScrollItemStyle style={{ background: '#fff' }}>
        <LandingSlideF isMobile />
      </ScrollItemStyle>
      <ScrollItemStyle style={{ background: '#EEE' }}>
        <LandingSlideB />
      </ScrollItemStyle>
      <ScrollItemStyle style={{ background: '#fff' }}>
        <RecommendStyle>이런 인플루언서에게 추천드려요!</RecommendStyle>
        <div style={{ marginLeft: 20 }}>
          <Swiper
            style={{ maxWidth: 1280 }}
            modules={[Navigation, Pagination, Autoplay]}
            slidesPerView={1.2}
            spaceBetween={30}
          >
            {LandingPageData.map((item, index) => (
              <SwiperSlide key={index} style={{ maxWidth: '100%' }}>
                <ScrollItemStyle style={{}}>
                  <RecommendLandingSlides
                    description={item.description}
                    description2={item.description2}
                    imgData={item.imgdata}
                    size={500}
                  />
                </ScrollItemStyle>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </ScrollItemStyle>
      <ScrollItemStyle style={{ height: '60vh' }}>
        <FooterMobile />
      </ScrollItemStyle>

      {validUser() && (
        <GnbComponentStyle style={{ bottom: 0, zIndex: 10, position: 'relative' }}>
          <NavigationTabBar type="main" />
        </GnbComponentStyle>
      )}
    </div>
  );
};

const ScrollItemStyle = styled.div`
  width: 100%;
  position: relative;

  @media ${(props) => props.theme.media.mobile} {
    scroll-snap-align: start;
  }
`;

const GnbComponentStyle = styled.div`
  position: fixed;
  top: 10px;
  z-index: 10;

  @media ${(props) => props.theme.media.mobile} {
    border-radius: 0;
    top: 0px;
    box-shadow: none;
  }
`;
const RecommendStyle = styled.div`
  top: 80px;
  left: 20px;
  right: 20px;
  z-index: 1;
  margin: auto;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  padding: 20px;
  border-radius: 60px;
  background: rgba(255, 255, 255, 0.8);

  @media ${(props) => props.theme.media.mobile} {
    top: 0px;
    position: unset;
    font-size: 24px;
  }
`;

export default Mobile;
