import { makeAutoObservable } from 'mobx';
import { CsService } from 'services/CsService';
import { LinkDto, MessageDto } from 'services/data-contracts';

import _ from 'lodash';
import { HttpClient } from 'services/http-client';

class MemberCSStore {
  links: Array<LinkDto>;

  constructor() {
    makeAutoObservable(this);
  }

  api: CsService<unknown> = new CsService(new HttpClient());

  /**
   * @description 문의 목록을 가져온다.
   * @returns
   */

  *getDeals() {
    const { response } = yield this.api.getDealsByMemberUser();
    return response.status < 300 ? response.data : [];
  }

  *getDeal1(dealId: number) {
    const { response } = yield this.api.getDeal1(dealId);
    return response.status < 300 ? response.data : [];
  }

  *getInquiryList(status?: 'PENDING' | 'ANSWERED' | 'CLOSED') {
    const { response } = yield this.api.getInquiries1({ status });
    return response.status < 300 ? response.data : [];
  }

  *answer(inquiryId: number, data: MessageDto) {
    const { response } = yield this.api.answer1(inquiryId, data);
    return response.status < 300 ? response.data : [];
  }

  *getInquiryDetail(inquiryId: number) {
    const { response } = yield this.api.getInquiry(inquiryId);
    return response.status < 300 ? response.data : [];
  }

  *getInquiriesByDealId(dealId: number) {}

  *getInquiryThreads(inquiryId: number) {
    const { response } = yield this.api.getInquiryThreads1(inquiryId, { sort: ['createDate,ASC'] });
    return response.status < 300 ? response.data : [];
  }

  *closeInquiry(inquiryId: number) {
    const { response } = yield this.api.closeInquiry(inquiryId);
    return response.status < 300 ? response.data : [];
  }

  *getCSSummary() {
    const { response } = yield this.api.getInquirySummaryForMember();
    return response.status < 300 ? response.data : null;
  }

  unMount() {}
}

export default MemberCSStore;
