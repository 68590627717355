import React, { CSSProperties, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ChannelChecklistTopicDto } from 'services/data-contracts';
import useNavigation from 'hooks/useNavigation';
import styled from 'styled-components';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import { isMobile } from 'react-device-detect';
import { useStateContext } from 'libs/hooks/usePageState';
import { CSTownChecklistState } from 'types/CommonTypes';
import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import Text from 'components/commons/Text';
import { Row } from 'components/commons/layouts';
import { OhmossButton } from 'resources/controls/OhmossButton';
import colors from '../../components/checklist/ChecklistColors';
import { ListBoxItemTemplate } from 'features/csTown/commonComponents/styles';

const ChecklistTopicContainer = ({
  category,
  topics,
  checklistId,
  tabLabel,
  style,
}: {
  category?: string;
  topics?: ChannelChecklistTopicDto[];
  checklistId?: any;
  tabLabel?: string;
  style?: CSSProperties;
}) => {
  const location = useLocation();
  const { setPageState } = useStateContext<CSTownChecklistState>();
  // console.log(topics);
  const { navigate } = useNavigation();
  const handleClickChecklistDetails = (topic: ChannelChecklistTopicDto, index: number) => {
    setPageState('selectedTopic', topic);
  };

  const handleClickViewSummary = (topicId?: number, index?: number) => {
    if (checklistId) {
      if (location.pathname === '/cstown/checklist/detail') {
        if (typeof index === 'undefined') {
          navigate(`/cstown/checklist/detail/summary?topicIndex=all&id=${checklistId}`);
        } else {
          navigate(`/cstown/checklist/detail/summary?topicIndex=${index}&id=${checklistId}`);
        }
      } else if (typeof index === 'undefined') {
        navigate(`/my/deals/checklist/detail/summary?topic=all&id=${checklistId}&topic=${topicId}`);
      } else {
        navigate(
          `/my/deals/checklist/detail/summary?topicIndex=${index}&id=${checklistId}&topic=${topicId}`,
        );
      }
    }
  };

  const getMeanPercentage = () => {
    let meanPercentage = 0;
    if (topics && topics?.length > 0) {
      meanPercentage =
        topics.reduce((sum, item) => sum + (item?.progressRate ?? 0), 0) / topics.length;
      if (!Number.isInteger(meanPercentage)) {
        meanPercentage = Number(meanPercentage.toFixed(1));
      }
    }
    return meanPercentage as number;
  };

  return (
    <MainContainer style={style}>
      {topics?.length === 0 && (
        <ListBoxItemTemplate>
          <div style={{ fontSize: 14, fontWeight: 500 }}>
            선택하신 카테고리에 대한 체크리스트가 없습니다.
          </div>
        </ListBoxItemTemplate>
      )}
      {topics && topics?.length > 0 && (
        <ContainerStyles
          onClick={() => {
            if (tabLabel === '요약보기') {
              handleClickViewSummary();
            }
          }}
        >
          <LabelStyles>전체보기</LabelStyles>
          <Box sx={{ width: '100%' }}>
            <LinearProgress
              variant="determinate"
              style={{ height: 20, borderRadius: 20 }}
              sx={{
                '&.MuiLinearProgress-root': { backgroundColor: '#ebebeb' },
                '&.MuiLinearProgress-root .MuiLinearProgress-bar': {
                  transition: 'all 0.25s ease-in-out',
                  background:
                    'linear-gradient(to right, rgba(255,0,24,1) 0%, rgba(0,152,255,1) 100%)',
                },
              }}
              value={getMeanPercentage()}
            />
            <div style={{ padding: '20px 0' }}>
              {getMeanPercentage() === 100 ? (
                <Text type="H4">모든 항목을 체크하였습니다!</Text>
              ) : (
                <Text type="H4" buttonRole="DESCRIPTION">
                  모든 체크리스트 항목 중 {getMeanPercentage()}%를 완료하였습니다.
                </Text>
              )}
            </div>
          </Box>
          {tabLabel === '요약보기' && (
            <ArrowForwardIos
              style={{
                width: 16,
                color: 'rgba(202, 202, 202, 1)',
                position: 'absolute',
                height: 14,
                bottom: isMobile ? 20 : 15,
                right: 20,
                cursor: 'pointer',
              }}
            />
          )}
        </ContainerStyles>
      )}
      {topics?.map((topic, index) => (
        <ContainerStyles
          onClick={() => {
            handleClickChecklistDetails(topic, index);
            //
          }}
        >
          <Row style={{ justifyContent: 'space-between' }}>
            <LabelStyles>{topic?.name}</LabelStyles>
            <OhmossButton style={{ width: 100, height: 30, borderRadius: 8 }} type="square">
              상세보기
            </OhmossButton>
          </Row>
          <Box sx={{ width: '100%' }}>
            <LinearProgress
              variant="determinate"
              style={{ height: 20, borderRadius: 20 }}
              sx={{
                '&.MuiLinearProgress-root': { backgroundColor: '#ebebeb' },
                '&.MuiLinearProgress-root .MuiLinearProgress-bar': {
                  transition: 'all 0.25s ease-in-out',
                  backgroundColor: colors[index]?.color,
                },
              }}
              value={topic?.progressRate ?? 0}
            />
          </Box>
          <div style={{ padding: '20px 0' }}>
            {topic?.progressRate === 100 ? (
              <Text type="H4">모든 항목을 체크하였습니다!</Text>
            ) : (
              <Text type="H4" buttonRole="DESCRIPTION">
                체크항목 중 {topic?.progressRate}%를 완료하였습니다.
              </Text>
            )}
          </div>
          {isMobile && (
            <ArrowForwardIos
              style={{
                width: 16,
                color: 'rgba(202, 202, 202, 1)',
                position: 'absolute',
                height: 14,
                bottom: isMobile ? 20 : 15,
                right: 20,
                cursor: 'pointer',
              }}
            />
          )}
        </ContainerStyles>
      ))}
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
`;

const ContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  border: 1px solid rgba(228, 228, 228, 0.3);
  background: white;
  padding: 10px 20px;
  align-items: flex-start;
`;

const FillerStyles = styled.div<{ endColor?: string; completed?: number }>`
  height: 20px;
  width: ${(props) => props.completed}%;
  background-color: ${(props) => props.endColor};
  border-radius: var(--border-radius);

  transition: all 0.3s ease-in-out;
`;

const LabelStyles = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 10px;
`;

export default ChecklistTopicContainer;
