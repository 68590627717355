import React, { useEffect, useState } from 'react';
import { Row } from './layouts';
import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

const CircularLoading = ({ interval }: { interval?: number }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, interval || 500);

    return () => {
      setShow(true);
    };
  }, []);

  return (
    <>
      <ContainerStyle show={show}>
        <Row style={{ justifyContent: 'center', height: '100%', alignItems: 'center' }}>
          <CircularProgress />
        </Row>
      </ContainerStyle>
    </>
  );
};

const ContainerStyle = styled.div<{ show?: boolean }>`
  position: absolute;
  background: #f3f4f1;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: ${(props) => (props.show ? 'all' : 'none')};
  transition: all 0.3s ease;
`;
export default CircularLoading;
