import React from 'react';
import {
  ItemSectionStyle,
  ItemSectionTitle,
  ItemSectionDescription,
} from 'resources/controls/ItemSectionStyle';
import { ChannelConfigModel } from 'types/CommonTypes';
import { t } from 'i18next';
import { FormControlLabel, Switch } from '@mui/material';

const SettingItemHideChannel = ({
  model,
}: {
  model: [
    ChannelConfigModel | undefined,
    React.Dispatch<React.SetStateAction<ChannelConfigModel | undefined>>,
  ];
}) => {
  const patchModel = model[0];
  const dispatch = model[1];

  return (
    <ItemSectionStyle style={{ padding: 20 }}>
      <ItemSectionTitle>{t('A257')}</ItemSectionTitle>
      <ItemSectionDescription>{t('A258')}</ItemSectionDescription>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={patchModel?.isChannelPrivate}
            onChange={(event: never, value: boolean) => {
              dispatch((prev: ChannelConfigModel) => {
                return {
                  ...prev,
                  isChannelPrivate: value,
                };
              });
            }}
          />
        }
        label={t('A259')}
      />
    </ItemSectionStyle>
  );
};

export default SettingItemHideChannel;
