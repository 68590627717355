import { makeObservable, flow, observable, action } from 'mobx';
import { BoardConfig as BoardConfigService } from 'services/BoardConfig';
import { HttpClient } from 'services/http-client';
import { BoardCategoryDto } from 'services/data-contracts';
import _ from 'lodash';
import ServiceStoreBase from './ServiceStoreBase';

/**
 * @description Public Link Store
 */
class BoardConfigStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      getBoardCategories: flow,
      boardCategories: observable,
    });
  }

  api: BoardConfigService<unknown> = new BoardConfigService(new HttpClient());

  boardCategories: Array<BoardCategoryDto> = [];

  /** ========================================================================================= */

  *getBoardCategories() {
    const { response } = yield this.api.getCategories();
    this.boardCategories = response.data;
  }
}

export default BoardConfigStore;
