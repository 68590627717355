import React, { useEffect, useState } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import Radio from '@mui/material/Radio';
import Switch from '@mui/material/Switch';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { BoardCategoryDto, ChannelBoardDto } from 'services/data-contracts';
import styled from 'styled-components';
import { Row } from 'components/commons/layouts';
import MUIRadio from 'components/commons/buttons/MUIRadio';

const BoardCategoryForm = ({
  viewType,
  onChangeviewType,
}: {
  viewType: ChannelBoardDto['viewType'];
  onChangeviewType: (viewType: ChannelBoardDto['viewType']) => void;
}) => {
  const [layoutType, setLayoutType] = useState<ChannelBoardDto['viewType']>('GRID');

  useEffect(() => {
    if (viewType) {
      setLayoutType(viewType);
    }
  }, [viewType]);
  return (
    <>
      <ItemSectionStyle>
        <ItemSectionTitle>레이아웃</ItemSectionTitle>
        <ItemContent>
          <FormControl sx={{ '&.MuiFormControl-root': { width: '100%' } }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={layoutType}
              value={layoutType}
              name="radio-buttons-group"
              onChange={(e: any, value: string) => {
                setLayoutType(value as ChannelBoardDto['viewType'])
                onChangeviewType(value as ChannelBoardDto['viewType']);
              }}
            >
              <LayoutStyle
                onClick={() => {
                  setLayoutType('GRID');
                  onChangeviewType('GRID');
                }}
              >
                <Row style={{ width: 'auto' }}>
                  <img src="/images/management/feed.svg" alt="" />
                  <div style={{ marginLeft: 10 }}>Feed</div>
                </Row>
                <FormControlLabel
                  sx={{ '&.MuiFormControlLabel-root': { marginLeft: 0, marginRight: 0 } }}
                  value="GRID"
                  control={<MUIRadio />}
                  label=""
                />
              </LayoutStyle>
              <LayoutStyle
                style={{ margin: '10px 0px' }}
                onClick={() => {
                  onChangeviewType('MASONRY');
                }}
              >
                <Row style={{ width: 'auto' }}>
                  <img src="/images/management/flex.svg" alt="" />
                  <div style={{ marginLeft: 10 }}>Flex</div>
                </Row>
                <FormControlLabel
                  sx={{ '&.MuiFormControlLabel-root': { marginLeft: 0, marginRight: 0 } }}
                  value="MASONRY"
                  control={<MUIRadio />}
                  label=""
                />
              </LayoutStyle>
            </RadioGroup>
          </FormControl>
        </ItemContent>
      </ItemSectionStyle>
    </>
  );
};

const LayoutStyle = styled.div`
  border: 1px solid #bebebe;
  padding: 20px;
  border-radius: 8px;
  display: flex;

  justify-content: space-between;
`;

export default BoardCategoryForm;
