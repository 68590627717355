import { Avatar, Badge, IconButton } from '@mui/material';
import { Row } from 'components/commons/layouts';
import { BoxStyle, ListItemTemplate } from 'features/csTown/commonComponents/styles';
import { useRootContext } from 'libs/hooks/useRootContext';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CommonThumbnail from 'features/management/components/commonControl/CommonThumbnail';
import { GroupChannel } from '@sendbird/chat/groupChannel';

/**
 * @description Inquiry목록(채널 채팅)
 * @param param0
 * @returns
 */
const MemberListItem = ({
  inquiry,
  selected,
  useSingleSeller = false,
  cutoffString,
  onClick,
  onContextClick,
}: {
  inquiry?: any;
  selected?: boolean;
  useSingleSeller?: boolean;
  cutoffString?: number;
  onClick: () => void;
  onContextClick: (e: any) => void;
}) => {
  const [members, setMembers] = useState<any[]>([]);

  const MemberNames = () => {
    const names = inquiry?.members?.map((member: any) => member.userId).join(',');
    return <div>{names}</div>;
  };
  useEffect(() => {
    setMembers(inquiry.members);
  }, [inquiry]);

  return (
    <BoxStyle selected={selected}>
      <div>
        <Row style={{ justifyContent: 'flex-start' }}>
          <AvatarContainerStyle onClick={onClick}>
            {members
              ?.slice(0, 2)
              .map((member, i) => (
                <Avatar
                  key={i}
                  src={member.plainProfileUrl || ''}
                  style={{ width: 50, height: 50, marginLeft: i === 1 ? -30 : 0 }}
                />
              ))}
            {members?.length > 2 && <BadgeStyle>+{members?.length - 2}</BadgeStyle>}
          </AvatarContainerStyle>
          <div style={{ marginLeft: members?.length > 2 ? 0 : 10, flexGrow: 1 }} onClick={onClick}>
            <div style={{ fontWeight: 600, overflow: 'hidden' }}>
              <MemberNames />
              {/* {inquiry?.lastMessage?.sender?.nickname} */}
            </div>
            <MessageStyle>{inquiry?.lastMessage?.message}</MessageStyle>
          </div>
          <div style={{ textAlign: 'center' }}>
            <div>
              {inquiry?.unreadMessageCount > 0 && (
                <Badge
                  badgeContent={inquiry?.unreadMessageCount}
                  sx={{
                    '& .MuiBadge-badge': {
                      background: '#173300',
                      color: '#96e06e',
                      fontWeight: 600,
                    },
                  }}
                />
              )}
            </div>
            <div style={{ fontSize: 12, color: '#777' }}>
              {moment(inquiry.myLastRead).fromNow()}
            </div>
          </div>
          <IconButton onClick={onContextClick}>
            <MoreVertIcon />
          </IconButton>
        </Row>
      </div>
    </BoxStyle>
  );
};

const AvatarContainerStyle = styled.div`
  display: flex;
  > div {
  }
  > div:nth-child(2) {
    /* margin-left: -30px; */
  }
`;

const BadgeStyle = styled.div`
  left: -15px;
  background: #173300;
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
`;

const MessageStyle = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    font-size: 12px;
  }
`;
export default MemberListItem;
