import React from 'react';
import { MembershipPlanDto } from 'services/data-contracts';
import Common from './Common';

const SELLER_FREE = ({ plan }: { plan: MembershipPlanDto }) => {
  const texts = [
    '링크 서비스 무료 제공 (일부 블록 제외)',
    '팔로우 기능 제공',
    '제안받기 기능 제공',
    '대시보드 기능 제공',
  ];
  const description = '다양한 프로필 링크';
  return (
    <>
      <Common texts={texts} plan={plan} description={description} />
    </>
  );
};

export default SELLER_FREE;
