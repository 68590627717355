import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function PartnersManagersTab({
  onHandleTabClick,
}: {
  onHandleTabClick: (label: string) => void;
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleTabClick = (label: string) => {
    onHandleTabClick(label);
  };

  return (
    <Box>
      <Box>
        <Tabs
          TabIndicatorProps={{ style: { background: '#173300' } }}
          value={value}
          onChange={(event: React.SyntheticEvent, newValue: number) =>
            handleChange(event, newValue)
          }
        >
          <Tab
            sx={{
              width: '30%',
              color: 'grey', // Customize text color
              '&.Mui-selected': {
                color: 'black', // Customize selected text color
              },
              '& .MuiTab-indicator': {
                backgroundColor: 'orange', // Customize indicator color
              },
              fontSize: '1rem',
              fontWeight: '600',
            }}
            label="파트너"
            onClick={() => handleTabClick('파트너')}
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              width: '30%',
              fontSize: '1rem',
              fontWeight: '600',
              color: 'grey', // Customize text color
              '&.Mui-selected': {
                color: 'black', // Customize selected text color
              },
              '& .MuiTab-indicator': {
                backgroundColor: 'orange', // Customize indicator color
              },
            }}
            label="매니저"
            onClick={() => handleTabClick('매니저')}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
    </Box>
  );
}
