import React from 'react';
import MyProposalList from '../containers/MyProposalList';
import styled from 'styled-components';

const BusinessProposalListPage = () => {
  return (
    <>
      {/* <DetailPageHeader title={'제안 내역'} /> */}
      <ContainerStyle>
        <MyProposalList />
      </ContainerStyle>
    </>
  );
};

const ContainerStyle = styled.div`
  width: 100%;
  @media ${(props) => props.theme.media.desk} {
    margin-left: 30px;
    width: 800px;
  }
`;
export default BusinessProposalListPage;
