/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ChannelCreationDto,
  ChannelDto,
  ChannelProfileDto,
  FriendshipDto,
  FriendshipTagAddDto,
  FriendshipTagDeleteDto,
  FriendshipTagDto,
  ProposalDto,
  RequestChannelAuthenticationDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MyChannel<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 채널 전체 조회
   *
   * @tags my channel
   * @name GetChannels
   * @summary 내 채널 전체 조회
   * @request GET:/v2/me/channels
   * @secure
   */
  getChannels = (
    query?: {
      /** 검색어 (채널 프로필 닉네임) */
      searchText?: string;
      /** 채널 타입 (SELLER, BRAND) */
      channelType?: 'SELLER' | 'BRAND' | 'MEMBER';
      /**
       * 페이지 번호 default: 1
       * @format int32
       */
      page?: number;
      /**
       * 페이지 사이즈 default: 20
       * @format int32
       */
      size?: number;
      /** 정렬 기준 default: createDate */
      sortBy?: string;
      /** 정렬 방향 (desc, asc) default: 내림차순 */
      direction?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ChannelDto[], any>({
      path: `/v2/me/channels`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 채널 생성
   *
   * @tags my channel
   * @name CreateChannel
   * @summary 채널 생성
   * @request POST:/v2/me/channels
   * @secure
   */
  createChannel = (data: ChannelCreationDto, params: RequestParams = {}) =>
    this.http.request<ChannelDto, any>({
      path: `/v2/me/channels`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 언팔로우
   *
   * @tags my channel
   * @name Unfollow
   * @summary 언팔로우
   * @request POST:/v2/me/channels/{channelId}/unfollow
   * @secure
   */
  unfollow = (channelId: number, data: FriendshipTagDto, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/unfollow`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags my channel
   * @name RequestChannelAuthentication
   * @summary 채널 인증요청
   * @request POST:/v2/me/channels/{channelId}/request-authentication
   * @secure
   */
  requestChannelAuthentication = (
    channelId: number,
    data: RequestChannelAuthenticationDto,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/request-authentication`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 팔로워 태그 추가
   *
   * @tags my channel
   * @name AddFollowTag
   * @summary 팔로워 태그 추가
   * @request POST:/v2/me/channels/{channelId}/followers/tags
   * @secure
   */
  addFollowTag = (channelId: number, data: FriendshipTagDto, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/followers/tags`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 팔로워 태그 삭제
   *
   * @tags my channel
   * @name DeleteFollowTag
   * @summary 팔로워 태그 삭제
   * @request DELETE:/v2/me/channels/{channelId}/followers/tags
   * @secure
   */
  deleteFollowTag = (channelId: number, data: FriendshipTagDto, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/followers/tags`,
      method: 'DELETE',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 팔로워 태그 일괄 추가
   *
   * @tags my channel
   * @name AddAllFollowTags
   * @summary 팔로워 태그 일괄 추가
   * @request POST:/v2/me/channels/{channelId}/follower-tags-exclude-follower
   * @secure
   */
  addAllFollowTags = (channelId: number, data: FriendshipTagAddDto, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/follower-tags-exclude-follower`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 팔로워 태그 일괄 삭제
   *
   * @tags my channel
   * @name DeleteAllFollowTags
   * @summary 팔로워 태그 일괄 삭제
   * @request DELETE:/v2/me/channels/{channelId}/follower-tags-exclude-follower
   * @secure
   */
  deleteAllFollowTags = (
    channelId: number,
    data: FriendshipTagDeleteDto,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/follower-tags-exclude-follower`,
      method: 'DELETE',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 팔로우
   *
   * @tags my channel
   * @name Follow
   * @summary 팔로우
   * @request POST:/v2/me/channels/{channelId}/follow
   * @secure
   */
  follow = (channelId: number, data: FriendshipTagDto, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/follow`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 채널 조회
   *
   * @tags my channel
   * @name GetChannel
   * @summary 내 채널 조회
   * @request GET:/v2/me/channels/{channelId}
   * @secure
   */
  getChannel = (channelId: number, params: RequestParams = {}) =>
    this.http.request<ChannelDto, any>({
      path: `/v2/me/channels/${channelId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 채널 삭제
   *
   * @tags my channel
   * @name DeleteChannel
   * @summary 채널 삭제
   * @request DELETE:/v2/me/channels/{channelId}
   * @secure
   */
  deleteChannel = (channelId: number, params: RequestParams = {}) =>
    this.http.request<ChannelDto, any>({
      path: `/v2/me/channels/${channelId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 채널명 수정
   *
   * @tags my channel
   * @name UpdateChannel
   * @summary 채널명 수정
   * @request PATCH:/v2/me/channels/{channelId}
   * @secure
   */
  updateChannel = (channelId: number, data: ChannelDto, params: RequestParams = {}) =>
    this.http.request<ChannelDto, any>({
      path: `/v2/me/channels/${channelId}`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 채널 프로필 수정
   *
   * @tags my channel
   * @name UpdateChannelProfile
   * @summary 채널 프로필 수정
   * @request PATCH:/v2/me/channels/{channelId}/profile
   * @secure
   */
  updateChannelProfile = (channelId: number, data: ChannelProfileDto, params: RequestParams = {}) =>
    this.http.request<ChannelDto, any>({
      path: `/v2/me/channels/${channelId}/profile`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 삭제된 채널 조회
   *
   * @tags my channel
   * @name GetDeletedChannel
   * @summary 삭제된 채널 조회
   * @request GET:/v2/me/channels/{channelId}/deleted
   * @secure
   */
  getDeletedChannel = (channelId: number, params: RequestParams = {}) =>
    this.http.request<ChannelDto, any>({
      path: `/v2/me/channels/${channelId}/deleted`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 삭제된 채널 되돌리기
   *
   * @tags my channel
   * @name RecoveryChannel
   * @summary 삭제된 채널 되돌리기
   * @request PATCH:/v2/me/channels/{channelId}/deleted
   * @secure
   */
  recoveryChannel = (channelId: number, params: RequestParams = {}) =>
    this.http.request<ChannelDto, any>({
      path: `/v2/me/channels/${channelId}/deleted`,
      method: 'PATCH',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 제안한 목록
   *
   * @tags my channel
   * @name GetSuggestedProposals
   * @summary 제안한 목록
   * @request GET:/v2/me/channels/{channelId}/suggested-proposals
   * @secure
   */
  getSuggestedProposals = (
    channelId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["proposalDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ProposalDto[], any>({
      path: `/v2/me/channels/${channelId}/suggested-proposals`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 보낸 제안 정보
   *
   * @tags my channel
   * @name GetSuggestedProposal
   * @summary 보낸 제안 정보
   * @request GET:/v2/me/channels/{channelId}/suggested-proposals/{proposalId}
   * @secure
   */
  getSuggestedProposal = (channelId: number, proposalId: number, params: RequestParams = {}) =>
    this.http.request<ProposalDto, any>({
      path: `/v2/me/channels/${channelId}/suggested-proposals/${proposalId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 보낸 제안 삭제
   *
   * @tags my channel
   * @name DeleteProposalByProposer
   * @summary 보낸 제안 삭제
   * @request DELETE:/v2/me/channels/{channelId}/suggested-proposals/{proposalId}
   * @secure
   */
  deleteProposalByProposer = (channelId: number, proposalId: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/suggested-proposals/${proposalId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 받은 제안 목록
   *
   * @tags my channel
   * @name GetReceivedProposals
   * @summary 받은 제안 목록
   * @request GET:/v2/me/channels/{channelId}/received-proposals
   * @secure
   */
  getReceivedProposals = (
    channelId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["proposalDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ProposalDto[], any>({
      path: `/v2/me/channels/${channelId}/received-proposals`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 받은 제안 정보
   *
   * @tags my channel
   * @name GetReceivedProposal
   * @summary 받은 제안 정보
   * @request GET:/v2/me/channels/{channelId}/received-proposals/{proposalId}
   * @secure
   */
  getReceivedProposal = (channelId: number, proposalId: number, params: RequestParams = {}) =>
    this.http.request<ProposalDto, any>({
      path: `/v2/me/channels/${channelId}/received-proposals/${proposalId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 받은 제안 삭제
   *
   * @tags my channel
   * @name DeleteProposalByReceipient
   * @summary 받은 제안 삭제
   * @request DELETE:/v2/me/channels/{channelId}/received-proposals/{proposalId}
   * @secure
   */
  deleteProposalByReceipient = (
    channelId: number,
    proposalId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/received-proposals/${proposalId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 내 팔로잉 목록
   *
   * @tags my channel
   * @name GetFollowings
   * @summary 내 팔로잉 목록
   * @request GET:/v2/me/channels/{channelId}/followings
   * @secure
   */
  getFollowings = (
    channelId: number,
    query?: {
      /** 검색어 (채널 프로필 닉네임) */
      searchText?: string;
      /** 채널 타입 (SELLER, BRAND) */
      channelType?: 'SELLER' | 'BRAND' | 'MEMBER';
      /**
       * 페이지 번호 default: 1
       * @format int32
       */
      page?: number;
      /**
       * 페이지 사이즈 default: 20
       * @format int32
       */
      size?: number;
      /** 정렬 기준 default: createDate */
      sortBy?: string;
      /** 정렬 방향 (desc, asc) default: 내림차순 */
      direction?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ChannelDto[], any>({
      path: `/v2/me/channels/${channelId}/followings`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 팔로워 목록
   *
   * @tags my channel
   * @name GetFollowers
   * @summary 내 팔로워 목록
   * @request GET:/v2/me/channels/{channelId}/followers
   * @secure
   */
  getFollowers = (
    channelId: number,
    query?: {
      /** 검색어 (채널명) */
      searchText?: string;
      /**
       * 페이지 번호 default: 1
       * @format int32
       */
      page?: number;
      /**
       * 페이지 사이즈 default: 20
       * @format int32
       */
      size?: number;
      /** 필터링 태그 */
      tagIds?: number[];
      /**
       * 현재 기준 최근 일 수
       * @format int32
       */
      beforeDays?: number;
      /** 정렬 기준 default: createDate */
      sortBy?: string;
      /** 정렬 방향 (desc, asc) default: 내림차순 */
      direction?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FriendshipDto[], any>({
      path: `/v2/me/channels/${channelId}/followers`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 팔로워 삭제
   *
   * @tags my channel
   * @name DeleteFollower
   * @summary 팔로워 삭제
   * @request DELETE:/v2/me/channels/{channelId}/followers
   * @secure
   */
  deleteFollower = (channelId: number, data: FriendshipDto, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/followers`,
      method: 'DELETE',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 삭제된 채널 전체 조회
   *
   * @tags my channel
   * @name GetDeletedChannels
   * @summary 삭제된 채널 전체 조회
   * @request GET:/v2/me/channels/deleted
   * @secure
   */
  getDeletedChannels = (
    query?: {
      /** 검색어 (채널 프로필 닉네임) */
      searchText?: string;
      /** 채널 타입 (SELLER, BRAND) */
      channelType?: 'SELLER' | 'BRAND' | 'MEMBER';
      /**
       * 페이지 번호 default: 1
       * @format int32
       */
      page?: number;
      /**
       * 페이지 사이즈 default: 20
       * @format int32
       */
      size?: number;
      /** 정렬 기준 default: createDate */
      sortBy?: string;
      /** 정렬 방향 (desc, asc) default: 내림차순 */
      direction?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ChannelDto[], any>({
      path: `/v2/me/channels/deleted`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 채널 완전 삭제
   *
   * @tags my channel
   * @name DeleteChannelPermanently
   * @summary 채널 완전 삭제
   * @request DELETE:/v2/me/channels/{channelId}/delete-permanently
   * @secure
   */
  deleteChannelPermanently = (channelId: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/delete-permanently`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
}
