import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import PartnerController from 'controllers/PartnerController';
import { MemberDto, PartnerDto } from 'services/data-contracts';
import SearchBox from 'components/commons/SearchBox';
import { MainText } from 'resources/styles/common/ContextItem';
import { useRootContext } from 'libs/hooks/useRootContext';
import PartnerSettingsContainer from '../containers/PartnerSettingsContainer';
import PartnersManagersList from '../containers/PartnersManagersList';
import PartnersManagersTab from './PartnersManagersTab';

const DealPartnerList = () => {
  const originPartners = useRef<PartnerDto[]>([]);
  const originMembers = useRef<MemberDto[]>([]);

  const { uiStore } = useStore();
  const { myChannel } = useRootContext();
  const { getPartners, getPartnerInvitations, getPartnerMembers } = PartnerController();

  const [partnersList, setPartnersList] = useState<PartnerDto[]>([]);
  const [managersList, setManagersList] = useState<MemberDto[]>([]);
  const [invitationList, setInvitationList] = useState<MemberDto[]>([]);
  const [tab, setTab] = useState<string>('파트너');

  const getPartnersList = async () => {
    const result = await getPartners();
    if (result) {
      setPartnersList(result);
      originPartners.current = result;
    }
  };

  const getManagersList = async () => {
    const result = await getPartnerMembers();
    if (result) {
      setManagersList(result);
      originMembers.current = result;
    }
  };

  const getInvitationsResult = async () => {
    const result = (await getPartnerInvitations()) as unknown as MemberDto[];
    if (result) {
      setInvitationList(result);
    }
  };

  const handleAddPartner = () => {
    uiStore.modal.show({
      title: '파트너 설정',
      style: {
        minHeight: '85%',
      },
      children: <PartnerSettingsContainer />,
    });
  };

  const handleSearch = (value: string) => {
    if (tab === '매니저') {
      setManagersList(
        originMembers.current.filter((member) => member.memberUser?.username?.includes(value)),
      );
    }

    if (tab === '파트너') {
      setPartnersList(
        originPartners.current.filter(
          (partner) => partner.partnerChannel?.channelName?.includes(value),
        ),
      );
    }
  };

  useEffect(() => {
    if (myChannel) {
      getPartnersList();
      getManagersList();
      getInvitationsResult();
    }
  }, [myChannel]);

  return (
    <ManagementLayout style={{ height: 'calc(100vh - 80px)', padding: 0 }}>
      <div>
        <PartnersManagersTab onHandleTabClick={setTab} />
        {tab === '파트너' && (
          <MainContainer>
            <MainText>파트너 찾기</MainText>
            <div style={{ marginBottom: 20 }}>
              <SearchBox
                onEntered={handleSearch}
                onCancel={() => {
                  handleSearch('');
                }}
              />
            </div>
            {invitationList.length > 0 && (
              <div style={{ marginBottom: 20 }}>
                <MainText>초대중인 파트너</MainText>
                <PartnersManagersList
                  partners={invitationList}
                  acceptedPartner
                  onRefresh={getPartnersList}
                />
              </div>
            )}
            <MainText> 리스트</MainText>
            {partnersList.length === 0 && <SubText>등록된 {tab}가 없습니다.</SubText>}
            <PartnersManagersList partners={partnersList} onRefresh={getPartnersList} />
          </MainContainer>
        )}
        {tab === '매니저' && (
          <MainContainer>
            <MainText>매니저 찾기</MainText>
            <div style={{ marginBottom: 50 }}>
              <SearchBox
                onEntered={handleSearch}
                onCancel={() => {
                  handleSearch('');
                }}
              />
            </div>
            <MainText> 리스트</MainText>
            {managersList.length === 0 && <SubText>등록된 {tab}가 없습니다.</SubText>}
            <PartnersManagersList managers={managersList} onRefresh={getManagersList} />
          </MainContainer>
        )}
      </div>
      {tab === '파트너' && (
        <OhmossButton
          style={{
            position: 'absolute',
            bottom: 100,
            margin: 'auto',
            left: 0,
            right: 0,
            width: '90%',
          }}
          onClick={handleAddPartner}
        >
          파트너 초대
        </OhmossButton>
      )}
    </ManagementLayout>
  );
};

const MainContainer = styled.div`
  display: flex;
  padding: 20px 20px 100px 20px;
  flex-direction: column;
`;

const SubText = styled.div`
  margin-top: 10px;
  color: #cacaca;
  font-size: 0.82rem;
`;

export default DealPartnerList;
