import { makeObservable, observable, action } from 'mobx';
import { HistoryType } from 'types/CommonTypes';

/**
 * @description Public Link Store
 */
class HistoryStore {
  histories: Array<HistoryType> = [];

  constructor() {
    makeObservable(this, { histories: observable, pushPopHistory: action });
  }

  pushPopHistory(newHistory: HistoryType) {
    const recent = this.histories.findIndex(
      (history: HistoryType) => history.key === newHistory.key,
    );
    if (recent > -1) {
      this.histories.splice(recent, 1);
    } else {
      this.histories.push(newHistory);
    }
  }

  //   popHistory() {
  //     if (this.histories.length > 0) {
  //       this.histories.splice(this.popHistory.length, 1);
  //     }
  //   }
}

export default HistoryStore;
