import chatController from 'features/community/controllers/chatController';
import { useRootContext } from 'libs/hooks/useRootContext';

import { useState } from 'react';
import { DealInquiryDto, InquirySummaryDto, LinkDto, PartnerDto } from 'services/data-contracts';
import PartnershipStore from 'stores/PartnershipStore';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';

/**
 * @description Brand의 CS만을 위한 컨트롤러임.
 * @returns
 */
const BrandCSController = () => {
  const { myChannel } = useRootContext();
  const partnershipStore = new PartnershipStore();
  const { acceptChannelInvitation } = chatController();

  const [deals, setDeals] = useState<LinkDto[]>();
  const [inquiries, setInquiries] = useState<DealInquiryDto[]>();
  const [threads, setThreads] = useState<DealInquiryDto[]>();
  const [summary, setSummary] = useState<InquirySummaryDto>();

  const getDeals = async () => {
    const result = (await partnershipStore.getDelegatedDeals(
      myChannel?.id as number,
    )) as unknown as LinkDto[];
    setDeals(result);
  };

  const getAllInquiries = async (status?: 'PENDING' | 'ANSWERED' | 'CLOSED') => {
    const result = (await partnershipStore.getInquiriesByPartnerChannel(
      myChannel?.id as number,
      status,
    )) as unknown as DealInquiryDto[];
    setInquiries(result);
  };

  const getSummary = async () => {
    const result = (await partnershipStore.getCSSummary(
      myChannel?.id as number,
    )) as unknown as InquirySummaryDto;
    setSummary(result);
  };

  const getDealInquiries = async (dealId: number) => {
    const result = (await partnershipStore.getDealInquiries(
      myChannel?.id as number,
      dealId,
    )) as unknown as DealInquiryDto[];
    setInquiries(result);
  };

  const getInquiryThreads = async (dealId: number, inquiryId: number) => {
    const result = (await partnershipStore.getInquiryThreads(
      myChannel?.id as number,
      dealId,
      inquiryId,
    )) as unknown as DealInquiryDto[];
    setThreads(result);
  };

  const getInquiriesByPartnerChannel = async (channelId: number) => {
    const result = (await partnershipStore.getInquiriesByPartnerChannel(
      channelId,
    )) as unknown as DealInquiryDto[];
    setInquiries(result);
  };

  const getDealsByPartnerChannel = async (channelId: number) => {
    const partners = (await partnershipStore.getPartners(
      myChannel?.id as number,
    )) as unknown as PartnerDto[];
    const sellerPartner = partners.find((partner) => partner.channel?.id === channelId);
    if (sellerPartner) {
      const result = (await partnershipStore.getDealsByPartner(
        myChannel?.id as number,
        sellerPartner?.id as number,
      )) as unknown as LinkDto[];
      return result;
    }

    return null;
  };

  /**
   * @description 브랜드가 초대를 수락한 경우, 해당 딜 채팅에 참여한다.
   * @param channelName 브랜드 자신의 채널명
   * @param deals 딜 Key Array
   */
  const joinChatChannels = async (sellerChannelId: number) => {
    const temps = (await getDealsByPartnerChannel(sellerChannelId)) as unknown as LinkDto[];
    const deals = temps.map((deal) => {
      return deal.linkKey;
    }) as string[];
    const result = await acceptChannelInvitation(deals);
  };

  // const banChatChannels = async (sellerChannelId: number) => {
  //   const temps = (await getDealsByPartnerChannel(sellerChannelId)) as unknown as LinkDto[];
  //   const deals: LinkDtoWithAssignedDeal[] = temps.map((deal) => {
  //     return { ...deal, assigned: true };
  //   });
  //   const result = await inviteOrDeleteUser(deals, myChannel?.channelName as string);
  // };

  return {
    deals,
    inquiries,
    threads,
    summary,
    getDeals,
    getDealInquiries,
    getInquiryThreads,
    getInquiriesByPartnerChannel,
    getAllInquiries,
    setDeals,
    getSummary,
    joinChatChannels,
    getDealsByPartnerChannel,
  };
};

export default BrandCSController;
