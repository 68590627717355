import TitleForm from 'components/items/forms/TitleForm';
import useSetState from 'libs/hooks/useSetState';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { DealServiceItemModel } from 'types/CommonTypes';
import { useStore } from 'stores/StoreHelper';

const DealCustomTypeA = ({
  item,
  onChange,
}: {
  item: DealServiceItemModel;
  onChange: (result: Partial<DealServiceItemModel>) => void;
}) => {
  const { uiStore } = useStore();
  const [localModel, setLocalModel] = useSetState<DealServiceItemModel>();

  const handleClickImoji = () => {};

  return (
    <>
      {/* <div>고객문의가 활성화 되면 "고객게시판"을 통해 문의가 가능합니다.</div> */}
      <CustomItemWrapper>
        <CustomItemStyle onClick={handleClickImoji}>{item.icon}</CustomItemStyle>
        <FormContainer>
          <TitleForm
            onChangeValue={(value: string) => {
              setLocalModel('title', value);
              onChange({ ...item, title: value });
            }}
            initialValue={item?.title}
          />
        </FormContainer>
      </CustomItemWrapper>
    </>
  );
};

const CustomItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const CustomItemStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: #efefef;
  width: 40px;
  height: 40px;
  min-width: 40px;
  cursor: pointer;
  margin-right: 10px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-right: 20px;
`;

export default DealCustomTypeA;
