/** PC */
import MyInquriesContainer from 'features/csTown/containers/pc/MyInquriesContainer';
import MobildMyInquiriesContainer from 'features/csTown/containers/mobile/MyInquriesContainer';
import MobileCstownContainer from 'features/csTown/containers/mobile/CSTownContainer';
import RootContainer from './pc/RootContainer';
import CstownDealsContainer from './pc/CstownDealsContainer';
import PartnerListContainer from './pc/PartnerListContainer';
import ManagerListContainer from './pc/ManagerListContainer';
import CSTownChecklistContainer from './pc/CSTownChecklistContainer';
import CSTownChecklistDetailContainer from './pc/CSTownChecklistDetailContainer';

/** Mobile */
import MobileRootContainer from './mobile/RootContainer';
import MobileDealListContainer from './mobile/DealListContainer';
import MobilePartnerListContainer from './mobile/PartnerListContainer';
import MobileCSTownChecklistContainer from './mobile/CSTownChecklistContainer';
import MobileCSTownChecklistDetailContainer from './mobile/CSTownChecklistDetailContainer';

const MobileContainers = {
  MobileCstownContainer,
  MobileDealListContainer,
  MobileRootContainer,
  MobildMyInquiriesContainer,
  MobilePartnerListContainer,
  MobileCSTownChecklistContainer,
  MobileCSTownChecklistDetailContainer,
};
const PCContainers = {
  MyInquriesContainer,
  CstownDealsContainer,
  RootContainer,
  PartnerListContainer,
  ManagerListContainer,
  CSTownChecklistContainer,
  CSTownChecklistDetailContainer,
};

export { MobileContainers, PCContainers };
