import React, { useEffect, useState } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import MUIToggleGroup from 'components/commons/buttons/MUIToggleGroup';
import MUIToggle from 'components/commons/buttons/MUIToggle';
import * as DealLayoutButtons from 'features/management/resources/images/deal';
import { Layout } from 'types/BlockExtendsTypes';
import useSetDefaultValue from 'features/management/hooks/useDefaultValue';

type CommonProps = {
  initialValue?: string;
  useTitle?: boolean;
  onChange?: (value: string) => void;
};
const DealLayout = ({ initialValue, useTitle = true, onChange }: CommonProps) => {
  const [value, setValue] = useState<string>();

  useSetDefaultValue(setValue, initialValue);

  useEffect(() => {
    if (typeof value !== 'undefined' && onChange) onChange(value as Layout);
  }, [value]);

  return (
    <>
      <ItemSectionStyle>
        {useTitle && <ItemSectionTitle>레이아웃</ItemSectionTitle>}
        <ItemContent>
          <MUIToggleGroup
            value={value}
            onChange={(e: any, value: string) => {
              if (value !== null) {
                setValue(value);
              }
            }}
          >
            <MUIToggle value="TypeA">
              <DealLayoutButtons.DealLayoutA />
            </MUIToggle>
            <MUIToggle value="TypeB">
              <DealLayoutButtons.DealLayoutB />
            </MUIToggle>
            <MUIToggle value="TypeC">
              <DealLayoutButtons.DealLayoutC />
            </MUIToggle>
            <MUIToggle value="TypeD">
              <DealLayoutButtons.DealLayoutD />
            </MUIToggle>
          </MUIToggleGroup>
        </ItemContent>
      </ItemSectionStyle>
    </>
  );
};

export default DealLayout;
