import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { AdCategoryDto, PostTagDto } from 'services/data-contracts';
import { Row } from 'components/commons/layouts';
import { IconButton } from '@mui/material';
import { isMobile } from 'react-device-detect';

import styled from 'styled-components';
import StyledRefBox from 'components/commons/layouts/StyledRefBox';
import BottomSheetButtonContainer from 'components/commons/buttons/BottomSheetButtonContainer';
import BottomSheetButton from 'components/commons/buttons/BottomSheetButton';
import useNavigation from 'hooks/useNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import StickyHeader from 'components/commons/stickyHeader';
import HashTagContainer from './HashTagContainer';
import { usePageContext } from '../context/usePageContext';

const DealsHeader = observer(() => {
  const context = usePageContext();
  const { adCategoryStore, uiStore } = useStore();
  const { setCategory, setDisplayType, displayType, selectedCategory, selectedTags, setTags } =
    context;

  const currentScroll = useRef<number>(0);
  const headerRef = useRef<any>(null);
  const upStartingPosition = useRef<number>(0);
  const recommendTagRef = useRef<any>(null);
  const { navigate } = useNavigation();

  const handleClickCategory = (category: AdCategoryDto) => {
    setCategory(category);
  };

  const handleClickRecommentHash = () => {
    uiStore.bottomSheet.show({
      children: <TagBox />,
    });
  };

  const handleClickCloseRecommendTags = () => {
    uiStore.bottomSheet.close();
    const tags = recommendTagRef.current.getTags();
    if (tags.length > 0) {
      const t = tags.map((tag: PostTagDto) => tag.name).join('&tags=');
      navigate(`/?tags=${t}`);
    } else {
      setCategory(undefined);
      navigate(`/`);
    }
  };

  const handleClickHash = (hash: PostTagDto) => {
    navigate(`/?tags=${hash.name}`);
  };

  useEffect(() => {
    adCategoryStore.getAdCategories();

    const handleScroll = () => {
      if (currentScroll.current > window.scrollY) {
        if (upStartingPosition.current === 0) {
          upStartingPosition.current = window.scrollY;
        }
      } else {
        upStartingPosition.current = 0;
      }

      if (upStartingPosition.current > 20) {
        headerRef.current.style.top = `${60}px`;
      } else {
        headerRef.current.style.top = '-60px';
      }
      currentScroll.current = window.scrollY;
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const Filters = () => {
    return (
      <Row
        style={{ justifyContent: 'flex-end', display: 'inline-flex', width: 100, marginRight: 15 }}
      >
        <IconButton onClick={handleClickRecommentHash}>
          <img
            src={
              selectedTags && selectedTags.length > 0
                ? `./images/filter_on.svg`
                : `./images/filter.svg`
            }
            alt=""
          />
        </IconButton>
        <IconButton
          onClick={() => {
            setDisplayType(displayType === 'list' ? 'square' : 'list');
          }}
        >
          {displayType !== 'category' && (
            <>
              {displayType === 'list' ? (
                <img src="./images/main_view.svg" alt="" />
              ) : (
                <img src="./images/main_view_box.svg" alt="" />
              )}
            </>
          )}
        </IconButton>
      </Row>
    );
  };

  const TagBox = () => {
    return (
      <BottomSheetButtonContainer>
        <HashTagContainer ref={recommendTagRef} selectedHashs={selectedTags} />
        <BottomSheetButton type="apply" onClick={handleClickCloseRecommendTags}>
          적용
        </BottomSheetButton>
      </BottomSheetButtonContainer>
    );
  };

  return (
    <StickyHeader>
      <>
        <DealTypeStyle>
          <Swiper
            spaceBetween={20}
            slidesPerView={5.1}
            grabCursor
            enabled
            style={{ maxWidth: 500 }}
          >
            {adCategoryStore.adCategories?.map((category: AdCategoryDto, index: number) => (
              <SwiperSlide>
                <DealItemStyle
                  selected={category.id === selectedCategory?.id}
                  key={index}
                  onClick={() => {
                    handleClickCategory(category);
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      width: 20,
                    }}
                  >
                    <div style={{ minHeight: 25, display: 'flex', alignItems: 'center' }}>
                      <img src={category.file?.publicUrl} alt="" />
                    </div>
                    <div style={{ fontSize: 13 }}>{category.name}</div>
                  </div>
                </DealItemStyle>
              </SwiperSlide>
            ))}
          </Swiper>
        </DealTypeStyle>

        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderTop: '1px solid #efefef',
            }}
          >
            <XScrollStyle style={{ marginLeft: 20 }}>
              <Row style={{ justifyContent: 'flex-start', gap: 10 }}>
                {selectedTags?.map((hash: PostTagDto) => (
                  <div
                    style={{
                      scrollSnapAlign: 'start',
                      flexWrap: 'nowrap',
                      whiteSpace: 'nowrap',
                    }}
                    onClick={() => {
                      handleClickHash(hash);
                    }}
                    className="common-hash-tags"
                  >
                    #{hash.name}
                  </div>
                ))}
              </Row>
            </XScrollStyle>
            <Filters />
          </div>
        </>
      </>
    </StickyHeader>
  );
});

const HeaderStyle = styled(StyledRefBox)<{ visible?: boolean }>`
  top: 20px;
  position: sticky;
  width: 100%;
  top: 0px;
  background: #fff;
  transition: top 0.3s ease-in-out;
  z-index: 1;
`;

const XScrollStyle = styled.div`
  overflow-x: scroll;
  margin: auto;
  @media ${(props) => props.theme.media.mobile} {
    width: calc(100vw - 40px);
  }
`;

const DealTypeStyle = styled.div`
  height: 62px;
  width: 100%;
  max-width: 550px;
  display: flex;
  /* align-items: center; */
  gap: 55px;
  scroll-snap-align: start;
  width: 100vw;
  flex-wrap: nowrap;
  > div:first-child {
    margin-left: 32px;
  }
  > div:last-child {
    margin-right: 32px;
  }
`;

const DealItemStyle = styled.div<{ selected?: boolean }>`
  scroll-snap-align: start;
  flex-wrap: nowrap;
  white-space: nowrap;
  scroll-snap-align: start;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 0 20px;
  > div > img {
    margin-bottom: 3px;
  }
  border-bottom: ${(props) => (props.selected ? '4px solid #23BA50' : '4px solid transparent')};
`;

export default DealsHeader;
