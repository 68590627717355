import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { koKR } from '@mui/x-date-pickers';
import { ko } from 'date-fns/locale';
import { Row } from 'components/commons/layouts';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { InputAdornment } from '@mui/material';
import { validationHelper } from 'controls/validationHelper';

import TextField from '@mui/material/TextField';
import moment from 'moment';
import Popover from '@mui/material/Popover';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TimePicker = forwardRef(
  (
    {
      alldayEvent,
      initialValue,
      minTime,
      startDate,
      pickerType,
      onChange,
      onBlur,
    }: {
      alldayEvent?: boolean;
      initialValue?: string;
      startDate?: string;
      minTime?: string;
      pickerType: 'start' | 'end';
      onChange: (date?: string, hasError?: boolean) => void;
      onBlur: () => void;
    },
    ref,
  ) => {
    const tempTime = useRef<string | null>();
    const tempDate = useRef<string | null>();

    const [selectedDate, setSelectedDate] = useState<Date | string>();
    const [selectedTime, setSelectedTime] = useState<string>();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [formatError, setFormatError] = useState<boolean>(false);
    const [startDateTemp, setStartDateTemp] = useState<string>();
    const [timeIntervals, setTimeIntervals] = useState<string[]>();

    useImperativeHandle(ref, () => ({
      setDate,
    }));

    const setDate = (date: string) => {
      setSelectedDate(new Date(date));
      setSelectedTime(moment(date).format('HH:mm'));
    };

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleDateChange = (date: any) => {
      if (date === null) {
        setSelectedDate('');
        tempDate.current = undefined;
      } else {
        tempDate.current = moment(date).format('YYYY-MM-DD');
        setSelectedDate(date);
      }
      setNewTime();
    };

    const generateTimeIntervals = (min?: string): void => {
      const times: string[] = [];

      // 입력된 minTime을 시간과 분으로 분리
      const [minHours, minMinutes] = (min || minTime)?.split(':').map(Number) || [0, 0];

      // minTime 설정
      const minDate = new Date();
      minDate.setHours(minHours, minMinutes, 0, 0);

      const startTime = new Date();
      startTime.setHours(0, 0, 0, 0);
      const endTime = new Date();
      endTime.setHours(23, 30, 0, 0);

      for (
        let current = startTime;
        current <= endTime;
        current.setMinutes(current.getMinutes() + 30)
      ) {
        // current 시간이 minTime 이후일 경우에만 배열에 추가
        if (current >= minDate) {
          const hours = current.getHours();
          const minutes = current.getMinutes();
          const timeStr = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
          times.push(timeStr);
        }
      }

      setTimeIntervals(times);
    };

    // const  = generateTimeIntervals();

    const handleClickTimeItem = (time: string) => {
      handleClose();
      setSelectedTime(time);
      setNewTime();
    };

    /**
     * @description 반환은 utc를 사용하지 않는다.(최종 저장할때 상황에 맞춰 변환)
     */
    const setNewTime = () => {
      if (tempDate.current !== '' && tempTime.current !== '') {
        const newDate = moment(`${tempDate.current} ${tempTime.current}`).format(
          'YYYY-MM-DD HH:mm:ss',
        );
        onChange(newDate, formatError);
      } else {
        onChange(undefined, true);
      }
    };

    const handleChange = (event: any) => {
      let { value } = event.target;

      // 숫자와 콜론만 남기기
      value = value.replace(/[^0-9:]/g, '');

      // 길이가 2일 때 콜론 추가, 이미 콜론이 포함된 경우 추가하지 않음
      if (value.length === 2 && !value.includes(':')) {
        value += ':';
      }

      // 이미 콜론이 포함된 경우 그 뒤로 숫자만 유지
      if (value.length > 2 && value.indexOf(':') === 2) {
        value = `${value.slice(0, 2)}:${value.slice(3, 5).replace(/[^0-9]/g, '')}`;
      }

      // 정규식 검사
      const timeRange = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
      if (timeRange.test(value) || value === '' || value.length <= 5) {
        setSelectedTime(value);
        tempTime.current = value;
      }
    };

    useEffect(() => {
      if (alldayEvent === true) {
        tempTime.current = selectedTime;
        if (pickerType === 'start') {
          setSelectedTime('00:00');
        } else {
          setSelectedTime('23:59');
        }
        setSelectedDate(moment(initialValue).toDate());
      } else if (typeof initialValue === 'undefined') {
        if (pickerType === 'start') {
          setSelectedTime('08:00');
          generateTimeIntervals('00:00');
        } else {
          setSelectedTime('08:30');
          generateTimeIntervals('08:30');
        }
        setSelectedDate(moment(initialValue).toDate());
        tempDate.current = moment(initialValue).format('YYYY-MM-DD');
      } else if (typeof initialValue !== 'undefined') {
        setSelectedDate(moment(initialValue).toDate());
        setSelectedTime(moment(initialValue).format('HH:mm'));
      }
    }, [alldayEvent, initialValue]);

    useEffect(() => {
      if (selectedDate) {
        tempDate.current = moment(selectedDate).format('YYYY-MM-DD');
      }

      if (selectedTime) {
        const timeValid = validationHelper.timeRange(selectedTime);
        if (timeValid) {
          tempTime.current = selectedTime;
        }
      }
    }, [selectedTime, selectedDate]);

    useEffect(() => {
      if (pickerType === 'end') {
        setStartDateTemp(startDate);
      }
    }, [startDate]);

    useEffect(() => {
      if (minTime) {
        generateTimeIntervals();
      }
    }, [minTime]);

    return (
      <>
        <Row>
          <div style={{ flex: 0.6 }}>
            <LocalizationProvider adapterLocale={ko} dateAdapter={AdapterDateFns}>
              <DatePicker
                format="yyyy-MM-dd"
                disabled={alldayEvent}
                shouldDisableDate={(day) => {
                  if (pickerType === 'end') {
                    return moment(day).isBefore(startDateTemp, 'day');
                  }
                  return false;
                }}
                disablePast={false}
                localeText={koKR.components.MuiLocalizationProvider.defaultProps.localeText}
                sx={{ '&.MuiFormControl-root': { flex: 1 } }}
                onChange={handleDateChange}
                value={selectedDate}
                defaultValue={moment(initialValue).toDate()}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </LocalizationProvider>
          </div>
          <div style={{ margin: '0 10px', flex: 0.4 }}>
            <input type="text" name="username" style={{ display: 'none' }} autoComplete="off" />
            <TextField
              error={formatError}
              disabled={alldayEvent}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    style={{ cursor: 'pointer' }}
                    position="end"
                    onClick={(e) => {
                      handleClick(e);
                    }}
                  >
                    <ExpandMoreIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                autoComplete: 'off',
                onBlur: (e: any) => {
                  let timeValid = false;
                  let temp;
                  if (e.target.value.indexOf(':') > -1) {
                    timeValid = validationHelper.timeRange(e.target.value);
                    if (timeValid) handleClickTimeItem(e.target.value);
                  } else {
                    temp = `${e.target.value.substring(0, 2)}:${e.target.value.substring(2)}`;
                    timeValid = validationHelper.timeRange(e.target.value);
                    if (timeValid) handleClickTimeItem(temp);
                  }
                  if (!timeValid) {
                    onChange(undefined, !timeValid);
                  }
                  setFormatError(!timeValid);
                },
              }}
              onChange={(e: any) => {
                // handleChange(e);
                // setSelectedTime(e.target.value);
                // setNewTime();
              }}
              value={selectedTime}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              style={{ height: 200, width: 120 }}
              sx={{
                '& .MuiPaper-root': {
                  transition: 'none !important',
                  boxShadow: 'unset',
                  // marginTop: '28px !important',
                },
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <div style={{ border: '1px solid #efefef' }}>
                {timeIntervals?.map((option) => (
                  <TimeItemStyle
                    onClick={() => {
                      tempTime.current = option;
                      handleClickTimeItem(option);
                    }}
                    style={{ width: 200 }}
                  >
                    {option}
                  </TimeItemStyle>
                ))}
              </div>
            </Popover>
          </div>
        </Row>
      </>
    );
  },
);

const TimeItemStyle = styled.div`
  padding: 10px;
  &:hover {
    background: #efefef;
  }
`;

export default TimePicker;
