import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import owebviewPostMessage from 'libs/webview/owebviewPostMessage';

const isOWebView = navigator.userAgent.includes('OWebView');

export default function useNavigation() {
  const domNavigate = useNavigate();

  const navigate = useCallback((path: string) => {
    if (isOWebView) {
      owebviewPostMessage('NAVIGATION_UPDATE_PATH', path);
    } else {
      domNavigate(path);
    }
  }, []);

  const replace = useCallback((path: string) => {
    if (isOWebView) {
      owebviewPostMessage('NAVIGATION_REPLACE_PATH', path);
    } else {
      domNavigate(path);
    }
  }, []);

  const reset = useCallback((path?: string) => {
    if (isOWebView) {
      owebviewPostMessage('NAVIGATION_RESET_PATH', path);
    } else {
      domNavigate(path ?? '/');
    }
  }, []);

  const pop = useCallback((count?: number) => {
    if (isOWebView) {
      owebviewPostMessage('NAVIGATION_GOBACK', count?.toString());
    } else {
      domNavigate(-(count ?? 1));
    }
  }, []);

  return { navigate, replace, reset, pop };
}
