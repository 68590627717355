import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';

import { Row } from './layouts';
import { useStore } from 'stores/StoreHelper';
import { IconButton } from '@mui/material';
import { QRContainer } from 'containers/adminContainers';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { toast } from 'react-toastify';
import BottomSheetItem from './BottomSheetItem';

const ShareBox = ({ subject, url }: { subject?: string; url: string }) => {
  const SIZE = 55;
  const { channelStore, uiStore } = useStore();
  const { t } = useTranslation();

  const handleClickGenerateQR = () => {
    uiStore.modal.update({
      title: 'Share',
      children: <QRContainer url={url} />,
    });
  };

  const handleClickCopy = () => {
    navigator.clipboard.writeText(url);
    toast(t('A253'));
  };

  const handleClickMore = async () => {
    if (window.isWebView) {
      window?.ReactNativeWebView?.postMessage(JSON.stringify({ action: 'share', message: url }));
    } else if (navigator.share) {
      try {
        const shareDetails = {
          label: 'Share',
          title: subject,
          text: url,
        };
        await navigator
          .share(shareDetails)
          .then(() => console.log('Hooray! Your content was shared to tha world'));
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      console.log(
        'Web share is currently not supported on this browser. Please provide a callback',
      );
    }
  };
  return (
    <div style={{ padding: '0', paddingBottom: 50, margin: 10 }}>
      {/* <Row style={{ justifyContent: 'space-evenly' }}>
        <FacebookShareButton url={url}>
          <IconButton>
            <FacebookIcon round size={SIZE} />
          </IconButton>
        </FacebookShareButton>
        <LinkedinShareButton title={subject} url={url}>
          <IconButton>
            <LinkedinIcon round size={SIZE} />
          </IconButton>
        </LinkedinShareButton>
        <TwitterShareButton title={subject} url={url}>
          <IconButton>
            <TwitterIcon round size={SIZE} />
          </IconButton>
        </TwitterShareButton>
        <EmailShareButton subject={`${channelStore.channel?.channelName} ${t('A250')}`} url={url}>
          <IconButton>
            <EmailIcon round size={SIZE} />
          </IconButton>
        </EmailShareButton>
      </Row> */}
      <Row direction="column" style={{ padding: 20 }}>
        <div style={{ marginBottom: 30 }}>
          <QRContainer url={url} />
        </div>
        <BottomSheetItem onClick={handleClickCopy} label={t('A251')}>
          <InsertLinkIcon style={{ color: '#fff' }} />
        </BottomSheetItem>

        <BottomSheetItem onClick={handleClickMore} label={t('A252')}>
          <MoreHorizIcon style={{ color: '#fff' }} />
        </BottomSheetItem>
      </Row>
    </div>
  );
};

const ShareMoreStyle = styled(Row)`
  margin-bottom: 20px;
`;
export default ShareBox;
