import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { ReactComponent as Left } from 'resources/images/sticker/left_c.svg';
import { ReactComponent as Right } from 'resources/images/sticker/right_c.svg';

const StickerTypeC = ({ text, style }: { text: string; style: CSSProperties }) => {
  return (
    <ContainerStyle className="sticker-item">
      <div>
        <Left style={{ fill: style?.fill, stroke: style?.stroke }} />
      </div>
      <TextStyle text={text} style={{ ...style }}>
        {typeof text === 'undefined' || text === 'StickerTypeC' ? 'sample' : text}
      </TextStyle>
      <div>
        <Right style={{ fill: style?.fill, stroke: style?.stroke }} />
      </div>
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  display: flex;
`;

const TextStyle = styled.div<{ text: string | undefined }>`
  display: flex;
  align-items: center;
  font-size: 11px;
  border-top: 1;
  border-bottom: 1;
  border-color: #05c755;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  margin: 0.5px 0;
  padding: ${(props) =>
    // typeof props.text === 'undefined' || props.text === 'StickerTypeC' ? '0 28px' : '0 8px'
    '0 8px'};
  color: #05c755;
`;

export default StickerTypeC;
