import React, { CSSProperties } from 'react';
import { Add as AdIconWrapper } from '@mui/icons-material';

import { IconButton } from '@mui/material';

const AddIcon = ({ style, onClick }: { style?: CSSProperties; onClick: () => void }) => {
  return (
    <IconButton onClick={onClick} style={style}>
      <AdIconWrapper style={{ width: 25, height: 25 }} />
    </IconButton>
  );
};

export default AddIcon;
