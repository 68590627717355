import React, { useState, useEffect, useRef } from 'react';
import {
  CustomFile,
  defaultLinkEditorProps,
  SizeInfo,
  VideoLinkModel,
  LinkModel,
} from 'types/CommonTypes';
import { validationHelper as VH } from 'controls/validationHelper';
import { getYoutubeUrl } from 'libs/helper/utils';
import { useTranslation } from 'react-i18next';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { initLinkItemDto, updateLink, updateModel } from './commonLogic';

import styled from 'styled-components';
import VideoEditor from 'features/adminFeatures/commons/components/VideoEditor';
import LinkEditorContainerStyle from 'resources/controls/LinkEditorConatainerStyle';
import SaveButtonWrapper from './SaveButtonWrapper';
import TitleForm from './forms/TitleForm';
import UrlForm from './forms/UrlForm';

const YouTubeEditor = ({
  model,
  stores,
  onRegistComplete,
}: defaultLinkEditorProps<VideoLinkModel>) => {
  const { t } = useTranslation();

  /** @description iFrame용 prefix */
  const youtubePrefix = 'https://www.youtube.com/embed/';

  /** @description 유튜브 주소용 prefix */

  const [youtubeUrl, setYoutubeUrl] = useState<string>();
  const [videoType, setVideoType] = useState<'upload' | 'external' | undefined>();
  const [videoSource, setVideoSource] = useState<CustomFile | undefined>();

  const [linkModel, setLinkModel] = useState<VideoLinkModel>();
  const [isValid, setIsValid] = useState<boolean>(false);

  const handleClickSave = async () => {
    if (typeof linkModel !== 'undefined' && typeof onRegistComplete === 'function') {
      const result = await updateLink(linkModel, typeof linkModel.id !== 'undefined', stores);
      onRegistComplete(true, result);
    }
  };

  const handleChangeValue = (
    key: 'title' | 'linkUrl' | 'thumbnail' | 'metadataObject',
    value: unknown,
  ) => {
    // checkValidation(key === 'thumbnail' ? value : undefined, linkModel?.customObject?.videoType);

    setLinkModel((prev: LinkModel) => {
      return { ...prev, [key]: value };
    });
    // return;
    // if (linkModel?.customObject?.videoType === 'external') {
    //   if (
    //     typeof urlRef.current?.value !== 'undefined' &&
    //     urlRef.current?.value.indexOf('youtube.com/watch') > 0
    //   ) {
    //     const result = VH.required(titleRef.current?.value) && VH.url(urlRef.current?.value);
    //     setIsValid(result);
    //   } else {
    //     setIsValid(false);
    //   }
    // } else {
    //   const result = VH.required(titleRef.current?.value) && typeof value !== 'undefined';
    //   setIsValid(result);
    // }
  };

  const checkValidation = (thumbnail?: any, type?: 'external' | 'upload' | undefined) => {
    const t = thumbnail || linkModel?.thumbnail;
    if (type === 'external') {
      if (typeof linkModel?.linkUrl !== 'undefined' && typeof youtubeUrl !== 'undefined') {
        const result = VH.required(linkModel?.title) && VH.url(linkModel?.linkUrl);
        setIsValid(result);
      } else {
        setIsValid(false);
      }
    } else {
      setTimeout(() => {
        const result = VH.required(linkModel?.title) && typeof t !== 'undefined';
        setIsValid(result);
      }, 100);
    }
  };

  useEffect(() => {
    if (linkModel) {
      checkValidation(linkModel.thumbnail, linkModel.metadataObject?.videoType);
    }
  }, [linkModel]);

  useEffect(() => {
    if (typeof model?.id === 'undefined') {
      setLinkModel(initLinkItemDto('Video'));
    } else {
      setLinkModel(model);
      // const result = VH.required(model.title) && VH.url(model.linkUrl);
      // setIsValid(result);
      checkValidation(model.thumbnail, model.metadataObject?.videoType);
    }
  }, [model]);

  /** Primitives - end */

  const handleOnUrlPaste = (value: string) => {
    if (value.indexOf('youtube.com/watch') > 0) {
      setYoutubeUrl(youtubePrefix + getYoutubeUrl(value));
    } else if (value.indexOf('youtu.be') > 0) {
      setYoutubeUrl(youtubePrefix + getYoutubeUrl(value));
    }
  };

  /** @description value를 바꿀 대상이므로 반대로 선언하여야 함. */
  const handleChangeMode = (value: 'external' | 'upload') => {
    let shouldCheck = false;
    const text =
      value === 'external'
        ? '업로드할 동영상이 취소됩니다.\r\n 모드를 바꾸시겠습니까?'
        : '동영상 주소가 삭제됩니다.\r\n 모드를 바꾸시겠습니까?';
    if (value === 'external') {
      shouldCheck = typeof linkModel?.thumbnail !== 'undefined';
    } else {
      shouldCheck = VH.required(linkModel?.linkUrl);
    }
    if (shouldCheck) {
      stores.uiStore.confirm.show({
        message: text,
        onConfirmed: () => {
          // setIsValid(false);
          if (value === 'external') {
            setVideoSource(undefined);

            setLinkModel((prev: VideoLinkModel) => {
              return {
                ...prev,
                thumbnail: undefined,
                metadataObject: { ...prev.metadataObject, videoType: value },
              };
            });
          } else {
            setLinkModel((prev: VideoLinkModel) => {
              return {
                ...prev,
                linkUrl: '',
                metadataObject: { ...prev.metadataObject, videoType: value },
              };
            });
          }
        },
      });
    } else {
      setLinkModel((prev: VideoLinkModel) => {
        return {
          ...prev,
          metadataObject: { ...prev.metadataObject, videoType: value },
        };
      });
    }
    checkValidation(
      undefined,
      linkModel?.metadataObject?.videoType === 'external' ? 'upload' : 'external',
    );
  };

  useEffect(() => {
    if (typeof model?.id !== 'undefined') {
      if (typeof model.metadata !== 'undefined') {
        model.metadataObject = JSON.parse(model.metadata);
        // setSizeInfo(model.customObject?.videoSize);
        setVideoType(model?.metadataObject?.videoType);
      }
      setLinkModel(model);

      if (model.metadataObject?.videoType === 'external') {
        handleOnUrlPaste(model?.linkUrl as string);
        setYoutubeUrl(youtubePrefix + getYoutubeUrl(model.linkUrl as string));
      } else if (typeof model.thumbnail !== 'undefined') {
        setVideoSource(model.thumbnail);
      }
    } else {
      setYoutubeUrl(undefined);
      setVideoType('external');
      setLinkModel((prev: LinkModel) => {
        return { ...prev, linkType: 'Youtube', metadataObject: { videoType: 'external' } };
      });

      setVideoSource(undefined);
    }
  }, []);

  useEffect(() => {
    if (typeof videoType !== 'undefined') {
      setLinkModel((prev: LinkModel) => {
        return { ...prev, ...linkModel?.metadataObject, videoType };
      });
    }
  }, [videoType]);

  return (
    <LinkEditorContainerStyle>
      <SaveButtonWrapper
        isValid={isValid}
        onSave={handleClickSave}
        isNew={typeof linkModel?.id === 'undefined'}
      >
        <TitleForm
          model={linkModel?.title}
          onChangeValue={(value: string) => {
            handleChangeValue('title', value);
          }}
        />

        <div style={{ marginBottom: 20 }}>
          <ToggleButtonGroup
            color="primary"
            value={linkModel?.metadataObject?.videoType}
            exclusive
            onChange={(e: any, value: any) => {
              if (value === null) {
                return;
              }
              handleChangeMode(value);
            }}
            aria-label="Platform"
          >
            <ToggleButton
              sx={{
                '&.MuiToggleButton-root': {
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                  padding: '0 20px',
                  height: 40,
                },
                '&.Mui-selected': {
                  color: '#11C668',
                  background: '#F8FFFB',
                },
              }}
              value="external"
            >
              Youtube
            </ToggleButton>
            <ToggleButton
              value="upload"
              sx={{
                '&.MuiToggleButton-root': {
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  padding: '0 20px',
                  height: 40,
                },
                '&.Mui-selected': {
                  color: '#11C668',
                  background: '#F8FFFB',
                },
              }}
            >
              Upload
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        {linkModel?.metadataObject?.videoType === 'upload' ? (
          <>
            <VideoEditorStyle>
              <VideoEditor
                // isNew={typeof model.id === 'undefined'}
                mode="edit"
                video={videoSource}
                videoSettings={linkModel.metadataObject.videoSize}
                isCover={linkModel.metadataObject?.isCover}
                onAutoplayChange={(autoplay: boolean) => {
                  handleChangeValue('metadataObject', {
                    ...linkModel?.metadataObject,
                    autoplay,
                  });
                }}
                onObjectFitChange={(isCover: boolean) => {
                  handleChangeValue('metadataObject', {
                    ...linkModel?.metadataObject,
                    isCover,
                  });
                }}
                onSettingComplete={(videoSize: SizeInfo) => {
                  handleChangeValue('metadataObject', {
                    ...linkModel?.metadataObject,
                    videoSize,
                  });
                  updateModel(setLinkModel, 'metadataObject', {
                    ...linkModel?.metadataObject,
                    videoSize,
                  });
                }}
                // maxHeight="500px"
                onVideoChange={(video: any) => {
                  handleChangeValue('thumbnail', video[0]);
                }}
              />
            </VideoEditorStyle>
          </>
        ) : (
          <>
            <YoutubeContainerStyle>
              <YoutubeVideoStyle>
                <iframe
                  className="youtube-frame"
                  style={{ aspectRatio: '16 / 9' }}
                  // src={youtubePrefix + youtubeUrl}
                  src={youtubeUrl}
                  title="YouTube video player"
                  // eslint-disable-next-line max-len
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media;fullscreen; gyroscope; picture-in-picture"
                />
              </YoutubeVideoStyle>
              {typeof youtubeUrl === 'undefined' && (
                <NoSourceStyle className="absolute-center">{t('A155')}</NoSourceStyle>
              )}
            </YoutubeContainerStyle>
            <div style={{ flex: 1, marginRight: 20 }}>
              <UrlForm
                usePrefix={false}
                label="Youtube Url"
                model={linkModel?.linkUrl}
                onChangeValue={(value: string) => {
                  handleChangeValue('linkUrl', value);
                  handleOnUrlPaste(value);
                }}
                onPaste={(value: string) => {
                  setVideoType('external');
                  handleOnUrlPaste(value);
                }}
              />
            </div>
          </>
        )}
      </SaveButtonWrapper>
    </LinkEditorContainerStyle>
  );
};

const YoutubeContainerStyle = styled.div`
  background: #000;
  border-radius: 6px;
  position: relative;
  margin-bottom: 20px;
`;

const VideoEditorStyle = styled.div`
  margin: 0 0 10px 0;
`;

const YoutubeVideoStyle = styled.div`
  border-radius: 10px;
  text-align: center;
  word-wrap: break-word;
  min-height: 50px;
  justify-content: space-between;
  display: flex;
  position: relative;
  box-shadow: 0px 0px 7px -1px #c8c8c8;
  overflow: hidden;
`;

const NoSourceStyle = styled.div`
  position: absolute;
  color: #fff;
  width: 300px;
  margin: auto;
  text-align: center;
`;

export default YouTubeEditor;
