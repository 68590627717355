import React, { useEffect, useRef, useState } from 'react';
import { ImageUploader } from 'components/commons';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import TitleForm from 'components/items/forms/TitleForm';
import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { CustomFile } from 'types/CommonTypes';
import { Align, IContactBlock, Layout } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { validationHelper } from 'controls/validationHelper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { LinkTypes } from 'features/management/context/providers/ContextTypes';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import BlockController from 'controllers/BlockController';
import CommonAlign from '../commonControl/CommonAlign';
import CommonLayout from '../commonControl/CommonLayout';
import LinkButtonLayout from '../commonControl/LinkButtonLayout';
import TelLinkLayout from '../commonControl/TelLinkLayout';
import ActionButton from './ActionButton';

const TelLink = ({ blockModel }: { blockModel?: IContactBlock }) => {
  const { uiStore } = useStore();
  const contactRef = useRef<any>();

  const defaultProps: Partial<IContactBlock> = {
    linkType: 'TelLink',
    contactType: 'tel',
    isActive: true,
  };

  const { initialModel, serviceModel, initialBinding, updateServiceModel, unmountModel } =
    useOhmossContext<LinkTypes<IContactBlock>>();

  const { updateBlock } = BlockController<IContactBlock>();

  const [localModel, setLocalModel] = useInitializeModel<Partial<IContactBlock>>(
    blockModel,
    defaultProps,
    initialBinding,
  );
  const [isValid, setIsValid] = useState<boolean>(false);

  // const initializeModel = () => {
  //   if (blockModel) {
  //     initialBinding(blockModel);
  //   } else {
  //     setMultipleState({
  //       linkType: 'TelLink',
  //       contactType: 'tel',
  //       isActive: true,
  //     });
  //   }
  // };

  useEffect(() => {
    if (localModel) {
      const titleValid = validationHelper.required(localModel.title);
      const urlValid =
        localModel?.contactType === 'email'
          ? validationHelper.email(localModel.phoneNumber)
          : validationHelper.tel(localModel.phoneNumber);

      setIsValid(titleValid && urlValid);
    }
  }, [localModel]);

  useEffect(() => {
    return () => {
      unmountModel();
    };
  }, []);

  return (
    <div style={{}}>
      <div style={{ marginBottom: 20 }}>
        <ImageUploader
          type="single"
          useCircularCrop={false}
          initialValue={initialModel?.thumbnail}
          attachType="image"
          onSelected={(image: CustomFile) => {
            updateServiceModel('thumbnail', image);
          }}
        />
      </div>
      <ItemSectionStyle>
        <ToggleButtonGroup
          fullWidth
          value={localModel?.contactType}
          exclusive
          onChange={(e: any, value: string) => {
            if (value === null) {
              return;
            }
            updateServiceModel('contactType', value as IContactBlock['contactType']);
            setLocalModel('contactType', value as IContactBlock['contactType']);
            contactRef.current.validate(value);
          }}
        >
          <ToggleButton value="tel" aria-label="left aligned">
            <div>전화번호</div>
          </ToggleButton>
          <ToggleButton value="email" aria-label="centered">
            <div>이메일</div>
          </ToggleButton>
        </ToggleButtonGroup>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>타이틀</ItemSectionTitle>
        <ItemContent>
          <TitleForm
            onChangeValue={(value: string) => {
              updateServiceModel('title', value);
              setLocalModel('title', value);
            }}
            initialValue={initialModel?.title}
            placeHolder={`제목을 작성해 주세요.`}
            label=""
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>
          {localModel?.contactType === 'tel' ? '전화번호' : '이메일'}
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            ref={contactRef}
            onChangeValue={(value: string) => {
              updateServiceModel('phoneNumber', value);
              setLocalModel('phoneNumber', value);
            }}
            validationType={localModel?.contactType}
            initialValue={initialModel?.phoneNumber}
            errorText={
              localModel?.contactType === 'tel'
                ? '전화번호 형식이 맞지 않습니다.'
                : '이메일형식이 맞지 않습니다.'
            }
            placeHolder={`${
              localModel?.contactType === 'tel' ? '전화번호를' : '이메일을'
            }작성해 주세요.`}
            label=""
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <MenuItemCheckBox
          onChangeValue={(value: boolean) => {
            updateServiceModel('useTransparent', value);
          }}
          initialValue={initialModel?.useTransparent}
          title="투명배경"
        />
        {/* 
        <MenuItemCheckBox
          initialValue={initialModel?.openNewWindow}
          onChangeValue={(value: boolean) => {
            updateServiceModel('openNewWindow', value);
          }}
          title="클릭해"
        /> */}
      </ItemSectionStyle>
      {/* <CommonLayout
        initialValue={initialModel?.layout || 'bottom'}
        onChange={(layout: Layout) => updateServiceModel('layout', layout)}
      /> */}
      {/* 
      <TelLinkLayout
        initialValue={initialModel?.layout || 'TypeA'}
        onChange={(layout: Layout) => updateServiceModel('layout', layout)}
      /> */}
      <CommonAlign
        initialValue={initialModel?.contentAlign || 'center'}
        onChange={(align: Align) => updateServiceModel('contentAlign', align)}
      />
      <ActionButton isValid={isValid} serviceModel={serviceModel} />
    </div>
  );
};

export default TelLink;
