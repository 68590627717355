import React from 'react';
import { ChannelBoardDto, PostDto } from 'services/data-contracts';

import FreeBoardType from './FreeBoardType';
import AdBoardType from './AdBoardType';
import FeedBoardType from './FeedBoardType';
import PinterestBoardType from './PinterestBoardType';

const BoardTemplaes = {
  NONE: ({
    posts,
    currentBoard,
  }: {
    posts: Array<PostDto>;
    currentBoard: ChannelBoardDto | null;
  }) => <FreeBoardType posts={posts} currentBoard={currentBoard} />,
  AD: ({
    posts,
    currentBoard,
  }: {
    posts: Array<PostDto>;
    currentBoard: ChannelBoardDto | null;
  }) => <AdBoardType posts={posts} currentBoard={currentBoard} />,
  GRID: ({
    posts,
    currentBoard,
  }: {
    posts: Array<PostDto>;
    currentBoard: ChannelBoardDto | null;
  }) => <PinterestBoardType posts={posts} currentBoard={currentBoard} />,
  MASONRY: ({
    posts,
    currentBoard,
  }: {
    posts: Array<PostDto>;
    currentBoard: ChannelBoardDto | null;
  }) => <PinterestBoardType posts={posts} currentBoard={currentBoard} />,
};

export default BoardTemplaes;
