/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { GraySNS, ISNS, OriginSNS } from 'resources/images/sns';
import ImageSearchOutlined from '@mui/icons-material/ImageSearchOutlined';

const SNSItem = ({
  name,
  useOrigin,
  iconType,
  color,
  width,
}: {
  name?: string;
  useOrigin?: boolean;
  iconType?: 'color' | 'solid';
  color?: string;
  width?: number;
}) => {
  let Icon;
  if (useOrigin || iconType === 'color') {
    Icon = OriginSNS.find((sns: ISNS) => sns.name === name);
  } else {
    Icon = GraySNS.find((sns: ISNS) => sns.name === name);
  }
  if (Icon) {
    return <Icon.component style={{ width: width || 40, fill: color }} />;
  }

  return <ImageSearchOutlined />;
};
export default SNSItem;
