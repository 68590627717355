import React, { useEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { ChannelBoardDto } from 'services/data-contracts';

import styled from 'styled-components';
import Avatar from 'components/commons/Avatar';
import Footer from 'components/items/Footer';
import NameCard from './NameCard';

import TemplateCommon from './TemplateCommon';
import PageLoader from 'components/commons/PageLoader';

const MobileSimpleTemplate = observer(
  ({ onSelectBoard }: { onSelectBoard: (board: ChannelBoardDto) => void }) => {
    const { channelStore, uiStores } = useStore();
    const { profileStore, userHomeStore } = uiStores;

    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
      if (channelStore.channelBoards.length > 0) {
        onSelectBoard(channelStore.channelBoards[0]);
      }
      setTimeout(() => {
        setLoaded(true);
      }, 500);
    }, [channelStore.channelBoards]);

    return (
      <StackStyle>
        {userHomeStore.currentBoard?.useProfile !== false ? (
          <ProfileWrapStyle
            isMine={channelStore.channel?.isOwner}
            style={{ marginTop: window.isWebView ? 30 : 130 }}
          >
            <Avatar size="medium" profile={profileStore.profile} />
          </ProfileWrapStyle>
        ) : (
          <div style={{ marginTop: 80 }}></div>
        )}
        <ItemContainerStyle>
          <NameCard profileInfo={profileStore.profile} type={'simple'} />
          <TemplateCommon />
        </ItemContainerStyle>

        <Footer model={profileStore.profile} />
        <PageLoader />
      </StackStyle>
    );
  },
);

const StackStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`;

const ProfileWrapStyle = styled.div<{ isMine?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  max-width: 550px;
  margin: auto;
  margin-top: 100px;
  transition: all 0.3s ease-out;

  @media ${(props) => props.theme.media.mobile} {
    transition: all 0.3s ease-out;
  }

  justify-content: center;
`;

const ItemContainerStyle = styled.div`
  padding-bottom: 50px;
  flex: 3;
  @media ${(props) => props.theme.media.mobile} {
    margin-top: 10px;
    padding: 0px 10px;
    padding-bottom: 30px;
    margin-top: 25px;
  }
  position: relative;
`;

export default MobileSimpleTemplate;
