import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DealServiceItemModel } from 'types/CommonTypes';
import { Row } from 'components/commons/layouts';
import { validationHelper } from 'controls/validationHelper';
import { DealItemContainerStyle, DealItemServiceIconStyle } from './styles';
import { LinkItemDto } from 'services/data-contracts';
import MUISwitch from 'components/commons/buttons/MUISwitch';
import Text from 'components/commons/Text';

const DealServiceItems = ({
  items,
  readonly = false,
  parentBorderRadius,
  mode,
  onUpdate,
}: {
  items?: LinkItemDto[];
  readonly?: boolean;
  parentBorderRadius?: number;
  mode?: 'VIEW' | 'EDIT';
  onUpdate?: (type?: string, value?: { items?: LinkItemDto[] }) => void;
}) => {
  const [serviceItems, setServiceItems] = useState<LinkItemDto[]>();

  const getContent = (content?: string) => {
    const tel = validationHelper.tel(content);
    const email = validationHelper.email(content);
    if (tel) {
      return <a href={`tel:${content}`}>{content}</a>;
    } else if (email) {
      return <a href={`mailto:${content}`}>{content}</a>;
    }
    return content;
  };

  const updateItems = (item: LinkItemDto) => {
    const newItems = serviceItems?.map((serviceItem) => {
      if (serviceItem.seq === item.seq) {
        return item;
      }
      return serviceItem;
    });
    onUpdate?.('', { items: newItems });
    setServiceItems(newItems);
  };

  useEffect(() => {
    if (items) {
      setServiceItems(items);
    }
  }, [items]);

  return (
    <ContainerStyle round={parentBorderRadius}>
      <>
        {serviceItems?.map((serviceItem: DealServiceItemModel) => (
          <DealItemContainerStyle>
            <Row>
              <DealItemServiceIconStyle style={{ fontSize: 20 }}>
                {serviceItem?.icon}
              </DealItemServiceIconStyle>
              <div style={{ marginRight: 20 }}>
                <Text type="H4">{serviceItem?.title}</Text>
                <div style={{ padding: '0' }}>{getContent(serviceItem?.content)}</div>
              </div>
              <>
                {mode !== 'VIEW' && (
                  <div>
                    <MUISwitch
                      disabled={readonly}
                      checked={serviceItem?.isActive}
                      onChange={(e: any, value: boolean) => {
                        serviceItem.isActive = value;
                        updateItems(serviceItem);
                      }}
                    />
                  </div>
                )}
              </>
            </Row>
          </DealItemContainerStyle>
        ))}
      </>
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div<{ round?: number }>`
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 10px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export default DealServiceItems;
