import React from 'react';
import MenuItem from 'components/commons/menuItem/MenuItem';
import MenuItemBox from 'components/commons/menuItem/MenuItemBox';
import TextLabel from 'components/commons/labels/TextLabel';
import useNavigation from 'hooks/useNavigation';
import { useGlobalContext } from 'context/useGlobalContext';
import useLogout from 'hooks/useLogout';
import SettingProvider from '../context/settings/SettingProvider';

declare const FB: any; // Declare FB globally

const BizMemberMenus = () => {
  const { navigate } = useNavigation();
  const { withDrawal } = SettingProvider();
  const logout = useLogout();

  const handleClickAuth = async () => {
    FB.getLoginStatus((response: any) => {
      // statusChangeCallback(response);
      console.log(response);
    });

    // https://localhost:3000/v2/social-auth/instagram?code=AQAt3DSZAVWsRVpPNlp1J979e5CLIAg0GARmjILoW6VkrUIGcaO4tQzcrhmx3iZ1O6L5ciTGqfxU10O0eFhCa5WEZ7Bp1oC7gdLIwanMLnnPUV5GWFyZHkBMHoEX_1Y5-F7JNksLUc11nCcy8Dams8oivcc_03V542gcc5BCJsICTEzUUVU715nyRAvVNtoXdMXR6gErK8apEmctCwGzLfXsUmBJAi8IG12R-tOVLGSXDXIOwfQK5QDxpIv6d3wk_oqwe9-hvCtZFqjGp10nZxQVs7QieSzBuD-fdGdn35sLIkIKPOD3r6U-YUaokT8y4YOyqWHrTc2hn7drWk0yZB-jnrmYaIfitFcYhMCJXOCP-2aNge5LTKJddz9C6zpxBjg#_=_
    // const response = await login({
    //   scope: 'email',
    // });

    // console.log('fb', response);
  };

  return (
    <>
      <MenuItemBox title="인플루언서">
        <MenuItem
          title="내 광고관리"
          onClick={() => {
            navigate('/settings/myad');
          }}
        />
        <MenuItem
          title="내 대시보드 보기"
          onClick={() => {
            navigate('/settings/dashboard');
          }}
        />
        <MenuItem
          title="내 모스코인"
          onClick={() => {
            navigate('/settings/mosscoin');
          }}
        />
        <MenuItem
          title="공구 관리"
          onClick={() => {
            navigate('/settings/userinfo');
          }}
        />
        <MenuItem title="인플루언서 인증">
          {/* <FacebookProvider appId="360396142998604"> */}
          <div onClick={handleClickAuth}>
            <TextLabel type="content">인증완료</TextLabel>
            <a href="https://www.facebook.com/v18.0/dialog/oauth?response_type=code&display=popup&client_id=360396142998604&redirect_uri=https://localhost:3000/my/auth/instagram&scope=public_profile%2Cinstagram_basic%2Cemail">
              Login to Facebook
            </a>
          </div>
          {/* </FacebookProvider> */}
        </MenuItem>
      </MenuItemBox>
      <MenuItemBox title="회원">
        <MenuItem
          title="계정 설정"
          onClick={() => {
            navigate('/settings/userinfo');
          }}
        />
        {/* <MenuItem
          title="알림설정"
          onClick={() => {
            navigate('/settings/notification');
          }}
        /> */}
      </MenuItemBox>
      <MenuItemBox title="서비스 정보">
        <MenuItem
          title="서비스 이용 약관"
          onClick={() => {
            navigate('/deals/userterms');
          }}
        />
        <MenuItem
          title="개인정보 수집 및 취급 동의"
          onClick={() => {
            navigate('/deals/privacypolicy');
          }}
        />
      </MenuItemBox>
      <MenuItemBox title="기타">
        <MenuItem title="앱 버전" menuType="contents">
          <TextLabel type="content">{process.env.VERSION}</TextLabel>
        </MenuItem>
        <MenuItem title="고객센터">
          <TextLabel type="content">
            <a href="mailto:cs@nbhd.kr">cs@nbhd.kr</a>
          </TextLabel>
        </MenuItem>
      </MenuItemBox>
      <MenuItemBox title={` `}>
        <MenuItem title="로그아웃" onClick={logout} menuType="action" />
        <MenuItem
          title="탈퇴하기"
          onClick={() => {
            withDrawal();
          }}
          menuType="danger"
        />
      </MenuItemBox>
    </>
  );
};

export default BizMemberMenus;
