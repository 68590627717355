import { useStore } from 'stores/StoreHelper';
import { useState } from 'react';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import { ChannelBoardDto, LinkDto, PostDto, PostItemDto } from 'services/data-contracts';
import { extractAdditionalProperties } from 'libs/hooks/useModelUpdate';
import { CustomFile, IChannelBoardModel, IPostModel } from 'types/BlockExtendsTypes';
import { postDtoDummy } from 'features/management/context/dummy/PostDtoDummy';
import { PostItemDtoDummy } from 'features/management/context/dummy/PostItemDtoDummy';

import FileStore from 'stores/FileStore';
import MyPostStore from 'stores/MyPostStore';
import useLocalParams from 'libs/hooks/useLocalParams';
import PostStore from 'stores/PostStore';
import MyBoardStore from 'stores/MyBoardStore';
import ChannelBoardStore from 'stores/ChannelBoardStore';
import { useRootContext } from 'libs/hooks/useRootContext';

/**
 * @description 각 Block의 등록/수정/삭제를 수행함.
 * @returns
 */
const MyShopController = () => {
  const { xhrStore } = useStore();
  const { categoryId } = useLocalParams();
  const { currentChannel } = useChannelContext();
  const { myChannel } = useRootContext();

  const fileStore = new FileStore();
  const postStore = new PostStore();
  const myPostStore = new MyPostStore();
  const myBoardStore = new MyBoardStore();

  const [posts, setPosts] = useState<PostDto[]>([]);
  const [links, setLinks] = useState<LinkDto[]>();

  const getShopBoard = async (categoryId: number) => {
    let categoryBoard: IChannelBoardModel | undefined;

    if (myChannel?.id) {
      categoryBoard = (await myBoardStore.getBoardById(
        myChannel?.id as number,
        categoryId,
      )) as unknown as IChannelBoardModel;
    } else {
      // categoryBoard = (await ca.getBoardById(
      //   currentChannel?.id as number,
      //   categoryId,
      // )) as unknown as IChannelBoardModel;
    }
    if (categoryBoard) {
      const metadata = JSON.parse(categoryBoard.metadata || '{}');
      Object.assign(categoryBoard, metadata);
    }
    return categoryBoard;
  };

  const getPosts = async (boardId: number) => {
    xhrStore.setState('pending');
    setPosts([]);
    if (boardId) {
      if (myChannel) {
        const posts = (await myPostStore.getPosts(myChannel?.id as number, boardId as number, {
          ownChannelId: myChannel?.id || '',
          size: 30,
        })) as never as PostDto[];
        xhrStore.setState('done');
        return posts;
      }
    }
  };

  const uploadImage = async (thumbnail: CustomFile) => {
    return fileStore.uploadImage(thumbnail?.blob, 'Link');
  };

  const reOrderPostItems = async (categoryId: number, item: PostDto) => {
    myPostStore.reOrderPostItem(myChannel?.id as number, categoryId, item);
  };

  const updateShopItem = async (serviceModel: PostDto) => {
    xhrStore.setState('pending');
    const newModel = { ...serviceModel };
    const targetCategoryId = !Number.isNaN(categoryId)
      ? categoryId
      : (serviceModel.board?.id as number);

    try {
      if (typeof newModel === 'undefined') {
        return;
      }
      const extracted = extractAdditionalProperties<PostDto, PostDto>(newModel, postDtoDummy);
      if (newModel.items && newModel.items.length > 0) {
        await preProcess(newModel.items);
      }
      if ((newModel?.thumbnail as CustomFile)?.blob) {
        const file = (await uploadImage(newModel?.thumbnail as CustomFile)) as CustomFile;
        newModel.thumbnail = { id: file.id };
      }

      if (typeof extracted !== 'undefined') {
        newModel.metadata = JSON.stringify(extracted);
      }
      if (newModel.id) {
        const result = await myPostStore.updatePost(
          myChannel?.id as number,
          targetCategoryId as number,
          newModel?.id as number,
          newModel,
        );
        xhrStore.setState('done');
        return result;
      } else {
        if (!newModel.items) {
          newModel.items = [];
        }

        const result = await myPostStore.createPost(
          myChannel?.id as number,
          targetCategoryId as number,
          newModel,
        );
        xhrStore.setState('done');

        return result;
      }
    } catch (e) {
      console.log(e);
      xhrStore.setState('error');
    }
  };

  const preProcess = async (items?: PostItemDto[]) => {
    return Promise.all(
      (await items?.map(async (item: PostItemDto, index: number) => {
        const file = item?.file as CustomFile;
        if (file?.blob?.constructor.name === 'File') {
          const result = (await fileStore.uploadImage(
            (item.file as CustomFile).blob,
            'Post',
          )) as CustomFile;
          item.file = { id: result.id };
        }
        item.isActive = typeof item?.isActive === 'undefined' ? false : item.isActive;
        const extracted = extractAdditionalProperties(item, PostItemDtoDummy);
        if (typeof extracted !== 'undefined') {
          item.metadata = JSON.stringify(extracted);
        }
        return item;
      })) as unknown as PostItemDto[],
    );
  };

  const getOthersPosts = async (boardId: number, isInfinity: boolean) => {
    let posts: IPostModel | undefined;
    xhrStore.setState('pending');
    setPosts([]);
    if (boardId) {
      posts = (await postStore.getPosts(
        currentChannel?.channelName as string,
        boardId as number,
        isInfinity as boolean,
        { ownChannelId: myChannel?.id || '' },
      )) as never as IPostModel;
      xhrStore.setState('done');
      return posts;
    }
  };

  const getOthersPost = async (boardId: number, postId: number) => {
    xhrStore.setState('pending');
    setPosts([]);
    if (boardId) {
      const post = (await postStore.getPost(
        currentChannel?.channelName as string,
        boardId as number,
        postId as number,
        myChannel?.id,
      )) as never as PostDto;
      xhrStore.setState('done');
      return post;
    }
  };

  const deletePost = async (boardId: number, postId: number) => {
    xhrStore.setState('pending');
    if (boardId) {
      const result = (await myPostStore.deletePost(
        myChannel?.id as number,
        boardId as number,
        postId as number,
      )) as unknown as PostDto;
      xhrStore.setState('done');
      return result;
    }
  };

  return {
    getPosts,
    updateShopItem,
    getShopBoard,
    getOthersPosts,
    getOthersPost,
    deletePost,
    reOrderPostItems,
  };
};

export default MyShopController;
