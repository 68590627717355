/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { LinkDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class MyLinkService2<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 링크정보
   *
   * @tags MyLinkService2
   * @name GetLink
   * @summary 링크정보
   * @request GET:/v2/me/links/{linkKey}
   * @secure
   */
  getLink = (linkKey: string, params: RequestParams = {}) =>
    this.http.request<LinkDto, any>({
      path: `/v2/me/links/${linkKey}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
