import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { ChannelDto } from 'services/data-contracts';
import { toast } from 'react-toastify';
import useNavigation from 'hooks/useNavigation';

import styled from 'styled-components';
import CRUDHelper from 'libs/helper/crudHelper';
import useChannelSelector from 'libs/hooks/useChannelSelector';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useChat } from 'libs/hooks/useChat';
import chatController from 'features/community/controllers/chatController';
import ChannelComponent from '../components/ChannelComponent';

const MyChannelList = observer(
  ({
    type,
    onSelected,
    onClick,
    isCstown = false,
  }: {
    type: 'channel' | 'admin' | 'setting' | 'my';
    isCstown?: boolean;
    onSelected?: (isSelected: boolean) => void;
    onClick?: () => void;
  }) => {
    const { navigate } = useNavigation();
    const { setChannel, myChannel } = useRootContext();
    const { initChat } = useChat();
    const { getUser } = chatController();
    const { uiStore, postStore, myChannelStore } = useStore();
    const { saveChannel } = useChannelSelector();

    const [mode, setMode] = useState<'new' | 'view' | 'modify'>('view');
    const [model, setModel] = useState<ChannelDto>();

    const crudHelper = new CRUDHelper(myChannelStore, model);

    const handleClickEdit = (channel: ChannelDto) => {
      setModel(channel);
      setMode('modify');
    };

    const isCstownUser = (channel?: ChannelDto) => {
      if (!isCstown) {
        return true;
      }
      if (channel?.channelType === 'SELLER' && channel?.membershipType !== 'FREE') {
        return true;
      } else if (channel?.channelType === 'BRAND') {
        return true;
      } else {
        return false;
      }
    };

    const handleClickSwitchChannel = async (channel: ChannelDto) => {
      const user = await getUser(channel.channelName as string);

      // user가 생성되어 있는경우만 채팅 준비를 한다.
      if (user) {
        await initChat(channel?.channelName as string);
      }

      saveChannel(channel);
      setChannel(channel);

      uiStore.bottomSheet.close();

      if (onClick) {
        onClick();
      } else if (type === 'admin') {
        window.history.replaceState(
          'force',
          `${channel.channelName}`,
          `/${channel.channelName}/admin/links`,
        );
        toast(`${channel.channelName}으로 전환되었습니다.`);
      } else if (type === 'my') {
        navigate(`/my`);
      } else {
        navigate(`/${channel.channelName}`);
      }

      postStore.unMount();
    };

    const handleClickDelete = (channel: ChannelDto) => {
      uiStore.confirm.show({
        message: '선택하신 채널을 삭제하시겠습니까? 해당 채널과 관련된 모든 정보가 삭제됩니다.',
        onConfirmed: async () => {
          await crudHelper.delete(channel.id as number);
          const channels = (await crudHelper.getItems()) as unknown as Array<ChannelDto>;
          if (channels.length > 0) handleClickSwitchChannel(channels[0]);
        },
      });
    };
    useEffect(() => {
      myChannelStore.getItems();
    }, []);

    return (
      <MyChannelsContainerStyle>
        {mode === 'view' ? (
          <>
            <ChannelsListContainerStyle>
              <>
                {myChannelStore.myChannels?.map((myChannelItem: ChannelDto, index: number) => (
                  <>
                    {isCstownUser(myChannelItem as ChannelDto) && (
                      <ChannelComponent
                        canDelete={myChannelStore.myChannels?.length > 1}
                        mode={type === 'channel' ? 'view' : 'edit'}
                        channel={myChannelItem}
                        selected={myChannelItem?.id === myChannel?.id}
                        isOwner
                        onSelected={handleClickSwitchChannel}
                        onEdit={handleClickEdit}
                        onDelete={handleClickDelete}
                      />
                    )}
                  </>
                ))}
              </>
            </ChannelsListContainerStyle>
            {myChannelStore.myChannels?.length < 2 && (
              <div style={{ marginTop: 20 }}>
                <OhmossButton
                  style={{ width: '100%', height: 48 }}
                  onClick={() => {
                    uiStore.bottomSheet.close();
                    navigate(`/${myChannel?.channelName}/newlinkchannel`);
                  }}
                >
                  <span>새 채널</span>
                </OhmossButton>
              </div>
            )}
          </>
        ) : (
          <></>
        )}

        {window.isWebView && <div style={{ height: 50 }} />}
      </MyChannelsContainerStyle>
    );
  },
);

const MyChannelsContainerStyle = styled.div`
  margin: 0 auto 20px auto;
  justify-content: center;
  width: 100%;
`;

const ChannelsListContainerStyle = styled.div`
  left: 20px;
  right: 20px;
  /* top: 60px; */
  bottom: 0px;
  overflow: scroll;
  @media ${(props) => props.theme.media.mobile} {
    /* height: 280px; */
  }
`;

const MyChannelContainerStyle = styled.div<{ selected?: boolean }>`
  border-radius: 15px;
  cursor: pointer;
  margin: 0 auto 10px auto;
  padding: 10px;
  justify-content: center;
  background-color: #fcfcfc;
  border: ${(props) => (props.selected ? '2px solid #0BBD58' : '2px solid #fff')};
`;

const NewChannelButtonStyle = styled.div`
  position: absolute;
  text-align: center;
  bottom: 10px;
  left: 0;
  right: 0;
`;
export default MyChannelList;
