import React, { useState } from 'react';
import {
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  ListItemText,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import UrlForm from 'components/items/forms/UrlForm';
import { LinkDto } from 'services/data-contracts';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Step2 = ({ links }: { links?: Array<LinkDto> }) => {
  const [linkType, setLinkType] = useState<string>('internal');
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleLinkTypeChange = (e: any, value: string) => {
    setLinkType(value);
  };

  const handleChangeTag = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div style={{ marginTop: 30 }}>
      <div style={{ marginTop: 30 }}>
        <ToggleButtonGroup
          color="primary"
          value={linkType}
          exclusive
          onChange={handleLinkTypeChange}
          aria-label="Platform"
        >
          <ToggleButton value="internal">내부링크</ToggleButton>
          <ToggleButton value="external">외부링크</ToggleButton>
        </ToggleButtonGroup>
        <div style={{ marginTop: 20 }}>
          {linkType === 'internal' ? (
            <div>
              <FormControl sx={{ width: 300, marginLeft: 0, marginBottom: 2.9 }}>
                <InputLabel id="demo-multiple-checkbox-label">Links</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={personName}
                  onChange={handleChangeTag}
                  input={<OutlinedInput label="Links" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {links?.map((link) => (
                    <MenuItem key={link.id} value={link.title}>
                      <ListItemText primary={link.title} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div>내부링크는 생성한 링크의 타입이 "페이지"인 경우만 가능합니다.</div>
            </div>
          ) : (
            <div style={{}}>
              <UrlForm label="Link url" onChangeValue={() => {}} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Step2;
