import React from 'react';

import { OhmossButton } from 'resources/controls/OhmossButton';
import { t } from 'i18next';

const SaveButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
      <OhmossButton
        style={{ width: '50%', padding: '20px 20px' }}
        onClick={() => {
          onClick();
        }}
      >
        {t('Save')}
      </OhmossButton>
    </div>
  );
};

export default SaveButton;
