/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from 'react';
import { ISNS } from 'resources/images/sns';
import { getBrightnessType } from 'libs/helper/utils';
import styled from 'styled-components';

const SNSIcon = ({ Component, checked, fill, onSelected }: { Component: ISNS, checked?: boolean; fill?: string; onSelected: (component: ISNS) => void }) => {
  const [brightnessType, setBrightnessType] = useState<'dark' | 'bright' | 'normal'>();

  useEffect(() => {
    if (fill) {
      const bType = getBrightnessType(fill);
      setBrightnessType(bType)
    }
  }, [fill])

  return (
    // <ComponentStyle style={{ width: 70, height: 70, margin: 10, background: brightnessType === 'bright' ? '#efefef' : '#fff' }} onClick={() => { onSelected(Component) }}>
    <ComponentStyle style={{ width: 70, height: 70, margin: 10, background: checked ? '#efefef' : '#fff' }} onClick={() => { onSelected(Component) }}>
      <Component.component style={{ width: 30, fill }} />
    </ComponentStyle>

  )
}


const ComponentStyle = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  &:hover{
    background: #efefef;
  }
`
export default SNSIcon;
