import React from 'react';
import { observer } from 'mobx-react';
import MobileModalHeader from 'components/commons/MobileModalHeader';
import UniversalLayout from 'components/commons/layouts/UniversalLayout';
import VerifyInstagramContainer from 'features/myPage/containers/VerifyInstagramContainer';
import DetailPageHeader from 'components/commons/DetailPageHeader';

const UserModifyPage = observer(() => {
  return (
    <UniversalLayout>
      {/* <DetailPageHeader title="회원정보 수정" /> */}
      <VerifyInstagramContainer />
    </UniversalLayout>
  );
});

export default UserModifyPage;
