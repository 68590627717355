import { RoutePropInterface } from 'types/CommonTypes';
import * as Pages from 'pages';
import AdPostDetailPage from 'features/mossMain/pages/AdPostDetailPage';
import NewLinkPage from 'features/linkService/pages/NewLinkPage';

const dealsRoutes: RoutePropInterface[] = [
  // {
  //   path: '',
  //   title: 'landing',
  //   isPublic: true,
  //   element: Pages.LandingPage,
  // },
  // {
  //   path: '/deals/settings',
  //   title: '채널 없음',
  //   isPublic: true,
  //   element: Pages.MainSettingPage,
  // },
  {
    path: '/deals/settings/myad',
    title: '내 광고관리',
    element: Pages.SettingsMyAdPage,
  },
  {
    path: '/deals/settings/myad/detail/:channelid/:boardid/:postid',
    title: '내 광고관리',
    element: AdPostDetailPage,
  },
  {
    path: '/mypost/detail/:channelname/:boardid/:postid',
    title: '포스트 상세',
    isPublic: true,
    element: Pages.PostDetailPage,
  },
  {
    path: '/deals/settings/myad/detail/:adid',
    title: '내 광고관리',
    element: Pages.AdDetailPage,
  },
  {
    path: '/deals/settings/myad/newdeal',
    title: '채널 없음',
    element: Pages.NewDealPage,
  },
  {
    path: '/deals/settings/myad/newdeal/:postid',
    title: '채널 없음',
    element: Pages.NewDealPage,
  },
  {
    path: '/deals/settings/myad/deals/:adid',
    title: '딜정보',
    element: Pages.DealInfo,
  },
  {
    path: '/deals/posts/:postid/modify',
    title: '딜포스트 내용 수정',
    element: Pages.NewPostPage,
  },
  {
    path: '/deals/new',
    title: '새 게시물',
    element: Pages.NewADPostPage,
  },
  {
    path: '/post/:boardid/new',
    title: '새 게시물',
    element: Pages.NewPostPage,
  },
  {
    path: '/link/:boardid/new',
    title: '새 게시물',
    element: NewLinkPage,
  },
  {
    path: '/settings/myad/:postid/modify',
    title: '게시물 편집',
    element: Pages.NewPostPage,
  },
  {
    path: '/deals/agreement',
    title: '광고 이동 동의',
    isPublic: true,
    element: Pages.Agreement,
  },
  {
    path: '/deals/settings/dashboard',
    title: '대시보드',
    element: Pages.DashBoard,
  },
  {
    path: '/deals/settings/mosscoin',
    title: '모스코인',
    element: Pages.MossCoin,
  },
  {
    path: '/deals/settings/userinfo',
    title: '회원정보',
    element: Pages.UserModifyPage,
  },
  {
    path: '/deals/settings/notification',
    title: '알림설정',
    element: Pages.NotificationPage,
  },
  {
    path: '/deals/userterms',
    title: '이용약관',
    isPublic: true,
    element: Pages.Terms,
  },
  {
    path: '/deals/privacypolicy',
    title: '개인정보 수집 및 동의',
    isPublic: true,
    element: Pages.Privacy,
  },
  {
    path: '/',
    title: '오모스 소개 페이지',
    element: Pages.DealsPage,
    //  element: Pages.OhmossIntroducePage,
    isPublic: true,
  },
  {
    path: '/deals/:adId',
    title: 'ohmoss',
    isPublic: true,
    element: Pages.AdDetailPage,
  },
  {
    path: '/deals',
    title: '오모스 소개 페이지',
    element: Pages.DealsPage,
    isPublic: true,
  },
];

export default dealsRoutes;
