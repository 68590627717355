import React from 'react';
import { SocialLinkDto } from 'services/data-contracts';
import { observer } from 'mobx-react';
import { SocialProps } from 'types/CommonTypes';
import { SNS, ISNS } from 'resources/images/sns';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const SocialComponent = observer(
  ({ socials, socialProps }: { socials?: Array<SocialLinkDto>; socialProps?: SocialProps }) => {
    const handleClickSNS = (social: SocialLinkDto) => {
      window.open(social.linkUrl, '_blank');
    };

    const SNSIcon = ({ type }: { type?: string }) => {
      const Temp = SNS.find((s: ISNS) => `${s.name}`.toLowerCase() === type?.toLowerCase());
      let size = 10;
      if (typeof Temp !== 'undefined') {
        switch (socialProps?.size) {
          case 'small':
            size = 20;
            break;
          case 'medium':
            size = 30;
            break;
          case 'large':
            size = 40;
            break;
          default:
            size = 30;
            break;
        }
        // eslint-disable-next-line react/jsx-pascal-case
        return (
          <Temp.component
            style={{ stroke: socialProps?.color || '#000', width: isMobile ? size : size + 5 }}
          />
        );
      } else {
        return <></>;
      }
    };

    return (
      <>
        {typeof socials !== 'undefined' && (
          <SocialContainerStyle>
            {socials?.map((social: SocialLinkDto) => (
              <SocialWrapStyle key={social?.id}>
                <SocialStyle
                  onClick={() => {
                    handleClickSNS(social);
                  }}
                >
                  <SNSIcon type={social.socialType} />
                  {/* <SocialImageStyle
                    src={`/image/sns/${getIcon(social)}`}
                    alt=""
                    size={props?.size}
                  /> */}
                </SocialStyle>
              </SocialWrapStyle>
            ))}
          </SocialContainerStyle>
        )}
      </>
    );
  },
);

const SocialContainerStyle = styled.div`
  margin: 10px 10px 10px 10px;
  justify-content: center;
  flex-direction: row;
  display: flex;
  border-radius: 30px;
  padding: 0 10px 0 10px;
  position: relative;
`;

const SocialWrapStyle = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 10px;
  cursor: pointer;
`;

const SocialStyle = styled.div`
  /* display: table-cell; */
  padding: 2px;
  display: flex;
  vertical-align: middle;
  text-align: center;
`;

export default SocialComponent;
