import { makeObservable, observable, flow, action } from 'mobx';
import { MyCoin as MyCoinService } from 'services/MyCoin';
import { AdDto, CoinEventDto } from 'services/data-contracts';

import _ from 'lodash';
import { HttpClient } from 'services/http-client';
import i18n from 'i18n';
import ServiceStoreBase from './ServiceStoreBase';

export interface isValid {
  isValid: boolean;
}

class MyCoinStore extends ServiceStoreBase {
  t = i18n.t;

  coinEvents: CoinEventDto[];

  constructor() {
    super();
    makeObservable(this, {
      getConinEvents: flow,
      coinEvents: observable,
    });
  }

  api: MyCoinService<unknown> = new MyCoinService(new HttpClient());

  *getConinEvents(channelId: number, query?: any) {
    const { response } = yield this.api.getCoinEvents(channelId, query);
    if (response.status === 200) {
      this.coinEvents = response.data;

      return response.data;
    } else {
      this.coinEvents = [];
      return [];
    }
  }
}

export default MyCoinStore;
