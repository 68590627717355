import { makeObservable, flow, action, observable } from 'mobx';
import { HttpClient } from 'services/http-client';
import { DealListDto, PartnerDto, SimpleDealDto } from 'services/data-contracts';
import _ from 'lodash';
import i18n from 'i18n';
import { PartnerService } from 'services/PartnerService';
import { toast } from 'react-toastify';
import ServiceStoreBase from './ServiceStoreBase';

/**
 * @description Public Link Store
 */
class PartnerStore extends ServiceStoreBase {
  t = i18n.t;

  constructor() {
    super();
    makeObservable(this, {
      myPartners: observable,
      myPartnerDeals: observable,
      getPartners1: flow,
      getPartnerDeals1: flow,
      updatePartner: flow,
      assignDeals: flow,
      deletePartner: flow,
      getPartnerMembers: flow,
      getDealsByMember: flow,
      unsignDeal: flow,
      assignDeal: flow,
    });
  }

  api: PartnerService<unknown> = new PartnerService(new HttpClient());

  myPartners: Array<PartnerDto[]> = [];

  myPartnerDeals: Array<SimpleDealDto[]> = [];

  *getPartners1(channelId: number, dealId?: number) {
    const { response } = yield this.api.getPartners1(channelId, { dealId });
    if (response.status === 200) {
      this.myPartners = response.data;
      return response.data;
    } else {
      return [];
    }
  }

  *getPartnerDeals1(channelId: number, partnerId: number) {
    const { response } = yield this.api.getPartnerDeals1(channelId, partnerId);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  }

  *unsignDeal(channelId: number, partnerId: number, dealId: number) {
    const { response } = yield this.api.unassignDeal(channelId, partnerId, dealId);

    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  }

  *updatePartner(channelId: number, partnerId: number, data: PartnerDto) {
    const { response } = yield this.api.updatePartner(channelId, partnerId, data);
    if (response.status === 200) {
      toast(this.t('정보가 수정되었습니다.'));
    } else {
      toast.error(this.t('셀러정보 수정에 실패했습니다.'));
    }
    return response;
  }

  *assignDeals(channelId: number, partnerId: number, data: DealListDto) {
    const { response } = yield this.api.assignDeals(channelId, partnerId, data);
    if (response.status === 200) {
      toast(this.t('파트너 공구 위임 목록이 수정되었습니다.'));
    } else {
      toast.error(this.t('파트너 공구 위임 목록 수정에 실패했습니다.'));
    }
    return response;
  }

  *assignDeal(channelId: number, partnerId: number, dealId: number) {
    const { response } = yield this.api.assignDeal(channelId, partnerId, dealId);
    if (response.status === 200) {
      toast(this.t('파트너 공구 위임 목록이 수정되었습니다.'));
    } else {
      toast.error(this.t('파트너 공구 위임 목록 수정에 실패했습니다.'));
    }
    return response;
  }

  *deletePartner(channelId: number, partnerId: number) {
    const { response } = yield this.api.deletePartner(channelId, partnerId);
    if (response.status === 200) {
      toast(this.t('파트너 삭제가 완료되었습니다.'));
    } else {
      toast.error(this.t('파트너 삭제에 실패했습니다.'));
    }
    return response;
  }

  *getPartnerMembers(channelId: number) {
    const { response } = yield this.api.getPartnerMembers(channelId);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  }

  *getDealsByMember(channelId: number, memberId: number) {
    const { response } = yield this.api.getDealsByMember(channelId, memberId);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  }
}

export default PartnerStore;
