import React, { CSSProperties, forwardRef, useEffect } from 'react';

const StyledRefBox = forwardRef(
  (
    {
      className,
      style,
      contentEditable,
      children,
      onInput,
      onClick,
    }: {
      className?: string;
      style?: CSSProperties;
      contentEditable?: any;
      children: JSX.Element | JSX.Element[];
      onInput?: (value: string) => void;
      onClick?: () => void;
    },
    forwardRef: any,
  ) => {
    return (
      <div
        className={className}
        style={style}
        onInput={(e: any) => {
          if (onInput) {
            onInput(e.target.innerHTML);
          }
        }
        }
        contentEditable={contentEditable || false}
        ref={forwardRef}
        onClick={() => {
          if (typeof onClick === 'function') {
            onClick();
          }
        }}
      >
        {children}
      </div >
    );
  },
);

export default StyledRefBox;
