import { useState, useEffect } from 'react';

function useSetDefaultValue<T>(
  setValue: (value: T) => void,
  initialValue?: T,
  defaultValue?: T,
): void {
  useEffect(() => {
    if (typeof initialValue !== 'undefined') {
      setValue(initialValue);
    } else if (typeof defaultValue !== 'undefined') {
      setValue(defaultValue);
    }
  }, [setValue, initialValue, defaultValue]);
}

export default useSetDefaultValue;
