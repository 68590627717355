import React, { CSSProperties, useEffect, useState } from 'react';
import { StickerTypes } from './StickerTypes';
import StickerItems from 'resources/images/sticker';
import CustomizedStickers from './CustomizedStickers';

interface StickerContainerProps {
  style?: CSSProperties;
  stickers?: StickerTypes[];
  useModify?: boolean;
  onUpdate?: (
    type?: 'STICKER_POSITION' | 'STICKERS',
    value?: { stickerPosition?: boolean; stickers?: StickerTypes[] },
  ) => void;
}
const StickerBox = ({ style, stickers, useModify, onUpdate }: StickerContainerProps) => {
  const [selectedStickers, setSelectedStickers] = useState<StickerTypes[]>([]);
  useEffect(() => {
    if (stickers) {
      const data = stickers.map((sticker) => {
        const parsedStyle =
          sticker.style !== undefined && sticker.style !== '' ? JSON.parse(sticker.style) : {};
        const stickerType = (sticker.stickerType ?? 'TypeA') as keyof typeof StickerItems;
        const componentBox = StickerItems[stickerType];
        if (componentBox) {
          return {
            ...sticker,
            component: componentBox.component,
            internalStyle: parsedStyle,
          };
        } else {
          return {};
        }
      });
      setSelectedStickers(data);
    }
  }, [stickers]);

  return (
    <>
      {selectedStickers && selectedStickers.length > 0 && (
        <>
          <div style={{ ...style, display: 'flex' }}>
            <CustomizedStickers mode="view" selectedStickers={selectedStickers} />
          </div>
        </>
      )}
    </>
  );
};

export default StickerBox;
