import React, { useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { isMobile } from 'react-device-detect';
import { IconButton } from '@mui/material';
import { Row } from 'components/commons/layouts';

import styled from 'styled-components';
import Avatar from 'components/commons/Avatar';
import MyChannelList from 'features/commonFeatures/containers/MyChannelList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AdminMobileAvatar = observer(() => {
  const { uiStore, myChannelStore } = useStore();
  const [clickProfile, setClickProfile] = useState<boolean>(false);

  const handleClickShowModal = () => {
    uiStore.universalModal.show({
      title: 'My Channels',
      children: <MyChannelList type="admin" />,
      modalOption: { style: { maxWidth: '650px', height: '350px' } },
      bottomSheetOptions: { style: { padding: 20 } },
    });
  };

  return (
    <>
      <ProfileContainerStyle mobileMenu={isMobile}>
        <Row>
          <AvatarStyle
            isMobile={isMobile}
            clickProfile={clickProfile}
            onClick={() => {
              handleClickShowModal();
              setClickProfile(!clickProfile);
            }}
          >
            <Avatar size="tiny" profile={myChannelStore.currentChannel?.profile} />
          </AvatarStyle>
          <Row>
            <NameStyle>{myChannelStore?.currentChannel?.channelName}</NameStyle>
            <IconButton
              onClick={() => {
                handleClickShowModal();
                setClickProfile(!clickProfile);
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Row>
        </Row>
      </ProfileContainerStyle>
    </>
  );
});

const ProfileContainerStyle = styled.div<{ mobileMenu?: boolean }>`
  display: flex;
  justify-content: space-between;
`;

const AvatarStyle = styled.div<{ isMobile?: boolean; clickProfile?: boolean }>`
  justify-content: center;
  text-align: center;
  position: relative;
  margin-left: 10px;
`;

const NameStyle = styled.div`
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-left: 5px;
`;

export default AdminMobileAvatar;
