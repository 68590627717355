import StatusBlockedButtons from './StatusBlockedButtons';

import StatusCancledButtons from './StatusCancledButtons';

import StatusExpiredButtons from './StatusExpiredButtons';

import StatusOngoingButtons from './StatusOngoingButtons';

import StatusWaitingButtons from './StatusWaitingButtons';

import StatusActiveButtons from './StatusActiveButtons';

import StatusInActiveButtons from './StatusInActiveButtons';

const componentMap = {
  BLOCKED: StatusBlockedButtons,
  CANCELED: StatusCancledButtons,
  EXPIRED: StatusExpiredButtons,
  ONGOING: StatusOngoingButtons,
  WAITING: StatusWaitingButtons,
  ACTIVE: StatusActiveButtons,
  INACTIVE: StatusInActiveButtons,
};

export default componentMap;
