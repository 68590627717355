import React, { useState, useEffect } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import MUIToggleGroup from 'components/commons/buttons/MUIToggleGroup';
import MUIToggle from 'components/commons/buttons/MUIToggle';
import { AlignCenter, AlignLeft, AlignRight, Left } from 'features/management/resources/images';
import { Align } from 'types/BlockExtendsTypes';
import useSetDefaultValue from 'features/management/hooks/useDefaultValue';

type CommonProps = {
  title?: string;
  useTitle?: boolean;
  initialValue?: string;
  onChange?: (value: string) => void;
};
const CommonAlign = ({ title, initialValue, useTitle = true, onChange }: CommonProps) => {
  const [value, setValue] = useState<string>();
  useSetDefaultValue(setValue, initialValue, 'center');

  useEffect(() => {
    if (typeof value !== 'undefined' && onChange) onChange(value as Align);
  }, [value]);

  return (
    <>
      <ItemSectionStyle>
        {useTitle && <ItemSectionTitle>정렬</ItemSectionTitle>}
        <ItemContent>
          <MUIToggleGroup
            value={value}
            onChange={(e: any, value: string) => {
              setValue(value);
            }}
          >
            <MUIToggle value="left">
              <AlignLeft />
            </MUIToggle>
            <MUIToggle value="center">
              <AlignCenter />
            </MUIToggle>
            <MUIToggle value="right">
              <AlignRight />
            </MUIToggle>
          </MUIToggleGroup>
        </ItemContent>
      </ItemSectionStyle>
    </>
  );
};

export default CommonAlign;
