import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { ReactComponent as Triangle } from 'resources/images/sticker/triangle_f.svg';

const StickerTypeF = ({ text, style }: { text: string; style: CSSProperties }) => {
  return (
    <ContainerWrapper>
      <ContainerStyle text={text} style={{ ...style }}>
        <div style={{ color: style?.color }} className="sticker-item">
          {typeof text === 'undefined' || text === 'StickerTypeF' ? 'sample' : text}
        </div>
      </ContainerStyle>
      <div>
        <Triangle
          style={{
            fill: style?.fill,
            stroke: style?.stroke,
            // zIndex: 2,
            position: 'relative',
            bottom: 2,
          }}
        />
      </div>
    </ContainerWrapper>
  );
};

const ContainerStyle = styled.div<{ text: string | undefined }>`
  display: flex;
  height: 25px;
  border-radius: 5px;
  border: 1px solid #05c755;
  padding: 0 10px 0 10px;
  align-items: center;
  justify-content: center;
  padding: ${(props) =>
    // typeof props.text === 'undefined' || props.text === 'StickerTypeF' ? '0 28px' : '0 8px'
    '0 8px'};
  /* z-index: 1; */
`;

const ContainerWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  flex-direction: column;
  font-size: 11px;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  color: #05c755;
`;

export default StickerTypeF;
