import Text from 'components/commons/Text';
import TitleForm from 'components/items/forms/TitleForm';
import ChecklistController from 'controllers/ChecklistController';
import { validationHelper } from 'controls/validationHelper';
// eslint-disable-next-line max-len
import CSTownChecklistController from 'features/csTown/pages/pageControllers/CSTownChecklistController';
import DealList from 'features/myPage/containers/DealList';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import { useStateContext } from 'libs/hooks/usePageState';
import { useRootContext } from 'libs/hooks/useRootContext';
import useSetState from 'libs/hooks/useSetState';
import React, { useEffect, useState } from 'react';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { ChannelChecklistCreationDto, LinkDto } from 'services/data-contracts';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CSTownChecklistState } from 'types/CommonTypes';

import { ListBoxItemTemplate } from 'features/csTown/commonComponents/styles';
import DealInfo from '../DealInfo';
import { useStore } from 'stores/StoreHelper';
import useNavigation from 'hooks/useNavigation';

function ChecklistCategorySelector({ categoryProps }: { categoryProps: any }) {
  const { getDeals, unmountDeals } = CSTownChecklistController();
  const { uiStore } = useStore();
  const { navigate } = useNavigation();
  const { myChannel } = useRootContext();
  const { stateModel, broadcast } = useStateContext<CSTownChecklistState>();
  const { updateServiceModel, serviceModel } = useModelManagement<ChannelChecklistCreationDto>();
  const { createMyChecklist } = ChecklistController();

  const [localModel, setLocalModel] = useSetState<ChannelChecklistCreationDto>();
  const [swiper, setSwiper] = useState<any>(null);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [targetDeal, setTargetDeal] = useState<LinkDto>();

  const handleClickNext = (step: number) => {
    swiper?.slideTo(step);
  };

  const handleClickDeal = (deal?: LinkDto) => {
    setTargetDeal(deal);
    updateServiceModel('dealId', deal?.id as number);
    setLocalModel('dealId', deal?.id);
  };

  const handleClickCreateCheckList = async () => {
    if (serviceModel) {
      const result = await createMyChecklist(myChannel?.id as number, serviceModel);
      if (result) {
        broadcast({ id: 'reload' });
        uiStore.bottomSheet.close();
        navigate(`/cstown/checklist/detail?category=${result.category?.id}&id=${result.id}`);
      }
    }
  };

  const validation = () => {
    const categoryId = validationHelper.required(localModel?.categoryId);
    const titleOrDeal = localModel?.title
      ? validationHelper.required(localModel?.title)
      : validationHelper?.required(localModel?.dealId);

    setIsValid(categoryId && titleOrDeal);
  };

  useEffect(() => {
    if (localModel) {
      validation();
    }
  }, [localModel]);

  useEffect(() => {
    return () => {
      unmountDeals();
    };
  }, []);

  return (
    <div>
      <Swiper
        pagination
        spaceBetween={20}
        enabled={false}
        slidesPerView={1}
        onSwiper={(swiper) => setSwiper(swiper)}
      >
        <SwiperSlide className="">
          <CategorySet>
            {categoryProps?.map((category: any, index: number) => (
              <Category
                selected={localModel?.categoryId === category?.id}
                onClick={() => {
                  updateServiceModel('categoryId', category?.id as number);
                  setLocalModel('categoryId', category?.id);
                }}
                key={category?.id}
              >
                <img src={category?.image?.publicUrl} alt={category?.id} />
                {category?.name}
              </Category>
            ))}

            <OhmossButton
              onClick={() => {
                swiper.enabled = true;
                handleClickNext(1);
              }}
              disabled={localModel?.categoryId === undefined}
              style={{ width: '90%', marginTop: 10 }}
            >
              선택
            </OhmossButton>
          </CategorySet>
        </SwiperSlide>
        <SwiperSlide className="">
          <div style={{ padding: '0 20px' }}>
            <ListBoxItemTemplate>
              <Text type="H4">신규 Checklist 생성</Text>
              <Text type="H4" buttonRole="DESCRIPTION" style={{ margin: '5px 0' }}>
                공동구매 시작 준비를 위한 체크리스트를 생성합니다. <br />
                나중에 공동구매에 연결할 수 있습니다.
              </Text>
              <TitleForm
                disabled={typeof localModel?.dealId !== 'undefined'}
                label="체크리스트 제목"
                placeHolder="체크리스트 제목을 입력 해주세요."
                onChangeValue={(value: string) => {
                  updateServiceModel('title', value);
                  setLocalModel('title', value);
                }}
              />
            </ListBoxItemTemplate>
            <ListBoxItemTemplate style={{ marginTop: 20 }}>
              <Text type="H4">또는, 공구 체크리스트 생성</Text>
              <Text type="H4" buttonRole="DESCRIPTION" style={{ margin: '5px 0' }}>
                처음부터 공동구매와 연동할 체크리스트를 생성합니다.
              </Text>
              {typeof stateModel?.deals === 'undefined' && (
                <OhmossButton type="square" onClick={getDeals}>
                  연결할 공구 불러오기
                </OhmossButton>
              )}
              <div style={{ width: '85vw', maxWidth: '85vw' }}>
                {typeof targetDeal === 'undefined' ? (
                  <DealList deals={stateModel?.deals} onClick={handleClickDeal} />
                ) : (
                  <div>
                    <DealInfo item={targetDeal} cutOff={1000} />
                    <div style={{ margin: '20px 0' }}>
                      <OhmossButton
                        type="square"
                        onClick={() => {
                          setTargetDeal(undefined);
                          setLocalModel('dealId', undefined);
                        }}
                      >
                        선택 취소
                      </OhmossButton>
                    </div>
                  </div>
                )}
              </div>
            </ListBoxItemTemplate>
            <div style={{ margin: '20px 0' }}>
              <OhmossButton
                type="primaryPc"
                disabled={!isValid}
                onClick={handleClickCreateCheckList}
              >
                Checklist 생성
              </OhmossButton>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

const LabelStyle = styled.div``;

const CategorySet = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 18px;
  flex-wrap: wrap;
  /* width: 100%; */
  max-width: 380px; /* Adjust as needed */
`;
const Category = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 1.25rem;
  font-weight: 700;
  gap: 5px;
  /* width: 110px; */
  width: calc(33.33% - 18px);
  height: 100px;
  max-width: 110px; /* Adjust as needed */
  background-color: ${(props) => (props.selected ? 'rgba(202, 202, 202, 0.3)' : 'white')};
  padding: 0.625rem 1.25rem 0.5rem 1.1875rem;
  align-items: center;
`;

export default ChecklistCategorySelector;
