import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import { validationHelper } from 'controls/validationHelper';
import { t } from 'i18next';

const TelephoneForm = forwardRef(
  (
    {
      model,
      onChangeValue,
    }: {
      model?: string;
      onChangeValue: (value: string) => void;
    },
    ref,
  ) => {
    const [isValid, setIsValid] = useState<boolean>(true);
    const [titleModel, setTitleModel] = useState<string>('');

    const checkValidate = (value: string) => {
      const result = validationHelper.tel(value);
      setIsValid(result);
    };

    useEffect(() => {
      if (typeof model !== 'undefined') {
        setTitleModel(model);
        checkValidate(model);
      }
      return () => {
        setTitleModel('');
      };
    }, [model]);

    return (
      <TextField
        required
        inputRef={ref}
        error={!isValid}
        label="Phone number"
        inputMode="numeric"
        maxRows={5}
        multiline
        type="Number"
        inputProps={{
          inputMode: 'numeric',
          pattern: 'd*',
          maxLength: 15,
          onBlur: (e: any) => {
            checkValidate(e.target.value);
          },
        }}
        onChange={(e: any) => {
          checkValidate(e.target.value);
          setTitleModel(e.target.value);
          onChangeValue(e.target.value);
        }}
        sx={{ width: '100%' }}
        helperText={!isValid ? t('A154') : ' '}
        value={titleModel}
      />
    );
  },
);

export default TelephoneForm;
