import styled from 'styled-components';

export const BadgeStyle = styled.div`
  background: #173300;
  color: #96e06e;
  font-size: 10px;
  border-radius: 20px;
  padding: 3px 15px;
  margin-left: 5px;
  min-width: 10px;
  font-weight: 600;
`;
