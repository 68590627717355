import React from 'react';
import BottomSheetButton from 'components/commons/buttons/BottomSheetButton';
import BottomSheetButtonContainer from 'components/commons/buttons/BottomSheetButtonContainer';
import { useStore } from 'stores/StoreHelper';
import { PostDto } from 'services/data-contracts';
import useNavigation from 'hooks/useNavigation';

/**
 * @description 모든 링크를 생성하기 위한 컨테이너임
 */
const StatusActiveButtons = ({ post }: { post: PostDto }) => {
  const { uiStore, myPostStore, postStore } = useStore();
  const { navigate } = useNavigation();

  const handleClickHide = async () => {
    const newPost: PostDto = { ...post, isActive: false };
    await myPostStore.updatePost(
      post.channel?.id as number,
      post.board?.id as number,
      post.id as number,
      newPost,
    );
    uiStore.universalModal.close();
    uiStore.bottomSheet.close();
    fetchData();
  };

  const fetchData = () => {
    myPostStore.getPosts(post.channel?.id as number, post.board?.id as number, {
      ownChannelId: post.channel?.id as number,
      isActive: true,
    });
  };

  const handleClickEdit = () => {
    uiStore.universalModal.close();
    uiStore.bottomSheet.close();
    navigate(
      `/deals/posts/${post.id}/modify?channelid=${post.channel?.id}&boardid=${post.board?.id}`,
      // `/deals/settings/myad/${post.id}/modify?channelid=${post.channel?.id}&boardid=${post.board?.id}`,
    );
  };

  const handleClickDelete = () => {
    uiStore.universalModal.close();
    uiStore.bottomSheet.close();
    uiStore.confirm.show({
      title: '게시물 삭제',
      message: '해당 게시물을 삭제하시겠습니까?',
      onConfirmed: async () => {
        await myPostStore.deletePost(
          post.channel?.id as number,
          post.board?.id as number,
          post.id as number,
        );
        fetchData();
      },
    });
  };

  return (
    <BottomSheetButtonContainer>
      <BottomSheetButton
        onClick={() => {
          handleClickHide();
        }}
      >
        비공개
      </BottomSheetButton>
      <BottomSheetButton onClick={handleClickEdit}>수정</BottomSheetButton>
      <BottomSheetButton type="danger" onClick={handleClickDelete}>
        삭제
      </BottomSheetButton>
      <BottomSheetButton type="separator" />
      <BottomSheetButton
        onClick={() => {
          uiStore.universalModal.close();
          uiStore.bottomSheet.close();
        }}
      >
        닫기
      </BottomSheetButton>
    </BottomSheetButtonContainer>
  );
};

export default StatusActiveButtons;
