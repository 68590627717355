import React, { useEffect, useState } from 'react';
import {
  ItemSectionStyle,
  ItemSectionTitle,
  ItemSectionDescription,
} from 'resources/controls/ItemSectionStyle';
import { MyStyle } from 'types/CommonTypes';
import { Row } from 'components/commons/layouts';
import { useTranslation } from 'react-i18next';
import { Slider } from '@mui/material';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { MyStyleTypes } from 'features/management/context/providers/ContextTypes';

import SimpleColorPickerWithPopOver from 'components/items/SimpleColorPickerWithPopOver';
import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';

const LinkDesignItemBoxBorder = () => {
  const { t } = useTranslation();
  const { serviceModel, setState, stateModel, initialModel } =
    useOhmossContext<MyStyleTypes<MyStyle>>();

  const [useBorder, setUseBorder] = useState<boolean>(false);

  useEffect(() => {
    if (initialModel) {
      setUseBorder(initialModel?.boxStyle?.borderWidth ? true : false);
    }
  }, [initialModel]);
  return (
    <ItemSectionStyle style={{ padding: 20 }}>
      <ItemSectionTitle>
        <Row style={{ justifyContent: 'space-between' }}>
          <div>{t('A194')}</div>
          <SimpleColorPickerWithPopOver
            disabled={!useBorder}
            useGradient={false}
            defaultValue={serviceModel?.boxStyle?.borderColor}
            onColorChanged={(color: string) => {
              setState('boxStyle', { ...stateModel?.boxStyle, borderColor: color });
            }}
          />
        </Row>
      </ItemSectionTitle>
      <ItemSectionDescription>{t('A195')}</ItemSectionDescription>
      <div style={{ marginTop: 10 }}>
        <MenuItemCheckBox
          title="경계선 사용"
          initialValue={useBorder}
          onChangeValue={(value: boolean) => {
            setUseBorder(value);
            if (value) {
              setState('boxStyle', {
                ...stateModel?.boxStyle,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#000000',
              });
            } else {
              setState('boxStyle', {
                ...stateModel?.boxStyle,
                borderColor: 'unset',
                borderWidth: undefined,
                borderStyle: 'none',
              });
            }
          }}
        />
        <div style={{ marginTop: 10 }}>
          <Slider
            disabled={!useBorder}
            sx={{ marginLeft: 1, width: '95%' }}
            value={(stateModel?.boxStyle?.borderWidth as number) || 1}
            min={1}
            max={5}
            onChange={(e: any, value: number) => {
              setState('boxStyle', {
                ...stateModel?.boxStyle,
                borderWidth: value,
              });
            }}
            aria-label="Default"
            valueLabelDisplay="auto"
          />
        </div>
      </div>
    </ItemSectionStyle>
  );
};

export default LinkDesignItemBoxBorder;
