import * as React from 'react';
import styled, { css } from 'styled-components';
import { Button, ButtonProps } from '@mui/material';
import { styled as muiStyled } from '@mui/system';

const primaryStyle = css`
  background: #173300;
  color: #96e06e;
  border-radius: 30px;
  padding: 10px;
  &:hover {
    background: #112400;
    color: yellow;
    transition: 0.4s;
    transition-timing-function: ease;
  }
  @media ${(props) => props.theme.media.mobile} {
    font-size: 14px;
  }
`;

const primaryPcStyle = css`
  background: #173300;
  color: #96e06e;
  border-radius: 8px;
  padding: 10px;
  &:hover {
    background: #112400;
    color: yellow;
    transition: 0.4s;
    transition-timing-function: ease;
  }
  @media ${(props) => props.theme.media.mobile} {
    font-size: 14px;
  }
`;
const secondaryStyle = css`
  color: #173300;
  background-color: #96e06e;
  border-radius: 30px;
  padding: 10px;
  &:hover {
    background: #efefef;
    color: #173300;
    transition: 0.4s;
    transition-timing-function: ease;
  }
`;

const simpleStyle = css`
  background: #fff;
  border: 1.5px solid #173300;
  border-radius: 30px;
  padding: 10px;
  color: #173300;
  &:hover {
    opacity: 0.6;
    transition: 0.4s;
    transition-timing-function: ease;
  }
  @media ${(props) => props.theme.media.mobile} {
    font-size: 14px;
  }
`;

const linkStyle = css`
  background: none;
  color: #6e6e6e;
  font-weight: 400 !important;
  &:hover {
    opacity: 0.6;
    transition: 0.4s;
    transition-timing-function: ease;
  }
  @media ${(props) => props.theme.media.mobile} {
    font-size: 14px;
  }
`;

const dangerStyle = css`
  border-radius: 30px;
  background: #bf000b;
  color: #fff;
  font-weight: 400 !important;
  &:hover {
    opacity: 0.6;
    transition: 0.4s;
    transition-timing-function: ease;
  }
  @media (hover: none) {
    button:hover {
      background-color: #0bbd58;
    }
  }
  @media ${(props) => props.theme.media.mobile} {
    font-size: 14px;
  }
`;

const disabledStyle = css`
  background: #fff;
  color: #bebebe !important;
  font-weight: 400 !important;
  border-radius: 5px;
  padding: 10px;
  &:hover {
    opacity: 0.6;
    transition: 0.4s;
    transition-timing-function: ease;
  }
  @media (hover: none) {
    button:hover {
      background-color: #0bbd58;
    }
  }
  @media ${(props) => props.theme.media.mobile} {
    font-size: 14px;
  }
`;

const boxStyle = css`
  /* border: 1px solid #bebebe; */
  background-color: #cacaca;
  color: #fff !important;
  font-weight: 400 !important;
  border-radius: 30px;
  padding: 10px;
  &:hover {
    opacity: 0.6;
    transition: 0.4s;
    transition-timing-function: ease;
  }
  @media (hover: none) {
    button:hover {
      background-color: #0bbd58;
    }
  }
  @media ${(props) => props.theme.media.mobile} {
    font-size: 14px;
  }
`;

const squareStyle = css`
  background-color: #fff;
  color: #000 !important;
  font-weight: 400 !important;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #bebebe;
  &:hover {
    opacity: 0.6;
    transition: 0.4s;
    transition-timing-function: ease;
  }
  @media (hover: none) {
    button:hover {
      background-color: #0bbd58;
    }
  }
  @media ${(props) => props.theme.media.mobile} {
    font-size: 14px;
  }
`;

const cancelStyle = css`
  background: #cacaca;
  color: #fff !important;
  font-weight: 400 !important;
  border-radius: 30px;
  padding: 10px;
  &:hover {
    opacity: 0.6;
    transition: 0.4s;
    transition-timing-function: ease;
  }
  @media (hover: none) {
    button:hover {
      background-color: #fff;
      color: #cacaca;
    }
  }
  @media ${(props) => props.theme.media.mobile} {
    font-size: 14px;
  }
`;

const DisabledButton = css`
  cursor: default !important;
  pointer-events: none;
  color: #ffffff !important;
  background: #e8e8e8 !important;
  border: 1px solid #e8e8e8 !important;
`;

const OhmossButton = styled.div<{
  type?:
    | 'primary'
    | 'primaryPc'
    | 'secondary'
    | 'simple'
    | 'link'
    | 'danger'
    | 'box'
    | 'disabled'
    | 'cancel'
    | 'square';
  disabled?: boolean;
  isShow?: boolean;
  role?: 'pc' | 'mobile';
}>`
  align-items: center;
  border-radius: 10px;
  height: 48px;
  width: ${(props) => (props.role === 'pc' ? '300px' : '100%')};

  ${(props) => {
    if (props.type === 'primary') {
      return primaryStyle;
    }
    if (props.type === 'primaryPc') {
      return primaryPcStyle;
    } else if (props.type === 'secondary') {
      return secondaryStyle;
    } else if (props.type === 'simple') {
      return simpleStyle;
    } else if (props.type === 'link') {
      return linkStyle;
    } else if (props.type === 'danger') {
      return dangerStyle;
    } else if (props.type === 'box') {
      return boxStyle;
    } else if (props.type === 'disabled') {
      return disabledStyle;
    } else if (props.type === 'cancel') {
      return cancelStyle;
    } else if (props.type === 'square') {
      return squareStyle;
    } else {
      return primaryStyle;
    }
  }}
  cursor: ${({ disabled }) => (disabled ? 'none' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  justify-content: center;
  align-items: center;
  align-content: center;
  display: inline-flex;
  font-weight: 500;
  cursor: pointer;
  transition: 0.4s;
  transition-timing-function: ease;

  opacity: ${(props) => (typeof props.isShow === 'undefined' || props.isShow ? 1 : 0)};
  ${(props) => {
    if (props.disabled === true) {
      return DisabledButton;
    }
  }}
`;

const DeleteButtonStyle = muiStyled(Button)({
  background: '#fff',
  height: '40px',
});

export { OhmossButton, DeleteButtonStyle };
