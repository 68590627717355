import React, { useState, useEffect, CSSProperties } from 'react';
import {
  ItemContainerStyle,
  SimpleLinkStyle,
} from 'features/adminFeatures/template/components/ItemContainerStyle';
import { useTranslation } from 'react-i18next';
import { ContactLinkModel } from 'types/CommonTypes';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const TelLink = observer(
  ({ item, isPreview = false }: { item?: ContactLinkModel; isPreview?: boolean }) => {
    const { channelStore } = useStore();
    const IMAGE_WIDTH = 50;
    const { t } = useTranslation();
    const [linkModel, setLinkModel] = useState<ContactLinkModel>();
    const [overrideStyle, setOverrideStyle] = useState<CSSProperties>();

    useEffect(() => {
      if (isPreview) {
        setLinkModel(item);
      } else if (typeof item?.metadata !== 'undefined') {
        const custom: any = JSON.parse(item.metadata);
        item.metadataObject = custom;
        setLinkModel(item);
        if (channelStore.myStyle?.style) {
          const parsedStyle = JSON.parse(channelStore.myStyle.style);
          setOverrideStyle(parsedStyle);
        }
      }
    }, [item, channelStore.myStyle]);

    return (
      <ItemContainerStyleOverride
        style={{ justifyContent: 'center', ...overrideStyle }}
        hover={true}
      >
        {item?.thumbnail !== null && (
          <div
            style={{
              height: IMAGE_WIDTH,
              width: IMAGE_WIDTH,
              left: 25,
              position: 'absolute',
            }}
          >
            <img
              alt=""
              src={item?.thumbnail?.publicUrl}
              width={IMAGE_WIDTH}
              height={IMAGE_WIDTH}
              style={{ borderRadius: 8 }}
            />
          </div>
        )}
        <a
          style={{
            color: overrideStyle?.color ? overrideStyle.color : 'black',
            textDecoration: 'none',
          }}
          href={
            linkModel?.metadataObject?.contactType === 'phone'
              ? `tel:${linkModel?.phoneNumber}`
              : `mailto:${linkModel?.metadataObject?.contact}`
          }
        >
          <Row>
            <div style={{ marginRight: 10 }}>
              <>
                {linkModel?.metadataObject?.contactType === 'phone' ? (
                  <img src="../../image/call-received.png" alt="" />
                ) : (
                  <MailOutlineIcon />
                )}
              </>
            </div>
            <div style={{ fontFamily: overrideStyle?.fontFamily }}>{linkModel?.title}</div>
          </Row>
        </a>
      </ItemContainerStyleOverride>
    );
  },
);

const ItemContainerStyleOverride = styled(ItemContainerStyle)<{ hover: boolean }>``;

export default TelLink;
