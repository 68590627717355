import React, { useEffect } from 'react';
import { useRootContext } from 'libs/hooks/useRootContext';

import useSandbirdChat from 'hooks/useSandbirdChat';
import { ChannelQueryType } from 'types/CommonTypes';
import MyInquriesContainer from '../containers/MyInquriesContainer';

const InquiryPage = () => {
  const { myChannel } = useRootContext();
  const { getInquiryChannels } = useSandbirdChat();

  useEffect(() => {
    if (myChannel) {
      const query: ChannelQueryType = {
        channelType: 'INQUIRY',
        metaKey: 'owner',
        metaValues: [myChannel?.id!.toString()],
      };
      getInquiryChannels(query);
    }
  }, [myChannel]);

  return (
    <>
      {myChannel && (
        <div style={{ padding: 10 }}>{myChannel && <MyInquriesContainer useSearch={true} />}</div>
      )}
    </>
  );
};
export default InquiryPage;
