import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useRoutes } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import FollowProvider from 'providers/follow/FollowProvider';
import DealsProvider from 'features/mossMain/context/DealsProvider';
import UniversalLayout from 'components/commons/layouts/UniversalLayout';
import linksRoute from 'routes/linksRoute';
import BottomSheetOnly from 'components/commons/BottomSheetOnly';
import FullModal from 'components/commons/FullModal';
import Confirm2 from 'components/commons/Confirm2';
import UniversalModal from 'components/commons/UniversalModal/Modal';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import { useRootContext } from 'libs/hooks/useRootContext';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import AuthContainer from './AuthContainer';
import ProviderWrapper from './ProviderWrapper';
import { initGA } from './MainContainerBase';
import { BackgroundLayerStyle } from '../components/BackgroundComponent';

const LinksRouter = () => {
  const { pathname } = useLocation();
  const { getCurrentChannel } = useChannelContext();
  const { myChannel } = useRootContext();
  const location = useLocation();
  const currentRoute = useRoutes(linksRoute);

  const getElement = (route: any) => {
    if (window.isWebView) {
      return route.webViewElement ? <route.webViewElement /> : <route.element />;
    } else if (isMobile) {
      return route.mobileElement ? <route.mobileElement /> : <route.element />;
    } else {
      return <route.element />;
    }
  };

  useEffect(() => {
    const channelName = currentRoute?.props?.match?.params?.channelname;
    initGA();
    if (channelName && typeof myChannel !== 'undefined') {
      getCurrentChannel(channelName);
    }
  }, [myChannel, pathname]);

  return (
    <>
      <ManagementLayout style={{ height: 'unset' }}>
        <UniversalLayout useBottom={typeof myChannel?.channelName !== 'undefined'}>
          <BackgroundLayerStyle />

          <Routes location={location}>
            {linksRoute.map((route, index: number) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <AuthContainer isPublic={route.isPublic} title={route.title}>
                      <FollowProvider>
                        {/* <ChannelProvider> */}
                        <DealsProvider>
                          <ProviderWrapper provider={route.provider}>
                            <BottomSheetOnly />
                            <FullModal />
                            <Confirm2 />
                            <UniversalModal />
                            {getElement(route)}
                          </ProviderWrapper>
                        </DealsProvider>
                        {/* </ChannelProvider> */}
                      </FollowProvider>
                    </AuthContainer>
                  }
                />
              );
            })}
          </Routes>
        </UniversalLayout>
      </ManagementLayout>
    </>
  );
};

export default LinksRouter;
