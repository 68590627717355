import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Avatar, Badge, IconButton } from '@mui/material';
import useNavigation from 'hooks/useNavigation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Row } from 'components/commons/layouts';
import { useRootContext } from 'libs/hooks/useRootContext';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import MyChannelList from 'features/commonFeatures/containers/MyChannelList';
import { useStore } from 'stores/StoreHelper';
import MyNotifyInfo from 'components/commons/MyNotifyInfo';
import { ChannelNotificationDto } from 'services/data-contracts';
import { observer } from 'mobx-react';
import { Divider } from 'features/myPage/components/CommonStyleComponent';
import { useLocation, useRoutes } from 'react-router-dom';
import { useChat } from 'libs/hooks/useChat';
import Text from 'components/commons/Text';
import { useStateContext } from 'libs/hooks/usePageState';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import GNBCommonButtons from 'components/commons/layouts/GNBCommonButtons';
import myRoutes from 'routes/myRoutes';
import cstownRoute from 'routes/csTownRoute';
import ProfileAvatar from 'components/commons/ProfileAvatar';

const CsTownGnb = observer(({ useMy = true, type }: { useMy?: boolean; type: 'my' | 'cstown' }) => {
  const location = useLocation();
  const { navigate } = useNavigation();
  const currentRoute = useRoutes([...(myRoutes || []), ...(cstownRoute || [])]);
  const { authStore } = useStore();
  const { subMenus } = useStateContext();
  const { myChannel } = useRootContext();
  const { myNotificationStore, uiStore } = useStore();
  const { unReadMessageCounts, getUnreadCount, initCompleted } = useChat();

  const [showPopup, setShowPopup] = useState(false);
  const [pageTitle, setPageTitle] = useState<string>('');

  const handleClickAlarm = () => {
    uiStore.drawer.show({
      children: (
        <>
          <MyNotifyInfo
            onClick={(alarn: ChannelNotificationDto) => {
              uiStore.drawer.close();
            }}
          />
        </>
      ),
    });
  };

  const getCounts = async () => {
    await getUnreadCount();
  };

  useEffect(() => {
    if (myChannel && initCompleted) {
      getCounts();
    }
  }, [myChannel, initCompleted]);

  const handleClickLogout = async () => {
    uiStore.confirm.show({
      message: '로그아웃 하시겠습니까?',
      onConfirmed: async () => {
        await authStore.logout();
        navigate('/');
      },
    });
    authStore.logout();
  };

  useEffect(() => {
    const channelName = currentRoute?.props?.match?.route?.title;
    setPageTitle(channelName);
    // const t = location.pathname.split('/');
    // if (t.length > 1) {
    //   const menu = t[2];
    //   switch (menu?.toUpperCase()) {
    //     case 'DEALS':
    //       setPageTitle('내 공구목록');
    //       break;
    //     case 'INQUIRIES':
    //       setPageTitle('내 문의목록');
    //       break;
    //     case 'DASHBOARD':
    //       setPageTitle('통합 대시보드');
    //       break;
    //     case 'CHECKLIST':
    //       setPageTitle('체크리스트');
    //       break;
    //     case 'PARTNERS':
    //       setPageTitle('파트너 관리');
    //       break;
    //     case 'MANAGERS':
    //       setPageTitle('매니저 관리');
    //       break;
    //     case 'SUBSCRIPTIONS':
    //       setPageTitle('내 구독정보');
    //       break;
    //     default:
    //       setPageTitle('');
    //       break;
    //   }
    // } else {
    //   setPageTitle('');
    // }
  }, [location]);

  useEffect(() => {
    const handleDocumentClick = (e: any) => {
      if (e.target.classList?.contains('gnb-pop-menu')) return;
      const parent = e.target.parentElement.classList;
      if (parent && parent.contains('gnb-pop-menu')) return;
      setShowPopup(false);
    };

    // Document에 클릭 이벤트 리스너 추가
    document.addEventListener('click', handleDocumentClick);

    // Clean-up function to remove the event listener
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    // <ClickAwayListener

    //   onClickAway={() => {
    //     setShowPopup(false);
    //   }}
    // >
    <>
      <div style={{ width: '100%' }} id="cstown-gnb">
        <Row
          style={{
            justifyContent: 'space-between',
          }}
        >
          <Row style={{ justifyContent: 'flex-start', maxWidth: 410 }}>
            <IconButton
              style={{ marginRight: 20, background: '#fff' }}
              onClick={() => {
                if (pageTitle.toLocaleLowerCase() === 'my page') {
                  navigate(`/${myChannel?.channelName}`);
                } else {
                  history.back();
                }
              }}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
            <Text type="H3">{pageTitle}</Text>

            <div>{subMenus && subMenus}</div>
          </Row>
          <Row
            style={{
              justifyContent: 'flex-start',
              width: 'unset',
              gap: 20,
            }}
          >
            <Badge
              variant="dot"
              invisible={unReadMessageCounts === 0}
              onClick={() => {
                navigate('/cstown/inquiries');
              }}
              sx={{
                '& .MuiBadge-badge': {
                  background: '#173300',
                  color: '#96e06e',
                  fontWeight: 600,
                  marginTop: '5px',
                },
              }}
            >
              <IconButton style={{ background: '#efefef' }}>
                <SmsOutlinedIcon />
              </IconButton>
            </Badge>
            <Badge
              badgeContent={myNotificationStore.alarmsCount}
              onClick={handleClickAlarm}
              sx={{
                '& .MuiBadge-badge': {
                  background: '#173300',
                  color: '#96e06e',
                  fontWeight: 600,
                  marginTop: '5px',
                },
              }}
            >
              <IconButton style={{ background: '#efefef' }}>
                <NotificationsOutlinedIcon />
              </IconButton>
            </Badge>
            <Row
              style={{
                justifyContent: 'space-between',
                minWidth: 230,
                borderLeft: '1px solid #d6d6d6',
                marginLeft: 20,
                paddingLeft: 30,
              }}
            >
              <ProfileAvatar src={myChannel?.profile?.profileImage?.publicUrl} />
              <div style={{ marginLeft: 10 }}>
                <div style={{ fontWeight: 600 }}>{myChannel?.channelName}</div>
                <div style={{ color: '#777', fontSize: 12 }}>
                  {myChannel?.channelType} {myChannel?.membershipType}
                </div>
              </div>
              <IconButton
                className="gnb-pop-menu"
                style={{ marginLeft: 20 }}
                onClick={() => {
                  setShowPopup((prev) => !prev);
                }}
              >
                {showPopup ? (
                  <ExpandLessIcon className="gnb-pop-menu" />
                ) : (
                  <ExpandMoreIcon className="gnb-pop-menu" />
                )}
              </IconButton>
            </Row>
          </Row>
        </Row>
      </div>
      <div>
        {showPopup && (
          <PopUpStyle className="gnb-pop-menu">
            <>
              {myChannel?.channelType === 'MEMBER' ? (
                <>
                  <div>
                    <Row style={{ justifyContent: 'center', flexDirection: 'column' }}>
                      <IconButton onClick={handleClickLogout} style={{ background: '#efefef' }}>
                        <PowerSettingsNewIcon />
                      </IconButton>
                      <Text buttonRole="DESCRIPTION">Logout</Text>
                    </Row>
                  </div>
                </>
              ) : (
                <>
                  <GNBCommonButtons useMy={useMy} />
                  <Divider />
                  <MyChannelList
                    isCstown={type === 'cstown'}
                    onClick={() => {
                      navigate(`/${type}`);
                    }}
                    type="channel"
                  />
                  <Divider />
                </>
              )}
            </>
          </PopUpStyle>
        )}
      </div>
    </>
    // </ClickAwayListener>
  );
});

const PopUpStyle = styled.div`
  position: absolute;
  top: 80px;
  right: 0;
  height: calc(100vh - 80px);
  width: 300px;
  overflow-y: auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #ebebeb;
  z-index: 10;
`;

export default CsTownGnb;
