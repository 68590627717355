import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { Button, IconButton } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ClearIcon from '@mui/icons-material/Clear';
import styled from 'styled-components';

const Follow = observer(
  ({ follow, onClick }: { follow?: boolean; onClick: (isFollow: boolean) => void }) => {
    const { uiStore } = useStore();
    const handleFollow = () => {
      if (follow === true) {
        uiStore.confirm.show({
          message: 'Follow를 취소하시겠습니까?',
          onConfirmed: () => {
            onClick(!follow);
          },
        });
      } else {
        onClick(!follow);
      }
    };

    return (
      <>
        {typeof follow !== 'undefined' && follow === false && (
          <FollowButtonStyle style={{ cursor: 'pointer' }} onClick={handleFollow} follow={follow}>
         
              <Button
                style={{ color: '#000' }}
                startIcon={<FavoriteIcon style={{ color: '#000' }} />}
              >
                Follow
              </Button>
          </FollowButtonStyle>
        )}
      </>
    );
  },
);

const FollowButtonStyle = styled.div<{ follow?: boolean }>`
  padding: ${(props) => (props.follow ? '0px' : '0px 10px')};

  border-radius: 20px;
  border: ${(props) => (props.follow ? '2px solid transparent' : '2px solid #000')};

  background: ${(props) => (props.follow ? '#000' : '#fff')};
`;
export default Follow;
