import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams, useSearchParams } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
// import { toast } from 'react-toastify';
import styled from 'styled-components';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import useToken from 'libs/hooks/useToken';
import PartnerController from 'controllers/PartnerController';
import { ChannelDto, PartnerInvitationDto } from 'services/data-contracts';
import _ from 'lodash';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import { useRootContext } from 'libs/hooks/useRootContext';
import { toast } from 'react-toastify';
import CSTownController from 'controllers/CSTownController';
import BrandController from 'controllers/BrandController';

export interface AcceptInvitationPageProps {
  responseStatus: string;
  message: string;
}

const AcceptInvitationPage = observer(() => {
  const [searchParam] = useSearchParams();
  const { uiStore } = useStore();
  const { validUser } = useToken();
  const { getInvitationInfo } = PartnerController();
  const { acceptInvitation } = BrandController();
  const { myChannel } = useRootContext();
  const { Brand } = CSTownController();

  const { navigate } = useNavigation();

  const [invitationInfo, setInvitationInfo] = useState<PartnerInvitationDto>();
  const [status, setStatus] = useState<AcceptInvitationPageProps>({
    responseStatus: '',
    message: '',
  });
  const { invitationKey } = useParams();
  const channelId = searchParam.get('partnerChannelId');
  const action = searchParam.get('action');

  const auth = async () => {
    if ((invitationKey && channelId) || action) {
      let key;
      let cId;
      if (action) {
        const temp = action.replace('(', '').replace(')', '').split('|');
        key = temp[0];
        cId = temp[1];
      } else {
        key = invitationKey;
        cId = channelId;
      }
      if (key && cId) {
        const response = (await getInvitationInfo(key, Number(cId) as number)) as any;
        if (response?.data?.errorMessage) {
          // toast.error(response?.data.errorMessage);
          setStatus({ responseStatus: 'error', message: response?.data.errorMessage });
        } else {
          setStatus({ responseStatus: 'success', message: '' });
        }
        if (response?.status === 200) {
          setInvitationInfo(response.data);
        }
      }
    } else {
      // toast.error('초대장을 찾을 수 없습니다.11');
      setStatus({
        responseStatus: 'error',
        message: '이미 처리된 초대장입니다.',
      });
    }
  };

  const handleClickAcceptBtn = async () => {
    // uiStore.bottomSheet.show({
    //   title: '관리 채널을 선택해주세요.',
    //   style: { minHeight: '150px' },
    //   children: (
    //     <MyChannelsContainerStyle>
    //       <ChannelsListContainerStyle>
    //         <>
    //           <MyChannelsSelector channel={myChannelStore?.myChannels} />
    //         </>
    //       </ChannelsListContainerStyle>
    //     </MyChannelsContainerStyle>
    //   ),
    //   onConfirmed(result) {
    //     handleSelectChannel(result);
    //   },
    // });
    uiStore.confirm.show({
      message: '초대를 수락하시겠습니까?',
      onConfirmed: () => {
        handleAcceptInvitation();
      },
    });
  };

  const handleAcceptInvitation = async () => {
    let key;
    let cId;
    if ((invitationKey && channelId) || action) {
      if (action) {
        const temp = action.replace('(', '').replace(')', '').split('|');
        key = temp[0];
        cId = temp[1];
      } else {
        key = invitationKey;
        cId = channelId;
      }
      if (key && cId) {
        const response = (await acceptInvitation(invitationKey as string, {
          partnerChannelId: Number(channelId) as number,
          inviterChannelName: invitationInfo?.channel?.channelName as string,
        })) as any;

        if (response?.data?.errorMessage) {
          toast.error(response?.data.errorMessage);
        } else {
          // 나에게 할당된 모든 딜을 취득해서 참여시킴
          Brand.CS.joinChatChannels(invitationInfo?.channel?.id as number);
          navigate('/cstown');
        }
      }
    }
  };

  // const handleSelectChannel = async (channelId: number) => {
  //   if (channelId) {
  //     let key;
  //     let cId;
  //     if ((invitationKey && channelId) || action) {
  //       if (action) {
  //         const temp = action.replace('(', '').replace(')', '').split('|');
  //         key = temp[0];
  //         cId = temp[1];
  //       } else {
  //         key = invitationKey;
  //         cId = channelId;
  //       }
  //       if (key && cId) {
  //         const response = (await acceptInvitation(invitationKey as string, {
  //           partnerChannelId: channelId as number,
  //           inviterChannelName: invitationInfo?.channel?.channelName as string,
  //         })) as any;

  //         if (response?.data?.errorMessage) {
  //           toast.error(response?.data.errorMessage);
  //         } else {
  //           navigate('/my');
  //         }
  //       }
  //     }
  //   } else {
  //     toast.error('채널을 선택해주세요.');
  //   }
  // };

  useEffect(() => {
    if (!validUser()) {
      uiStore.confirm.show({
        message: '로그인이 필요한 메뉴입니다. 로그인 하시겠습니까?',
        onConfirmed: async () => {
          navigate(`/login?action=(/invitation.(${channelId}|${invitationKey})`);
        },
        onCancel: () => {
          navigate('/');
        },
      });
    } else if (myChannel && myChannel?.id === Number(channelId)) {
      auth();
    }
  }, [myChannel]);

  return (
    <ManagementLayout>
      <MainContainer>
        {status.responseStatus && status.responseStatus === 'success' && (
          <ContentWrapper>
            <WelcomeText>
              Become part of the team! 🎉
              <br />
            </WelcomeText>
            <RequestText>
              {invitationInfo?.channel?.channelName}님의 신규 관리 요청이 들어왔습니다.
            </RequestText>
            <OhmossButton
              type="secondary"
              onClick={() => {
                navigate('/my');
              }}
              style={{ marginTop: 30, width: '90%' }}
            >
              거부
            </OhmossButton>
            <OhmossButton onClick={handleClickAcceptBtn} style={{ marginTop: 10, width: '90%' }}>
              승인
            </OhmossButton>
          </ContentWrapper>
        )}
        {status.responseStatus === 'error' && (
          <ContentWrapper>
            <img src="/images/sad.svg" alt="" style={{ width: '200px' }} />
            <WelcomeText>{status?.message} 🙁</WelcomeText>
            <div>이미 승인된 초대장일수도 있어요!</div>
            <OhmossButton
              onClick={() => {
                history.back();
                // navigate('/cstown');
              }}
              style={{ marginTop: 30 }}
            >
              CS Town으로 돌아가기
            </OhmossButton>
          </ContentWrapper>
        )}
      </MainContainer>
    </ManagementLayout>
  );
});

const MainContainer = styled.div`
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30%;
`;

const WelcomeText = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  color: #4e4e4e;
  text-align: center;
`;

const RequestText = styled.div`
  font-size: 18px;
  margin-top: 20px;
  color: #4e4e4e;
  text-align: center;
`;
const MyChannelsContainerStyle = styled.div`
  padding: 0 20px;
  justify-content: center;
  width: 100%;
`;

const ChannelsListContainerStyle = styled.div`
  left: 20px;
  right: 20px;
  /* top: 60px; */
  bottom: 0px;
  overflow: scroll;
  @media ${(props) => props.theme.media.mobile} {
    /* height: 280px; */
  }
`;

export default AcceptInvitationPage;
