/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PartnerInvitationDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class InvitationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 초대 수락
   *
   * @tags InvitationService
   * @name AcceptInvitation
   * @summary 초대 수락
   * @request POST:/v2/invitations/{invitationKey}/accept
   * @secure
   */
  acceptInvitation = (
    invitationKey: string,
    query: {
      /** @format int64 */
      partnerChannelId: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<PartnerInvitationDto[], any>({
      path: `/v2/invitations/${invitationKey}/accept`,
      method: 'POST',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 초대장 정보
   *
   * @tags InvitationService
   * @name GetInvitation
   * @summary 초대장 정보
   * @request GET:/v2/invitations/{invitationKey}
   * @secure
   */
  getInvitation = (
    invitationKey: string,
    query: {
      /** @format int64 */
      partnerChannelId: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<PartnerInvitationDto, any>({
      path: `/v2/invitations/${invitationKey}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
