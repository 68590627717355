import React, { useEffect, useRef, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useStore } from 'stores/StoreHelper';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useChat } from 'libs/hooks/useChat';

import FollowProvider from 'providers/follow/FollowProvider';
import DealsProvider from 'features/mossMain/context/DealsProvider';
import FullModal from 'components/commons/FullModal';
import Confirm2 from 'components/commons/Confirm2';
import UniversalModal from 'components/commons/UniversalModal/Modal';
import myRoutes from 'routes/myRoutes';
import NavigationTabBar from 'components/commons/NavigationTabBar';
import BottomSheetOnly from 'components/commons/BottomSheetOnly';
import chatController from 'features/community/controllers/chatController';
import useMobileLayout from 'hooks/useMobileLayout';
import { initGA } from './MainContainerBase';
import ProviderWrapper from './ProviderWrapper';
import AuthContainer from './AuthContainer';
import { useMediaContext } from 'providers/useMediaContext';
import styled from 'styled-components';
import MyLNBCommon from 'features/management/components/MyLNBCommon';
import { menuItems } from 'definitions/menuItems';
import { Row } from 'components/commons/layouts';
import { ClickAwayListener } from '@mui/material';
import MyChannelList from 'features/commonFeatures/containers/MyChannelList';
import useNavigation from 'hooks/useNavigation';
import { useStateContext } from 'libs/hooks/usePageState';
import {
  CenterContainerStyle,
  ContainerStyle,
  GnbStyle,
  LeftContainerStyle,
} from 'components/commons/layouts/LayoutBox';
import GNBCommonButtons from 'components/commons/layouts/GNBCommonButtons';
import CsTownGnb from 'features/csTown/components/CsTownGnb';

const MyPCRouter = () => {
  const location = useLocation();
  const containerRef = useRef(null);
  const ScreenTypes = useMediaContext();

  const { navigate } = useNavigation();
  const { initChat } = useChat();
  const { myChannel } = useRootContext();
  const { getUser } = chatController();
  const { setSize } = useMobileLayout(containerRef);
  const { historyStore } = useStore();
  const { setPageHeaderTitle, pageTitle } = useStateContext();

  const [showPopup, setShowPopup] = useState(false);

  const getElement = (route: any) => {
    if (window.isWebView) {
      return route.webViewElement ? <route.webViewElement /> : <route.element />;
    } else if (isMobile) {
      return route.mobileElement ? <route.mobileElement /> : <route.element />;
    } else {
      return <route.element />;
    }
  };

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    historyStore.pushPopHistory({ key: location.key, path: location.pathname });
  }, [location]);

  useEffect(() => {
    if (myChannel) {
      const initialize = async () => {
        const result = await getUser(myChannel?.channelName as string);
        if (result) {
          initChat(myChannel?.channelName as string);
        }
      };

      initialize();
    }
  }, [myChannel]);

  useEffect(() => {
    setSize();
  }, []);

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setShowPopup(false);
        }}
      >
        <>
          <ContainerStyle style={{ overflow: 'hidden' }}>
            <LeftContainerStyle>
              <LogoStyle style={{ width: '100%' }}>
                <Row
                  style={{ padding: 10, cursor: 'pointer', justifyContent: 'flex-start' }}
                  onClick={() => {
                    setPageHeaderTitle('My page');
                    navigate('/my');
                  }}
                >
                  <img src="/images/oh_logo.svg" style={{ width: 60 }} />
                </Row>
              </LogoStyle>
              <MyLNBCommon items={menuItems} style={{ paddingTop: 130 }} />
            </LeftContainerStyle>
            <CenterContainerStyle style={{ maxWidth: '100%', height: 'unset' }}>
              <GnbStyle style={{ background: 'unset' }}>
                <CsTownGnb useMy={false} type="my" />
              </GnbStyle>

              <Row>
                <div style={{ maxWidth: '100%', width: '100%', height: '100%' }}>
                  <Routes location={location}>
                    {myRoutes.map((route, index: number) => {
                      return (
                        <Route
                          key={index}
                          path={route.path}
                          element={
                            <AuthContainer isPublic={route.isPublic} title={route.title}>
                              <FollowProvider>
                                <ProviderWrapper provider={route.provider}>
                                  <BottomSheetOnly />
                                  <FullModal />
                                  <Confirm2 />
                                  <UniversalModal />
                                  <DealsProvider>
                                    <div ref={containerRef}>{getElement(route)}</div>
                                  </DealsProvider>
                                </ProviderWrapper>
                              </FollowProvider>
                            </AuthContainer>
                          }
                        />
                      );
                    })}
                  </Routes>
                  {ScreenTypes.isSmall && <NavigationTabBar type="main" />}
                </div>
                {/* <RightContainerStyle>sdf</RightContainerStyle> */}
              </Row>
            </CenterContainerStyle>
          </ContainerStyle>
          <div>
            {showPopup && (
              <>
                <PopUpStyle>
                  <GNBCommonButtons useMy={false} />
                  <>
                    <MyChannelList
                      onClick={() => {
                        setShowPopup(false);
                      }}
                      type="channel"
                    />
                  </>
                </PopUpStyle>
              </>
            )}
          </div>
        </>
      </ClickAwayListener>
    </>
  );
};

const ManagementLayoutStyle = styled.div`
  background: #fff !important;
  position: absolute;
  top: 100px;
  border-radius: 20px;
  right: 20px;
  left: 20px;
`;

const LargeGnbStyle = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  max-width: 270px;
  width: 100%;
  min-width: 100px;
  align-items: flex-start;
  justify-content: flex-start;
  /* background: #fff; */
`;

const LogoStyle = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
`;

const PopUpStyle = styled.div`
  position: absolute;
  right: 0;
  top: 80px;
  max-width: 300px;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #ebebeb;
  z-index: 10;
`;

export default MyPCRouter;
