import React from 'react';
import styled from 'styled-components';
import MainSettingContainer from '../containers/MainSettingContainer';

const MainSettingPage = () => {
  return <MainSettingContainer />;
};

const MenuItemGroupStyle = styled.div``;
export default MainSettingPage;
