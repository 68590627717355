import React from 'react';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';

import styled from 'styled-components';
import { Row } from 'components/commons/layouts';
import DealBox from './DealBox';
import { CountStyle } from '../commonComponents/styles';

type Status = Exclude<LinkDtoWithAssignedDeal['status'], undefined>;
type GroupedByStatus = { [K in Status]?: LinkDtoWithAssignedDeal[] };

const AssignedDealsList = ({ dealList }: { dealList?: LinkDtoWithAssignedDeal[] }) => {
  // const [newSelectedDeals, setNewSelectedDeals] = React.useState<LinkDtoWithAssignedDeal[]>([]);

  const groupedByStatus = dealList?.reduce<GroupedByStatus>((acc, item) => {
    const status = item.status!;
    if (!acc[status]) {
      acc[status] = [];
    }
    acc[status]?.push(item);
    return acc;
  }, {});

  // useEffect(() => {
  //   if (dealList) {
  //     setNewSelectedDeals(dealList);
  //   }
  // }, [dealList]);

  return (
    <DealListWrapper>
      <div>
        <LaneStyle>
          <TableStyle>
            <tr>
              <th>
                <Row style={{ width: 'unset', justifyContent: 'flex-start', gap: 10 }}>
                  <CountStyle type="UPCOMING">대기중인 공구</CountStyle>
                  <CountStyle style={{ width: 35, height: 35 }}>
                    {groupedByStatus?.UPCOMING?.length || 0}
                  </CountStyle>
                </Row>
              </th>
              <th>
                <Row style={{ width: 'unset', justifyContent: 'flex-start', gap: 10 }}>
                  <CountStyle type="ONGOING">진행중인 공구</CountStyle>
                  <CountStyle style={{ width: 35, height: 35 }}>
                    {groupedByStatus?.ONGOING?.length || 0}
                  </CountStyle>
                </Row>
              </th>
              <th>
                <Row style={{ width: 'unset', justifyContent: 'flex-start', gap: 10 }}>
                  <CountStyle type="ENDED">완료된 공구</CountStyle>
                  <CountStyle style={{ width: 35, height: 35 }}>
                    {groupedByStatus?.ENDED?.length || 0}
                  </CountStyle>
                </Row>
              </th>
            </tr>
            <tr>
              <td>
                <>{groupedByStatus?.UPCOMING?.map((deal, index) => <DealBox deal={deal} />)}</>
              </td>
              <td>
                <>{groupedByStatus?.ONGOING?.map((deal, index) => <DealBox deal={deal} />)}</>
              </td>
              <td>
                <ScrollDivStyle>
                  {groupedByStatus?.ENDED?.map((deal, index) => <DealBox deal={deal} />)}
                </ScrollDivStyle>
              </td>
            </tr>
          </TableStyle>
        </LaneStyle>
      </div>
    </DealListWrapper>
  );
};

const DealListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LaneStyle = styled.div``;

const TableStyle = styled.table`
  width: 100%;
  border: 1px solid #efefef;
  > tr > th {
    border-right: 1px solid #efefef;
    width: 33%;
    background: #fff;
    height: 50px;
    text-align: left;
    padding: 20px;
    border-bottom: 2px solid #e8ebef;
  }
  > tr > th:last-child {
    border: 0px;
    border-bottom: 2px solid #e8ebef;
  }
  > tr > td {
    border: 1px solid #efefef;
    vertical-align: top;
    background: #fbfbfb;
  }
  > tr > td:last-child {
    border: 0px;
  }
`;

const ScrollDivStyle = styled.div`
  overflow: auto;
`;
export default AssignedDealsList;
