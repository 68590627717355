import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';

import styled, { css } from 'styled-components';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StyledRefBox from 'components/commons/layouts/StyledRefBox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MyChannelList from 'features/commonFeatures/containers/MyChannelList';
import { IconButton } from '@mui/material';
import useToken from 'libs/hooks/useToken';
import useLocalParams from 'libs/hooks/useLocalParams';
import { useRootContext } from 'libs/hooks/useRootContext';
import useNavigation from 'hooks/useNavigation';
import { ChannelDto } from 'services/data-contracts';

const GnbHeader = observer(() => {
  const channelNameBoxRef = useRef<any>(null);
  const channelHeaderBoxRef = useRef<any>(null);

  const { uiStores, uiStore, myChannelStore } = useStore();
  const { userHomeStore } = uiStores;
  const { channelName } = useLocalParams();
  const { myChannel, setChannel } = useRootContext();

  const { validUser } = useToken();

  const [isFollow, setIsFollow] = useState<boolean>();
  const [isMyChannel, setIsMyChannel] = useState<boolean>();

  const handleClickUnFollow = () => {
    if (channelName) {
      uiStore.confirm.show({
        message: 'Follow를 취소하시겠습니까?',
        onConfirmed: async () => {
          const result = await myChannelStore.unfollow(
            myChannelStore.currentChannel?.id as number,
            channelName,
          );
          if (result) {
            userHomeStore.setChannelFollow(false);
          }
        },
      });
    } else {
      // todo modal로 교체
      alert('채널명이 바르지 않습니다.');
    }
  };

  useEffect(() => {
    if (typeof userHomeStore.isFollow !== 'undefined') {
      setIsFollow(userHomeStore.isFollow);
    }
  }, [userHomeStore.isFollow]);

  const handleClickAvatar = () => {
    uiStore.bottomSheet.show({
      children: (
        <ContainerStyle>
          <MyChannelList type="channel" />
        </ContainerStyle>
      ),
    });
  };

  const isMyChannelCheck = (channelName?: string): boolean => {
    const myChannel = myChannelStore.myChannels?.findIndex(
      (myChannel?: ChannelDto) => channelName === myChannel?.channelName,
    );
    if (myChannel === -1) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (channelName) {
      setIsMyChannel(isMyChannelCheck(channelName));
    }
  }, [channelName, myChannel]);

  return (
    <>
      <ChannelHeaderStyle ref={channelHeaderBoxRef}>
        <ChannelNameStyle show={isFollow} ref={channelNameBoxRef}>
          <FavoriteContainerStyle show={isFollow} onClick={handleClickUnFollow}>
            <FavoriteIcon style={{ color: '#ea4c89' }} />
          </FavoriteContainerStyle>
          <ChannelNameInnterStyle>{channelName}</ChannelNameInnterStyle>
          <>
            {validUser() && isMyChannel && (
              <IconButton onClick={handleClickAvatar} style={{ marginLeft: 5 }}>
                <ExpandMoreIcon style={{ color: 'var(--primary-color)' }} />
              </IconButton>
            )}
          </>
        </ChannelNameStyle>
      </ChannelHeaderStyle>
    </>
  );
});

const ChannelNameStyle = styled(StyledRefBox)<{ show?: boolean }>`
  padding-right: 30px;
  margin-left: ${(props) => (props.show ? '20px' : '20px')};

  align-items: center;
  display: flex;
  font-weight: 400;
  transition: margin-left 400ms ease;
  @media ${(props) => props.theme.media.mobile} {
    padding-right: 5px;
    border-right: unset;
    margin-left: ${(props) => (props.show ? '20px' : '10px')};
  }
`;

const ChannelNameInnterStyle = styled.div`
  display: unset;
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-color);
  @media ${(props) => props.theme.media.mobile} {
    font-size: 16px;
  }
`;
const ChannelHeaderStyle = styled(StyledRefBox)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  > div:nth-child(2) {
    padding-right: 30px;
  }

  @media ${(props) => props.theme.media.mobile} {
    font-size: 16px;
    > div:nth-child(2) {
      padding-right: 10px;
    }
  }
`;

const FavoriteContainerStyle = styled.div<{ show?: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  opacity: ${(props) => (props.show ? '1' : '0')};
  transition: all 0.5s ease-in-out;
  pointer-events: ${(props) => (props.show ? 'box-only' : 'none')};
  margin-left: 5px;
  position: relative;
  align-items: center;
  /* display: flex; */
  left: -5px;
  cursor: ${(props) => (props.show ? 'pointer' : 'default')};
  ${(props) =>
    props.show &&
    css`
      @keyframes grow-shrink {
        0%,
        100% {
          transform: scale(1); /* 원래 크기 */
        }
        50% {
          transform: scale(1.6); /* 2배로 커지는 중간 지점 */
        }
      }
      animation: grow-shrink 1s ease-in-out;
    `}
`;
const ContainerStyle = styled.div`
  padding: 20px;
  width: 100%;
`;
export default GnbHeader;
