import { makeObservable, flow } from 'mobx';
import { MyDealService } from 'services/MyDealService';
import { DealOptionDto, LinkDto, MessageDto } from 'services/data-contracts';

import _ from 'lodash';
import { HttpClient } from 'services/http-client';
import { parseJSONAll } from 'libs/helper/utils';
import ServiceStoreBase from './ServiceStoreBase';

export interface isValid {
  isValid: boolean;
}

class MyDealStore extends ServiceStoreBase {
  links: Array<LinkDto>;

  constructor() {
    super();
    makeObservable(this, {
      getMyDeals: flow,
      getDeal: flow,
      getDealOptions: flow,
      getReceivedInquiriesByDeal: flow,
      getReceivedAllInquiries: flow,
      getInquiryThreads: flow,
      saveDealOption: flow,
      answer: flow,
    });
  }

  api: MyDealService<unknown> = new MyDealService(new HttpClient());

  *getMyDeals(channelId: number) {
    const { response } = yield this.api.getDeals(channelId);
    response.data.map((deal: LinkDto) => {
      let parsedMetadata: any = {};
      try {
        parsedMetadata = parseJSONAll(deal?.metadata);
      } catch (error) {
        console.error('JSON parsing error:', error);
      }
      const mergedDeal = { ...deal, ...parsedMetadata };
      return { ...mergedDeal };
    });

    const t = 'a';
    return response.status < 300 ? response.data : [];
  }

  *getDeal(channelId: number, dealId: number) {
    const { response } = yield this.api.getDeal(channelId, dealId);
    return response.status < 300 ? response.data : null;
  }

  *getDealOptions(channelId: number, linkId: number) {
    const { response } = yield this.api.getDealOptions(channelId, linkId);
    return response.status < 300 ? response.data : null;
  }

  *getReceivedInquiriesByDeal(dealId: number, channelId: number) {
    const { response } = yield this.api.getReceivedInquiriesByDeal(channelId, dealId);
    return response.status < 300 ? response.data : [];
  }

  *getReceivedAllInquiries(channelId: number, status?: 'PENDING' | 'ANSWERED' | 'CLOSED') {
    const { response } = yield this.api.getReceivedAllInquiries(channelId, { status });
    return response.status < 300 ? response.data : [];
  }

  *getInquiryThreads(channelId: number, dealId?: number, inquiryId?: number) {
    const { response } = yield this.api.getReceivedInquiryThreads(
      channelId,
      dealId as number,
      inquiryId as number,
      { sort: ['createDate,ASC'], size: 1000 },
    );
    return response.status < 300 ? response.data : [];
  }

  *saveDealOption(
    channelId: number,
    boardId: number,
    linkId: number,
    dealOptions: DealOptionDto[],
  ) {
    const { response } = yield this.api.saveDealOptions(channelId, linkId, dealOptions);
    return response.status < 300 ? response.data : null;
  }

  *answer(channelId: number, linkId: number, inquiryId: number, data: MessageDto) {
    const { response } = yield this.api.answer(channelId, linkId, inquiryId, data);
    return response.status < 300 ? response.data : [];
  }

  *getCSSummary(channelId: number) {
    const { response } = yield this.api.getInquirySummaryForChannel(channelId);
    return response.status < 300 ? response.data : [];
  }

  unMount() {}
}

export default MyDealStore;
