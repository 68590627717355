import { Row } from 'components/commons/layouts';
import SNSIconWapper from 'components/items/SNSIconWrapper';
import boardCommonLogics from 'features/board/logic/boardCommonLogic';
import { MagServiceType } from 'features/management/context/providers/ContextTypes';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
import { MagazineDto } from 'services/data-contracts';
import styled from 'styled-components';
import { toJS } from 'mobx';

const MagDetailPage = ({
  magazineId,
  magazine,
  isBanner,
}: {
  magazineId?: string;
  magazine?: MagazineDto;
  isBanner?: boolean;
}) => {
  const { getMagByKey, magazines, unmount } = useOhmossContext<MagServiceType>();
  const { getImageFromDom } = boardCommonLogics();
  const { magazineid } = useParams();
  const { navigate } = useNavigation();

  const [magazineDetail, setMagazineDetail] = React.useState<MagazineDto>();

  // const handleClickMag = (magazine: MagazineDto) => {
  //   navigate(`/mag/${magazine.id}`);
  // };

  const handleGoSNS = (linkUrl?: string) => {
    if (linkUrl) {
      window.open(linkUrl);
    }
  };
  // const getThumbnail = (magazine: MagazineDto) => {
  //   if (magazine.thumbnail) {
  //     return magazine?.thumbnail.publicUrl;
  //   }

  //   const contentImage = getImageFromDom(magazine.content);
  //   if (contentImage) {
  //     return contentImage;
  //   }
  // };

  const getMagazine = async () => {
    if (magazineId) {
      const mag = (await getMagByKey(magazineId as string)) as any as MagazineDto;
      if (mag) {
        setMagazineDetail(mag);
      }
    }
  };
  useEffect(() => {
    if (!isBanner) {
      getMagazine();
      setMagazineDetail(magazine);
    } else {
      getMagazine();
    }
    return () => {
      unmount();
    };
  }, [magazineId]);

  return (
    <div style={{ paddingBottom: 10, padding: '0 20px' }}>
      <div style={{ fontSize: 20, fontWeight: 700 }}>{magazineDetail?.title}</div>
      <div style={{ fontSize: 12, color: '#676767', margin: 10 }}>
        {moment(magazineDetail?.modifyDate ?? new Date()).format('YYYY-MM-DD')}
      </div>
      <div
        style={{}}
        dangerouslySetInnerHTML={{
          __html: magazineDetail?.content ?? '',
        }}
      />
      <hr />
      <Row style={{ justifyContent: 'space-between', alignContent: 'center' }}>
        <div style={{}}>Written by {magazineDetail?.author}</div>
        <>
          {magazineDetail?.socialLinks?.map((social) => (
            <>
              <div
                onClick={() => {
                  handleGoSNS(social.linkUrl);
                }}
                style={{ cursor: 'pointer' }}
              >
                <SNSIconWapper
                  name={social.socialType.toLowerCase()}
                  iconType="origin"
                  width={20}
                />
              </div>
            </>
          ))}
        </>
      </Row>
    </div>
  );
};

export default MagDetailPage;
