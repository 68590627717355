/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable class-methods-use-this */
import { makeObservable, action, flow, observable } from 'mobx';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import {
  LinkDto,
  SocialLinkDto,
  UserProfileDto,
  // UserStyleDto,
  // UserProfileImageDto,
  // UserBackgroundImageDto,
} from 'services/data-contracts';
import i18n from 'i18n';

type Event = {
  type: 'none' | 'linkActive' | 'sns' | 'profile';
  item?: LinkDto | Array<SocialLinkDto> | any;
};

class ServiceStoreBase {
  t = i18n.t;

  isBusy: boolean;

  event: Event;

  constructor() {
    makeObservable(this, {
      event: observable,
      handleByStatus: action,
      raiseEvent: action,
      getItem: flow,
      getItems: flow,
      createItem: flow,
      updateItem: flow,
      deleteItem: flow,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  handleByStatus(
    response: AxiosResponse,
    params?: { successMessage?: string; failMessage?: string; },
  ) {
    /** Success */
    if (response.status === 200) {
      if (typeof params?.successMessage !== 'undefined') {
        toast(params.successMessage);
      }
      return true;
    }

    /** Client Error */
    if (response.status >= 400 && response.status < 500) {
      if (typeof params?.failMessage !== 'undefined') {
        toast.error(params.failMessage);
      } else {
        toast.error(response.data.errorMessage);
      }
      return false;
    }

    /** Server Error */
    if (response.status >= 500) {
      if (typeof params?.failMessage !== 'undefined') {
        toast.error(params.failMessage);
      } else {
        toast.error(response.data.errorMessage || this.t('A223'));
      }
      return false;
    }

    if (response.status === 500) {
      if (typeof params?.failMessage !== 'undefined') {
        toast.error(params.failMessage);
      } else {
        toast.error(response.data.errorMessage || this.t('A223'));
      }
      return false;
    }
  }

  raiseEvent(event: Event) {
    this.event = event;
  }

  /** @description Call interfaces */

  getItem(itemId: number, ...args: any[]) { }

  getItems<T>(...args: any[]) { }

  createItem(item: any, ...args: any[]) { }

  updateItem(itemId: number, item: any, ...args: any[]) { }

  deleteItem(itemId: number, ...args: any[]) { }
}

export default ServiceStoreBase;
