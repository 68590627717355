import TabContext from '@mui/lab/TabContext/TabContext';
import TabList from '@mui/lab/TabList/TabList';
import TabPanel from '@mui/lab/TabPanel/TabPanel';
import { Tab } from '@mui/material';
import EmptyArticle from 'components/commons/EmptyArticle';
import MobileModalHeader from 'components/commons/MobileModalHeader';
import { Row } from 'components/commons/layouts';
import PaginationBase from 'features/board/containers/PaginationBase';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { CoinEventDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import DetailPageHeader from 'components/commons/DetailPageHeader';

const MossCoinContainer = observer(() => {
  const { myCoinStore, myChannelStore } = useStore();

  const [value, setValue] = React.useState<string>('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    window.history.replaceState('', '', `/settings/mosscoin?type=${newValue}`);
    fetchData(newValue);
  };

  const handleChangePage = (pageNo: number) => {
    fetchData('1', pageNo - 1);
  };

  const fetchData = (type: string, page?: number) => {
    switch (type) {
      case '1':
        break;
      case '2':
        myCoinStore.getConinEvents(myChannelStore.currentChannel?.id as number, { page });
        break;
      default:
        break;
    }
  };

  return (
    <>
      {/* <DetailPageHeader title="모스코인" /> */}
      <div style={{ padding: '0 20px', fontWeight: 600, fontSize: 14 }}>
        <div>보유 모스코인</div>
        <div style={{ fontSize: 18, color: '#2EBE59', fontWeight: 600 }}>
          <NumberFormat
            displayType="text"
            value={myChannelStore.currentChannel?.coin || 0}
            thousandSeparator
          />
        </div>
      </div>
      <TabContext value={value}>
        <TabList
          sx={{
            '& .MuiTabs-flexContainer': { justifyContent: 'flex-start', margin: 0, padding: 0 },
          }}
          onChange={handleChange}
          aria-label="lab API tabs example"
        >
          <Tab label="구매내역" value="1" />
          <Tab label="이용내역" value="2" />
        </TabList>
        <TabPanel value="1">
          <>
            구매내역은 개발중입니다.
            {/* <PaginationBase paginationType="pager" totalPages={1} onChangePage={handleChangePage}>
             
            </PaginationBase> */}
            {/* {myPostStore.posts?.length === 0 && <EmptyArticle title="" />} */}
          </>
        </TabPanel>
        <TabPanel value="2">
          <PaginationBase paginationType="pager" totalPages={1} onChangePage={handleChangePage}>
            {myCoinStore.coinEvents?.map((history: CoinEventDto) => (
              <>
                <UseStyle>
                  <Row style={{ justifyContent: 'space-between' }}>
                    <div style={{ color: '#808080', fontSize: 14 }}>
                      {moment(history.transactionDate).format('YYYY-MM-DD')}
                    </div>

                    <div style={{ textAlign: 'right' }}>
                      <div style={{ fontSize: 14, fontWeight: 600, color: '#000' }}>
                        {history.description}
                      </div>
                      <div style={{ fontSize: 12, color: '#666' }}>
                        <NumberFormat
                          displayType="text"
                          value={-history.coin! || 0}
                          thousandSeparator
                        />{' '}
                        모스코인
                      </div>
                    </div>
                  </Row>
                </UseStyle>
                <LinkSeparator />
              </>
            ))}
            {myCoinStore.coinEvents?.length === 0 && <EmptyArticle title="이용내역이 " />}
          </PaginationBase>
        </TabPanel>
      </TabContext>
    </>
  );
});

const UseStyle = styled.div`
  margin: 15px 0;
  padding: 10px 0;
`;

const LinkSeparator = styled.div`
  border-top: 1px solid #bebebe;
`;

export default MossCoinContainer;
