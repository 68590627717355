import React, { useEffect, useRef, useState, CSSProperties } from 'react';
import { ImageUploader } from 'components/commons';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import TitleForm from 'components/items/forms/TitleForm';
import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';

import UrlForm from 'components/items/forms/UrlForm';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import { validationHelper } from 'controls/validationHelper';
import { LinkTypes } from 'features/management/context/providers/ContextTypes';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import BlockController from 'controllers/BlockController';
import { StickerTypes } from 'components/commons/stickers/StickerTypes';
import ImageLayout from '../commonControl/ImageLayout';
import CommonSticker from '../commonControl/CommonSticker';
import CommonAlign from '../commonControl/CommonAlign';
import { IImageBlock } from 'types/BlockExtendsTypes';
import ActionButton from './ActionButton';

const Image = ({ blockModel }: { blockModel?: IImageBlock }) => {
  const { uiStore } = useStore();
  const { initialModel, updateServiceModel, serviceModel, initialBinding, unmountModel } =
    useOhmossContext<LinkTypes<IImageBlock>>();

  const { updateBlock } = BlockController<IImageBlock>();

  const defaultProps: Partial<IImageBlock> = {
    linkType: 'Image',
    isActive: true,
    layout: 'TypeE',
    stickerPosition: 'top',
  };

  const [isValid, setIsValid] = useState<boolean>(false);
  const [localModel, setLocalModel] = useInitializeModel<Partial<IImageBlock>>(
    blockModel,
    defaultProps,
    initialBinding,
  );

  const [style, setStyle] = useState<CSSProperties>();
  const defaultStyle = useRef<CSSProperties>();

  useEffect(() => {
    if (localModel) {
      const urlValid = validationHelper.urlOrEmpty(localModel?.linkUrl);
      const imageValid = typeof localModel.thumbnail !== 'undefined';
      setIsValid(urlValid && imageValid);
    }
  }, [localModel]);

  useEffect(() => {
    return () => {
      unmountModel();
    };
  }, []);

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <ImageUploader
          type="single"
          useCircularCrop={false}
          initialValue={initialModel?.thumbnail}
          cropSize={{ width: 1200, height: 1200 }}
          attachType="media"
          useCrop={false}
          multipleUpload
          onSelected={(thumbnail: any) => {
            updateServiceModel('thumbnail', thumbnail);
            setLocalModel('thumbnail', thumbnail);
          }}
        />
      </div>
      <ItemSectionStyle>
        <ItemSectionTitle>타이틀</ItemSectionTitle>
        <ItemContent>
          <TitleForm
            onChangeValue={(value: string) => {
              updateServiceModel('title', value);
              setLocalModel('title', value);
            }}
            initialValue={initialModel?.title}
            placeHolder="타이틀을 작성해 주세요"
            label="타이틀"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>링크주소</ItemSectionTitle>
        <ItemContent>
          <UrlForm
            onChangeValue={(value: string) => {
              updateServiceModel('linkUrl', value);
              setLocalModel('linkUrl', value);
            }}
            initialValue={initialModel?.linkUrl}
            label="링크주소"
          />
        </ItemContent>
      </ItemSectionStyle>
      <CommonSticker
        initialValue={initialModel?.stickers}
        initialPosition={initialModel?.stickerPosition}
        onChange={(stickers: StickerTypes[]) => {
          updateServiceModel('stickers', stickers);
        }}
        onChangePosition={(position: 'top' | 'bottom') => {
          updateServiceModel('stickerPosition', position);
        }}
      />
      <ItemSectionStyle>
        <MenuItemCheckBox
          onChangeValue={(value: boolean) => {
            updateServiceModel('useTransparent', value);
          }}
          initialValue={initialModel?.useTransparent}
          title="투명배경"
        />
        <MenuItemCheckBox
          initialValue={initialModel?.openNewWindow || true}
          onChangeValue={(value: boolean) => {
            updateServiceModel('openNewWindow', value);
          }}
          title="새창열기"
        />
      </ItemSectionStyle>
      <ImageLayout
        initialValue={initialModel?.layout || 'TypeE'}
        onChange={(value: string) => {
          updateServiceModel('layout', value);
          setLocalModel('layout', value);
        }}
      />
      <CommonAlign
        initialValue={initialModel?.contentAlign || 'center'}
        title="타이틀 정렬"
        onChange={(value: string) => {
          updateServiceModel('contentAlign', value);
        }}
      />
      <ActionButton isValid={isValid} serviceModel={serviceModel} />
    </div>
  );
};

export default Image;
