import { Avatar } from '@mui/material';
import React, { CSSProperties, useEffect, useState } from 'react';

const ProfileAvatar = ({
  src,
  size = '150',
  style,
}: {
  src?: string;
  size?: '150' | '300';
  style?: CSSProperties;
}) => {
  const [profileUrl, setProfileUrl] = useState<string>('');
  useEffect(() => {
    const newUrl = src?.replace(/\/Profile/, `/thumbnails/${size}/Profile`);
    setProfileUrl(newUrl || '');
  }, [src, size]);

  return (
    <>
      <Avatar src={profileUrl} style={style} />
    </>
  );
};

export default ProfileAvatar;
