import React, { CSSProperties, useEffect, useState } from 'react';
import { isValid } from 'libs/helper/utils';
import styled, { css } from 'styled-components';
import { IChannelProfile } from 'types/BlockExtendsTypes';

/**
 * @description 배경이미지를 표현한다.!!
 */
const BackgroundImageComponent = ({
  style,
  profile,
  isPreview,
  useEmpty,
  colorMode = false,
  onClick,
}: {
  style?: CSSProperties;
  isPreview?: boolean;
  profile?: IChannelProfile | any;
  useEmpty: boolean;
  colorMode?: boolean;
  onClick?: () => void;
}) => {
  const [backgroundImage, setBackgroundImage] = useState<string | undefined>();
  const [backgroundColor, setBackgroundColor] = useState<string | undefined>();

  useEffect(() => {
    if (isValid(profile)) {
      // Todo
      //profile?.backgroundProps 는 추후 제거할것
      if (typeof profile?.metadata !== 'undefined') {
        if (
          profile?.backgroundType === 'image' ||
          profile?.backgroundProps?.backgroundType === 'image'
        ) {
          setBackgroundImage(profile?.backgroundImage?.publicUrl);
          setBackgroundColor(undefined);
        } else {
          setBackgroundImage(undefined);
          setBackgroundColor(
            profile?.contentStyle?.backgroundColor ?? profile?.backgroundProps?.backgroundColor,
          );
        }
      } else {
        setBackgroundImage(undefined);
        setBackgroundColor(undefined);
      }
    }
  }, [profile]);

  // useEffect(() => {
  //   // Resize 이벤트 핸들러
  //   const handleResize = () => {
  //     setWindowHeight(window.innerHeight);
  //   };

  //   // Resize 이벤트 리스너 추가
  //   window.addEventListener('resize', handleResize);

  //   // 컴포넌트 언마운트 시 이벤트 리스너 제거
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);
  return (
    <>
      <ImageBackgroundContainerStyle
        isPreview={isPreview}
        backgroundImage={backgroundImage}
        onClick={() => {
          if (typeof onClick === 'function') {
            onClick();
          }
        }}
        style={{
          ...style,
          background: backgroundColor,
          // height: `${windowHeight}px`,
        }}
      >
        {/* {typeof backgroundImage !== 'undefined' && <ImageBackgroundStyle src={backgroundImage} />} */}
      </ImageBackgroundContainerStyle>
    </>
  );
};

const PreviewStyle = css`
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const backgroundStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  min-height: 100lvh;
  padding-bottom: env(safe-area-inset-bottom);
`;

const ImageBackgroundContainerStyle = styled.div<{ backgroundImage?: string; isPreview?: boolean }>`
  pointer-events: none;
  z-index: -1;
  ${(props) => {
    if (props.isPreview === true) {
      return PreviewStyle;
    } else {
      return backgroundStyle;
    }
  }};

  background: #efefef;

  ${(props) =>
    props.backgroundImage &&
    css`
      background-image: url(${props.backgroundImage});
      background-size: cover;
      background-repeat: none;
      background-position: center center;
    `}
  pointer-events: none;
`;

export default BackgroundImageComponent;
