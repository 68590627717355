import React, { useEffect, useState } from 'react';
import { OhmossButton } from 'resources/controls/OhmossButton';
import styled from 'styled-components';
import { ChannelDto, MembershipDto, MembershipGiftDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import MembershipController from 'controllers/MembershipController';
import CommonBottomsheetHeader from 'features/myPage/components/CommonBottomSheetHeader';
import MembershipChangeInfo from 'features/myPage/components/membership/MembershipChangeInfo';
import { useRootContext } from 'libs/hooks/useRootContext';
import ReceivedGiftInfoCard from 'features/myPage/components/membership/ReceivedGiftInfoCard';
import { Row } from 'components/commons/layouts';
import MembershipInfoCard from '../../components/membership/MembershipInfoCard';
import MembershipPaymentHistory from '../../components/membership/MembershipPaymentHistory';
import MembershipTerms from './MembershipTerms';
import Text from 'components/commons/Text';
import { useMediaContext } from 'providers/useMediaContext';

const MyMembershipInfo = ({ channelType }: { channelType?: ChannelDto['channelType'] }) => {
  const { uiStore } = useStore();
  const { myChannel } = useRootContext();
  const ScreenTypes = useMediaContext();

  const { getCurrentMembership, getPreviousMemberships, getSentGifts, getReceivedGifts } =
    MembershipController();

  const [selectedTab, setSelectedTab] = useState<'내 멤버십' | '멤버십 결제정보'>('내 멤버십');
  const [myMembership, setMyMembership] = useState<MembershipDto>();
  const [allMemberships, setAllMemberships] = useState<MembershipDto[]>([]);
  const [receivedGifts, setReceivedGifts] = useState<MembershipGiftDto[]>();

  const getMyMembership = async () => {
    const result = (await getCurrentMembership()) as any;
    if (result) {
      setMyMembership(result);
    }
  };

  const getMembershipHistory = async () => {
    const result = (await getPreviousMemberships()) as any;
    if (result) {
      setAllMemberships((prev) => [...prev, ...result]);
    }
  };

  const getMySentGifts = async () => {
    if (myChannel?.channelType === 'BRAND') {
      const result = (await getSentGifts(myChannel?.id as number)) as any;
      setAllMemberships((prev) => [...prev, ...result]);
    }
  };

  const getMyReceivedGifts = async () => {
    if (myChannel?.channelType === 'SELLER') {
      const result = (await getReceivedGifts(myChannel?.id as number)) as any;
      setReceivedGifts(result);
    }
  };

  const handleChangeMembership = () => {
    uiStore.bottomSheet.show({
      style: { minHeight: '50vh', height: 'max-content' },
      header: (
        <CommonBottomsheetHeader
          title="멤버십 변경"
          styles={{ color: '#fff', backgroundColor: '#011208' }}
        />
      ),
      children: <MembershipChangeInfo orderId={myMembership?.id} />,
    });
  };

  const handleShowTerms = () => {
    uiStore.modal.show({
      style: { height: '100%', width: '100%' },
      children: <MembershipTerms />,
    });
  };

  useEffect(() => {
    if (myChannel) {
      getMyMembership();
      getMembershipHistory();
      getMySentGifts();
      getMyReceivedGifts();
    }
  }, [myChannel]);

  return (
    <div>
      {/* <Row style={{ margin: 20, borderRadius: 10, overflow: 'hidden', width: 'unset' }}>
        <MembershipInfoTab
          position="left"
          onClick={() => {
            setSelectedTab('내 멤버십');
          }}
          selected={selectedTab === '내 멤버십'}
        >
          내 멤버십
        </MembershipInfoTab>
        <MembershipInfoTab
          position="right"
          onClick={() => {
            setSelectedTab('멤버십 결제정보');
          }}
          selected={selectedTab === '멤버십 결제정보'}
        >
          멤버십 결제정보
        </MembershipInfoTab>
      </Row> */}
      <div style={{ padding: 20 }}>
        {selectedTab === '내 멤버십' && (
          <>
            <MembershipInfoCard channelType={channelType} membership={myMembership} />
            <div style={{ marginBottom: 20 }} />
            {myChannel?.channelType === 'SELLER' && receivedGifts && (
              <ReceivedGiftInfoCard channelType={channelType} membership={receivedGifts} />
            )}

            {typeof myMembership !== 'undefined' ? (
              <Terms>
                <div>환불조건등 서비스 이용 약관 안내 보기</div>
                <div
                  onClick={handleShowTerms}
                  style={{
                    fontSize: 12,
                    textDecoration: 'underline',
                    color: 'rgba(0, 0, 0, 0.35)',
                    cursor: 'pointer',
                  }}
                >
                  약관보기
                </div>
              </Terms>
            ) : (
              <>
                <Terms>멤버십 업그레이드로 다양한 혜택을 누려보세요.</Terms>
                <Text buttonRole="DESCRIPTION">
                  카드심사를 위한 결제안내로 실제 결제는 이루어지지 않습니다.
                </Text>
              </>
            )}
          </>
        )}
        {selectedTab === '멤버십 결제정보' && (
          <>
            {allMemberships.length === 0 ? (
              <div style={{ textAlign: 'center' }}>결제 내역이 없습니다.</div>
            ) : (
              <MembershipPaymentHistory history={allMemberships} />
            )}
          </>
        )}
      </div>
      {selectedTab === '내 멤버십' && (
        <div style={{ width: '100%', padding: 20 }}>
          <OhmossButton
            style={{ width: ScreenTypes.isLarge ? 300 : '100%' }}
            type="primaryPc"
            onClick={handleChangeMembership}
          >
            멤버십 변경
          </OhmossButton>
        </div>
      )}
    </div>
  );
};

const MembershipInfoTab = styled.div<{ selected?: boolean; position: 'left' | 'right' }>`
  display: flex;
  cursor: pointer;
  flex-grow: 1;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border: ${(props) => (props.selected ? '#011208' : '0.5px solid #bebebe')};
  background: ${(props) => (props.selected ? '#011208' : ' #fff')};
  ${(props) =>
    props.position === 'left'
      ? 'border-top-left-radius: 10px; border-bottom-left-radius: 10px;'
      : 'border-top-right-radius: 10px; border-bottom-right-radius: 10px;'}

  color: ${(props) => (props.selected ? '#9DE4BC' : ' #cacaca')};
`;

const Terms = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  padding: 20px 0px 0px 0px;
`;

export default MyMembershipInfo;
