import React, { useEffect, useState } from 'react';
import { Row } from 'components/commons/layouts';

import SBConversation from '@sendbird/uikit-react/GroupChannel';
import { useChat } from 'libs/hooks/useChat';
import LinkController from 'controllers/LinkController';
import { ChatModel } from 'types/CommonTypes';
import { useRootContext } from 'libs/hooks/useRootContext';
import chatController from 'features/community/controllers/chatController';
import { LinkDto } from 'services/data-contracts';

/** @description 고객별 문의 쓰레드 */
interface DealInquiryByCustomerProps {
  chatInfo?: ChatModel;
  chatroomUrl?: string;
  joinType?: 'INQUIRY' | 'CHAT';
  type?: 'new' | 'edit';
}

const ChatContainer: React.FC<DealInquiryByCustomerProps> = ({
  chatInfo,
  joinType = 'INQUIRY',
  chatroomUrl,
  type,
}) => {
  const { getDealMembers } = LinkController();

  const { createInquiryChannel, createChatroom } = chatController();
  const { myChannel } = useRootContext();
  const [currentChannelUrl, setCurrentChannelUrl] = useState<string>();

  const createInquiry = async () => {
    if (chatInfo) {
      if (myChannel) {
        let invitedMembers;
        if (chatInfo?.deal) {
          invitedMembers = (await getDealMembers(
            chatInfo.deal?.linkKey as string,
          )) as unknown as string[];
          const memberChannel = invitedMembers.map((member) => {
            return { channelName: member, profile: { nickname: member } };
          });
          const [groupChannel, error] = await createInquiryChannel(
            chatInfo.operators,
            chatInfo?.deal as LinkDto,
            memberChannel,
            chatInfo.deal?.title as string,
            chatInfo.deal?.linkKey,
          );
          setCurrentChannelUrl(groupChannel.url);
        } else {
          const [groupChannel, error] = await createChatroom(chatInfo.operators, chatInfo.members);
          setCurrentChannelUrl(groupChannel.url);
        }

        hideSettingButton();
      }
    }
  };

  const joinChat = async () => {
    if (chatInfo) {
      if (myChannel) {
        const [groupChannel, error] = await createChatroom(chatInfo.operators, chatInfo.members);
        setCurrentChannelUrl(groupChannel.url);
        hideSettingButton();
      }
    }
  };

  const hideSettingButton = () => {
    const temp = document.querySelector('.sendbird-chat-header__right') as any;
    if (temp) {
      temp.style.display = 'none';
    }
  };

  useEffect(() => {
    if (chatInfo && joinType) {
      if (joinType === 'INQUIRY') {
        createInquiry();
      } else {
        joinChat();
      }
    }
  }, [chatInfo, joinType]);

  useEffect(() => {
    if (chatroomUrl) {
      setCurrentChannelUrl(chatroomUrl);
    }
  }, [chatroomUrl]);

  return (
    <>
      {type !== 'new' && (
        <div style={{ background: '#fff' }}>
          <div>
            {/* <DealInfoForCustomer item={item} /> */}
            {/* <CustomerInfoBox channel={myChannel as ChannelDto} /> */}
          </div>
        </div>
      )}

      <div style={{ flexGrow: 1, height: '100%' }}>
        <Row style={{ justifyContent: 'flex-start', alignItems: 'flex-start', height: '100%' }}>
          <div style={{ height: '100%', width: '100%' }}>
            {currentChannelUrl && <SBConversation channelUrl={currentChannelUrl} />}
          </div>
        </Row>
      </div>
    </>
  );
};

export default ChatContainer;
