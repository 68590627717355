import { makeAutoObservable } from 'mobx';
import { Term as TermService } from 'services/Term';
// import { TermDto } from 'services/data-contracts';
import { HttpClient } from 'services/http-client';

class TermStore {
  constructor() {
    makeAutoObservable(this);
  }

  api: TermService<unknown> = new TermService(new HttpClient());

  terms: Array<any> = [];

  *getTerms() {
    const { response } = yield this.api.getTerms();
    if (response.status === 200) {
      this.terms = response.data;
    }
  }
}

export default TermStore;
