import { makeAutoObservable, toJS } from 'mobx';
import { FileUpload as FileUploadService } from 'services/FileUpload';

// import _ from "lodash";
import { REST_API_URL_V2 } from 'stores/AppStore';

import _ from 'lodash';
import { FileDto } from 'services/data-contracts';
import { HttpClient } from 'services/http-client';
import { File } from 'services/data-contracts';

class FileStore {
  constructor() {
    makeAutoObservable(this);
  }

  api: FileUploadService<unknown> = new FileUploadService(new HttpClient());

  /** 링크 이미지 업로드 */
  *uploadImage(file: any, fileType: File['type']) {
    if (typeof file.publicUrl !== 'undefined') {
      delete file.publicUrl;
    }
    if (typeof fileType !== 'undefined') {
      const { response } = yield this.api.uploadFile({ fileType }, { file });
      if (response.status < 300) {
        return response.data as File;
      } else {
        return response.error;
      }
    }
  }

  // _base64ToArrayBuffer(base64: any) {
  //   var binary_string = window.atob(base64);
  //   var len = binary_string.length;
  //   var bytes = new Uint8Array(len);
  //   for (var i = 0; i < len; i++) {
  //     bytes[i] = binary_string.charCodeAt(i);
  //   }
  //   return bytes.buffer;
  // }
}

export default FileStore;
