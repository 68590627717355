import React, { useEffect, useRef, useState } from 'react';

import { PartnerDto } from 'services/data-contracts';
import PartnerController from 'controllers/PartnerController';
import { Row } from 'components/commons/layouts';
import { OhmossButton } from 'resources/controls/OhmossButton';
import Text from 'components/commons/Text';
import useNavigation from 'hooks/useNavigation';
import { Divider } from 'features/myPage/components/CommonStyleComponent';
import { useStore } from 'stores/StoreHelper';
import CSPartnerListItem from '../components/pc/CSPartnerListItem';

interface NewPartnerModel extends PartnerDto {
  isPartner?: boolean;
}

/**
 * @description 셀러가 파트너를 추가하기 위해 사용하는 컴포넌트
 * @param param0
 * @returns
 */
const CSPartnerList = ({
  type,
  recentPartners,
  onSelect,
}: {
  type?: 'ADD_ITEM';
  recentPartners?: PartnerDto[];
  onSelect: (partner: PartnerDto) => void;
}) => {
  const recentPartnersRef = useRef<NewPartnerModel[]>();
  const { uiStore } = useStore();
  const { getPartners } = PartnerController();
  const { navigate } = useNavigation();

  const [partnerList, setPartnerList] = useState<NewPartnerModel[]>();

  const setTargetPartners = async () => {
    const partnersListTemp = await getPartners();
    const newPartners: NewPartnerModel[] = partnersListTemp.map((partner: PartnerDto) => {
      const temp = recentPartners?.find(
        (np) => np.partnerChannel?.id === partner?.partnerChannel?.id,
      );
      return {
        ...partner,
        isPartner: typeof temp !== 'undefined',
      };
    });
    setPartnerList(newPartners);
  };

  const handleOnSelect = async (partner: PartnerDto) => {
    recentPartnersRef.current?.push(partner);
    onSelect(partner);
    setTargetPartners();
  };

  useEffect(() => {
    if (recentPartners) {
      recentPartnersRef.current = recentPartners;
      setTargetPartners();
    }
  }, [recentPartners]);

  return (
    <div style={{ padding: 20 }}>
      {partnerList?.map((partner) => (
        <Row style={{ justifyContent: 'space-between' }} key={partner?.id}>
          <CSPartnerListItem partner={partner} />
          <>
            {type === 'ADD_ITEM' && (
              <>
                {partner?.isPartner ? (
                  <div style={{ width: 100, textAlign: 'center' }}>추가됨</div>
                ) : (
                  <OhmossButton
                    style={{ width: 80, height: 30, padding: 20 }}
                    type="square"
                    onClick={() => handleOnSelect(partner)}
                  >
                    추가
                  </OhmossButton>
                )}
              </>
            )}
          </>
        </Row>
      ))}
      <div style={{ padding: 20, border: '1px solid #efefef', borderRadius: 10, marginTop: 20 }}>
        <Text style={{ marginBottom: 10 }}>
          신규 파트너초대는{' '}
          <span>
            <b>파트너관리</b>
          </span>
          에서 가능합니다.
        </Text>
        <OhmossButton
          type="primaryPc"
          style={{ width: 200, height: 45 }}
          onClick={() => {
            uiStore.drawer.close();
            navigate('/cstown/partners');
          }}
        >
          파트너관리
        </OhmossButton>
      </div>
    </div>
  );
};

export default CSPartnerList;
