import { RoutePropInterface } from 'types/CommonTypes';
import MagPage from 'features/mag/pages/MagPage';
import MagProvider from 'features/management/context/providers/MagProvider';
import MagDetailPage from 'features/mag/pages/MagDetailPage';

const magRoutes: RoutePropInterface[] = [
  {
    path: '/mag',
    title: 'mag page',
    element: MagPage,
    provider: MagProvider,
    isPublic: true,
  },
  {
    path: '/mag/:magazineid',
    title: 'mag page',
    element: MagDetailPage,
    provider: MagProvider,
    isPublic: true,
  },
];

export default magRoutes;
