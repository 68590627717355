import { createContext, useContext, Context as ReactContext, ReactNode } from 'react';
import { ReceiverType } from 'types/CommonTypes';

interface PropsTypes<T> {
  stateModel?: T;
  setMultipleState(updates: Partial<T>): void;
  subMenus: ReactNode;
  pageTitle: string;
  setPageHeaderTitle: (title: string) => void;
  broadcast: (message: ReceiverType) => void;
  receiver: ReceiverType;
  setSubMenus: React.Dispatch<React.SetStateAction<React.ReactChild | undefined | string>>;
  setPageState: (key: keyof T, value: T[keyof T]) => void;
}

const Context = createContext<(PropsTypes<any> & any) | undefined>(undefined);

function useStateContext<T>(): PropsTypes<T> & T {
  const context = useContext(Context as ReactContext<(PropsTypes<T> & T) | undefined>);
  if (!context) {
    throw new Error('useStateContext must be used within a Provider that has a value');
  }
  return context;
}

export { useStateContext, Context, PropsTypes };
