import React, { useEffect } from 'react';

import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';
import { Row } from 'components/commons/layouts';

import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Avatar } from '@mui/material';

const AssignedDealItem = ({
  deal,
  mode = 'edit',
  index,
  onChanged,
  onClickMore,
}: {
  deal?: LinkDtoWithAssignedDeal;
  mode?: 'edit' | 'view';
  index?: number;
  onChanged?: (value: boolean) => void;
  onClickMore?: () => void;
}) => {
  return (
    <DealContainer
      key={index}
      style={{ cursor: 'pointer' }}
      // onClick={() => handleDealsClicked(deal)}
    >
      {mode === 'edit' && (
        <div>
          <Checkbox
            checked={deal?.assigned}
            defaultChecked={deal?.assigned}
            onChange={(e: any, value: boolean) => {
              onChanged?.(value);
            }}
          />
        </div>
      )}
      <Row style={{ justifyContent: 'flex-start' }}>
        <div>
          <Row>
            <ImageStyle url={deal?.thumbnail?.publicUrl ?? ''} />
            <>
              {mode === 'edit' && (
                <div style={{}}>
                  {typeof deal?.dealMembers !== 'undefined' && deal.dealMembers?.length > 0 && (
                    <Row style={{ position: 'relative' }}>
                      {deal?.dealMembers?.slice(0, 2).map((member, i) => (
                        <div style={{ width: 30 }}>
                          <Avatar style={{ position: 'relative', left: -10 }} />
                          {i === 1 && deal?.dealMembers!.length > 2 && (
                            <MoreStyle style={{}}>+{deal?.dealMembers!.length - 2}</MoreStyle>
                          )}
                        </div>
                      ))}
                    </Row>
                  )}
                </div>
              )}
            </>
          </Row>
        </div>
        <DealTitle>{deal?.title}</DealTitle>
        <>
          {console.log(onClickMore)}
          {onClickMore && (
            <StyledIcon
              onClick={() => {
                onClickMore?.();
              }}
            />
          )}
        </>
      </Row>
    </DealContainer>
  );
};

const MoreStyle = styled.div`
  width: 20px;
  height: 20px;
  font-size: 10px;
  background: #173300;
  border-radius: 50px;
  color: white;
  position: absolute;
  top: 0;
  right: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ImageStyle = styled.div<{ url: string }>`
  width: 50px;
  height: 50px;
  border-radius: 25%;
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
`;

const StyledIcon = styled(ArrowForwardIosIcon)<{ isselected?: boolean }>`
  padding: 5px;
  color: #bebebe;
  position: absolute;
  right: 30px;
`;

const DealContainer = styled.div<{ isselected?: boolean }>`
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-direction: row;

  border-bottom: 0.5px solid #cacaca;
  padding: 15px 30px 10px 15px;
  gap: 10px;
`;

const DealTitle = styled.div`
  font-size: 14px;
  color: #48505d;
  font-weight: 400;
  margin-left: 10px;
`;

export default AssignedDealItem;
