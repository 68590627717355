import { useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';

import useParam from 'libs/hooks/useParam';

function useLocalParams() {
  const { get } = useParam();
  const { channelid, boardid, categoryid, postid, adid, channelname, linkkey, memberid } =
    useParams();

  const channelName = useRef<string | null | undefined>();
  const channelId = useRef<number>();
  const memberId = useRef<number>();
  // boardId와 CategoryId는 통합하여야 함.
  const boardId = useRef<number | undefined>();
  const categoryId = useRef<number | undefined>();
  const postId = useRef<number>();
  const adId = useRef<number>();
  const linkKey = useRef<string>();

  const getPrimitiveParams = () => {
    const channelIdTemp =
      get('channelid', 'number') || (Number.parseInt(channelid || '') as number);
    // const boardIdTemp = get('boardid', 'number') || (Number.parseInt(boardid || '') as number);
    const boardIdTemp = (Number.parseInt(boardid || '') as number) || get('boardid', 'number');
    const categoryTemp =
      get('categoryid', 'number') || (Number.parseInt(categoryid || '') as number);
    const postIdTemp = get('postid', 'number') || Number.parseInt(postid || '');
    const adIdTemp = get('adid', 'number') || Number.parseInt(adid || '');
    const channelNameTemp = channelname || '';
    const linkKeyTemp = linkkey || '';
    const memberIdTemp = get('memberId', 'number') || (Number.parseInt(memberid || '') as number);

    boardId.current = typeof boardIdTemp === 'string' ? NaN : boardIdTemp;
    categoryId.current = typeof categoryTemp === 'string' ? NaN : categoryTemp;
    channelName.current = channelNameTemp.length > 0 ? channelNameTemp : null;
    channelId.current = typeof channelIdTemp === 'string' ? NaN : channelIdTemp;
    postId.current = typeof postIdTemp === 'string' ? NaN : postIdTemp;
    adId.current = typeof adIdTemp === 'string' ? NaN : adIdTemp;
    linkKey.current = typeof linkKeyTemp === 'string' ? linkKeyTemp : '';
    memberId.current = typeof memberIdTemp === 'string' ? NaN : memberIdTemp;
  };
  getPrimitiveParams();
  useEffect(() => {
    getPrimitiveParams();
  }, []);

  return {
    channelName: channelName.current,
    channelId: channelId.current,
    boardId: boardId.current,
    categoryId: categoryId.current,
    postId: postId.current,
    adId: adId.current,
    linkKey: linkKey.current,
    memberId: memberId.current,
  };
}

export default useLocalParams;
