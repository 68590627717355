import React from 'react';
import styled from 'styled-components';
import { LandingPageWrapper } from './LandingPageWrapper';
import { BodyContainer, CenterImg, DetailText, MainText, SubText, TextContainer } from './Styles';

const LandingSlideD = () => {
  return (
    <LandingPageWrapper>
      <BodyContainer>
        <TextContainer>
          <DetailText>CS TOWN</DetailText>
          <MainText>
            손쉽게 CS 하면서
            <br />
            확실하게 고객응대하기
          </MainText>
          <SubText>
            매니저 CS초대기능을 사용해서 브랜드(에이전시)를 초대해 공구 CS의 도움을 받을 수
            있습니다. 빠른 응대로 고객만족도를 높이고, 셀러의 피곤함을 낮출 수 있습니다.
          </SubText>
        </TextContainer>
        <CenterImg>
          <img alt="left" src="/images/landing/slided_image1.png" style={{}} />
        </CenterImg>
      </BodyContainer>
    </LandingPageWrapper>
  );
};

export default LandingSlideD;
