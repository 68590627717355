import React, { useEffect, useState, useRef } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { IChannelBoardModel, IGalleryBlock, IPostModel } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';

import { useStore } from 'stores/StoreHelper';
import { CustomFile, LinkItemModel } from 'types/CommonTypes';
import { validationHelper } from 'controls/validationHelper';

import WebEditor from 'components/commons/webEditor/Editor';
import TitleForm from 'components/items/forms/TitleForm';
import UrlForm from 'components/items/forms/UrlForm';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import MultipleImageHeader from 'components/commons/imageUploader/MultipleImageHeader';
import MultipleImageSelector from 'components/commons/imageUploader/MultipleImageSelector';
import MyShopController from 'controllers/MyShopController';

import { PostDto } from 'services/data-contracts';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Required from 'components/commons/Required';

const MyShopItemCreator = ({ item }: { item?: IPostModel }) => {
  const editorRef = useRef<any>();

  const { uiStore } = useStore();
  const { updateShopItem, deletePost } = MyShopController();

  const defaultProps: Partial<IGalleryBlock & IPostModel> = {
    isActive: true,
    isDirectLink: false,
  };

  const { initialModel, initialBinding, updateServiceModel, serviceModel } =
    useModelManagement<Partial<IPostModel>>();

  const [localModel, setLocalModel] = useInitializeModel<IPostModel>(
    item,
    defaultProps,
    initialBinding,
  );

  const [galleryItems, setGalleryItems] = useState<LinkItemModel[]>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [currency, setCurrency] = useState<Array<string>>([
    'KRW',
    'USD',
    'EUR',
    'JPY',
    'CNY',
    'CAD',
    'GBP',
    'SEK',
    'CHF',
    'DKK',
    'CZK',
    'PLN',
    'NZD',
    'AUD',
    'TWD',
    'TRY',
    'VND',
    'SGD',
    'PHP',
    'HKD',
  ]);

  const initializeModel = () => {
    if (item?.items) {
      setGalleryItems(item.items);
    }
  };

  const handleSetItems = (newItems: CustomFile[]) => {
    const lastSeq = typeof galleryItems === 'undefined' ? 0 : galleryItems?.length;

    // 이미지를 아이템으로 변환.
    const items: LinkItemModel[] = newItems?.map((newItem, index: number) => {
      return {
        file: newItem,
        seq: lastSeq + index + 1,
        isActive: true,
        linkUrl: '',
      };
    }) as LinkItemModel[];
    setGalleryItems([...(galleryItems || []), ...items]);
  };

  // console.log(item?.board?.id, item?.id);

  const handleClickDeletePost = () => {
    uiStore.confirm.show({
      title: '상품을 삭제하시겠습니까?',
      onConfirmed: async () => {
        if (item) {
          const result = (await deletePost(
            item?.board?.id as number,
            item?.id as number,
          )) as unknown as PostDto;
          uiStore.modal.confirm(result);
        }
      },
    });
  };

  const handleChange = (event: SelectChangeEvent) => {
    const selectedCurrency = event.target.value as any;
    setLocalModel('thumbnailPriceUnit', selectedCurrency);
    updateServiceModel('thumbnailPriceUnit', selectedCurrency);
  };

  useEffect(() => {
    initializeModel();
    return () => {
      initialBinding(undefined);
    };
  }, [item]);

  useEffect(() => {
    if (initialModel) {
      const parsedMetadata = JSON.parse(initialModel?.metadata ?? '{}');
      const isDirectLink = parsedMetadata?.isDirectLink ?? false;
      setLocalModel('isDirectLink', isDirectLink);
    }
  }, [initialModel]);

  useEffect(() => {
    if (localModel) {
      const title = validationHelper.required(localModel.title);
      const pictureValid = !!(galleryItems && galleryItems?.length > 0);
      let priceNumber = true;
      if (localModel.thumbnailPrice) {
        priceNumber = validationHelper.number(localModel.thumbnailPrice?.toString());
      }
      setIsValid(pictureValid && priceNumber && title);
    }
  }, [localModel, galleryItems]);

  return (
    <div style={{ padding: 20 }}>
      <Row style={{ marginBottom: 20, justifyContent: 'flex-start', marginRight: 20 }}>
        <MultipleImageSelector
          attachType="media"
          onSelected={(thumbnails: CustomFile[]) => {
            if (thumbnails.length > 0) {
              handleSetItems(thumbnails);
            }
          }}
        />
        <MultipleImageHeader
          onChange={(items: LinkItemModel[]) => {
            setGalleryItems(items);
            setLocalModel('items', galleryItems);
          }}
          items={galleryItems}
        />
      </Row>
      <ItemSectionStyle>
        <ItemSectionTitle>링크 주소</ItemSectionTitle>
        <ItemContent>
          <UrlForm
            onChangeValue={(value: string) => {
              updateServiceModel('linkUrl', value);
            }}
            // onBlur={(value: string, valid: boolean) => {
            //   handleChangeItemUrl(value, valid);
            // }}
            initialValue={item?.linkUrl}
            placeHolder="링크주소를 입력해 주세요"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <MenuItemCheckBox
          onChangeValue={(value: boolean) => {
            setLocalModel('isDirectLink', value);
            updateServiceModel('isDirectLink', value);
          }}
          initialValue={localModel?.isDirectLink ?? initialModel?.isDirectLink}
          title="링크 바로가기"
        />
        <MenuItemCheckBox
          onChangeValue={(value: boolean) => {
            setLocalModel('isDirectLink', !value);
            updateServiceModel('isDirectLink', !value);
          }}
          initialValue={!localModel?.isDirectLink ?? !initialModel?.isDirectLink}
          title="팝업 보기"
        />
        <MenuItemCheckBox
          onChangeValue={(value: boolean) => {
            updateServiceModel('openNewWindow', value);
          }}
          initialValue={initialModel?.openNewWindow}
          title="새창으로 열기"
        />
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>
          타이틀
          <Required />
        </ItemSectionTitle>
        <ItemContent>
          <TitleForm
            required={false}
            onChangeValue={(value: string) => {
              updateServiceModel('title', value);
              setLocalModel('title', value);
            }}
            initialValue={initialModel?.title}
            placeHolder="타이틀을 작성해 주세요"
            label="타이틀"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>내용</ItemSectionTitle>
        <ItemContent>
          <WebEditor
            ref={editorRef}
            onEditorChange={(contents: string) => {
              // set('content', contents);
            }}
            initialValue={initialModel?.content}
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>썸네일 타이틀</ItemSectionTitle>
        <ItemContent>
          <TitleForm
            required={false}
            onChangeValue={(value: string) => {
              updateServiceModel('thumbnailTitle', value);
              // setLocalModel('title', value);
            }}
            initialValue={initialModel?.thumbnailTitle}
            placeHolder="타이틀을 작성해 주세요"
            label="타이틀"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>썸네일 내용</ItemSectionTitle>
        <ItemContent>
          <TitleForm
            required={false}
            onChangeValue={(value: string) => {
              setLocalModel('thumbnailContent', value);
              updateServiceModel('thumbnailContent', value);
            }}
            initialValue={initialModel?.thumbnailContent}
            placeHolder="타이틀을 작성해 주세요"
            label="타이틀"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>썸네일 가격</ItemSectionTitle>
        <ItemContent>
          <Row style={{ width: '100%', gap: 10 }}>
            <TitleForm
              validationType="number"
              errorText="숫자만 입력해 주세요"
              required={false}
              onChangeValue={(value: string) => {
                if (!Number.isNaN(Number(value))) {
                  setLocalModel('thumbnailPrice', Number(value));
                  updateServiceModel('thumbnailPrice', Number(value));
                } else {
                  setIsValid(false);
                }
              }}
              initialValue={initialModel?.thumbnailPrice?.toString()}
            />
            <Box
              sx={{
                minWidth: '30%',
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">화폐 단위</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={localModel?.thumbnailPriceUnit ?? initialModel?.thumbnailPriceUnit}
                  label="화페 단위"
                  onChange={handleChange}
                >
                  {currency.map((item) => (
                    <MenuItem
                      key={item}
                      value={item}
                      sx={{
                        '&:hover': { backgroundColor: '#eee' },
                        '&.Mui-selected': { backgroundColor: '#96E06E' },
                      }}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Row>
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle
          onClick={handleClickDeletePost}
          style={{ color: 'red', fontSize: 14, fontWeight: 600, cursor: 'pointer' }}
        >
          삭제
        </ItemSectionTitle>
      </ItemSectionStyle>

      <Row style={{ gap: 10 }}>
        <OhmossButton
          type="box"
          style={{ color: '#000' }}
          onClick={() => {
            uiStore.modal.close();
          }}
        >
          취소
        </OhmossButton>
        <OhmossButton
          onClick={async () => {
            if (serviceModel) {
              const data = editorRef.current.getData();
              updateServiceModel('content', data);
              updateServiceModel('items', galleryItems);
              const result = await updateShopItem(serviceModel);
              uiStore.modal.confirm(result);
            }
          }}
          disabled={!isValid}
        >
          적용
        </OhmossButton>
      </Row>
    </div>
  );
};

const CurrencyContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
`;

const CurrencyStyle = styled.div`
  display: flex;
  padding: 0.5rem;
  justify-content: center;
  text-align: center;
  font-size: 0.75rem;
  align-items: center;
  border-radius: 1rem;
  border: 0.3px solid rgba(0, 0, 0, 0.54);
`;

export default MyShopItemCreator;
