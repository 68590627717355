import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { PostItemDto } from 'services/data-contracts';
import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore, { EffectCoverflow, Pagination, Autoplay } from "swiper";
import { Autoplay, Pagination, Navigation } from 'swiper';
import styled from 'styled-components';
import VideoComponent from 'features/adminFeatures/commons/components/VideoComponent';
import useNavigation from 'libs/hooks/useNavigate';

import 'swiper/css/pagination';
import 'swiper/css';

const PostSwipeItem = observer(
  ({
    items,
    useSwipe,
    useAutoPlay,
  }: {
    items?: PostItemDto[];
    useSwipe?: boolean;
    useAutoPlay?: boolean;
  }) => {
    const imgRefs = useRef<any>([]);
    const navigate = useNavigation();

    const [imgBoxHeight, setImgBoxHeight] = useState<number>();
    const [swiperModuls, setSwiperModuls] = useState<any>([Pagination]);
    const [initialized, setInitialized] = useState<boolean>(false);

    const handleClickImage = (card: PostItemDto) => {
      if (card.linkUrl) {
        return () => {
          navigate(card.linkUrl, true);
        };
      }
    };

    const setMaxHeight = () => {
      const heights: Array<number> = [];
      setTimeout(() => {
        for (let index = 0; index < imgRefs.current.length; index++) {
          const { height } = imgRefs.current[index].getBoundingClientRect();
          heights.push(height);
          if (imgRefs.current.length === heights.length) {
            setImgBoxHeight(Math.max(...heights));
          }
        }
      }, 1020);
    };

    useEffect(() => {
      if (useAutoPlay) {
        setSwiperModuls([Pagination, Autoplay, Navigation]);
      } else {
        setSwiperModuls([Pagination, Navigation]);
      }
      setInitialized(true);
    }, [useAutoPlay]);

    useEffect(() => {
      if (
        items &&
        items?.length > 0 &&
        imgRefs.current?.length === items?.length &&
        initialized === true
      ) {
        setMaxHeight();

        window.addEventListener('resize', setMaxHeight);
        return () => {
          window.removeEventListener('resize', setMaxHeight);
        };
      }
    }, [items, initialized]);

    return (
      <>
        {initialized === true && (
          <>
            <Swiper
              pagination
              centeredSlides={false}
              enabled={useSwipe || true}
              modules={swiperModuls}
              spaceBetween={0}
              style={{ zIndex: 0 }}
              slidesPerView={1}
              // loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
            >
              {items?.map((card: PostItemDto, index: number) => (
                <SwiperSlide key={card.id} style={{ alignItems: 'center', display: 'flex' }}>
                  <>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <div>
                        {card.file ? (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              background: '#000',
                              height: imgBoxHeight,
                            }}
                          >
                            {card.file?.contentType?.indexOf('image/') === 0 ? (
                              <>
                                <img
                                  onClick={handleClickImage(card)}
                                  ref={(el: any) => {
                                    // eslint-disable-next-line no-return-assign
                                    return (imgRefs.current[index] = el);
                                  }}
                                  className="feed-image"
                                  //  src={getFeedThmbnailUrl(card.file?.publicUrl)}
                                  src={card.file?.publicUrl}
                                  style={{
                                    width: '100%',
                                    height: 'auto',
                                    cursor: card.linkUrl ? 'pointer' : 'default',
                                  }}
                                  alt=""
                                />
                              </>
                            ) : (
                              // <VideoComponent
                              //   isCover={card.file?.sizeInfo?.isCover}
                              //   video={card.file}
                              // />
                              <div />
                            )}
                          </div>
                        ) : (
                          <NoImageStyle />
                        )}
                      </div>
                    </div>
                  </>
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        )}
      </>
    );
  },
);

const NoImageStyle = styled.div`
  width: 100%;
  min-height: 300px;
  background: #efefef;
`;
export default PostSwipeItem;
