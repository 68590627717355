import React, { useEffect, useRef, useState } from 'react';
import { ChannelDto, DealInquiryThreadDto } from 'services/data-contracts';
import { IconButton } from '@mui/material';
import { Row } from 'components/commons/layouts';

import styled from 'styled-components';
import TitleForm from 'components/items/forms/TitleForm';
import SendIcon from '@mui/icons-material/Send';
import StyledRefBox from 'components/commons/layouts/StyledRefBox';
import InquiryThreads from './InquiryThreads';

/** @description 고객별 문의 쓰레드 */
interface DealInquiryByCustomerProps {
  children?: React.ReactNode;
  customer?: ChannelDto;
  threads?: DealInquiryThreadDto[];
  isCustomer?: boolean;
  channelType?: ChannelDto['channelType'];
  onSend: (message: string) => void;
}

const ChatBox: React.FC<DealInquiryByCustomerProps> = ({
  children,
  customer,
  threads,
  isCustomer,
  channelType,
  onSend,
}) => {
  const message = useRef<string>('');
  const titleRef = useRef<any>(null);
  const scrollDiv = React.useRef<HTMLDivElement>(null);

  const [tempMessage, setTempMessage] = useState<DealInquiryThreadDto[]>([]);

  const handleClickSend = async () => {
    setTempMessage((prev) => [
      ...prev,
      {
        message: message.current,
        createdAt: new Date().toISOString(),
        sender: 'CUSTOMER',
      },
    ]);
    onSend(message.current);
    message.current = '';
    titleRef.current?.setValue('');
    scrollToBottom();
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollDiv.current) {
        scrollDiv.current.scrollTop = scrollDiv.current.scrollHeight + 100;
      }
    }, 100);
  };

  useEffect(() => {
    scrollToBottom();
  }, [scrollDiv]);

  return (
    <ChatContainerStyle>
      {children}
      <ThreadsStyle className="global-width">
        {typeof threads === 'undefined' ? (
          <DefaultWecomeText>
            안녕하세요 {customer?.channelName}님.
            <br /> 무엇이 궁금하신가요?
          </DefaultWecomeText>
        ) : (
          <></>
        )}
        <InquiryThreads
          isCustomer={isCustomer}
          threads={threads ?? tempMessage}
          channelType={channelType}
          customer={customer}
        />
      </ThreadsStyle>
      <ReplyBoxStyle>
        <Row>
          <TitleForm
            disabled={channelType === 'BRAND' && isCustomer === false}
            ref={titleRef}
            placeHolder={
              channelType === 'BRAND' && isCustomer === false
                ? '매니저 계정으로 답글 작성이 가능합니다.'
                : ''
            }
            multiline
            rows={2}
            onChangeValue={(value: string) => {
              message.current = value;
            }}
          />
          <IconButton
            onClick={handleClickSend}
            disabled={channelType === 'BRAND' && isCustomer === false}
          >
            <SendIcon />
          </IconButton>
        </Row>
      </ReplyBoxStyle>
    </ChatContainerStyle>
  );
};

const ChatContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: calc(100vh - 50px);
  box-sizing: border-box;
  background: #efefef;
`;

const DefaultWecomeText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 0.875rem;
  padding: 22px;
`;

const ReplyBoxStyle = styled.div`
  padding: 10px;
  background: #fff;
`;

const ThreadsStyle = styled(StyledRefBox)`
  flex-grow: 1;
  overflow-y: auto;
`;
export default ChatBox;
