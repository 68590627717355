import React, { useEffect, useState } from 'react';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import AgencyController from 'controllers/BrandController';
import { PartnerDto } from 'services/data-contracts';
import PartnersList from '../containers/PartnersList';
import SearchBox from 'components/commons/SearchBox';
import { useRootContext } from 'libs/hooks/useRootContext';

const DealPartnersPage = () => {
  const { myChannel } = useRootContext();
  const { getPartners, getInvitations } = AgencyController();

  const [activePartners, setActivePartners] = useState<PartnerDto[]>();
  const [invitations, setInvitations] = useState<PartnerDto[]>();

  const getPartnerList = async () => {
    const result = (await getPartners(myChannel?.id as number)) as unknown as PartnerDto[];
    setActivePartners(result);
    const invitationResult = (await getInvitations(
      myChannel?.id as number,
    )) as unknown as PartnerDto[];

    setInvitations(invitationResult);
  };

  const handleSearch = (value?: string) => {};
  useEffect(() => {
    if (myChannel) {
      getPartnerList();
    }
  }, [myChannel]);

  return (
    <>
      <ManagementLayout>
        <div>
          <div style={{ marginBottom: 20 }}>
            <SearchBox onEntered={handleSearch} onCancel={() => handleSearch('')} />
          </div>
          {invitations && invitations?.length > 0 && (
            <PartnersList
              partners={invitations}
              title="초대중인 셀러"
              useNoContent={false}
              onReset={getPartnerList}
            />
          )}
          <PartnersList
            partners={activePartners}
            title="셀러 목록"
            useNoContent={false}
            onReset={getPartnerList}
          />
        </div>
      </ManagementLayout>
    </>
  );
};

export default DealPartnersPage;
