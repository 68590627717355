/* eslint-disable react/jsx-pascal-case */
import React, { ReactElement, useEffect, useState } from 'react';

import { Row } from 'components/commons/layouts';
import { ColorPicker } from 'components/items';

import styled from 'styled-components';
import _ from 'lodash';

const InlineColorPicker = ({
  disabled,
  defaultColor,
  onChangeColor,
}: {
  disabled?: boolean;
  defaultColor?: string;
  onChangeColor: (color: string) => void;
}) => {
  const [selectedColor, setSelectedColor] = useState<string | undefined>('');
  const [enable, setEnable] = useState<boolean | undefined>(false);

  useEffect(() => {
    setSelectedColor(defaultColor);
  }, [defaultColor]);

  useEffect(() => {
    setEnable(disabled);
  }, [disabled]);

  return (
    <Row style={{ flex: 1, justifyContent: 'flex-end' }}>
      <ColorStyle
        innerColor={'#ffffff'}
        selected={selectedColor === '#ffffff'}
        disabled={enable}
        onClick={() => {
          onChangeColor('#ffffff');
        }}
      >
        <div />
      </ColorStyle>
      <ColorStyle
        selected={selectedColor === '#000000'}
        innerColor={'#000000'}
        disabled={enable}
        onClick={() => {
          onChangeColor('#000000');
        }}
      >
        <div />
      </ColorStyle>

      <ColorPicker
        useGradient={false}
        defaultValue={defaultColor}
        onColorChanged={(color: string) => {
          onChangeColor(color);
        }}
      >
        <ColorStyle
          selected={selectedColor !== '#ffffff' && selectedColor !== '#000000'}
          disabled={enable}
        >
          <img src="/images/color.png" alt="" width={20} height={20} />
        </ColorStyle>
      </ColorPicker>
    </Row>
  );
};

const ColorStyle = styled.div<{ selected: boolean; innerColor?: string; disabled?: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: ${(props) => (!props.disabled ? 'pointer' : 'default')};
  display: flex;
  justify-content: center;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'box-only')};
  align-items: center;
  overflow: hidden;
  border: ${(props) =>
    props.disabled
      ? '2px solid #cdcdcd'
      : props.selected
      ? '2px solid #11c668'
      : '2px solid #cdcdcd'};
  > div {
    border-radius: 50%;
    width: 20px;
    height: 20px;

    background-color: ${(props) => props.innerColor};
  }
`;
export default InlineColorPicker;
