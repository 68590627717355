import React, { useState, ReactNode, useRef } from 'react';
import { ChannelDto, FriendshipTagDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import { Button } from '@mui/material';
import { GroupedTag } from 'types/CommonTypes';
import { t } from 'i18next';
import useNavigation from 'hooks/useNavigation';
import { OhmossButton } from 'resources/controls/OhmossButton';

import TagList from 'features/mainFeatures/commons/components/TagList';
import useToken from 'libs/hooks/useToken';
import styled from 'styled-components';
import { Context } from './useFollowContext';

interface PageProviderProps {
  children: ReactNode | ReactNode[];
}

const FollowProvider = ({ children }: PageProviderProps) => {
  const { uiStore, tagStore, userHomeStore, myChannelStore } = useStore();
  const { validUser } = useToken();
  const tagListRef = useRef<any>(null);
  const { navigate } = useNavigation();
  const selectedTags = useRef<FriendshipTagDto>({ tags: [] });

  const [isChannelFollow, setIsChannelFollow] = useState<boolean>();
  const [followInfo, setFollowInfo] = useState<
    { channelName: string; isFollow: boolean } | undefined
  >();

  const follow = async (channelName: string, isFollow: boolean): Promise<any> => {
    if (validUser() && channelName) {
      if (!isFollow) {
        const result = (await tagStore.getItems([
          channelName as never,
        ])) as unknown as Array<GroupedTag>;
        if (result.length === 0) {
          if (result) {
            handleConfirmFollow(channelName);
            return { isFollow: true, channelName };
          }
        } else {
          showModal(result, channelName);
        }
        // }
      } else {
        if (channelName) {
          const result = await myChannelStore.unfollow(
            myChannelStore.currentChannel?.id as number,
            channelName,
          );
          if (result) {
            setIsChannelFollow(false);
            setFollowInfo({ channelName, isFollow: false });

            userHomeStore.setChannelFollow(false);
            return { isFollow: false, channelName };
          }
        }

        uiStore.universalModal.close();
      }
    } else {
      uiStore.confirm.show({
        message: t('A245'),
        onConfirmed: async () => {
          navigate(`/login?action=(/${channelName}.follow)`);
        },
      });
      return undefined;
    }
  };

  const handleConfirmFollow = async (channelName: string) => {
    if (channelName && typeof selectedTags.current !== 'undefined') {
      selectedTags.current.targetChannel = { channelName };
      const result = (await myChannelStore.follow(
        myChannelStore.currentChannel?.id as number,
        channelName,
        selectedTags.current,
      )) as any;

      if (result.status === 200) {
        setFollowInfo({ channelName, isFollow: true });
        userHomeStore.setChannelFollow(true);
        uiStore.universalModal.close();
        setIsChannelFollow(true);
        return true;
      }
    } else {
      return false;
      alert(t('A246'));
    }
  };

  const handleSelectedTags = (tags: Array<number>) => {
    selectedTags.current.tags = tags.map((tag: number) => {
      return {
        id: tag,
      };
    }) as any;
  };

  const showModal = async (result: GroupedTag[], channelName: string) => {
    const modalTitle = <div>{t('A243')}</div>;
    const description = <div>{t('A244')}</div>;
    uiStore.universalModal.show({
      title: modalTitle,
      description,
      bottomSheetOptions: { topPosition: 100 },
      modalOption: {
        style: { maxWidth: '650px', maxHeight: '550px' },
      },
      children: (
        <TagListContainer>
          <TagList tags={result} ref={tagListRef} onSelected={handleSelectedTags} />
          <CenterStyle>
            <div>
              <OhmossButton
                onClick={() => {
                  setFollowInfo({ channelName, isFollow: true });
                  uiStore.universalModal.confirm(true);
                }}
              >
                Follow
              </OhmossButton>
            </div>
            <div>
              <OhmossButton
                type="link"
                onClick={() => {
                  uiStore.universalModal.close();
                }}
                style={{ marginTop: 15, fontSize: 14 }}
              >
                {t('A225')}
              </OhmossButton>
            </div>
          </CenterStyle>
        </TagListContainer>
      ),
      onConfirmed: async (result: any) => {
        setFollowInfo({ channelName, isFollow: result });
        const result2 = await handleConfirmFollow(channelName);
        setIsChannelFollow(result2);
      },
    });
  };

  return (
    <>
      {typeof children !== 'undefined' ? (
        <Context.Provider
          value={{
            follow,
            followInfo,
            isChannelFollow,
            setIsChannelFollow,
          }}
        >
          {children}
        </Context.Provider>
      ) : (
        <></>
      )}
    </>
  );
};

const FollowButtonStyle = styled(Button)<{ isFollow?: boolean }>`
  border: ${(props) => (props.isFollow ? '1px solid transparent' : '1px solid #bebebe')};
  border-radius: 5px;
  font-size: 12px;
  padding: 3px 5px;
  margin-left: 10px;
  background: ${(props) => (props.isFollow ? '#0bbd58' : '#bebebe')};
`;

const TagListContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  height: 95%;
  align-content: space-between;
  flex-direction: row;
  width: 90%;
`;

const CenterStyle = styled.div`
  margin: auto;
  display: inline-block;
  text-align: center;
  margin-top: 30px;
`;

export default FollowProvider;
