import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { LinkDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';

import SocialComponent from 'features/commonFeatures/SocialComponent';
import styled from 'styled-components';
import NoContent from 'components/commons/NoContent';
import BlockItem from 'features/management/components/block/BlockItem';
import PopUpModal from 'features/management/components/block/PopUpModal';
import { isOver24Hours } from 'libs/helper/utils';
import { IPopUpBlock } from 'types/BlockExtendsTypes';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import { MyStyle } from 'types/CommonTypes';

/**
 * @description 실제 링크를 표시하는 컴포넌트
 * @description 시뮬레이터와 사용자링크 페이지에서 함께 사용하므로
 * @description 사이드이펙트에 주의할것.
 */
const LinkBoardType = observer(
  ({ links, isPreviewMode }: { links?: Array<LinkDto>; isPreviewMode?: boolean }) => {
    const { uiStores, socialStore, uiStore, uiLinkStore } = useStore();
    // const { board } = useOhmossContext<LinkServiceType>();
    const { categoryBoard } = useChannelContext();

    const [customStyle, setCustomStyle] = useState<MyStyle>({});
    const [socialPosition, setSocialPosition] = useState<'top' | 'bottom'>();
    const [hasLink, setHasLink] = useState<boolean>();

    const showPopUp = (item: LinkDto) => {
      uiStore.modal.show({
        children: <PopUpModal item={item} editMode={false} />,
        modalType: 'modal',
        style: {
          maxHeight: '80vh',
        },
      });
    };

    const doPopUp = async () => {
      if (links && links.length > 0) {
        const popup = links.find((link: LinkDto) => link.linkType === 'Popup') as IPopUpBlock;
        if (typeof popup !== 'undefined' && popup.usePopUp === true) {
          const popupItem = await localStorage.getItem(`${popup.channel?.id}-${popup.id}-popup`);
          if (popupItem) {
            const oldDate: Date = new Date();
            if (isOver24Hours(oldDate)) {
              showPopUp(popup);
            } else {
              console.log('24시간이 경과하지 않았습니다.');
            }
          } else {
            showPopUp(popup);
          }
        }
      }
    };
    /* //기존 링크 사용자들이 있으므로 삭제하지 말것 */
    useEffect(() => {
      setSocialPosition(uiStores.profileStore.profile?.metadataObject?.socialProps?.position);
    }, [uiStores.profileStore.profile]);

    useEffect(() => {
      doPopUp();
      setHasLink(links?.length !== 0);
    }, [links]);

    useEffect(() => {
      if (uiLinkStore.myStyle) {
        setCustomStyle(uiLinkStore.myStyle);
      }
    }, [uiLinkStore.myStyle]);

    return (
      <>
        <ContainerStyle>
          <div
            className="global-width"
            style={{ position: 'relative', zIndex: 1, height: '100%', top: -20 }}
          >
            {links?.map((link: LinkDto, index) => (
              <Fragment key={index}>
                {link?.isActive && (
                  <BlockItem
                    item={link}
                    editMode={false}
                    isPreviewMode={isPreviewMode}
                    customStyle={
                      link.linkType !== 'Spacer' && link.linkType !== 'Seperator'
                        ? customStyle
                        : undefined
                    }
                    blockMargin={categoryBoard?.blockMargin}
                    key={index}
                  />
                )}
              </Fragment>
            ))}
            {hasLink === false && <NoContent title="Link가" />}
            {/* <PageLoader /> */}
          </div>
          {/* //기존 사용자들이 있으므로 삭제하지 말것 */}
          {socialPosition === 'bottom' && (
            <div style={{ marginTop: 30 }}>
              <SocialComponent
                socials={socialStore?.socials}
                socialProps={uiStores.profileStore.profile?.metadataObject?.socialProps}
              />
            </div>
          )}
        </ContainerStyle>
      </>
    );
  },
);

const ContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 0;
  /* padding-bottom: 40px;
  margin-top: 20px; */
`;
export default LinkBoardType;
