/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { useGlobalContext } from 'context/useGlobalContext';
import { OhmossButton } from 'resources/controls/OhmossButton';
import useNavigation from 'hooks/useNavigation';

import * as Templates from 'components/template';
import styled from 'styled-components';
import useLocalParams from 'libs/hooks/useLocalParams';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import { useRootContext } from 'libs/hooks/useRootContext';

/**
 * @description 사용자에게 표시되는 템플릿을 처리한다.
 */
const TemplateMaster = observer(() => {
  const { uiStores } = useStore();
  const { userHomeStore } = uiStores;

  const { categoryBoards } = useChannelContext();
  const { myChannel } = useRootContext();
  const { channelName } = useLocalParams();

  const [isMine, setIsMine] = useState<boolean>();

  const { navigate } = useNavigation();
  /**
   * @description Board Type이 아닌 템플릿 타입
   * @description ProfileType은 프로필 이미지가 큰 타입
   * @description SimpleType은 기본형 타입
   * @returns
   */
  const getTemplate = () => {
    switch (userHomeStore.channelConfig?.metadataObject?.profileType) {
      case 'profile':
        return <Templates.ProfileTemplate />;
      case 'simple':
        return <Templates.SimpleTemplate />;
      default:
        return <Templates.SimpleTemplate />;
    }
  };

  const handleClickNewCategory = () => {
    navigate(`/management`);
  };

  useEffect(() => {
    setIsMine(channelName === myChannel?.channelName);
  }, []);

  return (
    <>
      <UniversalLayoutStyle style={{}}>
        {categoryBoards?.length === 0 ? (
          <NoBoardStyle>
            <div>
              <div style={{ marginBottom: 20 }}>
                <img src="/images/charactor.svg" style={{ width: 120, margin: 'auto' }} />
              </div>
              <div>아직 생성된 페이지가 없습니다.</div>
              {isMine && (
                <div style={{ textAlign: 'center', paddingTop: 20 }}>
                  <OhmossButton onClick={handleClickNewCategory}>새 페이지 만들기</OhmossButton>
                </div>
              )}
            </div>
          </NoBoardStyle>
        ) : (
          <div style={{}}>{getTemplate()}</div>
        )}
      </UniversalLayoutStyle>
    </>
  );
});

const UniversalLayoutStyle = styled.div`
  height: 100%;
  /* padding-bottom: 70px;
  position: relative; */
  /* background: rgba(255, 255, 255, 0.2); */
`;

const NoBoardStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 180px);
`;

export default TemplateMaster;
