import SearchBox from 'components/commons/SearchBox';
import React, { useEffect, useRef, useState } from 'react';
import { OhmossButton } from 'resources/controls/OhmossButton';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import { useStore } from 'stores/StoreHelper';
import useNavigation from 'hooks/useNavigation';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import ChecklistController from 'controllers/ChecklistController';
import { useRootContext } from 'libs/hooks/useRootContext';
import { ChannelChecklistDto } from 'services/data-contracts';
import ChecklistCategorySelector from '../components/checklist/ChecklistCategory';
import ChecklistContainer from '../containers/checklist/ChecklistContainer';

function ChecklistPage() {
  const { navigate } = useNavigation();
  const location = useLocation();

  const { uiStore } = useStore();
  const { myChannel } = useRootContext();
  const { getChecklistCategories, getMyChecklists } = ChecklistController();

  const checklistRef = useRef<ChannelChecklistDto[]>([]);
  const [checklist, setChecklist] = useState<ChannelChecklistDto[]>();

  const getChecklists = async () => {
    const result = await getMyChecklists(myChannel?.id as number);
    setChecklist(result);
    checklistRef.current = result;
  };

  const handleSearch = (value: string) => {
    if (value === '') {
      setChecklist(checklistRef.current);
    } else {
      const result = checklistRef?.current.filter((item) => {
        return (
          item?.title?.includes(value) || item?.deal?.sellerChannel?.channelName?.includes(value)
        );
      });
      setChecklist(result);
    }
  };

  const handleCreateCheckList = async () => {
    if (myChannel?.membershipType === 'FREE') {
      toast.warn('유료 회원만 이용 가능합니다.');
      return;
    }
    {
      const categories = await getChecklistCategories(myChannel?.id as number);
      uiStore.bottomSheet.show({
        title: '체크리스트 카테고리',
        style: { minHeight: '320px' },
        children: <ChecklistCategorySelector categoryProps={categories} />,
      });
    }
  };

  useEffect(() => {
    if (location.pathname) {
      setTimeout(() => {
        const t = document.querySelector('.ohmoss-navigation-tab') as any;
        if (
          (t && location.pathname === '/my/deals/checklist/detail/list') ||
          location.pathname === '/cstown/checklist/detail/list'
        ) {
          t.style.display = 'none';
        } else {
          t.style.display = 'flex';
        }
      }, 0);
    }
  }, [location]);

  const handleClickItem = (item: ChannelChecklistDto) => {
    if (location.pathname === '/cstown/checklist') {
      navigate(`/cstown/checklist/detail?category=${item?.category?.id}&id=${item?.id}`);
    } else if (location.pathname === '/my/deals/checklist') {
      navigate(`/my/deals/checklist/detail?category=${item?.category?.id}&id=${item?.id}`);
    }
  };

  useEffect(() => {
    if (
      (myChannel && myChannel?.membershipType === 'PREMIUM') ||
      myChannel?.membershipType === 'PRO'
    ) {
      getChecklists();
    }
  }, [myChannel]);

  return (
    <ManagementLayout
      style={{
        justifyContent: 'flex-start',
        paddingBottom: 100,
      }}
    >
      <SearchBox onEntered={handleSearch} onCancel={() => handleSearch('')} />
      <ChecklistContainer checklist={checklist} onClickItem={handleClickItem} />

      <OhmossButton style={{ marginTop: 10 }} onClick={handleCreateCheckList}>
        체크리스트 만들기
      </OhmossButton>
    </ManagementLayout>
  );
}

export default ChecklistPage;
