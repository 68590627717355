import React, { useState, useEffect, useRef } from 'react';
import { PostModel, PostItemModel } from 'types/CommonTypes';
import { setDefaultValue } from 'libs/helper/utils';
import { useTranslation } from 'react-i18next';
import { validationHelper as VH } from 'controls/validationHelper';
import { useStore } from 'stores/StoreHelper';
import { PostDto } from 'services/data-contracts';

import LinkEditorContainerStyle from 'resources/controls/LinkEditorConatainerStyle';
import Editor from 'components/commons/webEditor/Editor';
import SaveButtonWrapper from 'components/items/SaveButtonWrapper';
import TitleForm from 'components/items/forms/TitleForm';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import boardCommonLogics from '../logic/boardCommonLogic';
import { Row } from 'components/commons/layouts';
import moment from 'moment';
import dayjs from 'dayjs';

interface CalendarModel extends PostDto {
  dateStr?: string;
}

const CalendarEditor = ({ model }: { model: CalendarModel }) => {
  const initDto = (reset?: boolean): PostModel => {
    if (reset) {
      return {
        title: 'feed',
        channel: {},
        items: [],
      };
    } else {
      return {
        ...model,
        channel: setDefaultValue(model?.channel, {}),
      };
    }
  };
  const editorRef = useRef<any>(null);

  const { t } = useTranslation();
  const { uiStore, postStore } = useStore();
  const { handleSavePost } = boardCommonLogics();

  const [feedItems, setFeedItems] = useState<Array<PostItemModel>>(() => {
    return uiStore.universalModal.contentModel?.items || [];
  });
  const [postModel, setPostModel] = useState<PostModel>(() => {
    return uiStore.universalModal.contentModel?.model || initDto();
  });

  const [startDate, setStartDate] = useState<dayjs.Dayjs>();
  const [endDate, setEndDate] = useState<dayjs.Dayjs>();

  const [isValid, setIsValid] = useState<boolean>(false);

  const handleOnSave = () => {
    const content = editorRef.current.getData();
    const newFeedModel: PostModel = { ...postModel, content, items: feedItems };
    handleSavePost(newFeedModel, (result: boolean, model: PostDto) => {
      if (result) {
        // postStore.updateFeedByItem(model, 'new');
        uiStore.universalModal.close();
      }
    });
  };

  const checkValidation = () => {
    const title = VH.required(postModel.title);
    setIsValid(title);
  };

  useEffect(() => {
    if (model.id) {
      setPostModel(initDto());
      setStartDate(dayjs(model.startDate || model.dateStr));
      setEndDate(dayjs(model.endDate || model.dateStr));
    }
  }, [model]);

  useEffect(() => {
    if (postModel) {
      checkValidation();
    }
  }, [postModel]);

  return (
    <>
      <LinkEditorContainerStyle>
        <SaveButtonWrapper
          isValid={isValid}
          onSave={handleOnSave}
          isNew={typeof postModel?.id === 'undefined'}
        >
          <div style={{ margin: '10px 0' }}>
            <TitleForm
              label="일정제목"
              model={postModel?.title}
              onChangeValue={(value: string) => {
                setPostModel((prev: PostDto) => {
                  return { ...prev, title: value };
                });
              }}
            />
          </div>
          <Row>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="일정시작일"
                value={startDate}
                onChange={(value: any) => {
                  setPostModel((prev: any) => {
                    return { ...prev, startDate: dayjs(value).format('YYYY-MM-DDThh:mm:ss') };
                  });
                }}
              />
              <DatePicker
                label="일정종료일"
                value={endDate}
                onChange={(value: any) => {
                  setPostModel((prev: any) => {
                    return { ...prev, endDate: dayjs(value).format('YYYY-MM-DDThh:mm:ss') };
                  });
                }}
              />
            </LocalizationProvider>
          </Row>
          <div>
            <Editor
              ref={editorRef}
              defaultValue={
                uiStore.universalModal.contentModel?.model?.content || postModel.content
              }
              onEditorChange={() => {
                // 브라우저 사이즈 변경할때 모델을 공유하기 위해 공용 모델을 업데이트 한다.
                // Editor는 실시간으로 데이터를 취득하지 않고 저장하는 시점에 꺼내온다.
                uiStore.universalModal.updateModel({
                  model: { ...postModel, content: editorRef.current.getData() },
                  items: feedItems,
                });
              }}
            />
          </div>
        </SaveButtonWrapper>
      </LinkEditorContainerStyle>
    </>
  );
};

export default CalendarEditor;
