import React from 'react';

const ProviderWrapper = ({
  provider: ProviderComponent,
  children,
}: {
  provider?: any;
  children: React.ReactNode;
}) => {
  if (ProviderComponent) {
    return <ProviderComponent>{children}</ProviderComponent>;
  } else {
    if (!children) {
      return <div />;
    } else {
      return <>{children}</>;
    }
  }
};
// const ProviderWrapper = ({ provider, children }: { provider?: any | any[]; children: any }) => {
//   const providers = Array.isArray(provider) ? provider : [provider];

//   // 반환 타입을 React.ReactNode로 명시
//   const renderWithProviders = (index: number): React.ReactNode => {
//     if (index >= providers.length) {
//       return children;
//     }

//     const CurrentProvider = providers[index];
//     if (!CurrentProvider) {
//       return renderWithProviders(index + 1);
//     }

//     return <CurrentProvider>{renderWithProviders(index + 1)}</CurrentProvider>;
//   };

//   return <>{renderWithProviders(0)}</>;
// };

export default ProviderWrapper;
