/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ChannelIdentifierDto,
  PostCommentDto,
  PostCommentRequestDto,
  PostDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Post<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 포스트 코멘트 정보
   *
   * @tags post
   * @name GetPostComment
   * @summary 포스트 코멘트 정보
   * @request GET:/v2/channels/{channelName}/boards/{boardId}/posts/{postId}/comments/{commentId}
   * @secure
   */
  getPostComment = (
    channelName: string,
    boardId: number,
    postId: number,
    commentId: number,
    params: RequestParams = {},
  ) =>
    this.http.request<PostCommentDto, any>({
      path: `/v2/channels/${channelName}/boards/${boardId}/posts/${postId}/comments/${commentId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 포스트 코멘트 수정
   *
   * @tags post
   * @name UpdatePostComment
   * @summary 포스트 코멘트 수정
   * @request PUT:/v2/channels/{channelName}/boards/{boardId}/posts/{postId}/comments/{commentId}
   * @secure
   */
  updatePostComment = (
    channelName: string,
    boardId: number,
    postId: number,
    commentId: number,
    data: PostCommentRequestDto,
    params: RequestParams = {},
  ) =>
    this.http.request<PostCommentDto, any>({
      path: `/v2/channels/${channelName}/boards/${boardId}/posts/${postId}/comments/${commentId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 포스트 코멘트 삭제
   *
   * @tags post
   * @name DeletePostComment
   * @summary 포스트 코멘트 삭제
   * @request DELETE:/v2/channels/{channelName}/boards/{boardId}/posts/{postId}/comments/{commentId}
   * @secure
   */
  deletePostComment = (
    channelName: string,
    boardId: number,
    postId: number,
    commentId: number,
    data: ChannelIdentifierDto,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/v2/channels/${channelName}/boards/${boardId}/posts/${postId}/comments/${commentId}`,
      method: 'DELETE',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 포스트 좋아요
   *
   * @tags post
   * @name AddLikeToPost
   * @summary 포스트 좋아요
   * @request POST:/v2/channels/{channelName}/boards/{boardId}/posts/{postId}/like
   * @secure
   */
  addLikeToPost = (
    channelName: string,
    boardId: number,
    postId: number,
    data: PostDto,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/v2/channels/${channelName}/boards/${boardId}/posts/${postId}/like`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 포스트 좋아요 취소
   *
   * @tags post
   * @name RemoveLikeFromPost
   * @summary 포스트 좋아요 취소
   * @request DELETE:/v2/channels/{channelName}/boards/{boardId}/posts/{postId}/like
   * @secure
   */
  removeLikeFromPost = (
    channelName: string,
    boardId: number,
    postId: number,
    data: PostDto,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/v2/channels/${channelName}/boards/${boardId}/posts/${postId}/like`,
      method: 'DELETE',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 포스트 코멘트 목록
   *
   * @tags post
   * @name GetPostComments
   * @summary 포스트 코멘트 목록
   * @request GET:/v2/channels/{channelName}/boards/{boardId}/posts/{postId}/comments
   * @secure
   */
  getPostComments = (
    channelName: string,
    boardId: number,
    postId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<PostCommentDto[], any>({
      path: `/v2/channels/${channelName}/boards/${boardId}/posts/${postId}/comments`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 포스트 코멘트 생성
   *
   * @tags post
   * @name CreatePostComment
   * @summary 포스트 코멘트 생성
   * @request POST:/v2/channels/{channelName}/boards/{boardId}/posts/{postId}/comments
   * @secure
   */
  createPostComment = (
    channelName: string,
    boardId: number,
    postId: number,
    data: PostCommentRequestDto,
    params: RequestParams = {},
  ) =>
    this.http.request<PostCommentDto, any>({
      path: `/v2/channels/${channelName}/boards/${boardId}/posts/${postId}/comments`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 포스트 정보
   *
   * @tags post
   * @name GetPost
   * @summary 포스트 정보
   * @request GET:/v2/posts/{postKey}
   * @secure
   */
  getPost = (postKey: string, params: RequestParams = {}) =>
    this.http.request<PostDto, any>({
      path: `/v2/posts/${postKey}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 포스트 목록
   *
   * @tags post
   * @name GetPosts1
   * @summary 포스트 목록
   * @request GET:/v2/channels/{channelName}/boards/{boardId}/posts
   * @secure
   */
  getPosts1 = (
    channelName: string,
    boardId: number,
    query?: {
      /** @format int64 */
      ownChannelId?: number;
      /** 포스트 타입 (NORMAL, EVENT, TIME_DEAL, APPOINTMENT */
      postType?: 'NORMAL' | 'APPOINTMENT' | 'PRODUCT';
      /** 검색어 (post 제목) */
      searchText?: string;
      /**
       * 'yyyy-MM-dd' (시작 날짜)
       * @format date
       */
      startDate?: string;
      /**
       * 'yyyy-MM-dd' (마지막 날짜)
       * @format date
       */
      endDate?: string;
      /**
       * 위도
       * @format double
       */
      latitude?: number;
      /**
       * 경도
       * @format double
       */
      longitude?: number;
      /**
       * 반경거리
       * @format int32
       */
      radius?: number;
      /** 활성여부 */
      isActive?: boolean;
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<PostDto[], any>({
      path: `/v2/channels/${channelName}/boards/${boardId}/posts`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 포스트 정보
   *
   * @tags post
   * @name GetPost2
   * @summary 포스트 정보
   * @request GET:/v2/channels/{channelName}/boards/{boardId}/posts/{postId}
   * @secure
   */
  getPost2 = (
    channelName: string,
    boardId: number,
    postId: number,
    query?: {
      /** @format int64 */
      ownChannelId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<PostDto, any>({
      path: `/v2/channels/${channelName}/boards/${boardId}/posts/${postId}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
