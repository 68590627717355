import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { PostDto } from 'services/data-contracts';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';

import useNavigation from 'libs/hooks/useNavigate';
import MyShopDetailModal from 'pages/MyShopDetailModal';
import MyShopCardListItem from './MyShopCardListItem';

const MyShopCardList = observer(
  ({
    items,
    itemsPerRow,
    boxStyle,
    useThumbnailInfo,
    thumbnailColor,
    thumbnailBackgroundColor,
  }: {
    items?: Array<PostDto>;
    useThumbnailInfo?: boolean;
    boxStyle?: CSSProperties;
    itemsPerRow: number | undefined;
    thumbnailColor?: string;
    thumbnailBackgroundColor?: string;
  }) => {
    const navigate = useNavigation();
    const { uiStore } = useStore();

    const [newItems, setItems] = React.useState<Array<PostDto>>([]);
    const [rowItemCount, setRowItemCount] = React.useState<number>(2);

    const handleOnClickItem = (item: PostDto) => {
      const parsedMetadata = JSON.parse(item?.metadata ?? '{}');
      const isDirectLink = parsedMetadata?.isDirectLink ?? false;
      if (isDirectLink) {
        navigate(item?.linkUrl, item.openNewWindow ?? true);
      } else {
        uiStore.modal.show({
          style: { maxHeight: '80vh' },
          children: <MyShopDetailModal isOwner post={item} />,
        });
      }
    };

    React.useEffect(() => {
      if (itemsPerRow) {
        setRowItemCount(itemsPerRow);
      }
      if (items) {
        setItems(items);
      }
    }, [itemsPerRow, items]);

    return (
      <ContainerStyle>
        {newItems?.map((item: PostDto, index: number) => (
          <MyShopCardListItem
            item={item}
            layout={rowItemCount}
            onClick={handleOnClickItem}
            useThumbnailInfo={useThumbnailInfo}
            style={{
              width: `calc(100% / ${rowItemCount} - 4px)`,
              ...boxStyle,
            }}
            thumbnailStyle={{
              backgroundColor: thumbnailBackgroundColor,
              color: thumbnailColor,
            }}
            key={index}
          />
        ))}
      </ContainerStyle>
    );
  },
);

const ContainerStyle = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  gap: 3px;
  flex-wrap: wrap;
  padding-left: 3px;
  /* bottom 패딩을 주지않으면 왼쪽 하단에 공간이 부족해서 부모 컨테이너에 따라 둥그래짐 */
  padding-bottom: 10px;
`;

export default MyShopCardList;
