import React from 'react';
import ReactDOM from 'react-dom';
import { CustomProvider } from 'stores/StoreHelper';
import { QueryClientProvider, QueryClient } from 'react-query';
import registerServiceWorker from 'ServiceWorker';
import { MessageProvider } from 'context/MessageProvider';
import RootProvider from 'providers/RootProvider';
import ChatProvider from 'providers/ChatProvider';
import SendBirdProvider from 'components/commons/chat/SendBirdProvider';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import './tailwind.css';
import MediaProvider from 'providers/MediaProvider';

// import 'resources/css/editor.css';

const queryClient = new QueryClient();

ReactDOM.render(
  <MediaProvider>
    <RootProvider>
      <SendBirdProvider>
        <ChatProvider>
          <QueryClientProvider client={queryClient}>
            <CustomProvider>
              {/* <Provider {...store}> */}
              <MessageProvider>
                <React.StrictMode>
                  <App />
                </React.StrictMode>
              </MessageProvider>
              {/* </Provider> */}
            </CustomProvider>
          </QueryClientProvider>
        </ChatProvider>
      </SendBirdProvider>
    </RootProvider>
  </MediaProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
!window.isWebView && registerServiceWorker();
