import React from 'react';
import { ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';

const MUIToggleGroup = (props: ToggleButtonGroupProps) => {
  return (
    <ToggleButtonGroup
      {...props}
      sx={{ '&.MuiToggleButtonGroup-root': { gap: '8px', display: 'flex' } }}
      exclusive
      aria-label="Platform"
    >
      {props.children}
    </ToggleButtonGroup>
  );
};

export default MUIToggleGroup;
