import React, { useEffect, useState, ReactChild, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';
import { Row } from 'components/commons/layouts';
import { OhmossButton } from 'resources/controls/OhmossButton';
import useNavigation from 'hooks/useNavigation';
import { useStore } from 'stores/StoreHelper';
import { MenuType } from 'types/CommonTypes';
import { getAdminMenus } from 'resources/definitions/AdminMenus';
import { t } from 'i18next';

import AdminMobileAvatar from 'features/commonFeatures/navigationBar/containers/AdminMobileAvatar';
import GnbComponent from 'features/commonFeatures/navigationBar/containers/GnbComponent';
import useToken from 'libs/hooks/useToken';

const MobileMenus = observer(
  ({
    type,
    onMenuChange,
  }: {
    type: 'webview' | 'mobile';
    onMenuChange: (menu: MenuType) => void;
  }) => {
    const menuRef = useRef<any>(null);
    const { navigate } = useNavigation();

    const { myChannelStore } = useStore();
    const { getUserInfo } = useToken();

    const [selectedItem, setSelectedItem] = useState<MenuType>();

    type InternalMenuType = {
      title?: string;
      onClick: () => void;
      children?: ReactChild;
    };

    const MenuItem = ({ title, onClick, children }: InternalMenuType) => {
      return (
        <MenuItemStyle
          onClick={() => {
            onClick();
            menuRef.current.close();
          }}
        >
          <div style={{ marginRight: 20, alignItems: 'center', display: 'flex' }}>{children}</div>
          {title}
        </MenuItemStyle>
      );
    };

    useEffect(() => {
      setSelectedItem({ key: 0, title: 'Links' });
    }, []);

    return (
      <HeaderStyle className="global-width" style={{ paddingTop: 5 }} isMobile>
        {type === 'mobile' && (
          <Row style={{ justifyContent: 'space-between' }}>
            <GnbComponent
              ref={menuRef}
              useCommonButtons={false}
              defaultComponent={
                <div style={{ marginLeft: 5 }}>
                  <AdminMobileAvatar />
                </div>
              }
              position="top"
            >
              <MenuContentStyle>
                {getAdminMenus()
                  .filter((menu: MenuType) => menu.group === 'biz')
                  .map((menu: MenuType) => (
                    <>
                      <MenuItem
                        title={menu.title}
                        onClick={() => {
                          setSelectedItem(menu);
                          onMenuChange(menu);
                        }}
                      >
                        <div>{menu.icon}</div>
                      </MenuItem>
                    </>
                  ))}

                <OhmossButton
                  style={{ padding: '15px', width: 'calc(100% - 30px)' }}
                  onClick={() => {
                    navigate(`/${myChannelStore.currentChannel?.channelName}`);
                  }}
                >
                  {t('A255')}
                </OhmossButton>
              </MenuContentStyle>
            </GnbComponent>
          </Row>
        )}
        <HeaderMenuContinerStyle style={{ paddingTop: window.isWebView ? 0 : 64 }}>
          {getAdminMenus()
            .filter((menu: MenuType) => menu.group === 'profile')
            .map((menu: MenuType) => (
              <HeaderItemStyle
                selected={selectedItem?.title === menu.title}
                onClick={() => {
                  setSelectedItem(menu);
                  onMenuChange(menu);
                }}
              >
                <div>
                  {menu.icon}
                  <div>{menu.description}</div>
                </div>
              </HeaderItemStyle>
            ))}
        </HeaderMenuContinerStyle>
      </HeaderStyle>
    );
  },
);

const HeaderStyle = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  left: 0;
  top: 0;
  /* top: ${(props) => (props.isMobile ? '100px' : 0)}; */
  background-color: white;
`;

const HeaderItemStyle = styled.div<{ selected: boolean }>`
  text-align: center;
  margin: auto;
  cursor: pointer;
  ${(props) => props.selected && css``}
  font-size: 12px;
  font-weight: ${(props) => (props.selected ? '600' : '400')};
  margin: 5px 0;
  color: ${(props) => (props.selected ? 'black' : '#b8b8b8')};
  @media screen and (max-width: 1040px) {
    padding: 0 5px;
  }
`;

const HeaderMenuContinerStyle = styled.div`
  display: flex;
  width: 100%;
  padding-top: 64px;
  flex: 1;
  justify-content: space-evenly;
`;

const MenuContentStyle = styled.div`
  padding: 0px 40px;
  margin: 30px 0;
`;

const MenuItemStyle = styled.div`
  margin: 10px 0;
  padding: 10px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  &:first-child {
    margin-top: 0;
  }
  &:hover {
    background: #f6f5fa;
  }
`;

export default MobileMenus;
