import React, { useEffect, useRef } from 'react';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useChat } from 'libs/hooks/useChat';

import CustomerChatBox from 'components/commons/chat/CustomerChatBox';
import chatController from 'features/community/controllers/chatController';
import { SimpleDealDto } from 'services/data-contracts';
import CircularLoading from 'components/commons/CircularLoading';

/**
 * @description 일반 고객 -> 마이페이지 -> 나의 문의 -> 개별클릭
 * @param param0
 * @returns
 */
const MyInquryChatContainer = ({
  channel,
  dealInfo,
}: {
  channel?: GroupChannel;
  dealInfo?: SimpleDealDto;
}) => {
  const { sendFile, sendMessage } = chatController();
  const { messages, setMessages } = useChat();
  const channelRef = useRef<any>();

  const scrollToBottom = (item: any, smooth: string) => {
    item?.scrollTo({
      top: item.scrollHeight,
      // behavior: smooth,
    });
  };

  const handleSendFile = (files: File[]) => {
    if (channel && files) {
      sendFile(channel, files);
    }
  };

  const handleSendMessage = (msg: string) => {
    if (channel && msg) {
      sendMessage(channel, msg);
      scrollToBottom(channelRef.current, 'smooth');
    }
  };

  useEffect(() => {
    return () => {
      setMessages([]);
    };
  }, []);

  return (
    <>
      <CustomerChatBox
        dealInfo={dealInfo}
        messages={messages}
        onSendFile={handleSendFile}
        onSendMessage={handleSendMessage}
        channel={channel}
      />
    </>
  );
};

export default MyInquryChatContainer;
