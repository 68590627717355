import React, { Fragment, memo, useEffect, useState } from 'react';
import { IChannelProfile } from 'types/BlockExtendsTypes';
import { ChannelBoardDto } from 'services/data-contracts';
import styled, { css } from 'styled-components';
import { ChannelBoardModel, ChannelProfileModel } from 'types/CommonTypes';

import CategoryTabView from './CategoryTabView';
import ProfileTemplate from './ProfileTemplate';
import useToken from 'libs/hooks/useToken';
import { isMobile } from 'react-device-detect';

const ProfileBlocks = ({
  profile,
  channelBoards,
  editMode = true,
  previewMode = false,
}: {
  profile?: ChannelProfileModel;
  channelBoards?: ChannelBoardModel[];
  editMode?: boolean;
  previewMode?: boolean;
}) => {
  const { validUser } = useToken();

  const [items, setItems] = useState<ChannelBoardDto[]>([]);

  const itemsTemplate = [
    { type: 'profile', id: 1, template: ProfileTemplate },
    { type: 'tabBlock', id: 2, template: CategoryTabView },
  ];

  const getComponentByType = (item: any, seq: number) => {
    const Temp = item.template;
    const metadata: IChannelProfile = JSON.parse(profile?.metadata || '{}');
    const merged = {
      ...profile,
      ...metadata,
    };
    item.model = merged;
    item.model.id = seq;
    // if (item.type === 'profile' && merged.globalActive === false) {
    //   return <></>;
    // }
    const useTransparent =
      item.type === 'profile'
        ? item.model?.useTransparent ?? false
        : item.model?.categoryTabInfo?.useTransparent;
    return (
      <OverloadStyle useTransparent={useTransparent} editMode={editMode}>
        <Temp
          item={item?.model}
          channelBoards={channelBoards}
          editMode={editMode}
          onClick={() => {
            // handleClickItem(item);
          }}
        />
      </OverloadStyle>
    );
  };

  useEffect(() => {
    if (profile && channelBoards) {
      if ((profile as IChannelProfile).isPin === true) {
        setItems(itemsTemplate.sort((a, b) => a.id - b.id));
      } else {
        setItems(itemsTemplate.sort((a, b) => b.id - a.id));
      }
    }
  }, [profile, channelBoards]);

  return (
    <div
      style={{
        marginBottom: 20,
        marginTop: validUser() ? (isMobile ? 0 : previewMode ? 10 : 80) : 20,
      }}
    >
      {items.map((item: IChannelProfile, index: number) => (
        <Fragment key={index}>{getComponentByType(item, index)}</Fragment>
      ))}
    </div>
  );
};

const EditModeCss = css``;

const DisplayMode = css`
  margin: 0px;
  border-radius: 0;
`;

const OverloadStyle = styled.div<{ editMode?: boolean; useTransparent?: boolean }>`
  width: 100%;
  ${(props) => {
    if (props.editMode === true) {
      return EditModeCss;
    } else {
      return DisplayMode;
    }
  }}
`;

export default memo(ProfileBlocks);
