import { IconButton } from '@mui/material';
import React from 'react';
import Row from './Row';
import Text from '../Text';
import { OhmossButton } from 'resources/controls/OhmossButton';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import useNavigation from 'hooks/useNavigation';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useStore } from 'stores/StoreHelper';
import styled from 'styled-components';
import CommonMyMenus from 'features/mossMainSettings/components/CommonMyMenus';

const GNBCommonButtons = ({ useMy = true }: { useMy?: boolean }) => {
  const { navigate } = useNavigation();
  const { uiStore, authStore } = useStore();

  const handleClickLogout = async () => {
    uiStore.confirm.show({
      message: '로그아웃 하시겠습니까?',
      onConfirmed: async () => {
        await authStore.logout();
        navigate('/');
      },
    });
    authStore.logout();
  };

  const handleClickSettings = () => {
    uiStore.modal.show({
      children: (
        <PaddingStyle>
          <CommonMyMenus />
        </PaddingStyle>
      ),
    });
  };

  return (
    <div style={{ marginBottom: 20 }}>
      <Row style={{ justifyContent: 'space-evenly', marginBottom: 20 }}>
        <div>
          {useMy && (
            <div>
              <Row style={{ justifyContent: 'center', flexDirection: 'column' }}>
                <IconButton
                  onClick={() => {
                    navigate('/my');
                  }}
                  style={{ background: '#efefef' }}
                >
                  <AccountCircleOutlinedIcon />
                </IconButton>
                <Text buttonRole="DESCRIPTION">MY</Text>
              </Row>
            </div>
          )}
        </div>
        <div>
          <Row style={{ justifyContent: 'center', flexDirection: 'column' }}>
            <IconButton onClick={handleClickSettings} style={{ background: '#efefef' }}>
              <SettingsOutlinedIcon />
            </IconButton>
            <Text buttonRole="DESCRIPTION">Settings</Text>
          </Row>
        </div>
        <div>
          <Row style={{ justifyContent: 'center', flexDirection: 'column' }}>
            <IconButton onClick={handleClickLogout} style={{ background: '#efefef' }}>
              <PowerSettingsNewIcon />
            </IconButton>
            <Text buttonRole="DESCRIPTION">Logout</Text>
          </Row>
        </div>
      </Row>
      {/* <OhmossButton
        type="primaryPc"
        onClick={() => {
          navigate('/management');
        }}
      >
        내 링크관리
      </OhmossButton> */}
    </div>
  );
};

const PaddingStyle = styled.div`
  padding: 20px 40px;
`;

export default GNBCommonButtons;
