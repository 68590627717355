import React, { useState } from 'react';
import { Blocks, BlockType } from 'features/management/components/block';
import { useStore } from 'stores/StoreHelper';
import { useRootContext } from 'libs/hooks/useRootContext';

import styled from 'styled-components';
import useNavigation from 'hooks/useNavigation';
import { useMediaContext } from 'providers/useMediaContext';
import BlockSelectorPC from './BlockSelectorPC';
import BlockSelectorMobile from './BlockSelectorMobile';

const BlockSelector = () => {
  const { uiStore } = useStore();
  const { navigate } = useNavigation();
  const { myChannel } = useRootContext();
  const ScreenTypes = useMediaContext();

  const handleClickBlock = (block: BlockType) => {
    uiStore.bottomSheet.close();
    if (block.name === 'Deal' || block.name === 'MyShop') {
      if (myChannel?.membershipType === 'FREE' || myChannel?.channelType === 'BRAND') {
        uiStore.confirm.show({
          message: `${block.name}블럭은 셀러유료회원 전용 블럭입니다.`,
          okText: '자세히 알아보기',
          closeText: '취소하기',
          onConfirmed: () => {
            navigate('/my/subscriptions');
          },
        });
        return;
      }
    }

    const Template = block.component;
    uiStore.modal.show({
      title: block.title,
      children: (
        <TemplateContainer style={{ padding: ScreenTypes.isLarge ? 60 : 20 }}>
          <Template />
        </TemplateContainer>
      ),
    });
  };

  return (
    <>
      {ScreenTypes.isLarge ? (
        <BlockSelectorPC onClick={handleClickBlock} />
      ) : (
        <BlockSelectorMobile onClick={handleClickBlock} />
      )}
    </>
  );
};

const TemplateContainer = styled.div`
  padding: 20px;
  height: 100%;
  @media ${(props) => props.theme.media.desk} {
    width: 100%;
    padding: 20px;
  }
`;
export default BlockSelector;
