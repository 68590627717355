/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from 'react';
import { SocialLinkDto } from 'services/data-contracts';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { SocialProps, SocialType } from 'types/CommonTypes';
import { Row } from 'components/commons/layouts';
import {
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  RadioProps,
} from '@mui/material';
import { validationHelper as VH } from 'controls/validationHelper';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { t } from 'i18next';

import Confirm from 'components/commons/Confirm';
import UrlForm from 'components/items/forms/UrlForm';
import _ from 'lodash';
import { ItemSectionStyle } from 'resources/controls/ItemSectionStyle';
import ListOfSNSContainer from './ListOfSNSContainer';
import SettingContainer from './SettingContainer';
import SettingItemRow from './SettingItemRow';
import InlineColorPicker from './InlineColorPicker';

const ProfileSocialComponent = observer(({ onSave }: { onSave: () => void }) => {
  const SOCIAL_LIST: Array<SocialType> = [
    { url: 'facebook', text: 'Facebook' },
    { url: 'youtube', text: 'Youtube' },
    { url: 'pin', text: 'Pinterest' },
    { url: 'pinterest', text: 'Pinterest' },
    { url: 'instagram', text: 'Instagram' },
    { url: 'blog.naver', text: 'Naver blog' },
  ];
  const { myChannelStore, mySocialStore, uiStore, uiStores } = useStore();
  const { t } = useTranslation();
  const urlRef = useRef<HTMLFormElement>(null);
  const { profileStore } = uiStores;
  const [socialModel, setSocialModel] = useState<SocialProps>();
  const [socialLink, setSocialLink] = useState<SocialLinkDto>();
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  const handleChangeUrl = (value: string) => {
    const result = VH.url(value);
    if (result) {
      handlePasteUrl(value);
    } else {
      setIsValid(false);
    }
  };

  const handlePasteUrl = (url: string) => {
    const preCheck = url.indexOf('http');
    if (preCheck !== 0) {
      url = `https://${url}`;
    }

    const urltemp = new URL(url);
    // const social = urltemp.hostname.replace('www.', '').split('.')[0].toLocaleLowerCase();
    const socialTemp = urltemp.hostname.replace('www.', '').toLocaleLowerCase();
    const social = socialTemp.substring(0, socialTemp.lastIndexOf('.'));
    const temp = SOCIAL_LIST.find((sns: any) => sns.url.includes(social));

    if (typeof temp !== 'undefined') {
      if (typeof socialLink?.id === 'undefined') {
        setSocialLink((prev: SocialLinkDto) => {
          return {
            ...prev,
            socialType: temp.text,
            linkUrl: url,
          };
        });
        // setSocialLink({ linkUrl: urlOrigin, socialType: temp.text });
      } else {
        setSocialLink((prev: SocialLinkDto) => {
          return {
            ...prev,
            linkUrl: url,
          };
        });
      }
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleConfirmRegister = async () => {
    if (typeof socialLink !== 'undefined') {
      const response: any = await mySocialStore.createSocialLink(
        myChannelStore.currentChannel?.id as number,
        socialLink,
      );
      if (response.status === 200) {
        mySocialStore.appendSocial((response as any).data as SocialLinkDto);
        reset();
      }
    } else {
      toast(t('A171'));
    }
  };

  const handleConfirmEdit = async () => {
    if (typeof socialLink !== 'undefined') {
      const response: any = await mySocialStore.updateSocialLink(
        myChannelStore.currentChannel?.id as number,
        socialLink,
      );
      if (response.status === 200) {
        mySocialStore.updateSNSByItem((response as any).data as SocialLinkDto, 'update');
        reset();
      }
    } else {
      toast(t('A171'));
    }
  };

  const GreenRadio = (props: RadioProps, c: any) => {
    return (
      <Radio
        {...props}
        sx={{
          color: '#11c668',
          '&.Mui-checked': {
            color: '#11c668',
          },
          '& .MuiSvgIcon-root': {
            fontSize: 18,
          },
        }}
      />
    );
  };

  const reset = () => {
    urlRef.current?.reset();
    setSocialLink(undefined);
    setIsValid(false);
  };

  const handleSelectSNS = (sns: SocialLinkDto) => {
    setSocialLink(sns);
    const result = VH.url(sns.linkUrl);
    if (result) {
      setIsValid(true);
    }
  };

  const handleSaveSettings = () => {
    if (typeof profileStore.profileMetadataObject !== 'undefined' && socialModel) {
      // model.metadataObject = { socialProps: socialModel };
      profileStore.updateProfileMetadata('social', socialModel);
      onSave();
    }
  };

  // const getKeyValue =
  //   <T extends object, U extends keyof T>(key: U) =>
  //   (obj: T) =>
  //     obj[key];

  const handleDeleteSNS = async (sns: SocialLinkDto) => {
    uiStore.confirm.show({
      message: t('A172'),
      onConfirmed: async () => {
        const result = await mySocialStore.deleteSocialLink(
          myChannelStore.currentChannel?.id as number,
          sns?.id as number,
        );
        if (result) {
          mySocialStore.updateSNSByItem(sns, 'delete');
          reset();
        }
      },
    });
  };

  useEffect(() => {
    if (typeof profileStore.profileMetadataObject !== 'undefined') {
      setSocialModel(profileStore.profileMetadataObject.socialProps);
    }
  }, [profileStore.profileMetadataObject]);

  useEffect(() => {
    if (myChannelStore.currentChannel) {
      mySocialStore.getSocials(myChannelStore.currentChannel.id as number);
    }
  }, [myChannelStore.currentChannel]);
  return (
    <>
      <ItemSectionStyle>
        <SettingContainer title="Social Setting" height={270}>
          <>
            <SettingItemRow title={t('A170')} style={{ marginTop: 20 }}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  row
                  value={socialModel?.position || 'top'}
                  name="radio-buttons-group"
                  onChange={(e: any, value: any) => {
                    if (value === null) {
                      return;
                    }
                    setSocialModel((prev: SocialProps) => {
                      return {
                        ...prev,
                        position: value,
                      };
                    });
                  }}
                >
                  <FormControlLabel value="top" control={<GreenRadio />} label="Top" />
                  <FormControlLabel value="bottom" control={<GreenRadio />} label="Bottom" />
                </RadioGroup>
              </FormControl>
            </SettingItemRow>
            <SettingItemRow title={t('A173')} style={{ margin: '10px 0' }}>
              <FormControl>
                {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                <RadioGroup
                  sx={{ '&.MuiFormGroup-root': { justifyContent: 'flex-end' } }}
                  aria-labelledby="demo-radio-buttons-group-label"
                  row
                  value={socialModel?.size || 'medium'}
                  name="radio-buttons-group"
                  onChange={(e: any, value: any) => {
                    if (value === null) {
                      return;
                    }
                    setSocialModel((prev: SocialProps) => {
                      return {
                        ...prev,
                        size: value,
                      };
                    });
                  }}
                >
                  <FormControlLabel value="small" control={<GreenRadio />} label="Small" />
                  <FormControlLabel value="medium" control={<GreenRadio />} label="Medium" />
                  <FormControlLabel value="large" control={<GreenRadio />} label="Large" />
                </RadioGroup>
              </FormControl>
            </SettingItemRow>
            <SettingItemRow title={t('A174')}>
              <InlineColorPicker
                defaultColor={socialModel?.color}
                onChangeColor={(color: string) => {
                  setSocialModel((prev: SocialProps) => {
                    return {
                      ...prev,
                      color,
                    };
                  });
                }}
              />
            </SettingItemRow>

            <Button
              variant="contained"
              sx={{ borderRadius: 20, marginTop: 2, boxShadow: 'none' }}
              onClick={handleSaveSettings}
            >
              {t('A175')}
            </Button>
          </>
        </SettingContainer>
      </ItemSectionStyle>
      <ItemSectionStyle style={{ padding: 10 }}>
        <Row style={{ justifyContent: 'space-between', marginTop: 20 }}>
          <div style={{ flex: 1 }}>
            <UrlForm
              usePrefix={false}
              ref={urlRef}
              label="SNS Url"
              model={typeof socialLink?.id !== 'undefined' ? socialLink?.linkUrl : ''}
              onChangeValue={handleChangeUrl}
              onPaste={handlePasteUrl}
            />
          </div>
          <Row
            style={{
              width: typeof socialLink?.id !== 'undefined' ? 150 : 100,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <OhmossButton
              style={{ padding: '10px 20px', marginRight: 20 }}
              disabled={!isValid}
              onClick={(e) => {
                if (!isValid) {
                  return;
                }
                if (typeof socialLink?.id === 'undefined') {
                  handleConfirmRegister();
                } else {
                  handleConfirmEdit();
                }
              }}
            >
              {t(typeof socialLink?.id === 'undefined' ? 'save' : 'edit')}
            </OhmossButton>
            <>
              {typeof socialLink?.id !== 'undefined' && (
                <OhmossButton
                  type="link"
                  onClick={() => {
                    reset();
                  }}
                >
                  {t('Cancel')}
                </OhmossButton>
              )}
            </>
          </Row>
        </Row>
      </ItemSectionStyle>
      <ListOfSNSContainer onLinkDelete={handleDeleteSNS} onLinkSelect={handleSelectSNS} />
      <Confirm
        title={t('linkDelete')}
        visible={deleteConfirmVisible}
        setVisible={setDeleteConfirmVisible}
      />
    </>
  );
});

export default ProfileSocialComponent;
