import React from 'react';

import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { Row } from 'components/commons/layouts';
import { IconButton } from '@mui/material';

import styled from 'styled-components';
import boardCommonLogics from 'features/board/logic/boardCommonLogic';
import { PostDto } from 'services/data-contracts';

const PostModifyTool = ({
  shareUrl,
  shareSubject,
  item,
  onClickEdit,
  onClickDelete,
}: {
  shareUrl: string;
  shareSubject: string;
  item: PostDto;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
}) => {
  const { t } = useTranslation();
  const { channelStore, uiStores } = useStore();
  const { showEditBox, showShareBox } = boardCommonLogics();

  return (
    <div style={{ width: '100%' }}>
      <Row style={{ justifyContent: 'space-between' }}>
        <Row style={{ width: 150, justifyContent: 'flex-start', padding: '0 20px' }}>
          <div>
            <img src="/images/postlike.svg" alt="" />
          </div>
          <div style={{ margin: '0 20px' }}>
            <img src="/images/comment.svg" alt="" />
          </div>
          <div
            onClick={() => {
              showShareBox(shareSubject, shareUrl);
            }}
          >
            <img src="/images/share.svg" alt="" />
          </div>
        </Row>
        <>
          {channelStore.channel.isOwner && (
            <div
              style={{ marginRight: 20 }}
              onClick={() => {
                if (uiStores.userHomeStore?.currentBoard) {
                  showEditBox(item, uiStores.userHomeStore.currentBoard);
                }
              }}
            >
              <img src="/images/editpost.svg" alt="" />
            </div>
          )}
        </>
      </Row>
    </div>
  );
};

export default PostModifyTool;
