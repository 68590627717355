import React, { CSSProperties, memo, useEffect, useState } from 'react';
import { CustomType, ChannelProfileModel } from 'types/CommonTypes';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';

type AvatarSizeProps = 'default' | 'tiny' | 'small' | 'medium' | 'large' | 'full';

type AvatarProps = {
  size?: AvatarSizeProps;
  profile?: ChannelProfileModel;
  mode?: 'view' | 'edit';
  customStyle?: CustomType;
  style?: CSSProperties;
  containerHover?: boolean;
};

const Avatar = observer(
  ({ size = 'default', profile, mode, customStyle, style, containerHover }: AvatarProps) => {
    const [currentSize, setCurrentSize] = useState<number>(100);

    useEffect(() => {
      switch (size) {
        case 'default':
          return setCurrentSize(100);
        case 'tiny':
          return setCurrentSize(40);
        case 'small':
          return setCurrentSize(80);
        case 'medium':
          return setCurrentSize(120);
        case 'large':
          return setCurrentSize(140);

        default:
          return setCurrentSize(100);
      }
    }, []);

    return (
      <>
        <AvatarContainerStyle
          customStyle={customStyle}
          style={style}
          containerHover={containerHover}
        >
          <AvatarLayoutStyle
            mode={mode}
            size={currentSize}
          // useBorder={profile?.borderInfo?.useBorder}
          // borderColor={profile?.borderInfo?.borderColor}
          // borderWidth={profile?.borderInfo?.borderWidth}
          // borderRadius={profile?.borderInfo?.borderRadius}
          >
            {profile?.profileImage?.publicUrl ? (
              <AvatarImageStyle src={profile?.profileImage?.publicUrl} alt="" />
            ) : (
              <EmptyAvatarImageStyle
                src={'/images/profileAvatar.svg'}
                style={{ width: '100%' }}
                alt=""
              />
            )}
          </AvatarLayoutStyle>
        </AvatarContainerStyle>
      </>
    );
  },
);

const AvatarContainerStyle = styled.div<{
  customStyle?: CustomType;
  containerHover?: boolean;
  style?: CSSProperties;
  isUserPage?: boolean;
  didScroll?: boolean;
}>`
  ${(props) => props.customStyle?.avatarWrapper}
  > div {
    ${(props) => props.customStyle?.avatarContainer}
    ${(props) => (props.containerHover ? props.customStyle?.avatarContainerHover : '')}
    ${(props) => (props.isUserPage ? props.customStyle?.userAvatarContainer : '')}
    ${(props) => (props.didScroll ? props.customStyle?.avatarContainerHover : '')}
    > img {
      ${(props) => props.customStyle?.avatar}
      ${(props) => (props.containerHover ? props.customStyle?.avatarHover : '')}
      ${(props) => (props.isUserPage ? props.customStyle?.userAvatar : '')}
      ${(props) => (props.didScroll ? props.customStyle?.avatarHover : '')}
    }
  }
`;

const AvatarLayoutStyle = styled.div<{
  size: number;
  useBorder?: boolean;
  mode?: 'view' | 'edit';
  borderColor?: string;
  borderRadius?: number;
  borderWidth?: number;
}>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  position: relative;
  transition: border 0.2s ease-in-out;
  left: 0;
  right: 0;
  border: ${(props) =>
    props.useBorder
      ? (props) => `${props.borderWidth}px solid ${props.borderColor || 'black'}`
      : '0px solid transparent'};
  border-radius: ${(props) =>
    typeof props.borderRadius !== 'undefined' ? props.borderRadius : 50}%;
  text-align: center;
  overflow: hidden;
  z-index: 0;
  margin: auto;
`;

const AvatarImageStyle = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const EmptyAvatarImageStyle = styled.img`
  position: relative;
  top: 20%;
  left: 20%;
`;

export default memo(Avatar);
