import React, { useEffect, useState } from 'react';
import { ImageUploader } from 'components/commons';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import TitleForm from 'components/items/forms/TitleForm';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { ISuggestion } from 'types/BlockExtendsTypes';

import { CustomFile } from 'types/CommonTypes';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { validationHelper } from 'controls/validationHelper';
import useInitializeModel from 'libs/hooks/useInitializeModel';

import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { LinkTypes } from 'features/management/context/providers/ContextTypes';
import BlockController from 'controllers/BlockController';
import { LinkItemDto } from 'services/data-contracts';
import ChannelCategoryOptions from '../common/ChannelCategoryOptions';
import CommonAlign from '../commonControl/CommonAlign';
import ActionBox from 'features/csTown/components/pc/ActionBox';

const Proposal = ({ blockModel }: { blockModel?: ISuggestion }) => {
  const { uiStore } = useStore();
  const { initialModel, updateServiceModel, serviceModel, initialBinding, unmountModel } =
    useOhmossContext<LinkTypes<ISuggestion>>();
  const { updateBlock } = BlockController<ISuggestion>();

  const defaultProps: Partial<ISuggestion> = {
    contactType: 'offer',
    linkType: 'Suggestion',
    isActive: true,
  };

  const [isValid, setIsValid] = useState<boolean>(false);
  const [localModel, setLocalModel] = useInitializeModel<Partial<ISuggestion>>(
    blockModel,
    defaultProps,
    initialBinding,
  );

  const [optionItems, setOptionItems] = useState<LinkItemDto[]>([]);

  const handleNewItems = (items: LinkItemDto[]) => {
    updateServiceModel('items', items);
  };

  useEffect(() => {
    if (localModel) {
      const titleValid = validationHelper.required(localModel.title);
      // const itemsValid = localModel?.items?.length && localModel?.items?.length > 0;
      setIsValid(titleValid);
    }
  }, [localModel]);

  useEffect(() => {
    setOptionItems(initialModel?.items || []);
  }, [initialModel]);

  useEffect(() => {
    return () => {
      unmountModel();
    };
  }, []);

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <ImageUploader
          type="single"
          useCircularCrop={false}
          initialValue={initialModel?.thumbnail}
          attachType="image"
          onSelected={(image: CustomFile) => {
            updateServiceModel('thumbnail', image);
          }}
        />
      </div>
      <ItemSectionStyle>
        <ItemSectionTitle>타이틀</ItemSectionTitle>
        <ItemContent>
          <TitleForm
            onChangeValue={(value: string) => {
              setLocalModel('title', value);
              updateServiceModel('title', value);
            }}
            initialValue={initialModel?.title}
            placeHolder="문의 환영합니다."
            label="타이틀"
            style={{ width: '100%' }}
          />
        </ItemContent>
      </ItemSectionStyle>

      <ChannelCategoryOptions items={optionItems} onChangeItems={handleNewItems} />

      <CommonAlign
        initialValue={initialModel?.contentAlign || 'center'}
        title="타이틀 정렬"
        onChange={(value: string) => {
          updateServiceModel('contentAlign', value);
        }}
      />
      <ActionBox
        style={{ marginTop: 60, paddingBottom: 20 }}
        isValid={isValid}
        okText="저장"
        onOk={() => {
          uiStore.bottomSheet.close();
          updateBlock(serviceModel);
        }}
        onCancel={() => {
          uiStore.modal.close();
        }}
      />
    </div>
  );
};

export default Proposal;
