import React, { useEffect, useState } from 'react';
import { Row } from 'components/commons/layouts';
import { IChannelBoardModel, IShopBlock } from 'types/BlockExtendsTypes';
import { PostDto } from 'services/data-contracts';

import { useChannelContext } from 'features/linkService/context/useChannelContext';
import { ContentWrapper } from '../styles/ContentWrapperStyle';
import { OhmossButton } from 'resources/controls/OhmossButton';

import styled from 'styled-components';
import MyShopController from 'controllers/MyShopController';
import MyShopCardList from 'components/commons/MyShopCardList';
import useNavigation from 'hooks/useNavigation';

const MyShopTemplate = ({ item, editMode = true }: { item: IShopBlock; editMode?: boolean }) => {
  const { getOthersPosts, getPosts } = MyShopController();
  const { categoryBoards, categoryBoard } = useChannelContext();
  const { navigate } = useNavigation();
  const [shopItems, setShopItems] = useState<PostDto[]>([]);
  const [category, setCategory] = useState<IChannelBoardModel>();

  const getShopItems = async () => {
    await getCategory();
    if (item?.shopBoardId !== undefined) {
      const posts = (await getOthersPosts(item.shopBoardId as number, true)) as PostDto[];
      setShopItems(posts);
    }
  };

  const handleClickAddItem = () => {
    navigate(`/management/myshop/${item.shopBoardId as number}`);
  };

  const getEditableShopItems = async () => {
    if (item?.shopBoardId !== undefined) {
      getCategory();
      const posts = (await getPosts(item.shopBoardId as number)) as PostDto[];
      setShopItems(posts);
    }
  };

  const getCategory = async () => {
    const t = categoryBoards?.find((board) => board.id === item.shopBoardId) as IChannelBoardModel;
    if (t?.metadata) {
      const metadata = JSON.parse(t?.metadata);
      setCategory({ ...t, ...metadata });
    } else {
      setCategory(t);
    }
  };

  useEffect(() => {
    if (editMode) {
      getEditableShopItems();
    } else {
      getShopItems();
    }
  }, [item?.shopBoardId]);

  return (
    <>
      <ContainerStyle
        isTransparent={category?.useTransparent}
        id={`block_item_id_${item.id}`}
        style={{ cursor: item.linkUrl ? 'pointer' : 'default' }}
      >
        <Row style={{ justifyContent: 'flex-start' }}>
          <ContentWrapper
            style={{
              padding: !item.thumbnail?.publicUrl ? '10px' : 0,
            }}
          >
            {item?.showTitle && item?.title}
          </ContentWrapper>
        </Row>
        {shopItems?.length > 0 ? (
          <MyShopCardList
            useThumbnailInfo={category?.displayOnThumbnail}
            items={shopItems}
            thumbnailBackgroundColor={category?.thumbnailBackgroundColorRgba}
            thumbnailColor={category?.thumbnailColorRgba}
            itemsPerRow={category?.layout}
            boxStyle={category?.boxStyle}
          />
        ) : (
          <>
            <div style={{ textAlign: 'center' }}>등록된 상품이 없습니다.</div>
            {editMode && (
              <OhmossButton type="simple" onClick={handleClickAddItem}>
                상품 등록
              </OhmossButton>
            )}
          </>
        )}
      </ContainerStyle>
    </>
  );
};

const ContainerStyle = styled.div<{ isTransparent?: boolean }>``;

export default MyShopTemplate;
