import { makeObservable, observable, action, flow } from 'mobx';
import { MyChannel as MyChannelService } from 'services/MyChannel';
import {
  ChannelCreationDto,
  ChannelDto,
  FriendshipDto,
  UserProfileDto,
  FriendshipTagDto,
  RequestChannelAuthenticationDto,
} from 'services/data-contracts';
import _ from 'lodash';
import { HttpClient } from 'services/http-client';
import { ChannelModel } from 'types/CommonTypes';
import { parseJSONAll } from 'libs/helper/utils';

import i18n from 'i18n';
import ServiceStoreBase from './ServiceStoreBase';

export interface isValid {
  isValid: boolean;
}

class MyChannelStore extends ServiceStoreBase {
  t = i18n.t;

  myChannels: Array<ChannelDto> = [];

  myFollowers: Array<FriendshipDto>;

  myFollowings: Array<FriendshipDto>;

  filteredFollowers: Array<UserProfileDto> | undefined;

  /**
   * @deprecated
   */
  currentChannelId: number;

  currentChannel: ChannelModel | undefined;

  constructor() {
    super();
    makeObservable(this, {
      myChannels: observable,
      myFollowers: observable,
      myFollowings: observable,
      currentChannel: observable,
      filteredFollowers: observable,
      setCurrentChannelId: action,
      setCurrentChannel: action,
      unMount: action,
      updateChannelName: flow,
      follow: flow,
      unfollow: flow,
      getMyFollowers: flow,
      getMyFollowings: flow,
      getMyFollowersByTags: flow,
      authInstagram: flow,
      requestInstagramAuthUrl: flow,
      authChannel: flow,
      deleteProposalByProposer: flow,
      deleteProposalByReceipient: flow,
      getReceivedProposals: flow,
      getReceivedProposal: flow,
      getSentProposals: flow,
      getSentProposal: flow,
      // checkChannelName: flow,
    });
  }

  api: MyChannelService<unknown> = new MyChannelService(new HttpClient());

  *getItem(itemId: number) {
    const { response } = yield this.api.getChannel(itemId);
    return response.data;
  }

  *getItems(args?: Array<never>) {
    const { response } = yield this.api.getChannels({ size: 50 });
    if (response.status === 200) {
      const newData = response.data?.map((data: ChannelDto) => {
        let parsedProfile: any = {};
        try {
          parsedProfile = parseJSONAll(data.profile?.metadata);
        } catch (error) {
          console.error('JSON parsing error:', error);
        }
        const mergedProfile = { ...data.profile, ...parsedProfile };
        return { ...data, profile: mergedProfile };
      });
      this.myChannels = newData;
      return newData;
    } else {
      return [];
    }
  }

  *createItem(channelInfo: ChannelCreationDto) {
    const { response } = yield this.api.createChannel(channelInfo);
    super.handleByStatus(response);
    return response.status === 200 ? response.data : null;
  }

  *updateItem(channelId: number, channelInfo: ChannelDto) {
    const { response } = yield this.api.updateChannelProfile(channelId, channelInfo);
    super.handleByStatus(response, { successMessage: this.t('A219') });
    if (response.status === 200) {
      let parsedProfile: any = {};
      try {
        parsedProfile = parseJSONAll(response.data.profile?.metadata);
      } catch (error) {
        console.error('JSON parsing error:', error);
      }
      const mergedProfile = {
        ...response.data,
        profile: {
          ...response.data?.profile,
          ...parsedProfile,
        },
      };
      return mergedProfile;
    } else {
      return undefined;
    }
  }

  *updateChannelName(channelInfo: ChannelDto) {
    const { response } = yield this.api.updateChannel(channelInfo?.id as number, channelInfo);
    super.handleByStatus(response, { successMessage: this.t('A219') });
    if (response.status === 200) {
      return response.data;
    }
  }

  *deleteItem(channelId: number) {
    const { response } = yield this.api.deleteChannel(channelId);
    return super.handleByStatus(response, { successMessage: this.t('A220') });
  }

  *getMyFollowers(channelId: number) {
    const { response } = yield this.api.getFollowers(channelId);
    if (response.status === 200) {
      this.myFollowers = response.data;
      return response.data;
    } else {
      this.myFollowers = [];
      response.data;
    }
  }

  *getMyFollowings(channelId: number) {
    const { response } = yield this.api.getFollowings(channelId);
    this.myFollowings = response.data;
    if (response.status === 200) {
      this.myFollowings = response.data;
      return response.data;
    } else {
      this.myFollowings = [];
      response.data;
    }
  }

  *getMyFollowersByTags(channelId: number, tagIds: Array<number>) {
    const { response } = yield this.api.getFollowers(channelId, { tagIds });
    if (tagIds.length > 0) {
      this.filteredFollowers = response.data;
    } else {
      this.filteredFollowers = [];
    }
  }

  *follow(channelId: number, channelName: string, tags: FriendshipTagDto) {
    if (channelName) {
      const { response } = yield this.api.follow(channelId, tags);
      super.handleByStatus(response, {
        successMessage: `${channelName}채널을 Follow합니다.`,
      });
      return response;
    }
  }

  *authInstagram(channelId: number, data: RequestChannelAuthenticationDto) {
    // const { response } = yield this.api.authenticateInstagram(channelId, { code, redirect_uri });
    const { response } = yield this.api.requestChannelAuthentication(channelId, data);
    return response.status < 300;
  }

  *unfollow(channelId: number, channelName: string) {
    const { response } = yield this.api.unfollow(channelId, {
      targetChannel: { channelName },
      tags: [],
    });
    return super.handleByStatus(response, {
      successMessage: `${channelName}채널을 unfollow합니다.`,
    });
  }

  *requestInstagramAuthUrl(channelId: number, data: RequestChannelAuthenticationDto) {
    const { response } = yield this.api.requestChannelAuthentication(channelId, data);
    return response.status < 300 ? response.data : response;
  }

  *authChannel(channelId: number, data: RequestChannelAuthenticationDto) {
    const { response } = yield this.api.requestChannelAuthentication(channelId, data);
    return response.status < 300 ? response.data : response;
  }

  *deleteProposalByReceipient(channelId: number, proposalId: number) {
    const { response } = yield this.api.deleteProposalByReceipient(channelId, proposalId);
    return response.status < 300 ? response.data : response;
  }

  *deleteProposalByProposer(channelId: number, proposalId: number) {
    const { response } = yield this.api.deleteProposalByProposer(channelId, proposalId);
    return response.status < 300 ? response.data : response;
  }

  *getReceivedProposals(channelId: number) {
    const { response } = yield this.api.getReceivedProposals(channelId);
    return response.status < 300 ? response.data : response;
  }

  *getReceivedProposal(channelId: number, proposalId: number) {
    const { response } = yield this.api.getReceivedProposal(channelId, proposalId);
    return response.status < 300 ? response.data : response;
  }

  *getSentProposals(channelId: number) {
    const { response } = yield this.api.getSuggestedProposals(channelId);
    return response.status < 300 ? response.data : response;
  }

  *getSentProposal(channelId: number, proposalId: number) {
    const { response } = yield this.api.getSuggestedProposal(channelId, proposalId);
    return response.status < 300 ? response.data : response;
  }

  setCurrentChannelId(chanelId: number) {
    if (chanelId) {
      this.currentChannelId = chanelId;
    }
  }

  setCurrentChannel(channel: ChannelDto | undefined) {
    this.currentChannel = channel;
  }

  unMount() {
    this.myChannels = [];
  }
}

export default MyChannelStore;
