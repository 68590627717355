import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  ChannelChecklistCreationDto,
  ChannelChecklistTopicDto,
  LinkDto,
} from 'services/data-contracts';
import styled from 'styled-components';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { useRootContext } from 'libs/hooks/useRootContext';
import { useStore } from 'stores/StoreHelper';
import { Row } from 'components/commons/layouts';
import CommonThumbnail from 'features/management/components/commonControl/CommonThumbnail';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import ChecklistController from 'controllers/ChecklistController';
import { toast } from 'react-toastify';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ChecklistTopicContainer from 'features/myPage/containers/checklist/ChecklistTopicContainer';
import { useStateContext } from 'libs/hooks/usePageState';
import { CSTownChecklistState } from 'types/CommonTypes';
import Text from 'components/commons/Text';
import CSTownChecklistController from 'features/csTown/pages/pageControllers/CSTownChecklistController';
import DealList from 'features/myPage/containers/DealList';
import { IconButton } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';

import { DetailHeader, MenuIconBoxStyle } from 'features/csTown/commonComponents/styles';

import DeleteIcon from 'features/csTown/commonComponents/DeleteIcon';
import { Menu } from 'features/csTown/commonComponents/SubTabs';
import ChecklistTopicItemsContainer from './ChecklistTopicItemsContainer';
import ChecklistTopicSummaryContainer from './ChecklistTopicSummaryContainer';

const CSTownChecklistDetailContainer = () => {
  const menus: Menu[] = [
    { id: 1, title: '체크리스트 상세', Component: ChecklistTopicItemsContainer },
    { id: 2, title: '요약보기', Component: ChecklistTopicSummaryContainer },
  ];
  const { uiStore } = useStore();
  const { myChannel } = useRootContext();
  const {
    getMyChecklist,
    getChannelChecklistTopic,
    getChecklistsByCategory,
    deleteMyChecklist,
    updateMyChecklist,
    downloadTopicsPdf,
  } = ChecklistController();
  const { serviceModel, updateServiceModel, updateServiceModelMultiple } =
    useModelManagement<ChannelChecklistCreationDto>();
  const { stateModel, setPageState, broadcast, receiver } = useStateContext<CSTownChecklistState>();

  const [topics, setTopics] = useState<ChannelChecklistTopicDto[]>([]);

  // const category = searchParam.get('category');
  // const id = searchParam.get('id');
  const { getDeals } = CSTownChecklistController();

  const getChecklistInfo = async () => {
    const result = await getMyChecklist(
      myChannel?.id as number,
      stateModel?.selectedChecklist?.id as number,
    );

    if (result?.deal) {
      setPageState('deal', result.deal);
    }
  };

  const getTopics = async () => {
    const tempTopics = await getChannelChecklistTopic(
      myChannel?.id as number,
      stateModel?.selectedChecklist?.id as number,
    );
    setTopics(tempTopics);
  };

  const getNewChecklistTopics = async () => {
    const tempTopics = (await getChecklistsByCategory(
      stateModel?.selectedChecklist?.category?.id as number,
      myChannel?.id as number,
    )) as any;

    if (tempTopics?.items) {
      const uniqueTopics = Array.from(
        new Set(tempTopics?.items.map((item: any) => item.topicName)),
      ).map((topicName) => ({
        topicName,
        progressBar: 0,
      }));
      setTopics(uniqueTopics as ChannelChecklistTopicDto[]);
    }
  };

  const handleSelectDeal = () => {
    getDeals();
    uiStore.drawer.show({
      title: '연결할 공구 선택',
      children: (
        <DealListWrapperStyle>
          <DealList
            useContext={false}
            deals={stateModel?.deals}
            onClick={async (result?: LinkDto) => {
              setPageState('deal', result);
              updateServiceModel('dealId', result?.id);
              const newChecklist = await updateMyChecklist(
                myChannel?.id as number,
                stateModel?.selectedChecklist?.id as number,
                serviceModel as ChannelChecklistCreationDto,
              );
              uiStore.drawer.close();
              broadcast({ id: 'reload' });
              setPageState('selectedChecklist', newChecklist);
            }}
            customContext={
              <IconButton>
                <InsertLinkIcon />
              </IconButton>
            }
          />
        </DealListWrapperStyle>
      ),
    });
  };

  const handleDelete = async () => {
    if (stateModel?.selectedChecklist?.id !== null) {
      uiStore.confirm.show({
        message: '체크리스트를 삭제하시겠습니까?',
        onConfirmed: async () => {
          await deleteMyChecklist(
            myChannel?.id as number,
            stateModel?.selectedChecklist?.id as number,
          );
          setPageState('selectedChecklist', null);
          broadcast({ id: 'reload', param: 'setPageMode' });
        },
      });
    }
  };

  const handleClickPdf = () => {
    if (stateModel?.selectedChecklist) {
      downloadTopicsPdf(stateModel?.selectedChecklist?.id as number);
    }
  };

  useEffect(() => {
    if (myChannel) {
      if (stateModel?.selectedChecklist?.id !== null) {
        getDeals();
        getChecklistInfo();
        getTopics();
        updateServiceModelMultiple({
          ...stateModel?.selectedChecklist,
        });
        // updateServiceModel('categoryId', stateModel?.selectedChecklist?.category?.id as number);
      } else {
        getDeals();
        getNewChecklistTopics();
        updateServiceModelMultiple({
          ...stateModel?.selectedChecklist,
        });
        // updateServiceModel('categoryId', stateModel?.selectedChecklist?.category?.id as number);
      }
    }
  }, [stateModel?.selectedChecklist]);

  useEffect(() => {
    if (receiver?.param == 'checklistItemUpdate') {
      getTopics();
    }
    setPageState('deal', undefined);
  }, [stateModel?.selectedChecklist, receiver]);

  return (
    <>
      <div style={{ padding: '0px 20px' }}>
        <DetailHeader>
          <Row style={{ justifyContent: 'space-between' }}>
            <Row style={{ justifyContent: 'flex-start', gap: 10 }}>
              <>
                {stateModel?.selectedChecklist?.deal ? (
                  <CommonThumbnail
                    initialValue={stateModel?.selectedChecklist?.deal?.thumbnail?.publicUrl}
                  />
                ) : (
                  <img
                    src={stateModel?.selectedChecklist?.category?.image?.publicUrl}
                    alt=""
                    style={{ width: 'auto', height: 45 }}
                  />
                )}
              </>
              <div>
                <Row
                  style={{
                    gap: 5,
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <Row style={{ justifyContent: 'flex-start' }}>
                    <Text type="H2">{stateModel?.selectedChecklist?.title}</Text>
                    <Text type="H4" buttonRole="DESCRIPTION" style={{ textTransform: 'uppercase' }}>
                      - {stateModel?.selectedChecklist?.category?.name} Checklist
                    </Text>
                  </Row>
                </Row>
                <div>
                  <Text type="H4" buttonRole="DESCRIPTION" style={{ textTransform: 'uppercase' }}>
                    {stateModel?.selectedChecklist?.deal && (
                      <Text type="H4" buttonRole="DESCRIPTION">
                        {stateModel?.selectedChecklist?.deal?.title} Checklist
                      </Text>
                    )}
                  </Text>
                </div>
              </div>
            </Row>
            <>
              {stateModel?.selectedChecklist?.deal === null && (
                <>
                  <SelectDealBtn onClick={handleSelectDeal}>공구 연결하기</SelectDealBtn>
                </>
              )}
            </>
          </Row>
          <MenuIconBoxStyle style={{ height: 70, width: '100%', justifyContent: 'flex-end' }}>
            <>
              {stateModel?.deal === null && (
                <IconButton
                  onClick={() => {
                    // setPageState('pageMode', 'EDIT');
                  }}
                >
                  <CreateIcon style={{ width: 25, height: 25 }} />
                </IconButton>
              )}
              <IconButton>
                <PictureAsPdfOutlinedIcon onClick={handleClickPdf} />
              </IconButton>
              <DeleteIcon onClick={handleDelete} />
            </>
          </MenuIconBoxStyle>
        </DetailHeader>
      </div>
      {stateModel?.selectedTopic ? (
        <div style={{ marginTop: 20 }}>
          <ChecklistTopicItemsContainer />
        </div>
      ) : (
        //
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <ChecklistTopicContainer
            category={stateModel?.selectedChecklist?.category?.id?.toString() ?? undefined}
            topics={topics}
            checklistId={stateModel?.selectedChecklist?.id}
          />
        </div>
      )}
    </>
  );
};

const SelectDealBtn = styled.button`
  background: #011208;
  color: #0bbd58;
  font-size: 0.875rem;
  font-weight: 500;
  width: 100px;
  padding: 0.3rem 0.3rem;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
`;

const DealListWrapperStyle = styled.div`
  padding: 20px;
`;

export default CSTownChecklistDetailContainer;
