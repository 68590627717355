import { makeObservable, flow, observable, action } from 'mobx';
import { LinkDto, SocialLinkDto } from 'services/data-contracts';
import { SocialLink as SocialLinkService } from 'services/SocialLink';
import { HttpClient } from 'services/http-client';

import _ from 'lodash';
import ServiceStoreBase from './ServiceStoreBase';

class SocialStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      socials: observable,
      social: observable,
      getSocials: flow,
    });
  }

  api: SocialLinkService<unknown> = new SocialLinkService(new HttpClient());

  socials: Array<SocialLinkDto>;

  social: SocialLinkDto;

  /** ========================================================================================= */

  *getSocials(channelName: string) {
    const { response } = yield this.api.getSocialLinks1(channelName);
    this.socials = response.data;
  }
}

export default SocialStore;
