import React from 'react';
import useNavigation from 'hooks/useNavigation';
import MenuItemBox from 'components/commons/menuItem/MenuItemBox';
import { MenuContainer } from 'features/myPage/components/CommonStyleComponent';
import MenuItem from 'components/commons/menuItem/MenuItem';
import { useRootContext } from 'libs/hooks/useRootContext';

const ManagedDeals = ({ url }: { url: string }) => {
  const { navigate } = useNavigation();
  const { myChannel } = useRootContext();

  return (
    <MenuItemBox style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
      <MenuContainer>
        <MenuItem
          title={myChannel?.channelType === 'SELLER' ? '내 공구목록' : '관리 공구'}
          onClick={() => {
            navigate(url);
            // if (myChannel?.channelType === 'INFLUENCER') {
            //   navigate('/my/deals');
            // } else {
            //   navigate('/my/deals/delegated-deals');
            // }
          }}
        />
      </MenuContainer>
    </MenuItemBox>
  );
};

export default ManagedDeals;
