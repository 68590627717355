import React, { ReactNode, useEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { BottomSheet as BottomSheetOrigin } from 'react-spring-bottom-sheet';
import { BottomSheetOptions } from 'types/CommonTypes';
import styled from 'styled-components';

import 'react-spring-bottom-sheet/dist/style.css';
import MobileModalHeader from './MobileModalHeader';

/**
 * @description debug 모드에서 작동안함
 */
const BottomSheetOnly = observer(
  ({ topPosition, useDefaultButtons }: { topPosition?: number; useDefaultButtons?: boolean }) => {
    const { uiStore } = useStore();
    const defaultTopPosition = topPosition || window.isWebView ? 50 : 10;
    const [options, setOptions] = useState<BottomSheetOptions>();

    const handleClickClose = () => {
      uiStore.bottomSheet.close();
    };

    const getSnapPoint = (heightProps: any) => {
      if (typeof options?.topPosition === 'undefined') {
        return [heightProps.minHeight];
      }
      return [heightProps.maxHeight - (options?.topPosition || defaultTopPosition)];
    };

    useEffect(() => {
      if (uiStore.bottomSheet.modalShow) {
        setTimeout(() => {
          const rootLayout = document.querySelector('.universal-layout') as any;
          const position = rootLayout?.getBoundingClientRect();
          const overlayElement = document.querySelector('[data-rsbs-overlay]') as any;
          if (overlayElement) {
            overlayElement.style.left = `${position.left}px`;
          }
        }, 0);

        if (uiStore.bottomSheet.options) {
          setOptions(uiStore.bottomSheet.options);
        }
      }
      return () => {
        uiStore.bottomSheet.updateModel(uiStore.bottomSheet?.contentModel);
      };
    }, [uiStore.bottomSheet.modalShow]);

    return (
      <>
        <CustomStyle show={uiStore.bottomSheet.modalShow} onClick={handleClickClose} />
        <BottomSheetOrigin
          style={{ left: 300, position: 'absolute' }}
          expandOnContentDrag={uiStore.bottomSheet.options?.expandOnContentDrag}
          open={uiStore.bottomSheet.modalShow}
          blocking={false}
          snapPoints={getSnapPoint}
          onDismiss={() => uiStore.bottomSheet.close()}
          header={false}
        >
          {!uiStore.bottomSheet.options?.header ? (
            <MobileModalHeader
              type="modal"
              title={uiStore.bottomSheet.options?.title || ''}
              onCloseClick={() => {
                uiStore.bottomSheet.close();
              }}
            />
          ) : (
            <>{uiStore.bottomSheet.options?.header}</>
          )}

          <ChildrenStyle
            style={{ marginTop: 20, ...uiStore.bottomSheet?.options?.style }}
            className="global-width"
          >
            {uiStore.bottomSheet.options?.children}
          </ChildrenStyle>
        </BottomSheetOrigin>
      </>
    );
  },
);

const CustomStyle = styled.div<{ show?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: ${(props) => (props.show ? 'all' : 'none')};
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s ease;
  z-index: 4;
`;

const ChildrenStyle = styled.div`
  margin-bottom: 20px;
`;
export default BottomSheetOnly;
