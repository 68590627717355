import React from 'react';
import { GroupChannel } from '@sendbird/chat/groupChannel';

import ChannelListBox from 'components/commons/chat/TalkChannelListBox';
import { useChat } from 'libs/hooks/useChat';
import styled from 'styled-components';
import useNavigation from 'hooks/useNavigation';
import { useMediaContext } from 'providers/useMediaContext';
import { useStore } from 'stores/StoreHelper';
import MyInquriesChat from './MyInquriesChat';
import StyleBox from 'components/commons/StyleBox';

/**
 * @description 일반 고객용
 * @param param0
 * @returns
 */
const MyInquriesContainer = ({ useSearch }: { useSearch: boolean }) => {
  const { channels } = useChat();
  const { navigate } = useNavigation();
  const { uiStore } = useStore();
  const ScreenTypes = useMediaContext();

  const handleClickChannel = (channel: GroupChannel) => {
    if (ScreenTypes.isSmall) {
      navigate(`/chat/${channel.url}`);
    } else {
      uiStore.modal.show({
        style: { background: '#f5f7fa' },
        children: <MyInquriesChat channelUrl={channel.url} />,
      });
    }
  };

  return (
    <ContainerStyle>
      <ChannelListBox
        inquiries={channels}
        onJoinChannel={handleClickChannel}
        useSearch={useSearch}
        role="CUSTOMER"
      />
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  /* margin-bottom: 200px; */
`;

export default MyInquriesContainer;
