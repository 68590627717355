import React from 'react';
import styled, { css } from 'styled-components';
import useNavigation from 'hooks/useNavigation';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { OhmossButton } from 'resources/controls/OhmossButton';

import _ from 'lodash';
import EmailSignUpForm from 'features/mainFeatures/auth/components/EmailSignUpForm';
import KakaoSignUpForm from 'features/mainFeatures/auth/components/KakaoSignUpForm';
import GoogleSignUpForm from 'features/mainFeatures/auth/components/GoogleSignUpForm';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import UniversalLayout from 'components/commons/layouts/UniversalLayout';
import DetailPageHeader from 'components/commons/DetailPageHeader';

const SignUpPage = observer(() => {
  const { t } = useTranslation();
  const { key } = useParams();
  const { navigate } = useNavigation();

  const getForm = () => {
    if (key) {
      switch (key) {
        case 'a7934':
          return <EmailSignUpForm />;
        case 'k9934':
          return <KakaoSignUpForm />;
        case 'g7931':
          return <GoogleSignUpForm />;
        default:
          break;
      }
    }
  };

  return (
    <>
      <DetailPageHeader title="회원가입" />
      <ManagementLayout style={{ justifyContent: 'flex-start' }}>
        <SignUpContainerStyle>
          {/* <SignUpTitleStyle>{t('A93')}</SignUpTitleStyle> */}
          {getForm()}
          <LoginStyle>
            {t('A110')}
            <OhmossButton
              style={{ width: '15%', color: '#3e64ea' }}
              type="link"
              onClick={() => {
                navigate('/login');
              }}
            >
              {t('A94')}
            </OhmossButton>
          </LoginStyle>
        </SignUpContainerStyle>
      </ManagementLayout>
    </>
  );
});

const alignCenter = css`
  display: flex;
  align-items: center;
`;

const SignUpTitleStyle = styled.div`
  /* ${alignCenter} */
  justify-content: center;
  font-size: 30px;
  font-weight: 500;
`;

const SignUpContainerStyle = styled.div`
  text-align: center;
  width: 90%;
`;

const LoginStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
`;

export default SignUpPage;
