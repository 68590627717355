import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { LinkDto, LinkStickerDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import { StickerTypes } from 'components/commons/stickers/StickerTypes';

import StickerBox from 'components/commons/stickers/StickerBox';
import AddIcon from '@mui/icons-material/Add';
import SimpleStickerBox from 'components/commons/stickers/SimpleStickerBox';
import { IDealBlock } from 'types/BlockExtendsTypes';
import { ListBoxItem } from './styles';
import { usePageModelContext } from 'features/management/context/usePageModelContext';
import { Row } from 'components/commons/layouts';
import styled from 'styled-components';
import { useStateContext } from 'libs/hooks/usePageState';
import { MyDealState } from 'types/CommonTypes';

const ItemStickerBox = ({
  stickers,
  useModify,
  onUpdate,
}: {
  stickers?: LinkStickerDto[];
  useModify?: boolean;
  onUpdate?: (
    type: 'STICKER_POSITION' | 'STICKERS',
    value: { stickerPosition?: 'top' | 'bottom'; stickers?: StickerTypes[] },
  ) => void;
}) => {
  const { uiStore } = useStore();
  const { setState, stateModel, updateServiceModel, initialModel } =
    usePageModelContext<IDealBlock>();

  const [stickerPosition, setStickerPosition] = useState<'top' | 'bottom'>('top');

  const handleClickAddStickers = () => {
    uiStore.modal.show({
      children: (
        <SimpleStickerBox
          initialValue={stateModel?.stickers || []}
          onChange={async (stickers: StickerTypes[]) => {
            setState('stickers', stickers);
            onUpdate?.('STICKERS', { stickers });
          }}
        />
      ),
    });
  };

  useEffect(() => {
    if (stickers) {
      setState('stickers', stickers);
    }
  }, [stickers]);

  useEffect(() => {
    if (initialModel?.stickerPosition) {
      setStickerPosition(initialModel?.stickerPosition);
    }
  }, [initialModel?.stickerPosition]);

  return (
    <ListBoxItem
      style={{}}
      title="Stickers"
      action={
        <>
          {useModify === true && (
            <IconButton style={{ background: '#efefef' }} onClick={handleClickAddStickers}>
              <AddIcon />
            </IconButton>
          )}
        </>
      }
    >
      <>
        {stateModel?.stickers && stateModel?.stickers?.length > 0 && (
          <div style={{ margin: '10px 0' }}>
            {useModify === true && (
              <>
                <div>스티커 표시위치</div>
                <div style={{ display: 'inline-block', margin: '20px 0', marginTop: 5 }}>
                  <Row
                    style={{
                      background: '#e2e2e2',
                      borderRadius: 40,
                      padding: 2,
                      height: 30,
                      alignItems: 'center',
                    }}
                  >
                    <ButtonStyle
                      on={stickerPosition === 'top'}
                      onClick={() => {
                        setStickerPosition('top');
                        updateServiceModel('stickerPosition', 'top');
                        onUpdate?.('STICKER_POSITION', { stickerPosition: 'top' });
                      }}
                    >
                      위
                    </ButtonStyle>
                    <ButtonStyle
                      on={stickerPosition === 'bottom'}
                      onClick={() => {
                        setStickerPosition('bottom');
                        updateServiceModel('stickerPosition', 'bottom');
                        onUpdate?.('STICKER_POSITION', {
                          stickerPosition: 'bottom',
                        });
                      }}
                    >
                      아래
                    </ButtonStyle>
                  </Row>
                </div>
              </>
            )}
            <StickerBox stickers={stateModel?.stickers} useModify={useModify} />
          </div>
        )}
      </>
    </ListBoxItem>
  );
};

const ButtonStyle = styled.div<{ on?: boolean }>`
  background: ${(props) => (props.on ? '#fff' : 'transparent')};
  padding: 6px;
  border-radius: 30px;
  width: 66px;
  font-size: 12px;
  height: 26px;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export default ItemStickerBox;
