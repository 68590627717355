import { flow, makeObservable } from 'mobx';
import { Inquiry as InquiryService } from 'services/Inquiry';
import { InquiryDto } from 'services/data-contracts';

import _ from 'lodash';
import { HttpClient } from 'services/http-client';
import ServiceStoreBase from './ServiceStoreBase';

class InquiryStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      inquire: flow,
    });
  }

  api: InquiryService<unknown> = new InquiryService(new HttpClient());

  *inquire(channelId: number, inquiryData: InquiryDto) {
    const { status, data, error } = yield this.api.inquire(channelId, inquiryData);
    if (status === 200) {
      return data;
    } else {
      return error;
    }
  }
}

export default InquiryStore;
