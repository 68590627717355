import { ListBoxItemTemplate } from 'features/csTown/commonComponents/styles';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { ChannelDto } from 'services/data-contracts';
import styled from 'styled-components';

const ReceivedGiftInfoCard = ({
  channelType,
  membership,
  hideMonth,
}: {
  channelType?: ChannelDto['channelType'];
  membership?: any;
  hideMonth?: boolean;
}) => {
  const getMembershipName = (name: any) => {
    if (name) {
      return <>{name}</>;
    } else {
      return 'FREE 멤버십';
    }
  };

  const handleAcceptGift = async (id: number) => {
    toast.warn('준비중입니다.');
  };

  const handleRejectGift = async (id: number) => {
    toast.warn('준비중입니다.');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
      {membership.map((item: any, index: number) => (
        <ListBoxItemTemplate style={{ margin: 0 }}>
          <CardLine>
            <CardItem style={{ fontWeight: 500, fontSize: 18 }}>
              {getMembershipName(item?.membershipPlan?.name)} 이용권
              {/* <Period>유효기간: </Period> */}
            </CardItem>
            <Status>이용가능</Status>
          </CardLine>

          <CardLine style={{ padding: '0 1.5rem' }}>
            <Divider />
          </CardLine>

          <CardLine>
            <CardItem style={{ fontWeight: 400 }}>{item?.monthly}개월</CardItem>
          </CardLine>

          <CardLine style={{ padding: '0 1.5rem' }} />
          <CardLine style={{ padding: '0 1.5rem 1rem 1.5rem', gap: 20 }}>
            <OhmossButton style={{ height: 40 }} onClick={() => handleAcceptGift(item?.id)}>
              승인
            </OhmossButton>
            <OhmossButton
              onClick={() => handleRejectGift(item?.id)}
              style={{
                backgroundColor: 'transparent',
                border: '1.5px solid #173300',
                color: '#173300',
                height: 40,
              }}
            >
              거절
            </OhmossButton>
          </CardLine>
        </ListBoxItemTemplate>
      ))}
    </div>
  );
};

const CardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  border-radius: 1.25rem;
  align-self: stretch;
  background-color: #9de4bc;
  border: 1px solid #173300;
`;

const CardLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 1.5rem;
`;

const CardItem = styled.div`
  color: #173300;
  font-size: 1rem;
  font-weight: 100;
`;

const Divider = styled.div`
  width: 100%;
  border: 0.9px dashed #666;
`;

const Status = styled.div`
  display: flex;
  height: 1.375rem;
  padding: 0.25rem 0.625rem;
  align-items: center;
  border-radius: 62.4375rem;
  border: 1px solid #173300;
  color: #173300;
  font-size: 0.75rem;
`;

const Period = styled.div`
  color: #717171;
  font-size: 13px;
  font-weight: 300;
`;

export default ReceivedGiftInfoCard;
