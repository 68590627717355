import React, { ReactElement, useEffect } from 'react';
import { LinkDto } from 'services/data-contracts';

import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useStore } from 'stores/StoreHelper';
import { useRootContext } from 'libs/hooks/useRootContext';

import AdItemMoreButtons from 'features/mossMain/components/AdItemMoreButtons';
import useNavigation from 'hooks/useNavigation';
import DealInfo from '../components/DealInfo';
import DealListContenxt from '../components/DealListContenxt';

const DealList = observer(
  ({
    deals,
    useContext = true,
    customContext,
    onClick,
  }: {
    deals: LinkDto[] | undefined | null;
    useContext?: boolean;
    customContext?: any;
    onClick?: (deal?: LinkDto) => void;
  }) => {
    const { myChannel } = useRootContext();
    const { uiStore } = useStore();
    const { navigate } = useNavigation();

    const handleClickMore = (deal: LinkDto) => {
      uiStore.bottomSheet.show({
        title: '공구 상세',
        children:
          myChannel?.channelType === 'SELLER' ? (
            <AdItemMoreButtons link={deal as LinkDto} />
          ) : (
            <DealListContenxt deal={deal} />
          ),
      });
    };

    return (
      <>
        <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
          {deals?.map((deal) => (
            <InquiryStyle
              onClick={() => {
                if (!customContext) {
                  onClick?.(deal);
                }
              }}
            >
              <div style={{ flexGrow: 1 }}>
                <DealInfo
                  item={deal}
                  useContext={useContext}
                  cutOff={1000}
                  onClick={() => {
                    if (myChannel?.channelType === 'MEMBER') {
                      navigate(`/cstown/deal/${deal?.id}/inquiries`);
                    }
                  }}
                  onClickMore={() => {
                    handleClickMore(deal);
                  }}
                />
              </div>
              {customContext && (
                <div
                  onClick={() => {
                    onClick?.(deal);
                  }}
                >
                  {customContext}
                </div>
              )}
            </InquiryStyle>
          ))}
        </div>
      </>
    );
  },
);

const InquiryStyle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default DealList;
