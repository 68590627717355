import TitleForm from 'components/items/forms/TitleForm';
import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { ItemSectionTitle } from 'resources/controls/ItemSectionStyle';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import styled from 'styled-components';
import _, { debounce } from 'lodash';
import { LinkDto, MemberCreationDto, MemberDto, MemberUpdateDto } from 'services/data-contracts';
import PasswordForm from 'components/items/forms/PasswordForm';
import EmailForm from 'components/items/forms/EmailForm';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import { validationHelper } from 'controls/validationHelper';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';
import PartnerController from 'controllers/PartnerController';
import CSTownController from 'controllers/CSTownController';
import { useRootContext } from 'libs/hooks/useRootContext';
import AssignedDealsList from './AssignedDealsList';
import AssignedDealItem from './AssignedDealItem';

type MemberCombineModel = MemberDto & MemberCreationDto;
const MemberCreateContainer = ({
  model,
  type,
  managerId,
  style,
  onUpdate,
}: {
  model?: MemberDto;
  type?: 'view' | 'edit';
  managerId?: number;
  style?: CSSProperties;
  onUpdate?: () => void;
}) => {
  const { uiStore } = useStore();
  const { myChannel } = useRootContext();

  const { initialModel, initialBinding, updateServiceModel, serviceModel } =
    useModelManagement<MemberCombineModel>();

  const [localModel, setLocalModel] = useInitializeModel<Partial<MemberCombineModel>>(
    model,
    undefined,
    initialBinding,
  );

  // const {
  //   updateManager,
  //   checkDuplicateUsername,
  //   createManager,
  //   getDelegatedDeals,
  //   assignDealsToManager,
  // } = AgencyController();

  const { Brand } = CSTownController();
  const { getMemberAssignedDeals } = PartnerController();
  const [ongoingDeals, setOngoingDeals] = useState<LinkDto[]>([]);
  const [selectedDeals, setSelectedDeals] = useState<LinkDto[]>();
  const [isDuplicated, setIsDuplicated] = useState(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  const handleChangeChannelName = useCallback(
    debounce(async (username) => {
      const result = (await Brand.DEAL.checkDuplicateUsername(
        `${myChannel?.channelName}.${username}`,
      )) as any;
      setIsDuplicated(result);
      setIsValid(!result);
      updateServiceModel('username', `${myChannel?.channelName}.${username}`);
      setLocalModel('username', `${myChannel?.channelName}.${username}`);
    }, 500),
    [],
  );

  const getMyDeals = async () => {
    if (type === 'view') {
      const assignedDealsTemp = (await getMemberAssignedDeals(
        managerId as number,
      )) as unknown as LinkDto[];
      setOngoingDeals(assignedDealsTemp);
    } else {
      const result = (await Brand.DEAL.getDelegatedDeals(
        (model?.id as number) || undefined,
      )) as unknown as LinkDto[];
      setOngoingDeals(result);
    }
  };

  const handleSelectedDeals = (selectedDeals: LinkDto[]) => {
    setOngoingDeals(selectedDeals);
  };

  const handleOpenListOfDeals = () => {
    if (myChannel) {
      uiStore.bottomSheet.show({
        title: '위임 공구 리스트',
        children: (
          <AssignedDealsList
            dealList={ongoingDeals}
            onSelected={(selectedDeals: LinkDto[]) => {
              handleSelectedDeals(selectedDeals);
            }}
          />
        ),
      });
    }
  };

  const updateDeals = async (result: MemberDto) => {
    const dealList = ongoingDeals.map((deal) => {
      return { id: deal.id };
    });

    await Brand.DEAL.assignDealsToManager(myChannel?.id as number, result?.id as number, {
      deals: dealList,
    });
  };

  const validate = () => {
    const password = validationHelper.password(localModel?.password as string);
    const id = validationHelper.required(localModel?.username as string);
    const name = validationHelper.required(localModel?.name as string);
    if (model?.id) {
      if (name) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    } else if (
      password &&
      localModel?.password &&
      localModel?.password?.length >= 8 &&
      id &&
      name
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  useEffect(() => {
    if (ongoingDeals && myChannel?.channelType === 'BRAND') {
      const assignedDeal = ongoingDeals.filter(
        (deal: LinkDtoWithAssignedDeal) => deal.assigned,
      ) as unknown as LinkDto[];
      setSelectedDeals(assignedDeal);
    }
  }, [ongoingDeals]);

  useEffect(() => {
    if (localModel) {
      validate();
    }
  }, [localModel]);

  useEffect(() => {
    if (myChannel) {
      getMyDeals();
    }
  }, [myChannel]);

  useEffect(() => {
    if (model) {
      initialBinding(model);
    }
  }, [model]);

  return (
    <ContainerStyle style={style}>
      {/* <ImageWrapper>
        <ProfileImageUploader
          type="copper"
          useCircularCrop
          //   initialValue={initialModel?.profileImage}
          attachType="image"
          onSelected={(image: CustomFile) => {
            // updateServiceModel('profileImage', image);
          }}
        />
      </ImageWrapper> */}
      <InputBoxWrapper>
        <ItemSectionTitle style={{ margin: '10px 0px 5px 0px', fontSize: 13 }}>
          <span>매니저 아이디</span>
          {isDuplicated && <span style={{ color: 'red' }}>이미 사용중인 아이디입니다.</span>}
        </ItemSectionTitle>
        <TitleForm
          disabled={typeof initialModel?.id !== 'undefined'}
          initialValue={initialModel?.username?.split('.').slice(1).join('.')}
          prefix={`${myChannel?.channelName?.toUpperCase()}.`}
          style={{ borderColor: 'rgba(0, 0, 0, 0.35)' }}
          onChangeValue={(value: string) => {
            handleChangeChannelName(value);
          }}
          placeHolder="아이디를 입력해 주세요."
        />
        <ItemSectionTitle style={{ margin: '10px 0px 5px 0px', fontSize: 13 }}>
          패스워드
        </ItemSectionTitle>
        <PasswordForm
          autoComplete="new-password"
          onChangeValue={(value: string) => {
            setLocalModel('password', value);
            updateServiceModel('password', value);
          }}
          showLabel={false}
          placeHolder="이메일 주소로 안내가 발송 됩니다."
        />
        <ItemSectionTitle style={{ margin: '10px 0px 5px 0px', fontSize: 13 }}>
          매니저 이메일
        </ItemSectionTitle>
        <EmailForm
          model={initialModel?.email}
          style={{ borderColor: 'rgba(0, 0, 0, 0.35)' }}
          onChangeValue={(value: string) => {
            updateServiceModel('email', value);
          }}
        />
        <ItemSectionTitle style={{ margin: '10px 0px 5px 0px', fontSize: 13 }}>
          이름
        </ItemSectionTitle>
        <TitleForm
          style={{ borderColor: 'rgba(0, 0, 0, 0.35)' }}
          initialValue={initialModel?.name}
          onChangeValue={(value: string) => {
            updateServiceModel('name', value);
            setLocalModel('name', value);
          }}
        />
        <ItemSectionTitle style={{ margin: '10px 0px 5px 0px', fontSize: 13 }}>
          소속
        </ItemSectionTitle>
        <TitleForm
          style={{ borderColor: 'rgba(0, 0, 0, 0.35)' }}
          initialValue={initialModel?.company}
          onChangeValue={(value: string) => {
            updateServiceModel('company', value);
          }}
        />
        <ItemSectionTitle style={{ margin: '10px 0px 5px 0px', fontSize: 13 }}>
          연락처
        </ItemSectionTitle>
        <TitleForm
          initialValue={initialModel?.phoneNumber}
          style={{ borderColor: 'rgba(0, 0, 0, 0.35)' }}
          onChangeValue={(value: string) => {
            updateServiceModel('phoneNumber', value);
          }}
          placeHolder="파트너의 연락처를 입력하세요."
        />
        <ItemSectionTitle style={{ margin: '10px 0px 5px 0px', fontSize: 13 }}>
          위임 공구
        </ItemSectionTitle>
        {ongoingDeals && (
          <>
            {ongoingDeals
              ?.filter((deal: LinkDtoWithAssignedDeal) => deal.assigned)
              .map((deal, index) => <AssignedDealItem deal={deal} mode="view" />)}
          </>
        )}
        {ongoingDeals && myChannel?.channelType === 'SELLER' && (
          <>{ongoingDeals?.map((deal, index) => <AssignedDealItem deal={deal} mode="view" />)}</>
        )}
      </InputBoxWrapper>

      {myChannel?.channelType === 'BRAND' && (
        <ButtonWrapper>
          <OhmossButton type="secondary" onClick={handleOpenListOfDeals}>
            위임 공구 리스트
          </OhmossButton>
          <OhmossButton
            disabled={!isValid}
            onClick={async () => {
              let result;
              if (serviceModel?.id) {
                result = (await Brand.DEAL.updateManager(
                  myChannel?.id as number,
                  serviceModel?.id as number,
                  serviceModel as MemberUpdateDto,
                )) as unknown as MemberDto;
              } else {
                result = (await Brand.DEAL.createManager(
                  myChannel?.id as number,
                  serviceModel as MemberCreationDto,
                )) as unknown as MemberDto;
              }

              if (result) {
                updateDeals(result);
                onUpdate?.();
              }
            }}
          >
            확인
          </OhmossButton>
        </ButtonWrapper>
      )}
      {myChannel?.channelType === 'SELLER' && (
        <ButtonWrapper>
          <OhmossButton
            disabled={!isValid}
            onClick={() => {
              uiStore.modal.close();
            }}
          >
            확인
          </OhmossButton>
        </ButtonWrapper>
      )}
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const InputBoxWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  width: 100%;
  flex-direction: column;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
`;

export default MemberCreateContainer;
