import { PostItemDto } from 'services/data-contracts';

export const PostItemDtoDummy: PostItemDto = {
  id: undefined,
  seq: undefined,
  postId: undefined,
  title: undefined,
  content: undefined,
  linkUrl: undefined,
  file: undefined,
  metadata: undefined,
  isActive: undefined,
  createDate: undefined,
  modifyDate: undefined,
};
