import React, { useEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import LinkComponent from 'components/items/admin/LinkComponent';
import { LinkModel } from 'types/CommonTypes';
import { LinkDto } from 'services/data-contracts';

const ListOfLinksContainer = observer(
  ({
    draggable,
    onLinkSelect,
    onLinkDelete,
    onLinkEdit,
  }: {
    draggable?: boolean;
    onLinkSelect?: (link: LinkModel) => void;
    onLinkDelete?: (linkId: LinkModel) => void;
    onLinkEdit?: (link: LinkModel) => void;
  }) => {
    const { t } = useTranslation();
    const { myLinkStore, myChannelStore, uiStores } = useStore();
    const { userHomeStore } = uiStores;
    const { currentChannel } = myChannelStore;
    const [links, setLinks] = useState<LinkModel[]>([]);
    const grid = 1;

    const reorder = (startIndex: number, endIndex: number): Array<LinkModel> => {
      const result = Array.from(links);
      const [dragItem] = result.splice(startIndex, 1);

      /** 서버 반영전, UI 변경 목적 */
      result.splice(endIndex, 0, dragItem);

      /** 옮긴 링크의 seq를 옮긴 index + 1 값으로 수정해준다 */
      dragItem.seq = endIndex + 1;

      /** 링크 순번 수정 */
      if (dragItem.id) {
        myLinkStore.reOrderLinkItem(
          currentChannel?.id as number,
          userHomeStore.currentBoard?.id as number,
          dragItem?.id,
          dragItem,
        );
      }

      setTimeout(() => {
        myLinkStore.getItems(
          currentChannel?.id as number,
          userHomeStore.currentBoard?.id as number,
        );
      }, 300);

      return result;
    };

    const onDragEnd = (result: DropResult): void => {
      if (!result.destination) {
        return;
      }
      const dragLinks: Array<LinkModel> = reorder(result.source.index, result.destination.index);
      setLinks(dragLinks);
    };

    /** (라이브러리) Droppable 스타일링 */
    const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
      padding: grid,
      width: '100%',
    });

    /** (라이브러리) Draggable 스타일링 */
    const getItemStyle = (
      isDragging: boolean,
      draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
    ): React.CSSProperties => ({
      ...draggableStyle,
      // userSelect: 'none',
      // padding: grid * 2,
      // margin: `0 0 ${grid}px 0`,
      // left: 'auto !important',
      // top: 'auto !important',
    });

    const LinkComponetWrapper = (link: LinkModel) => {
      return (
        <LinkComponent
          item={link}
          // eslint-disable-next-line react/destructuring-assignment
          child={link.title}
          on={false}
          onSelect={() => {
            if (typeof onLinkSelect === 'function') onLinkSelect(link);
          }}
          onDelete={() => {
            // eslint-disable-next-line react/destructuring-assignment
            if (typeof onLinkDelete === 'function') onLinkDelete(link);
          }}
          onEdit={(link: LinkModel) => {
            if (typeof onLinkEdit === 'function') onLinkEdit(link);
          }}
          onShowChange={(isShow: boolean) => {
            handleLinkShowChanged(link, isShow);
          }}
        />
      );
    };

    /**
     * @todo API붙일것
     * @param link
     * @param isShow
     */
    const handleLinkShowChanged = async (link: LinkDto, isShow: boolean) => {
      if (typeof link?.id !== 'undefined') {
        const copied = { ...link };
        copied.isActive = isShow;
        const result = (await myLinkStore.updateItem(
          myChannelStore.currentChannel?.id as number,
          userHomeStore.currentBoard?.id as number,
          copied,
          true,
        )) as any;
        if (result) {
          myLinkStore.updateLinkByItem(result, 'update');
        }
      }
    };

    useEffect(() => {
      // setLinks(myLinkStore.links);
    }, [myLinkStore.links]);

    return (
      <>
        {draggable === true ? (
          <div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot): JSX.Element => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {links?.map((link: LinkModel, index: number) => (
                      <Draggable key={link?.id} draggableId={String(link?.id)} index={index}>
                        {(provided, snapshot): JSX.Element => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <LinkComponetWrapper {...link} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        ) : (
          <>
            {links?.map((link: LinkModel, index: number) => (
              <div key={index}>
                <LinkComponetWrapper {...link} />
              </div>
            ))}
          </>
        )}
        {/* {linkStore.links?.length === 0 && <EmptyContainer>{t('registerNotLink')}</EmptyContainer>} */}
      </>
    );
  },
);

export default ListOfLinksContainer;
