import React from 'react';
import { CreateChannel } from 'pages/adminPages';

const NewLinkChannelPage = () => {
  return (
    <>
      <CreateChannel />
    </>
  );
};

export default NewLinkChannelPage;
