import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormGroup } from '@mui/material';
import styled from 'styled-components';
import NumberForm from 'components/items/forms/NumberForm';

export default function DropdownSelection({
  items,
  savedOptions,
}: {
  items?: string[];
  savedOptions?: any[];
}) {
  const [value, setValue] = React.useState<string>('');

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 10 }}>
      {savedOptions?.map((option) => (
        <Container>
          <FormControl style={{}}>
            <Select
              // labelId="select-label"
              id="simple-select"
              value={value}
              // label="Facebook"
              onChange={handleChange}
              sx={{
                minWidth: 110,
                height: 26,
                fontSize: 'small',
                borderRadius: 30,
              }}
            >
              {items?.map((item) => (
                <MenuItem sx={{ fontSize: 'small' }} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <FormGroup style={{ flexDirection: 'row', alignItems: 'center' }}>
              <CalculationText style={{ marginRight: 10 }}>이전</CalculationText>
              <NumberForm validationType="number" style={{ width: 100 }} onChangeValue={() => {}} />
            </FormGroup>
            <FormGroup style={{ flexDirection: 'row', alignItems: 'center' }}>
              <CalculationText style={{ marginRight: 10 }}>이후</CalculationText>
              <NumberForm validationType="number" style={{ width: 100 }} onChangeValue={() => {}} />
            </FormGroup>
          </div>
        </Container>
      ))}
    </div>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

const CalculationText = styled.div`
  color: '#666';
  font-size: 15px;
  align-self: center;
  /* margin-left: 130px; */
  @media ${(props) => props.theme.media.mobile} {
    font-size: 13px;
    /* margin-left: 30px; */
    align-self: center;
  }
`;
