import React, { CSSProperties, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { PostDto } from 'services/data-contracts';
import { observer } from 'mobx-react';
import { ReactComponent as LinkIcon } from 'resources/images/commons/link.svg';
import { ReactComponent as Multiimages } from 'resources/images/commons/multiimages.svg';
import { isMobile } from 'react-device-detect';
import CardListItemThumbnail1 from './CardListItemThumbnail1';
import CardListItemThumbnail2 from './CardListItemThumbnail2';
import CardListItemThumbnail3 from './CardListItemThumbnail3';

const MyShopCardListItem = observer(
  ({
    item,
    useThumbnailInfo,
    layout,
    style,
    thumbnailStyle,
    onClick,
  }: {
    item: PostDto;
    useThumbnailInfo?: boolean;
    layout: number;
    style?: CSSProperties;
    thumbnailStyle?: CSSProperties;
    onClick: (item: PostDto) => void;
  }) => {
    const [isDirectLink, setIsDirectLink] = useState<boolean>(false);

    const getThumbnailBox = () => {
      switch (layout) {
        case 1:
          return (
            <CardListItemThumbnail1 item={item} style={style} thumbnailStyle={thumbnailStyle} />
          );
        case 2:
          return (
            <CardListItemThumbnail2 item={item} style={style} thumbnailStyle={thumbnailStyle} />
          );
        default:
          return (
            <CardListItemThumbnail3 item={item} style={style} thumbnailStyle={thumbnailStyle} />
          );
      }
    };

    const getIcon = () => {
      if (isDirectLink && item?.linkUrl) {
        return (
          <IconBackgroundStyle layout={layout}>
            <LinkIcon
              style={{
                stroke: '#96E06E',
                width: layout !== 1 ? '1rem' : '1.5rem',
                height: layout !== 1 ? '1rem' : '1.5rem',
              }}
            />
          </IconBackgroundStyle>
        );
      } else if (!isDirectLink && item?.items && item?.items?.length > 1) {
        return (
          <IconBackgroundStyle layout={layout}>
            <Multiimages
              style={{
                fill: '#96E06E',
                width: layout !== 1 ? '1rem' : '1.5rem',
                height: layout !== 1 ? '1rem' : '1.5rem',
              }}
            />
          </IconBackgroundStyle>
        );
      }
    };

    useEffect(() => {
      if (item?.metadata) {
        const parsedMetadata = JSON.parse(item?.metadata ?? '{}');
        setIsDirectLink(parsedMetadata.isDirectLink);
      }
    }, [item?.metadata]);

    return (
      <GridItemInnerStyle
        style={{ borderRadius: style?.borderRadius, width: style?.width }}
        url={item?.items?.[0].file?.publicUrl}
        onClick={() => onClick(item)}
      >
        <InnerIconStyle layout={layout}>{getIcon()}</InnerIconStyle>
        {useThumbnailInfo && <>{getThumbnailBox()}</>}
      </GridItemInnerStyle>
    );
  },
);

export default MyShopCardListItem;

const InnerIconStyle = styled.div<{ layout?: number; index?: number }>`
  position: absolute;

  ${(props) => {
    if (props.layout === 1) {
      return {
        top: 16,
        right: 16,
      };
    } else if (props.layout === 2) {
      return {
        top: 12,
        right: 12,
      };
    } else if (props.layout === 3) {
      return {
        top: 10,
        right: 10,
      };
    }
  }}
`;

const IconBackgroundStyle = styled.div<{ layout?: number }>`
  background: #173300;
  justify-content: center;
  /* padding: 3px; */
  align-items: center;
  border-radius: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  ${(props) =>
    props.layout !== 1 &&
    css`
      height: 1.8rem;
      width: 1.8rem;
    `}/* 
  @media ${(props) => props.theme.media.mobile} {
    ${(props) =>
    props.layout !== 1 &&
    css`
      height: 24px;
      width: 24px;
    `}
  } */
`;

const GridItemInnerStyle = styled.div<{
  on?: boolean;
  url?: string;
}>`
  cursor: pointer;
  position: relative;
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
  aspect-ratio: 1;
`;
