import { useStore } from 'stores/StoreHelper';
import FileStore from 'stores/FileStore';
import MyChannelStore from 'stores/MyChannelStore';
import { ChannelProposalConfigDto, ProposalDto } from 'services/data-contracts';
import ChannelStore from 'stores/ChannelStore';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import ProposalConfigServiceStore from 'stores/ProposalConfigServiceStore';
import { CustomFile } from 'types/BlockExtendsTypes';
import { useRootContext } from 'libs/hooks/useRootContext';
/**
 * @description 각 Block의 등록/수정/삭제를 수행함.
 * @returns
 */

const ProposalController = () => {
  const fileStore = new FileStore();
  const myChannelStore = new MyChannelStore();
  const channelStore = new ChannelStore();
  const proposalConfigStore = new ProposalConfigServiceStore();
  const { xhrStore } = useStore();
  const { currentChannel } = useChannelContext();
  const { myChannel } = useRootContext();

  /**
   * @description 채널에 대한 제안서를 생성함.
   * @param standAlonModel
   * @returns
   */
  const createProposal = async (serviceModel: ProposalDto, channelName: string) => {
    xhrStore.setState('pending');
    try {
      if (typeof serviceModel === 'undefined') {
        return;
      }

      if ((serviceModel?.attechFile as CustomFile)?.blob) {
        const file = (await fileStore.uploadImage(
          (serviceModel?.attechFile as CustomFile)?.blob,
          'Proposal',
        )) as any;
        serviceModel.attechFile = { id: file.id };
      }

      if (serviceModel?.attechFile) {
        const file = (await fileStore.uploadImage(serviceModel?.attechFile, 'Proposal')) as any;
        serviceModel.attechFile = { id: file.id };
      }

      const result = (await channelStore.createProposal(
        channelName as string,
        serviceModel,
        myChannel?.id as number,
      )) as unknown as ProposalDto;
      xhrStore.setState('done');
      return result;
    } catch (e) {
      console.log(e);
      xhrStore.setState('error');
    }
  };

  const deleteProposalByReceipient = async (serviceModel: ProposalDto) => {
    xhrStore.setState('pending');
    const result = await myChannelStore.deleteProposalByReceipient(
      myChannel?.id as number,
      serviceModel?.id as number,
    );
    xhrStore.setState('done');
    return result;
  };

  const deleteProposalByProposer = async (serviceModel: ProposalDto) => {
    xhrStore.setState('pending');
    const result = await myChannelStore.deleteProposalByProposer(
      myChannel?.id as number,
      serviceModel?.id as number,
    );
    xhrStore.setState('done');
    return result;
  };

  const getReceivedProposals = async () => {
    xhrStore.setState('pending');
    const result = await myChannelStore.getReceivedProposals(myChannel?.id as number);
    xhrStore.setState('done');
    return result;
  };

  const getSentProposals = async () => {
    xhrStore.setState('pending');
    const result = await myChannelStore.getSentProposals(myChannel?.id as number);
    xhrStore.setState('done');
    return result;
  };

  const getReceivedProposal = async (proposalId: number) => {
    xhrStore.setState('pending');
    const result = await myChannelStore.getReceivedProposal(myChannel?.id as number, proposalId);
    xhrStore.setState('done');
    return result;
  };

  const getSentProposal = async (proposalId: number) => {
    xhrStore.setState('pending');
    const result = await myChannelStore.getReceivedProposal(myChannel?.id as number, proposalId);
    xhrStore.setState('done');
    return result;
  };

  const getChannelProposalConfig = async () => {
    xhrStore.setState('pending');
    const result = await proposalConfigStore.getChannelProposalConfig(myChannel?.id as number);
    xhrStore.setState('done');
    return result;
  };

  const createChannelProposalConfig = async (data: ChannelProposalConfigDto) => {
    xhrStore.setState('pending');
    const result = await proposalConfigStore.createChannelProposalConfig(
      myChannel?.id as number,
      data,
    );
    xhrStore.setState('done');
    return result;
  };

  const getChannelProposalType = async () => {
    xhrStore.setState('pending');
    const result = await channelStore.getChannelProposalType(currentChannel?.channelName as string);
    xhrStore.setState('done');
    return result;
  };

  return {
    createProposal,
    deleteProposalByProposer,
    deleteProposalByReceipient,
    getReceivedProposals,
    getSentProposals,
    getReceivedProposal,
    getSentProposal,
    getChannelProposalConfig,
    createChannelProposalConfig,
    getChannelProposalType,
  };
};

export default ProposalController;
