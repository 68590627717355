import React, { useEffect, useState } from 'react';
import { CustomFile } from 'types/CommonTypes';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const SingleImage = ({
  image,
  onClick,
  onDelete,
}: {
  image?: CustomFile;
  onClick: (e: any) => void;
  onDelete: (e: any) => void;
}) => {
  const [file, setFile] = useState<CustomFile>();

  useEffect(() => {
    if (image) {
      setFile(image);
    }
    return () => {
      setFile(undefined);
    };
  }, [image]);

  return (
    <DefaultIconStyle
      onClick={(e: any) => {
        if (!file) onClick(e);
      }}
    >
      {file ? (
        <>
          <div style={{ position: 'relative' }}>
            <ImageStyle
              onClick={(e) => {
                e.preventDefault();
                onClick(e);
              }}
            >
              <img
                src={file.publicUrl}
                alt=""
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </ImageStyle>
            <IconStyle
              style={{ right: -10, bottom: -10 }}
              onClick={(e: any) => {
                e.preventDefault();
                onClick(e);
              }}
            >
              <AddIcon style={{ color: '#fff', width: 20, height: 20 }} />
            </IconStyle>
            <IconStyle
              style={{ top: -10, right: -10, background: 'red', width: 30, height: 30 }}
              onClick={(e: any) => {
                e.preventDefault();
                onDelete(e);
                setFile(undefined);
              }}
            >
              <DeleteOutlineIcon style={{ color: '#fff', width: 20, height: 20 }} />
            </IconStyle>
          </div>
        </>
      ) : (
        <CameraIconStyle
        // onClick={(e) => {
        //   e.preventDefault();
        //   onClick(e);
        // }}
        >
          <CameraAltIcon sx={{ width: 30, height: 30 }} />
        </CameraIconStyle>
      )}
    </DefaultIconStyle>
  );
};

const DefaultIconStyle = styled.div`
  width: 60px;
  height: 60px;
  background: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  :hover {
    background: #2ebe59;
  }
`;

const IconStyle = styled.div`
  width: 30px;
  height: 30px;
  background: #05c755;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  /* right:0px; */
  border-radius: 40px;
`;

const ImageStyle = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
  object-fit: cover;
  width: 60px;
  height: 60px;
  border-radius: 8px;
`;

const CameraIconStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SingleImage;
