/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PartnerInvitationCreationDto, PartnerInvitationDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class PartnerInvitationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 파트너 초대 목록
   *
   * @tags PartnerInvitationService
   * @name GetPartnerInvitations
   * @summary 파트너 초대 목록
   * @request GET:/v2/me/channels/{channelId}/invitations
   * @secure
   */
  getPartnerInvitations = (
    channelId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<PartnerInvitationDto[], any>({
      path: `/v2/me/channels/${channelId}/invitations`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 파트너 초대 생성
   *
   * @tags PartnerInvitationService
   * @name CreatePartnerInvitation
   * @summary 파트너 초대 생성
   * @request POST:/v2/me/channels/{channelId}/invitations
   * @secure
   */
  createPartnerInvitation = (
    channelId: number,
    data: PartnerInvitationCreationDto,
    params: RequestParams = {},
  ) =>
    this.http.request<PartnerInvitationDto, any>({
      path: `/v2/me/channels/${channelId}/invitations`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 파트너 초대 정보
   *
   * @tags PartnerInvitationService
   * @name GetPartnerInvitation
   * @summary 파트너 초대 정보
   * @request GET:/v2/me/channels/{channelId}/invitations/{invitationId}
   * @secure
   */
  getPartnerInvitation = (channelId: number, invitationId: number, params: RequestParams = {}) =>
    this.http.request<PartnerInvitationDto, any>({
      path: `/v2/me/channels/${channelId}/invitations/${invitationId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 파트너 초대 삭제
   *
   * @tags PartnerInvitationService
   * @name DeletePartnerInvitation
   * @summary 파트너 초대 삭제
   * @request DELETE:/v2/me/channels/{channelId}/invitations/{invitationId}
   * @secure
   */
  deletePartnerInvitation = (channelId: number, invitationId: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/invitations/${invitationId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
}
