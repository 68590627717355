import styled from 'styled-components';

const MainTitleText = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const SubTitleText = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const DateText = styled.div`
  font-size: 14px;
  opacity: 0.5;
`;
export { MainTitleText, SubTitleText, DateText };
