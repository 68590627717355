import React, { CSSProperties } from 'react';
import styled from 'styled-components';

const StickerTypeB = ({ text, style }: { text: string; style: CSSProperties }) => {
  return (
    <ContainerStyle style={style} className="sticker-item">
      {typeof text === 'undefined' || text === 'StickerTypeB' ? 'sample' : text}
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  display: flex;
  height: 25px;
  border-radius: 5px;
  color: #05c755;
  border: 1px solid #05c755;
  min-width: 60px;
  padding: 0 10px 0 10px;
  font-size: 11px;
  align-items: center;
  justify-content: center;
`;

export default StickerTypeB;
