import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { OhmossButton } from 'resources/controls/OhmossButton';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import { useStore } from 'stores/StoreHelper';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AgencyController from 'controllers/BrandController';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import { MemberDto } from 'services/data-contracts';

import { ContextContainer, ContextItem } from 'resources/styles/common/ContextItem';
import MemberCreateContainer from '../containers/MemberCreateContainer';
import ResetPassword from '../containers/ResetPassword';
import { useRootContext } from 'libs/hooks/useRootContext';

const MemberContext = ({
  member,
  onReload,
  onEdit,
}: {
  member: MemberDto;
  onReload: () => void;
  onEdit: () => void;
}) => {
  const { uiStore } = useStore();
  const { getMembers, deleteManager } = AgencyController();
  const { myChannel } = useRootContext();
  const [members, setMembers] = useState<MemberDto[]>([]);

  const handleClickDelete = () => {
    uiStore.confirm.show({
      message:
        '매니저를 삭제하시겠습니까? 이 매니저 계정으로 관리하는 모든 공구에서도 위임 해제됩니다.',
      onConfirmed: async () => {
        const result = (await deleteManager(member?.id as number)) as unknown as string;
        if (result === '') {
          onReload();
        }
      },
    });
  };

  const handleOnUpdate = () => {};

  const handleClickResetPassword = () => {
    uiStore.bottomSheet.show({
      title: '패스워드 재설정',
      style: {
        minHeight: '85%',
      },
      children: <ResetPassword onUpdate={handleOnUpdate} />,
    });
  };

  const getMemberList = async () => {
    const result = (await getMembers(myChannel?.id as number)) as unknown as MemberDto[];
    setMembers(result);
  };

  useEffect(() => {
    if (myChannel) {
      getMemberList();
    }
  }, [myChannel]);
  return (
    <ContextContainer>
      <ContextItem
        onClick={() => {
          uiStore.bottomSheet.close();
          onEdit();
        }}
      >
        설정
      </ContextItem>
      <ContextItem
        onClick={() => {
          uiStore.bottomSheet.close();
          handleClickDelete();
        }}
      >
        삭제
      </ContextItem>
      <ContextItem
        onClick={() => {
          handleClickResetPassword();
        }}
      >
        패스워드 리셋
      </ContextItem>
      <ContextItem style={{ padding: '20px 0' }}>
        <OhmossButton
          onClick={() => {
            uiStore.bottomSheet.close();
          }}
        >
          닫기
        </OhmossButton>
      </ContextItem>
    </ContextContainer>
  );
};

export default MemberContext;
