import React, { ReactNode, useRef, useEffect, CSSProperties } from 'react';
import styled from 'styled-components';
import StyledRefBox from '../layouts/StyledRefBox';

const StickyHeader = ({
  top,
  style,
  children,
}: {
  top?: number;
  style?: CSSProperties;
  children: ReactNode;
}) => {
  const headerRef = useRef<any>(null);
  const currentScroll = useRef<number>(0);
  const upStartingPosition = useRef<number>(0);

  useEffect(() => {
    const handleScroll = () => {
      if (window.isWebView) {
        return;
      }
      if (currentScroll.current > window.scrollY) {
        if (upStartingPosition.current === 0) {
          upStartingPosition.current = window.scrollY;
        }
      } else {
        upStartingPosition.current = 0;
      }

      if (upStartingPosition.current > 20) {
        headerRef.current.style.top = `${top || 60}px`;
      } else {
        headerRef.current.style.top = `-${top || 60}px`;
      }
      currentScroll.current = window.scrollY;
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <HeaderStyle ref={headerRef} top={top} style={style}>
      <>{children}</>
    </HeaderStyle>
  );
};

const HeaderStyle = styled(StyledRefBox) <{ visible?: boolean; top?: number }>`
  /* top: ${(props) => (props.top ? `${props.top}px !important` : '20px')}; */
  top: 60px;
  position: sticky;
  width: 100%;
  top: 0px;
  background: #fff;
  transition: top 0.3s ease-in-out;
  z-index: 1;
`;

export default StickyHeader;
