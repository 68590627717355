import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { t } from 'i18next';

const NoContent = ({ title, style }: { title: string; style?: CSSProperties }) => {
  return (
    <NoFollowerStyle style={style}>
      <img src="/images/cstown/noContent.png" style={{ width: 100 }} alt="" />
      <div style={{ marginTop: 30, fontSize: 18, fontWeight: 400 }}>
        {title ? `${title} 없습니다.` : <>{t('A256')}</>}
      </div>
    </NoFollowerStyle>
  );
};

const NoFollowerStyle = styled.div`
  display: flex;
  align-items: center;
  /* padding-top: 200px; */
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @media ${(props) => props.theme.media.desk} {
    height: 100%;
  }
  /* @media ${(props) => props.theme.media.mobile} {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  } */
`;

export default NoContent;
