/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { MembershipPlanDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class MembershipPlan<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 구독상품 목록
   *
   * @tags membership-plan
   * @name GetMembershipPlans
   * @summary 구독상품 목록
   * @request GET:/v2/membership-plan
   * @secure
   */
  getMembershipPlans = (
    query: {
      channelType: 'SELLER' | 'BRAND' | 'MEMBER';
    },
    params: RequestParams = {},
  ) =>
    this.http.request<MembershipPlanDto[], any>({
      path: `/v2/membership-plan`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 구독상품 정보
   *
   * @tags membership-plan
   * @name GetMembershipPlan
   * @summary 구독상품 정보
   * @request GET:/v2/membership-plan/{id}
   * @secure
   */
  getMembershipPlan = (id: number, params: RequestParams = {}) =>
    this.http.request<MembershipPlanDto, any>({
      path: `/v2/membership-plan/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
