/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState, useRef } from 'react';

import { ISeparatorBlock } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';
import { Lines } from 'resources/images/separators';
import { LineThumbnails } from 'resources/images/separators/thumbnails';
import styled from 'styled-components';

const SeparatorTemplate = ({ item }: { item?: ISeparatorBlock }) => {
  const Line = () => {
    if (item) {
      if (item?.separatorType === 'line1') {
        return <SolidLineStyle item={item} />;
      }
      if (item?.separatorType === 'line2') {
        return <DottedLineStyle item={item} />;
      }
      if (item?.separatorType === 'line3') {
        return <DashedLineStyle item={item} />;
      }
      if (item?.separatorType === 'line4') {
        return <DoubleLineStyle item={item} />;
      }
    } else {
      return <></>;
    }
  };

  return (
    <div>
      <Row
        style={{
          minHeight: 10,
          alignItems: 'center',
          justifyContent: 'center',
          // flexGrow: 1,
        }}
      >
        {Line()}
      </Row>
    </div>
  );
};

const SolidLineStyle = styled.div<{ item: ISeparatorBlock }>`
  height: 1.5px;
  background-color: ${(props) => (props.item.strokeColor ? `${props.item.strokeColor}` : ' #000')};
  width: ${(props) =>
    // eslint-disable-next-line no-unsafe-optional-chaining
    props.item?.widthRate ? `${props.item?.widthRate}%` : `100%`};
`;
const DottedLineStyle = styled.div<{ item: ISeparatorBlock }>`
  width: ${(props) =>
    // eslint-disable-next-line no-unsafe-optional-chaining
    props.item?.widthRate ? `${props.item?.widthRate}%` : `100%`};
  height: 0;
  border-top: ${(props) =>
    props.item.strokeColor ? `2px dotted ${props.item.strokeColor}` : '1px dotted #000'};
`;

const DashedLineStyle = styled.div<{ item: ISeparatorBlock }>`
  width: ${(props) =>
    // eslint-disable-next-line no-unsafe-optional-chaining
    props.item?.widthRate ? `${props.item?.widthRate}%` : `100%`};
  height: 0;
  border-top: ${(props) =>
    props.item.strokeColor ? `2px dashed ${props.item.strokeColor}` : '1px dotted #000'};
`;
const DoubleLineStyle = styled.div<{ item: ISeparatorBlock }>`
  width: 100%;
  height: 0;
  border-top: ${(props) =>
    props.item.strokeColor ? `5px double ${props.item.strokeColor}` : '5px double #000'};
`;

export default SeparatorTemplate;
