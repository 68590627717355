import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import { ChannelBoardDto } from 'services/data-contracts';
import { isMobile } from 'react-device-detect';

import EmptyPage from 'pages/EmptyPage';
import CategoryController from 'controllers/CategoryController';
import styled from 'styled-components';
import MyShopFormContainer from '../containers/MyShopFormContainer';
import MyShopItem from '../components/common/MyShopItem';
import { useRootContext } from 'libs/hooks/useRootContext';
import ManagementInnerLayout from '../components/common/ManagementInnerLayout';
import { ListBoxItemTemplate } from 'features/csTown/commonComponents/styles';
import NoContent from 'components/commons/NoContent';
import { BottomBoxStyle } from '../components/common/BottomButonStyle';

const MyShopPage = observer(() => {
  const { myChannel } = useRootContext();
  const { getMyCategoryBoards } = CategoryController();

  const { uiStore } = useStore();

  const [categories, setCategories] = useState<ChannelBoardDto[]>();

  const handleClickNewCategory = (item?: ChannelBoardDto) => {
    uiStore.universalModal.show({
      bottomSheetOptions: { topPosition: isMobile ? 40 : undefined },
      title: item ? '마이샵 설정' : '새 마이샵',
      children: <MyShopFormContainer item={item} />,
      onConfirmed: () => {
        fetchData();
      },
    });
  };

  const fetchData = async () => {
    const response = await getMyCategoryBoards();
    const categoriesTemp = response.filter((x) => x.boardType === 'SHOP');
    if (categoriesTemp.length > 0) {
      setCategories(categoriesTemp);
    }
  };

  useEffect(() => {
    if (myChannel) {
      fetchData();
    }
  }, [myChannel]);

  return (
    <>
      <ManagementInnerLayout>
        {categories?.length === 0 && <NoContent title="등록된 마이샵이" />}
        {categories?.map((category: ChannelBoardDto, index: number) => (
          <ListBoxItemTemplate style={{ padding: 0, margin: '10px' }} key={category?.id}>
            <MyShopItem
              fetchData={() => {
                fetchData();
              }}
              item={category}
              editMode
              onClickMore={() => {}}
            />
          </ListBoxItemTemplate>
        ))}
      </ManagementInnerLayout>
      <BottomBoxStyle style={{ padding: 20 }}>
        <OhmossButton
          type="primaryPc"
          onClick={() => {
            handleClickNewCategory();
          }}
        >
          마이샵 추가
        </OhmossButton>
      </BottomBoxStyle>
    </>
  );
});

export default MyShopPage;
