import React, { useState, useEffect } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';

import useSetDefaultValue from 'features/management/hooks/useDefaultValue';
import FormControlLabel from '@mui/material/FormControlLabel';
import MUIRadio from 'components/commons/buttons/MUIRadio';

type RadioItem = {
  label: string;
  value: string | undefined;
  checked?: boolean;
};

type CommonProps = {
  title?: string;
  defaultChecked?: string;
  initialValue?: string;
  items: RadioItem[];
  onChange?: (value: string) => void;
};
const CommonRadioGroup = ({
  title,
  items,
  defaultChecked,
  initialValue,
  onChange,
}: CommonProps) => {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return (
    <>
      <ItemSectionStyle>
        {title && <ItemSectionTitle>{title}</ItemSectionTitle>}
        <ItemContent>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={value}
              name="radio-buttons-group"
              onChange={(e: any, value: string) => {
                if (value === null) {
                  return;
                }
                setValue(value);
                if (onChange) onChange(value);
              }}
            >
              {items.map((item: RadioItem) => (
                <FormControlLabel
                  checked={item.value === value}
                  value={item.value}
                  control={<MUIRadio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </ItemContent>
      </ItemSectionStyle>
    </>
  );
};

export default CommonRadioGroup;
