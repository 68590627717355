import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

interface Props {
  type?: 'monthly' | 'bi-annual' | 'annual';
  onChange?: (type: 'monthly' | 'bi-annual' | 'annual') => void;
}
const MembershipDuration: React.FC<Props> = ({ type, onChange }) => {
  const [membershipType, setMembershipType] = useState<typeof type>('monthly');

  useEffect(() => {
    setMembershipType('monthly');
  }, []);

  return (
    <PaymentContainer>
      <PaymentDuration
        onClick={() => {
          setMembershipType('monthly');
          onChange?.('monthly');
        }}
        selected={membershipType === 'monthly'}
      >
        매월 결제<Discount selected={membershipType === 'monthly'}>매월 자동결제</Discount>
      </PaymentDuration>
      <PaymentDuration
        onClick={() => {
          setMembershipType('bi-annual');
          onChange?.('bi-annual');
        }}
        selected={membershipType === 'bi-annual'}
      >
        6개월 결제<Discount selected={membershipType === 'bi-annual'}>10% 할인</Discount>
      </PaymentDuration>
      <PaymentDuration
        onClick={() => {
          setMembershipType('annual');
          onChange?.('annual');
        }}
        selected={membershipType === 'annual'}
      >
        1년 결제<Discount selected={membershipType === 'annual'}>20% 할인</Discount>
      </PaymentDuration>
    </PaymentContainer>
  );
};

const PaymentContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 3.5rem;
  border-radius: 3.5rem;
  border: 0.5px solid #cbcbcb;
`;

const PaymentDuration = styled.div<{ selected?: boolean }>`
  color: ${(props) => (props.selected ? '#9DE4BC' : '#131313')};
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 0.875rem;
  letter-spacing: 0.00938rem;
  background-color: ${(props) => (props.selected ? '#173300' : 'none')};
  border-radius: 3.5rem;
  padding: 10px;
  width: 100%;

  cursor: pointer;
`;

const Discount = styled.div<{ selected?: boolean }>`
  color: ${(props) => (props.selected ? '#fff' : '#131313')};
  font-size: 0.6875rem;
  font-weight: 500;
  padding-top: 0.25rem;
`;

export default MembershipDuration;
