import styled from 'styled-components';

const LinkEditorStyle = styled.div`
  height: 50px;
  cursor: pointer;
  display: flex;
  margin-top: 10px;
  background: rebeccapurple;
  align-items: center;
  justify-content: center;
  margin: 10px 20px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 10px;
`;

export default LinkEditorStyle;
