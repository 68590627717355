import styled from 'styled-components';

/**
 * @description
 */
const TermsBtnLabelStyle = styled.label`
  word-break: keep-all;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid rgb(212, 212, 212);
  text-align: center;
  padding:20px 0;
  @media ${(props) => props.theme.media.desk} {
    width: 80%;
    margin: 0 10px;
  }
  @media ${(props) => props.theme.media.mobile} {
    width: 106px;
    /* font-size: 12px; */
  }
`;

export default TermsBtnLabelStyle;
