const colors = [
  {
    color: '#F97053',
  },
  { color: '#EFC013' },
  { color: '#4287F5' },
  { color: '#FFA07A' },
  { color: '#1DCBBB' },
  { color: '#A0CD74' },
];

export default colors;
