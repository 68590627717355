import React, { CSSProperties } from 'react';
import { Row } from 'components/commons/layouts';
import { ILinkButton } from 'types/BlockExtendsTypes';
import { ContentWrapper } from '../styles/ContentWrapperStyle';
import { BlocktitleBarStyle } from '../../common/BlockTitleBar';
import { getFlexPosition } from 'libs/helper/utils';

import styled from 'styled-components';
import useNavigation from 'libs/hooks/useNavigate';
import CommonThumbnail from '../../commonControl/CommonThumbnail';
import StickerBox from 'components/commons/stickers/StickerBox';

const TitleStickerBox = ({
  style,
  textStyle,
  boxStyle,
  item,
  onClick,
}: {
  item: ILinkButton;
  textStyle?: CSSProperties;
  boxStyle?: CSSProperties;
  style?: CSSProperties;
  onClick?: () => void;
}) => {
  return (
    <BlocktitleBarStyle
      style={{
        textAlign: item?.contentAlign as 'left' | 'center' | 'right',
        paddingRight: boxStyle?.borderRadius,
        ...style,
        ...textStyle,
        flexDirection: 'column',
      }}
    >
      <div>
        {(item?.stickerPosition === 'top' || typeof item?.stickerPosition === 'undefined') && (
          <div style={{ paddingTop: 5 }}>
            <StickerBox stickers={item?.stickers} />
          </div>
        )}
      </div>
      <div style={{ padding: '5px 0' }}>{item?.title}</div>
      {item?.stickerPosition === 'bottom' && (
        <div style={{ marginBottom: 5 }}>
          <StickerBox
            stickers={item?.stickers}
            style={{ justifyContent: getFlexPosition(item?.contentAlign) }}
          />
        </div>
      )}
    </BlocktitleBarStyle>
  );
};

export default TitleStickerBox;
