import { usePageContext } from 'features/mossMain/context/usePageContext';
import { useStateContext } from 'libs/hooks/usePageState';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { MyDealState } from 'types/CommonTypes';
import TabComponent from './TabComponent';

type Menu = {
  id: number;
  title: string;
  Component?: any;
  useOngoing?: boolean;
};
const SubTabs = ({
  menus,
  selected,
  onClickTab,
}: {
  menus: Menu[];
  selected?: Menu;
  onClickTab?: (tab: Menu) => void;
}) => {
  const { stateModel } = useStateContext<MyDealState>();

  const [newMenu, setNewMenu] = React.useState<Menu[]>();
  useEffect(() => {
    if (stateModel?.selectedDeal) {
      if (stateModel?.selectedDeal?.status === 'UPCOMING') {
        setNewMenu(menus.filter((menu) => menu.useOngoing === false));
      } else {
        setNewMenu(menus);
      }
    }
  }, [stateModel?.selectedDeal]);
  return <TabComponent menus={newMenu} selected={selected} onClickTab={onClickTab} />;
};

export { SubTabs, Menu };
