import React, { createContext } from 'react';
import { AdCategoryDto, AdDto, PostTagDto } from 'services/data-contracts';

export type displayTypes = 'list' | 'square' | 'category';

interface propsTypes {
  selectedCategory?: AdCategoryDto;
  selectedTags?: PostTagDto[];
  displayType?: displayTypes;
  isLogin?: boolean;
  initPage: (query?: any) => void;
  setTags: (tags?: PostTagDto[]) => void;
  setLatestDeals: (deals?: AdDto[]) => void;
  setCategory: (category?: AdCategoryDto) => void;
  setDisplayType: (type?: displayTypes) => void;
  getAds: (query?: any) => void;
  updateFollowStatus: (isFollow?: boolean, channelName?: string | undefined) => void;
  setFavoriteAd: (isFavorite: boolean, channelId: number, adId: number) => void;
  ProviderInstance: any;
  deals?: AdDto[];

}

const PageContext = createContext<propsTypes | undefined>(undefined);

export { PageContext, propsTypes };
