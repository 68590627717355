import React, { useEffect, useState } from 'react';
import {
  ItemSectionStyle,
  ItemSectionTitle,
  ItemSectionDescription,
} from 'resources/controls/ItemSectionStyle';
import { MyStyle } from 'types/CommonTypes';
import { Row } from 'components/commons/layouts';
import { useTranslation } from 'react-i18next';
import { ToggleButton } from 'components/commons/ToggleButton';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { ProfileTypes } from 'features/management/context/providers/ContextTypes';

import SimpleColorPickerWithPopOver from 'components/items/SimpleColorPickerWithPopOver';
import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import styled from 'styled-components';

const LinkDesignItemBoxShadow = () => {
  const { initialModel, setState, stateModel, serviceModel } =
    useOhmossContext<ProfileTypes<MyStyle>>();
  const [useBackground, setUseBackground] = useState<boolean>(false);
  const [selectedShadow, setSelectedShadow] = useState<string | undefined>();

  const shadows = [
    {
      description: '강하게',
      values: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    },
    {
      description: '보통',
      values: '0px 0px 10px rgba(0, 0, 0, 0.15)',
    },
    {
      description: '부드럽게',
      values: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    },
  ];

  useEffect(() => {
    if (initialModel?.boxStyle?.boxShadow) {
      if (initialModel?.boxStyle?.boxShadow) {
        setSelectedShadow(initialModel?.boxStyle?.boxShadow);
      }
      setUseBackground(
        typeof initialModel?.boxStyle?.boxShadow === 'undefined' ||
          initialModel?.boxStyle?.boxShadow === 'unset'
          ? false
          : true,
      );
    }
  }, [initialModel]);

  return (
    <ItemSectionStyle style={{ padding: 20 }}>
      <ItemSectionTitle>
        <Row style={{ justifyContent: 'space-between' }}>
          <div>박스 그림자</div>
          <SimpleColorPickerWithPopOver
            disabled={!useBackground}
            useGradient={false}
            defaultValue={serviceModel?.boxStyle?.borderColor}
            onColorChanged={(color: string) => {
              setState('boxStyle', { ...serviceModel?.boxStyle, borderColor: color });
            }}
          />
        </Row>
      </ItemSectionTitle>
      <ItemSectionDescription>
        박스 그림자 사용으로 블록을 더 돋보이게 표시할 수 있습니다.
      </ItemSectionDescription>
      <div style={{ marginTop: 10 }}>
        <MenuItemCheckBox
          title="그림자 사용"
          initialValue={useBackground}
          onChangeValue={(value: boolean) => {
            setUseBackground(value);
            if (value) {
              setState('boxStyle', {
                ...serviceModel?.boxStyle,
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
              });
              setSelectedShadow('0px 0px 10px rgba(0, 0, 0, 0.05)');
            } else {
              setState('boxStyle', { ...serviceModel?.boxStyle, boxShadow: 'unset' });
              setSelectedShadow(undefined);
            }
          }}
        />
        <div style={{ marginTop: 10 }}>
          <Row style={{ justifyContent: 'space-between' }}>
            {shadows.map((shadow: any) => (
              <ToggleButtonStyle
                onClick={() => {
                  if (useBackground) {
                    setSelectedShadow(shadow.values);
                    setState('boxStyle', { ...serviceModel?.boxStyle, boxShadow: shadow.values });
                  }
                }}
                item={shadow}
                defaultChecked={selectedShadow === shadow.values}
                style={{
                  boxShadow: shadow.values,
                  margin: '0 10px',
                  borderRadius: serviceModel?.boxStyle?.borderRadius,
                  cursor: useBackground ? 'pointer' : 'not-allowed',
                }}
              >
                <>{shadow.description}</>
              </ToggleButtonStyle>
            ))}
          </Row>
        </div>
      </div>
    </ItemSectionStyle>
  );
};

const ToggleButtonStyle = styled(ToggleButton)`
  &:nth-child(1) {
    margin: 0 10px !important;
  }
`;

export default LinkDesignItemBoxShadow;
