import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { DealInquiryThreadDto } from 'services/data-contracts';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import CSTownController from 'controllers/CSTownController';
import ChatBox from '../components/ChatBox';
import { useRootContext } from 'libs/hooks/useRootContext';

const InquiryThreadsPage = () => {
  const { myChannel } = useRootContext();
  // const { getInquiryThreads } = new CSRootController(myChannel?.channelType);
  const { Brand } = CSTownController();
  const getThreads = async () => {
    const dealId = Number(dealid);
    const inquiryId = Number(inquiryid);
    let result;
    switch (myChannel?.channelType) {
      case 'BRAND':
        result = await Brand.CS.getInquiryThreads(dealId, inquiryId);
        break;

      default:
        break;
    }
    setThreads(result as unknown as DealInquiryThreadDto[]);
  };

  const { dealid, inquiryid } = useParams();

  const [threads, setThreads] = useState<DealInquiryThreadDto[]>();

  // const getThreads = async () => {
  //   const dealId = Number(dealid);
  //   const inquiryId = Number(inquiryid);

  //   const result = (await getInquiryThreads(
  //     myChannel?.id as number,
  //     dealId,
  //     inquiryId,
  //   )) as unknown as DealInquiryThreadDto[];
  //   setThreads(result);
  // };
  useEffect(() => {
    if (dealid && myChannel && inquiryid) {
      getThreads();
    }
  }, [dealid, inquiryid, myChannel]);

  return (
    <ManagementLayout>
      {threads && (
        <ChatBox threads={threads} channelType={myChannel?.channelType} onSend={() => {}}>
          <div style={{ background: '#fff' }} />
        </ChatBox>
      )}
    </ManagementLayout>
  );
};

export default InquiryThreadsPage;
