import React from 'react';
import styled from 'styled-components';
import RoundedImg, { RoundedImgProps } from './RoundedImg';
import { LandingPageWrapper } from './LandingPageWrapper';

interface LandingSlidesProps {
  title?: string;
  imgData: RoundedImgProps;
  description?: string;
  description2?: string;
  size: number;
}

const RecommendLandingSlides = ({
  title,
  imgData,
  description,
  description2,
  size,
}: LandingSlidesProps) => {
  return (
    <SWiperImageWrapper style={{ flexDirection: 'column', textAlign: 'center' }}>
      <BackgroundImage backgroundImage={imgData.image}>
        <DescriptionTextContainer>
          <div>
            <RoundedImg {...imgData} />
            <DescriptionText>{description}</DescriptionText>
            <DescriptionText>{description2}</DescriptionText>
          </div>
        </DescriptionTextContainer>
      </BackgroundImage>
    </SWiperImageWrapper>
  );
};

const SWiperImageWrapper = styled.div`
  margin: 20px;
  overflow: hidden;
  border-radius: 20px;
  @media ${(props) => props.theme.media.mobile} {
    margin: 15px 0;
  }
`;

const BackgroundImage = styled.div<{ backgroundImage: string }>`
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  /* z-index: -1; */
  /* scroll-snap-stop: always; */
  width: 100%;
  height: 100%;
  height: 600px;
  /* height: 100vh; */
  background: url(${(props) => props.backgroundImage}) center/cover no-repeat; /* Set background image properties */
`;

const DescriptionTextContainer = styled.div`
  position: absolute;
  background: rgba(23, 51, 0, 0.78);
  width: 550px;
  padding: 20px;
  /* height: 100%; */
  bottom: 20px;
  z-index: 0;
  border-radius: 20px;

  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
    bottom: 0px;
    border-radius: 0px;
    min-height: 400px;
    max-height: 500px;
  }
`;
const DescriptionText = styled.div`
  text-align: left;
  color: var(--white, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  &:nth-child(2) {
  }
  @media ${(props) => props.theme.media.mobile} {
    font-size: 15px;
  }
`;

export default RecommendLandingSlides;
