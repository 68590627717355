import React from 'react';

function useNavigation() {
  const navigateToLink = (linkUrl?: string, openNew: boolean = false) => {
    if (!linkUrl) {
      return; // linkUrl이 undefined이면 무시
    }

    const formattedUrl = formatUrl(linkUrl);

    if (isValidUrl(formattedUrl)) {
      if (openNew) {
        window.open(formattedUrl, '_blank');
      } else {
        window.location.href = formattedUrl;
      }
    } else {
      console.warn('제공된 URL이 유효하지 않습니다.');
    }
  };

  const formatUrl = (url: string): string => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return 'https://' + url;
    }
    return url;
  };

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  return navigateToLink;
}

export default useNavigation;
