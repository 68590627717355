import React, { createContext } from 'react';

interface propsTypes {
  report: (adId: number) => void;
  setLogin: (isLogin: boolean) => void;
  setDeal: (isDeal: boolean) => void;
  // setMyChannel: (channelInfo: ChannelDto) => void;
  // setCurrentChannel: (channelInfo: ChannelDto) => void;
  // getCategoryBoard: (categoryId: number) => void;
  login: () => void;
  logout: () => void;
  isLoggedIn: boolean;
  // board: ChannelBoardDto | null | undefined;
  isDeal: boolean;
  // myChannel?: ChannelDto | null;
  // currentChannel?: ChannelDto | null;
}

const GlobalContext = createContext<propsTypes | undefined>(undefined);

export { GlobalContext, propsTypes };
