import React, { useEffect, useState, useCallback } from 'react';
import { useStore } from 'stores/StoreHelper';
import TitleForm from 'components/items/forms/TitleForm';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import _, { debounce } from 'lodash';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { ChannelDto } from 'services/data-contracts';
import CRUDHelper from 'libs/helper/crudHelper';
import { useParams } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
import { Row } from 'components/commons/layouts';
import TextLabel from 'components/commons/labels/TextLabel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { validationHelper as VH } from 'controls/validationHelper';
import { toast } from 'react-toastify';
import useChannelSelector from 'libs/hooks/useChannelSelector';
import DetailPageHeader from 'components/commons/DetailPageHeader';
import ManagementLayout from 'features/management/components/common/ManagementLayout';
import RadioButtonsGroup from 'features/management/components/common/RadioButton';
import { ChannelDtoModel } from 'types/CommonTypes';
import MossyInputForm from 'components/items/forms/MossyInputForm';
import { useRootContext } from 'libs/hooks/useRootContext';
import Text from 'components/commons/Text';

interface ChannelModel extends ChannelDto {
  nickname?: string;
}

const CreateChannel = observer(() => {
  const { myChannelStore, channelStore } = useStore();
  const { type } = useParams();
  const { navigate } = useNavigation();
  const { setChannel } = useRootContext();
  const { saveChannel } = useChannelSelector();

  const [model, setModel] = useState<ChannelModel>();
  const [channelType, setChannelType] = useState<ChannelDto['channelType']>('SELLER');
  const [tempChannelName, setTempChannelName] = useState<string>();
  const [checkDupResult, setCheckDupResult] = useState<boolean>();
  const [mode, setMode] = useState<'new' | 'modify' | 'created'>('modify');
  const [isValid, setIsValid] = useState<boolean>();
  const [invalidPattern, setInvalidPattern] = useState<boolean>();
  const crudHelper = new CRUDHelper(myChannelStore, model);

  const handleChangeChannelName = useCallback(
    debounce(async (channelName) => {
      setTempChannelName(channelName);

      if (channelName.length < 2) {
        setIsValid(false);
        setInvalidPattern(false);
        setCheckDupResult(undefined);
        return;
      }
      const validString = VH.alphanumericWithSymbols(channelName);
      if (!validString) {
        setInvalidPattern(true);
        setCheckDupResult(undefined);
        return;
      } else {
        setInvalidPattern(false);
      }
      const result = (await channelStore.duplicateChannelName(channelName)) as any;
      setModel((prev) => {
        return { ...prev, channelName };
      });
      setCheckDupResult(result as boolean);
    }, 500),
    [],
  );

  const handleClickCreate = async () => {
    if (!model?.id) {
      const result = (await myChannelStore.createItem({
        name: model?.channelName,
        nickname: model?.nickname,
        channelType,
      })) as never as ChannelDto;
      if (result) {
        const channel = (await myChannelStore.getItem(
          result?.id as number,
        )) as never as ChannelDtoModel;
        setChannel(channel);
        saveChannel(channel);

        setMode('created');
        toast('채널이 정상적으로 생성되었습니다. 3초후 해당 채널로 이동합니다.');
        setTimeout(() => {
          navigate(`/${model?.channelName}`);
        }, 3000);
      }
    } else {
      const result = await crudHelper.update(model.id);
    }
  };

  const handleSelectChannelType = (channelType: string) => {
    if (channelType === '셀러(일반) 채널') {
      setModel((prev) => {
        return { ...prev, channelType: 'SELLER' };
      });
    } else {
      setModel((prev) => {
        return { ...prev, channelType: 'BRAND' };
      });
    }
  };

  useEffect(() => {
    if (type?.toLowerCase() === 'welcome') {
      setMode('modify');
    }
  }, [type]);

  useEffect(() => {
    const nick = VH.required(model?.nickname);
    setIsValid(nick && checkDupResult);
  }, [model, checkDupResult]);

  return (
    <>
      <DetailPageHeader title="새 채널 생성" />
      <ManagementLayout style={{ justifyContent: 'flex-start' }}>
        {mode === 'modify' ? (
          <>
            <Row style={{ flexDirection: 'column' }}>
              <Text type="H2" style={{ padding: 10 }}>
                원하시는 채널 타입을 선택 해 주세요.
              </Text>
              <div>
                <InfoOutlinedIcon />
                생성된 채널타입은 변경이 어렵습니다.
              </div>
            </Row>
            <Row style={{ justifyContent: 'space-evenly', marginTop: 30 }}>
              <ChannelTypeStyle
                selected={channelType === 'SELLER'}
                onClick={() => {
                  setChannelType('SELLER');
                }}
              >
                <div>
                  <StorefrontIcon style={{ width: 100, height: 100 }} />
                </div>
                <div>Seller</div>
                <div>상품판매를 전문으로 하는 채널</div>
              </ChannelTypeStyle>
              <ChannelTypeStyle
                selected={channelType === 'BRAND'}
                onClick={() => {
                  setChannelType('BRAND');
                }}
              >
                <div>
                  <CorporateFareIcon style={{ width: 100, height: 100 }} />
                </div>
                <div>Brand</div>
                <div>상품관리를 전문으로 하는 채널</div>
              </ChannelTypeStyle>
            </Row>
            <div style={{ textAlign: 'center', marginTop: 20 }}>
              채널명은 영문소문자, 숫자, 그리고 ! @ # $ - _ 만 사용 가능합니다.
            </div>

            <Row>
              <UrlStyle>ohmoss.com / </UrlStyle>
              <ChannelNameStyle>{tempChannelName ?? ''}</ChannelNameStyle>
            </Row>
            <MossyInputForm
              maxLength={20}
              model={model?.channelName}
              onChangeValue={(value: string) => handleChangeChannelName(value)}
              label="채널명"
              placeHolder="채널명을 입력해주세요."
              surfix={
                !invalidPattern &&
                checkDupResult && <img src="/images/checkbox-circle-line.svg" alt="" />
              }
            />
            <div style={{ height: 20 }}>
              {typeof checkDupResult === 'undefined' ||
              model?.channelName === '' ? undefined : checkDupResult ? (
                <GoodStyle>좋습니다! 사용가능한 채널명입니다. :)</GoodStyle>
              ) : (
                <BadStyle>아쉽게도 이미 사용중인 채널명입니다 :(</BadStyle>
              )}
              <>
                {invalidPattern && (
                  <WarningStyle>허용되지 않는 문자열이 포함되어 있습니다.</WarningStyle>
                )}
              </>
            </div>

            <TextLabel type="subject">
              닉네임&nbsp;
              <TextLabel style={{ display: 'inline' }} type="content">
                (검색에 사용됩니다.)
              </TextLabel>
            </TextLabel>

            <MossyInputForm
              maxLength={20}
              model={model?.nickname}
              onChangeValue={(value: string) => {
                setModel((prev) => {
                  return { ...prev, nickname: value };
                });
              }}
              label="닉네임"
              surfix={isValid && <img src="/images/checkbox-circle-line.svg" alt="" />}
            />
            <OhmossButton
              style={{ width: '100%', marginTop: 20 }}
              onClick={handleClickCreate}
              disabled={!isValid}
            >
              신규 채널생성
            </OhmossButton>
          </>
        ) : (
          <div>계정이 생성되었습니다. 3초후 채널 페이지로 이동하겠습니다.</div>
        )}
      </ManagementLayout>
    </>
  );
});

const UrlStyle = styled.div`
  font-size: 1rem;
  padding: 20px 5px 20px 10px;
  color: rgba(0, 0, 0, 0.87);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.03rem;
  font-weight: 600;
`;

const GoodStyle = styled.div`
  color: #96e06e;
  font-weight: 500;
  font-size: 0.85rem;
`;

const BadStyle = styled.div`
  color: #75003c;
  font-weight: 500;
  font-size: 0.85rem;
`;

const WarningStyle = styled.div`
  color: red;
  font-weight: 500;
  font-size: 0.85rem;
`;

const ChannelNameStyle = styled.div`
  color: #96e06e;
  font-size: 1.0625rem;
  font-weight: 600;
  letter-spacing: 0.01063rem;
`;

const ChannelTypeStyle = styled.div<{ selected?: boolean }>`
  cursor: pointer;
  background: #fff;
  display: flex;
  justify-content: center;
  width: 45%;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  border: ${(props) => (props.selected ? '2px solid #173300' : '1px solid #afafaf')};
  padding: 20px;
  > div:nth-child(2) {
    font-size: 20px;
    font-weight: 700;
  }
`;

export default CreateChannel;
