import React from 'react';
import { observer } from 'mobx-react';
import { MenuType } from 'types/CommonTypes';

import styled from 'styled-components';
import MobileMenus from 'features/adminFeatures/admin/components/MobileMenus';
import MobileGnbBottom from '../components/MobileGnbBottom';

const WebViewContainer = observer(
  ({
    bottomSize,
    children,
    onChangeMenu,
  }: {
    bottomSize: number;
    children: React.ReactNode;
    onChangeMenu: (menu: MenuType) => void;
  }) => {
    return (
      <>
        <MobileMenus type="webview" onMenuChange={onChangeMenu} />
        <ContainerStyle style={{ paddingBottom: bottomSize + 50 }}>
          <>{children}</>
        </ContainerStyle>
        <BottomStyle style={{ bottom: bottomSize }}>
          <MobileGnbBottom />
        </BottomStyle>
      </>
    );
  },
);

const ContainerStyle = styled.div`
  margin-top: 80px;
  @media ${(props) => props.theme.media.mobile} {
  }
`;

const BottomStyle = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;
export default WebViewContainer;
