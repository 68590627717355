import styled from 'styled-components';

const LinkHeaderStyle = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
  /* background: #0bbd58; */
  font-weight: 500;
  /* color: #fff; */
  text-transform: uppercase;
  border: 2px solid #0bbd58;
  border-radius: 10px;
  padding: 10px 20px;
  top: -8px;
  & > div {
    font-size: 14px;
    color: #797979;
  }
  /* font-family: 'Raleway !important'; */
`;

export default LinkHeaderStyle;
