import React, { useEffect, useState } from 'react';
import Drawer from 'components/commons/Drawer';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import useNavigation from 'hooks/useNavigation';
import Modal from 'components/commons/Modal';
import BottomSheet from '../BottomSheet';

const UniversalModal = observer(() => {
  const [windowMode, setWindowMode] = useState<'window' | 'mobile'>();
  const [useDrawer, setUseDrawer] = useState<boolean>(false);

  const navigation = useNavigation();
  const { uiStore } = useStore();
  const handleResize = () => {
    if (window.innerWidth < 600 || window.isWebView) {
      setWindowMode('mobile');
    } else {
      setWindowMode('window');
    }
  };

  const handlePressEscape = (event: any) => {
    if (event.key === 'Escape' && uiStore.universalModal.modalShow) {
      uiStore.universalModal.close(uiStore.universalModal.options?.useHistoryBack || false);

      if (uiStore.universalModal.options?.useHistoryBack) {
        navigation.pop();

        setTimeout(() => {
          const newPath = window.location.pathname.toString().split('/');
          if (newPath.length === 4) {
            const slicedArr = newPath.slice(0, -1);
            window.history.replaceState('force', '', slicedArr.join('/'));
          }
        }, 50);
      }
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    window.addEventListener('keydown', handlePressEscape, true);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('keydown', handlePressEscape);
    };
  }, []);

  useEffect(() => {
    setUseDrawer(uiStore.universalModal.options?.useDrawer || false);
  }, [uiStore.universalModal.options]);

  // eslint-disable-next-line no-nested-ternary
  return (
    <>
      {typeof uiStore.universalModal.options?.modalType !== 'undefined' ? (
        uiStore.universalModal.options?.modalType === 'confirm' ? (
          <Modal />
        ) : (
          <BottomSheet />
        )
      ) : (
        <>{windowMode === 'mobile' ? useDrawer ? <Drawer /> : <BottomSheet /> : <Modal />}</>
      )}
    </>
  );
});

export default UniversalModal;
