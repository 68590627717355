import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Row } from 'components/commons/layouts';
import { PostModel } from 'types/CommonTypes';
import { ChannelBoardDto, PostDto } from 'services/data-contracts';
import { getFeedThmbnailUrl, stripedSting } from 'components/items/commonLogic';

import PaginationBase from './PaginationBase';
import useNavigation from 'hooks/useNavigation';

const AdBoardType = observer(
  ({ posts, currentBoard }: { posts?: Array<PostDto>; currentBoard: ChannelBoardDto | null }) => {
    const { navigate } = useNavigation();

    const handleClickPost = (post: PostDto) => {
      // navigate(`/mypost/detail/${post.channel?.channelName}/${post.board?.id}/${post.id}`);
      navigate(`/${post.channel?.channelName}/${post.board?.id}/${post.id}`);
    };

    return (
      <PaginationBase paginationType="pager" containerStyle={{ borderRadius: 0, maxWidth: 570 }}>
        <FreeBoardContainerStyle>
          {posts?.map((post: PostModel) => (
            <GridItemStyle
              onClick={() => {
                handleClickPost(post);
              }}
            >
              <Row style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <ImageContainerStyle>
                  <img
                    src={getFeedThmbnailUrl(post.items?.[0]?.file?.publicUrl, '150')}
                    style={{
                      height: 'auto',
                      width: '100%',
                      aspectRatio: '1',
                      objectFit: 'cover',
                    }}
                  />
                </ImageContainerStyle>
                <Row style={{ justifyContent: 'flex-start', alignItems: 'center', height: 80 }}>
                  {/* <div style={{ paddingLeft: 10 }}> */}
                  <MainTitleTextStyle style={{ paddingLeft: 10 }}>{post.title}</MainTitleTextStyle>
                  {/* <ContentTextStyle>{stripedSting(post.content, 70)}</ContentTextStyle> */}
                  {/* </div> */}
                </Row>
              </Row>
            </GridItemStyle>
          ))}
        </FreeBoardContainerStyle>
      </PaginationBase>
    );
  },
);

const ImageContainerStyle = styled.div`
  width: 100px;
  overflow: hidden;
  border-radius: 15px;
  display: flex;
  align-items: center;
`;

const FreeBoardContainerStyle = styled.div`
  max-width: 570px;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media ${(props) => props.theme.media.mobile} {
  }
`;

const GridItemStyle = styled.div`
  padding: 10px 10px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 5px;
  transition: all 0.2s ease;
  background: #fff;
  &:hover {
  }
  /* @media ${(props) => props.theme.media.mobile} {
    padding-left: 5px 15px;
  } */
`;

const MainTitleTextStyle = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const ContentTextStyle = styled.div`
  font-size: 13px;
  color: #666;
`;

export default AdBoardType;
