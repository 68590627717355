import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { AdDto, PostTagDto } from 'services/data-contracts';
import { isMobile } from 'react-device-detect';
import useNavigation from 'hooks/useNavigation';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useFollowContext } from 'providers/follow/useFollowContext';

import Masonry from '@mui/lab/Masonry';
import useToken from 'libs/hooks/useToken';
import styled from 'styled-components';
import MainGnbComponent from 'features/mossMain/components/MainGnbComponent';
import useLog from 'libs/hooks/useLog';
import InfinityBoardBase from 'features/board/containers/InfinityBoardBase';
import DealsHeader from 'features/mossMain/components/DealsHeader';
import EmptyArticle from 'components/commons/EmptyArticle';
import MobileAdPostItem3 from 'pages/MobileAdPostItem3';
import NavigationTabBar from 'components/commons/NavigationTabBar';
import { useGlobalContext } from 'context/useGlobalContext';
import MobileAdPostItem from '../../../pages/MobileAdPostItem';
import { usePageContext } from '../context/usePageContext';
import { useRootContext } from 'libs/hooks/useRootContext';

const DealsContainer = observer(() => {
  const { navigate } = useNavigation();

  const gnbRef = useRef<any>(null);

  const { writeLog } = useLog();
  const { historyStore, uiStore, adStore } = useStore();
  const {
    displayType,
    setTags,
    initPage,
    updateFollowStatus,
    setFavoriteAd,
    getAds,
    deals,
    setLatestDeals,
  } = usePageContext();
  const { setDeal } = useGlobalContext();
  const { myChannel } = useRootContext();
  const { followInfo } = useFollowContext();
  const { t } = useTranslation();
  const { validUser } = useToken();

  const location = useLocation();
  const [searchParam] = useSearchParams();

  setDeal(true);

  const showLoginConfirm = (referrer: string, params?: string) => {
    uiStore.confirm.show({
      message: t('A245'),
      onConfirmed: async () => {
        navigate(`/login?action=(${referrer}.${params})`);
      },
    });
  };

  const LogoHeader = () => {
    return (
      <div style={{ display: 'flex', marginLeft: 16, flex: 1, flexGrow: 1 }}>
        <img src="./images/ohmoss_logo.svg" style={{ width: 100 }} alt="" />
      </div>
    );
  };

  const handleClickVisit = (adItem: AdDto) => {
    adStore.setDeals(deals);
    navigate(`/deals/${adItem.id}`);
  };

  const handleClickTag = (tag: PostTagDto) => {
    navigate(`/?tags=${tag.name}`);
  };

  const getTags = () => {
    const tags = searchParam.getAll('tags');
    if (tags.length > 0) {
      setTags(
        tags.map((tag: string) => {
          return { name: tag };
        }),
      );
      return tags.join('&tags=');
    }
    return null;
  };

  const handleClickFavorite = async (adItem: AdDto, isFavorite: boolean) => {
    const result = validUser();
    if (result) {
      setFavoriteAd(isFavorite, adItem.channel?.id as number, adItem.id as number);
    } else {
      showLoginConfirm('6', `adid=${adItem.id}`);
    }
  };

  useLayoutEffect(() => {
    const tags = getTags();
    if (tags) {
      initPage({ tags: `${tags}` });
    } else {
      initPage();
    }
    writeLog();
    // setTimeout(() => {
    //   window.scrollTo(0, 0);
    // }, 0);
  }, [location]);

  useEffect(() => {
    if (followInfo) {
      updateFollowStatus(followInfo.isFollow, followInfo.channelName);
      uiStore.universalModal.close();
    }
  }, [followInfo]);

  useEffect(() => {
    const last = historyStore.histories[historyStore.histories.length - 1];
    const valid = last && last.path.indexOf('/deals') === -1;
    if (typeof myChannel !== 'undefined' && valid) {
      getAds();
    } else if (adStore.ads.length === 0) {
      getAds();
    } else {
      setLatestDeals(adStore.ads);
    }
  }, [historyStore.histories, myChannel]);

  return (
    <>
      <MainGnbComponent
        defaultComponent={<LogoHeader />}
        ref={gnbRef}
        position={isMobile ? 'bottom' : 'top'}
      />
      <FeedContainerStyle>
        <DealsHeader />
        {displayType === 'list' ? (
          <InfinityBoardBase hasMore={false} postCounts={deals?.length}>
            {deals?.map((ad: AdDto) => (
              <MobileAdPostItem
                isPreview
                isOwner={ad.channel?.id === myChannel?.id}
                post={{ ...ad.post, ad, channel: ad.channel }}
                onClickVisit={() => {
                  handleClickVisit(ad);
                }}
                onClickTag={handleClickTag}
                onClickFavorite={(isFavorite: boolean) => {
                  handleClickFavorite(ad, isFavorite);
                }}
              />
            ))}
          </InfinityBoardBase>
        ) : (
          <InfinityBoardBase hasMore={false} postCounts={deals?.length}>
            <GridViewStyle>
              <Masonry columns={2} spacing={2}>
                {deals && deals?.length > 0 ? (
                  <>
                    {deals?.map((ad: AdDto) => (
                      <MobileAdPostItem3
                        item={ad}
                        onClickVisit={() => {
                          handleClickVisit(ad);
                        }}
                        onClickTag={handleClickTag}
                      />
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </Masonry>
            </GridViewStyle>
          </InfinityBoardBase>
        )}
        {deals && deals.length === 0 && <EmptyArticle title="" />}
      </FeedContainerStyle>
      <NavigationTabBar type="main" />
    </>
  );
});

const FeedContainerStyle = styled.div`
  margin: auto;
  padding-top: 60px;
  padding-bottom: 70px;
  background: #fff;
  height: 100vh;
`;

const GridViewStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-left: 10px;
  justify-content: flex-start;
`;

export default DealsContainer;
