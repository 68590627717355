import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { useStore } from 'stores/StoreHelper';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
// import EditorSwiper from '@ckeditor/custom-plugins/EditorSwiper';

const WebEditor = forwardRef(
  (
    {
      defaultValue,
      initialValue,
      onEditorChange,
    }: {
      defaultValue?: string;
      initialValue?: string;
      onEditorChange: (value?: string) => void;
    },
    ref,
  ) => {
    const editorConfiguration = {
      extraPlugins: [
        function (editor: any) {
          editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
            return uploadImageHandler(loader);
          };
        },
        // EditorSwiper,
      ],
      // plugins: [ ],
    };

    const editorRef = useRef<any>(null);
    const { fileStore } = useStore();
    // const [initialValue, setInitialValue] = useState<string>();
    const [value, setValue] = useState('');

    useImperativeHandle(ref, () => ({
      getData,
      setData
    }));

    const getData = () => {
      if (editorRef.current.editor) return editorRef.current.editor.getData();
    };

    const setData = (data: string) => {
      if (editorRef.current.editor) return editorRef.current.editor.setData(data);
    };

    const uploadImageHandler = (loader: any) => {
      return {
        upload() {
          return new Promise((resolve, reject) => {
            loader.file.then(async (file: any) => {
              const data: any = await fileStore.uploadImage(file, 'Link');
              resolve({ default: data.publicUrl });
            });
          });
        },
      };
    };

    // useEffect(() => {
    //   if (defaultValue) {
    //     setInitialValue(defaultValue);
    //   }
    // }, [defaultValue]);

    return (
      <>
        <CKEditor
          ref={editorRef}
          editor={ClassicEditor}
          config={editorConfiguration}
          data={initialValue || ''}
          onReady={(editor: any) => {
            // You can store the "editor" and use when it is needed.
            console.log('Editor is ready to use!', editor);
          }}
          onChange={(event: any, editor: any) => {
            const data = editor.getData();
            if (onEditorChange) onEditorChange();
          }}
          onBlur={(event: any, editor: any) => {
            console.log('Blur.', editor);
          }}
          onFocus={(event: any, editor: any) => {
            console.log('Focus.', editor);
          }}
        />
      </>
    );
  },
);
// https://ohmoss-dev.s3.ap-northeast-2.amazonaws.com/Link/7d2b9cbd-45d1-4fbd-b793-3db11f94bb09
export default React.memo(WebEditor);
