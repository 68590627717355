import React, { useState, useEffect } from 'react';
import Slider, { SliderProps } from '@mui/material/Slider';

interface ExtendedSliderProps extends SliderProps {
  useThumb?: boolean;
}

const MUISlider = (props: ExtendedSliderProps) => {
  const [sliderValue, setValue] = useState<number | number[] | undefined>(5);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props]);

  return (
    <Slider
      sx={{
        '& .MuiSlider-track': { height: 32, borderRadius: '5px', background: '#05C755' },
        '& .MuiSlider-rail': { height: 32, borderRadius: '5px', background: '#efefef' },
        '& .MuiSlider-thumb': {
          height: 32,
          width: 32,
          borderRadius: '5px',
          background: '#05C755',
          display: props.useThumb === false ? 'none' : 'block',
        },
      }}
      onChange={(e: any, value: number, activeThumb: number) => {
        setValue(value);
        if (props.onChange) {
          props.onChange(e, value, activeThumb);
        }
      }}
      value={sliderValue}
      min={props.min || 5}
      max={props.max || 100}
      aria-label="Default"
      valueLabelDisplay="auto"
      {...props}
    />
  );
};

export default MUISlider;
