import { IconButton } from '@mui/material';
import TitleForm from 'components/items/forms/TitleForm';
import React, { useEffect, useState } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import styled from 'styled-components';
import { ChannelProposalConfigDto, ChannelProposalTypeDto } from 'services/data-contracts';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import { isMobile } from 'react-device-detect';
import ProposalOptionItem from './ProposalOptionItem';

function ProfileCategoryOptions({
  profileItems,
  onChangeProfileItems,
  onLabelCheck,
}: {
  profileItems?: any;
  onChangeProfileItems?: (items?: ChannelProposalTypeDto[]) => void;
  onLabelCheck?: (data: string) => void;
}) {
  const { updateServiceModel, unmountModel } = useModelManagement<ChannelProposalConfigDto>();

  const [profileOptionItems, setProfileOptionItems] = useState<ChannelProposalTypeDto[]>([]);
  const [label, setLabel] = useState<string>('');

  const onChangeProfileProposalOptions = async (value: string, seq: number) => {
    const updatedProfileItems = profileOptionItems.map((item) =>
      item.seq === seq ? { ...item, name: value } : item,
    );
    setProfileOptionItems(updatedProfileItems);
    onChangeProfileItems?.(updatedProfileItems);
  };

  const addOptionItem = () => {
    let maxValue = 0;
    if (profileOptionItems?.length > 0) {
      maxValue = Math.max(...profileOptionItems.map((item) => item.seq || 0));
    }
    setProfileOptionItems([...profileOptionItems, { seq: maxValue + 1, name: '' }]);
    updateServiceModel('types', profileOptionItems);
  };

  const deleteOptionItem = (item: any, index: number) => {
    const newProfileOptions = profileOptionItems.filter(
      (item) => item.seq !== profileOptionItems[index].seq,
    );
    setProfileOptionItems([...newProfileOptions]);
    onChangeProfileItems?.(newProfileOptions);
  };

  const sendLabelForValidity = (data: string) => {
    onLabelCheck?.(data);
  };

  useEffect(() => {
    if (profileItems) {
      setProfileOptionItems(profileItems?.types ?? []);
    }
  }, [profileItems]);

  useEffect(() => {
    sendLabelForValidity(label);
  }, [label]);

  useEffect(() => {
    sendLabelForValidity(profileItems?.label);
  }, [profileItems?.label]);

  useEffect(() => {
    return () => {
      unmountModel();
    };
  }, []);

  return (
    <>
      <ItemSectionStyle>
        <ItemSectionTitle>타이틀</ItemSectionTitle>
        <ItemContent>
          <TitleForm
            onChangeValue={(value: string) => {
              setLabel(value);
              updateServiceModel('label', value);
            }}
            initialValue={profileItems?.label}
            placeHolder="문의 환영합니다."
            label="타이틀"
          />
        </ItemContent>
      </ItemSectionStyle>

      {profileOptionItems?.map((item, index) => (
        <ProposalOptionItem
          key={item?.seq}
          seq={item?.seq}
          initialValue={item?.name}
          onDeleteClick={() => {
            deleteOptionItem(item, index);
          }}
          onChangeValue={(value: string) => {
            // console.log(value);
            if (item?.seq !== undefined) {
              onChangeProfileProposalOptions?.(value, item.seq);
            }
          }}
        />
      ))}

      <AddOptionBtn onClick={addOptionItem}>
        <AddOptionText>문의 옵션 추가</AddOptionText>
        <img src="/images/dark_plus.svg" alt="" />
      </AddOptionBtn>
    </>
  );
}

const AddOptionBtn = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  border: 1px solid #efefef;
  border-radius: 25px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const AddOptionText = styled.div`
  display: inline-block;
  color: #333;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 5px;
`;

export default ProfileCategoryOptions;
