import React, { useEffect, useState } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import { validationHelper as VH } from 'controls/validationHelper';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { Row } from 'components/commons/layouts';
import { ImageUploader } from 'components/commons';
import { useStore } from 'stores/StoreHelper';
import { ToggleButton } from 'components/commons/ToggleButton';
import { t } from 'i18next';
import { CustomFile, IChannelBoardModel } from 'types/BlockExtendsTypes';
import { useModelManagement } from 'libs/hooks/useModelUpdate';

import MUISwitch from 'components/commons/buttons/MUISwitch';
import TitleForm from 'components/items/forms/TitleForm';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import CategoryController from 'controllers/CategoryController';
import styled from 'styled-components';
import SimpleColorPickerWithPopOver from 'components/items/SimpleColorPickerWithPopOver';

const MyShopFormContainer = ({ item }: { item?: IChannelBoardModel }) => {
  const defaultProps: Partial<IChannelBoardModel> = {
    boardType: 'SHOP',
    viewType: 'NONE',
    useProfile: false,
    isDefault: false,
    isCommentAllow: false,
    isPrimary: false,
    isActive: true,
    layout: 2,
    boxStyle: { borderRadius: '8px' },
    useTransparent: true,
  };

  const { uiStore } = useStore();

  const { updateServiceModel, initialBinding, initialModel, unmountModel, serviceModel } =
    useModelManagement<IChannelBoardModel>();

  const { updateCategory, deleteCategory } = CategoryController();

  const [localModel, setLocalModel] = useInitializeModel<Partial<IChannelBoardModel>>(
    item,
    defaultProps,
    initialBinding,
  );

  const [isValid, setIsValid] = useState<boolean>(false);
  const [newStyle, setNewStyle] = useState<IChannelBoardModel>({
    layout: 2,
    boxStyle: { borderRadius: '8px' },
  });
  const rounds = [
    {
      description: t('A189'),
      radius: '0px',
    },
    {
      description: t('A190'),
      radius: '8px',
    },
    {
      description: t('A191'),
      radius: '30px',
    },
  ];

  const layouts = [
    { description: 1, img: '/images/management/layout_1.svg' },
    { description: 2, img: '/images/management/layout_2.svg' },
    { description: 3, img: '/images/management/layout_3.svg' },
  ];

  const handleClickAddNewShop = async () => {
    if (typeof serviceModel !== 'undefined') {
      // updateServiceModel('boxStyle', undefined);
      const result = await updateCategory(serviceModel);
      uiStore?.bottomSheet.confirm(result);
    }
  };

  const handleDeleteShopItem = async (item: IChannelBoardModel) => {
    if (typeof item !== 'undefined') {
      uiStore.confirm.show({
        message: '정말 삭제하시겠습니까?',
        onConfirmed: async () => {
          const result = await deleteCategory(item?.id as number);
          uiStore?.bottomSheet.confirm(result);
        },
      });
    }
  };

  useEffect(() => {
    if (localModel) {
      const name = VH.required(localModel?.name);
      setIsValid(name);
    }
  }, [localModel]);

  useEffect(() => {
    if (item) {
      if (typeof item.metadata !== 'undefined') {
        const parsedStyle = JSON.parse(item.metadata);
        initialBinding({ ...item, ...parsedStyle });
      } else {
        initialBinding(item);
      }
    }
    return () => {
      unmountModel();
    };
  }, [item]);

  useEffect(() => {
    if (typeof item !== 'undefined') {
      if (typeof item.metadata !== 'undefined') {
        const parsedStyle = JSON.parse(item.metadata);
        setNewStyle(parsedStyle);
        // updateServiceModel('layout', parsedStyle?.layout);
        // updateServiceModel('boxStyle', parsedStyle?.boxStyle);
      }
    }
  }, [item?.metadata]);

  useEffect(() => {
    if (initialModel) {
      setLocalModel('displayOnThumbnail', initialModel?.displayOnThumbnail);
      if (
        typeof initialModel?.displayOnThumbnail !== 'undefined' &&
        initialModel?.thumbnailColorRgba
      ) {
        setLocalModel('thumbnailColorRgba', initialModel?.thumbnailColorRgba);
      } else {
        setLocalModel('thumbnailColorRgba', '#000000');
      }
      if (
        typeof initialModel?.displayOnThumbnail !== 'undefined' &&
        initialModel?.thumbnailBackgroundColorRgba
      ) {
        setLocalModel('thumbnailBackgroundColorRgba', initialModel?.thumbnailBackgroundColorRgba);
      } else {
        setLocalModel('thumbnailBackgroundColorRgba', '#FFFFFF');
      }
    }
  }, [initialModel]);

  return (
    <div style={{ padding: 20, width: '100%' }}>
      <ItemSectionStyle>
        <ItemSectionTitle>마이샵 이미지</ItemSectionTitle>
        <ItemContent>
          <ImageUploader
            type="single"
            modalType="modal"
            useCircularCrop={false}
            initialValue={localModel?.thumbnail}
            attachType="image"
            onSelected={(image: CustomFile) => {
              updateServiceModel('thumbnail', image);
            }}
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>타이틀</ItemSectionTitle>
        <ItemContent>
          <TitleForm
            initialValue={initialModel?.name}
            placeHolder="타이틀을 작성해 주세요."
            onChangeValue={(value: string) => {
              setLocalModel('name', value);
              updateServiceModel('name', value);
            }}
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>
          <Row style={{ justifyContent: 'space-between' }}>
            <div>썸네일 정보표시</div>
            <MUISwitch
              checked={localModel?.displayOnThumbnail}
              onChange={(e: any, value: boolean) => {
                setLocalModel('displayOnThumbnail', value);
                updateServiceModel('displayOnThumbnail', value);
              }}
            />
          </Row>
        </ItemSectionTitle>
      </ItemSectionStyle>
      {localModel?.displayOnThumbnail && (
        <>
          <ItemSectionStyle>
            <ItemSectionTitle>
              <Row style={{ justifyContent: 'space-between' }}>
                <div>썸네일 정보 글자 색상</div>
                <HexColorCodeText>{localModel?.thumbnailColorRgba ?? '#000000'}</HexColorCodeText>
                {/* <HexColorCodeText>
                  {convertOpacityToPercent(localModel?.thumbnailColorRgba ?? '')}
                </HexColorCodeText> */}
                <SimpleColorPickerWithPopOver
                  useOpacity
                  useGradient={false}
                  defaultValue={localModel?.thumbnailColorRgba ?? 'rgba(0,0,0,1)'}
                  onColorChanged={(color: string) => {
                    // handleColorChanged(color);
                    setLocalModel('thumbnailColorRgba', color);
                    updateServiceModel('thumbnailColorRgba', color);
                  }}
                />
              </Row>
            </ItemSectionTitle>
          </ItemSectionStyle>

          <ItemSectionStyle>
            <ItemSectionTitle>
              <Row style={{ justifyContent: 'space-between' }}>
                <div>썸네일 정보 배경 색상</div>
                <HexColorCodeText>
                  {localModel?.thumbnailBackgroundColorRgba ?? 'rgba(255,255,255,0.6)'}
                </HexColorCodeText>
                <SimpleColorPickerWithPopOver
                  useOpacity
                  useGradient={false}
                  defaultValue={localModel?.thumbnailBackgroundColorRgba ?? 'rgba(255,255,255,1)'}
                  onColorChanged={(color: string) => {
                    setLocalModel('thumbnailBackgroundColorRgba', color);
                    updateServiceModel('thumbnailBackgroundColorRgba', color);
                    // handleBackgroundColorChanged(color);
                  }}
                />
              </Row>
            </ItemSectionTitle>
          </ItemSectionStyle>
        </>
      )}

      <ItemSectionStyle>
        <ItemSectionTitle>레이아웃</ItemSectionTitle>
        <div style={{ marginTop: 10 }}>
          <Row style={{ justifyContent: 'flex-start', gap: 20 }}>
            {layouts.map((layout: any) => (
              <ToggleButtonStyle
                onClick={() => {
                  setNewStyle({ ...newStyle, layout: layout.description });
                  // setLocalModel('layout', layout.description);
                  updateServiceModel('layout', layout.description);
                }}
                item={layout}
                defaultChecked={newStyle?.layout === layout.description}
                style={{ width: '7rem', height: '2.5rem', borderRadius: 5, padding: '0 5px' }}
              >
                <div
                  style={{
                    display: 'flex',
                    fontSize: '0.75rem',
                    flexDirection: 'row',
                    textAlign: 'center',
                    alignItems: 'center',
                    gap: 10,
                  }}
                >
                  <img src={layout.img} alt="" />
                  {layout.description}줄
                </div>
              </ToggleButtonStyle>
            ))}
          </Row>
        </div>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>사진 프레임</ItemSectionTitle>
        <div style={{ marginTop: 10 }}>
          <Row style={{ gap: 20, justifyContent: 'flex-start' }}>
            {rounds.map((round: any) => (
              <ToggleButtonStyle
                onClick={() => {
                  // setSelectedRadius(round.radius);
                  setNewStyle((prevStyle) => ({
                    ...prevStyle,
                    boxStyle: {
                      ...prevStyle?.boxStyle,
                      borderRadius: round.radius,
                    },
                  }));

                  // setLocalModel('boxStyle', {
                  //   ...localModel?.boxStyle,
                  //   borderRadius: round.radius,
                  // });
                  updateServiceModel('boxStyle', {
                    ...serviceModel?.boxStyle,
                    borderRadius: round.radius,
                  });
                }}
                item={round}
                defaultChecked={newStyle?.boxStyle?.borderRadius === round.radius}
                style={{
                  borderRadius: round.radius,
                  width: '4.5rem',
                  height: '2rem',
                }}
              >
                <div style={{ fontSize: '0.75rem' }}>{round.description}</div>
              </ToggleButtonStyle>
            ))}
          </Row>
        </div>
      </ItemSectionStyle>
      {typeof item !== 'undefined' && (
        <DeleteButton onClick={() => handleDeleteShopItem(item)}>마이샵 삭제</DeleteButton>
      )}
      <div style={{ marginTop: 50 }}>
        <OhmossButton style={{ width: '100%' }} onClick={handleClickAddNewShop} disabled={!isValid}>
          {typeof item === 'undefined' ? '추가' : '적용'}
        </OhmossButton>
      </div>
    </div>
  );
};

const ToggleButtonStyle = styled(ToggleButton)``;

const DeleteButton = styled.div`
  color: #f03e3e;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
`;

const HexColorCodeText = styled.div`
  color: rgba(202, 202, 202, 0.92);
  font-weight: 700;
  font-size: 0.875rem;
`;

export default MyShopFormContainer;
