import React, { ReactNode } from 'react';

import { DealOptionDto, LinkDto } from 'services/data-contracts';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import { Context } from '../useOhmossContext';

import MyLinkStore from 'stores/MyLinkStore';
import { useStore } from 'stores/StoreHelper';
import MyDealStore from 'stores/MyDealStore';
import { useRootContext } from 'libs/hooks/useRootContext';

interface PageProviderProps {
  children: ReactNode | ReactNode[] | Element | Element[];
}

const LinkProvider = <T extends LinkDto>({ children }: PageProviderProps) => {
  const myLinkStore = new MyLinkStore();
  const myDealStore = new MyDealStore();
  const { uiStore } = useStore();

  /** Primitive */
  const serviceItems = useModelManagement<T>();

  const { myChannel } = useRootContext();

  const getDealOptions = async (boardId: number, linkId: number) => {
    return await myDealStore.getDealOptions(myChannel?.id as number, linkId);
  };

  const saveDealOptions = async (boardId: number, linkId: number, options: DealOptionDto[]) => {
    return await myDealStore.saveDealOption(myChannel?.id as number, boardId, linkId, options);
  };

  const deleteBlock = (item: LinkDto) => {
    uiStore.bottomSheet.close();
    uiStore.confirm.show({
      message: '선택하신 블록을 삭제하시겠습니까? ',
      onConfirmed: async () => {
        const result = await myLinkStore.deleteItem(
          myChannel?.id as number,
          item.boardId as number,
          item.id as number,
        );
        if (result) {
          return result;
        }
      },
    });
  };

  return (
    <>
      {typeof children !== 'undefined' ? (
        <Context.Provider
          value={{
            getDealOptions,
            saveDealOptions,
            deleteBlock,
            ...serviceItems,
          }}
        >
          {children}
        </Context.Provider>
      ) : (
        <></>
      )}
    </>
  );
};

export default LinkProvider;
