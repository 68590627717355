import React, { useEffect, useState } from 'react';
import useNavigation from 'hooks/useNavigation';
import styled from 'styled-components';
import { DealInquiryDto } from 'services/data-contracts';
import MyAvatarBox from 'components/commons/MyAvatarBox';
import { IconButton } from '@mui/material';

import InquiryList from '../../components/InquiryList';
import InquirySummery from '../../components/InquirySummery';
import ManagedDeals from '../../components/ManagedDeals';
import { useRootContext } from 'libs/hooks/useRootContext';
import { Row } from 'components/commons/layouts';
import { BadgeStyle } from 'components/commons/buttons/Badge';
import NewInquiriesBox from '../pc/NewInquiriesBox';
import { ListBoxItem } from 'features/csTown/commonComponents/styles';
import Text from 'components/commons/Text';

const RootContainer = ({ inquiries }: { inquiries?: DealInquiryDto[] }) => {
  const { navigate } = useNavigation();
  const { myChannel, getMyChannel } = useRootContext();

  const [unhandledInquiries, setUnhandledInquiries] = useState<DealInquiryDto[]>([]); // 미처리 문의

  useEffect(() => {
    setUnhandledInquiries(inquiries?.filter((inquiry) => inquiry?.status === 'PENDING') || []);
  }, [inquiries]);

  return (
    <div style={{ background: '#F3F4F1', paddingBottom: 60 }}>
      <div>
        <div
          style={{
            margin: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 60,
            padding: '0 20px',
          }}
          className="global-width"
        >
          <CSTownStyle>
            <Row
              style={{ padding: 10, cursor: 'pointer', justifyContent: 'flex-start' }}
              onClick={() => {
                navigate('/cstown/home');
              }}
            >
              <img src="/images/oh_logo.svg" style={{ width: 60 }} />
              <BadgeStyle>CSTOWN V1</BadgeStyle>
            </Row>
          </CSTownStyle>
          <IconButton
            onClick={() => {
              navigate(`/my/settings`);
            }}
          >
            <img src="/images/settings-green.svg" alt="settings-button" />
          </IconButton>
        </div>
        <div style={{ padding: '0 20px' }}>
          <MyAvatarBox
            avatarUrl={myChannel?.profile?.profileImage?.publicUrl}
            channelName={myChannel?.channelName}
            channelType={`${myChannel?.channelType} - ${myChannel?.membershipType}`}
          />
        </div>
      </div>
      {typeof myChannel !== 'undefined' && (
        <div style={{ padding: 20, paddingTop: 0 }}>
          {/* <InquirySummery
            newInquiry={unhandledInquiries?.length}
            unHandledInquiry={unhandledInquiries?.length}
          /> */}
          <div style={{ margin: '20px 0' }}>
            <ManagedDeals url="/cstown/deals" />
          </div>
          <ListBoxItem
            style={{ padding: 0 }}
            title={
              <Text type="H4" style={{ margin: 20 }}>
                최신문의
              </Text>
            }
          >
            <NewInquiriesBox style={{ margin: 0 }} />
          </ListBoxItem>
          {/* <InquiryList inquiries={inquiries} /> */}
        </div>
      )}
    </div>
  );
};

const CSTownStyle = styled.div`
  color: #173300;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.096px;
`;

export default RootContainer;
