/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AdDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MyAd<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 내 광고 목록 조회
   *
   * @tags my ad
   * @name GetAds
   * @summary 내 광고 목록 조회
   * @request GET:/v2/me/channels/{channelId}/ads
   * @secure
   */
  getAds = (
    channelId: number,
    query?: {
      /** 카테고리 ('의', '식', '주') */
      category?: string;
      /**
       * 'yyyy-MM-dd' (시작 날짜)
       * @format date
       */
      startDate?: string;
      /**
       * 'yyyy-MM-dd' (마지막 날짜)
       * @format date
       */
      endDate?: string;
      /** 광고 진행 상태 */
      status?: 'WAITING' | 'EXPIRED' | 'ONGOING' | 'BLOCKED' | 'CANCELED';
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<AdDto[], any>({
      path: `/v2/me/channels/${channelId}/ads`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 광고 등록
   *
   * @tags my ad
   * @name CreateAd
   * @summary 광고 등록
   * @request POST:/v2/me/channels/{channelId}/ads
   * @secure
   */
  createAd = (channelId: number, data: AdDto, params: RequestParams = {}) =>
    this.http.request<AdDto, any>({
      path: `/v2/me/channels/${channelId}/ads`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 광고 취소
   *
   * @tags my ad
   * @name Cancel
   * @summary 내 광고 취소
   * @request PATCH:/v2/me/channels/{channelId}/ads/{adId}/cancel
   * @secure
   */
  cancel = (channelId: number, adId: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/me/channels/${channelId}/ads/${adId}/cancel`,
      method: 'PATCH',
      secure: true,
      ...params,
    });
  /**
   * @description 내 광고 정보 조회
   *
   * @tags my ad
   * @name GetAd
   * @summary 내 광고 정보 조회
   * @request GET:/v2/me/channels/{channelId}/ads/{adId}
   * @secure
   */
  getAd = (channelId: number, adId: number, params: RequestParams = {}) =>
    this.http.request<AdDto, any>({
      path: `/v2/me/channels/${channelId}/ads/${adId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 광고 즐겨찾기 목록
   *
   * @tags my ad
   * @name GetInterestedAds
   * @summary 광고 즐겨찾기 목록
   * @request GET:/v2/me/channels/{channelId}/ads/interest
   * @secure
   */
  getInterestedAds = (
    channelId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<AdDto[], any>({
      path: `/v2/me/channels/${channelId}/ads/interest`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
