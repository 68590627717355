/* eslint-disable react/jsx-pascal-case */
import React, { ReactElement, useState } from 'react';

import { observer } from 'mobx-react';

import { Row } from 'components/commons/layouts';

import SettingsIcon from '@mui/icons-material/Settings';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from 'styled-components';

import _ from 'lodash';

const SettingContainer = observer(
  ({ title, height, children }: { title: string; height: number; children: ReactElement }) => {
    const [settingMode, setSettingMode] = useState<boolean>(false);

    return (
      <SettingsContainerStyle style={{ marginTop: 20 }}>
        <Row
          style={{ justifyContent: 'flex-start', cursor: 'pointer', padding: 20 }}
          onClick={() => {
            setSettingMode((prev) => !prev);
          }}
        >
          <SettingsIcon sx={{ marginRight: 1 }} />
          <div>{title}</div>
          <ExpandIconStyle on={settingMode} />
        </Row>
        <SettingStyle show={settingMode} height={height}>
          {children}
        </SettingStyle>
      </SettingsContainerStyle>
    );
  },
);

const SettingsContainerStyle = styled.div`
  background: #f8f8f8;
  border-radius: 15px;
  @media ${(props) => props.theme.media.mobile} {
    background: #fff;
  }
`;

const ExpandIconStyle = styled(KeyboardArrowDownIcon)<{ on: boolean }>`
  transform: ${(props) => (props.on ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 1.3s ease-in-out;
`;

const SettingStyle = styled.div<{ show: boolean; height: number }>`
  transition: all 0.3s ease-in-out;
  height: ${(props) => (props.show ? `${props.height}px` : '0px')};
  overflow: hidden;
`;

export default SettingContainer;
