import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { IImageBlock } from 'types/BlockExtendsTypes';
import useNavigation from 'libs/hooks/useNavigate';

import TitleStickerBox from 'components/commons/stickers/TitleStickerBox';
import { getFlexPosition } from 'libs/helper/utils';

const ImageTemplate = ({
  item,
  textStyle,
  boxStyle,
  editMode,
}: {
  item: IImageBlock;
  textStyle?: CSSProperties;
  boxStyle?: CSSProperties;
  editMode?: boolean;
}) => {
  const navigate = useNavigation();

  const handleClickImage = () => {
    if (item.linkUrl) {
      return () => {
        navigate(item.linkUrl, true);
      };
    }
  };

  return (
    <ContainerStyle>
      {item.layout === 'TypeD' && (
        <div
          style={{
            padding: 10,
          }}
        >
          <TitleStickerBox
            item={item}
            stickerPosition={item?.stickerPosition}
            textStyle={textStyle}
            align={getFlexPosition(item.contentAlign)}
            containerStyle={{
              textAlign: item.contentAlign as 'left' | 'center' | 'right',
            }}
          />
        </div>
      )}
      <div style={{ position: 'relative' }}>
        <ImageStyle
          editMode={editMode}
          src={item?.thumbnail?.publicUrl}
          onClick={handleClickImage()}
          alt=""
          style={{
            cursor: item?.linkUrl ? 'pointer' : 'default',
          }}
        />
        {(item.layout === 'TypeA' || item.layout === 'TypeB') && (
          <div>
            <ImageTitleAbsStyle
              position={item.layout}
              style={{
                ...textStyle,
                justifyContent: getFlexPosition(item.contentAlign),
              }}
            >
              <TitleStickerBox
                item={item}
                stickerPosition={item?.stickerPosition}
                textStyle={textStyle}
                textBoxStyle={{
                  background: '#fff',
                  borderRadius: 15,
                  padding: '0 10px',
                }}
                containerStyle={{
                  textAlign: item.contentAlign as 'left' | 'center' | 'right',
                }}
              />
            </ImageTitleAbsStyle>
          </div>
        )}
      </div>
      {item.layout === 'TypeC' && (
        <div style={{ padding: 10 }}>
          <TitleStickerBox
            item={item}
            stickerPosition={item?.stickerPosition}
            textStyle={textStyle}
            align={getFlexPosition(item.contentAlign)}
            containerStyle={{
              textAlign: item.contentAlign as 'left' | 'center' | 'right',
            }}
          />
        </div>
      )}
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  /* background: #fff; */
  /* border-radius: 10px; */
  width: 100%;
  overflow: hidden;
`;

const SmallStyle = css`
  height: 200px;
  width: 100%;
  object-fit: cover;
`;
const OriginStyle = css``;

const TopStyle = css`
  top: 10px;
`;

const BottomStyle = css`
  bottom: 10px;
`;
const ImageStyle = styled.img<{ editMode?: boolean }>`
  /* OriginStyle */
  /* ${(props) => {
    if (props.editMode === true) {
      return SmallStyle;
    } else {
    }
  }} */
`;

const ImageTitleAbsStyle = styled.div<{ position?: 'TypeA' | 'TypeB' }>`
  position: absolute;
  width: 100%;
  display: flex;
  border-radius: 8px;
  padding: 5px 10px;
  ${(props) => {
    if (props.position === 'TypeB') {
      return TopStyle;
    } else if (props.position === 'TypeA') {
      return BottomStyle;
    }
  }}
`;
export default ImageTemplate;
