import React, { useEffect, useState } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import MUIToggleGroup from 'components/commons/buttons/MUIToggleGroup';
import MUIToggle from 'components/commons/buttons/MUIToggle';
import { GalleryLayoutButtons } from 'features/management/resources/images';
import { Layout } from 'types/BlockExtendsTypes';
import useSetDefaultValue from 'features/management/hooks/useDefaultValue';

type CommonProps = {
  initialValue?: string;
  onChange?: (value: string) => void;
};
const GalleryLayout = ({ initialValue, onChange }: CommonProps) => {
  const [value, setValue] = useState<string>();

  useSetDefaultValue(setValue, initialValue);

  useEffect(() => {
    if (typeof value !== 'undefined' && onChange) onChange(value as Layout);
  }, [value]);

  return (
    <>
      <ItemSectionStyle>
        <ItemSectionTitle>이미지 레이아웃</ItemSectionTitle>
        <ItemContent>
          <MUIToggleGroup
            value={value}
            onChange={(e: any, value: string) => {
              setValue(value);
            }}
          >
            <MUIToggle value="TypeA">
              <GalleryLayoutButtons.GalleryLayoutA />
            </MUIToggle>
            <MUIToggle value="TypeB">
              <GalleryLayoutButtons.GalleryLayoutB />
            </MUIToggle>
            <MUIToggle value="TypeC">
              <GalleryLayoutButtons.GalleryLayoutC />
            </MUIToggle>
            <MUIToggle value="TypeD">
              <GalleryLayoutButtons.GalleryLayoutD />
            </MUIToggle>
          </MUIToggleGroup>
        </ItemContent>
      </ItemSectionStyle>
    </>
  );
};

export default GalleryLayout;
