import React, { ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

const InfinityBoardBase = ({
  children,
  postCounts,
  hasMore,
  onNext,
}: {
  children: ReactNode;
  postCounts?: number;
  hasMore: boolean;
  onNext?: () => void;
}) => {
  return (
    <>
      <InfiniteScroll
        style={{}}
        dataLength={postCounts || 0}
        next={() => {
          if (onNext) onNext();
        }}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>{/* <b>더이상 표시할 게시글이 없습니다.</b> */}</p>
        }
        refreshFunction={() => { }}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
        pullDownToRefreshContent={
          <></>
          // <h3 style={{ textAlign: 'center' }}>&#8595; 리프레시 하시려면 내려주세요.</h3>
        }
        releaseToRefreshContent={
          <></>
          // <h3 style={{ textAlign: 'center' }}>&#8593; 놓으면 리프레시 됩니다.</h3>
        }
      >
        {children}
      </InfiniteScroll>
    </>
  );
};

export default InfinityBoardBase;
