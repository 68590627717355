import React from 'react';
import { observer } from 'mobx-react';
import MobileModalHeader from 'components/commons/MobileModalHeader';
import UseTermsContainer from 'features/mainFeatures/terms/containers/UseTermsContainer';
import UniversalLayout from 'components/commons/layouts/UniversalLayout';
import DetailPageHeader from 'components/commons/DetailPageHeader';

const UseTermsPage = observer(() => {
  return (
    <UniversalLayout>
      {/* <DetailPageHeader title="서비스 이용 약관" /> */}
      <UseTermsContainer />
    </UniversalLayout>
  );
});

export { UseTermsPage };
