import { IconButton } from '@mui/material';
import TitleForm from 'components/items/forms/TitleForm';
import React, { useEffect, useState } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import styled from 'styled-components';
import { ChannelProposalConfigDto, LinkItemDto } from 'services/data-contracts';
import { isMobile } from 'react-device-detect';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import ProposalOptionItem from './ProposalOptionItem';

function ChannelCategoryOptions({
  items,
  onChangeItems,
}: {
  items?: LinkItemDto[];
  onChangeItems?: (items: LinkItemDto[]) => void;
}) {
  const { serviceModel, updateServiceModel, unmountModel } =
    useModelManagement<ChannelProposalConfigDto>();

  const [optionItems, setOptionItems] = useState<LinkItemDto[]>([]);

  const onChangeProposalOptions = (value: string, seq: number) => {
    const updatedOptionItems = optionItems.map((item) =>
      item.seq === seq ? { ...item, title: value } : item,
    );
    setOptionItems(updatedOptionItems);
    onChangeItems?.(updatedOptionItems);
  };

  const addOptionItem = () => {
    let maxValue = 0;
    if (optionItems?.length > 0) {
      maxValue = Math.max(...optionItems.map((item) => item.seq || 0));
    }
    setOptionItems([...optionItems, { seq: maxValue + 1, title: '' }]);
  };

  const deleteOptionItem = (item: any, index: number) => {
    const newOptions = optionItems.filter((item) => item.seq !== optionItems[index].seq);
    setOptionItems([...newOptions]);
    onChangeItems?.(newOptions);
  };

  useEffect(() => {
    if (items) {
      setOptionItems(items);
    }
  }, [items]);

  useEffect(() => {
    return () => {
      unmountModel();
    };
  }, []);

  return (
    <>
      {optionItems?.map((item, index) => (
        <ProposalOptionItem
          initialValue={item?.title}
          onDeleteClick={() => {
            deleteOptionItem(item, index);
          }}
          onChangeValue={(value: string) => {
            onChangeProposalOptions?.(value, item?.seq as number);
          }}
        />
      ))}

      <AddOptionBtn onClick={addOptionItem}>
        <AddOptionText>문의 옵션 추가</AddOptionText>
        <img src="/images/dark_plus.svg" alt="" />
      </AddOptionBtn>
    </>
  );
}

const AddOptionBtn = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  border: 1px solid #efefef;
  border-radius: 25px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const AddOptionText = styled.div`
  display: inline-block;
  color: #333;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 5px;
`;

export default ChannelCategoryOptions;
