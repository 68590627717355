import React, { CSSProperties, SyntheticEvent, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useMediaContext } from 'providers/useMediaContext';
import { useLocation } from 'react-router-dom';
import { MembershipNaviParmametersDto } from 'services/data-contracts';
import { useRootContext } from 'libs/hooks/useRootContext';
import useNavigation from 'hooks/useNavigation';

const screenWidth = window.innerWidth;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CommonTabButtons({
  items,
  initialValue,
  style,
  noOfTabs,
  onHandleTabClick,
}: {
  items: string[];
  initialValue?: string;
  style?: CSSProperties;
  noOfTabs?: number;
  onHandleTabClick: (label: string) => void;
}) {
  const location = useLocation();
  const { navigate } = useNavigation();

  const { myChannel } = useRootContext();
  const ScreenTypes = useMediaContext();
  const [value, setValue] = useState(0);
  const [newItems, setNewItems] = useState<string[]>();
  const [navigatorLocation, setNavigatorLocation] = useState<MembershipNaviParmametersDto>();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleTabClick = (label: string) => {
    getNavigatorParameter();
    onHandleTabClick(label);
  };

  const getNavigatorParameter = async () => {
    const locationPathName = location?.pathname;
    const parsedLocationPathName = locationPathName?.split('/').splice(1);
    const locationSearch = location?.search?.replace('?', '')
    const parsedLocationSearch = locationSearch?.split('=');

    const newLocation: MembershipNaviParmametersDto = {
      depth: parsedLocationPathName.length,
      pathNames: parsedLocationPathName,
      paramName: parsedLocationSearch[0],
      paramValue: parsedLocationSearch[1],
    }
    setNavigatorLocation(newLocation);
  }

  useEffect(() => {
    if (initialValue && items) {
      getNavigatorParameter();
      if(noOfTabs === 3) {
        setNewItems(items.filter(item => item !== '멤버십 설명서' && item !== '멤버십 선물'));
      } else if(noOfTabs === 4) {
        setNewItems(items.filter(item => item !== '멤버십 설명서'));
      } else {
        setNewItems(items.filter(item => item !== '멤버십 설명서'));
      }
    }
  }, [initialValue, myChannel]);

  useEffect(() => {
    if(newItems){
      const i = newItems?.findIndex((item: string) => item === initialValue);
      if(i > -1){
        setValue(i);
      } else {
        navigate(`/my/subscriptions?tab=0`);
      }
    }
  },[newItems])

  return (
    <Tabs
      sx={{
        borderBottom: '1px solid #173300',
        margin: '0',
        padding: '0px 0px 0px 0px',
        display: 'flex',
        '& .MuiTabs-root': { margin: '0' },
      }}
      TabIndicatorProps={{
        style: { background: '#173300' },
      }}
      value={value}
      onChange={(event: SyntheticEvent, newValue: number) => handleChange(event, newValue)}
    >
      {newItems?.map((item: string) => (
        <Tab
          sx={{
            minWidth: ScreenTypes.isLarge ? '200px' : (
              (navigatorLocation?.depth === 2 && navigatorLocation?.pathNames && navigatorLocation?.pathNames[1] === 'subscriptions') ? '100px' : '150px'
            ),
            flex: 1,
            // width: noOfTabs === 4 ? '25%' : '33.33%',
            fontSize: '14px',
            fontWeight: '500',
            color: 'grey',
            wordBreak: 'keep-all',
            whiteSpace: 'pre-wrap',
            '&.Mui-selected': {
              color: '#173300',
            },
            '& .MuiTab-indicator': {},
          }}
          label={item}
          onClick={() => handleTabClick(item)}
          {...a11yProps(1)}
        />
      ))}
    </Tabs>
  );
}
