/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { BoardCategoryDto, ChannelBoardDto } from 'services/data-contracts';
import { Row } from 'components/commons/layouts';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { BoardCategoryModel, ChannelBoardTypes } from 'types/CommonTypes';
import { isMobile } from 'react-device-detect';

import ToggleButton from '@mui/material/ToggleButton';
import styled from 'styled-components';
import _ from 'lodash';
import CreateBoardFormTypeSelector from './CreateBoardFormTypeSelector';
import { useTranslation } from 'react-i18next';

/**
 * @description BoardType이 Link로 설정된 경우, 링크메뉴에서 직접 보드를 생성하는 경우임.
 */
const CreateBoardForm = observer(
  ({
    boardModel,
    show,
    boardType,
    onClose,
  }: {
    boardModel?: BoardCategoryModel;
    boardType?: ChannelBoardTypes;
    show?: boolean;
    onClose: () => void;
  }) => {
    const { uiStore, boardConfigStore, uiStores, myBoardStore, myChannelStore } = useStore();
    const { adminStore } = uiStores;
    const { t } = useTranslation();

    const showCreateBox = (boardCategory?: BoardCategoryModel) => {
      uiStore.universalModal.show({
        title: boardCategory?.channel ? t('Modify') : t('A125'),
        modalOption: { style: { maxWidth: 800, maxHeight: 800 } },
        bottomSheetOptions: {
          topPosition: isMobile ? 50 : 10,
          style: {},
        },
        children: (
          <Row
            style={{
              width: '100%',
              flexDirection: 'column',

              justifyContent: 'flex-start',
            }}
          >
            <CreateBoardFormTypeSelector
              onDelete={handleOnItemDelete}
              onCreate={handleCreateBoard}
              boardCategory={boardCategory}
              boardType={boardType}
              boardModel={boardModel}
            />
          </Row>
        ),
        onClose: () => {
          handleCloseForm();
        },
        onConfirmed() {
          handleCloseForm();
        },
      });
    };

    const handleCloseForm = () => {
      adminStore.setShowNewBoardEditor(false);
      onClose();
    };

    const handleCreateBoard = async (board: ChannelBoardDto) => {
      if (board) {
        let result;
        if (!board.channel) {
          result = (await myBoardStore.createBoard(
            myChannelStore.currentChannel?.id as number,
            board,
          )) as any;
        } else {
          result = (await myBoardStore.updateBoard(
            myChannelStore.currentChannel?.id as number,
            board,
          )) as any;
        }
        if (typeof result.errorCode === 'undefined') {
          myBoardStore.updateBoardByItem(
            result as ChannelBoardDto,
            board.channel ? 'update' : 'create',
          );
        }
      }
      uiStore.universalModal.close();
    };

    const handleOnItemDelete = (board: ChannelBoardDto) => {
      uiStore.confirm.show({
        style: { maxWidth: 400, height: 220 },
        message: `${board?.name} ${t('A127')}`,
        okText: t('Delete'),
        closeText: t('Cancel'),
        onConfirmed: async () => {
          myBoardStore.deleteBoard(
            myChannelStore.currentChannel?.id as number,
            board?.id as number,
          );
          myBoardStore.updateBoardByItem(board as ChannelBoardDto, 'delete');
          uiStore.universalModal.close();
        },
      });
    };

    useEffect(() => {
      boardConfigStore.getBoardCategories();
      myBoardStore.getBoards(myChannelStore.currentChannel?.id as number);
    }, []);

    useEffect(() => {
      if (boardType) {
        const b = boardConfigStore.boardCategories.find(
          (category: BoardCategoryDto) => category.boardType === boardType,
        );
        if (show === true) showCreateBox(b);
      } else if (show === true) showCreateBox(boardModel);
    }, [show]);

    return <></>;
  },
);

export default CreateBoardForm;
