/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  DealInquiryDto,
  DealInquiryThreadDto,
  InquirySummaryDto,
  LinkDto,
  MessageDto,
  SimpleDealDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class CsService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags CSService
   * @name CloseInquiry
   * @summary 문의 종료처리
   * @request PUT:/v2/cs/inquiries/{inquiryId}/close
   * @secure
   */
  closeInquiry = (inquiryId: number, params: RequestParams = {}) =>
    this.http.request<DealInquiryDto, any>({
      path: `/v2/cs/inquiries/${inquiryId}/close`,
      method: 'PUT',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags CSService
   * @name GetInquiryThreads1
   * @summary 문의 스레드목록
   * @request GET:/v2/cs/inquiries/{inquiryId}/threads
   * @secure
   */
  getInquiryThreads1 = (
    inquiryId: number,
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DealInquiryThreadDto[], any>({
      path: `/v2/cs/inquiries/${inquiryId}/threads`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags CSService
   * @name Answer1
   * @summary 문의 답변하기
   * @request POST:/v2/cs/inquiries/{inquiryId}/threads
   * @secure
   */
  answer1 = (inquiryId: number, data: MessageDto, params: RequestParams = {}) =>
    this.http.request<DealInquiryThreadDto, any>({
      path: `/v2/cs/inquiries/${inquiryId}/threads`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags CSService
   * @name DeleteAnswer1
   * @summary 문의 답변삭제
   * @request POST:/v2/cs/inquiries/{inquiryId}/threads/{threadId}
   * @secure
   */
  deleteAnswer1 = (inquiryId: number, threadId: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v2/cs/inquiries/${inquiryId}/threads/${threadId}`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags CSService
   * @name GetInquiries1
   * @summary 문의 목록
   * @request GET:/v2/cs/inquiries
   * @secure
   */
  getInquiries1 = (
    query?: {
      status?: 'PENDING' | 'ANSWERED' | 'CLOSED';
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DealInquiryDto[], any>({
      path: `/v2/cs/inquiries`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags CSService
   * @name GetInquiry
   * @summary 문의 정보
   * @request GET:/v2/cs/inquiries/{inquiryId}
   * @secure
   */
  getInquiry = (inquiryId: number, params: RequestParams = {}) =>
    this.http.request<DealInquiryDto, any>({
      path: `/v2/cs/inquiries/${inquiryId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags CSService
   * @name GetInquirySummaryForMember
   * @summary 위임된 전체 딜 문의 집계
   * @request GET:/v2/cs/inquiries/summary
   * @secure
   */
  getInquirySummaryForMember = (params: RequestParams = {}) =>
    this.http.request<InquirySummaryDto, any>({
      path: `/v2/cs/inquiries/summary`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags CSService
   * @name GetDealsByMemberUser
   * @summary 내게 위임된 딜 목록
   * @request GET:/v2/cs/deals
   * @secure
   */
  getDealsByMemberUser = (
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 10
       */
      size?: number;
      /**
       * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
       * @default ["createDate,DESC"]
       */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SimpleDealDto[], any>({
      path: `/v2/cs/deals`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags CSService
   * @name GetDeal1
   * @summary 딜 정보
   * @request GET:/v2/cs/deals/{dealId}
   * @secure
   */
  getDeal1 = (dealId: number, params: RequestParams = {}) =>
    this.http.request<LinkDto, any>({
      path: `/v2/cs/deals/${dealId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
