import { Row } from 'components/commons/layouts';
import StyledRefBox from 'components/commons/layouts/StyledRefBox';
import React, { useState, useEffect, useRef } from 'react';
import { PostTagDto } from 'services/data-contracts';
import styled from 'styled-components';
import Chip from '@mui/material/Chip';
import { useStore } from 'stores/StoreHelper';

const HashEditor = ({ model, onSelected }: { model?: PostTagDto[]; onSelected: (tags: PostTagDto[]) => void; }) => {
  const editorRef = useRef<any>(null);
  const boxRef = useRef<any>(null);
  const [hashs, setHashes] = useState<PostTagDto[]>([]);
  const [recent, setRecent] = useState<string>('');

  const { uiStores } = useStore();

  const handleDelete = (targetTag: PostTagDto) => {
    const target = hashs.findIndex((tag: PostTagDto) => tag.name === targetTag.name);
    if (target >= 0) {
      const newHashes = [...hashs];
      newHashes.splice(target, 1);
      setHashes(newHashes);
    }
  };

  const handleOnInput = (e: any) => {
    if (e.target.value !== recent) {
      setRecent('');
    }
    if (e.target.value.includes(' ') || e.target.value.includes('&nbsp;')) {
      const low = e.target.value.replace(/&nbsp;/g, '').replace(/\s/g, '');
      if (low !== '') {
        addHash(low);
      } else {
        editorRef.current.value = '';
      }
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      const low = e.target.value;
      if (low.length > 0) {
        addHash(low);
      }
    } else if (e.key === 'Backspace' && e.target.value.length === 0) {
      const newHashes = [...hashs];
      newHashes.splice(newHashes.length - 1, 1);
      setHashes(newHashes);
    }
  };

  const addHash = (hash: string) => {
    setTimeout(() => {

      const hashName = hash.replace(/<[^>]*>/g, '');
      const recent = hashs.find((hash: PostTagDto) => hash.name === hashName);
      if (!recent) {
        const newHashes = [...hashs];
        newHashes.push({ name: hashName });
        setHashes(newHashes);
        setRecent('');
      } else {
        setRecent(recent.name as string);
        editorRef.current.value = editorRef.current.value.replace(/\s/g, '');
      }
    }, 10);
  };

  useEffect(() => {
    if (hashs.length > 0) {
      editorRef.current.value = '';
      boxRef.current.scrollLeft = boxRef.current.scrollWidth + 100;
      onSelected(hashs);
    }
  }, [hashs]);


  useEffect(() => {
    if (model && Array.isArray(model)) {
      setHashes(model);
    }
  }, [model]);
  return (
    <>
      <HashEditorStyle ref={boxRef}>
        <Row style={{ justifyContent: 'flex-start' }}>
          <>
            {hashs.map((hash: PostTagDto) => (
              <HashTagStyle>
                <Chip
                  className={recent === hash.name ? 'chip-on' : ''}
                  label={`#${hash.name}`}
                  onDelete={() => {
                    handleDelete(hash);
                  }}
                />
              </HashTagStyle>
            ))}
          </>
          <input
            ref={editorRef}
            placeholder="Hash tags"
            style={{ minWidth: 100, height: 40, padding: 10 }}
            onInput={handleOnInput}
            onKeyDown={handleKeyDown}
          />
        </Row>
      </HashEditorStyle>
      {recent && <DuplicatedTagStyle>중복된 태그가 있습니다.</DuplicatedTagStyle>}
    </>
  );
};

const HashEditorStyle = styled(StyledRefBox)`
  height: 50px;
  padding: 5px 10px;
  overflow-x: scroll;
  margin-bottom: 10px;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  justify-content: flex-start;
`;

const HashTagStyle = styled.div``;

const DuplicatedTagStyle = styled.div`
  font-size: 12px;
  color: red;
  position: relative;
  top: -5px;
`;

export default HashEditor;
