import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { validationHelper } from 'controls/validationHelper';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  TextField,
} from '@mui/material';
import { t } from 'i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: any) => ({
  outlinedInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.625rem',
      border: ({ passwordModel }: any) =>
        passwordModel?.length > 0 ? '1.5px solid #173300' : 'none',
      background: 'rgba(23, 51, 0, 0.1)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.625rem',
      border: '1.5px solid #173300',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.625rem',
      border: '1.5px solid #173300',
    },
  },
});

const PasswordForm = forwardRef(
  (
    {
      model,
      label,
      rows,
      autoComplete = 'on',
      showLabel = true,
      multiline = false,
      required = true,
      helperText = '',
      errorText = t('A146'),
      placeHolder,
      onChangeValue,
      onEntered,
      type,
      classes,
    }: {
      model?: string;
      label?: string;
      rows?: number;
      autoComplete?: 'new-password' | 'on';
      showLabel?: boolean;
      placeHolder?: string;
      multiline?: boolean;
      required?: boolean;
      helperText?: string;
      errorText?: string;
      onEntered?: () => void;
      onChangeValue: (value: string) => void;
      type?: 'login' | 'input' | undefined;
      classes?: any;
    },
    ref: any,
  ) => {
    const titleRef = useRef<any>(null);

    const [isValid, setIsValid] = useState<boolean>(true);
    const [passwordModel, setPasswordModel] = useState<string>('');
    const [showPassword, setShowPassword] = useState(false);
    const [meetsPassword, setMeetsPassword] = useState<boolean>(false);

    const sx = useRef<any>(null);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const checkValidate = (value: string) => {
      if (!required) {
        return;
      }
      const result = validationHelper.required(value);
      setIsValid(result);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    useEffect(() => {
      if (typeof model !== 'undefined') {
        setPasswordModel(model);
        checkValidate(model);
      }
      return () => {
        setPasswordModel('');
      };
    }, [model]);

    useEffect(() => {
      onChangeValue(passwordModel);
    }, [meetsPassword, passwordModel]);

    return (
      <>
        {showLabel && (
          <InputLabel htmlFor="outlined-adornment-password" shrink required>
            {label || '비밀번호'}
          </InputLabel>
        )}
        <TextField
          className={type === 'login' ? classes.outlinedInput : ''}
          error={meetsPassword}
          sx={{ width: '100%' }}
          inputProps={{
            minLength: 8,
            maxLength: 14,
            autoComplete,
            style: { padding: '1.2rem 1rem 1rem 1.2rem' },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          id="outlined-adornment-password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e: any) => {
            setPasswordModel(e.target.value);
            setMeetsPassword(e.target.value.length < 8);
          }}
        />
        {meetsPassword && <FormHelperText> {t('A105')}</FormHelperText>}
      </>
    );
  },
);

export default withStyles(styles)(PasswordForm);
