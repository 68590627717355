import React, { ReactChild } from 'react';
import useNavigation from 'hooks/useNavigation';
import { useTranslation } from 'react-i18next';

import { observer } from 'mobx-react';

import styled from 'styled-components';
import { OhmossButton } from 'resources/controls/OhmossButton';

const PublicMenu = observer(() => {
  const { navigate } = useNavigation();

  return (
    <>
      <>
        <div style={{ margin: '50px 40px' }}>
          <TitleContainer>
            <TitleStyle>
              인플루언서를 위한
              <br /> 프로페셔널 링크, <OhmossText>oh!moss</OhmossText>
            </TitleStyle>
          </TitleContainer>
          <OhmossButton
            onClick={() => {
              navigate('/login');
            }}
          >
            로그인
          </OhmossButton>
          <SignUpContainer>
            <SignUpTitleStyle
              style={{ fontSize: 18, paddingTop: 40, paddingBottom: 20, margin: 'auto' }}
            >
              아직 계정이 없으신가요?
            </SignUpTitleStyle>
          </SignUpContainer>
          <OhmossButton type="simple" onClick={() => navigate('/sign-up')}>
            회원가입
          </OhmossButton>
          <SignUpContainer>
            <SignUpTitleStyle
              style={{ fontSize: 16, paddingTop: 40, paddingBottom: 0, margin: 'auto' }}
            >
              셀러와 브랜드의 성공 파트너
            </SignUpTitleStyle>
          </SignUpContainer>
          <OhmossButton type="link" onClick={() => navigate('/my/subscriptions?tab=0')}>
            유료서비스 보기
          </OhmossButton>
        </div>
      </>
    </>
  );
});

const MenuItemStyle = styled.div`
  margin: 20px 0;
  padding: 10px;
  display: flex;
  border-radius: 8px;
  cursor: pointer;
  &:first-child {
    margin-top: 0;
  }
  &:hover {
    background: #f6f5fa;
  }
`;

const SignUpContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
`;

const SignUpTitleStyle = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: -0.0275rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
  margin-bottom: 40px;
`;

const TitleStyle = styled.div`
  color: #173300;
  font-size: 1.7rem;
  font-weight: 600;
  display: inline;
`;

const OhmossText = styled.span`
  color: #96e06e;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.008rem;
`;

export default PublicMenu;
