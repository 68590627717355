import React, { ReactElement, useRef } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useNavigation from 'hooks/useNavigation';
import { IconButton } from '@mui/material';
import { useRootContext } from 'libs/hooks/useRootContext';
import SettingsIcon from '@mui/icons-material/Settings';
import StyledRefBox from './layouts/StyledRefBox';
import { Row } from './layouts';

const DetailPageHeader = observer(
  ({ title, gobackUrl }: { title?: string | ReactElement; gobackUrl?: string }) => {
    const boxRef = useRef<any>();
    const { myChannel } = useRootContext();
    const navigation = useNavigation();

    return (
      <HeaderContainerStyle className="global-width">
        <HeaderInnerStyle ref={boxRef} className="global-width">
          <Row style={{ justifyContent: 'space-between', height: 60 }}>
            <IconButton
              style={{ marginLeft: 10 }}
              onClick={() => {
                if (gobackUrl) {
                  if (gobackUrl === 'channel') {
                    navigation.navigate(`/${myChannel?.channelName}`);
                  }
                } else {
                  navigation.pop();
                }
              }}
            >
              <ArrowBackIosIcon style={{ color: '#96e06e', paddingLeft: 5 }} />
            </IconButton>
            <div style={{ flexGrow: 1, textAlign: 'center' }}>
              {title && <div style={{ fontWeight: 700, color: '#fff' }}>{title}</div>}
            </div>
            <IconButton
              onClick={() => {
                navigation.navigate('/my/settings');
              }}
            >
              <SettingsIcon style={{ color: '#96e06e' }} />
            </IconButton>
          </Row>
        </HeaderInnerStyle>
      </HeaderContainerStyle>
    );
  },
);

const HeaderContainerStyle = styled.div`
  position: sticky;
  top: 0px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  /* padding: 10px 0; */
  @media ${(props) => props.theme.media.mobile} {
    /* padding: 5px; */
  }
`;

const HeaderInnerStyle = styled(StyledRefBox)`
  /* height: 44px; */
  top: auto;
  /* background: #ffffff; */
  color: var(--secondary-color);
  background: var(--primary-color);
  margin: auto;
  /* border-radius: var(--border-radius); */
  align-items: center;
  z-index: 3;
  cursor: pointer;
`;

const LeftAlignStyle = styled.div`
  /* position: absolute; */
  /* left: 20px; */
  cursor: pointer;
`;
export default DetailPageHeader;
