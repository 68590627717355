import ReactGA from 'react-ga4';

/**
 * @description 현재 접근한 경로의 페이지가 공용인지 체크
 * @param pathname
 * @returns
 */
export const isPublieMenu = (pathname: string) => {
  if (
    !pathname.startsWith('/email') ||
    !pathname.startsWith('/verify') ||
    !pathname.startsWith('/admin') ||
    !pathname.startsWith('/')
  ) {
    return false;
  } else {
    return true;
  }
};

export const handleScroll = (e: any) => {
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  const zero = vh - window.scrollY;
  if (zero < 80) {
    return true;
  } else {
    return false;
  }
};

export const initGA = () => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('G-D8BYME7NGJ');
  }
};

export const sendGA = (pathname: string) => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
};
