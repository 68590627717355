import { makeObservable, flow, observable } from 'mobx';
import { MagazineService } from 'services/MagazineService';
import { HttpClient } from 'services/http-client';
import ServiceStoreBase from './ServiceStoreBase';
import { PagenationType } from 'types/CommonTypes';

/**
 * @description Public Link Store
 */
class MagazineStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      getMagazine: flow,
      getMagazineByKey: flow,
    });
  }

  api: MagazineService<unknown> = new MagazineService(new HttpClient());

  /** ========================================================================================= */

  *getMagazine(page?: number) {
    const { response } = yield this.api.getMagazines({ page: page || 0 });

    return response.status < 300 ? response : undefined;
  }

  *getMagazineByKey(magazineKey: string) {
    const { response } = yield this.api.getMagazineByKey(magazineKey);
    return response.status < 300 ? response.data : undefined;
  }
}

export default MagazineStore;
