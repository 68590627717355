import React, { useEffect, useState } from 'react';
import { Row } from 'components/commons/layouts';
import { ListBoxItem } from 'features/csTown/commonComponents/styles';
import { useStore } from 'stores/StoreHelper';

import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';

const ItemUseInquiry = ({
  useInquiry,
  readonly = false,
  onUpdate,
}: {
  useInquiry?: boolean;
  readonly?: boolean;
  onUpdate: (useInquiry?: boolean) => void;
}) => {
  const [inquiry, setUseInquiry] = useState<boolean>(() => useInquiry ?? false);

  useEffect(() => {
    if (useInquiry) {
      setUseInquiry(useInquiry);
    }
  }, [useInquiry]);
  return (
    <ListBoxItem
      title="고객문의 활성화"
      action={
        <MenuItemCheckBox
          disabled={readonly}
          initialValue={inquiry}
          onChangeValue={(value: boolean) => {
            setUseInquiry(value);
            onUpdate(value);
          }}
        />
      }
    />
  );
};

export default ItemUseInquiry;
