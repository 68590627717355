import React, { useEffect, useRef, useState } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { IVideoBlock } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { validationHelper } from 'controls/validationHelper';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { LinkTypes } from 'features/management/context/providers/ContextTypes';

import TitleForm from 'components/items/forms/TitleForm';
import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import UrlForm from 'components/items/forms/UrlForm';
import BlockController from 'controllers/BlockController';
import ActionButton from './ActionButton';

const Video = ({ blockModel }: { blockModel?: IVideoBlock }) => {
  const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)|(?:https?:\/\/)?youtu\.be\/([a-zA-Z0-9_-]+)/;

  const shortRegexs = /https:\/\/(?:www\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]+)/;
  const { uiStore } = useStore();

  const {
    initialModel,
    setState,
    stateModel,
    serviceModel,
    updateServiceModel,
    setMultipleState,

    initialBinding,
  } = useOhmossContext<LinkTypes<IVideoBlock>>();

  const { updateBlock } = BlockController<IVideoBlock>();

  const contactRef = useRef<any>();

  const [isValid, setIsValid] = useState<boolean>(false);

  const initializeModel = () => {
    if (blockModel) {
      initialBinding(blockModel);
    } else {
      setMultipleState({
        linkType: 'Video',
        isActive: true,
      });
    }
  };

  const handleOnUrlPaste = (value: string) => {
    const match = youtubeRegex.exec(value);
    const matchShort = shortRegexs.exec(value);

    // 매치된 결과가 있으면 해당 비디오 ID 반환
    if (match) {
      const code = match[1] ? match[1] : match[2];
      setState('address', code);
      updateServiceModel('videoType', 'youtube');
    } else if (matchShort) {
      const shortCode = matchShort[1];
      setState('address', shortCode);
      updateServiceModel('videoType', 'shorts');
    } else {
      setState('address', undefined);
    }
  };

  useEffect(() => {
    if (stateModel) {
      const titleValid = validationHelper.required(stateModel.title);
      const urlValid = validationHelper.url(stateModel.linkUrl);
      setIsValid(titleValid && urlValid);
    }
  }, [stateModel]);

  useEffect(() => {
    initializeModel();
    return () => {
      initialBinding(undefined);
    };
  }, [blockModel]);

  return (
    <div style={{}}>
      <ItemSectionStyle>
        <ItemSectionTitle>타이틀</ItemSectionTitle>
        <ItemContent>
          <TitleForm
            onChangeValue={(value: string) => {
              updateServiceModel('title', value);
              setState('title', value);
            }}
            initialValue={initialModel?.title}
            placeHolder={`제목을 작성해 주세요.`}
            label=""
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>동영상 주소</ItemSectionTitle>
        <ItemContent>
          <UrlForm
            format={[youtubeRegex, shortRegexs]}
            ref={contactRef}
            onChangeValue={(value: string) => {
              setState('linkUrl', value);
            }}
            onPaste={(value: string) => {
              handleOnUrlPaste(value);
            }}
            initialValue={initialModel?.linkUrl}
            errorText="올바른 Youtube주소형식이 아닙니다."
            placeHolder={`Youtube주소를 입력해 주세요.`}
            label=""
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <MenuItemCheckBox
          onChangeValue={(value: boolean) => {
            setState('autoplay', value);
          }}
          initialValue={initialModel?.useTransparent}
          title="자동재생"
        />
      </ItemSectionStyle>
      <ActionButton isValid={isValid} serviceModel={serviceModel} />
    </div>
  );
};

export default Video;
