import React, { useEffect, useState } from 'react';
import { ListBoxItem } from 'features/csTown/commonComponents/styles';
import { useRootContext } from 'libs/hooks/useRootContext';
import { Row } from 'components/commons/layouts';
import { Avatar } from '@mui/material';
import Text from 'components/commons/Text';
import LineChart from 'features/myPage/components/dashboard/LineChart';
import MyAnalyticsStore from 'stores/MyAnalyticsStore';
import moment from 'moment';
import { CountDto } from 'services/data-contracts';
import OnGoingDealsBox from './OnGoingDealsBox';
import NewInquiriesBox from './NewInquiriesBox';
import { OhmossButton } from 'resources/controls/OhmossButton';
import useNavigation from 'hooks/useNavigation';

const CSTownRootContainer = () => {
  const myAnalyticsStore = new MyAnalyticsStore();
  const { navigate } = useNavigation();
  const { myChannel } = useRootContext();

  const [visitCount, setVisitCount] = useState<CountDto[]>();

  const getAnalystics = async () => {
    const today = moment();
    const yesterday = today.subtract(1, 'days').format('YYYY-MM-DD');
    const thirtyDaysAgo = today.subtract(30, 'days').format('YYYY-MM-DD');
    const visitCount = (await myAnalyticsStore.getDailyVisitCounts(
      myChannel?.id as number,
      thirtyDaysAgo,
      yesterday,
    )) as unknown as CountDto[];

    setVisitCount(visitCount);
  };

  useEffect(() => {
    if (myChannel && myChannel?.channelType !== 'MEMBER') {
      getAnalystics();
    }
  }, [myChannel]);
  return (
    <Row style={{ flexWrap: 'wrap', alignItems: 'flex-start', padding: 20, gap: 20 }}>
      <div style={{ flexGrow: 1, width: '100%' }}>
        <ListBoxItem style={{ width: '33%', marginBottom: 0 }}>
          {myChannel && (
            <Row style={{ justifyContent: 'flex-start' }}>
              <Avatar
                src={myChannel?.profile?.profileImage?.publicUrl || ''}
                style={{ width: 80, height: 80 }}
              />
              <div style={{ marginLeft: 10 }}>
                <Text type="H2" style={{ color: '#173300' }}>
                  {myChannel?.channelName}
                  <span style={{ fontSize: 14, marginLeft: 5, color: '#777' }}>
                    님! 환영합니다.
                  </span>
                </Text>
                <div style={{ textTransform: 'uppercase' }}>
                  <Text buttonRole="DESCRIPTION">
                    {myChannel?.channelType} {myChannel?.membershipType}
                  </Text>
                </div>
              </div>
            </Row>
          )}
        </ListBoxItem>
      </div>
      <>
        {myChannel?.channelType !== 'MEMBER' && (
          <ListBoxItem title="대시보드" style={{ flex: 1 }}>
            <LineChart title="일별 방문추이" visitCount={visitCount} />
          </ListBoxItem>
        )}
      </>
      <ListBoxItem
        title="신규 고객문의"
        action={
          <>
            <OhmossButton
              type="square"
              style={{ width: 100, height: 20 }}
              onClick={() => {
                navigate('/cstown/inquiries');
              }}
            >
              더보기
            </OhmossButton>
          </>
        }
      >
        <NewInquiriesBox style={{ margin: 0 }} />
      </ListBoxItem>
      <ListBoxItem title="진행중인 공구">
        <OnGoingDealsBox style={{ margin: 0 }} />
      </ListBoxItem>
    </Row>
  );
};

export default CSTownRootContainer;
