import React from 'react';
import styled from 'styled-components';
import { LandingPageWrapper } from './LandingPageWrapper';
import { BodyContainer, DetailText, MainText, SubText, TextContainer } from './Styles';

const LandingSlideE = ({ isMobile }: { isMobile?: boolean }) => {
  return (
    <LandingPageWrapper>
      <BodyContainer>
        {!isMobile && (
          <CenterImg>
            <img
              alt="left"
              src="/images/landing/slidee_image2.svg"
              style={{ width: 200, height: 800 }}
            />
          </CenterImg>
        )}
        <TextContainer>
          <DetailText>체크리스트</DetailText>
          <MainText>공구의 시작과 끝</MainText>
          <SubText>
            공구를 하다보면 사소한 것을 놓쳐 고객 또는 브랜드와 마찰이 생기곤 합니다. 이때 깨진
            신뢰는 다시 복원하기가 쉽지 않습니다. 이에, 오모스는 셀러들을 위해 전용 체크리스트를
            준비했습니다. 브랜드 제안서를 받을때 부터 미팅, 계약, 공구마감까지 오모스와 함께라면
            여러분의 공구가 든든해집니다.
          </SubText>
        </TextContainer>
        {isMobile && (
          <CenterImg>
            <img alt="left" src="/images/landing/slidee_image2.svg" style={{ height: 600 }} />
          </CenterImg>
        )}
      </BodyContainer>
    </LandingPageWrapper>
  );
};

const CenterImg = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  > img {
    width: 90% !important;
  }
`;

export default LandingSlideE;
