import boardCommonLogics from 'features/board/logic/boardCommonLogic';
import { MagServiceType } from 'features/management/context/providers/ContextTypes';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import React, { useEffect } from 'react';
import { MagazineDto } from 'services/data-contracts';
import styled from 'styled-components';
import InfinityBoardBase from 'features/board/containers/InfinityBoardBase';
import { useStore } from 'stores/StoreHelper';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { getFeedThmbnailUrl } from 'components/items/commonLogic';
import GnbComponent from 'features/commonFeatures/navigationBar/containers/GnbComponent';
import GnbHeader from 'features/commonFeatures/navigationBar/containers/GnbHeader';
import { useGlobalContext } from 'context/useGlobalContext';
import SignInMenu from 'features/commonFeatures/navigationBar/containers/SignInMenu';
import PublicMenu from 'features/commonFeatures/navigationBar/containers/PublicMenu';
import MagDetailPage from './MagDetailPage';
import RootGnbComponent from 'components/commons/RootGnbComponent';

const MagPage = () => {
  const { uiStore } = useStore();
  const { isLoggedIn } = useGlobalContext();
  const { getMags, magazines, hasMore, getMagByKey } = useOhmossContext<MagServiceType>();
  const { getImageFromDom } = boardCommonLogics();

  console.log(magazines);

  const handleClickMag = (magazine: MagazineDto, isBanner?: boolean) => {
    uiStore.bottomSheet.show({
      children: (
        <>
          <MagDetailPage
            magazine={magazine}
            magazineId={magazine?.magazineKey}
            isBanner={isBanner}
          />
          <ButtonStyle>
            <OhmossButton
              onClick={() => {
                uiStore.bottomSheet.close();
              }}
            >
              닫기
            </OhmossButton>
          </ButtonStyle>
        </>
      ),
      topPosition: 20,
    });
  };

  const getThumbnail = (magazine: MagazineDto) => {
    if (magazine.thumbnail) {
      return getFeedThmbnailUrl(magazine.thumbnail.publicUrl, '900');
    }

    const contentImage = getImageFromDom(magazine.content);
    if (contentImage) {
      return contentImage;
    }
  };

  const handleOnNext = () => {
    getMags();
  };

  useEffect(() => {
    getMags();
  }, []);

  return (
    <div style={{ paddingBottom: 10 }}>
      <RootGnbComponent current="MAG" />
      {/* <GnbComponentStyle className="global-width">
        <GnbComponent defaultComponent={<GnbHeader />} current="MAG">
          {isLoggedIn ? <SignInMenu /> : <PublicMenu />}
        </GnbComponent>
      </GnbComponentStyle> */}
      <div
        style={{ margin: 20, marginTop: 100, cursor: 'pointer' }}
        onClick={() => {
          handleClickMag({ magazineKey: '2vNotdIzhp4ZkhL' }, true);
        }}
      >
        <img src="/images/mag/MagBanner02.png" alt="" />
      </div>
      <div>
        <InfinityBoardBase
          hasMore={hasMore}
          onNext={handleOnNext}
          postCounts={magazines ? magazines?.length : 0}
        >
          {magazines?.map((magazine: MagazineDto, index: number) => (
            <MagContainerStyle
              onClick={() => {
                handleClickMag(magazine);
              }}
              style={{ cursor: 'pointer' }}
              key={index}
            >
              <ImageStyle image={getThumbnail(magazine)}>
                <TitleStyle>
                  <div style={{ fontSize: 14, color: '#2EBE59' }}>{magazine?.topic}</div>
                  <div style={{ fontSize: 20, fontWeight: 700 }}>{magazine?.title}</div>
                </TitleStyle>
              </ImageStyle>
            </MagContainerStyle>
          ))}
        </InfinityBoardBase>
      </div>
    </div>
  );
};

const MagContainerStyle = styled.div`
  margin: 20px;
  text-align: left;
  position: relative;
  width: auto;
  aspect-ratio: 1/0.8;
  /* height: 370px; */
  overflow: hidden;
  border-radius: 10px;
`;

const ImageStyle = styled.div<{ image?: string }>`
  position: absolute;
  background-image: url(${(props) => props.image});
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-position: center;
  background-color: #efefef;
`;

const TitleStyle = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  display: inline-block;
  position: absolute;
  bottom: 20px;
`;

const ButtonStyle = styled.div`
  padding: 20px;
`;

const GnbComponentStyle = styled.div`
  position: fixed;
  top: 10px;
  z-index: 10;
  @media ${(props) => props.theme.media.mobile} {
    position: fixed;
    border-radius: 0;
    top: 0px;
    box-shadow: none;
  }
`;

export default MagPage;
