import React, { useEffect, useState } from 'react';
import Collapse from '@mui/material/Collapse';

import { OhmossButton } from 'resources/controls/OhmossButton';
import styled from 'styled-components';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton } from '@mui/material';
import useNavigation from 'hooks/useNavigation';
import { useStore } from 'stores/StoreHelper';
import MembershipController from 'controllers/MembershipController';
import { toast } from 'react-toastify';
import { useRootContext } from 'libs/hooks/useRootContext';
import BrandController from 'controllers/BrandController';
import InfoBox from 'components/commons/InfoBox';
import { MemberDto } from 'services/data-contracts';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';
import chatController from 'features/community/controllers/chatController';
import CommonBottomsheetHeader from '../CommonBottomSheetHeader';

const MembershipChangeInfo = ({ orderId }: { orderId?: number }) => {
  const { navigate } = useNavigation();
  const { uiStore } = useStore();
  const { cancelMembership } = MembershipController();
  const { getMyChannel } = useRootContext();
  const { myChannel } = useRootContext();
  const { getMembers, getDelegatedDeals } = BrandController();
  const { inviteOrDeleteUser } = chatController();

  const [downgrade, setDowngrade] = React.useState(false);
  const [upgrade, setUpgrade] = React.useState(false);
  const [unsubscribeDetails, setUnsubscribeDetails] = React.useState(false);
  const [managers, setManagers] = useState<MemberDto[]>();

  const handleUnsubscribeMembership = async () => {
    uiStore.bottomSheet.show({
      style: { minHeight: '50vh' },
      header: (
        <CommonBottomsheetHeader
          title="멤버십을 해지합니다"
          styles={{ color: '#fff', backgroundColor: '#0BBD58' }}
        />
      ),
      children: (
        <Wrapper>
          <UnsubscribeContainer>
            <UnsubscribeText>구독 멤버십을 해지하게 되면, 즉시 결제가 해지됩니다</UnsubscribeText>
            <UnsubscribeText> ✔️ 주의사항</UnsubscribeText>
            <UnsubscribeText>
              무료이용기간 중 해지하시는 경우, 이후 재 가입하실때 무료멤버십은 제공되지 않습니다.
            </UnsubscribeText>
            <UnsubscribeText>
              이용중인 구독 멤버십이 즉시 해지되며, 유료서비스 이용 제공이 즉시 중단됩니다. 이점을
              꼭 유의하셔서 해지준비를 하시기 바랍니다.
            </UnsubscribeText>
            <UnsubscribeText>
              해당일로부터 일할 계산되어 환불되어 집니다. 멤버십기간내에 해지시 기존 할인받았던
              금액과 위약수수료를 제한 후 환불해 드립니다.
            </UnsubscribeText>
            <UnsubscribeText>
              {'환불금액은 멤버십 > 내 멤버십 > 멤버십 결제정보에서 확인할 수 있습니다'}
            </UnsubscribeText>
          </UnsubscribeContainer>
          <div>현재 총 {managers?.length}명의 멤버가 등록되어 있습니다.</div>
          <OhmossButton onClick={unsubscribeMembership} style={{ borderRadius: 8 }} type="danger">
            멤버십 해지하기
          </OhmossButton>
        </Wrapper>
      ),
    });
  };

  const unsubscribeMembership = async () => {
    if (typeof orderId !== 'undefined') {
      /**
       * 1. 내 멤버십 점검(셀러/브랜드)
       * 2. 셀러일경우,
       */

      // 해지시 채팅정리
      removeAllChannels();

      await cancelMembership(orderId as number);
      await getMyChannel();
      // todo
      // 해지시 매니저 정리(다운그레이드가 아니므로 모두 삭제)
      uiStore.bottomSheet.close();
      toast.success('멤버십 해지가 완료되었습니다.', { type: 'success' });
      navigate('/');
    } else {
      toast.warn('해지할 멤버십이 없습니다.');
    }
  };

  const removeAllChannels = async () => {
    const results = (await getDelegatedDeals()) as unknown as LinkDtoWithAssignedDeal[];
    const deals = results?.map((result) => {
      return { ...result, assigned: false };
    });

    const channels = managers?.map((manager) => manager.memberUser?.username) as string[];
    inviteOrDeleteUser(deals, [...channels, myChannel?.channelName as string]);
  };

  const getBrandMembers = async () => {
    const result = (await getMembers(myChannel?.id as number)) as unknown as MemberDto[];
    setManagers(result || 0);
  };

  useEffect(() => {
    if (myChannel && myChannel?.channelType === 'BRAND') {
      getBrandMembers();
    }
  }, [myChannel]);

  return (
    <Wrapper>
      <MainTitle>
        🗓️ 멤버십구독에서
        <br /> 원하는 멤버십을 고르세요
      </MainTitle>
      <GuideText>
        변경날짜로부터 즉시 멤버십이 변경됩니다.
        <br /> 결제일도 변경날짜부터로 변경됩니다.
      </GuideText>
      <Divider />
      <OhmossButton
        style={{ marginTop: 20, marginBottom: 30 }}
        onClick={() => {
          navigate('/my/subscriptions?tab=0');
          uiStore.bottomSheet.close();
        }}
      >
        멤버십 구독 바로가기
      </OhmossButton>
      <MembershipChangeOption>
        ⬆ 멤버십 업그레이드
        <IconButton onClick={() => setUpgrade((prev) => !prev)}>
          {!upgrade && <ExpandMoreIcon />}
          {upgrade && <ExpandLessIcon />}
        </IconButton>
      </MembershipChangeOption>
      <div style={{ marginBottom: 28, fontSize: 14, fontWeight: 300 }}>
        <Collapse in={upgrade} collapsedSize={0}>
          1. 변경날짜로부터 기존 결제일까지의 금액 환불
          <br /> 2. 변경날짜로부터 상위멤버십 시작 및 결제
          <div style={{ margin: '20px 0px 20px 0px' }}>
            예) FREE ➡️ 셀러PRO
            <br /> 멤버십구독에서 셀러PRO를 선택후 결제방식을 고른 후 결제를 하면 업그레이드됩니다.
          </div>
          <div style={{ margin: '20px 0px 20px 0px' }}>
            예) 셀러PRO ➡️ 셀러PREMIUM 멤버십구독에서 셀러PREMIUM을 선택후 결제방식을 고른 후 결제를
            하면 업그레이드됩니다.
          </div>
          프로에서 프리미엄으로 업그레이드 하는 경우 프로의 남은 기간에 대한 환불이 이루어집니다.
        </Collapse>
      </div>

      {/* <MembershipChangeOption>
        ⬇ 멤버십 다운그레이드
        <IconButton onClick={() => setDowngrade((prev) => !prev)}>
          {!downgrade && <ExpandMoreIcon />}
          {downgrade && <ExpandLessIcon />}
        </IconButton>
      </MembershipChangeOption> */}
      <div style={{ fontSize: 14, fontWeight: 300 }}>
        <Collapse in={downgrade} collapsedSize={0}>
          1. 변경날짜이후 기존결제일까지의 금액 환불
          <br /> (기존 할인된 금액과 위약수수료를 제한 후 환불) <br />
          2. 변경날짜로부터 하위멤버십 시작 및 결제
          <div style={{ margin: '20px 0px 20px 0px' }}>
            예) 셀러PREMIUM ➡️ 셀러PRO
            <br /> 멤버십구독에서 셀러PRO을 선택후 결제방식을 고른 후 결제를 하면
            다운그레이드됩니다.
          </div>
          프리미엄에서 프로로 다운그레이드 하는 경우 프리미엄 서비스를 사용하는 기간동안 할인된
          금액과 위약수수료를 제한 나머지 시용일에 대한 금액을 환불해드립니다.
        </Collapse>
      </div>
      <Divider style={{ marginBottom: 28 }} />

      <MembershipChangeOption>
        🛑 멤버십 해지
        <IconButton onClick={() => setUnsubscribeDetails((prev) => !prev)}>
          {!unsubscribeDetails && <ExpandMoreIcon />}
          {unsubscribeDetails && <ExpandLessIcon />}
        </IconButton>
      </MembershipChangeOption>
      <div style={{ fontSize: 14, fontWeight: 300 }}>
        <Collapse in={unsubscribeDetails} collapsedSize={0}>
          구독 멤버십을 해지하게 되면, 즉시 결제가 해지됩니다.
          <div style={{ margin: '20px 0px 20px 0px' }}>예) 셀러PRO or 셀러PREMIUM ➡️ FREE</div>
          ✔️ 주의사항 <br />
          <ExplainStyle style={{ marginLeft: 12 }}>
            <ul>
              <li>
                무료이용기간이 제공된 경우, 멤버십 해지시 무료이용기간이 종료되며, 이후 동일한
                계정으로 가입할 경우, 무료 이용기간이 제공되지 않습니다.
              </li>
              <li>
                이용중인 구독 멤버십이 즉시 해지되며, 유료서비스 이용 제공이 즉시 중단됩니다. 이점을
                유의하셔서 해지준비를 하시기 바랍니다.
              </li>
              <li>
                해지후 15일이내에 멤버십 재 구독시 기존 데이터를 사용하실 수 있습니다. (15일
                이후에는 데이터 복구가 불가능합니다)
              </li>
              <li>해당일로부터 일할 계산되어 환불되어 집니다.</li>
              <li>
                멤버십기간내에 해지시 위약수수료가 발생하며, 환불시 위약수수료를 제한 후 환불해
                드립니다.
              </li>
            </ul>
            {myChannel?.membershipType !== 'FREE' && (
              <>
                {managers && managers?.length > 1 && (
                  <InfoBox style={{ marginTop: 20 }}>
                    현재 총<b> {managers?.length}</b>명의 매니저가 등록되어 있으며, 모든 매니저가
                    삭제됩니다.
                  </InfoBox>
                )}
                <div
                  style={{
                    marginTop: 20,
                    cursor: 'pointer',
                    fontSize: 12,
                    textDecoration: 'underline',
                    fontWeight: 500,
                  }}
                  onClick={handleUnsubscribeMembership}
                >
                  멤버십 해지하기
                </div>
              </>
            )}
          </ExplainStyle>
          <Divider />
        </Collapse>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px 30px;
`;

const ExplainStyle = styled.div`
  padding: 20px;
  > ul {
    list-style-type: disc;
  }
`;
const MainTitle = styled.div`
  color: #505050;
  font-size: 25px;
  font-weight: 600;
`;

const GuideText = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
  font-weight: 400;
  margin-top: 20px;
`;

const Divider = styled.div`
  width: 100%;
  margin-top: 20px;
  border: 1px dashed #bebebe;
`;

const MembershipChangeOption = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-weight: 500;
`;

const UnsubscribeContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.54);
`;

const UnsubscribeText = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 300;
`;

export default MembershipChangeInfo;
