import React from 'react';
import { ToggleButton as MuiToggle, ToggleButtonProps } from '@mui/material';
import { Row } from '../layouts';

const MUIToggle = (props: ToggleButtonProps) => {
  return (
    <MuiToggle
      {...props}
      value={props.value}
      sx={{
        '&.MuiToggleButton-root': {
          borderRadius: '10px !important',
          // padding: '0 20px',
          flexGrow: 1,
          minHeight: 42,
          color: '#AAAAAA',
          stroke: '#AAAAAA',
          fill: '#AAAAAA',
          border: '1px solid #efefef !important',
        },
        '&.Mui-selected': {
          background: '#11C668',
          color: '#F8FFFB !important',
          stroke: '#ffffff',
          fill: '#ffffff !important',
        },
        ...props.style,
      }}
    >
      <Row direction="column">
        <div>{props.children}</div>
        <div>{props.title}</div>
      </Row>
    </MuiToggle>
  );
};

export default MUIToggle;
