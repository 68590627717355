import React, { useEffect, useState } from 'react';
import { Row } from 'components/commons/layouts';

import { IconButton } from '@mui/material';
import { LinkDto, SimpleDealDto } from 'services/data-contracts';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';
import { NameStyle } from 'resources/styles/common/ContextItem';

import useNavigation from 'hooks/useNavigation';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CommonThumbnail from 'features/management/components/commonControl/CommonThumbnail';
import styled from 'styled-components';
import boardCommonLogics from 'features/board/logic/boardCommonLogic';
import { useRootContext } from 'libs/hooks/useRootContext';

/** @description 고객별 문의 쓰레드 */
interface Props {
  item?: LinkDtoWithAssignedDeal;
  useOnlyTitle?: boolean;
  useSimpleInfo?: boolean;
  useContext?: boolean;
  cutOff?: number;
  onClick?: (item?: LinkDtoWithAssignedDeal) => void;
  onClickMore?: () => void;
}

const DealInfo: React.FC<Props> = ({
  item,
  cutOff = 25,
  onClick,
  onClickMore,
  useSimpleInfo,
  useContext,
  useOnlyTitle,
}) => {
  const { getImageFromDom } = boardCommonLogics();
  const { myChannel } = useRootContext();
  const { replace } = useNavigation();

  const [thumbnailUrl, setThumbnailUrl] = useState<string>();

  const handleClickInquiry = () => {
    replace(`/cstown/deal/${item?.id}/inquiries`);
  };

  useEffect(() => {
    if (item) {
      if (!item.thumbnail?.publicUrl) {
        const thumbnail = getImageFromDom(item?.content);
        if (thumbnail) {
          setThumbnailUrl(thumbnail);
        }
      } else {
        setThumbnailUrl(item.thumbnail?.publicUrl);
      }
    }
  }, [item]);

  return (
    <div style={{ width: '100%' }} onClick={() => onClick?.(item)}>
      <Row style={{ width: 'unset', justifyContent: 'space-between', flex: 1 }}>
        <Row
          style={{
            position: 'relative',
            width: 'unset',
            justifyContent: 'flex-start',
          }}
        >
          <CommonThumbnail useAlternative alternativeType="image" initialValue={thumbnailUrl} />
        </Row>
        <Row style={{ justifyContent: 'flex-start', flexGrow: 1, overflow: 'hidden' }}>
          <Row style={{ width: 'unset', marginLeft: 10 }}>
            <Row
              style={{
                justifyContent: 'flex-start',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <div className="item-title" style={{ whiteSpace: 'pre-line' }}>
                <span style={{ color: '#48505D', fontWeight: '500' }}>{item?.title}</span>
              </div>
              <>
                {item?.dealMembers && item?.dealMembers.length > 0 && !useOnlyTitle && (
                  <Row style={{ gap: 10, marginBottom: 5, width: 'unset' }}>
                    {item?.dealMembers?.map((member, i) => (
                      <NameStyle>{member?.member?.name}</NameStyle>
                    ))}
                  </Row>
                )}
              </>
            </Row>
          </Row>
        </Row>
        <>
          {useContext && (
            <IconButton onClick={onClickMore}>
              <MoreVertIcon />
            </IconButton>
          )}
        </>
      </Row>
    </div>
  );
};

const CountStyle = styled.div<{ type?: 'encore' | 'inquiry' | 'name' | undefined }>`
  border-radius: 62.4375rem;
  text-align: left;
  display: flex;
  padding: 0.25rem 0.625rem;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  /* gap: 10px; */
  font-size: 0.6875rem;
  background-color: ${(props) => (props.type === 'encore' ? '#CACACA' : '#96E06E')};
`;

const OnGoingStyle = styled.div<{ status: LinkDto['status'] }>`
  border-radius: 4px;
  padding: 0px 5px;
  min-width: 60px;
  display: inline-block;
  padding: 0.25rem 0.625rem;
  text-align: center;
  font-size: 0.6875rem;
  margin-right: 10px;

  ${(props) => {
    if (props.status === 'ONGOING') {
      return { background: '#0bbd58', color: '#fff' };
    } else if (props.status === 'UPCOMING') {
      return { background: 'rebeccapurple', color: '#fff' };
    } else if (props.status === 'ENDED') {
      return { background: '#848484', color: '#fff' };
    }
  }};
`;
const MoreStyle = styled.div`
  width: 20px;
  height: 20px;
  font-size: 10px;
  background: #173300;
  border-radius: 50px;
  color: white;
  position: absolute;
  top: 0;
  right: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default DealInfo;
