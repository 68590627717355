import useNavigation from 'hooks/useNavigation';
import React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import MembershipManualContainer from './MembershipManualContainer';

const screenWidth = window.innerWidth;

const MembershipManual = () => {
  const { navigate } = useNavigation();
  const [searchParam] = useSearchParams();
  const mode = searchParam.get('mode');

  const handleShowSelllerManual = () => {
    navigate('/my/subscriptions?tab=멤버십 설명서&mode=셀러');
  };
  const handleShowBrandManual = () => {
    navigate('/my/subscriptions?tab=멤버십 설명서&mode=브랜드');
  };

  return (
    <div>
      {!mode && (
        <Wrapper>
          <img src="/images/management/membership/membership_guide_main.svg" alt="" />
          <MainText>
            <span style={{ paddingTop: 4, fontSize: isMobile && screenWidth < 768 ? 22 : 26 }}>
              공구코치
            </span>
            <img
              src="/images/management/membership/membership_moss.svg"
              alt=""
              style={{
                height: isMobile && screenWidth < 768 ? 30 : 40,
                width: isMobile && screenWidth < 768 ? 30 : 40,
              }}
            />
            <span style={{ paddingTop: 4, fontSize: isMobile && screenWidth < 768 ? 22 : 26 }}>
              오모스가
            </span>
          </MainText>
          <MainText style={{ paddingTop: 0 }}>여러분의 공구를 도와드릴께요!</MainText>
          <SelectAccountType style={{ margin: '16px 0px' }}>
            셀러이신가요? 👉 <SelectBtn onClick={handleShowSelllerManual}>클릭</SelectBtn>
          </SelectAccountType>
          <SelectAccountType>
            브랜드 또는 에이전시이신가요? 👉
            <SelectBtn onClick={handleShowBrandManual}>클릭</SelectBtn>
          </SelectAccountType>
        </Wrapper>
      )}

      {mode === '셀러' && <MembershipManualContainer mode="셀러" />}
      {mode === '브랜드' && <MembershipManualContainer mode="브랜드" />}
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const MainText = styled.div`
  display: inline-flex;
  padding: 10px;
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
  font-size: 26px;
  font-weight: 200;
  color: rgba(0, 0, 0, 0.87);
`;

const SelectAccountType = styled.div`
  display: flex;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  flex-direction: row;
  padding-left: 10px;
  align-items: center;
`;

const SelectBtn = styled.div`
  padding: 5px 18px;
  margin-left: 10px;
  font-size: 12px;
  color: #fff;
  border-radius: 20px;
  background: #173300;
`;

export default MembershipManual;
