import React, { useState, useEffect, useRef } from 'react';

import { FileDto } from 'services/data-contracts';
import ReactCrop, { type Crop, centerCrop, makeAspectCrop, PercentCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { OhmossButton } from 'resources/controls/OhmossButton';

import 'react-image-crop/dist/ReactCrop.css';
import TextLabel from 'components/commons/labels/TextLabel';
import { convertBase64Files2, cropImage } from 'components/commons/imageUploader/commonLogin';
import { CustomFile } from 'types/BlockExtendsTypes';
import { Size } from 'react-easy-crop/types';
import { useDebounceEffect } from './useBounceEffect';
import { Row } from 'components/commons/layouts';
import { resizeImage } from 'libs/helper/cropImage';

/**
 * @description 특정 이미지를 크롭후 리사이즈 한다.
 * @param param0
 * @returns
 */
const ImageCropper = ({
  image,
  useCircularCrop,
  onCropComplete,
  onCancel,
}: {
  image?: CustomFile;
  useCircularCrop?: boolean;
  onCropComplete?: (blob: CustomFile) => void;
  onCancel?: () => void;
}) => {
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const [crop, setCrop] = useState<Crop>();
  // const [imageSize, setImageSize] = useState<Size>();
  const [completedCrop, setCompletedCrop] = useState<PercentCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(16 / 9);

  function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        1,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  }
  const onDownloadCropClick = async () => {
    if (typeof previewCanvasRef.current === 'undefined') {
      throw new Error('Crop canvas does not exist');
    }

    if (typeof image === 'undefined') return;

    try {
      const croppedImage = (await cropImage(
        { width: 1200, height: 1200 },
        image,
        completedCrop,
        0,
      )) as CustomFile;

      if (onCropComplete) {
        // const blob = (await resizeImage(croppedImage, { width: 1200, height: 1200 })) as Blob;
        onCropComplete(croppedImage);
      }
    } catch (e) {
      console.error(e);
    }
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    if (aspect) {
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (typeof completedCrop === 'undefined') {
        return;
      }
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
      }
    },
    300,
    [completedCrop, scale, rotate, crop],
  );

  return (
    <div style={{ padding: 20 }}>
      <TextLabel type="content">이미지 선택 영역을 조절할 수 있습니다.</TextLabel>
      <div style={{ margin: 'auto' }}>
        <ReactCrop
          crop={crop}
          aspect={1}
          circularCrop={useCircularCrop}
          onChange={(c) => setCrop(c)}
          onComplete={(c: any, p: PercentCrop) => {
            setCompletedCrop(p);
          }}
        >
          <img ref={imgRef} src={image?.publicUrl} onLoad={onImageLoad} />
        </ReactCrop>
      </div>
      <Row style={{ gap: 15, marginTop: 20 }}>
        <OhmossButton
          onClick={() => {
            if (onCancel) onCancel();
          }}
          type="simple"
        >
          취소
        </OhmossButton>
        <OhmossButton onClick={onDownloadCropClick}>적용하기</OhmossButton>
      </Row>
    </div>
  );
};

export default ImageCropper;
