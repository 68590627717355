import React from 'react';
import { observer } from 'mobx-react';

import LinkBoardType from 'features/adminFeatures/template/components/LinkBoardType';
import CalendarBoardType from 'features/board/containers/CalendarBoardType';
import BoardBase from 'features/board/containers/BoardBase';
import { useChannelContext } from 'features/linkService/context/useChannelContext';
import { PostDto } from 'services/data-contracts';
// import { useLinkServiceContext } from 'features/linkService/context/useLinkServiceContext';

/**
 * @deprecated
 * @description TemplateCommon에서 게시판 타입을 결정하여 렌더링한다.
 */
const TemplateCommon = observer(() => {
  const { posts, links } = useChannelContext();
  const { categoryBoard } = useChannelContext();

  const getBoardType = () => {
    switch (categoryBoard?.boardType) {
      case 'BOARD':
        return <BoardBase posts={posts as PostDto[]} />;
      case 'LINK':
        return <LinkBoardType links={links} />;
      case 'CALENDAR':
        return <CalendarBoardType links={links} />;
      default:
        break;
    }
  };
  return <>{getBoardType()}</>;
});

export default TemplateCommon;
