import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import SocialComponent from 'features/commonFeatures/SocialComponent';
import styled from 'styled-components';
import NoContent from 'components/commons/NoContent';
import { useStore } from 'stores/StoreHelper';

import { LinkDto } from 'services/data-contracts';
import Calendar from '../components/Calendar';

/**
 * @description 실제 링크를 표시하는 컴포넌트
 * @description 시뮬레이터와 사용자링크 페이지에서 함께 사용하므로
 * @description 사이드이펙트에 주의할것.
 */
const CalendarBoardType = observer(({ links }: { links?: Array<LinkDto> }) => {
  const { uiStores, channelStore, uiStore, postStore, socialStore } = useStore();
  const [socialPosition, setSocialPosition] = useState<'top' | 'bottom'>();

  useEffect(() => {
    if (channelStore.channel) {
      // linkStore.getLinks(channelStore.channel.channelName!, channelBoard?.id as number);
    }
  }, [channelStore.channel]);

  useEffect(() => {
    setSocialPosition(uiStores.profileStore.profile?.metadataObject?.socialProps?.position);
  }, [uiStores.profileStore.profile]);

  return (
    <ContainerStyle>
      <div className="global-width" style={{ position: 'relative', zIndex: 1, height: '100%' }}>
        <Calendar posts={postStore.posts} />
      </div>
      {socialPosition === 'bottom' && (
        <div style={{ marginTop: 30 }}>
          <SocialComponent
            socials={socialStore?.socials}
            socialProps={uiStores.profileStore.profile?.metadataObject?.socialProps}
          />
        </div>
      )}
    </ContainerStyle>
  );
});

const ContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  position: relative;
  z-index: 10;
`;
export default CalendarBoardType;
