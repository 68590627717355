import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { Row } from 'components/commons/layouts';
import { IContactBlock } from 'types/BlockExtendsTypes';
import { Avatar } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CommonThumbnail from '../../commonControl/CommonThumbnail';
import { ContentWrapper } from '../styles/ContentWrapperStyle';

const TelLinkTemplate = ({
  item,
  textStyle,
  boxStyle,
  onClick,
  onClickMore,
}: {
  item: IContactBlock;
  textStyle?: CSSProperties;
  boxStyle?: CSSProperties;
  onClick: () => void;
  onClickMore?: () => void;
}) => {
  const ActionButton = ({ style }: { style?: CSSProperties }) => {
    if (item.contactType === 'email') {
      return (
        <div style={style}>
          <div>{item?.title}</div>
          <a href={`mailto:${item.phoneNumber}`}>{item.phoneNumber}</a>
        </div>
      );
    } else {
      return (
        <div style={style}>
          <div>{item?.title}</div>
          <a href={`tel:${item.phoneNumber}`}>{item.phoneNumber}</a>
        </div>
      );
    }
  };

  const Thumbnail = () => {
    if (typeof item.thumbnail?.publicUrl !== 'undefined') {
      return (
        <div style={{ padding: 10 }}>
          <CommonThumbnail
            initialValue={item.thumbnail?.publicUrl}
            parent={boxStyle?.borderRadius}
            style={{
              borderRadius: boxStyle?.borderRadius,
              minHeight: boxStyle?.minHeight,
              minWidth: boxStyle?.minHeight,
            }}
          />
        </div>
      );
    } else if (item.contactType === 'email') {
      return (
        <div style={{ padding: 10 }}>
          <Avatar>
            <MailOutlineIcon />
          </Avatar>
        </div>
      );
    } else {
      return (
        <div style={{ padding: 10 }}>
          <Avatar>
            <PhoneIcon />
          </Avatar>
        </div>
      );
    }
  };

  const getComponent = () => {
    switch (item?.layout) {
      case 'TypeA':
        return (
          <Row style={{ justifyContent: 'flex-start' }}>
            <div style={{ marginRight: item.thumbnail?.publicUrl && 10 }}>
              <div style={{ padding: '5px 0' }}>
                {item.thumbnail?.publicUrl ? (
                  <CommonThumbnail
                    parent={boxStyle?.borderRadius}
                    initialValue={item.thumbnail?.publicUrl}
                    style={{
                      borderRadius: boxStyle?.borderRadius,
                      minHeight: boxStyle?.minHeight,
                      minWidth: boxStyle?.minHeight,
                    }}
                  />
                ) : (
                  <Thumbnail />
                )}
              </div>
            </div>
            <ContentWrapper
              style={{
                padding: !item.thumbnail?.publicUrl ? '10px' : 0,
                alignItems:
                  item?.contentAlign === 'left'
                    ? 'flex-start'
                    : item?.contentAlign === 'right'
                    ? 'flex-end'
                    : 'center',
              }}
            >
              <div
                style={{
                  ...textStyle,
                  textAlign: item?.contentAlign as 'left' | 'center' | 'right',
                }}
              >
                <ActionButton />
              </div>
            </ContentWrapper>
          </Row>
        );
      case 'TypeB':
        return (
          <Row style={{ justifyContent: 'center' }}>
            <>
              <div style={{ padding: '5px 0' }}>
                {item.thumbnail?.publicUrl ? (
                  <CommonThumbnail
                    parent={boxStyle?.borderRadius}
                    initialValue={item.thumbnail?.publicUrl}
                    style={{
                      borderRadius: boxStyle?.borderRadius,
                      minHeight: boxStyle?.minHeight,
                      minWidth: boxStyle?.minHeight,
                    }}
                  />
                ) : (
                  <Thumbnail />
                )}
              </div>
            </>
            <ContentWrapper
              style={{
                alignItems:
                  item?.contentAlign === 'left'
                    ? 'flex-start'
                    : item?.contentAlign === 'right'
                    ? 'flex-end'
                    : 'center',
                padding: '10px',
              }}
            >
              <div style={{ ...textStyle }}>
                <ActionButton />
              </div>
            </ContentWrapper>
          </Row>
        );
      case 'TypeC':
        return (
          <Row style={{ width: '100%' }}>
            <ContentWrapper
              style={{
                padding: !item.thumbnail?.publicUrl ? '10px' : 0,
                alignItems:
                  item?.contentAlign === 'left'
                    ? 'flex-start'
                    : item?.contentAlign === 'right'
                    ? 'flex-end'
                    : 'center',
              }}
            >
              <div style={{ ...textStyle }}>
                <ActionButton
                  style={{
                    alignItems: item?.contentAlign,
                    marginRight: 10,
                  }}
                />
              </div>
            </ContentWrapper>
            <div style={{ marginRight: item.thumbnail?.publicUrl && 10 }}>
              <div style={{ padding: '5px 0' }}>
                {item.thumbnail?.publicUrl ? (
                  <CommonThumbnail
                    parent={boxStyle?.borderRadius}
                    initialValue={item.thumbnail?.publicUrl}
                    style={{
                      borderRadius: boxStyle?.borderRadius,
                      minHeight: boxStyle?.minHeight,
                      minWidth: boxStyle?.minHeight,
                    }}
                  />
                ) : (
                  <Thumbnail />
                )}
              </div>
            </div>
          </Row>
        );
      default:
        return (
          <Row style={{ justifyContent: 'flex-start' }}>
            <div style={{ padding: '5px 0' }}>
              {item.thumbnail?.publicUrl ? (
                <CommonThumbnail
                  parent={boxStyle?.borderRadius}
                  initialValue={item.thumbnail?.publicUrl}
                  style={{
                    borderRadius: boxStyle?.borderRadius,
                    minHeight: boxStyle?.minHeight,
                    minWidth: boxStyle?.minHeight,
                  }}
                />
              ) : (
                <Thumbnail />
              )}
            </div>
            <ContentWrapper
              style={{
                alignItems:
                  item?.contentAlign === 'left'
                    ? 'flex-start'
                    : item?.contentAlign === 'right'
                    ? 'flex-end'
                    : 'center',
              }}
            >
              <div style={{ textAlign: 'center', ...textStyle }}>
                <ActionButton />
              </div>
            </ContentWrapper>
          </Row>
        );
    }
  };
  return <ContainerStyle onClick={onClick}>{getComponent()}</ContainerStyle>;
};

const ContainerStyle = styled.div`
  justify-content: center;
  cursor: pointer;
  width: 100%;
  min-height: 90px;
  display: flex;
  padding: 0px 10px;
`;

const TitleStyle = styled.div`
  font-size: 16px;
`;
export default TelLinkTemplate;
