import React, { useState, useEffect } from 'react';
import { HexColorPicker } from 'react-colorful';
import { TextField, Slider } from '@mui/material';
import { observer } from 'mobx-react';
import { Row } from 'components/commons/layouts';
import { colorGroups } from 'resources/definitions/Definitions';
import styled from 'styled-components';

const SolidColorPicker = observer(
  ({
    defaultColor,
    onColorChanged,
  }: {
    defaultColor?: string;
    onColorChanged: (color: string) => void;
  }) => {
    const [hexColor, setHexColor] = useState<string>();

    useEffect(() => {
      if (typeof defaultColor !== 'undefined') {
        setHexColor(defaultColor);
      }
    }, [defaultColor]);

    return (
      <>
        <HexColorPicker
          color={hexColor}
          style={{ margin: 'auto' }}
          onChange={(color: string) => {
            setHexColor(color);
            onColorChanged(color);
          }}
        />
        <Row style={{ marginTop: 20 }}>
          <TextField
            value={hexColor || ''}
            label="HEX code"
            style={{ width: '100%' }}
            onChange={(e: any) => {
              setHexColor(e.target.value);
              onColorChanged(e.target.value);
            }}
          />
        </Row>
        <Row style={{ flexWrap: 'wrap' }}>
          {colorGroups.map((color: string) => (
            <div style={{ position: 'relative' }}>
              {/* {backgroundModel?.backgroundColor === color && (
                <CheckIconStyle>
                  <CheckIcon sx={{ color: '#fff', width: 40, height: 40 }} />
                </CheckIconStyle>
              )} */}
              <ColorStyle
                useHover
                style={{
                  overflow: 'hidden',
                  background: color.toUpperCase() !== 'TRANSPARENT' ? color : '#fff',
                  margin: '5px 0',
                  border:
                    color === '#ffffff' || color.toUpperCase() === 'TRANSPARENT'
                      ? '1px solid #e2e2e2'
                      : '1px solid transparent',
                }}
                onClick={() => {
                  setHexColor(color);
                  onColorChanged(color);
                }}
              >
                {color.toUpperCase() === 'TRANSPARENT' && (
                  <img
                    src="/images/transparent.png"
                    style={{ width: '100%', height: '100%' }}
                    alt=""
                  />
                )}
              </ColorStyle>
            </div>
          ))}
        </Row>
      </>
    );
  },
);

const ColorStyle = styled.div<{ selected?: boolean; useHover?: boolean; }>`
  width: 50px;
  height: 50px;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  @media ${(props) => props.theme.media.mobile} {
    width: 50px;
    height: 50px;
  }
  &:hover {
    transition: all 0.1s ease-in-out;
    transform: ${(props) => (props.useHover ? 'scale(1.1)' : 'none')};
  }
`;

const CheckIconStyle = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  align-content: center;
  align-items: center;
`;
export default SolidColorPicker;
