import { makeObservable, flow, observable, action } from 'mobx';
import { Channel as ChannelService } from 'services/Channel';
import {
  ChannelBoard,
  ChannelBoardDto,
  ChannelDto,
  ChannelStyleDto,
  ProposalDto,
} from 'services/data-contracts';
import { HttpClient } from 'services/http-client';

import { ChannelConfigModel, ChannelModel } from 'types/CommonTypes';
import { parseJSONAll } from 'libs/helper/utils';
import ServiceStoreBase from './ServiceStoreBase';

class ChannelStore extends ServiceStoreBase {
  channels: Array<ChannelDto> | undefined;

  channel: ChannelModel;

  channelBoards: Array<ChannelBoardDto> = [];

  myStyle: ChannelStyleDto;

  constructor() {
    super();
    makeObservable(this, {
      channels: observable,
      channel: observable,
      channelBoards: observable,
      myStyle: observable,
      getChannel: flow,
      searchChannels: flow,
      getChannelPolicies: flow,
      duplicateChannelName: flow,
      getChannelBoards: flow,
      getMyStyle: flow,
      setMyStyle: action,
      unMount: action,
      createProposal: flow,
      getChannelProposalType: flow,
      searchBrandChannels: flow,
      searchSellerChannels: flow,
    });
  }

  api: ChannelService<unknown> = new ChannelService(new HttpClient());

  *getChannel(channelName: string, myChannelId?: number) {
    const { response } = yield this.api.getChannel1(channelName, { myChannelId });

    if (response.status === 200) {
      const newChannel: ChannelModel = { ...response.data };

      if (response.data?.channelConfig?.metadata) {
        const channelConfig: ChannelConfigModel = response.data.channelConfig as ChannelConfigModel;
        channelConfig.metadataObject = JSON.parse(response.data.channelConfig.metadata);
        newChannel.channelConfig = channelConfig;
      }

      if (response.data.profile?.metadata) {
        let parsedProfile: any = {};

        parsedProfile = parseJSONAll(response.data.profile?.metadata);
        const metadataObject = JSON.parse(response.data.profile.metadata);
        newChannel.metadataObject = metadataObject;
        const mergedProfile = {
          ...newChannel,
          profile: { ...response.data.profile, ...parsedProfile },
        };
        this.channel = mergedProfile;
        return mergedProfile;
      } else {
        this.channel = newChannel;
        return newChannel;
      }
    } else {
      return null;
    }
  }

  *getChannelPolicies(channelName: string) {
    // const { response } = yield this.api.getpr(channelName);
    // return response.data as PersonalInfoPolicyDto;
  }

  *duplicateChannelName(channelName: string) {
    const { response } = yield this.api.checkDuplicateChannelName(encodeURIComponent(channelName));
    return response.status !== 400;
  }

  *searchChannels(name: string, channelId: number, query?: any) {
    const { response } = yield this.api.getChannels1({
      searchText: name,
      myChannelId: channelId,
      ...query,
    });
    this.channels = response.data;
  }

  *searchBrandChannels(name: string, channelId: number) {
    const { response } = yield this.api.getChannels1({ searchText: name, myChannelId: channelId });
    this.channels = response.data.filter((channel: ChannelDto) => channel.channelType === 'BRAND');
    return response.data.filter((channel: ChannelDto) => channel.channelType === 'BRAND');
  }

  *searchSellerChannels(name: string, channelId: number) {
    const { response } = yield this.api.getChannels1({ searchText: name, myChannelId: channelId });
    this.channels = response.data.filter((channel: ChannelDto) => channel.channelType === 'SELLER');
    return response.data.filter((channel: ChannelDto) => channel.channelType === 'SELLER');
  }

  *getChannelBoards(channelName: string) {
    const { response } = yield this.api.getBoards1(channelName);
    // 광고 보드가 있는경우, 맨 앞으로 보낸다.
    // const adBoard = response.data.findIndex((board: ChannelBoardDto) => board.isDefault === true);
    // if (adBoard >= 0) {
    //   const tempBoard = response.data;
    //   const t = response.data[adBoard];
    //   tempBoard.splice(adBoard, 1);
    //   tempBoard.unshift(t);
    //   this.channelBoards = tempBoard;
    //   return tempBoard;
    // } else {
    if (response.status >= 200 && response.status < 300) {
      const tempBoards = (response.data as unknown as ChannelBoardDto[])?.filter(
        (board) => board.boardType === 'LINK',
      );

      this.channelBoards = tempBoards;
      // return response.data.sort((a: any, b: any) => a.seq! - b.seq!);
      return tempBoards?.sort((a: any, b: any) => a.seq! - b.seq!);
    } else {
      this.channelBoards = [];
      return [];
    }
    // }
  }

  *getMyStyle(channelName: string) {
    const { response } = yield this.api.getChannelStyle(channelName, 'link');
    this.myStyle = response.status === 200 ? response.data : undefined;
    return response.status === 200 ? response.data : undefined;
  }

  *createProposal(channelName: string, data: ProposalDto, ownChannelId: number) {
    const { response } = yield this.api.propose(channelName, { ownChannelId }, data);
    return response.status < 300 ? response : undefined;
  }

  *getChannelProposalType(channelName: string) {
    const { response } = yield this.api.getChannelProposalType(channelName);
    return response.status < 300 ? response.data : undefined;
  }

  setMyStyle(myStyle: string) {
    this.myStyle = { style: myStyle };
  }

  unMount() {
    this.channels = undefined;
  }
}

export default ChannelStore;
