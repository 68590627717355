import AuthStore from 'stores/AuthStore';
import FileStore from 'stores/FileStore';
import LinkStore from 'stores/LinkStore';
// import MeStore from 'stores/MeStore';
import ParameterStore from 'stores/ParameterStore';
// import TemplateStore from 'stores/TemplateStore';
// import UserStore from 'stores/UserStore';
// import ProfileStore from 'stores/uiStore/ProfileStore';
import FormValidationStore from './FormValidationStore';
import FollowStore from './FollowStore';
import UIStore from './UIStore';
// import SimulatorStore from './SimulatorStore';
// import PaymentStore from './PaymentStore';
// import ListingStore from './ListingStore';
import PushStore from './PushStore';
import MyTagStore from './MyTagStore';
import TagStore from './TagStore';
import MyChannelStore from './MyChannelStore';
import ChannelStore from './ChannelStore';
import FriendshipStore from './FriendshipStore';
import MyLinkStore from './MyLinkStore';
import MySocialStore from './MySocialStore';
import SocialStore from './SocialStore';
// import MyFeedStore from './MyFeedStore';
// import FeedStore from './FeedStore';
import PostStore from './PostStore';
import MyPostStore from './MyPostStore';
import MyChannelConfigStore from './MyChannelConfigStore';
import TermStore from './TermStore';
import AdStore from './AdStore';

import UIRootStore from './uiStore/UIRootStore';
import UserHomeStore from './uiStore/UserHomeStore';
import FreeImageStore from './FreeImageStore';
import ICalStore from './ICalStore';
import ChannelBoardStore from './ChannelBoardStore';
import MyBoardStore from './MyBoardStore';
import BoardConfigStore from './BoardConfigStore';
import LogStore from './LogStore';
import MyChannelStyleStore from './MyChannelStyleStore';
import MyStore from './MyStore';
import InquiryStore from './InquryStore';
import MyNotificationStore from './MyNotificationStore';
import HistoryStore from './HistoryStore';
import MyAdStore from './MyAdStore';
import AdCategoryStore from './AdCategoryStore';
import AdminServiceStore from './AdminServiceStore';
import MyCoinStore from './MyCoinStore';
import AIStore from './AIStore';
import UILinkStore from './UILinkStore';
import XHRStore from './XHRStore';
import ProposalConfigServiceStore from './ProposalConfigServiceStore';
import PartnerInvitationStore from './PartnerInvitationStore';
import PartnerStore from './PartnerStore';
import PartnershipStore from './PartnershipStore';
import ChecklistStore from './ChecklistStore';

const RootStore = {
  fileStore: new FileStore(),
  linkStore: new LinkStore(),
  // meStore: new MeStore(),
  parameterStore: new ParameterStore(),
  // templateStore: new TemplateStore(),
  // userStore: new UserStore(),
  formValidationStore: new FormValidationStore(),
  followStore: new FollowStore(),
  uiStore: new UIStore(),
  // simulatorStore: new SimulatorStore(),
  // paymentStore: new PaymentStore(),
  // listingStore: new ListingStore(),
  pushStore: new PushStore(),
  myTagStore: new MyTagStore(),
  tagStore: new TagStore(),
  myChannelStore: new MyChannelStore(),
  channelStore: new ChannelStore(),
  friendshipStore: new FriendshipStore(),
  myLinkStore: new MyLinkStore(),
  mySocialStore: new MySocialStore(),
  socialStore: new SocialStore(),
  // profileStore: new ProfileStore(),
  // myFeedStore: new MyFeedStore(),
  // feedStore: new FeedStore(),
  postStore: new PostStore(),
  myPostStore: new MyPostStore(),
  termStore: new TermStore(),
  myChannelConfigStore: new MyChannelConfigStore(),
  userHomeStore: new UserHomeStore(),
  freeImageStore: new FreeImageStore(),
  myStore: new MyStore(),
  iCalStore: new ICalStore(),
  channelBoardStore: new ChannelBoardStore(),
  myBoardStore: new MyBoardStore(),
  boardConfigStore: new BoardConfigStore(),
  logStore: new LogStore(),
  myChannelStyleStore: new MyChannelStyleStore(),
  uiStores: UIRootStore,
  inquryStore: new InquiryStore(),
  myNotificationStore: new MyNotificationStore(),
  historyStore: new HistoryStore(),
  adStore: new AdStore(),
  myAdStore: new MyAdStore(),
  adCategoryStore: new AdCategoryStore(),
  adminServiceStore: new AdminServiceStore(),
  myCoinStore: new MyCoinStore(),
  aiStore: new AIStore(),
  uiLinkStore: new UILinkStore(),
  xhrStore: new XHRStore(),
  ProposalConfigServiceStore: new ProposalConfigServiceStore(),
  PartnerInvitationStore: new PartnerInvitationStore(),
  PartnerStore: new PartnerStore(),
  PartnershipStore: new PartnershipStore(),
  checklistStore: new ChecklistStore(),
  authStore: new AuthStore(),
};

export default RootStore;
