import React, { useState, useEffect, memo } from 'react';
import styled from 'styled-components';
import { Row } from 'components/commons/layouts';
import { ICalendarBlock, IContactItem } from 'types/BlockExtendsTypes';
import moment from 'moment';
import CommonCalendar from '../../commonControl/CommonCalendar';
import Text from 'components/commons/Text';
import { useStore } from 'stores/StoreHelper';
import CommonCalendarItem from '../../commonControl/CommonCalendarItem';

const CalendarTemplate = ({
  item,
  onClick,
  onClickMore,
}: {
  item: ICalendarBlock;
  onClick: () => void;
  onClickMore?: () => void;
}) => {
  const { uiStore } = useStore();
  const [calendarType, setCalendarType] = useState<ICalendarBlock['displayType']>();
  const [events, setEvents] = useState<any>([]);

  const handleClickEvent = (info: IContactItem) => {
    const event = {
      title: info?.title,
      alldayEvent: info.alldayEvent,
      origin: { content: info?.content },
    };

    uiStore.universalModal.show({
      title: '이벤트 상세보기',
      modalOption: { style: { height: '60%', textAlign: 'center' } },
      children: <CommonCalendarItem event={event} />,
    });
  };

  useEffect(() => {
    if (item) {
      const eventItems = item.items?.map((item: IContactItem) => {
        return {
          allDay: item.alldayEvent ?? false,
          alldayEvent: item.alldayEvent ?? false,
          title: item.title,
          start: item.startDate,
          end: item.endDate,
          id: item.id,
          created: item.createDate,
          origin: item,
        };
      });
      setEvents(eventItems);

      setCalendarType(item.displayType);
    }
  }, [item]);

  return (
    <ContainerStyle>
      <div style={{ textAlign: item.contentAlign as 'left' | 'right' | 'center' }}>
        {item?.title}
      </div>
      <div onClick={onClick} style={{}}>
        {calendarType === 'calendar' ? (
          <div>
            <CommonCalendar events={events} />
          </div>
        ) : (
          <>
            {item.items?.map((item: IContactItem) => (
              <EventStyle key={item?.id}>
                <Row style={{ justifyContent: 'space-between' }}>
                  <BoxStyle>
                    <Text type="H4">{item.title}</Text>
                    <div style={{ fontSize: 12, color: '#333' }}>
                      {item?.alldayEvent === true ? (
                        <Row style={{ justifyContent: 'flex-start', gap: 10 }}>
                          <Text buttonRole="DESCRIPTION">
                            {moment(item.startDate).format('YYYY-MM-DD')}
                          </Text>
                          <AlldayStyle>하루종일</AlldayStyle>
                        </Row>
                      ) : (
                        <>
                          {moment(item.startDate).format('YYYY-MM-DD hh:mm')} ~{' '}
                          {moment(item.endDate).format('YYYY-MM-DD hh:mm')}
                        </>
                      )}
                    </div>
                  </BoxStyle>
                </Row>
                <DetailStyle
                  onClick={() => {
                    handleClickEvent(item);
                  }}
                >
                  <Text type="H4" style={{ fontSize: 14 }}>
                    이벤트 상세보기
                  </Text>
                </DetailStyle>
              </EventStyle>
            ))}
          </>
        )}
      </div>
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  /* background: #fff; */
  /* border-radius: 10px; */
  position: relative;
  z-index: 0;
  width: 100%;
  min-height: 90px;
  padding: 10px;
`;

const EventStyle = styled.div`
  cursor: pointer;
  margin: 20px 0;
  border: 1px solid #efefef;
  border-left: 3px solid rebeccapurple;
  border-radius: 5px;
  background: #fff;
`;

const BoxStyle = styled.div`
  border-radius: 5px;
  padding: 10px;
  flex: 1;
  margin-right: 15px;
`;

const DetailStyle = styled.div`
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px;
  margin: 10px;
  text-align: center;
`;

const AlldayStyle = styled.div`
  border-radius: 10px;
  background: rebeccapurple;
  font-size: 12px;
  color: #fff;
  padding: 3px 10px;
`;
export default CalendarTemplate;
