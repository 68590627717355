import React, { useRef, useState } from 'react';
import { CHAT_GPT_KEY } from 'stores/AppStore';
import { Button, TextField } from '@mui/material';
import styled from 'styled-components';
import OpenAI from 'openai';
import AIStore from 'stores/AIStore';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';

const Chatbot = observer(() => {
  const { aiStore } = useStore();
  const [input, setInput] = useState('');
  const [response, setResponse] = useState('');
  const responseRef = useRef<any>();

  async function handleSubmit() {
    setInput('');
    aiStore.chat(input);
  }

  return (
    <ContainerStyle>
      <div>
        <div style={{ flex: 1 }}>
          <TitleStyle>oh!moss AI</TitleStyle>
        </div>
        <div style={{ width: '100%' }}>
          <TextField
            sx={{ width: '100%' }}
            type="text"
            value={input}
            onChange={(event) => setInput(event.target.value)}
          />
        </div>
        <Button
          sx={{ width: '100%', marginTop: 1, marginBottom: 2 }}
          variant="contained"
          onClick={handleSubmit}
        >
          Send
        </Button>
        <div style={{ padding: 20 }} ref={responseRef}>
          {aiStore.messages}
        </div>
      </div>
    </ContainerStyle>
  );
});

const ContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  padding: 10px;
`;

const TitleStyle = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 50px;
`;
export default Chatbot;
