import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { AdDto, PostItemDto, PostTagDto } from 'services/data-contracts';
import { stripedSting } from 'components/items/commonLogic';

import styled from 'styled-components';
import Countdown from 'react-countdown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TextLabel from 'components/commons/labels/TextLabel';

const MobileAdPostItem3 = observer(
  ({
    item,
    onClickVisit,
    onClickTag,
  }: {
    item: AdDto;
    onClickVisit?: () => void;
    onClickTag: (tag: PostTagDto) => void;
  }) => {
    const handleTimerComplete = () => { };

    const handleClickOnVisit = () => {
      if (onClickVisit) onClickVisit();
    };

    return (
      <FeedContainerStyle>

        <FeedItemStyle onClick={handleClickOnVisit}>
          <img src={item.post?.items?.[0]?.file?.publicUrl} />
          <div style={{
            padding: '3px 10px', position: 'absolute',
            margin: 10,
            borderRadius: '20px',
            background: 'rgba(0,0,0,0.3)',
            top: 0,
            zIndex: 1,
          }}>
            <Countdown
              daysInHours
              date={item.endDate}
              onComplete={() => {
                handleTimerComplete();
              }}
            />
          </div>
        </FeedItemStyle>
        <div style={{ margin: '10px 0', paddingLeft: 5 }}>
          <TextLabel type="content" style={{ color: '#4C4C4C', }}>
            {stripedSting(item.post?.title, 15)}
          </TextLabel>
        </div>
      </FeedContainerStyle>
    );
  },
);

const FeedContainerStyle = styled.div`
  width:calc(50% - 10px);
`;

const FeedItemStyle = styled.div<{ url?: string; }>`
  overflow: hidden;
  position:relative;
  border-top: 1px solid #efefef;
  /* max-width: calc(50% - 10px);
  min-width: calc(50% - 10px); */
  
  align-items: center;
  border-radius: 12px;
  
  > div > span {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
  }
  > img {
    display: block;
    object-fit: contain;
    padding:0;
    margin:0;
  }
`;

const GradiendStyle = styled.div`
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  top: 0%;
  left: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default MobileAdPostItem3;
