import React, { useEffect, useState } from 'react';
import { SimpleDealDto, SimpleLinkDto } from 'services/data-contracts';
import { observer } from 'mobx-react-lite';
import { ListItemTemplate } from 'features/csTown/commonComponents/styles';

import { useStateContext } from 'libs/hooks/usePageState';
import { MyDealState } from 'types/CommonTypes';
import DealBox from '../DealBox';

const PCDealList = observer(
  ({
    deals,
    onClick,
  }: {
    deals: SimpleDealDto[] | undefined;
    onClick?: (deal: SimpleLinkDto) => void;
  }) => {
    const { setMultipleState, stateModel } = useStateContext<MyDealState>();

    const [selected, setSelected] = useState<number>();

    useEffect(() => {
      if (typeof stateModel?.selectedDeal === 'undefined') {
        setSelected(undefined);
      } else {
        setSelected(stateModel?.selectedDeal?.id);
      }
    }, [stateModel?.selectedDeal]);

    return (
      <>
        <div>
          {deals?.map((deal) => (
            <ListItemTemplate
              selected={selected === deal?.id}
              style={{ padding: 0 }}
              key={deal?.id}
            >
              <div
                onClick={() => {
                  if (onClick) {
                    onClick(deal);
                  } else {
                    setSelected(deal?.id);
                    setMultipleState({ selectedDeal: deal, pageMode: 'VIEW' });
                    window.history.replaceState('', '', `/cstown/deals/${deal?.linkKey}`);
                  }
                }}
              >
                <DealBox selected={selected === deal?.id} deal={deal} />
              </div>
            </ListItemTemplate>
          ))}
        </div>
      </>
    );
  },
);

export default PCDealList;
