import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { stripedSting } from 'components/items/commonLogic';
import { Row } from 'components/commons/layouts';
import { PostDto, PostTagDto } from 'services/data-contracts';
import { OhmossButton } from 'resources/controls/OhmossButton';

import styled from 'styled-components';
import TextLabel from 'components/commons/labels/TextLabel';
import useNavigation from 'libs/hooks/useNavigate';
import { IPostModel } from 'types/BlockExtendsTypes';
import PostSwipeItem from './PostSwipeItem';

const MyShopDetailModal = observer(
  ({
    post,
    isPreview,
    onClickVisit,
  }: {
    post: IPostModel;
    isPreview?: boolean;
    isOwner: boolean;
    onClickVisit?: () => void;
    onClickTag?: (tag: PostTagDto) => void;
  }) => {
    const navigateToLink = useNavigation();
    const [goDirectToLink, setGoDirectToLink] = useState<boolean>(false);
    const [cutstringLength, setCutStringLength] = useState<number>(80);

    const handleClickVisit = () => {
      if (post?.openNewWindow) {
        navigateToLink(post.linkUrl, true);
      } else {
        navigateToLink(post.linkUrl);
      }
    };

    const StrippedString = () => {
      const string = stripedSting(post?.content);
      if (string) {
        if (string.length > cutstringLength) {
          return (
            <div>
              {string.substring(0, cutstringLength)}...{' '}
              <span
                onClick={() => {
                  setCutStringLength(10000);
                }}
                style={{ color: '#BEBEBE' }}
              >
                더보기
              </span>
            </div>
          );
        }
      }
      return <div>{string}</div>;
    };

    return (
      <>
        <FeedItemStyle>
          <Row style={{ justifyContent: 'space-between' }} className="py-2" />
          <div onClick={onClickVisit}>
            <PostSwipeItem items={post?.items} />
          </div>
          <div>
            <div>
              <TextLabel type="subject" style={{ margin: '10px 0', padding: 20 }}>
                {post?.title}
              </TextLabel>
              {post?.content && (
                <div style={{ padding: 20 }}>
                  {isPreview ? (
                    <TextLabel type="content" style={{ color: '#4C4C4C', fontSize: 14 }}>
                      <StrippedString />
                    </TextLabel>
                  ) : (
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: post?.content as string,
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            {post?.linkUrl && (
              <div style={{ padding: 20 }}>
                <OhmossButton onClick={handleClickVisit}>방문하기</OhmossButton>
              </div>
            )}
          </div>
        </FeedItemStyle>
      </>
    );
  },
);

const FeedItemStyle = styled.div`
  border-top: 1px solid #efefef;
  width: 100%;
  background: #fff;
  padding-bottom: 40px;
`;

export default MyShopDetailModal;
