import React, { useState, useEffect } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Row } from 'components/commons/layouts';
import { TextField, Slider } from '@mui/material';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import 'swiper/css/pagination';
import 'swiper/css';
import { gradientColorSets } from 'resources/definitions/Definitions';
import { OhmossButton } from 'resources/controls/OhmossButton';

const GradientColorPicker = observer(
  ({
    defaultColor,
    onColorChanged,
  }: {
    defaultColor?: string;
    onColorChanged: (color: string) => void;
  }) => {
    const [hexColor, setHexColor] = useState<string>('#ffcc70');
    const [index, setIndex] = useState(0);
    const [colors, setColors] = useState(['#ffcc70', '#c850c0']);
    const [angle, setAngle] = useState<string>('160');

    const colorsString = colors.join(', ');
    const backgroundImage = `linear-gradient(${angle}deg, ${colorsString})`;

    const decomposeLinearGradient = (value: string) => {
      const match = value.match(/(\d+deg)/);
      if (match) {
        setAngle(parseInt(match[0], 10).toString());

        const rgbValues: string[] =
          value.match(/rgb\(\s*([0-9]+)\s*,\s*([0-9]+)\s*,\s*([0-9]+)\s*\)/g) || [];

        const hexValues: string[] = rgbValues.map((rgb) => {
          const [r, g, b]: number[] = rgb.match(/\d+/g)?.map(Number) || [];
          return `#${[r, g, b].map((v) => v.toString(16).padStart(2, '0')).join('')}`;
        });

        if (hexValues.length > 0) {
          setColors(hexValues);
          setHexColor(hexValues[0]);
        } else {
          const regex2 = /linear-gradient\((\d+deg), (#[A-Fa-f0-9]{6}), (#[A-Fa-f0-9]{6})\)/;
          const match = value.match(regex2);
          if (match) {
            setColors([match[2], match[3]]);
            setHexColor(match[2]);
          }
        }
      } else {
        return null;
      }
    };

    const changeColor = (color: string) => {
      setHexColor(color);
      const colorsCopy = colors.slice();
      colorsCopy[index] = color;
      setColors(colorsCopy);
    };

    useEffect(() => {
      if (typeof defaultColor !== 'undefined') {
        decomposeLinearGradient(defaultColor);
      }
    }, [defaultColor]);

    useEffect(() => {
      if (angle && colorsString) onColorChanged(backgroundImage);
    }, [backgroundImage]);

    return (
      <>
        <PickerButtonStyle>
          <HexColorPicker
            color={colors[index]}
            style={{ margin: 'auto' }}
            onChange={(color: string) => {
              changeColor(color);
            }}
          />

          <div style={{ display: 'flex', marginTop: 20 }}>
            <PreviewStyle style={{ backgroundImage }} />

            {colors.map((color, i) => (
              <div
                key={i}
                className={`color color--${i} ${i === index ? 'color--active' : ''}`}
                style={{ backgroundColor: color, width: 30 }}
                onClick={() => {
                  setIndex(i);
                  setHexColor(colors[i]);
                }}
              />
            ))}
          </div>
          <Row style={{ marginTop: 20 }}>
            <div style={{ flex: 1 }}>
              <TextField
                value={hexColor || ''}
                sx={{ flex: 1 }}
                label="HEX code"
                onChange={(e: any) => {
                  changeColor(e.target.value);
                }}
              />
            </div>

            <div style={{ flex: 0.7, marginRight: 10, marginLeft: 10 }}>
              <div style={{ fontSize: 12, textAlign: 'left' }}>Angle</div>
              <Slider
                sx={{ marginLeft: 1 }}
                value={Number(angle) || 0}
                min={0}
                max={360}
                onChange={(e: any, value: number) => {
                  setAngle(value.toString());
                }}
                aria-label="Default"
                valueLabelDisplay="auto"
              />
            </div>
          </Row>
        </PickerButtonStyle>
        <div style={{ margin: '20px 0' }}>
          <Swiper spaceBetween={5} slidesPerView={1.2}>
            {gradientColorSets.map((color: any, index: number) => (
              <SwiperSlide key={index} style={{ width: '100%' }}>
                <Row
                  direction="column"
                  style={{ padding: 10, overflow: 'hidden', borderRadius: 10 }}
                >
                  <div
                    style={{
                      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.22)',
                      width: '100%',
                      overflow: 'hidden',
                      borderRadius: 10,
                    }}
                  >
                    <GradientBoxStyle
                      style={{
                        backgroundImage: `linear-gradient(${angle}deg, ${color.from}, ${color.to})`,
                      }}
                    />
                    <Row style={{ fontSize: 14, margin: '10px 0' }}>
                      {/* <div>시작 : {color.from}</div>
                    <div>끝 : {color.to}</div> */}
                      <OhmossButton
                        onClick={() => {
                          setHexColor(color.from);
                          setColors([color.from, color.to]);
                        }}
                        style={{ padding: '6px 20px' }}
                      >
                        선택
                      </OhmossButton>
                    </Row>
                  </div>
                </Row>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </>
    );
  },
);

const PreviewStyle = styled.div`
  height: 32px;
  border-radius: 8px;
  flex-grow: 1;
  margin: 0 8px;
  border: 3px solid #fff;
`;

const PickerButtonStyle = styled.div`
  width: 100%;
  position: relative;
`;

const GradientBoxStyle = styled.div`
  width: 100%;
  height: 100px;

  /* width: 400px;
  aspect-ratio: 1; */
`;

export default GradientColorPicker;

//https://codesandbox.io/s/react-colorful-gradient-qovjy
