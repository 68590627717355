import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

export default function useMobileLayout(containerRef: any) {
  const setSize = () => {
    if (isMobile) {
      const content = document.querySelector('.management-layout') as any;

      const observer = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const { height } = entry.contentRect;
          if (height + 100 > window.innerHeight) {
            content.style.marginBottom = '130px';
            window.scrollTo(0, 1);
          } else {
            content.style.marginBottom = '-10px';
            window.scrollTo(0, 0);
          }
        }
      });

      if (containerRef.current) {
        observer.observe(containerRef.current);
      }

      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
          if (content) content.style.marginBottom = '10px';
        }
      };
    }
  };
  useEffect(() => {
    setSize();
  }, [location.href]);

  return { setSize };
}
