import React from 'react';
import styled from 'styled-components';

import { Row } from './layouts';
import { IconButton, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const BottomSheetItem = ({
  children,
  label,
  onClick,
}: {
  // eslint-disable-next-line @typescript-eslint/ban-types
  children: React.ReactElement;
  label: string;
  onClick: () => void;
}) => {
  const Children = () => {
    return React.cloneElement(children, {
      style: { color: '#fff' },
    });
  };
  return (
    <ShareMoreStyle onClick={onClick} style={{ justifyContent: 'flex-start' }}>
      <IconButton style={{ background: '#4E4848' }}>
        <Children />
      </IconButton>
      <div style={{ marginLeft: 10 }}>{label}</div>
    </ShareMoreStyle>
  );
};

const ShareMoreStyle = styled(Row)`
  margin-bottom: 20px;
`;
export default BottomSheetItem;
