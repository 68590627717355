import React, { CSSProperties } from 'react';

import { MemberDto } from 'services/data-contracts';
import { Avatar } from '@mui/material';

import styled from 'styled-components';
import { Row } from 'components/commons/layouts';
import Text from 'components/commons/Text';
import DeleteIcon from 'features/csTown/commonComponents/DeleteIcon';
import { OhmossButton } from 'resources/controls/OhmossButton';

const ManagerListItem = ({
  member,
  type,
  assigned,
  style,
  onAdd,
  onDelete,
}: {
  member?: MemberDto;
  type: 'VIEW' | 'EDIT' | 'ADD';
  assigned?: boolean;
  style?: CSSProperties;
  onAdd?: () => void;
  onDelete?: () => void;
}) => {
  return (
    <ManagerStyle style={style}>
      <Row style={{ gap: 10 }}>
        <Avatar src="/images/cstown/manager.svg" />
        <div style={{ flexGrow: 1 }}>
          <Text type="H4">{member?.name}</Text>
          <div>{member?.memberUser?.username}</div>
        </div>
        <>
          {type === 'EDIT' ? (
            <div>
              <DeleteIcon
                onClick={() => {
                  onDelete?.();
                }}
              />
            </div>
          ) : (
            <div style={{ textAlign: 'center', width: 100 }}>
              {assigned ? (
                <>추가됨</>
              ) : (
                <>
                  <OhmossButton
                    type="square"
                    onClick={() => {
                      onAdd?.();
                    }}
                    style={{ width: 100 }}
                  >
                    추가하기
                  </OhmossButton>
                </>
              )}
            </div>
          )}
        </>
      </Row>
    </ManagerStyle>
  );
};

const ManagerStyle = styled.div`
  background: #fff;
  padding: 20px;
  min-width: 300px;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
`;

export default ManagerListItem;
