import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { isMobile } from 'react-device-detect';
import { IconButton } from '@mui/material';
import { Row } from 'components/commons/layouts';

import styled from 'styled-components';
import Avatar from 'components/commons/Avatar';
import MyChannelList from 'features/commonFeatures/containers/MyChannelList';
import useToken from 'libs/hooks/useToken';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useNavigation from 'hooks/useNavigation';

const AdminLnbAvatar = observer(() => {
  const { uiStore, myChannelStore } = useStore();
  const { t } = useTranslation();
  const { getUserInfo } = useToken();
  const { navigate } = useNavigation();

  const [clickProfile, setClickProfile] = useState<boolean>(false);

  const handleClickShowModal = () => {
    uiStore.universalModal.show({
      title: 'My Channels',
      children: <MyChannelList type="admin" />,
      modalOption: { style: { width: '650px', height: '350px' } },
    });
  };

  return (
    <>
      <ProfileContainerStyle mobileMenu={isMobile}>
        <AvatarStyle
          isMobile={isMobile}
          clickProfile={clickProfile}
          onClick={() => {
            handleClickShowModal();
            setClickProfile(!clickProfile);
          }}
        >
          <Avatar size="small" profile={myChannelStore.currentChannel?.profile} />
        </AvatarStyle>
        <Row>
          <NameStyle>{myChannelStore?.currentChannel?.channelName}</NameStyle>
          <IconButton
            onClick={() => {
              handleClickShowModal();
              setClickProfile(!clickProfile);
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Row>
        <UserInfoStyle
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate('/admin/account');
          }}
        >
          {getUserInfo()}
        </UserInfoStyle>
      </ProfileContainerStyle>
    </>
  );
});

const ProfileContainerStyle = styled.div<{ mobileMenu?: boolean }>`
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
  /* > button:hover,
  > div:first-child > div > div:hover {
    opacity: 0.7;
  } */
`;

const AvatarStyle = styled.div<{ isMobile?: boolean; clickProfile?: boolean }>`
  margin-bottom: 10px;
  justify-content: center;
  text-align: center;
  position: relative;
  z-index: 0;
`;

const NameStyle = styled.div`
  display: block;
  font-size: 18px;
  font-weight: 500;
  /* @media screen and (max-width: 740px) {
    display: none;
  } */
`;

const UserInfoStyle = styled.div`
  font-size: 14px;
  margin-top: -5px;
`;

export default AdminLnbAvatar;
