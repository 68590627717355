import React from 'react';
import { MenuType } from 'types/CommonTypes';
import AddLinkIcon from '@mui/icons-material/AddLink';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import SettingsIcon from '@mui/icons-material/Settings';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import LinkCreateContainer from 'features/adminFeatures/admin/containers/LinkCreateContainer';
import ProfileContainer from 'features/adminFeatures/profile/containers/ProfileContainer';
import AppearanceContainer from 'features/adminFeatures/appearance/containers/AppearanceContainer';
import SettingsContainer from 'features/adminFeatures/admin/containers/SettingsContainer';
import FollowerContainer from 'features/adminFeatures/profile/components/FollowerContainer';
import TagsContainer from 'features/proFeatures/tag/containers/TagsContainer';
import BoardContainer from 'features/adminFeatures/profile/containers/BoardContainer';
import i18n from 'i18n';
import AdManagementContainer from 'features/adminFeatures/admin/containers/AdManagementContainer';
import { useStore } from 'stores/StoreHelper';

const getAdminMenus = () => {
  const { myChannelStore } = useStore();
  const t = i18n.t;

  const adminMenus: Array<MenuType> = [
    {
      title: 'Boards',
      description: t('A116'),
      key: 6,
      route: '/admin/tag',
      icon: <DashboardCustomizeOutlinedIcon />,
      component: <BoardContainer />,
      group: 'profile',
    },
    {
      title: 'Links',
      description: t('A117'),
      key: 0,
      route: '/admin/links',
      icon: <AddLinkIcon />,
      component: <LinkCreateContainer />,
      group: 'profile',
    },
    {
      title: 'Profile',
      description: t('A118'),
      key: 1,
      route: '/admin/profile',
      icon: <PersonAddAltIcon />,
      component: <ProfileContainer />,
      group: 'profile',
    },
    {
      title: 'Design',
      description: t('A119'),
      key: 2,
      route: '/admin/background',
      icon: <PhotoCameraBackIcon />,
      component: <AppearanceContainer />,
      group: 'profile',
    },
    {
      title: 'Follower',
      description: t('A120'),
      key: 3,
      used: 'pc' || 'mobile',
      route: '/admin/follower',
      icon: <FavoriteBorderIcon />,
      component: <FollowerContainer />,
      group: 'biz',
    },
    {
      title: 'Tags',
      description: t('A121'),
      key: 4,
      route: '/admin/tag',
      icon: <SellOutlinedIcon />,
      component: <TagsContainer />,
      group: 'biz',
    },
    {
      title: 'Ad',
      description: t('A118_1'),
      key: 5,
      route: '/admin/background',
      icon: <SettingsIcon />,
      isPro: true,
      component: <AdManagementContainer />,

      group: 'profile',
    },
    {
      title: 'Settings',
      description: t('A122'),
      key: 5,
      route: '/admin/admanagement',
      icon: <SettingsIcon />,
      component: <SettingsContainer />,
      group: 'profile',
    },
  ];
  return myChannelStore.currentChannel?.isBusiness ? adminMenus : adminMenus.filter((menus: MenuType) => !menus.isPro);
};

const bizMenus: Array<MenuType> = [
  { title: 'Followers', key: 4, isPro: true, route: '/follower' },
  { title: 'Message', key: 5, isPro: true, route: '/push' },
  { title: 'Tags', key: 6, isPro: true, route: '/tag' },
];

export { getAdminMenus, bizMenus };
