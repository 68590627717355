import styled from 'styled-components';

const ToggleContainerStyle = styled.div`
  margin-bottom: 5px;
  background: #fff;
  width: 50%;
  margin-right: 10px;
  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;
export default ToggleContainerStyle;
