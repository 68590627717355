import { useStore } from 'stores/StoreHelper';
import {
  ChannelChecklistCreationDto,
  ChannelChecklistDto,
  ChannelChecklistItemDto,
  ChannelChecklistItemUpdateDto,
  ChannelChecklistUpdateDto,
  ChecklistCategoryDto,
  ChannelChecklistTopicDto,
} from 'services/data-contracts';
import ChecklistStore from 'stores/ChecklistStore';
import { useRootContext } from 'libs/hooks/useRootContext';
/**
 * @description 각 Block의 등록/수정/삭제를 수행함.
 * @returns
 */

const ChecklistController = () => {
  const checklistStore = new ChecklistStore();
  const { xhrStore } = useStore();
  const { myChannel } = useRootContext();

  /**
   * @description 채널에 대한 제안서를 생성함.
   * @param standAlonModel
   * @returns
   */
  const createMyChecklist = async (
    channelId: number,
    serviceModel: ChannelChecklistCreationDto,
  ) => {
    xhrStore.setState('pending');
    const result = (await checklistStore.createMyChecklist(
      channelId as number,
      serviceModel as ChannelChecklistCreationDto,
    )) as unknown as ChannelChecklistDto;
    xhrStore.setState('done');
    return result;
  };

  const getChecklistCategories = async (ownChannelId: number) => {
    xhrStore.setState('pending');
    const result = (await checklistStore.getChecklistCategories(
      ownChannelId,
    )) as unknown as ChecklistCategoryDto[];
    xhrStore.setState('done');
    return result;
  };

  const getMyChecklist = async (channelId: number, checklistId: number) => {
    xhrStore.setState('pending');
    const result = (await checklistStore.getMyChecklist(
      channelId,
      checklistId,
    )) as unknown as ChannelChecklistDto;
    xhrStore.setState('done');
    return result;
  };

  const getMyChecklists = async (channelId: number) => {
    xhrStore.setState('pending');
    const result = (await checklistStore.getMyChecklists(
      channelId,
    )) as unknown as ChannelChecklistDto[];
    xhrStore.setState('done');
    return result;
  };

  const getChecklistsByCategory = async (categoryId: number, ownChannelId: number) => {
    xhrStore.setState('pending');
    const result = (await checklistStore.getChecklistsByCategory(
      categoryId,
      ownChannelId,
    )) as unknown as ChannelChecklistDto[];
    xhrStore.setState('done');
    return result;
  };

  const downloadTopicsPdf = async (checklistId: number) => {
    const result = await checklistStore.downloadTopicsPdf(myChannel?.id as number, checklistId);
    if (result) {
      const element = document.createElement('a');
      const file = new Blob([result as unknown as BlobPart], { type: 'application/pdf' });
      element.href = URL.createObjectURL(file);
      element.download = `${myChannel?.channelName}_${Date.now()}.pdf`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  };

  const updateMyChecklist = async (
    channelId: number,
    checklistId: number,
    data: ChannelChecklistUpdateDto,
  ) => {
    xhrStore.setState('pending');
    const result = (await checklistStore.updateMyChecklist(
      channelId,
      checklistId,
      data,
    )) as unknown as ChannelChecklistDto;
    xhrStore.setState('done');
    return result;
  };

  const getChannelChecklistTopic = async (channelId: number, checklistId: number) => {
    xhrStore.setState('pending');
    const result = (await checklistStore.getMyChecklistTopics(
      channelId,
      checklistId,
    )) as unknown as ChannelChecklistTopicDto[];
    xhrStore.setState('done');
    return result;
  };

  const getMyChecklistItems = async (channelId: number, checklistId: number, topicId: number) => {
    xhrStore.setState('pending');
    const result = (await checklistStore.getMyChecklistItems(
      channelId,
      checklistId,
      topicId,
    )) as unknown as ChannelChecklistItemDto[];
    xhrStore.setState('done');
    return result;
  };

  const updateMyChecklistItem = async (
    channelId: number,
    checklistId: number,
    checklistItemId: number,
    topicId: number,
    data: ChannelChecklistItemUpdateDto,
  ) => {
    xhrStore.setState('pending');
    const result = (await checklistStore.updateMyChecklistItem(
      channelId,
      checklistId,
      topicId,
      checklistItemId,
      data,
    )) as unknown as ChannelChecklistItemDto[];
    xhrStore.setState('done');
    return result;
  };

  const deleteMyChecklist = async (channelId: number, checklistId: number) => {
    xhrStore.setState('pending');
    const result = (await checklistStore.deleteMyChecklist(
      channelId,
      checklistId,
    )) as unknown as ChannelChecklistDto;
    xhrStore.setState('done');
    return result;
  };

  const getTopicSummarize = async (channelId: number, checklistId: number, topicId: number) => {
    const result = await checklistStore.getTopicSummarize(channelId, checklistId, topicId);
    return result;
  };

  const saveTopicSummarize = async (
    channelId: number,
    checklistId: number,
    topic: ChannelChecklistTopicDto,
  ) => {
    xhrStore.setState('pending');
    const result = await checklistStore.saveTopicSummarize(
      channelId,
      checklistId,
      topic.id as number,
      topic,
    );
    xhrStore.setState('done');
    return result;
  };

  return {
    createMyChecklist,
    getChecklistCategories,
    getMyChecklist,
    getMyChecklists,
    getChecklistsByCategory,
    updateMyChecklist,
    getChannelChecklistTopic,
    updateMyChecklistItem,
    getMyChecklistItems,
    deleteMyChecklist,
    downloadTopicsPdf,
    getTopicSummarize,
    saveTopicSummarize,
  };
};

export default ChecklistController;
