import React, { useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { MemberDto } from 'services/data-contracts';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { CSTownPartnerState } from 'types/CommonTypes';
import { useStateContext } from 'libs/hooks/usePageState';

import PartnerSettingsContainer from 'features/myPage/containers/PartnerSettingsContainer';
import TabButtons from 'features/csTown/components/pc/TabButtons';
import NoContent from 'components/commons/NoContent';
import CSPartnerList from 'features/csTown/components/pc/CSPartnerList';
import { CSTownMobileLayout } from 'features/csTown/commonComponents/styles';
import { BottomButtonStyle } from './ButtonContainer';
import { AnimatedTabButton, TabItem } from 'components/commons/buttons/AnimatedTabButton';

type tabs = '파트너' | '초대중';
const PartnerListContainer = ({ onSearch }: { onSearch: (value: string) => void }) => {
  const { uiStore } = useStore();
  const { stateModel, setPageState } = useStateContext<CSTownPartnerState>();

  const [tab, setTab] = useState<number>(1);

  const handleClickSelect = async (partner: MemberDto) => {
    setPageState('selectedPartner', partner);
  };

  const handleAddPartner = (isEdit?: boolean) => {
    uiStore.modal.show({
      title: '파트너 설정',
      style: {
        minHeight: '100%',
        borderRadius: 0,
      },
      children: (
        <PartnerSettingsContainer memberInfo={isEdit ? stateModel?.selectedPartner : undefined} />
      ),
    });
  };

  return (
    <CSTownMobileLayout>
      <div
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          padding: 10,
          background: '#f0f0f0',
        }}
      >
        <AnimatedTabButton
          onClick={(item: any) => {
            const key = Number(item.key);

            setTab(key as number);
            setPageState('selectedPartner', undefined);
          }}
          style={{ width: '100%' }}
        >
          <TabItem key={1}>파트너</TabItem>
          <TabItem key={2}>초대중</TabItem>
        </AnimatedTabButton>
        {/* <TabButtons
          items={['파트너', '초대중']}
          onHandleTabClick={(value: string) => {
            setTab(value as tabs);
            setPageState('selectedPartner', undefined);
          }}
        /> */}
      </div>
      <div style={{ flexGrow: 1, position: 'relative', paddingBottom: 150 }}>
        {tab === 1 && stateModel?.partnerList?.length === 0 && (
          <NoContent title="등록된 파트너가" />
        )}
        {tab === 2 && stateModel?.invitationList?.length === 0 && (
          <NoContent title="초대중인 파트너가" />
        )}
        <CSPartnerList
          partners={tab === 1 ? stateModel?.partnerList : stateModel?.invitationList}
          onSelect={handleClickSelect}
        />
      </div>
      <BottomButtonStyle>
        <OhmossButton
          style={{ borderRadius: 10 }}
          onClick={() => {
            handleAddPartner(false);
          }}
        >
          파트너 초대
        </OhmossButton>
      </BottomButtonStyle>
    </CSTownMobileLayout>
  );
};

export default PartnerListContainer;
