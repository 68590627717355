import SuggestLinkEditor from 'components/items/SuggestLinkEditor';
import CardLinkEditor from 'components/items/CardLinkEditor';
import MapLinkEditor from 'components/items/MapLinkEditor';
import YouTubeEditor from 'components/items/YouTubeEditor';
import TelLinkEditor from 'components/items/TelLinkEditor';
import PageEditor from 'components/items/PageEditor';
import SimpleLinkEditor from 'components/items/SimpleLinkEditor';
import ItemLinkEditor from 'components/items/ItemLinkEditor';
// import * as CardItems from 'components/items';
import i18n from 'i18n';
import SocialEditor from 'components/items/SocialEditor';

const { t } = i18n;

enum LinkItemType {
  CardLink,
  Booking,
  MapLink,
  Youtube,
  TelLink,
  Page,
  SimpleLink,
  Suggestion,
  ItemLink,
  SNS,
}
interface ILinkItemType {
  iconSrc: string;
  description: string;
  id: string;
  title: string;
  type: LinkItemType;
  exclusive?: boolean;
  component: any;

  /** 예약등을 사용하는 비지니스 회원 */
  business?: boolean;

  /** 카드를 접어서 표시 */
  expand?: boolean;
}

// const BookLinkEditor: ILinkItemType = {
//   iconSrc: '/images/menu/calendar.svg',
//   description: 'Booking',
//   id: 'Booking',
//   type: LinkItemType.Booking,
//   expand: true,
//   business: true,
//   component: CardItems.BookingLinkEditor,
// };

const MapLink: ILinkItemType = {
  iconSrc: '/images/menu/map.svg',
  title: t('Map link'),
  description: t('Map link_1'),
  id: 'MapLink',
  type: LinkItemType.MapLink,
  expand: true,
  component: MapLinkEditor,
};

const VideoLink: ILinkItemType = {
  iconSrc: '/images/menu/play.svg',
  title: t('Video link'),
  description: t('Video link_1'),
  id: 'Youtube',
  type: LinkItemType.Youtube,
  expand: true,
  component: YouTubeEditor,
};

const TelLink: ILinkItemType = {
  iconSrc: '/images/menu/call.svg',
  title: t('Tel link'),
  description: t('Tel link_1'),
  type: LinkItemType.TelLink,
  id: 'TelLink',
  component: TelLinkEditor,
};

const PageLink: ILinkItemType = {
  iconSrc: '/images/menu/document.svg',
  title: t('Page link'),
  description: t('Page link_1'),
  id: 'Page',
  type: LinkItemType.Page,
  expand: true,
  exclusive: false,
  business: true,
  component: PageEditor,
};

const SimpleLink: ILinkItemType = {
  iconSrc: '/images/menu/link.svg',
  title: t('Simple link'),
  description: t('Simple link_1'),
  id: 'SimpleLink',
  type: LinkItemType.SimpleLink,
  component: SimpleLinkEditor,
};

const ItemLink: ILinkItemType = {
  iconSrc: '/images/menu/link.svg',
  title: '아이템링크',
  description: 'Item',
  id: 'ItemLink',
  type: LinkItemType.ItemLink,
  exclusive: true,
  component: ItemLinkEditor,
};

const CardLink: ILinkItemType = {
  iconSrc: '/images/menu/카드.svg',
  title: t('Card link'),
  description: t('Card link_1'),
  id: 'CardLink',
  type: LinkItemType.CardLink,
  expand: true,
  component: CardLinkEditor,
};

const Suggestion: ILinkItemType = {
  iconSrc: '/images/menu/suggestion.svg',
  title: t('Suggestion link'),
  description: t('Suggestion link_1'),
  id: 'Suggestion',
  type: LinkItemType.Suggestion,
  component: SuggestLinkEditor,
};

const SNS: ILinkItemType = {
  iconSrc: '/images/menu/suggestion.svg',
  title: t('SNS link'),
  description: t('SNS link_1'),
  id: 'SNS',
  type: LinkItemType.SNS,
  component: SocialEditor,
};

const linkTypes: Array<ILinkItemType> = [
  SimpleLink,
  TelLink,
  CardLink,
  VideoLink,
  MapLink,
  PageLink,
  Suggestion,
  ItemLink,
  SNS,
];

export { linkTypes, ILinkItemType, LinkItemType };
