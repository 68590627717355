import { useStore } from 'stores/StoreHelper';
import { extractAdditionalProperties } from 'libs/hooks/useModelUpdate';

import FileStore from 'stores/FileStore';
import MyBoardStore from 'stores/MyBoardStore';
import ChannelStore from 'stores/ChannelStore';
import { ChannelBoardDto } from 'services/data-contracts';
import { CustomFile, IChannelBoardModel } from 'types/BlockExtendsTypes';
import { ChannelBoardDtoDummy } from 'features/management/context/dummy/ChannelBoardDtoDummy';
import { parseJSONAll } from 'libs/helper/utils';
import { useRootContext } from 'libs/hooks/useRootContext';

/**
 * @description 각 Block의 등록/수정/삭제를 수행함.
 * @returns
 */
const CategoryController = () => {
  const { xhrStore } = useStore();
  const { myChannel } = useRootContext();

  const fileStore = new FileStore();
  const myBoardStore = new MyBoardStore();
  const channelStore = new ChannelStore();

  /**
   * @description 채널의 프로필을 업데이트한다.
   * @param standAlonModel
   * @returns
   */
  const updateCategory = async (serviceModel: ChannelBoardDto) => {
    xhrStore.setState('pending');
    const newModel: ChannelBoardDto = {
      ...serviceModel,
    };

    if (newModel) {
      if (typeof newModel === 'undefined') {
        return;
      }
      const extracted = extractAdditionalProperties<IChannelBoardModel, ChannelBoardDto>(
        newModel,
        ChannelBoardDtoDummy,
      );

      if ((newModel?.thumbnail as CustomFile)?.blob) {
        const file = (await fileStore.uploadImage(
          (newModel?.thumbnail as CustomFile)?.blob,
          'ChannelBoard',
        )) as CustomFile;

        newModel.thumbnail = { id: file.id };
      }
      if (typeof extracted !== 'undefined') {
        newModel.metadata = JSON.stringify(extracted);
      }
      if (!newModel.id) {
        const result = (await myBoardStore.createBoard(
          myChannel?.id as number,
          newModel,
        )) as ChannelBoardDto;
        xhrStore.setState('done');
        return result;
      } else {
        const result = (await myBoardStore.updateBoard(
          myChannel?.id as number,
          newModel,
        )) as ChannelBoardDto;
        xhrStore.setState('done');
        return result;
      }
    }
  };

  const getCategoryBoard = async (categoryId: number) => {
    const categoryBoard = (await myBoardStore.getBoardById(
      myChannel?.id as number,
      categoryId,
    )) as unknown as ChannelBoardDto;

    return categoryBoard;
  };

  /**
   * @description 내 채널 게시판을 가져온다.
   * @param targetChannelName
   */
  const getMyCategoryBoards = async () => {
    const response = (await myBoardStore.getBoards(
      myChannel?.id as number,
    )) as never as ChannelBoardDto[];

    const newData = response?.map((data: ChannelBoardDto) => {
      let parsedProfile: any = {};
      try {
        parsedProfile = parseJSONAll(data.metadata);
      } catch (error) {
        console.error('JSON parsing error:', error);
      }
      return { ...data, ...parsedProfile };
    });

    return newData;
  };

  /**
   * @description 다른 사용자의 채널 게시판을 가져온다.
   * @param targetChannelName
   */
  const getCategoryBoardsByChannelName = async (targetChannelName: string) => {
    if (typeof targetChannelName !== 'undefined') {
      const boards = (await channelStore.getChannelBoards(
        targetChannelName as string,
      )) as any as ChannelBoardDto[];
      boards.sort((a, b) => {
        // isPrimary가 true인 항목을 앞으로 이동
        if (a.isPrimary && !b.isPrimary) {
          return -1;
        }
        if (!a.isPrimary && b.isPrimary) {
          return 1;
        }
        // isPrimary가 모두 같다면, seq 기준으로 내림차순
        return a.seq! - b.seq!;
      });
      // setBoards(boards);
      return boards;
    }
  };

  /**
   * @description 채널의 기본 게시판을 가져온다.
   * @param boards
   * @param boardId
   * @returns
   */
  const getDefaultCategoryBoard = async (boards: ChannelBoardDto[], boardId?: number) => {
    if (boardId) {
      const tempBoard = boards.find((board: ChannelBoardDto) => board.id === boardId);
      if (tempBoard) {
        return tempBoard;
      } else {
        return boards[0];
      }
    } else if (boards.length > 0) {
      const defaultBoard = boards.find((board) => board.isPrimary);
      return defaultBoard ?? boards[0];
    } else {
      return null;
    }
  };

  const deleteCategory = async (boardId?: number) => {
    if (boardId) {
      const result = (await myBoardStore.deleteBoard(myChannel?.id as number, boardId)) as unknown;
      return result;
    }
  };

  return {
    updateCategory,
    getMyCategoryBoards,
    getCategoryBoardsByChannelName,
    getCategoryBoard,
    getDefaultCategoryBoard,
    deleteCategory,
  };
};

export default CategoryController;
