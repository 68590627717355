import styled from 'styled-components';

const DottedBoxStyle = styled.div<{ width: string; height: string; show?: boolean }>`
  background-image: ${(props) =>
    props.show === false
      ? 'none'
      : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='13' ry='13' stroke='%23787878' stroke-width='3' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");`};
  width: ${(props) => props.width};
  border-radius: 13px;
  height: ${(props) => props.height};
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: auto;
`;

export default DottedBoxStyle;
