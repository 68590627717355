import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import useNavigation from 'hooks/useNavigation';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { OhmossButton } from 'resources/controls/OhmossButton';
import RowColumn from './layouts/RowColumn';
import MobileModalHeader from './MobileModalHeader';
import { useMediaContext } from 'providers/useMediaContext';
import ActionBox from 'features/csTown/components/pc/ActionBox';

const Modal = observer(() => {
  const ScreenTypes = useMediaContext();
  const { uiStore, historyStore } = useStore();
  const { t } = useTranslation();
  const navigation = useNavigation();
  const [zIndex, setZIndex] = useState(2);

  useEffect(() => {
    return () => {
      uiStore.universalModal.updateModel(uiStore.universalModal?.contentModel);
    };
  }, []);

  useEffect(() => {
    if (uiStore.bottomSheet.modalShow || uiStore.universalModal.modalShow) {
      setZIndex(3);
    } else {
      setZIndex(100);
    }
  }, [uiStore.bottomSheet.modalShow, uiStore.universalModal.modalShow]);

  return (
    <ReactModal
      isOpen={uiStore.universalModal.modalShow}
      style={{
        overlay: {
          backgroundColor:
            uiStore.universalModal.options?.modalOption?.backgroundColor || 'rgba(0, 0, 0, 0.75)',
          zIndex,
        },
        content: {
          border: '0',
          display: 'flex',
          flexDirection: 'column',
          padding: 0,
          margin: 'auto',
          height: 'auto',
          // maxHeight: '80%',
          maxWidth: ScreenTypes.isSmall ? 550 : 900,
          borderRadius: 8,
          ...uiStore.universalModal.options?.modalOption?.style,
        },
      }}
      ariaHideApp={false}
    >
      <ContentStyle className="model-content-style">
        {/* <UniversalHeader /> */}
        {uiStore.universalModal.options?.modalType !== 'confirm' && (
          <MobileModalHeader type="modal" />
        )}
        <RowColumn style={{ top: 40, height: 'calc(100% - 60px)' }}>
          <ContentBodyStyle id="modalContentBody" style={{ paddingBottom: 20 }}>
            {uiStore.universalModal.options?.children}
          </ContentBodyStyle>
          <>
            {uiStore.universalModal.options?.useActions && (
              <div
                style={{
                  width: '100%',
                  height: 50,
                  bottom: 0,
                  position: 'absolute',
                  zIndex: 1,
                  textAlign: 'center',
                }}
              >
                <ActionBox
                  style={{
                    justifyContent: 'center',
                    padding: '10px',
                    borderTop: '1px solid #d1d1d1',
                    background: '#fff',
                  }}
                  onOk={() => {
                    // update();
                    uiStore.universalModal.close();
                  }}
                  onCancel={() => {
                    uiStore.universalModal.close();
                  }}
                />
              </div>
            )}
          </>
        </RowColumn>
      </ContentStyle>
    </ReactModal>
  );
});

const ContentStyle = styled.div`
  width: 100%;
  height: 100%;
`;

const ContentBodyStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
`;

export default Modal;
