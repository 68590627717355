/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import { InternalLinkCard, PostModel } from 'types/CommonTypes';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { observer } from 'mobx-react';
import { getFeedThmbnailUrl } from 'components/items/commonLogic';
import { useStore } from 'stores/StoreHelper';
import { OhmossButton } from 'resources/controls/OhmossButton';
import styled, { css } from 'styled-components';

import VideoComponent from 'features/adminFeatures/commons/components/VideoComponent';
import useScreenType from 'libs/hooks/useScreenType';

import 'swiper/css/pagination';
import 'swiper/css';
import ReplyForm from 'features/board/containers/ReplyForm';
import ListOfComments from 'features/board/containers/ListOfComments';
import { Row } from 'components/commons/layouts';
import { IconButton } from '@mui/material';
import ShareBox from 'components/commons/ShareBox';
import { isMobile } from 'react-device-detect';
import moment from 'moment';

// todo : 수정처리 하기
const BoardListItem = observer(({ item }: { item: PostModel }) => {
  const containerRef = useRef<any>(null);
  const { postStore, channelStore, myChannelStore, uiStore, uiStores } = useStore();
  const [cards, setCards] = useState<Array<InternalLinkCard>>();
  const [swiper, setSwiper] = useState<any>(null);
  const [index, setIndex] = useState<number>(0);
  const [imgBoxHeight, setImgBoxHeight] = useState<number>();

  const imgRefs = useRef<any>([]);

  const screenType = useScreenType();

  return (
    <CardContainerStyle>
      <TitleContainerStyle>
        <TitleStyle>{item.title}</TitleStyle>
        <DateStyle>{moment(item.createDate).fromNow()}</DateStyle>
      </TitleContainerStyle>
      <div
        style={{ whiteSpace: 'pre-line', padding: '0 20px', paddingBottom: 20 }}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: item?.content as string,
        }}
      />
      {uiStores.userHomeStore.currentBoard?.isCommentAllow && (
        <>
          <div style={{ marginLeft: 20 }}>
            <ListOfComments postId={item.id as number} />
          </div>
          <ReplyForm feedId={item?.id} type="reply" />
        </>
      )}
    </CardContainerStyle>
  );
});
// min-width: ${(props) => (props.isSimulatore ? '100px' : props.isMobile ? '110px' : '150px')};

const CardContainerStyle = styled.div`
  transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);

  @media ${(props) => props.theme.media.mobile} {
  }
`;

const TitleContainerStyle = styled.div`
  padding: 20px;
`;

const TitleStyle = styled.div`
  white-space: pre-line;
  font-weight: 500;
  font-size: 18px;
`;

const DateStyle = styled.div`
  font-size: 12px;
  color: #999;
`;
export default BoardListItem;
