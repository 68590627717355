import React from 'react';
import MenuItem from 'components/commons/menuItem/MenuItem';
import MenuItemBox from 'components/commons/menuItem/MenuItemBox';
import TextLabel from 'components/commons/labels/TextLabel';
import useNavigation from 'hooks/useNavigation';
import useLogout from 'hooks/useLogout';

import styled from 'styled-components';

const CommonMyMenus = () => {
  const { navigate } = useNavigation();
  const logout = useLogout();

  return (
    <ContainerStyle>
      <div>
        <MenuItemBox title="회원">
          <MenuItem
            title="계정 설정"
            onClick={() => {
              navigate('/admin/account');
            }}
          />
          {/* <MenuItem
          title="알림설정"
          onClick={() => {
            navigate('/settings/notification');
          }}
        /> */}
        </MenuItemBox>
        <MenuItemBox title="서비스 정보">
          <MenuItem
            title="서비스 이용 약관"
            onClick={() => {
              navigate('/my/userterms');
            }}
          />
          <MenuItem
            title="개인정보 수집 및 취급 동의"
            onClick={() => {
              navigate('/my/privacypolicy');
            }}
          />
        </MenuItemBox>
        <MenuItemBox title="기타">
          <MenuItem title="앱 버전" menuType="contents">
            <TextLabel type="content">{process.env.VERSION}</TextLabel>
          </MenuItem>
          <MenuItem title="고객센터">
            <TextLabel type="content">
              <a href="mailto:cs@nbhd.kr">cs@nbhd.kr</a>
            </TextLabel>
          </MenuItem>
          <MenuItem
            title="Company"
            onClick={() => {
              navigate('/company');
            }}
          />
          <MenuItem
            title="About Us"
            onClick={() => {
              navigate('/aboutus');
            }}
          />
        </MenuItemBox>
        <MenuItemBox title={` `}>
          <MenuItem title="로그아웃" onClick={logout} menuType="action" />
        </MenuItemBox>
      </div>
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default CommonMyMenus;
