import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import UniversalLayout from 'components/commons/layouts/UniversalLayout';
import { useSearchParams } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import useToken from 'libs/hooks/useToken';

export interface AuthenticateChannelPageProps {
  responseStatus: string;
  message: string;
}

const AuthenticateChannelPage = observer(() => {
  const [searchParam] = useSearchParams();
  const { uiStore } = useStore();
  const { validUser } = useToken();
  const { navigate } = useNavigation();

  const [status, setStatus] = useState<AuthenticateChannelPageProps>({
    responseStatus: '',
    message: '',
  });

  const auth = async () => {
    const authKey = searchParam.get('authKey');
    const channelId = searchParam.get('channelId');
    const action = searchParam.get('action');

    if (validUser()) {
      if ((authKey && channelId) || action) {
        let key;
        let cId;
        if (action) {
          const temp = action.replace('(', '').replace(')', '').split('|');
          key = temp[0];
          cId = temp[1];
        } else {
          key = authKey;
          cId = channelId;
        }
      } else {
        toast('인증정보가 바르지 않습니다. 고객센터로 문의 해 주세요', { type: 'warning' });
      }
    } else {
      // https://ohmoss.com/authenticate-channel?authKey=psqxNtyXojCliYy&channelId=408
      // https://ohmoss.com/authenticate-channel?authKey=psqxNtyXojCliYy&channelId=408
      uiStore.confirm.show({
        message: '로그인이 필요한 메뉴입니다. 로그인 하시겠습니까?',
        onConfirmed: async () => {
          navigate(`/login?action=(/authenticate-channel.(${authKey}|${channelId}))`);
        },
      });
    }
  };

  useEffect(() => {
    auth();
  }, []);

  return (
    <UniversalLayout>
      {/* <DetailPageHeader title="인스타그램 인증" /> */}
      {status.responseStatus === 'success' ? (
        <ContentWrapper>
          <img src="/images/welcome.svg" alt="" style={{ width: '200px' }} />
          <WelcomeText>
            환영합니다!
            <br />
            인플루언서 계정이 인증되었습니다 ☺️
          </WelcomeText>
          <OhmossButton
            onClick={() => {
              navigate('/my');
            }}
            style={{ marginTop: 30, width: '70%' }}
          >
            My page로 이동하기
          </OhmossButton>
        </ContentWrapper>
      ) : status.responseStatus === 'error' ? (
        <ContentWrapper>
          <img src="/images/sad.svg" alt="" style={{ width: '200px' }} />
          <WelcomeText>
            계정 인증에 실패했습니다 🙁
            <br />
            인증정보가 없거나 이미 인증 처리된 주소입니다.
          </WelcomeText>
        </ContentWrapper>
      ) : (
        <></>
      )}
    </UniversalLayout>
  );
});

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30%;
`;

const WelcomeText = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  color: #4e4e4e;
  text-align: center;
`;

export default AuthenticateChannelPage;
