import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import Theme from 'resources/styles/common/theme';
import styled from 'styled-components';
import Modal from 'react-modal';
import TextLabel from './labels/TextLabel';

const Confirm2 = observer(({}: any) => {
  const { uiStore } = useStore();
  const { t } = useTranslation();

  const handleClickClose = async () => {
    uiStore.confirm.close();
  };

  const handleClickConfirm = async () => {
    uiStore.confirm.confirm();
  };

  useEffect(() => {}, [uiStore.confirm.confirmShow]);
  return (
    <Modal
      isOpen={uiStore.confirm.confirmShow}
      style={{
        overlay: { backgroundColor: 'rgba(0, 0, 0, 0.75)', zIndex: 10000000000 },
        content: {
          border: '0',
          display: 'flex',
          padding: 0,
          margin: 'auto',
          inset: 0,
          position: 'relative',
          alignItems: 'center',
          background: 'transparent',
          justifyContent: 'center',
          height: '100%',
          ...uiStore.confirm.options?.style,
        },
      }}
      ariaHideApp={false}
    >
      <ContentStyle>
        <div style={{ margin: '10px 0' }}>
          <img src="/images/confirm.png" alt="confirm" width="150" />
          <TextLabel type="subject">
            {typeof uiStore.confirm.options?.title === 'undefined'
              ? '확인'
              : uiStore.confirm.options?.title}
          </TextLabel>
        </div>
        <TextLabel type="content">{uiStore.confirm.options?.message}</TextLabel>
        {uiStore.confirm.options?.customButtons ? (
          <>{uiStore.confirm.options?.customButtons}</>
        ) : (
          <ButtonAreaStyle>
            <ButtonStyle onClick={handleClickClose} style={{ marginRight: 10 }}>
              {uiStore.confirm.options?.closeText || t('Cancel')}
            </ButtonStyle>
            <ButtonStyle
              backgroundColor={Theme.colors.pointColor}
              fontColor={Theme.colors.pointColor}
              onClick={handleClickConfirm}
            >
              {uiStore.confirm.options?.okText || t('Confirm')}
            </ButtonStyle>
          </ButtonAreaStyle>
        )}
      </ContentStyle>
    </Modal>
  );
});

const ContentStyle = styled.div`
  text-align: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  height: auto;
  padding: 20px;
  max-width: 320px;
`;

const ButtonAreaStyle = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px 20px 20px 20px;
`;

const ButtonStyle = styled.div<{ backgroundColor?: string; fontColor?: string }>`
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  background-color: #f6f6f6;
  color: ${(props) => props.fontColor};
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 120px;
  height: 50px;
  /* @media ${(props) => props.theme.media.mobile} {
   
  }
  @media ${(props) => props.theme.media.desk} {
    width: 175px;
    height: 60px;
  } */
`;

export default Confirm2;
