import { useStore } from 'stores/StoreHelper';

import { ChannelDto } from 'services/data-contracts';
import { decodeToken } from 'react-jwt';
import { toast } from 'react-toastify';

/**
 * @description 로그인 후 기본 채널을 설정한다.
 * https://www.npmjs.com/package/secure-web-storage
 * @returns
 */
function useAI() {
  const { aiStore } = useStore();

  function extractText(content: string) {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(content, 'text/html');
    return parsedHtml.body.textContent || '';
  }

  const chat = async (content: string) => {
    const result = await aiStore.chat(content);
    return result;
  };

  return {
    chat,
    extractText,
  };
}

export default useAI;
