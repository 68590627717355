import React, { CSSProperties, useEffect, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

const PageLoader = ({ style }: { style?: CSSProperties }) => {
  const referrer = useRef<string>();
  const { pathname } = useLocation();

  const [loaded, setLoaded] = React.useState<boolean>(false);

  useLayoutEffect(() => {
    if (referrer.current !== pathname) {
      setLoaded(false);
    }
    setTimeout(() => {
      setLoaded(true);
      referrer.current = pathname;
    }, 700);
  }, [pathname]);

  return <LoaderStyle loaded={loaded} style={style} />;
};

const LoaderStyle = styled.div<{ loaded?: boolean }>`
  background: #fff;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  position: fixed;
  transition: ${(props) =>
    props.loaded ? 'all 700ms cubic-bezier(0.47, 1.64, 0.41, 0.8)' : 'none'};
  opacity: ${(props) => (props.loaded ? 0 : 1)};
  pointer-events: ${(props) => (props.loaded ? 'none' : '')};
`;

export default PageLoader;
