import React from 'react';
import styled from 'styled-components';
import { ChannelDto, MembershipPlanDto } from 'services/data-contracts';
import InfoBox from 'components/commons/InfoBox';
import Text from 'components/commons/Text';
import MembershipSelector from '../../components/membership/MembershipSelector';

const MembershipContainer = ({
  plans,
  channelType,
}: {
  plans?: MembershipPlanDto[];
  channelType?: ChannelDto['channelType'];
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <TextContainer>
        {channelType === 'SELLER' ? (
          <>
            🙍🏻‍♀️셀러를 위한
            <br />
            강력한 공구 솔루션!💰
          </>
        ) : (
          <>
            🤵‍♂️브랜드를 위한
            <br /> 강력한 공구 솔루션!💰
          </>
        )}
      </TextContainer>
      <InfoBox style={{ margin: 20, backgroundColor: 'rgba(0, 174, 66, 0.12)' }}>
        <div style={{ textAlign: 'center' }}>
          <img src="/images/nice.png" alt="" style={{ width: '50%', margin: 'auto' }} />
          <Text type="H3" style={{ marginTop: 10 }}>
            oh!moss 런칭기념 3개월 무료 혜택!
          </Text>
          <Text type="H4" buttonRole="DESCRIPTION" style={{ marginTop: 10 }}>
            PRO서비스만 해당되며 PREMIUM서비스는 정상적으로 과금됩니다.
          </Text>
        </div>
      </InfoBox>
      <MembershipSelector plans={plans} channelType={channelType} isGift={false} />
    </div>
  );
};

const TextContainer = styled.div`
  display: flex;
  height: 6.875rem;
  flex-direction: column;
  color: #173300;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.015rem;
  margin-top: 1.5rem;
`;

export default MembershipContainer;
