import React, { useState, ReactNode, useRef } from 'react';
import { Context, PropsTypes } from 'libs/hooks/useChat';
import SendbirdChat, { UserEventHandler } from '@sendbird/chat';
import { SEND_BIRD_KEY } from 'stores/AppStore';

import { GroupChannelModule, GroupChannel } from '@sendbird/chat/groupChannel';
import chatController from 'features/community/controllers/chatController';
import { BaseMessage } from '@sendbird/chat/message';
import { useRootContext } from 'libs/hooks/useRootContext';

interface PageProviderProps {
  children: ReactNode | ReactNode[];
}

const ChatProvider = <T extends PropsTypes<T>>({ children }: PageProviderProps) => {
  const sendbirdChatRef = useRef<any>(null);
  const messagesRef = useRef<BaseMessage[]>([]);
  const { myChannel } = useRootContext();

  const [unReadMessageCounts, setUnReadMessageCounts] = useState<number>();
  const [channels, setInquiryChannels] = useState<GroupChannel[]>([]);
  const [messages, setChannelMessages] = useState<BaseMessage[]>();
  const [initCompleted, setInitCompleted] = useState<boolean>(false);

  const initChat = async (channelName: string) => {
    if (
      sendbirdChatRef.current === null ||
      sendbirdChatRef.current?.currentUser?.userId !== channelName
    ) {
      setInitCompleted(false);

      if (sendbirdChatRef.current !== null) {
        sendbirdChatRef.current.removeAllUserEventHandler();
      }

      sendbirdChatRef.current = await SendbirdChat.init({
        appId: SEND_BIRD_KEY as string,
        localCacheEnabled: true,
        modules: [new GroupChannelModule()],
      });

      await sendbirdChatRef.current.connect(channelName);
      await sendbirdChatRef.current.setChannelInvitationPreference(
        myChannel?.channelType === 'SELLER' ? true : false,
      );

      const userEventHandler = new UserEventHandler({
        onTotalUnreadMessageCountChanged: (unreadMessageCount) => {
          // toast('새로운 메시지가 도착했습니다.', { type: 'info' });
          setUnReadMessageCounts(unreadMessageCount?.groupChannelCount);
        },
      });

      sendbirdChatRef.current.addUserEventHandler(channelName, userEventHandler);
      setInitCompleted(true);
      return sendbirdChatRef.current;
    }
    return sendbirdChatRef.current;
  };

  const getUnreadCount = async () => {
    // const result = await getUnreadCounts();
    // sendbirdChatRef.current.groupChannel.getTotalUnreadMessageCount({
    //   channelCustomTypesFilter: [],
    // });
    // setUnreadCount(result);

    const count = await sendbirdChatRef.current.groupChannel.getTotalUnreadMessageCount({
      channelCustomTypesFilter: [`userInquiry-${myChannel?.id}`],
      // 셀러나 브랜드가 다른곳에 문의를 하더라도 카운팅됨(센드버드 구조 문제)
      // channelCustomTypesFilter: [`userInquiry`],
    });

    setUnReadMessageCounts(count);
  };

  const setUnreadCount = (count?: number) => {
    setUnReadMessageCounts(count as number);
  };

  const setChannels = (channels: GroupChannel[]) => {
    setInquiryChannels(channels);
  };

  const setMessages = (messages: BaseMessage[]) => {
    messagesRef.current = messages;
    setChannelMessages(messages);
  };

  return (
    <Context.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        sendbirdChatRef,
        messagesRef: messagesRef.current,
        // sendbirdChat,
        initChat,
        channels,
        setChannels,
        unReadMessageCounts,
        setUnreadCount,
        initCompleted,
        getUnreadCount,
        messages,
        setMessages,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ChatProvider;
