import React, { ReactNode } from 'react';

import { Context } from '../useOhmossContext';
import { ChannelBoardDto } from 'services/data-contracts';
import { useModelManagement } from 'libs/hooks/useModelUpdate';

import FileStore from 'stores/FileStore';
import MyBoardStore from 'stores/MyBoardStore';
import { CustomFile } from 'types/BlockExtendsTypes';
import { useRootContext } from 'libs/hooks/useRootContext';

interface Props<T> {
  children: ReactNode | ReactNode[] | Element | Element[];
}

const CategoryProvider = <T extends ChannelBoardDto>({ children }: Props<T>) => {
  const fileStore = new FileStore();
  const myBoardStore = new MyBoardStore();

  /** Primitive */
  const serviceItems = useModelManagement<T>();
  const { serviceModel, extractAdditionalProperties } = serviceItems;
  const { myChannel } = useRootContext();

  /**
   * @description 채널의 프로필을 업데이트한다.
   * @param standAlonModel
   * @returns
   */
  const updateCategory = async () => {
    const newModel: ChannelBoardDto = {
      ...serviceModel,
    };

    if (newModel) {
      if ((newModel?.thumbnail as CustomFile)?.blob) {
        const file = (await fileStore.uploadImage(
          (newModel?.thumbnail as CustomFile)?.blob,
          'ChannelBoard',
        )) as CustomFile;

        newModel.thumbnail = { id: file.id };
      }
      if (!newModel.id) {
        return (await myBoardStore.createBoard(
          myChannel?.id as number,
          newModel,
        )) as ChannelBoardDto;
      } else {
        return (await myBoardStore.updateBoard(myChannel?.id as number, newModel)) as any;
      }
    }
  };

  return (
    <>
      {typeof children !== 'undefined' ? (
        <Context.Provider
          value={{
            updateCategory,
            ...serviceItems,
          }}
        >
          {children}
        </Context.Provider>
      ) : (
        <></>
      )}
    </>
  );
};

export default CategoryProvider;
