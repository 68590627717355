import React, { ReactNode, useEffect } from 'react';
import { MediaContext } from './MediaContext';
import { useMediaQuery, createTheme } from '@mui/material';

interface PageProviderProps {
  children: ReactNode | ReactNode[];
}

const MediaProvider = ({ children }: PageProviderProps) => {
  // const theme = useTheme();
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 961,
        lg: 1280,
        xl: 1920,
      },
    },
  });
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <MediaContext.Provider value={{ isSmall, isMedium, isLarge }}>{children}</MediaContext.Provider>
  );
};

export default MediaProvider;
