import React from 'react';
import styled from 'styled-components';
import { Menu } from './SubTabs';

const TabComponent = ({
  menus,
  selected,
  onClickTab,
}: {
  menus?: Menu[];
  selected?: Menu;
  onClickTab?: (tab: Menu) => void;
}) => {
  return (
    <MenuContainerStyle>
      {menus?.map((menu) => (
        <MenuStyle
          on={selected?.id === menu.id}
          onClick={() => {
            onClickTab?.(menu);
          }}
          key={menu.id}
        >
          {menu.title}
        </MenuStyle>
      ))}
    </MenuContainerStyle>
  );
};

const MenuContainerStyle = styled.div`
  display: flex;
  gap: 30px;
  justify-content: space-between;
`;

const MenuStyle = styled.div<{ on?: boolean }>`
  cursor: pointer;
  text-align: center;
  margin: auto;
  padding: 0 10px;
  padding-bottom: 10px;

  border-bottom: ${(props) => (props.on ? '2px solid rebeccapurple' : '2px solid transparent')};
`;

export default TabComponent;
