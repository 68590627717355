import React, { useEffect, useRef, useState } from 'react';
import { LinkDto, MemberDto } from 'services/data-contracts';

import PasswordIcon from '@mui/icons-material/Password';
import { Row } from 'components/commons/layouts';
import {
  DetailHeader,
  ListBoxContentsTemplate,
  ListBoxItem,
  ListBoxTemplate,
  ListBoxWrapper,
  NoContentStyle,
} from 'features/csTown/commonComponents/styles';

import ManagerList from 'features/csTown/components/pc/ManagerList';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { useStore } from 'stores/StoreHelper';
import { toast } from 'react-toastify';
import { IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteIcon from 'features/csTown/commonComponents/DeleteIcon';
import BrandController from 'controllers/BrandController';
import { useStateContext } from 'libs/hooks/usePageState';
import { useRootContext } from 'libs/hooks/useRootContext';
import CSTownController from 'controllers/CSTownController';
import Text from 'components/commons/Text';
import AssignedDealsList from 'features/myPage/containers/AssignedDealsList';
import { LinkDtoWithAssignedDeal } from 'types/CommonTypes';
import CSTownManagersController from 'features/csTown/pages/pageControllers/CSTownManagersController';
import MemberCreateContainer from './MemberCreateContainer';
import useNavigation from 'hooks/useNavigation';

const ManagerListContainer = ({}: {}) => {
  const { deleteManager } = BrandController();
  const { myChannel } = useRootContext();
  const { navigate } = useNavigation();
  const { Brand } = CSTownController();
  const { uiStore } = useStore();
  const { broadcast, receiver } = useStateContext();
  const { updateManager } = CSTownManagersController();

  const originMembers = useRef<MemberDto[]>([]);

  const [manager, setManager] = useState<MemberDto | null | undefined>(null);
  const [newManager, setNewManager] = useState<MemberDto | null | undefined>(null);
  const [isValid, setIsValid] = useState<boolean>();
  const [isModelValid, setIsModelValid] = useState<boolean>(false);
  const [members, setMembers] = useState<MemberDto[]>([]);
  const [ongoingDeals, setOngoingDeals] = useState<LinkDtoWithAssignedDeal[]>([]);

  const handleClickManager = async (manager: MemberDto | undefined) => {
    const result = (await Brand.DEAL.getDelegatedDeals(
      (manager?.id as number) || undefined,
    )) as unknown as LinkDto[];

    setOngoingDeals(result);

    if (manager) {
      const newModel = { ...manager, username: manager?.memberUser?.username };
      setManager(newModel);
    } else {
      const newModel: any = { username: '', name: '', phoneNumber: '', company: '', email: '' };
      setManager(newModel);
    }
  };

  const getMemberList = async () => {
    const result = (await Brand.DEAL.getMembers(myChannel?.id as number)) as unknown as MemberDto[];
    setMembers(result);
    originMembers.current = result;
  };

  const handleResetPassword = () => {
    uiStore.confirm.show({
      message: '해당 매니저의 비밀번호를 초기화하시겠습니까?',
      onConfirmed: async () => {
        alert('준비중입니다.');
        // const result = (await Brand.DEAL.resetPassword(manager?.id as number)) as unknown as string;
        // if (result === '') {
        //   toast('비밀번호가 초기화 되었습니다.');
        // }
      },
    });
  };

  const handleChangeManager = async () => {
    const result = (await updateManager(newManager, ongoingDeals)) as any;
    if (result) {
      if (newManager?.id) {
        broadcast({ id: 'RELOAD_MANAGER_LIST' });
      } else {
        broadcast({ id: 'RELOAD_MANAGER_LIST', param: { id: 'reset' } });
      }
    }
  };

  const handleDeleteMember = async () => {
    uiStore.confirm.show({
      message: '해당 매니저를 삭제하시겠습니까? ',
      onConfirmed: async () => {
        const result = (await deleteManager(manager?.id as number)) as unknown as string;
        if (result === '') {
          broadcast({ id: 'RELOAD_MANAGER_LIST' });
          toast('정상적으로 삭제되었습니다.');
          setManager(null);
        }
      },
    });
  };

  const handleClickShowPlan = () => {
    uiStore.confirm.show({
      title: '플랜 업그레이드 안내',
      message:
        '해당 기능을 사용하기 위해서는 플랜\n업그레이드가 필요합니다.\n플랜을 업그레이드 하시겠습니까?',
      onConfirmed: async () => {
        navigate(`/my/subscriptions?tab=0`);
      },
    });
  };

  const handleSelectDeselectAll = () => {
    const isCheckedAll =
      ongoingDeals.filter((deal) => deal.assigned).length === ongoingDeals.length;

    // targetDealsRef.current = isCheckedAll ? [] : ongoingDeals;
    setOngoingDeals(
      ongoingDeals.map((deal) => {
        return { ...deal, assigned: !isCheckedAll };
      }),
    );
  };

  useEffect(() => {
    if (members) {
      switch (myChannel?.membershipType) {
        case 'FREE': {
          setIsValid(members.length < 1);
          break;
        }
        case 'PRO': {
          setIsValid(members.length <= 4);
          break;
        }
        case 'PREMIUM': {
          setIsValid(true);
          break;
        }
      }
    }
  }, [members]);

  useEffect(() => {
    if (myChannel) {
      getMemberList();
    }
  }, [myChannel]);

  useEffect(() => {
    if (receiver && receiver.id === 'RELOAD_MANAGER_LIST') {
      getMemberList();
    }

    if (receiver && receiver.id === 'RELOAD_MANAGER_LIST' && receiver.param?.id === 'reset') {
      setManager(null);
    }
  }, [receiver]);

  return (
    <div>
      <ListBoxWrapper>
        <ListBoxTemplate>
          <ManagerList
            type="PC"
            members={members}
            style={{ width: '100%', flexGrow: 1 }}
            onClick={handleClickManager}
          />
          <div>
            <OhmossButton
              // disabled={isValid}
              type="primaryPc"
              onClick={() => {
                if (isValid) {
                  handleClickManager(undefined);
                } else {
                  handleClickShowPlan();
                }
              }}
            >
              {!isValid && <InfoOutlinedIcon style={{ marginRight: 10, color: 'yellow' }} />}
              {isValid ? '매니저 추가' : '플랜 업그레이드 필요'}
            </OhmossButton>
          </div>
        </ListBoxTemplate>
        <ListBoxContentsTemplate>
          {manager !== null ? (
            <>
              <DetailHeader style={{ minHeight: 90 }}>
                <Row style={{ justifyContent: 'space-between' }}>
                  <Row style={{ justifyContent: 'flex-start' }}>
                    <Text type="H3">{manager?.name}</Text>
                    <Text type="H3" style={{ fontWeight: 400, marginLeft: 10 }}>
                      {manager?.id ? '매니저 상세정보' : '매니저 추가'}
                    </Text>
                  </Row>
                  <div style={{ minWidth: 120 }}>
                    {typeof manager?.id !== 'undefined' && (
                      <div>
                        <IconButton onClick={handleResetPassword}>
                          <PasswordIcon />
                        </IconButton>
                        <DeleteIcon onClick={handleDeleteMember} />
                      </div>
                    )}
                  </div>
                </Row>
              </DetailHeader>
              <Row style={{ gap: 20, alignItems: 'flex-start', marginTop: 20 }}>
                <ListBoxItem style={{ borderRadius: 10 }}>
                  <>
                    <Text type="H4" style={{ marginLeft: 20, marginBottom: 20 }}>
                      매니저 정보
                    </Text>
                    <div style={{ width: '100%' }}>
                      <MemberCreateContainer
                        style={{ justifyContent: 'flex-start', flex: 1 }}
                        type="edit"
                        mode="PC"
                        model={manager}
                        onUpdate={(model?: MemberDto) => {
                          setIsModelValid(typeof model !== 'undefined');
                          setNewManager(model);
                        }}
                      />
                    </div>

                    <div
                      style={{ padding: 20, paddingBottom: 0, width: '100%', textAlign: 'left' }}
                    >
                      <OhmossButton
                        disabled={!isModelValid}
                        type="primaryPc"
                        style={{ width: 300 }}
                        onClick={handleChangeManager}
                      >
                        <>{manager?.id ? '정보 수정하기' : '등록하기'}</>
                      </OhmossButton>
                    </div>
                  </>
                </ListBoxItem>
                <ListBoxItem style={{ marginRight: 20 }}>
                  <div>
                    <Row style={{ gap: 10, justifyContent: 'space-between' }}>
                      <Text type="H4" style={{ marginLeft: 20 }}>
                        관리공구 목록
                      </Text>
                      <OhmossButton
                        type="square"
                        style={{ width: 140, height: 40 }}
                        onClick={handleSelectDeselectAll}
                      >
                        전체 선택/해제
                      </OhmossButton>
                    </Row>
                    <AssignedDealsList
                      dealList={ongoingDeals}
                      showButton={false}
                      onSelected={(selectedDeals: LinkDto[]) => {
                        // targetDealsRef.current = selectedDeals;
                        setOngoingDeals(selectedDeals);
                      }}
                    />
                  </div>
                </ListBoxItem>
              </Row>
            </>
          ) : (
            <NoContentStyle>매니저를 선택해주세요.</NoContentStyle>
          )}
        </ListBoxContentsTemplate>
      </ListBoxWrapper>
    </div>
  );
};

export default ManagerListContainer;
