import React from 'react';
import { Avatar } from '@mui/material';
import { ChannelDto, DealInquiryThreadDto } from 'services/data-contracts';
import { Row } from 'components/commons/layouts';

import styled from 'styled-components';
import moment from 'moment';
import { useRootContext } from 'libs/hooks/useRootContext';

type InquiryThreadsProps = {
  customer?: ChannelDto;
  isCustomer?: boolean;
  threads?: DealInquiryThreadDto[]; // Array of inquiry threads
  channelType: ChannelDto['channelType'];
};

const InquiryThreads: React.FC<InquiryThreadsProps> = ({
  threads,
  channelType,
  customer,
  isCustomer,
}) => {
  const { myChannel } = useRootContext();
  const isMe = (thread: DealInquiryThreadDto) => {
    if (isCustomer === true) {
      return thread.sender === 'CUSTOMER';
    } else {
      if (channelType === 'MEMBER') {
        return thread?.memberUsername === myChannel?.channelName;
      } else if (channelType === 'SELLER') {
        return thread?.memberChannel?.channelName === myChannel?.channelName;
      }
      return false;
    }
  };

  const getDate = (thread: DealInquiryThreadDto, index: number) => {
    const currentDate = moment(thread?.createDate || new Date());
    if (index === 0)
      return (
        <DateStyle>
          <div>{currentDate?.format('YYYY-MM-DD')}</div>
        </DateStyle>
      );

    const previousDate = moment(threads?.[index - 1].createDate);

    if (currentDate?.format('YYYY-MM-DD') !== previousDate?.format('YYYY-MM-DD')) {
      return (
        <DateStyle>
          <div>{currentDate?.format('YYYY-MM-DD')}</div>
        </DateStyle>
      ); // 이전 아이템의 날짜와 다른 경우에만 출력
    } else {
      return null; // 같은 경우에는 숨기기 (null 반환)
    }
  };

  const SellerInfo = ({ thread }: { thread: DealInquiryThreadDto }) => {
    return (
      <div>
        <span style={{ fontSize: 14 }}>{thread?.memberChannel?.channelName}</span>
        <span />
      </div>
    );
  };

  return (
    <div style={{ background: '#efefef' }} className="opd-20">
      {threads?.map((thread, index) => (
        <>
          <>{getDate(thread, index)}</>

          <ul>
            <>
              {isMe(thread) ? (
                <ThreadStyle key={index} isMe>
                  <Row style={{ width: 'unset', gap: 10 }}>
                    <div>{thread.message}</div>
                    <>
                      <div>
                        <div style={{ fontSize: 14 }}>
                          {thread.sender === 'SELLER' ? (
                            <SellerInfo thread={thread} />
                          ) : (
                            thread?.memberUsername
                          )}
                        </div>
                        <div style={{ fontSize: 12 }}> {moment(thread.createDate).fromNow()}</div>
                      </div>
                    </>
                  </Row>
                </ThreadStyle>
              ) : (
                <ThreadStyle key={index} isMe={false}>
                  <Row style={{ width: 'unset', gap: 10 }}>
                    <div>{thread?.message}</div>
                    <>
                      {thread?.sender === 'CUSTOMER' ? (
                        <>
                          <Avatar
                            src={customer?.profile?.profileImage?.publicUrl}
                            style={{ width: 30, height: 30 }}
                          />
                          <div style={{ fontSize: 12 }}>{moment(thread?.createDate).fromNow()}</div>
                        </>
                      ) : (
                        <div>
                          <div style={{ fontSize: 14 }}>
                            {thread?.sender === 'SELLER' ? (
                              <SellerInfo thread={thread} />
                            ) : (
                              thread?.memberUsername
                            )}
                          </div>
                          <div style={{ fontSize: 12 }}>{moment(thread?.createDate).fromNow()}</div>
                        </div>
                      )}
                    </>
                  </Row>
                </ThreadStyle>
              )}
            </>
          </ul>
        </>
      ))}
    </div>
  );
};

const ThreadStyle = styled.div<{ isMe: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isMe ? 'flex-end' : 'flex-start')};
  margin-bottom: 10px;
  & > div > div:first-child {
    background: ${(props) => (props.isMe ? '#173300' : '#fff')};
    color: ${(props) => (props.isMe ? '#fff' : '#000')};
    border-radius: 10px;
    padding: 10px;
    max-width: 80%;
    text-align: right;
  }
  & > div > div {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  & > div > span {
    font-size: 12px;
    color: ${(props) => (props.isMe ? '#fff' : '#000')};
    opacity: 0.7;
  }
`;

const DateStyle = styled.div`
  padding: 5px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin: auto;
  text-align: center;
  > div {
    background: #f3f5f6;
    color: black;
    border-radius: 20px;
    display: inline-block;
    padding: 5px 20px;
    font-size: 14px;
    font-weight: 600;
  }
`;

export default InquiryThreads;
