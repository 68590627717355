import React, { useEffect, useState, useRef } from 'react';
import { ImageUploader } from 'components/commons';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { CustomFile, DealServiceItemModel, EventType, OpenGraphType } from 'types/CommonTypes';
import { IDealBlock, Layout } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { validationHelper } from 'controls/validationHelper';
import { toast } from 'react-toastify';
import { parseJSONAll } from 'libs/helper/utils';
import { ChannelBoardDto, DealOptionDto } from 'services/data-contracts';
import { useModelManagement } from 'libs/hooks/useModelUpdate';
import AddIcon from 'features/csTown/commonComponents/AddIcon';

import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import TitleForm from 'components/items/forms/TitleForm';
import WebEditor from 'components/commons/webEditor/Editor';
import UrlForm from 'components/items/forms/UrlForm';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import useOpenGraph from 'libs/hooks/useOpenGraph';
import TimeDealPicker from 'components/items/TimeDealPicker';
import OhmossAI from 'components/commons/AI/OhmossAI';
import useAI from 'libs/hooks/useAI';
import BlockController from 'controllers/BlockController';
import useLocalParams from 'libs/hooks/useLocalParams';
// import DealOptions from './DealOptions';
import { useRootContext } from 'libs/hooks/useRootContext';
import CategoryController from 'controllers/CategoryController';
import MenuItem from 'components/commons/menuItem/MenuItem';
import DealOptions from 'features/csTown/components/pc/dealList/components/DealOptions';
import ActionBox from 'features/csTown/components/pc/ActionBox';
import CommonAlign from '../../commonControl/CommonAlign';
import CommonSticker from '../../commonControl/CommonSticker';
import DealCustomerServices from './DealCustomerServices';
import DealLayout from '../../commonControl/DealLayout';
import DateRangePicker from 'components/items/DateRangePicker';
import Required from 'components/commons/Required';
import { FormControl, Select, MenuItem as SelectMenuItem } from '@mui/material';
import CategoryFormContainer from 'features/management/containers/category/CategoryFormContainer';

const Deal = ({ blockModel, onUpdate }: { blockModel?: IDealBlock; onUpdate: () => void }) => {
  const { uiStore } = useStore();
  const { myChannel } = useRootContext();
  const { categoryId } = useLocalParams();
  const serviceItemsDef: DealServiceItemModel[] = [
    { title: '전화번호', serviceType: 'multi', seq: 2, icon: '📞' },
    { title: '배송 문의', serviceType: 'multi', seq: 3, icon: '🚚' },
    { title: '공지사항', serviceType: 'multi', seq: 4, icon: '🔔' },
  ];

  /** Primitives */
  /** @description 초기값 */
  const defaultProps: Partial<IDealBlock> = {
    linkType: 'Deal',
    openNewWindow: true,
    useInquiry: false,
    isActive: true,
    layout: 'TypeA',
    useTimeDeal: false,
    items: serviceItemsDef,
  };

  const { getSiteInfo } = useOpenGraph();
  // const { getDealOptions, saveDealOptions } = useOhmossContext<LinkTypes<IDealBlock>>();

  const {
    initialModel,
    initialBinding,
    updateServiceModel,
    updateServiceModelMultiple,
    unmountModel,
    setState,
    stateModel,
    setMultipleState,
    serviceModel,
  } = useModelManagement<IDealBlock>();
  const { updateBlock, getDealOptions, saveDealOptions } = BlockController<IDealBlock>();

  const { getMyCategoryBoards } = CategoryController();

  const [localModel, setLocalModel] = useInitializeModel<Partial<IDealBlock>>(
    blockModel,
    undefined,
    initialBinding,
  );
  const [siteInfo, setSiteInfo] = useState<OpenGraphType>();
  const [dateValid, setDateValid] = useState<boolean | undefined>(true);

  /** @description 카테고리가 없을경우, 카테고리를 선택할수 있게 한다. */
  const [categories, setCategories] = useState<ChannelBoardDto[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>();

  /** Primitives */

  const { chat, extractText } = useAI();

  const [isValid, setIsValid] = useState<boolean>(false);
  const [dealOptions, setDealOptions] = useState<DealOptionDto[]>([]);

  const titleRef = useRef<any>(null);
  const editorRef = useRef<any>(null);

  const handleClickShowCustomInfo = () => {
    uiStore.bottomSheet.show({
      title: '고객 서비스 설정',
      children: <DealCustomerServices items={serviceModel?.items} actionType="BottomSheet" />,
      onConfirmed(result) {
        updateServiceModel('items', result);
      },
    });
  };

  const handleClickNewCategory = () => {
    uiStore.drawer.show({
      title: '새 페이지 만들기',
      children: <CategoryFormContainer />,
      onConfirmed: (result) => {
        setCategories([...categories, result]);
        setState('boardId', result?.id);
      },
    });
  };

  const handleClickShowDealOptions = () => {
    uiStore.bottomSheet.show({
      title: '제품옵션 설정',
      children: <DealOptions items={dealOptions} />,
      onConfirmed(result) {
        setDealOptions(result);
      },
    });
  };

  // const handleTimeRangeChange = (
  //   from: string,
  //   to: string,
  //   toHour?: number,
  //   type?: 'time' | 'day' | undefined,
  // ) => {
  //   if (from && to) {
  //     updateServiceModelMultiple({ startDate: from, endDate: to, timeType: type });
  //     setLocalModel('startDate', from);
  //     setLocalModel('endDate', to);
  //   }
  // };

  const handleTimeRangeChange = (event: EventType) => {
    if (event.start && event.end) {
      updateServiceModelMultiple({
        ...defaultProps,
        startDate: event.start,
        endDate: event.end,
        timeType: 'day',
        allDay: event.allDay,
      });
      setDateValid(event?.isValid);
      setLocalModel('startDate', event.start);
      setLocalModel('endDate', event.end);
    }
  };

  const handlePaste = async (value: string) => {
    if (value) {
      const result = (await getSiteInfo(value)) as any as OpenGraphType;
      if (result) {
        setSiteInfo(result);
        if (result.faviconUrl) {
          updateServiceModelMultiple({ title: result.title, faviconUrl: result.faviconUrl });
          setLocalModel('showIcon', true);
        } else {
          updateServiceModel('title', result.title);
        }

        if (titleRef.current && result.image) {
          const image = `<figure><img src=${result.image} alt=${result.title} /><br/></figure>`;
          editorRef.current.setData(result.description + image);
          titleRef.current.setValue(result.title);
          validation();
        }
      }
    }
  };

  const handleClickAI = () => {
    const content = editorRef.current.getData();
    if (content.length === 0) {
      toast('먼저 본문 내용을 입력해 주세요.', { type: 'warning' });
      return;
    } else if (content.length < 10) {
      toast('내용이 너무 짧습니다. 10자 이상 입력해 주세요.', { type: 'warning' });
      return;
    }
    const text = extractText(content);
    chat(text);
  };

  const setCategoryBoard = async () => {
    const boards = await getMyCategoryBoards();
    setCategories(boards);
  };

  const validation = () => {
    const titleValid = validationHelper.required(localModel?.title || titleRef.current.getValue());
    const urlValid = validationHelper.urlOrEmpty(localModel?.linkUrl);
    // const start = validationHelper.required(localModel?.startDate);
    // const end = validationHelper.required(localModel?.endDate);
    const newCategory = !Number.isNaN(categoryId) || typeof localModel?.boardId !== 'undefined';
    setIsValid(titleValid && urlValid && dateValid === true && newCategory);
  };

  const getBlockOptions = async () => {
    const data = (await getDealOptions(
      categoryId as number,
      blockModel?.id as number,
    )) as unknown as DealOptionDto[];
    setDealOptions(data ?? []);
  };

  useEffect(() => {
    if (localModel) {
      validation();
    }
  }, [localModel]);

  useEffect(() => {
    if (blockModel) {
      const blockItems = blockModel?.items?.map((item) => ({
        ...item,
        ...(parseJSONAll(item?.metadata) as DealServiceItemModel),
      }));
      const newBlockModel = { ...blockModel, items: blockItems } as IDealBlock;
      initialBinding(newBlockModel);
      setMultipleState({
        useInquiry: newBlockModel?.useInquiry,
        useTimeDeal: newBlockModel?.useTimeDeal,
      });

      getBlockOptions();
    }
  }, [blockModel]);

  useEffect(() => {
    if (Number.isNaN(categoryId)) {
      setCategoryBoard();
    }
    return () => {
      unmountModel();
    };
  }, []);

  return (
    <div style={{}}>
      <ItemSectionStyle>
        <ItemSectionTitle>
          <Row style={{ width: 'unset', justifyContent: 'flex-start' }}>
            <>
              <div style={{ marginRight: 5 }}>
                {siteInfo?.faviconUrl && localModel?.showIcon && (
                  <img src={siteInfo?.faviconUrl} style={{ width: 16 }} alt="" />
                )}
              </div>
            </>
            <div>링크주소</div>
          </Row>
        </ItemSectionTitle>

        <ItemContent>
          <UrlForm
            onPaste={handlePaste}
            onChangeValue={(value: string) => {
              updateServiceModel('linkUrl', value);
              setLocalModel('linkUrl', value);
            }}
            initialValue={initialModel?.linkUrl}
            label="링크주소"
          />
        </ItemContent>
        {siteInfo?.faviconUrl && (
          <MenuItemCheckBox
            initialValue={initialModel?.showIcon || true}
            onChangeValue={(value: boolean) => {
              setState('showIcon', value);
              setLocalModel('showIcon', value);
            }}
            title="링크 아이콘 보이기"
          />
        )}
      </ItemSectionStyle>
      <div style={{ marginBottom: 20 }}>
        <ImageUploader
          type="single"
          useCrop={false}
          useCircularCrop={false}
          initialValue={initialModel?.thumbnail}
          attachType="image"
          onSelected={(image: CustomFile) => {
            updateServiceModel('thumbnail', image);
          }}
        />
      </div>
      <ItemSectionStyle
        style={{ flexDirection: 'row', flexWrap: 'wrap', display: 'flex', gap: 20 }}
      >
        <div style={{ flex: 1 }}>
          <ItemSectionTitle>
            타이틀
            <Required />
          </ItemSectionTitle>
          <ItemContent>
            <TitleForm
              ref={titleRef}
              onChangeValue={(value: string) => {
                updateServiceModel('title', value);
                setLocalModel('title', value);
              }}
              initialValue={initialModel?.title || serviceModel?.title}
              placeHolder="타이틀을 작성해 주세요"
              label="타이틀"
            />
          </ItemContent>
        </div>

        {/* <div style={{ flex: 1 }}>
          <ItemSectionTitle>등록할 카테고리</ItemSectionTitle>
          <ItemContent>
            {categories?.length > 0 && (
              <>
                <FormControl fullWidth>
                  <Select
                    defaultValue={categoryId || categories[0].id}
                    onChange={(e, data: any) => {
                      // setSelectedCategory(data?.props?.value);
                      updateServiceModel('boardId', data?.props?.value);
                    }}
                  >
                    {categories.map((category: ChannelBoardDto) => (
                      <MenuItem value={category?.id}>{category.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          </ItemContent>
        </div> */}
      </ItemSectionStyle>
      <ItemSectionStyle>
        <ItemSectionTitle>
          <Row style={{ justifyContent: 'space-between' }}>
            <Row style={{ width: 'unset', justifyContent: 'flex-start' }}>
              <div>등록할 페이지</div>
              <Required />
            </Row>
            <AddIcon onClick={handleClickNewCategory} />
          </Row>
        </ItemSectionTitle>
        <ItemContent style={{ marginTop: 0 }}>
          {categories?.length > 0 ? (
            <>
              <FormControl fullWidth>
                <Select
                  defaultValue={blockModel?.boardId}
                  onChange={(e, data: any) => {
                    setLocalModel('boardId', data?.props?.value);
                    updateServiceModel('boardId', data?.props?.value);
                  }}
                >
                  {categories
                    ?.filter((category) => category.boardType === 'LINK')
                    .map((category: ChannelBoardDto) => (
                      <SelectMenuItem value={category?.id}>{category.name}</SelectMenuItem>
                    ))}
                </Select>
              </FormControl>
            </>
          ) : (
            <div>
              <OhmossButton type="square" onClick={handleClickNewCategory}>
                새 페이지 만들기
              </OhmossButton>
            </div>
          )}
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <WebEditor
          ref={editorRef}
          onEditorChange={(contents: string) => {}}
          initialValue={initialModel?.content}
        />
        <div style={{ marginTop: 10 }}>
          <OhmossAI onClick={handleClickAI} />
        </div>
      </ItemSectionStyle>
      <CommonSticker
        initialValue={initialModel?.stickers}
        initialPosition={initialModel?.stickerPosition}
        onChange={(stickers: any) => {
          updateServiceModel('stickers', stickers);
        }}
        onChangePosition={(position: 'top' | 'bottom') => {
          updateServiceModel('stickerPosition', position);
        }}
      />

      <ItemSectionStyle>
        <ItemSectionTitle>공구 기간</ItemSectionTitle>

        <ItemContent>
          <DateRangePicker
            useAllday
            event={{
              start: serviceModel?.startDate,
              end: serviceModel?.endDate,
              allDay: serviceModel?.allDay,
            }}
            timeRange={handleTimeRangeChange}
          />
          {/* <TimeDealPicker
            mode={initialModel?.id ? 'EDIT' : 'NEW'}
            timePickerType={initialModel?.timeType ?? 'time'}
            start={initialModel?.startDate}
            end={initialModel?.endDate}
            timeRange={handleTimeRangeChange}
          /> */}
        </ItemContent>
        {/* )} */}
      </ItemSectionStyle>
      <ItemSectionStyle>
        <MenuItemCheckBox
          initialValue={initialModel?.useInquiry}
          onChangeValue={(value: boolean) => {
            setState('useInquiry', value);
          }}
          title="문의하기 사용"
        />
        {stateModel?.useInquiry && (
          <MenuItem
            title={`제품 옵션 설정 (${dealOptions?.length}개의 옵션)`}
            onClick={() => {
              handleClickShowDealOptions();
            }}
          />
        )}
        <MenuItem
          title="고객 서비스 설정"
          onClick={() => {
            handleClickShowCustomInfo();
          }}
        />
      </ItemSectionStyle>
      <DealLayout
        initialValue={initialModel?.layout || 'TypeA'}
        onChange={(layout: Layout) => updateServiceModel('layout', layout)}
      />
      <CommonAlign
        initialValue={initialModel?.contentAlign || 'center'}
        title="타이틀 정렬"
        onChange={(value: string) => {
          updateServiceModel('contentAlign', value);
        }}
      />
      <div style={{ paddingBottom: 20 }}>
        <ActionBox
          style={{ marginTop: 60 }}
          isValid={isValid}
          okText="저장"
          onOk={async () => {
            if (serviceModel) {
              updateServiceModel('content', editorRef.current.getData());
              const result = (await updateBlock(serviceModel)) as unknown as IDealBlock;
              if (result) {
                onUpdate?.();
                if ((myChannel?.id && categoryId) || (serviceModel.boardId && result?.id)) {
                  await saveDealOptions(
                    categoryId || (serviceModel.boardId as number),
                    result?.id as number,
                    dealOptions,
                  );
                  uiStore.modal.confirm();
                }
              }
            }
          }}
          onCancel={() => {
            uiStore.modal.close();
          }}
        />
        {/* <OhmossButton
          type="box"
          style={{ color: '#000' }}
          onClick={() => {
            uiStore.modal.close();
          }}
        >
          취소
        </OhmossButton>
        <OhmossButton
          onClick={async () => {
            if (serviceModel) {
              updateServiceModel('content', editorRef.current.getData());
              const result = (await updateBlock(serviceModel)) as unknown as IDealBlock;
              if (result) {
                onUpdate?.();
                if ((myChannel?.id && categoryId) || (serviceModel.boardId && result?.id)) {
                  await saveDealOptions(
                    categoryId || (serviceModel.boardId as number),
                    result?.id as number,
                    dealOptions,
                  );
                  uiStore.modal.confirm();
                }
              }
            }
          }}
          disabled={!isValid}
        >
          적용
        </OhmossButton> */}
      </div>
    </div>
  );
};

export default Deal;
