import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useNavigation from 'hooks/useNavigation';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { CustomUserProfile } from 'types/CommonTypes';
import GnbLanguage from 'features/commonFeatures/navigationBar/containers/GnbLanguage';
import MobileDrawer from 'components/commons/MobileDrawer';
import useScreenType from 'libs/hooks/useScreenType';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import _ from 'lodash';

/**
 * @deprecated
 */
const Gnb = observer(() => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [languageOpen, setLanguageOpen] = useState<boolean>(false);
  const [myProfile, setMyProfile] = useState<CustomUserProfile>();

  const screenType = useScreenType();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const { navigate } = useNavigation();

  const menuListNoToken = [
    {
      title: t('signUp'),
      onClick: () => {
        navigate('/sign-up');
        handleClickCloseMenu();
      },
    },
    {
      title: t('logIn'),
      onClick: () => {
        navigate('/login');
        handleClickCloseMenu();
      },
    },
    {
      title: t('languages'),
      onClick: () => {
        setLanguageOpen(true);
      },
    },
  ];

  const handleGoMyProfile = () => {
    navigate('/');
    // navigate(`/${meStore.myProfile?.userName}`);
  };

  const handleClickOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setLanguageOpen(false);
  };

  const handleClickCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = (languageOpen: boolean) => {
    setLanguageOpen(languageOpen);
  };

  const checkMe = async () => {
    const me = await localStorage.getItem('me');
    if (me === null) {
      // navigate('/login');
    } else {
      const _me = JSON.parse(me);
      setMyProfile(_me?.profile);
    }
  };
  useEffect(() => {
    checkMe();
  }, []);

  return (
    <GnbLayoutStyle>
      {screenType === 'window' ? (
        <>
          <LogoContainerStyle onClick={handleGoMyProfile}>
            <LogoTitleStyle>
              <span>
                <img
                  style={{ opacity: 0.8 }}
                  width={110}
                  src={
                    pathname === '/admin' || pathname === '/'
                      ? '/images/ohmoss_logo_white.svg '
                      : '/images/ohmoss_logo.svg'
                  }
                />
              </span>
              <span style={{ fontSize: 15, fontWeight: 500, color: 'orange' }}> Beta2 </span>
            </LogoTitleStyle>
          </LogoContainerStyle>
          <MenuContainerStyle>
            {myProfile !== null ? (
              <div />
            ) : (
              <>
                <LanguageStyle>
                  <GnbLanguage />
                </LanguageStyle>
                <ChildMenuStyle
                  isActivated={pathname === '/sign-up'}
                  onClick={() => {
                    navigate('/sign-up');
                  }}
                >
                  <MenuNameStyle>{t('signUp')}</MenuNameStyle>
                </ChildMenuStyle>
                <ChildMenuStyle
                  isActivated={pathname === '/login'}
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  <MenuNameStyle>{t('logIn')}</MenuNameStyle>
                </ChildMenuStyle>
              </>
            )}
          </MenuContainerStyle>
        </>
      ) : (
        <MobileMenuStyle>
          <ButtonStyle
            style={{ color: '#fff' }}
            onClick={handleClickOpenMenu}
            size="large"
            sx={{ height: '70px', color: 'black' }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MenuIcon />
          </ButtonStyle>
          <MobileDrawer
            open={open}
            languageOpen={languageOpen}
            menuList={menuListNoToken}
            handleClickOpen={handleClickOpen}
            handleClose={handleClickCloseMenu}
          />
        </MobileMenuStyle>
      )}
    </GnbLayoutStyle>
  );
});

const GnbLayoutStyle = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  height: 60px;
  margin-top: auto;
  /* background-color: white; */
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  @media ${(props) => props.theme.media.mobile} {
    background-color: transparent;
    width: 100px;
  }
`;

const LogoContainerStyle = styled.div`
  width: 15%;
  text-align: center;
  min-width: 100px;
  line-height: 55px;
  float: left;
  cursor: pointer;
  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
    margin: 0 10px 0 10px;
    text-align: start;
    float: none;
  }
  @media ${(props) => props.theme.media.desk} {
    margin: 0 10px 0 32px;
  }
`;

const LogoTitleStyle = styled.p`
  display: inline;
  font-family: 'Barlow';
  font-size: 25px;
  font-weight: 600;
`;

const MenuContainerStyle = styled.div`
  display: flex;
  /* background-color: white; */
  float: right;
  @media ${(props) => props.theme.media.mobile} {
    display: flex;
    width: 100%;
  }
`;

const LanguageStyle = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 1;
  width: 45px;
  height: 45px;
  right: 160px;
  margin: 10px 20px 20px 20px;
`;

const ChildMenuStyle = styled.div<{ isActivated: boolean }>`
  width: 15%;
  min-width: 90px;
  height: ${(props) => (props.isActivated ? '57px' : '60px')};
  text-align: center;
  float: left;
  cursor: pointer;
  border-bottom: ${(props) => (props.isActivated ? '3px solid #3e64ea' : '')};
  &:hover {
    background-color: #3e64ea;
    color: #fff;
  }
`;

const MenuNameStyle = styled.p`
  margin: 20px 0 0 0;
`;

const ProfileContainerStyle = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  float: right;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  @media ${(props) => props.theme.media.mobile} {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const MobileMenuStyle = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonStyle = styled(Button)`
  @media ${(props) => props.theme.media.mobile} {
    color: white;
  }
`;

const AvatarStyle = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: #efefef;
  margin: 0 10px 0 10px;
  float: left;
`;

export default Gnb;
