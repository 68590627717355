import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --primary-color: #173300;
    --secondary-color: #2ecc71;
    --padding: 10px;
    --margin: 20px;
    --gnb-height:85px;
    --border-radius:10px;
    --border-color:#ebebeb;
    --background:#F3F4F1;
    --max-width:550px;
    --pc-button-width:300px;
    --pc-max-width:1200px;
    --pc-max-modal-width:850px;
  }
    .ios {
    --platform-height: -webkit-fill-available;
  }

  .android {
    --platform-height: 100%;
  }
`;

export default GlobalStyle;
