import React, { useEffect, useState, CSSProperties } from 'react';
import { ItemContainerStyle } from 'features/adminFeatures/template/components/ItemContainerStyle';
import { LinkDto } from 'services/data-contracts';
import styled from 'styled-components';
import _ from 'lodash';
import { useStore } from 'stores/StoreHelper';
import ModalHeader from 'features/adminFeatures/template/components/ModalHeader';
import { isMobile } from 'react-device-detect';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { observer } from 'mobx-react';

type defaultProps = {
  item: LinkDto;
  mode?: 'create' | 'edit';
  onDelete?: (item: LinkDto) => void;
};

const PageLink = observer(({ item, mode, onDelete }: defaultProps) => {
  const IMAGE_WIDTH = 50;
  const { uiStore, channelStore } = useStore();
  const [overrideStyle, setOverrideStyle] = useState<CSSProperties>();

  const PageComponent = () => {
    return (
      <>
        {/* <div style={{ marginLeft: 20 }}>
          <ModalHeader
            item={item}
            onEdit={(item: LinkDto) => {}}
            onDelete={(item: LinkDto) => {}}
          />
        </div> */}
        <PageStyle
          dangerouslySetInnerHTML={{
            __html: item.content as string,
          }}
        >
          {/* <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: item.content as string,
            }}
          /> */}
        </PageStyle>
      </>
    );
  };

  const handleClickPage = () => {
    uiStore.universalModal.show({
      bottomSheetOptions: {
        topPosition: 20,
      },
      modalOption: {
        style: { maxWidth: 800, paddingBottom: 20 },
      },
      useDefaultButtons: true,
      title: item.title,
      children: (
        <>
          <PageComponent />
        </>
      ),
    });

    // if (isMobile) {
    //   uiStore.universalModal.show({
    //     bottomSheetOptions: {
    //       topPosition: 20,
    //     },
    //     useDefaultButtons: true,
    //     title: item.title,
    //     children: (
    //       <>
    //         <PageComponent />
    //       </>
    //     ),
    //   });
    // } else {
    //   uiStore.modal.show({
    //     title: item.title,

    //     style: { maxWidth: 800, paddingBottom: 20 },
    //     useDefaultButtons: true,
    //     children: <PageComponent />,
    //   });
    // }
  };

  useEffect(() => {
    if (typeof item !== 'undefined') {
      if (channelStore.myStyle?.style) {
        const parsedStyle = JSON.parse(channelStore.myStyle.style);
        setOverrideStyle(parsedStyle);
      }
    }
  }, [item, channelStore.myStyle]);

  return (
    <ItemContainerStyleOverride onClick={handleClickPage} style={overrideStyle} hover={true}>
      <ItemStyle rel="noreferrer" href={item?.linkUrl} target="_blank">
        <LinkContainerTextStyle>
          <LinkTextStyle>
            {item.thumbnail !== null && (
              <div
                style={{
                  height: IMAGE_WIDTH,
                  width: IMAGE_WIDTH,
                  float: 'left',
                  position: 'relative',
                }}
              >
                <img
                  alt=""
                  src={item.thumbnail?.publicUrl}
                  width={IMAGE_WIDTH}
                  height={IMAGE_WIDTH}
                  style={{ borderRadius: 8 }}
                />
              </div>
            )}
            <div
              style={{
                display: 'table',
                position: 'absolute',
                left: typeof item.thumbnail !== 'undefined' ? IMAGE_WIDTH : 10,
                right: typeof item.thumbnail !== 'undefined' ? IMAGE_WIDTH : 10,
                width: typeof item.thumbnail !== 'undefined' ? 'calc(100% - 100px)' : '95%',
                height: 50,
              }}
            >
              <LinkTitle style={{ fontFamily: overrideStyle?.fontFamily }}>{item?.title}</LinkTitle>
            </div>
          </LinkTextStyle>
        </LinkContainerTextStyle>
      </ItemStyle>
      {mode === 'edit' && (
        <RowContainerStyle>
          <div>E</div>
          <div
            style={{
              cursor: 'pointer',
              width: 20,
            }}
            onClick={() => {
              if (typeof onDelete !== 'undefined') {
                onDelete(item);
              }
            }}
          >
            <img src="/image/trash.png"></img>
          </div>
        </RowContainerStyle>
      )}
    </ItemContainerStyleOverride>
  );
});

const PageStyle = styled.div`
  padding: 10px 40px;
  width: 100%;
  > p > img {
    max-width: 100% !important;
  }
  @media ${(props) => props.theme.media.mobile} {
    padding: 10px 10px;
    max-width: calc(100% - 20px);
  }
`;

const ItemStyle = styled.a`
  width: 100%;
  text-decoration: none;
  cursor: pointer;
`;

const LinkContainerTextStyle = styled.div`
  display: table;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const LinkTextStyle = styled.div`
  border-radius: 10px;
  text-align: center;
  word-wrap: break-word;
  min-height: 50px;
  padding: 5px;
  justify-content: space-between;
  display: flex;
  position: relative;
`;

const LinkTitle = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 16px;
`;

const RowContainerStyle = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
`;

const ItemContainerStyleOverride = styled(ItemContainerStyle)<{ hover: boolean }>``;

export default PageLink;
