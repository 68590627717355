import { makeAutoObservable } from 'mobx';
import { PartnershipService } from 'services/PartnershipService';

import _ from 'lodash';
import { HttpClient } from 'services/http-client';
import i18n from 'i18n';

export interface isValid {
  isValid: boolean;
}

class PartnershipStore {
  t = i18n.t;

  constructor() {
    makeAutoObservable(this);
  }

  api: PartnershipService<unknown> = new PartnershipService(new HttpClient());

  *getPartners(channelId: number) {
    const { response } = yield this.api.getPartners(channelId);
    return response.status < 300 ? response.data : [];
  }

  /**
   * @description 파트너로부터 위임된 모든공구 목록
   * @param channelId
   * @returns
   */
  *getDelegatedDeals(channelId: number) {
    const { response } = yield this.api.getAllPartnerDeals(channelId);
    return response.status < 300 ? response.data : [];
  }

  /**
   * @description 파트너로부터 위임된 공구 목록
   * @param channelId
   * @param partnerId
   * @returns
   */
  *getDealsByPartner(channelId: number, partnerId: number) {
    const { response } = yield this.api.getPartnerDeals(channelId, partnerId);
    return response.status < 300 ? response.data : [];
  }

  /**
   * @description 공구 상세 조회
   * @param channelId
   * @param dealId
   * @returns
   */
  *getDealById(channelId: number, dealId: number) {
    const { response } = yield this.api.getPartnerDeal(channelId, dealId);
    return response.status < 300 ? response.data : [];
  }

  /**
   * @description 딜에 속한 문의 목록을 취득하여 반환한다.
   * @param channelId
   * @param dealId
   */
  *getDealInquiries(channelId: number, dealId: number) {
    const { response } = yield this.api.getInquiries(channelId, dealId);
    return response.status < 300 ? response.data : [];
  }

  /**
   * @description 문의의 쓰레드를 취득하여 반환한다.
   * @param channelId
   * @param dealId
   * @param inquiryId
   * @returns
   */
  *getInquiryThreads(channelId: number, dealId: number, inquiryId: number) {
    const { response } = yield this.api.getInquiryThreads(channelId, dealId, inquiryId, {
      sort: ['createDate,ASC'],
      size: 1000,
    });
    return response.status < 300 ? response.data : [];
  }

  *getCSSummary(channelId: number) {
    const { response } = yield this.api.getInquirySummaryForPartnerChannel(channelId as number);
    return response.status < 300 ? response.data : {};
  }

  *getInquiriesByPartnerChannel(channelId: number, status?: 'PENDING' | 'ANSWERED' | 'CLOSED') {
    const { response } = yield this.api.getInquiriesByPartnerChannel(channelId, { status });
    return response.status < 300 ? response.data : [];
  }

  unMount() {}
}

export default PartnershipStore;
