import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { BrowserType } from 'types/CommonTypes';

const ManagementLayout = ({ style, children }: { style?: CSSProperties; children: any }) => {
  return (
    <ManagementLayoutStyle style={style} browserType={window.browserType}>
      {children}
    </ManagementLayoutStyle>
  );
};

const ManagementLayoutStyle = styled.div<{ browserType?: BrowserType }>`
  background: var(--background);
  ${(props) => {
    if (props.browserType === 'ios') {
      return iosStyle;
    } else if (props.browserType === 'android') {
      return androidStyle;
    } else if (props.browserType === 'pc') {
      return pcStyle;
    }
  }}
  display: flex;

  max-width: 550px;
  width: 100%;
  flex-direction: column;

  @media ${(props) => props.theme.media.mobile} {
    background: unset;
  }

  margin: auto;
  > div {
    width: 100%;
  }
`;

const iosStyle = css`
  height: -webkit-fill-available;
`;

const androidStyle = css`
  height: 100%;
`;

const pcStyle = css`
  height: 100vh;
  /* padding-top: 80px; */
  display: flex;
  background: transparent;
  @media ${(props) => props.theme.media.mobile} {
    padding-top: 0px;
  }
`;

export default ManagementLayout;
