import PaddingLayout from 'features/mainFeatures/terms/components/PaddingLayout';
import TermsSection from 'features/mainFeatures/terms/components/TermsSection';
import TermsTitle from 'features/mainFeatures/terms/components/TermsTitle';
import React from 'react';

const ServiceTerms = () => {
  return (
    <PaddingLayout>
      <TermsSection>
        <TermsTitle>제 1장 총칙</TermsTitle>
      </TermsSection>
      <TermsSection>
        <TermsTitle>제1조(목적)</TermsTitle>
        <ol>
          이 약관은 엔비에이치디 회사(전자상거래 사업자)가 운영하는{' '}
          {process.env.REACT_APP_SERVEICE_NAME}에서 제공하는 인터넷 관련 서비스(이하 “서비스”라
          한다)를 이용함에 있어 사이버 {process.env.REACT_APP_SERVEICE_NAME}과 이용자의 권리, 의무
          및 책임사항을 규정함을 목적으로 합니다.
        </ol>
        <ol>
          ※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을
          준용합니다.」
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>제2조(정의)</TermsTitle>
        <ol>
          <li>
            “{process.env.REACT_APP_SERVEICE_NAME}”이란 엔비에이치디 회사가 재화 또는 용역(이하
            “재화 등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화
            등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버
            {process.env.REACT_APP_SERVEICE_NAME}을 운영하는 사업자의 의미로도 사용합니다.
          </li>
          <li>
            “이용자”란 “{process.env.REACT_APP_SERVEICE_NAME}”에 접속하여 이 약관에 따라 “
            {process.env.REACT_APP_SERVEICE_NAME}”이 제공하는 서비스를 받는 회원 및 비회원을
            말합니다.
          </li>
          <li>
            ‘회원’이라 함은 “{process.env.REACT_APP_SERVEICE_NAME}”에 회원등록을 한 자로서,
            계속적으로 “{process.env.REACT_APP_SERVEICE_NAME}”이 제공하는 서비스를 이용할 수 있는
            자를 말합니다.
          </li>
          <li>
            ‘비회원’이라 함은 회원에 가입하지 않고 “{process.env.REACT_APP_SERVEICE_NAME}”이
            제공하는 서비스를 이용하는 자를 말합니다.
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>제3조 (약관 등의 명시와 설명 및 개정)</TermsTitle>
        <ol>
          <li>
            “{process.env.REACT_APP_SERVEICE_NAME}”은 이 약관의 내용과 상호 및 대표자 성명, 영업소
            소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함),
            전화번호․모사전송번호․전자우편주소, 사업자등록번호, 통신판매업 신고번호,
            개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 {process.env.REACT_APP_SERVEICE_NAME}
            의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼
            수 있도록 할 수 있습니다.
          </li>
          <li>
            “{process.env.REACT_APP_SERVEICE_NAME}은 이용자가 약관에 동의하기에 앞서 약관에 정하여져
            있는 내용 중 청약철회․배송책임․환불조건 등과 같은 중요한 내용을 이용자가 이해할 수
            있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.
          </li>
          <li>
            “{process.env.REACT_APP_SERVEICE_NAME}”은 「전자상거래 등에서의 소비자보호에 관한
            법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」,
            「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한
            법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수
            있습니다.
          </li>
          <li>
            “livelink”이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께
            livelink의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만,
            이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고
            공지합니다. 이 경우 “livelink“은 개정 전 내용과 개정 후 내용을 명확하게 비교하여
            이용자가 알기 쉽도록 표시합니다.
          </li>
          <li>
            “livelink”이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만
            적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다.
            다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한
            개정약관의 공지기간 내에 “livelink”에 송신하여 “livelink”의 동의를 받은 경우에는
            개정약관 조항이 적용됩니다.
          </li>
          <li>
            이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의
            소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래
            등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>제2장 회원 가입 및 서비스의 내용 </TermsTitle>
      </TermsSection>
      <TermsSection>
        <TermsTitle>제4조 (서비스 이용 계약)</TermsTitle>
        <ol>
          <li>
            회사가 제공하는 “서비스를 이용하기 위한 이용 계약”은 회사의 서비스를 이용하고자 하는
            자의 회원가입 신청에 대하여 회사가 승낙함으로써 성립합니다.{' '}
          </li>
          <li>
            전항의 회원가입 신청을 하기 위해서는 회사가 정한 온라인 회원가입 신청서에 필수
            기재사항을 입력하고 약관 동의 및 본인인증 절차를 완료하여야 합니다.{' '}
          </li>
          <li>
            회원가입 신청을 위해 필수적으로 기재한 항목이 변경된 경우(폐업 등의 사유 발생 포함) 해당
            회원은 위 변경 사실을 직접 수정하거나 회사에 이메일 문의 등을 통해 통지하여야 하며,
            통지하지 않음으로 인하여 발생한 회원의 불이익에 관하여는 회원이 책임을 집니다.{' '}
          </li>
          <li>
            회사가 정한 필수 기재사항을 입력하지 않거나 허위의 정보를 입력할 경우 회원가입 신청이
            제한될 수 있습니다.{' '}
          </li>
          <li>
            회사는 회사의 서비스를 이용하기 위해 회원가입 신청을 한 자가 본 조 제2항에서 정한 필수
            기재사항을 정확하게 입력하고 본 약관에 동의한 경우 원칙적으로 서비스의 이용을
            승낙합니다. 다만, 회사는 다음과 같은 사유가 있는 경우 회원가입 신청의 승낙을 거절 또는
            보류할 수 있고, 이 경우 회사는 승낙 거절 또는 보류의 사유와 승낙에 필요한 추가 요청 정보
            등 관련 사항을 통지합니다.{' '}
            <ol type="a">
              <li>회사의 설비에 여유가 없는 경우 </li>
              <li>회사의 서비스 제공에 기술적인 문제가 있는 경우 </li>
              <li>
                회원가입 신청자가 본 약관에 따라 회원 자격을 상실하거나 이용 계약이 해지된 적이 있는
                경우 (단, 회사의 회원 재가입 승낙을 얻은 경우는 예외){' '}
              </li>
              <li>타인의 명의를 도용한 경우 </li>
              <li>허위의 정보를 기재한 경우 </li>
              <li>기타 회사의 합리적인 판단 하에 필요하다고 인정하는 경우 </li>
            </ol>
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle> 제5조 (서비스 변경 및 중단)</TermsTitle>
        <ol>
          <li>
            회사는 서비스의 제공을 위한 별도의 계약 종료, 신규 서비스 개시, 기존 서비스 개정 등의
            사유로 서비스의 내용을 변경할 수 있습니다.{' '}
          </li>
          <li>
            회사는 서비스의 내용에 변경이 있는 경우 변경된 서비스가 적용되기 30일 이전부터 적용 일자
            이후 상당한 기간까지 어플리케이션 공지사항을 통해 회원들에게 변경된 서비스의 내용을 사전
            공지합니다. 다만 회원의 거래와 관련하여 중대한 영향을 미치는 변경 사항이 있는 경우
            회원에게 별도로 통지합니다.{' '}
          </li>
          <li>
            회사는 연중무휴, 24시간 서비스를 제공함을 원칙으로 하되, 컴퓨터 등 정보통신설비의 보수,
            점검, 교체, 고장 등 운영상 상당한 이유가 있는 경우 회원에게 통지한 후 서비스의 제공을
            일시적으로 중단할 수 있습니다. 다만, 회사가 사전에 서비스의 중단을 통지할 수 없었던
            부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.{' '}
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>제6조 (이용 계약의 종료 및 회원 자격 상실)</TermsTitle>
        <ol>
          <li>
            회원은 언제든지 회사에 탈퇴의 의사를 통지함으로써 이용 계약을 해지할 수 있습니다.{' '}
          </li>
          <li>
            회사는 원칙적으로 전항의 이용 계약 해지의 의사 표시를 확인한 후 해당 회원의 계정을
            삭제하여 탈퇴 절차를 즉시 완료해야 합니다. 다만, 다음 각 호의 사유가 있는 경우 이용 계약
            해지 절차를 보류할 수 있습니다.{' '}
            <ol type="a">
              <li>
                이용계약 해지의 의사표시를 한 자가 회원 당사자인지 여부를 확인할 수 없는 경우{' '}
              </li>
              <li>이용계약 해지의 의사표시를 한 회원에 관한 사기 및 분쟁문제가 남아있는 경우 </li>
            </ol>
          </li>
          <li>
            회사는 본 약관 또는 운영정책에 따라 특정한 사유가 발생한 경우 회원의 자격을 박탈하고
            이용계약을 해지할 수 있습니다.{' '}
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>제7조 (개인정보보호 및 개인정보 제공 동의 등) </TermsTitle>
        <ol>
          <li>
            회사는 개인정보처리방침에 따라 회원가입시 회원이 동의하고, 회원이 제공한 정보를 수집 및
            보관합니다.{' '}
          </li>
          <li>
            회사는 회원의 개인정보 수집시 서비스제공에 필요한 범위 내에서 최소한의 개인정보만을
            수집합니다.{' '}
          </li>
          <li>
            이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를
            받습니다.{' '}
          </li>
          <li>
            회사는 회원의 회원가입 신청 및 판매자 등록을 위해 제공받은 정보를 다음의 예외적인 경우가
            아닌 한 원활한 서비스 제공 이외의 목적으로 사용하거나 이용자의 동의없이 제3자에게
            제공하지 않습니다.{' '}
            <ol type="a">
              <li>
                관련 법령에 근거하여 회원 정보의 이용 및 제3자에 대한 정보제공을 허용하는 경우
              </li>
              <li>회사의 약관 및 운영 정책 등에 따라 회원의 동의를 얻은 경우 </li>
              <li>
                회사는 회원의 개인정보를 보호하기 위하여 개인정보처리방침을 수립하여 이를 따르고,
                개인정보보호책임자를 지정합니다.{' '}
              </li>
            </ol>
          </li>
          <li>
            제3항과 제4항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의
            신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한
            정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진
            및 정보보호 등에 관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며
            이용자는 언제든지 이 동의를 철회할 수 있습니다.{' '}
          </li>
          <li>
            회원은 언제든지 “livelink”이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을
            요구할 수 있으며 “livelink”은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다.
            이용자가 오류의 정정을 요구한 경우에는 “livelink”은 그 오류를 정정할 때까지 당해
            개인정보를 이용하지 않습니다.{' '}
          </li>
          <li>
            “livelink”은 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로
            제한하여야 하며 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로
            인한 이용자의 손해에 대하여 모든 책임을 집니다.{' '}
          </li>
          <li>
            “livelink” 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은
            목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.{' '}
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>제3장 회원의 의무와 책임 </TermsTitle>
      </TermsSection>
      <TermsSection>
        <TermsTitle>제8조 (회원의 의무와 책임) </TermsTitle>
        <ol>
          <li>
            회원은 본 약관, 운영정책, 이용안내 사항과 관련 법령을 준수하여야 하며 기타 회사 업무에
            방해되는 행위를 하여서는 안됩니다.{' '}
          </li>
          <li>
            회원은 다음의 행위를 해서는 안됩니다.
            <ol type="a">
              <li>가입신청 또는 계정변경시 허위 내용의 등록 </li>
              <li>타인의 정보 도용 </li>
              <li>“livelink”에 게시된 정보의 변경 </li>
              <li>“livelink”이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시 </li>
              <li>“livelink” 기타 제3자의 저작권 등 지적재산권에 대한 침해 </li>
              <li>“livelink” 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위 </li>
              <li>
                외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 livelink에 공개
                또는 게시하는 행위{' '}
              </li>
            </ol>
          </li>
          <li>
            회사는 회원이 본 약관 및 운영정책에서 제한 또는 금지하는 행위를 한 경우 해당 회원이
            회사로부터 부수적으로 제공받은 혜택의 전부 또는 일부를 회수하거나 계정 접속을 제한 또는
            회원 자격을 박탈할 수 있고, 해당 회원의 라이프링크를 임시 삭제 또는 영구 삭제할 수
            있습니다. 이에 따라 회원 자격을 박탈당한 자의 재가입은 제한될 수 있습니다.{' '}
          </li>
          <li>
            회사가 전항에 따른 조치를 취할 경우 원칙적으로 사전에 해당 회원에게 통지하나 긴급을
            요하는 등 부득이한 사유가 있는 경우 선 조치 후 사후 통지할 수 있습니다.{' '}
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>제9조 (회원의 ID 및 비밀번호에 대한 의무) </TermsTitle>
        <ol>
          <li>
            제7조의 경우를 제외하고는 회원은 ID, 비밀번호 그리고 이메일 주소에 관한 관리책임이
            있습니다.{' '}
          </li>
          <li>
            회원은 자신의 ID, 비밀번호 그리고 이메일 주소를 제3자에게 이용하게 해서는 안됩니다.{' '}
          </li>
          <li>
            회원이 자신의 ID 및 비밀번호, 이메일 주소를 도난당하거나 제3자가 사용하고 있음을 인지한
            경우에는 바로 “livelink”에 통보하고 “livelink”의 안내가 있는 경우에는 그에 따라야
            합니다.{' '}
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>제4장 회사의 의무 </TermsTitle>
      </TermsSection>
      <TermsSection>
        <TermsTitle>제10조 (운영) </TermsTitle>
        <ol>
          <li>
            회사는 회원에게 건전하고 안전한 서비스를 원활하게 제공하기 위해 운영정책을 수립하고
            운영합니다.{' '}
          </li>
          <li>
            회사는 회원의 동의 없이 회원의 정보를 수집 또는 활용하거나 제3자에게 제공하지 않습니다.{' '}
          </li>
          <li>
            회사는 회사가 제공하는 서비스가 제공목적에 맞게 이용되고 있는지 수시로 확인할 수 있으며,
            이용 목적에 위반되거나 벗어난 행위, 해를 가하는 내용이 확인되는 즉시, 해당 회원에게
            소명을 요청할 수 있으며, 소명 내용이 합당하지 않는다 판단될 시, 이용에 제한을 둘 수
            있습니다.{' '}
          </li>
          <li>
            회사는 판매자가 등록한 상품의 내용과 거래조건에 대해서 어떠한 보증이나 대리를 하지
            않습니다.{' '}
          </li>
          <li>
            회사는 서비스의 제공을 위한 별도의 계약 종료, 신규 서비스 개시, 기존 서비스 개정 등의
            사유로 서비스의 내용을 변경할 수 있습니다.{' '}
          </li>
          <li>회사는 회사가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다. </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>제11조 (연결“livelink”과 피연결“livelink” 간의 관계) </TermsTitle>
        <ol>
          <li>
            상위 “livelink”과 하위 “livelink”이 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및
            동화상 등이 포함됨)방식 등으로 연결된 경우, 전자를 연결 “livelink”(웹 사이트)이라고 하고
            후자를 피연결 “livelink”(웹사이트)이라고 합니다.{' '}
          </li>
          <li>
            연결“livelink”은 피연결“livelink”이 독자적으로 제공하는 재화 등에 의하여 회원간 행하는
            거래에 대해서 보증 책임을 지지 않습니다.{' '}
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>제12조 (고지) </TermsTitle>
        <ol>
          <li>
            회사는 회사가 제공하는 서비스를 이용하는 회원에게 서비스 이용에 관련한 중요사항을
            고지합니다.{' '}
          </li>
          <li>
            회사는 서비스의 내용에 변경이 있는 경우 변경된 서비스가 적용되기 30일 이전부터 적용 일자
            이후 상당한 기간까지 회사는 회원에 대한 통지를 하는 경우, 가입시 입력한 혹은 통지 당시에
            등록된 이메일 주소를 이용할 수 있습니다.{' '}
          </li>
          <li>
            회사는 연중무휴, 24시간 서비스를 제공함을 원칙으로 하되, 컴퓨터 등 정보통신설비의 보수,
            점검, 교체, 고장 등 운영상 상당한 이유가 있는 경우 회원에게 통지한 후 서비스의 제공을
            일시적으로 중단할 수 있습니다. 다만, 회사가 사전에 서비스의 중단을 통지할 수 없었던
            부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle> 제13조 (서비스 이용제재) </TermsTitle>
        <ol>
          <li>
            회사는 회사가 제공하는 서비스의 원활한 진행 및 회원간 안전한 서비스 이용이 이루어지도록
            하기 위하여 일정한 경우 회원자격을 정지할 수 있습니다.{' '}
            <ol type="a">
              <li>
                일시정지 : 고의적, 상습적으로 취소하거나 상호비방, 명예훼손 등 서비스 이용을
                방해하는 행위 등 운영자가 판단에 따라 경고 및 일시정지 처리됩니다.{' '}
              </li>
              <li>
                영구정지 : 유해사이트 운영, 신용카드 부정 사용행위, 매매부적합 상품의 매매,
                식품위생법 위반, 상표권, 저작권, 초상권, 특허권 등의 권리침해, 타인의 개인정보 유출
                및 제공 목적외의 이용(스팸메일 발송 등), 회원정보의 허위등록, 금융정보의 도용 및
                차용, 다른 회원의 정당한 권리의 심각한 침해, 일시정지 사유의 고의적이거나 반복적
                발생, 기타 서비스 이용규칙의 위반행위시에 적용될 수 있습니다.{' '}
              </li>
            </ol>
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>제14조 (회사의 면책) </TermsTitle>
        <ol>
          <li>Livelink 및 플랫폼에 포함된 모든 콘텐츠의 사용은 전적으로 귀하의 책임입니다.</li>
          <li>
            회사는 회원의 플랫폼 사용에 대해 책임을 지지 않으며, 회원은 자신의 콘텐츠를 백업할
            책임이 있습니다.{' '}
          </li>
          <li>
            회사는 플랫폼을 다운로드, 설치 또는 사용하거나 플랫폼에서 콘텐츠를 복사, 배포 또는
            다운로드함으로써 발생한 모든 손해에 대해 책임을 지지 않습니다. 회원는 플랫폼과 관련하여
            사용되는 데이터, 콘텐츠 및 장치의 적절한 보호 및 백업에 대한 단독 책임이 있습니다.{' '}
          </li>
          <li>
            회사는 천재지변 또는 이에 준하는 불가항력, 정보통신설비의 보수점검, 교체 또는 고장,
            통신의 두절 등으로 인하여 일시적 또는 종국적으로 서비스를 제공할 수 없는 경우, 서비스
            제공에 관한 책임이 면제됩니다. 이 경우 회사는 회사가 제공하는 인터넷사이트 화면에
            게시하거나 기타의 방법으로 회원들에게 통지합니다.{' '}
          </li>
          <li>
            회사는 인터넷 이용자 또는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지
            않습니다.{' '}
          </li>
          <li>
            회사는 회원이 다른 회원이 게재한 정보, 자료, 사실의 정확성 등을 신뢰함으로써 입은 손해에
            대하여 책임을 지지 않습니다.{' '}
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>제15조 (저작권 등 콘텐츠에 대한 권리의 귀속) </TermsTitle>
        <ol>
          <li>
            서비스의 저작권 및 지적재산권을 전적으로 회사에 귀속됩니다. 다만, 제휴 계약에 따라
            제공된 저작물은 그렇지 아니합니다.{' '}
          </li>
          <li>
            회사가 제공하는 서비스의 디자인, 회사가 만든 텍스트, 스크립트(script), 그래픽, 회원
            상호간 메시지 전송 기능 등 회사가 제공하는 서비스에 관한 모든 상표, 서비스 마크, 로고 등
            관련 저작권 기타 지적재산권은 대한민국 및 외국의 관련 법령에 근거하여 회사가 보유하고
            있거나 회사에게 소유권 또는 사용권이 있습니다.{' '}
          </li>
          <li>
            회원은 본 약관으로 인하여 서비스를 소유하거나 서비스에 관한 저작권을 보유하게 되는 것이
            아니라, 회사로부터 서비스의 이용을 허락 받게 되는 것입니다.{' '}
          </li>
          <li>
            회원은 회사에 의해 명시적으로 허락된 내용을 제외하고는 서비스를 통해 얻어지는 회원 상태
            정보를 영리 목적으로 사용, 복사, 유통할 수 없고, 회사가 만든 텍스트, 스크립트, 그래픽의
            회원 상호간 메시지 전송 기능 등을 복사하거나 유통할 수 없습니다.{' '}
          </li>
          <li>
            회사는 서비스와 관련하여 회원에게 회사가 정한 이용 조건에 따라 계정, 아이디, 콘텐츠 등을
            이용할 수 있는 이용 권한을 부여하며, 회원은 이를 양도, 판매, 담보제공 등의 처분행위를 할
            수 없습니다.{' '}
          </li>
          <li>
            회사는 서비스를 통해 얻어지는 회원의 게시물 관련 정보를 영리 목적으로 회원의 허락 없이
            사용할 수 있습니다.{' '}
          </li>
          <li>
            회원이 서비스를 이용하면서 발생 및 생성된 정보에 대한 저작권 및 지적재산권은 모두 회사에
            귀속됩니다. 다만, 회원이 단독으로 생성한 콘텐츠에 대해서는 회사와 해당 회원에게 공동
            소유권 또는 사용권이 있습니다.{' '}
          </li>
          <li>
            회사는 약정에 따라 회원에게 귀속된 저작권을 사용하는 경우 당해 회원에게 통보하여야
            합니다. 단, “livelink”은 회원이 작성한 후기는 통보없이 마케팅에 사용할 수 있습니다.{' '}
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>제16조 (분쟁) </TermsTitle>
        <ol>
          <li>회원이 제기하는 정당한 의견이나 불만을 반영하고자 노력하고, 운영합니다.</li>
          <li>
            “livelink”는 회원으로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다.
            다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해
            드립니다.{' '}
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>제17조 (재판권 및 준거법) </TermsTitle>
        <ol>
          <li>
            본 약관과 회사와 회원 간의 서비스 이용 계약에 관하여는 대한민국 법령이 적용되며,
            분쟁발생시 분쟁을 해결하기 위한 소송이나 절차는 회사의 본점 소재지를 관할하는 법원에
            따르는 것으로 합니다.{' '}
          </li>
          <li>
            본 약관의 조항이 국가의 법률에 따라 무효인 경우 해당 국가의 본 약관에서 분리되고 본
            약관의 나머지 부분이 계속 적용됩니다.{' '}
          </li>
          <li>
            회사가 본 약관의 조항을 주장하거나 시행하지 않는 경우 조항이나 권리의 포기로 간주되지
            않습니다. 본 약관이나 플랫폼은 회원과 회사 사이에 대리인/본인의 관계를 생성하지
            않습니다.{' '}
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <p>이 약관은 2022년 5월 2일부터 시행합니다. </p>
      </TermsSection>
    </PaddingLayout>
  );
};

export default ServiceTerms;
