import React from 'react';
import { observer } from 'mobx-react';
import MobileModalHeader from 'components/commons/MobileModalHeader';
import UseTermsContainer from 'features/mainFeatures/terms/containers/UseTermsContainer';
import PrivateTerms from 'pages/useTerms/PrivateTerms';
import DetailPageHeader from 'components/commons/DetailPageHeader';

const PrivacyPolicyPage = observer(() => {
  return (
    <>
      {/* <DetailPageHeader title="개인정보 수집 및 동의" /> */}
      <PrivateTerms />
    </>
  );
});

export { PrivacyPolicyPage };
