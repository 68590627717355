import React, { useEffect, useState, CSSProperties } from 'react';
import {
  ItemContent,
  ItemSectionStyle,
  ItemSectionTitle,
} from 'resources/controls/ItemSectionStyle';
import { OhmossButton } from 'resources/controls/OhmossButton';
import { Align, ITextBlock } from 'types/BlockExtendsTypes';
import { Row } from 'components/commons/layouts';
import { useStore } from 'stores/StoreHelper';
import { validationHelper } from 'controls/validationHelper';
import { useOhmossContext } from 'features/management/context/useOhmossContext';
import { LinkTypes } from 'features/management/context/providers/ContextTypes';

import UrlForm from 'components/items/forms/UrlForm';
import TitleForm from 'components/items/forms/TitleForm';
import MenuItemCheckBox from 'components/commons/menuItem/MenuItemCheckBox';
import useInitializeModel from 'libs/hooks/useInitializeModel';
import useSetRef from 'libs/hooks/useSetRef';
import CommonAlign from '../commonControl/CommonAlign';
import CommonFontSelect from '../commonControl/CommonFontSelect';
import CommonColorSet from '../commonControl/CommonColorSet';
import BlockController from 'controllers/BlockController';
import ActionBox from 'features/csTown/components/pc/ActionBox';

const TextLink = ({ blockModel }: { blockModel?: ITextBlock }) => {
  const { uiStore } = useStore();
  const defaultProps: Partial<ITextBlock> = {
    linkType: 'TextLink',
    isActive: true,
  };

  const { initialModel, updateServiceModel, serviceModel, unmountModel, initialBinding } =
    useOhmossContext<LinkTypes<ITextBlock>>();

  const { updateBlock } = BlockController<ITextBlock>();

  const [localModel, setLocalModel] = useInitializeModel<Partial<ITextBlock>>(
    blockModel,
    defaultProps,
    initialBinding,
  );

  const [isValid, setIsValid] = useState<boolean>(false);
  const [contentStyleRef, setContentStyleRef] = useSetRef<CSSProperties>();

  useEffect(() => {
    if (localModel) {
      const title = validationHelper.required(localModel.title);
      const content = validationHelper.required(localModel.content);
      const url = validationHelper.urlOrEmpty(localModel.linkUrl);
      if (localModel.showTitle === true) {
        setIsValid(title && content && url);
      } else {
        setIsValid(content && url);
      }
    }
  }, [localModel]);

  useEffect(() => {
    if (initialModel?.contentStyle) {
      setContentStyleRef(JSON.parse(initialModel.contentStyle));
    }
  }, [initialModel?.contentStyle]);

  useEffect(() => {
    return () => {
      unmountModel();
    };
  }, []);

  return (
    <div style={{}}>
      <ItemSectionStyle>
        <ItemSectionTitle>타이틀</ItemSectionTitle>
        <ItemContent>
          <TitleForm
            onChangeValue={(value: string) => {
              setLocalModel('title', value);
              updateServiceModel('title', value);
            }}
            initialValue={initialModel?.title}
            errorText="타이틀은 필수항목입니다."
            placeHolder="타이틀을 작성해 주세요"
            label="타이틀"
          />
        </ItemContent>
      </ItemSectionStyle>
      <ItemSectionStyle>
        <TitleForm
          initialValue={initialModel?.content}
          multiline
          rows={10}
          maxLength={1000}
          useCount
          onChangeValue={(value: string) => {
            setLocalModel('content', value);
            updateServiceModel('content', value);
          }}
        />
      </ItemSectionStyle>
      <CommonFontSelect
        // initialValue={initialModel?.contentStyle}
        onChange={(value: CSSProperties) => {
          if (value) {
            setContentStyleRef({ ...value });
          }
        }}
        useTextEffect
      />
      <CommonColorSet
        title="배경 색상"
        initialValue={initialModel?.contentStyle}
        onChange={(color: string) => {
          setContentStyleRef('background', color);
        }}
      />
      <CommonAlign
        initialValue={initialModel?.contentAlign || 'center'}
        onChange={(align: Align) => updateServiceModel('contentAlign', align)}
      />
      <ItemSectionStyle>
        <ItemSectionTitle>링크주소</ItemSectionTitle>
        <ItemContent>
          <UrlForm
            onChangeValue={(value: string) => {
              setLocalModel('linkUrl', value);
              updateServiceModel('linkUrl', value);
            }}
            initialValue={initialModel?.linkUrl}
            label="링크주소"
          />
        </ItemContent>
      </ItemSectionStyle>
      <MenuItemCheckBox
        onChangeValue={(value: boolean) => {
          setLocalModel('showTitle', value);
          updateServiceModel('showTitle', value);
        }}
        initialValue={initialModel?.showTitle}
        title="타이틀 표시하기"
      />
      <MenuItemCheckBox
        onChangeValue={(value: boolean) => {
          updateServiceModel('useTransparent', value);
        }}
        initialValue={initialModel?.useTransparent}
        title="투명배경"
      />
      <ActionBox
        style={{ marginTop: 60, paddingBottom: 20 }}
        isValid={isValid}
        okText="저장"
        onOk={() => {
          updateServiceModel('contentStyle', JSON.stringify(contentStyleRef.current));
          updateBlock(serviceModel);
        }}
        onCancel={() => {
          uiStore.modal.close();
        }}
      />
    </div>
  );
};

export default TextLink;
