import RootStore from 'stores/RootStore';
import FileStore from 'stores/FileStore';
import MyChannelStore from 'stores/MyChannelStore';
import {
  CustomFile,
  LinkModel,
  ChannelProfileModel,
  MetadataObjectTypes,
  MetadataObjectModel,
  PostModel,
} from 'types/CommonTypes';
import { ChannelDto, ChannelProfileDto, FreeImageDto } from 'services/data-contracts';

/**
 * @description 카드를 생성하거나 업데이트 한다
 * @param model model that will updated
 * @param isCreate true: create, false : update
 * @returns
 */
export const updateLink = async (
  model: LinkModel | PostModel,
  isModify: boolean,
  stores: typeof RootStore,
  type?: 'post' | 'link',
) => {
  try {
    if (model?.thumbnail?.file) {
      const data: CustomFile = (await stores.fileStore.uploadImage(
        model?.thumbnail.file,
        'Link',
      )) as any;
      model.thumbnail = { id: data.id };
    }

    if (typeof model.metadataObject !== 'undefined') {
      model.metadata = JSON.stringify(model.metadataObject);
    }
    if (isModify) {
      // TODO: 예외처리 필요
      if (type === 'post') {
        if (stores.channelStore.channel.isOwner) {
          return (await stores.myPostStore.updatePost(
            stores.channelStore?.channel?.id as number,
            stores.uiStores.userHomeStore?.currentBoard?.id as number,
            model?.id as number,
            model as PostModel,
          )) as any;
        } else {
          return (await stores.postStore.updateItem(
            stores.myChannelStore?.currentChannel?.id as number,
            model as PostModel,
          )) as any;
        }
      } else {
        return (await stores.myLinkStore.updateItem(
          stores.myChannelStore?.currentChannel?.id as number,
          stores.uiStores.userHomeStore.currentBoard?.id as number,
          model as any,
        )) as any;
      }
      // TODO: translate
    } else {
      if (!model.items) {
        model.items = [];
      }
      model.isActive = true;
      if (type === 'post') {
        if (stores.channelStore.channel.isOwner) {
          return (await stores.postStore.createPost(
            stores.channelStore.channel?.id as number,
            stores.uiStores.userHomeStore.currentBoard?.id as number,
            model as PostModel,
          )) as any;
        } else {
          return (await stores.postStore.createItem(
            stores.myChannelStore?.currentChannel?.id as number,
            model as PostModel,
          )) as any;
        }
      } else {
        return (await stores.myLinkStore.createItem(
          stores.myChannelStore?.currentChannel?.id as number,
          stores.uiStores.userHomeStore.currentBoard?.id as number,
          model as any,
        )) as any;
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateModel = (model: any, key: string, value: any) => {
  model((prevState: any) => {
    return {
      ...prevState,
      [key]: value,
    };
  });
};

/**
 * @description Input의 입력값이 정상적인 경우, http를 붙여서 반환한다.
 * @param value
 * @returns
 */
export const replaceStringToUrl = (value: string) => {
  const v = value.trim().indexOf('http');
  if (v === 0) {
    return value;
  }
  if (v === -1) {
    // 대부분의 웹사이트는 http로 접근하면 자동으로 https로 리다이렉트 한다.
    // https로 선언하지 않는 이유는 아직까지 https를 기본으로 하는 사이트가 존재하기 때문에 오류가 발생한다.
    return `http://${value.trim()}`;
  } else {
    return value;
  }
};

export const updateProfile = async (
  profile: ChannelProfileModel,
  fileStore: FileStore,
  myChannelStore: MyChannelStore,
  metadataObject: MetadataObjectModel,
  type?: 'Profile' | 'Background' | 'Footer',
) => {
  const profileClone: ChannelProfileModel = { ...profile };
  if (type === 'Profile') {
    if (profileClone?.profileImage?.file) {
      const image: CustomFile = (await fileStore.uploadImage(
        profileClone?.profileImage?.file,
        type,
      )) as CustomFile;
      profileClone.profileImage = { id: image.id };
    }

    if (typeof profileClone.profileImage === 'undefined') {
      profileClone.profileImage = null;
    }
  } else if (type === 'Background') {
    if (profileClone?.backgroundImage?.file) {
      const image: CustomFile = (await fileStore.uploadImage(
        profileClone?.backgroundImage?.file,
        type,
      )) as CustomFile;
      profileClone.backgroundImage = { id: image.id };
    }

    if (typeof profileClone.backgroundImage === 'undefined') {
      delete profileClone.backgroundImage;
    }
  } else if (type === 'Footer') {
    if (profileClone?.footerImage?.file) {
      const image: CustomFile = (await fileStore.uploadImage(
        profileClone?.footerImage?.file,
        type,
      )) as CustomFile;
      profileClone.footerImage = { id: image.id };
    }

    if (typeof profileClone.footerImage === 'undefined') {
      profileClone.footerImage = null;
    }
  }

  if (profileClone) {
    // if (profileClone.image) delete profileClone.image.publicUrl;
    profileClone.metadata = JSON.stringify(metadataObject);
    delete profileClone.metadataObject;
    const channel = (await myChannelStore.updateItem(
      myChannelStore.currentChannel?.id as number,
      profileClone,
    )) as ChannelDto;

    if (channel) {
      // setProfileModel(channel?.profile);
      myChannelStore.setCurrentChannel(channel as ChannelProfileDto);
    }
  }
};

export const updateMetadata = (
  metadataObject: MetadataObjectModel,
  propsType: MetadataObjectTypes,
) => {
  switch (propsType) {
    case 'background':
      metadataObject.backgroundProps;
      return metadataObject;

    default:
      break;
  }
};

export const getFeedThmbnailUrl = (url?: string, size?: '150' | '300' | '900') => {
  if (url) {
    const first = new URL(url || '');
    return `${first.origin}/thumbnails/${size}${first.pathname}`;
  }
  return '';
};

export const getFeedThmbnailUrlAsync = async (url: string, size = '300') => {
  if (!url) return '';

  try {
    const thumbnailUrl = new URL(url);
    const fullThumbnailUrl = `${thumbnailUrl.origin}/thumbnails/${size}${thumbnailUrl.pathname}`;

    // 서버로부터의 응답을 기다리는 부분
    let response = await fetch(fullThumbnailUrl);
    let retryCount = 0;
    const maxRetries = 5;

    // 정상적인 응답(200)을 받을 때까지 반복
    while (!response.ok && retryCount < maxRetries) {
      await new Promise(resolve => setTimeout(resolve, 2000)); // 2초 대기
      response = await fetch(fullThumbnailUrl);
      retryCount++;
    }

    // 정상 응답이면 URL 반환, 아니면 빈 문자열 반환
    return response.ok ? fullThumbnailUrl : '';
  } catch (error) {
    console.error('Error fetching thumbnail:', error);
    return '';
  }
};


export const stripedSting = (content?: string, length?: number) => {
  if (content) {
    const textOnly = content.replace(/<[^>]+>|&[^;]+;/g, '');
    return length
      ? `${textOnly.substring(0, length)} ${textOnly.length > length ? '...' : ''}`
      : textOnly;
  } else {
    return content;
  }
};

export function initLinkItemDto<T extends Object | undefined>(
  linkType:
    | 'NoticeLink'
    | 'ItemLink'
    | 'SimpleLink'
    | 'CardLink'
    | 'TelLink'
    | 'MapLink'
    | 'Youtube'
    | 'Video'
    | 'Page'
    | 'TextLink'
    | 'Suggestion'
    | 'Booking',
  metadata?: T | undefined,
): LinkModel {
  const defaultDto: LinkModel = {
    linkType,
    title: '',
    channel: {},
    seq: 0,
    isActive: true,
    metadataObject: metadata,
  };
  return defaultDto;
}
