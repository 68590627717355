import { makeAutoObservable } from 'mobx';
import { ChannelConfigModel, DefaultPageTemplateMode } from 'types/CommonTypes';
import { ChannelBoardDto } from 'services/data-contracts';

import _ from 'lodash';

/**
 * @description Public Link Store
 */
class UserHomeStore {
  constructor() {
    makeAutoObservable(this);
  }

  channelConfig: ChannelConfigModel;

  currentBoard: ChannelBoardDto | undefined;

  isFollow = false;

  // 피드 수정, 삭제등 현제 작업이 일어난 모드를 기억한다.
  lastPageMode = false;

  setChannelConfig(config: ChannelConfigModel) {
    this.channelConfig = config;
  }

  setPreventModelChange(result: boolean) {
    this.lastPageMode = result;
  }

  setChannelFollow(follow: boolean) {
    this.isFollow = follow;
  }

  setBoard(board: ChannelBoardDto | undefined) {
    this.currentBoard = board;
  }
}

export default UserHomeStore;
